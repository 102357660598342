
import _ from 'lodash';

import {
  START_LOADING_TARGET_DE_OBJECT_ID, STOP_LOADING_TARGET_DE_OBJECT_ID, ADD_TARGET_DATA_EXTENSION,
  REMOVE_TARGET_DATA_EXTENSION,
} from '../../actions/types';

const initialState = {
  targetDataExtensions: [],
  loadingTargetDEObjectIDs: [],
};

/**
 * Function that takes an action and the previous state of the application and returns the new state
 * @param {object} state - contain initial and final state of data
 * @param {object} action - return the action object
 * @returns {object} updated state
 */
const targetDataExtensionReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING_TARGET_DE_OBJECT_ID:
      return {
        ...state,
        loadingTargetDEObjectIDs: _.flatten([...state.loadingTargetDEObjectIDs, action.payload]),
      };

    case STOP_LOADING_TARGET_DE_OBJECT_ID:
      return {
        ...state,
        // reduce an array with object IDs
        loadingTargetDEObjectIDs: state.loadingTargetDEObjectIDs.reduce((objectIDsArray, objectID) => {
          // find object id in action.payload
          const getObjectIDFromPayload = action.payload.find(payload => payload === objectID);

          // do not return Object ID when it's stops loading
          if (!getObjectIDFromPayload && getObjectIDFromPayload !== '') {
            return [...objectIDsArray, objectID];
          }

          return objectIDsArray;
        }, []),
      };
    case ADD_TARGET_DATA_EXTENSION:
      return {
        ...state,
        targetDataExtensions: _.flatten([...state.targetDataExtensions,
          action.payload]),
      };
    case REMOVE_TARGET_DATA_EXTENSION:
      return {
        ...state,
        targetDataExtensions: state.targetDataExtensions.filter(targetDE => targetDE.ObjectID !== action.payload),
      };
    default:
      return state;
  }
};

export default targetDataExtensionReducer;
