import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import Button from '../../shared_v2/Button/Button';
import Constants from '../../../constants/constants';
import Select from '../../shared_v2/Select/Select';

const Pagination = ({
  paginationIndex,
  handleChangePaginationIndex,
  pageItems,
  axiosCancelToken,
  hasNoBackground,
}) => (
  <div className={hasNoBackground ? 'pagination_container_with_no_bg' : 'pagination_container'}>
    <nav aria-label="Page navigation example" id="pagination">
      <ul>
        <li
          className={`${(paginationIndex === 0 || paginationIndex === 1) &&
              'disabled'}`}
        >
          <Button
            id="pagination_left"
            buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
            className="previous-btn"
            onClick={() => handleChangePaginationIndex(null, paginationIndex - 1, axiosCancelToken)}
            disabled={paginationIndex === 1}
          >
            <span className="slds-icon_container slds-icon-utility-announcement">
              <svg
                className="slds-icon slds-icon-text-default slds-icon_x-small"
                aria-hidden="true"
              >
                <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#left" />
              </svg>
            </span>
          </Button>
        </li>
        <div className="slds-form-element pagination-select">
          <div className="slds-form-element__control">
            <Select
              id="paginationMiddle"
              onChange={e => handleChangePaginationIndex(e, null, axiosCancelToken)}
              value={paginationIndex?.toString()}
              options={pageItems.map(item => ({ value: item, label: item }))}
            />
          </div>
        </div>
        <li
          className={`${paginationIndex === pageItems.length && 'disabled'}`}
        >
          <Button
            id="pagination_right"
            buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
            className="next-btn"
            onClick={() => handleChangePaginationIndex(null, paginationIndex + 1, axiosCancelToken)}
            disabled={paginationIndex >= pageItems.length}
          >
            <span className="slds-icon_container slds-icon-utility-announcement">
              <svg
                className="slds-icon slds-icon-text-default slds-icon_x-small"
                aria-hidden="true"
              >
                <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#right" />
              </svg>
            </span>
          </Button>
        </li>
      </ul>
    </nav>
  </div>
);

Pagination.propTypes = {
  /**
   * This prop keeps the current section on Overview page for the Selections
   * It will be passed from Overview.js
   */
  paginationIndex: PropTypes.number,
  /**
   * This prop helps to change the section on Overview page
   * It will be passed from Overview.js
   */
  handleChangePaginationIndex: PropTypes.func,
  /**
   * It keep the page numbers and determines how many buttons will be rendered for pagination
   * It will be passed from Overview.js
   */
  pageItems: PropTypes.instanceOf(Array),
  /**
   * It helps to cancel a subscription of an API call to backend
   */
  axiosCancelToken: PropTypes.instanceOf(Object),
};

export default Pagination;
