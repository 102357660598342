import React from 'react';
import PropTypes from 'prop-types';

import Constants from '../../../../../constants/constants';
import Util from '../../../../../util';

const RelatedRow = ({
  predefinedRelation, handleSetSelectionState, DEBorderMouseOver, deAlias, selectedDataExtension,
}) => {
  /**
   * returns an object with predefined relations depending on the selected data extension and defined relation
   * @returns {object} relation data
   */
  const getPredefinedRelationData = () => {
    if (selectedDataExtension.ObjectID === predefinedRelation.toDEObjectId) {
      // replace toDE with fromDE and vice versa
      return Util.replaceOrdersInPredefinedRelation(predefinedRelation);
    }

    // return predefined relation unchanged
    return predefinedRelation;
  };

  /**
   * onDragStart set data to transfer
   * @param {object} event - event
   * @param {object} relation - object with predefinedRelation data
   * @returns {void}
   */
  const dragDE = (
    event,
    relation,
  ) => {
    event.dataTransfer.setData('deObjectID', relation.fromDEObjectId);
    event.dataTransfer.setData('fieldObjectID', relation.fromFieldObjectId);
    event.dataTransfer.setData('alias', deAlias);
    event.dataTransfer.setData('type', Constants.FILTERLINE__FILTER_TYPE__RELATION);

    // pass relation ID
    event.dataTransfer.setData('relationId', relation._id);
  };

  // define predefined relation data
  const predefinedRelationData = getPredefinedRelationData();

  return (
    <div
      title={predefinedRelationData.toDEName}
      id={deAlias + predefinedRelationData.fromFieldObjectId}
      data-collection-alias={deAlias}
      className="drag-field available-field"
      data-field={predefinedRelationData.fromDEName.toString()}
      onDragStart={(e) => {
        handleSetSelectionState({ filterBorderMouseOver: true, DEBorderMouseOver: false });

        return dragDE(e, predefinedRelationData);
      }}
      onDragEnd={() => {
        handleSetSelectionState({ filterBorderMouseOver: false, DEBorderMouseOver: false });
      }}
      onDragOver={e => e.preventDefault()}
      onDrop={() => { handleSetSelectionState({ filterBorderMouseOver: false, DEBorderMouseOver: false }); }}
      draggable
      key={deAlias + predefinedRelationData.fromFieldObjectId}
      style={{ pointerEvents: DEBorderMouseOver ? 'none' : '' }}
    >
      {predefinedRelationData.toDEName}
    </div>
  );
};

RelatedRow.propTypes = {
  /**
   * An object containing predefinedRelation for selected Data Extension
   */
  predefinedRelation: PropTypes.instanceOf(Object).isRequired,
  /**
   * It helps to set the Selection`s state
   * It will be passed from Selection.js
   */
  handleSetSelectionState: PropTypes.func.isRequired,
  /*
   * Keeps track whether related DE is dragged
   */
  DEBorderMouseOver: PropTypes.bool.isRequired,
  /*
   * deAlias for fromDE in predefined relation
   */
  deAlias: PropTypes.string.isRequired,
  /**
   * An object containing Selected Data Extension for which relations have been defined
   */
  selectedDataExtension: PropTypes.instanceOf(Object).isRequired,
};

export default RelatedRow;
