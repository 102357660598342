import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import Button from '../../shared/Button/Button';
import Constants from '../../../constants/constants';

const Banner = ({ onclickHideBannerModal }) => {
  const handleUpgrade = () => {
    window.open('https://deselect.com/contact/', '_blank');
  };

  return (
    <div className="banner_header" id="banner-header">
        <div className="banner_wrapper">
            <div className="banner_title">
            Unlock the Full Power of DESelect! 🚀
             Upgrade to a paid DESelect plan for Advanced Features and Unleash Your Marketing Potential. 🌟
              Get Started Today!
            </div>
            <div className="banner_buttons">
            <Button
            id="banner"
            buttonLook={Constants.BUTTON__TYPE__BRAND}
            onClick={() => handleUpgrade()}
            >
                <svg className="slds-button__icon" aria-hidden="true">
                    <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#favorite" />
                </svg>
                <span className="banner-span">Upgrade</span>
            </Button>
            <Button
            noButtonClass
            className="remove-tab"
            id="remove-tab"
            onClick={() => onclickHideBannerModal()}
            >
                <i className="fas fa-times remove-tab" />
            </Button>
            </div>
        </div>
    </div>
  );
};

Banner.propTypes = {
/**
 * Hide banner function
 */
  onclickHideBannerModal: PropTypes.func.isRequired,
};
export default Banner;
