import axios from 'axios';

import Util from '../util';

const apiUrl = process.env.REACT_APP_API_URL;

const NotificationsAPI = {
  /**
   * Retrieves active notifications for the user
   * @param {object} cancelToken - Axios token
   * @returns {array<object>} Notifications data
   */
  getNotifications: async (cancelToken) => {
    const res = await Util.catch418And403Error(
      axios.get,
      `${apiUrl}/notifications`,
      Util.apiGetCallSettings(cancelToken),
    );

    return res.data;
  },
};

export default NotificationsAPI;
