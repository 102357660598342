import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import mapStateToProps from '../../../mapStateToProps';
import Tooltip from '../../shared/Tooltip/Tooltip';
import Checkbox from '../../shared/Checkbox/Checkbox';
import Constants from '../../../constants/constants';
import './styles.scss';
import ModalTemplate from '../../shared/ModalTemplate/ModalTemplate';

class TemplateSettingsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTemplate: props.isTemplate,
      templateDescription: props.templateDescription,
      selectionDescription: props.selectionDescription,
      templateInstructions: props.templateInstructions,
    };
  }

  /**
   * Handles the closing of the template settings modal
   * @returns {void}
   */
  handleCloseTemplateSettingsModal = () => {
    const { handleSetSelectionState } = this.props;

    handleSetSelectionState({ showTemplateSettingsModal: false });
  };

  /**
   * OnChange event handler for text areas
   * @param {object} e - The event object
   * @returns {void}
   */
  handleOnChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  /**
   * Handles the saving of template settings
   * @returns {void}
   */
  saveTemplateSettings = () => {
    const {
      handleSetSelectionState,
    } = this.props;
    const {
      isTemplate,
      templateDescription,
      selectionDescription,
      templateInstructions,
    } = this.state;

    handleSetSelectionState({
      isTemplate,
      templateDescription,
      selectionDescription,
      templateInstructions,
      showTemplateSettingsModal: false,
    });
  };

  /**
   * Handles the disabling of template editing if the user is not an admin or
   * the template selection is not created by the active user
   * @returns {boolean} - Determines whether saving should be disabled
   */
  shouldIsTemplateCheckboxBeDisabled = () => {
    const { isTemplate, selectionCreator, userInfo } = this.props;

    return isTemplate && !userInfo.isAdmin && userInfo.id !== selectionCreator;
  };

  render() {
    const {
      isTemplate,
      selectionDescription,
      templateDescription,
      templateInstructions,
    } = this.state;

    return (
      <ModalTemplate
          id="selection-settings-modal-dialog"
          containerClassName="template-settings-modal"
          headerId="template-settings-header"
          headerTitle="Selection Settings"
          contentClassName="template-settings-content"
          footerId="template-setting-footer"
          cancelButtonId="template-settings-cancel"
          handleCancel={this.handleCloseTemplateSettingsModal}
          saveButtonId="template-settings-save"
          handleSave={this.saveTemplateSettings}
        >
          <div className="selection-description-container">
            <h2>Selection Description</h2>
            <div className="slds-form-element">
              <div className="slds-form-element__control">
                <textarea
                  value={selectionDescription}
                  name="selectionDescription"
                  onChange={this.handleOnChange}
                  className="slds-textarea"
                  aria-label="Selection Description"
                  disabled={this.shouldIsTemplateCheckboxBeDisabled()}
                />
              </div>
            </div>
          </div>
          <div className="checkbox-container">
            <Checkbox
              id="isTemplate"
              name="isTemplate"
              label="This selection is a template."
              checked={isTemplate}
              onChange={() => this.setState({ isTemplate: !isTemplate })}
              disabled={this.shouldIsTemplateCheckboxBeDisabled()}
              formElementClassName="template-checkbox"
            />
            <Tooltip
              nubbinPosition={Constants.NUBBIN_POSITION__TOP_LEFT}
              type={Constants.TOOLTIP_TYPE__TEMPLATE_SELECTION}
            />
          </div>
          <div>
            <div className="template-description-container">
              <h2>Template Description</h2>
              <div className="slds-form-element">
                <div className="slds-form-element__control">
                  <textarea
                    value={templateDescription}
                    name="templateDescription"
                    onChange={this.handleOnChange}
                    className="slds-textarea"
                    aria-label="Template Description"
                    disabled={!isTemplate || this.shouldIsTemplateCheckboxBeDisabled()}
                  />
                </div>
              </div>
            </div>
            <div className="template-instructions-container">
              <h2>Template Instructions</h2>
              <div className="slds-form-element">
                <div className="slds-form-element__control">
                  <textarea
                    value={templateInstructions}
                    name="templateInstructions"
                    onChange={this.handleOnChange}
                    className="slds-textarea"
                    aria-label="Template Instructions"
                    disabled={!isTemplate || this.shouldIsTemplateCheckboxBeDisabled()}
                  />
                </div>
              </div>
            </div>
          </div>
      </ModalTemplate>
    );
  }
}

TemplateSettingsModal.propTypes = {
  /*
   * Sets the Selection`s state
   * It is passed from Selection.js
   */
  handleSetSelectionState: PropTypes.func.isRequired,
  /**
   * Indicates whether a selection is a template
   */
  isTemplate: PropTypes.bool.isRequired,
  /**
   * Selection's description
   */
  selectionDescription: PropTypes.string.isRequired,
  /**
   * Template's description
   */
  templateDescription: PropTypes.string.isRequired,
  /**
   * Template's instructions
   */
  templateInstructions: PropTypes.string.isRequired,
  /**
   * Id of the user that created the selection
   */
  selectionCreator: PropTypes.string,
  /**
   * User info from cookie
   */
  userInfo: PropTypes.object,
};

export default connect(mapStateToProps(['userInfo']), null, null, { pure: false })(TemplateSettingsModal);
