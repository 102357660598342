import React from 'react';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import axios from 'axios';
import { connect } from 'react-redux';

import Constants from '../../../constants/constants';
import DataSetsAPI from '../../../api/data-sets';
import AdminHeader from '../../shared/AdminHeader/AdminHeader';
import Table from '../../shared/Table/Table';
import timeUtil from '../../../utils/time/timeUtil';
import ActionButton from '../../shared_v2/ActionButton/ActionButton';
import './styles.scss';
import mapStateToProps from '../../../mapStateToProps';

const DataSets = ({
  dataSets = [],
  handleSetAdminPanelState,
  handleEditDataSet,
  openPanel,
  handleSortDataSets,
  sortDirection,
  sortedProperty,
  isLoading,
  userInfo,
}) => {
  const DATEFORMAT = timeUtil.getUserDateTimeFormat(userInfo);

  const tableHeadersData = [
    {
      title: 'Name',
      propertyName: 'name',
      sortIconId: 'nameSort',
      leftSize: Constants.TABLE__CELL_LEFT_SIZE__SMALL,
    },
    {
      title: 'Last Modified Date',
      propertyName: 'updatedAt',
      sortIconId: 'updatedAtSort',
      leftSize: Constants.TABLE__CELL_LEFT_SIZE__X_SMALL,
    },
    {
      title: 'Action',
    },
  ];
    /**
     * Deletes the dataSet selected in the
     * overview with the given id
     * @param {String} dataSetId - id of the dataSet to delete
     * @returns {void}
     */
  const handleRemoveDataSet = async (dataSetId) => {
    const result = await Swal.fire({
      type: 'warning',
      title: 'Remove Data Set',
      html: '<p class="width_swal">Are you sure you want to remove this data set?</p>',
      showCancelButton: true,
      confirmButtonText: 'Remove',
      footer: '<div></div>',
      buttonsStyling: false,
    });

    if (result.value) {
      // Delete dataSet
      await DataSetsAPI.deleteDataSet(dataSetId, axios.CancelToken.source().token);

      // Reload data
      openPanel(Constants.ADMIN_PANEL__MENU__DATA_SETS);
    }
  };

  const tableData = dataSets && dataSets.length > 0 ?
    (dataSets.map(dataSet => (
    <tr key={dataSet._id} className="slds-hint-parent row-data">
      <td data-label="Name">
        <div className="slds-truncate">
          <a
            href="#!"
            title={dataSet.name}
            onClick={(e) => {
              handleEditDataSet(e, dataSet._id, dataSet.dataExtensionCustomerKey);
            }}
          >
            {dataSet.name}
          </a>
        </div>
      </td>
      <td data-label="Last Modified Date">
        <div className="slds-truncate slds-m-left_x-small">{timeUtil.formatDate(dataSet.updatedAt, DATEFORMAT)}</div>
      </td>
      <td data-label="Action">
        <div className="datasets-actions-button">
        <ActionButton
          rowIndex={dataSet._id}
          actions={[{
            title: 'Delete',
            iconCategory: 'utility',
            iconName: 'delete',
            actionHandler: () => handleRemoveDataSet(dataSet._id),
          }]}
        />
        </div>
      </td>
    </tr>
    ))) :
    (
    <tr className="row-data">
      <td
        colSpan="5"
        className="slds-text-align_center"
      >
        <div id="no-dataSets-defined">
          No DataSet have been defined yet. Hit the &apos;New Data Set&apos; button to get started.
        </div>
      </td>
    </tr>
    );

  return (
    <>
      {/* Data Sets header */}
            {/* Picklists header */}
      <AdminHeader
        title="Data Sets"
        buttonId="new-dataset"
        onClick={() => handleSetAdminPanelState({ activePanel: Constants.ADMIN_PANEL__MENU__NEW_DATA_SET })}
        buttonLabel="New Data Set"
        iconLink="/assets/icons/standard-sprite/svg/symbols.svg#picklist_type"
      />

      <Table
        tableHeaders={tableHeadersData}
        bodyContent={tableData}
        id="datasets-panel"
        className="dataset-table"
        handleSort={handleSortDataSets}
        sortedProperty={sortedProperty}
        sortDirection={sortDirection}
        isLoading={isLoading}
      />
    </>
  );
};

DataSets.propTypes = {
  /**
   * array containing the dataSets retrieved, this prop comes
   * from the admin panel component
   */
  dataSets: PropTypes.instanceOf(Array).isRequired,
  // handles the state of the admin panel
  handleSetAdminPanelState: PropTypes.func.isRequired,
  // function to open another panel / reload data
  openPanel: PropTypes.func.isRequired,
  // function to open the edit screen for a dataSet
  handleEditDataSet: PropTypes.func.isRequired,
  /**
   * It sorts dataSets
   */
  handleSortDataSets: PropTypes.func.isRequired,
  /**
   * It indicates the direction of sort
   */
  sortDirection: PropTypes.string.isRequired,
  /**
   * It gives by which value sort will take place
   */
  sortedProperty: PropTypes.string,
  /**
   * Responsible for showing/hiding loader
   */
  isLoading: PropTypes.bool.isRequired,
  /**
   * User info from cookie
   */
  userInfo: PropTypes.object,
};

export default connect(mapStateToProps(['userInfo']), null, null, { pure: false })(DataSets);
