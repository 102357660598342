import { useState, useEffect } from 'react';
import axios from 'axios';

import PicklistsAPI from '../../api/picklists';

/**
 *  Custom hook for updating picklists
 * @returns {Array} - Array that contains state of picklists
 */
const usePicklists = () => {
  const [picklists, setPicklists] = useState([]);

  /**
   * Fetches picklists and updates state.
   * @returns {void}
   */
  const fetchPicklists = async () => {
    const pickLists = await PicklistsAPI.getPicklists(
      axios.CancelToken.source().token,
    );

    setPicklists(pickLists);
  };

  useEffect(() => {
    fetchPicklists();
  }, []);

  // return custom hook elements
  return [picklists, setPicklists];
};

export { usePicklists };
