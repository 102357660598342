import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import mapStateToProps from '../../../../../../mapStateToProps';
import Constants from '../../../../../../constants/constants';
import Features from '../../../../../../features';
import './styles.scss';
import Tooltip from '../../../../../shared/Tooltip/Tooltip';
import RadioButton from '../../../../../shared/RadioButton/RadioButton';

const Modes = [
  Constants.PRIO_DEDUP__MODE__BASIC__LABEL,
  Constants.PRIO_DEDUP__MODE__ADVANCED__LABEL,
];

/**
 * Indicates if a given mode should be enabled for user or not, given the feature flags
 * @param {object} mode - Label of the mode option that's being rendered
 * @param {object} featuresInfo - Features info from cookie
 * @returns {boolean} True/false if mode option should be disabled for user
 */
const isModeDisabled = (mode, featuresInfo) => {
  const featurePrioDedup = Features.isFeatureEnabled(
    featuresInfo,
    Constants.FEATURE__PRIO_DEDUP,
  );

  let disabled;

  switch (mode) {
    case Constants.PRIO_DEDUP__MODE__BASIC__LABEL:
      if (featurePrioDedup === Constants.FEATURE__PRIO_DEDUP__BASIC ||
        featurePrioDedup === Constants.FEATURE__PRIO_DEDUP__ADVANCED) {
        disabled = false;
      }
      break;

    case Constants.PRIO_DEDUP__MODE__ADVANCED__LABEL:
      disabled = (featurePrioDedup !== Constants.FEATURE__PRIO_DEDUP__ADVANCED);
      break;

    default:
      disabled = true;
  }

  return disabled;
};

/**
 * checks the selected deduplication mode and sets the correct description
 * @param {string} currentMode - Current mode
 * @returns {object} HTML containing the description
 */
const setSelectedModeDescription = (currentMode) => {
  switch (currentMode) {
    case Constants.PRIO_DEDUP__MODE__BASIC__VALUE:
      return (
        <p>
          Deduplication mode ‘Basic’ allows you to deduplicate the results based on the order
          of given values for a field.
        </p>
      );
    case Constants.PRIO_DEDUP__MODE__ADVANCED__VALUE:
      return (
        <p>
          Deduplication mode ‘Advanced’ allows you to deduplicate the results based on the
          rules specified below.
          <br />
          <br />
          The records matching the first rule will have priority over the records matching
          the 2nd rule, which will have priority over the third rule, and so on.
        </p>
      );
    default:
      return null;
  }
};

/**
 * Renders label for radio button
 * @param {string} mode - Current mode
 * @returns {object} HTML containing the label
 */
const renderRadioLabel = mode => (
  <>
    <span>{mode}</span>
    { isModeDisabled(mode) && (
      <Tooltip
        nubbinPosition={Constants.NUBBIN_POSITION__TOP_LEFT}
        type={Constants.TOOLTIP_TYPE__UNAVAILABLE_FEATURE}
      />
    )}
  </>
);

/**
 * Get the HTML for the prio dedup mode
 * @param {object} param - Object with 'selectedMode' and 'handleChangeRadioValues' as properties
 * @returns {object} The HTML for the prio dedup mode
 */
const PrioDedupMode = ({ selectedMode, handleChangeRadioValues, featuresInfo }) => (
  <fieldset className="slds-form-element prio-dedup-mode">
    <legend className="slds-form-element__legend slds-form-element__label">
      Prio Deduplication Mode
    </legend>
    <div className="slds-form-element__control">
      <div className="slds-radio_button-group">
        {Modes.map(mode => (
          <RadioButton
            key={mode}
            unlabeledInput
            containerClassName="slds-button slds-radio_button"
            id={mode}
            name="selectedMode"
            value={mode.toString().toLowerCase()}
            checked={selectedMode === mode.toString().toLowerCase()}
            onChange={e => handleChangeRadioValues(e)}
            disabled={isModeDisabled(mode, featuresInfo)}
            label={renderRadioLabel(mode)}
            />
        ))}
      </div>
    </div>
    {setSelectedModeDescription(selectedMode)}
  </fieldset>
);

PrioDedupMode.propTypes = {
  /**
   * It keeps the info about selectedMode for deduplication
   * Basic or Advanced
   */
  selectedMode: PropTypes.string.isRequired,
  /**
   * It helps to switch between deduplication modes
   */
  handleChangeRadioValues: PropTypes.func.isRequired,
  /**
   * Features info from cookie
   */
  featuresInfo: PropTypes.object,
};

export default connect(mapStateToProps(['featuresInfo']), null, null, { pure: false })(PrioDedupMode);
