import React from 'react';
import { Button, Modal } from '@salesforce/design-system-react';

import './styles.scss';

const ConfirmHome = ({ navigateToOverview, backToWaterFall, handleClose }) => {
  return (
    <div>
    <Modal
        isOpen
        disableClose
        footer={[
            <Button key="1" label="Cancel" onClick={() => handleClose()} />,
            <Button key="2" label="Yes" variant="brand" onClick={() => navigateToOverview()} />,
        ]}
        heading="Confirmation Needed"
        className="confirm-heading"
    >
        <section className="slds-p-around_large">
        <div className="slds-form-element slds-m-bottom_large">
            <span className="slds-form-element__label confirm-text" htmlFor="opptyName">
            { `Are you sure you want to go ${backToWaterFall ?
              `back to the Waterfall Selection <b style='font-weight: 700'>${backToWaterFall?.name}</b>` :
              'to the Selections Overview'}?
                Unsaved changes will be lost.`}
            </span>
        </div>
        </section>
    </Modal>
    </div>
  );
};

export default ConfirmHome;
