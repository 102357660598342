// import Cookies from 'js-cookie';
import _ from 'lodash';
import qs from 'qs';
import moment from 'moment';
import { escape } from 'html-escaper';

import Constants from './constants/constants';
import CustomError from './customError';
// eslint-disable-next-line import/no-cycle
import SwalUtil from './utils/swal/swalUtil';
import TimezoneList from './components/Selection/ScheduleSelectionModal/TimezoneList.json';

const Util = {
  /**
   * Replaces a character 'replace' in string at index
   * @param {string} string - The original string
   * @param {number} index - The index of the character to replace
   * @param {string} replace - The character to replace with
   * @returns {string} The new string with the replaced character
   */
  replaceAt: (string, index, replace) => string.substring(0, index) + replace + string.substring(index + 1),

  /**
   * Prevent adding text in date picker input
   * @param {Event} e - Key Down Event
   * @return {void}
   */
  preventTextInputOnDatePicker: (e) => {
    const validationRegExp = /[0-9\/]+/;

    if (!validationRegExp.test(e.key) &&
      !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key)) {
      e.preventDefault();
    }
  },

  /**
   * Abbreviate text to a maxLength, if longer: add ...
   * @param {string} str - The string to abbreviate
   * @param {number} maxLength - The max length allowed for the string to be
   * @returns {string} The abbreviated string
   */
  abbreviate: (str, maxLength) => {
    if (typeof str === 'string') {
      return str.length > maxLength ? `${str.substring(0, maxLength)}...` : str.substring(0, maxLength);
    }

    return str;
  },
  /**
   * Sort array of object by 'sortBy'
   * @param {array} array - The array to sort
   * @param {string} sortBy - The key of the array to sort by
   * @returns {array} The sorted array
   */
  sortArrayOfObjects: (array, sortBy) => array.sort((a, b) => {
    if (a[sortBy].toString().toUpperCase() < b[sortBy].toString().toUpperCase()) return -1;
    if (a[sortBy].toString().toUpperCase() > b[sortBy].toString().toUpperCase()) return 1;

    return 0;
  }),

  /**
   * Get navigation bar options
   * @param {object} user - The user object
   * @param {object} orgInfo - The organisation info object
   * @returns {void}
   */
  getNavBarOptions: (user, orgInfo) => {
    const engageStatus = user?.engageInstallationStatus?.organisationExists;
    const searchStatus = user?.searchInstallationStatus?.organisationExists;
    const stackNumber = orgInfo?.marketingCloudStackNumber;

    let search = Constants.DESELECT_SEARCH_STAGING_APP_NAME;

    let engage = Constants.DESELECT_ENGAGE_STAGING_APP_NAME;

    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      search = Constants.DESELECT_SEARCH_PRODUCTION_APP_NAME;
      engage = Constants.DESELECT_ENGAGE_PRODUCTION_APP_NAME;
    }

    if (process.env.REACT_APP_ENVIRONMENT === 'release') {
      search = Constants.DESELECT_SEARCH_RELEASE_APP_NAME;
      engage = Constants.DESELECT_ENGAGE_RELEASE_APP_NAME;
    }

    let engageLink = Constants.DESELECT_ENGAGE_APP_EXCHANGE_URL;

    let searchLink = Constants.DESELECT_SEARCH_APP_EXCHANGE_URL;

    if (engageStatus) {
      engageLink = `https://mc.${stackNumber}.exacttarget.com/cloud/#app/${engage}`;
    }

    if (searchStatus) {
      // eslint-disable-next-line max-len
      searchLink = `https://mc.${stackNumber}.exacttarget.com/cloud/#app/${search}`;
    }

    const options = [
      {
        id: 'Segment',
        name: 'Segment',
        url: '',
      },
      {
        id: 'Engage',
        name: 'Engage',
        url: engageLink,
      },
      {
        id: 'Search',
        name: 'Search',
        url: searchLink,
      },
    ];

    return options;
  },

  /**
   * Helps to know if the current user is a DESelect Essentials user
   * @param {object} orgInfo - The Org info from cookie
   * @returns {Boolean} - True if it's a DESelect Essentials user
   */
  isDESelectFreeUser: (orgInfo) => {
    return orgInfo?.edition === Constants.ORGANISATION__EDITION__ESSENTIALS;
  },

  /**
   * @param {object} userInfo - The User info from cookie
   * @returns {string} ID of logged in admin user
   */
  loggedInUserId: userInfo => userInfo.id,

  /**
   * Get the user timezone, return timezone name
   * @param {array} arrayWithTimezones - array with timezone objects (look at TimezoneList.json in Schedule Selection)
   * @param {boolean} timezoneValue - defines that timezone value, not name, must be returned
   * @returns {string} The user timezone name
   */
  getCurrentUserTimezone: (arrayWithTimezones, timezoneValue) => {
    let findTimezoneFromList;

    // get user local timezone
    const userTimezone = moment.tz.guess();

    // get user local timezone offset
    const userTimezoneOffset = moment().tz(userTimezone).format('Z');

    // check if local user timezone is found
    if (userTimezone) {
      // find timezone name in the arrayWithTimezones (search for each object in the utc table)
      findTimezoneFromList = arrayWithTimezones.find(tz => tz.utc
        .find(zoneInUtc => zoneInUtc === userTimezone ||
          zoneInUtc.match(userTimezone)));
    } else if (userTimezoneOffset) {
      // if a timezone name is not found in the list, look for the name using offset value
      findTimezoneFromList = arrayWithTimezones.find(tz => tz.utc
        .find(zoneInUtc => moment().tz(zoneInUtc).format('Z') === userTimezoneOffset));
    }

    if (findTimezoneFromList && !Util.objectIsEmpty(findTimezoneFromList) &&
      findTimezoneFromList.utc && findTimezoneFromList.utc.length) {
      /*
       * If it finds a timezone name in object, it will return the first timezone name for that object
       * (or value if timezoneValue is passed).
       * We take the first name to select initial timezone in timezoneSelect
       */
      return timezoneValue ? findTimezoneFromList.value : findTimezoneFromList.utc[0];
    }

    const utcTimezone = arrayWithTimezones.find(timezone => timezone.value === 'UTC');

    // if it doesn't find timezone, select the default timezone name for utc
    return timezoneValue ? utcTimezone.value : utcTimezone.utc[0];
  },

  /**
   * Get the user locale, return 'en-US' if undefined
   * @param {object} userInfo - The User info from cookie
   * @returns {string} The user locale
   */
  getUserLocale: (userInfo) => {
    let userLocale;
    // get the user locale

    userLocale = userInfo ? userInfo.locale : Constants.LOCALE__EN_US;
    try {
      // try to use function for number formatting with user locale
      new Intl.NumberFormat(userLocale);
      // if we catch an error that means user locale is not correct so set locale to en-US
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Wrong locale');
      userLocale = Constants.LOCALE__EN_US;
    }

    return userLocale;
  },

  /**
   * Get the user dateTimeFormat
   * @param {object} userInfo - The User info from cookie
   * @returns {string} The user date time format
   */
  getUserDateTimeFormat: (userInfo) => {
    const userLocale = Util.getUserLocale(userInfo);

    return (userLocale === Constants.LOCALE__EN_US ? 'MM/DD/YYYY HH:mm' : 'DD/MM/YYYY HH:mm');
  },

  /**
   * Get the date format for datepicker
   * @param {string} userLocale - The user locale
   * @returns {string} The date format for datepicker
   */
  getDatePickerDateFormat: userLocale => (userLocale === Constants.LOCALE__EN_US ? 'MM/dd/yyyy' : 'dd/MM/yyyy'),

  /**
   * Get the datetime format for datepicker
   * @param {string} userLocale - The user locale
   * @returns {string} The date format for datepicker
   */
  getDatePickerDateTimeFormat: userLocale => (userLocale === Constants.LOCALE__EN_US ?
    'MM/dd/yyyy HH:mm' :
    'dd/MM/yyyy HH:mm'),

  /**
   * Format numbers with decimals and thousand separator for users locale
   * @param {number} number - The number to format
   * @param {number} scale - The desired scale
   * @param {string} locale - The user local
   * @returns {number} The formatted number
   */
  formatNumber: (number, scale, locale) => new Intl.NumberFormat(
    locale,
    { style: 'decimal', minimumFractionDigits: scale, maximumFractionDigits: scale },
  ).format(number),

  /**
   * Adds a leading zero to a number if it's smaller than 10
   * @param {number} number - The number to add leading zero to
   * @returns {string} The number with leading zero added if necessary
   */
  addLeadingZero: number => number < 10 ? '0' + number : number,

  /**
   * Formats a date into DD/MM/YYYY HH:MM or MM/DD/YYY HH:MM format depending on users locale
   * @param {string} date - The date to format
   * @param {string} dateFormat - The desired date format
   * @returns {string} The formatted date
   */
  formatDate: (date, dateFormat) => moment(date).format(dateFormat),

  /**
   * Get the current users' timezone
   * @param {String} timezone - The user's timezone
   * @returns {Object}  Timezone object
   */
  getUserTimezone: (timezone = null) => {
    // Guess the user's timezone if not supplied
    const userTimezone = timezone || moment.tz.guess(true);

    const timezoneFound = TimezoneList.find(tz => tz.utc.find(
      tzName => tzName === userTimezone,
    ) !== undefined);

    return timezoneFound;
  },

  /**
   * Get the API settings for the GET method
   * @param {object} cancelToken - The cancel token from axios
   * @returns {string} An object with 'withCredentials' set to true and the 'cancelToken'
   */
  apiGetCallSettings: cancelToken => ({
    withCredentials: true,
    cancelToken,
  }),

  /**
   * Get the API settings for the POST method
   * @param {object} cancelToken - The cancel token from axios
   * @returns {string} An object with 'withCredentials' set to true and the 'cancelToken' and 'headers'
   */
  apiPostCallSettings: cancelToken => ({
    withCredentials: true,
    cancelToken,
    /*
     * headers: {
     *   'csrf-token': Cookies.get('csrf-token'),
     *   'token-secret': Cookies.get('token-secret'),
     * },
     */
  }),

  /**
   * Checks if name starts with underscore
   * @param {string} name - name of a field / DE
   * @returns {boolean} If name starts with underscore return true
   */
  startsWithUnderScore: name => name && name.toString().charAt(0) === '_',

  /**
   * Removes underscore from field name
   * @param {string} name - field name
   * @returns {string} Field name without underscore
   */
  removeUnderscoreFirstCharacter: (name) => {
    if (Util.startsWithUnderScore(name)) {
      return name.substring(1);
    }

    return name;
  },

  /**
   * Sort selections according to its direction and type of properties
   * @param {object[]} array - List of selections
   * @param {string} property - Name of a property
   * @param {string} sortDirection - Type of direction
   * @returns {array|null} The sorted array
   */
  sortProperty: (array, property, sortDirection) => {
    switch (sortDirection) {
      case Constants.SORT_DIRECTION__ASCENDING:
        return array.sort((a, b) => {
          switch (typeof a[property]) {
            case 'string':
              // If property is a string
              if (Number.isNaN(parseInt(a[property]))) {
                if (a[property].toString().toLowerCase() > b[property].toString().toLowerCase()) return 1;
                if (a[property].toString().toLowerCase() < b[property].toString().toLowerCase()) return -1;
                if (a[property].toString().toLowerCase() === b[property].toString().toLowerCase()) return 0;
                // If the property is a number
              }
              /*
               *  If property is a date Date.parse will parse date
               * string otherwise set NaN
               */
              if (Date.parse(a[property])) {
                if (new Date(a[property]).getTime() > new Date(b[property]).getTime()) return 1;
                if (new Date(a[property]).getTime() < new Date(b[property]).getTime()) return -1;

                // If property is a regular number
              } else {
                if (a[property] > b[property]) return 1;
                if (a[property] < b[property]) return -1;
              }

              break;
            case 'number':
              // If property is a regular number
              if (a[property] > b[property]) return 1;
              if (a[property] < b[property]) return -1;
              break;
            case 'boolean':
              if (a[property] === b[property]) return 0;
              if (a[property]) return -1;
              if (b[property]) return 1;
              break;
            default:
              return 0;
          }

          return 0;
        });

      case Constants.SORT_DIRECTION__DESCENDING:
        return array.sort((a, b) => {
          switch (typeof a[property]) {
            case 'string':
              // If property is a string
              if (Number.isNaN(parseInt(a[property]))) {
                if (b[property].toString().toLowerCase() > a[property].toString().toLowerCase()) return 1;
                if (b[property].toString().toLowerCase() < a[property].toString().toLowerCase()) return -1;

                // If the property is a number
              }
              /*
               *  If property is a date Date.parse will parse date
               * string otherwise set NaN
               */
              if (Date.parse(a[property])) {
                if (new Date(b[property]).getTime() > new Date(a[property]).getTime()) return 1;
                if (new Date(b[property]).getTime() < new Date(a[property]).getTime()) return -1;
              } else {
                // If property is a regular number
                if (b[property] > a[property]) return 1;
                if (b[property] < a[property]) return -1;
              }

              break;
            case 'number':
              // If property is a regular number
              if (b[property] > a[property]) return 1;
              if (b[property] < a[property]) return -1;
              break;
            case 'boolean':
              if (a[property] === b[property]) return 0;
              if (a[property]) return 1;
              if (b[property]) return -1;
              break;
            default:
              return 0;
          }

          return 0;
        });
      default:
        return null;
    }
  },
  /**
   * Sort array based on the provided property, its type and the sort direction
   * @param {object[]} array - List of objects to sort
   * @param {string} property - Name of a property
   * @param {'string' | 'number' | 'date' | 'boolean'} propertyType - Type of the property to sort
   * @param {string} sortDirection - Sort direction
   * @returns {array|null} The sorted array
   */
  sortPropertyByType: (array, property, propertyType, sortDirection) => {
    switch (sortDirection) {
      case Constants.SORT_DIRECTION__ASCENDING:
        return array.sort((a, b) => {
          switch (propertyType) {
            case 'string':
              // If property is a string
              if (
                a[property].toString().toLowerCase().trim() >
                b[property].toString().toLowerCase().trim()
              ) return 1;
              if (
                a[property].toString().toLowerCase().trim() <
                b[property].toString().toLowerCase().trim()
              ) return -1;

              return 0;

            case 'date':
              // If the property is a date
              if (
                new Date(a[property]).getTime() > new Date(b[property]).getTime()
              ) return 1;
              if (
                new Date(a[property]).getTime() < new Date(b[property]).getTime()
              ) return -1;

              return 0;

            case 'number':
              // If property is a regular number
              if (a[property] > b[property]) return 1;
              if (a[property] < b[property]) return -1;

              return 0;

            case 'boolean':
              if (a[property] === b[property]) return 0;
              if (a[property]) return -1;
              if (b[property]) return 1;
              break;
            default:
              return 0;
          }

          return 0;
        });

      case Constants.SORT_DIRECTION__DESCENDING:
        return array.sort((a, b) => {
          switch (propertyType) {
            case 'string':
              // If property is a string
              if (
                a[property].toString().toLowerCase().trim() >
                b[property].toString().toLowerCase().trim()
              ) return -1;
              if (
                a[property].toString().toLowerCase().trim() <
                b[property].toString().toLowerCase().trim()
              ) return 1;

              return 0;

            case 'date':
              if (
                new Date(a[property]).getTime() > new Date(b[property]).getTime()
              ) return -1;
              if (
                new Date(a[property]).getTime() < new Date(b[property]).getTime()
              ) return 1;

              return 0;

            case 'number':
              // If property is a regular number
              if (a[property] > b[property]) return -1;
              if (a[property] < b[property]) return 1;

              return 0;

            case 'boolean':
              if (a[property] === b[property]) return 0;
              if (b[property]) return -1;
              if (a[property]) return 1;
              break;
            default:
              return 0;
          }

          return 0;
        });
      default:
        return null;
    }
  },

  /**
   * Checks if name contains illegal characters
   * @param {string} name - Name of a field / DE
   * @returns {string} If name contains illegal characters return the illegal characters
   */
  containsIllegalCharacters: (name) => {
    const regexString = /[`@!#$£&%^~*+=[\]\\'.;,/|(){}|\\":<>?]/;

    if (regexString.test(name)) {
      let invalidCharacters = [];

      for (let n = 0; n < name.length; n += 1) {
        // check the name`s characters if they are special characters
        if (regexString.test(name[n])) {
          // then push them in the array
          invalidCharacters.push(name[n]);
        }
      }
      // get the distinct values of invalid characters in the name
      invalidCharacters = [...new Set(invalidCharacters)];

      return invalidCharacters.join(' ');
    }

    return false;
  },

  /**
   * Checks if value contains something else except numbers
   * @param {string} value - Value which is checked in function
   * @returns {boolean} If value contains something else except numbers return true
   */
  containsOnlyNumbers: value => String(value).match(/^\d+$/),

  /**
   * RFC 4122 version 4 UUID.
   * This implementation does NOT include the hyphen.
   * Note also that it uses a low-quality source of random (Math.random) so beware.
   * @param {number} length - Length of the UUID, can't exceed 32 or be negative
   * @returns {string} The uuid
   * @see https://www.ietf.org/rfc/rfc4122.txt
   */
  uuid: (length = 32) => 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'
    .replace(/[xy]/g, (c) => {
      const r = Math.random() * 16 | 0;
      const v = c === 'x' ? r : ((r & 0x3) | 0x8);

      return v.toString(16);
    })
    .slice(0, length > 32 ? 32 : length),

  /**
   * It loops through the filters object received as a prop and
   * updates the proper fields name using fieldObjectID
   * @param {array} selectedDataExtensions - Data extensions containing the reference field names to update filters
   * @param {object} filtersToUpdate - Could be a filter group or a single filter line
   * @returns {void}
   */
  updateFiltersFieldName: (selectedDataExtensions, filtersToUpdate) => {
    // Store fields with already updated name
    const fields = [];

    // This obtains the fields from the selected Data Extension
    if (selectedDataExtensions && selectedDataExtensions.length > 0) {
      selectedDataExtensions.forEach((dataExtension) => {
        if (dataExtension.fields && dataExtension.fields.length > 0) {
          dataExtension.fields.forEach(field => fields.push(field));
        }
      });
    }

    // Abstraction needed to call function recursively
    const update = (filter) => {
      const actualFilter = filter;
      // If it's a filter group

      if ('filters' in filter && 'operator' in filter) {
        if (filter.filters && filter.filters.length) {
          filter.filters.forEach(childFilter => update(childFilter));
        }
      } else {
        // If it's a filter line
        fields.forEach((field) => {
          // Get the field name matching the ID of the filter we are updating
          if (field.ObjectID === filter.fieldObjectID) actualFilter.field = field.Name.toString();
        });

        /*
         * Set the new fields on the subQuery
         */
        if (filter.subQuery) {
          if (filter.subQuery.fields && filter.subQuery.fields.length > 0) {
            fields.forEach((field) => {
              filter.subQuery.fields.forEach((filterField) => {
                if (field.ObjectID === filterField.availableFieldObjectID) {
                  // Have to skip this eslint error as the prop 'filterField' needs to be reassigned
                  // eslint-disable-next-line no-param-reassign
                  filterField.field = field.Name.toString();
                }
              });
            });
          }
        }
      }
    };

    // Run the update
    update(filtersToUpdate);
  },

  /**
   *
   * @param {object} filters - filters to update
   * @param {object} settings - settings to apply to filters
   * @returns {void}
   */
  updateTimezoneSettingsForDateFilter: (filters, settings) => {
    const {
      convertToTimezone, convertTimezone, convertFromTimezone,
    } = settings;

    // Abstraction needed to call function recursively
    const update = (filter) => {
      // If it's a filter group
      if ('filters' in filter) {
        if (filter.filters?.length) {
          filter.filters.forEach(childFilter => update(childFilter));
        }
      } else {
        // If it's a filter line
        if (filter.fieldType === Constants.FILTERLINE__FIELDTYPE__DATE) {
          filter.convertToTimezone = convertToTimezone;
          filter.convertTimezone = convertTimezone;
          filter.convertFromTimezone = convertFromTimezone;
        }

        /*
         * Change settings on the subQuery
         */
        if (filter.subQuery?.filters?.filters?.length) {
          update(filter.subQuery.filters);
        }
      }
    };

    update(filters);
  },

  /**
   * Find the first available date filter in a filter container.
   * @param {object} filtersArray - array of filters
   * @returns {object} - The found filter
   */
  findDateTypeFilter: (filtersArray) => {
    let element;

    filtersArray.find((filter) => {
      if (filter.fieldType === Constants.FILTERLINE__FIELDTYPE__DATE) {
        element = filter;
      } else if (filter.filters) {
        element = Util.findDateTypeFilter(filter.filters);
      }

      return element;
    });

    return element;
  },

  /**
   * It loops through the filters object received as a prop and
   * updates the parentId of inResults formula filters
   * @param {array} filters - Could be a filter group or a single filter line
   * @returns {void}
   */
  handleUpdateFormulaFiltersParentId: (filters) => {
    // Abstraction needed to call function recursively
    const update = (filter, parentId, isInResultsFormula) => {
      // If it's a filter group
      if ('filters' in filter) {
        if (filter.filters?.length) {
          filter.filters.forEach(childFilter => update(childFilter, parentId, isInResultsFormula));
        }
      } else {
        // if inResults formula, update the parentId property
        if (isInResultsFormula) {
          filter.parentId = parentId;
        } else {
          // If it's a filter line
          if (filter.subQuery?.formulas) {
            if (filter.subQuery?.formulas.filters?.length) {
              update(filter.subQuery.formulas, filter.id, true);
            }
          }
        }
      }
    };

    update(filters);
  },

  /**
   * Find a filterline in the filters array.
   * @param {string} id - id of a filter
   * @param {object} filtersArray - array of filters
   * @returns {object} - The found filter
   */
  findFilterElement: (id, filtersArray) => {
    // Loop through filtersArray to find filterLine
    let element;

    filtersArray.find((filter) => {
      if (filter.id === id) {
        element = filter;
      } else if (filter.filters) {
        element = Util.findFilterElement(filter.filters);
      }

      return element;
    });

    return element;
  },

  /*
   * This function helps to know if a field type can be mapped to another
   * @param {string} sourceType - The source type
   * @param {string} targetType - The target type
   * @returns {boolean} True if the source type can be mapped to the target field, false otherwise
   */
  canFieldBeMapped: (sourceType, targetType) => {
    if (!sourceType || !targetType) return false;

    const cannotBeMapped = {
      [Constants.FILTERLINE__FIELDTYPE__TEXT]: [
        Constants.FILTERLINE__FIELDTYPE__DATE,
        Constants.FILTERLINE__FIELDTYPE__BOOLEAN,
      ],
      [Constants.FILTERLINE__FIELDTYPE__DATE]: [Constants.FILTERLINE__FIELDTYPE__TEXT],
      [Constants.FILTERLINE__FIELDTYPE__BOOLEAN]: [Constants.FILTERLINE__FIELDTYPE__TEXT],
    };

    return !(
      cannotBeMapped[sourceType] &&
      cannotBeMapped[sourceType].includes(targetType)
    );
  },

  /**
   * Iterates through properties of two objects and returns true if both objects have the same properties
   * with same values.
   * @param {Object} object1 - First object to compare.
   * @param {Object} object2 - Second object to compare.
   * @returns {Boolean} - Returns true if they're equal.
   */
  compareEqualityOfObjectProperties: (object1, object2) => {
    let result = true;

    if (object1 && object2 && object1.length === object2.length) {
      // eslint-disable-next-line no-restricted-syntax, no-unused-vars
      for (const property in object1) {
        if (Object.prototype.hasOwnProperty.call(object2, property)) {
          if (object1[property] !== object2[property]) {
            result = false;
          }
        } else result = false;
      }
    } else {
      result = false;
    }

    return result;
  },

  /**
   * Builds cron expression from scheduledRun object
   * @param {Object} scheduledRun - the object containing schedule details
   * @returns {String|null} The cron expression built
   */
  buildCronExpression: (scheduledRun) => {
    const {
      runRepeat: {
        repeatMode,
        daysValue,
        daysOfWeek,
        hoursValue,
        minutesValue,
        monthsValue,
      },
    } = scheduledRun;

    switch (repeatMode) {
      case Constants.REPEAT_MODE__HOURLY:
        /**
         * 54 *"/6 * * *
         * At minute 54 past every 6th hour.
         */
        return `${minutesValue} */${hoursValue} * * *`;
      case Constants.REPEAT_MODE__DAILY:
        /**
         * At 12:30 on every 2nd day-of-month.
         * 30 12 *"/2 * *
         */
        return `${minutesValue} ${hoursValue} */${daysValue} * *`;
      case Constants.REPEAT_MODE__WEEKLY:
        /**
         * At 16:35 on Monday and Thursday.
         * 35 16 * * 1,4
         */
        return `${minutesValue} ${hoursValue} * * ${daysOfWeek.join()}`;
      case Constants.REPEAT_MODE__MONTHLY:
        /**
         * At 17:26 on day-of-month 15 in every 6th month.
         * 26 17 15 *"/6 *
         */
        return `${minutesValue} ${hoursValue} ${daysValue} */${monthsValue} *`;
    }

    return null;
  },

  /**
   * Builds date in the specified timezone
   * @param {String} date - The date part of the date object
   * @param {String} time - The time part of the date object
   * @param {String} UTCOffset - Timezone's difference in hours and minutes from (UTC)
   * @returns {Date} - The created date object
   */
  buildDateTime: (date, time, UTCOffset) => new Date(`${date}T${time}${UTCOffset || ''}`),

  /*
   * This function helps to know if a field type can be mapped to another
   * @param {string} sourceType - The source type
   * @param {string} targetType - The target type
   * @returns {boolean} True if the source type can be mapped to the target field, false otherwise
   */
  canFieldBeMappedWithWarning: (sourceType, targetType) => {
    if (!sourceType || !targetType) return false;

    const cannotBeMapped = {
      [Constants.FILTERLINE__FIELDTYPE__TEXT]: [
        Constants.FILTERLINE__FIELDTYPE__NUMBER,
        Constants.FILTERLINE__FIELDTYPE__DECIMAL,
      ],
      [Constants.FILTERLINE__FIELDTYPE__NUMBER]: [Constants.FILTERLINE__FIELDTYPE__TEXT],
      [Constants.FILTERLINE__FIELDTYPE__DECIMAL]: [Constants.FILTERLINE__FIELDTYPE__TEXT],
    };

    return !(
      cannotBeMapped[sourceType] &&
      cannotBeMapped[sourceType].includes(targetType)
    );
  },

  /**
   * Determines whether an object is empty or not
   * @param {object} object - Object to test if its empty
   * @returns {boolean} - True if empty and false if filled
   */
  objectIsEmpty: (object) => {
    let isEmpty = true;

    Object.keys(object).forEach((prop) => {
      if ({}.hasOwnProperty.call(object, prop)) {
        isEmpty = false;
      }
    });

    return isEmpty;
  },

  /**
   * Helps to parse and validate a JSON string
   * @param {string} text - The string to parse as JSON
   * @returns {string} - the string value corresponding to the given JSON text.
   */
  parseAndValidateJSONResponse: (text) => {
    try {
      // if the text is in JSON format (valid), then it will be parsed and returned
      return JSON.parse(text);
    } catch {
      // in other case, return the text
      return text;
    }
  },

  /**
   * Catch the 418 error, which corresponds to the password expired and the 403 error, which corresponds
   * to the inactive user.
   * @param {function} functionToExec - The function to call
   * @param {...*} param - Parameters passed to the function
   * @returns {*|void}
   * - If no error, then it will return the result of the execution of `functionToExec` with `param`.
   * - If error is `418` or `403`, this function will be blocking everything, it should never return any value.
   * - If error is something else, it throws the error.
   */
  catch418And403Error: async (functionToExec, ...param) => {
    try {
      const res = await functionToExec.call({}, ...param);

      // Return result as everything went fine
      return res;
    } catch (error) {
      const { stackTrace } = error?.response?.data || {};
      const { upgradeRequired } = error?.response?.data?.additionalProperties || {};
      const { reachedLimit } = error?.response?.data?.additionalProperties || {};
      // Text for swal pop up

      let title = '';

      let paragraph = '';

      let isPermissionError = false;

      // If error 418, handle it here
      if (error?.request?.status === 418) {
        title = 'Password expired';
        paragraph = 'Your Marketing Cloud password has expired. ' +
          'Please log out of Marketing Cloud and re-login with the new password to continue using DESelect.';
      } else if (error?.request?.status === 403) {
        title = 'Not authorized';
        paragraph = error?.response?.data.actualError || 'You do not have access.';
      } else if (stackTrace?.includes(3201)) {
        title = 'Insufficient Permissions';
        paragraph = Constants.ERROR_INSUFFICIENT_PERMISSIONS_DETAILS;
        isPermissionError = true;
      } else if (error?.request?.status === 422) {
        throw error;
      } else {
        // depends on the message we get from the backend, throw to the frontend
        if (error?.request?.response) {
          throw new CustomError(Util.parseAndValidateJSONResponse(error.request.response), error);
        } if (error?.response) {
          // throw an error from the backend for error.response
          throw error.response;
        } else {
          // Handle normally as it's not error 418 or 403
          throw error;
        }
      }

      if (upgradeRequired) {
        return Util.handleUpgradeWarning(reachedLimit);
      }

      // If error was not thrown, display our message
      return SwalUtil.fire({
        type: Constants.SWAL__TYPE__ERROR,
        title,
        messageHTML: paragraph,
        // Remove all ways to close the alert
        options: {
          showCancelButton: false,
          showConfirmButton: false,
          showCloseButton: false,
          allowOutsideClick: false,
        },
      }).then((result) => {
        if (result.isConfirmed) {
          if (isPermissionError) {
            throw error;
          }
        }
      });
    }
  },

  /**
   * Escapes a string (for example coming from user input) to be used in a regular expression.
   * @param {string} string - The string to escape
   * @returns {string} The escaped string
   */
  escapeRegExp: string => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), // $& means the whole matched string

  /**
   * Removes duplicate objects from an array
   * @param {array} array - The array from which to remove duplicates
   * @param {string} key - The object property to filter by
   * @returns {array} The filtered array
   */
  removeDuplicateObjectsFromArray: (array, key) => [
    ...new Map(
      array.map(x => [key(x), x]),
    ).values(),
  ],

  /**
   * Compares all values and removes duplicate objects, without specifying any properties to filter
   * @param {array} array - The array from which to remove duplicates
   * @returns {array} The filtered array
   */
  removeDuplicatesFromArray: array => _.uniqWith(array, _.isEqual),

  /**
   * Return number of characters for string based on width
   * @param {number} width - The width of the element
   * @returns {number} The number of characters for string
   */
  returnNumberBasedOnWidth: (width) => {
    // creates variable number to specify number of characters for string
    let number;

    // conditions specifying the number of characters for string depending on the width
    if (width >= 700) {
      number = 70;
    } else if (width >= 600 && width < 700) {
      number = 60;
    } else if (width < 600 && width >= 500) {
      number = 50;
    } else if (width < 500 && width >= 280) {
      number = 40;
    } else if (width < 280) {
      number = 30;
    }

    return number;
  },

  /**
   * Function for testing whether or not an email address has a correct format
   * @param {string} email - Email format for testing
   * @returns {boolean} boolean value that indicates whether or not an email address is valid.
   */
  validateEmail: (email) => {
    // Regex to test if the given email meets the salesforce requirements
    // eslint-disable-next-line max-len, no-useless-escape
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\,;:\s@"]+)*))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return regex.test(email);
  },

  /**
   * Function for testing whether or not comma separated emails are valid
   * @param {string} emails - Emails separated by comma
   * @returns {boolean} boolean that tells if it valid or not
   */
  validateCommaSeparatedEmails: (emails) => {
    const emailsArray = emails.split(',');

    return emailsArray.map(email => Util.validateEmail(email.trim())).every(Boolean);
  },

  /**
   * Validate new Data Extension name
   * @param {string} name - validated name
   * @returns {boolean} True if name is valid, false otherwise
   */
  // eslint-disable-next-line consistent-return
  nameValidation: (name) => {
    if (!name) {
      SwalUtil.fire({
        type: Constants.SWAL__TYPE__ERROR,
        title: 'Missing Name',
        message: 'Please enter a name.',
        options: {
          customClass: {
            popup: 'popup-targetDE',
          },
        },
      });

      return false;
    } if (Util.startsWithUnderScore(name)) {
      SwalUtil.fire({
        type: Constants.SWAL__TYPE__ERROR,
        title: 'Invalid Name',
        message: `New Data Extension name <b>${escape(name)}</b> cannot start with underscore.`,
        options: {
          customClass: {
            popup: 'popup-targetDE',
          },
        },
      });

      return false;
    } if (Util.containsOnlyNumbers(name)) {
      SwalUtil.fire({
        type: Constants.SWAL__TYPE__ERROR,
        title: 'Invalid Name',
        message: `New Data Extension name <b>${escape(name)}</b> cannot consist entirely of numbers.`,
        options: {
          customClass: {
            popup: 'popup-targetDE',
          },
        },
      });

      return false;
    } if (Util.containsIllegalCharacters(name)) {
      const invalidCharacters = Util.containsIllegalCharacters(name);

      SwalUtil.fire({
        type: Constants.SWAL__TYPE__ERROR,
        title: 'Invalid Name',
        message: `New Data Extension name <b>${escape(name)}</b> cannot contain <b>${invalidCharacters}</b>`,
        options: {
          customClass: {
            popup: 'popup-targetDE',
          },
        },
      });

      return false;
    }

    return true;
  },

  /**
   * it's the function used when you create foldersChildren from the folder path array,
   * you can modify an array with object e.g. [{311: {…}}, {11915: {…}}, {17118: {…}}]
   * into object with key-value pairs: {311: {…}, 11915: {…}, 17118: {…}}
   * @param {string} array - array to be reduce
   * @returns {object} - object with key-values pair
   */
  getKeyValueObjectFromObjectsArray: (array) => {
    const newObject = array.reduce((acc, cur) => {
      const key = Object.keys(cur)[0];
      const value = Object.values(cur)[0];
      const n = {
        ...acc,
        [key]: value,
      };

      return n;
    });

    return newObject;
  },

  /**
   * This function returns data extension found by its alias
   * @param {string} collectionAlias - alias of DE we want to find
   * @param {array} selectedDataExtensions - selected DEs from where we want to get DE we are looking for
   * @returns {object} found DE
   */
  getDataExtensionByAlias: (collectionAlias, selectedDataExtensions) => {
    // find DE by alias
    const fieldDataExtension = selectedDataExtensions.find(de => de.deAlias === collectionAlias);

    return fieldDataExtension;
  },

  /**
   * This function returns fields for given data extension found by ObjectID or CustomerKey
   * @param {string} selectedDataExtensions - array with selected data extensions in which we are looking for fields
   * @param {array} dataExtension - DE for which we want to get the fields
   * @returns {array} array with fields
   */
  getSelectedDataExtensionFields: (selectedDataExtensions, dataExtension) => selectedDataExtensions.find(sDE => (
    sDE?.ObjectID === dataExtension?.ObjectID ||
    sDE?.CustomerKey?.toString() === dataExtension?.CustomerKey?.toString()))?.fields ||
    [],

  /**
   * This function returns data extension name found by passed Customer Key or Object ID
   * @param {array} dataExtensions - array with data extensions
   * @param {string} customerKey - customer key of the DE we are looking for
   * @param {string} objectID - objectID of the DE we are looking for
   * @returns {string|null} DE name if found or null if not
   */
  getDataExtensionName: (dataExtensions, customerKey, objectID) => {
    const dataExtension = dataExtensions.find(de => de.CustomerKey.toString() ===
      customerKey.toString() || de.ObjectID === objectID);

    // if targetDataExtension and its name was found
    if (dataExtension?.Name) {
      // return the name of Target Data Extension
      return dataExtension.Name.toString();
    }

    return null;
  },

  /**
   * This function cleans up text by removing enter keys and replacing non-breaking spaces
   * @param {string} text = The text to clean up
   * @returns {string} - The cleaned-up text
   */
  replaceEnterKeysAndNonBreakingSpaces: text => text.split('').map((c) => {
    // If character is enter (line feed), remove it
    if (c.charCodeAt(0) === 10) {
      return '';
    }
    // If character is a non-breaking space, replace it with normal space
    if (c.charCodeAt(0) === 160) {
      return ' ';
    }

    return c;
  }).join(''),

  /**
   * Get's exact position of right click event
   * @param {object} e - Refers to position of a click
   * @returns {object} Returns the x and y position
   */
  getPosition: (e) => {
    let event = e;

    let positionX = 0;

    let positionY = 0;

    // if event is not defined, get event that is being handled by the window
    if (!event) event = window.event;

    // get pageY and pageX property and return it
    if (e.pageX || e.pageY) {
      positionX = e.pageX;
      positionY = e.pageY;
    }

    return {
      x: positionX,
      y: positionY,
    };
  },

  /**
   * This function set properly positions of the submenu for folders and copy tabs.
   * @param {Object} e - refer to position of a click
   * @param {Object} menuRef - ref object
   * @returns {void}
   */
  positionMenu: (e, menuRef) => {
    const menu = menuRef;

    // get position x and y of right click event
    const clickCoords = Util.getPosition(e);
    const clickCoordsX = clickCoords.x;
    const clickCoordsY = clickCoords.y;

    // get submenu properties: width and height
    const menuWidth = menuRef.current.offsetWidth + 4;
    const menuHeight = menuRef.current.offsetHeight + 4;

    // get window properties: width and height
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    // set the left property for the submenu
    if (windowWidth - clickCoordsX < menuWidth) {
      menu.current.style.left = windowWidth - menuWidth + 'px';
    } else {
      menu.current.style.left = clickCoordsX + 'px';
    }

    // set the top property for the submenu
    if (windowHeight - clickCoordsY < menuHeight) {
      menu.current.style.top = windowHeight - menuHeight + 10 + 'px';
    } else {
      menu.current.style.top = clickCoordsY + 'px';
    }

    return menu;
  },

  /**
   * Turn on submenu for folders and copy tabs.
   * @param {function} handleChangeMenuState - function that changes the menu status
   * @param {Object} menuRef - ref object
   * @param {string} className - optional class name
   * @returns {void}
   */
  toggleMenuOn: (handleChangeMenuState, menuRef, className) => {
    const menu = menuRef;

    // at the beginning the status is 0 (menu is off)
    handleChangeMenuState(0);
    menu.current.className = 'submenu' + className && `${className}`;

    // change the status on 1 (menu on), add active class
    handleChangeMenuState(1);
    menu.current.className = 'submenu' + (className ? `${className}` : ' ') + 'active';

    setTimeout(() => {
      // add a class that opens the submenu, delayed for better visual perception
      menu.current.className = 'submenu' + (className ? `${className}` : ' ') + 'active down';
    }, 10);

    return menu;
  },

  /**
   * Turns off submenu for folders and copy tabs.
   * @param {function} handleChangeMenuState - function that changes the menu status
   * @param {number} menuState - the number of menu state
   * @param {Object} menuRef - ref object
   * @returns {void}
   */
  toggleMenuOff: (handleChangeMenuState, menuState, menuRef) => {
    if (menuState !== 0) {
      // change the menu status to 0 (menu off)
      handleChangeMenuState(0);

      // remove activity classes
      menuRef?.current?.classList?.remove('active', 'down');
    }
  },

  /**
   * Makes sure all spaces in one string are of the same format.
   * @param {String} string - The string to be sanitized
   * @returns {string} - Sanitized string
   */
  sanitizeSpaces: string => string.replace(/\s+/, ' '),

  /**
   * Returns the MaxLength of fields.
   * @param {string} type - FieldType
   * @returns {any} The maxLength of the field
   */
  getMaxLength: (type) => {
    switch (type) {
      case Constants.FILTERLINE__FIELDTYPE__TEXT:
      case Constants.FILTERLINE__FIELDTYPE__EMAILADDRESS:
        return 254;
      case Constants.FILTERLINE__FIELDTYPE__PHONE:
        return 50;
      case Constants.FILTERLINE__FIELDTYPE__LOCALE:
        return 5;
      case Constants.FILTERLINE__FIELDTYPE__NUMBER:
      case Constants.FILTERLINE__FIELDTYPE__DATE:
      case Constants.FILTERLINE__FIELDTYPE__BOOLEAN:
        return null;
      case Constants.FILTERLINE__FIELDTYPE__DECIMAL:
        /**
         * Object is stringified here because event.dataTransfer converts everything
         * to string but doesn't stringify objects well ([object, object])
         */
        return JSON.stringify({ MaxLength: 12, Scale: 6 });
      default:
        return null;
    }
  },

  /**
   * Checks if the values of the arrayToCheck are included in the arrayWithAllValues
   * @param {array} arrayToCheck - an array with the values to be checked
   * @param {array} arrayWithAllValues - an array with all values
   * @returns {boolean} - true / false depending on whether the values from arrayToCheck are in arrayWithAllValues
   */
  checkElementsInArray: (arrayToCheck, arrayWithAllValues) => {
    for (let i = 0; i < arrayToCheck.length; i += 1) {
      if (arrayWithAllValues.indexOf(arrayToCheck[i]) === -1) {
        return false;
      }
    }

    return true;
  },

  /**
   * bold the data extension name if exist or insert it in bold if not
   * @param {object} error - the thrown error
   * @param {string} name  - the new name of the Data Extension
   * @returns {object} error
   */

  boldDataExtensionName: (error, name) => {
    if (error && name && error.message && typeof error.message === 'string') {
      // check if the DE name exist
      if (RegExp('(' + name + ')', 'img').test(error.message)) {
        // bold the DE name if it is in the message
        error.message = error.message
          // eslint-disable-line no-param-reassign
          .replace(RegExp('(' + name + ')', 'img'), '<b style="font-weight: bold;">$1</b>');
      } else {
        // put and bold the DE name if it does not exist and the message match this sentence
        error.message = error.message.replace( // eslint-disable-line no-param-reassign
          /(There already exists a Data Extension with this name)/img,
          '$1 <b style="font-weight: bold;">' + name + '</b>',
        );
      }
    }

    return error;
  },

  /**
   * Check if the input is a valid natural number
   * @param {Number | String} number - input to check
   * @returns {Boolean} - returns true only if the input i a valid natural number
   */
  isNaturalNumber: (number) => {
    const naturalNumberPattern = /^[0-9]*$/;

    return naturalNumberPattern.test(number);
  },

  /**
   * Prevent typing invalid natural number
   * @param {Event} e - event object
   * @returns {void}
   */
  preventTypingInvalidNaturalNumber: (e) => {
    if (e.which < 48 || e.which > 57) {
      e.preventDefault();
    }
  },

  /**
   * Find duplicate name in the array
   * @param {array} array - List of elements to check
   * @param {string} newName - value with the new name
   * @param {string} existingName - value with existing name
   * @param {string} currentId - current element ID
   * @param {boolean} insensitiveCase - indicates that the search is to be insensitive
   * @returns {object|null} Element with duplicated name or null
   */
  findDuplicateName: (array, newName, existingName, currentId, insensitiveCase) => {
    const name = arr => arr?.name || arr?.Name;
    const condition = name => insensitiveCase ? name?.toString().trim().toLowerCase() : name?.toString().trim();

    if (array?.length) {
      return array.find(
        arr => condition(name(arr)) === (condition(newName) || condition(existingName)) &&
          ((arr.ObjectID && arr.ObjectID !== currentId) || (arr._id && arr._id !== currentId)),
      );
    }

    return null;
  },

  /**
   * check if the given string contains HTML tags
   * @param {string} str - the string we want to check
   * @returns {Boolean} returns true the given string contains HTML tags and false if not
   */
  isHTML: str => /(<([^>]+)>)/.test(str),

  /**
   * Returns array of objects with formatted data for dropdown
   * @param {array} array - array with data for the dropdown
   * @param {string} valueProperty - the name of the property to be the value for dropdown
   * @param {string} nameProperty - the name of the property to be the text for dropdown
   * @param {string} typeProperty - the name of the property to be the type for dropdown
   * @param {function} disableFn - function that blocks the selection of option
   * @returns {array} array of objects with formatted data for dropdown component
   */
  formattedDataForTheDropdown: (array, valueProperty, nameProperty, typeProperty, disableFn) => array?.length ?
    array.map(arr => ({
      value: arr?.[valueProperty]?.toString() || arr?.CustomerKey?.toString() || '',
      title: arr?.[nameProperty]?.toString() || '',
      text: arr?.[nameProperty]?.toString() || '',
      key: arr?.[valueProperty]?.toString() || arr?.CustomerKey?.toString() || '',
      disabled: disableFn ? disableFn(arr) : false,
      type: typeProperty ? arr[typeProperty] : '',
    })) :
    [],

  /**
   * Replace order fromDE -> toDE and toDE -> fromDE for given predefined relation
   * @param {object} relationToReplace - relation in which we change the order
   * @returns {object|*} object with predefined relation in reverse order
   */
  replaceOrdersInPredefinedRelation: (relationToReplace) => {
    // replace toDE with fromDE and vice versa
    const replacedFromDataExtension = {
      fromDECustomerKey: relationToReplace.toDECustomerKey,
      fromDEName: relationToReplace.toDEName,
      fromDEObjectId: relationToReplace.toDEObjectId,
      fromFieldName: relationToReplace.toFieldName,
      fromFieldObjectId: relationToReplace.toFieldObjectId,
      fromFieldType: relationToReplace.toFieldType,
      fromDEFields: relationToReplace.toDEFields,
    };

    const replacedToDataExtension = {
      toDECustomerKey: relationToReplace.fromDECustomerKey,
      toDEName: relationToReplace.fromDEName,
      toDEObjectId: relationToReplace.fromDEObjectId,
      toFieldName: relationToReplace.fromFieldName,
      toFieldObjectId: relationToReplace.fromFieldObjectId,
      toFieldType: relationToReplace.fromFieldType,
      toDEFields: relationToReplace.fromDEFields,
    };

    // replace the data and return a new predefined relation object
    const newPredefinedRelation = {
      ...relationToReplace,
      ...replacedToDataExtension,
      ...replacedFromDataExtension,
    };

    return newPredefinedRelation;
  },

  /**
   * This function helps to return an array with the number increased by the given value
   * @param {number} initialValue - initial value for the loop
   * @param {number} scope - scope of function execution
   * @param {string} selectedNumberInString - selectedNumber to increase as a string
   * @returns {Array} an array with numbers increased by the given numbers
   */
  getArrayWithNumbers: (initialValue, scope, selectedNumberInString) => (
    Array.from({ length: scope }, (key, i) => initialValue + i * parseInt(selectedNumberInString))),

  /**
   * Returns duplicate fields by name from an array
   * @param {array} array - array with target Data Extension fields
   * @param {string} fieldValue - the name value of the field
   * @param {string} firstFieldName - the name of the first field in TDE
   * @returns {object} duplicate target data extension fields if found
   */
  getDuplicateField: (array, fieldValue, firstFieldName) => array.filter(
    newField => (fieldValue && newField.Name &&
      newField.Name.toString().toLowerCase() === fieldValue.toString().toLowerCase()) &&
      firstFieldName?.toString().toLowerCase() !== fieldValue.toString().toLowerCase(),
  ),

  /**
   * Returns query string for fetching selections API
   * @param {number} page - the current page number
   * @param {string} folderId - the selected folder ID
   * @param {string} value - the value searched in search input
   * @param {string} criteria - selected criteria
   * @param {string} sortBy - property name by which selections will be sorted
   * @param {number} sortOrder - order number for sorting - 1 for ascending and -1 for descending
   * @param {number} limit - limit for the number of selections to get, -1 to have no limit
   * @param {number} hasEnabledSchedule - pass true to get the Selections that has enabled schedule
   * @returns {string} string query
   */
  queryForSelections: ({
    page, folderId, value, criteria, sortBy,
    sortOrder, limit, hasEnabledSchedule,
  }) => {
    // default query object
    const queryObject = {
      page: page || 1,
      limit: limit || Constants.OVERVIEW__LIMIT__NUMBER_OF_SELECTIONS_PER_PAGE,
      sortBy,
      sortOrder,
    };

    // when all selections folder is selected, then do not pass folderId property
    if (folderId && folderId !== null || folderId === '') {
      queryObject.folderId = folderId;
    }

    if (folderId === 'archivedSelectionFolderId') {
      queryObject.folderId = undefined;
      queryObject.isArchived = true;
    } else {
      queryObject.isArchived = false;
    }

    // if the value is not empty and criteria is selected
    if (value && value !== '' && criteria) {
      queryObject[criteria] = value;
    }

    if (hasEnabledSchedule) {
      queryObject.hasEnabledSchedule = hasEnabledSchedule;
    }

    return qs.stringify(queryObject);
  },

  /**
   * Returns text label for timezone filter
   * @param {string} convertToTimezone - value of the timezone to convert to
   * @param {string} convertFromTimezone - value of the timezone to convert from
   * @returns {string} text label
   */
  labelForTimezoneText: (convertToTimezone, convertFromTimezone) => {
    // get the object with timezone properties for convertToTimezone
    const convertToTimezoneValue = TimezoneList.find(timezone => timezone.value === convertToTimezone) ||
      TimezoneList[0];

    // get the object with timezone properties for convertFromTimezone
    const convertFromTimezoneValue = convertFromTimezone ?
      TimezoneList.find(timezone => timezone.value === convertFromTimezone) :
      Constants.TIME_ZONE__CENTRAL_STANDARD_TIME__OBJECT;

    // get the proper UTC offset values for both variables
    const fromTimezoneUTCValue = moment().tz(convertFromTimezoneValue.utc[0]).format('Z');
    const toTimezoneUTCValue = moment().tz(convertToTimezoneValue.utc[0]).format('Z');

    return `Converting timezone from UTC${fromTimezoneUTCValue} to UTC${toTimezoneUTCValue}`;
  },

  /**
   * Convert the stored value of a data set into the required states
   * @param {object[]} relations - DE relations
   * @param {array} dataExtensions - data extensions array
   * @param {object[]} collections - selected DEs
   * @param {function} setDataExtensionProperties - function to set properties for selected DEs
   * @param {function} getDataExtensionOrDataViewFields - function to fetch DEs fields
   * @param {function} handleDataExtensionMissing - function for handling missing DEs
   * @param {function} addToSelectedDataExtensions - pushes related data extensions into selectedDataExtension state
   * @param {object} axios - axios token
   * @returns {Promise<array>} fetchedDataSet - formatted data set
   */
  loadStatesForDataSet: async (
    relations,
    dataExtensions,
    collections,
    setDataExtensionProperties,
    getDataExtensionOrDataViewFields,
    handleDataExtensionMissing,
    addToSelectedDataExtensions,
    axios,
  ) => {
    const dataExtensionsCopy = JSON.parse(JSON.stringify(dataExtensions));

    const fetchedDataSet = await Util.formatRelationsAndSelectedDEs(
      relations,
      dataExtensionsCopy,
      collections,
      true,
      setDataExtensionProperties,
      handleDataExtensionMissing,
      null,
      null,
      null,
      null,
      addToSelectedDataExtensions,
      null,
      null,
      axios,
      null,
      null,
    );

    if (fetchedDataSet) {
      // get fields for Selected DEs and update relations and additional joins
      for (let j = 0; j < fetchedDataSet.selectedDataExtensions.length; j += 1) {
        // eslint-disable-next-line
        const fields = await getDataExtensionOrDataViewFields(fetchedDataSet.selectedDataExtensions[j]);

        if (fields) {
          fetchedDataSet.relations?.forEach((relation) => {
            // Assign fields and update additional joins for the fromDE part of relation
            if (relation.fromCollection.ObjectID === fetchedDataSet.selectedDataExtensions[j].ObjectID) {
              relation.fromCollection.fields = fields;

              fields.forEach((field) => {
                relation.additionalJoins.forEach((join) => {
                  if (field.ObjectID === join.fromFieldObjectID) {
                    join.fromFieldObjectID = field.ObjectID;
                  } else if (field.ObjectID === join.toFieldObjectID) {
                    join.toFieldObjectID = join.fromFieldObjectID;
                    join.fromFieldObjectID = field.ObjectID;
                  }

                  join.rowID = Util.uuid();
                });
              });
            }

            // Assign fields and update additional joins for the toDE part of relation
            if ((relation.toCollection.ObjectID === fetchedDataSet.selectedDataExtensions[j].ObjectID) ||
              (relation.toCollection.CustomerKey === fetchedDataSet.selectedDataExtensions[j].CustomerKey)) {
              relation.toCollection.fields = fields;

              fields.forEach((field) => {
                relation.additionalJoins.forEach((join) => {
                  if ((field.ObjectID === join.fromFieldObjectID) ||
                    (field.ObjectID === join.toFieldObjectID)) {
                    join.toFieldObjectID = field.ObjectID;
                  }

                  join.rowID = Util.uuid();
                });
              });
            }
          });
        }
      }

      return fetchedDataSet;
    }
  },

  /**
   * Generates selectedDEsTree
   * @param {array} selectedDEs - array of selectedDEs
   * @returns {object} - the generated selectedDEsTree
   */
  generateSelectedDEsTree: selectedDEs => selectedDEs.reduce((object, currentDE) => {
    currentDE.subCollections?.forEach((subC) => {
      object[subC.id] = currentDE.id;
    });

    return object;
  }, {}),

  /**
   * Returns the difference between two objects
   * @param {object} o1 - object to compare
   * @param {object} o2 - base object
   * @returns {Array} - the difference between the two objects
   */
  getObjectDifference: (o1, o2) => {
    return Object.keys(o1)
      .filter(k => !Object.keys(o2).includes(k))
      .concat(Object.keys(o2).filter(k => !Object.keys(o1).includes(k)))
      .map(k => o1[k] || o2[k]);
  },

  /**
   * Returns formatted relations and selected DEs
   * @param {array} relations - regular or data sets relations
   * @param {array} dataExtensionsCopy - copy of data extensions
   * @param {array} collections - selected DEs (regular or data sets selected DEs)
   * @param {bool} isDataSet - if this function is used for data sets
   * @param {function} setDataExtensionProperties - function to set properties for selected DEs
   * @param {function} handleDataExtensionMissing - function for handling missing DEs
   * @param {function} getDataExtensionOrDataViewFields - function to fetch DEs fields
   * @param {function} updateSubquerySelectedDEFields - function for updating subqueries
   * @param {function} updateComparableFiltersFields - function for updating comparable filters
   * @param {function} getMatchedFieldsStateForSelection - function for getting matched fields
   * @param {function} addToSelectedDataExtensions - pushes related data extensions into selectedDataExtension state
   * @param {array} selectedFilters - filters used in a selection
   * @param {array} fieldsMap - helps to store the fields properly
   * @param {object} axios - axios token
   * @param {array} customValues - array of custom values
   * @param {array} fields - matched  fields in target definition
   * @param {array} globalCustomValues - array of shared custom values
   * @returns {object} returnState - formatted states for a selection
   */
  formatRelationsAndSelectedDEs: async (
    relations,
    dataExtensionsCopy,
    collections,
    isDataSet,
    setDataExtensionProperties,
    handleDataExtensionMissing,
    getDataExtensionOrDataViewFields,
    updateSubquerySelectedDEFields,
    updateComparableFiltersFields,
    getMatchedFieldsStateForSelection,
    addToSelectedDataExtensions,
    selectedFilters,
    fieldsMap,
    axios,
    customValues,
    fields,
    globalCustomValues,
  ) => {
    const returnState = {};
    /**
     * If there is/are relations; get their customerKeys and save it in Set.
     * (customerKeySet will be used while we're getting the fields. )
     */

    if (relations && relations.length) {
      const relationsState = [];

      let selectedDataExtensions = [];

      /**
       * This value will be different from 0 if parent data extension and child data extension is the same.
       * (its value updates at the end of every iteration in for loop)
       */
      let prevCheckDE = 0;

      // Traverse relations and determine parent and child extension
      for (let i = 0; i < relations.length; i += 1) {
        const dataExtensionsClone = JSON.parse(JSON.stringify(dataExtensionsCopy));

        // Get fromCollection dataExtension
        const fromCollections = dataExtensionsClone.filter(
          de => de.ObjectID === relations[i].fromCollectionObjectID ||
            de.CustomerKey === relations[i].fromCollectionCustomerKey,
        );

        let selectedDataExtensionsFromCollections = [];

        if (selectedDataExtensions && selectedDataExtensions.length > 0) {
          // Get fromCollection from selectedDataExtensions
          selectedDataExtensionsFromCollections = selectedDataExtensions.filter(
            de => de && de.deAlias && de.deAlias === relations[i].fromCollectionAlias,
          );
        }

        let fromCollection;

        if (selectedDataExtensionsFromCollections.length > 0) {
          [fromCollection] = selectedDataExtensionsFromCollections;
        } else if (fromCollections && fromCollections.length > 0) {
          [fromCollection] = fromCollections;
        } else {
          return handleDataExtensionMissing(relations[i].fromCollection);
        }

        // Get toCollection dataExtension
        const toCollections = dataExtensionsClone.filter(de => de.ObjectID === relations[i].toCollectionObjectID ||
          de.CustomerKey === relations[i].toCollectionCustomerKey);

        let selectedDataExtensionsToCollections = [];

        if (selectedDataExtensions && selectedDataExtensions.length > 0) {
          // Get fromCollection from selectedDataExtensions
          selectedDataExtensionsToCollections = selectedDataExtensions.filter(
            de => de && de.deAlias && de.deAlias === relations[i]?.toCollectionAlias,
          );
        }

        let toCollection;

        if (selectedDataExtensionsToCollections && selectedDataExtensionsToCollections.length > 0) {
          [toCollection] = selectedDataExtensionsToCollections;
        } else if (toCollections && toCollections.length > 0) {
          [toCollection] = toCollections;
        } else {
          return handleDataExtensionMissing(relations[i].toCollection);
        }

        /**
         * Check whether there is the same de in selectedDE state,
         * We will clone new object with different reference if there is
         */
        const checkDEExists = selectedDataExtensions.filter(de => de.CustomerKey === toCollection.CustomerKey);

        // Create clone of the same DE which has different reference
        if (toCollection === fromCollection) {
          toCollection = JSON.parse(JSON.stringify(toCollection));
          toCollection.deAlias = relations[i]?.toCollectionAlias;
          /**
           * Remove its subCollections because this will be newly created extension.
           * We don't want to see previous one's subCollections.
           */
          toCollection.subCollections = [];
        }

        // Clone parent component and add alias and fromField.
        const fromCollectionCopy = JSON.parse(JSON.stringify(fromCollection));

        fromCollectionCopy.deAlias = relations[i].fromCollectionAlias;
        fromCollectionCopy.fromField = relations[i].fromField;
        fromCollectionCopy.fromFieldType = relations[i].fromFieldType;
        fromCollectionCopy.fromFieldObjectID = relations[i].fromFieldObjectID;

        // If parent and the child of the previous extensions are the same
        if (prevCheckDE > 0) {
          // Assign parent component's subCollection as current child extension.
          fromCollectionCopy.subCollections = [toCollection];
        }

        // If there is previous relation or indicating parent DE
        if (relations[i - 1] || i === 0) {
          /**
           * I -> 0 means first extension in the selectedDataExtension, we want to use this on
           * Rendering so instead of using clone of fromCollection we should call fromCollection itself.
           */
          if (i === 0) {
            setDataExtensionProperties(
              fromCollection,
              relations[i].fromCollectionAlias,
              [],
              relations[i].fromField,
              0,
              i,
              relations[i].fromFieldType,
              relations[i].fromFieldObjectID,
            );
          }

          if (i !== 0) {
            // If there is previous relation AND if the parent de of previous one and current one are the same
            if (relations[i - 1] && relations[i - 1].fromCollection === relations[i].fromCollection) {
              // Get the all information of previous data extension
              const previousDE = dataExtensionsCopy
                .filter(de => de.Name.toString() === relations[i - 1].toCollection.toString());

              // Send previous de as a subcollection parameter
              setDataExtensionProperties(
                fromCollectionCopy,
                relations[i].fromCollectionAlias,
                previousDE,
                relations[i].fromField,
                0,
                i,
              );
            } else {
              // Send empty array as a subCollection
              setDataExtensionProperties(
                fromCollectionCopy,
                relations[i].fromCollectionAlias,
                [],
                relations[i].fromField,
                0,
                i,
                relations[i].fromFieldType,
                relations[i].fromFieldObjectID,
              );
            }
          }

          if (toCollection) {
            // Add properties to CURRENT child extension
            setDataExtensionProperties(
              toCollection,
              relations[i].toCollectionAlias,
              [],
              relations[i].toField,
              1,
              i,
              relations[i].toFieldType,
              relations[i].toFieldObjectID,
            );
          }
          // Push CURRENT child extension to subCollections of Parent extension.
          if (fromCollection) {
            if (fromCollection.subCollections) {
              fromCollection.subCollections.push(toCollection);
            } else {
              fromCollection.subCollections = [toCollection];
            }
          }

          // Set up properties of relation
          const newRelation = {
            relationalModalId: toCollection ? toCollection.relationalModalId : '',
            fromCollection: fromCollectionCopy,
            toCollection,
            joinType: relations[i].type,
            additionalJoins: relations[i].additionalJoins || [],
          };

          relationsState.push(newRelation);

          /**
           * Check whether from/to collection pushed into selectedDataExtensions
           * Before or not and push if it hasn't pushed before
           */

          // eslint-disable-next-line require-atomic-updates
          selectedDataExtensions =
            // eslint-disable-next-line
            await addToSelectedDataExtensions(selectedDataExtensions, toCollection, fromCollection);
        }
        prevCheckDE = checkDEExists.length || 0;
      } // End of for loop

      if (!isDataSet) {
        const DEFieldsPromises = (selectedDataExtensions || []).map(DE => getDataExtensionOrDataViewFields(DE));

        if (DEFieldsPromises.length) {
          try {
            const promiseResults = await Promise.all([
              ...DEFieldsPromises,
              // Function call to update fields in subquery selected DE
              updateSubquerySelectedDEFields(selectedFilters),
              // Check if there are updated or added filters for comparable filter`s fields
              updateComparableFiltersFields(selectedFilters),
            ]);
            const DEFieldsResults = promiseResults.slice(0, DEFieldsPromises.length);

            /**
             * As Array.map and Promise.all keep order, `idx` is the same as it
             * Would be in a for loop of selectedDataExtension
             */
            DEFieldsResults.forEach((DEFields, idx) => {
              // Set fields of customer key and keep it in Map.
              fieldsMap.set(selectedDataExtensions[idx].CustomerKey, DEFields);
              selectedDataExtensions.forEach((de) => {
                if (de.CustomerKey === selectedDataExtensions[idx].CustomerKey) {
                  // eslint-disable-next-line no-param-reassign
                  de.fields = DEFields;
                }
              });

              // Assign Map values to fields property of every relation.
              relationsState.forEach((relation) => {
                // eslint-disable-next-line no-param-reassign
                relation.fromCollection.fields = fieldsMap.get(relation.fromCollection.CustomerKey);
              });
            });
          } catch (error) {
            if (!axios.isCancel(error)) this.setState({ error });
          }
        }
      }

      const selectedDEsTree = Util.generateSelectedDEsTree(selectedDataExtensions);

      if (!isDataSet) {
        const matchedFields = getMatchedFieldsStateForSelection(
          selectedDataExtensions,
          fields,
          customValues,
          globalCustomValues,
        );

        returnState.matchedFields = matchedFields;
        returnState.selectedFilters = selectedFilters;
        returnState.customValues = customValues;
      }

      returnState.relations = relationsState;
      returnState.selectedDataExtensions = selectedDataExtensions;
      returnState.selectedDEsTree = selectedDEsTree;

      return returnState;
    }

    // In this case we just have 1 data extension which has no relations
    if (collections[0]) {
      let filteredDataExtensions;

      let dataExtension;

      if (collections[0].collectionObjectID) {
        filteredDataExtensions = dataExtensionsCopy.filter(de => de.ObjectID === collections[0].collectionObjectID);
      } else {
        // Legacy: filter on name (to be removed later)
        filteredDataExtensions = dataExtensionsCopy.filter(de => de.Name.toString() === collections[0].collection);
      }

      if (filteredDataExtensions && filteredDataExtensions.length) {
        [dataExtension] = filteredDataExtensions;
      }

      if (dataExtension) {
        setDataExtensionProperties(
          dataExtension,
          collections[0].alias,
          [],
          filteredDataExtensions, // This was 'filteredDataExtensions'
          // Before, does not make sense? Should be from/toField
          2,
          null,
        );

        if (!isDataSet) {
          try {
            dataExtension.fields = getDataExtensionOrDataViewFields(dataExtension);

            await Promise.all([
              dataExtension.fields,
              // Function call to update fields in subquery selected DE
              updateSubquerySelectedDEFields(selectedFilters),
              // Check if there are updated or added filters for comparable filter`s fields
              updateComparableFiltersFields(selectedFilters),
            ]);

            const matchedFields = getMatchedFieldsStateForSelection(
              filteredDataExtensions,
              fields,
              customValues,
              globalCustomValues,
            );

            /**
             * If data extension fields are known set selectedDataExtension, matchedFields and selectedFilters
             * To returnState
             */
            if (dataExtension.fields) {
              returnState.selectedDataExtensions = filteredDataExtensions;
              returnState.matchedFields = matchedFields;
              returnState.selectedFilters = selectedFilters;
              returnState.customValues = customValues;

              return returnState;
            }
          } catch (error) {
            return { error };
          }
        }

        returnState.selectedDataExtensions = filteredDataExtensions;

        return returnState;
      }

      // Throw error indicating the selection cannot be opened because of the missing data extension
      return handleDataExtensionMissing(collections[0].collection);
    }
  },

  /**
   * Function that returns object without some properties
   * @param {object} objectWithState - object to reduce property
   * @returns {object} object with reduced property
   */
  returnStateWithoutSomeProperties: objectWithState => ({
    ...objectWithState,
    globalReducer: {
      ...objectWithState.globalReducer,
      waterfallCopy: {},
      selectedView: Constants.NAVIGATION__STEPS,
      loadingWaterfallSelection: false,
      runStatusForSelectionChain: [],
      runningQuery: false,
      waterfallSelectionStatus: null,
    },
    selectionReducer: {
      ...objectWithState.selectionReducer,
      selectionsSchedules: {},
      selectionsForRunChain: [],
      allSelections: [],
      selectionsWithMissingTargetDE: [],
      selectionsWithNoMatchedField: [],
      selectionsWithoutMappedRequiredFields: [],
    },
    targetDataExtensionReducer: {
      targetDataExtensions: [],
    },
  }),

  /**
   * builds HTML error to render in a swal
   * @param {string} error.actualError - The actual error to render
   * @param {string} error.customError - The custom error to render
   * @returns {string} - html of the error
   */
  buildErrorHTML: ({ actualError, message }) => `
    <details>
      <summary>${message} <span class='show-more'>Show More</span></summary>
      <p>${actualError}</p>
      <span class='show-less' onclick="${Util.hideDetails()}">Show Less</span>
    </details>
  `,

  hideDetails: () => `(function() {
    document.querySelector('details').removeAttribute('open');
  })();
  `,

  /**
   * Handles throwing a sweet alert
   * @param {string} message - Warning text
   * @param {array} missingFields - An array containing missing fields information
   * @param {boolean} isRelationPanel - Indicates whether error is thrown in relation panel
   * @returns {object} the sweet alert object
   */
  handleMissingOrRenamedField: async (message, missingFields, isRelationPanel) => SwalUtil.fire({
    type: Constants.SWAL__TYPE__ERROR,
    title: 'Missing fields in relation',
    options: {
      customClass: 'missing-fields-table',
      buttonsStyling: false,
      allowOutsideClick: false,
    },
    messageHTML: `<div class="main-content-container">
    <div class="swal-message">${message}</div>
    <table class="slds-table slds-table_cell-buffer slds-table_bordered">
      <thead>
        <tr class="slds-line-height_reset">
          <th>
            <div class="slds-truncate" title="Field Name">Field Name</div>
          </th>
          <th>
            <div class="slds-truncate" title="Data Extension">Data Extension</div>
          </th>
          ${isRelationPanel ?
    '' :
    `
            <th>
              <div class="slds-truncate" title="Tab">Tab</div>
            </th>`
}
        </tr>
      </thead>
      <tbody>
        ${missingFields.map(f => (
    `<tr class="slds-hint-parent">
        <td
          data-label="Field Name"
          class="slds-truncate"
          title="${f.name}"
        >${Util.abbreviate(f.name, 30)}</td>
        <td
          data-label="Data Extension"
          class="slds-truncate"
          title="${f.deName}"
        >${Util.abbreviate(f.deName, 30)}</td>
        ${isRelationPanel ?
      '' :
      `
        <td
          data-label="Tab"
          class="slds-truncate"
          title="${f.tabName}"
        >${Util.abbreviate(f.tabName, 15)}</td>`}
      </tr>`
  ))}
    </tbody>
    </table>
  </div>`.replace(/,/g, ''),
  }),

  /**
   * check if a specific cookie key equals true
   * @param {string} cookieInfo - the key of the cookie we want to check
   * @returns {boolean} if a specific cookie key equals true or not
   */
  checkIsEnabledCookies: cookieInfo => cookieInfo === 'true',

  /**
   * Helps handling upgrade warning by showing the right swal
   * @param {String} reachedLimit - Limit reached by the user
   * @returns {Promise} - A swal alert
   */
  handleUpgradeWarning: (reachedLimit) => {
    const getSwalContent = () => {
      if (reachedLimit === Constants.ESSENTIALS_EDITION_MAX_SELECTIONS_LIMIT_LABEL) {
        return {
          title: 'Active Selection Limit Reached',
          messageHTML: `<p style="text-align: left;">
          You have reached the limit of ${Constants.ESSENTIALS_EDITION_MAX_SELECTIONS_COUNT
} active Selections and cannot create any more Selections.
           Please delete existing Selections to create new Selections. <br><br>
          Upgrade to a paid plan to have access to unlimited Selections.
          <a href="https://deselect.com/pricing" target="_blank">Learn More</a>
          </p>`,
        };
      }
    };
    const { title, messageHTML } = getSwalContent();

    return SwalUtil.fire({
      type: Constants.SWAL__TYPE__WARNING,
      title,
      messageHTML,
      // Remove all ways to close the alert
      options: {
        showCancelButton: true,
        cancelButtonText: 'Close',
        showConfirmButton: false,
        showCloseButton: false,
        allowOutsideClick: false,
      },
    });
  },

  getDefaultValueByFieldType: (fieldType) => {
    switch (fieldType) {
      case Constants.FILTERLINE__FIELDTYPE__BOOLEAN:
        return Constants.FILTERLINE__VALUE__TRUE;

      case Constants.FILTERLINE__FIELDTYPE__DATE:
        return moment();
      case Constants.FILTERLINE__FIELDTYPE__NUMBER:
      case Constants.FILTERLINE__FIELDTYPE__DECIMAL:
        return '0';

      default:
        return '';
    }
  },

  checkFunctionPropExists: (fn) => {
    return typeof fn !== 'undefined';
  },

  /**
   * Formats a string to be used as a description in a Deedee AI request.
   * @param {string} str - The string to be formatted.
   * @returns {string} The formatted string.
   */
  formatDeedeeAIRequestDescription: (str) => {
    // Rule: Replace all occurrences of "this/the query/command" with "this selection"
    str = str.replace(/(this|the) (query|command)/gi, 'This selection');

    // Rule: Replacing all occurrences of 'table' or 'list' with 'data extension'
    str = str.replace(/(table|list)/gi, 'data extension');

    return str;
  },

  /**
   * Returns a description of the failure based on the given key.
   * @param {string} key - The key to determine the failure description.
   * @returns {string} The description of the failure.
   */
  getProcessingLogFailureDescription: (key) => {
    switch (key) {
      case 'createFields':
        return 'Fields in target DE.';
      case 'createRelations':
        return 'Relation between selected data extensions.';
      case 'createFilters':
        return 'Filters.';
      case 'createSortLimit':
        return 'Sort & Limit.';
      default:
        return '';
    }
  },
};

export default Util;
