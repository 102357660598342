import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import classNames from 'classnames';
import _ from 'lodash';

import ModalTemplate from '../../../shared/ModalTemplate/ModalTemplate';
import ToggleButton from '../../../shared/ToogleButton/ToggleButton';
import Constants from '../../../../constants/constants';
import DropdownOptions from '../../../../constants/dropdownOptions';
import SwalUtil from '../../../../utils/swal/swalUtil';
import Util from '../../../../util';

import './styles.scss';

const SourceLimiting = ({
  unionSelections,
  handleSetSelectionState,
  sourceLimitingEnabled,
  handleSetAppState,
}) => {
  const [limits, setLimits] = useState(
    unionSelections.map(source => ({
      active: source?.sourceLimit?.active || false,
      value: source?.sourceLimit?.value || 100,
      type:
        source?.sourceLimit?.type ||
        DropdownOptions.SOURCE_LIMITING__OPTIONS[1].value,
      tabName: source.tabName,
    })),
  );

  const [localSourceLimitingEnabled, setLocalSourceLimitingEnabled] = useState(sourceLimitingEnabled);

  /**
   * Handles changing value of source limit
   * @param {number} index - index of the source to change
   * @param {number} value - new value of the source
   * @returns {void}
   */
  const handleValueChange = useCallback((index, value) => {
    const integerValue = parseInt(value);

    if (value !== integerValue.toString()) {
      value = integerValue;
    }
    setLimits((prevLimits) => {
      const newLimits = [...prevLimits];

      newLimits[index].value = value;

      return newLimits;
    });
  }, []);

  /**
   * Handles validation of source limit value
   * @param {number} index - index of the source to change
   * @param {number} value - new value of the source
   * @returns {void}
   */
  const handleInputBlur = useCallback((index, value) => {
    if (value === '' || value < 1) {
      handleValueChange(index, 1);
    } else if (limits[index].type === Constants.SELECTION_SOURCE_LIMIT__TYPE__PERCENTAGE && value > 100) {
      handleValueChange(index, 100);
    }
  }, [limits, handleValueChange]);

  /**
   * Handles changing source limiting type
   * @param {object} option - new type of the source
   * @param {number} option.index - index of the source to change
   * @param {string} option.value - new type of the source
   * @returns {void}
   */
  const handleTypeToggle = useCallback((option) => {
    setLimits((prevLimits) => {
      const newLimits = [...prevLimits];

      newLimits[option.index].type = option.value;

      if (option.value === Constants.SELECTION_SOURCE_LIMIT__TYPE__PERCENTAGE && newLimits[option.index].value > 100) {
        newLimits[option.index].value = 100;
      }

      return newLimits;
    });
  }, []);

  /**
   * Closes the source limiting modal
   */
  const handleCloseSourceLimitingModal = useCallback(() => {
    handleSetSelectionState({ showSourceLimitingModal: false });
  }, [handleSetSelectionState]);

  /**
   * Saves the source limiting by updating union selection data in app's state
   * @returns {void}
   */
  const onSave = useCallback(() => {
    if (limits.every(limit => Number(limit?.value) === 100 &&
    limit?.type === Constants.SELECTION_SOURCE_LIMIT__TYPE__PERCENTAGE) && localSourceLimitingEnabled) {
      // Show warning message
      return SwalUtil.fire({
        type: Constants.SWAL__TYPE__ERROR,
        title: 'No limits applied',
        message: `You need to set at least one limit.
        If you do not want to apply any limits, turn Source Limiting off.`,
        options: {
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
        },
      });
    }
    /**
     * update union selection
     */

    const updatedUnionSelections = unionSelections.map((source, index) => {
      _.set(source, 'sourceLimit', {
        type: limits[index].type,
        value: limits[index].value,
        active: true,
        sourceLimitingEnabled: localSourceLimitingEnabled,
      });

      return source;
    });

    handleSetSelectionState({ sourceLimitingEnabled: localSourceLimitingEnabled });
    // Update the app's state
    handleSetAppState({ unionSelections: updatedUnionSelections });
    handleCloseSourceLimitingModal();
  }, [limits, unionSelections, handleSetSelectionState,
    localSourceLimitingEnabled, handleSetAppState, handleCloseSourceLimitingModal]);

  const sourceLimitContainerClass = classNames('source-limit-container', {
    'source-limit-container--disabled': !localSourceLimitingEnabled,
  });

  return (
    <ModalTemplate
      id="source-limiting-modal"
      headerId="source-limiting-header"
      headerTitle="Source Limiting"
      contentClassName="slds-p-around_medium"
      cancelButtonId="Cancel"
      handleCancel={handleCloseSourceLimitingModal}
      saveButtonId="save-source-limiting"
      handleSave={onSave}
      saveButtonTitle="Save"
      footerId="source-limiting-footer"
    >
      <div className="source-limit-title">
        <p>Limit the number of records generated by each Source.</p>
        <ToggleButton
          checked={localSourceLimitingEnabled}
          onChange={() => setLocalSourceLimitingEnabled(!localSourceLimitingEnabled)}
        />
      </div>
      {unionSelections.map((source, index) => (
        <div key={index} className={sourceLimitContainerClass}>
          <p>Keep</p>
          {' '}
          <span className="bold-text">first</span>
          <input
            id={`source-limit-input-${index}`}
            className="source-limit-input"
            type="number"
            value={limits[index].value}
            onChange={e => handleValueChange(index, e.target.value)}
            onBlur={e => handleInputBlur(index, e.target.value)}
            min={1}
            aria-label="Source limit input"
            max={limits[index].type === Constants.SELECTION_SOURCE_LIMIT__TYPE__PERCENTAGE ? 100 : null}
            style={{ marginRight: '0.5rem' }}
            disabled={!localSourceLimitingEnabled}
          />
          <Dropdown
            selection
            className="target-data-extension-dropdown source-limit-dropdown"
            placeholder="Choose data extension"
            loading
            options={DropdownOptions.SOURCE_LIMITING__OPTIONS}
            onChange={(e, data) => handleTypeToggle(data)}
            value={limits[index].type}
            index={index}
            disabled={!localSourceLimitingEnabled}
          />
          <p className="source-limit-record-text" title={source.tabName}>
            {` records from '${Util.abbreviate(source.tabName, 30)}'.`}
          </p>
        </div>
      ))}
    </ModalTemplate>
  );
};

SourceLimiting.propTypes = {
  /**
   * Array of objects containing the union selections to manipulate source limiting
   */
  unionSelections: PropTypes.arrayOf(PropTypes.object).isRequired,
  /**
   * It helps to set the Selection`s state
   * It will be passed from Selection.js
   */
  handleSetSelectionState: PropTypes.func.isRequired,
  /**
   * Boolean indicating if source limiting is enabled
   */
  sourceLimitingEnabled: PropTypes.bool.isRequired,
  /**
   * It sets the App component`s state
   * This prop will be passed from App.js component through Selection.js
   */
  handleSetAppState: PropTypes.func.isRequired,
};

export default SourceLimiting;
