import './Style.scss';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Constants from '../../../../constants/constants';
// Children components
import PrioDedupMode from './ChildrenComponents/PrioDedupMode/PrioDedupMode';
import CriteriaField from './ChildrenComponents/CriteriaField/CriteriaField';
import PrioType from './ChildrenComponents/PrioType/PrioType';
import PrioValueInput from './ChildrenComponents/PrioValueInput/PrioValueInput';
import PrioValueContainer from './ChildrenComponents/PrioValueContainer/PrioValueContainer';
import DeduplicationField from './ChildrenComponents/DeduplicationField/DeduplicationField';
import Util from '../../../../util';
import PrioDeduplicationSortMode from './ChildrenComponents/PrioDeduplicationSortMode/PrioDeduplicationSortMode';
import PrioDeduplicationStatus from './ChildrenComponents/PrioDeduplicationStatus/PrioDeduplicationStatus';
import AdvancedDedupModal from './ChildrenComponents/AdvancedDedupModal/AdvancedDedupModal';
import AdvancedPrioFiltersContainer
  from './ChildrenComponents/AdvancedPrioFiltersContainer/AdvancedPrioFiltersContainer';
import MultipleCriteriaChoice from './ChildrenComponents/MultipleCriteriaChoice/MultipleCriteriaChoice';
import Button from '../../../shared_v2/Button/Button';
import ProgressBar from '../../../shared_v2/ProgressBar/ProgressBar';
import SwalUtil from '../../../../utils/swal/swalUtil';
import ModalTemplate from '../../../shared_v2/ModalTemplate/ModalTemplate';

export default class PrioDedup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      value: null,
      deduplicationFieldObjectID: null,
      criteriaFieldType: null,
      selectedMode: Constants.PRIO_DEDUP__MODE__BASIC__VALUE,
      selectedType: Constants.PRIO_DEDUP__SORTING_PRIO__DEFINE_VALUES__VALUE,
      sortOrder: Constants.PRIO_DEDUP__SORT_ORDER__ASC,
      criteriaFieldObjectID: null,
      criteriaFieldName: null,
      deduplicationFieldName: null,
      usePrioDeduplication: false,
      prioDedupStep: true,
      showCriteriaModal: false, // state for opening or closing the criteria modal
      multipleSortingOptionLines: [],
      arrayOfDisabledCriteriaOptions: [],
      unchangedMultipleSortingOptionLines: [],
    };

    // static variables those we can use
    this.extensionFields = props.targetDataExtensionFields || [];
  }

  componentDidMount() {
    const {
      prioDeduplication,
      usePrioDeduplication,
      advancedDeduplicationRules,
    } = this.props;

    if (prioDeduplication) {
      const prios = [];
      const unchangedMultipleSortingOptionLines = [];

      // Clone multipleSortingOptionLines
      if (prioDeduplication.multipleSortingOptionLines && prioDeduplication.multipleSortingOptionLines.length > 0) {
        prioDeduplication.multipleSortingOptionLines.forEach((sortingOption) => {
          unchangedMultipleSortingOptionLines.push({ ...sortingOption });
        });
      }
      // Find criteria field
      const criteriaField = this.extensionFields.find(
        field => field.ObjectID === prioDeduplication.criteriaFieldObjectID,
      );
      // Find deduplication field
      const deduplicationField = this.extensionFields.find(
        field => field.ObjectID === prioDeduplication.deduplicationFieldObjectID,
      );
      // If there are any priorities, set them

      if (prioDeduplication.priorities) {
        prioDeduplication.priorities.map(prio => prios.push({ title: prio.value, value: prio.value }));
      }
      let multipleSortingOptionLinesSpread = [];
      // If there are any multiple sorting option lines, set them

      if (prioDeduplication.multipleSortingOptionLines) {
        multipleSortingOptionLinesSpread =
          prioDeduplication.multipleSortingOptionLines.map(el => el.criteriaFieldObjectID);
      }

      this.setState({
        data: prios || [],
        deduplicationFieldObjectID:
          prioDeduplication.deduplicationFieldObjectID || null,
        criteriaFieldObjectID: prioDeduplication.criteriaFieldObjectID || null,
        sortOrder:
          prioDeduplication.sortOrder || Constants.PRIO_DEDUP__SORT_ORDER__ASC,
        selectedType:
          prioDeduplication.type ||
          Constants.PRIO_DEDUP__SORTING_PRIO__DEFINE_VALUES__VALUE,
        selectedMode:
          prioDeduplication.mode || Constants.PRIO_DEDUP__MODE__BASIC__VALUE,
        criteriaFieldType: criteriaField ? criteriaField.FieldType : null,
        criteriaFieldName: criteriaField ? criteriaField.Name.toString() : null,
        deduplicationFieldName: deduplicationField ?
          deduplicationField.Name.toString() :
          null,
        usePrioDeduplication,
        multipleSortingOptionLines: prioDeduplication.multipleSortingOptionLines || [],
        arrayOfDisabledCriteriaOptions: [
          prioDeduplication.criteriaFieldObjectID,
          prioDeduplication.deduplicationFieldObjectID,
          ...multipleSortingOptionLinesSpread || [],
        ],
        unchangedAdvancedDeduplicationRules: advancedDeduplicationRules && advancedDeduplicationRules.length > 0 ?
          [...advancedDeduplicationRules] :
          [],
        unchangedMultipleSortingOptionLines: unchangedMultipleSortingOptionLines || [],
      });
    } else {
      this.setState({
        usePrioDeduplication,
      });
    }
  }

  /**
   * sets the showCriteriaModal state value to true
   * @returns {void}
   */
  openCriteriaModal = () => {
    this.setState({ showCriteriaModal: true });
  };

  /**
   * sets the showCriteriaModal state value to false
   * @returns {void}
   */
  closeCriteriaModal = () => {
    this.setState({ showCriteriaModal: false });
  };

  /**
   * clears the state value
   * @param {string} classInstance - classInstance
   * @returns {void}
   */
  makeStateClear = (classInstance) => {
    const { value } = this.state;

    if (!(value instanceof classInstance)) {
      this.setState({
        value: null,
      });
    }
  };

  handleChangeValue = (e) => {
    switch (e.target.id) {
      case Constants.PRIO_DEDUP__TEXT_INPUT__ID: {
        this.setState({
          value: e.target.value.trimLeft(),
        });
        break;
      }
      case Constants.PRIO_DEDUP__NUMBER_INPUT__ID: {
        /*
         * if field type is number and value doesn't containe only numbers
         * don't allow entering that value (eg. dot, comma, etc...)
         */
        if (!Util.containsOnlyNumbers(e.target.value) && e.target.value !== '') {
          return;
        }
        this.setState({
          value: e.target.value,
        });
        break;
      }
      case Constants.PRIO_DEDUP__DECIMAL_INPUT__ID: {
        this.makeStateClear(Number);
        this.setState({
          value: e.target.value,
        });
        break;
      }
      case Constants.PRIO_DEDUP__BOOLEAN_INPUT__ID: {
        this.makeStateClear(Boolean);
        this.setState({
          value: e.target.value,
        });
        break;
      }
      default:
        break;
    }
  };

  handleChangeRadioValues = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  /**
   * function to change the stat of dedup component
   * @param {object} newState - new state to set
   * @returns {void}
   */
  handleChangePrioDedupState = (newState) => {
    this.setState(newState);
  };

  /**
   * Handle adding new value
   * @param {object} e - used for preventing the refreshment
   * @returns {void}
   */
  handleAddValueToArray = (e) => {
    e.preventDefault();
    const { value, data, criteriaFieldType } = this.state;
    // If values already exist return

    if (data.length > 0) {
      let exist;

      data.forEach(async (d) => {
        if (d.value === value) {
          exist = true;
          SwalUtil.fire({
            type: Constants.SWAL__TYPE__ERROR,
            message: 'Value already exists in this list.',
          });
        }
      });
      if (exist) return;
    }
    if (value === null || value.length === 0) return;

    // Create data with anything else
    data.push({
      title: value,
      value,
    });

    if (criteriaFieldType === Constants.FILTERLINE__FIELDTYPE__BOOLEAN) {
      // Keep boolean value so you can click many times without changing options
      this.setState({
        value,
        data,
      });
    } else {
      // Clear if everything else
      this.setState({
        value: null,
        data,
      });
    }
  };

  /**
   * Change criteria field
   * @param {object} e - event. Use e.target to get the value
   * @returns {void}
   */
  handleChangeCriteriaField = (e) => {
    const { deduplicationFieldObjectID } = this.state;

    this.setState({
      value: null,
    });
    /**
     * If no selected value then set criteriaFieldObjectID
     * to null so the priorization logic div will not appear
     */
    if (e.target.value) {
      if (e.target.value === Constants.PRIO_DEDUP__OPTION__PLEASE_SELECT) {
        this.setState({
          criteriaFieldObjectID: null,
        });
      } else {
        // Find the correct field for the selected value
        const field = this.extensionFields.find(
          f => f.ObjectID === e.target.value,
        );
        const { criteriaFieldType } = this.state;

        if (criteriaFieldType !== field.FieldType) {
          this.setState({
            data: [],
          });
        }

        this.setState(
          {
            arrayOfDisabledCriteriaOptions: [deduplicationFieldObjectID, field.ObjectID],
            criteriaFieldObjectID: field.ObjectID,
            criteriaFieldType: field.FieldType,
            criteriaFieldName: field.Name.toString(),
            multipleSortingOptionLines: [],
          },
          () => {
            const { criteriaFieldType: ctrFieldType } = this.state;

            if (ctrFieldType === Constants.FILTERLINE__FIELDTYPE__BOOLEAN) {
              this.setState({
                value: Constants.FILTERLINE__VALUE__TRUE,
                selectedType: Constants.PRIO_DEDUP__SORTING_PRIO__DEFINE_VALUES__VALUE,
              });
            } else if (
              ctrFieldType === Constants.FILTERLINE__FIELDTYPE__DATE
            ) {
              this.setState({
                value: null,
                selectedType: Constants.PRIO_DEDUP__SORTING_PRIO__SORT_ALL_VALUES__VALUE,
              });
            } else {
              this.setState({
                value: null,
                selectedType: Constants.PRIO_DEDUP__SORTING_PRIO__DEFINE_VALUES__VALUE,
              });
            }
          },
        );
      }
    }
  };

  handleChangeDeduplicationField = (e) => {
    if (e.target.value) {
      const deduplicationField = this.extensionFields.find(
        field => field.ObjectID === e.target.value,
      );

      this.setState({
        deduplicationFieldObjectID: deduplicationField.ObjectID,
        deduplicationFieldName: deduplicationField ?
          deduplicationField.Name.toString() :
          '',
        arrayOfDisabledCriteriaOptions: [deduplicationField.ObjectID],
        // Reset
        criteriaFieldName: null,
        criteriaFieldObjectID: null,
        criteriaFieldType: null,
        selectedMode: Constants.PRIO_DEDUP__MODE__BASIC__VALUE,
        selectedType: Constants.PRIO_DEDUP__SORTING_PRIO__DEFINE_VALUES__VALUE,
        sortOrder: Constants.PRIO_DEDUP__SORT_ORDER__ASC,
        value: null,
      });
    }
  };

  handleRemoveValue = (e, index) => {
    const { data } = this.state;

    data.splice(index, 1);
    this.setState({ data });
  };

  handleSavePrioDedup = async () => {
    const {
      deduplicationFieldObjectID,
      criteriaFieldObjectID,
      data,
      selectedType,
      usePrioDeduplication,
      sortOrder,
      selectedMode,
      multipleSortingOptionLines,
    } = this.state;

    const {
      advancedDeduplicationRules,
      handleSetSelectionState,
      switchToTargetDE,
    } = this.props;

    if (usePrioDeduplication) {
      switch (selectedMode) {
        case Constants.PRIO_DEDUP__MODE__ADVANCED__VALUE: {
          if (Array.isArray(advancedDeduplicationRules) && advancedDeduplicationRules.length) {
            // Save the rules
            const prioDeduplication = {
              mode: selectedMode,
              type: selectedType,
              deduplicationFieldObjectID,
              criteriaFieldObjectID,
              advancedDeduplicationRules,
            };

            handleSetSelectionState({
              prioDeduplication,
              usePrioDeduplication,
            });
          } else {
            await SwalUtil.fire({
              type: Constants.SWAL__TYPE__ERROR,
              title: 'Missing Rules',
              message: 'Please define at least one rule',
            });

            return;
          }
          break;
        }

        case Constants.PRIO_DEDUP__MODE__BASIC__VALUE: {
          if (!deduplicationFieldObjectID) {
            await SwalUtil.fire({
              type: Constants.SWAL__TYPE__ERROR,
              message: 'You cannot leave Field That Needs To Be Unique empty.',
            });

            return;
          } if (!criteriaFieldObjectID) {
            await SwalUtil.fire({
              type: Constants.SWAL__TYPE__ERROR,
              message: 'You cannot leave Field That Determines Priority empty.',
            });

            return;
          }
          if (selectedType === Constants.PRIO_DEDUP__SORTING_PRIO__DEFINE_VALUES__VALUE && data.length <= 0) {
            await SwalUtil.fire({
              type: Constants.SWAL__TYPE__ERROR,
              message: 'Please select at least one sort value.',
            });

            return;
          }
          const prios = [];

          let prioDeduplication = {};

          switch (selectedType) {
            case Constants.PRIO_DEDUP__SORTING_PRIO__DEFINE_VALUES__VALUE: {
              data.map(val => prios.push({
                fieldObjectID: criteriaFieldObjectID,
                criteria: '=',
                value: val.value,
              }));
              prioDeduplication = {
                mode: selectedMode,
                type: selectedType,
                deduplicationFieldObjectID,
                criteriaFieldObjectID,
                priorities: prios,
              };
              break;
            }
            case Constants.PRIO_DEDUP__SORTING_PRIO__SORT_ALL_VALUES__VALUE:
              prioDeduplication = {
                mode: selectedMode,
                type: selectedType,
                deduplicationFieldObjectID,
                criteriaFieldObjectID,
                sortOrder,
                multipleSortingOptionLines,
              };
              break;
            default:
              break;
          }
          handleSetSelectionState({
            prioDeduplication,
            usePrioDeduplication,
          });
          break;
        }
        default:
          break;
      }
    } else {
      handleSetSelectionState({
        usePrioDeduplication: false,
      });
    }

    const prios = [];

    let newSortingOptionLines = [];

    let newArrayOfDisabledCriteriaOptions = [];
    // Here eslint says its not used but its actually being used (prioDeduplication Object)
    // eslint-disable-next-line no-unused-vars

    let prioDeduplication = {};

    switch (selectedType) {
      case Constants.PRIO_DEDUP__SORTING_PRIO__DEFINE_VALUES__VALUE: {
        data.map(val => prios.push({
          fieldObjectID: criteriaFieldObjectID,
          criteria: '=',
          value: val.value,
        }));
        prioDeduplication = {
          mode: selectedMode,
          type: selectedType,
          deduplicationFieldObjectID,
          criteriaFieldObjectID,
          priorities: prios,
        };
        break;
      }
      case Constants.PRIO_DEDUP__SORTING_PRIO__SORT_ALL_VALUES__VALUE:
        // If there is empty lowest/highest criteria for multiple sorting options - delete the one with missing field
        newSortingOptionLines = [];
        newArrayOfDisabledCriteriaOptions = [];
        multipleSortingOptionLines.forEach((el) => {
          if (el.sortOrder !== '') {
            newSortingOptionLines.push(el);
            newArrayOfDisabledCriteriaOptions.push(el.criteriaFieldObjectID);
          }
        });
        newArrayOfDisabledCriteriaOptions.push(criteriaFieldObjectID);
        newArrayOfDisabledCriteriaOptions.push(deduplicationFieldObjectID);

        // eslint-disable-next-line no-unused-vars
        prioDeduplication = {
          mode: selectedMode,
          type: selectedType,
          deduplicationFieldObjectID,
          criteriaFieldObjectID,
          sortOrder,
          multipleSortingOptionLines: newSortingOptionLines,
          arrayOfDisabledCriteriaOptions: newArrayOfDisabledCriteriaOptions,
        };
        break;
      default:
        break;
    }

    switchToTargetDE();
  };

  /**
   * Check for any changes made while on Prio Dedup
   * screen and handle if the user wants to save or
   * discard them
   * @returns {void}
   */
  handleCancelPrioDedup = async () => {
    const { switchToTargetDE } = this.props;

    if (this.prioDeduplicationHasChanged()) {
      const result = await SwalUtil.fire({
        title: 'Discard Deduplication Settings',
        message: 'Are you sure you want to discard your changes to the deduplication settings?',
        options: {
          showCancelButton: true,
          confirmButtonText: 'Discard changes',
        },
      });

      if (result.value) {
        const {
          handleSetSelectionState,
          prioDeduplication,
          usePrioDeduplication,
        } = this.props;

        handleSetSelectionState({
          prioDeduplication,
          usePrioDeduplication,
        });
        switchToTargetDE();
      }
    } else {
      switchToTargetDE();
    }
  };

  /**
   * Check for any changes made while on
   * dedup screen before going back
   * to avoid asking the user for saving
   * if no changes were made
   * @returns {boolean} true or false depending on changes made
   */
  prioDeduplicationHasChanged = () => {
    const {
      prioDeduplication,
      usePrioDeduplication,
      advancedDeduplicationRules,
    } = this.props;

    const {
      criteriaFieldObjectID,
      deduplicationFieldObjectID,
      selectedMode,
      selectedType,
      sortOrder,
      data,
      multipleSortingOptionLines,
      usePrioDeduplication: usePrioDeduplicationState,
      unchangedAdvancedDeduplicationRules,
      unchangedMultipleSortingOptionLines,
    } = this.state;

    let prioDedupChanged = false;

    // Check if advancedDeduplicationRules have changed
    if (!Util.compareEqualityOfObjectProperties(advancedDeduplicationRules, unchangedAdvancedDeduplicationRules)) {
      prioDedupChanged = true;
    }

    // Check if any state of the prioDeduplication has changed
    if (prioDeduplication) {
      if (usePrioDeduplication !== usePrioDeduplicationState) {
        prioDedupChanged = true;
      }

      // Check if criteriaFieldObjectID has changed
      if (prioDeduplication.criteriaFieldObjectID &&
        prioDeduplication.criteriaFieldObjectID !== criteriaFieldObjectID) {
        prioDedupChanged = true;
      }

      // Check if deduplicationFieldObjectID has changed
      if (prioDeduplication.deduplicationFieldObjectID &&
        prioDeduplication.deduplicationFieldObjectID !== deduplicationFieldObjectID) {
        prioDedupChanged = true;
      }

      // Check if mode has changed
      if (prioDeduplication.mode &&
        prioDeduplication.mode !== selectedMode) {
        prioDedupChanged = true;
      }

      // Check if sortOrder has changed
      if (prioDeduplication.sortOrder &&
        prioDeduplication.sortOrder !== sortOrder) {
        prioDedupChanged = true;
      }

      // Check if multipleSortingOptionLines has changed
      if (multipleSortingOptionLines && multipleSortingOptionLines.length > 0) {
        if (unchangedMultipleSortingOptionLines.length === multipleSortingOptionLines.length) {
          for (let option = 0; option < unchangedMultipleSortingOptionLines.length; option += 1) {
            if (unchangedMultipleSortingOptionLines[option].criteriaFieldObjectID !==
              multipleSortingOptionLines[option].criteriaFieldObjectID ||
              unchangedMultipleSortingOptionLines[option].sortOrder !==
              multipleSortingOptionLines[option].sortOrder) {
              prioDedupChanged = true;
            }
          }
        } else {
          prioDedupChanged = true;
        }
      }

      // Check if type has changed
      if (prioDeduplication.type &&
        prioDeduplication.type !== selectedType) {
        prioDedupChanged = true;
      }

      // Check if priorities has changed
      if (prioDeduplication.priorities && prioDeduplication.priorities.length > 0) {
        if (prioDeduplication.priorities.length === data.length) {
          for (let p = 0; p < prioDeduplication.priorities.length; p += 1) {
            if (data[p].value !== prioDeduplication.priorities[p].value) {
              prioDedupChanged = true;
            }
          }
        } else {
          prioDedupChanged = true;
        }
      }
    }

    return prioDedupChanged;
  };

  /**
   * Function adds another sorting option line
   * @returns {void}
   */
  handleAddMoreSortingOptionLine = () => {
    const { multipleSortingOptionLines, arrayOfDisabledCriteriaOptions } = this.state;

    const arr = [];

    multipleSortingOptionLines.map(el => arr.push(el.id));
    let id = 1; // used for specifying id of multiple sorting option line

    while (arr.includes(id)) {
      id += 1;
    }

    /**
     * In order to set initial value in a field look for elements which are not in array of disabled criteria
     * options and set first one found
     */
    const uniqueArray =
      this.extensionFields.filter(field => !arrayOfDisabledCriteriaOptions.some(i => i === field.ObjectID));
    const setArray = [];

    uniqueArray.map(el => setArray.push(el.ObjectID));

    const obj = {
      id,
      sortOrder: Constants.PRIO_DEDUP__SORT_ORDER__ASC, // initially lowest
      criteriaFieldObjectID: setArray[0], // initially first element from the unique array
    };

    this.setState(prevState => ({
      multipleSortingOptionLines: [...prevState.multipleSortingOptionLines, obj],
      arrayOfDisabledCriteriaOptions: [...prevState.arrayOfDisabledCriteriaOptions, setArray[0]],
    }));
  };

  /**
   * Delete more sorting option line
   * @param {string} id - id of deleted option line
   * @returns {void}
   */
  handleRemoveMoreSortingOptionLine = (id) => {
    const { multipleSortingOptionLines, arrayOfDisabledCriteriaOptions } = this.state;

    const newArray = multipleSortingOptionLines.filter(line => line.id !== id);

    this.setState({ multipleSortingOptionLines: newArray });

    const deletedLine = multipleSortingOptionLines.filter(line => line.id === id);
    const newListOfDisabledOptions =
      arrayOfDisabledCriteriaOptions.filter(option => option !== deletedLine[0].criteriaFieldObjectID);

    this.setState({ arrayOfDisabledCriteriaOptions: newListOfDisabledOptions });
  };

  /**
   * @param {object} e - event. Use e.target to get the value
   * @param {string} id - id of currently changed line
   * @param {string} type - type of the dropdown
   * @returns {void}
   */
  handleAddOptionToMoreSortingOptionLine = (e, id, type) => {
    const { multipleSortingOptionLines, arrayOfDisabledCriteriaOptions } = this.state;
    const copyArray = [...arrayOfDisabledCriteriaOptions];

    /*
     * Value is assigned initially even if we dont choose an option. When choosing,
     * we find element which will be changed
     */
    const line = multipleSortingOptionLines.find(l => l.id === id);
    // Copy all elements without the one that is being changed
    const copiedArray = copyArray.filter(el => el !== line[type]);
    // Set new value for this element

    line[type] = e.target.value;

    if (type === Constants.PRIO_DEDUP__OPTION_TYPE__CRITERIA_FIELD_OBJECT_ID) {
      this.setState({
        arrayOfDisabledCriteriaOptions: [...copiedArray, e.target.value],
      });
    } else {
      if (e.target.value === Constants.PRIO_DEDUP__SORT_ORDER__ASC ||
        e.target.value === Constants.PRIO_DEDUP__SORT_ORDER__DESC) {
        this.setState({
          arrayOfDisabledCriteriaOptions: [...arrayOfDisabledCriteriaOptions],
        });
      } else {
        this.setState({
          arrayOfDisabledCriteriaOptions: [...arrayOfDisabledCriteriaOptions, e.target.value],
        });
      }
    }
  };

  /**
   * Check if initial criteria is among options. If so then disable
   * @param {string} fieldId - field id
   * @returns {boolean} - Return true if criteria should be disable.
   */
  handleCheckWhichCriteriaWasChosen = (fieldId) => {
    const { arrayOfDisabledCriteriaOptions } = this.state;

    return arrayOfDisabledCriteriaOptions.includes(fieldId);
  };

  /**
   * Change the state of the PrioDedup component
   * @param {Object} newState - new state for the PrioDedup component
   * @returns {void}
   */
  setPrioDedupState = (newState) => {
    this.setState(newState);
  };

  render() {
    const {
      value,
      deduplicationFieldObjectID,
      criteriaFieldType,
      data,
      selectedMode,
      selectedType,
      sortOrder,
      criteriaFieldObjectID,
      criteriaFieldName,
      deduplicationFieldName,
      usePrioDeduplication,
      prioDedupStep,
      showCriteriaModal,
      multipleSortingOptionLines,
    } = this.state;

    const {
      selectedDataExtensions,
      handleSetSelectionState,
      filterBorderMouseOver,
      DEBorderMouseOver,
      getDataExtensionOrDataViewFields,
      advancedDeduplicationRules,
      handleAdvancedDedupFiltersSave,
      advancedDedupFilterSaveIndex,
      previousAdvancedDeduplicationRules,
      pickLists,
      handlePickListOptions,
      handleRemoveFilterLine,
      applyTimezoneSettingsToAllDateFields,
      handleSetTimezoneToAllDateFields,
      timezoneSettingsForAllDateFields,
    } = this.props;

    // Check for deduplication disabled status
    const isPrioDeduplicationDisabled = usePrioDeduplication.toString() ===
      Constants.PRIO_DEDUP__USE_PRIO_DEDUPLICATION__FALSE;

    // Step completion
    const firstStepCompleted = (
      selectedMode === Constants.PRIO_DEDUP__MODE__BASIC__VALUE && deduplicationFieldObjectID
    ) || (
      selectedMode === Constants.PRIO_DEDUP__MODE__ADVANCED__VALUE && advancedDeduplicationRules.length
    );

    const secondStepCompleted = criteriaFieldObjectID && (
      (
        selectedType === Constants.PRIO_DEDUP__SORTING_PRIO__DEFINE_VALUES__VALUE &&
        data.length
      ) || selectedType === Constants.PRIO_DEDUP__SORTING_PRIO__SORT_ALL_VALUES__VALUE
    );

    const firstStepClassName = classNames({
      'slds-is-completed': firstStepCompleted,
      'slds-is-active': prioDedupStep,
    });

    const firstStepBtnClassName = classNames(
      'first-step slds-progress__marker',
      {
        'slds-progress__marker_icon': firstStepCompleted,
      },
    );

    const secondStepClassName = classNames({
      'slds-is-completed': secondStepCompleted,
      'slds-is-active': !prioDedupStep,
    });

    const secondStepBtnClassName = classNames(
      'second-step slds-progress__marker',
      {
        'slds-progress__marker_icon': secondStepCompleted &&
          selectedMode === Constants.PRIO_DEDUP__MODE__BASIC__VALUE,
      },
    );

    // items for progress bar
    const progressItems = [
      {
        className: firstStepClassName,
        onClick: () => this.setState({ prioDedupStep: !prioDedupStep }),
        buttonClassName: firstStepBtnClassName,
        icon: firstStepCompleted,
        condition: true,
        disabled: isPrioDeduplicationDisabled ||
          selectedMode === Constants.PRIO_DEDUP__MODE__ADVANCED__VALUE ||
          !firstStepCompleted,
      },
      {
        className: secondStepClassName,
        onClick: () => this.setState({ prioDedupStep: !prioDedupStep }),
        buttonClassName: secondStepBtnClassName,
        icon: secondStepCompleted,
        condition: true,
        disabled: isPrioDeduplicationDisabled ||
          selectedMode === Constants.PRIO_DEDUP__MODE__ADVANCED__VALUE ||
          !firstStepCompleted,
      },
    ];

    // values for progress bar
    const values = {
      min: '0',
      max: '100',
      now: '0',
    };

    /**
     * Returns class name for dedup element
     * @param {string} className - additional className passed as a prop
     * @returns {string} - class name
     */
    const prioDedupElementClassName = className => classNames(
      className,
      'prio-dedup-element',
      {
        // eslint-disable-next-line quote-props
        'prio-dedup-grayed-out': isPrioDeduplicationDisabled || !deduplicationFieldObjectID,
      },
    );

    /**
     * Renders footer in the modal
     * @return {object} HTML for the footer
     */
    const renderFooter = () => {
      return (
        <div className="cancel-save-prio-dedup">
          {prioDedupStep ?
            (
              <Button
                buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
                label="Cancel"
                onClick={() => this.handleCancelPrioDedup()}
              >
                Cancel
              </Button>
            ) :
            (
              <Button
                id="previous-button"
                buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
                onClick={() => this.setState({ prioDedupStep: true })}
              >
                Previous
              </Button>
            )}

          <ProgressBar
            progressItems={progressItems}
            progressBarValueClassName={prioDedupStep ? 'zero-width' : ''}
            containerClassName={deduplicationFieldObjectID ? 'with-dedup-field-object-id' : ''}
            values={values}
            className="prio-dedup-progress-bar"
          />

          {prioDedupStep && selectedMode === Constants.PRIO_DEDUP__MODE__BASIC__VALUE && !isPrioDeduplicationDisabled ?
            (
              <Button
                buttonLook={Constants.BUTTON__TYPE__BRAND}
                title="Deduplication Logic"
                onClick={() => {
                  this.setState({ prioDedupStep: false });
                }}
                disabled={!firstStepCompleted}
              >
                Next
              </Button>
            ) :
            (
              <Button
                id="save-prio"
                buttonLook={Constants.BUTTON__TYPE__BRAND}
                onClick={e => this.handleSavePrioDedup(e)}
                disabled={
                  !isPrioDeduplicationDisabled &&
                  (
                    selectedMode === Constants.PRIO_DEDUP__MODE__BASIC__VALUE &&
                    !secondStepCompleted
                  ) || (
                    selectedMode === Constants.PRIO_DEDUP__MODE__ADVANCED__VALUE &&
                    !firstStepCompleted
                  )
                }
              >
                Confirm
              </Button>
            )}
        </div>
      );
    };

    return (
      <>
        <ModalTemplate
          containerClassName="prio-dedup_container-v2"
          className={classNames({ 'hide-prio-dedup-modal': showCriteriaModal })}
          headerId="modal-heading-01"
          headerTitle="Deduplication Settings"
          contentClassName="slds-p-around_medium prio-dedup-body"
          contentId="modal-content-id-3"
          cancelButtonId="cancel-prio-dedup-modal"
          cancelButtonTitle="Cancel"
          saveButtonId="save-prio-dedup-modal"
          extraFooterContent={renderFooter()}
          showCancelIcon
          handleCloseModal={() => this.handleCancelPrioDedup()}
        >
          {prioDedupStep ?
            (
              <>
                {/* On Off button */}
                <div className="prio-dedup-onoff">
                  <PrioDeduplicationStatus
                    usePrioDeduplication={usePrioDeduplication}
                    handleChangeRadioValues={this.handleChangeRadioValues}
                    handleChangePrioDedupState={this.handleChangePrioDedupState}
                  />
                </div>
                {/* Priodedup pages */}
                <div className="prio-dedup-page-1">
                  <div
                    className={
                      classNames({
                        'prio-dedup-grayed-out': isPrioDeduplicationDisabled,
                        'deduplication-field-container': true,
                      })
                    }
                  >
                    <p className="labels">
                      Unique Field
                      <abbr className="slds-required" title="required">
                        *
                      </abbr>
                    </p>

                    <DeduplicationField
                      extensionFields={this.extensionFields}
                      handleChangeDeduplicationField={
                        this.handleChangeDeduplicationField
                      }
                      deduplicationFieldObjectID={deduplicationFieldObjectID}
                      handleChangePrioDedupState={this.handleChangePrioDedupState}
                    />
                  </div>

                  <div
                    className={
                      classNames({
                        'prio-dedup-grayed-out': isPrioDeduplicationDisabled || !deduplicationFieldObjectID,
                      })
                    }
                  >
                    <PrioDedupMode
                      selectedMode={selectedMode}
                      handleChangeRadioValues={this.handleChangeRadioValues}
                    />
                  </div>

                  {/* Advanced Deduplication */}
                  {
                    selectedMode === Constants.PRIO_DEDUP__MODE__ADVANCED__VALUE ?
                      (
                      <div
                        className={
                          classNames('add-rule-btn-container', {
                            'prio-dedup-grayed-out': isPrioDeduplicationDisabled,
                          })
                        }
                      >
                        <Button
                          buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
                          className="add-rule-btn"
                          onClick={() => {
                            const actualIndex = advancedDeduplicationRules.length;

                            handleSetSelectionState({
                              previousAdvancedDeduplicationRules: JSON.parse(
                                JSON.stringify(advancedDeduplicationRules),
                              ),
                              advancedDedupFilterSaveIndex: actualIndex,
                            });

                            this.setState({ showCriteriaModal: true });
                          }}
                        >
                          <svg
                            className="slds-button__icon slds-icon_small plus-icon"
                            aria-hidden="true"
                          >
                            <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#add" />
                          </svg>
                          Add Rule
                        </Button>
                        <AdvancedPrioFiltersContainer
                          advancedDeduplicationRules={advancedDeduplicationRules}
                          openCriteriaModal={this.openCriteriaModal}
                          handleSetSelectionState={handleSetSelectionState}
                          pickLists={pickLists}
                        />
                      </div>
                      ) :
                      null
                  }
                </div>
              </>
            ) :
            (
              <div className="prio-dedup-page-2">
                <div className="prioritization-logic-container">
                  <legend className="slds-form-element__legend slds-form-element__label">
                    Prioritization Logic
                  </legend>
                  <div className="prioritization-logic">
                    {
                      selectedMode === Constants.PRIO_DEDUP__MODE__BASIC__VALUE ?
                        (
                          <div
                            className={prioDedupElementClassName()}
                          >
                            <CriteriaField
                              deduplicationFieldName={deduplicationFieldName}
                              extensionFields={this.extensionFields}
                              criteriaFieldObjectID={criteriaFieldObjectID}
                              handleChangeCriteriaField={this.handleChangeCriteriaField}
                              handleChangePrioDedupState={this.handleChangePrioDedupState}
                            />
                          </div>
                        ) :
                        null
                    }
                    <PrioType
                      selectedType={selectedType}
                      handleChangeRadioValues={this.handleChangeRadioValues}
                      deduplicationFieldName={deduplicationFieldName}
                      criteriaFieldName={criteriaFieldName}
                      criteriaFieldType={criteriaFieldType}
                    />
                    {selectedType === Constants.PRIO_DEDUP__SORTING_PRIO__DEFINE_VALUES__VALUE ?
                      (
                        <div
                          className={prioDedupElementClassName('define-sort-box')}
                        >
                          <PrioValueInput
                            value={value}
                            handleChangeValue={this.handleChangeValue}
                            handleAddValueToArray={this.handleAddValueToArray}
                            criteriaFieldType={criteriaFieldType}
                          />
                          <PrioValueContainer
                            data={data}
                            handleRemoveValue={this.handleRemoveValue}
                            criteriaFieldName={criteriaFieldName}
                            setPrioDedupState={this.setPrioDedupState}
                          />
                        </div>
                      ) :
                      (
                        <div
                          className={prioDedupElementClassName('sort-way-box')}
                        >
                          <fieldset className="slds-form-element mb-05rem">
                            <legend className="slds-form-element__label labels mb-05rem">
                              Value For
                              {' '}
                              {Util.abbreviate(criteriaFieldName, 30)}
                              <abbr className="slds-required" title="required">* </abbr>
                            </legend>
                            <div className="slds-form-element__control mb-05rem">
                              <PrioDeduplicationSortMode
                                handleChangeRadioValues={this.handleChangeRadioValues}
                                sortOrder={sortOrder}
                              />
                              <p className="explanation-text mb-05rem">
                                Give priority to the record with the
                                {sortOrder === Constants.PRIO_DEDUP__SORT_ORDER__ASC ?
                                  (
                                    <span className="bold-text">lowest</span>
                                  ) :
                                  (
                                    <span className="bold-text">highest</span>
                                  )}
                                {' '}
                                value of
                                <span>
                                  {Util.abbreviate(criteriaFieldName, 30)}
                                </span>
                                {' '}
                                for each
                                <span>
                                  {Util.abbreviate(deduplicationFieldName, 30)}
                                </span>
                                .
                              </p>
                            </div>
                          </fieldset>
                          <MultipleCriteriaChoice
                            handleAddMoreSortingOptionLine={this.handleAddMoreSortingOptionLine}
                            handleRemoveMoreSortingOptionLine={this.handleRemoveMoreSortingOptionLine}
                            // eslint-disable-next-line max-len
                            handleAddOptionToMoreSortingOptionLine={this.handleAddOptionToMoreSortingOptionLine}
                            handleCheckWhichCriteriaWasChosen={this.handleCheckWhichCriteriaWasChosen}
                            multipleSortingOptionLines={multipleSortingOptionLines}
                            extensionFields={this.extensionFields}
                            criteriaFieldName={criteriaFieldName || ''}
                            sortOrder={sortOrder}
                          />
                        </div>
                      )}
                  </div>
                </div>
              </div>
            )}
        </ModalTemplate>
        {
          showCriteriaModal ?
            (
              <AdvancedDedupModal
                show={showCriteriaModal}
                selectedDataExtensions={selectedDataExtensions}
                handleSetSelectionState={handleSetSelectionState}
                advancedDeduplicationRules={advancedDeduplicationRules}
                closeCriteriaModal={this.closeCriteriaModal}
                filterBorderMouseOver={filterBorderMouseOver}
                DEBorderMouseOver={DEBorderMouseOver}
                getDataExtensionOrDataViewFields={getDataExtensionOrDataViewFields}
                handleAdvancedDedupFiltersSave={handleAdvancedDedupFiltersSave}
                advancedDedupFilterSaveIndex={advancedDedupFilterSaveIndex}
                selectedMode={selectedMode}
                previousAdvancedDeduplicationRules={previousAdvancedDeduplicationRules}
                pickLists={pickLists}
                handlePickListOptions={handlePickListOptions}
                handleRemoveFilterLine={handleRemoveFilterLine}
                applyTimezoneSettingsToAllDateFields={applyTimezoneSettingsToAllDateFields}
                timezoneSettingsForAllDateFields={timezoneSettingsForAllDateFields}
                handleSetTimezoneToAllDateFields={handleSetTimezoneToAllDateFields}
              />
            ) :
            null
        }
      </>
    );
  }
}

PrioDedup.propTypes = {
  /**
   * It keeps the fields of an existing target data extension
   * It will be passed from Selection.js
   */
  targetDataExtensionFields: PropTypes.instanceOf(Array).isRequired,
  /**
   * It helps to navigate between TargetDefinition and PrioDedup screens
   */
  switchToTargetDE: PropTypes.func.isRequired,
  /**
   * It helps to set the Selection`s state
   * It will be passed from Selection.js
   */
  handleSetSelectionState: PropTypes.func.isRequired,
  /**
   * Keep the required data for prioDedup
   */
  prioDeduplication: PropTypes.instanceOf(Object),
  /**
   * It keeps if the deduplication settings will be applied
   */
  usePrioDeduplication: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /**
   * selected data extensions
   */
  selectedDataExtensions: PropTypes.instanceOf(Array).isRequired,
  /**
   * Filters Rules for the advanced deduplication
   */
  advancedDeduplicationRules: PropTypes.instanceOf(Array).isRequired,
  /**
   * saves the filters for the advanced deduplication
   */
  handleAdvancedDedupFiltersSave: PropTypes.func.isRequired,
  /**
   * gets the index for the actual rule being worked on
   */
  advancedDedupFilterSaveIndex: PropTypes.number,
  /**
   * holds the state of the selected filter in case the cancel button is working
   */
  previousAdvancedDeduplicationRules: PropTypes.instanceOf(Array).isRequired,
  /**
   * Keeps track whether Available DE are dragged
   */
  DEBorderMouseOver: PropTypes.bool.isRequired,
  /**
   * Keeps track whether Available Fields are dragged
   */
  filterBorderMouseOver: PropTypes.bool.isRequired,
  /**
   * It helps to retrieve fields of a data extension or data view from SFMC
   * It will be passed from Selection.js
   */
  getDataExtensionOrDataViewFields: PropTypes.func.isRequired,
  /**
   * Responsible for adding/deleting fields Object IDs when searching picklist for the options
   * it will be passed from Selection.js
   */
  handlePickListOptions: PropTypes.func.isRequired,
  /**
   * Keeps searched picklist
   * It will be passed from Selection.js
   */
  pickLists: PropTypes.instanceOf(Array).isRequired,
  /**
   * It Removes a given filterLine
   * It is passed from Selection.js
   */
  handleRemoveFilterLine: PropTypes.func,
  /**
   * Indicates whether timezone settings are applied to all date filters
   */
  applyTimezoneSettingsToAllDateFields: PropTypes.bool,
  /**
   * Handles the setting of timezone settings to all date filters
   */
  handleSetTimezoneToAllDateFields: PropTypes.func,
  /**
   * An object containing timezone details
   */
  timezoneSettingsForAllDateFields: PropTypes.instanceOf(Object),
};
