import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import timeUtil from '../../../../../utils/time/timeUtil';

const SelectionRunLogsModalLine = ({
  selectionRunLog,
  handleSelectRunLog,
  userDateTimeFormat,
  showTriggerMode,
  showSourceLabel,
  disableRunDetailsForRunningSelection,
}) => {
  /**
   * className for run log button
   */
  const runLogButtonClassName = classNames(
    'slds-button__icon run-logs-details',
    {
      'disabled-log-btn': disableRunDetailsForRunningSelection(selectionRunLog),
    },
  );

  const showStatusIcon = () => {
    // in case of error show error icon
    if (selectionRunLog?.success === false) {
      return (
        <svg
          className="slds-icon slds-icon-text-default slds-icon_x-small status-error"
          aria-hidden="true"
        >
          <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#error" />
        </svg>
      );
    }

    if(disableRunDetailsForRunningSelection(selectionRunLog)) {
      // if selection is running show processing icon
      return (
        <svg
        className="slds-icon slds-icon-text-default slds-icon_x-small status-queued running-query-icon"
        aria-hidden="true"
        >
        <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#automate" />
        </svg>
      );
    }

    // in other case show check icon
    return (
      <svg className="slds-button__icon status-success" aria-hidden="true">
        <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#check" />
      </svg>
    );
  };

  return (
    <ul className="selections_run_log_list">
      <li className="start-date-time-for-selection-run-log">
        {timeUtil.formatDate(selectionRunLog.startDateTime, userDateTimeFormat)}
      </li>
      <li>
        {showTriggerMode(selectionRunLog)}
      </li>
      <li>
        {showSourceLabel(selectionRunLog)}
      </li>
      <li>
        {showStatusIcon()}
      </li>
      <li>
        {(selectionRunLog.error || selectionRunLog.query || selectionRunLog.userName ||
            selectionRunLog.dedupNumberOfRecords) ?
          (
            <svg
              className={runLogButtonClassName}
              aria-hidden="true"
              onClick={() => handleSelectRunLog(selectionRunLog)}
            >
              <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#pop_in" />
            </svg>
          ) :
          null}
      </li>
    </ul>
  );
};

SelectionRunLogsModalLine.propTypes = {
  /**
   * SelectionRunLog object
   */
  selectionRunLog: PropTypes.instanceOf(Object).isRequired,
  /**
   * Function to handle the selection of the run log
   */
  handleSelectRunLog: PropTypes.func.isRequired,
  /**
   * Time format read from user's cookies
   */
  userDateTimeFormat: PropTypes.string.isRequired,
  /**
   * Function that returns trigger mode label for run log
   */
  showTriggerMode: PropTypes.func.isRequired,
  /**
   * Function that returns source label for run log
   */
  showSourceLabel: PropTypes.func.isRequired,
  /**
   * Function that helps to disable run details button when selection is running
   */
  disableRunDetailsForRunningSelection: PropTypes.func.isRequired,
};

export default SelectionRunLogsModalLine;
