import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import Button from '../Button/Button';

const Toast = ({
  handleOnClick, notificationText, customClassName,
}) => (
  <div
    className={`slds-notify slds-notify_toast slds-theme_success ${customClassName}`}
    role="status"
  >
    <span className="slds-assistive-text">success</span>
    <span
      className="slds-icon_container slds-icon-utility-success
              slds-m-right_small slds-no-flex slds-align-top"
      title="Description of icon when needed"
    >
      <svg className="slds-icon slds-icon_small" aria-hidden="true">
        <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#success" />
      </svg>
    </span>
    <div className="slds-notify__content">
      <h2 className="slds-text-heading_small ">{notificationText}</h2>
    </div>
    {handleOnClick ?
      (
        <div className="slds-notify__close">
          <Button
            buttonIcon
            className="slds-button_icon-inverse"
            title="Close"
            onClick={handleOnClick}
          >
            <svg className="slds-button__icon slds-button__icon_large" aria-hidden="true">
              <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#close" />
            </svg>
            <span className="slds-assistive-text">Close</span>
          </Button>
        </div>
      ) :
      null}
  </div>
);

Toast.propTypes = {
  /**
   * Function that handles the close notification button
   */
  handleOnClick: PropTypes.func,
  /**
   * Notification text
   */
  notificationText: PropTypes.string.isRequired,
  /**
   * Determines the custom className to make Toast better positioned
   */
  customClassName: PropTypes.string,
};

export default Toast;
