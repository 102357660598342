import ValidationConstants from './ValidationConstants';

/* eslint-disable no-continue */
/* eslint-disable no-plusplus */

/**
 * This function takes the formula string as an input and tokenizes it
 * @param {string} input - The formula entered by the user
 * @param {bool} coloring - Determines if we are using this function to colour formula or not
 * @returns {object} - An object containing array of tokens and status
 */
const tokenize = (input, coloring) => {
  // A `current` variable for tracking our position in the code like a cursor.
  let current = 0;

  /**
   * Handles the tokenization of a string
   * @param {string} quoteType - Is it a single or a double quote ?
   * @returns {object} - An object containing a token and its type
   */
  const handleString = (quoteType) => {
    // Keep a `value` variable for building up our string token.
    let value = '';

    // Set token type to string
    let tokenType = ValidationConstants.TOKEN_TYPE__STRING;

    // Skip the opening quote in the token.
    let char = input[++current];

    /*
     * Build the string by iterating through each character until another
     * quote is come across. Take into consideration how fields are represented,
     * i.e "DataExtensionAlias"."FieldName"
     * '.'
     * 'a period.', '.'
     */
    while (char) {
      // If character is a quote
      if (char === quoteType) {
        // Set tokenType to field if next or previous character is a point and value is not dot(.)
        if (value !== '.' &&
          ((input[current + 1] === '.' && input[current + 2] === quoteType) ||
            (input[current - 1] === '.' && input[current - 2] === quoteType)
          )
        ) {
          tokenType = ValidationConstants.TOKEN_TYPE__FIELD;
          value += char;
          char = input[++current];
        } else {
          // If the next token is a quote (''), that means we're escaping a quote
          if (input[current + 1] === quoteType) {
            // Add quote to string
            value += char;

            // Increment the counter(skip the other quote)
            current += 2;
            char = input[current];
          } else break; // The last quote, so break out of the loop
        }
      } else {
        value += char;
        char = input[++current];
      }
    }

    // Skip the closing quote.
    char = input[++current];

    return { type: tokenType, value };
  };

  try {
    // Declare a `tokens` array for pushing our tokens to.
    const tokens = [];

    /*
     * We start by creating a `while` loop where we are setting up our `current`
     * variable to be incremented as much as we want `inside` the loop.
     */
    /*
     * We do this because we may want to increment `current` many times within a
     * single loop because our tokens can be any length.
     */
    while (current < input.length) {
      // We're also going to store the `current` character in the `input`.
      let char = input[current];

      /*
       * The first thing we want to check for is an open parenthesis.
       * We check to see if we have an open parenthesis:
       */
      if (char === '(') {
        /*
         * If we do, we push a new token with the type `open-parenthesis` and set the value
         * to an open parenthesis.
         */
        tokens.push({
          type: ValidationConstants.TOKEN_TYPE__OPEN_PAREN,
          value: char,
        });

        // Then we increment `current`
        current++;

        // And we `continue` onto the next cycle of the loop.
        continue;
      }

      /*
       * Next we're going to check for a closing parenthesis. We do the same exact
       * thing as before: Check for a closing parenthesis, add a new token,
       * increment `current`, and `continue`.
       */
      if (char === ')') {
        tokens.push({
          type: ValidationConstants.TOKEN_TYPE__CLOSED_PAREN,
          value: char,
        });
        current++;
        continue;
      }

      // Handle comma (function argument delimiter)
      if (char === ',') {
        tokens.push({ type: ValidationConstants.TOKEN_TYPE__DELIMITER, value: char });
        current++;
        continue;
      }

      /*
       * Next we're going to check for operators.
       */
      // eslint-disable-next-line no-useless-escape
      const OPERATORS = /[\-\+/*]/;

      if (OPERATORS.test(char)) {
        tokens.push({
          type: ValidationConstants.TOKEN_TYPE__OPERATOR,
          value: char,
        });

        // Increment `current`, and `continue`.
        current++;
        continue;
      }

      // We're going to check for comparison operators
      const COMPARISON_OPERATORS = />|<|=|!/;

      if (COMPARISON_OPERATORS.test(char)) {
        // Keep a `value` variable for building up our comparison operator.
        let value = '';

        while (char && COMPARISON_OPERATORS.test(char)) {
          value += char;
          char = input[++current];
        }

        // After that we push our token to the `tokens` array.
        tokens.push({ type: ValidationConstants.TOKEN_TYPE__COMPARISON_OPERATOR, value });

        // And we continue on.
        continue;
      }

      /*
       * Moving on, we're now going to check for whitespace. This is interesting
       * because we care that whitespace exists to separate characters, but it
       * isn't actually important for us to store as a token. We would only throw
       * it out later.
       * So here we're just going to test for existence and if it does exist we're
       * going to just `continue` on.
       */
      const WHITESPACE = /\s/;

      if (WHITESPACE.test(char)) {
        // if we use this function to colour formula then we should store 'enter (↵)' or space
        if (coloring) {
          /*
           * This if statement is for the case when textarea is empty and we press the enter.
           * So the 'current' variable will be zero and the first char will be enter, second char will be enter and
           * the third char can be either enter or some other value.
           * In that case push 'new line' twice
           */
          if (current === 0 && char.match(/[\n\r]/g) && input[current + 1] && input[current + 1].match(/[\n\r]/g) &&
          input[current + 2]) {
            /*
             * The first 'new line' is for the first row in the textarea (first row will be 'div' -> that's how
             * textarea handles the new lines on the beginning)
             */
            tokens.push({
              type: ValidationConstants.TOKEN_TYPE__NEW_LINE,
              value: char,
            });

            // the second new line is for the second char which is enter
            tokens.push({
              type: ValidationConstants.TOKEN_TYPE__NEW_LINE,
              value: char,
            });

            /*
             * in total we will have three 'new line' characters, so skip two 'new line' characters because
             * they represent one 'new line' and the third 'new line' character will be pushed into token array
             */
            current++;
            current++;
            // new line is represented as two "new line" chars (↵)
          } else if (char.match(/[\n\r]/g) && input[current + 1] && input[current + 1].match(/[\n\r]/g)) {
            tokens.push({
              type: ValidationConstants.TOKEN_TYPE__NEW_LINE,
              value: char,
            });

            /*
             * After the current char is pushed in the token array,
             * by doing "current++" we move to the next char. Next char is also a "new line" char (↵).
             * So we want to skip next character because we don't want to push
             * two times a "new line" character. That's why the second "current++" is there
             */
            current++;
            current++;
            // new line can be followed by some string char
          } else if (char.match(/[\n\r]/g) && input[current + 1]) {
            tokens.push({
              type: ValidationConstants.TOKEN_TYPE__NEW_LINE,
              value: char,
            });

            current++;
            // check if the current char is not a new line (↵) (then it is space) and store it
          } else if (input[current] && !input[current].match(/[\n\r]/g)) {
            tokens.push({
              type: ValidationConstants.TOKEN_TYPE__SPACE,
              value: char,
            });

            current++;
          } else {
            current++;
          }
        } else {
          current++;
        }

        continue;
      }

      /*
       * The next type of token is a number and decimal. This is different than what we have
       * seen before because a number could be any number of characters and we
       * want to capture the entire sequence of characters as one token.
       * So we start this off when we encounter the first number in a sequence.
       */
      const NUMBERS = /[0-9]/;

      if (NUMBERS.test(char)) {
        /*
         * We're going to create a `value` string that we are going to push
         * characters to.
         */
        let value = '';

        // Set tokenType as number
        let tokenType = ValidationConstants.TOKEN_TYPE__NUMBER;

        /*
         * Then we're going to loop through each character in the sequence until
         * we encounter a character that is not a number or a point(for decimals),
         * pushing each character that is a number to our `value` and increment
         * `current` as we go.
         */
        while (NUMBERS.test(char) || char === '.') {
          // Set tokenType as decimal if we encounter a point
          if (char === '.') tokenType = ValidationConstants.TOKEN_TYPE__DECIMAL;
          value += char;
          char = input[++current];
        }

        // After that we push our token to the `tokens` array.
        tokens.push({ type: tokenType, value });

        // And we continue on.
        continue;
      }

      /*
       * We'll also add support for strings in our language which will be any
       * text surrounded by single or double quotes (' or ").
       * We'll start by checking for the opening quote:
       */
      if (char === '"' || char === '\'') {
        // if are using this function for coloring then store double and single quotes
        if (coloring) {
          tokens.push({
            type: ValidationConstants.TOKEN_TYPE__STRING,
            value: char,
          });

          current++;
          continue;
        } else {
          // Get the token
          const token = handleString(char);

          // Add token to the `tokens` array.
          tokens.push(token);
          continue;
        }
      }

      /*
       * The last type of token will be a function or keyword token. This is a sequence of
       * letters instead of numbers, that are the names of functions or keywords.
       *  E.g:
       *   UPPER("John")
       *   ^^^^^
       */
      const LETTERS = /[a-z]/i;

      if (LETTERS.test(char)) {
        let value = '';
        /*
         * Again we're just going to loop through all the letters pushing them to
         * a value.
         */

        while (char && (LETTERS.test(char) || char === '_' || NUMBERS.test(char))) {
          value += char;
          char = input[++current];
        }

        // Set tokenType to function
        let tokenType = ValidationConstants.TOKEN_TYPE__FUNCTION;

        // Set tokenType to keyword if the next character is not an open bracket and value is not 'like'
        if (char !== '(' && value.toLowerCase() !== ValidationConstants.KEYWORD__LIKE.toLowerCase()) {
          tokenType = ValidationConstants.TOKEN_TYPE__KEYWORD;
        } else if (value.toLowerCase() === ValidationConstants.KEYWORD__LIKE.toLowerCase()) {
          // Set tokenType to keyword if value is 'like'
          tokenType = ValidationConstants.TOKEN_TYPE__COMPARISON_OPERATOR;
        }

        // Add token to tokens array
        tokens.push({ type: tokenType, value });

        continue;
      }

      // if we are using this function for coloring then store any characters which are not stored before
      if (coloring) {
        tokens.push({
          type: ValidationConstants.TOKEN_TYPE__CHAR,
          value: char,
        });

        current++;
        continue;
      }

      /*
       * Finally if we have not matched a character by now, we're going to return
       * an error object.
       */
      return { success: false, error: `unknown character ${char}` };
    }

    // Then at the end of our `tokenizer` we simply return the tokens array.
    return { success: true, tokens };
  } catch (e) {
    // Return an error object
    return { success: false, error: 'an unknown error occurred' };
  }
};

export default tokenize;
