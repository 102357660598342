import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import TimezoneList from '../../../ScheduleSelectionModal/TimezoneList.json';
import Select from '../../../../shared/Select/Select';

const TimezoneDropdown = ({
  handleOnChange, defaultValue, name, disabled = false, timezoneName, timezoneClassName,
}) => {
  // sort timezone list by current offset
  const sortedTimezoneByUtcOffsetList = TimezoneList.sort((a, b) => {
    // Get timezone offsets
    const offsetA = moment().tz(a.utc[0]).utcOffset();
    const offsetB = moment().tz(b.utc[0]).utcOffset();

    // Sort by hours
    return offsetA - offsetB;
  });

  /**
   * Renders the sorted timezone list
   * @param {boolean} byValue - defines whether the value of the option is to be the value from the list
   * @returns {Array.<Object>} - An array of HTML objects;
   */
  const renderSortedTimeZoneList = byValue => sortedTimezoneByUtcOffsetList.map(tz => (
    {
      value: byValue ? tz.value : tz.utc[0],
      key: byValue ? tz.value : tz.text,
      label: `(UTC${moment().tz(tz.utc[0]).format('Z')}) ${tz.value}`,
    }
  ));

  return (
    <div className={timezoneClassName || ''}>
      { /* timezone name is used in schedule selection modal */}
      {timezoneName ?
        (
          <Select
            name={name || 'timezone'}
            value={timezoneName || ''}
            className="slds-select timezone-name"
            onChange={handleOnChange}
            selected={!!timezoneName}
            disabled={disabled}
            options={renderSortedTimeZoneList(false)}
            />
        ) :
        (
            <Select
              containerClassName="timezone-dropdown"
              onChange={handleOnChange}
              value={defaultValue}
              name={name}
              disabled={disabled}
              options={renderSortedTimeZoneList(true)}
            />
        )}
    </div>
  );
};

TimezoneDropdown.propTypes = {
  /**
   * Function to handle value change
   */
  handleOnChange: PropTypes.func.isRequired,
  /**
   * Name of the select element
   */
  name: PropTypes.string,
  /**
   * Determines if the dropdown should be disabled
   */
  disabled: PropTypes.bool,
  /**
   * Determines which option should be selected by default, if none is specified then the first one is selected
   */
  defaultValue: PropTypes.string,
  /*
   * string with timezone name
   */
  timezoneName: PropTypes.string,
  /*
   * class name for the timezone dropdown container
   */
  timezoneClassName: PropTypes.string,
};
export default TimezoneDropdown;
