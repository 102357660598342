
import React from 'react';
import PropTypes from 'prop-types';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import classNames from 'classnames';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';

import mapStateToProps from '../../../../../mapStateToProps';
import Constants from '../../../../../constants/constants';
import Button from '../../../../shared/Button/Button';
import InputTypeSelect from '../InputTypeSelect';
import Select from '../../../../shared/Select/Select';
import timeUtil from '../../../../../utils/time/timeUtil';
import Input from '../../../../shared/Input/Input';
import filtersUtil from '../../../../../utils/filters/filtersUtil';
import DropdownOptions from '../../../../../constants/dropdownOptions';

const SortableCriteriaItem = ({
  criteria, index, editCriteria, toggleInputType, thenValues, fieldTypeDynamicValue,
  selectedDataExtensions, dynamicCriteriaValues, showInvalidEmailMessage,
  renderDeleteCriteriaDynamicModal, renderNullCheckbox, isDefaultValueNull, nullValues,
  showNoAvailableFieldsError, getSelectedDEFields, noAvailableFieldsRef,
  handleSelectedDEChange, handleSelectedFieldChange, handleChange,
  handlePeriodDateChange, handleBlur, renderInvalidEmailAddressMessage, disabled, userInfo,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: criteria });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const userLocale = timeUtil.getUserLocale(userInfo);

  return (
    <div className="dynamic-line" ref={setNodeRef} style={style}>
    <div className="dynamic-line-body">
      <div className="when-container">
        <div><b>When:&nbsp;</b></div>
        <strong
          className="flex-when"
          // eslint-disable-next-line react/no-array-index-key
          key={index}
        >
          <span className="dynamic-filter-text">
            {criteria}
          </span>
          <Button
            buttonIcon
            className="edit-criteria-btn"
            title="Edit Criteria"
            id="edit-info-icon"
            onClick={() => editCriteria(index)}
          >
            <svg className="slds-button__icon" aria-hidden="true">
              <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#edit" />
            </svg>
          </Button>
        </strong>
      </div>
      {fieldTypeDynamicValue === Constants.FILTERLINE__FIELDTYPE__BOOLEAN ?
        (
          <div
            id="field-value-boolean"
            className="field field-value-dynamic"
          >
            <div><b>Then:</b></div>
            <div className="compare-fields-filter-toggle">
              <Button
                buttonLook={Constants.BUTTON__TYPE__BRAND}
                className="input-type-toggle-button"
                title="Pick available fields"
                onClick={() => toggleInputType(index)}
                disabled={disabled}
              >
                <i className={classNames('fas', {
                  'fa-font': thenValues?.[index],
                  'fa-table': !thenValues?.[index],
                })} />
              </Button>
            </div>
            {
              thenValues.length && thenValues[index] ?
                (
                  <InputTypeSelect
                    selectedDataExtensions={selectedDataExtensions}
                    dataExtensionAlias={
                      dynamicCriteriaValues[index]?.then?.dataExtensionAlias
                    }
                    fieldObjectId={
                      dynamicCriteriaValues[index]?.then?.fieldObjectId
                    }
                    isDefaultValueNull={isDefaultValueNull}
                    renderNullCheckbox={renderNullCheckbox}
                    index={index}
                    showNoAvailableFieldsError={showNoAvailableFieldsError}
                    fields={getSelectedDEFields(
                      dynamicCriteriaValues[index] &&
                      dynamicCriteriaValues[index].then &&
                      dynamicCriteriaValues[index].then.dataExtensionObjectId,
                      fieldTypeDynamicValue,
                    )}
                    noAvailableFieldsRef={noAvailableFieldsRef}
                    handleSelectedDEChange={handleSelectedDEChange}
                    handleSelectedFieldChange={handleSelectedFieldChange}
                    nullValues={nullValues}
                    disabled={disabled}
                  />
                ) :
                (
                  <div className="dynamic-values-select">
                    <Select
                      id={index}
                      containerClassName="boolean-filter-value then-select-value"
                      value={dynamicCriteriaValues[index] &&
                        dynamicCriteriaValues[index].then !== null ?
                        dynamicCriteriaValues[index].then :
                        ''}
                      onChange={e => handleChange(e, index)}
                      disabled={nullValues[index] || disabled}
                      options={DropdownOptions.BOOLEAN__OPTIONS}
                    />
                    <div>
                      {renderNullCheckbox(index, nullValues)}
                    </div>
                  </div>
                )
            }
            {renderDeleteCriteriaDynamicModal(index)}
          </div>
        ) :
        (
          <div className="field field-value-dynamic">
            <div><b>Then:</b></div>
            <div className="compare-fields-filter-toggle">
              <Button
                buttonLook={Constants.BUTTON__TYPE__BRAND}
                className="input-type-toggle-button"
                title="Pick available fields"
                onClick={() => toggleInputType(index)}
                disabled={disabled}
              >
                <i className={classNames('fas', {
                  'fa-font': thenValues?.[index],
                  'fa-table': !thenValues?.[index],
                })} />
              </Button>
            </div>
            {
              thenValues.length && thenValues[index] ?
                (
                  <InputTypeSelect
                    selectedDataExtensions={selectedDataExtensions}
                    dataExtensionAlias={
                      dynamicCriteriaValues[index]?.then?.dataExtensionAlias
                    }
                    fieldObjectId={
                      dynamicCriteriaValues[index]?.then?.fieldObjectId
                    }
                    isDefaultValueNull={isDefaultValueNull}
                    renderNullCheckbox={renderNullCheckbox}
                    index={index}
                    showNoAvailableFieldsError={showNoAvailableFieldsError}
                    fields={getSelectedDEFields(
                      dynamicCriteriaValues[index]?.then?.dataExtensionObjectId,
                      fieldTypeDynamicValue,
                    )}
                    noAvailableFieldsRef={noAvailableFieldsRef}
                    handleSelectedDEChange={handleSelectedDEChange}
                    handleSelectedFieldChange={handleSelectedFieldChange}
                    nullValues={nullValues}
                    disabled={disabled}
                  />
                ) :
                (
                  <div className="dynamic-value-input-container">
                    {fieldTypeDynamicValue === Constants.FILTERLINE__FIELDTYPE__DATE ?
                      (
                        <DatePicker
                          id={index}
                          fixedHeight
                          popperPlacement="top-start"
                          className={classNames(
                            `form-control filter-value
                            dynamic-value-date then-clause-date`,
                            { 'disabled-date-picker': nullValues[index] },
                          )}
                          name="customValueDate"
                          type="text"
                          dateFormat={timeUtil.getDatePickerDateTimeFormat(userLocale)}
                          timeFormat="HH:mm"
                          showTimeInput
                          selected={timeUtil.formatDateInDatePicker(
                            dynamicCriteriaValues[index]?.then ||
                            new Date().setHours(0, 0),
                          )}
                          onChange={e => handlePeriodDateChange(e, index)}
                          data-placement="top-start"
                          disabled={nullValues[index] || disabled}
                        />
                      ) :
                      (
                        <Input
                          name="value"
                          type={
                            fieldTypeDynamicValue ===
                              Constants.FILTERLINE__FIELDTYPE__DECIMAL ?
                              Constants.FILTERLINE__FIELDTYPE__NUMBER :
                              fieldTypeDynamicValue
                          }
                          pattern={
                            fieldTypeDynamicValue ===
                              Constants.FILTERLINE__FIELDTYPE__NUMBER ?
                              '[0-9]*' :
                              null
                          }
                          inputMode={
                            fieldTypeDynamicValue ===
                              Constants.FILTERLINE__FIELDTYPE__NUMBER ?
                              'numeric' :
                              null
                          }
                          id={index}
                          min="1"
                          max="2550"
                          className="dynamic-values-input then-clause"
                          value={dynamicCriteriaValues[index] &&
                            dynamicCriteriaValues[index].then ?
                            dynamicCriteriaValues[index].then :
                            ''}
                          disabled={nullValues[index] || disabled}
                          onChange={(e) => {
                            if (fieldTypeDynamicValue ===
                              Constants.FILTERLINE__FIELDTYPE__NUMBER
                            ) {
                              if (!/^[0-9]*$/.test(e.target.value)) {
                                return;
                              }
                            }
                            handleChange(e, index);
                          }}
                          onBlur={(e) => {
                            if (filtersUtil.isNumberOrDecimal(fieldTypeDynamicValue)) {
                              handleBlur(e, index);
                            }
                          }}
                        />
                      )}
                    {showInvalidEmailMessage.includes(index) && (
                      renderInvalidEmailAddressMessage()
                    )}
                    {renderNullCheckbox(index, nullValues)}
                  </div>
                )
            }
            {renderDeleteCriteriaDynamicModal(index)}
          </div>
        )}
    </div>
      <svg
        id="move-criteria"
        className="slds-button__icon drag-and-drop"
        aria-hidden="true"
        {...listeners}
        {...attributes}>
        <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#drag_and_drop" />
        <title>Drag this icon up and down to sort the criteria list</title>
      </svg>
    </div>
  );
};

SortableCriteriaItem.propTypes = {
  /**
   * criteria text
   */
  criteria: PropTypes.string,
  /**
   * criteria index
   */
  index: PropTypes.number,
  /**
   * a function to edit criteria
   */
  editCriteria: PropTypes.func,
  /**
   * Toggles the input type of a then or default criteria
   */
  toggleInputType: PropTypes.func,
  /**
   * then values
   */
  thenValues: PropTypes.instanceOf(Array),
  /**
   * Field type (Default: Text)
   */
  fieldTypeDynamicValue: PropTypes.string,
  /**
   * It keeps the selected data extensions for Selection.js
   * selected data extensions are stored as collections in database
   * It will be passed from Selection.js
   */
  selectedDataExtensions: PropTypes.instanceOf(Array),
  /**
   * When and then clauses list
   */
  dynamicCriteriaValues: PropTypes.instanceOf(Array),
  /**
   * invalid email messages array
   */
  showInvalidEmailMessage: PropTypes.instanceOf(Array),
  /**
   * Renders the delete criteria button for the dynamic modal
   */
  renderDeleteCriteriaDynamicModal: PropTypes.func,
  /**
   * Renders the null checkbox inside dynamic
   */
  renderNullCheckbox: PropTypes.func,
  /**
   * Determines whether default value is null or not
   */
  isDefaultValueNull: PropTypes.bool,
  /**
   * The array that keeps track of which checkbox is checked
   */
  nullValues: PropTypes.instanceOf(Array),
  /**
   * showNoAvailableFieldsError
   */
  showNoAvailableFieldsError: PropTypes.bool,
  /**
   * a function that helps to get fields from picked selected DE
   */
  getSelectedDEFields: PropTypes.func,
  /**
   * Ref for the noAvailableFields error
   */
  noAvailableFieldsRef: PropTypes.instanceOf(Object),
  /**
   * a function that helps with changing selected DE
   */
  handleSelectedDEChange: PropTypes.func,
  /**
   * a function that helps with changing default field
   */
  handleSelectedFieldChange: PropTypes.func,
  /**
   * a function that helps to custom value field name, Then clause (dynamic type) or value (fixed type)
   */
  handleChange: PropTypes.func,
  /**
   * handle period date change
   */
  handlePeriodDateChange: PropTypes.func,
  /**
   * a function that helps to set Then clause (dynamic type) or value (fixed type) on blur
   */
  handleBlur: PropTypes.func,
  /**
   * a function for rendering an invalid email error message
   */
  renderInvalidEmailAddressMessage: PropTypes.func,
  /**
   * disable property for sortable container
   */
  disabled: PropTypes.bool,
  /**
   * User info from cookie
   */
  userInfo: PropTypes.object,
};

export default connect(mapStateToProps(['userInfo']), null, null, { pure: false })(SortableCriteriaItem);
