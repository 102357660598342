import React, { PureComponent } from 'react';
import { connect, Provider } from 'react-redux';
import 'sweetalert2/src/sweetalert2.scss';
import PropTypes from 'prop-types';
import axios from 'axios';
import classNames from 'classnames';
import qs from 'qs';
import { toast, ToastContainer } from 'react-toastify';

import mapStateToProps from '../../mapStateToProps';
import './styles.scss';
import SelectionList from '../../components/Overview/SelectionList/SelectionList';
import Navbar from '../../components/Overview/Navbar/Navbar';
import Pagination from '../../components/Overview/Pagination/Pagination';
import LoadingModal from '../../components/shared/LoadingModal/LoadingModal';
import SelectionsAPI from '../../api/selections';
import Constants from '../../constants/constants';
import Util from '../../util';
import Features from '../../features';
import SelectionFoldersApi from '../../api/selectionsFolders';
import Notifications from '../../components/Overview/Notifications/Notifications';
import ArchiveSelectionModal from '../../components/Overview/SelectionList/ArchiveSelectionModal/ArchiveSelectionModal';
import CopySelectionModal from '../../components/Overview/SelectionList/CopySelectionModal/CopySelectionModal';
// eslint-disable-next-line max-len
import CalendarViewForSchedules from '../../components/Overview/SelectionList/CalendarViewForSchedules/CalendarViewForSchedules';
import SelectionFolders from '../../components/Overview/SelectionFolders/SelectionFolders';
import WaterfallSelectionsAPI from '../../api/waterfallSelections';
import SwalUtil from '../../utils/swal/swalUtil';
import UsersAPI from '../../api/users';
import Button from '../../components/shared/Button/Button';
import store from '../../redux/store/store';
import FeatureAdvertContainer from '../../components/AdminPanel/FeatureAdvertContainer/FeatureAdvertContainer';
import { NewDesignContext } from '../../utils/contexts/newDesignContext';
import NewNavbar from '../../components_v2/NewNavbar/NewNavbar';
import NewSelectionFolders from '../../components_v2/Overview/SelectionFolders/NewSelectionFolders';
import DeleteModal from '../../components_v2/DataTable/DeleteModal';
import SelectionRunLogsModal from '../../components_v2/DataTable/SelectionRunLogsModal';
import FreeLimitsBanner from '../../components/Selection/Banner/FreeLimitsBanner';

class Overview extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selections: [],
      searchField: '',
      error: null,
      showLoadingModal: true,
      sortedProperty: Constants.SELECTION__SEARCH_CRITERIA__UPDATED_AT,
      sortDirection: Constants.SORT_DIRECTION__DESCENDING,
      pageItems: [],
      searchCriteria: Constants.SELECTION__SEARCH_CRITERIA__NAME,
      searchCriteriaValue: '',
      searchBarLonger: false,
      hideFolders: props?.orgInfo?.edition === Constants.ORGANISATION__EDITION__ESSENTIALS, // shrink folder section
      openAllFolders: true, // open all created folders
      openSelections: false, // open uncategorized selections
      blurDrop: false, // blur where user can't drop
      folderBorderDragOver: false, // make border when drag over
      draggedSelectionId: '', // id of dragged selection
      draggedFolderId: '', // id of dragged folder
      draggedFolderName: '', // name of dragged folder
      menuState: 0, // Right click menu: state of menu. 0 closed. 1 open.
      openDeleteSelectionModalID: null, // selection ID for which the delete modal was opened
      archiveSelection: {
        openArchiveSelectionModalID: null,
        defaultRestoreFolderId: null,
      },
      deletingSelection: false, // informs that the selection is currently being removed
      archivingSelection: false, // informs that the selection is currently being archived
      openCopySelectionModalID: null,
      creatingCopySelection: false,
      openSelectionRunLogsModalID: null, // selection ID for which the selection run logs modal was opened
      offsetTop: null,
      hideCopySelectionModal: false,
      loadingSelectionsList: false,
      users: [],
      showCalendarViewForSchedulesId: null,
      showCalendarViewForAllSchedules: false,
      showBanner: true,
      featureAdvertModal: {
        show: false,
        feature: '',
      },
    };

    // Create 'CancelToken' for further cancellation of memory leak
    this.axiosCancelToken = axios.CancelToken.source();

    this.myRef = React.createRef();
    this.menuRef = React.createRef();
  }

  /**
   * Before mounting
   * Refresh and set the selections state
   */
  // eslint-disable-next-line camelcase
  async UNSAFE_componentWillMount() {
    const { refreshFolders } = this.props;

    this.getSelectionsWithInterval();
    refreshFolders();

    document.addEventListener('click', this.closeSubMenuOnClickOutside);
  }

  /**
   * Attach the "resize" event on the window object.
   * @returns {void}
   */
  componentDidMount() {
    window.addEventListener('resize', this.getOffsetTop.bind(this), false);

    if (this.myRef.current !== null) {
      // trigger getOffsetTop function
      this.getOffsetTop();
    }
  }

  /**
   * Update the current state of search field
   * @param {object} prevProps - Previous props
   * @param {object} prevState - Previous state
   * @returns {void}
   */
  async componentDidUpdate(prevProps, prevState) {
    const { offsetTop } = this.state;
    const { openAidown, handleOpenAidown } = this.props;

    // update offsetTop state, after changing the offsetTop property
    if (prevState.offsetTop !== offsetTop) {
      this.getOffsetTop();
    }

    const { overviewSection, refreshFolders } = this.props;

    // if the overviewSection has changed
    if (prevProps.overviewSection !== overviewSection) {
      // clear the interval
      clearInterval(this.interval);

      // get selections and folders for proper section
      this.getSelectionsWithInterval();
      refreshFolders();
    }

    if (openAidown !== prevState.prevProps && openAidown) {
      toast.info(
        <div className="slds-notify slds-notify_toast slds-theme_">
          <div>
            <p style={{ marginLeft: '0.5em' }}>
              Deedee AI is currently down. Please try again later.
            </p>
          </div>
        </div>,
        {
          position: toast.POSITION.TOP_RIGHT,
          className: 'toast-info',
          toastId: 'DeedeeAi',
          hideProgressBar: true,
          autoClose: 3000,
          containerId: 'DeedeeAi_1',
          draggable: false,
          pauseOnHover: false,
          pauseOnFocusLoss: false,
        },
      );
      handleOpenAidown(false);
    }
  }

  /**
   * Avoid memory leak by canceling all subscriptions and asynchronous tasks (interval)
   * @returns {void}
   */
  componentWillUnmount() {
    const { handleSetAppState } = this.props;

    clearInterval(this.interval);
    this.axiosCancelToken.cancel({
      message: 'all async subscriptions have been canceled',
    });

    document.removeEventListener('click', this.closeSubMenuOnClickOutside);
    handleSetAppState({ justLoggedIn: false });

    // unmount event listener and unbind the same listener that was bound.
    window.removeEventListener('resize', this.getOffsetTop.bind(this), false);
  }

  /**
   * Get and set the offsetTop for myRef (overview_selection_wrapper)
   * @returns {void}
   */
  getOffsetTop() {
    if (this.myRef.current !== null) {
      this.setState({
        offsetTop: this.myRef.current.offsetTop,
      });
    }
  }

  /**
   * It sets current number of pagination buttons.
   * @param {number} totalPages - total number of all pages
   * @returns {array|null} The page items
   */
  calculatePageItems = (totalPages) => {
    const pageItems = [...Array(totalPages).keys()].map(i => i + 1);

    this.setState({
      pageItems,
    });
  };

  /**
   * Search field handler for selections. It updates the state on every keystroke.
   * If search field is empty, take user to page one on pagination button one
   * @param {object} e - e.target
   * @param {bool} isSelectValue - Determines if we are searching by name or by other criteria
   * @returns {void}
   */
  handleSearchField = (e, isSelectValue) => {
    const { handlePaginationIndex } = this.props;

    // After search if we go on page 2 and then delete search input then it comes back on page 1
    handlePaginationIndex(1);

    if (isSelectValue) {
      this.setState({ searchCriteriaValue: e.target.value?.value });
    } else {
      this.setState({ searchField: e.target.value });
    }
  };

  /**
   * Delete selected selection by its id
   * Ask user if he wants to delete additional items
   * @param {string} selectionId - Selection's id
   * @param {boolean} deleteQueryActivities - additional option QueryActivities to be removed (true or false)
   * @param {boolean} deleteTargetDE - additional option TargetDE to be removed (true or false)
   * @returns {void}
   */
  handleRemoveSelection = async (selectionId, deleteQueryActivities, deleteTargetDE) => {
    const { overviewSection } = this.props;

    try {
      // the selection is deleting, show the loading modal
      this.setState({ deletingSelection: true });

      if (overviewSection === Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS) {
        // call an API to delete waterfall selection
        await WaterfallSelectionsAPI.deleteWaterfallSelection(
          selectionId,
          this.axiosCancelToken.token,
        );
      } else {
        // call an API to delete Selection and additional options (if checked)
        await SelectionsAPI.deleteSelection(
          selectionId,
          deleteQueryActivities,
          deleteTargetDE,
          this.axiosCancelToken.token,
        );
      }

      // refresh selections
      const pages = await this.refreshSelections();

      // stop deleting selection, close the loading modal
      this.setState({ deletingSelection: false });

      // After deleting last item from last page, set pagination index to the last available one
      const {
        filterFolderId,
        paginationIndex,
        handlePaginationIndex,
      } = this.props;

      if (paginationIndex > pages) {
        handlePaginationIndex(pages);

        // fetch selections from the last available page
        await this.refreshSelections(filterFolderId, pages);
      }
    } catch (error) {
      const isPermissionError = String(error?.actualError).includes(Constants.ERROR_SFMC_PERMISSION_FAILED);

      if (isPermissionError) {
        this.setState({ deletingSelection: false });

        SwalUtil.fire({
          type: Constants.SWAL__TYPE__ERROR,
          title: 'Insufficient Permissions',
          messageHTML: Constants.ERROR_INSUFFICIENT_PERMISSIONS_DETAILS,
          // Remove all ways to close the alert
          options: {
            showCancelButton: false,
            showConfirmButton: true,
            showCloseButton: false,
            allowOutsideClick: false,
          },
        });
      } else {
        this.setState({ error });
      }
    }
  };

  /**
   * Archive selected selection by its id
   * @param {string} selectionId - Selection's id
   * @param {string} restoreFolderId - Folder in which selection should be restored
   * @returns {void}
   */
  handleArchiveSelection = async (selectionId, restoreFolderId) => {
    try {
      // the selection is being archived, show the loading modal
      this.setState({ archivingSelection: true });

      const { overviewSection } = this.props;

      const { archiveSelection } = this.state;

      let result;

      if (overviewSection === Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS) {
        // call an API to archive Selection
        result = await WaterfallSelectionsAPI.archiveOrRestoreWaterfallSelection(
          {
            selectionId,
            restoreFolderId,
            cancelToken: this.axiosCancelToken.token,
          },
        );

        // also restore all normal selections that are being used in the waterfall selection
        if (archiveSelection?.archivedSelections?.length) {
          const restoredSelections = archiveSelection.archivedSelections.map((selection) => {
            return SelectionsAPI.archiveOrRestoreSelection(
              {
                selectionId: selection._id,
                restoreFolderId: selection.folderId,
                cancelToken: this.axiosCancelToken.token,
              },
            );
          });

          await Promise.all(restoredSelections);
        }
      } else {
        // call an API to archive Selection
        result = await SelectionsAPI.archiveOrRestoreSelection(
          {
            selectionId,
            restoreFolderId,
            cancelToken: this.axiosCancelToken.token,
          },
        );
      }

      // Check if the action succeeded otherwise show message from backend on modal to the user
      if (result?.success) {
        this.setState({ archivingSelection: false });

        // refresh selections
        const pages = await this.refreshSelections();

        // After archiving last item from last page, set pagination index to the last available one
        const {
          paginationIndex,
          handlePaginationIndex,
          filterFolderId,
        } = this.props;

        if (paginationIndex > pages) {
          handlePaginationIndex(pages);

          // fetch selections from the last available page
          await this.refreshSelections(filterFolderId, pages);
        }
      } else {
        this.setState({ archivingSelection: false }, async () => {
          await SwalUtil.fire({
            type: 'info',
            title: 'Archive Selection',
            messageHTML: `<p id="archive-exception-swal"
            class="width_swal" style="text-align:left">${result.message}</p>`,
          });
        });
      }
    } catch (error) {
      this.setState({ archivingSelection: false });
      this.setState({ error });
    }
  };

  /**
   * Return proper sortProperty for query param, depending on what is sorted
   * @param {string} sortedProperty - sortedProperty
   * @returns {void} string - sortedProperty or in case of Last Run - a different sort property name
   */
  returnSortProperty = (sortedProperty) => {
    if (sortedProperty === 'taskCompletedDate') {
      return 'taskStartDateSortable';
    } if (sortedProperty === 'waterfallSelectionCompletedDate') {
      return 'waterfallSelectionTaskStartDateSortable';
    }

    return sortedProperty;
  };

  /**
   * Set the name of current type of search.
   * Set the sorting direction
   * @param {string} property - name of the column
   * @param {string} sortDirection - sorting direction
   * @returns {void} Will setState for sortedProperty and sortDirection
   */
  handleSortSelections = (property, sortDirection) => {
    const {
      sortedProperty, searchField, searchCriteriaValue, searchCriteria,
    } = this.state;

    const { filterFolderId, handlePaginationIndex } = this.props;

    // define search value for query
    const searchValue = searchField && searchField !== '' ? searchField : searchCriteriaValue;

    // assign number for sort order
    const sortOrder = sortDirection === Constants.SORT_DIRECTION__ASCENDING ? 1 : -1;

    // build the query to get sorted selections
    const query = Util.queryForSelections({
      page: 1,
      folderId: filterFolderId,
      value: searchValue,
      criteria: searchCriteria,
      sortBy: this.returnSortProperty(property),
      sortOrder,
      limit: null,
      hasEnabledSchedule: null,
    });

    // fetch sorted selections
    this.refreshSelections(null, null, query);

    handlePaginationIndex(1);

    /*
     * When changing sort type, start always with ascending order
     * and paginationIndex = 1
     */
    this.setState({
      sortedProperty: property,
      sortDirection: property === sortedProperty ? sortDirection : Constants.SORT_DIRECTION__ASCENDING,
    });
  };

  /**
   * Change type of filter for input search
   * @param {string} criteria - type of a filter
   * @returns {void} Will setState for searchCriteria, searchCriteriaValue, searchField and paginationIndex
   */
  handleChangeSearchCriteria = (criteria) => {
    const { searchCriteria } = this.state;
    const { handlePaginationIndex } = this.props;

    // Initially searchCriteria is set to "name"
    if (searchCriteria !== criteria) {
      handlePaginationIndex(1);

      // Set searching criteria and delete any values so the list is new every time
      this.setState({
        searchCriteria: criteria?.value,
        searchCriteriaValue: '',
        searchField: '',
      }, () => this.refreshSelections());
    }
  };

  /**
   * Creates an unique option list for select in Navbar Header
   * @param {array<object>} sel - The select
   * @param {string} criteria - The criteria
   * @returns {array<object>} The option list as HTML
   */
  handleFilterSelectList = () => {
    const { users } = this.state;

    return users.map(el => ({
      value: el._id,
      label: el.name,
    }));
  };

  /**
   * Change index of current page
   * @param {object} e - e.target
   * @param {number} nr - A number to set paginationIndex to if `e` is null
   * @param {boolean} withoutRefresh - indicates that selection shouldn't be fetched
   * @returns {void} Will setState for paginationIndex
   */
  handleChangePaginationIndex = async (e, nr, withoutRefresh) => {
    const { filterFolderId, handlePaginationIndex } = this.props;

    const paginationIndex = e === null ? +nr : +e.target.value;

    handlePaginationIndex(paginationIndex);

    if (withoutRefresh) return;

    // start loading selections in overview table
    this.setState({ loadingSelectionsList: true });

    // refresh selections for defined pagination index
    await this.refreshSelections(filterFolderId, nr || e.target.value);

    // stop showing loading indicator
    this.setState({ loadingSelectionsList: false });
  };

  /**
   * Create a copy of selected selection (by its id)
   * Ask user if he wants to create a copy of the selection (Swal pop up)
   * @param {string} selectionId - Selection's id
   * @param {string} copySelectionName - Selection's copy name
   * @param {boolean} isCopyTargetDE - true if copyTarget DE is selected, false if not
   * @param {string} copyTargetDEName - name of Target Data Extension Copy
   * @param {number} copyTargetDEFolderId - copy Target Data Extension folder ID
   * @param {number} businessUnitId - conditional, if copying to another business unit
   * @param {string} businessUnitName - conditional, if copying to another business unit
   * @param {boolean} copySchedule - true if copySchedule is selected, false if not
   * @returns {void}
   */
  handleCopySelection = async ({
    selectionId, copySelectionName, isCopyTargetDE,
    copyTargetDEName, copyTargetDEFolderId,
    businessUnitId, businessUnitName, copySchedule,
  }) => {
    try {
      const { overviewSection } = this.props;

      // start creating copy selection, hide the copy selection modal
      this.setState({ creatingCopySelection: true, hideCopySelectionModal: true });

      if (overviewSection === Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS) {
        // copy waterfall selection
        await WaterfallSelectionsAPI.copyWaterfallSelection(
          {
            selectionId,
            copyWaterfallSelectionName: copySelectionName,
            copySchedule,
          },
          this.axiosCancelToken.token,
        );
      } else {
        // copy selection, then refresh selection list
        await SelectionsAPI.copySelection(
          {
            selectionId,
            copySelectionName,
            isCopyTargetDE,
            copyTargetDEName,
            copyTargetDEFolderId,
            businessUnitId,
            copySchedule,
          },
          this.axiosCancelToken.token,
        );
      }

      await this.refreshSelections();
      this.setState({ creatingCopySelection: false });

      // close the modal if the request was successful
      this.handleCloseCopySelectionModal();

      if (businessUnitId) {
        const buName = businessUnitName ? `${businessUnitName} - ` : '';

        SwalUtil.fire({
          title: 'Copy Selection',
          message: `Selection <span style='font-weight: 700'>${copySelectionName}</span>
          copied successfully to business unit <span style='font-weight: 700'>${buName}${businessUnitId}
          </span>.`,
          options: {
            confirmButtonText: 'OK',
          },
        });
      }
    } catch (e) {
      this.setState({ creatingCopySelection: false });

      // Error is from the backend or from network error or code execution
      const error = e.response || e?.actualError || e.message?.message || e.message;

      await SwalUtil.fire({
        title: 'Something went wrong while copying selection',
        type: Constants.SWAL__TYPE__ERROR,
        message: error,
        options: {
          confirmButtonText: 'OK',
        },
      });

      // in case name of the target DE exists, show the modal again
      if (error === Constants.ERROR__DATA_EXTENSION_ALREADY_EXISTS) {
        this.setState({ hideCopySelectionModal: false });
      } else {
        // in case of other errors, close the modal
        this.handleCloseCopySelectionModal();
      }
    }
  };

  /**
   * Edit button for selections
   * @param {string} id - Id of selection
   * @param {string} folderId - The folder id
   * @returns {void}
   */
  handleEditSelection = (id, folderId) => {
    const { selections } = this.state;
    const { handleSetAppState, overviewSection } = this.props;

    // find edited selection name to display it in loading modal
    const editedSelectionName = selections?.find(sel => sel._id === id)?.name || '';

    // for waterfall selection
    if (overviewSection === Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS) {
      handleSetAppState({
        currentSelectionName: editedSelectionName,
        currentSelectionId: id,
        navigator: Constants.NAVIGATION__WATERFALL_SELECTION,
        globalNavigator: Constants.NAVIGATION__WATERFALL_SELECTION,
        folderId,
      });
    } else {
      // for selection
      handleSetAppState({
        currentSelectionName: editedSelectionName,
        currentSelectionId: id,
        navigator: Constants.NAVIGATION__SELECTION,
        globalNavigator: Constants.NAVIGATION__SELECTION,
        folderId,
      });
    }
  };

  /**
   * Set overview state
   *  @param {object} newState - state to be set
   * @returns {void}
   */
  handleSetOverviewState = (newState) => {
    this.setState(newState);
  };

  /**
   * OnClick event handler for when a folder is clicked
   * @param {object} folderId - clicked folder Id
   * @returns {void}
   */
  handleFolderClicked = async (folderId) => {
    const { handleFilterFolderId } = this.props;

    handleFilterFolderId(folderId);

    // after clicking on the folder set the pagination index to 1
    this.setState({ loadingSelectionsList: true });

    // refresh selections for selected folder
    await this.refreshSelections(folderId, 1);

    // stop showing loading indicator
    this.setState({ loadingSelectionsList: false });
  };

  /**
   * Right click to open sub menu
   * @param {object} e - Refer to clicked folder
   * @param {string} folderId - Id of a folder
   * @returns {void}
   */
  rightClick = (e, folderId) => {
    const { handleSetAppState } = this.props;
    const { menuState } = this.state;

    // Check if clicked inside
    let taskItemInContext = this.clickInsideElement(e);

    if (taskItemInContext) {
      e.preventDefault();
      Util.toggleMenuOn(this.handleChangeMenuState, this.menuRef); // turn on menu
      Util.positionMenu(e, this.menuRef); // position menu
      handleSetAppState({ folderId });
    } else {
      taskItemInContext = null;
      Util.toggleMenuOff(this.handleChangeMenuState, menuState, this.menuRef); // turn off menu
      handleSetAppState({ folderId });
    }
  };

  /**
   * Check if click inside and open
   * @param {object} e - refer to click inside of a element
   * @returns {object|false} - The element clicked or false
   */
  clickInsideElement = (e) => {
    const el = e.srcElement || e.target;

    // Check where the click took place
    if (
      el.className &&
      (el.className === 'folder_element' ||
        el.className === 'fas fa-folder' ||
        el.className === 'fas fa-folder-open' ||
        el.className === 'folderDrop active false' ||
        el.className === 'folderDrop' ||
        el.className === 'folder-zero' ||
        el.className === 'mainFolder false' ||
        el.className === 'mainFolder mark_all' ||
        el.className === 'folder_element mainFolderText')
    ) {
      return el;
    }
    while ((el === el.parentNode)) {
      if (
        el.className &&
        (el.className === 'folder_element' ||
          el.className === 'fas fa-folder' ||
          el.className === 'fas fa-folder-open' ||
          el.className === 'folderDrop active false' ||
          el.className === 'folderDrop' ||
          el.className === 'folder-zero' ||
          el.className === 'mainFolder false' ||
          el.className === 'mainFolder mark_all' ||
          el.className === 'mainFolderText')
      ) {
        return el;
      }
    }

    return false;
  };

  /**
   * Close menu when click outside
   * @param {object} e - e.target
   * @returns {void}
   */
  closeSubMenuOnClickOutside = (e) => {
    const { menuState } = this.state;
    const { handleSetAppState } = this.props;

    if (menuState === 1) {
      if (
        e.target.className !== 'submenu_folder' &&
        e.target.className !== 'submenu active'
      ) {
        Util.toggleMenuOff(this.handleChangeMenuState, menuState, this.menuRef);
        handleSetAppState({ folderId: '' });
      }
    }
  };

  /**
   * Handle change menuState
   * @param {number} newState - number for the menu state - 0 or 1
   * @returns {void}
   */
  handleChangeMenuState = (newState) => {
    this.setState({ menuState: newState });
  };

  /**
   * Show error message if folder name already exists
   * @returns {void}
   */
  showErrorFolderMessage = async () => {
    await SwalUtil.fire({
      title: 'Not a Unique Folder Name',
      type: Constants.SWAL__TYPE__ERROR,
      message: 'There is already a folder with the chosen name. Please choose a different name.',
    });
  };

  /**
   * Lookup for a folder matching with the provided name in the the same parent folder
   * @param {Object} params - All params object
   * @param {Array} params.folders - All the folders
   * @param {String} params.name - Name to lookup
   * @param {String} params.folderId - Parent folder Id
   * @returns {Object} - Folder found
   */
  findByNameInParentFolder = ({ folders, name, folderId }) => {
    const folder =
      folders?.filter(folder => folder.parentFolderId === folderId)?.find(folder => folder.name === name);

    return folder;
  };

  /**
   * Create the folder
   * @returns {void}
   */
  createNewFolder = async () => {
    const {
      folders, refreshFolders, handleSetAppState, folderId, overviewSection,
    } = this.props;

    let nameExists = true;

    let result;

    // If folder name exists show error
    while (nameExists) {
      // eslint-disable-next-line no-await-in-loop
      result = await SwalUtil.fire({
        title: 'Folder Name',
        options: {
          input: 'text',
          showCancelButton: true,
          confirmButtonText: 'Save',
          inputAttributes: {
            maxlength: 150,
          },
          inputValidator: (value) => {
            if (!value) {
              return 'Folder name cannot be empty.';
            }
            if (Util.isHTML(value)) {
              return 'HTML tags are not allowed.';
            }

            return null;
          },
        },
      });

      const folder = this.findByNameInParentFolder({ folderId, folders, name: result?.value });

      if (folder) {
        // eslint-disable-next-line no-await-in-loop
        await this.showErrorFolderMessage();
      } else {
        nameExists = false;
      }
    }

    // If name is unique create new folder
    if (result.value) {
      try {
        const folder = this.findByNameInParentFolder({ folderId, folders, name: result?.value });

        if (folder) {
          await this.showErrorFolderMessage();

          return;
        }

        // Create new folder - depending on the selected section in overview, create a folder with the appropriate type
        const isWaterfall = overviewSection === Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS;

        await SelectionFoldersApi
          .createSelectionFolder(this.axiosCancelToken.token, result.value, folderId, isWaterfall);

        const foundFolder = folders.filter(el => el._id === folderId);

        if (foundFolder.length !== 0) {
          handleSetAppState({ folderId: '' });
        }

        refreshFolders();
      } catch (err) {
        this.setState({ error: err });
      }
    } else {
      handleSetAppState({ folderId: '' });
    }
  };

  /**
   * Delete the folder
   * @returns {void}
   */
  deleteFolder = async () => {
    const { selections } = this.state;
    const {
      folders, refreshFolders, folderId, handleSetAppState, overviewSection, handleFilterFolderId,
    } = this.props;

    // Find folder which will be deleted
    const foundFolder = folders.filter(el => el._id === folderId);
    // Find name of parent folder
    const parentFolderName = folders.filter(el => el._id === foundFolder[0].parentFolderId);

    const arrayOfFolderIDs = [];
    /**
     * Get all folder ids which will be deleted
     * @param {array} child - Array with deleted folders Ids
     * @returns {void}
     */
    const getFolderIds = (child) => {
      child.map(el => arrayOfFolderIDs.push(el._id));
      if (child[0].children && child[0].children.length > 0) {
        getFolderIds(child[0].children);
      }
    };

    getFolderIds(foundFolder);

    // User confirmation
    const result = await SwalUtil.fire({
      title: 'Delete Folder',
      // eslint-disable-next-line max-len
      message: `Are you sure you want to delete this folder? All selections in it will be moved to the <span style='font-weight: 700'>${parentFolderName[0] ? parentFolderName[0].name : 'All Selections'} folder.</span>`,
      options: {
        showCancelButton: true,
        confirmButtonText: 'Confirm',
      },
    });

    if (result.value) {
      try {
        // loop through array of folder ids
        for (let i = arrayOfFolderIDs.length - 1; i >= 0; i -= 1) {
          selections.forEach(async (selection) => {
            /*
             * find selections with deleted folder ids and set
             * their folder ids to the parent folder id
             */
            if (selection.folderId === arrayOfFolderIDs[i]) {
              if (overviewSection === Constants.OVERVIEW__SECTION__SELECTIONS) {
                // when Selection section is selected
                await SelectionsAPI.updateSelection(
                  selection._id,
                  foundFolder[0].parentFolderId,
                  this.axiosCancelToken.token,
                );
              } else {
                // when Waterfall selection section is selected
                await WaterfallSelectionsAPI.updateWaterfallSelection(
                  selection._id,
                  foundFolder[0].parentFolderId,
                  this.axiosCancelToken.token,
                );
              }
            }
          });

          // delete folder / folders
          // eslint-disable-next-line no-await-in-loop
          await SelectionFoldersApi.deleteSelectionFolder(this.axiosCancelToken.token, arrayOfFolderIDs[i]);
        }

        // refresh all folders
        await refreshFolders();

        // define folder to be selected
        let selectedFolderId = null;

        if (foundFolder[0].parentFolderId === '0') {
          handleFilterFolderId(selectedFolderId);
        } else {
          selectedFolderId = foundFolder[0].parentFolderId;
          handleFilterFolderId(selectedFolderId);
        }

        // refresh selections for defined folder
        await this.refreshSelections(selectedFolderId);
        handleSetAppState({ folderId: '' });
      } catch (err) {
        this.setState({ error: err });
      }
    } else {
      handleSetAppState({ folderId: '' });
    }
  };

  /**
   * Rename the folder
   * @returns {void}
   */
  renameFolder = async () => {
    const {
      folders, refreshFolders, folderId, handleSetAppState,
    } = this.props;

    const foundFolder = folders.filter(el => el._id === folderId);

    let nameExists = true;

    let result;

    while (nameExists) {
      // eslint-disable-next-line no-await-in-loop
      result = await SwalUtil.fire({
        title: 'Rename Folder',
        options: {
          input: 'text',
          inputValue: foundFolder[0].name,
          showCancelButton: true,
          confirmButtonText: 'Save',
          inputAttributes: {
            maxlength: 150,
          },
          inputValidator: (value) => {
            if (!value) {
              return 'Folder name cannot be empty.';
            }
            if (Util.isHTML(value)) {
              return 'HTML tags are not allowed.';
            }

            return null;
          },
        },
      });

      const folder = this.findByNameInParentFolder({
        folderId: foundFolder?.[0]?.parentFolderId,
        folders,
        name: result?.value,
      });

      if (folder) {
        // eslint-disable-next-line no-await-in-loop
        await this.showErrorFolderMessage();
      } else {
        nameExists = false;
      }
    }

    if (result.value) {
      try {
        await SelectionFoldersApi.updateSelectionFolder(
          this.axiosCancelToken.token,
          {
            folderId: foundFolder[0]._id,
            name: result.value,
            parentFolderId: foundFolder[0].parentFolderId,
          },
        );

        refreshFolders();
        handleSetAppState({ folderId: '' });
      } catch (err) {
        this.setState({ error: err });
      }
    } else {
      handleSetAppState({ folderId: '' });
    }
  };

  /**
   * Mark a folder as favorite or not
   * @param {string} folderId - The folder id
   * @param {boolean} newValue - The new value
   * @returns {void}
   */
  starOrRemoveStarOnFolder = async (folderId, newValue) => {
    try {
      await SelectionFoldersApi.updateSelectionFolder(
        this.axiosCancelToken.token,
        {
          folderId,
          isFavorite: newValue,
        },
      );

      const { refreshFolders } = this.props;

      await refreshFolders();
    } catch (err) {
      this.setState({ error: err });
    }
  };

  /**
   * Drop folder on folder
   * Drop selection on folder
   * @param {object} e - refer to target folder
   * @returns {void}
   */
  dropOnFolder = async (e) => {
    const { draggedSelectionId, draggedFolderId, draggedFolderName } = this.state;

    const { folders, refreshFolders } = this.props;

    // Don't do anything if we don't drag a selection or folder
    if (!draggedSelectionId && !draggedFolderId) return;

    let folderId;

    let folderName;

    if (e.target.nodeName.toString().toLowerCase() === 'span') {
      folderId = e.target.dataset.id;
      folderName = e.target.dataset.name;
    } else {
      folderId = e.target.parentElement.dataset.id;
      folderName = e.target.parentElement.dataset.name;
    }

    // Dont drop to the same folder
    if (draggedFolderId === folderId) return;

    // Check if selection is archived and open restore modal if it's the case
    if (draggedSelectionId) {
      const { selections } = this.state;

      const draggedSelection = selections.find(elm => elm._id === draggedSelectionId);

      if (draggedSelection?.isArchived) {
        this.handleOpenArchiveSelectionModal(draggedSelectionId, folderId);

        return;
      }
    }

    // When dragging folder to folder check for errors
    if (draggedFolderId) {
      // Check if user drops folder to its child and forbidden such behavior
      const arrOfChildrenId = [];
      const foundFolder = folders.filter(el => el._id === draggedFolderId);

      /**
       * Create an array with children of the moved folder
       * @param {array<object>} child - The moved folder
       * @returns {void} Will add id of element in `child` to `arrOfChildrenID`
       */
      const updateChildrenFolder = (child) => {
        // For each child
        child.forEach((el) => {
          // If this child has children, re-execute this function with them
          if (el.children && el.children.length > 0) updateChildrenFolder(el.children);
          // Push current child id
          arrOfChildrenId.push(el._id);
        });
      };

      updateChildrenFolder(foundFolder);

      // Loop over array If folderId === id in array, stop action
      const cantDropParentOnChild = arrOfChildrenId.find(el => el === folderId);

      if (cantDropParentOnChild) {
        await SwalUtil.fire({
          title: 'Cannot drop here',
          message: 'You can\'t move parent folder to its child.',
          options: {
            showCancelButton: false,
            confirmButtonText: 'Ok',
          },
        });

        return;
      }
    }
    // When dragging folder to folder join them
    if (draggedFolderId) {
      // Check if there is a folder name in the target folder with the same name as the dragged folder
      const duplicateFound = !!folders
        ?.filter(folder => folder.parentFolderId === folderId)
        ?.find(childFolder => childFolder.name === draggedFolderName && childFolder._id !== draggedFolderId);

      if (duplicateFound) {
        await SwalUtil.fire({
          title: 'Folder already exists',
          messageHTML: `You cannot drag the <strong class="break-all">${draggedFolderName}</strong> folder into the
           <strong>${folderName}</strong> folder because a folder with the same name already exists
            in the destination folder. Please choose a different destination or rename the folder before proceeding.`,
          options: {
            showCancelButton: false,
            confirmButtonText: 'Ok',
          },
        });

        return;
      }

      const result = await SwalUtil.fire({
        title: 'Move Folder',
        // eslint-disable-next-line max-len
        message: `Do you want to move folder <span style='font-weight: 700'>${draggedFolderName}</span> to folder <span style='font-weight: 700'>${folderName}</span>?`,
        options: {
          showCancelButton: true,
          confirmButtonText: 'Move',
        },
      });

      if (result.value) {
        try {
          await SelectionFoldersApi.updateSelectionFolder(
            this.axiosCancelToken.token,
            {
              folderId: draggedFolderId,
              name: draggedFolderName,
              parentFolderId: folderId,
            },
          );

          refreshFolders();
        } catch (err) {
          this.setState({ error: err });
        }
      }

      // When dragging selection to folder join them
    } else {
      const result = await SwalUtil.fire({
        title: 'Move Selection',
        // eslint-disable-next-line max-len
        message: `Are you sure you want to move selection to folder <span style='font-weight: 700'>${folderName}</span>?`,
        options: {
          showCancelButton: true,
          confirmButtonText: 'Move',
        },
      });

      if (result.value) {
        const { overviewSection } = this.props;

        try {
          if (overviewSection === Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS) {
            // update waterfall selection
            await WaterfallSelectionsAPI
              .updateWaterfallSelection(draggedSelectionId, folderId, this.axiosCancelToken.token);
          } else {
            // update selection
            await SelectionsAPI.updateSelection(draggedSelectionId, folderId, this.axiosCancelToken.token);
          }

          this.setState({ draggedSelectionId: '' });
          this.refreshSelections();
        } catch (err) {
          this.setState({ draggedSelectionId: '', error: err });
        }
      }
    }
  };

  /**
   * When dragging selection to folder
   * @param {string} _id - id of dragged selection
   * @returns {void} Will setState draggedSelectionId
   */
  startDraggingToFolder = (_id) => {
    this.setState({ draggedSelectionId: _id });
  };

  /**
   * When dragging folder to folder
   * @param {string} id - id of dragged folder
   * @param {string} name - name of the dragged folder
   * @returns {void} Will setState draggedFolderId, draggedFolderName, folderBorderDragOver
   */
  startDraggingFolder = (id, name) => {
    this.setState({ draggedFolderId: id, draggedFolderName: name, folderBorderDragOver: true });
  };

  /**
   * Refresh and set the selections state on the front page
   * @param {string} folderId - Id of the current folder
   * @param {number} pageIdx - index number of the page
   * @param {string} query - stringify query data for API
   * @returns {number} pages - number of pages for fetched selections
   */
  refreshSelections = async (folderId, pageIdx, query) => {
    const {
      searchField,
      searchCriteriaValue,
      searchCriteria,
      sortedProperty,
      sortDirection,
    } = this.state;

    const {
      filterFolderId,
      handleSetAppState,
      overviewSection,
      paginationIndex,
    } = this.props;

    let retrievedSelections;

    let pages;

    let apiQuery = query;

    // define search value for api query params
    const searchValue = searchField && searchField !== '' ? searchField : searchCriteriaValue;

    // define folder ID for api query params
    const folderID = folderId || folderId === null || folderId === '' ? folderId : filterFolderId;

    // define sort order for api query params --> 1 for ascending and -1 for descending
    const sortOrder = sortDirection === Constants.SORT_DIRECTION__ASCENDING ? 1 : -1;

    if (!apiQuery) {
      // build a query for api call if it's not pass as a prop
      apiQuery = Util.queryForSelections({
        page: pageIdx || paginationIndex,
        folderId: folderID,
        value: searchValue,
        criteria: searchCriteria,
        sortBy: this.returnSortProperty(sortedProperty),
        sortOrder,
        limit: null,
        hasEnabledSchedule: null,
      });
    }

    try {
      // when selections section is selected
      if (overviewSection === Constants.OVERVIEW__SECTION__SELECTIONS) {
        // get selections
        const { selections, totalPages } = await SelectionsAPI.getSelections(
          this.axiosCancelToken.token,
          apiQuery,
        );

        retrievedSelections = selections;
        pages = totalPages;
      } else {
        // get waterfall selections
        const { waterfallSelections: selections, totalPages } = await WaterfallSelectionsAPI.getWaterfallSelections(
          this.axiosCancelToken.token,
          apiQuery,
        );

        retrievedSelections = selections;
        pages = totalPages;
      }
    } catch (error) {
      // clear loading indicators and set error state
      this.setState({ error, showLoadingModal: false, loadingSelectionsList: false });
    }

    // set retrieved selections in state
    this.setState({
      selections: retrievedSelections,
    });
    handleSetAppState({ selections: retrievedSelections });

    // refresh the number of pagination buttons
    this.calculatePageItems(pages, true);

    // return number of page
    return pages;
  };

  /**
   * Gets all users to display in Created By / Modified By dropdown options
   * @returns {void}
   */
  getAllUsersForFilter = async () => {
    try {
      // get all users
      const users = await UsersAPI.getUsers();

      this.setState({ users });
    } catch (error) {
      this.setState({ error });
    }
  };

  /**
   * Invoke an interval with fetching the selection on the front page
   * @returns {void}
   */
  getSelectionsWithInterval = async () => {
    // start loading selections in overview table
    this.setState({ loadingSelectionsList: true });

    // get all users
    await this.getAllUsersForFilter();

    // get selections
    await this.refreshSelections();
    this.setState({ showLoadingModal: false, loadingSelectionsList: false });

    // Refresh selections state, every 15 seconds
    this.interval = setInterval(async () => {
      const { filterFolderId, paginationIndex } = this.props;

      await this.refreshSelections(filterFolderId, paginationIndex);
    }, 15000);
  };

  /**
   * Open deleteSelectionModal, sets the ID of the open modal
   * @param {string} id - id of the selection
   * @returns {void}
   */
  handleOpenDeleteSelectionModal = (id) => {
    this.setState({ openDeleteSelectionModalID: id });
  };

  /**
   * Function which helps to close delete selection modal
   * @returns {void}
   */
  handleCloseDeleteSelectionModal = () => {
    // set the modal ID to null and close the modal
    this.setState({ openDeleteSelectionModalID: null });
  };

  /**
   * Open archiveSelectionModal, sets the ID of the open modal
   * @param {string} id - id of the selection
   * @param {string} defaultRestoreFolderId - id of the default folder on restore
   * @returns {void}
   */
  handleOpenArchiveSelectionModal = async (id, defaultRestoreFolderId) => {
    const { selections } = this.state;
    const { overviewSection } = this.props;

    const draggedSelection = selections.find(elem => elem._id === id);

    if (defaultRestoreFolderId === 'archivedSelectionFolderId' && draggedSelection?.isArchived) {
      return;
    }

    let archivedSelections = [];

    // when restoring a waterfall selection
    if (overviewSection === Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS && draggedSelection?.isArchived) {
      const archivedNotWaterfallSelectionsIds = [];

      // get all IDs of the selections that are being used in the waterfall selection
      draggedSelection.selectionChain.forEach((chain) => {
        if (chain.selectionId) {
          archivedNotWaterfallSelectionsIds.push(chain.selectionId);
        }
      });

      try {
        // get selections
        const archivedNotWaterfallSelections = await SelectionsAPI.getMultipleSelections(
          archivedNotWaterfallSelectionsIds,
          this.axiosCancelToken.token,
        );

        // get only archived selections
        if (archivedNotWaterfallSelections.length) {
          archivedSelections = archivedNotWaterfallSelections.reduce((arr, selection) => {
            if (selection.isArchived) {
              arr.push(selection);
            }

            return arr;
          }, []);
        }
      } catch (error) {
        this.setState({ error });

        return;
      }
    }

    this.setState({
      archiveSelection: {
        openArchiveSelectionModalID: id,
        defaultRestoreFolderId,
        archivedSelections,
      },
    });
  };

  /**
   * Function which helps to close delete selection modal
   * @returns {void}
   */
  handleCloseArchiveSelectionModal = () => {
    // set the modal ID to null and close the modal
    this.setState({ archiveSelection: { openArchiveSelectionModalID: null, defaultRestoreFolderId: null } });
  };

  /**
   * Open copySelectionModal, sets the ID of the open modal
   * @param {string} id - id of the selection
   * @returns {void}
   */
  handleOpenCopySelectionModal = (id) => {
    this.setState({ openCopySelectionModalID: id });
  };

  /**
   * Function which helps to close copy selection modal
   * @returns {void}
   */
  handleCloseCopySelectionModal = () => {
    // set the modal ID to null and close the modal
    this.setState({ openCopySelectionModalID: null, hideCopySelectionModal: false });
  };

  /**
   * Open selectionRunLogsModal, sets the ID of the open modal
   * @param {string} id - id of the selection
   * @returns {void}
   */
  handleOpenSelectionRunLogsModal = (id) => {
    this.setState({ openSelectionRunLogsModalID: id });
  };

  /**
   * Opens the CalendarView for a single selection
   * @param {string} id - id of the selection
   * @returns {void}
   */
  showCalendarViewForSchedules = (id) => {
    this.setState({ showCalendarViewForSchedulesId: id });
  };

  /**
   * Closes the CalendarView modal for a single selection
   * @returns {void}
   */
  hideCalendarViewForSchedules = () => this.setState({ showCalendarViewForSchedulesId: null });

  /**
   * Opens the general CalendarView for all selections
   * @returns {void}
   */
  handleOpenCalendarView = () => {
    this.setState({ showCalendarViewForAllSchedules: true });
  };

  /**
   * Closes the CalendarView modal for all scheduled selection
   * @returns {void}
   */
  handleCloseCalendarView = () => this.setState({ showCalendarViewForAllSchedules: false });

  /**
   * Function which helps to close selectionRunLogsModal modal
   * @returns {void}
   */
  handleCloseSelectionRunLogsModal = () => {
    // set the modal ID to null and close the modal
    this.setState({ openSelectionRunLogsModalID: null });
  };

  /**
   * Find duplicate Selection by its name
   * @param {object} selectionName - selection Name
   * @returns {Promise<object>} Duplicate Selection
   */
  findDuplicateSelection = async (selectionName) => {
    const { overviewSection } = this.props;

    try {
      // define query params to fetch selections by property
      const query = qs.stringify({
        propertyName: Constants.SELECTION__SEARCH_CRITERIA__NAME,
        propertyValue: selectionName?.toString().trim(),
      });

      const isWaterfall = overviewSection === Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS;

      // get selections with the same name depending of selected section in Overview
      const selections = isWaterfall ?
        await WaterfallSelectionsAPI.getWaterfallSelectionsByProperty(this.axiosCancelToken.token, query) :
        await SelectionsAPI.getSelectionsByProperty(this.axiosCancelToken.token, query);

      // check if there is a second selection with duplicate name
      const duplicateSelection = Util.findDuplicateName(selections, null, selectionName);

      // return the object if found. If not, return false
      return duplicateSelection || false;
    } catch (error) {
      this.setState({ error });
    }
  };

  /**
   * Function that handles a specific option in a submenu
   * @param {function} handleOption - function for specified option
   * @returns {void}
   */
  handleMenuOptions = (handleOption) => {
    const { menuState } = this.state;

    // trigger function for option
    handleOption();

    // turn off the submenu
    Util.toggleMenuOff(this.handleChangeMenuState, menuState, this.menuRef);
  };

  /**
   * Function that returns number of active users per org
   * @returns {Number} number of active users in the org
   */
  handleGetActiveUsersOrg = () => {
    const { users } = this.state;

    const activeUsers = users.filter(user => user.isActive === true);

    return activeUsers.length ?? 1;
  };

  /**
   * Function that handles showing of feature advert modal with specific feature
   * @param {String} feature - feature to show
   * @returns {void}
   */
  showEssentialsUpgradeModal = (feature) => {
    this.setState(prevState => ({
      featureAdvertModal: {
        ...prevState.featureAdvertModal,
        show: true,
        feature,
      },
    }));
  };

  /**
   * Function that handles hiding of feature advert modal
   * @returns {void}
   */
  cancelEssentialsUpgradeModal = () => {
    this.setState(prevState => ({
      featureAdvertModal: {
        ...prevState.featureAdvertModal,
        show: false,
        feature: '',
      },
    }));
  };

  render() {
    // Navbar and Selection are red ones.
    const {
      searchField,
      selections,
      error,
      showLoadingModal,
      sortDirection,
      sortedProperty,
      searchCriteria,
      searchCriteriaValue,
      searchBarLonger,
      openAllFolders,
      hideFolders,
      folderBorderDragOver,
      openSelections,
      blurDrop,
      draggedSelectionId,
      draggedFolderId,
      pageItems,
      openDeleteSelectionModalID,
      archiveSelection,
      deletingSelection,
      archivingSelection,
      openCopySelectionModalID,
      creatingCopySelection,
      openSelectionRunLogsModalID,
      showCalendarViewForSchedulesId,
      showCalendarViewForAllSchedules,
      offsetTop,
      hideCopySelectionModal,
      loadingSelectionsList,
      featureAdvertModal,
      showBanner,
    } = this.state;

    const {
      handleNavigator,
      getNestedSelectionFolders,
      folders,
      folderId,
      justLoggedIn,
      notifications,
      handleSetAppState,
      latePayment,
      overviewSection,
      loadingFolders,
      folderSettings,
      filterFolderId,
      paginationIndex,
      preferences,
      orgInfo,
      featuresInfo,
      expandedRightBar,
    } = this.props;

    if (error) {
      throw error;
    } else {
      // Set features
      const featureSelectionFolders = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__FOLDERS);
      // eslint-disable-next-line max-len
      const featureScheduleSelectionsIsEnabled = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__SCHEDULE_SELECTIONS);
      // eslint-disable-next-line max-len
      const featureSelectionTemplateIsEnabled = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__SELECTION_TEMPLATE);
      // Set folders
      const nestedFolders = getNestedSelectionFolders(folders, '0');

      const submenuButtonClassName = classNames(
        'submenu_folder',
        // eslint-disable-next-line quote-props
        { 'disabled': folderId === '0' },
      );

      return (
        <NewDesignContext.Consumer>
          {showNewDesign => (
            <Provider store={store}>
              <div className={classNames('overview-v2', { 'overview-v2--expanded': expandedRightBar })}>
                {showLoadingModal &&
                  (
                    <LoadingModal
                      closeModal={() => handleNavigator(Constants.NAVIGATION__OVERVIEW)}
                      loadingText={Constants.OVERVIEW__SELECTIONS_LOADING}
                      id="loadingmodal-wrapper"
                    />
                  )}
                {deletingSelection &&
                  (
                    <LoadingModal
                      loadingText="Deleting Selection"
                      hideCloseButton
                      bgNone
                      id="DE-loadingmodal"
                    />
                  )}
                {archivingSelection &&
                  (
                    <LoadingModal
                      loadingText="Loading..."
                      hideCloseButton
                      bgNone
                      id="DE-loadingmodal"
                    />
                  )}
                {creatingCopySelection &&
                  (
                    <LoadingModal
                      loadingText="Creating Copy of Selection"
                      bgNone
                      hideCloseButton
                      id="DE-loadingmodal"
                    />
                  )}
                {justLoggedIn && (
                  <Notifications
                    notifications={notifications}
                    handleSetAppState={handleSetAppState}
                    latePayment={latePayment}
                  />
                )}
                {showNewDesign ?
                  (
                    <NewNavbar
                      handleSearch={this.handleSearchField}
                      handleChangeSearchCriteria={this.handleChangeSearchCriteria}
                      handleNavigator={handleNavigator}
                      searchCriteria={searchCriteria}
                      selections={selections}
                      filterSelectList={this.handleFilterSelectList}
                      searchField={searchField}
                      searchCriteriaValue={searchCriteriaValue}
                      searchBarLonger={searchBarLonger}
                      handleSetOverviewState={this.handleSetOverviewState}
                      folderId={folderId}
                      overviewSection={overviewSection}
                      handleSetAppState={handleSetAppState}
                      refreshSelections={this.refreshSelections}
                      handleOpenCalendarView={this.handleOpenCalendarView}
                    />
                  ) :
                  (
                    <Navbar
                      handleSearch={this.handleSearchField}
                      handleChangeSearchCriteria={this.handleChangeSearchCriteria}
                      handleNavigator={handleNavigator}
                      searchCriteria={searchCriteria}
                      selections={selections}
                      filterSelectList={this.handleFilterSelectList}
                      searchField={searchField}
                      searchCriteriaValue={searchCriteriaValue}
                      searchBarLonger={searchBarLonger}
                      handleSetOverviewState={this.handleSetOverviewState}
                      folderId={folderId}
                      overviewSection={overviewSection}
                      handleSetAppState={handleSetAppState}
                      refreshSelections={this.refreshSelections}
                      handleOpenCalendarView={this.handleOpenCalendarView}
                    />
                  )}

                {showBanner && orgInfo?.edition === Constants.ORGANISATION__EDITION__ESSENTIALS && (
                  <FreeLimitsBanner
                    showLimits
                    activeSelections={selections?.length}
                    activeBUs={orgInfo.numberOfActiveBUs}
                    activeUsers={this.handleGetActiveUsersOrg()}
                  />
                )}

                <div id="context-menu" className="submenu" ref={this.menuRef}>
                  <Button
                    noButtonClass
                    className="submenu_folder"
                    id="new-folder"
                    onClick={() => {
                      this.handleMenuOptions(this.createNewFolder);
                    }}
                  >
                    New Folder
                  </Button>
                  <Button
                    noButtonClass
                    id="delete-folder"
                    className={submenuButtonClassName}
                    onClick={() => {
                      this.handleMenuOptions(this.deleteFolder);
                    }}
                    disabled={folderId === '0'}
                  >
                    Delete
                  </Button>
                  <Button
                    noButtonClass
                    id="rename-folder"
                    className={submenuButtonClassName}
                    onClick={() => {
                      this.handleMenuOptions(this.renameFolder);
                    }}
                    disabled={folderId === '0'}
                  >
                    Rename
                  </Button>
                </div>
                <div className="overview_selection_wrapper" ref={this.myRef}>
                  {showNewDesign ?
                    (
                      <NewSelectionFolders
                        hideFolders={hideFolders}
                        isWaterfall={overviewSection === Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS}
                        blurDrop={blurDrop}
                        offsetTop={offsetTop}
                        filterFolderId={filterFolderId}
                        rightClick={this.rightClick}
                        starOrRemoveStarOnFolder={this.starOrRemoveStarOnFolder}
                        handleSetOverviewState={this.handleSetOverviewState}
                        handleOpenArchiveSelectionModal={this.handleOpenArchiveSelectionModal}
                        refreshSelections={this.refreshSelections}
                        openAllFolders={openAllFolders}
                        isArchived={selections.find(elem => elem._id === draggedSelectionId)?.isArchived}
                        draggedSelectionId={draggedSelectionId}
                        nestedFolders={nestedFolders}
                        folderId={folderId}
                        handleFolderClicked={this.handleFolderClicked}
                        dropOnFolder={this.dropOnFolder}
                        folderBorderDragOver={folderBorderDragOver}
                        startDraggingFolder={this.startDraggingFolder}
                        draggedFolderId={draggedFolderId}
                        openSelections={openSelections}
                        handleHideFolders={() => this.handleSetOverviewState({ hideFolders: !hideFolders })}
                        loadingFolders={loadingFolders}
                        showLoadingModal={showLoadingModal}
                        handleSetAppState={handleSetAppState}
                        folderSettings={folderSettings}
                        allFolders={folders}
                        featureSelectionFolders={featureSelectionFolders}
                        showEssentialsUpgradeModal={this.showEssentialsUpgradeModal}
                      />
                    ) :
                    (<SelectionFolders
                      hideFolders={hideFolders}
                      isWaterfall={overviewSection === Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS}
                      blurDrop={blurDrop}
                      offsetTop={offsetTop}
                      filterFolderId={filterFolderId}
                      rightClick={this.rightClick}
                      handleSetOverviewState={this.handleSetOverviewState}
                      handleOpenArchiveSelectionModal={this.handleOpenArchiveSelectionModal}
                      refreshSelections={this.refreshSelections}
                      openAllFolders={openAllFolders}
                      isArchived={selections.find(elem => elem._id === draggedSelectionId)?.isArchived}
                      draggedSelectionId={draggedSelectionId}
                      nestedFolders={nestedFolders}
                      folderId={folderId}
                      handleFolderClicked={this.handleFolderClicked}
                      dropOnFolder={this.dropOnFolder}
                      folderBorderDragOver={folderBorderDragOver}
                      startDraggingFolder={this.startDraggingFolder}
                      draggedFolderId={draggedFolderId}
                      openSelections={openSelections}
                      handleHideFolders={() => this.handleSetOverviewState({ hideFolders: !hideFolders })}
                      loadingFolders={loadingFolders}
                      showLoadingModal={showLoadingModal}
                      handleSetAppState={handleSetAppState}
                      folderSettings={folderSettings}
                      allFolders={folders}
                      featureSelectionFolders={featureSelectionFolders}
                      showEssentialsUpgradeModal={this.showEssentialsUpgradeModal}
                    />)}
                  <div className="selection-list">
                    <ToastContainer
                      enableMultiContainer
                      containerId="DeedeeAi_1"
                      limit={1}
                    />

                    {!showLoadingModal &&
                      (
                        <div>
                          {selections === undefined ?
                            null :
                            (
                              <>
                                <SelectionList
                                  selections={selections}
                                  searchField={searchField}
                                  handleNavigator={handleNavigator}
                                  handleOpenDeleteSelectionModal={this.handleOpenDeleteSelectionModal}
                                  handleOpenArchiveSelectionModal={this.handleOpenArchiveSelectionModal}
                                  handleEditSelection={this.handleEditSelection}
                                  hideFolders={hideFolders}
                                  handleSortSelections={this.handleSortSelections}
                                  sortDirection={sortDirection}
                                  sortedProperty={sortedProperty}
                                  startDraggingToFolder={this.startDraggingToFolder}
                                  handleSetOverviewState={this.handleSetOverviewState}
                                  folderBorderDragOver={folderBorderDragOver}
                                  openAllFolders={openAllFolders}
                                  openSelections={openSelections}
                                  handleOpenCopySelectionModal={this.handleOpenCopySelectionModal}
                                  handleOpenSelectionRunLogsModal={this.handleOpenSelectionRunLogsModal}
                                  offsetTop={offsetTop}
                                  featureScheduleSelectionsIsEnabled={featureScheduleSelectionsIsEnabled}
                                  featureSelectionTemplateIsEnabled={featureSelectionTemplateIsEnabled}
                                  featureSelectionFoldersEnabled={featureSelectionFolders}
                                  loadingSelectionsList={loadingSelectionsList}
                                  overviewSection={overviewSection}
                                  inArchivedFolder={filterFolderId === 'archivedSelectionFolderId'}
                                  showCalendarViewForSchedules={this.showCalendarViewForSchedules}
                                  showEssentialsUpgradeModal={this.showEssentialsUpgradeModal}
                                  userPreferences={preferences}
                                />
                                {openDeleteSelectionModalID && (
                                  <DeleteModal
                                    handleCloseDeleteSelectionModal={this.handleCloseDeleteSelectionModal}
                                    handleOpenArchiveSelectionModal={this.handleOpenArchiveSelectionModal}
                                    selection={selections.find(
                                      selection => selection._id === openDeleteSelectionModalID,
                                    )}
                                    handleRemoveSelection={this.handleRemoveSelection}
                                    isWaterfall={overviewSection === Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS}
                                  />
                                )}
                                {archiveSelection?.openArchiveSelectionModalID && (
                                  <ArchiveSelectionModal
                                    handleCloseArchiveSelectionModal={this.handleCloseArchiveSelectionModal}
                                    selection={selections.find(
                                      selection => selection._id === archiveSelection.openArchiveSelectionModalID,
                                    ) || {}}
                                    defaultRestoreFolderId={archiveSelection.defaultRestoreFolderId}
                                    folders={folders}
                                    handleArchiveSelection={this.handleArchiveSelection}
                                    isWaterfall={overviewSection === Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS}
                                    archivedNotWaterfallSelections={archiveSelection.archivedSelections}
                                  />
                                )}
                                {openCopySelectionModalID && (
                                  <CopySelectionModal
                                    selection={selections.find(selection => selection._id === openCopySelectionModalID)}
                                    handleCloseCopySelectionModal={this.handleCloseCopySelectionModal}
                                    handleCopySelection={this.handleCopySelection}
                                    hideCopySelectionModal={hideCopySelectionModal}
                                    isWaterfall={overviewSection === Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS}
                                    selections={selections}
                                    findDuplicateSelection={this.findDuplicateSelection}
                                    handleSetOverviewState={this.handleSetOverviewState}
                                    showEssentialsUpgradeModal={this.showEssentialsUpgradeModal}
                                    showNewDesign={showNewDesign}
                                  />
                                )}
                                {openSelectionRunLogsModalID && (
                                  <SelectionRunLogsModal
                                    selection={selections.find(
                                      selection => selection._id === openSelectionRunLogsModalID,
                                    )}
                                    selectionId={openSelectionRunLogsModalID}
                                    handleCloseSelectionRunLogsModal={this.handleCloseSelectionRunLogsModal}
                                    isWaterfall={overviewSection === Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS}
                                  />
                                )}
                                {showCalendarViewForSchedulesId && (
                                  <CalendarViewForSchedules
                                    selection={selections.find(selection => selection._id ===
                                      showCalendarViewForSchedulesId)}
                                    selectionId={showCalendarViewForSchedulesId}
                                    hideCalendarViewForSchedules={this.hideCalendarViewForSchedules}
                                    isWaterfall={overviewSection === Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS}
                                  />
                                )}
                                {showCalendarViewForAllSchedules && (
                                  <CalendarViewForSchedules
                                    isGeneralView
                                    hideCalendarViewForSchedules={this.handleCloseCalendarView}
                                    isWaterfall={overviewSection === Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS}
                                  />
                                )}
                              </>
                            )}
                        </div>
                      )}
                    {selections && selections.length > 0 ?
                      (
                        <Pagination
                          handleChangePaginationIndex={this.handleChangePaginationIndex}
                          pageItems={pageItems}
                          paginationIndex={paginationIndex}
                        />
                      ) :
                      null}
                  </div>
                </div>
                <div className="feature-advert-modals">
                  <FeatureAdvertContainer
                    feature={featureAdvertModal.feature}
                    showFeatureModal={featureAdvertModal.show}
                    handleCancel={this.cancelEssentialsUpgradeModal}
                  />
                </div>
              </div>
            </Provider>
          )}
        </NewDesignContext.Consumer>
      );
    }
  }
}
Overview.propTypes = {
  /**
   * Prop handleNavigator is passed from App.js and it helps to navigate between Overview and Selection
   */
  handleNavigator: PropTypes.func.isRequired,
  /**
   * It sets the App component`s state
   * This prop will be passed from App.js component
   */
  handleSetAppState: PropTypes.func.isRequired,
  /**
   * Folders for selection save
   */
  folders: PropTypes.instanceOf(Object).isRequired,
  /**
   * Function responsible for refreshing folders
   */
  refreshFolders: PropTypes.func.isRequired,
  /**
   * It gives the id of folder
   */
  folderId: PropTypes.string.isRequired,
  /**
   * Connect parent+children
   */
  getNestedSelectionFolders: PropTypes.func.isRequired,
  /**
   * Checks if the user just logged in or not (mainly for notifications)
   */
  justLoggedIn: PropTypes.bool.isRequired,
  /**
   * Notifications data from App.js
   */
  notifications: PropTypes.instanceOf(Array).isRequired,
  /**
   * Checks if the organization has some unpaid bills
   */
  latePayment: PropTypes.string,
  /**
   * Defines which section in Overview is selected - selections or waterfall
   */
  overviewSection: PropTypes.string.isRequired,
  /**
   * Indicates whether folders for selection are fetched
   */
  loadingFolders: PropTypes.bool.isRequired,
  /**
   * Object with internal folder settings
   */
  folderSettings: PropTypes.instanceOf(Object).isRequired,
  /**
   * Clicked folder Id, used to filter shown (waterfall) selections
   */
  filterFolderId: PropTypes.string,
  /**
   * Handles selected folder id
   */
  handleFilterFolderId: PropTypes.func.isRequired,
  /**
   * Selected pagination index
   */
  paginationIndex: PropTypes.number.isRequired,
  /**
   * Handles selected pagination index
   */
  handlePaginationIndex: PropTypes.func.isRequired,
  /**
   * User preferences
   */
  preferences: PropTypes.object,
  /**
   * Org info from cookie
   */
  orgInfo: PropTypes.object,
  /**
   * Features info from cookie
   */
  featuresInfo: PropTypes.object,
  /**
   * expandedRightBar prop is passed from App.js and it helps to expand the right bar
   */
  expandedRightBar: PropTypes.bool,
};

Overview.defaultProps = {
  /**
   * Indicates default folderId
   */
  folderId: '',
};

export default connect(mapStateToProps(['orgInfo', 'featuresInfo']), null, null, { pure: false })(Overview);
