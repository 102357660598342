import React, { useState, useEffect } from 'react';
import { Dropdown } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './styles.scss';
import ModalTemplate from '../../../shared_v2/ModalTemplate/ModalTemplate';
import Select from '../../../shared_v2/Select/Select';
import Alert from '../../../shared_v2/Alert/Alert';

const FilterSetsModal = ({
  handleCloseFilterSetModal,
  selectedDataExtensions,
  filterSetSelectedSourceValue,
  handleSourceOptionOnChange,
  selectedDataExtensionField,
  handleSelectedFieldOptionOnChange,
  handleFilterSetModalSave,
  handleSelectedSubscription,
  selectedSubscription,
  loadingDataExtensions,
}) => {
  const [selectedDataExtensionFields, setSelectedDataExtensionFields] = useState(null);
  const [selectedDataExtensionsOptions, setSelectedDataExtensionsOptions] = useState(null);
  const [selectedDataExtensionName, setSelectedDataExtensionName] = useState('');
  const [hasNoSelectedDataExtension, setHasNoSelectedDataExtension] = useState(false);
  const [hasNoSelectedField, setHasNoSelectedField] = useState(false);
  const [hasNoSelectedSubscriberValue, setHasNoSelectedSubscriberValue] = useState(false);

  /*
   * Format the data extensions for the dropdown
   */
  const dataExtensionsOptions = selectedDataExtensions
    ?.map(de => ({
      value: String(de.deAlias),
      title: String(de.Name),
      text: String(de.deAlias) + ` (${String(de.Name)})`,
      key: de.ObjectID,
      fields: de?.fields,
      isSendableFieldName: de?.SendableDataExtensionField?.Name,
      subscription: de?.SendableSubscriberField?.Name,
    }));

  const filterSetSourceOptions = dataExtensionsOptions;

  const getSelectedDataExtensionName = () => {
    const selectedDataExtension = dataExtensionsOptions?.find(de => de.value === filterSetSelectedSourceValue);

    return selectedDataExtension?.text || null;
  };

  useEffect(() => {
    const selectedDataExtension = selectedDataExtensions?.find(de => de.deAlias === filterSetSelectedSourceValue);

    const selectedDataExtensionsOptions = selectedDataExtension?.fields
      ?.map(de => ({
        value: de.ObjectID,
        label: String(de.Name),
        text: String(de.Name),
        key: de.ObjectID,
      }));

    setSelectedDataExtensionFields(selectedDataExtensionsOptions);
    setSelectedDataExtensionName(selectedDataExtension?.deAlias);

    if (selectedDataExtension) {
      setSelectedDataExtensionsOptions(selectedDataExtensionsOptions);
    } else {
      setSelectedDataExtensionsOptions(null);
    }
  }, [selectedDataExtensions, filterSetSelectedSourceValue]);

  /**
   * Validates if Data extension is selected if yes call handleFilterSetModalSave
   * @returns {void}
   */
  const handleValidation = () => {
    if (selectedDataExtensions.length === 0 || filterSetSelectedSourceValue === '') {
      setHasNoSelectedDataExtension(true);
    } else if (selectedSubscription === '') {
      setHasNoSelectedDataExtension(false);
      setHasNoSelectedSubscriberValue(true);
    } else if (selectedDataExtensionField === '') {
      setHasNoSelectedField(true);
    } else {
      handleFilterSetModalSave();
    }
  };

  return (
    <ModalTemplate
    id="filter-set-modal-dialog"
    headerId="modal-heading-001"
    noHeaderTitle
    cancelButtonId="relation-modal-cancel"
    handleCancel={handleCloseFilterSetModal}
    saveButtonId="save-relation-modal"
    handleSave={async () => handleValidation()}
    contentId="modal-content-id-41"
    saveButtonTitle={loadingDataExtensions ? 'Creating...' : 'Continue'}
    contentClassName="slds-p-around_medium"
    className="slds-modal_medium"
    >
        <div className="filter-set-modal-container">
            {/* banner */}
            <div className="banner-container">
                In order to add a behavioral Filter Set we need
                to know which Selected Data Extension field contains subscriber information
            </div>

            {/* Selected DataExtensions */}
            <p className="dropdown-title">
                Which Selected Data Extension contains subscriber information?
            </p>
            <Dropdown
              id="filter-set-selected-data-set-dropdown"
              selection
              className="target-data-extension-dropdown searchable-dropdown subscriber-info"
              search
              placeholder="Select Data Extension"
              value={filterSetSelectedSourceValue}
              options={filterSetSourceOptions}
              onChange={handleSourceOptionOnChange}
            />
            {hasNoSelectedDataExtension && (
            <Alert
              className={
                classNames(
                  'mt-5px',
                  { 'alert-visible': hasNoSelectedDataExtension, 'alert-hidden': !hasNoSelectedDataExtension },
                )
              }
              title="Select Data Extension to proceed"
              id="hasNoSelectedDataExtension"
            />
            )}

            {/* Subscribers information */}
            <p className="dropdown-title">
                How does it relate to Subscribers?
            </p>
            <div className="subscribers-container">
            <div>
            <Select
              value={selectedDataExtensionField}
              onChange={e => handleSelectedFieldOptionOnChange(
                e,
                selectedDataExtensionFields,
                selectedDataExtensionName,
              )}
              placeholder="select"
              options={selectedDataExtensionsOptions}
            />
             {hasNoSelectedField && (
            <Alert
            className={
              classNames(
                'mt-5px ',
                { 'alert-visible': hasNoSelectedField, 'alert-hidden': !hasNoSelectedField },
              )
            }
              title="Select Data Extension Field to proceed"
              id="hasNoSelectedField"
            />
             )}
            </div>

            <p className="subtitle">
               of
              <strong className="selected-name">
                { getSelectedDataExtensionName() }
              </strong>
              contains
            </p>
            <div>
            <Select
              value={selectedSubscription}
              onChange={e => handleSelectedSubscription(e.target.value)}
              options={[{
                value: 'SubscriberKey',
                label: 'SubscriberKey',
              },
              {
                value: 'SubscriberID',
                label: 'SubscriberID',
              }]}
            />
            {hasNoSelectedSubscriberValue && (
            <Alert
            className={
              classNames(
                'mt-5px ',
                { 'alert-visible': hasNoSelectedSubscriberValue, 'alert-hidden': !hasNoSelectedSubscriberValue },
              )
            }
              title="Select Subscriber to proceed"
              id="hasNoSelectedSubscriberValue"
            />
            )}
            </div>
            <p className="subtitle">values.</p>

            </div>
        </div>

    </ModalTemplate>
  );
};

FilterSetsModal.propTypes = {
  /**
   * A function to close the modal
   */
  handleCloseFilterSetModal: PropTypes.func.isRequired,
  /**
   * It keeps the selected data extensions for Selection.js
   * selected data extensions are stored as collections in database
   * It will be passed from selectedFilters.js.js
   */
  selectedDataExtensions: PropTypes.instanceOf(Array),
  /**
   * A variable that holds selected DE
   */
  filterSetSelectedSourceValue: PropTypes.string,
  /**
   * Handle change of source option
   */
  handleSourceOptionOnChange: PropTypes.func.isRequired,
  /**
   * Holds selected Data Extension Field
   */
  selectedDataExtensionField: PropTypes.string,
  /**
   * Handle change of data extension
   */
  handleSelectedFieldOptionOnChange: PropTypes.func,
  /**
   * Handles change creation and save of behavioral filter set
   */
  handleFilterSetModalSave: PropTypes.func,
  /**
   * Handle change of subscribers
   */
  handleSelectedSubscription: PropTypes.func,
  /**
   * A variable that holds selected subscriber
   */
  selectedSubscription: PropTypes.string,
  /**
   * shows if data extension is loading
   */
  loadingDataExtensions: PropTypes.bool,

};

export default FilterSetsModal;
