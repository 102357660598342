import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import {
  getMultipleSelectionsById, handleMissingMatchedField,
  handleMissingTargetDataExtension,
  handleUnmappedRequiredFields, setSelectionsForRunChain, startLoadingSelectionId, stopLoadingSelectionId,
}
  from '../../../redux/actions/waterfallSelection/selectionActions';
import { getMultipleTargetDataExtensions, startLoadingTargetDEObjectID }
  from '../../../redux/actions/waterfallSelection/targetDataExtensionActions';
import Util from '../../../util';
import waterfallSelectionUtil from '../../../utils/waterfallSelection/waterfallSelectionUtil';
import ChartResults from './ChartResults/ChartResults';
import SelectionChain from './SelectionChain/SelectionChain';
import './styles.scss';
import Alert from '../../shared/Alert/Alert';
import Constants from '../../../constants/constants';
import WarningAlert from '../../shared/WarningAlert/WarningAlert';

const RunDetails = ({ axiosCancelToken, isArchived }) => {
  // get state of properties from selection and global reducer
  const {
    selectedSelections, selectionChain, selectionsForRunChain, runningQuery,
    runStatusForSelectionChain, waterfallSelectionStatus,
    targetDataExtensions,
  } =
  useSelector(({ selectionReducer, globalReducer, targetDataExtensionReducer }) => ({
    selectedSelections: selectionReducer.selectedSelections,
    selectionChain: selectionReducer.selectionChain,
    selectionsForRunChain: selectionReducer.selectionsForRunChain,
    runningQuery: globalReducer.runningQuery,
    runStatusForSelectionChain: globalReducer.runStatusForSelectionChain,
    waterfallSelectionStatus: globalReducer.waterfallSelectionStatus,
    targetDataExtensions: targetDataExtensionReducer.targetDataExtensions,
  }), shallowEqual);

  const dispatch = useDispatch();

  // defined is there are other selections in selection chain as selected one
  const [otherSelectionsInRunChain, setOtherSelectionsInRunChain] = useState(false);

  // loading status for selections in chain
  const [fetchingSelectionsForRunChain, setFetchingSelectionsForRunChain] = useState(false);

  /**
   * Function that fetch data for Run Detail view
   * @param {array} selectionIdsToFetch - an array with the selection id for retrieve
   * @returns {void}
   */
  const fetchDataForRunChain = async (selectionIdsToFetch) => {
    // define array with selection ids
    const selectionIds = selectionIdsToFetch.map(selection => selection.selectionId);

    // start fetching selections for run detail view
    dispatch(startLoadingSelectionId(selectionIds));

    // fetch selections in run details view
    const selectionResponse = await dispatch(getMultipleSelectionsById(selectionIds, axiosCancelToken.token, true));

    // stop loading selections
    dispatch(stopLoadingSelectionId(selectionIds));

    const { selections } = selectionResponse;

    // check if any selections are not found
    const removedSelectionIds = [];

    selectionIds.forEach((id) => {
      const selectionObject = selections.find(selection => selection._id === id);

      if (!selectionObject) { removedSelectionIds.push(id); }
    });

    // get selected selections ids and its targetDE data
    const targetDEsData = selections.map(selection => ({
      collectionCustomerKey: selection.targetCollectionCustomerKey,
      collectionObjectID: selection.targetCollectionObjectID,
    }
    ));

    // check if any targetDE has been already fetched
    const targetDEsToFetch = targetDEsData.reduce((targetDEsArray, data) => {
      // if ObjectID exists in targetDataExtensions then don't return it
      const fetchedTargetDE = targetDataExtensions.find(targetDE => targetDE.ObjectID === data.collectionObjectID);

      if (!fetchedTargetDE) {
        return [...targetDEsArray, { ...data }];
      }

      return targetDEsArray;
    }, []);

    let targetDEResponse;

    // define array with targetDE ObjectIDs to be fetched
    const targetDEObjectIDsToFetch = targetDEsToFetch.map(target => target.collectionObjectID);

    // fetch multiple target data extensions only if exist in the array
    if (targetDEObjectIDsToFetch?.length) {
      dispatch(startLoadingTargetDEObjectID(targetDEObjectIDsToFetch));
      targetDEResponse = await dispatch(getMultipleTargetDataExtensions(
        targetDEsToFetch,
        axiosCancelToken.token,
      ));
    }

    const selectionsForRunWithData = [];

    let selectionsIdsWithMissingTargetDE = [];

    // just set selections for selection chain using fetched data from selected data extensions
    runStatusForSelectionChain.forEach((status) => {
      // selection from selected one
      const selectionFromSelected = selectedSelections.find(selectionObj => selectionObj.selectionId ===
          status.selectionId);

      // selection from fetched data
      const selectionFromFetched = selections.find(selectionObj => selectionObj._id ===
          status.selectionId);

      // selection which was not found (deleted)
      const removedSelection = removedSelectionIds.find(selectionId => selectionId === status.selectionId);

      if (selectionFromSelected) {
        // for selected selection push its data into an array
        selectionsForRunWithData.push({ ...selectionFromSelected, _id: Util.uuid(32) });
      }

      if (selectionFromFetched) {
        // for fetched selection get targetDE ObjectID
        const selectedDEObjectIDs = waterfallSelectionUtil.getSourceDEsUsedInSelection(
          selectionFromFetched.unionSelections,
        );

        // get matched fields with targetDE
        const matchedFields = [];

        selectionFromFetched.unionSelections.forEach((union) => {
          if (union?.fields?.length) {
            // get all matched fields from each unionSelection
            matchedFields.push(union.fields);
          }
        });

        // push proper data for fetched selection
        selectionsForRunWithData.push({
          ...selectionFromFetched,
          selectedDEObjectIDs,
          _id: Util.uuid(32),
          selectionId: selectionFromFetched._id,
          matchedFields: _.flattenDeep(matchedFields),
        });
      }

      if (removedSelection) {
        // push proper data for selection which was not found
        selectionsForRunWithData.push({
          _id: Util.uuid(32),
          selectionId: removedSelection._id,
          removedSelection: true,
        });
      }
    });

    // save selected selections as selections for run chain
    dispatch(setSelectionsForRunChain(selectionsForRunWithData));

    // if one of target DE does not exists or there is no matched field with targetDE
    if (targetDEResponse?.missingObjectIDs) {
      const { missingObjectIDs } = targetDEResponse;

      // show message and remove selected selections using missing targetCollectionObjectID
      selectionsIdsWithMissingTargetDE = await dispatch(handleMissingTargetDataExtension(
        missingObjectIDs,
        selectionsForRunWithData,
        true,
      ));
    }

    // if there is a selection which does not have a matched field
    if (selectionResponse?.selectionWithoutMatchedField) {
      const { selectionWithoutMatchedField } = selectionResponse;

      // show message and remove selections that do not have a matched field
      await dispatch(handleMissingMatchedField(
        selectionWithoutMatchedField,
        selectionsIdsWithMissingTargetDE,
        true,
        [],
      ));
    }

    // if all the data have been fetched correctly
    if (selections && targetDEResponse?.targetDataExtensions) {
      const dataExtensions = targetDEResponse.targetDataExtensions || [];
      const allDataExtensions = _.flatten([...targetDataExtensions, ...dataExtensions]);

      // check if all required fields of target data extension have been mapped
      await dispatch(handleUnmappedRequiredFields(selectionsForRunWithData, allDataExtensions, [], true));
    }

    // finish loading indicator
    setFetchingSelectionsForRunChain(false);
  };

  useEffect(() => {
    if (selectionChain?.length) {
      // define chain from selected selections
      const chainFromSelectedSelections = selectedSelections.map((selection, index) => ({
        selectionId: selection.selectionId,
        order: index + 1,
      }));

      // if selection has runStatusForSelectionChain data
      if (runStatusForSelectionChain?.length) {
        // create selection chain based on selectionChainStatus
        const selectionsFromSelectionChainStatus = runStatusForSelectionChain.map((status, index) => ({
          selectionId: status.selectionId,
          order: index + 1,
        }));

        // check if there are the same selections for selected as for run chain
        const arrayWithDifferentSelectionsInSelectionChain = _.differenceWith(
          selectionsFromSelectionChainStatus,
          chainFromSelectedSelections,
          _.isEqual,
        );

        // check if there are other selections for selected selections
        const isOtherSelectionsSelected = !((!arrayWithDifferentSelectionsInSelectionChain?.length &&
        selectionsFromSelectionChainStatus?.length === chainFromSelectedSelections?.length));

        if (isOtherSelectionsSelected) {
        // set other selections in run chain state
          setOtherSelectionsInRunChain(true);

          // check what selections and targetDE needs to be fetched if there are not fetched yet
          const selectionIdsToFetch = runStatusForSelectionChain.reduce((selectionsToFetch, status) => {
            if (selectedSelections.find(selection => selection.selectionId === status.selectionId)) {
              return selectionsToFetch;
            }

            return [...selectionsToFetch, { selectionId: status.selectionId }];
          }, []);

          if (!selectionsForRunChain?.length && otherSelectionsInRunChain) {
            if (selectionIdsToFetch?.length) {
              // set loading indicator
              setFetchingSelectionsForRunChain(true);

              /*
               * when existing waterfall selection is open, after clicking on Run Details
               * the selections and targetDE needs to be fetched for run chain
               */
              fetchDataForRunChain(selectionIdsToFetch);
            } else {
              const selectionsForRunWithData = [];
              // just set selections for selection chain using fetched data from selected data extensions

              runStatusForSelectionChain.forEach((status) => {
                const selection = selectedSelections.find(selectionObj => selectionObj.selectionId ===
                status.selectionId);

                if (selection) {
                  selectionsForRunWithData.push({ ...selection, _id: Util.uuid(32) });
                }
              });

              // save selected selections as selections for run chain
              dispatch(setSelectionsForRunChain(selectionsForRunWithData));
            }
          }
        } else {
          // clear warning notification
          setOtherSelectionsInRunChain(false);
          /*
           * if the selection chain has the same selections and orders as selected one
           * and selections for run chain has not been set
           * dispatch action to set it in redux state
           */
          if (!selectionsForRunChain?.length) {
          // define selections for run chain the same as selected one, with changed id
            const selectedSelectionsWithChangedId = selectedSelections?.length ?
              selectedSelections.map(selection => ({
                ...selection,
                _id: Util.uuid(32),
              })) :
              [];

            // save selected selections as selections for run chain
            dispatch(setSelectionsForRunChain(selectedSelectionsWithChangedId));
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [selectionChain, otherSelectionsInRunChain, runStatusForSelectionChain]);

  return (
    <div className="waterfall_view">
      {isArchived && <WarningAlert text={Constants.WARNING_TEXT__ARCHIVED_SELECTION_READ_ONLY} />}

      <div className="warning-notification">
        {otherSelectionsInRunChain &&
          !waterfallSelectionUtil.isWaterfallSelectionRunning(waterfallSelectionStatus, runningQuery) &&
          !fetchingSelectionsForRunChain ?
          (
            <Alert
              type={Constants.ALERT__TYPE__WARNING}
              className="warning-run-different-selections"
              id="overwrite-warning"
              title={(
                <>
                  <b>Selected selections have changed.</b>
                  {' '}
                  These results are presented for the previously selected selections.
                  If you want to generate new records, click the Run button.
                </>
              )}
            />
          ) :
          null}
      </div>
      <div className="waterfall_wrapper">
        <SelectionChain fetchingSelectionsForRunChain={fetchingSelectionsForRunChain} />
        <ChartResults otherSelectionsInRunChain={otherSelectionsInRunChain} />
      </div>
    </div>
  );
};

RunDetails.propTypes = {
  /**
   * It helps to cancel a subscription of an API call to backend
   */
  axiosCancelToken: PropTypes.instanceOf(Object),
  /**
   * Indicates if the waterfall selection is archived or not
   */
  isArchived: PropTypes.bool.isRequired,
};

export default RunDetails;
