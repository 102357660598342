import React from 'react';
import PropTypes from 'prop-types';

const WarningAlert = ({ text }) => {
  return (
    <div
      className="slds-notify slds-notify_alert slds-theme_alert-texture slds-theme_warning target-de-warning"
      role="alert"
    >
      <span className="slds-icon_container slds-icon-utility-warning slds-m-right_x-small">
        <svg className="slds-icon slds-icon_x-small" aria-hidden="true">
          <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#warning" />
        </svg>
      </span>
      <span>
        {text}
      </span>
    </div>
  );
};

WarningAlert.propTypes = {
  /**
   * The text to render
   */
  text: PropTypes.string.isRequired,
};
export default WarningAlert;
