import axios from 'axios';

import Util from '../util';
import Constants from '../constants/constants';

const apiUrl = process.env.REACT_APP_API_URL;

const foldersAPI = {
  /**
   * Get parent folder
   * @param {number} data.businessUnitId - options BU id
   * @param {object} cancelToken - Axios token
   * @returns {object} The parent folder
   */
  getRootDataExtensionFolders: async ({ businessUnitId }, cancelToken) => {
    const businessUnitQuery = businessUnitId ? `?businessUnitId=${businessUnitId}` : '';

    const res = await Util.catch418And403Error(
      axios.get,
      `${apiUrl}/folders/dataextensions${businessUnitQuery}`,
      Util.apiGetCallSettings(cancelToken),
    );

    return res.data.data;
  },
  /**
   * Get children folders
   * @param {object[]} data.parentFolderId - Id of parent folder
   * @param {String} data.contentType - Content type of the folder, used to determine the value of 'shared' parameter.
   * @param {number} data.businessUnitId - optional BU id
   * @param {object} cancelToken - Axios token
   * @returns {array<object>} The children folders
   */
  getChildDataExtensionFolders: async ({ parentFolderId, contentType, businessUnitId }, cancelToken) => {
    const isSharedFolder = contentType === Constants.FOLDER__CONTENT_TYPE__SHARED_DATA ||
        contentType === Constants.FOLDER__CONTENT_TYPE__SHARED_DATAEXTENSION;
    const businessUnitQuery = businessUnitId ? `&businessUnitId=${businessUnitId}` : '';

    const res = await Util.catch418And403Error(
      axios.get,
      `${apiUrl}/folders/dataextensions/${parentFolderId}?filter=true&shared=${isSharedFolder}${businessUnitQuery}`,
      Util.apiGetCallSettings(cancelToken),
    );

    return res.data.data;
  },

  /**
   * Get the path of folders (from parent to folderId)
   * @param {number} folderId -  folder Id for the TargetDE
   * @param {object} cancelToken - Axios token
   * @returns {array} array with parents folderId
   */
  getFoldersPath: async (folderId, cancelToken) => {
    const res = await Util.catch418And403Error(
      axios.get,
      `${apiUrl}/folders/path/${folderId}`,
      Util.apiGetCallSettings(cancelToken),
    );

    return res.data.data;
  },

  /**
   * Gets all the folders in a BU
   * @param {number} data.businessUnitId - options BU id
   * @param {string} data.dataExtensionType - Available or Target DE?
   * @param {object} cancelToken - Axios token
   * @returns {array} array of folders
   */
  getAllFolders: async ({ businessUnitId, dataExtensionType }, cancelToken) => {
    const businessUnitQuery = businessUnitId ? `?businessUnitId=${businessUnitId}` : '';

    // eslint-disable-next-line max-len
    const dataExtensionTypeQuery = `dataExtensionType=${dataExtensionType || Constants.ADMIN_PANEL__SUBMENU__TARGET_DES}`;

    // Send get request
    const res = await Util.catch418And403Error(
      axios.get,
      `${apiUrl}/folders/all${businessUnitQuery}&${dataExtensionTypeQuery}`,
      Util.apiGetCallSettings(cancelToken),
    );

    // Return data
    return res.data.data;
  },

  /**
   * Gets folders settings
   * @param {object} cancelToken - Axios token
   * @param {string} businessUnitId - businessUnitId for the query
   * @returns {object} - Folders settings
   */
  getFoldersSettings: async (cancelToken, businessUnitId) => {
    const businessUnitQuery = businessUnitId ? `?businessUnitId=${businessUnitId}` : '';

    const res = await Util.catch418And403Error(
      axios.get,
      `${apiUrl}/folders/settings${businessUnitQuery}`,
      Util.apiGetCallSettings(cancelToken),
    );

    return res.data.data;
  },

  /**
   * Sets folder settings
   * @param {object} settings - The settings to set
   * @param {number} mode - Mode of the filter settings - Available or Target
   * @param {object} cancelToken - Axios token
   * @returns {object} An object with success/error message
   */
  saveFoldersSettings: async (settings, mode, cancelToken) => {
    const res = await Util.catch418And403Error(
      axios.post,
      `${apiUrl}/folders/settings?mode=${mode}`,
      settings,
      Util.apiPostCallSettings(cancelToken),
    );

    return res.data.data;
  },
};

export default foldersAPI;
