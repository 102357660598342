import axios from 'axios';
import * as rax from 'retry-axios';

import raxConfig from '../configs/raxConfig';
import Util from '../util';

const apiUrl = process.env.REACT_APP_API_URL;

// use retry-axios to automatically retry failed requests
axios.defaults.raxConfig = { ...raxConfig, instance: axios };
rax.attach(axios);

const DataExtensionsAPI = {
  /**
   * Get data of a Data Extension
   * @param {string} customerKey - DE customer key
   * @param {string} objectId - DE objectId
   * @param {object} cancelToken - Token axios
   * @param {number} limit - Number of results
   * @returns {object} The data
   */
  getDataExtensionData: async (customerKey, objectId, cancelToken, limit) => {
    const res = await Util.catch418And403Error(
      axios.get,
      `${apiUrl}/dataextensions/${customerKey}/${objectId}/data?limit=${limit}`,
      Util.apiGetCallSettings(cancelToken),
    );

    return res.data.data;
  },

  /**
   * Get one specific data extensions
   * @param {string} customerKey - DE customer key
   * @param {object} cancelToken - Token axios
   * @returns {object} An object with the properties `data`, `contentType` and `success`
   */
  getDataExtensionFields: async (customerKey, cancelToken) => {
    const res = await Util.catch418And403Error(
      axios.get,
      `${apiUrl}/dataextensions/${customerKey}/fields`,
      Util.apiGetCallSettings(cancelToken),
    );

    return res.data;
  },

  /**
   * Get all data extensions for org
   * @param {object} cancelToken - Token axios
   * @param {string} mode - 1 on Selection Criteria or 2 on Target Definition. Numbers needed for the backend
   * @returns {array<object>} The data extensions
   */
  getDataExtensions: async (cancelToken, mode) => {
    const res = await Util.catch418And403Error(
      axios.get,
      `${apiUrl}/dataextensions?mode=${mode}`,
      Util.apiGetCallSettings(cancelToken),
    );

    return res.data.data;
  },

  /**
   * Get limited batch of data extensions for org
   * @param {object} cancelToken - Token axios
   * @param {string} mode - 1 on Selection Criteria or 2 on Target Definition. Numbers needed for the backend
   * @returns {array<object>} The data extensions
   */
  getLimitedDataExtensions: async (cancelToken, mode) => {
    const res = await Util.catch418And403Error(
      axios.get,
      `${apiUrl}/dataextensions?mode=${mode}&shouldGetLimitedResults=true`,
      Util.apiGetCallSettings(cancelToken),
    );

    return res.data.data;
  },

  /**
   * Get all data extensions for org and skip exclusion
   * @param {object} cancelToken - Token axios
   * @param {string} mode - 1 on Selection Criteria or 2 on Target Definition. Numbers needed for the backend
   * @returns {array<object>} The data extensions
   */
  getDataExtensionsWithExclusionSkipped: async (cancelToken, mode) => {
    const res = await Util.catch418And403Error(
      axios.get,
      `${apiUrl}/dataextensions?mode=${mode}&exclusion=false`,
      Util.apiGetCallSettings(cancelToken),
    );

    return res.data.data;
  },

  /**
   * Retrieves a single dataExtension by its customerKey
   * @param {string} customerKey - CustomerKey of the data extension
   * @param {object} cancelToken - Axios token
   * @returns {object} Data from a single DE retrieved from the backend
   */
  getDataExtension: async (customerKey, cancelToken) => {
    const res = await Util.catch418And403Error(
      axios.get,
      `${apiUrl}/dataextensions/${customerKey}`,
      Util.apiGetCallSettings(cancelToken),
    );

    return res.data.data;
  },

  /**
   * Retrieves multiple dataExtensions by its customerKeys
   * @param {array} customerKeys - array with customer keys
   * @param {object} cancelToken - Axios token
   * @returns {object} Data from a single DE retrieved from the backend
   */
  getMultipleDataExtensions: async (customerKeys, cancelToken) => {
    const postData = { customerKeys };
    const res = await Util.catch418And403Error(
      axios.post,
      `${apiUrl}/dataextensions/multiple`,
      postData,
      Util.apiGetCallSettings(cancelToken),
    );

    return res.data.dataExtensions;
  },

  /**
   * Retrieves many dataExtensions and their fields by their customerKeys
   * @param {array} dataExtensions - array with customer keys
   * @param {object} cancelToken - Axios token
   * @returns {object} Data from a single DE retrieved from the backend
   */
  getManyDataExtensionsWithFields: async (dataExtensions, cancelToken) => {
    const postData = { dataExtensions };
    const res = await Util.catch418And403Error(
      axios.post,
      `${apiUrl}/dataextensions/multiple-fields`,
      postData,
      Util.apiGetCallSettings(cancelToken),
    );

    return res.data.dataExtensions;
  },

  /**
   * Create new (target) data extension
   * @param {object} cancelToken - Token axios
   * @param {number} folderId - Number of the folder DE will be in
   * @param {string} name - Name of new DE
   * @param {string} description - Description
   * @param {object[]} fields - Fields in target definition
   * @param {object} retentionPolicy - Data retention policy
   * @param {object} relationship - Relationship
   * @param {string} contentType - type of folder
   * @param {string} enterpriseBusinessUnitId - enterprise Business Unit Id
   * @returns {object} An object with the properties `newTargetDataExtension` and `success`.
   */
  createDataExtension:
  async (
    cancelToken,
    folderId,
    name,
    description,
    fields,
    retentionPolicy,
    relationship,
    contentType,
    enterpriseBusinessUnitId,
  ) => {
    const postData = {
      folderId,
      name,
      description,
      fields,
      retentionPolicy,
      relationship,
      contentType,
      enterpriseBusinessUnitId,
    };

    const res = await Util.catch418And403Error(
      axios.post,
      `${apiUrl}/dataextensions/create`,
      postData,
      Util.apiPostCallSettings(cancelToken),
    );

    return res.data;
  },

  /**
   * Updates Target Data Extension
   * @param {object} cancelToken - Token axios
   * @param {object[]} fieldsForUpdate - Fields for the update
   * @param {object[]} removedFields - Fields to remove from DE
   * @param {String} customerKey - Customer Key of the Target Data Extension we are going to update
   * @param {Object} newTargetDataExtension - object with the new properties of target data extension
   * @returns {object} An object with the properties `updatedDataExtension` and `success`
   */
  updateTargetDataExtension: async ({
    cancelToken,
    fieldsForUpdate,
    removedFields,
    customerKey,
    newTargetDataExtension,
  }) => {
    const postData = {
      fieldsForUpdate,
      removedFields,
      customerKey,
      newTargetDataExtension,
    };

    const res = await Util.catch418And403Error(
      axios.post,
      `${apiUrl}/dataextensions/update`,
      postData,
      Util.apiPostCallSettings(cancelToken),
    );

    return res.data;
  },

  /**
   * Get data extensions with their fields by given Customer Keys
   * @param {object} cancelToken - Token axios
   * @param {array} dataExtensions - selection's Data Extensions information
   * @param {String} taskType - TaskType of the QueryActivity e.g preview, run, run-waterfall-selection .. etc
   * @returns {array<object>} The data extensions
   */
  getDataExtensionsAndFieldsByCustomerKeys: async (cancelToken, dataExtensions, taskType) => {
    const postData = { dataExtensions, taskType };

    const res = await Util.catch418And403Error(
      axios.post,
      `${apiUrl}/dataextensions/fields`,
      postData,
      Util.apiGetCallSettings(cancelToken),
    );

    return res.data.data;
  },
  /**
   * Search Data Extensions by the name
   * @param {string} name - search string
   * @param {object} cancelToken - Axios token
   * @returns {object} Data Extensions retrieved from the backend
   */
  searchDataExtensions: async (name, cancelToken) => {
    const res = await Util.catch418And403Error(
      axios.get,
      `${apiUrl}/dataextensions/search-by/${name}`,
      Util.apiGetCallSettings(cancelToken),
    );

    return res.data.data;
  },
};

export default DataExtensionsAPI;
