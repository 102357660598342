import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import { Popover, Button, Tooltip } from '@salesforce/design-system-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import axios from 'axios';
import ReactDragListView from 'react-drag-listview';
import { connect } from 'react-redux';

import Constants from '../../constants/constants';
import SwalUtil from '../../utils/swal/swalUtil';
import UsersAPI from '../../api/users';
import './styles.scss';
import mapStateToProps from '../../mapStateToProps';
import Checkbox from '../shared_v2/Checkbox/Checkbox';
import ColumnsIcon from '../../icons_v2/columns.svg';


/**
 * RightBar component for application navigation and user preferences
 * @param {Object} props - Component props
 * @param {Object} props.userPreferences - User preferences object
 * @param {Function} props.updateUserPreferences - Function to update user preferences
 * @param {Function} props.handleNavigator - Function to handle navigation
 * @param {string} props.navigator - Current navigation state
 * @param {Function} props.handleSetOverviewState - Function to set overview state
 * @param {Object} props.selections - Selections object
 * @param {boolean} props.isOverview - Boolean indicating if current view is overview
 * @param {string} props.overviewSection - Current overview section
 * @param {Object} props.userInfo - User information object
 * @param {Function} props.handleExpandRightBar - Function to expand the right bar
 * @param {boolean} props.expandedRightBar - Boolean indicating if the right bar is expanded
 * @returns {JSX.Element} - RightBar component
 */
const RightBar = ({
  userPreferences,
  handleNavigator,
  navigator,
  handleSetOverviewState,
  selections,
  isOverview,
  overviewSection,
  userInfo,
  handleExpandRightBar,
  expandedRightBar,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [isColumnsClicked, setIsColumnsClicked] = useState(false);
  const [checkedColumns, setCheckedColumns] = useState([]);

  const hasReadOnlyAccess = userInfo?.hasReadOnlyAccess;

  const axiosToken = axios.CancelToken.source();

  /**
   * Update user's preferences in the DB
   * @param {Object} preferences - user preferences object
   * @returns {void}
   */
  const updatePreferences = async (preferences) => {
    try {
      await UsersAPI.updatePreferences(
        preferences,
        axiosToken.token,
      );
    } catch (error) {
      console.log('error: ', error);
    }
  };


  /**
   * Remove or add a column from the overview table
   * @param {Object} column - Column object
   * @param {boolean} useInUseEffect - Flag to indicate if function is called in useEffect
   * @param {boolean} isCheckBoxClicked - Flag to indicate if checkbox was clicked
   * @returns {void} - Remove or add column from the overview table
   */
  const removeOrAddColumn = (column, useInUseEffect, isCheckBoxClicked) => {
    const statusColumn = document.getElementsByClassName(`des-lib-datatable-th ${column.name}`)[0];
    const statusCell = document.getElementsByClassName(`des-lib-datatable-td ${column.name}`);

    const checkedColumnsCopy = useInUseEffect ?
      [...userPreferences.selectionOverviewColumnsToBeShown] :
      [...checkedColumns];

    const columnIndex = checkedColumnsCopy.findIndex(obj => obj.name === column.name);

    if ((column.isChecked && !isCheckBoxClicked) ||
      (isCheckBoxClicked && !column.isChecked)) {
      if (!useInUseEffect) {
        statusColumn.style.display = '';

        for (let i = 0; i < statusCell.length; i += 1) {
          statusCell[i].style.display = '';
        }

        checkedColumnsCopy[columnIndex].isChecked = true;
      }
    } else {
      if (statusColumn) statusColumn.style.display = 'none';

      for (let i = 0; i < statusCell.length; i += 1) {
        statusCell[i].style.display = 'none';
      }

      checkedColumnsCopy[columnIndex].isChecked = false;
    }

    if (!useInUseEffect) {
      setCheckedColumns(checkedColumnsCopy);

      try {
        updatePreferences({ selectionOverviewColumnsToBeShown: checkedColumnsCopy });
      } catch (error) {
        if (!axios.isCancel(error)) handleSetOverviewState({ error });
      }
    }
  };

  useEffect(() => {
    if (userPreferences.selectionOverviewColumnsToBeShown) {
      setCheckedColumns(
        [
          ...userPreferences.selectionOverviewColumnsToBeShown,
        ],
      );
    }
  }, [userPreferences]);

  useEffect(() => {
    if (navigator === Constants.NAVIGATION__OVERVIEW) {
      const interval = setInterval(() => {
        const statusColumn = document.getElementsByClassName('des-lib-datatable-th Status')[0];

        if (userPreferences.selectionOverviewColumnsToBeShown && statusColumn) {
          clearInterval(interval);

          userPreferences.selectionOverviewColumnsToBeShown.forEach((column) => {
            removeOrAddColumn(column, true, false);
          });
        }

        if (!userPreferences.selectionOverviewColumnsToBeShown && statusColumn) {
          clearInterval(interval);
        }
      }, 500);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const statusColumn = document.getElementsByClassName('des-lib-datatable-th Status')[0];

    if (userPreferences.selectionOverviewColumnsToBeShown && statusColumn) {
      userPreferences.selectionOverviewColumnsToBeShown.forEach((column) => {
        removeOrAddColumn(column, true, false);
      });
    }

    // eslint-disable-next-line
  }, [selections]);

  /**
   * Handler for the onClick event on the admin button
   * @returns {void}
   */
  const handleClickOnAdminButton = async () => {
    const confirmationNeededFor = [
      Constants.NAVIGATION__SELECTION_CRITERIA,
      Constants.NAVIGATION__TARGET_DEFINITION,
      Constants.NAVIGATION__PREVIEW,
    ];

    if (confirmationNeededFor.includes(navigator)) {
      const res = await SwalUtil.fire({
        title: 'Confirmation needed',
        message: `Are you sure you want to go to the Admin Panel?
        Unsaved changes will be lost.`,
        options: {
          showCancelButton: true,
          allowOutsideClick: false,
        },
      });

      if (res.value) {
        handleNavigator(Constants.NAVIGATION__ADMIN_PANEL);
      }
    } else {
      handleNavigator(Constants.NAVIGATION__ADMIN_PANEL);
    }
  };

  /**
   * Toggle the expanded state of the RightBar
   * @returns {void} - Toggle the expanded state of the RightBar
   */
  const toggleExpand = useCallback(() => {
    handleExpandRightBar(!expandedRightBar);
    setShowTooltip(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleExpandRightBar, expandedRightBar]);

  const handleMouseEnter = useCallback(() => {
    setShowTooltip(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setShowTooltip(false);
  }, []);

  /**
   * Handle the drag end event for reordering columns
   * @param {number} fromIndex - The starting index of the dragged item
   * @param {number} toIndex - The ending index where the item was dropped
   * @returns {void} - Handle the drag end event for reordering columns
   */
  const onDragEnd = (fromIndex, toIndex) => {
    const newColumns = [...checkedColumns];
    const draggedColumn = newColumns.splice(fromIndex, 1)[0];

    newColumns.splice(toIndex, 0, draggedColumn);

    const rows = document.querySelectorAll('#selection-overview-table > thead > tr');
    const cells = document.querySelectorAll('#selection-overview-table > tbody > tr');

    let toIndexIncrement = 2;

    if (fromIndex > toIndex) toIndexIncrement = 1;

    for (let i = 0; i < rows.length; i += 1) {
      rows[i].insertBefore(rows[i].children[fromIndex + 1], rows[i].children[toIndex + toIndexIncrement]);
    }

    for (let i = 0; i < cells.length; i += 1) {
      cells[i].insertBefore(cells[i].children[fromIndex + 1], cells[i].children[toIndex + toIndexIncrement]);
    }

    setCheckedColumns(newColumns);

    try {
      updatePreferences({ selectionOverviewColumnsToBeShown: newColumns });
    } catch (error) {
      if (!axios.isCancel(error)) handleSetOverviewState({ error });
    }
  };

  const editColumnsPopover = (
    <div className="rightbar-v2__columns-menu">
      <div
        className="expand-column-header"
      >
        <span className="expand-column-label">
          Edit and reorder columns
        </span>
      </div>
      <ReactDragListView nodeSelector="div" handleSelector="#drag" onDragEnd={onDragEnd}>
        {checkedColumns.map((column) => {
          if (overviewSection === Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS &&
            column.name === 'Template') return null;

          return (
            <div
              key={column.name}
              className="column-item"
            >
              <Checkbox
                formElementClassName="folder-selection-checkbox"
                name={column.name}
                id={column.name}
                checked={column.isChecked}
                onChange={() => removeOrAddColumn(column, false, true)}
                label={column.name}
              />
              <Button
                title="Drag this card to change its order"
                id="drag"
                className="drag-and-drop"
                iconCategory="utility"
                iconName="drag_and_drop"
                iconSize="xx-small"
                variant="icon"
              />
            </div>
          );
        })}
      </ReactDragListView>
    </div>
  );

  return (
    <div className={classNames('rightbar-v2', { 'rightbar-v2--expanded': expandedRightBar })}>
      <div className="rightbar-v2__content">
        {isOverview && (
          <div className="rightbar-v2__popover">
            <Popover
              body={editColumnsPopover}
              id="edit-columns-popover"
              ariaLabelledby="edit-columns-popover"
              isOpen={isColumnsClicked}
              onRequestClose={() => setIsColumnsClicked(false)}
              align="right"
            >
              <Button
                className="rightbar-v2__button"
                iconCategory="utility"
                variant="base"
                onClick={() => setIsColumnsClicked(!isColumnsClicked)}
                title="Edit Columns"
              >
                <img
                  className="rightbar-v2__button-icon"
                  src={ColumnsIcon}
                  alt="icon"
                />
                {expandedRightBar && <span>Edit Columns</span>}
              </Button>
            </Popover>
          </div>
        )}
        {userInfo !== undefined && userInfo.isAdmin && !hasReadOnlyAccess && (
          <Button
            className="rightbar-v2__button"
            iconCategory="utility"
            iconName="settings"
            iconSize="large"
            variant="icon"
            onClick={handleClickOnAdminButton}
            title="Admin Panel"
          >
            {expandedRightBar && <span>Admin Panel</span>}
          </Button>
        )}
      </div>
      <div
        className="rightbar-v2__toggle"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        <Tooltip
          align={expandedRightBar ? 'left' : 'right'}
          content={expandedRightBar ? 'Collapse the navigation' : 'Expand the navigation'}
          isOpen={showTooltip}
        >
          <Button
            className="rightbar-v2__toggle-button"
            iconCategory="utility"
            iconName={expandedRightBar ? 'chevronright' : 'chevronleft'}
            iconSize="medium"
            variant="icon"
            onClick={toggleExpand}
            onMouseEnter={(e) => {
              e.stopPropagation();
              console.log('Button mouse enter');
              handleMouseEnter();
            }}
            onMouseLeave={(e) => {
              e.stopPropagation();
              console.log('Button mouse leave');
              handleMouseLeave();
            }}
            onFocus={handleMouseEnter}
            onBlur={handleMouseLeave}
            assistiveText={{ icon: expandedRightBar ? 'Collapse navigation' : 'Expand navigation' }}
          />
        </Tooltip>
      </div>
    </div>
  );
};

RightBar.propTypes = {
  /**
   * User preferences
   */
  userPreferences: PropTypes.object,
  /**
   * HandleNavigator prop is passed from App.js and it helps to navigate between the different screen of the App
   */
  handleNavigator: PropTypes.func.isRequired,
  /**
   * Navigator prop is passed from App.js and it determines to know where we where in the App
   */
  navigator: PropTypes.string.isRequired,
  /**
   * Function setting state of overview.js
   */
  handleSetOverviewState: PropTypes.func,
  /**
   * selections which are kept in the db
   */
  selections: PropTypes.instanceOf(Object).isRequired,
  /**
   * Boolean indicating if current view is overview
   */
  isOverview: PropTypes.bool.isRequired,
  /**
   * Current overview section
   */
  overviewSection: PropTypes.string.isRequired,
  /**
   * User info from cookie
   */
  userInfo: PropTypes.object,
  /**
   * Function to expand the right bar
   */
  handleExpandRightBar: PropTypes.func.isRequired,
  /**
   * Boolean indicating if the right bar is expanded
   */
  expandedRightBar: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps(['userInfo']), null, null, { pure: false })(RightBar);
