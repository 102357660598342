import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import ToggleButton from '../../../../shared/ToogleButton/ToggleButton';
import NewAutoCreatedTargetDE from '../../../TargetDefinition/NewAutoCreatedTargetDE/NewAutoCreatedTargetDE';

function SelectionTemplateTargetDE({
  targetDE,
  targetDataExtensions,
  toggleCopy,
  handleToggleCopy,
  handleSetNewCopyState,
  loadingFoldersForCopy,
}) {
  // check if targetDE is available
  const isTargetDEAvailable = targetDE !== null;

  // class names for the target de container
  const targetDEClass = classNames('template-copy-container__target-de', { 'disabled-text': !toggleCopy });

  // class names for the toggle button
  const toggleTitleClass = classNames(
    'template-copy-container__toggle__title',
    { 'disabled-text': !isTargetDEAvailable },
  );

  // class names for the toggle button
  const toggleButtonClass = classNames('template-copy-container__toggle__button', { disabled: !isTargetDEAvailable });

  return (
    <div className="template-copy-container">
      <div className="template-copy-container__toggle">
        <p
          className={toggleTitleClass}
        >
          Copy Target Data Extension
        </p>
        <ToggleButton
          className={toggleButtonClass}
          name="select-template-copy"
          checked={toggleCopy && isTargetDEAvailable}
          onChange={handleToggleCopy}
          disabled={!isTargetDEAvailable}
          checkboxOffTitle={isTargetDEAvailable ? 'Off' : 'Disabled'}
        />
      </div>
      {targetDE ?
        (
          <div
            className={targetDEClass}>
            <NewAutoCreatedTargetDE
              isCopySelectionModal
              isChangeLocation
              newTargetDataExtension={targetDE}
              selectCopyTargetDE={toggleCopy}
              targetDataExtensions={targetDataExtensions}
              handleSetNewCopyState={handleSetNewCopyState}
              loadingFoldersForCopy={loadingFoldersForCopy}
            />
          </div>
        ) :
        <p className="no-target-DE-text">
          There is no target data extension in this selection.
          The selection will be copied without a target data extension.
        </p>}

    </div>
  );
}

SelectionTemplateTargetDE.propTypes = {
  /**
   * Target data extension
   */
  targetDE: PropTypes.object,
  /**
   * Boolean value to check if copy is toggled
   */
  toggleCopy: PropTypes.bool,
  /**
   * Function to toggle copy
   */
  handleToggleCopy: PropTypes.func,
  /**
   * Array of target data extensions
   */
  targetDataExtensions: PropTypes.array,
  /**
   * Function to set new copy state for new auto created target DE
   */
  handleSetNewCopyState: PropTypes.func,
  /**
   * Boolean value to check if loading folders for copy
   */
  loadingFoldersForCopy: PropTypes.bool,
};

export default SelectionTemplateTargetDE;
