import React, { Component } from 'react';
import moment from 'moment';
import axios from 'axios';
import PropTypes from 'prop-types';
import './styles.scss';
import { parseCronExpression } from 'cron-schedule';
import { CronJob } from 'cron';
import { Calendar, DateObject } from 'react-multi-date-picker';

import Button from '../../../shared/Button/Button';
import Spinner from '../../../shared/Spinner/Spinner';
import Util from '../../../../util';
import Constants from '../../../../constants/constants';
import ModalTemplate from '../../../shared/ModalTemplate/ModalTemplate';
import timeUtil from '../../../../utils/time/timeUtil';
import ScheduleAPI from '../../../../api/schedule';

class CalendarViewForSchedules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateValues: null,
      daysMap: {},
      dayId: null,
      selectionsSchedulesMap: {},
      showSelectionSchedules: false,
      schedulesCalendarLoading: false,
      schedulesCalendarIsReady: false,
      error: null,
      currentMonth: moment().month(),
      currentYear: moment().year(),
    };

    // Create 'CancelToken' for further cancellation of memory leak
    this.axiosCancelToken = axios.CancelToken.source();
  }

  /**
   * Fetch data while loading modal
   * @returns {void}
   */
  async componentDidMount() {
    await this.getSchedules();
  }

  getSchedulesData = () => {
    const { isWaterfall } = this.props;

    if (isWaterfall) {
      return ScheduleAPI.getWaterfallSchedules(this.axiosCancelToken.token);
    }

    return ScheduleAPI.getSchedules(this.axiosCancelToken.token);
  };

  getSchedules = async () => {
    this.setState({ schedulesCalendarLoading: true });
    const { selection, isGeneralView } = this.props;

    const schedules = await this.getSchedulesData();
    const enabledSelections = (schedules || [])
      .filter(({ selection }) => !!selection)
      .map(({ selection }) => ({ ...selection }));

    if (isGeneralView) {
      const resultingSchedules = enabledSelections.reduce((data, selection) => {
        if (selection.scheduledRun?.mode === Constants.SCHEDULE_SELECTION__MODE__ONCE) {
          const { dateValue, daysMap } = this.generateNonRecurringSchedules(selection);

          data.dates = [...data.dates, dateValue];
          data.daysMap = { ...data.daysMap, ...daysMap };
          data.selectionsSchedulesMap = { ...data.selectionsSchedulesMap, [selection._id]: daysMap };
        } else {
          const schedProps = this.generateUpcomingRepeatingSchedules(selection);
          const { dateValues, daysMap } = schedProps;

          data.dates = [...data.dates, ...dateValues];
          data.daysMap = { ...data.daysMap, ...daysMap };
          data.selectionsSchedulesMap = { ...data.selectionsSchedulesMap, [selection._id]: daysMap };
        }

        return data;
      }, { dates: [], daysMap: {}, selectionsSchedulesMap: {} });

      this.setState({
        dateValues: resultingSchedules.dates,
        daysMap: resultingSchedules.daysMap,
        selectionsSchedulesMap: resultingSchedules.selectionsSchedulesMap,
        enabledSelections,
        schedulesCalendarLoading: false,
      });
    } else {
      if (selection.scheduledRun.mode === Constants.SCHEDULE_SELECTION__MODE__ONCE) {
        const { dateValue, daysMap } = await this.generateNonRecurringSchedules(selection);

        this.setState({ dateValues: dateValue, daysMap, schedulesCalendarLoading: false });
      } else {
        const { dateValues, daysMap } = await this.generateUpcomingRepeatingSchedules(selection);

        this.setState({ dateValues, daysMap, schedulesCalendarLoading: false });
      }
    }
  };

  /**
   * Generates non-recurring schedule for a selection
   * @param {object} selection - The selection whose schedule is being generated
   * @returns {object} - The generated schedule and daysMap
   */
  generateNonRecurringSchedules = (selection) => {
    const dateValue = Util.buildDateTime(
      selection.scheduledRun.runOnce.dateValue,
      selection.scheduledRun.runOnce.timeValue,
      selection.scheduledRun.UTCOffset,
    );

    const day = dateValue.toDateString();
    const hour = Util.addLeadingZero(dateValue.getHours());
    const minutes = Util.addLeadingZero(dateValue.getMinutes());
    const timeString = hour + ':' + minutes;

    const daysMap = { [day]: [timeString] };

    return { dateValue, daysMap };
  };

  /**
   * Generates recurring schedules for a selection
   * @param {object} selection.scheduledRun - The scheduledRun object to generate schedules from
   * @returns {object} - The generated 100 schedules and daysMap
   */
  generateUpcomingRepeatingSchedules = ({ scheduledRun }) => {
    const { currentMonth, currentYear } = this.state;
    const utcOffset = scheduledRun?.UTCOffset;
    const runMode = scheduledRun?.runRepeat?.repeatMode;

    const startDate = moment().month(currentMonth).year(currentYear);
    const cronExpression = Util.buildCronExpression(scheduledRun);

    const cron = parseCronExpression(cronExpression);

    // Cron schedule package doesn't consider timezone, so we need to add it manually
    const timezoneOffset = new Date().getTimezoneOffset() / 60;

    const offsetHour = moment().hour() + timezoneOffset;

    const cronStartDate = moment(startDate).set({
      hour: offsetHour,
    })?._d;

    /**
     * TODO: in case of performance degradation, come up with better solution rather than
     * generating schedules for hourly case
     */
    const nextSchedules = cron.getNextDates(744, cronStartDate);

    const dateValues = nextSchedules.map(date => (
      runMode === Constants.SCHEDULE_SELECTION__REPEAT_MODE__HOURLY ?
        timeUtil.getOffsetDate(date) :
        timeUtil.getOffsetDate(date, utcOffset)
    ));

    const daysMap = dateValues.reduce((obj, date) => {
      const dateObject = moment(date).toDate();

      const day = dateObject?.toDateString();
      const hour = Util.addLeadingZero(dateObject?.getHours());
      const minutes = Util.addLeadingZero(dateObject?.getMinutes());
      const timeString = hour + ':' + minutes;

      if (obj[day]) {
        obj[day].push(timeString);
      } else {
        obj[day] = [timeString];
      }

      return obj;
    }, {});

    return { dateValues, daysMap };
  };

  /**
   * Event handler for when a date or a scheduled selection is clicked
   * @param {Date} date - The clicked date
   * @param {string} selectionId - Id of the the clicked scheduled selection
   * @returns {void}
   */
  handleShowScheduleTimes = (date, selectionId) => {
    const { daysMap } = this.state;
    const { isGeneralView } = this.props;

    if (selectionId) {
      this.setState({ showScheduleTimes: true, selectionId, showSelectionSchedules: false });
    } else if (isGeneralView) {
      this.setState({
        showSelectionSchedules: true,
        dayId: date.toDate?.().toDateString(),
        showScheduleTimes: false,
      });
    } else {
      if (daysMap[date.toDate?.().toDateString()]) {
        this.setState({ showScheduleTimes: true, dayId: date.toDate?.().toDateString() });
      }
    }
  };

  /**
   * onClick event handler for close button
   * @returns {void}
   */
  handleHideScheduleTimes = () => this.setState({
    showScheduleTimes: false, dayId: null, showSelectionSchedules: false,
  });

  /**
   * onClick event handler for back button
   * @returns {void}
   */
  handleBackToScheduledSelections = () => this.setState({
    showScheduleTimes: false, showSelectionSchedules: true,
  });

  /**
   * Generates schedule description text
   * @returns {string} - The generated text
   */
  getScheduleDescriptionText = () => {
    const {
      dayId, showSelectionSchedules, showScheduleTimes, selectionId, enabledSelections,
    } = this.state;
    const { selection, isGeneralView } = this.props;

    let modeSpecificText = '';

    if (isGeneralView && showSelectionSchedules) {
      return `Currently displaying Selections scheduled to run on ${new Date(dayId).toLocaleDateString('en-us', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })}.`;
    }

    if (isGeneralView && !showScheduleTimes) {
      return 'Selections are scheduled to run on the days highlighted in the calendar below.';
    }

    const { scheduledRun } = selection || enabledSelections?.find(sel => sel._id === selectionId) || {};

    if (scheduledRun?.mode === Constants.SCHEDULE_SELECTION__MODE__ONCE) {
      const { runOnce: { dateValue, timeValue }, UTCOffset } = scheduledRun;
      const date = Util.buildDateTime(
        dateValue,
        timeValue,
        UTCOffset,
      );

      const dateString = date?.toLocaleDateString('en-us', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });

      const hour = Util.addLeadingZero(date?.getHours());
      const minutes = Util.addLeadingZero(date?.getMinutes());
      const timeString = hour + ':' + minutes;

      modeSpecificText = `Once on ${dateString} at ${timeString}.`;
    } else {
      const { runRepeat = {}, UTCOffset = '' } = scheduledRun || {};

      modeSpecificText = this.generateRepeatModeText(runRepeat, UTCOffset);
    }

    return modeSpecificText;
  };

  /**
   * Generates repeatMode text
   * @param {object} utcOffset - The utcOffset of the schedule run
   * @returns {string} - The generated text
   */
  generateRepeatModeText = ({
    repeatMode = '',
    minutesValue,
    hoursValue,
    daysOfWeek = [],
    daysValue = null,
    monthsValue = null,
  }, utcOffset) => {
    const dateFromHours = new Date().setHours(hoursValue);
    const offSetDate = timeUtil.getOffsetDate(dateFromHours, utcOffset);

    const getRepeatHours = () => {
      if (repeatMode === Constants.SCHEDULE_SELECTION__REPEAT_MODE__HOURLY) {
        return hoursValue;
      }

      return offSetDate?.getHours();
    };

    const repeatHoursValue = getRepeatHours();

    const daysOfWeekArray = timeUtil.returnDaysOfWeek();
    const daysString = daysOfWeekArray.filter((_, i) => daysOfWeek.includes(i < 6 ? i + 1 : 0)).join(', ');

    // Add leading zero to hours and minutes
    const minutesValueWithLeadingZero = Util.addLeadingZero(+minutesValue);
    const hoursValueWithLeadingZero = Util.addLeadingZero(+repeatHoursValue);

    switch (repeatMode) {
      case Constants.SCHEDULE_SELECTION__REPEAT_MODE__HOURLY:
        return `Every ${repeatHoursValue} hour${repeatHoursValue > 1 ? 's' : ''} on minute ${minutesValue}.`;
      case Constants.SCHEDULE_SELECTION__REPEAT_MODE__DAILY:
        // eslint-disable-next-line max-len
        return `Every ${daysValue} day${daysValue > 1 ? 's' : ''} at ${hoursValueWithLeadingZero}:${minutesValueWithLeadingZero} from the 1st day throughout the month.`;
      case Constants.SCHEDULE_SELECTION__REPEAT_MODE__WEEKLY:
        return `Every ${daysString} at ${hoursValueWithLeadingZero}:${minutesValueWithLeadingZero}.`;
      case Constants.SCHEDULE_SELECTION__REPEAT_MODE__MONTHLY:
        // eslint-disable-next-line max-len
        return `On day ${daysValue} of every ${monthsValue} month${monthsValue > 1 ? 's' : ''} at ${hoursValueWithLeadingZero}:${minutesValueWithLeadingZero}.`;
      default: return '';
    }
  };

  /**
   * Generates next run date text
   * @returns {JSX.Element|null} - The next run component or null if run mode is once
   */
  getNextRunDateText = () => {
    const {
      dateValues, selectionId, enabledSelections, showScheduleTimes,
    } = this.state;

    const { isGeneralView } = this.props;

    let { selection } = this.props;

    let dateObject;

    if (isGeneralView) {
      if (!showScheduleTimes) return null;

      selection = enabledSelections.find(sel => sel._id === selectionId);

      if (selection?.scheduledRun?.mode === Constants.SCHEDULE_SELECTION__MODE__REPEAT) {
        const cronExpression = Util.buildCronExpression(selection.scheduledRun);

        const cron = new CronJob(cronExpression, () => {}, null, true, selection.scheduledRun.timezone);

        dateObject = cron.nextDates(1)[0].toJSDate();
      } else return null;
    } else {
      if (selection?.scheduledRun?.mode === Constants.SCHEDULE_SELECTION__MODE__REPEAT) {
        dateObject = new Date(dateValues?.[0]);
      } else return null;
    }

    const dateString = dateObject?.toLocaleDateString('en-us', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });

    const hour = Util.addLeadingZero(dateObject?.getHours());
    const minutes = Util.addLeadingZero(dateObject?.getMinutes());
    const timeString = hour + ':' + minutes;

    const text = `${dateString} at ${timeString}.`;

    const isValidDate = moment(dateObject).isValid();

    return (
      <div className="next-run-date">
        <span style={{ fontWeight: '800' }}>Next run:</span>
        {' '}
        {isValidDate ? text : 'Loading next run date...'}
      </div>
    );
  };

  formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-us', {
      weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',
    });
  };

  render() {
    const {
      dateValues,
      dayId,
      daysMap,
      showScheduleTimes,
      selectionId,
      selectionsSchedulesMap,
      showSelectionSchedules,
      enabledSelections,
      schedulesCalendarLoading,
      schedulesCalendarIsReady,
      error,
    } = this.state;

    const {
      hideCalendarViewForSchedules,
      selection,
      isGeneralView,
      isWaterfall,
    } = this.props;

    const scheduleTimesToRender = isGeneralView && selectionId ? selectionsSchedulesMap[selectionId] : daysMap;

    const firstDayOfTheMonth = moment().startOf('month').format('YYYY-MM-DD');

    if (error) {
      throw error;
    }

    return (
      <ModalTemplate
        id="calendar-view-modal-dialog"
        containerClassName="slds-modal__container"
        headerId="calendar-view-header"
        headerTitleHTML={isGeneralView ?
          null :
          `<span>Calendar view for <span style="font-weight: bold;">${selection?.name}</span></span>`}
        headerTitle={isGeneralView ?
          `Calendar view for ${isWaterfall ? 'Waterfall ' : ''}Selections` :
          null}
        footerId="selection-run-logs-footer"
        cancelButtonId="selection-run-logs-cancel-btn"
        handleCancel={hideCalendarViewForSchedules}
        cancelButtonTitle="Close"
      >
        <div
          className="modal-container"
        >
          {schedulesCalendarLoading ?
            (
              <Spinner
                size={Constants.SPINNER__SIZE__MEDIUM}
                loadingText="Calendar is loading..."
                loadingTextClassName="loading-text"
              />
            ) :
            <>
              <div className="schedule-description">
                {(!isGeneralView || showScheduleTimes) && schedulesCalendarIsReady && (
                    <span style={{ fontWeight: '800' }}>
                      Run schedule:
                      {' '}
                    </span>
                )}

                {schedulesCalendarIsReady && this.getScheduleDescriptionText()}

              </div>

              {schedulesCalendarIsReady && this.getNextRunDateText()}

              <div
                className="main-content"
                style={showScheduleTimes ? {} : { justifyContent: 'center' }}
              >
                <Calendar
                  value={dateValues}
                  readOnly
                  onReady={() => this.setState({ schedulesCalendarIsReady: true })}
                  minDate={firstDayOfTheMonth}
                  onMonthChange={date => this.setState({ currentMonth: new DateObject(date)?.month?.number })}
                  onYearChange={date => this.setState({ currentYear: new DateObject(date)?.year?.number })}
                  mapDays={({ date }) => {
                    if (daysMap?.[date.toDate?.().toDateString()]) {
                      return {
                        onClick: () => this.handleShowScheduleTimes(date),
                        style: {
                          backgroundColor: (showSelectionSchedules || showScheduleTimes) &&
                            date.toDate?.().toDateString() !== dayId && '#7fdbff',
                        },
                      };
                    }

                    return {
                      disabled: true,
                    };
                  }}
                />

                {!schedulesCalendarIsReady &&
                  (
                    <Spinner
                      size={Constants.SPINNER__SIZE__MEDIUM}
                      loadingText="Calendar is loading..."
                      loadingTextClassName="loading-text"
                    />
                  )}

                {showSelectionSchedules && schedulesCalendarIsReady && (
                  <div className="scheduled-selections-container">
                    <div className="scheduled-date">{this.formatDate(dayId)}</div>
                    <div className="scheduled-selections-header">Scheduled Selections</div>
                    <div className="scheduled-selections">
                      {enabledSelections.map((sel) => {
                        return selectionsSchedulesMap[sel._id][dayId] && (
                          // eslint-disable-next-line max-len
                          <div
                            key={sel._id}
                            onClick={() => this.handleShowScheduleTimes(null, sel._id)}
                            className="scheduled-selection"
                          >
                            {sel.name}
                          </div>
                        );
                      })}
                    </div>

                    <div className="buttons-container">
                      <Button
                        buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
                        className="slds-button_icon-border-filled close-scheduled-times"
                        title="Close"
                        onClick={this.handleHideScheduleTimes}
                      >
                        Close
                      </Button>
                    </div>
                  </div>
                )}

                {showScheduleTimes && schedulesCalendarIsReady && (
                  <div className="scheduled-times-container">
                    <div className="scheduled-date">
                      {this.formatDate(dayId)}
                    </div>
                    <div className="scheduled-selections-header">
                      Run time(s) for:
                      <br />
                      {isGeneralView ?
                        enabledSelections.find(sel => sel._id === selectionId)?.name :
                        selection.name}
                    </div>

                    <div className="scheduled-times">
                      {scheduleTimesToRender[dayId].map(time => (
                        <div key={time} className="scheduled-time">{time}</div>
                      ))}
                    </div>
                    <div className="buttons-container">
                      {isGeneralView && (
                        <Button
                          buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
                          className="slds-button_icon-border-filled show-scheduled-selections"
                          title="Back"
                          onClick={this.handleBackToScheduledSelections}
                        >
                          Back
                        </Button>
                      )}
                      <Button
                        buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
                        className="slds-button_icon-border-filled close-scheduled-times"
                        title="Close"
                        onClick={this.handleHideScheduleTimes}
                      >
                        Close
                      </Button>
                    </div>
                  </div>
                )}
              </div>

              {schedulesCalendarIsReady &&
                (<div className="calendar_notice">
                  <p className="calendar_notice__text">*All times shown here are in your local timezone:</p>
                  <p className="calendar_notice__timezone">{Util.getUserTimezone()?.text || ''}</p>
                 </div>)}
            </>}
        </div>
      </ModalTemplate>
    );
  }
}

CalendarViewForSchedules.propTypes = {
  /**
   * Selection for which the modal was opened
   */
  selection: PropTypes.instanceOf(Object),
  /**
   * Indicates whether we're showing schedules for all selections
   */
  isGeneralView: PropTypes.bool,
  /**
   * It closes this Modal
   */
  hideCalendarViewForSchedules: PropTypes.func.isRequired,
  /**
   * Defines whether the Waterfall Selections section is selected in the Overview
   */
  isWaterfall: PropTypes.bool,
};

export default CalendarViewForSchedules;
