import {
  SET_DATA_SET_NAME,
  SET_DATA_EXTENSIONS,
  SET_PREDEFINED_RELATIONS,
  SET_PREDEFINED_RELATIONS_MAP,
  SET_SELECTED_DATA_EXTENSIONS,
  SET_SELECTED_DES_TREE,
  SET_RELATIONS,
  SET_MODAL_DATA_EXTENSIONS,
  CLEAR_DATA_SET_STATE,
} from '../../actions/types';

const initialState = {
  dataSetName: '',
  dataExtensions: [],
  predefinedRelations: [],
  predefinedRelationsMap: {},
  selectedDataExtensions: [],
  selectedDEsTree: {},
  relations: [],
  modalDataExtensions: {},
};

/**
 * Function that takes an action and the previous state of the application and returns the new state
 * @param {object} state - contain initial and final state of data
 * @param {object} action - return the action object
 * @returns {object} updated state
 */
const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATA_SET_NAME:
      return {
        ...state,
        dataSetName: action.payload,
      };
    case SET_DATA_EXTENSIONS:
      return {
        ...state,
        dataExtensions: action.payload,
      };
    case SET_PREDEFINED_RELATIONS:
      return {
        ...state,
        predefinedRelations: action.payload,
      };
    case SET_PREDEFINED_RELATIONS_MAP:
      return {
        ...state,
        predefinedRelationsMap: action.payload,
      };
    case SET_SELECTED_DATA_EXTENSIONS:
      return {
        ...state,
        selectedDataExtensions: action.payload,
      };
    case SET_SELECTED_DES_TREE:
      return {
        ...state,
        selectedDEsTree: action.payload,
      };
    case SET_RELATIONS:
      return {
        ...state,
        relations: action.payload || [],
      };
    case SET_MODAL_DATA_EXTENSIONS:
      return {
        ...state,
        modalDataExtensions: action.payload,
      };
    case CLEAR_DATA_SET_STATE:
      return { ...initialState };
    default:
      return state;
  }
};

export default globalReducer;
