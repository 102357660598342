import axios from 'axios';

import Util from '../util';

const apiUrl = process.env.REACT_APP_API_URL;

const SettingsAPI = {
  /**
   * Get notification emails for current organisation and logged in BU
   * @param {object} cancelToken - Token axios
   * @returns {Array<object>} array of notification emails
   */
  getNotificationEmails: async (cancelToken) => {
    const res = await Util.catch418And403Error(
      axios.get,
      `${apiUrl}/notification-emails`,
      Util.apiGetCallSettings(cancelToken),
    );

    return res?.data?.data;
  },

  /**
   * Create a new notification email based on the given parameters
   * @param {object} postData - object of data
   * @param {object} cancelToken - Token axios
   * @returns {object} Newly created notification email
   */
  createNotificationEmail: async (postData, cancelToken) => {
    const res = await Util.catch418And403Error(
      axios.post,
      `${apiUrl}/notification-emails`,
      postData,
      Util.apiPostCallSettings(cancelToken),
    );

    return res.data;
  },

  /**
   * Delete a notification email based the on a given id
   * @param {string} id - Id of the notification email that's going to be deleted
   * @param {object} cancelToken - Axios token
   * @returns {object} Deleted notification email
   */
  deleteNotificationEmail: async (
    id,
    cancelToken,
  ) => {
    const res = await Util.catch418And403Error(
      axios.delete,
      `${apiUrl}/notification-emails/${id}`,
      Util.apiPostCallSettings(cancelToken),
    );

    return res.data;
  },

  /**
   * retrieves data retention settings
   * @param {object} cancelToken - Axios token
   * @returns {object} - Data retention settings object
   */
  getDataRetentionSettings: async (cancelToken) => {
    const res = await Util.catch418And403Error(
      axios.get,
      `${apiUrl}/organisations/data-retention-settings`,
      Util.apiGetCallSettings(cancelToken),
    );

    return res?.data?.data || {};
  },

  /**
   * retrieves send relationship settings
   * @param {object} cancelToken - Axios token
   * @returns {object} - Send relationship settings object
   */
  getSendRelationshipSettings: async (cancelToken) => {
    const res = await Util.catch418And403Error(
      axios.get,
      `${apiUrl}/organisations/send-relationship-settings`,
      Util.apiGetCallSettings(cancelToken),
    );

    return res?.data?.data || {};
  },

  /**
   * Add or update data retention settings
   * @param {object} postData - object of data
   * @param {object} cancelToken - Axios token
   * @returns {object} - Data retention settings object
   */
  addOrUpdateDataRetentionSettings: async (postData, cancelToken) => {
    const res = await Util.catch418And403Error(
      axios.post,
      `${apiUrl}/organisations/data-retention-settings`,
      postData,
      Util.apiPostCallSettings(cancelToken),
    );

    return res?.data?.data || {};
  },

  /**
   * Add or update send relationship settings
   * @param {object} postData - object of data
   * @param {object} cancelToken - Axios token
   * @returns {object} - Data retention settings object
   */
  addOrUpdateSendRelationshipSettings: async (postData, cancelToken) => {
    const res = await Util.catch418And403Error(
      axios.put,
      `${apiUrl}/organisations/send-relationship-settings`,
      postData,
      Util.apiPostCallSettings(cancelToken),
    );

    return res?.data?.data || {};
  },

  /**
   * retrieves data retention settings for ACDE
   * @param {object} cancelToken - Axios token
   * @returns {object} - Data retention settings object
   */
  getACDEDataRetentionSettings: async (cancelToken) => {
    const res = await Util.catch418And403Error(
      axios.get,
      `${apiUrl}/organisations/default-retention-settings`,
      Util.apiGetCallSettings(cancelToken),
    );

    return res?.data?.data || {};
  },

  /**
   * Add or updated data retention settings for ACDE
   * @param {object} postData - object of data
   * @param {object} cancelToken - Axios token
   * @returns {object} - Data retention settings object
   */
  addOrUpdateACDEDataRetentionSettings: async (postData, cancelToken) => {
    const res = await Util.catch418And403Error(
      axios.put,
      `${apiUrl}/organisations/default-retention-settings`,
      postData,
      Util.apiPostCallSettings(cancelToken),
    );

    return res?.data?.data || {};
  },
};

export default SettingsAPI;
