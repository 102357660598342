import axios from 'axios';

import Util from '../util';

const apiUrl = process.env.REACT_APP_API_URL;

const AuditLogAPI = {
  /**
   * Create job for generating audit log CSV
   * @param {object} postData - object of audit log data
   * @param {object} cancelToken - Token axios
   * @returns {object} generated csv log job
   */
  generateAuditLogCSV: async (postData, cancelToken) => {
    const res = await Util.catch418And403Error(
      axios.post,
      `${apiUrl}/audit-logs/generate-csv`,
      postData,
      Util.apiPostCallSettings(cancelToken),
    );

    return res.data.data;
  },

  /**
   * Get valid generated csv log jobs with link to csv
   * @param {object} postData - object of audit log data
   * @param {object} cancelToken - Token axios
   * @returns {object} Complete csv generated logs
   */
  getGeneratedCSVLogs: async (postData, cancelToken) => {
    const res = await Util.catch418And403Error(
      axios.post,
      `${apiUrl}/audit-logs/generated-csv-logs/`,
      postData,
      Util.apiPostCallSettings(cancelToken),
    );

    return res.data.data;
  },

};

export default AuditLogAPI;
