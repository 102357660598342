import React, { useState } from 'react';
import { Provider, connect } from 'react-redux';
import PropTypes from 'prop-types';

import mapStateToProps from '../../../mapStateToProps';
import store from '../../../redux/store/store';
import SelectionsAPI from '../../../api/selections';
import Constants from '../../../constants/constants';
import ModalTemplate from '../../shared_v2/ModalTemplate/ModalTemplate';
import SelectionName from '../Navbar/SelectionName/SelectionName';
import FeatureAdvertContainer from '../../../components/AdminPanel/FeatureAdvertContainer/FeatureAdvertContainer';
import Features from '../../../features';

import './styles.scss';

const SelectionNameModal = ({
  folders,
  handleSetAppState,
  currentSelectionId,
  axiosCancelToken,
  folderId,
  handleSetSelectionName,
  handleSetSelectionTemplate = () => {},
  selectionName,
  saveSelection,
  handleNavigator,
  handleSetSelectionRequestDone,
  isWaterfall,
  cloneSelectionState,
  backToWaterFall,
  targetDataExtensions,
  selectionTemplate,
  featureAdvertModal,
  showEssentialsUpgradeModal,
  cancelEssentialsUpgradeModal,
  featuresInfo,
}) => {
  const [nameSelectionError, setNameSelectionError] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [toggleCopy, setToggleCopy] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [copyState, setCopyState] = useState({
    loadingFoldersForCopy: false,
    copyTargetDE: null,
    nameDEError: false,
  });
  const [sqlText, setSqlText] = useState('');
  const [isValidating, setIsValidating] = useState(false);
  const [isSyntaxValid, setIsSyntaxValid] = useState(false);
  const [syntaxError, setSyntaxError] = useState('');
  const [expandSQLArea, setExpandSQLArea] = useState(false);

  const featureSqlToSelection = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__SQL_TO_SELECTION);
  /**
   * handle on change
   * @param {object} e -event
   * @returns {void}
   */
  const handleOnChange = (e) => {
    if(isSyntaxValid) {
      setIsSyntaxValid(false);
    }
    setSqlText(e.target.value);
  };
  /**
   * Handle check sql validation
   * @returns {void}
   */
  const handleCheckSyntax = async () => {
    setIsValidating(true);
    const res = await SelectionsAPI.validateQueryForSQLToSelection(sqlText, axiosCancelToken.token);

    if(res.isQueryValid) {
      setIsValidating(false);
      setIsSyntaxValid(true);
      setSyntaxError('');
    } else {
      // set error message
      setSyntaxError(res.error);
      // set true or false
      setIsValidating(false);
      setIsSyntaxValid(false);
    }
  };

  /**
   * toggle SQL Area
   *  @param {Boolean} status - toggle status
   *  @returns {void}
   */
  const toggleSQLArea = (status) => {
    setExpandSQLArea(status);
  };

  /**
   * @param {Object} dE - The Target data extension object
   * @returns {void} Sets the state of the Target DE
   */
  const handleSetTargetDE = (dE) => {
    setCopyState(prevState => ({
      ...prevState,
      copyTargetDE: dE,
    }));
  };

  /**
   * @param {Boolean} toggleCopy - state of whether to copy target de or not
   * @returns {void} Sets the state of whether to copy target de or not
   */
  const handleToggleCopy = () => {
    setToggleCopy(!toggleCopy);
  };

  /**
   * @param {object} state - updated state of copy from NewAutoCreatedTargetDE
   * @returns {void} Sets the state of copy from NewAutoCreatedTargetDE
   */
  const handleSetNewCopyState = (state) => {
    setCopyState(prevState => ({
      ...prevState,
      ...state,
    }));
  };

  /**
   * Function that handles saving the selection
   * @returns {void}
   */
  const handleSaveSelection = async () => {
    // set loading indicator on Save button
    setIsSaving(true);

    const isFromTemplate = !!selectionTemplate;

    const copyPayload = {
      selectionId: selectionTemplate,
      copySelectionName: selectionName,
      isCopyTargetDE: copyState?.copyTargetDE ? toggleCopy : false,
      copyTargetDEName: copyState?.copyTargetDE?.Name,
      copyTargetDEFolderId: copyState.copyTargetDE?.folderId,
      isCreationFromSqlQuery: sqlText !== '',
      sqlQuery: sqlText,
    };

    const saveSelectionHandler = async () => {
      if (isFromTemplate) {
        return saveSelection(null, null, true, copyPayload);
      }

      return saveSelection(null, null, false, copyPayload);
    };

    const selectionIdResponse = await saveSelectionHandler();

    if(selectionIdResponse) {
      // execute function which set isSelectionRequest done to true
      handleSetSelectionRequestDone(selectionIdResponse.selectionId);

      // create a copy of the state
      cloneSelectionState();

      // Updates selection state
    } else {
      // otherwise set a selection name error
      setNameSelectionError(true);
    }

    // stop loading Save button
    setIsSaving(false);
  };

  /**
   * Function that handles changing the selection name
   * @param {object} e - JS event
   * @returns {void}
   */
  const handleChangeSelectionName = (e) => {
    // first reset the error message
    setNameSelectionError(false);

    // then change the state
    handleSetSelectionName(e);
  };

  /**
   * Function that handles changing the selection template
   * @param {object} e - JS event
   * @returns {void}
   */
  const handleChangeSelectionTemplate = (e) => {
    handleSetSelectionTemplate(e);
  };

  /**
   * Function that handles cancel the selection modal name
   * @returns {void}
   */
  const handleCancel = () => {
    // cancel axios token
    axiosCancelToken.cancel();
    if (backToWaterFall) {
      // go back to waterfall
      handleSetAppState({
        backToWaterFall: null,
        currentSelectionName: '',
        currentSelectionId: backToWaterFall._id,
        navigator: Constants.NAVIGATION__WATERFALL_SELECTION,
        globalNavigator: Constants.NAVIGATION__WATERFALL_SELECTION,
        folderId: backToWaterFall.folderId,
      });
    } else{
    // go to Overview screen
      handleNavigator(Constants.NAVIGATION__OVERVIEW);
    }
  };

  /**
   * This function helps with saving selection name when enter is pressed
   * @param {object} e - event
   * @returns {void}
   */
  const handlePressEnter = async (e) => {
    // when Enter is pressed and name is not empty
    if(e.key === 'Enter' && selectionName && selectionName.trim() !== '') {
      await handleSaveSelection();
    }
  };

  const waterfallOrSelection = isWaterfall ? 'waterfall' : 'selection';

  return (
    <Provider store={store}>
      <ModalTemplate
        headerTitle={`${featureSqlToSelection ? 'Create' : 'Name Your'} ${isWaterfall ? 'Waterfall ' : ''}Selection`}
        id="selection-modal-name"
        header-id="selection-name-modal-header"
        handleCancel={handleCancel}
        handleSave={handleSaveSelection}
        saveButtonDisabled={!selectionName || selectionName.trim() === '' ||
        nameSelectionError || copyState.nameDEError || expandSQLArea && !isSyntaxValid}
        loadingSaveButton={isSaving}
        loadingSaveButtonTitle="Saving..."
        saveButtonId={`save-new-${waterfallOrSelection}-in-modal`}
        cancelButtonId={`cancel-new-${waterfallOrSelection}-in-modal`}
      >
        <SelectionName
          folders={folders}
          selectionName={selectionName}
          handleSetAppState={handleSetAppState}
          handleSetSelectionName={handleChangeSelectionName}
          handleSetSelectionTemplate={handleChangeSelectionTemplate}
          folderId={folderId}
          currentSelectionId={currentSelectionId}
          axiosCancelToken={axiosCancelToken}
          inModal
          showUseTemplate
          nameSelectionError={nameSelectionError}
          isWaterfall={isWaterfall}
          onKeyPress={handlePressEnter}
          targetDataExtensions={targetDataExtensions}
          targetDE={copyState.copyTargetDE}
          handleSetTargetDE={handleSetTargetDE}
          toggleCopy={toggleCopy}
          handleToggleCopy={handleToggleCopy}
          handleSetNewCopyState={handleSetNewCopyState}
          loadingFoldersForCopy={copyState.loadingFoldersForCopy}
          showEssentialsUpgradeModal={showEssentialsUpgradeModal}
          backToWaterFall={backToWaterFall}
          handleOnChange={handleOnChange}
          handleCheckSyntax={handleCheckSyntax}
          toggleSQLArea={toggleSQLArea}
          sqlText={sqlText}
          isValidating={isValidating}
          isSyntaxValid={isSyntaxValid}
          syntaxError={syntaxError}
          expandSQLArea={expandSQLArea}
        />
      </ModalTemplate>
      <div className="feature-advert-modals">
        <FeatureAdvertContainer
          feature={featureAdvertModal?.feature}
          showFeatureModal={featureAdvertModal?.show}
          handleCancel={cancelEssentialsUpgradeModal}
        />
      </div>
    </Provider>
  );
};

SelectionNameModal.propTypes = {
  /**
   * Folders for selection save
   */
  folders: PropTypes.array.isRequired,
  /*
   * It helps to navigate between Overview and Waterfall Selection
   */
  handleNavigator: PropTypes.func.isRequired,
  /*
   * It saves the selection to database with the given data
   */
  saveSelection: PropTypes.func.isRequired,
  /*
   * Helps to set state in app.js
   */
  handleSetAppState: PropTypes.func.isRequired,
  /*
   * Id of the selected folder
   */
  folderId: PropTypes.string.isRequired,
  /*
   * The id of the currently open selection
   */
  currentSelectionId: PropTypes.string.isRequired,
  /**
   * It helps to cancel a subscription of an API call to backend
   */
  axiosCancelToken: PropTypes.instanceOf(Object),
  /**
   * It sets the selection Name in Selection state
   */
  handleSetSelectionName: PropTypes.func.isRequired,
  /**
   * It sets the selection template in Selection state
   */
  handleSetSelectionTemplate: PropTypes.func.isRequired,
  /**
   * It keeps the selection name
   */
  selectionName: PropTypes.string.isRequired,
  /**
   * It helps to set the property isSelectionRequestDone on true
   */
  handleSetSelectionRequestDone: PropTypes.func,
  /**
   * Defines whether the component is used in waterfall selection
   */
  isWaterfall: PropTypes.bool,
  /**
   * It helps to create copy of the selection state
   */
  cloneSelectionState: PropTypes.func.isRequired,
  /**
   * the waterFall we want to go back to, null if we do not
   */
  backToWaterFall: PropTypes.object,
  /**
   * Array of target data extensions
   */
  targetDataExtensions: PropTypes.array,
  /**
   * Selection template id if selection is created from template
   */
  selectionTemplate: PropTypes.string,
  /**
   * It toggles a feature advert modal on with specific feature
   */
  showEssentialsUpgradeModal: PropTypes.func.isRequired,
  /**
   * It toggles a feature advert modal off
   */
  cancelEssentialsUpgradeModal: PropTypes.func.isRequired,
  /**
   * Hold feature advert modal state
   */
  featureAdvertModal: PropTypes.object,
  /**
   * Features info from cookie
   */
  featuresInfo: PropTypes.object,
};

export default connect(mapStateToProps(['featuresInfo']), null, null, { pure: false })(SelectionNameModal);
