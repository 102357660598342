import React from 'react';
import ReactDragListView from 'react-drag-listview';
import PropTypes from 'prop-types';

import './styles.scss';
import Util from '../../../../../../util';
import Button from '../../../../../shared_v2/Button/Button';

const PrioValueContainer = ({
  setPrioDedupState,
  data,
  handleRemoveValue,
  criteriaFieldName,
}) => {
  /**
   * Change the state when a drag-dropping is completed
   * @param {Integer} fromIndex - Previous index of element
   * @param {Integer} toIndex - New index of element
   * @returns {void}
   */
  const onDragEnd = (fromIndex, toIndex) => {
    const item = data.splice(fromIndex, 1)[0];

    data.splice(toIndex, 0, item);
    setPrioDedupState({ data });
  };

  return (
    <>
      <h2 className="prio-val-h2 explanation-text">
        Give priority to records with the following values for
        {' '}
        <span>{Util.abbreviate(criteriaFieldName, 30)}</span>
        :
      </h2>
      <div className="prio-val-container">
        <div className="prio-val-container-elements">
          <ReactDragListView nodeSelector="li" handleSelector="a" onDragEnd={onDragEnd}>
            <ol>
              {data.length === 0 ?
                (
                  <span>Empty</span>
                ) :
                (
                  data.map((item, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                    <li key={index}>
                      <p title={item.title}>
                        <span>{index + 1}</span>
                        {' '}
                        {Util.abbreviate(item.title, 25)}
                      </p>
                      <a href="#!">
                        <svg className="slds-button__icon drag-icon-prio" aria-hidden="true">
                          <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#drag_and_drop" />
                          <title>Drag this card to change its order</title>
                        </svg>
                        <Button
                          noButtonClass
                          className="remove-line"
                          onClick={e => handleRemoveValue(e, index)}
                        >
                          <i className="far fa-times-circle remove-filter" />
                        </Button>
                      </a>
                    </li>
                  ))
                )}
            </ol>
          </ReactDragListView>
        </div>
      </div>
    </>
  );
};

PrioValueContainer.propTypes = {
  /**
   * It keeps the data for container
   *
   */
  data: PropTypes.instanceOf(Object).isRequired,
  /**
   * /**
   * Sets the state of PrioDedup component
   */
  setPrioDedupState: PropTypes.func.isRequired,
  /**
   * It helps to remove added value from the container
   */
  handleRemoveValue: PropTypes.func.isRequired,
  /**
   * It keeps the selected field's name for prio
   *
   */
  criteriaFieldName: PropTypes.string,
};
export default PrioValueContainer;
