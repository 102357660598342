import axios from 'axios';

import Util from '../util';

const apiUrl = process.env.REACT_APP_API_URL;

const QueryActivitiesAPI = {
  /**
   * Save query activity for selection
   * @param {string} selectionId - Id of selection
   * @param {object} cancelToken - Axios token
   * @returns {object} An object with the properties `taskId, `queryActivityObjectID`, and `success`
   */
  runQueryActivity: async (selectionId, cancelToken) => {
    const postData = {
      selectionId,
    };

    const res = await Util.catch418And403Error(
      axios.post,
      `${apiUrl}/query-activities/run`,
      postData,
      Util.apiPostCallSettings(cancelToken),
    );

    return res.data;
  },

  /**
   * Run query activity for waterfall selection
   * @param {string} selectionId - Id of waterfall selection
   * @param {object} cancelToken - Axios token
   * @returns {object} An object with the properties `taskId, `queryActivityObjectID`, and `success`
   */
  runQueryActivityForWaterfall: async (selectionId, cancelToken) => {
    const postData = {
      selectionId,
    };

    const res = await Util.catch418And403Error(
      axios.post,
      `${apiUrl}/query-activities/waterfall-selections/run`,
      postData,
      Util.apiPostCallSettings(cancelToken),
    );

    return res.data;
  },

  /**
   * Run preview query activity
   * @param {string} selectionId - id of selection
   * @param {object} cancelToken - axios token
   * @returns {object} An object with the properties `taskId, `queryActivityObjectID`, and `success`
   */
  runPreviewQueryActivity: async (selectionId, cancelToken) => {
    const postData = {
      selectionId,
    };

    const res = await Util.catch418And403Error(
      axios.post,
      `${apiUrl}/query-activities/run-preview`,
      postData,
      Util.apiPostCallSettings(cancelToken),
    );

    return res.data;
  },

  /**
   * Cancel preview as a whole
   * @param {Object} params - parameters of the function
   * @param {string} params.selectionId - id of selection
   * @param {string} params.taskId - id of task
   * @param {object} params.cancelToken - axios token
   * @returns {object} An object with the property `success` or an error
   */
  cancelPreview: async ({ selectionId, taskId, cancelToken }) => {
    const postData = {
      selectionId,
      taskId,
    };

    const res = await Util.catch418And403Error(
      axios.post,
      `${apiUrl}/query-activities/cancel-preview`,
      postData,
      Util.apiPostCallSettings(cancelToken),
    );

    return res.data;
  },

  /**
   * Get status for query activity
   * @param {string} id - Query id
   * @param {object} cancelToken - Axios token
   * @returns {object} Contains status of the query activity and success value or error value depending what API returns
   */
  getStatusQueryActivity: async (id, cancelToken) => {
    let res;

    try {
      res = await Util.catch418And403Error(
        axios.get,
        `${apiUrl}/query-activities/${id}/status`,
        Util.apiPostCallSettings(cancelToken),
      );
    } catch (error) {
      res = error.response;
    }

    return res.data;
  },

  /**
   * Get query activity statuses for selection chain
   * @param {string} selectionId - waterfall selection Id
   * @param {object} cancelToken - Axios token
   * @returns {object} Contains status of the query activity and success value or error value depending what API returns
   */
  getRunStatusForSelectionChain: async (selectionId, cancelToken) => {
    let res;

    try {
      res = await Util.catch418And403Error(
        axios.get,
        `${apiUrl}/waterfall-selections/${selectionId}/status`,
        Util.apiPostCallSettings(cancelToken),
      );
    } catch (error) {
      res = error.response;
    }

    return res.data.data;
  },

  /**
   * Validate query from FreeFormulas in CustomValue
   * @param {string} sqlToValidate - it's SQL formula from FreeFormula
   * @param {Object[]} relations - Relations array from current tab
   * @param {Object[]} collections - Collections array from current tab
   * @param {string} fieldType - fieldType selected in CustomValues Modal
   * @param {string[]} dataExtensionsNames - names of data extensions for from clause
   * @param {object} cancelToken - Axios token
   * @returns {object} Contains status of the query activity and success value or an error value
   */
  validateQueryForCustomValues: async (
    sqlToValidate,
    relations,
    collections,
    fieldType,
    dataExtensionsNames,
    cancelToken,
  ) => {
    const postData = {
      sqlToValidate,
      relations,
      collections,
      fieldType,
      dataExtensionsNames,
    };

    let res;

    try {
      res = await Util.catch418And403Error(
        axios.post,
        `${apiUrl}/query-activities/validate`,
        postData,
        Util.apiPostCallSettings(cancelToken),
      );
    } catch (error) {
      res = error;
    }

    return res?.data || res?.message || res;
  },
};

export default QueryActivitiesAPI;
