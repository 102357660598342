import React from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import classNames from 'classnames';

import Spinner from '../../../shared_v2/Spinner/Spinner';
import SelectionDropLine from '../../StepsView/SelectionContainer/SelectionDropLine/SelectionDropLine';
import './styles.scss';
import Constants from '../../../../constants/constants';
import waterfallSelectionUtil from '../../../../utils/waterfallSelection/waterfallSelectionUtil';
import Tip from '../../../shared_v2/Tip/Tip';

const SelectionChain = ({ fetchingSelectionsForRunChain }) => {
  // get state of properties from selectionReducer
  const {
    selectedSelections,
    selectionsForRunChain,
    runStatusForSelectionChain,
    waterfallSelectionStatus,
    runningQuery,
  } =
    useSelector(({ selectionReducer, globalReducer }) => ({
      selectedSelections: selectionReducer.selectedSelections,
      selectionsForRunChain: selectionReducer.selectionsForRunChain,
      runStatusForSelectionChain: globalReducer.runStatusForSelectionChain,
      waterfallSelectionStatus: globalReducer.waterfallSelectionStatus,
      runningQuery: globalReducer.runningQuery,
    }), shallowEqual);

  // define selections for chain
  const selectionsForChain = (!selectionsForRunChain?.length && !runStatusForSelectionChain?.length) ?
    selectedSelections :
    selectionsForRunChain;

  return (
    <div className={classNames(
      'selection-chain-container',
      { 'not-running': !waterfallSelectionUtil.isWaterfallSelectionRunning(waterfallSelectionStatus, runningQuery) },
    )}>
      {selectionsForChain?.length ?
        selectionsForChain.map((selection, index) => (
          <SelectionDropLine
            selection={selection}
            id={selection._id}
            key={selection._id}
            index={index}
            selectedSelections={selectionsForChain}
            isReadOnly
          />
        )) :
        (

          <div className={`without-selected-selection-container-v2 ${fetchingSelectionsForRunChain}`}>
            {fetchingSelectionsForRunChain ?
              (
              <Spinner
                size={Constants.SPINNER__SIZE__MEDIUM}
                assistiveText="Loading..."
              />
              ) :
                <Tip
                  title="Tip:"
                  description="Before you can run a Waterfall Selection,
                  you must first select one or more Selections in the Input screen."
                  mode="dark"
                />}
          </div>
        )}
    </div>
  );
};

SelectionChain.propTypes = {
  /**
   * Indicates whether selections are being retrieved for a run chain
   */
  fetchingSelectionsForRunChain: PropTypes.bool.isRequired,
};

export default SelectionChain;
