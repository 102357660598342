import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import socketUtil from '../../../utils/socketIO/socketUtil';
import Constants from '../../../constants/constants';
import './styles.scss';

const ActiveUsers = ({ currentSelectionId }) => {
  const [activeUsersListMode, setActiveUsersListMode] = useState('minimalMode');
  const [activeUsersBarScrollLeft, setActiveUsersBarScrollLeft] = useState(0);
  const [activeUsersBarOffsetWidth, setActiveUsersBarOffsetWidth] = useState(0);
  const [activeUsersContainerBarOffsetWidth, setActiveUsersContainerBarOffsetWidth] = useState(0);
  const [activeUsers, setActiveUsers] = useState([]);
  const [activeUsersColors, setActiveUsersColors] = useState([]);
  const activeUsersContainerRef = useRef(null);
  const activeUsersRef = useRef(null);

  const refreshScrollCarets = () => {
    if (Number.isInteger(activeUsersContainerRef?.current?.scrollLeft)) {
      setActiveUsersBarScrollLeft(activeUsersContainerRef.current.scrollLeft);
    }
    if (Number.isInteger(activeUsersRef?.current?.scrollLeft)) {
      setActiveUsersBarOffsetWidth(activeUsersRef.current.offsetWidth);
      setActiveUsersContainerBarOffsetWidth(activeUsersContainerRef?.current?.offsetWidth);
    }
  };

  /**
   * update active users list
   * @param {Array} users - list of active users
   * @returns {void}
   */
  const updateActiveUsersList = (users) => {
    setActiveUsers(users);
    refreshScrollCarets();
  };

  /**
   * socket init for selections
   * @returns {void}
   */
  const socketInit = () => {
    // send a message to join a room
    socketUtil.getSocket().emit(Constants.ACTIVE_USERS_WEBSOCKET_ACTIONS___JOIN_ROOM, {
      roomId: currentSelectionId + '-selection',
      roomType: 'selection',
    });

    // Listen to GET_ALL_USERS event
    socketUtil.getSocket().on(Constants.ACTIVE_USERS_WEBSOCKET_ACTIONS___GET_ALL_USERS, (data) => {
      updateActiveUsersList(data);
    });

    // Listen to NEW_USER_JOINED event
    socketUtil.getSocket().on(Constants.ACTIVE_USERS_WEBSOCKET_ACTIONS___NEW_USER_JOINED, (data) => {
      updateActiveUsersList(data);
    });

    // Listen to USER_HAS_LEFT event
    socketUtil.getSocket().on(Constants.ACTIVE_USERS_WEBSOCKET_ACTIONS___USER_HAS_LEFT, (data) => {
      updateActiveUsersList(data);
    });
  };

  useEffect(() => {
    setTimeout(refreshScrollCarets, 300);
  }, [activeUsersListMode]);

  useEffect(() => {
    // set the random colors of the active users
    setActiveUsersColors(Constants.ACTIVE_USERS_COLORS.sort(() => Math.random() - 0.5));
    socketInit();

    // returned function will be called on component unmount
    return () => {
      // turn off socket listeners
      socketUtil.getSocket().off(Constants.ACTIVE_USERS_WEBSOCKET_ACTIONS___GET_ALL_USERS);
      socketUtil.getSocket().off(Constants.ACTIVE_USERS_WEBSOCKET_ACTIONS___NEW_USER_JOINED);
      socketUtil.getSocket().off(Constants.ACTIVE_USERS_WEBSOCKET_ACTIONS___USER_HAS_LEFT);
      // send a message to leave a room
      socketUtil.getSocket().emit(Constants.ACTIVE_USERS_WEBSOCKET_ACTIONS___LEAVE_ROOM, {
        roomId: currentSelectionId + '-selection',
        roomType: 'selection',
      });
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * scroll left and right
   * @param {string} direction - scrollLeft or scrollRight
   * @returns {void}
   */
  const scrollLeftAndRight = async (direction) => {
    activeUsersContainerRef.current.scrollLeft += (direction === 'right' ? 1 : -1) * 20;
    refreshScrollCarets();
  };

  /**
   * get first letters of first and the last syllables of a name
   * @param {string} name - name we want to get first letters from
   * @returns {string} first letters of first and the last syllables
   */
  const getFirstLettersOfName = (name) => {
    const Syllables = name?.split(' ');
    const lastSyllableIndex = (Array.isArray(Syllables) ? Syllables.length - 1 : 0);
    const firstSyllableLatter = Syllables?.[0]?.charAt(0)?.toUpperCase() || '';
    const lastSyllableLatter = Syllables?.[lastSyllableIndex]?.charAt(0)?.toUpperCase() || '';

    return firstSyllableLatter + lastSyllableLatter;
  };

  return (
    activeUsers?.length >= 2 ?
      <div className="active-users-component">
        <i className="fas fa-exclamation-triangle" />
        <p className="active-users-statement">
            This selection is currently being edited by another user and
            any changes made by you can be overwritten.
        </p>
        <div className="active-users-list">
          <div
            className={
              classNames(
                'active-users-minimalMode',
                { expanded: activeUsersListMode === Constants.ACTIVE_USERS_LIST_MODE__NORMAL },
              )
            }
            onClick={() => {
              setActiveUsersListMode(Constants.ACTIVE_USERS_LIST_MODE__NORMAL);
            }}>
            <i className="fas fa-user-friends" />
            <span className="active-users-number">{activeUsers?.length}</span>
          </div>
          <div className={
            classNames(
              'active-users-normalMode',
              { expanded: activeUsersListMode === Constants.ACTIVE_USERS_LIST_MODE__NORMAL },
            )
          }>
            {
              activeUsersBarOffsetWidth < activeUsersContainerBarOffsetWidth ||
                activeUsersBarScrollLeft <= 0 ?
                null :
                <i className="fas fa-caret-left" onClick={() => scrollLeftAndRight('left')} />
            }
            <div ref={activeUsersContainerRef} id="active-users-list-normalMode">
              <div ref={activeUsersRef} id="active-users" className="active-users">
                {activeUsers?.map((x, i) => (
                  <span
                    className="active-user"
                    key={x.name + ' ' + i}
                    style={{ borderColor: activeUsersColors[i % activeUsersColors.length] }}
                    title={x.name}>
                    {getFirstLettersOfName(x.name)}
                  </span>))}
              </div>
            </div>

            {
              (activeUsersContainerBarOffsetWidth < activeUsersBarOffsetWidth + 1) &&
              (activeUsersBarScrollLeft + (activeUsersContainerRef?.current?.offsetWidth || 0)) <
              activeUsersBarOffsetWidth &&
              <i className="fas fa-caret-right" onClick={() => scrollLeftAndRight('right')} />
            }

            <span
              className="close-btn"
              onClick={() => setActiveUsersListMode(Constants.ACTIVE_USERS_LIST_MODE__MINIMAL)}>
              <i className="fas fa-times" />
            </span>
          </div>
        </div>
      </div> :
      null

  );
};

ActiveUsers.propTypes = {
  /**
   * The current selection Id
   */
  currentSelectionId: PropTypes.string,
};

export default ActiveUsers;
