import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Input = ({
  type,
  placeholder,
  onChange,
  value,
  maxLength,
  name,
  id,
  className,
  containerClassName,
  containerId,
  readOnly,
  autoComplete,
  ariaAutoComplete,
  noInputClassName,
  onClick,
  required,
  onBlur,
  onKeyDown,
  forwardRef,
  containerTitle,
  min,
  max,
  withContainer,
  disabled,
  pattern,
  inputMode,
  checked,
  onKeyPress,
  onFocus,
}) => {
  const inputContainerClassName = classNames(
    `${containerClassName || 'slds-form-element__control'}`,
  );

  const inputClassName = classNames(
    noInputClassName ? '' : 'slds-input',
    `${className || ''}`,
  );

  const renderInput = () => (
    <input
      type={type || 'text'}
      placeholder={placeholder}
      className={inputClassName}
      onChange={onChange}
      maxLength={maxLength}
      value={value}
      name={name}
      aria-label={name}
      id={id}
      readOnly={readOnly}
      autoComplete={autoComplete}
      aria-autocomplete={ariaAutoComplete}
      onClick={onClick}
      required={required}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      ref={forwardRef}
      min={min}
      max={max}
      disabled={disabled}
      pattern={pattern}
      inputMode={inputMode}
      checked={checked}
      onKeyPress={onKeyPress}
      onFocus={onFocus}
    />
  );

  return (
    withContainer || containerClassName ?
    <div className={inputContainerClassName} id={containerId} title={containerTitle || ''}>
      {renderInput()}
    </div> :
      renderInput()
  );
};

Input.propTypes = {
  /**
   * input type
   */
  type: PropTypes.string,
  /**
   * placeholder for the input
   */
  placeholder: PropTypes.string,
  /**
   * onChange function for the input
   */
  onChange: PropTypes.func,
  /**
   * value for the input
   */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.bool]),
  /*
   * maximum length for input
   */
  maxLength: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /*
   * input name
   */
  name: PropTypes.string,
  /**
   * input id
   */
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * className of the input
   */
  className: PropTypes.string,
  /**
   * className of the input div element
   */
  containerClassName: PropTypes.string,
  /**
   * id of the input div element
   */
  containerId: PropTypes.string,
  /**
   * readOnly property for the input
   */
  readOnly: PropTypes.bool,
  /**
   * auto-complete property for the input
   */
  autoComplete: PropTypes.string,
  /**
   * aria-autocomplete property for the input
   */
  ariaAutoComplete: PropTypes.string,
  /**
   * indicates that the input element is to be used without slds-input class name
   */
  noInputClassName: PropTypes.bool,
  /**
   * onClick Event handler
   */
  onClick: PropTypes.func,
  /**
   * indicates that the user must specify a value for the input before the submitting
   */
  required: PropTypes.bool,
  /**
   * onBlur Event handler
   */
  onBlur: PropTypes.func,
  /**
   * onKeyDown Event handler
   */
  onKeyDown: PropTypes.func,
  /**
   * Ref element of input
   */
  forwardRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.elementType }),
  ]),
  /**
   * title of the input div element
   */
  containerTitle: PropTypes.string,
  /**
   * min property for the input
   */
  min: PropTypes.string,
  /**
   * max property for the input
   */
  max: PropTypes.string,
  /**
   * indicates that the input should be wrapped by div element
   */
  withContainer: PropTypes.bool,
  /**
   * disable property for the input
   */
  disabled: PropTypes.bool,
  /**
   * pattern property for the input
   */
  pattern: PropTypes.string,
  /**
   * inputMode property for the input
   */
  inputMode: PropTypes.string,
  /**
   * checked property for the input
   */
  checked: PropTypes.bool,
  /*
   * Function that triggers when the user presses a key on the keyboard
   */
  onKeyPress: PropTypes.func,
  /*
   * Function that triggers when the user focuses on the input
   */
  onFocus: PropTypes.func,
};

export default Input;
