import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './styles.scss';
import ModalTemplate from '../../../../shared/ModalTemplate/ModalTemplate';
import Button from '../../../../shared/Button/Button';
import Constants from '../../../../../constants/constants';
import Input from '../../../../shared/Input/Input';

class FolderDataExtensions extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedDE: {},
      dataExtensionSearchField: '',
      isScrollBarVisible: false,
    };

    this.searchDEsInputRef = React.createRef();
  }

  /**
   * Mount the component
   * @returns {void}
   */
  async componentDidMount() {
    // Auto focus the input field for searching DEs
    this.searchDEsInputRef.current.focus();

    const modalContentDiv = document.getElementById(
      'folder-data-extensions-modal-content',
    );

    // if scrollBar is visible set the state 'isScrollBarVisible' to true so the css styling is applied
    const isScrollBarVisible =
      modalContentDiv?.scrollHeight > modalContentDiv?.clientHeight;

    this.setState({ isScrollBarVisible });
  }

  /**
   * Helps to search for a DE
   * @param {object} e - event
   * @returns {void}
   */
  handleSearchForDE = (e) => {
    this.setState({ dataExtensionSearchField: e.target.value });
  };

  /**
   * Helps to selected a DE
   * @returns {void}
   */
  handleSelectDataExtension = async () => {
    const {
      handleSetAvailableExtensionsState,
      handleSetSelectionState,
      availableDEsFolderId,
      handleChangeTargetDataExtension,
      isTargetDEsFoldersModal,
      handleSetTargetDEState,
      targetDEsFolderId,
      isDataSetDEModal,
      addRelatedDE,
    } = this.props;

    const { selectedDE } = this.state;

    if (isTargetDEsFoldersModal) {
      handleChangeTargetDataExtension({ value: selectedDE.ObjectID });
      handleSetTargetDEState({
        showTargetDEsForFolder: false,
        showTargetDEsFoldersModal: false,
      });
      handleSetSelectionState({ targetDEsFolderId });
    } else if (isDataSetDEModal) {
      /*
       *  if we are in the data set modal then upon saving
       *  add a selected DE to the selected DEs in the data set component
       */
      addRelatedDE(selectedDE);
    } else {
      handleSetAvailableExtensionsState({
        showDEsForFolder: false,
        selectedDE,
        showAvailableDEsFoldersModal: false,
      });

      handleSetSelectionState({ availableDEsFolderId });
    }
  };

  /**
   * Helps to cancel the FolderDataExtensions modal
   * @returns {void}
   */
  handleCancelButton = () => {
    const {
      isTargetDEsFoldersModal,
      handleSetTargetDEState,
      handleSetAvailableExtensionsState,
      setShowRelatedDEsModal,
      isDataSetDEModal,
      handleSetScopeModalsState,
      isScopedDEsFoldersModal,
    } = this.props;

    if (isTargetDEsFoldersModal) {
      handleSetTargetDEState({
        showTargetDEsForFolder: false,
        showTargetDEsFoldersModal: false,
      });
    } else if (isDataSetDEModal) {
      setShowRelatedDEsModal(false);
    } else if (isScopedDEsFoldersModal) {
      handleSetScopeModalsState({
        isFolderDEModalVisible: false,
        isAvailableFoldersModalVisible: false,
      });
    } else {
      handleSetAvailableExtensionsState({
        showDEsForFolder: false,
        showAvailableDEsFoldersModal: false,
      });
    }
  };

  /**
   * Redirects to the previous modal (List of folders)
   * @returns {void}
   */
  handlePreviousButton = () => {
    const {
      isTargetDEsFoldersModal,
      handleSetTargetDEState,
      handleSetAvailableExtensionsState,
      isScopedDEsFoldersModal,
      handleSetScopeModalsState,
    } = this.props;

    if (isTargetDEsFoldersModal) {
      handleSetTargetDEState({ showTargetDEsForFolder: false });
    } else if (isScopedDEsFoldersModal) {
      handleSetScopeModalsState({ isFolderDEModalVisible: false });
    } else {
      handleSetAvailableExtensionsState({ showDEsForFolder: false });
    }
  };

  /**
   * Helps saving selected de or DEs to add to scope if we are in the scope modal
   * @returns {void}
   */
  handleSaveButton = () => {
    const { selectedDE, dataExtensionSearchField } = this.state;
    const {
      isScopedDEsFoldersModal,
      scopeDataExtensionsToAdd,
      dataExtensions,
      handleSaveSelectedScopeDEs,
      handleSetScopeModalsState,
    } = this.props;

    const filteredExtensions = dataExtensions.filter(collection => collection.Name.toString()
      .toLowerCase()
      .includes(dataExtensionSearchField.toString().toLowerCase()));

    // if we are in the scope modal then save the selected DEs to add to scope
    if (isScopedDEsFoldersModal) {
      if (scopeDataExtensionsToAdd?.length !== 0) {
        handleSaveSelectedScopeDEs();
        handleSetScopeModalsState({
          isFolderDEModalVisible: false,
          isAvailableFoldersModalVisible: false,
        });
      }
    } else {
      if (filteredExtensions?.length !== 0 && selectedDE?.Name) {
        this.handleSelectDataExtension();
      }
    }
  };

  render() {
    const {
      dataExtensions,
      foldersPath,
      isDataSetDEModal,
      isScopedDEsFoldersModal,
      scopeDataExtensionsToAdd,
      handleAddScopeDE,
    } = this.props;

    const { selectedDE, dataExtensionSearchField, isScrollBarVisible } =
      this.state;

    // Filtered results of available data extension
    const filteredExtensions = dataExtensions.filter(collection => collection.Name.toString()
      .toLowerCase()
      .includes(dataExtensionSearchField.toString().toLowerCase()));

    const saveButtonDisabled = isScopedDEsFoldersModal ?
      scopeDataExtensionsToAdd?.length === 0 :
      filteredExtensions.length === 0 || !selectedDE.Name;

    const renderContentForFooter = () => (
      <>
        {isDataSetDEModal ?
          null :
          (
          <div className="folder-path-div">
            {foldersPath?.map((path, i) => (
              <span className="folder-path-name" key={path}>
                <span className="folder-icon">
                  <i className="fas fa-folder" />
                </span>
                {path}
                {i !== foldersPath.length - 1 && (
                  <svg
                    className="slds-icon slds-icon-text-default slds-icon_x-small h-12px"
                    aria-hidden="true"
                  >
                    <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#chevronright" />
                  </svg>
                )}
              </span>
            ))}
          </div>
          )}
        <Button
          buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
          className={isDataSetDEModal ? '' : 'float-left'}
          id="cancel-btn"
          onClick={() => this.handleCancelButton()}
        >
          Cancel
        </Button>
      </>
    );

    return (
      <ModalTemplate
        id="newAutoCreatedTDE"
        containerId="folder-data-extensions"
        headerId="modal-heading-02"
        headerTitle="Choose Data Extension"
        footerClassName="pt-1rem"
        extraFooterContent={renderContentForFooter()}
        handleCancel={() => this.handlePreviousButton()}
        cancelButtonId="previous-btn"
        cancelButtonTitle="Previous"
        handleSave={this.handleSaveButton}
        saveButtonTitle="Select"
        saveButtonId="select-btn"
        saveButtonDisabled={saveButtonDisabled}
        backdropHidden
        withoutModalContent
        isDataSetDEModal={isDataSetDEModal}
      >
        <div className="search-data-extension-div">
          <div className="input-field-and-search-icon">
            <Input
              noInputClassName
              className="search-data-extension"
              onChange={this.handleSearchForDE}
              value={dataExtensionSearchField}
              placeholder="Search data extensions"
              forwardRef={this.searchDEsInputRef}
            />
            <span className="slds-icon_container search-available-folders-icon">
              <svg
                className="slds-icon slds-icon-text-default slds-icon_x-small"
                aria-hidden="true"
              >
                <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#search" />
              </svg>
            </span>
          </div>
        </div>
        <div
          className="slds-modal__content slds-p-around_medium"
          id="folder-data-extensions-modal-content"
        >
          {filteredExtensions?.length ?
            (
            <>
              {filteredExtensions.map((de) => {
                const isDESelectedInScopedModal =
                  isScopedDEsFoldersModal &&
                  scopeDataExtensionsToAdd?.some(
                    ext => ext.ObjectID === de.ObjectID,
                  );
                const isDESelected = selectedDE.ObjectID === de.ObjectID;

                const deClass = classNames(
                  'data-extensions-for-folder',
                  isScrollBarVisible ? '' : 'mr-2px',
                  isDESelectedInScopedModal ||
                    (!isScopedDEsFoldersModal && isDESelected) ?
                    'selectedDE' :
                    '',
                );

                const handleClick = () => {
                  if (isScopedDEsFoldersModal) {
                    handleAddScopeDE(de);
                  } else {
                    this.setState({ selectedDE: de });
                  }
                };

                return (
                  <div
                    className={deClass}
                    key={de.ObjectID}
                    onClick={handleClick}
                  >
                    {de.Name}
                  </div>
                );
              })}
            </>
            ) :
            (
            <div className="folder-no-data-extensions">
              <span>
                {isDataSetDEModal ?
                  'The selected data extension does not have any available related DEs' :
                  'Folder does not have any data extensions'}
              </span>
            </div>
            )}
        </div>
      </ModalTemplate>
    );
  }
}

FolderDataExtensions.propTypes = {
  /**
   * Available data extensions
   */
  dataExtensions: PropTypes.instanceOf(Array).isRequired,
  /**
   * Selected id of the available folder
   */
  availableDEsFolderId: PropTypes.number,
  /**
   * Sets the state in the AvailableExtensions component
   */
  handleSetAvailableExtensionsState: PropTypes.func,
  /**
   * It helps to set the Selection`s state
   * It will be passed from Selection.js
   */
  handleSetSelectionState: PropTypes.func,
  /**
   * Path of the selected folder
   */
  foldersPath: PropTypes.instanceOf(Array),
  /**
   * Changes the Target DE
   */
  handleChangeTargetDataExtension: PropTypes.func,
  /**
   * Determines if we are using this component from the TargetDE component
   */
  isTargetDEsFoldersModal: PropTypes.bool,
  /**
   * Sets the state in the TargetDE component
   */
  handleSetTargetDEState: PropTypes.func,
  /**
   * Selected id of the target DE folder
   */
  targetDEsFolderId: PropTypes.number,
  /**
   * Determines if this component is being used from the data set modal
   */
  isDataSetDEModal: PropTypes.bool,
  /**
   * Function for adding a new DE
   */
  addRelatedDE: PropTypes.func,
  /**
   * Function for setting the showRelatedDEsModal
   */
  setShowRelatedDEsModal: PropTypes.func,

  /**
   * Determines if this component is being used from the scoped DEs modal
   */
  isScopedDEsFoldersModal: PropTypes.bool,

  /**
   * Array of scoped DEs to add
   */
  scopeDataExtensionsToAdd: PropTypes.instanceOf(Array),

  /**
   * Function for adding a scoped DE
   */
  handleAddScopeDE: PropTypes.func,

  /**
   * Function for setting the state of the scoped DEs modals
   */
  handleSetScopeModalsState: PropTypes.func,

  /**
   * Function for saving the selected scope DEs
   */
  handleSaveSelectedScopeDEs: PropTypes.func,
};

export default FolderDataExtensions;
