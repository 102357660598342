import axios from 'axios';

import Util from '../util';

const apiUrl = process.env.REACT_APP_API_URL;

const SelectionsAPI = {
  /**
   * Get all Selections based on query params
   * @param {object} cancelToken - Token axios
   * @param {string} query - stringify query for API
   * @returns {array} An array with all the selections
   */
  getSelections: async (cancelToken, query) => {
    const res = await Util.catch418And403Error(
      axios.get,
      `${apiUrl}/selections?${query || ''}`,
      Util.apiGetCallSettings(cancelToken),
    );

    return res.data.data;
  },

  /**
   * Get all selections that match the passed properties in query params
   * @param {object} cancelToken - Token axios
   * @param {string} query - stringify query for API
   * @returns {array} An array with all matched selections
   */
  getSelectionsByProperty: async (cancelToken, query) => {
    const res = await Util.catch418And403Error(
      axios.get,
      `${apiUrl}/selections/find?${query}`,
      Util.apiGetCallSettings(cancelToken),
    );

    return res.data.data;
  },

  /**
   * Creates copy of the selection
   * @param {string} param.selectionId - Id of selection
   * @param {string} param.copySelectionName - Selection's copy name
   * @param {boolean} param.isCopyTargetDE - true if copyTarget DE is selected, false is not
   * @param {string} param.copyTargetDEName - name of Target Data Extension Copy
   * @param {number} param.copyTargetDEFolderId - copy Target Data Extension folder ID
   * @param {number} param.businessUnitId - conditional, id of target business unit ID
   * @param {boolean} param.copySchedule - true if copySchedule is selected, false if not
   * @param {object} cancelToken - Axios token
   * @returns {object} An object with the properties `selectionId` and `success`
   */
  copySelection: async ({
    selectionId,
    copySelectionName,
    isCopyTargetDE,
    copyTargetDEName,
    copyTargetDEFolderId,
    businessUnitId,
    copySchedule,
    matchOn,
  }, cancelToken) => {
    const postData = {
      copySelectionName,
      isCopyTargetDE,
      copyTargetDEName,
      copyTargetDEFolderId,
      copySchedule,
    };

    if (businessUnitId) {
      postData.businessUnitId = businessUnitId;
      postData.matchOn = matchOn;
    }

    const res = await Util.catch418And403Error(
      axios.post,
      `${apiUrl}/selections/${selectionId}/copy`,
      postData,
      Util.apiPostCallSettings(cancelToken),
    );

    return res.data;
  },

  /**
   * Deletes selection and additional options in selection (if selected)
   * @param {string} selectionId - Id of selection
   * @param {boolean} deleteQueryActivities - additional option QueryActivities to be removed (true or false)
   * @param {boolean} deleteTargetDE - additional option TargetDE to be removed (true or false)
   * @param {object} cancelToken - Axios token
   * @returns {object} An object with the property `success`
   */
  deleteSelection: async (selectionId, deleteQueryActivities, deleteTargetDE, cancelToken) => {
    const deleteItems = {
      deleteQueryActivities,
      deleteTargetDE,
    };
    const res = await Util.catch418And403Error(
      axios.post,
      `${apiUrl}/selections/${selectionId}/delete`,
      { deleteItems },
      Util.apiPostCallSettings(cancelToken),
    );

    return res.data;
  },

  /**
   * Archives waterfall selection or restores it based on isArchived flag
   * @param {object} params - Object containing parameters
   * @param {string} params.selectionId - Id of selection
   * @param {string} params.restoreFolderId - Id of selection
   * @param {object} params.cancelToken - Axios token
   * @returns {object} An object with the property `success`
   */
  archiveOrRestoreSelection: async ({ selectionId, restoreFolderId, cancelToken }) => {
    const res = await Util.catch418And403Error(
      axios.post,
      `${apiUrl}/selections/${selectionId}/archive-or-restore`,
      { restoreFolderId },
      Util.apiPostCallSettings(cancelToken),
    );

    return res.data;
  },

  /**
   * Update selection
   * @param {string} selectionId - Id of selection
   * @param {string} folderId - Id of folder
   * @param {object} cancelToken - Axios token
   * @returns {object} An object with the properties `selections` and `success`
   */
  updateSelection: async (selectionId, folderId, cancelToken) => {
    const res = await Util.catch418And403Error(
      axios.put,
      `${apiUrl}/selections/${selectionId}`,
      { folderId },
      Util.apiPostCallSettings(cancelToken),
    );

    return res.data;
  },

  /**
   * Get one selection
   * @param {string} selectionId - Id of selection
   * @param {object} cancelToken - Axios token
   * @returns {object} The selection
   */
  getSelection: async (selectionId, cancelToken) => {
    const res = await Util.catch418And403Error(
      axios.get,
      `${apiUrl}/selections/${selectionId}`,
      Util.apiGetCallSettings(cancelToken),
    );

    return res.data.data;
  },

  /**
   * Get scheduled waterfall selections in which the selection if part of
   * @param {string} selectionId - Id of selection
   * @param {object} cancelToken - Axios token
   * @returns {object} The selection
   */
  getScheduledWaterfallSelections: async (selectionId, cancelToken) => {
    const res = await Util.catch418And403Error(
      axios.get,
      `${apiUrl}/selections/${selectionId}/scheduled-waterfall-selections`,
      Util.apiGetCallSettings(cancelToken),
    );

    return res.data.data;
  },

  /**
   * Get multiple selections by their IDs
   * @param {array} selectionIds - array with selection Ids
   * @param {object} cancelToken - Axios token
   * @returns {object} Array with fetched selections
   */
  getMultipleSelections: async (selectionIds, cancelToken) => {
    const postData = { selectionIds };
    const res = await Util.catch418And403Error(
      axios.post,
      `${apiUrl}/selections/multiple`,
      postData,
      Util.apiGetCallSettings(cancelToken),
    );

    return res.data.selections;
  },

  /**
   * Get selections' waterfall schedules by their IDs
   * @param {array} targetDEObjectIDs - array with selections' TDE Object IDs
   * @param {object} cancelToken - Axios token
   * @returns {object} object with fetched selections' schedules
   */
  getSelectionsSchedules: async (targetDEObjectIDs, cancelToken) => {
    const postData = { targetDEObjectIDs };
    const res = await Util.catch418And403Error(
      axios.post,
      `${apiUrl}/selections/schedules`,
      postData,
      Util.apiGetCallSettings(cancelToken),
    );

    return res?.data.schedules;
  },

  /**
   * Get selections' waterfall schedules by their IDs
   * @param {string} selectionId - Id of the selection
   * @param {object} cancelToken - Axios token
   * @returns {array} array with fetched selections' schedules
   */
  getSelectionWaterfallSchedules: async (selectionId, cancelToken) => {
    const res = await Util.catch418And403Error(
      axios.get,
      `${apiUrl}/selections/${selectionId}/waterfallSchedules`,
      Util.apiGetCallSettings(cancelToken),
    );

    return res?.data.schedules;
  },

  /**
   * Save one selection
   * @param {object} postData - Data of the new selection
   * @param {object} cancelToken - Axios token
   * @returns {object} An object with the properties `selectionId` and `success`
   */
  saveSelection: async (postData, cancelToken) => {
    const { currentSelectionId } = postData;

    const res = await Util.catch418And403Error(
      axios.post,
      `${apiUrl}/selections/save/${currentSelectionId}`,
      { ...postData },
      Util.apiPostCallSettings(cancelToken),
    );

    return res.data;
  },

  /**
   * AutoFix selection
   * @param {object} selectionId - Selection ID to be autoFixed
   * @param {object} cancelToken - Axios token
   * @returns {object} An object with the properties `selectionId` and `success`
   */
  autoFixSelection: async (selectionId, cancelToken) => {
    const res = await Util.catch418And403Error(
      axios.get,
      `${apiUrl}/selections/autofix/${selectionId}`,
      Util.apiGetCallSettings(cancelToken),
    );

    return res.data.data;
  },
  /**
   * Validate query from SQL to Selection
   * @param {string} sqlToValidate - it's SQL
   * @param {object} cancelToken - Axios token
   * @returns {object} Contains status of the query activity and success value or an error value
   */
  validateQueryForSQLToSelection: async (
    sqlToValidate,
    cancelToken,
  ) => {
    const postData = {
      sqlToValidate,
    };

    let res;

    try {
      res = await Util.catch418And403Error(
        axios.post,
        `${apiUrl}/query-activities/sql-to-selection/validate`,
        postData,
        Util.apiPostCallSettings(cancelToken),
      );
    } catch (error) {
      res = error;
    }

    return res?.data || res?.message || res;
  },
};

export default SelectionsAPI;
