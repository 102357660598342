import axios from 'axios';

import DataViewsAPI from '../../api/data-views';
import Util from '../../util';
import DataExtensionsAPI from '../../api/data-extensions';
import SwalUtil from '../../utils/swal/swalUtil';

const axiosCancelToken = axios.CancelToken.source();

const AdminPanelUtil = {
  /**
   * Function for adjusting data view fields for further usage
   * @param {object[]} selectedCollection - Selected collection
   * @returns {void}
   */
  prepareDataViewsFields: (selectedCollection) => {
    const dataExtension = {
      CustomerKey: selectedCollection.CustomerKey,
    };

    selectedCollection.fields.forEach((field) => {
      /* eslint-disable no-param-reassign */
      field.CustomerKey = `[${selectedCollection.CustomerKey}].[${field.Name.toString()}]`;
      field.DataExtension = dataExtension;
      /* eslint-enable no-param-reassign */
    });
  },

  /**
   * Helps to retrieve fields of a data extension or data view from SFMC
   * @param {object[]} selectedCollection - Selected collection
   * @param {boolean} featureDataViews - Availability of data views feature
   * @param {boolean} isComparedField - Determined if we are calling this function for compared fields
   * @returns {Array} array of data extension fields
   */
  getDataExtensionOrDataViewFields: async (selectedCollection, featureDataViews) => {
    /**
     * Check if selected DE has CategoryID
     * If it has then move on to find DE
     * If it doesn't have then move on to find Data Views
     */
    if (selectedCollection && !selectedCollection.CategoryID) {
      /**
       * If feature 'dataViews' is 'true' get data views fields
       * Else show error message
       */
      if (featureDataViews) {
        try {
          // eslint-disable-next-line no-param-reassign, require-atomic-updates
          selectedCollection.fields = await DataViewsAPI.getDataViewFields(
            selectedCollection.CustomerKey.toString(),
            axiosCancelToken.token,
          );
        } catch (error) {
          if (!axios.isCancel(error)) {
            // show swal error if request failed with error
            SwalUtil.fire({
              type: 'error',
              title: '<div class="error-title">Error</div>',
              messageHTML: `<p class="width_swal">${error}</p>`,
              options: {
                showCancelButton: false,
                confirmButtonText: 'OK',
                footer: '<div></div>',
                buttonsStyling: false,
                allowOutsideClick: false,
              },
            });
          }
        }
      } else {
        /**
         * I am returning null so we can differentiate data views feature is disabled and
         * Data view is deleted
         */
        return null;
      }

      // Prepare fields so you can use it properly
      AdminPanelUtil.prepareDataViewsFields(selectedCollection);
      // Sort data view fields by Name
      Util.sortArrayOfObjects(selectedCollection.fields, 'Name');
    } else {
      try {
        // if it doesn't find the fields, get new ones
        const fields = await DataExtensionsAPI.getDataExtensionFields(
          selectedCollection.CustomerKey,
          axiosCancelToken.token,
        );

        if (fields?.data) {
          // eslint-disable-next-line require-atomic-updates, no-param-reassign
          selectedCollection.fields = fields.data;
        } else {
          // eslint-disable-next-line require-atomic-updates, no-param-reassign
          selectedCollection.fields = [];
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          // show swal error if request failed with error
          SwalUtil.fire({
            type: 'error',
            title: '<div class="error-title">Error</div>',
            messageHTML: `<p class="width_swal">${error}</p>`,
            options: {
              showCancelButton: false,
              confirmButtonText: 'OK',
              footer: '<div></div>',
              buttonsStyling: false,
              allowOutsideClick: false,
            },
          });
        }
      }
    }

    return selectedCollection.fields;
  },
};

export default AdminPanelUtil;
