import React from 'react';
import { useSprings, animated } from '@react-spring/web';

// Define the Dot component
export const Dot = animated(({ style }) => (
  <div
    style={{
      ...style,
      backgroundColor: '#FF9900',
      width: '10px',
      height: '10px',
      borderRadius: '50%',
      margin: '3px',
    }}
  />
));

// Define the PropagateLoader component
export function PropagateLoader() {
  const springs = useSprings(
    8, // number of dots
    [...Array(8)].map((_, i) => ({
      loop: true,
      to: [
        { scale: 1.5, opacity: 1 },
        { scale: 1, opacity: 0.3 },
      ],
      delay: i * 200, // delay between each dot starting its animation
      config: { duration: 1000 }, // duration of animation
      from: { scale: 1, opacity: 0.3 },
    })),
  );

  return (
    <div style={{ display: 'flex' }}>
      {springs.map((style, i) => (
        <Dot key={i} style={style} />
      ))}
    </div>
  );
}
