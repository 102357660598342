import React from 'react';
import PropTypes from 'prop-types';

import Constants from '../../../../../constants/constants';
import '../styles.scss';
import RadioButton from '../../../../shared/RadioButton/RadioButton';

const customValuesOption = ({
  title,
  name,
  tab,
  handleOptionSelected,
  checked,
  description,
  id,
  dontShowDynamicOrRowNumberValue,
  disabled,
}) => {
  /**
   * Checks if dynamic values or row number should be displayed or not, depending
   * on dontShowDynamicOrRowNumberValue and id variables
   * @returns {boolean} Enable or disable dynamic values and row number
   */
  const dynamicOrRowNumberValuesAreNotAvailable = () => dontShowDynamicOrRowNumberValue &&
    (id === Constants.CUSTOM_VALUES__FIELD_TYPE__DYNAMIC_VALUE ||
      id === Constants.CUSTOM_VALUES__FORMULA_TYPE__ROW_NUMBER);

  /**
   * Displays the appropriate title in the warning message depending on the variable id
   * @returns {string} title for dynamic value or row title
   */
  const returnCustomValueTypeTitle = () => id === Constants.CUSTOM_VALUES__FIELD_TYPE__DYNAMIC_VALUE ?
    Constants.CUSTOM_VALUES__FIELD_TYPE_WARNING__DYNAMIC_VALUE :
    Constants.CUSTOM_VALUES__FORMULA_TYPE_TITLE__ROW_NUMBER;

  const dynamicCustomValueNotAvailableText = `You need to select at least 1 Data Extension\
  in order to create a ${returnCustomValueTypeTitle()}.`;

  const renderLabel = () => (
    <>
      <div className="label-container">
        <span className={`title-container ${dynamicOrRowNumberValuesAreNotAvailable() ?
          'disable-container' :
          ''} `}>
          <b>{title}</b>
        </span>
      </div>
      <br />
      <p className={`${dynamicOrRowNumberValuesAreNotAvailable() ? 'disable-container' : 'description-container'}`}>
        {description}
      </p>
    </>
  );

  return (
    <>
      <RadioButton
        title={title}
        name={`${name}${tab}`}
        id={`${name}${tab}`}
        onChange={e => handleOptionSelected(e, id)}
        checked={checked}
        className={`${dynamicOrRowNumberValuesAreNotAvailable() ? 'disable-container' : ''}`}
        disabled={dynamicOrRowNumberValuesAreNotAvailable() || disabled}
        key={`${name}${tab}`}
        label={renderLabel()}
        tooltipText={dynamicOrRowNumberValuesAreNotAvailable() ? dynamicCustomValueNotAvailableText : ''}
      />
      { id !== Constants.CUSTOM_VALUES__FIELD_TYPE__AGGREGATION_VALUE && <br /> }
    </>
  );
};

customValuesOption.propTypes = {
  /**
   * Title of the option
   */
  title: PropTypes.string.isRequired,
  /**
   * Name of the option
   */
  name: PropTypes.string.isRequired,
  /**
   * The current tab's id
   */
  tab: PropTypes.number.isRequired,
  /**
   * Event handler for when the radio button is clicked
   */
  handleOptionSelected: PropTypes.func.isRequired,
  /**
   * The state of the radio button
   */
  checked: PropTypes.bool.isRequired,
  /**
   * Description of the option
   */
  description: PropTypes.string.isRequired,
  /**
   * Id of the option
   */
  id: PropTypes.string.isRequired,
  /**
   * Decides if dynamic values or row number should be rendered or not (coming from CustomValues.js)
   */
  dontShowDynamicOrRowNumberValue: PropTypes.bool,
};

export default customValuesOption;
