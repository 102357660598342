import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { freeFormulaItems } from './FreeFormula';
import InsertFunction from './InsertFunction/InsertFunction';
import './styles.scss';
import Constants from '../../../../../constants/constants';
import InsertField from './InsertField/InsertField';
import tokenize from './Validation/Tokenizer';
import ValidationConstants from './Validation/ValidationConstants';
import { isFunctionRecognized, isKeywordRecognized } from './Validation/Validator';
import Button from '../../../../shared_v2/Button/Button';
import Select from '../../../../shared_v2/Select/Select';
import DropdownOptions from '../../../../../constants/dropdownOptions';

class FreeFormula extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPasteTriggered: false,
      isNewLine: false,
      lastLengthToCursor: 0,
      lastDivCounter: 0,
    };

    // if the InstallTrigger (indicator for Mozilla) is not undefined that means that we are in Firefox
    this.isFirefox = typeof InstallTrigger !== 'undefined';
  }

  componentDidMount() {
    const { freeFormulaData } = this.props;
    const textArea = document.getElementById(Constants.FREE_FORMULA__TEXTAREA_INPUT__ID);

    // put focus on the textarea
    textArea.focus();
    const { value } = freeFormulaData;

    // set inner html to the free formula value
    textArea.innerHTML = value || '';

    let lengthToCursor = 0;

    let divCounter = 0;

    // get the lengthToCursor and divCounter for the freeFormulaData value
    const newValuesForCursorPosition = this.getNewCursorPosition(value);

    lengthToCursor = newValuesForCursorPosition.newLengthToCursor;
    divCounter = newValuesForCursorPosition.newDivCounter;

    // call function to colour formula text
    this.colourFunctions(textArea.textContent, lengthToCursor, divCounter);
  }

  /**
   * It helps to get the properties to find out where is the current cursor position
   * @returns {object} { lengthToCursor, divCounter, startOffsetOfRange } - where lengthToCursor is the length of the
   * beginning of the div to the current range offset, divCounter determines in which row is the cursor,
   * startOffsetOfRange is the start position of the range in the current element
   */
  getCurrentCursorPosition = () => {
    const textArea = document.getElementById(Constants.FREE_FORMULA__TEXTAREA_INPUT__ID);

    textArea.focus();
    // get current range
    const currentRange = window.getSelection().getRangeAt(0);

    // get startContainer of range (current node in which is the current cursor located)
    let container = currentRange.endContainer;

    // get startOffset (position of the cursor in the current node)
    const startOffsetOfRange = currentRange.startOffset;

    // counter to save length from the beginning to current cursor position
    let lengthToCursor = startOffsetOfRange;

    // determines in which div (row) we are
    let divCounter = 0;

    /*
     * in Mozilla html elements are handled differently than in Chrome
     * the last node will be always represented as a div
     * in that case, apply this piece of code
     */
    if (container && container.nodeName && container.nodeName === 'DIV' && this.isFirefox &&
    container.id !== Constants.FREE_FORMULA__TEXTAREA_INPUT__ID) {
      // check if the given container (html node) has a childNodes array which is not empty
      if (container.childNodes?.length > 0) {
        // get the last child of the current node
        container = container.childNodes[container.childNodes.length - 1];
      }

      /*
       * while the container (node) has a firstChild
       * set the current node to the the firstChild node until there are no more firstChild nodes
       */
      while (container.firstChild) {
        container = container.firstChild;
      }

      // get the length of the container (on the end this container should be the text node with the length property)
      if (container && container.length) {
        // set the lengthToCursor to the length of the container (node)
        lengthToCursor = container.length;
      }

      if (container.nodeName && container.nodeName === 'BR') {
        lengthToCursor = 0;
      }
    }

    /*
     * loop throught nodes until node is div
     * example: let's say that we are in the third span node
     * take its previous node and add length of that node to the 'lengthToCursor'
     * do that until div node is not found
     */
    while (container && container.nodeName && container.nodeName !== 'DIV') {
      // check for the previous sibling node
      const previousSiblingOfContainer = container.previousSibling;

      // if the previous container is a 'div' increase the divCounter
      if (previousSiblingOfContainer && previousSiblingOfContainer.nodeName &&
        previousSiblingOfContainer.nodeName === 'DIV') {
        divCounter += 1;
        break;
      }

      // if the previous sibling node exists
      if (previousSiblingOfContainer && previousSiblingOfContainer !== '' && previousSiblingOfContainer !== null) {
        // check for the child nodes of the previous sibling node
        if (previousSiblingOfContainer.childNodes && previousSiblingOfContainer.childNodes.length > 0) {
          // add length of the child node of the previous sibling node
          lengthToCursor += previousSiblingOfContainer.childNodes[0].length;
          // if the previousSibling contains 'new line' don't count it
        } else if (previousSiblingOfContainer.nodeValue && !previousSiblingOfContainer.nodeValue[0].match(/[\n\r]/g)) {
          // if the previous sibling doens't have child node then take length of that previous sibling node
          lengthToCursor += previousSiblingOfContainer.length;
        }

        // change current container to the previous sibling node
        container = previousSiblingOfContainer;
        // if the current node doesn't have previous sibling check for its parent node
      } else if (container.parentNode) {
        // set current container to the its parent node
        container = container.parentNode;
      } else {
        break;
      }
    }

    /*
     * after the while loop from before, container should be div element
     * check if the container is div element and if it's different than textarea
     */
    if (container && container.nodeName === 'DIV' && container.id !== Constants.FREE_FORMULA__TEXTAREA_INPUT__ID) {
      let prevContainer = container;

      /*
       * loop through  to find how many DIVs are
       * before current node (including the current div node)
       */
      while (prevContainer && prevContainer.nodeName === 'DIV') {
        /*
         * take 'previousElementSibling' of the current node or 'previousSibling'
         * 'previousElementSibling' will be null in the case that first line is deleted
         *  or if the first line only has a text (without coloured span)
         * if that is the case then check for previousSibling
         */
        prevContainer = prevContainer.previousElementSibling || prevContainer.previousSibling;

        // if prevContainer exists means that we are in the div element so the 'divCounter' will be increased
        if (prevContainer) {
          divCounter += 1;
        } else break;
      }
    }

    // return needed values
    return { lengthToCursor, divCounter, startOffsetOfRange };
  };

  /**
   * It helps to add proper values to the textarea (free input or function/DE import)
   * @param {string} formula - formula from textarea
   * @param {number} length - length of the textarea formula when first time rendering this component or on insert
   * @param {number} counterDivs - determines in which row is the current cursor position
   * @returns {void}
   */
  colourFunctions = (formula, length, counterDivs) => {
    // fetch textArea element by id
    const textArea = document.getElementById(Constants.FREE_FORMULA__TEXTAREA_INPUT__ID);

    // this will be for saving tokenized formula text
    let tokenizedFormula = {};

    // this will be for saving coloured formula text
    let newHTML = '';

    let startOffsetOfRange;

    let lengthToCursor;

    let divCounter;

    // if the length to the cursor and div are unknown
    if (length === undefined && counterDivs === undefined) {
    // get startOffsetOfRange, lengthToCursor and divCounter
      const currentCursorPosition = this.getCurrentCursorPosition();

      // set startOffsetOfRange
      startOffsetOfRange = currentCursorPosition.startOffsetOfRange;

      // set lengthToCursor
      lengthToCursor = currentCursorPosition.lengthToCursor;

      // set divCounter
      divCounter = currentCursorPosition.divCounter;
      // if the length to the cursor and div are known
    } else {
      // set lengthToCursor and divCounter from the received param
      lengthToCursor = length;
      divCounter = counterDivs;
    }

    // get tokenized formula
    tokenizedFormula = tokenize(formula, true);

    let newLine = false;

    // it's for checking whether we are in the string or not
    let inString = false;

    // loop through tokenized formula to find functions and key words
    if (tokenizedFormula.tokens && tokenizedFormula.tokens.length > 0) {
      tokenizedFormula.tokens.forEach((token, index) => {
        /**
         * It helps to skip the space or new line in the string
         * @param {number} idx - index from which we count
         * @returns {number} - index with omitted space
         */
        const findIndexWhereSpaceEnds = (idx) => {
          // start counting from 1, because if there is no space we will move one index back
          let indexForSpace = 1;

          /*
           * check if there is a space or new line before the given index, increment the indexForSpace
           * until it finds it. Use it to check what is before keyword without spaces or new-lines
           */
          while (tokenizedFormula.tokens[idx] && tokenizedFormula.tokens[idx - indexForSpace] && (
            tokenizedFormula.tokens[idx - indexForSpace].type === ValidationConstants.TOKEN_TYPE__SPACE ||
            tokenizedFormula.tokens[idx - indexForSpace].type === ValidationConstants.TOKEN_TYPE__NEW_LINE)) {
            indexForSpace += 1;
          }

          return indexForSpace;
        };

        /**
         * It helps to check how many indices does a string with quotes take
         * @param {number} idx - index from which we count
         * @returns {number} - number of quotes (single and double quotes are named in tokenizedFormula as a string)
         */
        const getNumberOfQuotes = (idx) => {
          let indexForString = 1;

          /*
           * check if there is a quote (named in the token as a string) before the given index,
           * increment the indexForString until there is a string.
           * Use it to check what is before keyword without quotes
           */
          while (tokenizedFormula.tokens[idx - indexForString] &&
            tokenizedFormula.tokens[idx - indexForString].type === ValidationConstants.TOKEN_TYPE__STRING) {
            indexForString += 1;
          }

          // subtract one because we're counting the number of quotes
          return indexForString - 1;
        };

        /**
         * It helps to find the index where the string starts
         * @param {number} idx - index from which we count
         * @returns {number} - index where the string begins
         */
        const findIndexOfStartingStringPosition = (idx) => {
          // start from 0
          let indexForString = 0;

          /*
           * go back (<--- in this direction) from the given index,
           * increment the indexForString until you find the string
           */
          while (tokenizedFormula.tokens[idx - indexForString] &&
            tokenizedFormula.tokens[idx - indexForString].type !== ValidationConstants.TOKEN_TYPE__STRING) {
            indexForString += 1;
          }

          return indexForString;
        };

        /**
         * It helps to find the index where the function starts
         * @param {number} idx - index from which we count
         * @returns {number} - index where function begins
         */
        const findIndexOfStartingFunctionPosition = (idx) => {
          // start from 0
          let indexForStartFunction = 0;

          /*
           * go back (<--- in this direction) from the given index,
           * increment the indexForString until you find the function
           */
          while (tokenizedFormula.tokens[idx - indexForStartFunction] &&
            tokenizedFormula.tokens[idx - indexForStartFunction].type !== ValidationConstants.TOKEN_TYPE__FUNCTION) {
            indexForStartFunction += 1;
          }

          return indexForStartFunction;
        };

        /**
         *  It helps to count number of spaces before string
         * @param {number} idx - index from which we count
         * @returns {number} - number of spaces before string
         */
        const findNumberOfSpaceBeforeString = (idx) => {
          // start from 0, because we count the number of spaces
          let indexForSpaceBeforeString = 0;

          /*
           * go back (<--- in this direction) from the given index, increment the indexForString until there is a space.
           * Use it to check how many spaces there are before a string
           */
          while (tokenizedFormula.tokens[idx - indexForSpaceBeforeString] &&
            tokenizedFormula.tokens[idx - indexForSpaceBeforeString].type === ValidationConstants.TOKEN_TYPE__SPACE) {
            indexForSpaceBeforeString += 1;
          }

          return indexForSpaceBeforeString;
        };

        /**
         * It helps to skip the parameters
         * @param {number} idx - index from which we count
         * @returns {number} - index with omitted parameters
         */
        const findIndexWithSkippedParam = (idx) => {
          let indexForSkipTheKeyword = 0;

          /*
           * go back (<--- in this direction) from the given index,
           * increment the indexForSkipTheKeyword until this parameters occur.
           * Use it to check if keyword is in the string or not
           */
          while (tokenizedFormula.tokens[idx - indexForSkipTheKeyword] &&
            (tokenizedFormula.tokens[idx - indexForSkipTheKeyword].type === ValidationConstants.PARAM_TYPE__KEYWORD ||
            tokenizedFormula.tokens[idx - indexForSkipTheKeyword].type === ValidationConstants.TOKEN_TYPE__SPACE ||
            tokenizedFormula.tokens[idx - indexForSkipTheKeyword].type === ValidationConstants.TOKEN_TYPE__NUMBER ||
            tokenizedFormula.tokens[idx - indexForSkipTheKeyword].type === ValidationConstants.TOKEN_TYPE__OPEN_PAREN
            )
          ) {
            indexForSkipTheKeyword += 1;
          }

          return indexForSkipTheKeyword;
        };

        /**
         * Function for checking if the keyword is in the string and can be colored
         * @param {number} customIndex - index for keyword
         * @returns {boolean} - whether the keyword is in the string or not
         */
        const validateColorKeywordInTheString = (customIndex) => {
          // skip the space, get index without space
          const spaceNumber = findIndexWhereSpaceEnds(customIndex);

          // get token without space
          const endTokenizedFormula = tokenizedFormula.tokens[customIndex -
            findIndexWhereSpaceEnds(customIndex)];

          // index with omitted parameters
          const omittedParamsIndex = findIndexWithSkippedParam(customIndex -
              findIndexWhereSpaceEnds(customIndex));

          // check what token appears after omitted parameters
          const tokenBeforeParams = tokenizedFormula.tokens[customIndex -
            findIndexWhereSpaceEnds(customIndex) - omittedParamsIndex];

          if (tokenBeforeParams) {
          // if we start with a string or operator +, we are in a string
            if (tokenBeforeParams.type &&
            (tokenBeforeParams.type === ValidationConstants.TOKEN_TYPE__STRING ||
            tokenBeforeParams.type === ValidationConstants.TOKEN_TYPE__OPERATOR)) {
              inString = true;
            } else if (tokenBeforeParams.type &&
              // when there is no string at the beginning
              tokenBeforeParams.type === ValidationConstants.TOKEN_TYPE__DELIMITER) {
              inString = false;
            }
          }

          /*
           * this is the condition when the functions begins, now we're not in the string,
           * because string occurs before the function (last condition)
           */
          if (tokenizedFormula.tokens[customIndex - findIndexOfStartingFunctionPosition(customIndex)] &&
            tokenizedFormula.tokens[customIndex - findIndexOfStartingFunctionPosition(customIndex)].type ===
           ValidationConstants.TOKEN_TYPE__FUNCTION && tokenizedFormula.tokens[customIndex -
            findIndexOfStartingFunctionPosition(customIndex) + 1] && tokenizedFormula.tokens[customIndex -
              findIndexOfStartingFunctionPosition(customIndex) + 1].type ===
              ValidationConstants.TOKEN_TYPE__OPEN_PAREN &&
               (findIndexOfStartingStringPosition(customIndex) > findIndexOfStartingFunctionPosition(customIndex))
          ) {
            inString = false;
          }

          // start counting with a comma or '+' operator, go backwards and check the conditions
          if (endTokenizedFormula && (endTokenizedFormula.type === ValidationConstants.TOKEN_TYPE__DELIMITER ||
            endTokenizedFormula.type === ValidationConstants.TOKEN_TYPE__OPERATOR)) {
            // check if we have a space or a string, return indexes without these token types
            const stringBeforeDelimiter = getNumberOfQuotes(customIndex - spaceNumber);
            const spaceBeforeDelimiter = findIndexWhereSpaceEnds(customIndex - spaceNumber);

            // check number of spaces before string
            const checkSpaceBeforeString = findNumberOfSpaceBeforeString(customIndex -
              findIndexWhereSpaceEnds(customIndex) - spaceBeforeDelimiter - stringBeforeDelimiter);

            // when the space is not pressed
            const endTokenizedFormulaWithoutSpace = tokenizedFormula.tokens[customIndex -
              findIndexWhereSpaceEnds(customIndex) - spaceBeforeDelimiter];

            // allow to put empty string as a separate parameter
            const beforeEmptyString = tokenizedFormula.tokens[customIndex -
              findIndexWhereSpaceEnds(customIndex) - spaceBeforeDelimiter -
              stringBeforeDelimiter - checkSpaceBeforeString];

            // treat an empty string as the beginning and ending of a string
            if (!beforeEmptyString || (beforeEmptyString && beforeEmptyString.type ===
             ValidationConstants.TOKEN_TYPE__DELIMITER)) {
              inString = false;
            } else if (stringBeforeDelimiter > 0 && stringBeforeDelimiter % 2 !== 0 &&
              endTokenizedFormulaWithoutSpace &&
              endTokenizedFormulaWithoutSpace.type === ValidationConstants.TOKEN_TYPE__STRING
            ) {
              // that means the string has ended
              inString = false;
            } else if (stringBeforeDelimiter > 0 && stringBeforeDelimiter % 2 === 0 &&
              endTokenizedFormulaWithoutSpace &&
              endTokenizedFormulaWithoutSpace.type === ValidationConstants.TOKEN_TYPE__STRING
            ) {
              // that means the string has not ended
              inString = true;
            } else if (stringBeforeDelimiter === 0) {
              /*
               * if there is a space before delimiter (not string),
               * get number of single string
               */
              const stringAfterSpace = getNumberOfQuotes(customIndex -
                findIndexWhereSpaceEnds(customIndex) - spaceBeforeDelimiter + 1);

              //  get number of spaces before string
              const countSpaceBeforeString = findNumberOfSpaceBeforeString(customIndex -
                findIndexWhereSpaceEnds(customIndex) - spaceBeforeDelimiter - stringAfterSpace);

              // check if there is a empty string there
              const checkForEmptyString = tokenizedFormula.tokens[customIndex -
                findIndexWhereSpaceEnds(customIndex) - spaceBeforeDelimiter - stringAfterSpace -
                countSpaceBeforeString];

              // get the token without empty strings
              const endTokenizedFormulaWithoutString = tokenizedFormula.tokens[customIndex -
                findIndexWhereSpaceEnds(customIndex) - stringAfterSpace];

              // index with omitted parameters
              const omittedParamInTheString = findIndexWithSkippedParam(customIndex -
                  findIndexWhereSpaceEnds(customIndex) - spaceBeforeDelimiter -
                  stringAfterSpace - countSpaceBeforeString);

              // check what is after omitted parameters - are we in the string or not?
              const beforeParams = tokenizedFormula.tokens[customIndex -
                findIndexWhereSpaceEnds(customIndex) - spaceBeforeDelimiter -
                stringAfterSpace - countSpaceBeforeString - omittedParamInTheString];

              // treat an empty string as the beginning and ending of a string
              if (!checkForEmptyString || (checkForEmptyString && checkForEmptyString.type ===
               ValidationConstants.TOKEN_TYPE__DELIMITER)) {
                inString = false;
              } else if (stringAfterSpace > 0 && stringAfterSpace % 2 !== 0 &&
                endTokenizedFormulaWithoutString &&
                (endTokenizedFormulaWithoutString.type === ValidationConstants.TOKEN_TYPE__SPACE ||
                endTokenizedFormulaWithoutString.type === ValidationConstants.TOKEN_TYPE__STRING ||
                endTokenizedFormulaWithoutString.type === ValidationConstants.TOKEN_TYPE__CLOSED_PAREN
                )
              ) {
                // that means the string has ended
                inString = false;
              } else if (stringAfterSpace > 0 && stringAfterSpace % 2 === 0 &&
                endTokenizedFormulaWithoutString &&
                (endTokenizedFormulaWithoutString.type === ValidationConstants.TOKEN_TYPE__SPACE ||
                endTokenizedFormulaWithoutString.type === ValidationConstants.TOKEN_TYPE__STRING)
              ) {
                // that means the string has not ended
                inString = true;
              } else if (stringAfterSpace === 0 && endTokenizedFormulaWithoutString &&
                endTokenizedFormulaWithoutString.type === ValidationConstants.TOKEN_TYPE__DELIMITER &&
                checkForEmptyString.type === ValidationConstants.TOKEN_TYPE__CLOSED_PAREN) {
                // in this case the function ends, we are no longer in the string
                inString = false;
              } else if (stringAfterSpace === 0 && endTokenizedFormulaWithoutString &&
                endTokenizedFormulaWithoutString.type === ValidationConstants.TOKEN_TYPE__DELIMITER && beforeParams &&
                beforeParams.type === ValidationConstants.TOKEN_TYPE__STRING) {
                // in this case we see that we have a string at the beginning, so we are in the string
                inString = true;
              }
            }

            // after the operator we start a new parameter
            if (endTokenizedFormula.type === ValidationConstants.TOKEN_TYPE__OPERATOR &&
               endTokenizedFormulaWithoutSpace &&
            endTokenizedFormulaWithoutSpace.type === ValidationConstants.TOKEN_TYPE__CLOSED_PAREN) {
              inString = false;
            }
          }

          // return if the keyword is in the string or not
          return inString;
        };

        /*
         * if we find token value that symbolizing a new line
         * then check if the next token value is different than new line
         */
        if (token.type === ValidationConstants.TOKEN_TYPE__NEW_LINE &&
          tokenizedFormula.tokens[index + 1] &&
          tokenizedFormula.tokens[index + 1].type !== ValidationConstants.TOKEN_TYPE__NEW_LINE
        ) {
          let j = index + 1;

          // create a new div for the new line
          let newLineHTML = '<div class="free-formula-input-div">';

          /*
           * continue looping through tokenized formula from the next token while new line is not found
           * or until tokenized formula length
           */
          while (tokenizedFormula.tokens[j] &&
            tokenizedFormula.tokens[j].type !== ValidationConstants.TOKEN_TYPE__NEW_LINE &&
            j < tokenizedFormula.tokens.length) {
            // if the token type is function add a new span (with the proper color) to the html element
            if (tokenizedFormula.tokens[j].type === ValidationConstants.TOKEN_TYPE__FUNCTION &&
              isFunctionRecognized(tokenizedFormula.tokens[j].value).success) {
              newLineHTML += `<span style="color:purple">${tokenizedFormula.tokens[j].value}</span>`;

            /*
             * if the token type is key word and does not appear in the string,
             * add a new span (with the proper color) to the html element
             */
            } else if (
              tokenizedFormula.tokens[j].type === ValidationConstants.TOKEN_TYPE__KEYWORD &&
              isKeywordRecognized(tokenizedFormula.tokens[j].value).success &&
               !validateColorKeywordInTheString(j)) {
              newLineHTML += `<span style="color:green">${tokenizedFormula.tokens[j].value}</span>`;

              // otherwise just add value to the new html
            } else if (tokenizedFormula.tokens[j].type === ValidationConstants.TOKEN_TYPE__SPACE) {
              newLineHTML += '&nbsp;';
            } else {
              newLineHTML += `${tokenizedFormula.tokens[j].value}`;
            }

            // increase the counter
            j += 1;
          }

          // close div element
          newLineHTML += '</div>';

          // add html element of the div node to the 'newHTML'
          newHTML += newLineHTML;

          // set newLine to true
          newLine = true;
          // if the current token is just a new line (without any text inside)
        } else if (token.type === ValidationConstants.TOKEN_TYPE__NEW_LINE) {
          // add a new div to the 'newHTML'
          newHTML += '<div class="free-formula-input-div">' +
          '</br>' +
          '</div>';

          // set newLine to true
          newLine = true;
          // if there are no new lines, check if the current token type is function
        } else if (token.type === ValidationConstants.TOKEN_TYPE__FUNCTION && !newLine &&
        isFunctionRecognized(token.value).success) {
          // add a new span element (with the proper color) to the 'newHTML'
          newHTML += `<span style="color:purple">${token.value}</span>`;
        // if there are no new lines and token is not in the string, check if the current token type is key word
        } else if (token.type === ValidationConstants.TOKEN_TYPE__KEYWORD && !newLine &&
          isKeywordRecognized(token.value).success && !validateColorKeywordInTheString(index)) {
          // add a new span element (with the proper color) to the 'newHTML'
          newHTML += `<span style="color:green">${token.value}</span>`;
          // check if there are no new lines and add the token value to the 'newHTML'
        } else if (token.type === ValidationConstants.TOKEN_TYPE__SPACE && !newLine) {
          newHTML += '&nbsp;';
        } else if (!newLine) {
          newHTML += `${token.value}`;
        }
      });
    }

    // set innerHTML of the textarea to the newly created HTML
    textArea.innerHTML = newHTML;

    // get child nodes of the textarea
    const childs = textArea.childNodes;

    // counter to find node where 'lengthToCursor' is
    let cursorPositionLength = 0;

    // new node container where the 'lengthToCursor' is
    let childrenContainer;

    // cursor position in the new node
    let cursorPosition = 0;

    // if childs of the textarea exist
    if (childs && childs.length > 0) {
      // check if the 'divCounter' is 0 (means we are not in div)
      if (divCounter === 0) {
        const child = childs[0];

        // set childrenContainer to the first child of the textarea
        childrenContainer = child;
        // otherwise 'divCounter' will be greater than 0 (means we are in one of the divs)
      } else {
        // to find in which div node we are
        let divNode = null;

        let counterDiv = 0;

        let i = 0;

        // if the first child is 'div' skip it (increase 'i' counter by one)
        if (childs[0].localName && childs[0].localName === 'div') {
          i += 1;
        }

        // loop through the textarea children
        for (i; i < childs.length; i += 1) {
          // if the div node is found increase the 'counterDiv'
          if (childs[i].localName && childs[i].localName === 'div') {
            counterDiv += 1;
          }

          // if the div is found set 'divNode' to the that div node
          if (counterDiv === divCounter) {
            divNode = childs[i];
            break;
          }
        }

        // if the div we are currently in has the childNodes
        if (divNode && divNode.childNodes && divNode.childNodes.length > 0) {
          const divChild = divNode.childNodes[0];

          // set 'childrenContainer' to the first child of the current div node
          childrenContainer = divChild;
        }
      }

      /*
       * loop through child nodes of the current div until cursorPositionLength is equal to the
       * lengthToCursor (means that node of the cursor position is found)
       */
      while (cursorPositionLength !== lengthToCursor && lengthToCursor !== undefined) {
        cursorPosition = 0;

        // if there are childNodes
        if (childrenContainer.childNodes && childrenContainer.childNodes.length > 0) {
          const childNode = childrenContainer.childNodes[0];

          // loop through first child node value to check if 'lengthToCursor' is found
          for (let i = 0; i < childNode.nodeValue.length; i += 1) {
            cursorPosition += 1;
            cursorPositionLength += 1;

            // if 'lengthToCursor' is found then set 'childrenContainer' to that node
            if (cursorPositionLength === lengthToCursor) {
              childrenContainer = childNode;
              break;
            }
          }
          // otherwise loop through 'childrenContainer' node value to check if 'lengthToCursor' is found
        } else if (childrenContainer?.nodeValue) {
          for (let i = 0; i < childrenContainer.nodeValue.length; i += 1) {
            cursorPosition += 1;
            cursorPositionLength += 1;

            // if 'lengthToCursor' is found then stop the loop
            if (cursorPositionLength === lengthToCursor) {
              break;
            }
          }
        } else break;

        // if lengthToCursor is not found set 'childrenContainer' to the 'nextSibling'
        if (cursorPositionLength !== lengthToCursor &&
            childrenContainer.nextSibling && childrenContainer.nextSibling !== '' &&
        childrenContainer.nextSibling !== null) {
          childrenContainer = childrenContainer.nextSibling;
        }
      }

      // get selection and create a new range
      const selection = window.getSelection();
      const newRange = document.createRange();

      // set range on the last element if the childrenContainer is undefined
      if (childrenContainer === undefined) {
        newRange.setStart(childs[childs.length - 1], startOffsetOfRange);
      } else {
        /*
         * otherwise set start position of the new range (first parameter: node,
         * second parameter: cursor position in the node)
         */
        newRange.setStart(childrenContainer, cursorPosition);
      }

      newRange.collapse(true);

      // remove the previous range
      selection.removeAllRanges();

      // add new range
      selection.addRange(newRange);

      this.setState({ lastLengthToCursor: lengthToCursor, lastDivCounter: divCounter });
    }
  };

  /**
   * It helps to add proper values to the textarea (free input or function/DE import)
   * @param {object} e - event
   * @param {string} insertedValue - can be either a function (UPPER,LEFT,...) or
   * a DE with a field ("DEMO_CONTACT"."AccountId")
   * @returns {void}
   */
  handleOnChange = (e, insertedValue) => {
    const { handleSetCustomValuesState, freeFormulaData } = this.props;

    // check if we are inserting a function
    if (insertedValue) {
      // when inserting some function or a field call handleOnPaste function
      this.handleOnPaste(e, insertedValue, true);
    } else {
      this.colourFunctions(e.target.innerText);
    }

    // get entire text from textarea
    const textArea = document.getElementById(Constants.FREE_FORMULA__TEXTAREA_INPUT__ID);

    // update freeFormulaData prop
    const updatedFreeFormulaData = { ...freeFormulaData, value: textArea.innerText };

    handleSetCustomValuesState({
      freeFormulaData: updatedFreeFormulaData,
      /*
       * if free formula has been changed set isFreeFormulaValidated to false so
       * the handleCheckSyntax function can be called
       */
      isFreeFormulaValidated: false,
    });
  };

  /**
   * It helps to change field type from dropdown
   * @param {object} e - event
   * @returns {void}
   */
  handleOnChangeFieldType = (e) => {
    const { handleSetCustomValuesState, freeFormulaData } = this.props;

    const { name } = e.target;
    const { value } = e.target;

    // update freeFormulaData prop
    const updatedFreeFormulaData = { ...freeFormulaData, [name]: value };

    handleSetCustomValuesState({ freeFormulaData: updatedFreeFormulaData });
  };

  /**
   * It helps to find a new position of the cursor
   * @param {string} text - text to find a new cursor position
   * @param {number} divCounter - div position
   * @returns {object} { newLengthToCursor, newDivCounter, index } where newLengthToCursor is the lengthToCursor of the
   * text, newDivCounter is the number of divs that passed text contains and index is iteration counter (i) of the
   * current cursor position (need that to get text before and after the current position so we can insert it there)
   */
  getNewCursorPosition = (text, divCounter) => {
    let newLengthToCursor = 0;

    let newDivCounter = 0;

    let isDiv = false;

    let index = 0;

    // loop through free formula value
    for (let i = 0; i < text?.length; i += 1) {
      /*
       * This if statement is to check if the first char is an enter.
       * So the iteration counter (i) will be zero and the first char will be enter, second char will be enter and
       * the third char can be either enter or some other value.
       * In that case increment the iteration counter and counterOfDivs
       */
      if (i === 0 && text[i].match(/[\n]/g) && text[i + 1] && text[i + 1].match(/[\n]/g) && text[i + 2]) {
        // increase the iteration counter and divCounter
        i += 1;
        newDivCounter += 1;

        // set isDiv state to true
        isDiv = true;
        // new line is represented as two "new line" chars (↵)
      } else if (text[i].match(/[\n]/g) && text[i + 1] && text[i + 1].match(/[\n]/g)) {
        // increase the div counter and the iteration counter
        newDivCounter += 1;
        i += 1;

        // set isDiv state to true
        isDiv = true;
        // new line can be followed by some string char
      } else if (text[i].match(/[\n]/g) && text[i + 1]) {
        // then increment the div counter
        newDivCounter += 1;

        // set isDiv state to true
        isDiv = true;
      }

      // if the 'div', where the cursor position is located, is found
      if (newDivCounter === divCounter) {
        index = i;
        break;
      }

      /*
       * whenever is the new div found reset the lengthToCursor counter
       * because we want only to count length to cursor for within div
       */
      if (isDiv) {
        newLengthToCursor = 0;

        // if div is true reset it (set it to false)
        isDiv = false;
        // increase lengthToCursor counter
      } else {
        newLengthToCursor += 1;
      }
    }

    return { newLengthToCursor, newDivCounter, index };
  };

  /**
   * It helps to paste the copied text
   * @param {object} e - event
   * @param {string} letterToInsert - letter to insert into textarea
   * @param {string} insertFormulaOrField - formula or a field to insert
   * @returns {void}
   */
  handleOnPaste = (e, letterToInsert, insertFormulaOrField) => {
    let textToPaste;

    // when this function is triggered from onKeyUp function set a letter as a parameter to paste
    if (letterToInsert) {
      textToPaste = letterToInsert;
    // else get the text from clipboard
    } else {
    // this one prevents default behaviour of the onPaste function
      e.preventDefault();

      // get text we want to copy
      textToPaste = (e.clipboardData || window.clipboardData).getData('text');

      /*
       * set this state to true so the code knows that onPaste is triggered, prevents
       * onKeyUp function to tigger handleOnPaste function again
       */
      this.setState({ isPasteTriggered: true });
    }

    /*
     * we need this because sometimes when you copy paste something
     * from different source it has different characters for new line
     */
    let modifiedTextToPast = '';

    // newLengthToCursor is the lengthToCursor of the text we are copying
    let newLengthToCursor = 0;

    // newDivCounter is the number of divs that copied text has
    let newDivCounter = 0;

    // determines if the div is found
    let isDiv = false;

    // loop through text to paste
    for (let i = 0; i < textToPaste.length; i += 1) {
    /*
     * text copied from the text area as a div has the following: first char is always \r and second is \n
     * so if we find something like that add two new line characters (cause tokenizer function
     * works only with \n characters)
     */
      if (textToPaste[i].match(/[\r]/g) && textToPaste[i + 1].match(/[\n]/g)) {
        modifiedTextToPast += '\n\n';

        // increase the div counter
        newDivCounter += 1;

        /*
         * set isDiv to true and increase the iteration counter (will skip the i + 1 char
         * because we already counted it as a new line)
         */
        isDiv = true;
        i += 1;
      /*
       * otherwise some text pasted from other sources (diff than textarea) will have
       * as a new line only one \n character. So add two new lines (cause tokenizer function
       * considers a new line as a two \n chars)
       */
      } else if (textToPaste[i].match(/[\n]/g)) {
        modifiedTextToPast += '\n\n';

        // increase the div counter
        newDivCounter += 1;

        // set isDiv to true
        isDiv = true;
      // if don't find any divs just add char to modifiedTextToPast
      } else {
        modifiedTextToPast += textToPaste[i];
      }
      /*
       * whenever is the new div found reset the newLengthToCursor counter
       * because we want only to count length to cursor for within div
       */
      if (isDiv) {
        newLengthToCursor = 0;
        // if div is true reset it (set it to false)
        isDiv = false;
      // increase newLengthToCursor counter
      } else {
        newLengthToCursor += 1;
      }
    }

    // current div counter
    let divCounter;

    let lengthToCursor;

    // if a field or formula is getting inserted set lengthToCursor and divCounter from the state
    if (insertFormulaOrField) {
      const { lastLengthToCursor, lastDivCounter } = this.state;

      lengthToCursor = lastLengthToCursor;
      divCounter = lastDivCounter;
    } else {
    // get the current length to cursor and the current div counter
      const retrievedValues = this.getCurrentCursorPosition();

      // set the current length to cursor and the current div counter
      divCounter = retrievedValues.divCounter;
      lengthToCursor = retrievedValues.lengthToCursor;
    }

    // focus on the textarea before inserting value
    const textArea = document.getElementById(Constants.FREE_FORMULA__TEXTAREA_INPUT__ID);

    textArea.focus();

    let textBeforeCursorPosition;

    let textAfterCursorPosition;

    // if the current cursor position is not in the first row of the textarea
    if (divCounter > 0) {
      const indexForSubstring = this.getNewCursorPosition(textArea.innerText, divCounter).index;

      // get text before the 'div' where the cursor position is located
      textBeforeCursorPosition = textArea.innerText.substring(0, indexForSubstring);

      /*
       * get text in the current div from the start position of the div (char on the
       * position of the iteration counter (i)) to the lengthToCursor + i + 1
       * lengthToCursor + i + 1 -> need this because we want to take the character on the
       * position of the lengthToCursor (substring function doesn't take the element on the
       * position of the second passed parameter)
       */
      textBeforeCursorPosition += textArea.innerText.substring(
        indexForSubstring,
        lengthToCursor + indexForSubstring + 1,
      );

      // get text after the current cursor position
      textAfterCursorPosition = textArea.innerText.substring(
        lengthToCursor + indexForSubstring + 1,
        textArea.innerText.length,
      );

    // if the cursor position is in the first row of the textarea
    } else {
    // get the text before and after cursor position
      textBeforeCursorPosition = textArea.innerText.substring(0, lengthToCursor);
      textAfterCursorPosition = textArea.innerText.substring(lengthToCursor, textArea.innerText.length);
    }

    // if the textAfterCursorPosition contains only a 'new-line' char, remove it
    if (textAfterCursorPosition && textAfterCursorPosition.length === 1 &&
      textAfterCursorPosition[0].match(/[\n\r]/g)) {
      textAfterCursorPosition = textAfterCursorPosition.slice(0, -1);
    }

    // insert the copied text on the position where the cursor is currently in
    const value = textBeforeCursorPosition + modifiedTextToPast + textAfterCursorPosition;

    // determines lengthToCursor for within last div
    let totalLength;

    // if newDivCounter (div counter of the text we are copying) is greater than zero
    if (newDivCounter > 0) {
    // add number of divs of the text we are copying to the current number of divs
      divCounter += newDivCounter;

      // total length will be the newLengthToCursor (lengthToCursor in the last div)
      totalLength = newLengthToCursor;

    // otherwise text we are copying doesn't have new lines
    } else {
    // totalLength will be length of the text we are copying + lengthToCursor
      totalLength = textToPaste.length + lengthToCursor;
    }

    // colour new text value
    this.colourFunctions(value, totalLength, divCounter);

    const { handleSetCustomValuesState, freeFormulaData } = this.props;

    // update freeFormulaData prop
    const updatedFreeFormulaData = { ...freeFormulaData, value };

    handleSetCustomValuesState({ freeFormulaData: updatedFreeFormulaData });
  };

  /**
   * It helps to store the last lengthToCursor and divCounter
   * @returns {void}
   */
  handleOnClick = () => {
    const currentCursorPosition = this.getCurrentCursorPosition();
    const { lengthToCursor, divCounter } = currentCursorPosition;

    this.setState({ lastLengthToCursor: lengthToCursor, lastDivCounter: divCounter });
  };

  render() {
    const { isPasteTriggered, isNewLine } = this.state;

    const {
      freeFormulaData: { value: text, fieldType },
      selectedDataExtensions,
      isSyntaxValid,
      syntaxError,
      handleCheckSyntax,
      isValidating,
      disabled,
    } = this.props;

    return (
    <div className="free-formula-modal">
      <div className="free-formula-modal__title">
        <span>{Constants.CUSTOM_VALUES__HEADER__FREE_FORMULA}</span>
      </div>
      <div className="free-formula-modal__description">
        {Constants.CUSTOM_VALUES__TEXT_FREE_FORMULA}
      </div>
      <div className="free-formula-modal-content">
        <div className="slds-select_container insert-components">
          <InsertFunction
            functions={freeFormulaItems.functions}
            handleOnChange={this.handleOnChange}
            disabled={disabled}
          />
          <InsertField
            selectedDataExtensions={selectedDataExtensions}
            handleOnChange={this.handleOnChange}
            disabled={disabled}
          />
        </div>
        <div className="floated-left">
          <div className="container field-type">
            <div className="item-label"><b>Field Type:</b></div>
            <Select
              id="select-field-type"
              name="fieldType"
              onChange={this.handleOnChangeFieldType}
              value={fieldType}
              containerClassName="custom-values-select"
              options={DropdownOptions.FIELD_TYPE__OPTIONS}
              disabled={disabled}
            />
          </div>
          <div className="slds-form-element__control">
            <div
              id={Constants.FREE_FORMULA__TEXTAREA_INPUT__ID}
              disabled={disabled}
              contentEditable="true"
              name="value"
              className="slds-textarea formula-container"
              type={Constants.FREE_FORMULA__TEXTAREA_INPUT__ID}
              value={text}
              onClick={() => this.handleOnClick()}
              onInput={(e) => {
                this.handleOnChange(e);
              }}
              onPaste={e => this.handleOnPaste(e)}
              onKeyUp={(e) => {
                if (this.isFirefox) {
                  if (window.getSelection().rangeCount > 0) {
                    const currentRange = window.getSelection().getRangeAt(0);

                    // get startContainer of range (current node in which is the current cursor located)
                    const container = currentRange.endContainer;

                    /*
                     * if the current node name is 'BR' means that the cursor is in a new line (empty new line)
                     * the issue was that a new line is represented as the following:
                     *  **<div>
                     *   </ br>
                     * <div>**
                     * That was causing issue when a user tries to type something (onInput wasn't being triggered)
                     * So now, we check if the current position is the BR element
                     * If so, set the 'isNewLine' state to true
                     */
                    if (container.nodeName === 'BR' && e.key.length !== 1) {
                      this.setState({ isNewLine: true });
                    } else {
                      this.setState({ isNewLine: false });
                    }

                    /*
                     * if the new line is added into textarea html and a letter is pressed (e.key.length === 1) and
                     * the copy paste action is not being triggered, then call the handleOnPaste function to insert the
                     * pressed letter into textarea
                     */
                    if (isNewLine && e.key.length === 1 && !isPasteTriggered) {
                      this.handleOnPaste(e, e.key);
                    }

                    /*
                     * in case that copy paste action is triggered then set isPasteTriggered to false because
                     * this function (onKeyUp) will be triggered after the function onPaste
                     */
                    this.setState({ isPasteTriggered: false });
                  }
                }
              }}
            />

            {(isSyntaxValid !== null && !isSyntaxValid && !isValidating) &&
                (
                  <div
                    className="slds-form-element__helps"
                    id="invalid-syntax-text"
                  >
                    {syntaxError}
                  </div>
                )}
            {(isSyntaxValid !== null && isSyntaxValid && !isValidating) &&
                (
                  <div
                    className="slds-form-element__helps"
                    id="valid-syntax-text"
                  >
                    Validation successful.
                  </div>
                )}
            {(isSyntaxValid === null || isValidating) && (
              <div
                className="slds-form-element__helps"
                id="syntax-empty-field"
              />
            )}
          </div>

          <Button
            buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
            className="check-syntax-btn"
            onClick={handleCheckSyntax}
            disabled={isValidating || !text || !text.trim() || disabled}
            loadingClickedButton={isValidating}
            titleInAction="Checking syntax..."
          >
            Check syntax
          </Button>
        </div>
      </div>
    </div>
    );
  }
}

FreeFormula.propTypes = {
  /**
   * It keeps information about Free Formula data
   */
  freeFormulaData: PropTypes.instanceOf(Object).isRequired,
  /**
   * Function to set the state of the CustomValues component
   */
  handleSetCustomValuesState: PropTypes.func.isRequired,
  /**
   * It keeps the selected data extensions for Selection.js
   * selected data extensions are stored as collections in database
   * It will be passed from Selection.js
   */
  selectedDataExtensions: PropTypes.instanceOf(Array),
  /**
   * It keeps information whether syntax in Free Formula is valid or not
   */
  isSyntaxValid: PropTypes.bool,
  /**
   * The error message shown when syntax is invalid
   */
  syntaxError: PropTypes.string,
  /*
   * Function to check if the function syntax is correct or not
   */
  handleCheckSyntax: PropTypes.func.isRequired,
  /**
   * It keeps information whether syntax validation is running or not
   */
  isValidating: PropTypes.bool.isRequired,
  /**
   * Determines whether all input fields should be editable or not by the user
   */
  disabled: PropTypes.bool,
};

export default FreeFormula;
