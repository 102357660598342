import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Constants from '../../../constants/constants';
import Button from '../Button/Button';
import Spinner from '../Spinner/Spinner';
import './styles.scss';

const LoadingModal = ({
  closeModal,
  loadingText,
  hide = false,
  buttonId,
  id,
  bgNone,
  hideCloseButton,
  children,
}) => {
  // class name for the component
  const loadingModalClassName = classNames(
    'loading-modal-container',
    { 'without-background-color': bgNone },
    hide ? 'hide' : '',
  );

  const footerClassName = classNames(
    'lmo-footer',
    { 'with-children-only': !loadingText },
  );

  return (
    <div id={id} className={loadingModalClassName}>
      <div className="lmo">
        <div className="lmo_wrapper">
          {hideCloseButton ?
            null :
            (
            <Button
              className="closeModal"
              noButtonClass
              noSpan
              id={buttonId}
              onClick={closeModal}
            >
              ×
            </Button>
            )}
          {/* spinner */}
          <div className="demo-only demo-only demo-only_viewport" style={{ height: '8rem' }}>
            <Spinner
              size={Constants.SPINNER__SIZE__LARGE}
              className="slds-spinner_brand"
              assistiveText="Loading"
            />
          </div>
          <div className={footerClassName}>
            {loadingText}
            {!loadingText && !children && 'Loading...'}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

LoadingModal.propTypes = {
  /**
   * function to close the modal
   */
  closeModal: PropTypes.func,
  /**
   * text displayed in the modal
   */
  loadingText: PropTypes.string,
  /**
   * informs that the modal should not be displayed
   */
  hide: PropTypes.bool,
  /**
   * id for the close modal button
   */
  buttonId: PropTypes.string,
  /**
   * id for the modal
   */
  id: PropTypes.string,
  /*
   * boolean responsible for making background lighter/darker
   */
  bgNone: PropTypes.bool,
  /*
   * boolean responsible for hiding the close button in the modal
   */
  hideCloseButton: PropTypes.bool,
  /*
   * everything that is used to display
   * it depends on what is included between the opening and closing tags when invoking a component
   */
  children: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array]),
};

export default LoadingModal;
