import Constants from '../../constants/constants';

const waterfallSelectionUtil = {
  /**
   * Returns reduced array with selected data extension Object IDs used in selection
   * @param {array} array - array to reduce
   * @returns {array} reduced array with object IDs
   */
  getSourceDEsUsedInSelection: array => (
    array.reduce((objectIDs, union) => {
      // get DE from collections
      if (union?.collections?.length) {
        // loop through each collection
        union.collections.forEach((collection) => {
          if (collection?.collectionObjectID) {
            // add ObjectID of selected DE
            objectIDs.push(collection.collectionObjectID);
          }
        });
      }

      // get DE from aggregation custom value
      if (union?.customValues?.length) {
        // loop through each collection
        union.customValues.forEach((customValue) => {
          if (customValue?.aggregationValue?.relations?.fromCollectionObjectId) {
            // add objectID of DE used in aggregation custom value
            objectIDs.push(customValue.aggregationValue.relations.fromCollectionObjectId);
          }
        });
      }

      // get DE from filters
      let subQueryDEObjectID;

      // define array with filters
      const filters = union?.filters?.length ?
        union.filters[0].filters :
        array;

      if (filters?.length) {
        filters.forEach((filter) => {
          // if filters are nested, execute function again
          if (filter?.filters) {
            subQueryDEObjectID = waterfallSelectionUtil.getSourceDEsUsedInSelection(
              filter.filters,
            );
          } else {
            // get collectionObjectID from DE used in subQuery filter
            subQueryDEObjectID = filter?.subQuery?.collections[0]?.collectionObjectID;
          }

          if (subQueryDEObjectID) {
            // add Object ID of DE used in subQuery filter
            objectIDs.push(subQueryDEObjectID);
          }
        });
      }

      return objectIDs;
    }, [])
  ),

  /**
   * Returns true / false depending on whether waterfall selection is running
   * @param {number} waterfallSelectionStatus - status number for waterfall selection
   * @param {boolean} runningQuery - determines whether the Run button is clicked and all query activity begins
   * @returns {boolean} overall run status for waterfall selection
   */
  isWaterfallSelectionRunning: (waterfallSelectionStatus, runningQuery) => (
    (
      waterfallSelectionStatus &&
      waterfallSelectionStatus !== Constants.STATUS_COMPLETE &&
      waterfallSelectionStatus !== Constants.STATUS_ERROR &&
      waterfallSelectionStatus !== Constants.STATUS_RETRY
    ) || runningQuery
  ),

  /**
   * Returns array with target data extension customer keys to remove
   * @param {array} selections - array with selections to reduce
   * @param {array} selectedSelections - array with selected selections
   * @param {array} runStatusForSelectionChain - array with selections in run chain
   * @returns {array} reduced array with customer keys of targetDE
   */
  targetDataExtensionsToRemoved: (selections, selectedSelections, runStatusForSelectionChain) => (
    selections.reduce((array, removedSelection) => {
    // check if targetDE is used in other selections
      const selectionsUsingTheSameTargetDE = selectedSelections.find(
        selection => selection.targetCollectionObjectID ===
          removedSelection.targetCollectionObjectID &&
          (selection?.selectionId ?
            selection.selectionId !== removedSelection.selectionId :
            selection._id !== removedSelection._id),
      );

      // check if targetDE for selection is used in run details
      const selectionInRunDetails = runStatusForSelectionChain ?
        runStatusForSelectionChain.find(
          selection => selection.targetCollectionObjectID ===
       removedSelection.targetCollectionObjectID,
        ) :
        {};

      if (!selectionsUsingTheSameTargetDE && !selectionInRunDetails) {
      // if there are no other selections, return Object ID of this targetDE
        return [...array, removedSelection.targetCollectionObjectID];
      }

      return array;
    }, [])),

  /**
   * Returns selection data based on array with fetched selections
   * @param {array} arrayWithSelectionIds - array with selectionsIds to get selection object
   * @param {array} fetchedSelections - array with fetched selections
   * @returns {array} array with selection objects
   */
  getSelectionsData: (arrayWithSelectionIds, fetchedSelections) => {
    const selections = [];

    if (arrayWithSelectionIds?.length) {
      // loop through array with selection id
      arrayWithSelectionIds.forEach((object) => {
        // get selection object from all selections
        const selectionObject = fetchedSelections.find((
          selection => (selection._id === object.selectionId ||
            selection.selectionId === object.selectionId)));

        if (selectionObject) {
          // push selection object into an array
          selections.push(selectionObject);
        }
      });
    }

    return selections;
  },

  /**
   * This function creates a copy of the dragged selection
   * @param {array} draggedItems - an array with items to drag
   * @param {array} droppedItems - an array with dropped items
   * @param {object} droppableSource - source from which dragging begins
   * @param {object} droppableDestination - the direction to which the element is dragged
   * @returns {array} new array with dropped items
   */
  copy: (draggedItems, droppedItems, droppableSource, droppableDestination) => {
    // get dragged item
    const item = draggedItems[droppableSource.index];

    // add this item into the array with dropped items
    droppedItems.splice(droppableDestination.index, 0, {
      ...item,
      _id: item._id + Date.now(),
      selectionId: item._id,
    });

    // return new array with dropped items
    return droppedItems;
  },

  /**
   * This function changes the order of the elements in an array
   * @param {array} array - an array to be reordered
   * @param {number} startIndex - the starting index of the item
   * @param {number} endIndex - the end index of the item
   * @returns {array} new array with changed order of elements
   */
  reorder: (array, startIndex, endIndex) => {
    const result = [...array];

    /*
     * reorganize array - remove an element from the starting index,
     * add an element in place of the ending index
     */
    const [removed] = result.splice(startIndex, 1);

    result.splice(endIndex, 0, removed);

    return result;
  },
};

export default waterfallSelectionUtil;
