import React from 'react';
import { SLDSMenuDropdown as Dropdown } from '@salesforce/design-system-react';
import PropTypes from 'prop-types';

import './styles.scss';

const transformGroupedOptions = (groupedOptions) => {
  const transformedOptions = [];

  Object.keys(groupedOptions).forEach((groupLabel) => {
    transformedOptions.push({ label: groupLabel, type: 'header' });
    const groupOptions = groupedOptions[groupLabel];

    groupOptions.forEach((option) => {
      transformedOptions.push({ label: option.label, value: option.value });
    });
  });

  return transformedOptions;
};

const Select = ({
  id,
  className,
  onChange,
  value,
  options,
  noOptionsLabel,
  groupedOptions,
  containerClassName,
  disabled,
  defaultValue,
  label,
  multiple,
  iconCategory,
  iconName,
  iconPosition,
  ...rest
}) => {
  // Handle grouped options
  const finalOptions = groupedOptions ?
    transformGroupedOptions(groupedOptions) :
    options;

  // If there are no options, show the noOptionsLabel
  if (finalOptions.length === 0) {
    finalOptions.push({
      label: noOptionsLabel || 'No options available',
      value: '',
      disabled: true,
    });
  }

  const handleSelect = (selectedValue) => {
    // Mimic the event object structure for compatibility with existing onChange handlers
    if (onChange) {
      onChange({
        target: {
          value: selectedValue,
          id,
        },
      });
    }
  };

  return (
    <div className="des-select">
      <Dropdown
        {...rest}
        id={id}
        className={className}
        onSelect={handleSelect}
        options={finalOptions}
        value={value || defaultValue}
        disabled={disabled}
        tabIndex={disabled ? '-1' : '0'}
        iconCategory="utility"
        iconName="chevrondown"
        iconPosition="right"
        label={label}
      />
    </div>
  );
};

// For slds Dropdown props, see: https://react.lightningdesignsystem.com/components/menu-dropdowns/#property-details
Select.propTypes = {
  /**
   * Aligns the menu center, right, or left respective to the trigger.
   */
  align: PropTypes.oneOf(['center', 'left', 'right']),
  /**
   * CSS classes to be added to triggering button.
   */
  buttonClassName: PropTypes.string,
  /**
   * If true, button/icon is white. Meant for buttons or utility icons on dark backgrounds.
   */
  buttonInverse: PropTypes.bool,
  /**
   * Determines variant of the Button component that triggers dropdown.
   */
  buttonVariant: PropTypes.oneOf(['base', 'neutral', 'brand', 'destructive', 'icon']),
  /**
   * If true, renders checkmark icon on the selected Menu Item.
   */
  checkmark: PropTypes.bool,
  /**
   * CSS classes to be added to dropdown menu.
   */
  className: PropTypes.string,
  /**
   * By default, these class names will be added to the absolutely-positioned Dialog component.
   */
  containerClassName: PropTypes.string,
  /**
   * A default value for the Select input.
   */
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  /**
   * Prevent dropdown menu from opening. Also applies disabled styling to trigger button.
   */
  disabled: PropTypes.bool,
  /**
   * Object with grouped options.
   */
  groupedOptions: PropTypes.object,
  /**
   * Name of the icon category for the dropdown icon.
   */
  iconCategory: PropTypes.string,
  /**
   * Name of the dropdown icon.
   */
  iconName: PropTypes.string,
  /**
   * Determines the size of the icon.
   */
  iconSize: PropTypes.oneOf(['x-small', 'small', 'medium', 'large']),
  /**
   * If omitted, icon position is centered. Determines the position of the dropdown icon.
   */
  iconPosition: PropTypes.oneOf(['left', 'right']),
  /**
   * Unique ID for the input element.
   */
  id: PropTypes.string.isRequired,
  /**
   * Adds inverse class to the dropdown.
   */
  inverse: PropTypes.bool,
  /**
   * Label for the dropdown.
   */
  label: PropTypes.string,
  /**
   * Label to show when there are no options.
   */
  noOptionsLabel: PropTypes.string,
  /**
   * Called when the triggering button loses focus.
   */
  onBlur: PropTypes.func,
  /**
   * Callback function triggered when an option is changed.
   */
  onChange: PropTypes.func,
  /**
   * Called when the triggering button is clicked.
   */
  onClick: PropTypes.func,
  /**
   * Called when the triggering button gains focus.
   */
  onFocus: PropTypes.func,
  /**
   * Called when a key is pressed.
   */
  onKeyDown: PropTypes.func,
  /**
   * Called when mouse clicks down on the trigger button.
   */
  onMouseDown: PropTypes.func,
  /**
   * Called when mouse hovers over the trigger button.
   */
  onMouseEnter: PropTypes.func,
  /**
   * Called when mouse hover leaves the trigger button.
   */
  onMouseLeave: PropTypes.func,
  /**
   * Triggered when an item in the menu is clicked.
   */
  onSelect: PropTypes.func.isRequired,
  /**
   * Triggered when the dropdown is opened.
   */
  onOpen: PropTypes.func,
  /**
   * Triggered when the dropdown is closed.
   */
  onClose: PropTypes.func,
  /**
   * An array of menu item objects.
   */
  options: PropTypes.array.isRequired,
  /**
   * Style object for the dropdown menu.
   */
  style: PropTypes.object,
  /**
   * Write "-1" if you don't want the user to tab to the button.
   */
  tabIndex: PropTypes.string,
  /**
   * Current selected menu item value.
   */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  /**
   * Whether this dropdown supports multi select.
   */
  multiple: PropTypes.bool,
  /**
   * To adjust the width of the menu dropdown.
   */
  width: PropTypes.oneOf(['xx-small', 'x-small', 'small', 'medium', 'large']),
};

Select.defaultProps = {
  checkmark: true,
  iconCategory: 'utility',
  iconName: 'chevrondown',
  iconPosition: 'right',
  multiple: false,
};

export default Select;
