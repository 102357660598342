import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { connect } from 'react-redux';

import mapStateToProps from '../../../../mapStateToProps';
import Util from '../../../../util';
import Button from '../../../shared/Button/Button';
import Constants from '../../../../constants/constants';
import Input from '../../../shared/Input/Input';

const UserPanel = ({
  user,
  closePanel, handleCheckboxChange, handleUserPanelSave, userInfo,
}) => (
  // if there is no user data the modal will not be shown
  <div className={`userpanel-container ${user ? 'active' : ''}`}>
    {
      user ?
        (
          <>
            <div className="userpanel title">
              Edit User
            </div>
            <div className="userpanel-content">
              <span
                className="closeModal"
                onClick={closePanel}
              >
                ×
              </span>
              <div className="userpanel headers">
                <div className="titlePanel bold" title="Full Name">
                  {user.name}
                </div>
                <div className="titlePanel">
                  {user.username}
                </div>
              </div>
              <div className="userpanel editContent">
                <div className="userpanel row">
                  <div className="titlePanel bold" title="Administrator">
                    Administrator
                  </div>
                  <div className="checkbox">
                    <Input
                      type="checkbox"
                      noInputClassName
                      name="admin checkbox-onoff"
                      className="isAdmin checkbox"
                      value={user.isAdmin}
                      checked={user.isAdmin}
                      onChange={() => handleCheckboxChange(user._id, 'isAdmin')}
                      disabled={user._id === Util.loggedInUserId(userInfo)}
                    />
                  </div>
                </div>

                <div className="userpanel row">
                  <div className="titlePanel bold" title="Active">
                    Active
                  </div>
                  <div className="checkbox">
                    <Input
                      noInputClassName
                      type="checkbox"
                      name="active checkbox-onoff"
                      className="isActive checkbox"
                      value={user.isActive}
                      checked={user.isActive}
                      onChange={() => handleCheckboxChange(user._id, 'isActive')}
                      disabled={user._id === Util.loggedInUserId(userInfo)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <footer className="footer user-footer" id="action-footer">
              <Button
                buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
                id="action-cancel"
                onClick={closePanel}
              >
                Cancel
              </Button>
              <Button
                buttonLook={Constants.BUTTON__TYPE__BRAND}
                id="action-save"
                onClick={() => handleUserPanelSave(user)}
                disabled={user._id === Util.loggedInUserId(userInfo)}
              >
                Save
              </Button>
            </footer>
          </>
        ) :
        null
    }
  </div>

);

UserPanel.propTypes = {
  /**
   * The object with user data
   */
  user: PropTypes.instanceOf(Object),
  /**
   * Closes the UserPanel
   */
  closePanel: PropTypes.func.isRequired,
  /**
   * Changes data in the user object after clicking on the checkbox
   */
  handleCheckboxChange: PropTypes.func.isRequired,
  /**
   * Saves the list of users after clicking on the save button
   */
  handleUserPanelSave: PropTypes.func.isRequired,
  /**
   * User info from cookie
   */
  userInfo: PropTypes.object,
};

export default connect(mapStateToProps(['userInfo']), null, null, { pure: false })(UserPanel);
