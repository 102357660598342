import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Filters from '../../../Selection/Filters/Filters';
import './styles.scss';
import ModalTemplate from '../../../shared/ModalTemplate/ModalTemplate';
import filtersUtil from '../../../../utils/filters/filtersUtil';
import SwalUtil from '../../../../utils/swal/swalUtil';
import Constants from '../../../../constants/constants';

const EditFiltersModal = ({
  dataExtensions,
  selectedDataExtensions,
  selectedFilters,
  handleFiltersSave,
  getDataExtensionOrDataViewFields,
  handleFeatureMissing,
  handlePickListOptions = () => {},
  pickLists,
  returnPredefinedRelationById,
  predefinedRelations,
  handleCloseEditFiltersModal,
}) => {
  const copyOfSelectedFilters = JSON.parse(JSON.stringify(selectedFilters || {}));

  const [selectedFiltersInModal, setSelectedFiltersInModal] = useState(copyOfSelectedFilters);

  const [mouseBorder, setMouseBorder] = useState({ DEBorderMouseOver: false, filterBorderMouseOver: false });

  /**
   * Cancel changes in modal and close it
   * @returns {void}
   */
  const cancel = () => {
    handleCloseEditFiltersModal();
  };

  /**
   * Handle click on save button
   * @returns {void}
   */
  const save = () => {
    if (!(selectedFiltersInModal?.filters?.length >= 1)) {
      // show swal error if required filters are not given
      return SwalUtil.fire({
        type: Constants.SWAL__TYPE__ERROR,
        title: 'Selected Filters',
        message: 'There should be at least one Selected Filter.',
        options: {
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonText: 'OK',
          showCloseButton: false,
          allowOutsideClick: false,
        },
      });
    }
    handleFiltersSave(selectedFiltersInModal);
    handleCloseEditFiltersModal();
  };

  /**
   *
   * @param {Object} properties - Properties about the filterline to remove
   * @returns {Object} returns the updated filters
   */
  const handleRemoveFilterLine = (properties) => {
    const updatedFilters = filtersUtil.removeFilterLine(selectedFiltersInModal, properties);

    setSelectedFiltersInModal(updatedFilters);

    return updatedFilters;
  };

  return (
    <div>
      <ModalTemplate
        id="edit-filters-modal-dialog"
        className="edit-filters-modal slds-modal_large"
        headerId="modal-heading-01"
        headerTitle="Edit Selected Filters"
        contentClassName="slds-p-around_medium edit-filters-dialog"
        contentId="modal-content-id-1"
        cancelButtonId="edit-filters-dialog-cancel"
        handleCancel={cancel}
        handleSave={save}
        saveButtonId="edit-filters-dialog-save"
      >
        <Filters
          hideCollectionAlias={false}
          showInResultsOption
          dataExtensions={dataExtensions}
          selectedDataExtensions={selectedDataExtensions}
          handleSetSelectionState={data => setMouseBorder(data)}
          selectedFilters={selectedFiltersInModal || {}}
          handleFiltersSave={filters => setSelectedFiltersInModal(filters)}
          getDataExtensionOrDataViewFields={getDataExtensionOrDataViewFields}
          handleFeatureMissing={handleFeatureMissing}
          DEBorderMouseOver={mouseBorder?.DEBorderMouseOver}
          filterBorderMouseOver={mouseBorder?.filterBorderMouseOver}
          handlePickListOptions={handlePickListOptions}
          handleRemoveFilterLine={handleRemoveFilterLine}
          inSelectionCriteria
          pickLists={pickLists}
          returnPredefinedRelationById={returnPredefinedRelationById}
          predefinedRelations={predefinedRelations}
          isRegularFilter={false}
        />
      </ModalTemplate>
    </div>
  );
};

EditFiltersModal.propTypes = {

  /**
   * It keeps the data extensions after they are retrieved from SFMC
   * if dataViews feature is enabled, it will also contain dataViews as well
   */
  dataExtensions: PropTypes.instanceOf(Array),
  /**
   * It keeps the selected data extensions for Selection.js
   * selected data extensions are stored as collections in database
   * It will be passed from Selection.js
   */
  selectedDataExtensions: PropTypes.instanceOf(Array),
  /**
   * It helps to save the selected filters for the selection
   * it is passed from Selection.js/SubqueryModal.js
   */
  handleFiltersSave: PropTypes.func.isRequired,
  /**
   * It keeps the selected filters for a Selection
   * selectedFilters are stored as filters in database
   * It will be passed from Selection.js
   */
  selectedFilters: PropTypes.instanceOf(Object),
  /**
   * It helps to retrieve fields of a data extension or data view from SFMC
   * It will be passed from Selection.js
   */
  getDataExtensionOrDataViewFields: PropTypes.func.isRequired,
  /**
   * It helps to throw a warning message if a feature is disabled and used in the Selection
   * It will be passed from Selection.js
   */
  handleFeatureMissing: PropTypes.func,
  /**
   * Responsible for adding/deleting fields Object IDs when searching picklist for the options
   * it will be passed from Selection.js
   */
  handlePickListOptions: PropTypes.func,
  /**
   * Keeps searched picklist
   * It will be passed from Selection.js
   */
  pickLists: PropTypes.instanceOf(Array).isRequired,
  /**
   * An array containing relations that were defined for Data Extensions
   */
  predefinedRelations: PropTypes.instanceOf(Array),
  /**
   * It returns predefined relation object for relation filter
   * it is passed from Selection.js
   */
  returnPredefinedRelationById: PropTypes.func,
  /**
   * Handle close edit filters modal
   */
  handleCloseEditFiltersModal: PropTypes.func,
};

export default EditFiltersModal;
