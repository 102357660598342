import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import Util from '../../../../../../util';
import Constants from '../../../../../../constants/constants';
import Select from '../../../../../shared/Select/Select';

/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
const CriteriaField = ({
  extensionFields,
  handleChangeCriteriaField,
  criteriaFieldObjectID,
  deduplicationFieldName,
}) => (
  <div className="slds-form-element exten-field">
    <label className="slds-form-element__label">
      Field that determines priority
    </label>
    <div className="slds-form-element__control">
      <Select
        onChange={(e) => {
          handleChangeCriteriaField(e);
        }}
        value={criteriaFieldObjectID || ''}
        required
        options={[{ value: Constants.PRIO_DEDUP__OPTION__PLEASE_SELECT, label: 'Please select' },
          ...extensionFields.map(field => (
            {
              value: field.ObjectID,
              disabled: deduplicationFieldName === field.Name.toString() && 'none',
              label: Util.abbreviate(field.Name.toString(), 30),
              title: (field.Name.toString() && field.Name.toString().length > 30) ? field.Name.toString() : '',
              style: {
                backgroundColor:
                  deduplicationFieldName === field.Name.toString() && '#ccc9c9',
              },
            }
          )),
        ]}
      />
    </div>
  </div>
);

CriteriaField.propTypes = {
  /**
   * It helps to change selected field for deduplication
   */
  handleChangeCriteriaField: PropTypes.func.isRequired,
  /**
   * It keeps the selected field's ObjectID
   */
  criteriaFieldObjectID: PropTypes.string,
  /**
   * It keeps the formatted target data extension' fields for combobox
   */
  extensionFields: PropTypes.instanceOf(Object),
  /**
   * It keeps the deduplication field's name
   *
   */
  deduplicationFieldName: PropTypes.string.isRequired,
};

export default CriteriaField;
