import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Player } from 'video-react';
import { isEmpty } from 'lodash';

import ModalTemplate from '../ModalTemplate/ModalTemplate';

import './styles.scss';

function FeatureModal({
  handleCancel,
  feature,
}) {
  // get state of properties from reducers
  const {
    featureAdverts,
  } =
    useSelector(({
      featureAdvertsReducer,
    }) => ({
      // From feature adverts reducer
      featureAdverts: featureAdvertsReducer.featureAdverts,
    }), shallowEqual);

  const advert = featureAdverts?.find(featureAdvert => featureAdvert.feature === feature);

  return (

    !isEmpty(advert) && advert?.status && (
      <ModalTemplate
        id="features-modal"
        headerId="features-modal-header"
        modalParentClassName="feature-modal-parent"
        headerTitleHTML={`
      <span>
        <img
          class="feature-premium-icon"
          src="/assets/icons/Deedee_Certificate_Banner.png"
          alt="Premium icon" />${advert?.title} </span>`}
        handleCancel={handleCancel}
        contentClassName="slds-p-around_medium upgrade-feature-body"
        cancelButtonTitle="OK"
      >
        {
          advert?.mediaType !== '' && (
            <div className="feature-media">
              {
                advert?.mediaType === 'video' && (
                  <div className="feature-video">
                    <Player
                      playsInline
                      autoPlay
                      poster="/assets/feature_image.jpg"
                      src={advert?.media}
                    />
                  </div>
                )
              }
              {
                advert?.mediaType === 'image' && (
                  <div className="feature-image">
                    <img src={advert?.media} alt="Feature advert" />
                  </div>
                )
              }
            </div>
          )
        }

        <div className="feature-list">
          <div className="feature-description" dangerouslySetInnerHTML={{ __html: advert?.description }} />
        </div>

        <div className="feature-footer">
          <p>
            Please
            {' '}
            <a
              className="link"
              href="https://deselect.com/contact/"
              target="_blank"
              rel="noreferrer">
              contact DESelect

            </a>
            {' '}
            for more information about using this feature.
          </p>
        </div>
      </ModalTemplate>
    )
  );
}

FeatureModal.propTypes = {
  /**
   * Function to handle cancel button click
   */
  handleCancel: PropTypes.func.isRequired,

  /**
   * Feature name
   */
  feature: PropTypes.string.isRequired,
};

export default FeatureModal;
