import React from 'react';
import PropTypes from 'prop-types';

import TimezoneDropdown from '../TimezoneDropdown/TimezoneDropdown';
import Constants from '../../../../../constants/constants';
import './style.scss';
import ToggleButton from '../../../../shared_v2/ToogleButton/ToggleButton';
import RadioButton from '../../../../shared_v2/RadioButton/RadioButton';
import timeUtil from '../../../../../utils/time/timeUtil';
import TimezoneList from '../../../ScheduleSelectionModal/TimezoneList.json';

/**
 * @returns {void}
 */
const TimestampModal = ({
  timestampData,
  handleConvertTimezoneSwitch,
  handleSetCustomValuesState,
  disabled,
}) => {
  const {
    format = Constants.CUSTOM_VALUES__FORMULA_FORMAT__NOW,
    convert = false,
    convertToTimezone = timeUtil.getCurrentUserTimezone(TimezoneList, true),
  } = timestampData;

  /**
   * Event handler for when form elements' values are changed
   * Most inputs' and selections' onChange events are handled using this function
   * @param {object} e - onChange event object
   * @returns {void}
   */
  const handleFormElementChanged = (e) => {
    const { value, name } = e.target;
    const updatedTimestampData = { ...timestampData, [name]: value };

    // Update the timestampData object in CustomValues.js
    handleSetCustomValuesState({ timestampData: updatedTimestampData });
  };

  return (
    <div className="timestamp-modal">
      <div className="custom-values-question">
        <span className="title">{Constants.CUSTOM_VALUES__HEADER__TIMESTAMP}</span>
        <span className="description">{Constants.CUSTOM_VALUES__TEXT_TIMESTAMP}</span>
      </div>
      <div className="custom-values-input">
        {/* Radio buttons */}
        <fieldset className="slds-form-element timestamp-format">
          <legend className="slds-form-element__legend slds-form-element__label">Format:</legend>
          <div className="slds-form-element__control">
            {/* Use constant for value */}
            <RadioButton
              id={Constants.CUSTOM_VALUES__FORMULA_FORMAT__NOW}
              name="format"
              label="Now (date + time)"
              value={Constants.CUSTOM_VALUES__FORMULA_FORMAT__NOW}
              onChange={handleFormElementChanged}
              checked={format === Constants.CUSTOM_VALUES__FORMULA_FORMAT__NOW}
              disabled={disabled}
            />
            <RadioButton
              id={Constants.CUSTOM_VALUES__FORMULA_FORMAT__TODAY}
              name="format"
              label="Today (date)"
              value={Constants.CUSTOM_VALUES__FORMULA_FORMAT__TODAY}
              onChange={handleFormElementChanged}
              checked={format === Constants.CUSTOM_VALUES__FORMULA_FORMAT__TODAY}
              disabled={disabled}
            />
          </div>
        </fieldset>

        {/* Toggle Checkbox */}
        <div className="slds-form-element">
          <ToggleButton
            id="convertTimezoneToggle"
            name="convert"
            value={convert}
            onChange={handleConvertTimezoneSwitch}
            checked={convert}
            label="Convert timezone"
            checkboxOnTitle="Enabled"
            checkboxOffTitle="Disabled"
            labelClassName="fit-content"
            disabled={disabled}
          />
        </div>

        <div className="convert-timezone">
          <p>Convert to timezone:</p>
          <TimezoneDropdown
            disabled={!convert || disabled}
            defaultValue={convertToTimezone}
            handleOnChange={handleFormElementChanged}
            name="convertToTimezone"
            timezoneClassName="convert-to-timezone-timestamp"
          />
        </div>
      </div>
    </div>
  );
};

TimestampModal.propTypes = {
  /**
   * An object containing all props for the component
   */
  timestampData: PropTypes.shape({
    /**
     * Determines which radio button should be checked
     */
    format: PropTypes.string.isRequired,
    /**
     * Determines if the checkbox is checked or not
     */
    convert: PropTypes.bool.isRequired,
    /**
     * Determines that convert to timezone is selected
     */
    convertToTimezone: PropTypes.string.isRequired,
  }).isRequired,
  /**
   * A function to handle the click on the checkbox to enable / disable the timezone select
   */
  handleConvertTimezoneSwitch: PropTypes.func.isRequired,
  /**
   * Function to set the state of the CustomValues component
   */
  handleSetCustomValuesState: PropTypes.func.isRequired,
  /**
   * disable property for the component
   */
  disabled: PropTypes.bool,
};

export default TimestampModal;
