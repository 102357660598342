import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import Spinner from '../Spinner/Spinner';
import Constants from '../../../constants/constants';
import './styles.scss';

const Button = ({
  disabled,
  id,
  onClick,
  buttonLook,
  loadingClickedButton,
  children,
  titleInAction,
  className,
  title,
  labelId,
  noButtonClass,
  buttonIcon,
  buttonIconBorderFilled,
  onKeyPress,
  noSpan,
  ariaDescribedBy,
  ariaDisabled,
  submitButton,
  xlinkHref,
}) => {
  /**
   * Returns class name depends of defined button look and additional className
   * @param {string} btnLook - the button appearance
   * @param {string} ownClassName - additional class name passed as a prop
   * @returns {string} class name
   */
  const classNamesForButton = (btnLook, ownClassName) => classNames(
    ownClassName,
    {
      'slds-button': !noButtonClass,
      'slds-button_icon': buttonIcon,
      'slds-button_icon-border-filled': buttonIconBorderFilled,
    },
    btnLook && `slds-button_${btnLook}`,
  );

  return (
    <button
      disabled={disabled || loadingClickedButton}
      id={id}
      className={classNamesForButton(buttonLook, className)}
      onClick={onClick}
      type={submitButton ? 'submit' : 'button'}
      onKeyPress={onKeyPress}
      aria-describedby={ariaDescribedBy}
      aria-disabled={ariaDisabled}
      title={title}
    >
      {loadingClickedButton ?
        (
          <div className="preview-loader-container">
            <Spinner
              size={Constants.SPINNER__SIZE__X_SMALL}
              loadingText={titleInAction}
              loadingTextClassName="when-pressed"
            />
          </div>
        ) :
        (
          noSpan ?
            children :
            (
              <span className="button-children" id={labelId}>
                {children}
              </span>
            )
        )}
      {xlinkHref && (
        <svg
          className="slds-button__icon slds-icon_small trash"
          aria-hidden="true"
        >
          <use xlinkHref={xlinkHref} />
        </svg>
      )}
    </button>
  );
};

Button.propTypes = {
  /**
   * defines whether the button is disabled
   */
  disabled: PropTypes.bool,
  /**
   * id of the button
   */
  id: PropTypes.string,
  /**
   * Handles click on the button
   */
  onClick: PropTypes.func,
  /**
   * defines what the button should look like
   */
  buttonLook: PropTypes.string,
  /**
   * defines whether the loading text should appear when the button is clicked
   */
  loadingClickedButton: PropTypes.bool,
  /*
   * everything that is used to display
   * it depends of what is include between the opening and closing tags when invoking a component
   */
  children: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array]),
  /**
   * title after pressing the button
   */
  titleInAction: PropTypes.string,
  /**
   * additional class name for the component
   */
  className: PropTypes.string,
  /**
   * title shown when hovering over a component
   */
  title: PropTypes.string,
  /**
   * Id for the button label
   */
  labelId: PropTypes.string,
  /**
   * indicates that the component is not used with a slds-button class
   */
  noButtonClass: PropTypes.bool,
  /**
   * indicates that the button is using an icon class
   */
  buttonIcon: PropTypes.bool,
  /**
   * indicates that the button uses the icon class with a filled border
   */
  buttonIconBorderFilled: PropTypes.bool,
  /**
   * function that handles pressing a key (on the keyboard)
   */
  onKeyPress: PropTypes.func,
  /**
   * indicates that items inside the button are not children of the span element
   */
  noSpan: PropTypes.bool,
  /**
   * aria-describedby property for the button element
   */
  ariaDescribedBy: PropTypes.string,
  /**
   * aria-disabled property for the button element
   */
  ariaDisabled: PropTypes.string,
  /**
   * indicates that the button is of the submit type
   */
  submitButton: PropTypes.bool,
  /**
   * link for the location of the svg icon
   */
  xlinkHref: PropTypes.string,

};

export default Button;
