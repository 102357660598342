import {
  SET_USER_INFORMATION,
} from '../types';

/**
 * Function that helps to set user information
 * @param {Object} userInformation - value for the user information
 * @returns {void}
 *
 */
export const setUserInformation = userInformation => (dispatch) => {
  dispatch({
    type: SET_USER_INFORMATION,
    payload: userInformation,
  });
};
