/* eslint-disable spellcheck/spell-checker */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Button from '../Button/Button';
import './styles.scss';
import Util from '../../../util';

const ProgressBar = ({
  progressItems,
  progressBarClassName,
  progressBarValueClassName,
  containerClassName,
  values,
  containerId,
  progressBarValueStyle,
  numberOfResults,
  previewTaskCompleted,
  previewDedupNumberOfRecords,
  uselocale,
  usePrioDeduplication,
  link,
}) => {
  const buttonClassName = className => classNames(
    className || 'slds-progress__marker slds-progress__marker_icon',
  );
  /**
   * Gets the values for the selection
   * @returns - the value of selections
   */

  const getProcessingNumberOfRecords = () => {
    let res = '';

    if (previewDedupNumberOfRecords === null && numberOfResults !== null) {
      previewDedupNumberOfRecords = numberOfResults;
    }

    if (previewDedupNumberOfRecords === null && numberOfResults === null) {
      previewDedupNumberOfRecords = 0;
      numberOfResults = 0;
    }

    if (usePrioDeduplication) {
      if ((previewDedupNumberOfRecords !== null || previewDedupNumberOfRecords !== undefined) && previewTaskCompleted) {
        res = Util.formatNumber(previewDedupNumberOfRecords, 0, uselocale);
      } else {
        res = (
          <span>
            (
            <i className="fas fa-spinner fa-spin" />
            &nbsp;Calculating)
          </span>);
      }
    } else {
      if (numberOfResults !== null &&
        (previewDedupNumberOfRecords === null || previewDedupNumberOfRecords === undefined) && previewTaskCompleted) {
        res = Util.formatNumber(numberOfResults, 0, uselocale);
      } else {
        res = Util.formatNumber(previewDedupNumberOfRecords, 0, uselocale);
      }
    }

    if (res === 'NaN') {
      res = 'unknown';
    }

    return res;
  };

  return (
    <div className={`slds-progress ${containerClassName || ''}`} id={containerId || ''}>
      <ol className="slds-progress__list">
        {progressItems.map(item => (
          item?.condition && (
            <li className={`slds-progress__item ${item.className || ''}`} key={item.name}>
              {item?.tooltip && (
                <div className={item?.tooltipClassName}>
                  <div
                    className="slds-popover slds-popover_tooltip slds-nubbin_bottom-left"
                    role="tooltip"
                    id={item?.tooltipId || ''}
                  >
                    <div className="slds-popover__body">{item?.tooltipLabel}</div>
                  </div>
                </div>
              )}
              <Button
                buttonIcon
                className={buttonClassName(item?.buttonClassName)}
                title={item.name}
                disabled={item.disabled}
                onClick={item.onClick}
              >
                {item.icon ?
                  <>
                    <svg className="slds-button__icon" aria-hidden="true">
                      <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#success" />
                    </svg>
                    <span className="slds-assistive-text">{item.name}</span>
                  </> :
                  <span />}
              </Button>

              {(item?.name === 'Processing' || item?.name === 'Deduplicating') &&
                previewTaskCompleted && item?.tooltip && (
                  <div className={item?.tooltipClassName}>
                    <div
                      className={
                        classNames(
                          'slds-popover',
                          item?.name === 'Processing' ?
                            'slds-popover_tooltip-bottom slds-nubbin_top-right' :
                            'slds-popover_tooltip-bottom_dup slds-nubbin_top-left',
                          {
                            'unknown-number-records':
                              getProcessingNumberOfRecords() === 'unknown' && item?.name === 'Processing',
                          },
                        )
                      }
                      role="tooltip"
                      id={item?.tooltipId || ''}
                    >
                      <div className="slds-popover__body slds-bottomtooltip">
                        {
                          item?.name === 'Deduplicating' ?
                            `Generated
                        ${numberOfResults !== null && previewTaskCompleted ?
                              Util.formatNumber(numberOfResults, 0, uselocale) :
                              (
                                <span>
                                  (
                                  <i className="fas fa-spinner fa-spin" />
                                  &nbsp;Calculating)
                                </span>
                              )} records` :
                            `Generated ${getProcessingNumberOfRecords()} records`
                        }
                      </div>
                      <a
                        className="slds-link-size"
                        href={link(item?.name)}
                        target="__blank">
                        <svg className="slds-icon-new_window">
                          <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#new_window" />
                        </svg>
                      </a>
                    </div>
                  </div>
              )}
            </li>
          )))}
      </ol>
      <div
        className={`slds-progress-bar slds-progress-bar_x-small ${progressBarClassName}`}
        aria-valuemin={values.min}
        aria-valuemax={values.max}
        aria-valuenow={values.now}
        role="progressbar"
      >
        <span
          className={`slds-progress-bar__value ${progressBarValueClassName}`}
          style={progressBarValueStyle}
        />
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  /**
   * An array with objects that are in the next steps of the progress bar
   */
  progressItems: PropTypes.instanceOf(Array).isRequired,
  /**
   * class name for the line of progress bar
   */
  progressBarClassName: PropTypes.string,
  /**
   * class name for the value of progress bar
   */
  progressBarValueClassName: PropTypes.string,
  /**
   * class name for the progress bar component
   */
  containerClassName: PropTypes.string,
  /**
   * id for the progress bar component
   */
  containerId: PropTypes.string,
  /**
   * An object with values for the progress bar
   */
  values: PropTypes.instanceOf(Object).isRequired,
  /**
   * An object with styles for the progress bar value
   */
  progressBarValueStyle: PropTypes.instanceOf(Object),
  /**
   * number of sections in preview screen
   */
  numberOfResults: PropTypes.string,
  /**
   * number of completed tasks
   */
  previewTaskCompleted: PropTypes.string,
  /**
   * number of deduplicated records in preview
   */
  previewDedupNumberOfRecords: PropTypes.string,
  /**
   *  check if selection is dedup or not
   */
  usePrioDeduplication: PropTypes.bool,
  /**
   * locale
   */
  uselocale: PropTypes.any,
  /**
   * Link to deduplicated records
   */
  link: PropTypes.any,
};

export default ProgressBar;
