/* eslint-disable import/prefer-default-export */
import { GET_ERRORS, GET_RUN_ERROR } from '../types';

/**
 * Handler for errors
 * @param {object} error - An Error object
 * @param {string} message - If set, this is the message that will be displayed
 * @returns {void}
 */
export const setErrors = error => async (dispatch) => {
  dispatch({
    type: GET_ERRORS,
    payload: error,
  });
};

/**
 * Handler for error that comes from running the query activity
 * @param {object} runError - an error object that comes from running a selection
 * @returns {void}
 */
export const setRunError = runError => async (dispatch) => {
  dispatch({
    type: GET_RUN_ERROR,
    payload: runError,
  });
};
