import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import classNames from 'classnames';

import ToggleButton from '../../../../shared/ToogleButton/ToggleButton';
import Constants from '../../../../../constants/constants';
import DataExtensionsAPI from '../../../../../api/data-extensions';
import Spinner from '../../../../shared/Spinner/Spinner';
import SelectionTemplateTargetDE from './SelectionTemplateTargetDE';
import UpgradeBadge from '../../../../shared/UpgradeBadge/UpgradeBadge';
import Util from '../../../../../util';
import Tooltip from '../../../../shared/Tooltip/Tooltip';
import { featureAdvertExists } from '../../../../shared/Feature/featureUtil';

import './styles.scss';

const SelectionTemplate = (
  {
    selectionTemplates,
    targetDataExtensions,
    targetDE,
    handleSetTargetDE,
    toggleCopy,
    handleToggleCopy,
    featureSqlToSelection,
    handleSetNewCopyState,
    loadingFoldersForCopy,
    isUseTemplate,
    handleSetSelectionTemplate = () => { },
    featureSelectionTemplates,
    name,
    showEssentialsUpgradeModal,
  },
) => {
  const axiosCancelToken = axios.CancelToken.source();
  const [useTemplate, setUseTemplate] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [loading, setLoading] = useState(false);

  /**
   * The function that assigns a default name for the copy
   * @param {string} name - name for which creates the name of the copy
   * @param {number} i - the index of the copy
   * @param {object} targetDEId - the target data extension id
   * @returns {string} defaultName or function that runs createDefaultName again
   */
  const createDefaultName = async (name, i, targetDEId) => {
    let index = i;

    // define default name for the copy and check if there is an object with duplicate name
    const defaultName = `${name} ${index}`;

    const duplicateName = await Util.findDuplicateName(
      targetDataExtensions,
      null,
      defaultName,
      targetDEId,
      true,
    );
    /**
     * if it doesn't find an object with duplicate nasme, return the default name,
     * in another case perform the function again increasing the index by 1
     */

    if (!duplicateName) {
      return defaultName;
    }

    index += 1;

    return createDefaultName(name, index, targetDEId);
  };

  /**
   *  Get required properties for options
   * @param {Array} selections - Selections to get options from
   * @returns {Array} - Options
   */
  const formatTemplateOptions = (selections) => {
    return selections.map(selection => ({
      value: selection._id,
      key: selection._id,
      text: selection.name,
    }));
  };

  /**
   * @returns {void} Toggle for whether to use a template or not when creating a selection
   */
  const handleToggleUseTemplate = () => {
    if (useTemplate === false) {
      setUseTemplate(true);
      handleSetSelectionTemplate(selectedTemplate);
    } else {
      setUseTemplate(false);
      handleSetSelectionTemplate(null);
    }
  };

  /**
   * @param {event} e  event object of dropdown
   * @param {*} data data object of dropdown
   * @returns {void} handles changing of template selection
   */
  const handleSelectedTemplateDropDown = async (e, data) => {
    if (useTemplate) {
      setSelectedTemplate(data.value);
      handleSetSelectionTemplate(data.value);

      // Set targetDE back to null while switching templates
      handleSetTargetDE(null);
      setLoading(true);

      // Get the template
      const selectedTemplate = await selectionTemplates.find(selectionTemplate => selectionTemplate._id === data.value);

      const getTargetDataExtension = (selectedTemplate) => {
        if (selectedTemplate.targetCollectionCustomerKey) {
          return DataExtensionsAPI.getDataExtension(
            selectedTemplate.targetCollectionCustomerKey,
            axiosCancelToken.token,
          );
        }

        return null;
      };

      const targetDataExtension = await getTargetDataExtension(selectedTemplate);

      if (targetDataExtension) {
        const defaultNameForTargetCopy = `${targetDataExtension?.Name} copy`;
        const defaultTargetDEName = await createDefaultName(defaultNameForTargetCopy, 1, targetDataExtension?.ObjectID);
        const targetDECopy = {
          ...targetDataExtension,
          Name: defaultTargetDEName,
          folderId: targetDataExtension.CategoryID,
        };

        handleSetTargetDE(targetDECopy);
      }
      setLoading(false);
    }
  };

  /**
   * @param {String} deCopyName - name of the copy of the target data extension
   * @returns {void}  handle changing the name of the target DE copy
   */
  const handleChangeTargetDeName = (deCopyName) => {
    const targetDECopy = { ...targetDE, Name: deCopyName };

    handleSetTargetDE(targetDECopy);
  };

  useEffect(() => {
    if (isUseTemplate) {
      setUseTemplate(true);
    } else {
      setUseTemplate(false);
    }
  }, [isUseTemplate]);

  return (
    <div
      className={classNames(
        'selection-template',
        { 'disabled-overlay': !featureSelectionTemplates },
      )}
      onClick={featureSelectionTemplates ?
        null :
        () => {
          showEssentialsUpgradeModal(Constants.FEATURE__SELECTION_TEMPLATE);
        }}
    >
      {!featureSqlToSelection && (
      <div
        className="use-template-toggle"
        >
        {
          !featureSelectionTemplates &&
            (featureAdvertExists(Constants.FEATURE__SELECTION_TEMPLATE) ?
            <UpgradeBadge /> :
            <Tooltip
              nubbinPosition={Constants.NUBBIN_POSITION__TOP_RIGHT}
              type={Constants.TOOLTIP_TYPE__UNAVAILABLE_FEATURE}
            />)
        }
        <span
          className="slds-form-element__label slds-m-bottom_none checkbox-label"
        >
         {name}
        </span>
        <ToggleButton
          name="select-template-toggle"
          onChange={handleToggleUseTemplate}
          checked={useTemplate}
        />
      </div>
      )}

      <Dropdown
        className="template-selection-dropdown  searchable-dropdown"
        selection
        value={selectedTemplate}
        search
        disabled={!useTemplate}
        options={formatTemplateOptions(selectionTemplates)}
        placeholder="Choose a template"
        onChange={handleSelectedTemplateDropDown}
        loading
        style={{ borderRadius: '.25rem' }}
      />

      {
        loading ?
          (
            <div className="spinner_container">
              <Spinner
                size={Constants.SPINNER__SIZE__MEDIUM}
              />
            </div>
          ) :
          (
            useTemplate && selectedTemplate &&
              <SelectionTemplateTargetDE
                targetDE={targetDE}
                handleChangeTargetDeName={handleChangeTargetDeName}
                targetDataExtensions={targetDataExtensions}
                toggleCopy={toggleCopy}
                handleToggleCopy={handleToggleCopy}
                handleSetNewCopyState={handleSetNewCopyState}
                loadingFoldersForCopy={loadingFoldersForCopy}
              />
          )
      }
    </div>
  );
};

SelectionTemplate.propTypes = {
  /**
   * Array of selection templates
   */
  selectionTemplates: PropTypes.array.isRequired,
  /*
   * It helps to set selection template in state
   */
  handleSetSelectionTemplate: PropTypes.func.isRequired,
  /**
   * Array of target data extensions
   */
  targetDataExtensions: PropTypes.array,
  /**
   * Target data extension
   */
  targetDE: PropTypes.object,
  /**
   * Function to set target data extension
   */
  handleSetTargetDE: PropTypes.func,
  /**
   * Boolean value to check if copy is toggled
   */
  toggleCopy: PropTypes.bool,
  /**
   * Function to toggle copy
   */
  handleToggleCopy: PropTypes.func,
  /**
   * Function to set new copy state for new auto created target DE
   */
  handleSetNewCopyState: PropTypes.func,
  /**
   * Boolean value to check if loading folders for copy
   */
  loadingFoldersForCopy: PropTypes.bool,
  /**
   * Indicates if the feature selection templates is enabled
   */
  featureSelectionTemplates: PropTypes.bool.isRequired,
  /**
   * It toggles a feature advert modal on with specific feature
   */
  showEssentialsUpgradeModal: PropTypes.func.isRequired,
};

export default SelectionTemplate;
