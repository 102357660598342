import { useState, useEffect } from 'react';
import axios from 'axios';

import DataExtensionsAPI from '../../api/data-extensions';
import HooksUtil from './hooksUtil';
import Constants from '../../constants/constants';
import Util from '../../util';
import DataViews from '../../constants/dataViews';

/**
 * Custom hook for updating available data extensions
 * @param {Function} setLoading - Function that updates loading state
 * @param {Boolean} skipExclusion - Helps to determine if excluded DEs are fetched or not
 * @returns {Array} - Array that contains state of available data extensions
 */
const useAvailableDataExtensions = (setLoading, skipExclusion = false) => {
  const [availableDataExtensions, setAvailableDataExtensions] = useState([]);

  /**
   * Fetches all Available Data Extensions and updates state.
   * @returns {void}
   */
  const fetchDataExtensions = async () => {
    setLoading(true);
    const availableDEs = skipExclusion ?
      await DataExtensionsAPI.getDataExtensionsWithExclusionSkipped(
        axios.CancelToken.source().token,
        Constants.DATAEXTENSION__FILTER_MODE__AVAILABLE,
      ) :
      await DataExtensionsAPI.getDataExtensions(
        axios.CancelToken.source().token,
        Constants.DATAEXTENSION__FILTER_MODE__AVAILABLE,
      );

    const dataViews = DataViews.map(dataView => ({ ...dataView, ObjectID: dataView.Name }));

    const dataExtensions = HooksUtil.formatDataExtensions(availableDEs.concat(dataViews));

    Util.sortArrayOfObjects(dataExtensions, 'Name');

    setAvailableDataExtensions(dataExtensions);
    setLoading(false);
  };

  useEffect(() => {
    fetchDataExtensions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // return custom hook elements
  return [availableDataExtensions, setAvailableDataExtensions];
};

export { useAvailableDataExtensions };
