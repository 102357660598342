import React from 'react';
import './styles.scss';

function UpgradeBadge() {
  return (
    <img
      className="upgrade-badge-icon"
      src="/assets/icons/Deedee_Certificate_Banner.png"
      alt="Premium icon"
    />
  );
}

export default UpgradeBadge;
