const Features = {
  // Return true if enabled, false if disabled, undefined if features is not defined for this org
  isFeatureEnabled: (featuresInfo, featureKey) => {
    if (featuresInfo) return featuresInfo[featureKey];

    return false;
  },
};

export default Features;
