
import React from 'react';
import PropTypes from 'prop-types';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers';

import SortableCriteriaItem from './SortableCriteriaItem';

const CriteriaSortableContainer = ({
  criteriaText, sortCriteria, editCriteria, toggleInputType, thenValues, fieldTypeDynamicValue,
  selectedDataExtensions, dynamicCriteriaValues, showInvalidEmailMessage,
  renderDeleteCriteriaDynamicModal, renderNullCheckbox, isDefaultValueNull, nullValues,
  showNoAvailableFieldsError, getSelectedDEFields, noAvailableFieldsRef,
  handleSelectedDEChange, handleSelectedFieldChange, handleChange,
  handlePeriodDateChange, handleBlur, renderInvalidEmailAddressMessage, disabled,
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  /**
   * handle drag end
   * @param {object} event - the object of drag end event
   * @returns {void}
   */
  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = criteriaText.indexOf(active.id);
      const newIndex = criteriaText.indexOf(over.id);

      sortCriteria(oldIndex, newIndex);
    }
  };

  return (
      <div>
        <DndContext
            sensors={sensors}
            modifiers={[restrictToVerticalAxis]}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}>
            <SortableContext
              items={criteriaText}
              strategy={verticalListSortingStrategy}>
              {criteriaText.map((criteria, index) => (
                <SortableCriteriaItem
                key={index}
                criteria={criteria}
                index={index}
                editCriteria={editCriteria}
                toggleInputType={toggleInputType}
                thenValues={thenValues}
                fieldTypeDynamicValue={fieldTypeDynamicValue}
                selectedDataExtensions={selectedDataExtensions}
                dynamicCriteriaValues={dynamicCriteriaValues}
                showInvalidEmailMessage={showInvalidEmailMessage}
                renderDeleteCriteriaDynamicModal={renderDeleteCriteriaDynamicModal}
                renderNullCheckbox={renderNullCheckbox}
                isDefaultValueNull={isDefaultValueNull}
                nullValues={nullValues}
                showNoAvailableFieldsError={showNoAvailableFieldsError}
                getSelectedDEFields={getSelectedDEFields}
                noAvailableFieldsRef={noAvailableFieldsRef}
                handleSelectedDEChange={handleSelectedDEChange}
                handleSelectedFieldChange={handleSelectedFieldChange}
                handleChange={handleChange}
                handlePeriodDateChange={handlePeriodDateChange}
                handleBlur={handleBlur}
                renderInvalidEmailAddressMessage={renderInvalidEmailAddressMessage}
                disabled={disabled}
                />))}
            </SortableContext>
        </DndContext>
      </div>
  );
};

CriteriaSortableContainer.propTypes = {
  /**
   * dynamic criteria array
   */
  criteriaText: PropTypes.instanceOf(Array),
  /**
   * a function to sort criteria list
   */
  sortCriteria: PropTypes.func,
  /**
   * a function to edit criteria
   */
  editCriteria: PropTypes.func,
  /**
   * Toggles the input type of a then or default criteria
   */
  toggleInputType: PropTypes.func,
  /**
   * then values
   */
  thenValues: PropTypes.instanceOf(Array),
  /**
   * Field type (Default: Text)
   */
  fieldTypeDynamicValue: PropTypes.string,
  /**
   * It keeps the selected data extensions for Selection.js
   * selected data extensions are stored as collections in database
   * It will be passed from Selection.js
   */
  selectedDataExtensions: PropTypes.instanceOf(Array),
  /**
   * When and then clauses list
   */
  dynamicCriteriaValues: PropTypes.instanceOf(Array),
  /**
   * invalid email messages array
   */
  showInvalidEmailMessage: PropTypes.instanceOf(Array),
  /**
   * Renders the delete criteria button for the dynamic modal
   */
  renderDeleteCriteriaDynamicModal: PropTypes.func,
  /**
   * Renders the null checkbox inside dynamic
   */
  renderNullCheckbox: PropTypes.func,
  /**
   * Determines whether default value is null or not
   */
  isDefaultValueNull: PropTypes.bool,
  /**
   * The array that keeps track of which checkbox is checked
   */
  nullValues: PropTypes.instanceOf(Array),
  /**
   * showNoAvailableFieldsError
   */
  showNoAvailableFieldsError: PropTypes.bool,
  /**
   * a function that helps to get fields from picked selected DE
   */
  getSelectedDEFields: PropTypes.func,
  /**
   * Ref for the noAvailableFields error
   */
  noAvailableFieldsRef: PropTypes.instanceOf(Object),
  /**
   * a function that helps with changing selected DE
   */
  handleSelectedDEChange: PropTypes.func,
  /**
   * a function that helps with changing default field
   */
  handleSelectedFieldChange: PropTypes.func,
  /**
   * a function that helps to custom value field name, Then clause (dynamic type) or value (fixed type)
   */
  handleChange: PropTypes.func,
  /**
   * handle period date change
   */
  handlePeriodDateChange: PropTypes.func,
  /**
   * a function that helps to set Then clause (dynamic type) or value (fixed type) on blur
   */
  handleBlur: PropTypes.func,
  /**
   * a function for rendering an invalid email error message
   */
  renderInvalidEmailAddressMessage: PropTypes.func,
  /**
   * disable property for sortable container
   */
  disabled: PropTypes.bool,
};

export default CriteriaSortableContainer;
