import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import Button from '../../../../../shared_v2/Button/Button';
import Constants from '../../../../../../constants/constants';
import Select from '../../../../../shared_v2/Select/Select';

const InsertFunction = ({ functions, handleOnChange, disabled }) => {
  const [selectedFunctionSyntax, setSelectedFunctionSyntax] = useState(functions[0].syntax);

  // get selected function object
  const selectedFunctionObject = functions.find(func => func.syntax === selectedFunctionSyntax);

  // used to group function in two categories: Text, Logical
  const groupedFunctions = {};

  // loop through functions array and group functions by category property
  functions.forEach((func) => {
    if (!groupedFunctions[func.category]) {
      groupedFunctions[func.category] = [];
    }

    groupedFunctions[func.category].push({
      value: func.syntax,
      label: func.name,
    });
  });

  /**
   * It helps to change selected function
   * @param {object} e - event. Use e.target to get the value
   * @returns {void}
   */
  const handleChangeFunctions = (e) => {
    setSelectedFunctionSyntax(e.target.value);
  };

  return (
    <div className="insert-function-container">
      <div className="insert-function-content">
        <div className="insert-function-title">
          Insert Function
        </div>
        <Select
          containerClassName="custom-values-select"
          id="select-field-type"
          value={selectedFunctionSyntax}
          onChange={handleChangeFunctions}
          required
          groupedOptions={groupedFunctions}
          disabled={disabled}
        />
        <div className="syntax-descriptions">
          <div>
            <b>
              Syntax:
              {' '}

            </b>
            <span id="Syntax">{selectedFunctionObject?.syntax}</span>
          </div>
          <div>
            <b>
              Description:
              {' '}

            </b>
            <span id="Description">{selectedFunctionObject?.description}</span>
          </div>
        </div>
        <div className="syntax-example">
          <div>
            <b>
              Example:
              {' '}
            </b>
            <span id="example">{selectedFunctionObject?.example}</span>
          </div>
          <a href={selectedFunctionObject?.link} target="_blank" rel="noopener noreferrer">More info</a>
        </div>
      </div>
      <Button
        id="insert-formula-button"
        buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
        className="black"
        disabled={!selectedFunctionSyntax || disabled}
        onClick={() => handleOnChange(null, selectedFunctionSyntax)}
      >
        Insert Formula
        <svg
          className="slds-button__icon slds-icon_small"
          id="plus-icon"
          aria-hidden="true"
        >
          <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#add" />
        </svg>
      </Button>
    </div>
  );
};

InsertFunction.propTypes = {
  /**
   * It keeps available functions for user to choose
   */
  functions: PropTypes.instanceOf(Array).isRequired,
  /**
   * It helps to add a selected function to the text box
   */
  handleOnChange: PropTypes.func.isRequired,
  /**
   * disable property for the component
   */
  disabled: PropTypes.bool,
};

export default InsertFunction;
