import axios from 'axios';

import Util from '../util';

const apiUrl = process.env.REACT_APP_API_URL;

const FilterSetsCategoryAPI = {
  /**
   * Get filter sets categories for current organization
   * @param {object} cancelToken - Token axios
   * @returns {object} Filter sets categories of current organization
   */
  getFilterSetsCategories: async (cancelToken) => {
    const { data: { data } } = await Util.catch418And403Error(
      axios.get,
      `${apiUrl}/filter-set-categories`,
      Util.apiGetCallSettings(cancelToken),
    );

    return data;
  },

  /**
   * Create a new filter set category based on the given parameters
   * @param {object} postData - object of data
   * @param {object} cancelToken - Token axios
   * @returns {object} Newly created filter set category
   */
  createFilterSetCategory: async (postData, cancelToken) => {
    const { data } = await Util.catch418And403Error(
      axios.post,
      `${apiUrl}/filter-set-category`,
      postData,
      Util.apiPostCallSettings(cancelToken),
    );

    return data;
  },

  /**
   * Updates an existing filter set category based on the given parameters
   * @param {string} id - Id of the filter set category
   * @param {object} postData - object of data
   * @param {object} cancelToken - Token axios
   * @returns {object} Updated filter set category
   */
  updateFilterSetCategory: async (
    id,
    postData,
    cancelToken,
  ) => {
    const { data } = await Util.catch418And403Error(
      axios.put,
      `${apiUrl}/filter-set-category/${id}`,
      postData,
      Util.apiPostCallSettings(cancelToken),
    );

    return data;
  },

  /**
   * Delete filter set category based the on a given id
   * @param {string} id - Id of the filter set category that's going to be deleted
   * @param {object} cancelToken - Axios token
   * @returns {object} Deleted filter set category
   */
  deleteFilterSetCategory: async (
    id,
    cancelToken,
  ) => {
    const { data } = await Util.catch418And403Error(
      axios.delete,
      `${apiUrl}/filter-set-category/${id}`,
      Util.apiPostCallSettings(cancelToken),
    );

    return data;
  },
};

export default FilterSetsCategoryAPI;
