import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Constants from '../../../../constants/constants';
import Input from '../../../shared/Input/Input';

class PicklistValueInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  /**
   * Execute when clicking outside or loosing focus of the input field
   * @param {Object} e - event
   * @param {string} filterType - type of the filter
   * @param {string} value - Value of the valueInput in the state for PicklistAddValuesComponent
   * @returns {void}
   */
  inputOnBlur(e, filterType, value) {
    const {
      val,
      i,
      isActive,
      handleChangeOptionLabel,
      handleChangeOptionValue,
      handleChangeOptionValueInOptions,
      labelValue,
      setLabelInput,
    } = this.props;

    // if props val is passed, then trigger the function to change the value of activeValues
    if (val) {
      handleChangeOptionValueInOptions(e, i, isActive);
    } else if (!val && handleChangeOptionLabel && handleChangeOptionValue) {
      // set the state of the value depending of type of the filter
      handleChangeOptionValue(e, filterType);

      // check if label has a value, if not set the label input state
      handleChangeOptionLabel(e, true);
      if (labelValue === '' && !labelValue) {
        setLabelInput(value);
      }
    }
  }

  renderInput() {
    const {
      selectedField,
      val,
      valueInputRef,
      onChange,
      value,
      inputOnKeyDown,
      readOnly,
      i,
    } = this.props;

    let classType = '';

    let filterType = '';

    let inputType = '';

    if (selectedField?.FieldType) {
      switch (selectedField.FieldType) {
        case Constants.FILTERLINE__FIELDTYPE__NUMBER:
          classType = 'value_input_number';
          filterType = Constants.FILTERLINE__FIELDTYPE__NUMBER;
          inputType = 'number';
          break;
        case Constants.FILTERLINE__FIELDTYPE__DECIMAL:
          classType = 'value_input_decimal';
          filterType = Constants.FILTERLINE__FIELDTYPE__DECIMAL;
          inputType = 'number';
          break;
        default:
          classType = 'value_input_text';
          filterType = Constants.FILTERLINE__FIELDTYPE__TEXT;
          inputType = 'text';
          break;
      }
    }

    return (
      <Input
        containerClassName="slds-truncate"
        containerTitle={val}
        id={`picklist-option-value-${i}`}
        type={inputType}
        forwardRef={val ? null : valueInputRef}
        placeholder="Value"
        required
        disabled={!selectedField?.ObjectID}
        className={classType}
        onBlur={e => this.inputOnBlur(e, filterType, value)}
        onChange={onChange}
        value={val || value || ''}
        onKeyDown={inputOnKeyDown}
        readOnly={readOnly}
      />
    );
  }

  render() {
    return (
      <>
        {this.renderInput()}
      </>
    );
  }
}

PicklistValueInput.propTypes = {
  /**
   * Selected field in state of PicklistsAE
   */
  selectedField: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  /**
   * Value that was previously passed a function parameter, needed for in-table input
   */
  val: PropTypes.node,
  /**
   * Value that was previously passed a function parameter, needed for in-table input
   */
  i: PropTypes.number,
  /**
   * Value that was previously passed a function parameter, needed for in-table input
   */
  isActive: PropTypes.bool,
  /**
   * Changes the option label in state from PicklistsAE
   */
  handleChangeOptionLabel: PropTypes.func,
  /**
   * Changes the option value in state from PicklistsAE
   */
  handleChangeOptionValue: PropTypes.func,
  /**
   * Changes the option value from the existing options in PicklistsAE state
   */
  handleChangeOptionValueInOptions: PropTypes.func,
  /**
   * Input reference in PicklistsAE
   */
  valueInputRef: PropTypes.oneOfType([PropTypes.object]),
  /**
   * Value of the valueInput in the state for PicklistAddValuesComponent
   */
  value: PropTypes.string,
  /**
   * Changes the state of the value in PicklistAddValuesComponent
   */
  onChange: PropTypes.func,
  /**
   * Add values to option after pressing the enter
   */
  inputOnKeyDown: PropTypes.func,
  /**
   * Label value in the state of PicklistAddValuesComponent
   */
  labelValue: PropTypes.string,
  /**
   * Changes the state for label input in PicklistAddValuesComponent
   */
  setLabelInput: PropTypes.func,
  /**
   * Means that the input field is to be read-only and cannot be changed
   */
  readOnly: PropTypes.bool,
};

export default PicklistValueInput;
