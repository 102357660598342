import { useState } from 'react';

// Utils
import SwalUtil from '../swal/swalUtil';

/**
 *  Custom hook for creating new email notification
 * @param {Object} param - Params of the custom hook
 * @param {Array} param.userList - list of users
 * @param {Array} param.notificationEmails - list of existing notification emails
 * @param {Function} param.handleCreateNotificationEmail - function that call the api and create the notification email
 * @returns {Array} - Array that contains state of email, set method and save method
 */
const useCreateNotificationEmail = ({ notificationEmails, handleCreateNotificationEmail }) => {
  const [newEmails, setNewEmails] = useState('');

  /**
   * Validate and save email
   * @param {string} emailToSave - email to save
   * @returns {string} - saved email
   */
  const saveOneEmail = async (emailToSave) => {
    // Find for any duplicate match
    const email = notificationEmails.find(elm => elm?.email?.trim() === emailToSave.trim());

    if (email) {
      // Show warning modal to notify that there is duplicates
      await SwalUtil.fire({
        title: 'Create Notification Email',
        messageHTML: `The notification email <b class="bold_swal">${emailToSave}</b> already exists.`,
        options: {
          confirmButtonText: 'Ok',
          allowOutsideClick: true,
        },
      });
    } else {
      await handleCreateNotificationEmail(emailToSave);

      return emailToSave;
    }
  };

  /**
   * Validate emails
   * @returns {void}
   */
  const saveEmails = async () => {
    const emails = [...new Set(newEmails.split(','))];

    const saveEmailsPromises = emails.map(async (email) => {
      const savedEmail = await saveOneEmail(email.trim());

      return savedEmail;
    });

    await Promise.all(saveEmailsPromises);
    setNewEmails('');
  };

  // return custom hook elements
  return [newEmails, setNewEmails, saveEmails];
};

export { useCreateNotificationEmail };
