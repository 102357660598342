import React from 'react';
import PropTypes from 'prop-types';
import sqlFormatter from '@sqltools/formatter';

import SQLQueryModal from '../../../../Selection/TargetDefinition/SQLQueryModal';
import Alert from '../../../../shared/Alert/Alert';
import Constants from '../../../../../constants/constants';
import timeUtil from '../../../../../utils/time/timeUtil';

const SelectionRunLogDetails = ({
  selectedRunLog, userDateTimeFormat, showTriggerMode, showSourceLabel,
}) => (
  <>
    <div className="selection-run-log-information">
      <div className="selection-run-logs-details-container">
        <h2 className="selection-run-logs-details-header">Date</h2>
        <h2 className="selection-run-logs-details-header">User</h2>
        <h2 className="selection-run-logs-details-header">Trigger</h2>
        <h2 className="selection-run-logs-details-header">Source</h2>
        {selectedRunLog.mainNumberOfRecords || selectedRunLog.mainNumberOfRecords === 0 ?
          <h2 className="selection-run-logs-details-header">
            {selectedRunLog.dedupNumberOfRecords || selectedRunLog.dedupNumberOfRecords === 0 ?
              'Records before Deduplication' :
              'Records'}
          </h2> :
          null}
        {selectedRunLog.dedupNumberOfRecords || selectedRunLog.dedupNumberOfRecords === 0 ?
          <h2 className="selection-run-logs-details-header">Deduplicated records</h2> :
          null}
      </div>
      <div>
        <div className="detail-section date">
          {timeUtil.formatDate(selectedRunLog.startDateTime, userDateTimeFormat)}
        </div>
        <div className="detail-section user">{selectedRunLog.userName}</div>
        <div className="detail-section trigger">
          {showTriggerMode(selectedRunLog)}
        </div>
        <div className="detail-section source">
          {showSourceLabel(selectedRunLog)}
        </div>
        {selectedRunLog.mainNumberOfRecords || selectedRunLog.mainNumberOfRecords === 0 ?
          <div className="detail-section main-number">{selectedRunLog.mainNumberOfRecords}</div> :
          null}
        {selectedRunLog.dedupNumberOfRecords || selectedRunLog.dedupNumberOfRecords === 0 ?
          <div className="detail-section dedup-number">{selectedRunLog.dedupNumberOfRecords}</div> :
          null}
      </div>
    </div>
    {/* run logs for waterfall selection steps */}
    {selectedRunLog?.selectionData?.length ?
      selectedRunLog?.selectionData.map(data => (
        data?.query || data?.numberOfRecords || data?.error ?
          (
            <div className="selection-run-log-information without-border" key={data._id}>
              <div className="selection-run-logs-details-full-width">
                <h2 className="selection-run-logs-details-header step">
                  Step
                  {' '}
                  {data.order}
                </h2>
                {data.query ?
                  (
                    <div className="selection-run-logs-details-step">
                      <h2>SQL Query</h2>
                      <SQLQueryModal taskQuery={sqlFormatter.format(data.query)} withoutModal />
                    </div>
                  ) :
                  null}
                {data.numberOfRecords || data.numberOfRecords === 0 ?
                  (
                    <div className="selection-run-logs-details-step">
                      <h2>Number Of Records</h2>
                      <div className="detail-section step-records">{data.numberOfRecords}</div>
                    </div>
                  ) :
                  null}

                {data.error ?
                  (
                    <div className="error-message selection-run-logs-details-step">
                      <h2>Error Message</h2>
                      <Alert
                        assistiveText={Constants.ALERT__TYPE__WARNING}
                        dangerouslySetInnerHTML={data.error}
                      />
                    </div>
                  ) :
                  null}
              </div>
            </div>
          ) :
          null
      )) :
      null}
    {selectedRunLog.error && !selectedRunLog?.selectionData?.length ?
      (
        <div className="selection-run-log-information without-border">
          <div className="error-message selection-run-logs-details-full-width">
            <h2 className="selection-run-logs-details-header error-message-header">Error Message</h2>
            <Alert
              assistiveText={Constants.ALERT__TYPE__WARNING}
              dangerouslySetInnerHTML={selectedRunLog.error}
            />
          </div>
        </div>
      ) :
      null}
    {selectedRunLog?.query && !selectedRunLog?.selectionData?.length ?
      (
        <div className="selection-run-log-information without-border">
          <div className="selection-run-logs-details-full-width">
            <h2 className="selection-run-logs-details-header sql-query-header">
              {selectedRunLog?.dedupQuery ?
                'Main SQL Query' :
                'SQL Query'}
            </h2>
            <SQLQueryModal
              taskQuery={sqlFormatter.format(selectedRunLog.query)}
              withoutModal
            />
          </div>
        </div>
      ) :
      null}
    {selectedRunLog?.dedupQuery && !selectedRunLog?.selectionData?.length ?
      (
        <div className="selection-run-log-information without-border">
          <div className="selection-run-logs-details-full-width">
            <h2 className="selection-run-logs-details-header sql-query-header">Deduplication SQL Query</h2>
            <SQLQueryModal taskQuery={sqlFormatter.format(selectedRunLog.dedupQuery)} withoutModal />
          </div>
        </div>
      ) :
      null}
  </>
);

SelectionRunLogDetails.propTypes = {
  /**
   * Single and selected run log for a given selection
   */
  selectedRunLog: PropTypes.instanceOf(Object).isRequired,
  /**
   * Time format read from user's cookies
   */
  userDateTimeFormat: PropTypes.string.isRequired,
  /**
   * Function that returns trigger mode label for run log
   */
  showTriggerMode: PropTypes.func.isRequired,
  /**
   * Function that returns source label for run log
   */
  showSourceLabel: PropTypes.func.isRequired,
};

export default SelectionRunLogDetails;
