import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

import Constants from '../../../../../constants/constants';
import TimezoneDropdown from '../TimezoneDropdown/TimezoneDropdown';
import TimezoneList from '../../../ScheduleSelectionModal/TimezoneList.json';
import './style.scss';
import ToggleButton from '../../../../shared_v2/ToogleButton/ToggleButton';
import Select from '../../../../shared_v2/Select/Select';
import Input from '../../../../shared_v2/Input/Input';
import RadioButton from '../../../../shared_v2/RadioButton/RadioButton';
import FromTimezoneContainer from '../../../Filters/SelectedFilters/FromTimezoneContainer/FromTimezoneContainer';
import timeUtil from '../../../../../utils/time/timeUtil';

class TransformDateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataExtension: null,
      dateFields: [],
    };
  }

  async componentDidMount() {
    const {
      handleSetCustomValuesState,
      transformDateData,
      selectedDataExtensions,
      getDataExtensionOrDataViewFields,
    } = this.props;

    let { collectionAlias, fieldObjectId } = transformDateData;

    if (selectedDataExtensions.length > 0) {
      /**
       * When the modal is first loaded and there's only one
       * data extension or the user does not change the default
       * data extension
       */
      if (!collectionAlias) {
        collectionAlias = null;
      }

      const dataExtension = this.getRequiredDataExtensions()?.find(de => de.deAlias === collectionAlias);

      if (!dataExtension) {
        fieldObjectId = null;
      }
      if (dataExtension) {
        const dataExtensionFields = dataExtension?.fields ?
          dataExtension.fields :
          (await getDataExtensionOrDataViewFields(dataExtension));

        const dateFields = dataExtensionFields.filter(de => de.FieldType === Constants.FILTERLINE__FIELDTYPE__DATE);

        this.setState({ dataExtension, dateFields });
      }

      /**
       * If fieldObjectId is truthy/exists, that means we don't need
       * to update the transformDateData object, else this is the case
       * where there's only one data extension or the user does not
       * change the default data extension and we need to update the
       * transformDateData
       */
      if (!fieldObjectId) {
        handleSetCustomValuesState({
          transformDateData: {
            ...transformDateData,
            dataExtensionCustomerKey: null,
            dataExtensionObjectId: null,
            collectionAlias: null,
            fieldObjectId: null,
          },
        });
      }
    } else {
      this.resetTransformDateModal();
    }
  }

  /**
   * Return the data extensions and add excluded DE that was selected before
   * @returns {void}
   */
  getRequiredDataExtensions = () => {
    const { transformDateData, selectedDataExtensions, availableDataExtensionsWithExclusionSkipped } = this.props;

    let selectedDataExtension =
        selectedDataExtensions?.find(de => de.ObjectID === transformDateData?.dataExtensionObjectId);

    if (!selectedDataExtension) {
      selectedDataExtension =
        availableDataExtensionsWithExclusionSkipped?.find(
          de => de.ObjectID === transformDateData?.dataExtensionObjectId,
        );

      if (selectedDataExtension) {
        return [...(selectedDataExtensions || []), selectedDataExtension];
      }
    }

    return selectedDataExtensions;
  };

  /**
   * This function resets the TransformDateData object
   * @returns {void}
   */
  resetTransformDateModal = () => {
    const { transformDateData, handleSetCustomValuesState } = this.props;

    handleSetCustomValuesState({
      transformDateData: {
        ...transformDateData,
        dataExtensionCustomerKey: null,
        dataExtensionObjectId: null,
        fieldObjectId: null,
        addUnit: Constants.FILTERLINE__DATE_VALUE__INTERVAL_HOUR,
        addValue: '0',
        convertToTimezone: timeUtil.getCurrentUserTimezone(TimezoneList, true),
        format: Constants.CUSTOM_VALUES__FORMULA_FORMAT__DATETIME,
        convert: false,
        convertFromTimezoneQuery: Constants.TIME_ZONE__CENTRAL_STANDARD_TIME__VALUE,
      },
    });
  };

  /**
   * Event handler for when data extension selection is changed
   * @param {object} data - event data
   * @returns {void}
   */
  handleDataExtensionSelected = async (data) => {
    const { value } = data;
    const {
      selectedDataExtensions,
      handleSetCustomValuesState,
      transformDateData,
      getDataExtensionOrDataViewFields,
    } = this.props;

    // Find the selected data extension
    const dataExtension = selectedDataExtensions.filter(de => de.deAlias === value)[0];

    const dataExtensionFields = dataExtension?.fields ?
      dataExtension.fields :
      (await getDataExtensionOrDataViewFields(dataExtension));

    // Find the date fields in that data extension
    const dateFields = dataExtension ?
      dataExtensionFields.filter(de => de.FieldType === Constants.FILTERLINE__FIELDTYPE__DATE) :
      [];

    this.setState({ dataExtension, dateFields });
    const updatedTransformDateData = {
      ...transformDateData,
      dataExtensionCustomerKey: dataExtension.CustomerKey,
      collectionAlias: dataExtension.deAlias,
      dataExtensionObjectId: dataExtension.ObjectID,
      fieldObjectId: dateFields.length ?
        dateFields[0].ObjectID :
        null,
    };
    // Update the transformDateData object in CustomValues.js

    handleSetCustomValuesState({ transformDateData: updatedTransformDateData });
  };

  /**
   * Event handler for when form elements' values are changed
   * Most inputs' and selections' onChange events are handled using this function
   * @param {object} value - onChange event object
   * @param {object} name - onChange event object
   * @returns {void}
   */
  handleFormElementChanged = (value, name) => {
    const { transformDateData, handleSetCustomValuesState } = this.props;
    const updatedTransformDateData = { ...transformDateData, [name]: value };

    // Update the transformDateData object in CustomValues.js
    handleSetCustomValuesState({ transformDateData: updatedTransformDateData });
  };

  /**
   * Event handler for when convert timezone's value is changed
   * @returns {void}
   */
  handleConvertTimezone = () => {
    const { transformDateData, handleSetCustomValuesState } = this.props;
    const updatedTransformDateData = { ...transformDateData, convert: !transformDateData.convert };
    // Update the transformDateData object in CustomValues.js

    handleSetCustomValuesState({ transformDateData: updatedTransformDateData });
  };

  /**
   * Builds dropdown options
   * @param {string} valueProperty - Property that contains dropdown values
   * @param {string} textProperty - Property that contains dropdown text
   * @param {array} array - Array that contains dropdown options
   * @returns {array} Array of option objects
   */
  buildOptions = (valueProperty, textProperty, array) => array.map(element => ({
    value: element[valueProperty],
    text: element[textProperty]?.toString(),
  }));

  render() {
    const { dataExtension, dateFields } = this.state;
    const { selectedDataExtensions, transformDateData, disabled } = this.props;

    const {
      convertToTimezone,
      convert,
      fieldObjectId,
      format,
      addUnit,
      addValue,
      collectionAlias,
      convertFromTimezoneQuery,
    } = transformDateData;

    // Options for Add time unit
    const optToRender = [
      [Constants.FILTERLINE__DATE_VALUE__INTERVAL_MINUTE, Constants.FILTERLINE__DATE_VALUE__INTERVAL_MINUTES_LABEL],
      [Constants.FILTERLINE__DATE_VALUE__INTERVAL_HOUR, Constants.FILTERLINE__DATE_VALUE__INTERVAL_HOURS_LABEL],
      [Constants.FILTERLINE__DATE_VALUE__INTERVAL_DAYS, Constants.FILTERLINE__DATE_VALUE__INTERVAL_DAYS_LABEL],
      [Constants.FILTERLINE__DATE_VALUE__INTERVAL_WEEK_DAY, Constants.FILTERLINE__DATE_VALUE__INTERVAL_WEEK_DAYS_LABEL],
      [Constants.FILTERLINE__DATE_VALUE__INTERVAL_WEEKS, Constants.FILTERLINE__DATE_VALUE__INTERVAL_WEEKS_LABEL],
      [Constants.FILTERLINE__DATE_VALUE__INTERVAL_MONTH, Constants.FILTERLINE__DATE_VALUE__INTERVAL_MONTHS_LABEL],
      [Constants.FILTERLINE__DATE_VALUE__INTERVAL_QUARTER, Constants.FILTERLINE__DATE_VALUE__INTERVAL_QUARTERS_LABEL],
      [Constants.FILTERLINE__DATE_VALUE__INTERVAL_YEAR, Constants.FILTERLINE__DATE_VALUE__INTERVAL_YEARS_LABEL],
    ];

    return (
      <div className="transform-date-modal">
        <div className="custom-values-question">
          <span className="title">{Constants.CUSTOM_VALUES__HEADER__TRANSFORM_DATE}</span>
          <span className="description">{Constants.CUSTOM_VALUES__TEXT_TRANSFORM_DATE}</span>
        </div>
        <div className="custom-values-input">
          <div className="dropdown-inline-group">
            <span className="form-element-label">Data extension: </span>
            <Dropdown
              id="data-extensions-dropdown"
              selection
              className="data-extension searchable-dropdown"
              search
              placeholder="No Data Extension is selected"
              options={this.buildOptions('deAlias', 'deAlias', this.getRequiredDataExtensions())}
              value={collectionAlias || ''}
              onChange={(e, data) => this.handleDataExtensionSelected(data)}
              disabled={!selectedDataExtensions.length || disabled}
              loading
              lazyLoad
            />
          </div>
          <div className="dropdown-inline-group">
            <span className="form-element-label">Field: </span>
            <Dropdown
              id="data-extensions-dropdown"
              selection
              className="data-extension searchable-dropdown"
              search
              placeholder="No Date Fields in the selected Data Extension"
              options={this.buildOptions('ObjectID', 'Name', dateFields)}
              value={fieldObjectId || ''}
              onChange={(e, data) => this.handleFormElementChanged(data?.value, 'fieldObjectId')}
              disabled={!dataExtension || !dateFields.length || disabled}
              loading
              lazyLoad
            />
          </div>
          <br />
          <div className="slds-form-element add">
            <span className="form-element-label">Add:</span>
            <div className="form-control-elements">
              <Input
                type="number"
                name="addValue"
                value={addValue}
                onChange={e => this.handleFormElementChanged(e?.target?.value, 'addValue')}
                disabled={disabled}
              />
              <Select
                id="time-unit"
                name="addUnit"
                onChange={e => this.handleFormElementChanged(e?.target?.value, 'addUnit')}
                value={addUnit}
                options={optToRender.map(([val, label]) => ({ value: val, label }))}
                disabled={disabled}
                />
            </div>
          </div>
          <br />
          <fieldset className="slds-form-element format">
            <legend className="slds-form-element__legend slds-form-element__label">Format:</legend>
            <div className="slds-form-element__control transform-date-options">
              <RadioButton
                id={Constants.CUSTOM_VALUES__FORMULA_FORMAT__DATETIME}
                name="format"
                label="Date + time"
                value={Constants.CUSTOM_VALUES__FORMULA_FORMAT__DATETIME}
                onChange={e => this.handleFormElementChanged(e?.target?.value, 'format')}
                checked={format === Constants.CUSTOM_VALUES__FORMULA_FORMAT__DATETIME}
                disabled={disabled}
              />
              <RadioButton
                id={Constants.CUSTOM_VALUES__FORMULA_FORMAT__DATE}
                name="format"
                label="Date"
                value={Constants.CUSTOM_VALUES__FORMULA_FORMAT__DATE}
                onChange={e => this.handleFormElementChanged(e?.target?.value, 'format')}
                checked={format === Constants.CUSTOM_VALUES__FORMULA_FORMAT__DATE}
                disabled={disabled}
              />
            </div>
          </fieldset>
          <br />
          <div className="slds-form-element">
            <ToggleButton
              id="convertTimezoneToggle"
              name="convertTimezoneToggle"
              onChange={this.handleConvertTimezone}
              checked={convert}
              label="Convert timezone"
              checkboxOnTitle="Enabled"
              checkboxOffTitle="Disabled"
              labelClassName="fit-content"
              disabled={disabled}
            />
          </div>
          <div className="timezone-dropdowns-container">
            <FromTimezoneContainer
              convertFromTimezone={convertFromTimezoneQuery}
              handleTimezoneChange={e => this.handleFormElementChanged(e?.target?.value, e?.target?.name)}
              convertTimezone={convert}
              tooltipPosition={Constants.NUBBIN_POSITION__BOTTOM_LEFT}
              customValueType={Constants.CUSTOM_VALUES__FORMULA_TYPE__TRANSFORM}
              disabled={disabled}
            />
          </div>
          <div className="timezone-dropdowns-container">
            <div className="timezone-dropdown-container">
              <span>Convert to timezone: </span>
              <TimezoneDropdown
                handleOnChange={e => this.handleFormElementChanged(e?.target?.value, e?.target?.name)}
                defaultValue={convertToTimezone}
                name="convertToTimezone"
                disabled={!convert || disabled}
                timezoneClassName="convert-to-timezone-transform"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TransformDateModal.propTypes = {
  /**
   * Function to set the state of the CustomValues component
   */
  handleSetCustomValuesState: PropTypes.func.isRequired,
  /**
   * It keeps the selected data extensions for Selection.js
   * selected data extensions are stored as collections in database
   * It will be passed from CustomValues.js
   */
  selectedDataExtensions: PropTypes.instanceOf(Array),
  /**
   * Object that stores values of the transformDate custom value
   */
  transformDateData: PropTypes.instanceOf(Object),
  /**
   * Function used to fetch DE's fields when needed
   */
  getDataExtensionOrDataViewFields: PropTypes.func,
  /**
   * Determines whether all input fields should be editable or not by the user
   */
  disabled: PropTypes.bool,
  /**
   * List of data extensions with excluded ones
   */
  availableDataExtensionsWithExclusionSkipped: PropTypes.instanceOf(Array),
};

export default TransformDateModal;
