import React from 'react';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import axios from 'axios';
import { connect } from 'react-redux';

import Constants from '../../../constants/constants';
import DataSetsAPI from '../../../api/data-sets';
import Util from '../../../util';
import SortIcon from '../../shared/SortIcon/SortIcon';
import mapStateToProps from '../../../mapStateToProps';

const DataSets = ({
  dataSets = [],
  handleSetAdminPanelState,
  handleEditDataSet,
  openPanel,
  handleSortDataSets,
  sortDirection,
  sortedProperty,
  isLoading,
  userInfo,
}) => {
  const userDateTimeFormat = Util.getUserDateTimeFormat(userInfo);

  /**
   * Deletes the dataSet selected in the
   * overview with the given id
   * @param {Object} event - onClick event
   * @param {String} dataSetId - id of the dataSet to delete
   * @returns {void}
   */
  const handleRemoveDataSet = async (event, dataSetId) => {
    event.preventDefault();

    const result = await Swal.fire({
      type: 'warning',
      title: 'Remove Data Set',
      html: '<p class="width_swal">Are you sure you want to remove this data set?</p>',
      showCancelButton: true,
      confirmButtonText: 'Remove',
      footer: '<div></div>',
      buttonsStyling: false,
    });

    if (result.value) {
      // Delete dataSet
      await DataSetsAPI.deleteDataSet(dataSetId, axios.CancelToken.source().token);

      // Reload data
      openPanel(Constants.ADMIN_PANEL__MENU__DATA_SETS);
    }
  };

  /**
   * Renders html with the data sets info
   * @returns {object} HTML for the Data Sets overview
   */
  const renderDataSetDataTable = () => {
    /*
     * if sortedDataSets are available show sortedDataSets
     * otherwise show text of no sortedDataSets available
     */
    if (dataSets?.length > 0 && !isLoading) {
      return (dataSets.map(dataSet => (
        <tr key={dataSet._id} className="slds-hint-parent row-data">
          <td data-label="DataSet">
            <div className="slds-truncate">
              <a
                href="#!"
                title={dataSet.name}
                onClick={(e) => {
                  handleEditDataSet(e, dataSet._id);
                }}
              >
                {dataSet.name}
              </a>
            </div>
          </td>
          <td data-label="LastModifiedDate">
            <div className="slds-truncate slds-m-left_x-small dataSet-type">
              {dataSet.updatedAt ? Util.formatDate(dataSet.updatedAt, userDateTimeFormat) : null}
            </div>
          </td>
          <td data-label="Action">
            <div className="slds-truncate slds-text-align_left">
              <button
                type="button"
                className="slds-button slds-button_icon slds-button_icon-border-filled remove-dataSet"
                title="Remove"
                onClick={async e => handleRemoveDataSet(e, dataSet._id)}
              >
                <svg className="slds-button__icon" aria-hidden="true">
                  <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#delete" />
                </svg>
              </button>
            </div>
          </td>
        </tr>
      )));
    }

    if (!dataSets?.length && !isLoading) {
      return (
        <tr className="row-data">
          <td
            colSpan="5"
            className="slds-text-align_center"
          >
            <div id="no-picklists-defined">
              No data sets have been defined yet. Hit the &apos;New Data Set&apos; button to get started.
            </div>
          </td>
        </tr>
      );
    }

    return false;
  };

  return (
    <>
      {/* Data Sets header */}
      <div className="slds-page-header slds-m-bottom_medium">
        <div className="slds-page-header__row">
          <div className="slds-page-header__col-title">
            <div className="slds-media header-alignment">
              <div className="slds-media__figure">
                <span className="slds-icon_container slds-icon-standard-account" title="Data Sets">
                  <svg className="slds-icon slds-page-header__icon" aria-hidden="true">
                    <use xlinkHref="/assets/icons/standard-sprite/svg/symbols.svg#picklist_type" />
                  </svg>
                </span>
              </div>
              <div className="slds-media__body">
                <div className="slds-page-header__name">
                  <div className="slds-page-header__name-title">
                    <h1>
                      <span className="slds-page-header__title slds-truncate" title="Data Sets">Data Sets</span>
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="slds-page-header__control">
            <button
              id="new-dataSet"
              className="slds-button slds-button_brand"
              type="button"
              onClick={() => handleSetAdminPanelState({ activePanel: Constants.ADMIN_PANEL__MENU__NEW_DATA_SET })}
              disabled={isLoading}
            >
              New Data Set
            </button>
          </div>
        </div>
      </div>

      <div className="slds-table--header-fixed_container" id="picklists-panel">
        <div className="slds-scrollable">
          <table className="slds-table slds-table_bordered slds-table--header-fixed">
            <thead>
              <tr className="slds-line-height_reset">
                <th scope="col">
                  <div className="slds-truncate slds-cell-fixed slds-m-left_small" title="Name">
                    Name
                    {/* sort icon  */}
                    <span
                      id="dataSetSort"
                      className="sorting slds-icon_container slds-icon-utility-announcement"
                      onClick={() => handleSortDataSets(
                        'name',
                        sortDirection === Constants.SORT_DIRECTION__ASCENDING ?
                          Constants.SORT_DIRECTION__DESCENDING :
                          Constants.SORT_DIRECTION__ASCENDING,
                      )}
                    >
                      <SortIcon sortDirection={sortedProperty === 'name' ? sortDirection : ''} />
                    </span>
                  </div>
                </th>
                <th scope="col">
                  <div className="slds-truncate slds-cell-fixed slds-m-left_x-small" title="Last Modified Date">
                    Last Modified Date
                    {/* sort icon  */}
                    <span
                      id="dateSort"
                      className="sorting slds-icon_container slds-icon-utility-announcement"
                      onClick={() => handleSortDataSets(
                        'updatedAt',
                        sortDirection === Constants.SORT_DIRECTION__ASCENDING ?
                          Constants.SORT_DIRECTION__DESCENDING :
                          Constants.SORT_DIRECTION__ASCENDING,
                      )}
                    >
                      <SortIcon sortDirection={sortedProperty === 'updatedAt' ? sortDirection : ''} />
                    </span>
                  </div>
                </th>
                <th scope="col">
                  <div className="slds-truncate slds-cell-fixed" title="Action">Action</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {renderDataSetDataTable()}
            </tbody>
          </table>
          {isLoading ?
            (
              <div role="status" className="slds-spinner slds-spinner_medium">
                <span className="slds-assistive-text">Loading</span>
                <div className="slds-spinner__dot-a" />
                <div className="slds-spinner__dot-b" />
              </div>
            ) :
            null}
        </div>
      </div>
    </>
  );
};

DataSets.propTypes = {
  /**
   * array containing the dataSets retrieved, this prop comes
   * from the admin panel component
   */
  dataSets: PropTypes.instanceOf(Array).isRequired,
  // handles the state of the admin panel
  handleSetAdminPanelState: PropTypes.func.isRequired,
  // function to open another panel / reload data
  openPanel: PropTypes.func.isRequired,
  // function to open the edit screen for a dataSet
  handleEditDataSet: PropTypes.func.isRequired,
  /**
   * It sorts dataSets
   */
  handleSortDataSets: PropTypes.func.isRequired,
  /**
   * It indicates the direction of sort
   */
  sortDirection: PropTypes.string.isRequired,
  /**
   * It gives by which value sort will take place
   */
  sortedProperty: PropTypes.string,
  /**
   * Responsible for showing/hiding loader
   */
  isLoading: PropTypes.bool.isRequired,
  /**
   * User info from cookie
   */
  userInfo: PropTypes.object,
};

export default connect(mapStateToProps(['userInfo']), null, null, { pure: false })(DataSets);
