import socketIOClient from 'socket.io-client';

const websocketUrl = process.env.REACT_APP_WEBSOCKET_BASEURL;
const websocketPath = '/v1/websocket/server';

let socket = null;
const socketUtil = {

  /**
   * get socket
   * @returns {Object} - socket instance
   */
  getSocket: () => socket,

  /**
   * socket connection
   * @returns {void}
   */
  socketConnection: () => {
    // init socketIO client
    socket = socketIOClient(websocketUrl, {
      withCredentials: true,
      path: websocketPath,
    });

    // on error Listener
    socket.on('error', (error) => {
      console.log('=socket error==', error);
    });
  },

  /**
   * socket disconnect
   * @returns {void}
   */
  socketDisconnect: () => {
    socket.disconnect();
  },
};

export default socketUtil;
