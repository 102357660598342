// create CustomError class inheriting from the Error object
class CustomError extends Error {
  constructor(response, error) {
    super(response, error);
    this.name = 'Error';
    this.message = typeof response === 'string' ? response : response.message;
    this.actualError = response.actualError;
    this.errorCode = response.errorCode;
    this.stack = error.stack;
  }
}

export default CustomError;
