import Constants from './constants';

const DropdownOptions = {
  FIELD_TYPE__OPTIONS: [
    { value: Constants.FILTERLINE__FIELDTYPE__TEXT, label: Constants.FILTERLINE__FIELDTYPE__TEXT },
    { value: Constants.FILTERLINE__FIELDTYPE__NUMBER, label: Constants.FILTERLINE__FIELDTYPE__NUMBER },
    { value: Constants.FILTERLINE__FIELDTYPE__DECIMAL, label: Constants.FILTERLINE__FIELDTYPE__DECIMAL },
    { value: Constants.FILTERLINE__FIELDTYPE__DATE, label: Constants.FILTERLINE__FIELDTYPE__DATE },
    { value: Constants.FILTERLINE__FIELDTYPE__BOOLEAN, label: Constants.FILTERLINE__FIELDTYPE__BOOLEAN },
    {
      value: Constants.FILTERLINE__FIELDTYPE__EMAILADDRESS,
      label: Constants.FILTERLINE__FIELDTYPE__EMAILADDRESS_LABEL,
    },
    { value: Constants.FILTERLINE__FIELDTYPE__PHONE, label: Constants.FILTERLINE__FIELDTYPE__PHONE },
    { value: Constants.FILTERLINE__FIELDTYPE__LOCALE, label: Constants.FILTERLINE__FIELDTYPE__LOCALE },
  ],

  BOOLEAN__OPTIONS: [{ value: 'True', label: 'True' }, { value: 'False', label: 'False' }],

  ADMIN_PANEL_SETTINGS__RETENTION_POLICY_OPTIONS: [
    { label: 'Day(s)', value: 'Days' },
    { label: 'Week(s)', value: 'Weeks' },
    { label: 'Month(s)', value: 'Months' },
    { label: 'Year(s)', value: 'Years' },
  ],

  CUSTOM_VALUE__OPTIONS: [
    {
      text: 'Fixed Value',
      description: 'Choose a fixed value, like a fixed text, number, date, boolean. ' +
      'All the records will have the same fixed value.',
      value: Constants.CUSTOM_VALUES__FIELD_TYPE__FIXED_VALUE,
    },
    {
      text: 'Dynamic Value',
      description: 'Select a value based on logic. Different records can have different values based on the logic ' +
      'you define.',
      value: Constants.CUSTOM_VALUES__FIELD_TYPE__DYNAMIC_VALUE,
    },
    {
      text: 'Apply formula to a field',
      description: 'Modify the value of a field by applying a formula on it.',
      value: Constants.CUSTOM_VALUES__FIELD_TYPE__FORMULA,
    },
    {
      text: 'Aggregation',
      description: 'Apply aggregate functions like Count, Sum, and more on values of a Data Extension / Data View.',
      value: Constants.CUSTOM_VALUES__FIELD_TYPE__AGGREGATION_VALUE,
    },
  ],

  FORMULA__OPTIONS: [
    {
      text: 'Transform Date',
      description: 'Transform a date field in a Data Extension / Data View.',
      value: Constants.CUSTOM_VALUES__FORMULA_TYPE__TRANSFORM,
    },
    {
      text: 'Timestamp',
      description: 'Choose a fixed value, like a fixed text, number, date, boolean. ' +
      'All the records will have the same fixed value.',
      value: Constants.CUSTOM_VALUES__FORMULA_TYPE__TIMESTAMP,
    },
    {
      text: 'Date Difference',
      description: 'Calculate the amount of time between 2 dates.',
      value: Constants.CUSTOM_VALUES__FORMULA_TYPE__DATE_DIFFERENCE,
    },
    {
      text: 'Row Number',
      description: 'Create a unique number for each row.',
      value: Constants.CUSTOM_VALUES__FORMULA_TYPE__ROW_NUMBER,
    },
    {
      text: 'Apply any function.',
      description: 'Freely combine as many SQL functions as you need.',
      value: Constants.CUSTOM_VALUES__FORMULA_TYPE__FREE_FORMULA,
    },
  ],

  SOURCE_LIMITING__OPTIONS: [
    {
      text: Constants.SELECTION_SOURCE_LIMIT__TYPE__NUMBER,
      description: '',
      value: Constants.SELECTION_SOURCE_LIMIT__TYPE__NUMBER,
    },
    {
      text: Constants.SELECTION_SOURCE_LIMIT__TYPE__PERCENTAGE,
      description: '',
      value: Constants.SELECTION_SOURCE_LIMIT__TYPE__PERCENTAGE,
    },
  ],
};

export default DropdownOptions;
