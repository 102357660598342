import React from 'react';
import PropTypes from 'prop-types';

import Filters from '../../../Filters/Filters';
import Constants from '../../../../../constants/constants';
import ModalTemplate from '../../../../shared/ModalTemplate/ModalTemplate';
import SwalUtil from '../../../../../utils/swal/swalUtil';
import filtersUtil from '../../../../../utils/filters/filtersUtil';
import './styles.scss';

const DynamicValuesCriteriaModal = ({
  show,
  handleSetSelectionState,
  selectedDataExtensions,
  dynamicCustomValuesFilterID,
  dynamicCustomValuesFilters,
  getDataExtensionOrDataViewFields,
  compareSelectedDataExtensions,
  DEBorderMouseOver,
  filterBorderMouseOver,
  closeCriteriaModal,
  handleFiltersSave,
  handleSetDynamicValueFilterState,
  pickLists,
  handlePickListOptions,
  handleRemoveFilterLine,
  applyTimezoneSettingsToAllDateFields,
  handleSetTimezoneToAllDateFields,
  timezoneSettingsForAllDateFields,
}) => {
  /**
   * Save filters and close subquery modal
   * @returns {void}
   */
  const save = () => {
    const filters = JSON.parse(JSON.stringify(dynamicCustomValuesFilters));

    if (filters && (filters.filters === undefined || (filters.filters && filters.filters.length === 0))) {
      return SwalUtil.fire({
        title: 'Filter container is empty',
        message: 'Please add at least one filter.',
      });
    }

    handleSetDynamicValueFilterState({
      dynamicValuesFilters: filters,
      dynamicCustomValuesFilterID,
    });

    if (applyTimezoneSettingsToAllDateFields) {
      // Find first available date filter
      const filtersParam = Array.isArray(filters) ? filters : [filters];

      const firstAvailableFilter = filtersUtil.findDateTypeFilter(filtersParam);

      const {
        convertToTimezone, convertTimezone, convertFromTimezone,
      } = firstAvailableFilter;

      handleSetTimezoneToAllDateFields(convertTimezone, convertToTimezone, convertFromTimezone);
    }

    closeCriteriaModal();

    return null;
  };

  return (
    <div style={{ display: show ? 'block' : 'none' }}>
      <ModalTemplate
        id="subquery-modal-dialog"
        className="subquery-modal slds-modal_large criteria-modal"
        headerId="criteria-filters-title"
        headerTitle="Criteria Filters"
        contentClassName="slds-p-around_medium subquery-dialog"
        cancelButtonId="criteria-filters-cancel"
        handleCancel={closeCriteriaModal}
        saveButtonId="criteria-filters-save"
        handleSave={save}
      >
        <Filters
          hideCollectionAlias={false}
          showInResultsOption={false}
          selectedDataExtensions={selectedDataExtensions}
          compareSelectedDataExtensions={compareSelectedDataExtensions}
          handleSetSelectionState={handleSetSelectionState}
          getDataExtensionOrDataViewFields={getDataExtensionOrDataViewFields}
          DEBorderMouseOver={DEBorderMouseOver}
          filterBorderMouseOver={filterBorderMouseOver}
          handleFiltersSave={handleFiltersSave}
          selectedFilters={dynamicCustomValuesFilters || []}
          pickLists={pickLists}
          isFromSelection
          handlePickListOptions={handlePickListOptions}
          handleRemoveFilterLine={handleRemoveFilterLine}
          filterType={Constants.FILTER_TYPE__CUSTOM_VALUES}
          applyTimezoneSettingsToAllDateFields={applyTimezoneSettingsToAllDateFields}
          timezoneSettingsForAllDateFields={timezoneSettingsForAllDateFields}
          handleSetTimezoneToAllDateFields={handleSetTimezoneToAllDateFields}
        />
      </ModalTemplate>
    </div>
  );
};

DynamicValuesCriteriaModal.propTypes = {
  /**
   * It stores the value of subquery modal if it will be shown or not
   */
  show: PropTypes.bool.isRequired,
  /**
   * It helps to set the Selection`s state
   * It will be passed from Selection.js
   */
  handleSetSelectionState: PropTypes.func.isRequired,
  /**
   * It keeps the selected data extension for the subquery
   */
  selectedDataExtensions: PropTypes.instanceOf(Array),
  /**
   * It helps to retrieve fields of a data extension or data view from SFMC
   * It will be passed from Selection.js
   */
  getDataExtensionOrDataViewFields: PropTypes.func.isRequired,
  /**
   * It keeps the all selected data extensions if the subquery modal is opened
   */
  compareSelectedDataExtensions: PropTypes.instanceOf(Array),
  /**
   * Keeps track whether Available DE are dragged
   */
  DEBorderMouseOver: PropTypes.bool.isRequired,
  /**
   * Keeps track whether Available Fields are dragged
   */
  filterBorderMouseOver: PropTypes.bool.isRequired,
  /**
   * It helps to save the selected filters for the criteria
   * it will be passed from Selection.js
   */
  dynamicCustomValuesFilters: PropTypes.instanceOf(Object),
  /**
   * Keeps the index of the selected filters for the criteria
   */
  dynamicCustomValuesFilterID: PropTypes.number,
  /**
   * It helps to close the criteria modal
   * It will be passed from CustomValues.js
   */
  closeCriteriaModal: PropTypes.func.isRequired,
  /**
   * It helps to save the selected filters for the criteria
   * it will be passed from Selection.js
   */
  handleFiltersSave: PropTypes.func.isRequired,
  /**
   * It helps to save the selected filters for the criteria
   * it will be passed from CustomValues.js
   */
  handleSetDynamicValueFilterState: PropTypes.func.isRequired,
  /**
   * Responsible for adding/deleting fields Object IDs when searching picklist for the options
   * it will be passed from Selection.js
   */
  handlePickListOptions: PropTypes.func.isRequired,
  /**
   * Keeps searched picklist
   * It will be passed from Selection.js
   */
  pickLists: PropTypes.instanceOf(Array).isRequired,
  /**
   * It Removes a given filterLine
   * It is passed from Selection.js
   */
  handleRemoveFilterLine: PropTypes.func,
  /**
   * Indicates whether timezone settings are applied to all date filters
   */
  applyTimezoneSettingsToAllDateFields: PropTypes.bool,
  /**
   * Handles the setting of timezone settings to all date filters
   */
  handleSetTimezoneToAllDateFields: PropTypes.func,
  /**
   * An object containing timezone details
   */
  timezoneSettingsForAllDateFields: PropTypes.instanceOf(Object),
};

export default DynamicValuesCriteriaModal;
