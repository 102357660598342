import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { NavBar } from '@DESelectMC/deselect-component-library';

import Constants from '../../../constants/constants';
import {
  createWaterfallCopy,
  fetchSelectionRunStatus,
  handleRun, setRunningQueryStatus,
  setSelectedView, setSelectionName,
  showScheduledSelectionModal,
} from
  '../../../redux/actions/waterfallSelection/globalActions';
import store from '../../../redux/store/store';
import CriteriaNavigation from '../../Selection/Navbar/CriteriaNavigation/CriteriaNavigation';
import SelectionName from '../../Selection/Navbar/SelectionName/SelectionName';
import Button from '../../shared_v2/Button/Button';
import './styles.scss';
import { setSelectionsForRunChain }
  from '../../../redux/actions/waterfallSelection/selectionActions';
import Util from '../../../util';
import waterfallSelectionUtil from '../../../utils/waterfallSelection/waterfallSelectionUtil';
import SwalUtil from '../../../utils/swal/swalUtil';
import { ReactComponent as HomeIcon } from '../../../icons_v2/home-icon.svg';

const NavigationBar = ({
  waterfallFolders,
  handleSetAppState,
  folderId,
  currentSelectionId,
  handleNavigator,
  axiosCancelToken,
  showSaveToast,
  disableScheduleButton,
  savingSelection,
  runClicked,
  setRunClicked,
  handleSelectionSave,
  isArchived,
}) => {
  const dispatch = useDispatch();

  // get state of properties from selectionSteps reducer
  const {
    selectionName, selectedSelections, selectedView, waterfallCopy, runningQuery,
    loadingTargetDEObjectIDs, loadingSelectionIds, scheduledRun, waterfallSelectionStatus, userInfo, orgInfo,
    hasServer2ServerInstalledPackageInfo,
  } = useSelector(({
    globalReducer, selectionReducer, targetDataExtensionReducer, userInformationGlobalReducer,
  }) => ({
    selectionName: globalReducer.selectionName,
    selectedView: globalReducer.selectedView,
    selectedSelections: selectionReducer.selectedSelections,
    waterfallCopy: globalReducer.waterfallCopy,
    runningQuery: globalReducer.runningQuery,
    loadingTargetDEObjectIDs: targetDataExtensionReducer.loadingTargetDEObjectIDs,
    loadingSelectionIds: selectionReducer.loadingSelectionIds,
    runStatusForSelectionChain: globalReducer.runStatusForSelectionChain,
    selectionChain: selectionReducer.selectionChain,
    scheduledRun: globalReducer.scheduledRun,
    waterfallSelectionStatus: globalReducer.waterfallSelectionStatus,
    userInfo: userInformationGlobalReducer.userInformation[process.env.REACT_APP_SEGMENT__SESSION_USER_KEY],
    orgInfo: userInformationGlobalReducer.userInformation.org,
    hasServer2ServerInstalledPackageInfo: userInformationGlobalReducer.userInformation.hasServer2ServerInstalledPackage,
  }), shallowEqual);

  const hasServer2ServerInstalledPackage = Util.checkIsEnabledCookies(hasServer2ServerInstalledPackageInfo);
  const scheduleDisableReason = hasServer2ServerInstalledPackage ? '' : Constants.S2S_NOT_INSTALLED;
  const { enabled: enabledScheduleSelection } = scheduledRun;

  /**
   * Function that handles click on the Overview button
   * @param {object} copyState - object with saved copy state
   * @returns {void}
   */
  const handleOverviewClick = async (copyState) => {
    // get the current state
    const currentState = store.getState();

    // current state without some property
    const currentStateWithoutProperties = Util.returnStateWithoutSomeProperties(currentState);

    // saved copy of the state without some property
    const copyStateWithoutProperties = Util.returnStateWithoutSomeProperties(copyState);

    // compare copy with current state
    const stateHasNotChanged = _.isEqual(currentStateWithoutProperties, copyStateWithoutProperties);

    // if state has not changed
    if (stateHasNotChanged || isArchived) {
      handleNavigator(Constants.NAVIGATION__OVERVIEW);
      handleSetAppState({ overviewSection: Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS, folderId: '' });
    } else {
      // show warning of unsaved changes
      const res = await SwalUtil.fire({
        title: 'Confirmation needed',
        message: `Are you sure you want to go to the Waterfall Selections Overview?
        Unsaved changes will be lost.`,
        options: {
          confirmButtonText: 'Yes',
          showCancelButton: true,
          allowOutsideClick: false,
        },
      });

      // if pressed ok, go to overview screen
      if (res.value) {
        handleNavigator(Constants.NAVIGATION__OVERVIEW);
        handleSetAppState({ overviewSection: Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS, folderId: '' });
      }
    }
  };

  /**
   * Function that handles click on the Run button
   * @returns {void}
   */
  const handleSelectionRun = async () => {
    let runningSelections;

    setRunClicked(true);

    if (selectedSelections?.length) {
      // get the selections that is running in other selections
      runningSelections = selectedSelections.filter((selected) => {
        // search in all currently fetched selection
        const selectionData = selected.runStatus &&
          selected.runStatus !== Constants.STATUS_COMPLETE &&
          selected.runStatus !== Constants.STATUS_ERROR;

        if (selectionData) { return selected; }

        return null;
      });
    }

    // if at least one of selection is running and not queued for retry
    if (runningSelections?.length && waterfallSelectionStatus !== Constants.STATUS_RETRY) {
      // save selection first
      await handleSelectionSave(true);

      // then throw swal message
      await SwalUtil.fire({
        type: Constants.SWAL__TYPE__ERROR,
        // eslint-disable-next-line max-len
        message: `One of the selected selections: <span class="bold_swal">
        ${runningSelections.map(selection => ' ' + selection.name)} </span> is currently running.
        Your waterfall selection has been saved, so if all selections are complete,
        you can go back and click the Run button again.`,
        options: {
          confirmButtonText: 'OK',
          allowOutsideClick: false,
        },
      });

      dispatch(createWaterfallCopy());
    } else {
      // start running the query
      dispatch(setRunningQueryStatus(true));

      // wait for the selection to be saved and get selectionId from response
      const selectionIdFromResponse = await handleSelectionSave(true);

      if (selectionIdFromResponse) {
        // define selections for run chain, generate unique id
        const newSelectionsForRunChain = selectedSelections.map(selection => (
          {
            ...selection,
            _id: Util.uuid(32),
          }
        ));

        // set new selections for run chain
        dispatch(setSelectionsForRunChain(newSelectionsForRunChain));

        // run the selection
        await dispatch(handleRun(selectionIdFromResponse, axiosCancelToken.token));

        // get status of run
        await dispatch(fetchSelectionRunStatus(selectionIdFromResponse, axiosCancelToken.token));

        dispatch(createWaterfallCopy());
      } else {
        dispatch(setRunningQueryStatus(false));
      }
    }

    // set runClicked on false
    setRunClicked(false);
  };

  // define when waterfall selection is still running
  const isSelectionRunning = waterfallSelectionUtil.isWaterfallSelectionRunning(waterfallSelectionStatus, runningQuery);

  // run status for CriteriaNavigation
  const runStatus = isSelectionRunning ? Constants.STATUS_PROCESSING : waterfallSelectionStatus;

  let isTargetDELoading = false;

  if (selectedSelections?.length) {
    // defines whether target data extension of the latest added selection is loading
    isTargetDELoading = !!((loadingTargetDEObjectIDs?.length &&
      loadingTargetDEObjectIDs.find(ck => ck ===
        selectedSelections[selectedSelections.length - 1].targetCollectionObjectID)));
  }

  // ClassNames for schedule icon
  const classNameForScheduleSelectionIcon = classNames(
    'slds-icon',
    enabledScheduleSelection && !isTargetDELoading && !disableScheduleButton ?
      'schedule-active' :
      'schedule',
  );

  /**
   * Get navigation bar options
   * @returns {void}
   */
  const getNavBarOptions = () => {
    const engageStatus = userInfo?.engageInstallationStatus?.organisationExists;
    const searchStatus = userInfo?.searchInstallationStatus?.organisationExists;
    const stackNumber = orgInfo?.marketingCloudStackNumber;

    let search = Constants.DESELECT_SEARCH_STAGING_APP_NAME;

    let engage = Constants.DESELECT_ENGAGE_STAGING_APP_NAME;

    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      search = Constants.DESELECT_SEARCH_PRODUCTION_APP_NAME;
      engage = Constants.DESELECT_ENGAGE_PRODUCTION_APP_NAME;
    }

    if (process.env.REACT_APP_ENVIRONMENT === 'release') {
      search = Constants.DESELECT_SEARCH_RELEASE_APP_NAME;
      engage = Constants.DESELECT_ENGAGE_RELEASE_APP_NAME;
    }

    let engageLink = Constants.DESELECT_ENGAGE_APP_EXCHANGE_URL;

    let searchLink = Constants.DESELECT_SEARCH_APP_EXCHANGE_URL;

    if (engageStatus) {
      engageLink = `https://mc.${stackNumber}.exacttarget.com/cloud/#app/${engage}`;
    }

    if (searchStatus) {
      // eslint-disable-next-line max-len
      searchLink = `https://mc.${stackNumber}.exacttarget.com/cloud/#app/${search}`;
    }

    const options = [
      {
        id: 'Segment',
        name: 'Segment',
        url: '',
      },
      {
        id: 'Engage',
        name: 'Engage',
        url: engageLink,
      },
      {
        id: 'Search',
        name: 'Search',
        url: searchLink,
      },
    ];

    return options;
  };

  return (
    <div className="navigation_bar_waterfall_v2_header">
      <div className="navigation_bar_waterfall_v2_wrapper">
        <NavBar
          options={getNavBarOptions()}
          selected="Segment"
        />
        <div className="waterfall-selection-name">
          <Button
            onClick={() => handleOverviewClick(waterfallCopy)}
            disabled={savingSelection || runningQuery}
            buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
            title="Back to the Overview Page"
            className="selection-home-button-WF"
            buttonIcon
          >
            <HomeIcon />
            <span className="home-btn-text">Home</span>
          </Button>
          <SelectionName
            fromNewDesign
            folders={waterfallFolders}
            selectionName={selectionName}
            handleSetSelectionName={e => dispatch(setSelectionName(e.target.value))}
            handleSetAppState={handleSetAppState}
            folderId={folderId}
            currentSelectionId={currentSelectionId}
            isWaterfall
            axiosCancelToken={axiosCancelToken}
            isArchived={isArchived}
          />
        </div>
        <div style={{ display: 'none' }}>
          <CriteriaNavigation
            waterfallSelection
            selectedSelections={selectedSelections}
            selectionNavigator={selectedView}
            selectionNavigation={view => dispatch(setSelectedView(view))}
            previewStatus={runStatus}
            disableRunDetails={!!(loadingSelectionIds?.length || loadingTargetDEObjectIDs?.length)}
          />
        </div>
        <div className="buttons_container schedule-waterfall-button">
          <div className="schedule-btn-container btn-container">
            <Button
              id="open-schedule-waterfall"
              buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
              onClick={() => dispatch(showScheduledSelectionModal())}
              disabled={disableScheduleButton || isTargetDELoading || isArchived || !hasServer2ServerInstalledPackage}
              title={disableScheduleButton ?
                Constants.SCHEDULE_BUTTON_DISABLED__TITLE__ON_HOVER :
                scheduleDisableReason}
              className={classNames(
                {
                  'active-scheduled-WF-button': enabledScheduleSelection &&
                    !isTargetDELoading && !disableScheduleButton,
                },
                {
                  'disabled-scheduled-WF-button': disableScheduleButton ||
                    isTargetDELoading ||
                    isArchived ||
                    !hasServer2ServerInstalledPackage,
                },
              )}
              style={disableScheduleButton || isTargetDELoading || isArchived || !hasServer2ServerInstalledPackage ?
                { fill: 'gray' } :
                {}}
            >
              <span className="slds-icon_container" title="Schedule waterfall selection">
                <svg
                  className={classNameForScheduleSelectionIcon}
                  aria-hidden="true"
                  id="schedule-icon"
                >
                  <use xlinkHref="/assets/icons/standard-sprite/svg/symbols.svg#service_appointment_capacity_usage" />
                </svg>
                <span className="slds-assistive-text">Schedule Selection</span>
              </span>
            </Button>
          </div>
          <div style={{ display: 'none' }}>
            {/* Save and Run Buttons */}
            <Button
              buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
              titleInAction="Saving..."
              onClick={() => handleSelectionSave(false)}
              loadingClickedButton={savingSelection}
              disabled={showSaveToast || runningQuery || isArchived}
              id="save-waterfall-button"
            >
              Save
            </Button>
            {selectedView === Constants.NAVIGATION__RUN_DETAILS &&
              (
                <Button
                  buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
                  titleInAction="Running..."
                  loadingClickedButton={isSelectionRunning || runningQuery || runClicked}
                  onClick={handleSelectionRun}
                  disabled={isSelectionRunning || (!!((loadingTargetDEObjectIDs?.length ||
                    loadingSelectionIds?.length))) || savingSelection || runClicked || isArchived}
                  id="run-waterfall-button"
                >
                  <i className="fa fa-play" />
                  Run
                </Button>
              )}
            <Button
              buttonLook={Constants.BUTTON__TYPE__BRAND_DARK}
              onClick={() => handleOverviewClick(waterfallCopy)}
              disabled={savingSelection || runningQuery}
              id="cancel-waterfall-button"
            >
              <svg className="slds-button__icon" aria-hidden="true">
                <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#home" />
              </svg>
              <span>Home</span>
            </Button>
          </div>
        </div>
      </div>
      <hr className="waterfall-selection-name-hr" />
    </div>
  );
};

NavigationBar.propTypes = {
  /*
   * array with all folders for waterfall selections
   */
  waterfallFolders: PropTypes.instanceOf(Array).isRequired,
  /*
   * helps to set state in app.js
   */
  handleSetAppState: PropTypes.func.isRequired,
  /*
   * id of selected folder
   */
  folderId: PropTypes.string.isRequired,
  /*
   * the id of the currently open selection
   */
  currentSelectionId: PropTypes.string.isRequired,
  /*
   * it helps to navigate between Overview and Waterfall Selection
   */
  handleNavigator: PropTypes.func.isRequired,
  /**
   * It helps to cancel a subscription of an API call to backend
   */
  axiosCancelToken: PropTypes.instanceOf(Object),
  /*
   * Indicates whether a toast notification for the Save action occurs
   */
  showSaveToast: PropTypes.bool,
  /**
   * Indicates whether the schedule button is disabled or not
   */
  disableScheduleButton: PropTypes.bool,
  /**
   * Indicates whether the saving selection is executed
   */
  savingSelection: PropTypes.bool.isRequired,
  /**
   * Indicates whether the running selection is executed
   */
  runClicked: PropTypes.bool.isRequired,
  /**
   * Function for setting runClicked state
   */
  setRunClicked: PropTypes.func.isRequired,
  /**
   * Function for saving waterfall selection
   */
  handleSelectionSave: PropTypes.func.isRequired,
  /**
   * Indicates if the waterfall selection is archived or not
   */
  isArchived: PropTypes.bool.isRequired,
};

export default NavigationBar;
