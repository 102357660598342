/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-key */
import React from 'react';
import PropTypes from 'prop-types';
import { BreadCrumb } from '@salesforce/design-system-react';
import './styles.scss';
import classNames from 'classnames';

import Constants from '../../../constants/constants';

/**
 * Get the classes for the arrow-1 'li' elements
 * @param {string} selectionNavigator - Current page's navigation value
 * @param {array} selectedElements - selectedElements - could be SelectedDataExtensions of the Selection
 * or selected Selections
 * @returns {string} The correct classes for the arrow-1 'li' elements
 */
const arrow1ClassName = (selectionNavigator, selectedElements) => {
  // if we are on the Selection Criteria (selection) or Steps (waterfall) page
  if (selectionNavigator === Constants.NAVIGATION__SELECTION_CRITERIA ||
    selectionNavigator === Constants.NAVIGATION__STEPS) {
    if (selectedElements && selectedElements.length > 0) {
      return 'selection-navbar-saleted-name';
    }

    return 'selection-navbar-saleted-name';
  }
  if (selectedElements && selectedElements.length > 0) {
    return 'selection-navbar-name';
  }

  return 'selection-navbar-name';
};

/**
 * Get the classes for the arrow-2 'li' elements
 * @param {string} selectionNavigator - Current page's navigation value
 * @param {array} matchedFields - MatchedFields for a target data extension of the Selection
 * @returns {string} The correct classes for the arrow-2 'li' elements
 */
const arrow2ClassName = (selectionNavigator, matchedFields) => {
  if (selectionNavigator === Constants.NAVIGATION__TARGET_DEFINITION) {
    if (matchedFields && matchedFields.length > 0) return 'selection-navbar-saleted-name';

    return 'selection-navbar-saleted-name';
  }
  if (matchedFields && matchedFields.length > 0) return 'selection-navbar-name';

  return '';
};

/**
 * Get the classes for the arrow-3 'li' elements
 * @param {string} selectionNavigator - Current page's navigation value
 * @param {number} previewStatus - Status of the preview task (quued, completed etc)
 * @param {string} previewQueryActivityId - Preview query activity id for the selection if the preview is run before
 * @param {number} numberOfResults - Number of results for a Selection's query after a run
 * @param {boolean} editNewAutoTargetDE - Defines if new auto target DE is edited
 * @param {boolean} editTargetDataExtension - Defines if Edit Target DE fields mode is enabled
 * @param {boolean} disableRunDetails - Defines if button for Run Details view is disabled
 * @returns {string} The correct classes for the arrow-3 'li' elements
 */
const arrow3ClassName = (
  selectionNavigator,
  previewStatus,
  previewQueryActivityId,
  numberOfResults,
  editNewAutoTargetDE,
  editTargetDataExtension,
  disableRunDetails,
) => {
  if (disableRunDetails) {
    // disable button for Run Details in waterfall
    return 'slds-is-incomplete opacity-for-disable';
  }
  const completeClassNameWithoutError = `slds-is-complete
  ${editNewAutoTargetDE || editTargetDataExtension ?
    'slds-path__item' :
    'slds-is-incomplete'}
  selection-navigator-${selectionNavigator}`;

  if (selectionNavigator === Constants.NAVIGATION__RUN_DETAILS) {
    return 'selection-navbar-saleted-name';
  }

  // if we are on the Preview (selection)
  if (selectionNavigator === Constants.NAVIGATION__PREVIEW) {
    return 'selection-navbar-saleted-name';
  }

  // if we are not on the Preview page and preview is completed
  if (previewStatus !== Constants.STATUS_ERROR && previewStatus === Constants.STATUS_COMPLETE) {
    // then set className of the Preview tab btn to the certain value
    return completeClassNameWithoutError;
  }

  return `${editNewAutoTargetDE || editTargetDataExtension ?
    'slds-is-incomplete opacity-for-disable' :
    'slds-is-incomplete'}`;
};

const CriteriaNavigation = ({
  selectedDataExtensions,
  selectionNavigation,
  selectionNavigator,
  matchedFields,
  previewQueryActivityId,
  numberOfResults,
  previewStatus,
  editNewAutoTargetDE,
  editTargetDataExtension,
  waterfallSelection,
  selectedSelections,
  disableRunDetails,
}) => {
  /**
   * Handles click on the first arrow
   * @returns {void}
   */
  const onClickFirstArrow = () => waterfallSelection ?
    selectionNavigation(Constants.NAVIGATION__STEPS) :
    selectionNavigation(Constants.NAVIGATION__SELECTION_CRITERIA);

  const firstArrowTitle = 'Input';

  const thirdArrowTitle = waterfallSelection ?
    'Run Details' :
    'Preview';

  /**
   * Handles click on the third arrow
   * @returns {void}
   */
  const handleThirdArrowClick = () => {
    if (editNewAutoTargetDE || editTargetDataExtension || disableRunDetails) {
      return null;
    } if (waterfallSelection) {
      // for waterfall selection go to Run Details view
      selectionNavigation(Constants.NAVIGATION__RUN_DETAILS);
    } else {
      // for selection go to Preview
      selectionNavigation(Constants.NAVIGATION__PREVIEW);
    }

    return null;
  };

  return (
    <div className={classNames('selection_criteriaNavigation', { 'waterfall-navigation': waterfallSelection })}>
      <div>
        <BreadCrumb
          assistiveText={{ label: 'selection navbar' }}
          trail={[
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              role="presentation"
              onClick={onClickFirstArrow}
              id="arrow-1"
              className={`selection-navbar-name ${arrow1ClassName(
                selectionNavigator,
                selectedDataExtensions || selectedSelections,
              )}`}
            >
              <span>{firstArrowTitle}</span>
            </a>,
            (waterfallSelection ?
              null :
              (
                <a
                  role="presentation"
                  onClick={() => selectionNavigation(
                    Constants.NAVIGATION__TARGET_DEFINITION,
                  )}
                  id="arrow-2"
                  // eslint-disable-next-line max-len
                  className={`selection-navbar-name ${arrow2ClassName(selectionNavigator, matchedFields)}`}
                >
                  Output
                </a>
              )
            ),
            <a
              role="presentation"
              onClick={handleThirdArrowClick}
              id="arrow-3"
              className={`selection-navbar-name ${arrow3ClassName(
                selectionNavigator,
                previewStatus,
                previewQueryActivityId,
                numberOfResults,
                editNewAutoTargetDE,
                editTargetDataExtension,
                disableRunDetails,
              )}`}
              disabled={!!(editNewAutoTargetDE || editTargetDataExtension || disableRunDetails)}
            >
              {thirdArrowTitle}
            </a>,
          ]}
        />
      </div>

    </div>
  );
};

CriteriaNavigation.propTypes = {
  /*
   * This function helps to navigate between pages in a Selection
   * This prop will be passed from Selection.js through Navbar.js
   */
  selectionNavigation: PropTypes.func,
  /*
   * This prop keep the current page`s navigation value
   * This prop will be passed from Selection.js through Navbar.js
   */
  selectionNavigator: PropTypes.string.isRequired,
  /*
   * This prop keeps the selectedDataExtensions of the Selection
   * It will be passed from Selection.js through Navbar.js
   */
  selectedDataExtensions: PropTypes.instanceOf(Array),
  /*
   * It keeps the matchedFields for a target data extension of the Selection
   * It will be passed from Selection.js through Navbar.js
   */
  matchedFields: PropTypes.instanceOf(Array),
  /*
   * it keeps the previewQueryActivityId for the selection if the preview is run before
   * It will be passed from Selection.js through Navbar.js
   */
  previewQueryActivityId: PropTypes.string,
  /*
   * It keeps the number of results for a Selection`s query after a run
   * It will be passed from Selection.js through Navbar.js
   */
  numberOfResults: PropTypes.number,
  /*
   * It keeps the status of the preview task (quued, completed etc)
   * It will be passed from Selection.js through Navbar.js
   */
  previewStatus: PropTypes.number,
  /*
   * Defines if new auto target DE is edited
   */
  editNewAutoTargetDE: PropTypes.bool,
  /*
   * Defines if Edit Target DE fields mode is enabled
   */
  editTargetDataExtension: PropTypes.bool,
  /*
   * defines if a component is used in a waterfall selection
   */
  waterfallSelection: PropTypes.bool,
  /*
   * array with selected selections in waterfall selection
   */
  selectedSelections: PropTypes.instanceOf(Array),
  /*
   * defines if button for Run Details view is disabled
   */
  disableRunDetails: PropTypes.bool,
};
export default CriteriaNavigation;
