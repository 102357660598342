import Constants from '../../../constants/constants';
import {
  CLEAR_WATERFALL_STATE, CREATE_WATERFALL_COPY, SET_SELECTION_NAME,
  SET_WATERFALL_VIEW, SET_RUN_STATUS_FOR_SELECTION_CHAIN, SET_SELECTION_ID,
  IS_QUERY_RUNNING,
  LOADING_WATERFALL_SELECTION,
  SET_WATERFALL_SELECTION_STATUS,
  SET_WATERFALL_SELECTION_COMPLETED_DATE,
  SET_PAGE_ITEMS,
  CHANGE_PAGINATION_INDEX,
  SET_SEARCH_VALUE,
  SHOW_SCHEDULED_SELECTION_MODAL,
  HIDE_SCHEDULED_SELECTION_MODAL,
  SET_SCHEDULED_RUN_STATE,
  SET_SELECTION_REQUEST_DONE,
  SET_HAS_SCHEDULE_BEEN_ENABLED_STATE,
} from '../../actions/types';

const initialState = {
  selectionName: '',
  selectedView: Constants.NAVIGATION__STEPS,
  waterfallCopy: {},
  runStatusForSelectionChain: [],
  currentSelectionId: '',
  runningQuery: false,
  scheduledRun: {
    enabled: false,
    mode: Constants.SCHEDULE_SELECTION__MODE__ONCE,
    runOnce: {
      dateValue: null,
      timeValue: null,
    },
    runRepeat: {
      repeatMode: null,
      minutesValue: null,
      hoursValue: null,
      daysOfWeek: [],
      daysValue: null,
      nextScheduledRunDate: null,
      monthsValue: null,
    },
    timezone: null,
    UTCOffset: null,
  },
  loadingWaterfallSelection: false,
  waterfallSelectionStatus: null,
  waterfallSelectionCompletedDate: null,
  paginationIndex: 1,
  pageItems: [],
  searchValue: '',
  isSelectionRequestDone: false,
  hasScheduleBeenEnabled: false,
};

/**
 * Function that takes an action and the previous state of the application and returns the new state
 * @param {object} state - contain initial and final state of data
 * @param {object} action - return the action object
 * @returns {object} updated state
 */
const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTION_NAME:
      return {
        ...state,
        selectionName: action.payload,
      };
    case SET_SELECTION_REQUEST_DONE:
      return {
        ...state,
        isSelectionRequestDone: !state.isSelectionRequestDone,
      };
    case SET_SELECTION_ID:
      return {
        ...state,
        currentSelectionId: action.payload,
      };
    case SET_WATERFALL_VIEW:
      return {
        ...state,
        selectedView: action.payload,
      };
    case CREATE_WATERFALL_COPY:
      return {
        ...state,
        waterfallCopy: action.payload,
      };
    case CLEAR_WATERFALL_STATE:
      return { ...initialState };
    case SET_RUN_STATUS_FOR_SELECTION_CHAIN:
      return {
        ...state,
        runStatusForSelectionChain: action.payload,
      };
    case IS_QUERY_RUNNING:
      return {
        ...state,
        runningQuery: action.payload,
      };
    case LOADING_WATERFALL_SELECTION:
      return {
        ...state,
        loadingWaterfallSelection: action.payload,
      };
    case SET_WATERFALL_SELECTION_STATUS:
      return {
        ...state,
        waterfallSelectionStatus: action.payload,
      };
    case SET_WATERFALL_SELECTION_COMPLETED_DATE:
      return {
        ...state,
        waterfallSelectionCompletedDate: action.payload,
      };
    case SET_PAGE_ITEMS:
      return {
        ...state,
        pageItems: action.payload,
      };
    case CHANGE_PAGINATION_INDEX:
      return {
        ...state,
        paginationIndex: action.payload,
      };
    case SET_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.payload,
      };
    case SHOW_SCHEDULED_SELECTION_MODAL:
      return {
        ...state,
        showScheduleSelectionModal: true,
      };
    case HIDE_SCHEDULED_SELECTION_MODAL:
      return {
        ...state,
        showScheduleSelectionModal: false,
      };
    case SET_SCHEDULED_RUN_STATE:
      return {
        ...state,
        scheduledRun: action.payload,
      };
    case SET_HAS_SCHEDULE_BEEN_ENABLED_STATE:
      return {
        ...state,
        hasScheduleBeenEnabled: action.payload,
      };
    default:
      return state;
  }
};

export default globalReducer;
