import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

import Constants from '../../../../../constants/constants';
import Util from '../../../../../util';
import './styles.scss';

const RowNumberModal = ({
  selectedDataExtensions, rowNumberData, handleSetCustomValuesState, disabled, getDataExtensionOrDataViewFields,
  availableDataExtensionsWithExclusionSkipped,
}) => {
  // fields for selected data extension
  const [deFields, setDeFields] = useState([]);

  const handleSetDeFields = async (dataExtension) => {
    const dataExtensionFields = dataExtension?.fields ?
      dataExtension.fields :
      (await getDataExtensionOrDataViewFields(dataExtension));

    // set fields of selected data extension
    setDeFields(dataExtensionFields);
  };

  /**
   * Return the data extensions and add excluded DE that was selected before
   * @returns {void}
   */
  const getRequiredDataExtensions = () => {
    let selectedDataExtension =
      selectedDataExtensions?.find(
        de => de?.deAlias?.toString() === rowNumberData?.orderBy?.collectionAlias?.toString(),
      );

    if (selectedDataExtensions && !selectedDataExtension) {
      selectedDataExtension =
      availableDataExtensionsWithExclusionSkipped?.find(
        de => de.deAlias.toString() === rowNumberData?.orderBy.collectionAlias.toString(),
      );

      if (selectedDataExtension) {
        return [...(selectedDataExtensions || []), selectedDataExtension];
      }
    }

    return selectedDataExtensions;
  };

  useEffect(() => {
    // get selected DE if it's selected
    const selectedDataExtension = rowNumberData?.orderBy?.collectionAlias &&
    getRequiredDataExtensions()?.find(
      de => de.deAlias.toString() === rowNumberData?.orderBy.collectionAlias.toString(),
    );

    if (selectedDataExtension?.CustomerKey) handleSetDeFields(selectedDataExtension);
    // eslint-disable-next-line
  }, []);

  /**
   * Function to set collectionAlias in orderBy property
   * @param {object} e - event. Use e.value to get the value
   * @returns {void}
   */
  const handleSetSelectedDataExtension = (e) => {
    // find the selected data extension
    const dataExtension = selectedDataExtensions?.find(d => d.deAlias === e.value);

    // if data extension is selected
    if (dataExtension) {
      // set collectionAlias and clear previous selected field
      handleSetCustomValuesState({
        rowNumberData: { ...rowNumberData, orderBy: { collectionAlias: dataExtension.deAlias, fieldObjectID: '' } },
      });

      handleSetDeFields(dataExtension);
    }
  };

  /**
   * Function to set fieldObjectID in orderBy property
   * @param {object} e - event. Use e.value to get the value
   * @returns {void}
   */
  const handleSetSelectedField = (e) => {
    handleSetCustomValuesState({
      rowNumberData: {
        ...rowNumberData,
        orderBy: {
          ...rowNumberData?.orderBy,
          fieldObjectID: e.value,
        },
        fieldType: Constants.FILTERLINE__FIELDTYPE__NUMBER,
      },
    });
  };

  /**
   * Function to set order in sort property
   * @param {object} e - event. Use e.value to get the value
   * @returns {void}
   */
  const handleSetOrder = (e) => {
    handleSetCustomValuesState({ rowNumberData: { ...rowNumberData, sort: e.value } });
  };

  // formatted options for data extension dropdown
  const optionsForDataExtensionDropdown = Util.formattedDataForTheDropdown(
    getRequiredDataExtensions(),
    'deAlias',
    'deAlias',
  );

  // formatted options for field dropdown
  const optionsForFieldDropdown = Util.formattedDataForTheDropdown(deFields, 'ObjectID', 'Name', 'FieldType');

  // sort optionsForFieldDropdown alphabetically
  if (optionsForFieldDropdown?.length) {
    Util.sortArrayOfObjects(optionsForFieldDropdown, 'text');
  }

  // sort options for order dropdown
  const sortOptions = [Constants.SORT_DIRECTION__ASCENDING, Constants.SORT_DIRECTION__DESCENDING];

  // formatted options for order dropdown
  const optionsForOrderDropdown = sortOptions.map(option => ({
    value: option === Constants.SORT_DIRECTION__ASCENDING ?
      Constants.PRIO_DEDUP__SORT_ORDER__ASC :
      Constants.PRIO_DEDUP__SORT_ORDER__DESC,
    title: option[0].toUpperCase() + option.substring(1),
    text: option[0].toUpperCase() + option.substring(1),
    key: option,
  }));

  /**
   * Function to show the description of the data set
   * @returns {object} HTML for the configuration description
   */
  const showConfigurationDescription = () => {
    // label for sort order
    const orderText = rowNumberData?.sort === Constants.PRIO_DEDUP__SORT_ORDER__ASC ?
      Constants.CUSTOM_VALUES__SORT_ORDER_LABEL__LOWEST :
      Constants.CUSTOM_VALUES__SORT_ORDER_LABEL__HIGHEST;

    // get the field name
    const fieldName = deFields?.length &&
      deFields.find(field => field.ObjectID === rowNumberData?.orderBy.fieldObjectID)?.Name;

    return (
      <>
        <span>
          The record with the
          {' '}
          <strong>{orderText}</strong>
          {' '}
          value for field
          {' '}
          <strong>
            {fieldName}
          </strong>
          {' '}
          will get row number 1.
        </span>
        {' '}
        <br />
        <span>
          The record with the 2nd
          {' '}
          <strong>{orderText}</strong>
          {' '}
          value for field
          <strong>
            {' '}
            {fieldName}
          </strong>
          {' '}
          will get row number 2,...
        </span>
      </>
    );
  };

  return (
    <div className="row-number-wrapper">
      <h2 className="row-number-wrapper__title">
        {Constants.CUSTOM_VALUES__HEADER__ROW_NUMBER}
      </h2>
      <p className="row-number-wrapper__description">
        {Constants.CUSTOM_VALUES__TEXT_ROW_NUMBER}
      </p>
      <div className="dropdown-container">
        <div className="item-label">Data Extension</div>
        <div className="dropdown-field data_extension">
          <Dropdown
            id="selected-data-extension-dropdown"
            selection
            className="target-data-extension-dropdown searchable-dropdown"
            search
            placeholder="Choose data extension"
            options={optionsForDataExtensionDropdown}
            loading
            value={rowNumberData?.orderBy?.collectionAlias}
            onChange={(e, data) => handleSetSelectedDataExtension(data)}
            disabled={!selectedDataExtensions?.length || disabled}
            lazyLoad
          />
        </div>
      </div>
      <div className="dropdown-container field">
        <div className="item-label">Field</div>
        <div className="dropdown-field data_extension_field">
          <Dropdown
            id="field-dropdown"
            selection
            className="target-data-extension-dropdown searchable-dropdown"
            search
            placeholder="Choose field"
            options={optionsForFieldDropdown}
            loading
            value={rowNumberData?.orderBy?.fieldObjectID}
            onChange={(e, data) => handleSetSelectedField(data)}
            disabled={!deFields?.length || disabled}
            lazyLoad
          />
        </div>
      </div>
      <div className="dropdown-container order">
        <div className="item-label">Order</div>
        <div className="dropdown-field">
          <Dropdown
            id="order-dropdown"
            selection
            className="target-data-extension-dropdown searchable-dropdown"
            options={optionsForOrderDropdown}
            loading
            value={rowNumberData?.sort}
            onChange={(e, data) => handleSetOrder(data)}
            lazyLoad
            disabled={disabled}
          />
        </div>
      </div>
      <div className="configuration-description">
        {rowNumberData?.orderBy?.fieldObjectID && rowNumberData?.sort ?
          showConfigurationDescription() :
          null}
      </div>
    </div>
  );
};

RowNumberModal.propTypes = {
  /**
   * It keeps the selected data extensions for Selection.js
   * selected data extensions are stored as collections in database
   * It will be passed from Selection.js
   */
  selectedDataExtensions: PropTypes.instanceOf(Array),
  /**
   * Keeps all information of the row number custom value
   */
  rowNumberData: PropTypes.instanceOf(Object),
  /**
   * Function to set the state of the CustomValues component
   */
  handleSetCustomValuesState: PropTypes.func.isRequired,
  /**
   * Function used to fetch DE's fields when needed
   */
  getDataExtensionOrDataViewFields: PropTypes.func,
  /**
   * Determines whether all input fields should be editable or not by the user
   */
  disabled: PropTypes.bool,
  /**
   * List of data extensions with excluded ones
   */
  availableDataExtensionsWithExclusionSkipped: PropTypes.instanceOf(Array),
};

export default RowNumberModal;
