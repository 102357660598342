import Constants from '../../../../../constants/constants';
import SwalUtil from '../../../../../utils/swal/swalUtil';

const RowNumberUtil = {
  changeAliases: (values, oldName, newName) => values?.forEach(value => (
    RowNumberUtil.changeAlias(value, oldName, newName)
  )),

  changeAlias: (value, oldName, newName) => {
    if (value?.orderBy?.collectionAlias?.toLowerCase() === oldName?.toLowerCase()) {
      // eslint-disable-next-line no-param-reassign
      value.orderBy.collectionAlias = newName;
    }
  },

  isValid: (customValue) => {
    if (!customValue?.orderBy?.collectionAlias) {
      SwalUtil.fire({
        type: Constants.SWAL__TYPE__ERROR,
        title: 'Value is missing',
        message: 'Please select Data Extension.',
        htmlClass: ' ',
      });

      return false;
    }

    // check if field is selected
    if (!customValue?.orderBy?.fieldObjectID) {
      SwalUtil.fire({
        type: Constants.SWAL__TYPE__ERROR,
        title: 'Value is missing',
        message: 'Please select a Field.',
        htmlClass: ' ',
      });

      return false;
    }

    return true;
  },
};

export default RowNumberUtil;
