import Constants from '../../../constants/constants';

const GlobalCustomValuesUtil = {
  /**
   * function helps to check if the same relation appears in aggregation data as in predefined relation
   * @param {array} relations - array with predefined relations
   * @param {object} aggregationData - data from aggregation custom value
   * @returns {object} - return object with predefined relation if the conditions are met
   */
  getSelectedRelation: (relations, aggregationData) => (
    relations?.find(relation => (
      aggregationData?.dataExtensionCustomerKey === relation.toDECustomerKey &&
      aggregationData?.relations?.toCollectionAlias === relation.fromDeAlias &&
      aggregationData?.relations?.toFieldObjectId === relation.fromFieldObjectId &&
      aggregationData?.relations?.fromFieldObjectId === relation.toFieldObjectId &&
      aggregationData?.relations?.fromCollectionObjectId === relation.toDEObjectId &&
      aggregationData?.relations?.fromCollectionCustomerKey === relation.toDECustomerKey
    ))
  ),

  /**
   * Creates relation array to be used in the FROM clause of the Free Formula validation.
   * @param {Array} selectedDataExtensions - Array of Selected Data Extensions
   * @returns {Array} Relations array built.
   */
  buildFakeRelations: (selectedDataExtensions) => {
    /**
     * Connect all Data Extensions with the first one used.
     * If there is only one Data Extension selected no need to create relations.
     */
    const defaultRelations = !selectedDataExtensions?.length || selectedDataExtensions?.length === 1 ?
      [] :
      new Array(selectedDataExtensions?.length).fill({
        joinType: Constants.RELATIONTYPE__INNER,
        fromCollection: {
          fromFieldObjectID: selectedDataExtensions[0].fields[0].ObjectID,
          fromField: selectedDataExtensions[0].fields[0].Name,
          Name: selectedDataExtensions[0].Name,
          deAlias: selectedDataExtensions[0].Name,
          CustomerKey: selectedDataExtensions[0].CustomerKey,
        },
      });

    /**
     * Add the information for the rest Data Extensions.
     */
    const relations = defaultRelations?.map((relation, index) => ({
      ...relation,
      toCollection: {
        toFieldObjectID: selectedDataExtensions[index].fields[0].ObjectID,
        toField: selectedDataExtensions[index].fields[0].Name,
        Name: selectedDataExtensions[index].Name,
        deAlias: selectedDataExtensions[index].Name,
        CustomerKey: selectedDataExtensions[index].CustomerKey,
      },
    }));

    // First relation is connecting first Data Extension with itself, remove it
    relations.shift();

    return relations;
  },

  /**
   * Based on the selected type and (optionally) formula type returns the Data Extensions
   *  details used in the Shared Custom Value.
   * @param {String} type - Custom Value type.
   * @param {String} formulaType - If Custom Value is a formula, then the formula type - optional.
   * @param {Object} value - The value of the Custom Value.
   * @param {Array} selectedDataExtensions - Data Extensions used in the custom value - optional
   * @param {Array} availableDataExtensions - All available Data Extensions
   * @returns {Array} Array of objects containing the ObjectID and CustomerKey of each Data Extension
   */
  getSelectedDataExtensionsForCustomValue: ({
    type,
    formulaType,
    value,
    selectedDataExtensions,
    availableDataExtensions,
  }) => {
    switch (type) {
      case Constants.CUSTOM_VALUES__FIELD_TYPE__DYNAMIC_VALUE:
        return selectedDataExtensions?.map(selectedDataExtension => ({
          ObjectID: selectedDataExtension?.ObjectID,
          CustomerKey: selectedDataExtension?.CustomerKey,
          Name: selectedDataExtension?.Name,
        }));

      case Constants.CUSTOM_VALUES__FIELD_TYPE__FORMULA:
        switch (formulaType) {
          case Constants.CUSTOM_VALUES__FORMULA_TYPE__TRANSFORM:
            return [{
              ObjectID: value.dataExtensionObjectId,
              CustomerKey: value.dataExtensionCustomerKey,
              Name: value.collectionAlias,
            }];

          case Constants.CUSTOM_VALUES__FORMULA_TYPE__DATE_DIFFERENCE:
            // eslint-disable-next-line no-case-declarations
            const dataExtensions = [];

            if (value?.field1?.format === 'field') {
              dataExtensions.push({
                ObjectID: value.field1.dataExtensionObjectId,
                CustomerKey: value.field1.dataExtensionCustomerKey,
                Name: value.field1.collectionAlias,
              });
            }

            if (value?.field2?.format === 'field') {
              dataExtensions.push({
                ObjectID: value.field2.dataExtensionObjectId,
                CustomerKey: value.field2.dataExtensionCustomerKey,
                Name: value.field2.collectionAlias,
              });
            }

            return dataExtensions;

          case Constants.CUSTOM_VALUES__FORMULA_TYPE__ROW_NUMBER:
            // eslint-disable-next-line no-case-declarations
            const selectedDataExtension = availableDataExtensions?.find(de => de.Name === value.collectionAlias);

            return [{
              ObjectID: selectedDataExtension?.ObjectID,
              CustomerKey: selectedDataExtension?.CustomerKey,
              Name: selectedDataExtension?.Name,
            }];

          case Constants.CUSTOM_VALUES__FORMULA_TYPE__FREE_FORMULA:
            return selectedDataExtensions?.map(selectedDataExtension => ({
              ObjectID: selectedDataExtension?.ObjectID,
              CustomerKey: selectedDataExtension?.CustomerKey,
              Name: selectedDataExtension?.Name,
            }));

          default:
            return [];
        }

      case Constants.CUSTOM_VALUES__FIELD_TYPE__AGGREGATION_VALUE:
        // eslint-disable-next-line no-case-declarations
        const selectedDataExtension =
          availableDataExtensions?.find(de => de.Name === value.relations.toCollectionAlias);

        return [{
          ObjectID: selectedDataExtension?.ObjectID,
          CustomerKey: selectedDataExtension?.CustomerKey,
          Name: selectedDataExtension?.Name,
        }];

      default:
        return [];
    }
  },
};

export default GlobalCustomValuesUtil;
