import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import Util from '../../../../../../util';
import Table from '../../../../../../icons_v2/table_simple.svg';

const DroplineTargetDEInfo = ({ targetDEName }) => {
  const [abbreviatedName, setAbbreviatedName] = useState(targetDEName);
  const pRef = useRef(null);

  useEffect(() => {
    const updateAbbreviation = () => {
      if (pRef.current) {
        const availableWidth = pRef.current.offsetWidth;
        const charWidth = 8; // Approximate width of a character in pixels
        const maxChars = Math.floor(availableWidth / charWidth);
        const abbreviationLength = Math.max(25, maxChars);

        setAbbreviatedName(Util.abbreviate(targetDEName, abbreviationLength));
      }
    };

    const resizeObserver = new ResizeObserver(updateAbbreviation);
    const currentPRef = pRef.current;

    if (currentPRef) {
      resizeObserver.observe(currentPRef);
    }

    updateAbbreviation(); // Initial calculation

    return () => {
      if (currentPRef) {
        resizeObserver.unobserve(currentPRef);
      }
    };
  }, [targetDEName]);

  return (
    <>
      <img src={Table} alt="Table icon" />
      <p ref={pRef} title={targetDEName}>
        {' '}
        {abbreviatedName}
      </p>
    </>
  );
};

DroplineTargetDEInfo.propTypes = {
  /*
   * defines the name of target data extension
   */
  targetDEName: PropTypes.string.isRequired,
};

export default DroplineTargetDEInfo;
