import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { setAllFeatureAdverts } from '../../../redux/actions/featureAdverts/globalActions';
import FeatureModal from '../../shared/Feature/FeatureModal';

function FeatureAdvertContainer({ feature, showFeatureModal, handleCancel }) {
  const dispatch = useDispatch();

  const fetchFeatureAdverts = async () => {
    dispatch(setAllFeatureAdverts());
  };

  useEffect(() => {
    fetchFeatureAdverts();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {
        showFeatureModal && (
          <FeatureModal
            feature={feature}
            handleCancel={handleCancel}
          />
        )
      }
    </div>
  );
}

FeatureAdvertContainer.propTypes = {
  /**
   * The feature to be displayed
   */
  feature: PropTypes.string.isRequired,
  /**
   * Boolean to show or hide the feature modal
   */
  showFeatureModal: PropTypes.bool.isRequired,
  /**
   * Function to handle cancel button click
   */
  handleCancel: PropTypes.func.isRequired,
};

export default FeatureAdvertContainer;
