import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

class SelectionFolderPopup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openSelectionFolderChild: false,
      changeIcon: false,
    };
  }

  /**
   * Open children if parent has them
   * @param {object} folder - current folder in which we are
   * @returns {void}
   */
  openFolderChildren = async (folder) => {
    const { onOpenChildren } = this.props;

    if (onOpenChildren) {
      onOpenChildren(folder);

      return;
    }

    const { openSelectionFolderChild, changeIcon } = this.state;
    const {
      handleSetSelectionNameState, handleSetAppState, fetchSelectionsInFolder,
    } = this.props;
    const transitionPopup = '.popupFolder > ul:nth-child(1)';

    await fetchSelectionsInFolder(folder._id);

    // Set the transition styles to go forward
    if (document.querySelector(transitionPopup)) {
      document.querySelector(transitionPopup).style.transitionDuration = '0s';
      document.querySelector(transitionPopup).style.transform = 'translate(100%,0)';
    }

    this.setState({
      openSelectionFolderChild: !openSelectionFolderChild,
      changeIcon: !changeIcon,
    });
    handleSetSelectionNameState({
      folderName: folder.name,
      foldersState: folder.children && folder.children.length > 0 ? folder.children : [],
    });
    handleSetAppState({ folderId: folder._id });

    // Styles for the transition with delay to create the "going forward" effect
    if (document.querySelector(transitionPopup)) {
      setTimeout(() => {
        document.querySelector(transitionPopup).style.transitionDuration = '.2s';
        document.querySelector(transitionPopup).style.transform = 'translate(0,0)';
      }, 200);
    }
  };

  render() {
    const { openSelectionFolderChild } = this.state;
    const {
      folders,
      handleSetAppState,
      folderId,
      selections,
      handleSetSelectionNameState,
    } = this.props;

    return (
      <div className="popupFolder slds-scrollable_y">
        <ul>
          {folders && folders.map(folder => (
            <li
              key={folder._id}
              className={`${folderId === folder._id && 'marked'}`}
              title={folder.name}
            >
              <span
                data-id={folder._id}
                className="popupLine"
                onClick={() => {
                  handleSetAppState({ folderId: folder._id });
                  handleSetSelectionNameState({ selectedFromPreview: true });
                }}
                onDoubleClick={async () => {
                  await this.openFolderChildren(folder);
                }}
              >
                <i
                  className="fas fa-folder"
                  onClick={() => {
                    handleSetAppState({ folderId: folder._id });
                    handleSetSelectionNameState({ selectedFromPreview: true });
                  }}
                  onDoubleClick={async () => {
                    await this.openFolderChildren(folder);
                  }}
                />
                <div className="slds-truncate">
                  <span
                    id="folder-name-popup"
                    onClick={() => {
                      handleSetAppState({ folderId: folder._id });
                      handleSetSelectionNameState({ selectedFromPreview: true });
                    }}
                    onDoubleClick={async () => {
                      await this.openFolderChildren(folder);
                    }}
                  >
                    {folder.name}
                  </span>
                </div>
                <span
                  className="arrowPopupFolder slds-icon_container slds-icon-utility-announcement"
                  onClick={() => this.openFolderChildren(folder)}
                  title={`Go to ${folder.name}`}
                >
                  <svg className="slds-icon slds-icon-text-default slds-icon_x-small" aria-hidden="true">
                    <use
                      style={{ fill: !openSelectionFolderChild && '#000' }}
                      xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#chevronright"
                    />
                  </svg>
                </span>
              </span>
            </li>
          ))}
          {selections && selections.map(selection => (
            <li
              key={selection._id}
              className="selections-in-folder slds-truncate"
              title={selection.name}
            >
              <span
                data-id={selection._id}
              >
                <span className="slds-icon_container slds-icon-doctype-xml" title={selection.name}>
                  <svg className="slds-icon" style={{ height: '19px' }} aria-hidden="true">
                    <use xlinkHref="/assets/icons/doctype-sprite/svg/symbols.svg#unknown" />
                  </svg>
                  <span className="slds-assistive-text">{selection.name}</span>
                </span>
                {selection.name}
              </span>
            </li>
          ))}
          {selections && selections.length === 0 && folders && folders.length === 0 ?
            (
              <div className="empty-folder">This folder is empty</div>
            ) :
            null}
        </ul>
      </div>
    );
  }
}

SelectionFolderPopup.propTypes = {
  /**
   * Folders for selection save
   */
  folders: PropTypes.instanceOf(Object).isRequired,
  /**
   * it sets the App component`s state
   * This prop will be passed from App.js component through Selection.js
   */
  handleSetAppState: PropTypes.func.isRequired,
  /**
   * It gives the id of folder
   */
  folderId: PropTypes.string.isRequired,
  /*
   * It helps to set a state of the SelectionName component
   * which is parent of this component
   */
  handleSetSelectionNameState: PropTypes.func.isRequired,
  /*
   * Fires on open children event
   */
  onOpenChildren: PropTypes.func,
  /**
   * Function for fetching selections in folder
   */
  fetchSelectionsInFolder: PropTypes.func.isRequired,
  /**
   * Selections which are kept in the db
   */
  selections: PropTypes.instanceOf(Array).isRequired,
};

export default SelectionFolderPopup;
