const DateDifferenceUtil = {
  changeAliases: (values, oldName, newName) => values?.forEach(value => (
    DateDifferenceUtil.changeAlias(value, oldName, newName)
  )),

  changeAlias: (value, oldName, newName) => {
    if (value.field1.collectionAlias?.toLowerCase() === oldName?.toLowerCase()) {
      // eslint-disable-next-line no-param-reassign
      value.field1.collectionAlias = newName;
    }

    if (value.field2.collectionAlias?.toLowerCase() === oldName?.toLowerCase()) {
      // eslint-disable-next-line no-param-reassign
      value.field2.collectionAlias = newName;
    }
  },

  isValid: customValue => !(
    (!customValue?.field1?.fieldObjectId && customValue?.field1?.format === 'field') ||
    (!customValue?.field2?.fieldObjectId && customValue?.field2?.format === 'field')
  ),
};

export default DateDifferenceUtil;
