/* eslint-disable spellcheck/spell-checker */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Button from '../Button/Button';
import './styles.scss';

const ProgressBar = ({
  progressItems,
  progressBarClassName,
  progressBarValueClassName,
  containerClassName,
  values,
  containerId,
  progressBarValueStyle,
}) => {
  const buttonClassName = className => classNames(
    className || 'slds-progress__marker slds-progress__marker_icon',
  );

  return (
    <div className={`slds-progress ${containerClassName || ''}`} id={containerId || ''}>
      <ol className="slds-progress__list">
        {progressItems.map(item => (
          item?.condition && (
            <li className={`slds-progress__item ${item.className || ''}`} key={item.name}>
              {item?.tooltip && (
                <div className={item?.tooltipClassName}>
                  <div
                    role="tooltip"
                    id={item?.tooltipId || ''}
                  >
                    <div className={classNames(
                      'slds-popover__body progress-bar-tooltip-text',
                      { completed: item.name === 'Completed' },
                    )}>
                      {item?.tooltipLabel}
                    </div>
                  </div>
                </div>
              )}
              <Button
                buttonIcon
                className={buttonClassName(item?.buttonClassName)}
                title={item.name}
                disabled={item.disabled}
                onClick={item.onClick}
              >
                {item.icon ?
                  (
                    <>
                      <svg className="slds-button__icon" aria-hidden="true">
                        <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#success" />
                      </svg>
                      <span className="slds-assistive-text">{item.name}</span>
                    </>
                  ) :
                  <span />}
              </Button>
            </li>
          )))}
      </ol>
      <div
        className={`slds-progress-bar slds-progress-bar_x-small ${progressBarClassName}`}
        aria-valuemin={values.min}
        aria-valuemax={values.max}
        aria-valuenow={values.now}
        role="progressbar"
      >
        <span
          className={`slds-progress-bar__value ${progressBarValueClassName}`}
          style={progressBarValueStyle}
        />
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  /**
   * An array with objects that are in the next steps of the progress bar
   */
  progressItems: PropTypes.instanceOf(Array).isRequired,
  /**
   * class name for the line of progress bar
   */
  progressBarClassName: PropTypes.string,
  /**
   * class name for the value of progress bar
   */
  progressBarValueClassName: PropTypes.string,
  /**
   * class name for the progress bar component
   */
  containerClassName: PropTypes.string,
  /**
   * id for the progress bar component
   */
  containerId: PropTypes.string,
  /**
   * An object with values for the progress bar
   */
  values: PropTypes.instanceOf(Object).isRequired,
  /**
   * An object with styles for the progress bar value
   */
  progressBarValueStyle: PropTypes.instanceOf(Object),

};

export default ProgressBar;
