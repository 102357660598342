import axios from 'axios';

import Util from '../util';

const apiUrl = process.env.REACT_APP_API_URL;

const ContactAttributesAPI = {
  /**
   * Get relations for current organization
   * @param {object} cancelToken - Token axios
   * @returns {object} Relations of current organization
   */
  fetchAttributeSets: async (cancelToken) => {
    const res = await Util.catch418And403Error(
      axios.get,
      `${apiUrl}/contact-builder/attributes`,
      Util.apiGetCallSettings(cancelToken),
    );

    return res.data.data;
  },

  /**
   * Get relations for current organization
   * @param {object} cancelToken - Token axios
   * @returns {object} Relations of current organization
   */
  getContactRelations: async (cancelToken) => {
    const res = await Util.catch418And403Error(
      axios.get,
      `${apiUrl}/contact-builder/relations`,
      Util.apiGetCallSettings(cancelToken),
    );

    return res.data.data;
  },

};

export default ContactAttributesAPI;
