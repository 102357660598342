import Constants from '../../../../../constants/constants';
import Util from '../../../../../util';
import filtersUtil from '../../../../../utils/filters/filtersUtil';
import SwalUtil from '../../../../../utils/swal/swalUtil';

const DynamicValueUtil = {
  changeAliases: (values, oldName, newName) => values?.forEach(value => (
    DynamicValueUtil.changeAlias(value, oldName, newName)
  )),

  changeAlias: (value, oldName, newName) => {
    value.criteria.forEach((criterion) => {
      // If then is truthy and it is an object
      if (criterion.then && typeof criterion.then === 'object') {
        if (criterion.then.dataExtensionAlias &&
          criterion.then.dataExtensionAlias.toLowerCase() === oldName.toLowerCase()) {
          // eslint-disable-next-line no-param-reassign
          criterion.then.dataExtensionAlias = newName;
        }
      }

      // Update alias in when filters
      const { filters } = criterion.when;

      // If filters exist
      if (filters && filters.length) {
        // eslint-disable-next-line no-param-reassign
        criterion.when.filters = filtersUtil.handleUpdateCollectionAliasInFilters(
          oldName,
          newName,
          filters,
        );
      }
    });

    // Edit defaultDataExtensionAlias
    if (value.defaultValue && value.defaultValue.defaultDataExtensionAlias) {
      if (value.defaultValue.defaultDataExtensionAlias.toLowerCase() === oldName.toLowerCase()) {
        // eslint-disable-next-line no-param-reassign
        value.defaultValue.defaultDataExtensionAlias = newName;
      }
    }
  },

  isValid: (customValue) => {
    if (!customValue.criteria?.length) {
      SwalUtil.fire({
        type: Constants.SWAL__TYPE__ERROR,
        title: 'Criteria is not defined',
        message: 'You need to have at least one criterion.',
      });

      return false;
    }

    // validation of email addresses
    if (customValue?.fieldType === Constants.FILTERLINE__FIELDTYPE__EMAILADDRESS) {
      const wrongFormatEmails = [];

      // Check for incorrect email formats in then statements
      customValue?.criteria.forEach((criterion) => {
        if (!Util.validateEmail(criterion.then) && criterion.then != null && criterion.then.length > 0) {
          wrongFormatEmails.push(criterion.then);
        }
      });

      // Check for incorrect email formats in default values
      if (
        !(customValue?.defaultValue?.defaultDataExtensionObjectId) &&
        !customValue?.defaultValue?.defaultValue === null
      ) {
        if (!Util.validateEmail(customValue?.defaultValue?.defaultValue)) {
          wrongFormatEmails.push(customValue?.defaultValue?.defaultValue);
        }
      }

      if (wrongFormatEmails.length > 0) {
        SwalUtil.fire({
          type: Constants.SWAL__TYPE__ERROR,
          title: 'Invalid Email Address',
          message: `The following email address${wrongFormatEmails.length > 1 ? 'es do not' : ' does not'}
          have the correct format: <p class="swal_bold">
          ${wrongFormatEmails?.join(', ')}</p>`,
          htmlClass: ' ',
        });

        return false;
      }
    }

    if (
      (
        customValue?.defaultValue?.defaultDataExtensionObjectId?.length &&
        !customValue?.defaultValue?.defaultFieldObjectId?.length
      ) ||
      !customValue?.criteria?.every(criterion => (
        criterion?.then?.dataExtensionObjectId && criterion?.then?.fieldObjectId
      ) ||
      !criterion?.then?.dataExtensionObjectId)
    ) {
      SwalUtil.fire({
        type: Constants.SWAL__TYPE__ERROR,
        title: 'No matching fields available.',
        message: 'One of the defined criteria does not have any matching fields with the selected field type.',
        htmlClass: ' ',
      });

      return false;
    }

    // If all then values and default value are null
    if (
      customValue?.defaultValue?.defaultValue === null &&
      customValue?.criteria?.every(criterion => criterion?.then === null)
    ) {
      SwalUtil.fire({
        type: Constants.SWAL__TYPE__ERROR,
        title: 'Make sure there is at least one value.',
        message: 'Make sure at least one of the generated values is not null.',
        htmlClass: ' ',
      });

      return false;
    }

    return true;
  },

  /**
   * Go through all the dynamic value filters and check if they use any field from the selected Data Extension
   * @param {Object} filter - Filter to traverse
   * @param {Object} dataExtension - Data Extension to search for
   * @param {index} index - Index of the custom value filter being searched
   * @returns {boolean} True if DE is used at least in one criteria, false otherwise.
   */
  traverseDynamicCustomValueFilters: (filter, dataExtension, index) => {
    // If it's a filter group
    if (filter?.filters && filter?.operator) {
      return filter.filters.some(childFilter => DynamicValueUtil.traverseDynamicCustomValueFilters(
        childFilter,
        dataExtension,
        index,
      ));
    }

    // If it's a filter line

    // Try to find DE
    const de = dataExtension?.fields?.find(field => field.ObjectID === filter?.fieldObjectID ||
      dataExtension?.ObjectID === filter?.comparedDataExtension?.ObjectID);

    // If de is found
    if (de) {
      SwalUtil.fire({
        type: Constants.SWAL__TYPE__ERROR,
        title: 'Data Extension can not be removed.',
        message: 'Make sure to remove all criteria using this Data Extension before removing it.',
        htmlClass: ' ',
      });

      return true;
    }

    return false;
  },

  /**
   * Go through all the dynamic value filters and check if they use any field from the selected Data Extension
   * @param {Object} dataExtension - Data Extension to search for
   * @param {Object} customValue - Dynamic value to be checked
   * @returns {boolean} True if DE is used at least in one criteria, false otherwise.
   */
  containsDataExtension: (dataExtension, customValue) => customValue?.criteria?.some(criteria => DynamicValueUtil
    .traverseDynamicCustomValueFilters(criteria.when, dataExtension, 0)),
};

export default DynamicValueUtil;
