import React from 'react';
import PropTypes from 'prop-types';

import Constants from '../../../../../../constants/constants';
import './styles.scss';
import CriteriaForRelationFilter from './CriteriaForRelationFilter';
import Select from '../../../../../shared_v2/Select/Select';

const classNames = require('classnames');

const RelationFilterLine = ({
  id,
  collectionAlias,
  formulaForRelationFilter,
  handleOnChangeFormulaFilterFormulaValue,
  handleUpdateFilterLineCriteria,
  handleUpdateFormulaFilterLineCriteria,
  criteria,
  handleOnChangeFormulaValue,
  value,
  subQuery,
  handleChangeFilterDataExtensionField,
  selectedFieldObjectID,
  predefinedRelation,
  isInResultsFormula,
  parentId,
  selectedActiveDE,
}) => {
  /**
   * filters the data extension fields and returns only those of type: number or decimal
   * @returns {array} - array with number and decimal type of fields
   */
  const fieldsForDropdown = () => {
    if (subQuery.dataExtensionFields?.length) {
      return subQuery.dataExtensionFields.filter(field => field.FieldType ===
        Constants.FILTERLINE__FIELDTYPE__NUMBER ||
        field.FieldType === Constants.FILTERLINE__FIELDTYPE__DECIMAL);
    }

    return [];
  };

  /**
   * displays options with number and decimal type of fields for DE
   * @returns {object} - options for dropdown depending on the fetched fields
   */
  const displayFetchedFields = () => (
    fieldsForDropdown()?.length ?
      fieldsForDropdown().map(field => ({
        value: field.ObjectID,
        label: field.Name.toString(),
      })) :
      []
  );

  /**
   * returns the name of the related DE (toDEName in predefined relation)
   * @returns {string} - toDEName if exists
   */
  const returnRelatedDEName = () => predefinedRelation?.toDEName?.toString() ||
    predefinedRelation?.Name?.toString() || '';

  /**
   * displays the criteria for the selected formula
   * @returns {object} - criteria depending on the selected formula
   */
  const displayCriteriaForOptions = () => {
    switch (formulaForRelationFilter) {
      case Constants.FORMULA__VALUE__COUNT:
        return (
          <>
            <CriteriaForRelationFilter
              handleUpdateFilterLineCriteria={handleUpdateFilterLineCriteria}
              handleUpdateFormulaFilterLineCriteria={handleUpdateFormulaFilterLineCriteria}
              id={id}
              parentId={parentId}
              criteria={criteria}
              handleOnChangeFormulaValue={handleOnChangeFormulaValue}
              value={value}
              formulaForRelationFilter={formulaForRelationFilter}
              dEName={collectionAlias}
              relatedDEName={returnRelatedDEName()}
              isInResultsFormula={isInResultsFormula}
              subQuery={subQuery}
            />
            <span className="relatedDEName-filterline" title={returnRelatedDEName()}>
              {returnRelatedDEName()}
            </span>
          </>
        );
      default:
        return (
          <>
            <Select
              className="filter-select filter-fields"
              value={selectedFieldObjectID || ''}
              disabled={!fieldsForDropdown()?.length}
              options={displayFetchedFields()}
              onChange={e => handleChangeFilterDataExtensionField(
                e.target.value,
                id,
                parentId,
                isInResultsFormula,
                subQuery,
              )}
            />

            <div className="span-container-without-count">
              <span>on</span>
              {' '}
              <span title={returnRelatedDEName()}>
                {returnRelatedDEName()}
              </span>
            </div>
            <CriteriaForRelationFilter
              handleUpdateFilterLineCriteria={handleUpdateFilterLineCriteria}
              handleUpdateFormulaFilterLineCriteria={handleUpdateFormulaFilterLineCriteria}
              id={id}
              parentId={parentId}
              criteria={criteria}
              handleOnChangeFormulaValue={handleOnChangeFormulaValue}
              value={value}
              formulaForRelationFilter={formulaForRelationFilter}
              dEName={collectionAlias}
              relatedDEName={returnRelatedDEName()}
              isInResultsFormula={isInResultsFormula}
              subQuery={subQuery}
            />
          </>
        );
    }
  };

  // class names
  const classNameForFilterLineCriteriaWithoutCount = classNames(
    'slds-form-element relation-filterline-criteria',
    {
      'slds-form-element relation-filterline-criteria without-count filter-fetched-fields':
        formulaForRelationFilter !== Constants.FORMULA__VALUE__COUNT,
    },
  );

  const classNameForRelationFilterLineFilterName = classNames(
    'slds-form-element__control relation-filter-line filter-line_filter-name',
    {
      // eslint-disable-next-line max-len
      'slds-form-element__control relation-filter-line__without-count filter-line_filter-name': formulaForRelationFilter !== Constants.FORMULA__VALUE__COUNT,
    },
  );

  // options for formula dropdown
  const formulaOptions = [
    {
      value: Constants.FORMULA__VALUE__COUNT,
      label: Constants.FORMULA__LABEL__COUNT,
    },
    {
      value: Constants.FORMULA__VALUE__AVERAGE,
      label: Constants.FORMULA__LABEL__AVERAGE,
    },
    {
      value: Constants.FORMULA__VALUE__SUM,
      label: Constants.FORMULA__LABEL__SUM,
    },
    {
      value: Constants.FORMULA__VALUE__MINIMUM,
      label: Constants.FORMULA__LABEL__MINIMUM,
    },
    {
      value: Constants.FORMULA__VALUE__MAXIMUM,
      label: Constants.FORMULA__LABEL__MAXIMUM,
    },
  ];

  return (
    <>
      <div className={classNameForRelationFilterLineFilterName}>
        <div className="slds-select_container">
          <Select
            className="filter-select filter-options"
            onChange={e => handleOnChangeFormulaFilterFormulaValue(
              id,
              e.target.value,
              null,
              isInResultsFormula,
              subQuery,
            )}
            value={formulaForRelationFilter || Constants.FORMULA__VALUE__COUNT}
            options={formulaOptions}
          />
        </div>
      </div>
      {formulaForRelationFilter === Constants.FORMULA__VALUE__COUNT ?
        (
          <div className="count-option filterline-container">
            <div className={classNameForFilterLineCriteriaWithoutCount}>
              <div className="collectionAlias-count-name">
                <span title={isInResultsFormula ? selectedActiveDE : collectionAlias}>
                  {isInResultsFormula ? selectedActiveDE : collectionAlias}
                </span>
                <span>
                  {' '}
                  has/have
                </span>
              </div>
              {displayCriteriaForOptions()}
            </div>
          </div>
        ) :
        (
          <>
            <span className="of-before-relation-field">of</span>
            <div className="without-count-filterline">
              <div className={classNameForFilterLineCriteriaWithoutCount}>
                {displayCriteriaForOptions()}
              </div>
            </div>
          </>
        )}

    </>
  );
};

RelationFilterLine.propTypes = {
  /**
   * it stores the id property of the filterlines
   */
  id: PropTypes.string.isRequired,
  /**
   * it stores the selected formula for the filterlines
   */
  formulaForRelationFilter: PropTypes.string.isRequired,
  /**
   * It keeps the data extension`s alias
   */
  collectionAlias: PropTypes.string.isRequired,
  /**
   * It helps to change the selected criteria for a filter
   * It will be passed from SelectedFilters.js
   */
  handleUpdateFilterLineCriteria: PropTypes.func.isRequired,
  /**
   * it stores the selected criteria for the filterlines
   */
  criteria: PropTypes.string.isRequired,
  /**
   * It helps to change the value for the filterlines
   * It will be passed from SelectedFilters.js
   */
  handleOnChangeFormulaValue: PropTypes.func.isRequired,
  /**
   * The value which is entered for the filter
   */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  /**
   * It helps to change the selected formula for a filter
   * It will be passed from SelectedFilters.js
   */
  handleOnChangeFormulaFilterFormulaValue: PropTypes.func.isRequired,
  /**
   * It keeps the subquery state for the relation filter
   */
  subQuery: PropTypes.instanceOf(Object).isRequired,
  /**
   * This function helps to change the selected field in the dropdown.
   * It will be passed from SelectedFilters.js
   */
  handleChangeFilterDataExtensionField: PropTypes.func.isRequired,
  /**
   * objectID of the selected field
   */
  selectedFieldObjectID: PropTypes.string,
  /**
   * It's predefined relation data for relation filter
   */
  predefinedRelation: PropTypes.instanceOf(Object).isRequired,
  /**
   * It helps to change the selected criteria of a formula in filter
   * It will be passed from SelectedFilters.js
   */
  handleUpdateFormulaFilterLineCriteria: PropTypes.func.isRequired,
  /**
   * Handles checking if passed filter is a subquery formula
   */
  isInResultsFormula: PropTypes.bool,
  /**
   * Gets the current id of the parent
   */

  parentId: PropTypes.string,

  selectedActiveDE: PropTypes.string,
};

export default RelationFilterLine;
