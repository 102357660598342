import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Constants from '../../../../constants/constants';
import './styles.scss';
import Util from '../../../../util';
import Button from '../../../shared_v2/Button/Button';
import SwalUtil from '../../../../utils/swal/swalUtil';
import DateDifferenceUtil from '../../TargetDefinition/CustomValues/DateDifferenceModal/dateDifferenceUtil';
import TransformDateUtil from '../../TargetDefinition/CustomValues/TransformDateModal/transformDateUtil';
import FreeFormulaUtil from '../../TargetDefinition/CustomValues/FreeFormulaModal/freeFormulaUtil';
import RowNumberUtil from '../../TargetDefinition/CustomValues/RowNumberModal/rowNumberUtil';
import AggregationValueUtil from '../../TargetDefinition/CustomValues/AggregationModal/aggregationValueUtil';
import DynamicValueUtil from '../../TargetDefinition/CustomValues/DynamicValueModal/dynamicValueUtil';

const AvailableDECard = ({
  Name,
  CustomerKey,
  dragged,
  deAlias,
  matchedFields,
  handleSetSelectionState,
  filtersRef,
  selectedDataExtensions,
  id,
  subCollections,
  edit,
  parentAlias,
  relationalModalId,
  handleDeleteSelectedDE,
  handleAliasPopUp,
  relations,
  DEBorderMouseOver,
  filterBorderMouseOver,
  customValues,
  parentId,
  showRelatedDEs,
  dataExtension,
  draggingDataSet,
  dataSets,
  parentDEOfDataSet,
  setSelectedDataExtensions,
  setModalDataExtensions,
  setShowRelationalModal,
  setFilterBorderMouseOver,
  setDEBorderMouseOver,
  disabled,
  handleExpandFiltersBox,
}) => {
  /**
   * joinType - it represents the relation type between 2 dataextensions (inner, left ...)
   * joinText - it represents the text explanation of the relation type
   */
  let joinType;

  let joinText;

  /**
   * Assign proper text value for relation type. Ex. 'X with/without matching Y'
   */
  if (relations && relations.length) {
    // Find current relation in the relations array
    const relation = relations.filter(rel => rel.relationalModalId === relationalModalId);
    // If relations exists

    if (relation && relation.length) {
      ([{ joinType }] = relation);

      switch (joinType) {
        case Constants.RELATIONTYPE__LEFT:
          joinText = Constants.RELATIONTYPE__JOIN_TEXT__WITH_WITHOUT;
          break;
        case Constants.RELATIONTYPE__RIGHT:
          joinText = Constants.RELATIONTYPE__JOIN_TEXT__WITH_WITHOUT;
          break;
        case Constants.RELATIONTYPE__FULL:
          joinText = Constants.RELATIONTYPE__JOIN_TEXT__WITH_ALL;
          break;
        case Constants.RELATIONTYPE__INNER:
          joinText = Constants.RELATIONTYPE__JOIN_TEXT__WITH;
          break;
        case Constants.RELATIONTYPE__CROSS:
          joinText = Constants.RELATIONTYPE__JOIN_TEXT__CROSS_JOIN;
          break;
        case Constants.RELATIONTYPE__LEFT_WITHOUT:
          joinText = Constants.RELATIONTYPE__JOIN_TEXT__WITHOUT;
          break;
        case Constants.RELATIONTYPE__RIGHT_WITHOUT:
          joinText = Constants.RELATIONTYPE__JOIN_TEXT__WITHOUT;
          break;
        default:
          break;
      }
    }
  }

  /**
   * On dragStart sets data you want to transfer
   * @param {object} event - Event.target
   * @param {string} name - Collection name
   * @param {string} customerKey - Customer key
   * @param {number} collId - Collection id
   * @returns {void}
   */
  const dragCollection = (event, name, customerKey, collId) => {
    event.stopPropagation();

    // set the draggingDataSet property if a data set is being dragged to the Selected DEs
    if (draggingDataSet) {
      event.dataTransfer.setData('draggingDataSet', draggingDataSet);
    }

    event.dataTransfer.setData('id', id);
    event.dataTransfer.setData('data-collection-customerkey', customerKey);
    event.dataTransfer.setData('data-collection-name', name);
    event.dataTransfer.setData('coll-id', collId);
    event.dataTransfer.setData('data-collection-alias', deAlias);
    event.dataTransfer.setData('dragged', dragged);
    event.dataTransfer.setData('parentAlias', parentAlias);
    event.dataTransfer.setData('parentId', parentId);
  };

  /**
   * On mouse click update the name of the selected data extension
   * @param {number} customerKey - customer key
   * @param {string} name - collection name
   * @param {string} collectionId - collection id
   * @returns {void}
   */
  const handleAliasChange = async (customerKey, name, collectionId) => {
    // Value to be changed, temporary alias with a name
    const alias = await handleAliasPopUp(name);

    if (alias.value && alias.value !== name) {
      // If there is already a DE with the same name - display error
      if (
        selectedDataExtensions.filter(de => de.deAlias === alias.value).length > 0
      ) {
        await SwalUtil.fire({
          type: Constants.SWAL__TYPE__ERROR,
          message: Constants.ERROR__ALIAS_NOT_UNIQUE,
        });

        handleAliasChange(customerKey, name, collectionId);

        return;
      }

      // Change deAlias on the data extension you are changing and assign it to a variable
      const selectedDataExtensionsResult = selectedDataExtensions.filter(
        (dataExtension) => {
          if (dataExtension.CustomerKey === customerKey && dataExtension.id === collectionId) {
            // eslint-disable-next-line no-param-reassign
            dataExtension.deAlias = alias.value;

            return true;
          }

          return false;
        },
      );

      // Update alias in different areas of application
      if (selectedDataExtensionsResult.length > 0) {
        // Update alias on matched fields
        matchedFields.forEach(
          (field) => {
            if (field.availableFieldDataExtensionAlias === name) {
              // eslint-disable-next-line no-param-reassign
              field.availableFieldDataExtensionAlias = alias.value;
            }
          },
        );

        // Update alias in custom values
        if (customValues) {
          // Update alias on freeFormula custom values
          const freeFormulaCVs = customValues.filter(
            cv => cv.formula === Constants.CUSTOM_VALUES__FORMULA_TYPE__FREE_FORMULA,
          );

          // Get aggregation custom values
          const aggregationCVs = customValues.filter(
            cv => cv.valueType === Constants.CUSTOM_VALUES__FIELD_TYPE__AGGREGATION_VALUE,
          );

          // Get dynamic custom values
          const dynamicCVs = customValues.filter(
            cv => cv.valueType === Constants.CUSTOM_VALUES__FIELD_TYPE__DYNAMIC_VALUE,
          );

          // get row number custom values
          const rowNumberCVs = customValues.filter(
            cv => cv.formula === Constants.CUSTOM_VALUES__FORMULA_TYPE__ROW_NUMBER,
          );

          // get dateDiff custom values
          const dateDifferenceCVs = customValues.filter(
            cv => cv.formula === Constants.CUSTOM_VALUES__FORMULA_TYPE__DATE_DIFFERENCE,
          );

          // get transformDate custom values
          const transformDateCVs = customValues.filter(
            cv => cv.formula === Constants.CUSTOM_VALUES__FORMULA_TYPE__TRANSFORM,
          );

          // update alias for date difference custom values
          if (dateDifferenceCVs.length) DateDifferenceUtil.changeAliases(dateDifferenceCVs, name, alias.value);

          // update alias for transform date custom values
          if (transformDateCVs.length) TransformDateUtil.changeAliases(transformDateCVs, name, alias.value);

          if (freeFormulaCVs.length) FreeFormulaUtil.changeAliases(freeFormulaCVs, name, alias.value);

          if (rowNumberCVs.length) RowNumberUtil.changeAliases(rowNumberCVs, name, alias.value);

          // Update alias in aggregation custom values
          if (aggregationCVs.length) AggregationValueUtil.changeAliases(aggregationCVs, name, alias.value);

          // Update alias on dynamic CVs
          if (dynamicCVs.length) DynamicValueUtil.changeAliases(dynamicCVs, name, alias.value);
        }

        /**
         * Update alias on subcollections, recursively
         * @param {Object[]} subCols - Sub collections
         * @returns {void}
         */
        const changeAliasOnSubcollections = (subCols) => {
          /* eslint-disable no-param-reassign */
          subCols.filter((sc) => {
            if (sc.CustomerKey === customerKey && sc.id === collectionId) {
              sc.deAlias = alias.value;

              return true;
            }
            if (sc.subCollections.length > 0) {
              changeAliasOnSubcollections(sc.subCollections);

              return true;
            }

            sc.subCollections.filter((subSc) => {
              if (subSc.CustomerKey === customerKey && subSc.id === collectionId) {
                subSc.deAlias = alias.value;

                return true;
              }

              return false;
            });

            return true;
          });
          /* eslint-enable no-param-reassign */
        };

        // Create a copy without a reference to the original var
        const subCollectionsCopy = selectedDataExtensions[0].subCollections.slice();

        changeAliasOnSubcollections(subCollectionsCopy);

        if (filtersRef) {
          // Update alias on filters
          filtersRef.current.handleUpdateCollectionAliasInFilters(
            name,
            alias.value,
          );
        }

        // Update fromCollection and toCollection alias
        relations.filter(
          (relation) => {
            if (relation.fromCollection.deAlias === name) {
              // eslint-disable-next-line no-param-reassign
              relation.fromCollection.deAlias = alias.value;

              return true;
            }

            if (relation.toCollection.deAlias === name) {
              // eslint-disable-next-line no-param-reassign
              relation.toCollection.deAlias = alias.value;

              return true;
            }

            return false;
          },
        );

        if (handleSetSelectionState) {
        // Update the state of Selection with a new alias
          handleSetSelectionState({ selectedDataExtensions });
        } else {
          setSelectedDataExtensions([...selectedDataExtensions]);
        }
      }
    }
  };

  /**
   * Edit button - on click, open relation modal and load the proper relation
   * @param {number} relationModalId - Id of the relation modal
   * @returns {void}
   */
  const handleOpenRelationModal = (relationModalId) => {
    /*
     * Filter out values like undefined and null
     * Filter the proper relation by id
     */
    const filteredRelations = relations.filter(Boolean).filter(
      relation => relation.relationalModalId === relationModalId,
    );

    // If relation exists, update the state in Selection with the proper relation, and display it
    if (filteredRelations.length) {
      const relation = filteredRelations[0];

      if (handleSetSelectionState) {
        handleSetSelectionState({
          modalDataExtensions: relation,
          showRelationalModal: true,
          prevRelations: relations,
        });
      } else {
        setModalDataExtensions(relation);
        setShowRelationalModal(true);
      }
    }
  };

  // class names for remove button (X)
  const classNamesForRemoveButton = classNames(
    'extension-remove-button',
    // eslint-disable-next-line quote-props
    { 'withoutPointer': DEBorderMouseOver || filterBorderMouseOver },
  );

  // class name for available data extension
  const availableDEDraggedCollectionClassName = classNames(
    'available-data-extension',
    dragged ? `${showRelatedDEs ? 'selected-de-card-data-set' : 'dragged-collection nohover'}` : 'drag-collection',
    { 'data-view-card': !dragged && Util.startsWithUnderScore(Name) },
    { disabled },
  );

  // class name for name of the DE
  const selectedDENameClassName = classNames(
    'selected-de-span',
    { parent_DE: subCollections?.length !== 0 },
  );

  /**
   * Used for the displaying borders where a Data Set could be dropped
   * @returns {boolean} true if the Name of the DE belongs to the DE that is the parent DE of the dragged Data Set
   */
  const styleDataSet = () => dragged && Name === parentDEOfDataSet;

  /**
   * Generates text for link title
   * @returns {string} - The link title
   */
  const generateRelationLinkTitle = () => {
    return joinType === Constants.RELATIONTYPE__RIGHT || joinType === Constants.RELATIONTYPE__RIGHT_WITHOUT ?
      `${deAlias.toString()} ${joinText} matching ${parentAlias.toString()} ` :
      `${parentAlias?.toString()} ${joinText} matching ${deAlias.toString()} `;
  };

  return (
    <div
      id={id}
      data-collection-customerkey={CustomerKey}
      data-collection-name={Name}
      data-collection-alias={dragged ? deAlias : ''}
      className={availableDEDraggedCollectionClassName}
      draggable
      disabled={disabled}
      onDragStart={(e) => {
        // do not allow dragging of a DE if we are in a data set modal
        if (showRelatedDEs || disabled) {
          e.preventDefault();

          return;
        }

        if (!disabled && draggingDataSet) {
          // find the parent data extension of the data set that is being dragged
          const parentDEOfDataSet = dataSets && dataSets.find(ds => ds.id === id).selectedDataExtensions[0].Name;

          if (handleSetSelectionState) {
            handleSetSelectionState({ filterBorderMouseOver: false, DEBorderMouseOver: true, parentDEOfDataSet });
          } else {
            setFilterBorderMouseOver(false);
            setDEBorderMouseOver(true);
          }

          dragCollection(e, Name, CustomerKey, id);
        }
      }}
      onDragEnd={() => {
        if (handleSetSelectionState) {
          handleSetSelectionState({ filterBorderMouseOver: false, DEBorderMouseOver: false, parentDEOfDataSet: '' });
        } else {
          setFilterBorderMouseOver(false);
          setDEBorderMouseOver(false);
        }
      }}
      style={{
        margin: dragged ? '.7rem' : '',
        pointerEvents: filterBorderMouseOver ? 'none' : '',
        border: (DEBorderMouseOver && dragged && !parentDEOfDataSet) ||
         (DEBorderMouseOver && parentDEOfDataSet && styleDataSet()) ?
          'var(--dashedBorder)' :
          '',
      }}
    >
      {/* X button */}
      {dragged && subCollections && subCollections.length === 0 ?
        (
          <Button
            className={classNamesForRemoveButton}
            noButtonClass
            onClick={() => handleDeleteSelectedDE(id, handleExpandFiltersBox)}
            title="Remove data extension"
            noSpan
          >
            <i
              className="far fa-times-circle remove-filter"
            />
          </Button>
        ) :
        null}
      {/* Name */}
      <span
        style={{ pointerEvents: DEBorderMouseOver || filterBorderMouseOver ? 'none' : '' }}
        className={selectedDENameClassName}
        onClick={dragged ? () => handleAliasChange(CustomerKey, deAlias, id) : null}
        title={dragged && deAlias !== undefined ? ` ${deAlias.toString()} (${Name.toString()})` : Name.toString()}
      >
        {dragged && deAlias !== undefined ?
          (
            <>
              <span>{deAlias}</span>
              {' '}
              <span>{`(${Name}`}</span>
            </>
          ) :
            <span className="no_alias">{Name}</span>}
        <span>{dragged && deAlias !== undefined ? ')' : '' }</span>

      </span>
      {showRelatedDEs && (
          <div
            className="relate-data-extension-button"
            onClick={() => showRelatedDEs(dataExtension)}
          >
            <button
              type="button"
              className="slds-button rightbar_admin-button">
              <div
                className="slds-icon_container"
                title="Relate another Data Extension">
                <svg
                  className="slds-icon slds-icon-text-default slds-icon_medium fill-white"
                  aria-hidden="true">
                  <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#add" />
                </svg>
                <span className="slds-assistive-text">
                  Relate another Data Extension
                </span>
              </div>
            </button>
          </div>
      )}
      {/* Edit button */}
      {edit ?
        (
          <div
            style={{ pointerEvents: DEBorderMouseOver || filterBorderMouseOver ? 'none' : '' }}
            className="edit-relation-link"
            title={generateRelationLinkTitle()}
          >
            {/* eslint-disable-next-line no-nested-ternary */}
            {joinType === Constants.RELATIONTYPE__RIGHT || joinType === Constants.RELATIONTYPE__RIGHT_WITHOUT ?
              `${Util.abbreviate(deAlias, 45)} ${joinText} matching ${Util.abbreviate(parentAlias, 45)}` :
              joinType === Constants.RELATIONTYPE__CROSS ?
                `${Constants.RELATIONTYPE__UPPERCASE_JOIN_TEXT__CROSS_JOIN_FROM}
                ${Util.abbreviate(parentAlias, 45)} ${Constants.RELATIONTYPE__UPPERCASE_JOIN_TEXT__CROSS_JOIN_TO}
                ${Util.abbreviate(deAlias, 45)}` :
                `${Util.abbreviate(parentAlias, 45)} ${joinText} matching ${Util.abbreviate(deAlias, 45)}`}

            <span
              className="slds-icon_container slds-icon-utility-announcement slds-current-color  dropEdit"
              id="edit-relation-link"
              onClick={() => handleOpenRelationModal(relationalModalId)}
              title="Edit relation"
              style={{ pointerEvents: DEBorderMouseOver || filterBorderMouseOver ? 'none' : '' }}
            >
              <svg
                className="slds-icon slds-icon_x-small dropEdit"
                aria-hidden="true"
                style={{ pointerEvents: DEBorderMouseOver || filterBorderMouseOver ? 'none' : '' }}
              >
                <use
                  style={{
                    pointerEvents:
                    DEBorderMouseOver ||
                    filterBorderMouseOver ?
                      'none' :
                      '',
                  }}
                  xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#edit"
                  className="dropEdit"
                />
              </svg>
            </span>
          </div>
        ) :
        null}

      {subCollections === undefined ?
        null :
        subCollections.map(nestedCollection => (
          <AvailableDECard
            key={nestedCollection.id}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...nestedCollection}
            handleAliasChange={handleAliasChange}
            Name={nestedCollection.Name.toString()}
            parentAlias={deAlias}
            dragged
            handleDeleteSelectedDE={handleDeleteSelectedDE}
            handleExpandFiltersBox={handleExpandFiltersBox}
            matchedFields={matchedFields}
            handleSetSelectionState={handleSetSelectionState}
            filtersRef={filtersRef}
            selectedDataExtensions={selectedDataExtensions}
            handleAliasPopUp={handleAliasPopUp}
            relations={relations}
            DEBorderMouseOver={DEBorderMouseOver}
            filterBorderMouseOver={filterBorderMouseOver}
            customValues={customValues}
            parentId={id}
            showRelatedDEs={showRelatedDEs}
            dataExtension={nestedCollection}
            parentDEOfDataSet={parentDEOfDataSet}
            setSelectedDataExtensions={setSelectedDataExtensions}
            setModalDataExtensions={setModalDataExtensions}
            setShowRelationalModal={setShowRelationalModal}
            setFilterBorderMouseOver={setFilterBorderMouseOver}
            setDEBorderMouseOver={setDEBorderMouseOver}
            disabled={disabled}
          />
        ))}
    </div>
  );
};

/**
 * Note: AvailableDECard is used for both available DEs and selected DEs: not always the same props
 */
AvailableDECard.propTypes = {
  /**
   * Name of the Data Extension which is rendered as available data extension
   */
  Name: PropTypes.string.isRequired,
  /**
   * CustomerKey of the Data Extension which is rendered as available data extension
   */
  CustomerKey: PropTypes.string,
  /**
   * This prop is true if a data extension is selected
   * false if not selected
   */
  dragged: PropTypes.bool,
  /**
   * Alias of the Data Extension which is rendered as selected data extension
   */
  deAlias: PropTypes.string,
  /**
   * Generated id value of the Data Extension which is rendered as selected data extension
   */
  id: PropTypes.string,
  /**
   * If there is another selected data extension which is connected to a parent one
   * it will be passed as a sub collection
   */
  subCollections: PropTypes.instanceOf(Array),
  /**
   * It determines if relation modal will be opened again or not
   * It is stored in the DE object which is a selected one
   */
  edit: PropTypes.bool,
  /**
   * It keeps the Alias value for the parent selected data extension
   */
  parentAlias: PropTypes.string,
  /**
   * It keeps the modal Ids for relations
   */
  relationalModalId: PropTypes.number,
  /**
   * It helps to delete a selected data extension
   * it will be passed from Selection.js
   */
  handleDeleteSelectedDE: PropTypes.func,
  /**
   * It keeps the matchedFields for a target data extension of the Selection
   * It will be passed from Selection.js
   */
  matchedFields: PropTypes.instanceOf(Array),
  /**
   * It helps to set the Selection`s state
   * It will be passed from Selection.js
   */
  handleSetSelectionState: PropTypes.func,
  /**
   * It keeps the reference of the selected filters
   */
  filtersRef: PropTypes.instanceOf(Object),
  /**
   * It keeps the selected data extensions for Selection.js
   * selected data extensions are stored as collections in database
   * It will be passed from Selection.js
   */
  selectedDataExtensions: PropTypes.instanceOf(Array),
  /**
   * This pop up will be shown when a user wants to change the data extension's
   * alias
   */
  handleAliasPopUp: PropTypes.func,
  /**
   * It keeps the relation between selected data extensions
   * It will be passed from Selection.js
   */
  relations: PropTypes.instanceOf(Array),
  /**
   * Keeps track whether Available DE are dragged
   */
  DEBorderMouseOver: PropTypes.bool.isRequired,
  /**
   * Keeps track whether Available Fields are dragged
   */
  filterBorderMouseOver: PropTypes.bool.isRequired,
  /**
   * It keeps custom values data
   * It will be passed from Selection.js
   */
  customValues: PropTypes.instanceOf(Array),
  /**
   * Id of the parent collection in case of nested collection
   */
  parentId: PropTypes.string,
  /**
   * Shows only DEs that are related via predef relations with the currentDE
   */
  showRelatedDEs: PropTypes.func,
  /**
   * Current DE for which we will show related DEs
   */
  dataExtension: PropTypes.instanceOf(Object),
  /**
   * Determines if a DE or data set is being dragged
   */
  draggingDataSet: PropTypes.bool,
  /**
   * Array of Data Sets
   */
  dataSets: PropTypes.instanceOf(Array),
  /**
   * Parent Data Extension of the data set that is being dragged
   */
  parentDEOfDataSet: PropTypes.string,
  /**
   * Sets loader hook when drag and dropping a DE
   */
  setLoaderSelectedDE: PropTypes.func,
  /**
   * Sets selected DEs
   */
  setSelectedDataExtensions: PropTypes.func,
  /**
   * Keeps the details about the relationship between two DEs
   */
  setModalDataExtensions: PropTypes.func,
  /**
   * Opens the relationship modal between two DEs
   */
  setShowRelationalModal: PropTypes.func,
  /**
   * Sets a border to green when drag and dropping
   */
  setDEBorderMouseOver: PropTypes.func,
  /**
   * Sets a border to green when drag and dropping
   */
  setFilterBorderMouseOver: PropTypes.func,
  /**
   * Handles the expansion of the filters box
   */
  handleExpandFiltersBox: PropTypes.func,
  /**
   * Determines whether the data set could be dragged or not
   */
  disabled: PropTypes.bool,
};

export default AvailableDECard;
