import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Constants from '../../../../../../constants/constants';
import Tooltip from '../../../../../shared/Tooltip/Tooltip';
import './styles.scss';
import Select from '../../../../../shared/Select/Select';
import Input from '../../../../../shared/Input/Input';

const CriteriaForRelationFilter = ({
  handleUpdateFilterLineCriteria, handleUpdateFormulaFilterLineCriteria, id,
  parentId, criteria, handleOnChangeFormulaValue, value, formulaForRelationFilter,
  dEName, relatedDEName, isInResultsFormula, subQuery,
}) => {
  // state for hide number input
  const [hideNumberInput, setHideNumberInput] = useState(false);

  useEffect(() => {
    // if the criteria is set to 'EXACTLY' with value '0', then set 'NO' option when mounting component
    if (formulaForRelationFilter === Constants.FORMULA__VALUE__COUNT &&
      criteria === Constants.FILTERLINE__RELATION_CRITERIA__EXACTLY_VALUE &&
      // eslint-disable-next-line eqeqeq
      value == 0) {
      setHideNumberInput(true);
    }

    // eslint-disable-next-line
  }, []);

  /**
   * Function for validate value in number input depending on the formula
   * @param {object} inputValue - value in the input
   * @param {string} currentCriteria - current relation criteria
   * @param {string} onBlur - determines if this function will be used in the onBlur function
   * @returns {boolean} true/false depending on whether the value is valid
   */
  const validateNumberInput = (inputValue, currentCriteria, onBlur) => {
    const relationCriteria = currentCriteria || criteria;

    // if the formula is COUNT
    if (formulaForRelationFilter === Constants.FORMULA__VALUE__COUNT) {
      // don't allow to put negative number if criteria is more than
      if (relationCriteria === Constants.FILTERLINE__RELATION_CRITERIA__MORE_THAN_VALUE &&
          inputValue < '0') {
        /*
         * if onBlur is true and input has empty value set the value to zero
         * or if onBlur is false and value is not empty
         */
        if ((onBlur && inputValue === '') || (!onBlur && inputValue !== '')) {
          handleOnChangeFormulaValue(id, '0', true, isInResultsFormula, subQuery);

          return false;
        }
      }

      // Don't allow input < 2 if criteria is less than
      if (relationCriteria === Constants.FILTERLINE__RELATION_CRITERIA__LESS_THAN_VALUE &&
        inputValue < '2') {
        /*
         * if onBlur is true and input has empty value set the value to one
         * or if onBlur is false and value is not empty
         */
        if ((onBlur && inputValue === '') || (!onBlur && inputValue !== '')) {
          handleOnChangeFormulaValue(id, '2', true, isInResultsFormula, subQuery);

          return false;
        }
      }

      // don't allow to put numbers < 1 if criteria is different than more than
      if (relationCriteria !== Constants.FILTERLINE__RELATION_CRITERIA__MORE_THAN_VALUE &&
        inputValue < '1') {
        /*
         * if onBlur is true and input has empty value set the value to one
         * or if onBlur is false and value is not empty
         */
        if ((onBlur && inputValue === '') || (!onBlur && inputValue !== '')) {
          handleOnChangeFormulaValue(id, '1', true, isInResultsFormula, subQuery);

          return false;
        }
      }
      // for the other formulas set the value to one if the value is empty onBlur
    } else if (onBlur && inputValue === '') {
      handleOnChangeFormulaValue(id, '1', true, isInResultsFormula, subQuery);

      return false;
    }

    return true;
  };

  /**
   * Show proper value for criteria dropdown
   * @returns {void}
   */
  const showValueForCriteria = () => {
    // eslint-disable-next-line eqeqeq
    if ((criteria === Constants.FILTERLINE__RELATION_CRITERIA__EXACTLY_VALUE && value == 0 && hideNumberInput) ||
    // eslint-disable-next-line eqeqeq
    (formulaForRelationFilter === Constants.FORMULA__VALUE__COUNT && value == 0 &&
      criteria === Constants.FILTERLINE__RELATION_CRITERIA__EXACTLY_VALUE)) {
      // show 'no' for exactly criteria with '0' value, when hideNumberInput is true
      return Constants.FILTERLINE__RELATION_CRITERIA__NO_LABEL;
    }

    // return criteria
    return criteria;
  };

  useEffect(() => {
    if (showValueForCriteria() !== Constants.FILTERLINE__RELATION_CRITERIA__NO_LABEL) {
    // check the value in number input each time the formula changes
      validateNumberInput(value);
    }

    /* eslint-disable-next-line */
  }, [formulaForRelationFilter]);

  /**
   * Update criteria in relation filter
   * @param {object} e - event JS
   * @returns {void}
   */
  const handleUpdateCriteria = (e) => {
    // when the "no" label is selected
    if (e.target.value === Constants.FILTERLINE__RELATION_CRITERIA__NO_LABEL) {
      // hide number input
      setHideNumberInput(true);
      if (isInResultsFormula) {
        handleUpdateFormulaFilterLineCriteria(
          parentId,
          id,
          Constants.FILTERLINE__RELATION_CRITERIA__EXACTLY_VALUE,
          subQuery,
          null,
          null,
          null,
          true,
        );
      } else {
        // set the criteria to 'exactly' with the value 0
        handleUpdateFilterLineCriteria(
          id,
          Constants.FILTERLINE__RELATION_CRITERIA__EXACTLY_VALUE,
          null,
          null,
          null,
          true,
        );
      }

      handleOnChangeFormulaValue(id, '0', true, isInResultsFormula, subQuery);
    } else {
      // eslint-disable-next-line eqeqeq
      if (value == 0 && criteria === Constants.FILTERLINE__RELATION_CRITERIA__EXACTLY_VALUE &&
        hideNumberInput &&
        e.target.value !== Constants.FILTERLINE__RELATION_CRITERIA__LESS_THAN_VALUE
      ) {
        // when switching from the "no" option, set the default value for the criteria
        handleOnChangeFormulaValue(id, '1', true, isInResultsFormula, subQuery);
      } else if (validateNumberInput(value, e.target.value)) {
        // in another case set current value
        handleOnChangeFormulaValue(id, value, true, isInResultsFormula, subQuery);
      }
      // in other case save selected criteria and don't hide number input
      if (isInResultsFormula) {
        handleUpdateFormulaFilterLineCriteria(parentId, id, e.target.value, subQuery, null, null, null, true);
      } else {
        handleUpdateFilterLineCriteria(id, e.target.value, null, null, null, true);
      }
      setHideNumberInput(false);
    }
  };

  /**
   * Determines tooltip text based on the filter criteria
   * @returns {string} - The tooltip text
   */
  const getTooltipText = () => {
    const boldTextStyle = { fontWeight: 'bold' };
    const italicsTextStyle = { fontStyle: 'italic' };

    switch (criteria) {
      case Constants.FILTERLINE__RELATION_CRITERIA__EXACTLY_VALUE:
        return (
          <>
            <span style={italicsTextStyle}>Has/have exactly</span>
            {' '}
            filters have a minimum input of 1. If you want to filter on
            {' '}
            <span style={boldTextStyle}>
              {dEName}
            </span>
            {' '}
            records without
            {' '}
            <span style={boldTextStyle}>
              {relatedDEName}
            </span>
            {' '}
            records, choose the
            {' '}
            <span style={italicsTextStyle}>Has/have no</span>
            {' '}
            filter.
          </>
        );
      case Constants.FILTERLINE__RELATION_CRITERIA__AT_MOST_VALUE:
        return (
          <>
            <span style={italicsTextStyle}>Has/have at most</span>
            {' '}
            filters will not include
            {' '}
            <span style={boldTextStyle}>
              {dEName}
            </span>
            {' '}
            records for which there are no matching
            {' '}
            <span style={boldTextStyle}>
              {relatedDEName}
            </span>
            {' '}
            records.
          </>
        );
      case Constants.FILTERLINE__RELATION_CRITERIA__LESS_THAN_VALUE:
        return (
          <>
            <span style={italicsTextStyle}>Has/have less than</span>
            {' '}
            filters will not include
            {' '}
            <span style={boldTextStyle}>
              {dEName}
            </span>
            {' '}
            records for which there are no matching
            {' '}
            <span style={boldTextStyle}>
              {relatedDEName}
            </span>
            {' '}
            records.
          </>
        );
      default:
        return null;
    }
  };

  // Determines the visibility of the tooltip info button
  const showTooltip = (formulaForRelationFilter === Constants.FORMULA__VALUE__COUNT &&
    ((criteria !== Constants.FILTERLINE__RELATION_CRITERIA__MORE_THAN_VALUE) &&
    (criteria !== Constants.FILTERLINE__RELATION_CRITERIA__AT_LEAST_VALUE) &&
    (criteria !== Constants.FILTERLINE__RELATION_CRITERIA__EXACTLY_VALUE || value !== '0'))
  );

  const relationCriteriaOptions = [
    {
      value: Constants.FILTERLINE__RELATION_CRITERIA__AT_LEAST_VALUE,
      label: Constants.FILTERLINE__RELATION_CRITERIA__AT_LEAST_LABEL,
    },
    {
      value: Constants.FILTERLINE__RELATION_CRITERIA__EXACTLY_VALUE,
      label: Constants.FILTERLINE__RELATION_CRITERIA__EXACTLY_LABEL,
    },
    {
      value: Constants.FILTERLINE__RELATION_CRITERIA__MORE_THAN_VALUE,
      label: Constants.FILTERLINE__RELATION_CRITERIA__MORE_THAN_LABEL,
    },
    {
      value: Constants.FILTERLINE__RELATION_CRITERIA__AT_MOST_VALUE,
      label: Constants.FILTERLINE__RELATION_CRITERIA__AT_MOST_LABEL,
    },
    {
      value: Constants.FILTERLINE__RELATION_CRITERIA__LESS_THAN_VALUE,
      label: Constants.FILTERLINE__RELATION_CRITERIA__LESS_THAN_LABEL,
    },
  ];

  return (
    <>
      <Select
        selectLabel={formulaForRelationFilter === Constants.FORMULA__VALUE__COUNT ? '' : 'is'}
        className="filter-select filter-criteria"
        onChange={e => handleUpdateCriteria(e)}
        value={showValueForCriteria()}
        options={formulaForRelationFilter === Constants.FORMULA__VALUE__COUNT ?
          [...relationCriteriaOptions, {
            value: Constants.FILTERLINE__RELATION_CRITERIA__NO_LABEL,
            label: Constants.FILTERLINE__RELATION_CRITERIA__NO_LABEL,
          }] :
          relationCriteriaOptions}
      />
      <div
        className="slds-form-element__control relation-filter-tooltip-container"
      >
        {!hideNumberInput && (
          <Input
            type="number"
            placeholder=""
            required
            className="slds-input test-value filter-value number-filter-value"
            onChange={e => validateNumberInput(e.target.value) &&
              handleOnChangeFormulaValue(id, e.target.value, true, isInResultsFormula, subQuery)}
            value={value || ''}
            onBlur={value ?
              null :
              () => validateNumberInput(value, null, true)}
          />
        )}
        {showTooltip && (
          <Tooltip
            nubbinPosition={Constants.NUBBIN_POSITION__BOTTOM_LEFT}
            tooltipText={getTooltipText()}
            customClassName={criteria === Constants.FILTERLINE__RELATION_CRITERIA__EXACTLY_VALUE ?
              'relation-filter-tooltip-exactly' :
              'relation-filter-tooltip'}
          />
        )}
      </div>
    </>
  );
};

CriteriaForRelationFilter.propTypes = {
  /**
   * It helps to change the selected criteria for a filter
   * It will be passed from SelectedFilters.js
   */
  handleUpdateFilterLineCriteria: PropTypes.func.isRequired,
  /**
   * it stores the id property of the filterlines
   */
  id: PropTypes.string.isRequired,
  /**
   * it stores the selected criteria for the filterlines
   */
  criteria: PropTypes.string.isRequired,
  /**
   * It helps to change the value for the filterlines
   * It will be passed from SelectedFilters.js
   */
  handleOnChangeFormulaValue: PropTypes.func.isRequired,
  /**
   * The value which is entered for the filter
   */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  /**
   * it stores the selected formula for the filterlines
   */
  formulaForRelationFilter: PropTypes.string,
  /**
   * It keeps the data extension`s alias
   */
  dEName: PropTypes.string,
  /**
   * Name of the related DE (toDEName in predefined relation)
   */
  relatedDEName: PropTypes.string.isRequired,
  /**
   * Handles checking if passed filter is a subquery formula
   */
  isInResultsFormula: PropTypes.bool,

  /**
   * Passes the current subQuery object
   */
  subQuery: PropTypes.instanceOf(Object),
  /**
   * It helps to change the selected criteria of a formula in filter
   * It will be passed from SelectedFilters.js
   */
  handleUpdateFormulaFilterLineCriteria: PropTypes.func.isRequired,
  /**
   * Gets the current id of the parent
   */

  parentId: PropTypes.string,
};

export default CriteriaForRelationFilter;
