import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import './styles.scss';
import axios from 'axios';
import _, { debounce } from 'lodash';
import classNames from 'classnames';
import { connect } from 'react-redux';

import mapStateToProps from '../../../../mapStateToProps';
import Constants from '../../../../constants/constants';
import DataRetentionPolicy from '../DataRetentionPolicy/DataRetentionPolicy';
import FoldersAPI from '../../../../api/folders';
import Folders from '../../DataExtensions/Folders';
import Util from '../../../../util';
import Button from '../../../shared_v2/Button/Button';
import ModalTemplate from '../../../shared_v2/ModalTemplate/ModalTemplate';
import Spinner from '../../../shared_v2/Spinner/Spinner';
import Input from '../../../shared_v2/Input/Input';
import Alert from '../../../shared_v2/Alert/Alert';
import SwalUtil from '../../../../utils/swal/swalUtil';
import SettingsAPI from '../../../../api/settings';
import ToggleButton from '../../../shared_v2/ToogleButton/ToggleButton';
import Tip from '../../../shared_v2/Tip/Tip';

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
class NewAutoCreatedTargetDE extends Component {
  /**
   * Sort alphabetically an array of Folders, should be passed as parameter to the 'sort' function
   * @param {object} folder1 - is a folder with at least the 'Name' property
   * @param {object} folder2 - is also folder with at least the 'Name' property
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
   * @returns {Integer} - it returns -1, 0 or 1
   */
  static alphabeticalFolderSort(folder1, folder2) {
    if (folder1 && folder1.Name !== undefined && folder2 && folder2.Name !== undefined) {
      return folder1.Name.toString().toLowerCase().localeCompare(folder2.Name.toString().toLowerCase());
    }

    return 0; // Don't sort this element
  }

  constructor(props) {
    super(props);
    this.state = {
      newTargetDataExtension: { ...props.newTargetDataExtension },
      showFoldersModal: false,
      foldersChildren: {},
      folders: [],
      folderId: props.newTargetDataExtension.folderId,
      initialFolderId: null,
      loading: false,
      loadingSelectedFolder: false,
      error: '',
      isSendableChecked: props.newTargetDataExtension.relationship ?
        props.newTargetDataExtension.relationship.isSendable :
        false,
      isTestableChecked: props.newTargetDataExtension.relationship ?
        props.newTargetDataExtension.relationship.isTestable :
        false,
      DE_name_error: false,
      foldersError: null,
      settings: {},
      foldersInclusionExclusionMap: {},
      foldersMap: {},
      searchedFolder: '',
      foldersToSearch: [],
      foldersChildrenToSearch: {},
      isFolderFound: true,
      areAvailableFoldersLoading: false,
      shouldFolderBeSetAsSelected: true,
      targetDEName: props.newTargetDataExtension.name ?
        props.newTargetDataExtension.name :
        props.selectionName,
    };

    this.searchFolders = debounce(this.searchFolders, 300);
    this.searchFoldersInputRef = React.createRef();
  }

  /**
   * Mount the component
   * @returns {void}
   */
  async componentDidMount() {
    this.axiosCancelToken = axios.CancelToken.source();

    const {
      selectCopyTargetDE, isAvailableDEsFoldersModal, isTargetDEsFoldersModal, editTargetDataExtension,
      newTargetDataExtension,
    } = this.props;

    // Auto focus the name input
    if (this.nameInput) this.nameInput.focus();

    // Auto focus the input field for searching the available DEs folders
    if ((isAvailableDEsFoldersModal || isTargetDEsFoldersModal) && this.searchFoldersInputRef) {
      this.searchFoldersInputRef.current.focus();
    }

    if (selectCopyTargetDE || isAvailableDEsFoldersModal || isTargetDEsFoldersModal) {
      // If the Target Data Extension will be copied, fetch folders
      this.fetchFolders();
    }

    // if we are not in the edit target De mode get the retention policy settings
    if (!editTargetDataExtension) {
      const retentionPolicy = await SettingsAPI.getACDEDataRetentionSettings(this.axiosCancelToken.token);

      // Extract values from retentionPolicy and newTargetDataExtension
      const retentionPolicyPeriod = retentionPolicy?.dataRetentionPeriod;
      const retentionPolicyPeriodLength = retentionPolicy?.dataRetentionPeriodLength;

      const targetDataExtPolicyPeriod = newTargetDataExtension?.dataRetentionPolicy?.dataRetentionPeriod;
      const targetDataExtPolicyPeriodLength = newTargetDataExtension?.dataRetentionPolicy?.dataRetentionPeriodLength;

      const finalRetentionPeriod = retentionPolicy ?
        retentionPolicyPeriod :
        (targetDataExtPolicyPeriod || Constants.DATA_RETENTION_POLICY__PERIOD__DAYS);

      const finalRetentionPeriodLength = retentionPolicy ?
        retentionPolicyPeriodLength :
        (targetDataExtPolicyPeriodLength || 1);

      const dataRetentionPolicy = {
        dataRetentionPolicy: retentionPolicy.dataRetentionPolicy || false,
        dataRetentionPeriod: finalRetentionPeriod || Constants.DATA_RETENTION_POLICY__PERIOD__DAYS,
        dataRetentionPeriodLength: finalRetentionPeriodLength,
        periodOn: false,
        resetRetentionPeriodOnImport: false,
        dataExtensionPeriodDate: '',
        periodAfter: true,
        individualRecords:
          retentionPolicy.applyTo === Constants.DATA_RETENTION_POLICY__APPLY_CRITERIA__INDIVIDUAL_RECORDS,
        allRecordsAndDE: retentionPolicy.applyTo === Constants.DATA_RETENTION_POLICY__APPLY_CRITERIA__ALL_RECORDS_DE,
        allRecords: retentionPolicy.applyTo === Constants.DATA_RETENTION_POLICY__APPLY_CRITERIA__ALL_RECORDS,
        toggle: retentionPolicy.dataRetentionPolicy || false,
      };

      this.handleDataRetentionPolicyObjectAndCloseModal({ ...dataRetentionPolicy });
    }

    this.setState({ areAvailableFoldersLoading: true });
  }

  /**
   * Update the component if user selects copyTargetDE
   * Update only when no folders have been defined yet
   * @param {object} prevProps - Previous props
   * @returns {void}
   */
  async componentDidUpdate(prevProps) {
    const {
      selectCopyTargetDE, copyToOtherBU, selectedBusinessUnit,
    } = this.props;
    const { folders, DE_name_error: deNameError } = this.state;

    if (selectCopyTargetDE !== prevProps.selectCopyTargetDE && selectCopyTargetDE && !folders.length &&
      !copyToOtherBU) {
      /*
       * if user selects copy TargetDE in Copy selection in this BU and no folders have been defined yet
       * then execute the function
       */
      await this.fetchFolders();
    }

    /**
     * if we change to copy to other BU or change selected business unit,
     * we have to refetch folders for this specific BU
     */
    if (prevProps.copyToOtherBU !== copyToOtherBU || selectedBusinessUnit !== prevProps.selectedBusinessUnit) {
      await this.fetchFolders(true);
    }

    if (copyToOtherBU && !prevProps.copyToOtherBU && deNameError) {
      this.setDENameError(false);
    }
  }

  /**
   * Avoid memory leak by canceling all subscriptions and asynchronous tasks
   * @returns {void}
   */
  componentWillUnmount() {
    this.axiosCancelToken.cancel();
  }

  /**
   * Set DE name error state
   * @param {bool} value - value for error
   * @returns {void}
   */
  setDENameError = (value) => {
    this.setState({ DE_name_error: value });
  };

  /**
   * Validate whether name in input field is same as the name of existing Target Data Extension
   * @param {*} name - name to validate
   * @returns {boolean}
   */

  validatePrePopulatedName = (name) => {
    const { targetDataExtensions, targetDataExtension, editTargetDataExtension } = this.props;

    // define objectID of targetDE, depending on whether a new DE is created or the existing one is edited
    const targetDEObjectId = editTargetDataExtension ? targetDataExtension?.ObjectID : null;

    // If typed name already exists among DE - display error below the input
    const alreadyExistingDEName = Util.findDuplicateName(
      targetDataExtensions,
      null,
      name,
      targetDEObjectId,
      true,
    );

    return alreadyExistingDEName;
  };

  /**
   * Get folders to display the folder structure in the component
   * @param {bool} copyToCurrentBU - indicates that fetching folders is done when copying to the current BU
   * @returns {void}
   */
  fetchFolders = async (copyToCurrentBU) => {
    const {
      selectCopyTargetDE,
      handleSetNewCopyState,
      newTargetDataExtension,
      isCopySelectionModal,
      copyToOtherBU,
      selectedBusinessUnit,
      showErrorMessage,
      refBtn,
      isAvailableDEsFoldersModal,
      availableDEFolderId,
      editNewAutoTargetDE,
      editTargetDataExtension,
      handleSetSelectionState,
      availableDEsFolders,
      foldersSettings,
      isTargetDEsFoldersModal,
      targetDEsFolders,
      isChangeLocation,
      isScopedDEsFoldersModal,
      setAvailableFolders,
      setFoldersSettings,
      userInfo,
    } = this.props;

    const { settings: defaultSettings } = this.state;

    this.setState({ foldersError: null, loadingSelectedFolder: true });

    try {
      // folders for Copy Target DE are loading
      if (selectCopyTargetDE && handleSetNewCopyState) { handleSetNewCopyState({ loadingFoldersForCopy: true }); }

      let businessUnitId;

      if (copyToOtherBU && selectedBusinessUnit) {
        businessUnitId = selectedBusinessUnit;
      } else {
        // Get loggedIn BU
        const { loggedInBusinessUnitId } = userInfo || {};

        businessUnitId = loggedInBusinessUnitId;
      }

      const foldersInclusionExclusionMap = {};

      let settings = {};

      // Get folders settings
      if (foldersSettings && !Util.objectIsEmpty(foldersSettings) && !copyToOtherBU && !copyToCurrentBU) {
        settings = { ...foldersSettings };
      } else if (defaultSettings && !Util.objectIsEmpty(defaultSettings) && !copyToOtherBU && !copyToCurrentBU) {
        settings = { ...defaultSettings };
      } else settings = await FoldersAPI.getFoldersSettings(this.axiosCancelToken.token, businessUnitId);

      // Get filter type and folders to filter
      const { availableDEFoldersToFilter, targetDEFoldersToFilter } = settings;

      // if this component is used from the AvailableExtensions component
      if (isAvailableDEsFoldersModal) {
        // get the folders to exclude/include
        availableDEFoldersToFilter.forEach((folderToFilter) => {
          foldersInclusionExclusionMap[folderToFilter] = true;
        });
      } else {
        // otherwise get the target folders to exclude/include
        targetDEFoldersToFilter.forEach((folderToFilter) => {
          foldersInclusionExclusionMap[folderToFilter] = true;
        });
      }

      this.setState({ settings, foldersInclusionExclusionMap });

      const params = {
        ...businessUnitId && { businessUnitId },
        dataExtensionType: isAvailableDEsFoldersModal ?
          Constants.ADMIN_PANEL__SUBMENU__AVAILABLE_DES :
          Constants.ADMIN_PANEL__SUBMENU__TARGET_DES,
      };

      let folders = [];

      // Get DE folders
      if (isAvailableDEsFoldersModal && availableDEsFolders?.length) {
        folders = availableDEsFolders;
      } else if (isTargetDEsFoldersModal && targetDEsFolders?.length) {
        folders = targetDEsFolders;
      } else {
        // Fetch folders
        folders = await FoldersAPI.getAllFolders(params, this.axiosCancelToken.token);

        if (isScopedDEsFoldersModal) {
          setAvailableFolders(folders);
          setFoldersSettings(settings);
        } else {
          // Set folders to selection state if we're rendering isAvailableDEsFoldersModal
          if (isAvailableDEsFoldersModal) {
            handleSetSelectionState({ availableDEsFolders: folders, foldersSettings: settings });
          } else if (isTargetDEsFoldersModal) {
            handleSetSelectionState({ targetDEsFolders: folders, foldersSettings: settings });
          }
        }
      }

      // Sort the folders to display them alphabetically
      folders.sort(NewAutoCreatedTargetDE.alphabeticalFolderSort);

      const pathsMap = {};

      const foldersMap = folders.reduce((obj, folder) => {
        // eslint-disable-next-line no-param-reassign
        obj[folder.ID] = folder;

        return obj;
      }, {});

      let paths;

      if (isAvailableDEsFoldersModal) {
        // Get paths of the exclude/include folders
        paths = availableDEFoldersToFilter.map((folderToFilter) => {
          // Get a folder's path
          const path = this.getFoldersPath(folderToFilter, foldersMap, pathsMap);

          // Add path to pathsMap
          pathsMap[folderToFilter] = path;

          return path;
        });
      } else {
        // Get paths of all selected folders
        paths = targetDEFoldersToFilter.map((folderToFilter) => {
          // Get a folder's path
          const path = this.getFoldersPath(folderToFilter, foldersMap, pathsMap);

          // Add path to pathsMap
          pathsMap[folderToFilter] = path;

          return path;
        });
      }

      const foldersChildren = {};

      // Build folders children object
      folders.forEach((folder) => {
        // Push folder in its parent's folders array if parent's key exists in the object
        if (foldersChildren[folder.ParentFolder.ID]) {
          foldersChildren[folder.ParentFolder.ID].folders.push(folder);
        } else {
          // Create the parent's entry in the object
          foldersChildren[folder.ParentFolder.ID] = {
            folders: [],
            showChildren: false,
          };

          // Push folder in its parent's folders array
          foldersChildren[folder.ParentFolder.ID].folders.push(folder);
        }
      });

      // Get root folders
      let rootFolders = folders.filter(
        f => f.ParentFolder.ID === 0 ||
          f.CustomerKey === Constants.FOLDER__CUSTOMER_KEY__DATA_EXTENSION ||
          f.CustomerKey === Constants.FOLDER__CUSTOMER_KEY__SALESFORCE_DATA_EXTENSION ||
          f.CustomerKey === Constants.FOLDER__CUSTOMER_KEY__SHARED_SALESFORCE_DATA_EXTENSION ||
          f.CustomerKey === Constants.FOLDER__CUSTOMER_KEY__SHARED_DATA_EXTENSION,
      );

      // Ensure that the default root folder customer key is dataextension_default & is child of root folder 0
      const defaultRootFolder = rootFolders.find(
        f => f.CustomerKey === Constants.FOLDER__CUSTOMER_KEY__DATA_EXTENSION &&
          f.ParentFolder.ID === 0,
      );

      // TODO: Enable Salesforce Data Extensions once the creation error is fixed
      if (isChangeLocation) {
        rootFolders = rootFolders.filter(obj => obj.Name !== 'Salesforce Data Extensions');
      }

      rootFolders = rootFolders.filter(obj => obj.Name !== 'Audiences');

      // Set state
      await this.setState({
        folders: rootFolders,
        foldersChildren,
        loading: false,
        foldersMap,
        foldersToSearch: rootFolders,
        foldersChildrenToSearch: foldersChildren,
      }, async () => {
        if (paths.length && !editTargetDataExtension) {
          const hasBeenOpenedBefore = {};

          // Open all the folders in each selected folder's path
          // eslint-disable-next-line array-callback-return
          paths.map((path) => {
            // eslint-disable-next-line array-callback-return
            path.map(async (folderId) => {
              // If folder has not been opened before, open it
              if (!hasBeenOpenedBefore[folderId]) {
                // Update the beenOpenedBefore object
                hasBeenOpenedBefore[folderId] = true;

                // Expand the folder
                await this.handleFolderClicked(folderId, true);
              }
            });
          });
        }
      });

      // if there is folderId for the available folders then select that folder when opening the folders modal
      if ((isAvailableDEsFoldersModal || isTargetDEsFoldersModal) && availableDEFolderId) {
        await this.setSelectedFolder(availableDEFolderId, foldersMap, pathsMap);
        // If there's no folder to expand, expand the root folder
      } else if (!_.flatten(paths).length && _.flatten(rootFolders).length) {
        const rootFolder = defaultRootFolder;

        this.handleFolderClicked(rootFolder.ID, true, rootFolder.Name);
      }

      // If chosen folder is not in root folder
      if (newTargetDataExtension.folderId !== defaultRootFolder.ID &&
        (isCopySelectionModal || editNewAutoTargetDE || editTargetDataExtension) && !copyToOtherBU) {
        await this.setSelectedFolder(newTargetDataExtension.folderId, foldersMap, pathsMap);
        this.handleFolderSelected(false);
      }

      const folderToOpen = defaultRootFolder;

      // open and set root folder when copying to other BU or when opening the available folders modal
      if (folderToOpen && (copyToOtherBU ||
        ((isAvailableDEsFoldersModal || isTargetDEsFoldersModal) && !availableDEFolderId))) {
        await this.handleFolderClicked(folderToOpen.ID, true, folderToOpen.Name);
      }

      // stop loading folders for copy TargetDE
      if (selectCopyTargetDE && handleSetNewCopyState) {
        handleSetNewCopyState({ loadingFoldersForCopy: false });
      }

      const {
        newTargetDataExtension: updatedTargetDataExtension,
        settings: updatedSettings,
      } = this.state;

      if (!updatedTargetDataExtension.folderId || !updatedTargetDataExtension.folderName) {
        // Generate an updated foldersInclusionExclusionMap
        const updatedFoldersInclusionExclusionMap = new Set(updatedSettings.targetDEFoldersToFilter);

        // Is the folders filterMode include?
        const isInclude = updatedSettings.includeOrExcludeTargetDEFolders === Constants.INCLUDE_FOLDERS;

        // Find available root folder
        // eslint-disable-next-line max-len
        const availableRootFolder = rootFolders.find(f => (isInclude && updatedFoldersInclusionExclusionMap.has(String(f.ID)) ||
          (!isInclude && !updatedFoldersInclusionExclusionMap.has(String(f.ID)))));

        let folderToSet;

        // Choose default folder
        if (availableRootFolder) {
          folderToSet = availableRootFolder;
        } else if (isInclude) {
          if (targetDEFoldersToFilter[updatedTargetDataExtension.folderId]) {
            folderToSet = foldersMap[targetDEFoldersToFilter[0]];
          } else folderToSet = foldersMap[updatedTargetDataExtension.folderId];
        }

        // Set default selected folder
        updatedTargetDataExtension.folderName = folderToSet?.Name;
        updatedTargetDataExtension.folderId = folderToSet?.ID;

        // Set state
        this.setState({
          newTargetDataExtension: updatedTargetDataExtension,
          folderId: folderToSet?.ID,
          loadingSelectedFolder: false,
        });
      }
    } catch (e) {
      let error = '';
      /*
       * e.response is a complex object so it was causing an error: Objects are not valid react elements
       * because in Folders component we are trying to render it
       */

      if (e.response && e.response.data && e.response.data.error) {
        error = e.response.data.error;
      } else if (e.message) {
        error = e.message;
      } else {
        error = e;
      }

      // when there is permission error related to copy from Parent BU to child BU
      if (!axios.isCancel(e) && error.includes(Constants.ERROR__DOES_NOT_HAVE_PERMISSION_TO_COPY) && showErrorMessage) {
        // switch radio button to copy to current BU
        handleSetNewCopyState({ copyTo: Constants.COPY_TO__CURRENT_BU, copyToOtherBU: false });

        // throw error message
        showErrorMessage(
          'Oops...',
          `At the moment it is not possible to copy the selection from Parent to Child Business Units.
        We are working on a solution that will allow it.`,
        );

        // add className to keep element unfocused
        if (refBtn?.current) refBtn.current.className = 'hidden';
      } else {
        if (!axios.isCancel(e)) {
          this.setState({ error, foldersError: error });
        }
      }
    }
  };

  /**
   * Gets a folder's path
   * @param {number} folderId - Id of the folder
   * @param {object} foldersMap - An object with folderId as keys and folder object as values
   * @param {object} pathsMap - An object containing already found paths
   * @returns {array} - An array containing folder's path starting from the root folder
   */
  getFoldersPath = (folderId, foldersMap, pathsMap) => {
    let parentId = '';

    // Get folder
    let currentFolder = foldersMap[folderId];

    let path = [];

    while (currentFolder && parentId !== 0) {
      // If path of current folder has already been found, concatenate to current path
      if (pathsMap[currentFolder.ID]) {
        path = pathsMap[currentFolder.ID].concat(path);

        return path;
      }

      // eslint-disable-next-line no-loop-func
      const parentFolder = foldersMap[currentFolder?.ParentFolder?.ID];

      // Push the id of parent folder if it exists
      if (parentFolder) {
        path.push(parentFolder.ID);
      }

      // Set parent folder as current folder
      currentFolder = parentFolder;

      // Set parent id
      parentId = currentFolder?.ParentFolder?.ID;
    }

    // Return reversed paths array
    return path.reverse();
  };

  /**
   * Sets selected folder in state
   * @param {object} folderId - folderId of selected folder
   * @param {object} foldersMap - An object with folderId as keys and folder object as values
   * @param {object} pathsMap - An object containing already found paths
   * @returns {void}
   */
  setSelectedFolder = async (folderId, foldersMap, pathsMap) => {
    const { newTargetDataExtension } = this.state;

    // Get a folder's path
    const path = this.getFoldersPath(folderId, foldersMap, pathsMap);

    // Open all the folders on this path
    path.forEach(async (id) => {
      await this.handleFolderClicked(id, true);
    });

    // set data in state
    this.setState({
      folderId,
      newTargetDataExtension: {
        ...newTargetDataExtension,
        folderName: foldersMap[folderId]?.Name || newTargetDataExtension.folderName,
        folderId,
      },
    });
  };

  /**
   * This function helps to change DE name and its description
   * @param {object} e - event
   * @param {boolean} isNameField - whether or not the field we are currently checking is the name field
   * @returns {void}
   */
  handleChange = (e, isNameField) => {
    const {
      handleSetNewCopyState, isCopySelectionModal, copyToOtherBU,
    } = this.props;
    const { newTargetDataExtension } = this.state;
    const newTargetDataExtensionCopy = { ...newTargetDataExtension };

    if (isNameField) {
      this.setState({ DE_name_error: false, targetDEName: e.target.value });

      // if this props is passed set it to false
      if (handleSetNewCopyState) { handleSetNewCopyState({ nameDEError: false }); }

      // If typed name already exists among DE - display error below the input
      const alreadyExistingDEName = this.validatePrePopulatedName(e.target.value);

      // if name exists set DE_name_error state to true
      if (alreadyExistingDEName && !copyToOtherBU) this.setState({ DE_name_error: true });

      // if name exists and props handleSetNewCopyState is passed, set its state to true
      if (alreadyExistingDEName && handleSetNewCopyState) {
        handleSetNewCopyState({ nameDEError: true });
      }
    }

    newTargetDataExtensionCopy[e.target.name] = e.target.value;
    this.setState({ newTargetDataExtension: newTargetDataExtensionCopy });

    if (handleSetNewCopyState && isCopySelectionModal) {
      // if handleSetNewCopyState and isCopySelectionModal are passed as a props - set copyTargetDE
      handleSetNewCopyState({ copyTargetDE: { ...newTargetDataExtension, [e.target.name]: e.target.value } });
    }
  };
  /**
   * This function fires when the name field when focused to validate prepoulated value
   * @param {object} e - event
   * @returns {void}
   */

  handleFocus = (e) => {
    // if name exists set DE_name_error state to true
    if (this.validatePrePopulatedName(e.target.value)) this.setState({ DE_name_error: true });
  };

  /**
   * Close newAutoCreatedTargetDE modal
   * if there are any matched fields, it saves to prevMatchedFields state
   * matchedFields can be restored later if it`s needed
   * @returns {void}
   */
  handleCloseModal = () => {
    const {
      handleSetSelectionState,
      prevMatchedFields,
      editNewAutoTargetDE,
      matchedFields,
    } = this.props;

    handleSetSelectionState({
      createNewAutoTargetDE: false,
      matchedFields: editNewAutoTargetDE ? matchedFields : prevMatchedFields,
    });
  };

  /**
   * Validate new target DE name
   * @param {string} name - validated name
   * @param {boolean} isTargetDeEdited - indicates whether targetDE is being edited
   * @returns {boolean} True if name is valid, false otherwise
   */
  checkNameValidation = (name, isTargetDeEdited) => {
    const { targetDataExtensions, copyToOtherBU, targetDataExtension } = this.props;

    let matched = false;

    // check if the name is valid
    if (!Util.nameValidation(name)) {
      return false;
    }

    const targetDEObjectId = isTargetDeEdited ? targetDataExtension?.ObjectID : null;

    // check if the name exists in targetDataExtensions
    matched = Util.findDuplicateName(targetDataExtensions, null, name, targetDEObjectId, true);

    // If DE name exists - display the error below the input
    if (matched && !copyToOtherBU) {
      this.setState({ DE_name_error: true });

      return false;
    }

    return true;
  };

  /**
   * Validate new target DE description
   * @param {string} description - validated description
   * @returns {boolean} True if description is valid, false otherwise
   */
  descriptionValidation = (description) => {
    const invalidCharacters = Util.containsIllegalCharacters(description);

    if (invalidCharacters) {
      SwalUtil.fire({
        type: Constants.SWAL__TYPE__ERROR,
        title: 'Invalid Description',
        // eslint-disable-next-line max-len
        message: `New Data Extension description <b>${description}</b> cannot contain <b>${invalidCharacters}</b>`,
        options: {
          customClass: {
            popup: 'popup-targetDE',
          },
        },
      });

      return false;
    }

    return true;
  };

  /**
   * Save newAutoCreatedTargetDE modal
   * check if name exists
   * if everything is right change newTargetDataExtension state in Selection
   * and set showModal to false to close the modal
   * @returns {void}
   */
  onSubmit = () => {
    const {
      newTargetDataExtension,
      isSendableChecked,
      isTestableChecked,
      folders,
      targetDEName,
    } = this.state;
    const {
      handleSetSelectionState, editTargetDataExtension,
      defaultSendRelationshipField, targetDataExtensionFields,
    } = this.props;

    if (editTargetDataExtension && !newTargetDataExtension?.relationship?.isSendable &&
      newTargetDataExtension?.relationship?.sendableDataExtensionField?.name &&
      newTargetDataExtension?.relationship?.sendableSubscriberField?.name && isSendableChecked) {
      const matchedDefaultField =
        targetDataExtensionFields?.find(field => field?.Name === defaultSendRelationshipField?.fieldName);

      // By default, the first field from the DE is selected for the Sender Relationship.
      const firstFieldFromDE = targetDataExtensionFields?.[0] ?? null;

      if (
        defaultSendRelationshipField?.isEnabled &&
        matchedDefaultField &&
        (
          (
            matchedDefaultField?.Name !== newTargetDataExtension?.relationship?.sendableDataExtensionField?.name ||
            matchedDefaultField?.Name === firstFieldFromDE?.Name
          )
        ) &&
        [
          Constants.FILTERLINE__FIELDTYPE__TEXT,
          Constants.FILTERLINE__FIELDTYPE__EMAILADDRESS,
          Constants.FILTERLINE__FIELDTYPE__NUMBER,
        ].includes(matchedDefaultField?.FieldType)
      ) {
        SwalUtil.fire({
          title: 'Default Send Relationship detected',
          // eslint-disable-next-line max-len
          messageHTML: `<strong>${defaultSendRelationshipField?.fieldName
          }</strong> field exists in the Target Data Extension and will be
           automatically selected for the Sender Relationship.`,
        });
        const fieldType = matchedDefaultField?.FieldType;

        let subscriberFieldName = Constants.SUBSCRIBER_FIELD__SUBSCRIBER_KEY;

        if (fieldType === Constants.FILTERLINE__FIELDTYPE__NUMBER) {
          subscriberFieldName = Constants.SUBSCRIBER_FIELD__SUBSCRIBER_ID;
        }

        newTargetDataExtension.relationship = {
          ...newTargetDataExtension.relationship,
          sendableDataExtensionField: {
            ...newTargetDataExtension.relationship.sendableDataExtensionField,
            name: matchedDefaultField?.Name?.toString(),
            type: fieldType,
          },
          sendableSubscriberField: {
            ...newTargetDataExtension.relationship.sendableSubscriberField,
            name: subscriberFieldName,
          },
        };
      } else {
        newTargetDataExtension.relationship = {
          ...newTargetDataExtension.relationship,
          sendableDataExtensionField: { name: '', type: '' },
          sendableSubscriberField: { name: '' },
        };
      }
    }
    newTargetDataExtension.relationship.isSendable = isSendableChecked;
    newTargetDataExtension.relationship.isTestable = isTestableChecked;
    newTargetDataExtension.name = targetDEName?.trim();
    /*
     * if user doesn't pick the folder, folder name will be empty so
     * set folder id to the root folder id which belongs to the regular data extensions folder
     */
    if (!newTargetDataExtension.folderId && folders && folders.length > 0) {
      /*
       * loop through the folders to find root folder (Parent folder ID === 0)
       * for regular DEs (Content type === dataExtension)
       */
      for (let i = 0; i < folders.length; i += 1) {
        if (folders[i].ParentFolder && folders[i].ParentFolder.ID === 0 &&
          folders[i].ContentType === Constants.FOLDER__CONTENT_TYPE__DATAEXTENSION) {
          newTargetDataExtension.folderId = folders[i].ID;
          break;
        }
      }
    }

    /*
     * Validate Retention Settings
     */
    if (!newTargetDataExtension.dataRetentionPolicy.dataRetentionPolicy) {
      // reset the settings if data retention policy is turned off
      Object.assign(newTargetDataExtension.dataRetentionPolicy, {
        individualRecords: false,
        allRecordsAndDE: false,
        allRecords: false,
        periodAfter: false,
        periodOn: false,
        resetRetentionPeriodOnImport: false,
      });
    }

    // if 'On' is checked but period length is not picked then show the message
    if (newTargetDataExtension.dataRetentionPolicy.periodAfter &&
      !newTargetDataExtension.dataRetentionPolicy.dataRetentionPeriodLength) {
      SwalUtil.fire({
        title: 'Period Length Missing',
        message: 'Please pick a number for the period.',
      });

      return;
    }

    // if 'On' is checked but date is not picked then show the message
    if (newTargetDataExtension.dataRetentionPolicy.periodOn &&
      !newTargetDataExtension.dataRetentionPolicy.dataExtensionPeriodDate) {
      SwalUtil.fire({
        title: 'Date Missing',
        message: 'Please pick a date.',
      });

      return;
    }

    // if period after or individual records are checked then set dataExtensionPeriodDate to empty
    if (newTargetDataExtension.dataRetentionPolicy.periodAfter ||
      newTargetDataExtension.dataRetentionPolicy.individualRecords) {
      newTargetDataExtension.dataRetentionPolicy.dataExtensionPeriodDate = '';
      // if 'On' is checked set dataRetentionPeriod and dataRetentionPeriodLength to their initial values
    } else if (newTargetDataExtension.dataRetentionPolicy.periodOn) {
      newTargetDataExtension.dataRetentionPolicy.dataRetentionPeriod = Constants.DATA_RETENTION_POLICY__PERIOD__DAYS;
      newTargetDataExtension.dataRetentionPolicy.dataRetentionPeriodLength = 1;
    }

    // check if New Target DE name and description are valid
    if (
      this.checkNameValidation(targetDEName, editTargetDataExtension) &&
      this.descriptionValidation(newTargetDataExtension.description)
    ) {
      handleSetSelectionState({
        newTargetDataExtension,
        createNewAutoTargetDE: false,
        editNewAutoTargetDE: !editTargetDataExtension,
      });
    }
  };

  /**
   * handle dataRetentionPolicy object and close the modal - work for save and cancel
   * @param {object} newState - state of retention policy
   * @returns {void}
   */
  handleDataRetentionPolicyObjectAndCloseModal = (newState) => {
    this.setState(prevState => ({
      newTargetDataExtension: {
        ...prevState.newTargetDataExtension,
        dataRetentionPolicy: {
          ...prevState.newTargetDataExtension.dataRetentionPolicy,
          ...newState,
        },
      },
    }));
  };

  /**
   * Show modal with folders
   * @returns {void}
   */
  handleShowFoldersModal = () => this.setState({ showFoldersModal: true });

  /**
   * Hide modal with folders
   * @returns {void}
   */
  handleHideFoldersModal = () => this.setState({ showFoldersModal: false });

  /**
   * Set the initial folder id when Change folder button is clicked
   * @returns {void}
   */
  handleSetInitialFolderId = () => {
    const { newTargetDataExtension } = this.state;
    const initialFolderId = newTargetDataExtension.folderId;

    this.setState({ initialFolderId });

    this.handleShowFoldersModal();
  };

  /**
   * Handles setting the folder back to the initial folder id when Cancel button is clicked
   * @returns {void}
   */
  handleCancelFoldersModal = () => {
    const {
      initialFolderId, foldersMap, newTargetDataExtension,
    } = this.state;
    const { handleSetNewCopyState } = this.props;

    const folder = foldersMap[initialFolderId];

    // Check if a folder has been chosen
    if (folder) {
      const chosenFoldersName = folder.Name.toString();
      const chosenFolderId = folder.ID;

      newTargetDataExtension.folderId = chosenFolderId;
      newTargetDataExtension.folderName = chosenFoldersName;

      // set the new state of CopyTargetDE with the folderId and folderName
      if (handleSetNewCopyState) {
        handleSetNewCopyState({ copyTargetDE: newTargetDataExtension });
      }
    }
    this.setState({ folderId: initialFolderId, initialFolderId: null });
    this.handleHideFoldersModal();
  };

  // Event handler for when the OK button is clicked
  handleFolderSelected = (hideFolders = true) => {
    const { folderId, foldersMap, newTargetDataExtension } = this.state;
    const { handleSetNewCopyState } = this.props;

    const folder = foldersMap[folderId];

    // Check if a folder has been chosen
    if (folder) {
      const chosenFoldersName = folder.Name.toString();
      const chosenFolderId = folder.ID;
      const chosenFolderType = folder.ContentType;

      newTargetDataExtension.folderId = chosenFolderId;
      newTargetDataExtension.folderName = chosenFoldersName;
      newTargetDataExtension.contentType = chosenFolderType;

      // set the new state of CopyTargetDE with the folderId and folderName
      if (handleSetNewCopyState) {
        handleSetNewCopyState({ copyTargetDE: newTargetDataExtension });
      }
    }

    this.setState({ initialFolderId: null });

    if (hideFolders) {
      this.handleHideFoldersModal();
    }
  };

  /**
   * OnClick event handler for when a folder is clicked
   * @param {string} id - Id of the clicked folder
   * @param {bool} openedAutomatically - Is the click triggered automatically?
   * @param {string} folderName - selected folder name
   * @param {string} folderBusinessUnitId - selected folder business unit id
   * @returns {void}
   */
  handleFolderClicked = (id, openedAutomatically, folderName, folderBusinessUnitId) => {
    const {
      foldersChildren, newTargetDataExtension, foldersChildrenToSearch,
      foldersInclusionExclusionMap, settings,
    } = this.state;

    const {
      copyToOtherBU, handleSetNewCopyState, selectCopyTargetDE, isAvailableDEsFoldersModal,
      targetDataExtension, editTargetDataExtension, orgInfo,
    } = this.props;

    const { includeOrExcludeTargetDEFolders, includeOrExcludeAvailableDEFolders } = settings;

    // Is the folders filterMode exclude?
    const isExclude = (includeOrExcludeTargetDEFolders === Constants.EXCLUDE_FOLDERS && !isAvailableDEsFoldersModal) ||
      (includeOrExcludeAvailableDEFolders === Constants.EXCLUDE_FOLDERS && isAvailableDEsFoldersModal);

    const folderIsNotExluded = isExclude && !foldersInclusionExclusionMap[id];
    const folderIsInlcuded = !isExclude && foldersInclusionExclusionMap[id];
    const folderIsSharedAndDeIsNot = (folderBusinessUnitId && editTargetDataExtension &&
      folderBusinessUnitId === orgInfo.enterpriseBusinessUnitId &&
      targetDataExtension?.Client?.ID !== orgInfo.enterpriseBusinessUnitId
    );

    // Should we let the clicked folder to be set as the selected folder?
    const shouldFolderBeSetAsSelected =
      !folderIsSharedAndDeIsNot && (
        folderIsNotExluded ||
        (folderIsInlcuded) ||
        (includeOrExcludeTargetDEFolders === Constants.DO_NOT_FILTER_FOLDERS && !isAvailableDEsFoldersModal) ||
        (includeOrExcludeAvailableDEFolders === Constants.DO_NOT_FILTER_FOLDERS && isAvailableDEsFoldersModal)
      );

    const folderId = shouldFolderBeSetAsSelected ? id : null;

    folderName = shouldFolderBeSetAsSelected ?
      folderName || newTargetDataExtension.folderName :
      null;

    // Set state
    this.setState({
      loading: true,
      folderId,
      shouldFolderBeSetAsSelected,
      newTargetDataExtension: {
        ...newTargetDataExtension,
        ...folderId && { folderId },
        ...folderName && { folderName },
      },
    });

    try {
      if (foldersChildren[id]) {
        this.setState(prevState => ({
          foldersChildren: {
            ...prevState.foldersChildren,
            [id]: {
              ...prevState.foldersChildren[id],
              showChildren: openedAutomatically || !prevState.foldersChildren[id].showChildren,
            },
          },
          loading: false,
        }));
      } else {
        const childrenCopy = {
          ...foldersChildren,
          [id]: {
            folders: [],
            showChildren: true,
          },
        };

        this.setState({
          foldersChildren: childrenCopy,
          loading: false,
        });
      }

      if (foldersChildrenToSearch[id]) {
        this.setState(prevState => ({
          foldersChildrenToSearch: {
            ...prevState.foldersChildrenToSearch,
            [id]: {
              ...prevState.foldersChildrenToSearch[id],
              showChildren: openedAutomatically || !prevState.foldersChildrenToSearch[id].showChildren,
            },
          },
          loading: false,
        }));
      } else {
        const childrenToSearchCopy = {
          ...foldersChildrenToSearch,
          [id]: {
            folders: [],
            showChildren: true,
          },
        };

        this.setState({
          foldersChildrenToSearch: childrenToSearchCopy,
          loading: false,
        });
      }

      if (copyToOtherBU) {
        this.handleFolderSelected(false);

        // stop loading folders for copy TargetDE
        if (selectCopyTargetDE && handleSetNewCopyState) { handleSetNewCopyState({ loadingFoldersForCopy: false }); }
      }
    } catch (e) {
      this.setErrorState(e);
    }
  };

  /**
   * Sets error in the state
   * @param {object} e - The error object
   * @returns {void}
   */
  setErrorState = (e) => {
    let error = '';

    // If error is from the backend
    if (e.response) {
      error = e.response;
    } else {
      // When error is from code execution
      error = e.message;
    }
    this.setState({ error, loading: false });
  };

  /**
   * Finds the path of the selected folder and triggers the function for opening the data extensions modal
   * @param {number} folderId - id of the selected folder
   * @returns {void}
   */
  openAvailableDataExtensionModal = (folderId) => {
    const { foldersMap, folders, newTargetDataExtension } = this.state;
    const { openAvailableDEsForCertainFolderModal } = this.props;

    const foldersPath = [];

    // get folders path eg. [311, 3456, 2145]
    const foundFolderPath = this.getFoldersPath(folderId, foldersMap, {});

    // for each path (folderId) find the name of that folder and push it into an array;
    foundFolderPath.forEach((path, i) => {
      if (i === 0) {
        const rootFolder = folders.find(folder => folder.ID === path);

        foldersPath.push(rootFolder.Name.toString());
      } else {
        foldersPath.push(foldersMap[path].Name.toString());
      }
    });

    // push the name of the selected folder
    foldersPath.push(newTargetDataExtension.folderName);

    // open the data extensions modal
    openAvailableDEsForCertainFolderModal(folderId, foldersPath);
  };

  /**
   * Determines if the 'Next' button should be disabled
   * @param {number} childFolders - folders children
   * @returns {bool} returns true of false depends if the id of selected folder exists in the child folders
   */
  shouldNextBtnBeDisabled = (childFolders) => {
    const { newTargetDataExtension } = this.state;

    let disabled = true;

    // get folders children values
    const foldersChildrenValues = Object.values(childFolders);

    // check if the Id of the selected folder exists in the child folders
    for (let i = 0; i < foldersChildrenValues.length; i += 1) {
      if (foldersChildrenValues[i].folders) {
        for (let j = 0; j < foldersChildrenValues[i].folders.length; j += 1) {
          if (foldersChildrenValues[i].folders[j].ID === newTargetDataExtension?.folderId) {
            disabled = false;
            break;
          }
        }
      }
    }

    return disabled;
  };

  /**
   * Returns a function, that set searched value in state and as long as it continues to be invoked,
   * will not be triggered. The function will be called after it stops being called for 300 ms.
   * @param {object} e - event
   * @returns {void}
   */
  handleSearch = (e) => {
    this.setState({ searchedFolder: e.target.value, loadingSearchedFolders: true }, () => {
      this.searchFolders();
    });
  };

  /**
   * Finds all folders that we are searching for
   * @param {object} e - event
   * @returns {void}
   */
  searchFolders = () => {
    const {
      foldersMap, foldersToSearch, foldersChildrenToSearch, searchedFolder,
    } = this.state;

    let filteredFolders = [...foldersToSearch];

    let filteredFoldersChildren = { ...foldersChildrenToSearch };

    this.setState({ isFolderFound: true, areAvailableFoldersLoading: false });

    if (searchedFolder) {
      // first check if the searched folder is one of the main (top level) folders (Data Extensions, Shared DEs...)
      filteredFolders = foldersToSearch?.filter(
        folder => folder.Name.toString().toLowerCase().includes(searchedFolder.toString().toLowerCase()),
      ) || [];

      // after that search for the child folders
      const foundChildFolders = [];

      // get folders children values
      const foldersChildrenValues = Object.values(foldersChildrenToSearch);

      // find the child folder(s) that we are searching for
      for (let i = 0; i < foldersChildrenValues.length; i += 1) {
        if (foldersChildrenValues[i].folders) {
          foldersChildrenValues[i].folders.forEach((childrenFolders) => {
            if (childrenFolders.Name.toString().toLowerCase().includes(searchedFolder.toString().toLowerCase())) {
              foundChildFolders.push(childrenFolders);
            }
          });
        }
      }

      // if the folder(s) is/are found
      if (foundChildFolders?.length) {
        const foundChildPath = [];
        const objectOfChildren = {};

        // find the path(s) of the folder(s)
        foundChildFolders.forEach((foundChildFolder) => {
          const findPath = this.getFoldersPath(foundChildFolder.ID, foldersMap, {});

          foundChildPath.push(findPath);
        });

        // if the path(s) is/are found
        if (foundChildPath?.length) {
          /*
           * loop through the found path array
           * example of the one found folder: [311, 1234, 1111, 2222]
           * example of the multiple found folders: [
           *                                         [311, 111, 222, 333]
           *                                         [304, 444, 555, 666]
           *                                        ]
           */
          for (let i = 0; i < foundChildPath.length; i += 1) {
            /*
             * find the root (top level) folder (it will be always the first folderId in the path array)
             * foundRootFolder => folder with an ID = 311
             */
            const foundRootFolder = foldersToSearch.find(f => f.ID === foundChildPath[i][0]);
            // if the root folder is found

            if (foundRootFolder) {
              if (!filteredFolders.find(f => f.ID === foundRootFolder.ID)) {
                // push the root folder into filtered folders array
                filteredFolders.push(foundRootFolder);
              }
              // loop through the path array of the searched child folder(s)
              foundChildPath[i].forEach((path, index) => {
                // if there is the next element in array
                if (foundChildPath[i][index + 1]) {
                  // find a folder of that next element
                  const pathFolder = foldersChildrenToSearch[path].folders.find(
                    f => f.ID === foundChildPath[i][index + 1],
                  );

                  let objectOfChildrenFolders = [];
                  // if there is an child that has a folder array

                  if (objectOfChildren[path]?.folders?.length) {
                    const foundObjectOfChildrenFolders = objectOfChildren[path]?.folders.find(
                      f => f.ID === pathFolder.ID,
                    );

                    if (foundObjectOfChildrenFolders) {
                      objectOfChildrenFolders = [...objectOfChildren[path].folders];
                    } else {
                      // keep the previous folders and add the path folder
                      objectOfChildrenFolders = [...objectOfChildren[path].folders, pathFolder];
                    }
                  } else {
                    // otherwise just add the path folder
                    objectOfChildrenFolders = [pathFolder];
                  }

                  // create a folder child object with the adequate folders
                  objectOfChildren[path] = {
                    folders: objectOfChildrenFolders,
                    showChildren: true,
                  };
                } else {
                  let objectOfChildrenFolders = [];
                  // if there is an child that has a folder array

                  if (objectOfChildren[path]?.folders?.length) {
                    const foundObjectOfChildrenFolders = objectOfChildren[path]?.folders.find(
                      f => f.ID === foundChildFolders[i].ID,
                    );

                    if (foundObjectOfChildrenFolders) {
                      objectOfChildrenFolders = [...objectOfChildren[path].folders];
                    } else {
                      // keep the previous folders and add the path folder
                      objectOfChildrenFolders = [
                        ...objectOfChildren[path].folders,
                        foundChildFolders[i],
                      ];
                    }
                  } else {
                    // otherwise just add the path folder
                    objectOfChildrenFolders = [foundChildFolders[i]];
                  }

                  // create a folder child object with the adequate folders
                  objectOfChildren[path] = {
                    folders: objectOfChildrenFolders,
                    showChildren: true,
                  };

                  // also find all children of the searched folder
                  if (foldersChildrenToSearch[foundChildFolders[i].ID]) {
                    objectOfChildren[foundChildFolders[i].ID] = foldersChildrenToSearch[foundChildFolders[i].ID];

                    const childFoldersOfFoundChild = foldersChildrenToSearch[foundChildFolders[i].ID].folders;

                    // create a recursive function to get the children of the found folder
                    const getAllChildFolderOfFoundChild = (chFoldersOfChild) => {
                      // loop through the folders of the searched folder
                      for (let chF = 0; chF < chFoldersOfChild.length; chF += 1) {
                        // if the folder[chF] is found
                        if (foldersChildrenToSearch[chFoldersOfChild[chF].ID]) {
                          // push it into folders children object
                          objectOfChildren[chFoldersOfChild[chF].ID] = foldersChildrenToSearch[
                            chFoldersOfChild[chF].ID
                          ];

                          // then get the folders of the folder[chF]
                          const foldersOfFolder = foldersChildrenToSearch[chFoldersOfChild[chF].ID].folders;

                          // if the folder[chF] has folders call this function again
                          if (foldersOfFolder.length) {
                            getAllChildFolderOfFoundChild(foldersOfFolder);
                          }
                        } else {
                          /*
                           * once the child folder doesn't have any child folders
                           * push that folder into folders children object
                           */
                          objectOfChildren[chFoldersOfChild[chF].ID] = {
                            folders: [],
                            showChildren: false,
                          };
                        }
                      }
                    };

                    getAllChildFolderOfFoundChild(childFoldersOfFoundChild);
                  }
                }
              });
            }
          }

          // if the main/top folder(s) is/are found
          if (filteredFolders.length) {
            /*
             * assign the folders children object to the filtered folders children object which will be used
             * as the children folders in the Folder component
             */
            filteredFoldersChildren = objectOfChildren;

            this.setState({ folders: filteredFolders, foldersChildren: filteredFoldersChildren });
          } else this.setState({ isFolderFound: false });
        }
      } else this.setState({ isFolderFound: false });
    } else this.setState({ folders: filteredFolders, foldersChildren: filteredFoldersChildren });

    this.setState({ loadingSearchedFolders: false });
  };

  /**
   * Helps to change a state
   * @param {object} newState - state that is going to be changed
   * @returns {void}
   */
  handleSetNewAutoCreatedTargetDEState = (newState) => {
    this.setState(newState);
  };

  /**
   * Determines whether folder should be disabled for a specific DE or not
   * @param {string} folderBusinessUnitId - id of the business unit of the folder
   * @returns {boolean} Should folder be disabled?
   */
  blockFolderForDE = (folderBusinessUnitId) => {
    const {
      targetDataExtension, editTargetDataExtension, userInfo, orgInfo,
    } = this.props;

    // Get loggedIn BU
    const { loggedInBusinessUnitId } = userInfo || {};

    if (folderBusinessUnitId && editTargetDataExtension &&
      loggedInBusinessUnitId !== orgInfo.enterpriseBusinessUnitId &&
      targetDataExtension?.Client?.ID !== folderBusinessUnitId
    ) return true;
  };

  /**
   * Determines whether folder should be disabled or not
   * @param {string} folderId - id of the folder
   * @param {string} folderBusinessUnitId - id of the business unit of the folder
   * @returns {boolean} Should folder be disabled?
   */
  greyOutFolder = (folderId, folderBusinessUnitId) => {
    const { foldersInclusionExclusionMap, settings } = this.state;
    const { isAvailableDEsFoldersModal } = this.props;

    const { includeOrExcludeTargetDEFolders, includeOrExcludeAvailableDEFolders } = settings;

    if (this.blockFolderForDE(folderBusinessUnitId)) return true;

    if (includeOrExcludeTargetDEFolders === Constants.INCLUDE_FOLDERS && !isAvailableDEsFoldersModal ||
      includeOrExcludeAvailableDEFolders === Constants.INCLUDE_FOLDERS && isAvailableDEsFoldersModal) {
      return !foldersInclusionExclusionMap[folderId];
    }

    if (includeOrExcludeTargetDEFolders === Constants.EXCLUDE_FOLDERS && !isAvailableDEsFoldersModal ||
      includeOrExcludeAvailableDEFolders === Constants.EXCLUDE_FOLDERS && isAvailableDEsFoldersModal) {
      return foldersInclusionExclusionMap[folderId];
    }

    return false;
  };

  handleModalCancel = () => {
    const {
      isAvailableDEsFoldersModal,
      isTargetDEsFoldersModal,
      handleSetAvailableExtensionsState,
      isScopedDEsFoldersModal,
      handleSetScopeModalsState,
    } = this.props;

    if ((isAvailableDEsFoldersModal || isTargetDEsFoldersModal) && !isScopedDEsFoldersModal) {
      handleSetAvailableExtensionsState({
        showTargetDEsFoldersModal: false,
        isAvailableDEsFoldersModal: false,
      });
    } else if (isScopedDEsFoldersModal) {
      handleSetScopeModalsState({ isFolderDEModalVisible: false, isAvailableFoldersModalVisible: false });
    }
  };

  render() {
    const {
      newTargetDataExtension,
      folders,
      showFoldersModal,
      loading,
      loadingSelectedFolder,
      folderId,
      foldersChildren,
      error,
      isSendableChecked,
      isTestableChecked,
      DE_name_error: DENameError,
      foldersError,
      settings,
      foldersInclusionExclusionMap,
      searchedFolder,
      foldersMap,
      isFolderFound,
      areAvailableFoldersLoading,
      shouldFolderBeSetAsSelected,
      loadingSearchedFolders,
      targetDEName,
    } = this.state;

    const {
      isCopySelectionModal,
      selectCopyTargetDE,
      loadingFoldersForCopy,
      isAvailableDEsFoldersModal,
      availableDEFolderId,
      isTargetDEsFoldersModal,
      editTargetDataExtension,
    } = this.props;

    // Set selected folder text
    const selectedFolderText = loadingSelectedFolder ? 'Loading...' : 'No folder found!';

    const availableDEsFoldersLoadingClassName = classNames(
      'slds-modal__content slds-p-around_medium',
      loadingSearchedFolders ? 'content-loading-search-folders' : 'content-spinner',
    );

    return (
      <>
        {(!isCopySelectionModal && !isAvailableDEsFoldersModal && !isTargetDEsFoldersModal) && (
          <>
            <div className="new-target-de-screen">
              <div className="title-container">
                <span className="title">
                  {editTargetDataExtension ? 'Edit Data Extension Settings' : 'Your New Data Extension'}
                </span>
                <p>
                  <Tip
                    title="Tip:"
                    description="The Target Data Extension is the Data Extension in which the results
                                of your Selection will be stored. This Data Extension can then be used
                                in a Journey, in Email Studio,...
                                Choose the settings for your new data extension below."
                    mode="dark"
                  />
                </p>
              </div>
              <div className="body">
                <div className={classNames(
                  'slds-form-element container',
                  { 'space-for-error': DENameError && selectCopyTargetDE },
                )}>
                  <label
                    className="slds-form-element__label new-target-de-label"
                    htmlFor="new-target-definition-name"
                  >
                    <abbr className="slds-required" title="required">
                      *
                      {' '}
                    </abbr>
                    Name
                  </label>
                  <Input
                    withContainer
                    forwardRef={(input) => { this.nameInput = input; }}
                    name="name"
                    id="new-target-definition-name"
                    placeholder=""
                    required
                    value={targetDEName}
                    onFocus={e => this.handleFocus(e)}
                    onChange={e => this.handleChange(e, true)}
                    maxLength="128"
                  />
                  {DENameError && selectCopyTargetDE &&
                    (<div className="de-name-error-alert">
                      <Alert
                        title="Data Extension with this name already exists."
                      />
                     </div>)}
                </div>
                <div className="slds-form-element container">
                  <label
                    className="slds-form-element__label new-target-de-label"
                    htmlFor="description"
                  >
                    Description
                  </label>
                  <div className="slds-form-element__control">
                    <textarea
                      id="description"
                      name="description"
                      className="slds-textarea"
                      placeholder=""
                      value={newTargetDataExtension.description}
                      aria-label="Description"
                      onChange={e => this.handleChange(e, false)}
                      maxLength="500"
                    />
                  </div>
                </div>
                <div className="location-container">
                  <label className="new-target-de-label">Location</label>
                  <div className="new-target-de-location container">
                    <div className="folder-with-icon">
                      <Button
                        className="change-new-target-de-location-v2"
                        onClick={this.handleSetInitialFolderId}
                        buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
                      >
                        <i className="fa fa-folder" aria-hidden="true" />
                      </Button>
                      <div
                        className="new-target-de-location-name"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: newTargetDataExtension.folderName ?
                            DOMPurify.sanitize(Util.abbreviate(newTargetDataExtension.folderName, 30)) :
                            selectedFolderText,
                        }}
                      />
                    </div>
                  </div>
                </div>
                <br />
                <div className="options-container container">
                  <span>Options</span>
                  <div className="subcontainers">
                    <ToggleButton
                      label="Make it sendable"
                      onChange={() => {
                        if (isSendableChecked) {
                          this.setState({ isTestableChecked: false });
                        }
                        this.setState({
                          isSendableChecked: !isSendableChecked,
                        });
                      }}
                      checked={isSendableChecked}
                    />
                    <p>
                      Sendable Data Extensions have one field that
                      relates to their Contacts either through Subscriber
                      Key or Subscriber Id.
                    </p>
                  </div>
                  <div className="subcontainers">
                    <ToggleButton
                      label="Make it testable"
                      onChange={() => this.setState({
                        isTestableChecked: !isTestableChecked,
                      })}
                      checked={isTestableChecked}
                      disabled={!isSendableChecked}
                    />
                    <p>
                      A Data Extension being &quot;testable&quot; means that it can be used for
                      testing purposes within your marketing campaigns and activities.
                    </p>
                  </div>
                  <DataRetentionPolicy
                    handleDataRetentionPolicyObjectAndCloseModal={
                      this.handleDataRetentionPolicyObjectAndCloseModal
                    }
                    {...newTargetDataExtension.dataRetentionPolicy}
                    editTargetDataExtension={editTargetDataExtension}
                  />
                </div>
                <div className="footer">
                  <Button
                    buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
                    onClick={() => this.handleCloseModal()}
                  >
                    Cancel
                  </Button>
                  <Button
                    buttonLook={Constants.BUTTON__TYPE__BRAND}
                    onClick={() => this.onSubmit()}
                    disabled={!!(DENameError ||
                      !newTargetDataExtension.folderId)}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>

            {/* Folders */}

            {showFoldersModal ?
              (
              <ModalTemplate
                id="second-modal"
                ariaHidden={showFoldersModal}
                className={showFoldersModal ? 'show change-location-target-de' : ''}
                headerId="modal-heading-02"
                headerTitle="Choose a Location"
                contentId="modal-content-id-2"
                contentClassName="slds-p-around_medium"
                cancelButtonId="cancel-btn"
                handleCancel={this.handleCancelFoldersModal}
                handleSave={this.handleFolderSelected}
                saveButtonId="ok-btn"
                saveButtonDisabled={!shouldFolderBeSetAsSelected}
              >
                <Folders
                  folders={folders}
                  loading={loading}
                  error={error}
                  id={folderId}
                  foldersChildren={foldersChildren}
                  handleFolderClicked={this.handleFolderClicked}
                  includeOrExcludeTargetDEFolders={settings.includeOrExcludeTargetDEFolders}
                  foldersInclusionExclusionMap={foldersInclusionExclusionMap}
                  greyOutFolder={this.greyOutFolder}
                  blockFolderForDE={this.blockFolderForDE}
                />
              </ModalTemplate>
              ) :
              null}
          </>
        )}

        {isCopySelectionModal && (
          <div className="component-for-copy-selection">
            <div className="name-copy-targetDE">
              <span style={{ color: !selectCopyTargetDE && '#dddbda' }}>TargetDE Name</span>
              <Input
                withContainer
                forwardRef={(input) => { this.nameInput = input; }}
                name="Name"
                id="new-target-definition-name"
                placeholder=""
                required
                className="copy-selection-modal field-name"
                value={selectCopyTargetDE ? newTargetDataExtension.Name : ''}
                disabled={!selectCopyTargetDE}
                onChange={e => this.handleChange(e, true)}
                maxLength="128"
              />
            </div>
            <Alert
              id="nameCopyError-1"
              className={DENameError && selectCopyTargetDE ? 'alert-visible' : 'alert-hidden'}
              title="Data Extension with this name already exists."
            />
            {loadingFoldersForCopy ?
              (
                <div className="preview-loader-container">
                  <Spinner
                    size={Constants.SPINNER__SIZE__X_SMALL}
                    className="left-8px"
                    loadingText="Loading Folders..."
                    loadingTextClassName="when-pressed pl-2rem"
                  />
                </div>
              ) :
              (
                <div className="copySelection-modal">
                  {foldersError ?
                    (
                      <div className="new-target-de-location">
                        {foldersError?.message || Constants.STATUS_ERROR_LABEL}
                      </div>
                    ) :
                    (
                      <div className="new-target-de-location">
                        <div className="folder-with-icon">
                          <Button
                            className="change-copy-target-de-location-v2"
                            onClick={this.handleSetInitialFolderId}
                            buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
                            disabled={!selectCopyTargetDE}
                          >
                            <i className="fa fa-folder" aria-hidden="true" />
                          </Button>
                          <div
                            className="new-target-de-location-name"
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                              __html: newTargetDataExtension.folderName ?
                                DOMPurify.sanitize(Util.abbreviate(newTargetDataExtension.folderName, 30)) :
                                'Data Extensions',
                            }}
                          />
                        </div>
                      </div>
                    )}
                  {/* Folders */}
                  <ModalTemplate
                    id="second-modal"
                    className={showFoldersModal ? 'show' : ''}
                    headerId="modal-heading-02"
                    headerTitle="Choose a Location"
                    contentId="copy-selection-modal-content-id-2"
                    contentClassName="slds-p-around_medium"
                    cancelButtonId="cancel-btn"
                    handleCancel={this.handleCancelFoldersModal}
                    handleSave={this.handleFolderSelected}
                    saveButtonId="ok-btn"
                    saveButtonDisabled={!shouldFolderBeSetAsSelected}
                  >
                    <Folders
                      folders={folders}
                      loading={loading}
                      error={error}
                      id={folderId}
                      foldersChildren={foldersChildren}
                      handleFolderClicked={this.handleFolderClicked}
                      includeOrExcludeTargetDEFolders={settings.includeOrExcludeTargetDEFolders}
                      foldersInclusionExclusionMap={foldersInclusionExclusionMap}
                      greyOutFolder={this.greyOutFolder}
                      blockFolderForDE={this.blockFolderForDE}
                    />
                  </ModalTemplate>
                </div>
              )}
          </div>
        )}

        {(isAvailableDEsFoldersModal || isTargetDEsFoldersModal) && (
          <ModalTemplate
            id="newAutoCreatedTDE-folder-modal"
            containerId="available-DEs-folders"
            headerId="modal-heading-02"
            headerTitle="Choose a Location"
            cancelButtonId="cancel-btn"
            contentId="available-de-search"
            handleCancel={this.handleModalCancel}
            handleSave={() => folderId && this.openAvailableDataExtensionModal(folderId)}
            saveButtonId="next-btn"
            saveButtonTitle="Next"
            saveButtonDisabled={!!(!folderId || (searchedFolder && this.shouldNextBtnBeDisabled(foldersChildren) &&
              !folders.find(f => f.ID === newTargetDataExtension.folderId)) || this.greyOutFolder(folderId))}
            withoutModalContent
          >
            <div className="search-data-extension-div">
              <div className="input-field-and-search-icon">
                <Input
                  className="search-data-extension"
                  forwardRef={this.searchFoldersInputRef}
                  onChange={e => this.handleSearch(e)}
                  value={searchedFolder}
                  placeholder="Search available folders"
                  id="search-available-folders"
                  noInputClassName
                />
                <span className="slds-icon_container search-available-folders-icon">
                  <svg className="slds-icon slds-icon-text-default slds-icon_x-small" aria-hidden="true">
                    <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#search" />
                  </svg>
                </span>
              </div>
            </div>
            {folders.length === 0 || loadingSearchedFolders ?
              (
                <div className={availableDEsFoldersLoadingClassName}>
                  <Spinner
                    size={loadingSearchedFolders ? Constants.SPINNER__SIZE__MEDIUM : Constants.SPINNER__SIZE__X_SMALL}
                    className="loading-available-folders-spinner"
                    loadingText={folders.length === 0 ? 'Loading Folders...' : null}
                    loadingTextClassName="loading-available-folders-label"
                  />
                </div>
              ) :
              (
                <div
                  className="slds-modal__content slds-p-around_medium"
                  id="available-folders-modal-content"
                >
                  {isFolderFound && (
                    <Folders
                      folders={folders}
                      loading={loading}
                      error={error}
                      id={folderId}
                      foldersChildren={foldersChildren}
                      handleFolderClicked={this.handleFolderClicked}
                      includeOrExcludeAvailableDEFolders={settings.includeOrExcludeAvailableDEFolders}
                      foldersInclusionExclusionMap={foldersInclusionExclusionMap}
                      isAvailableDEsFoldersModal={isAvailableDEsFoldersModal}
                      searchedFolder={searchedFolder}
                      setSelectedFolder={this.setSelectedFolder}
                      foldersMap={foldersMap}
                      areAvailableFoldersLoading={areAvailableFoldersLoading}
                      handleSetNewAutoCreatedTargetDEState={this.handleSetNewAutoCreatedTargetDEState}
                      availableDEFolderId={availableDEFolderId}
                      greyOutFolder={this.greyOutFolder}
                      isTargetDEsFoldersModal={isTargetDEsFoldersModal}
                      blockFolderForDE={this.blockFolderForDE}
                    />
                  )}
                  {!isFolderFound && (
                    <div className="folder-no-data-extensions">
                      <span>No Folders found</span>
                    </div>
                  )}
                </div>
              )}
          </ModalTemplate>
        )}
      </>
    );
  }
}

NewAutoCreatedTargetDE.propTypes = {
  /**
   * It stores the new target data extension state
   * It will be passed from Selection.js or from CopySelectionModal.js
   */
  newTargetDataExtension: PropTypes.instanceOf(Object).isRequired,
  /**
   * hide salesforce DE when creating Target DE
   */
  isChangeLocation: PropTypes.bool,
  /**
   * It helps to set the Selection`s state
   * It will be passed from Selection.js
   */
  handleSetSelectionState: PropTypes.func,
  /**
   * It keeps the previos matchedFields state when clicked on the create new de button
   * It will be passed from Selection.js
   */
  prevMatchedFields: PropTypes.instanceOf(Array),
  /**
   * It keeps the value of ac/de creation status
   * It will be passed from Selection.js
   */
  editNewAutoTargetDE: PropTypes.bool,
  /**
   * It keeps the matchedFields for a target data extension of the Selection
   * It will be passed from Selection.js
   */
  matchedFields: PropTypes.instanceOf(Array),
  /**
   * It keeps the data extensions which will be show in target definition page
   * It will be passed from Selection.js or from CopySelectionModal.js
   */
  targetDataExtensions: PropTypes.instanceOf(Array),
  /**
   * It helps to set the new state in CopySelectionModal
   */
  handleSetNewCopyState: PropTypes.func,
  /**
   * It helps to display only specific components for CopySelectionModal,
   * It will be passed only from CopySelectionModal
   */
  isCopySelectionModal: PropTypes.bool,
  /**
   * Determines whether the copy Target Data Extension in CopySelectionModal is selected or not.
   */
  selectCopyTargetDE: PropTypes.bool,
  /**
   * It keeps information about whether folders are loaded or not
   */
  loadingFoldersForCopy: PropTypes.bool,
  /**
   * If we copy to another BU
   */
  copyToOtherBU: PropTypes.bool,
  /**
   * If we copy to another BU, we pass selected BU
   */
  selectedBusinessUnit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Ref element of radio button
   */
  refBtn: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.elementType }),
  ]),
  /**
   * Function throws swal if an error occurs
   */
  showErrorMessage: PropTypes.func,
  /**
   * Determines if we are using this component in the Available Folders component
   */
  isAvailableDEsFoldersModal: PropTypes.bool,
  /**
   * Id of the selected available DE folder
   */
  availableDEFolderId: PropTypes.number,
  /**
   * Sets the state in the AvailableExtensions component
   */
  handleSetAvailableExtensionsState: PropTypes.func,
  /**
   * Opens the FolderDataExtensions component
   */
  openAvailableDEsForCertainFolderModal: PropTypes.func,
  /**
   * It keeps data for selected target data extension
   */
  targetDataExtension: PropTypes.instanceOf(Object),
  /**
   * Indicates if target data extension is updating
   */
  editTargetDataExtension: PropTypes.bool.isRequired,
  /**
   * Stored availableDEs folders in selection state (Stored in order to prevent making unnecessary api calls)
   */
  availableDEsFolders: PropTypes.instanceOf(Array),
  /**
   * Keeps folder filtering information
   */
  foldersSettings: PropTypes.instanceOf(Object),
  /**
   * Determines if we are using this component from the TargetDE component
   */
  isTargetDEsFoldersModal: PropTypes.bool,
  /**
   * Stored targetDEsFolders folders in selection state (Stored in order to prevent making unnecessary api calls)
   */
  targetDEsFolders: PropTypes.instanceOf(Array),
  /**
   * Stores the selection name
   */
  selectionName: PropTypes.string.isRequired,
  /**
   * Object containing default send relationship settings
   */
  defaultSendRelationshipField: PropTypes.object,
  /**
   * It keeps the fields of an existing target data extension
   * It will be passed from Selection.js
   */
  targetDataExtensionFields: PropTypes.instanceOf(Array),

  /**
   * Check for whether we are in scoped DEs folders modal
   */
  isScopedDEsFoldersModal: PropTypes.bool,

  /**
   * Sets fetched available folders
   */
  setAvailableFolders: PropTypes.func,

  /**
   * Function to set folders settings for scoped DEs
   */
  setFoldersSettings: PropTypes.func,
  /**
   * Function to set state of scope modals
   */
  handleSetScopeModalsState: PropTypes.func,
  /**
   * User info from cookie
   */
  userInfo: PropTypes.object,
  /**
   * Org info from cookie
   */
  orgInfo: PropTypes.object,
};

NewAutoCreatedTargetDE.defaultProps = {
  /**
   * Indicates default newTargetDataExtension object
   */
  newTargetDataExtension: {},
};

export default connect(mapStateToProps(['userInfo', 'orgInfo']), null, null, { pure: false })(NewAutoCreatedTargetDE);
