
import Constants from '../../../constants/constants';
import timeUtil from '../../../utils/time/timeUtil';
import TimezoneList from '../../Selection/ScheduleSelectionModal/TimezoneList.json';

const DefaultValues = {
  [Constants.CUSTOM_VALUES__FIELD_TYPE__FIXED_VALUE]: {
    fieldType: Constants.FILTERLINE__FIELDTYPE__TEXT,
    value: '',
    valueType: Constants.CUSTOM_VALUES__FIELD_TYPE__FIXED_VALUE,
  },
  [Constants.CUSTOM_VALUES__FIELD_TYPE__DYNAMIC_VALUE]: {
    valueType: Constants.CUSTOM_VALUES__FIELD_TYPE__DYNAMIC_VALUE,
    fieldType: Constants.FILTERLINE__FIELDTYPE__TEXT,
    criteria: [],
    defaultValue: {
      defaultValue: '',
      defaultDataExtensionObjectId: '',
      defaultFieldObjectId: '',
      defaultDataExtensionAlias: '',
    },
  },
  [Constants.CUSTOM_VALUES__FORMULA_TYPE__TRANSFORM]: {
    valueType: Constants.CUSTOM_VALUES__FIELD_TYPE__FORMULA,
    formula: Constants.CUSTOM_VALUES__FORMULA_TYPE__TRANSFORM,
    fieldType: Constants.FILTERLINE__FIELDTYPE__DATE,
    dataExtensionCustomerKey: null,
    dataExtensionObjectId: null,
    fieldObjectId: null,
    addUnit: Constants.FILTERLINE__DATE_VALUE__INTERVAL_HOUR,
    addValue: '0',
    format: Constants.CUSTOM_VALUES__FORMULA_FORMAT__DATETIME,
    convert: false,
    convertToTimezone: timeUtil.getCurrentUserTimezone(TimezoneList, true),
    collectionAlias: null,
    convertFromTimezoneQuery: Constants.TIME_ZONE__CENTRAL_STANDARD_TIME__VALUE,
  },
  [Constants.CUSTOM_VALUES__FORMULA_TYPE__TIMESTAMP]: {
    valueType: Constants.CUSTOM_VALUES__FIELD_TYPE__FORMULA,
    formula: Constants.CUSTOM_VALUES__FORMULA_TYPE__TIMESTAMP,
    fieldType: Constants.FILTERLINE__FIELDTYPE__DATE,
    format: Constants.CUSTOM_VALUES__FORMULA_FORMAT__NOW,
    convert: false,
    convertToTimezone: timeUtil.getCurrentUserTimezone(TimezoneList, true),
  },
  [Constants.CUSTOM_VALUES__FORMULA_TYPE__DATE_DIFFERENCE]: {
    valueType: Constants.CUSTOM_VALUES__FIELD_TYPE__FORMULA,
    formula: Constants.CUSTOM_VALUES__FORMULA_TYPE__DATE_DIFFERENCE,
    fieldType: Constants.FILTERLINE__FIELDTYPE__NUMBER,
    field1: {
      dataExtensionCustomerKey: null,
      dataExtensionObjectId: null,
      fieldObjectId: null,
      format: Constants.CUSTOM_VALUES__FORMULA_FORMAT__FIELD,
      collectionAlias: null,
    },
    field2: {
      dataExtensionCustomerKey: null,
      dataExtensionObjectId: null,
      fieldObjectId: null,
      format: Constants.CUSTOM_VALUES__FORMULA_FORMAT__FIELD,
      collectionAlias: null,
    },
    output: Constants.FILTERLINE__DATE_VALUE__INTERVAL_HOUR,
  },
  [Constants.CUSTOM_VALUES__FORMULA_TYPE__ROW_NUMBER]: {
    orderBy: {
      collectionAlias: '',
      fieldObjectID: '',
    },
    sort: Constants.PRIO_DEDUP__SORT_ORDER__ASC,
  },
  [Constants.CUSTOM_VALUES__FORMULA_TYPE__FREE_FORMULA]: {
    valueType: Constants.CUSTOM_VALUES__FIELD_TYPE__FORMULA,
    formula: Constants.CUSTOM_VALUES__FORMULA_TYPE__FREE_FORMULA,
    fieldType: Constants.FILTERLINE__FIELDTYPE__TEXT,
    value: '',
  },
  [Constants.CUSTOM_VALUES__FIELD_TYPE__AGGREGATION_VALUE]: {
    formula: null,
    dataExtensionCustomerKey: null,
    fieldObjectId: null,
    relations: {
      fromFieldObjectId: null,
      fromCollectionObjectId: null,
      toCollectionObjectId: null,
      fromCollectionCustomerKey: null,
      toCollectionAlias: null,
      toFieldObjectId: null,
      relationId: null,
    },
    filters: [],
  },
};

export default DefaultValues;
