import store from '../../../redux/store/store';

export function featureAdvertExists(feature) {
  // grab current state
  const state = store.getState();
  const { featureAdvertsReducer } = state;
  const { featureAdverts } = featureAdvertsReducer;

  // check if feature advert exists or if status of advert is true
  const advert = featureAdverts?.find(featureAdvert => featureAdvert.feature === feature && featureAdvert.status);

  return advert;
}
