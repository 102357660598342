import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Swal from 'sweetalert2';
import classNames from 'classnames';
import { useTransition, animated } from '@react-spring/web';
import './styles.scss';
import { connect } from 'react-redux';

import mapStateToProps from '../../../mapStateToProps';
import SelectScopeController from '../SelectScopeController/SelectScopeController';
import ScopedDataExtensions from '../ScopedDataExtensions/ScopedDataExtensions';
import ModalTemplate from '../../shared/ModalTemplate/ModalTemplate';
import DeedeeAIAPI from '../../../api/deedeeAI';
import Constants from '../../../constants/constants';
import Deedee from '../../../gif/deedee-summer-animation-sky.gif';

/**
 * Modal for editing scoped data extensions.
 *
 * @param {Object} props - Component properties.
 * @param {Function} props.setOpenScopedDEsModal - Function to control modal visibility.
 * @param {Array} props.deedeeAIScopeDEs - Array of data extensions for DeedeeAI scope.
 * @param {Function} props.handleSetAppState - Function to set parent component state.
 *
 * @returns {JSX.Element} ScopedModal component.
 */
function ScopedModal({
  setOpenScopedDEsModal,
  deedeeAIScopeDEs,
  handleSetAppState,
  setOpenDeedeeAIModal,
  userInfo,
}) {
  const [showOnboarding, setShowOnboarding] = useState(true);
  const [scopeDEs, setScopeDEs] = useState(deedeeAIScopeDEs || []);
  const [initialScopeDEs, setInitialScopeDEs] = useState(
    deedeeAIScopeDEs || [],
  );
  const [scopeDataExtensionsToAdd, setScopeDataExtensionsToAdd] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  const axiosCancelToken = axios.CancelToken.source();

  useEffect(() => {
    setInitialScopeDEs(deedeeAIScopeDEs || []);
  }, [deedeeAIScopeDEs]);

  /**
   * Add a data extension to the scope.
   *
   * @param {Object} de - The data extension to add.
   * @param {string} de.ObjectID - The identifier for the data extension.
   * @param {string} de.CustomerKey - The customer key for the data extension.
   * @param {string} de.Name - The name of the data extension.
   * @returns {void}
   */
  const handleAddScopeDE = (de) => {
    if (scopeDEs.length >= Constants.SCOPE_DES_LIMIT) {
      Swal.fire({
        icon: 'warning',
        title: 'Scope Limit Reached',
        html: `<p class="width_swal scope-de-width_swal">
        You cannot add more than ${Constants.SCOPE_DES_LIMIT} scope data extensions.</p>`,
        showCancelButton: false,
        confirmButtonText: 'OK',
        buttonsStyling: false,
        footer: '<div></div>',
        allowOutsideClick: false,
      });

      return;
    }
    if (scopeDataExtensionsToAdd.some(ext => ext.ObjectID === de.ObjectID)) {
      setScopeDataExtensionsToAdd(prevDEs => prevDEs.filter(ext => ext.ObjectID !== de.ObjectID));
    } else {
      setScopeDataExtensionsToAdd(prevDEs => [...prevDEs, de]);
    }
  };

  /**
   * Show a Swal message.
   *
   * @param {string} type - Type of Swal (e.g., 'error', 'success').
   * @param {string} title - Title of Swal.
   * @param {string} message - Message of Swal.
   * @returns {Promise} A promise resolved when Swal is closed.
   */
  const showSwal = (type, title, message) => {
    return Swal.fire({
      icon: type,
      title: `<div class="${classNames({
        'error-title': type === Constants.SWAL__TYPE__ERROR,
      })}">${title}</div>`,
      html: `<p class="width_swal scope-de-width_swal">${message}</p>`,
      showCancelButton: true,
      confirmButtonText: 'Save',
      cancelButtonText: 'Cancel',
      buttonsStyling: false,
      footer: '<div></div>',
      allowOutsideClick: false,
    });
  };

  /**
   * Save selected data extensions.
   * Add unique data extensions from scopeDataExtensionsToAdd to scopeDEs.
   * @returns {void}
   */
  const handleSaveSelectedScopeDEs = () => {
    const uniqueScopeDEsToAdd = scopeDataExtensionsToAdd
      .filter(deToAdd => !scopeDEs.some(de => de.deObjectID === deToAdd.ObjectID))
      .map(deToAdd => ({
        businessUnitId: userInfo.loggedInBusinessUnitId,
        deObjectID: deToAdd.ObjectID,
        customerKey: deToAdd.CustomerKey,
        name: deToAdd.Name,
        alias: deToAdd.Name,
        description: '',
        orgId: userInfo.orgId,
      }));

    const spaceRemaining = Constants.SCOPE_DES_LIMIT - scopeDEs.length;
    const desToAdd = uniqueScopeDEsToAdd.slice(0, spaceRemaining);
    const remainingDEs = uniqueScopeDEsToAdd.slice(spaceRemaining);

    if (remainingDEs.length > 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Scope Limit Reached',
        html: `<p class="width_swal scope-de-width_swal">
        You cannot add more than ${Constants.SCOPE_DES_LIMIT} scope data extensions.
        ${remainingDEs.length} of your selected data extensions were not added.</p>`,
        showCancelButton: false,
        confirmButtonText: 'OK',
        buttonsStyling: false,
        footer: '<div></div>',
        allowOutsideClick: false,
      });
    }

    setScopeDEs(prevScopeDEs => [...prevScopeDEs, ...desToAdd]);
    setScopeDataExtensionsToAdd(remainingDEs); // Keep the extensions that weren't added because of the limit
  };

  /**
   * Edit an existing data extension.
   *
   * @param {string} deObjectID - The identifier for the data extension.
   * @param {string} alias - The new alias for the data extension.
   * @param {string} description - The new description for the data extension.
   * @returns {void}
   */
  const handleEditDE = (deObjectID, alias, description) => {
    setScopeDEs(prevDEs => prevDEs.map(de => de.deObjectID === deObjectID ? { ...de, alias, description } : de));
  };

  /**
   * Delete an existing data extension.
   *
   * @param {string} deObjectID - The identifier for the data extension.
   * @returns {void}
   */
  const handleDeleteDE = (deObjectID) => {
    setScopeDEs(prevDEs => prevDEs.filter(de => de.deObjectID !== deObjectID));
  };

  /**
   * Add a new data extension.
   * @param {Object} newDE - The new data extension.
   * @returns {void}
   */
  const handleAddDE = (newDE) => {
    if (!scopeDEs.some(de => de.deObjectID === newDE.deObjectID)) {
      setScopeDEs(prevScopeDEs => [...prevScopeDEs, newDE]);
    }
  };

  /**
   * Update scoped data extensions.
   * New scoped data extensions are added and existing scoped data extensions are updated.
   * @returns {void}
   */
  const handleUpdateScopeDEs = async () => {
    const missingFields = [];

    scopeDEs.forEach((de, index) => {
      if (!de.alias) {
        missingFields.push({ index, field: 'Alias', name: de.name });
      }
      if (!de.description) {
        missingFields.push({ index, field: 'Content', name: de.name });
      }
    });

    if (missingFields.length > 0) {
      let errorMessage = 'The following fields require to be filled:<ul class="scope-missing-fields-li><br/>';

      missingFields.forEach((missingField) => {
        errorMessage += `<li">
        - <strong>${missingField.name}</strong> is missing <i>${missingField.field}</i></li>`;
      });

      errorMessage += '</ul>';

      Swal.fire({
        icon: 'error',
        type: Constants.SWAL__TYPE__WARNING,
        title: 'Missing Fields',
        html: `<div class="scope-width_swal scope-missing-fields-width_swal">
        ${errorMessage}</div>`,
        showCancelButton: false,
        confirmButtonText: 'OK',
        buttonsStyling: false,
        footer: '<div></div>',
        allowOutsideClick: false,
      });

      return;
    }

    if (!Array.isArray(scopeDEs) || scopeDEs.length > Constants.SCOPE_DES_LIMIT) {
      Swal.fire({
        type: Constants.SWAL__TYPE__WARNING,
        title: 'Scope Limit Reached',
        html: `<p class="width_swal scope-de-width_swal">
        You cannot add more than ${Constants.SCOPE_DES_LIMIT} scope data extensions.</p>`,
        showCancelButton: false,
        confirmButtonText: 'OK',
        buttonsStyling: false,
        footer: '<div></div>',
        allowOutsideClick: false,
      });

      return;
    }

    try {
      setIsSaving(true);

      const addedDEs = scopeDEs.filter(
        de => !initialScopeDEs.some(
          initialDE => initialDE.deObjectID === de.deObjectID,
        ),
      );

      const deletedDEs = initialScopeDEs.filter(
        initialDE => !scopeDEs.some(de => de.deObjectID === initialDE.deObjectID),
      );

      const swalMessage = `
      <div class="swal-changes">
        ${addedDEs.length > 0 ? '<div class="swal-changes__title">Add to Deedee AI scope:</div>' : ''}
        <ul class="swal-changes__list">${addedDEs.map(de => `<li>${de.name}</li>`).join('')}</ul>
        <br />
        ${deletedDEs.length > 0 ? '<div class="swal-changes__title">Remove from Deedee AI scope:</div>' : ''}
        <ul class="swal-changes__list">${deletedDEs.map(de => `<li>${de.name}</li>`).join('')}</ul>
      </div>
    `;

      const swalResult = await showSwal(
        Constants.SWAL__TYPE__WARNING,
        'Save Changes',
        swalMessage,
      );

      if (swalResult.isConfirmed) {
        const response = await DeedeeAIAPI.updateScopeDataExtensions(
          scopeDEs,
          axiosCancelToken.token,
        );

        handleSetAppState(prevState => ({
          ...prevState,
          deedeeAIScopeDEs: response.data,
        }));

        setOpenScopedDEsModal(false);
        setOpenDeedeeAIModal(true);
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        showSwal(Constants.SWAL__TYPE__ERROR, 'Error', error);
        setScopeDEs(initialScopeDEs);
      }
    } finally {
      setIsSaving(false);
    }
  };

  /**
   * Handle clicking the next button.
   * If the on-boarding screen is showing, hide it.
   * Otherwise, save the selected data extensions.
   * @returns {void}
   */
  const handleClickNext = () => {
    if (showOnboarding) {
      setShowOnboarding(false);
    } else {
      handleUpdateScopeDEs();
    }
  };

  /**
   * Handle closing the modal.
   * Discard changes if the modal is closed without saving.
   * @returns {void}
   */
  const handleCloseModal = () => {
    if (!isSaving) {
      // eslint-disable-next-line no-negated-condition
      if (JSON.stringify(scopeDEs) !== JSON.stringify(initialScopeDEs)) {
        // Display a confirmation dialog using Swal
        Swal.fire({
          type: Constants.SWAL__TYPE__WARNING,
          title: 'Unsaved Changes',
          html: '<p class="width_swal">You have unsaved changes. Are you sure you want to close the modal?</p>',
          showCancelButton: true,
          confirmButtonText: 'Discard Changes',
          cancelButtonText: 'Cancel',
          buttonsStyling: false,
          footer: '<div></div>',
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            setScopeDEs(initialScopeDEs);
            setOpenScopedDEsModal(false);
          }
        });
      } else {
        setOpenScopedDEsModal(false);
      }
    }
  };

  // Animation styles for the onboarding screen
  const onboardingTransitions = useTransition(showOnboarding, {
    from: { opacity: 0, transform: 'translateY(-20px)' },
    enter: { opacity: 1, transform: 'translateY(0)' },
    leave: { display: 'none', transform: 'translateY(-20px)' }, // Updated configuration
    config: { tension: 220, friction: 25 },
  });

  return (
    <div className="scope-des-modal">
      <ModalTemplate
        id="scoped-des-modal-dialog"
        className="slds-modal_large"
        headerId="modal-heading-01"
        headerTitle="Deedee AI"
        contentClassName="slds-p-around_medium"
        contentId="modal-content-id-1"
        saveButtonId="scoped-des-dialog-save"
        saveButtonTitle={isSaving ? 'Saving...' : 'Continue'}
        saveButtonDisabled={isSaving || (!showOnboarding && scopeDEs.length === 0)}
        containerClassName="scoped-des-modal-container"
        handleSave={handleClickNext}
        handleCloseModal={handleCloseModal}
        showCancelIcon
      >
        {/* Onboarding screen */}
        {onboardingTransitions((styles, item) => item ?
          (
            <animated.div style={styles} className="onboarding-screen">
              <h2 className="onboarding-screen__title">Welcome to Deedee AI</h2>
              <img
                src={Deedee}
                alt="Welcome onboarding message"
                className="onboarding-screen__image"
              />
              <ul className="onboarding-screen-body">
                <ul className="onboarding-screen__list">
                  <li className="onboarding-screen__text">
                    <strong>
                      Experience the power of AI-driven selections:
                    </strong>
                    {' '}
                    Deedee AI revolutionizes the way you create selections, with a
                    simple prompt, you can generate sophisticated selections.
                  </li>
                  <li className="onboarding-screen__text">
                    <strong>Uncover deeper insights with ease:</strong>
                    {' '}
                    Unleash the power of AI to unlock valuable insights and explore your data like never before.
                  </li>
                  <li className="onboarding-screen__text">
                    <strong>Getting started:</strong>
                    {' '}
                    To get started with Deedee AI,
                    please select the data extensions you want Deedee AI to use while creating your selections
                  </li>

                </ul>
              </ul>
            </animated.div>
          ) :
          null)}

        {/* Select Scope Controller and Scoped Data Extensions */}
        {!showOnboarding && (
          <>
            <div>
              <SelectScopeController
                axiosCancelToken={axiosCancelToken}
                handleAddScopeDE={handleAddScopeDE}
                scopeDataExtensionsToAdd={scopeDataExtensionsToAdd}
                handleSaveSelectedScopeDEs={handleSaveSelectedScopeDEs}
                scopeDEs={scopeDEs}
                isSavingChanges={isSaving}
              />
            </div>

            <div>
              <ScopedDataExtensions
                handleEditDE={handleEditDE}
                handleDeleteDE={handleDeleteDE}
                handleAddDE={handleAddDE}
                scopeDEs={scopeDEs}
              />
            </div>
          </>
        )}
      </ModalTemplate>
    </div>
  );
}

ScopedModal.propTypes = {
  setOpenScopedDEsModal: PropTypes.func.isRequired, // Function to control modal visibility
  deedeeAIScopeDEs: PropTypes.array, // Array of data extensions for DeedeeAI scope
  handleSetAppState: PropTypes.func.isRequired, // Function to set parent component state
  setOpenDeedeeAIModal: PropTypes.func, // Function to manage state of prompt modal
  /**
   * User info from cookie
   */
  userInfo: PropTypes.object,
};

export default connect(mapStateToProps(['userInfo']), null, null, { pure: false })(ScopedModal);
