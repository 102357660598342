import React, { useState, useCallback } from 'react';
import ReactDragListView from 'react-drag-listview';
import PropTypes from 'prop-types';
import './AdvancedPrioFiltersContainer.scss';
import { connect } from 'react-redux';

import mapStateToProps from '../../../../../../mapStateToProps';
import Button from '../../../../../shared_v2/Button/Button';
import filtersUtil from '../../../../../../utils/filters/filtersUtil';
import SwalUtil from '../../../../../../utils/swal/swalUtil';

const AdvancedPrioFiltersContainer = ({
  advancedDeduplicationRules,
  openCriteriaModal,
  handleSetSelectionState,
  pickLists,
  userInfo,
}) => {
  /*
   * using React Hooks to re-render the component
   * after a filter rule is removed
   */
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  /**
   * function passed to the ReactDragListView component to change the order of the deduplication rules
   * based on the user drag and dropping actions
   * @param {integer} fromIndex - Index where the rule is coming from
   * @param {integer} toIndex - Index where the rule is coming to
   * @returns {void}
   */
  const onDragEnd = (fromIndex, toIndex) => {
    const newRules = advancedDeduplicationRules;

    const draggedFilter = newRules.splice(fromIndex, 1)[0];

    newRules.splice(toIndex, 0, draggedFilter);

    handleSetSelectionState({ advancedDeduplicationRules: newRules });
    forceUpdate();
  };

  /**
   * Handles the edit rule button click
   * @param {Object} event - Event
   * @param {integer} ruleIndex - Index of the rule
   * @returns {void}
   */
  const handleEditRule = (event, ruleIndex) => {
    event.preventDefault();

    handleSetSelectionState({
      previousAdvancedDeduplicationRules: JSON.parse(JSON.stringify(advancedDeduplicationRules)),
      advancedDedupFilterSaveIndex: ruleIndex,
    });

    openCriteriaModal();
  };

  /**
   * Handles the remove rule button click
   * @param {Object} event - event
   * @param {integer} ruleIndex - index of the rule
   * @returns {void}
   */
  const handleRemoveRule = async (event, ruleIndex) => {
    event.preventDefault();
    const result = await SwalUtil.fire({
      title: 'Remove Rule',
      message: 'Are you sure you want to remove this rule?',
      options: {
        showCancelButton: true,
        confirmButtonText: 'Remove rule',
      },
    });

    if (result.value) {
      const newRules = advancedDeduplicationRules;

      newRules.splice(ruleIndex, 1);

      handleSetSelectionState({
        advancedDeduplicationRules: newRules,
      });

      forceUpdate();
    }
  };

  /**
   * Handles the copy rule button click
   * @param {Object} event - Event
   * @param {integer} ruleIndex - Index of the rule
   * @returns {void}
   */
  const handleCopyRule = (event, ruleIndex) => {
    event.preventDefault();

    handleSetSelectionState({ advancedDedupFilterSaveIndex: ruleIndex });

    const clonedRule = JSON.parse(JSON.stringify(advancedDeduplicationRules[ruleIndex]));
    const newRules = advancedDeduplicationRules;

    newRules.push(clonedRule);

    handleSetSelectionState({
      advancedDeduplicationRules: newRules,
    });
    forceUpdate();
  };

  return (
    <div className="advanced-prio-filters-container-v2">
      <ReactDragListView nodeSelector="li" handleSelector="#drag" onDragEnd={onDragEnd}>
        <ol>
          {advancedDeduplicationRules.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={index} className="slds-box">
              {/* DRAG BUTTON */}
              <Button
                title="Drag this card to change its order"
                id="drag"
                className="drag-and-drop"
              >
                <svg className="slds-button__icon" aria-hidden="true">
                  <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#drag_and_drop" />
                </svg>
              </Button>
              <span className="rule-index">{index + 1}</span>
              <p title={filtersUtil.getFilterTextForFilters(
                item.filters,
                item.operator,
                false,
                pickLists,
                userInfo,
              )}>
                {filtersUtil.getFilterTextForFilters(item.filters, item.operator, false, pickLists, userInfo)}
              </p>
              <div className="advanced-prio-icons">
                {/* EDIT BUTTON */}
                <Button
                  title="Edit Rule"
                  buttonIconBorderFilled
                  className="copy-line"
                  id="edit-rule"
                  onClick={e => handleEditRule(e, index)}
                >
                  <i className="fas fa-pencil-alt" />
                </Button>
                {/* REMOVE BUTTON */}
                <Button
                  title="Remove Rule"
                  className="remove-line"
                  id="remove-rule"
                  buttonIconBorderFilled
                  onClick={async e => handleRemoveRule(e, index)}
                >
                  <svg className="slds-button__icon" aria-hidden="true">
                    <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#delete" />
                  </svg>
                </Button>
                {/* COPY BUTTON */}
                <Button
                  title="Copy Rule"
                  className="copy-line"
                  id="copy-rule"
                  buttonIconBorderFilled
                  onClick={e => handleCopyRule(e, index)}
                >
                  <svg className="slds-button__icon" aria-hidden="true">
                    <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#copy" />
                  </svg>
                </Button>
              </div>
            </li>
          ))}
        </ol>
      </ReactDragListView>
    </div>
  );
};

AdvancedPrioFiltersContainer.propTypes = {
  /**
   * Rules for the advanced deduplication, comes from Selection.js
   */
  advancedDeduplicationRules: PropTypes.instanceOf(Array).isRequired,
  /**
   * sets the criteriaModal state value to true
   */
  openCriteriaModal: PropTypes.func.isRequired,
  /**
   * function to set the state in Selection.js
   */
  handleSetSelectionState: PropTypes.func.isRequired,
  /**
   * Keeps searched picklist
   * It will be passed from Selection.js
   */
  pickLists: PropTypes.instanceOf(Array).isRequired,
  /**
   * User info from cookie
   */
  userInfo: PropTypes.object,
};

export default connect(mapStateToProps(['userInfo']), null, null, { pure: false })(AdvancedPrioFiltersContainer);
