/* eslint-disable spellcheck/spell-checker */
import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

import timeUtil from '../../utils/time/timeUtil';

const SelectionRunLogsModalLine = ({
  selectionRunLog,
  handleSelectRunLog,
  userDateTimeFormat,
  showTriggerMode,
  showSourceLabel,
  disableRunDetailsForRunningSelection,
}) => {
  const showStatusIcon = () => {
    // in case of error show error icon
    if (selectionRunLog?.success === false) {
      return (
        <svg
          className="slds-icon slds-icon-text-default slds-icon_x-small status-error"
          aria-hidden="true"
        >
          <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#error" />
        </svg>
      );
    }

    if(disableRunDetailsForRunningSelection(selectionRunLog)) {
      // if selection is running show processing icon
      return (
        <svg
        className="slds-icon slds-icon-text-default slds-icon_x-small status-queued running-query-icon"
        aria-hidden="true"
        >
        <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#automate" />
        </svg>
      );
    }

    // in other case show check icon
    return (
      <svg className="slds-button__icon status-success" aria-hidden="true">
        <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#check" />
      </svg>
    );
  };

  return (

        <tr>
        <td>
            <span className="des-lib-datatable-action-last-run-date">
            {timeUtil.formatDate(selectionRunLog.startDateTime, userDateTimeFormat)}
            </span>
        </td>
        <td>{showTriggerMode(selectionRunLog)}</td>
        <td>{showSourceLabel(selectionRunLog)}</td>
        <td>
        {showStatusIcon()}
        </td>
        <td>
            <span
            onClick={() => handleSelectRunLog(selectionRunLog)}
className="des-lib-datatable-last-run"
            >
                See Details
            </span>
        </td>
        </tr>
  );
};

SelectionRunLogsModalLine.propTypes = {
  /**
   * SelectionRunLog object
   */
  selectionRunLog: PropTypes.instanceOf(Object).isRequired,
  /**
   * Function to handle the selection of the run log
   */
  handleSelectRunLog: PropTypes.func.isRequired,
  /**
   * Time format read from user's cookies
   */
  userDateTimeFormat: PropTypes.string.isRequired,
  /**
   * Function that returns trigger mode label for run log
   */
  showTriggerMode: PropTypes.func.isRequired,
  /**
   * Function that returns source label for run log
   */
  showSourceLabel: PropTypes.func.isRequired,
  /**
   * Function that helps to disable run details button when selection is running
   */
  disableRunDetailsForRunningSelection: PropTypes.func.isRequired,
};

export default SelectionRunLogsModalLine;
