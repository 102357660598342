import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextArea } from 'semantic-ui-react';

import Constants from '../../../constants/constants';
import Table from '../../shared/Table/Table';
import Input from '../../shared/Input/Input';
import Button from '../../shared/Button/Button';
import SwalUtil from '../../../utils/swal/swalUtil';
import Tooltip from '../../shared/Tooltip/Tooltip';

import './styles.scss';

const ScopedDataExtensions = ({ handleEditDE, handleDeleteDE, scopeDEs }) => {
  const [errorFields, setErrorFields] = useState({});
  const [sortState, setSortState] = useState({
    sortedProperty: null,
    sortDirection: Constants.SORT_DIRECTION__ASCENDING,
  });
  // Handles pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [scopesPerPage] = useState(5);

  /**
   * Sets the current page to the provided pageNumber.
   *
   * @param {number} pageNumber - The page number to set as the current page.
   * @returns {void}
   */
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calculate total number of pages
  const totalPages = Math.ceil(scopeDEs.length / scopesPerPage);

  // Generate an array of page numbers
  const pageNumbers = Array.from(
    { length: totalPages },
    (_, index) => index + 1,
  );

  /**
   * Increases the current page by one, if it is not the last page.
   *
   * @returns {void}
   */
  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  /**
   * Decreases the current page by one, if it is not the first page.
   *
   * @returns {void}
   */
  const goToPrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  const tableHeadersData = [
    {
      title: 'Name',
      propertyName: 'name',
      leftSize: Constants.TABLE__CELL_LEFT_SIZE__SMALL,
    },
    {
      title: 'Alias',
      propertyName: 'alias',
      leftSize: Constants.TABLE__CELL_LEFT_SIZE__X_SMALL,
    },
    {
      title: 'Content',
      propertyName: 'description',
      leftSize: Constants.TABLE__CELL_LEFT_SIZE__X_SMALL,
    },
    {
      title: 'Action',
    },
  ];
  const handleEditScopeDE = (deObjectID, alias, description) => {
    handleEditDE(deObjectID, alias, description);

    // Update the error state
    const newErrorFields = { ...errorFields };

    if (!alias) {
      newErrorFields[deObjectID] = 'alias';
    } else if (!description) {
      newErrorFields[deObjectID] = 'description';
    } else if (newErrorFields[deObjectID]) {
      delete newErrorFields[deObjectID];
    }

    setErrorFields(newErrorFields);
  };

  /**
   * Handles the deletion of a Data Extension with confirmation using SwalUtil.
   *
   * @param {string} deObjectID - The ID of the Data Extension to be deleted.
   * @returns {void}
   */
  const handleDeleteDEWithConfirmation = async (deObjectID) => {
    const result = await SwalUtil.fire({
      title: 'Remove Data Extension from scope',
      message:
        'Are you sure you want to remove this Data Extension from DeedeeAI scope?',
      options: {
        showCancelButton: true,
        confirmButtonText: 'Remove',
      },
    });

    if (result.value) {
      handleDeleteDE(deObjectID);

      // Check if we are on the last page and the current page becomes empty after deletion
      if (
        currentPage === totalPages &&
        (scopeDEs.length - 1) % scopesPerPage === 0
      ) {
        setCurrentPage(prevPage => Math.max(1, prevPage - 1));
      }
    }
  };

  const handleSortProperty = (property, sortDirection) => {
    setSortState((prevState) => {
      const newSortDirection =
        property === prevState.sortedProperty ?
          sortDirection :
          Constants.SORT_DIRECTION__ASCENDING;

      return {
        ...prevState,
        sortedProperty: property,
        sortDirection: newSortDirection,
      };
    });
  };

  /**
   * Renders the table body content for the ScopedDataExtensions component.
   * @returns {JSX.Element[]} An array of JSX elements representing the table rows.
   */
  const renderDEsTable = () => {
    // Filter scopeDEs based on searchTerm

    const indexOfLastScopeDE = currentPage * scopesPerPage;
    const indexOfFirstScopeDE = indexOfLastScopeDE - scopesPerPage;
    const currentScopeDEs = scopeDEs.slice(
      indexOfFirstScopeDE,
      indexOfLastScopeDE,
    );

    return currentScopeDEs.map((de, index) => (
      <tr key={index} className="slds-hint-parent row-data">
        <td data-label="Name">
          <div className="slds-truncate">{de.name}</div>
        </td>
        <td data-label="Alias">
          <div className="slds-truncate">
            <Input
              type="text"
              value={de.alias}
              className={de.alias ? '' : 'error'}
              aria-label="alias"
              onChange={e => handleEditScopeDE(de.deObjectID, e.target.value, de.description)}
            />
          </div>
        </td>
        <td data-label="Description">
          <div>
            <TextArea
              type="text"
              value={de.description}
              className={de.description ? '' : 'error'}
              aria-label="description"
              onChange={e => handleEditScopeDE(de.deObjectID, de.alias, e.target.value)}
            />
          </div>
        </td>
        <td>
          <Button
            type="button"
            className="slds-button_icon slds-button_icon-x-small delete-scope-de"
            onClick={() => handleDeleteDEWithConfirmation(de.deObjectID)}
          >
            <svg className="slds-button__icon" aria-hidden="true">
              <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#close" />
            </svg>
          </Button>
        </td>
      </tr>
    ));
  };

  return (
    <div className="scoped-data-extension-list">
      <div className="alias-tooltip">
        <Tooltip
          nubbinPosition={Constants.NUBBIN_POSITION__TOP_LEFT}
          tooltipText="Choose a user friendly alias for the data extension which Deedee AI
          can use when using a data extension in a selection."
        />
      </div>

      <div className="description-tooltip">
        <Tooltip
          nubbinPosition={Constants.NUBBIN_POSITION__TOP_LEFT}
          tooltipText="Explain what data is stored in a data extension,
          so Deedee AI can choose the correct data extensions to use when building a selection."
        />
      </div>

      <Table
        tableHeaders={tableHeadersData}
        id="scope-de-table"
        className="scope-de-table"
        handleSort={handleSortProperty}
        bodyContent={renderDEsTable()}
        sortedProperty={sortState.sortedProperty}
        sortDirection={sortState.sortDirection}
      />
      {scopeDEs.length ?
        (
        <div className="pagination">
          <Button onClick={goToPrevPage} disabled={currentPage === 1}>
            Prev
          </Button>
          {pageNumbers.map(number => (
            <Button
              key={number}
              onClick={() => paginate(number)}
              className={currentPage === number ? 'active-page' : ''}
            >
              {number}
            </Button>
          ))}
          <Button onClick={goToNextPage} disabled={currentPage === totalPages}>
            Next
          </Button>
        </div>
        ) :
        (
        <div className="no-scoped-de">No Data Extensions selected yet.</div>
        )}
    </div>
  );
};

ScopedDataExtensions.propTypes = {
  handleEditDE: PropTypes.func.isRequired,
  handleDeleteDE: PropTypes.func.isRequired,
  scopeDEs: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ScopedDataExtensions;
