import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert2';
import { toast } from 'react-toastify';
import _ from 'lodash';
import classNames from 'classnames';
import {
  Dropdown,
  ButtonStateful,
} from '@salesforce/design-system-react';
import './styles.scss';
import { connect } from 'react-redux';

import mapStateToProps from '../../../../mapStateToProps';
import Constants from '../../../../constants/constants';
import SwalUtil from '../../../../utils/swal/swalUtil';
import Util from '../../../../util';
import Features from '../../../../features';
import timeUtil from '../../../../utils/time/timeUtil';
import filtersUtil from '../../../../utils/filters/filtersUtil';
import Button from '../../../shared/Button/Button';
import Spinner from '../../../shared_v2/Spinner/Spinner';
import Tooltip from '../../../shared/Tooltip/Tooltip';
import DataAction from '../DataAction/DataAction';
import SortLimit from '../SortLimit/SortLimit';
import Alert from '../../../shared_v2/Alert/Alert';
import arrowRightIcon from '../../../../icons_v2/arrow-right-icon.svg';
import deleteIcon from '../../../../icons_v2/delete.svg';
import keyIcon from '../../../../icons_v2/key.svg';
import DataExtensionsAPI from '../../../../api/data-extensions';
import CustomSelect from '../../../shared_v2/CustomSelect/CustomSelect';
import PrioDedup from '../PrioDedup/PrioDedup';
import LoadingModal from '../../../shared_v2/LoadingModal/LoadingModal';
import Tip from '../../../shared_v2/Tip/Tip';

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
const SelectedTargetFields = ({
  targetDataExtensionFields,
  targetDataExtensionCustomerKey,
  matchedFields,
  relations,
  selectedDataExtensions,
  handleSetSelectionState,
  newTargetDataExtensionFields,
  editNewAutoTargetDE,
  newTargetDataExtension,
  subscriberFieldNames,
  handleAddFieldToTargetDE,
  dropToTargetDataExtensionField,
  editTargetDataExtension,
  prevTargetDEFields,
  dataAction,
  showDataActionModal,
  filterBorderMouseOver,
  targetDataExtensions,
  sortLimit,
  showSortLimitModal,
  prioDeduplication,
  usePrioDeduplication,
  isArchived,
  prevMatchedFields,
  axiosCancelToken,
  formatAvailableDEs,
  targetDataExtension,
  disableRunDetails,
  waterfallSelection,
  selectionNavigation,
  addSubscriberOption,
  targetCollectionObjectID,
  handleSetAppState,
  unionSelections,
  selectionType,
  switchToDedup,
  handleFiltersSave,
  getDataExtensionOrDataViewFields,
  handleAdvancedDedupFiltersSave,
  advancedDeduplicationRules,
  advancedDedupFilterSaveIndex,
  previousAdvancedDeduplicationRules,
  pickLists,
  handlePickListOptions,
  handleRemoveFilterLine,
  applyTimezoneSettingsToAllDateFields,
  handleSetTimezoneToAllDateFields,
  timezoneSettingsForAllDateFields,
  DEBorderMouseOver,
  loadingForTargetDataExtensions,
  handleChangeSendableAndSubscriberField = () => { },
  userInfo,
  orgInfo,
  featuresInfo,
}) => {
  const hasReadOnlyAccess = userInfo?.hasReadOnlyAccess;
  const [newDELoading, setNewDELoading] = useState(false);

  /**
   * function helps to open the NewAutoCreatedTargetDE modal
   * and sets the current matchedFields to prevMatchedFields so
   * matchedFields can be restored later if it`s needed
   * @returns {void}
   */
  const handleShowNewTargetDEModal = () => {
    handleSetSelectionState({
      createNewAutoTargetDE: true,
      prevMatchedFields: matchedFields,
      matchedFields: [],
    });
  };

  /**
   * Handle clicking on the Edit Target DE Fields link
   * @returns {void}
   */
  /**
   * Go in Edit Target Data Extension mode
   * Save previous mathed fields and target DE fields
   * @returns {void}
   */
  const handleOpenEditTargetDE = () => {
    /**
     * Make copies for target de fields and save them in prevTargetDEFields
     * which will contain those fields before we entered in edit target de mode
     * So if we press cancel we will have older fields
     */
    const prevTargetDataExtensionFields = JSON.parse(JSON.stringify(targetDataExtensionFields));
    const prevMatchedFields = JSON.parse(JSON.stringify(matchedFields));

    let subscriberFieldName = '';

    let sendableFieldName = targetDataExtension?.SendableDataExtensionField?.Name || '';

    let sendableFieldType = targetDataExtension?.SendableDataExtensionField?.FieldType || '';

    // if the SendableSubscriberField Name ends with the Key word
    if (targetDataExtension?.SendableSubscriberField?.Name.endsWith('Key')) {
      // assign 'Subscriber Key' name
      subscriberFieldName = Constants.SUBSCRIBER_FIELD__SUBSCRIBER_KEY;
    } else if (targetDataExtension?.SendableSubscriberField?.Name.endsWith('Id') ||
      targetDataExtension?.SendableSubscriberField?.Name.endsWith('ID')) {
      // if the SendableSubscriberField ends with the Id or ID word, assign 'Subscriber Id' name
      subscriberFieldName = Constants.SUBSCRIBER_FIELD__SUBSCRIBER_ID;
    } else if (targetDataExtension?.SendableSubscriberField?.Name.endsWith('Address')) {
      // if the SendableSubscriberField ends with the Address word, assign 'Email Address' name
      subscriberFieldName = Constants.SUBSCRIBER_FIELD__EMAILADDRESS;
    } else if (!targetDataExtension?.SendableSubscriberField && !targetDataExtension?.SendableDataExtensionField) {
      // if there is no SendableSubscriberField, then set the first field in SendableSubscriberField object
      sendableFieldName = targetDataExtensionFields[0].Name;
      sendableFieldType = targetDataExtensionFields[0].FieldType;
      subscriberFieldName = handleChangeSendableAndSubscriberField(targetDataExtensionFields[0], true);
    }

    // add options in Send Relationship dropdown
    addSubscriberOption(targetDataExtensionFields);

    // defines whether the data in targetDataExtension corresponds to an enabled Data Retention Policy
    const isDataRetentionPolicyOn = (targetDataExtension?.RowBasedRetention ||
      targetDataExtension.hasOwnProperty('DeleteAtEndOfRetentionPeriod')) &&
      (targetDataExtension?.DataRetentionPeriodLength &&
        targetDataExtension?.DataRetentionPeriod ||
        targetDataExtension?.RetainUntil && targetDataExtension?.RetainUntil !== '');

    /*
     * transform the retrieved data for the Target Data Extension into the appropriate data displayed
     * in DataRetentionPolicy
     */
    const editedTargetDataExtension = {
      ...newTargetDataExtension,
      name: targetDataExtension.Name,
      folderId: targetDataExtension.CategoryID,
      description: targetDataExtension.Description || '',
      dataRetentionPolicy: {
        ...newTargetDataExtension.dataRetentionPolicy,
        dataRetentionPolicy: !!isDataRetentionPolicyOn,
        toggle: !!isDataRetentionPolicyOn,
        allRecords: !!(targetDataExtension?.DeleteAtEndOfRetentionPeriod && !targetDataExtension?.RowBasedRetention &&
          isDataRetentionPolicyOn),
        individualRecords: !!(targetDataExtension?.RowBasedRetention && isDataRetentionPolicyOn),
        dataRetentionPeriod: targetDataExtension?.DataRetentionPeriod || Constants.DATA_RETENTION_POLICY__PERIOD__DAYS,
        dataRetentionPeriodLength: targetDataExtension?.DataRetentionPeriodLength || false,
        periodAfter: !!(targetDataExtension?.DataRetentionPeriod &&
          targetDataExtension?.DataRetentionPeriodLength),
        allRecordsAndDE: !!(isDataRetentionPolicyOn &&
          !targetDataExtension?.DeleteAtEndOfRetentionPeriod &&
          !targetDataExtension?.RowBasedRetention),
        resetRetentionPeriodOnImport: targetDataExtension?.ResetRetentionPeriodOnImport || false,
        dataExtensionPeriodDate: !targetDataExtension?.DataRetentionPeriod &&
          !targetDataExtension?.DataRetentionPeriodLength ?
          targetDataExtension?.RetainUntil :
          false,
        periodOn: !!(targetDataExtension?.RetainUntil && !targetDataExtension?.DataRetentionPeriod &&
          !targetDataExtension?.DataRetentionPeriodLength),
        periodOnDisabled: false,
      },
      relationship: {
        ...newTargetDataExtension.relationship,
        isSendable: targetDataExtension.IsSendable || false,
        isTestable: targetDataExtension.IsTestable || false,
        sendableDataExtensionField: {
          name: sendableFieldName,
          type: sendableFieldType,
        },
        sendableSubscriberField: {
          name: subscriberFieldName,
        },
      },
    };

    handleSetSelectionState({
      editTargetDataExtension: true,
      prevMatchedFields,
      prevTargetDEFields: prevTargetDataExtensionFields,
      openSettings: false,
      newTargetDataExtension: editedTargetDataExtension,
    });
  };

  // eslint-disable-next-line max-len
  const featureAutoCreateTargetDEIsEnabled = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__AUTO_CREATE_TARGET_DATA_EXTENSION);

  // feature for encrypted fields
  const featureEncryptedFieldsIsEnabled = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__ENCRYPTED_FIELDS);

  // feature for dedup
  const featurePrioDeduplication = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__PRIO_DEDUP);

  // Feature for custom values
  const featureCustomValuesIsEnabled = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__CUSTOM_VALUES);

  // Feature for source limiting
  const featureSourceLimitingIsEnabled = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__SOURCE_LIMITING);

  /**
   * Function which helps to open data action modal
   * @returns {void}
   */
  const handleOpenDataActionModal = () => {
    handleSetSelectionState({ showDataActionModal: true });
  };

  /**
   * Function for deleting option for dropdown
   * @param {object[]} newDeFields - New DE fields
   * @returns {void}
   */
  const deleteSubscriberOption = (newDeFields) => {
    let text = false;

    let number = false;

    if (newDeFields.length) {
      // Go through fields and check if there is any field with text, number of email field type
      newDeFields.forEach((field) => {
        if (
          field.FieldType === Constants.FILTERLINE__FIELDTYPE__TEXT ||
          field.FieldType === Constants.FILTERLINE__FIELDTYPE__EMAILADDRESS
        ) {
          text = true;
        }
        if (field.FieldType === Constants.FILTERLINE__FIELDTYPE__NUMBER) {
          number = true;
        }
      });
      /*
       * if there is not field with field type text then delete Subscriber Key as a option from dropdown
       * if there is not field with field type number then delete Subscriber Id as a option from dropdown
       * if there is not field with field type email then delete Email Address as a option from dropdown
       */
      if (!text) {
        const index = subscriberFieldNames.indexOf(
          Constants.SUBSCRIBER_FIELD__SUBSCRIBER_KEY,
        );

        if (index > -1) {
          subscriberFieldNames.splice(index, 1);
        }
      }
      if (!number) {
        const index = subscriberFieldNames.indexOf(
          Constants.SUBSCRIBER_FIELD__SUBSCRIBER_ID,
        );

        if (index > -1) {
          subscriberFieldNames.splice(index, 1);
        }
      }
    } else {
      // eslint-disable-next-line no-param-reassign
      subscriberFieldNames = [];
    }
    handleSetSelectionState({ subscriberFieldNames });
  };

  /**
   * Determines if the field is Sendable or not
   *  @param {string} fieldName - target DE field name
   *  @param {boolean} editMode - defines whether we are in editing TargetDE mode
   *  @returns {boolean} If field is sendable return true
   */
  const isSendableField = (fieldName, editMode) => {
    /*
     * Find field in target DE which is sendable, if we are in edit mode - check newTargetDataExtension,
     * otherwise, get data from all target data extensions
     */
    const sendableTargetDEFieldName = editMode ?
      (newTargetDataExtension?.relationship?.sendableDataExtensionField?.name?.toString()) :
      targetDataExtensions.find(
        de => de.CustomerKey === targetDataExtensionCustomerKey && de.IsSendable,
      )?.SendableDataExtensionField?.Name?.toString();

    if (sendableTargetDEFieldName === fieldName?.toString()) {
      // If targetDE field name matches the sendable field name, return true
      return true;
    }

    return false;
  };

  /**
   * when user clicks the x icon to remove a mapped field
   * @param {string} fieldObjectID - id of removed DE
   * @param {number} index - index of removed DE
   * @param {boolean} isTargetDEField - Are we deleting a target field ?
   * @returns {void}
   */
  const removeTargetDataExtensionField = async (fieldObjectID, index, isTargetDEField) => {
    let message;

    let title;

    const confirmationMessage = `Are you sure you want to ${isTargetDEField ? 'remove' : 'unmap'} this field?`;

    // target data extension fields depending on the mode
    const targetDEFields = editTargetDataExtension ? targetDataExtensionFields : newTargetDataExtensionFields;

    // get the name of the removed field
    const getRemovedFieldName = targetDEFields.find(field => field.ObjectID === fieldObjectID)?.Name;

    // get information whether this field is used in subscriber relationship
    const isRemovedFieldSendable = isSendableField(getRemovedFieldName, true);

    // when the sendable field is removed
    if (isRemovedFieldSendable && newTargetDataExtension?.relationship?.isSendable &&
      isTargetDEField && editTargetDataExtension) {
      // eslint-disable-next-line max-len
      message = 'This field is used in the subscriber relationship, therefore cannot be removed. Select another Sendable Field before removing this one.';

      title = 'Remove Sendable Field';

      await SwalUtil.fire({
        type: Constants.SWAL__TYPE__ERROR,
        title,
        message,
      });

      return;
    }

    message = isTargetDEField ?
      confirmationMessage + ' All its data will be lost.' :
      'This target field will be removed from matched Target Data Extension Fields.';

    title = `${isTargetDEField ? 'Remove Field' : ' Unmap Field'}`;

    const result = await SwalUtil.fire({
      title,
      message,
      options: {
        showCancelButton: true,
      },
    });

    if (result.value) {
      let removedFields = [];
      const removeNewTargetDEField = [];

      /**
       * If there is index value then it means we're trying to remove a newTargetDeField
       * so find it with given index and push the the other ones in to array
       */

      if (index > -1) {
        // If we are in Edit Target DE fields mode remove field from targetDataExtensionFields
        if (targetDEFields?.length) {
          targetDEFields.forEach((field, fieldIndex) => {
            if (index !== fieldIndex) {
              removeNewTargetDEField.push(field);
            }
          });
        }
      }

      // remove the field from the matchedFields
      removedFields = matchedFields.filter(
        field => field.targetDataExtensionFieldObjectID !== fieldObjectID,
      );

      if (isRemovedFieldSendable && !newTargetDataExtension?.relationship?.isSendable) {
        if (removeNewTargetDEField && removeNewTargetDEField.length > 0) {
          targetDEFields.forEach((field) => {
            if (field.ObjectID === fieldObjectID &&
              newTargetDataExtension.relationship.sendableDataExtensionField.name &&
              field.Name.toString() === newTargetDataExtension.relationship.sendableDataExtensionField.name.toString()
            ) {
              for (let i = 0; i < removeNewTargetDEField.length; i += 1) {
                if (removeNewTargetDEField[i].FieldType === Constants.FILTERLINE__FIELDTYPE__TEXT ||
                  removeNewTargetDEField[i].FieldType === Constants.FILTERLINE__FIELDTYPE__NUMBER ||
                  removeNewTargetDEField[i].FieldType === Constants.FILTERLINE__FIELDTYPE__EMAILADDRESS) {
                  handleChangeSendableAndSubscriberField(removeNewTargetDEField[i]);
                }
              }
            }
          });
        }
      }

      // Delete option for dropdown
      deleteSubscriberOption(removeNewTargetDEField);

      if (editTargetDataExtension) {
        handleSetSelectionState({ targetDataExtensionFields: removeNewTargetDEField });
      } else if (editNewAutoTargetDE) {
        handleSetSelectionState({ newTargetDataExtensionFields: removeNewTargetDEField });
      }

      handleSetSelectionState({
        matchedFields: removedFields,
      });
    }
  };
  /**
   * This function shows us about field`s information when user clicked on the pencil icon
   * @param {string} objectID - id of a clicked field
   * @returns {void}
   */
  const showInfoAboutField = async (objectID) => {
    let field = [];

    let primaryKeyProperty = false;

    let disableEncryptedFieldProperty = false;

    let isRequiredProperty = true;

    let maxFieldLength;

    /**
     * If we are not in ACDE mode (then we are in edit target de mode)
     * Then get field from target data extension
     */
    if (editTargetDataExtension) {
      field = targetDataExtensionFields.filter(
        f => f.ObjectID === objectID,
      );
      // Get field from target de fields before we entered edit target de mode
      const prevField = prevTargetDEFields.filter(
        f => f.ObjectID === objectID,
      );

      if (prevField && prevField.length > 0) {
        // Check if field has primary key
        if (prevField[0].IsPrimaryKey) {
          primaryKeyProperty = true;
        }

        // For existing fields disable the ability to change StorageType
        disableEncryptedFieldProperty = true;

        // Check if field is not required
        if (!prevField[0].IsRequired) {
          isRequiredProperty = false;
        }

        // Get field max length
        maxFieldLength = prevField[0].MaxLength;
      } else {
        // If we are in edit mode for new created field, allow to change StorageType to Encrypted
        disableEncryptedFieldProperty = false;
      }
    } else {
      field = newTargetDataExtensionFields.filter(
        f => f.ObjectID === objectID,
      );
    }

    let fieldIsSendable = false;
    /**
     * Check if field which we want to edit is Sendable
     * If so set fieldIsSendable to true so later in the code we can update sendable field name
     */

    if (newTargetDataExtension.relationship.sendableDataExtensionField &&
      newTargetDataExtension.relationship.sendableDataExtensionField.name &&
      newTargetDataExtension.relationship.sendableDataExtensionField.name === field[0].Name.toString()) {
      fieldIsSendable = true;
    }
    /**
     * Added new field property so when you first time open
     * field info it will be undefined and then initialize it to
     * the !field[0].IsRequired
     */
    if (typeof field[0].IsNullable === 'undefined') {
      field[0].IsNullable = !field[0].IsRequired;
    }

    /**
     * This function converts boolean value to Yes or No
     * @param {boolean} value - True/false
     * @returns {string|null} 'Yes' or 'No'
     */
    const convertBooleanToYesNoString = (value) => {
      switch (value) {
        case true:
          return 'Yes';
        case false:
          return 'No';
        default:
          return null;
      }
    };
    /**
     * This function helps to parse values into html elements
     * @param {string} name - Name
     * @param {string} value - Input value
     * @param {string} abbreviatedValue - Abbreviated input value
     * @param {string} disable - Disable
     * @returns {string} HTML for the fields
     */
    const parseFieldInfoIntoHtmlElements = (name, value, abbreviatedValue, disable) => {
      /**
       * Get the scale of the value
       * @returns {string} The string correspond to the scale
       */
      const getScale = () => {
        if (value?.scale) return `${value.length}.${value.scale}`;
        if (typeof value === 'boolean') return convertBooleanToYesNoString(value);
        if (value === Constants.FIELD_STORAGE_TYPE__ENCRYPTED) { return 'Yes'; }
        if (value === Constants.FIELD_STORAGE_TYPE__PLAIN) { return 'No'; }

        return value;
      };

      /**
       * Get the HTML for the field
       * @returns {string} HTML for the field
       */
      const getFieldHTML = () => {
        /**
         * Returns HTML for the input component
         * @param {boolean} disable - indicates if input should be disabled
         * @param {string} id - input id
         * @param {string|number} value - input value
         * @param {string} className - additional class name for the input
         * @param {string} type - input type
         * @param {string} max - max property for the input
         * @returns {string} HTML for input component
         */
        const htmlInput = (disable, id, value, className, type, max) => (
          `<div class="slds-form-element">
          <label class="slds-form-element__label" for="text-input-id-1">
          <div class="slds-form-element__control">
            <input
              ${disable ? 'disabled' : ''}
              type="${type}"
              id="${id}"
              min="1"
              max="${max || '2550'}"
              required=""
              class="slds-input ${className}"
              value="${value}"
            />
          </div>
        </div>`
        );

        /**
         * Returns HTML for the checkbox component
         * @param {boolean} disable - indicates if input should be disabled
         * @param {string} id - checkbox id
         * @param {boolean} isChecked - indicates if input should be checked
         * @param {string} label - label for the checkbox
         * @returns {string} HTML for checkbox component
         */
        const htmlCheckbox = (disable, id, isChecked, label) => (
          `<div class="slds-form-element">
          <div class="slds-form-element__control">
            <div class="slds-checkbox">
              <input
                ${disable ? 'disabled' : ''}
                type="checkbox"
                name="options"
                id="${id}"
                ${isChecked ? 'checked' : ''}
              />
              <label class="slds-checkbox__label" for="${label}">
                <span class="slds-checkbox_faux"></span>
              </label>
            </div>
          </div>
        </div>`
        );

        switch (name) {
          case Constants.FIELD_INFO_LABEL__NAME:
            return htmlInput(
              null,
              Constants.FIELD_INFO_INPUT_ID__FIELD_NAME,
              value,
              null,
              'text',
            );
          case Constants.FIELD_INFO_LABEL__LENGTH:
            return htmlInput(
              disable,
              Constants.FIELD_INFO_INPUT_ID__MAX_LENGTH,
              value,
              'slds-input_counter',
              'number',
            );
          case Constants.FIELD_INFO_LABEL__PRIMARY_KEY:
            return htmlCheckbox(
              disable,
              Constants.FIELD_INFO_INPUT_ID__PRIMARY_KEY,
              value,
              Constants.FIELD_INFO_INPUT_ID__PRIMARY_KEY,
            );
          case Constants.FIELD_INFO_LABEL__ENCRYPTED_FIELD:
            return htmlCheckbox(
              disable,
              Constants.FIELD_INFO_INPUT_ID__ENCRYPTED,
              value === Constants.FIELD_STORAGE_TYPE__ENCRYPTED,
              Constants.FIELD_INFO_INPUT_ID__ENCRYPTED,
            );
          case Constants.FIELD_INFO_LABEL__NULLABLE:
            return htmlCheckbox(
              disable,
              Constants.FIELD_INFO_INPUT_ID__NULLABLE,
              value,
              Constants.FIELD_INFO_INPUT_ID__NULLABLE,
            );
          case Constants.FIELD_INFO_LABEL__DEFAULT_DATE_VALUE:
            return (
              `<input
                id="${Constants.FIELD_INFO_INPUT_ID__DEFAULT_DATE_VALUE}"
                type="date"
                class="input-date-looks-like-LDS"
                max=${new Date().toLocaleDateString()}
                value=${value}
              />`
            );
          case Constants.FIELD_INFO_LABEL__LENGTH_SCALE:
            return (
              htmlInput(
                disable,
                Constants.FIELD_INFO_INPUT_ID__MAX_LENGTH,
                value.length,
                'slds-input_counter',
                'number',
                '4000',
              ) +
              htmlInput(
                disable,
                Constants.FIELD_INFO_INPUT_ID__SCALE,
                value.scale,
                'slds-input_counter',
                'number',
                '4000',
              )
            );
          case Constants.FIELD_INFO_LABEL__DEFAULT_NUMBER_VALUE:
            return htmlInput(
              null,
              Constants.FIELD_INFO_INPUT_ID__DEFAULT_NUMBER_VALUE,
              value,
              'slds-input_counter',
              'number',
              '4000',
            );
          case Constants.FIELD_INFO_LABEL__DEFAULT_TEXT_VALUE:
            return htmlInput(
              null,
              Constants.FIELD_INFO_INPUT_ID__DEFAULT_TEXT_VALUE,
              value,
              null,
              'text',
            );
          case Constants.FIELD_INFO_LABEL__DEFAULT_BOOLEAN_VALUE:
            return (
              `<div class="slds-form-element">
                <div class="slds-form-element__control">
                  <div class="slds-select_container">
                  <select class="slds-select" id="${Constants.FIELD_INFO_INPUT_ID__DEFAULT_BOOLEAN_VALUE}" >
                    <option value="" ${value === '' ? 'selected' : null}>none</option>
                    <option value="true"  ${value === 'true' ? 'selected' : null}>true</option>
                    <option value="false" ${value === 'false' ? 'selected' : null}>false</option>
                  </select>
                  </div>
                </div>
              </div>`
            );
          default:
            return `${abbreviatedValue || value}`;
        }
      };

      return (
        `<div class="field-info-row">
          <div class="field-info-row-left" title="${name}">
            ${name === Constants.FIELD_INFO_LABEL__DEFAULT_DATE_VALUE ||
          name === Constants.FIELD_INFO_LABEL__DEFAULT_NUMBER_VALUE ||
          name === Constants.FIELD_INFO_LABEL__DEFAULT_TEXT_VALUE ||
          name === Constants.FIELD_INFO_LABEL__DEFAULT_BOOLEAN_VALUE ?
          'Default Value:' :
          `${name}:`}
          </div>
          <div
            class="field-info-row-right"
            title="${getScale()}"
          >` +
        getFieldHTML() +
        `
          </div>
        </div>`
      );
    };

    // This variable is in html format so we can parse inputs into swal
    let message = parseFieldInfoIntoHtmlElements(
      Constants.FIELD_INFO_LABEL__NAME,
      field[0].Name.toString(),
      Util.abbreviate(field[0].Name.toString(), '16'),
    );

    message += parseFieldInfoIntoHtmlElements(Constants.FIELD_INFO_LABEL__TYPE, field[0].FieldType);

    if (field[0].FieldType === Constants.FILTERLINE__FIELDTYPE__DECIMAL) {
      message += parseFieldInfoIntoHtmlElements(
        Constants.FIELD_INFO_LABEL__LENGTH_SCALE,
        { length: field[0].MaxLength, scale: field[0].Scale || '' },
        null,
        // If we are not in ACDE mode disable input field
        !editNewAutoTargetDE,
      );
    } else if (field[0].MaxLength || (field[0].FieldType !== Constants.FILTERLINE__FIELDTYPE__NUMBER &&
      field[0].FieldType !== Constants.FILTERLINE__FIELDTYPE__DATE &&
      field[0].FieldType !== Constants.FILTERLINE__FIELDTYPE__BOOLEAN)) {
      message += parseFieldInfoIntoHtmlElements(
        Constants.FIELD_INFO_LABEL__LENGTH,
        field[0].MaxLength || '',
        null,
        // If we are not in ACDE mode and field type is something else then Text disable input field
        !!(!editNewAutoTargetDE && field[0].FieldType !== Constants.FILTERLINE__FIELDTYPE__TEXT),
      );
    }

    message += parseFieldInfoIntoHtmlElements(
      Constants.FIELD_INFO_LABEL__PRIMARY_KEY,
      typeof field[0].IsPrimaryKey === 'undefined' ? false : field[0].IsPrimaryKey,
      null,
      // If field already has defined primary key and nullable property disable checkboxes for those properties
      !!(!editNewAutoTargetDE && (!isRequiredProperty || primaryKeyProperty)),
    );

    // show only if encrypted fields feature is enabled
    if (featureEncryptedFieldsIsEnabled) {
      message += parseFieldInfoIntoHtmlElements(
        Constants.FIELD_INFO_LABEL__ENCRYPTED_FIELD,
        field[0].StorageType,
        null,
        // If we are not in ACDE mode and encryptedFieldProperty is disabled
        !!(!editNewAutoTargetDE && disableEncryptedFieldProperty),
      );
    }

    message += parseFieldInfoIntoHtmlElements(
      Constants.FIELD_INFO_LABEL__NULLABLE,
      field[0].IsNullable,
      null,
      // If field already has defined primary key and nullable property disable checkboxes for those properties
      !!(!editNewAutoTargetDE && (!isRequiredProperty || primaryKeyProperty)),
    );

    // If selectedField is date
    if (field[0].FieldType === Constants.FILTERLINE__FIELDTYPE__DATE) {
      let dateValue;
      // Check if the field has Default Value assigned before

      if (field[0].DefaultValue) {
        // Format the date for html`s date input (yyyy-mm-dd)
        dateValue = timeUtil.formatDateForHtmlDateInput(field[0].DefaultValue, userInfo);
      }
      message += parseFieldInfoIntoHtmlElements(
        Constants.FIELD_INFO_LABEL__DEFAULT_DATE_VALUE,
        dateValue || '',
      );
      // If selectedField is number or decimal (we can use input type number for both number and decimal field type)
    } else if (filtersUtil.isNumberOrDecimal(field[0].FieldType)) {
      message += parseFieldInfoIntoHtmlElements(
        Constants.FIELD_INFO_LABEL__DEFAULT_NUMBER_VALUE,
        field[0].DefaultValue || '',
      );
      // If selectedField is text
    } else if (field[0].FieldType === Constants.FILTERLINE__FIELDTYPE__TEXT) {
      message += parseFieldInfoIntoHtmlElements(
        Constants.FIELD_INFO_LABEL__DEFAULT_TEXT_VALUE,
        field[0].DefaultValue || '',
      );
      // If selectedField is boolean
    } else if (
      field[0].FieldType === Constants.FILTERLINE__FIELDTYPE__BOOLEAN
    ) {
      message += parseFieldInfoIntoHtmlElements(
        Constants.FIELD_INFO_LABEL__DEFAULT_BOOLEAN_VALUE,
        field[0].DefaultValue || '',
      );
    }

    /**
     * Returns the value of a tag with a `Constants.FIELD_INFO_INPUT_ID__DEFAULT_X_VALUE` or '' if it doesn't exist.
     * @returns {string} The default value
     */
    const getDefaultValue = () => {
      const idToCheck = [
        Constants.FIELD_INFO_INPUT_ID__DEFAULT_DATE_VALUE,
        Constants.FIELD_INFO_INPUT_ID__DEFAULT_NUMBER_VALUE,
        Constants.FIELD_INFO_INPUT_ID__DEFAULT_TEXT_VALUE,
        Constants.FIELD_INFO_INPUT_ID__DEFAULT_BOOLEAN_VALUE,
      ];

      const element = idToCheck.find(id => document.getElementById(id));

      return element ? document.getElementById(element).value : '';
    };

    // In this section we're getting the values from swal inputs
    let formValues = await SwalUtil.fire({
      title: 'Field Information',
      message,
      options: {
        preConfirm: () => ({
          maxLength: document.getElementById(Constants.FIELD_INFO_INPUT_ID__MAX_LENGTH) ?
            document.getElementById(Constants.FIELD_INFO_INPUT_ID__MAX_LENGTH).value :
            '',
          fieldName: document.getElementById(Constants.FIELD_INFO_INPUT_ID__FIELD_NAME).value,
          isPrimaryKey: document.getElementById(Constants.FIELD_INFO_INPUT_ID__PRIMARY_KEY).checked,
          StorageType: document.getElementById(Constants.FIELD_INFO_INPUT_ID__ENCRYPTED)?.checked ?
            Constants.FIELD_STORAGE_TYPE__ENCRYPTED :
            Constants.FIELD_STORAGE_TYPE__PLAIN,
          isNullable: document.getElementById(Constants.FIELD_INFO_INPUT_ID__NULLABLE).checked,
          scale: document.getElementById(Constants.FIELD_INFO_INPUT_ID__SCALE) ?
            document.getElementById(Constants.FIELD_INFO_INPUT_ID__SCALE).value :
            0,
          defaultValue: getDefaultValue(),
        }),
        showCancelButton: true,
      },
    });
    /**
     * It is assigned again like this, to get the values from preConfirm function
     * Assignment has been updated like the statement below
     */

    formValues = formValues.value;

    // For showing the warnings about values that user entered
    const fireSwal = async text => SwalUtil.fire({
      type: Constants.SWAL__TYPE__ERROR,
      title: 'Error',
      message: text,
    });

    // The conditions to check if length and scale are in the correct formats
    if (formValues) {
      /**
       * If are not in ACDE mode (then we are in edit target de mode) check if we decreased field length
       * If so, throw warning message
       */
      if (maxFieldLength > formValues.maxLength && !editNewAutoTargetDE) {
        await fireSwal(
          `The length of an existing Text field cannot be decreased. It will be set to ${field[0].MaxLength}.`,
        );
        showInfoAboutField(field[0].ObjectID);

        return;
      }
      if (Number.parseInt(formValues.maxLength) < 0) {
        await fireSwal(`You can't assign a value less than 0 to length.
        ${field[0].MaxLength ? `It will be set to ${field[0].MaxLength}.` : ''}`);
        showInfoAboutField(field[0].ObjectID);

        return;
      }
      if (
        formValues.maxLength.includes('.') ||
        formValues.maxLength.includes(',')
      ) {
        await fireSwal(`You can't assign decimal values to length.
        ${field[0].MaxLength ? `It will be set to ${field[0].MaxLength}.` : ''}`);
        showInfoAboutField(field[0].ObjectID);

        return;
      }
      if (
        field[0].FieldType === Constants.FILTERLINE__FIELDTYPE__NUMBER &&
        (formValues.defaultValue.includes(',') ||
          formValues.defaultValue.includes('.'))
      ) {
        await fireSwal('You can\'t assign a decimal value to Default Value. This field is a Number.');
        showInfoAboutField(field[0].ObjectID);

        return;
      }
      if (
        field[0].FieldType === Constants.FILTERLINE__FIELDTYPE__TEXT &&
        !formValues.maxLength
      ) {
        await fireSwal('Length property is required for Field of type Text.');
        showInfoAboutField(field[0].ObjectID);

        return;
      }
      /*
       * do not allow the length of the email field to be larger
       * than 254 (according to SFMC, length of the email field type cannot be longer than 254)
       */
      if (
        field[0].FieldType === Constants.FILTERLINE__FIELDTYPE__EMAILADDRESS &&
        Number.parseInt(formValues.maxLength) > 254
      ) {
        await fireSwal(`You can't assign a value greater than 254 to length. It will be set to ${field[0].MaxLength}.`);
        showInfoAboutField(field[0].ObjectID);

        return;
      }
      // check if the entered length is greater than 4000. If so, throw a swal message
      if (Number.parseInt(formValues.maxLength) > 4000) {
        await fireSwal(`You can't assign a value greater than 4000 to length.
        ${field[0].MaxLength ? `It will be set to ${field[0].MaxLength}.` : ''}`);
        showInfoAboutField(field[0].ObjectID);

        return;
      }

      if (field[0].Scale > 0) {
        if (field[0].Scale && !formValues.scale) {
          await fireSwal(`You can't assign empty value to scale. It will be set to ${field[0].Scale}.`);
          showInfoAboutField(field[0].ObjectID);

          return;
        }
        if (Number.parseInt(formValues.scale) < 1) {
          await fireSwal(`You can't assign a value less than 1 to scale. It will be set to ${field[0].Scale}.`);
          showInfoAboutField(field[0].ObjectID);

          return;
        }
        if (Number.isNaN(Number.parseInt(formValues.scale))) {
          await fireSwal(`You can't assign empty value to length. It will be set to ${field[0].Scale}.`);
          showInfoAboutField(field[0].ObjectID);

          return;
        }
        if (Number.parseInt(formValues.scale) > Number.parseInt(field[0].MaxLength)) {
          await fireSwal('You can\'t assign greater value than length to scale.');
          showInfoAboutField(field[0].ObjectID);

          return;
        }
        if (formValues.scale.includes('.') || formValues.scale.includes(',')) {
          await fireSwal(`You cant' assign decimal values to scale. It will be set to ${field[0].Scale}.`);
          showInfoAboutField(field[0].ObjectID);

          return;
        }
        // check if the entered scale is greater than 4000. If so, throw a swal message
        if (Number.parseInt(formValues.scale) > 4000) {
          await fireSwal(`You can't assign a value greater than 4000 to scale.
          ${field[0].Scale ? `It will be set to ${field[0].Scale}.` : ''}`);
          showInfoAboutField(field[0].ObjectID);

          return;
        }

        // eslint-disable-next-line require-atomic-updates
        field[0].Scale = Number.parseInt(formValues.scale);
      }

      // Check if formValues.fieldName exists
      if (formValues.fieldName !== undefined) {
        // Get field name without spaces on the beginning
        formValues.fieldName = formValues.fieldName.trimLeft();
      }

      // Check if there is a fieldName value given for the field
      if (formValues.fieldName) {
        let duplicatedField;

        // If we are in ACDE mode
        if (editNewAutoTargetDE) {
          // Check if that field name exists
          duplicatedField = Util.getDuplicateField(
            newTargetDataExtensionFields,
            formValues.fieldName,
            field[0].Name,
          );
        } else {
          // Check if that field name exists in target data extension fields
          duplicatedField = Util.getDuplicateField(
            targetDataExtensionFields,
            formValues.fieldName,
            field[0].Name,
          );
        }

        // If it exists then fire a swal
        if (duplicatedField?.length) {
          await fireSwal(`A field has been found with this name.
          It will be set to ${field[0].Name.toString()}.`);
          showInfoAboutField(field[0].ObjectID);

          return;
        }
        // If given name starts with an underscore, warn user about invalid field name.
        if (Util.startsWithUnderScore(formValues.fieldName)) {
          await fireSwal(Constants.WARNING__FIELD_INVALID__STARTS_WITH_UNDERSCORE);
          showInfoAboutField(field[0].ObjectID);
        }
        // If given name contains illegal characters, warn user about invalid field name.
        if (Util.containsIllegalCharacters(formValues.fieldName)) {
          const invalidCharacters = Util.containsIllegalCharacters(formValues.fieldName);

          await fireSwal(
            // eslint-disable-next-line max-len
            `Target Data Extension Field name <b>${formValues.fieldName}</b> cannot contain <b>${invalidCharacters}</b>.It will be set to ${field[0].Name.toString()}.`,
          );
          showInfoAboutField(field[0].ObjectID);

          return;
        }
        // If given name contains only numbers, warn user about invalid field name.
        if (Util.containsOnlyNumbers(formValues.fieldName)) {
          await fireSwal(
            // eslint-disable-next-line max-len
            `Field name <b>${formValues.fieldName}</b> cannot consist entirely of numbers. It will be set to ${field[0].Name.toString()}.`,
          );
          showInfoAboutField(field[0].ObjectID);
        }

        // check if the field with the same name exists from the beginning and has not been removed
        const existingFieldWithTheSameName = prevTargetDEFields.find(prevField => (
          targetDataExtensionFields.find(tdeField => prevField.Name.toString().toLowerCase().trim() ===
            formValues.fieldName.toString().toLowerCase().trim() &&
            prevField.ObjectID === tdeField.ObjectID)
        ));

        // check if the name of the new added field exists in SendableDataExtensionField
        const isAddedFieldNameInSendableDataExtensionField = targetDataExtension?.SendableDataExtensionField
          ?.Name?.toString()?.toLowerCase()?.trim() === formValues.fieldName?.toString()?.toLowerCase()?.trim() &&
          !existingFieldWithTheSameName;

        // If the name of the new added field exists in SendableDataExtensionField, throw swal message
        if (isAddedFieldNameInSendableDataExtensionField && !editNewAutoTargetDE && editTargetDataExtension) {
          await fireSwal(
            // eslint-disable-next-line max-len
            'The field with this name has been removed and added again. This name is used in the subscriber relationship. Please rename the field or click Update Target Data Extension first, then go back to editing and add field with this name again.',
          );
          showInfoAboutField(field[0].ObjectID);

          return;
        }

        /**
         * If there is no fieldName value given, then fire a swal
         * because in any case fields have their own default names!
         */
      } else {
        await fireSwal(`A field name cannot be empty.
        It will be set to ${field[0].Name.toString()}.`);
        showInfoAboutField(field[0].ObjectID);

        return;
      }
      // If a date value is selected for defaultValue then assign it to the field
      if (
        formValues.defaultValue &&
        field[0].FieldType === Constants.FILTERLINE__FIELDTYPE__DATE
      ) {
        // eslint-disable-next-line require-atomic-updates
        field[0].DefaultValue = new Date(
          formValues.defaultValue,
        ).toLocaleDateString(timeUtil.getUserLocale(userInfo));
        // If a value is selected for defaultValue then assign it to the field
      } else if (
        formValues.defaultValue &&
        field[0].FieldType === Constants.FILTERLINE__FIELDTYPE__DECIMAL
      ) {
        /**
         * If field is Decimal and if user input value eg. "23" it will be set to the 23,0000
         * Note: Length of number after decimal separator represents scale defined for that field
         */
        // eslint-disable-next-line require-atomic-updates
        field[0].DefaultValue = Number(formValues.defaultValue).toFixed(
          formValues.scale,
        );
        // If a value is selected for defaultValue then assign it to the field
      } else if (formValues.defaultValue) {
        // eslint-disable-next-line require-atomic-updates
        field[0].DefaultValue = formValues.defaultValue;
      } else {
        // Else assign an empty value
        // eslint-disable-next-line require-atomic-updates
        field[0].DefaultValue = '';
      }
      /**
       * If field`s name is not equal to given fieldName and is not invalid,
       * Then assign to the field and change matchedFields`s targetDataExtensionsFieldName and
       * targetDataExtensionFieldObjectID to keep them matched
       */
      if (field[0].Name.toString() !== formValues.fieldName && !Util.startsWithUnderScore(formValues.fieldName) &&
        !Util.containsOnlyNumbers(formValues.fieldName.toString())) {
        const matchedField = matchedFields.filter(mf => mf.targetDataExtensionFieldObjectID === field[0].ObjectID);

        // eslint-disable-next-line require-atomic-updates
        field[0].Name = formValues.fieldName.toString();

        // If we are not in Edit Target DE mode then don't add field name to the field ObjectID
        if (editNewAutoTargetDE) {
          // eslint-disable-next-line require-atomic-updates
          field[0].ObjectID = field[0].ObjectID + '+' + formValues.fieldName;
        }
        // Check if there is matched field
        if (matchedField && matchedField.length > 0) {
          matchedField[0].targetDataExtensionFieldName = field[0].Name.toString();
          matchedField[0].targetDataExtensionFieldObjectID = field[0].ObjectID;
        }
      }

      // if the length for a field marked as Primary Key is not set, throw a message
      if (formValues.isPrimaryKey && formValues.maxLength === '' &&
        (field[0].FieldType !== Constants.FILTERLINE__FIELDTYPE__NUMBER &&
          field[0].FieldType !== Constants.FILTERLINE__FIELDTYPE__DATE &&
          field[0].FieldType !== Constants.FILTERLINE__FIELDTYPE__BOOLEAN)) {
        await fireSwal(`You can't assign empty value to length of a field marked as Primary Key.
        ${field[0].MaxLength ? `It will be set to ${field[0].MaxLength}.` : ''}`);
        showInfoAboutField(field[0].ObjectID);

        return;
      }
      if (formValues.isPrimaryKey && formValues.maxLength === '0') {
        await fireSwal(`You can't assign a value less than 1 to length of a field marked as Primary Key.
        ${field[0].MaxLength ? `It will be set to ${field[0].MaxLength}.` : ''}`);
        showInfoAboutField(field[0].ObjectID);

        return;
      }

      // eslint-disable-next-line require-atomic-updates
      field[0].MaxLength = formValues.maxLength;

      /* eslint-disable require-atomic-updates */
      field[0].IsPrimaryKey = formValues.isPrimaryKey;

      // If primary key is true set IsNullable to false otherwise set it to the check box value
      field[0].IsNullable = field[0].IsPrimaryKey ?
        false :
        formValues.isNullable;

      // set Storage Type and isRequired property
      field[0].StorageType = formValues.StorageType;
      field[0].IsRequired = !field[0].IsNullable;

      /* eslint-enable require-atomic-updates */
      // If the field which we want to edit is Sendable update sendable field
      if (fieldIsSendable) {
        handleChangeSendableAndSubscriberField(field[0]);
      }

      handleSetSelectionState({
        newTargetDataExtensionFields,
        matchedFields,
      });
    }
  };

  /**
   * Automatically map fields if they have the same unique name and field type
   * @param {string} result - the option selected by the user in the swal
   * @returns {void}
   */
  const mapFields = async (result) => {
    const overWrite = result === Constants.AUTOMAP__OVERWRITE;

    /**
     * Create key for the fieldsMap
     * @param {*} fieldName - Name of the field
     * @param {*} fieldType - Type of the field
     * @return {string} Field name combined with field type with a '-' in-between
     */
    const createKey = (fieldName, fieldType) => `${fieldName}-${fieldType}`;

    // Create map with fields in selectedDataExtensions
    const fieldsMap = new Map();
    const tracker = [];

    selectedDataExtensions.forEach(de => de.fields.forEach((field) => {
      const fieldName = field.Name.toString().toLowerCase();
      const fieldType = field.FieldType;
      const key = createKey(fieldName, fieldType);
      const mapValue = fieldsMap.get(key) || [];

      if (mapValue instanceof Array) {
        mapValue.push(field);
        fieldsMap.set(key, mapValue[0]);
      } else {
        tracker.push(key);
      }
    }));

    tracker.forEach((tr) => {
      fieldsMap.delete(tr);
    });

    // Loop through all targetDataExtension fields to see if there is a match with a field from selectedDataExtensions
    targetDataExtensionFields.forEach((field) => {
      const fieldName = field.Name.toString().toLowerCase();
      const fieldType = field.FieldType;
      const key = createKey(fieldName, fieldType);
      const availableField = fieldsMap.get(key);

      /**
       * if we have some fields mapped then check if current target DE field can be matched
       * with a field from selectedDataExtension and overwrite option is picked
       */
      if (matchedFields && matchedFields.length > 0 && availableField && overWrite) {
        /**
         * if that is the case then remove currently mapped target de field with
         * selectedDataExtension field and update matchedFields
         */
        // eslint-disable-next-line no-param-reassign
        matchedFields = matchedFields.filter(mf => mf.targetDataExtensionFieldObjectID !== field.ObjectID);
        handleSetSelectionState({ matchedFields });
      }

      if (availableField) {
        // if a field is available then create variables used in matchedFields
        const targetDataExtensionFieldCustomerKey = field.CustomerKey;
        const targetDataExtensionFieldObjectID = field.ObjectID;
        const availableFieldFieldType = availableField.FieldType;
        const availableFieldName = availableField.Name.toString();
        const targetDataExtensionFieldName = field.Name.toString();
        const availableFieldDataExtensionCustomerKey = availableField.DataExtension.CustomerKey.toString();
        const availableFieldObjectID = availableField.ObjectID;
        const availableFieldIsRequired = `${availableField.IsRequired}`;
        const availableFieldMaxLength = availableField.MaxLength || Util.getMaxLength(availableField.FieldType);

        // pass these parameters to a function and assign to the matched fields
        // eslint-disable-next-line no-param-reassign
        matchedFields = dropToTargetDataExtensionField(
          availableFieldFieldType,
          targetDataExtensionFieldCustomerKey,
          availableFieldName,
          /**
           * This parameter is null because we cannot see data extension alias in fields
           * so we are passing null and then in this function we are handling data extension alias
           */
          null,
          targetDataExtensionFieldName,
          availableFieldDataExtensionCustomerKey,
          availableFieldObjectID,
          targetDataExtensionFieldObjectID,
          false,
          availableFieldIsRequired,
          availableFieldMaxLength,
        );
      }
    });

    /**
     * If matchedFields returns null it means it doesn't find any matched field.
     * In this case, assign it an empty array
     */
    if (matchedFields) {
      handleSetSelectionState({ matchedFields });
    } else {
      handleSetSelectionState({ matchedFields: [] });
    }
  };

  /**
   * Opens an automap modal and executes the automap function
   * @returns {void}
   */
  const autoMap = async () => {
    let matchedFieldsBeforeAutomap;

    // if the toast about automap exists, close it
    if (toast.isActive(Constants.NOTIFICATION__TOAST_ID__AUTOMAP)) {
      toast.dismiss(Constants.NOTIFICATION__TOAST_ID__AUTOMAP);
    }

    // show a swal with the options to choose
    SwalUtil.fire({
      title: 'Automap',
      // eslint-disable-next-line max-len
      message: `This will automatically map unique available fields with your Target Data Extension.
      Do you want to overwrite existing mappings?`,
      options: {
        input: 'radio',
        inputValue: Constants.AUTOMAP__OVERWRITE,
        inputOptions: {
          overwrite: 'Overwrite',
          doNotOverwrite: 'Do not overwrite',
        },
        showCancelButton: true,
        showConfirmButton: true,
        showLoaderOnConfirm: true,
        didOpen() {
          // if all fields are matched, disable do not overwrite radio button
          if (matchedFields.length === targetDataExtensionFields.length) {
            document.getElementsByClassName('swal2-label')[1].style.color = '#b5b5b5';
            document.getElementsByName('swal2-radio')[1].disabled = true;
          }
        },
        customClass: {
          confirmButton: 'targetDE-automap-confirm',
          loader: 'mapping-loader',
          input: 'input-class',
          cancelButton: 'targetDE-automap-cancel',
        },
        // it is a loading spinner with the button
        loaderHtml: `
        <div class="map-btn-container">
          <button disabled id="map-button" class="slds-button slds-button_neutral" type="button"> Mapping...
            <div class="preview-loader-container automap-spinner">
              <div
                role="status"
                class="slds-spinner slds-spinner_x-small"
              >
                <div class="slds-spinner__dot-a" />
                <div class="slds-spinner__dot-b" />
              </div>
            </div>
          </button>
        </div>`,
        preConfirm: result => new Promise((resolve) => {
          if (result === Constants.AUTOMAP__DO_NOT_OVERWRITE) {
            // define how many fields are matched before automap
            matchedFieldsBeforeAutomap = matchedFields.length;
          }

          // setTimeout to show loader on confirm button (for better visual perception)
          setTimeout(() => {
            // trigger mapFields function
            swal.disableButtons();
            mapFields(result);
            resolve();
          }, 200);
        }),
      },
    }).then((result) => {
      // if the user clicks on the confirm button
      if (result.value) {
        let automapNotification;

        // see how many fields have been matched without overwriting
        const notOverwrittenFields = matchedFields.length - matchedFieldsBeforeAutomap;

        // depending on the option, display a message in the toast
        if ((result.value === Constants.AUTOMAP__OVERWRITE && matchedFields.length === 1) ||
          (result.value === Constants.AUTOMAP__DO_NOT_OVERWRITE && matchedFields.length &&
            notOverwrittenFields === 1)) {
          automapNotification = 'Automap is complete. 1 field is matched.';
        } else if (result.value === Constants.AUTOMAP__OVERWRITE && matchedFields.length) {
          automapNotification = `Automap is complete. ${matchedFields.length} fields are matched.`;
        } else if (result.value === Constants.AUTOMAP__DO_NOT_OVERWRITE && matchedFields.length &&
          notOverwrittenFields) {
          automapNotification = `Automap is complete. ${notOverwrittenFields} fields are matched.`;
        } else if (!matchedFields.length || !notOverwrittenFields) {
          automapNotification = 'Automap is complete. No matching fields were found.';
        }

        // the toast will appear
        toast.success(
          <div className="slds-notify slds-notify_toast slds-theme_success">
            <span className="slds-assistive-text">success</span>
            <span
              className="slds-icon_container slds-icon-utility-success slds-m-right_small slds-no-flex slds-align-top"
              title="Description of icon when needed"
            >
              <svg className="slds-icon slds-icon_small" aria-hidden="true">
                <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#success" />
              </svg>
            </span>
            <span>
              {automapNotification}
            </span>
          </div>,
          {
            position: toast.POSITION.TOP_RIGHT,
            className: 'toast-mapped',
            toastId: Constants.NOTIFICATION__TOAST_ID__AUTOMAP,
            hideProgressBar: true,
            autoClose: 4000,
            containerId: Constants.NOTIFICATION__CONTAINER_ID__TARGET_DEFINITION,
            draggable: false,
            pauseOnHover: false,
            pauseOnFocusLoss: false,
          },
        );
      }
    });
  };

  /**
   * Delete all matched fields
   * @returns {void}
   */
  const deleteAllMatchedFields = async () => {
    const result = await SwalUtil.fire({
      title: 'Delete Mappings',
      message: 'Are you sure you want to delete all field mappings? You won\'t be able to revert this!',
      options: {
        showCancelButton: true,
        buttonsStyling: false,
      },
    });

    // If a user confirms the deletion
    if (result.value) {
      handleSetSelectionState({ matchedFields: [] });
    }
  };

  /**
   * Check if required field is mapped or not
   * @property {string} ObjectID => target DE field ObjectID
   * @param {string} ObjectID - target DE field ObjectID
   * @returns {array} The ObjectID of the fields which are not mapped
   */
  const isRequiredFieldMapped = (ObjectID) => {
    // Keeps ObjectID of the fields which are not mapped
    const notMappedFields = [];
    // Helps to determine either ObjectID is mapped or not

    let check = false;
    // Loop through matched fields and check if there is a field with ObjectID

    matchedFields.forEach((matchedField) => {
      if (ObjectID === matchedField.targetDataExtensionFieldObjectID) {
        // If field with ObjectID is in matched fields set check to true
        check = true;
      }
    });
    // If field is not inside matched fields then push ObjectID of that field in notMappedFields
    if (!check) {
      notMappedFields.push(ObjectID);
    }

    return notMappedFields;
  };

  /**
   * Looks if there is only one mapped required field for cross join relation
   * and return true if that is the case
   * @param {Object} targetDataExtensionField - target DE field to show warning from
   * @returns {Boolean} - Returns true or false depending the warning should be shown or not
   */
  const isShowingCrossJoinWarningRequired = (targetDataExtensionField) => {
    if (targetDataExtensionField.IsRequired === Constants.FIELD__IS_REQUIRED &&
      relations?.some(relation => relation.joinType === Constants.RELATIONTYPE__CROSS) &&
      matchedFields?.filter(
        matchedField => String(matchedField.availableFieldIsRequired) ===
          String(Constants.FIELD__IS_REQUIRED),
      )?.length === 1 &&
      !isRequiredFieldMapped(targetDataExtensionField.ObjectID).length) {
      return true;
    }

    return false;
  };

  /**
   * Handle onClick event on the cross join warning icon
   * @returns {void}
   */
  const handleClickCrossJoinWarningIcon = async () => {
    await SwalUtil.fire({
      title: 'Only one primary key mapped for Cross Join',
      message: `You only have one primary key mapped for Cross Join.
       This may result in unexpected results due to primary key constraints.
       Please remove the primary key or add a primary key from the second Data Extension in the Cross Join.`,
      options: {
        showConfirmButton: true,
        confirmButtonText: 'OK',
      },
    });
  };

  /**
   * Indicates whether a field is existing or is newly added
   * We need this so we can show correct swal message when removing a field
   * @param {string} targetDEFieldObjectID - targetDEFieldObjectId
   * @returns {boolean} If field already exists in target DE fields
   */
  const isExistingField = (targetDEFieldObjectID) => {
    let existingField = false;

    prevTargetDEFields.forEach((field) => {
      if (targetDEFieldObjectID === field.ObjectID) {
        existingField = true;
      }
    });

    return existingField;
  };

  /**
   * Returns true if all icons are rendered in edit TargetDE field mode
   * @param {object} targetDataExtensionField - object with target DE field
   * @returns {boolean} true/false depending on whether the conditions are met
   */
  const editedFieldWithAllIcons = (targetDataExtensionField) => {
    if (targetDataExtensionField.IsPrimaryKey &&
      targetDataExtensionField.StorageType === Constants.FIELD_STORAGE_TYPE__ENCRYPTED &&
      featureEncryptedFieldsIsEnabled) return true;

    return false;
  };

  /**
   * Returns true if availableFieldDataExtensionCustomerKey property in the field exists
   * @param {object} field - field object
   * @returns {boolean} true if availableFieldDataExtensionCustomerKey exists, false otherwise
   */
  const isAvailableFieldDECustomerKeyExists = field => field.availableFieldDataExtensionCustomerKey &&
    field.availableFieldDataExtensionCustomerKey !== 'null';

  /**
   * Function that handle onDragEnter for target collection row
   * @param {object} e - JS Event
   * @returns {void}
   */
  const onDragEnterForTargetCollectionRow = (e) => {
    if (!editTargetDataExtension && e.target.firstChild &&
      e.target.firstChild.className === 'target-collection-dropzone') {
      // eslint-disable-next-line no-param-reassign
      e.target.firstChild.style.pointerEvents = '';
    }
  };

  /**
   * Function that handle onDragEnter or onDragLeave for target collection dropzone
   * @param {object} e - JS Event
   * @param {boolean} isOnDragEnter - indicates if onDragEnter event is triggered
   * @returns {void}
   */
  const onDragEnterOrLeaveForTargetCollectionDropzone = (e, isOnDragEnter) => {
    const targetCollectionDropzoneWithFirstChild = e?.target?.className === 'target-collection-dropzone' &&
      e.target?.firstChild?.firstChild !== null;

    const isEmptyForDropzone = e?.target?.firstChild?.className === 'empty-for-dropzone';

    // Make border solid on dragEnter and dashed on dragLeave
    const firstChildStyleBorder = isOnDragEnter ? 'var(--solidBorder)' : 'var(--dashedBorder)';

    if (targetCollectionDropzoneWithFirstChild && isOnDragEnter) {
      e.target.style.pointerEvents = 'none';
    }

    if (!editTargetDataExtension && (isOnDragEnter || targetCollectionDropzoneWithFirstChild && !isOnDragEnter)) {
      // Enable pointer events if drag out of mapped field
      e.target.style.pointerEvents = '';
    }

    if (!editTargetDataExtension && !isOnDragEnter) {
      e.target.style.border = '';
    }

    // Make border solid or dashed
    if (isEmptyForDropzone && (isOnDragEnter || !editTargetDataExtension && !isOnDragEnter)) {
      e.target.firstChild.style.border = firstChildStyleBorder;
    }
  };

  /**
   * Function that handle onDrop for target collection dropzone
   * @param {object} e - JS Event
   * @param {object} targetDataExtensionField - target data extension field object
   * @returns {void}
   */
  const onDropForTargetCollectionDropzone = (e, targetDataExtensionField) => {
    if (!editTargetDataExtension) {
      if (e?.target?.firstChild?.className === 'empty-for-dropzone') {
        e.target.firstChild.style.border = '';

        return handleAddFieldToTargetDE(
          e,
          targetDataExtensionField.CustomerKey,
          targetDataExtensionField.Name.toString(),
          targetDataExtensionField.FieldType,
          targetDataExtensionField.ObjectID,
          null,
          targetDataExtensionField.IsRequired,
          false,
          false,
          false,
        );
      }

      if (e.target) { e.target.style.border = ''; }
    }

    return false;
  };

  /**
   * Renders a DE Field
   * @returns {object} The HTML for the DE fields
   */
  const renderDEField = () => {
    if (targetDataExtensionFields &&
      targetDataExtensionFields.length > 0 &&
      newTargetDataExtensionFields.length < 1 &&
      !editNewAutoTargetDE) {
      return (
        targetDataExtensionFields.map((targetDataExtensionField, index) => (
          <div
            key={targetDataExtensionField.Name.toString()}>
            <div
              key={targetDataExtensionField.Name.toString()}
              className="target-collection-row"
              onDragEnter={onDragEnterForTargetCollectionRow}
            >
              <div
                className={`target-collection-dropzone${editTargetDataExtension ? ' pointer-event-none' : ''}`}
                onDragEnter={e => onDragEnterOrLeaveForTargetCollectionDropzone(e, true)}
                onDragLeave={onDragEnterOrLeaveForTargetCollectionDropzone}
                onDragOver={e => e.preventDefault()}
                onDrop={e => onDropForTargetCollectionDropzone(e, targetDataExtensionField)}
              >
                <div
                  className="empty-for-dropzone"
                  style={{
                    pointerEvents: filterBorderMouseOver ? 'none' : '',
                    border: filterBorderMouseOver && !editTargetDataExtension ?
                      'var(--dashedBorder) ' :
                      '1px solid #DDDBDA',
                  }}
                >
                  {matchedFields.map(field => field.targetDataExtensionFieldObjectID ===
                    targetDataExtensionField.ObjectID ?
                    (
                      <div
                        key={field.availableFieldObjectID}
                        className="drag-field-v2 available-field"
                      >
                        <div className="drag-field-v2_wrapper">
                          <div
                            className="field-label"
                            title={
                              field.availableFieldName +
                              ' ' +
                              (isAvailableFieldDECustomerKeyExists(field) ?
                                field.availableFieldDataExtensionAlias :
                                `${field?.globalCustomValueId?.length ? 'Shared ' : ''}Custom Value`)
                            }
                          >
                            {`${Util.abbreviate(field.availableFieldName, 30)}`}
                            <span className="des-lib-tde-aliases">
                              {`${isAvailableFieldDECustomerKeyExists(field) ?
                                Util.abbreviate(field.availableFieldDataExtensionAlias, 40) :
                                `${field?.globalCustomValueId?.length ? 'Shared ' : ''}Custom Value`
                              }`}
                            </span>
                          </div>

                        </div>
                      </div>
                    ) :
                    null)}
                </div>
              </div>
              <img
                className="des-lib-tde-arrow-right"
                alt="arrow-right"
                src={arrowRightIcon}
              />
              <div
                className={'target-collection-field-v2' +
                  ((prevTargetDEFields && prevTargetDEFields.length === 0) ||
                    (isExistingField(targetDataExtensionField.ObjectID) &&
                      prevTargetDEFields &&
                      prevTargetDEFields.length > 0) ?
                    '' :
                    ' newly-added-field')}
                data-field-type="a"
                title={targetDataExtensionField.Name.toString()}
              >
                {targetDataExtensionField.IsPrimaryKey && !editTargetDataExtension ?
                  <img
                    className="des-lib-tde-key-icon-v2"
                    alt="key"
                    src={keyIcon}
                    title="This field is a primary key"
                  /> :
                  null}
                {editTargetDataExtension && targetDataExtensionField.IsPrimaryKey && (
                  <img
                    alt="key"
                    className="des-lib-tde-key-icon-v2"
                    src={keyIcon}
                    title="This field is a primary key"
                  />
                )}

                {editTargetDataExtension &&
                  targetDataExtensionField.StorageType === Constants.FIELD_STORAGE_TYPE__ENCRYPTED &&
                  featureEncryptedFieldsIsEnabled &&
                  (
                    <div className={`span-field-info-button ${editedFieldWithAllIcons(targetDataExtensionField) &&
                      'with-2-icons'}`}
                    >
                      <i
                        className="fas fa-lock encrypted-lock-icon"
                        title={Constants.FIELD_INFO_LABEL__ENCRYPTED_FIELD}
                      />
                    </div>
                  )}
                <span className="new-tde-fields">
                  {Util.abbreviate(targetDataExtensionField.Name.toString(), '35')}
                </span>
                {matchedFields.map(field => field.availableFieldIsRequired ===
                  Constants.FIELD__IS_NOT_REQUIRED &&
                  targetDataExtensionField.IsRequired ===
                  Constants.FIELD__IS_REQUIRED &&
                  field.targetDataExtensionFieldObjectID ===
                  targetDataExtensionField.ObjectID ?
                  (
                    <i
                      key={`selectedDEField_${new Date().getTime()}`}
                      title={Constants.WARNING__FIELD_REQUIRED}
                      className="fas fa-exclamation-triangle link status-warning target-definition-icon"
                      onClick={e => handleAddFieldToTargetDE(
                        e,
                        targetDataExtensionField.CustomerKey,
                        targetDataExtensionField.Name.toString(),
                        targetDataExtensionField.FieldType,
                        targetDataExtensionField.ObjectID,
                        null,
                        targetDataExtensionField.IsRequired,
                        true,
                        false,
                        false,
                      )}
                    />
                  ) :
                  null)}
                {matchedFields.map(
                  field => field.availableFieldMaxLength !== 'undefined' &&
                    targetDataExtensionField.MaxLength < field.availableFieldMaxLength &&
                    field.targetDataExtensionFieldObjectID === targetDataExtensionField.ObjectID ?
                    (
                      <i
                        key={`selectedTF_${new Date().getTime()}`}
                        title={Constants.WARNING__FIELD_LENGTH}
                        className="fas fa-exclamation-triangle link status-warning target-definition-icon"
                        onClick={e => handleAddFieldToTargetDE(
                          e,
                          targetDataExtensionField.CustomerKey,
                          targetDataExtensionField.Name.toString(),
                          targetDataExtensionField.FieldType,
                          targetDataExtensionField.ObjectID,
                          null,
                          targetDataExtensionField.IsRequired,
                          false,
                          false,
                          true,
                        )}
                      />
                    ) :
                    null,
                )}
                {targetDataExtensionField.IsRequired === Constants.FIELD__IS_REQUIRED ?
                  isRequiredFieldMapped(targetDataExtensionField.ObjectID)
                    .map(id => id === targetDataExtensionField.ObjectID ?
                      (
                        <i
                          key={id}
                          title={Constants.SWAL_TITLE__FIELD_REQUIRED}
                          className="fas fa-exclamation-triangle link status-error target-definition-icon"
                          onClick={e => handleAddFieldToTargetDE(
                            e,
                            targetDataExtensionField.CustomerKey,
                            targetDataExtensionField.Name.toString(),
                            targetDataExtensionField.FieldType,
                            targetDataExtensionField.ObjectID,
                            null,
                            targetDataExtensionField.IsRequired,
                            false,
                            true,
                          )}
                        />
                      ) :
                      null) :
                  null}

                {isShowingCrossJoinWarningRequired(targetDataExtensionField) ?
                  (<i
                    title={Constants.WARNING__USE_OF_REQUIRED_FIELD_WITH_CROSS_JOIN}
                    className="fas fa-exclamation-triangle link status-warning target-definition-icon"
                    onClick={() => handleClickCrossJoinWarningIcon()}
                  />) :
                  null}

              </div>
              {editTargetDataExtension ?
                (
                  <div className="v2-des-lib-tde-delete-icon">
                    <div className="edit-delete-icons">
                      <span
                        id="edit-info-icon"
                        // eslint-disable-next-line max-len
                        className={'slds-icon_container slds-icon-utility-announcement slds-current-color remove-filter edit-info-icon' + (isSendableField(targetDataExtensionField.Name.toString()) ? ' disable-div' : '')}
                        onClick={() => showInfoAboutField(targetDataExtensionField.ObjectID)}
                        title="Edit"
                      >
                        <svg
                          className="slds-icon slds-icon_x-small dropEdit"
                          aria-hidden="true"
                          style={{ fill: '#495D7A' }}
                        >
                          <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#edit" />
                        </svg>
                      </span>
                      <img
                        alt="tde-delete"
                        src={deleteIcon}
                        onClick={async () => removeTargetDataExtensionField(
                          targetDataExtensionField.ObjectID,
                          index,
                          true,
                        )}
                      />
                    </div>
                  </div>
                ) :
                (
                  <div className="des-lib-tde-delete-icon">
                    <Button
                      noButtonClass
                      className="tde-delete remove-mappings-button"
                      id="tde-delete"
                      onClick={async () => removeTargetDataExtensionField(
                        targetDataExtensionField.ObjectID,
                        index,
                      )}
                    >
                      <i className="fas fa-times" />
                    </Button>
                  </div>
                )}
            </div>
            <hr className="des-lib-tde-line" />
          </div>
        ))
      );
    }

    if (!targetDataExtensionFields?.length &&
      targetDataExtensionCustomerKey) {
      return (
        <div className="demo-only demo--inverse" style={{ height: '6rem' }}>
          <Spinner size={Constants.SPINNER__SIZE__MEDIUM} />
        </div>
      );
    }

    if (editNewAutoTargetDE &&
      newTargetDataExtensionFields &&
      featureAutoCreateTargetDEIsEnabled &&
      newTargetDataExtensionFields.length &&
      newTargetDataExtensionFields.length > 0) {
      return (
        newTargetDataExtensionFields.map((newTargetDeField, index) => (
          <div key={newTargetDeField.ObjectID}>
            <div className="target-collection-row">
              <div
                className="target-collection-dropzone"
              >
                {matchedFields.map(
                  field => field.targetDataExtensionFieldObjectID === newTargetDeField.ObjectID ?
                    (
                      <div
                        key={field.availableFieldName}
                        className="drag-field-v3 available-field card"
                        title={
                          field.availableFieldName +
                          ' ' +
                          (isAvailableFieldDECustomerKeyExists(field) ?
                            field.availableFieldDataExtensionAlias :
                            'Custom Values')
                        }
                      >
                        <div>
                          <div className="field-label">
                            {`${Util.abbreviate(field.availableFieldName, 20)}`}
                            <span className="des-lib-tde-aliases">
                              {`${isAvailableFieldDECustomerKeyExists(field) ?
                                Util.abbreviate(field.availableFieldDataExtensionAlias, 30) :
                                'Custom Values'
                              }`}
                            </span>
                          </div>
                        </div>
                      </div>
                    ) :
                    null,
                )}
              </div>
              <img
                className="des-lib-tde-arrow-right"
                alt="arrow-right"
                src={arrowRightIcon}
              />
              <div
                className="target-collection-field-v2"
                data-field-type="a"
                title={newTargetDeField.Name.toString()}
              >
                <div className={`span-field-info-button ${editedFieldWithAllIcons(newTargetDeField) &&
                  'with-2-icons'}`}
                >
                  {newTargetDeField.IsPrimaryKey ?
                    <img
                      alt="key"
                      src={keyIcon}
                    /> :
                    null}
                  {newTargetDeField.StorageType === Constants.FIELD_STORAGE_TYPE__ENCRYPTED &&
                    featureEncryptedFieldsIsEnabled ?
                    (
                      <i
                        className="fas fa-lock encrypted-lock-icon"
                        title={Constants.FIELD_INFO_LABEL__ENCRYPTED_FIELD}
                      />
                    ) :
                    null}
                </div>
                <span className="span-target-de-field-name">
                  {Util.abbreviate(newTargetDeField.Name.toString(), '35')}
                </span>
                <span
                  id="edit-info-icon"
                  // eslint-disable-next-line max-len
                  className="slds-icon_container slds-icon-utility-announcement slds-current-color remove-filter edit-info-icon des-lib-edit-icon"
                  onClick={() => showInfoAboutField(newTargetDeField.ObjectID)}
                  title="Edit"
                >
                  <svg
                    className="slds-icon slds-icon_x-small dropEdit"
                    aria-hidden="true"
                  >
                    <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#edit" />
                  </svg>
                </span>
              </div>
              <div>
                <div className="des-lib-tde-delete-icon-v2">
                  <img
                    alt="tde-delete"
                    src={deleteIcon}
                    onClick={async () => removeTargetDataExtensionField(
                      newTargetDeField.ObjectID,
                      index,
                      true,
                    )}
                  />
                </div>
              </div>
            </div>
            <hr className="des-lib-tde-line" />
          </div>
        ))
      );
    }

    return null;
  };

  /**
   * Returns sort limit select part message
   * @returns {string} message based on sort limit select type
   */
  const renderSortLimitSelectMessage = () => {
    // define a message variable
    const message = 'Selecting';

    // if type is number return type value and message accordingly
    if (sortLimit?.select?.type === Constants.SORT_LIMIT__SELECT_TYPE__NUMBER) {
      return `${message} ${sortLimit?.select?.value} records`;
    }

    // if type is percentage return type value and message accordingly
    if (sortLimit?.select?.type === Constants.SORT_LIMIT__SELECT_TYPE__PERCENTAGE) {
      return `${message} ${sortLimit?.select?.value}% of records`;
    }

    // otherwise return all record message
    return `${message} all records`;
  };

  const checkForMissingFields = async () => {
    // Variable that shows whether sort & limit should be reset
    let resetSortAndLimit = false;

    // Variable that shows whether prioDedup should be reset
    let resetPrioDeduplication = false;

    // State that should be reset
    let stateToReset = {};

    // Make sure we are not on edit mode
    if (!editTargetDataExtension) {
      // Check if sort & limit is active, and is using a field
      if (sortLimit?.enabled && sortLimit?.orderBy?.type === Constants.SORT_LIMIT__ORDER_BY_TYPE__FIELD) {
        // find the selected targeted field name
        const field = targetDataExtensionFields.find(x => x.ObjectID === sortLimit?.orderBy?.fieldObjectID)?.Name;

        // In case field is not found reset state and show swal
        if (!field) {
          resetSortAndLimit = true;

          stateToReset = {
            sortLimit: {
              enabled: false,
              select: {
                type: Constants.SORT_LIMIT__SELECT_TYPE__NUMBER,
                value: 0,
              },
              orderBy: {
                type: Constants.SORT_LIMIT__ORDER_BY_TYPE__FIELD,
                fieldObjectID: '',
                sort: '',
              },
            },
          };
        }
      }

      // Check if prioDedup is active
      if (usePrioDeduplication) {
        // find the selected deduplication field name
        const field = targetDataExtensionFields.find(x => x.ObjectID === prioDeduplication
          .deduplicationFieldObjectID)?.Name;

        // find the selected criteria field name
        const criteriaField = targetDataExtensionFields.find(x => x.ObjectID === prioDeduplication
          .criteriaFieldObjectID)?.Name;

        // In case a field is not found reset state and show swal
        if ((prioDeduplication.criteriaFieldObjectID && !criteriaField) || !field) {
          resetPrioDeduplication = true;

          stateToReset = {
            ...stateToReset,
            prioDeduplication: {
              multipleSortingOptionLines: [],
              mode: Constants.PRIO_DEDUP__MODE__BASIC__VALUE,
              type: Constants.PRIO_DEDUP__SORTING_PRIO__DEFINE_VALUES__VALUE,
              deduplicationFieldObjectID: '',
              criteriaFieldObjectID: '',
              priorities: [],
              sortOrder: Constants.PRIO_DEDUP__SORT_ORDER__ASC,
            },
            usePrioDeduplication: false,
            advancedDedupFilterSaveIndex: null,
            advancedDeduplicationRules: [],
            previousAdvancedDeduplicationRules: [],
          };
        }
      }

      // Reset state
      await handleSetSelectionState({
        ...stateToReset,
      });

      // Check if swal should be shown for Sort&Limit
      if (resetSortAndLimit) {
        // Fire swal message
        await SwalUtil.fire({
          title: 'Warning',
          message: 'The field used in Sort & Limit is deleted, therefore Sort & Limit will be disabled.',
          options: {
            showConfirmButton: true,
            confirmButtonText: 'OK',
          },
        });
      }

      // Check if swal should be shown for prioDedup
      if (resetPrioDeduplication) {
        // Fire swal message
        await SwalUtil.fire({
          title: 'Warning',
          message: 'A field used in Deduplication is deleted, therefore Deduplication will be disabled.',
          options: {
            showConfirmButton: true,
            confirmButtonText: 'OK',
          },
        });
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { checkForMissingFields(); }, [targetDataExtensionFields, editTargetDataExtension]);
  /**
   * Returns sort limit order part message
   * @returns {string} message based on sort limit order type
   */
  const renderSortLimitOrderMessage = () => {
    // define a message variable
    const message = ', sorted';

    // if type is set by field and order is set return message accordingly
    if (sortLimit?.orderBy?.type === Constants.SORT_LIMIT__ORDER_BY_TYPE__FIELD) {
      // find the selected targeted field name
      const field = targetDataExtensionFields.find(x => x.ObjectID === sortLimit?.orderBy?.fieldObjectID)?.Name;

      // eslint-disable-next-line max-len
      return `${message} by ${field}
        ${sortLimit.orderBy.sort === Constants.SORT_LIMIT__ORDER_BY_SORT__ASCENDING ? 'ascending' : 'descending'}.`;
    }

    // otherwise return random order message
    return `${message} at random.`;
  };

  /**
   * Returns deduplication multiple sorting options message
   * @param {array} list - list containing all sorting options fields
   * @returns {string} message of multiple sorting options
   */
  const renderDeduplicationMultipleSortingOptionLines = (list) => {
    // if list contains any items then iterate through each item
    if (list.length > 0) {
      let message = ',';

      // eslint-disable-next-line no-restricted-syntax
      for (const item of list) {
        // find the selected deduplication field name
        const field = targetDataExtensionFields.find(x => x.ObjectID === item.criteriaFieldObjectID)?.Name;

        // set the message with field name and sorting order
        message = `${message} ${field?.toString()} ${item?.sortOrder === Constants.PRIO_DEDUP__SORT_ORDER__ASC ?
          'ascending,' :
          'descending,'}`;
      }

      // return complete message
      message = `${message.substring(0, message.length - 1)}.`;

      return message;
    }

    return '.';
  };

  /**
   * Returns deduplication message
   * @returns {string} message based on deduplication type
   */
  const renderDeduplicationMessage = () => {
    // define a message variable
    const message = 'Deduplicating on';

    // find the selected deduplication field name
    const field = targetDataExtensionFields.find(x => x.ObjectID === prioDeduplication
      .deduplicationFieldObjectID)?.Name;

    // find the selected criteria field name
    const criteriaField = targetDataExtensionFields.find(x => x.ObjectID === prioDeduplication
      .criteriaFieldObjectID)?.Name;

    // if mode is basic return message with field names
    if (prioDeduplication?.mode === Constants.PRIO_DEDUP__MODE__BASIC__VALUE) {
      // if sorting all values selected then show all options message
      if (prioDeduplication?.type === Constants.PRIO_DEDUP__SORTING_PRIO__SORT_ALL_VALUES__VALUE) {
        // return message with sorting options and order
        // eslint-disable-next-line max-len
        return `${message} ${field}, based on ${criteriaField} ${prioDeduplication?.sortOrder === Constants.PRIO_DEDUP__SORT_ORDER__ASC ? 'ascending' : 'descending'}${renderDeduplicationMultipleSortingOptionLines(prioDeduplication?.multipleSortingOptionLines)}`;
      }

      // otherwise return message with criteria field name
      return `${message} ${field}, based on ${criteriaField}.`;
    }

    // if it is advanced mode, return message accordingly
    return `${message} ${field}, based on advanced rules.`;
  };

  /**
   * Returns HTML object with title for data action alert
   * @returns {object} HTML object
   */
  const renderDataActionAlertTitle = () => (
    <>
      <b className="des-lib-tde-warning-bold-text">Overwrite Data Action Selected.</b>
      {' '}
      <span className="des-lib-tde-warning-text">
        This action will overwrite
        all of the current data in the Target Data Extension.
      </span>
      <a
        href="#!"
        className="change-data-action"
        title="Change Data Action"
        role="button"
        onClick={handleOpenDataActionModal}
      >
        Change Data Action
      </a>
    </>
  );

  /**
   * Sets the proper state after returning from create and update target data extension mode
   * @param {boolean} toExistingTargetDE - returned to the existing targetDE from update mode
   * @returns {void}
   */
  const returnInitialState = (toExistingTargetDE) => {
    handleSetSelectionState({
      createNewAutoTargetDE: false,
      editTargetDataExtension: false,
      editNewAutoTargetDE: false,
      matchedFields: prevMatchedFields,
      newTargetDataExtension: {
        name: '',
        description: '',
        folderId: null,
        folderName: '',
        dataRetentionPolicy: {
          dataRetentionPolicy: false,
          individualRecords: false,
          allRecordsAndDE: false,
          allRecords: false,
          periodAfter: false,
          periodOn: false,
          resetRetentionPeriodOnImport: false,
          periodOnDisabled: true,
          dataRetentionPeriod: Constants.DATA_RETENTION_POLICY__PERIOD__DAYS,
          dataRetentionPeriodLength: 1,
          dataExtensionPeriodDate: '',
          toggle: false,
        },
        relationship: {
          isSendable: false,
          isTestable: false,
          sendableDataExtensionField: {
            name: '',
          },
          sendableSubscriberField: {
            name: '',
          },
        },
      },
      subscriberFieldNames: [],
      newTargetDataExtensionFields: [],
      targetDataExtensionFields: toExistingTargetDE ? prevTargetDEFields : targetDataExtensionFields,
    });
  };

  /**
   * Cancel data creation
   * @returns {void}
   */
  const switchBackToExistingDE = async () => {
    const swalResult = await SwalUtil.fire({
      title: 'Switch To Existing Data Extension',
      message: 'Are you sure you want to discard the creation of this Data Extension?',
      options: {
        confirmButtonText: 'Discard',
      },
    });

    // Return the state of all the fields to initial
    if (swalResult.value) { returnInitialState(); }
  };

  /**
   * Cancel changes of Target Data Extension
   * @returns {void}
   */
  const switchBackToExistingTargetDE = async () => {
    const swalResult = await SwalUtil.fire({
      title: 'Switch to Existing Target Data Extension',
      message: 'Are you sure you want to discard changes to this Target Data Extension?',
      options: {
        showCancelButton: true,
        confirmButtonText: 'Discard',
      },
    });

    if (swalResult.value) { returnInitialState(true); }
  };

  /**
   * Helps to check if the sendable field is from the target DE fields
   * @param {*} targetDE - Target DE Object
   * @param {*} targetDEFields - Array of fields
   * @returns {Boolean} - true if it's found
   */
  const isSendableFieldInSelectedTDEFields = (targetDE, targetDEFields) => {
    const fieldFound = targetDEFields?.find(
      field => field.Name === targetDE?.relationship?.sendableDataExtensionField?.name,
    );

    return !!fieldFound;
  };

  /**
   * Checks validation of target data extension
   * @returns {boolean} - true, false depending on whether the target data extension is valid
   */
  const validateTargetDataExtension = () => {
    let nonPrimaryKeyFieldExists = false;
    const targetDEFields = editNewAutoTargetDE ? newTargetDataExtensionFields : targetDataExtensionFields;

    // Error if no fields added
    if (!matchedFields.length && editNewAutoTargetDE || editTargetDataExtension && !targetDataExtensionFields?.length) {
      SwalUtil.fire({
        type: Constants.SWAL__TYPE__ERROR,
        title: 'Fields Missing',
        // eslint-disable-next-line max-len
        message: `You can\'t ${editNewAutoTargetDE ? 'create' : 'update'} a Target Data Extension without fields. Please drag fields to the right to select which fields you want to add to your ${editNewAutoTargetDE ? 'new' : 'updated'} target Data Extension.`,
      });

      return false;
    }

    // loop through new target de fields and check if there are any non-primary-key fields
    targetDEFields.forEach((field) => {
      if (!field.IsPrimaryKey) {
        nonPrimaryKeyFieldExists = true;
      }
    });

    // if non-primary-key field doesn't exist throw an error
    if (!nonPrimaryKeyFieldExists) {
      SwalUtil.fire({
        type: Constants.SWAL__TYPE__ERROR,
        title: 'Non Primary Key Field',
        message: 'Target Data Extension must have at least one non-primary-key field.',
      });

      return false;
    }

    // if targetDE is sendable and has no subscriberFieldNames, throw an error
    if (newTargetDataExtension?.relationship?.isSendable && !subscriberFieldNames.length) {
      SwalUtil.fire({
        type: Constants.SWAL__TYPE__ERROR,
        title: 'Subscriber Relationship is not defined',
        // eslint-disable-next-line max-len
        message: 'This Data Extension is set to be Sendable, but the Target Data Extension does not contain any Text, Number or Email field to define the Subscriber Relationship.',
      });

      return false;
    }

    // if targetDE is sendable and has no sendable data extension field, throw an error
    if (newTargetDataExtension?.relationship?.isSendable &&
      !isSendableFieldInSelectedTDEFields(newTargetDataExtension, targetDEFields)) {
      SwalUtil.fire({
        type: Constants.SWAL__TYPE__ERROR,
        title: 'No Sendable Data Extension Field Selected',
        message: 'This Data Extension is set to be Sendable, but no Sendable Data Extension Field is selected.',
      });

      return false;
    }

    return true;
  };
  /**
   * Defines whether the data action should be changed because there is no primary key field in the array
   * @param {array} fields - array with targetDE fields
   * @returns {boolean} - true, false depending on whether the data action should be changed
   */
  const checkIfActionTypeShouldBeChanged = (fields) => {
    // check if there is at least one field with Primary Key
    const primaryKeyExists = fields.filter(
      field => field.IsPrimaryKey === true,
    );

    // check if update action type should be disabled
    const disableUpdate = primaryKeyExists && primaryKeyExists.length === 0;

    // if data action is set to update and is disabled, return true
    if (disableUpdate && dataAction === Constants.DATA_ACTION__UPDATE) return true;

    // otherwise return false
    return false;
  };

  /**
   * Create new target DE
   * @returns {object|null} - an object or null
   */
  const handleCreateTargetDE = async () => {
    // check validation before creation
    const validate = await validateTargetDataExtension();

    if (!validate) return;

    // Confirmation message before final approval
    const swalResult = await SwalUtil.fire({
      title: 'Create Target Data Extension',
      // eslint-disable-next-line max-len
      message: 'Are you sure you want to create a Data Extension with these selected fields?',
      options: {
        confirmButtonText: 'Create',
        showCancelButton: true,
      },
    });

    // If confirmed
    if (swalResult.value) {
      // Set newDELoading state
      setNewDELoading(true);
      let createResult;
      // Create new DE

      try {
        createResult = await DataExtensionsAPI.createDataExtension(
          axiosCancelToken,
          newTargetDataExtension.folderId,
          newTargetDataExtension.name,
          newTargetDataExtension.description,
          newTargetDataExtensionFields,
          newTargetDataExtension.dataRetentionPolicy,
          newTargetDataExtension.relationship,
          newTargetDataExtension.contentType,
          orgInfo?.enterpriseBusinessUnitId,
        );
      } catch (error) {
        error = Util.boldDataExtensionName(error, newTargetDataExtension.name); // eslint-disable-line no-ex-assign
        createResult = { error };
      }

      // If error
      if (createResult?.error) {
        // Set newDELoading state
        setNewDELoading(false);
        if (!String(createResult?.error?.response?.data?.actualError).includes(
          Constants.ERROR_SFMC_PERMISSION_FAILED,
        )) {
          return SwalUtil.fire({
            type: Constants.SWAL__TYPE__ERROR,
            title: 'Error',
            message: createResult.error,
          });
        }
      }

      // If no error
      if (createResult?.newTargetDataExtension?.CustomerKey?.toString()) {
        // Get the data of the newly created targeted DE
        const [newDeResponse] = await DataExtensionsAPI.getDataExtensionsAndFieldsByCustomerKeys(
          axiosCancelToken,
          [{
            collectionCustomerKey: createResult.newTargetDataExtension.CustomerKey,
            collectionObjectID: createResult.newTargetDataExtension?.ObjectID || '',
            type: Constants.DESELECT__DATA_EXTENSION__TYPE__TARGET,
          }],
          null,
        );

        const newDeFields = newDeResponse.fields;

        /*
         * Set newDELoading state
         * After a new de is created, objectID of the fields didn't change.
         * We need to assign ObjectID from newly created DE to all matchedFields in order
         * to keep the connection/match between field and name
         */
        setNewDELoading(false);

        for (let f = 0; f < matchedFields.length; f += 1) {
          if (newDeFields[f] && newDeFields[f].ObjectID) {
            matchedFields[f].targetDataExtensionFieldObjectID = newDeFields[f].ObjectID;
          }

          // update availableFieldObjectID in matched fields that have 'ObjectID+Name' assigned
          if (matchedFields[f].availableFieldObjectID && matchedFields[f].availableFieldObjectID.includes('+')) {
            // assign only ObjectID without + and Name
            const [availableFieldObjectID] = matchedFields[f].availableFieldObjectID.split('+');

            matchedFields[f].availableFieldObjectID = availableFieldObjectID;
          }
        }

        handleSetSelectionState({
          createNewAutoTargetDE: false,
          editNewAutoTargetDE: false,
          targetDataExtensionCustomerKey: createResult.newTargetDataExtension.CustomerKey,
          targetCollectionObjectID: createResult.newTargetDataExtension.ObjectID,
          // Api returns an object instead of array for one field so I put that field into array
          targetDataExtensionFields: newDeFields,
          // save new created target data extension without fields
          targetDataExtension: _.omit(newDeResponse, 'fields') || {},
          matchedFields,
          newTargetDataExtension: {
            name: '',
            description: '',
            folderId: null,
            folderName: '',
            contentType: '',
            dataRetentionPolicy: {
              dataRetentionPolicy: false,
              individualRecords: false,
              allRecordsAndDE: false,
              allRecords: false,
              periodAfter: false,
              periodOn: false,
              resetRetentionPeriodOnImport: false,
              periodOnDisabled: true,
              dataRetentionPeriod:
                Constants.DATA_RETENTION_POLICY__PERIOD__DAYS,
              dataRetentionPeriodLength: 1,
              dataExtensionPeriodDate: '',
              toggle: false,
            },
            relationship: {
              isSendable: false,
              isTestable: false,
              sendableDataExtensionField: {
                name: '',
                type: '',
              },
              sendableSubscriberField: {
                name: '',
              },
            },
          },
          subscriberFieldNames: [],
          newTargetDataExtensionFields: [],
          targetDataExtensions: [],
          prioDeduplication: {
            multipleSortingOptionLines: [],
            mode: Constants.PRIO_DEDUP__MODE__BASIC__VALUE,
            type: Constants.PRIO_DEDUP__SORTING_PRIO__DEFINE_VALUES__VALUE,
            deduplicationFieldObjectID: '',
            criteriaFieldObjectID: '',
            priorities: [],
            sortOrder: Constants.PRIO_DEDUP__SORT_ORDER__ASC,
          },
          sortLimit: {
            enabled: false,
            select: {
              type: Constants.SORT_LIMIT__SELECT_TYPE__NUMBER,
              value: 0,
            },
            orderBy: {
              type: Constants.SORT_LIMIT__ORDER_BY_TYPE__FIELD,
              fieldObjectID: '',
              sort: '',
            },
          },
          usePrioDeduplication: false,
          previousAdvancedDeduplicationRules: [],
          advancedDeduplicationRules: [],
          advancedDedupFilterSaveIndex: null,

          /*
           * if there are no fields with primary key and data action is update then set
           * data action to overwrite.
           */
          dataAction: checkIfActionTypeShouldBeChanged(newDeFields) ?
            Constants.DATA_ACTION__OVERWRITE :
            dataAction,
        });
        // refresh the dataextensions to get new de as selected one
        const targetDataExtensions = await DataExtensionsAPI.getDataExtensions(
          axiosCancelToken,
          Constants.DATAEXTENSION__FILTER_MODE__TARGET,
        );

        // format dataExtensions
        formatAvailableDEs(targetDataExtensions);
        // update state of this component so we can see newly created DE in dropdown
        handleSetSelectionState({ targetDataExtensions }, () => setNewDELoading(false));
      } else {
        /*
         * If no errors are thrown but returned result isn't correct either,
         * Throw an error modal with unexpected error.
         */
        setNewDELoading(false);
      }
    }

    return null;
  };
  /**
   * Handles click on the third arrow
   * @returns {void}
   */
  const handleThirdArrowClick = () => {
    if (editNewAutoTargetDE || editTargetDataExtension || disableRunDetails) {
      return null;
    } if (waterfallSelection) {
      // for waterfall selection go to Run Details view
      selectionNavigation(Constants.NAVIGATION__RUN_DETAILS);
    } else {
      // for selection go to Preview
      selectionNavigation(Constants.NAVIGATION__PREVIEW);
    }

    return null;
  };

  /**
   * Deletes the certain properties from fields
   * @returns {Array[]} Array of objects with edited and added target de fields.
   */
  const prepareFieldsForUpdate = () => {
    const targetDataExtensionFieldsCopy = [...targetDataExtensionFields];
    const prevTargetDEFieldsCopy = [...prevTargetDEFields];

    // Create a targetDataExtensionFields map
    const targetDataExtensionFieldsMap = targetDataExtensionFieldsCopy.reduce((obj, item) => {
      obj[item.ObjectID] = true;

      return obj;
    }, {});

    // Create a prevTargetDEFieldsMap
    const prevTargetDEFieldsMap = prevTargetDEFieldsCopy.reduce((obj, item) => {
      obj[item.ObjectID] = true;

      return obj;
    }, {});

    // Get newly added fields
    const addedFields = [];
    const existingFields = [];

    targetDataExtensionFieldsCopy.forEach((field) => {
      if (prevTargetDEFieldsMap[field.ObjectID]) {
        // Field already exists, so push it to existingFields array
        existingFields.push(field);
      } else {
        // Field is newly added, so push it to addedFields array
        const addedField = JSON.parse(JSON.stringify(field));

        addedFields.push(addedField);
      }
    });

    // delete FieldType property from existing fields cause SFMC says that
    existingFields.forEach((field) => {
      // eslint-disable-next-line no-param-reassign
      delete field.FieldType;
    });

    // delete ObjectID from added fields cause SFMC says that
    addedFields.forEach((field) => {
      // eslint-disable-next-line no-param-reassign
      delete field.ObjectID;
    });

    // Identify removed fields
    const removedFields = prevTargetDEFieldsCopy.filter(field => !targetDataExtensionFieldsMap[field.ObjectID]);

    const fieldsForUpdate = [...existingFields, ...addedFields];

    // Set payload
    const payload = { fieldsForUpdate, removedFields };

    return payload;
  };

  /**
   * Update Target DE
   * @returns {object|null} - an object or null
   */
  const handleUpdateTargetDE = async () => {
    // check validation of target data extension before updating
    const validate = await validateTargetDataExtension();

    if (!validate) return;

    // Confirmation message before final approval
    const swalResult = await SwalUtil.fire({
      title: 'Update Target Data Extension',
      message: 'Are you sure you want to update the Target Data Extension with these selected fields?',
      options: {
        showCancelButton: true,
        confirmButtonText: 'Update',
      },
    });

    // If confirmed
    if (swalResult.value) {
      // Set newDELoading state
      setNewDELoading(true);
      const preparedFieldsForUpdate = prepareFieldsForUpdate();

      let updateResult;

      try {
        // Added and removed fields
        const { fieldsForUpdate, removedFields } = preparedFieldsForUpdate;

        // Check if newTargetDataExtension has the dataRetentionPolicy object
        if (newTargetDataExtension.dataRetentionPolicy) {
          const retentionPolicy = newTargetDataExtension.dataRetentionPolicy;

          // Convert dataRetentionPeriodLength to a string
          if (typeof retentionPolicy.dataRetentionPeriodLength === 'number') {
            retentionPolicy.dataRetentionPeriodLength = String(retentionPolicy.dataRetentionPeriodLength);
          }

          // Check if dataExtensionPeriodDate property exists within dataRetentionPolicy and is not an empty string
          if (typeof retentionPolicy.dataExtensionPeriodDate === 'string' &&
            retentionPolicy.dataExtensionPeriodDate.trim()) {
            // Modify the dataExtensionPeriodDate property using the timeUtil.formatDateForDatePicker function
            retentionPolicy.dataExtensionPeriodDate = timeUtil.formatDateForDatePicker(
              retentionPolicy.dataExtensionPeriodDate,
            );
          } else {
            retentionPolicy.dataExtensionPeriodDate = '';
          }
        }

        // update target data extension
        updateResult = await DataExtensionsAPI.updateTargetDataExtension({
          cancelToken: axiosCancelToken,
          fieldsForUpdate,
          removedFields,
          customerKey: targetDataExtensionCustomerKey,
          newTargetDataExtension,
        });
      } catch (error) {
        updateResult = { error: error.response?.data?.error || error };
      }

      // If error
      if (updateResult?.error) {
        // Set newDELoading state
        setNewDELoading(false);

        if (!String(updateResult?.error?.response?.data?.actualError).includes(
          Constants.ERROR_SFMC_PERMISSION_FAILED,
        )) {
          return SwalUtil.fire({
            type: Constants.SWAL__TYPE__ERROR,
            message: updateResult.error,
          });
        }
      }

      // If no error
      if (updateResult?.updatedDataExtension?.CustomerKey?.toString()) {
        // Get fields of the updated targeted DE
        const [newDeResponse] = await DataExtensionsAPI.getDataExtensionsAndFieldsByCustomerKeys(
          axiosCancelToken,
          [{
            collectionCustomerKey: targetDataExtensionCustomerKey,
            collectionObjectID: updateResult?.updatedDataExtension?.ObjectID || '',
            type: Constants.DESELECT__DATA_EXTENSION__TYPE__TARGET,
          }],
          null,
        );

        // if response contains data then set it to newDEFields
        const newDeFields = newDeResponse?.fields || [];

        // update target de field objectID in matched fields
        targetDataExtensionFields.forEach((tf) => {
          matchedFields.forEach((mf) => {
            if (tf.ObjectID === mf.targetDataExtensionFieldObjectID) {
              newDeFields.forEach((nf) => {
                if (nf.Name.toString() === tf.Name.toString()) {
                  // eslint-disable-next-line no-param-reassign
                  mf.targetDataExtensionFieldObjectID = nf.ObjectID;
                }
              });
            }
          });
        });

        // update availableFieldObjectID in matched fields
        matchedFields.forEach((field) => {
          // for new fields, the availableFieldObjectID looks like: 'ObjectID+Name', which needs to be update
          if (field.availableFieldObjectID.includes('+')) {
            // get new field
            const getNewField = newDeFields.find(newField => newField.Name.toString() ===
              field.targetDataExtensionFieldName.toString());

            if (getNewField) {
              // assign only ObjectID without + and Name
              const [availableFieldObjectID] = field.availableFieldObjectID.split('+');
              // eslint-disable-next-line no-param-reassign

              field.availableFieldObjectID = availableFieldObjectID;
            }
          }
        });

        // Set newDELoading state
        setNewDELoading(false);

        handleSetSelectionState({
          editTargetDataExtension: false,
          targetDataExtensionCustomerKey,
          targetCollectionObjectID: newDeResponse?.ObjectID,
          targetDataExtensionFields: newDeFields,
          matchedFields,
          prevMatchedFields: [],
          prevTargetDEFields: [],
          targetDataExtension: _.omit(newDeResponse, 'fields') || {},
          newTargetDataExtension: {
            name: '',
            description: '',
            folderId: null,
            folderName: '',
            dataRetentionPolicy: {
              dataRetentionPolicy: false,
              individualRecords: false,
              allRecordsAndDE: false,
              allRecords: false,
              periodAfter: false,
              periodOn: false,
              resetRetentionPeriodOnImport: false,
              periodOnDisabled: true,
              dataRetentionPeriod:
                Constants.DATA_RETENTION_POLICY__PERIOD__DAYS,
              dataRetentionPeriodLength: 1,
              dataExtensionPeriodDate: '',
              toggle: false,
            },
            relationship: {
              isSendable: false,
              isTestable: false,
              sendableDataExtensionField: {
                name: '',
                type: '',
              },
              sendableSubscriberField: {
                name: '',
              },
            },
          },
          subscriberFieldNames: [],
        });

        // refresh the dataextensions to get new de as selected one
        const targetDataExtensions = await DataExtensionsAPI.getDataExtensions(
          axiosCancelToken,
          Constants.DATAEXTENSION__FILTER_MODE__TARGET,
        );

        // format dataExtensions
        formatAvailableDEs(targetDataExtensions);

        handleSetSelectionState({
          targetDataExtensions,
        });

        // Set newDELoading state

        // this.setState({ clickedRefreshTargetDEButton: false });
      } else {
        setNewDELoading(false);
      }
    }

    return null;
  };

  /**
   * Function which helps to open custom values modal
   * @returns {void}
   */
  const openAddValuesModal = () => {
    handleSetSelectionState({ showAddValuesModal: true });
  };

  /**
   * Choose target DE's id
   * Request api
   * Set field state of target DE
   * @param {object} event - event
   * @returns {void}
   */
  const handleChangeTargetDataExtension = async (event) => {
    // if there are mapped fields and targetDE throw a warning message
    if (matchedFields && matchedFields.length > 0 && targetDataExtensionCustomerKey) {
      const swalResult = await SwalUtil.fire({
        title: 'Change Target Data Extension',
        // eslint-disable-next-line max-len
        message: `Are you sure you want to change the Target Data Extension? You will lose ${matchedFields.length > 1 ? 'all mapped fields' : 'the mapped field'}.`,
        options: {
          showCancelButton: true,
          confirmButtonText: 'Change',
        },
      });
      // if user chooses cancel then don't change the target de

      if (!swalResult.value) {
        return;
      }
    }

    if (selectionType === Constants.SELECTION__TYPE__UNION) {
      // reset the fields for each unionSelection
      unionSelections.forEach((selection) => {
        /* eslint-disable no-param-reassign */
        selection.targetDataExtensionFields = [];
        selection.matchedFields = [];
        /* eslint-enable no-param-reassign */
      });
      handleSetAppState({ unionSelections });
    }

    // get data of selected target data extension
    const targetDataExtension = targetDataExtensions.find(dataExtension => (
      dataExtension?.ObjectID?.toString() === event.value));

    handleSetSelectionState({
      targetDataExtensionFields: [],
      matchedFields: [],
      targetDataExtensionCustomerKey: targetDataExtension?.CustomerKey || '',
      targetCollectionObjectID: event.value,
    });

    if (event.value !== '') {
      try {
        // fetch targetDE data with fields
        const [collection] = await DataExtensionsAPI.getDataExtensionsAndFieldsByCustomerKeys(
          axiosCancelToken,
          [{
            collectionCustomerKey: targetDataExtension?.CustomerKey || '',
            collectionObjectID: event.value.toString(),
            type: Constants.DESELECT__DATA_EXTENSION__TYPE__TARGET,
          }],
          null,
        );

        // define fields for targetDE
        const collectionFields = collection?.fields || [];

        /*
         * Update selectedDataExtensions fields with the fields from collection
         * Available fields will automatically be updated
         */
        if (selectedDataExtensions && selectedDataExtensions.length > 0) {
          selectedDataExtensions.forEach((de) => {
            if (de?.ObjectID?.toString() === event.value) {
              // eslint-disable-next-line no-param-reassign
              de.fields = collectionFields;
            }
          });
        }

        handleSetSelectionState({
          // set targetDataExtensionFields
          targetDataExtensionFields: collectionFields,

          // set targetDE data without fields
          targetDataExtension: _.omit(collection, 'fields'),

          // restart priodedup settings
          prioDeduplication: {
            multipleSortingOptionLines: [],
            mode: Constants.PRIO_DEDUP__MODE__BASIC__VALUE,
            type: Constants.PRIO_DEDUP__SORTING_PRIO__DEFINE_VALUES__VALUE,
            deduplicationFieldObjectID: '',
            criteriaFieldObjectID: '',
            priorities: [],
            sortOrder: Constants.PRIO_DEDUP__SORT_ORDER__ASC,
          },
          usePrioDeduplication: false,
          previousAdvancedDeduplicationRules: [],
          advancedDeduplicationRules: [],
          advancedDedupFilterSaveIndex: null,

          // reset sort and limit when target DE is changed
          sortLimit: {
            enabled: false,
            select: {
              type: Constants.SORT_LIMIT__SELECT_TYPE__NUMBER,
              value: 0,
            },
            orderBy: {
              type: Constants.SORT_LIMIT__ORDER_BY_TYPE__FIELD,
              fieldObjectID: '',
              sort: '',
            },
          },

          /*
           * if there are no fields with primary key and data action is update then set
           * data action to overwrite.
           */
          dataAction: checkIfActionTypeShouldBeChanged(collectionFields) ?
            Constants.DATA_ACTION__OVERWRITE :
            dataAction,
        });

        if (selectionType === Constants.SELECTION__TYPE__UNION) {
          unionSelections.forEach((selection) => {
            // eslint-disable-next-line no-param-reassign
            selection.targetDataExtensionFields = collectionFields;
          });

          handleSetAppState({ unionSelections });
        }
      } catch (error) {
        handleSetSelectionState({ error });
      }
    }
  };

  /*
   * formatting dataExtensions for dropdown and removing data views from it
   * for that dataextensions array is filtered if de name (text in dropdown)
   * doesn't start with _ (underscore)
   */
  const targetDeOptions = Util.formattedDataForTheDropdown(targetDataExtensions, 'ObjectID', 'Name')
    .filter(de => (de.text || de.text.toString()) ? de.text[0].toString() !== '_' : false);

  let targetDE = [];

  if (switchToDedup) {
    if (targetDataExtensions && targetDataExtensions.length) {
      targetDE = targetDataExtensions.filter(
        DE => DE?.ObjectID?.toString() === targetCollectionObjectID?.toString(),
      );

      if (targetDE?.length && targetDE[0].Name) {
        // add the fields and deAlias properties to targetDE
        targetDE[0] = { ...targetDE[0], fields: targetDataExtensionFields, deAlias: targetDE[0].Name.toString() };
      } else {
        targetDE = {};
      }
    } else {
      targetDE = {};
    }
  }

  const handleSwitchTargetDE = () => {
    handleSetSelectionState({ switchToDedup: false });
  };

  const createPlaceholderText = () => {
    if ((newDELoading && !targetDataExtension) || loadingForTargetDataExtensions) {
      return 'Loading Target Data Extensions...';
    }

    if (!targetDataExtensions?.length && !targetDataExtension) {
      return 'There are no Target Data Extensions available';
    }

    if (targetDataExtension) {
      return targetDataExtension.Name;
    }

    if (targetCollectionObjectID) {
      const targetDEName = targetDataExtensions.find(de => de.ObjectID === targetCollectionObjectID)?.Name;

      return targetDEName;
    }

    return 'Select a Target DE';
  };

  /**
   * get Target Data Extension link
   * @returns {string} - returns the TDE link
   */
  const getTDELink = () => {
    const domain = `https://mc.${orgInfo?.marketingCloudStackNumber}.exacttarget.com`;

    const link = domain + `/contactsmeta/admin.html#admin/data-extension/${targetDataExtension?.ObjectID}`;

    return link;
  };

  /**
   * get label for the Tools dropdown
   * @returns {node} - JSX node
   */
  const getToolsDropdownLabel = () => {
    return (
      <span className="slds-icon_container null slds-icon__svg--default">
        <svg className="slds-button__icon slds-button__icon_medium" aria-hidden="true">
          <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#custom_apps" />
        </svg>
        &nbsp;Tools&nbsp;
      </span>
    );
  };

  /**
   * get label for the deduplication option in the Tools dropdown
   * @returns {node} - JSX node
   */
  const getDeduplicationLabel = () => {
    return (
      <div
        title={Constants.TOOLBAR__DEDUPLICATION}
        className={classNames(
          'select-target-de-dropdown-option-wrapper',
          { 'disabled-option': !featurePrioDeduplication },
        )}>
        {Constants.TOOLBAR__DEDUPLICATION}
        {!featurePrioDeduplication && (
          <Tooltip
            align={Constants.SLDS_TOOLTIP_POSITION__BOTTOM}
            content={Constants.TOOLTIP_TYPE__UNAVAILABLE_FEATURE}
          />
        )}
      </div>
    );
  };

  /**
   * get label for the source limiting option in the Tools dropdown
   * @returns {node} - JSX node
   */
  const getSourceLimitingLabel = () => {
    return (
      <div
        title={Constants.TOOLBAR__SOURCE_LIMITING}
        className={classNames(
          'select-target-de-dropdown-option-wrapper',
          { 'disabled-option': !featureSourceLimitingIsEnabled },
        )}>
        {Constants.TOOLBAR__SOURCE_LIMITING}
        {!featureSourceLimitingIsEnabled && (
          <Tooltip
            align={Constants.SLDS_TOOLTIP_POSITION__BOTTOM}
            content={Constants.TOOLTIP_TYPE__UNAVAILABLE_FEATURE}
          />
        )}
      </div>
    );
  };

  return (
    <div className="des-lib-target-de-fields">

      {/* toolbar */}
      <div className="selectedTF-info-v2">
        {!editNewAutoTargetDE && !editTargetDataExtension ?
          (
            <div className="box-STF-icons">
              <div className="des-lib-select-tool">
                <CustomSelect
                  placeholder={createPlaceholderText()}
                  disabled={!targetDataExtensions?.length}
                  optionsData={targetDeOptions}
                  value={
                    targetDataExtensions?.length && !newDELoading && targetDataExtension ?
                      targetCollectionObjectID?.toString() :
                      ''
                  }
                  handleShowNewTargetDEModal={handleShowNewTargetDEModal}
                  onChange={handleChangeTargetDataExtension} />
                {targetCollectionObjectID !== '' &&
                  (
                    <a
                      href={getTDELink()}
                      target="__blank"
                      title="Open Data Extension in Contact Builder"
                      className="link-to-target-de-v2"
                    >
                      <ButtonStateful
                        assistiveText={{ icon: 'Open Data Extension in Contact Builder' }}
                        buttonVariant="icon"
                        iconName="new_window"
                        iconVariant="border"
                        variant="icon"
                      />
                    </a>
                  )}
              </div>
              <Dropdown
                buttonClassName="slds-button slds-button_neutral"
                className="selection-targetde-dropdown"
                iconCategory="utility"
                label={getToolsDropdownLabel()}
                iconVariant="more"
                iconPosition="right"
                iconSize="medium"
                style={{ width: '6rem' }}
                length={null}
                width="small"
                onSelect={(e) => {
                  switch (e.value) {
                    case Constants.TOOLBAR__EDIT_DATA_EXTENSION:
                      handleOpenEditTargetDE();
                      break;

                    case Constants.TOOLBAR__DATA_ACTION:
                      handleSetSelectionState({ showDataActionModal: true });
                      break;

                    case Constants.TOOLBAR__DEDUPLICATION:
                      handleSetSelectionState({ switchToDedup: true });
                      break;

                    case Constants.TOOLBAR__SORT_AND_LIMIT:
                      handleSetSelectionState({ showSortLimitModal: true });
                      break;

                    case Constants.TOOLBAR__SOURCE_LIMITING:
                      handleSetSelectionState({ showSourceLimitingModal: true });
                      break;

                    case Constants.TOOLBAR__DELETE_MAPPINGS:
                      deleteAllMatchedFields();
                      break;

                    case Constants.TOOLBAR__AUTOMAP:
                      autoMap();
                      break;

                    default:
                      break;
                  }
                }}
                options={[
                  {
                    label: Constants.TOOLBAR__EDIT_DATA_EXTENSION,
                    value: Constants.TOOLBAR__EDIT_DATA_EXTENSION,
                    leftIcon: {
                      category: 'utility',
                      name: 'edit',
                    },
                  },
                  {
                    label: Constants.TOOLBAR__DATA_ACTION,
                    value: Constants.TOOLBAR__DATA_ACTION,
                    leftIcon: {
                      category: 'utility',
                      name: 'database',
                    },
                  },
                  {
                    label: getDeduplicationLabel(),
                    value: Constants.TOOLBAR__DEDUPLICATION,
                    disabled: !featurePrioDeduplication,
                    leftIcon: {
                      category: 'utility',
                      name: 'priority',
                    },
                  },
                  {
                    label: Constants.TOOLBAR__SORT_AND_LIMIT,
                    value: Constants.TOOLBAR__SORT_AND_LIMIT,
                    leftIcon: {
                      category: 'utility',
                      name: 'sort',
                    },
                  },
                  {
                    label: getSourceLimitingLabel(),
                    value: Constants.TOOLBAR__SOURCE_LIMITING,
                    disabled: !featureSourceLimitingIsEnabled,
                    leftIcon: {
                      category: 'utility',
                      name: 'file',
                    },
                  },
                  {
                    label: Constants.TOOLBAR__AUTOMAP,
                    value: Constants.TOOLBAR__AUTOMAP,
                    leftIcon: {
                      category: 'utility',
                      name: 'open_folder',
                    },
                  },
                  {
                    label: Constants.TOOLBAR__DELETE_MAPPINGS,
                    value: Constants.TOOLBAR__DELETE_MAPPINGS,
                    leftIcon: {
                      category: 'utility',
                      name: 'delete',
                    },
                  },
                ]}
              />

              {showDataActionModal && (
                <DataAction
                  show={showDataActionModal}
                  dataAction={dataAction}
                  targetDataExtensionFields={targetDataExtensionFields}
                  handleSetSelectionState={handleSetSelectionState}
                />
              )}

              {showSortLimitModal && (
                <SortLimit
                  show={showSortLimitModal}
                  sortLimit={sortLimit}
                  targetDataExtensionFields={targetDataExtensionFields}
                  handleSetSelectionState={handleSetSelectionState}
                />
              )}

              {switchToDedup && (
                <PrioDedup
                  switchToTargetDE={() => handleSwitchTargetDE()}
                  targetDataExtensionFields={targetDataExtensionFields}
                  prioDeduplication={prioDeduplication}
                  handleSetSelectionState={handleSetSelectionState}
                  usePrioDeduplication={usePrioDeduplication}
                  selectedDataExtensions={targetDE}
                  handleFiltersSave={handleFiltersSave}
                  advancedDeduplicationRules={advancedDeduplicationRules}
                  filterBorderMouseOver={filterBorderMouseOver}
                  DEBorderMouseOver={DEBorderMouseOver}
                  getDataExtensionOrDataViewFields={getDataExtensionOrDataViewFields}
                  handleAdvancedDedupFiltersSave={handleAdvancedDedupFiltersSave}
                  advancedDedupFilterSaveIndex={advancedDedupFilterSaveIndex}
                  previousAdvancedDeduplicationRules={previousAdvancedDeduplicationRules}
                  pickLists={pickLists}
                  handlePickListOptions={handlePickListOptions}
                  handleRemoveFilterLine={handleRemoveFilterLine}
                  applyTimezoneSettingsToAllDateFields={applyTimezoneSettingsToAllDateFields}
                  timezoneSettingsForAllDateFields={timezoneSettingsForAllDateFields}
                  handleSetTimezoneToAllDateFields={handleSetTimezoneToAllDateFields}
                />
              )}

            </div>
          ) :
          null}
      </div>

      {/* Alerts */}
      <span>
        {dataAction === Constants.DATA_ACTION__OVERWRITE && !editNewAutoTargetDE && !editTargetDataExtension ?
          (
            <Alert
              className="warning-data-action-v2"
              type={Constants.ALERT__TYPE__WARNING}
              id="overwrite-warning"
              title={renderDataActionAlertTitle()}
            />
          ) :
          null}
        {(usePrioDeduplication && !editNewAutoTargetDE && !editTargetDataExtension &&
          targetDataExtensionFields?.length) ?
          (
            <Alert
              type={Constants.ALERT__TYPE__SUCCESS}
              className="indication-message"
              id="deduplication-message"
              withoutIcon
              title={`Deduplication enabled: ${renderDeduplicationMessage()}`}
            />
          ) :
          null}
        {(sortLimit?.enabled && !editNewAutoTargetDE && !editTargetDataExtension &&
          targetDataExtensionFields?.length) ?
          (
            <Alert
              type={Constants.ALERT__TYPE__SUCCESS}
              className="indication-message"
              id="sort-limit-message"
              withoutIcon
              title={`Sort & Limit enabled: ${renderSortLimitSelectMessage()}${renderSortLimitOrderMessage()}`}
            />
          ) :
          null}
      </span>
      <div className="des-lib-target-de-fields-container">

        <div className="des-lib-target-fields-wrapper">
          <div className="des-lib-target-field">
            <div className="des-lib-target-field-text">Original Field Name</div>
          </div>
          <div className="des-lib-target-field">
            <div className="des-lib-target-field-text">Target Data Extension Field Name</div>
          </div>
        </div>

        {/* Fields */}
        <div id="selected-fields" className="selectedTF-fields-v2">
          {/* <div className="row target-collection-row"></div> things will be rendered here */}
          <br />
          {renderDEField()}
          {/* enable this */}
          {(editNewAutoTargetDE && featureAutoCreateTargetDEIsEnabled) || editTargetDataExtension ?
            (
              <div
                className="target-de-fields-dropzone filter-drop filterline-dropzone"
                onDragOver={e => e.preventDefault()}
                onDrop={handleAddFieldToTargetDE}
                /* eslint-disable no-param-reassign */
                onDragEnter={(e) => { e.target.style.border = 'var(--solidBorder)'; }}
                onDragLeave={(e) => { e.target.style.border = 'var(--dashedBorder)'; }}
                /* eslint-enable no-param-reassign */
                style={{
                  border: filterBorderMouseOver ?
                    'var(--dashedBorder)' :
                    'var(--transparentBorder)',
                }}
              >
                <Tip
                  title="Tip:"
                  description={`Drop Fields or Custom Values here from the section on the left to
                     ${editTargetDataExtension ?
                    'add new fields to the Target Data Extension.' :
                    'automatically create a new Target Data Extension.'}`}
                  mode="dark"
                />
              </div>
            ) :
            null}
        </div>
      </div>
      <div className="des-lib-target-field-footer">
        <div>
          {/* <Button
            iconCategory="utility"
            className="des-lib-target-field-footer-text"
            iconName="add"
            iconPosition="left"
            label="Add Field"
          /> */}
          <Button
            iconCategory="utility"
            iconName="add"
            onClick={openAddValuesModal}
            className="des-lib-target-field-footer-text"
            iconPosition="left"
            label="Create Custom Value"
            disabled={!featureCustomValuesIsEnabled}
            tooltip={featureCustomValuesIsEnabled ?
              null :
              {
                type: Constants.TOOLTIP_TYPE__UNAVAILABLE_FEATURE,
                align: Constants.SLDS_TOOLTIP_POSITION__BOTTOM,
                id: 'custom-values-tab-tooltip',
              }}
          />
        </div>
        <div>
          {editNewAutoTargetDE || editTargetDataExtension ?
            (
              <span>
                <Button
                  variant="neutral"
                  className="des-lib-target-field-footer-text-next"
                  onClick={() => editNewAutoTargetDE ?
                    switchBackToExistingDE() :
                    switchBackToExistingTargetDE()}
                  label="Cancel"
                />
                <Button
                  variant="brand"
                  disabled={isArchived || hasReadOnlyAccess}
                  onClick={() => editNewAutoTargetDE ? handleCreateTargetDE() : handleUpdateTargetDE()}
                  className="des-lib-target-field-footer-text-next des-button-space"
                  label={editNewAutoTargetDE ? 'Save Data Extension' : 'Update Target Data Extension'}
                />
              </span>
            ) :
            (
              <Button
                variant="brand"
                onClick={() => handleThirdArrowClick()}
                disabled={!!(editNewAutoTargetDE || editTargetDataExtension || disableRunDetails)}
                className="des-lib-target-field-footer-text-next"
                label="Next"
              />
            )}
        </div>
      </div>
      <br />
      {(!editTargetDataExtension && !editNewAutoTargetDE) && (
        <Tip
          title="Tip:"
          description="Drag and drop a Field or Custom Value from the section on the left to
                     an Original Field Name on the right to indicate which values will be populated
                     in the Target Data Extension.
                     To add new fields to the Target Data Extension, click on Tools > Edit Data Extension."
          mode="dark"
        />
      )}
      <LoadingModal
        hideCloseButton
        hide={!newDELoading}
        loadingText={editTargetDataExtension ?
          'Updating Target Data Extension' :
          'Creating Target Data Extension'}
        id="DE-loadingmodal"
      />
    </div>
  );
};

SelectedTargetFields.propTypes = {
  /**
   * It keeps the value of AC/DE creation status
   * It will be passed from Selection.js
   */
  editNewAutoTargetDE: PropTypes.bool.isRequired,
  /**
   * It keeps the fields which user drag and drop during the process of creating a new DE
   */
  newTargetDataExtensionFields: PropTypes.instanceOf(Array),
  /**
   * It keeps the fields of an existing target data extension
   * It will be passed from Selection.js
   */
  targetDataExtensionFields: PropTypes.instanceOf(Array).isRequired,
  /**
   * It keeps the Customer Key of a selected existing target data extension
   * It will be passed from Selection.js
   */
  targetDataExtensionCustomerKey: PropTypes.string,
  /**
   * It keeps the matchedFields for a target data extension of the Selection
   * It will be passed from Selection.js
   */
  matchedFields: PropTypes.instanceOf(Array).isRequired,
  /**
   * Array of relations of the Selection
   */
  relations: PropTypes.instanceOf(Array).isRequired,
  /**
   * It keeps the selected data extensions for Selection.js
   * selected data extensions are stored as collections in database
   * It will be passed from Selection.js
   */
  selectedDataExtensions: PropTypes.instanceOf(Array).isRequired,
  /**
   * It helps to set the Selection`s state
   * It will be passed from Selection.js
   */
  handleSetSelectionState: PropTypes.func.isRequired,
  /**
   * It keeps the info about a New Auto Create Data Extension
   */
  newTargetDataExtension: PropTypes.instanceOf(Object).isRequired,
  /**
   * It keeps subscriber field names
   */
  subscriberFieldNames: PropTypes.instanceOf(Array),
  /**
   * It helps to add a new field to a DE during the process of creating a new DE
   */
  handleAddFieldToTargetDE: PropTypes.func,
  /**
   * It helps to match a field between available fields and target DE fields
   */
  dropToTargetDataExtensionField: PropTypes.func,
  /**
   * boolean state from Selection for going in or out the edit target de mode
   */
  editTargetDataExtension: PropTypes.bool.isRequired,
  /**
   * keeps target de fields before entering the edit target de mode
   * It will be passed from Selection.js
   */
  prevTargetDEFields: PropTypes.instanceOf(Array),
  /**
   * It keeps data action state (overwrite, append, update)
   */
  dataAction: PropTypes.string.isRequired,
  /**
   * It keeps the boolean state which is used to open or close data action modal
   * It will be passed from Selection.js
   */
  showDataActionModal: PropTypes.bool.isRequired,
  /**
   * Keeps he target data extensions after they are retrieved from SFMC
   */
  targetDataExtensions: PropTypes.instanceOf(Array),
  /**
   * Keeps track whether Available Fields are dragged
   */
  filterBorderMouseOver: PropTypes.bool.isRequired,
  /**
   * It keeps data about sort and limit
   */
  sortLimit: PropTypes.instanceOf(Object).isRequired,
  /**
   * It keeps the boolean state which is used to open or close sort and limit modal
   * It will be passed from Selection.js
   */
  showSortLimitModal: PropTypes.bool.isRequired,
  /**
   * It keeps if the deduplication settings will be applied
   */
  prioDeduplication: PropTypes.instanceOf(Object).isRequired,
  /**
   * It keeps if the deduplication settings will be applied
   */
  usePrioDeduplication: PropTypes.bool.isRequired,
  /**
   * Handles the changing of sendable and subscriber field names
   */
  handleChangeSendableAndSubscriberField: PropTypes.func,
  /**
   * It keeps data for selected target data extension
   */
  targetDataExtension: PropTypes.instanceOf(Object).isRequired,
  /**
   * Filters Rules for the advanced deduplication
   */
  advancedDeduplicationRules: PropTypes.instanceOf(Array).isRequired,
  /**
   * saves the filters for the advanced deduplication
   */
  handleAdvancedDedupFiltersSave: PropTypes.func.isRequired,
  /**
   * gets the index for the actual rule being worked on
   */
  advancedDedupFilterSaveIndex: PropTypes.number,
  /**
   * holds the state of the selected filter in case the cancel button is working
   */
  previousAdvancedDeduplicationRules: PropTypes.instanceOf(Array).isRequired,
  /**
   * Keeps searched picklist
   */
  pickLists: PropTypes.instanceOf(Array),
  /**
   * Responsible for adding/deleting fields Object IDs when searching picklist for the options
   */
  handlePickListOptions: PropTypes.func,
  /**
   * It helps to save the selected filters for the selection
   * it will be passed from Selection.js
   */
  handleFiltersSave: PropTypes.func.isRequired,
  /**
   * It helps to retrieve fields of a data extension or data view from SFMC
   * It will be passed from Selection.js
   */
  getDataExtensionOrDataViewFields: PropTypes.func.isRequired,
  /**
   * Keeps track whether Available DE are dragged
   */
  DEBorderMouseOver: PropTypes.bool.isRequired,
  /**
   * Decide if Dedup is screen will be opened or not
   */
  switchToDedup: PropTypes.bool,
  /**
   * It Removes a given filterLine
   * It is passed from Selection.js
   */
  handleRemoveFilterLine: PropTypes.func,
  /**
   * Indicates whether timezone settings are applied to all date filters
   */
  applyTimezoneSettingsToAllDateFields: PropTypes.bool,
  /**
   * Handles the setting of timezone settings to all date filters
   */
  handleSetTimezoneToAllDateFields: PropTypes.func,
  /**
   * An object containing timezone details
   */
  timezoneSettingsForAllDateFields: PropTypes.instanceOf(Object),
  /**
   * User info from cookie
   */
  userInfo: PropTypes.object,
  /**
   * Org info from cookie
   */
  orgInfo: PropTypes.object,
  /**
   * Features info from cookie
   */
  featuresInfo: PropTypes.object,
};

export default connect(
  mapStateToProps(['userInfo', 'orgInfo', 'featuresInfo']),
  null,
  null,
  { pure: false },
)(SelectedTargetFields);
