import axios from 'axios';

import Util from '../util';

const apiUrl = process.env.REACT_APP_API_URL;

const organisationsAPI = {
  /**
   * Gets user's business units
   * @param {object} cancelToken - Axios token
   * @returns {array} - An array containing user's BUs
   */
  getUsersBUs: async (cancelToken) => {
    const { data } = await Util.catch418And403Error(
      axios.get,
      `${apiUrl}/business-units`,
      Util.apiGetCallSettings(cancelToken),
    );

    if (data.success) {
      return data.data || [];
    }

    return [];
  },

  /**
   * activate user's business unit
   * @param {object} putData - object of data
   * @param {object} cancelToken - Axios token
   * @returns {array} - An array containing user's BUs
   */
  activateUsersBU: async (putData, cancelToken) => {
    const { data } = await Util.catch418And403Error(
      axios.put,
      `${apiUrl}/activate-business-unit`,
      putData,
      Util.apiGetCallSettings(cancelToken),
    );

    if (data.success) {
      return data || [];
    }

    return [];
  },

  /**
   * retrieves licenses information
   * @param {object} cancelToken - Axios token
   * @returns {object} - Licenses info object
   */
  getLicensesInfo: async (cancelToken) => {
    const res = await Util.catch418And403Error(
      axios.get,
      `${apiUrl}/organisations/licenses`,
      Util.apiGetCallSettings(cancelToken),
    );

    return res?.data?.licenses || [];
  },
};

export default organisationsAPI;
