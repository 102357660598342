/* eslint-disable import/prefer-default-export */
import axios from 'axios';

import SelectionFoldersAPI from '../../../api/selectionsFolders';
import { setErrors } from './errorActions';
import {
  LOADING_FOLDERS, SET_ALL_SELECTION_FOLDERS, SET_SELECTED_FOLDER_ID,
} from '../types';

/**
 * Function that sets the selection folders loading status
 * @param {string} isLoading - true/false depending of loading status
 * @returns {void}
 */
export const loadingFolders = isLoading => (dispatch) => {
  dispatch({
    type: LOADING_FOLDERS,
    payload: isLoading,
  });
};

/**
 * Function that gets and sets all existing folders for selections
 * @param {object} cancelToken - The cancel token from Axios
 * @returns {void}
 */
export const setAllSelectionFolders = cancelToken => async (dispatch) => {
  try {
    // get the selection folders data
    const { data } = await SelectionFoldersAPI.getSelectionFolders(
      cancelToken,
      false,
    );

    // set fetched folders in redux store
    dispatch({
      type: SET_ALL_SELECTION_FOLDERS,
      payload: data,
    });

    // stop loading folders
    dispatch(loadingFolders(false));
  } catch (err) {
    dispatch(loadingFolders(false));
    if (!axios.isCancel(err)) dispatch(setErrors(err));
  }
};

/**
 * Function that sets selected folder id
 * @param {string} folderId - selected folder ID
 * @returns {void}
 */
export const setSelectedFolderId = folderId => (dispatch) => {
  // set folderId in the redux store
  dispatch({
    type: SET_SELECTED_FOLDER_ID,
    payload: folderId,
  });
};
