/* eslint-disable max-len */
import ValidationConstants from './Validation/ValidationConstants';

// Date intervals
const DATE_INTERVALS = [
  ValidationConstants.KEYWORD__YEAR,
  ValidationConstants.KEYWORD__YYYY,
  ValidationConstants.KEYWORD__YY,
  ValidationConstants.KEYWORD__MONTH,
  ValidationConstants.KEYWORD__MM,
  ValidationConstants.KEYWORD__M,
  ValidationConstants.KEYWORD__QUARTER,
  ValidationConstants.KEYWORD__QQ,
  ValidationConstants.KEYWORD__Q,
  ValidationConstants.KEYWORD__DAY_OF_YEAR,
  ValidationConstants.KEYWORD__DY,
  ValidationConstants.KEYWORD__Y,
  ValidationConstants.KEYWORD__DAY,
  ValidationConstants.KEYWORD__DD,
  ValidationConstants.KEYWORD__D,
  ValidationConstants.KEYWORD__WEEK,
  ValidationConstants.KEYWORD__WW,
  ValidationConstants.KEYWORD__WK,
  ValidationConstants.KEYWORD__WEEKDAY,
  ValidationConstants.KEYWORD__DW,
  ValidationConstants.KEYWORD__W,
  ValidationConstants.KEYWORD__HOUR,
  ValidationConstants.KEYWORD__HH,
  ValidationConstants.KEYWORD__MINUTE,
  ValidationConstants.KEYWORD__MI,
  ValidationConstants.KEYWORD__N,
  ValidationConstants.KEYWORD__SECOND,
  ValidationConstants.KEYWORD__SS,
  ValidationConstants.KEYWORD__S,
  ValidationConstants.KEYWORD__MILLISECOND,
  ValidationConstants.KEYWORD__MS,
];

const DATA_TYPE = [
  ValidationConstants.KEYWORD__BINARY,
  ValidationConstants.KEYWORD__VARBINARY,
  ValidationConstants.KEYWORD__CHAR,
  ValidationConstants.KEYWORD__VARCHAR,
  ValidationConstants.KEYWORD__NCHAR,
  ValidationConstants.KEYWORD__NVARCHAR,
  ValidationConstants.KEYWORD__DATETIME,
  ValidationConstants.KEYWORD__DATE,
  ValidationConstants.KEYWORD__TIME,
  ValidationConstants.KEYWORD__DATETIMEOFFSET,
  ValidationConstants.KEYWORD__DATETIME2,
  ValidationConstants.KEYWORD__DECIMAL,
  ValidationConstants.KEYWORD__NUMERIC,
  ValidationConstants.KEYWORD__FLOAT,
  ValidationConstants.KEYWORD__REAL,
  ValidationConstants.KEYWORD__BIGINT,
  ValidationConstants.KEYWORD__INT,
  ValidationConstants.KEYWORD__SMALLINT,
  ValidationConstants.KEYWORD__TINYINT,
  ValidationConstants.KEYWORD__MONEY,
  ValidationConstants.KEYWORD__SMALLMONEY,
  ValidationConstants.KEYWORD__BIT,
  ValidationConstants.KEYWORD__TIMESTAMP,
  ValidationConstants.KEYWORD__UNIQUEIDENTIFIER,
  ValidationConstants.KEYWORD__NTEXT,
  ValidationConstants.KEYWORD__TEXT,
  ValidationConstants.KEYWORD__SQL_VARIANT,
  ValidationConstants.KEYWORD__XML,
  ValidationConstants.KEYWORD__HIERARCHYID,
];

const freeFormulaItems = {
  functions: [
    /** String functions */
    {
      name: 'ASCII',
      syntax: 'ASCII(string)',
      description: 'Returns the ASCII value of the first character in a string expression.',
      category: 'Text',
      parameters: [[ValidationConstants.TOKEN_TYPE__STRING]],
      numberOfParams: {
        min: 1,
        max: 1,
      },
      example: 'ASCII(\'A\')',
      link: 'https://www.w3schools.com/sql/func_sqlserver_ascii.asp',
      output: ValidationConstants.TOKEN_TYPE__NUMBER,
    },
    {
      name: 'CHAR',
      syntax: 'CHAR(number_code)',
      description: 'The CHAR() function returns the character based on the ASCII code.',
      category: 'Text',
      parameters: [[ValidationConstants.TOKEN_TYPE__NUMBER]],
      numberOfParams: {
        min: 1,
        max: 1,
      },
      example: 'CHAR(65)',
      link: 'https://www.w3schools.com/sql/func_sqlserver_char.asp',
      output: ValidationConstants.TOKEN_TYPE__STRING,
    },
    {
      name: 'CHARINDEX',
      syntax: 'CHARINDEX(substring, string, start_position)',
      description: 'The CHARINDEX() function searches for a substring in a string, and returns the position. Start position number is optional.',
      category: 'Text',
      parameters: [
        [ValidationConstants.TOKEN_TYPE__STRING],
        [ValidationConstants.TOKEN_TYPE__STRING],
        [ValidationConstants.TOKEN_TYPE__NUMBER],
      ],
      numberOfParams: {
        min: 2,
        max: 3,
      },
      example: 'CHARINDEX(\'to\', \'Customer\', 3)',
      link: 'https://www.w3schools.com/sql/func_sqlserver_charindex.asp',
      output: ValidationConstants.TOKEN_TYPE__NUMBER,
    },
    {
      name: 'CONCAT',
      syntax: 'CONCAT(string1, string2, ...., string_n)',
      description: 'The CONCAT() function adds two or more strings together.',
      category: 'Text',
      parameters: [[ValidationConstants.TOKEN_TYPE__STRING]],
      numberOfParams: {
        min: 2,
        max: 254,
      },
      containsNParams: true,
      example: 'CONCAT(\'SQL\', \' is\', \' fun!\')',
      link: 'https://www.w3schools.com/sql/func_sqlserver_concat.asp',
      output: ValidationConstants.TOKEN_TYPE__STRING,
    },
    {
      name: 'DATALENGTH',
      syntax: 'DATALENGTH(expression)',
      description: 'The DATALENGTH() function returns the number of bytes used to represent an expression of any data type.',
      category: 'Text',
      parameters: [[ValidationConstants.PARAM_TYPE__ANY]],
      numberOfParams: {
        min: 1,
        max: 1,
      },
      example: 'DATALENGTH(\'DESelect\')',
      link: 'https://www.w3schools.com/sql/func_sqlserver_datalength.asp',
      output: ValidationConstants.TOKEN_TYPE__NUMBER,
    },
    {
      name: 'DIFFERENCE',
      syntax: 'DIFFERENCE(string, string)',
      description: 'The DIFFERENCE() function compares two SOUNDEX values, and returns an integer (the match for this two values) from 0 to 4 (the best match).',
      category: 'Text',
      parameters: [[ValidationConstants.TOKEN_TYPE__STRING], [ValidationConstants.TOKEN_TYPE__STRING]],
      numberOfParams: {
        min: 2,
        max: 2,
      },
      example: 'DIFFERENCE(\'Customer\', \'Custom\')',
      link: 'https://www.w3schools.com/sql/func_sqlserver_difference.asp',
      output: ValidationConstants.TOKEN_TYPE__NUMBER,
    },
    {
      name: 'FORMAT',
      syntax: 'FORMAT(value, format_string, culture)',
      description: 'The FORMAT() function formats a value (date/time and number) with the specified format pattern (and an optional culture).',
      category: 'Text',
      parameters: [
        [ValidationConstants.TOKEN_TYPE__NUMBER,
          ValidationConstants.TOKEN_TYPE__DECIMAL,
          ValidationConstants.PARAM_TYPE__DATE],
        [ValidationConstants.TOKEN_TYPE__STRING],
        [ValidationConstants.TOKEN_TYPE__STRING],
      ],
      numberOfParams: {
        min: 2,
        max: 3,
      },
      example: 'FORMAT(11122020, \'##-##-####\')',
      link: 'https://www.w3schools.com/sql/func_sqlserver_format.asp',
      output: ValidationConstants.TOKEN_TYPE__STRING,
    },
    {
      name: 'LEFT',
      syntax: 'LEFT(string, number_of_chars)',
      description: 'The LEFT() function extracts a number of characters from a string (starting from left).',
      category: 'Text',
      parameters: [[ValidationConstants.TOKEN_TYPE__STRING], [ValidationConstants.TOKEN_TYPE__NUMBER]],
      numberOfParams: {
        min: 2,
        max: 2,
      },
      example: 'LEFT(\'SQL Example\', 3)',
      link: 'https://www.w3schools.com/sql/func_sqlserver_left.asp',
      output: ValidationConstants.TOKEN_TYPE__STRING,
    },
    {
      name: 'LEN',
      syntax: 'LEN(string)',
      description: 'The LEN() function returns the length of a string.',
      category: 'Text',
      parameters: [[ValidationConstants.TOKEN_TYPE__STRING]],
      numberOfParams: {
        min: 1,
        max: 1,
      },
      example: 'LEN(\'DESelect\')',
      link: 'https://www.w3schools.com/sql/func_sqlserver_len.asp',
      output: ValidationConstants.TOKEN_TYPE__NUMBER,
    },
    {
      name: 'LOWER',
      syntax: 'LOWER(string)',
      description: 'The LOWER() function converts a string to lower-case.',
      category: 'Text',
      parameters: [[ValidationConstants.TOKEN_TYPE__STRING]],
      numberOfParams: {
        min: 1,
        max: 1,
      },
      example: 'LOWER(\'SQL is FUN!\')',
      link: 'https://www.w3schools.com/sql/func_sqlserver_lower.asp',
      output: ValidationConstants.TOKEN_TYPE__STRING,
    },
    {
      name: 'LTRIM',
      syntax: 'LTRIM(string)',
      description: 'The LTRIM() function removes leading spaces from a string.',
      category: 'Text',
      parameters: [[ValidationConstants.TOKEN_TYPE__STRING]],
      numberOfParams: {
        min: 1,
        max: 1,
      },
      example: 'LTRIM(\'' + Array(10).fill('\xa0').join('') + 'DESelect\')',
      link: 'https://www.w3schools.com/sql/func_sqlserver_ltrim.asp',
      output: ValidationConstants.TOKEN_TYPE__STRING,
    },
    {
      name: 'NCHAR',
      syntax: 'NCHAR(number_code)',
      description: 'The NCHAR() function returns the Unicode character based on the number code.',
      category: 'Text',
      parameters: [[ValidationConstants.TOKEN_TYPE__NUMBER]],
      numberOfParams: {
        min: 1,
        max: 1,
      },
      example: 'NCHAR(65)',
      link: 'https://www.w3schools.com/sql/func_sqlserver_nchar.asp',
      output: ValidationConstants.TOKEN_TYPE__STRING,
    },
    {
      name: 'PATINDEX',
      syntax: 'PATINDEX(\'%pattern%\', string)',
      description: 'The PATINDEX() function returns the position of a pattern in a string. If the pattern is not found, this function returns 0.',
      category: 'Text',
      parameters: [[ValidationConstants.TOKEN_TYPE__STRING], [ValidationConstants.TOKEN_TYPE__STRING]],
      numberOfParams: {
        min: 2,
        max: 2,
      },
      example: 'PATINDEX(\'%select%\', \'deselect.io\')',
      link: 'https://www.w3schools.com/sql/func_sqlserver_patindex.asp',
      output: ValidationConstants.TOKEN_TYPE__NUMBER,
    },
    {
      name: 'QUOTENAME',
      syntax: 'QUOTENAME(string, quote_char)',
      description: 'The QUOTENAME() function returns a Unicode string with delimiters added to make the string a valid SQL Server delimited identifier. The quote_char is optional.',
      category: 'Text',
      parameters: [[ValidationConstants.TOKEN_TYPE__STRING], [ValidationConstants.TOKEN_TYPE__STRING]],
      numberOfParams: {
        min: 1,
        max: 2,
      },
      example: 'QUOTENAME(\'string\', \'()\')',
      link: 'https://www.w3schools.com/sql/func_sqlserver_quotename.asp',
      output: ValidationConstants.TOKEN_TYPE__STRING,
    },
    {
      name: 'REPLACE',
      syntax: 'REPLACE(string, old_string, new_string)',
      description: 'The REPLACE() function replaces all occurrences of a substring within a string, ' +
        'with a new substring.',
      category: 'Text',
      parameters: [
        [ValidationConstants.TOKEN_TYPE__STRING],
        [ValidationConstants.TOKEN_TYPE__STRING],
        [ValidationConstants.TOKEN_TYPE__STRING],
      ],
      numberOfParams: {
        min: 3,
        max: 3,
      },
      example: 'REPLACE(\'SQL is FUN!\', \'SQL\', \'DESelect\')',
      link: 'https://www.w3schools.com/sql/func_sqlserver_replace.asp',
      output: ValidationConstants.TOKEN_TYPE__STRING,
    },
    {
      name: 'REPLICATE',
      syntax: 'REPLICATE(string, integer)',
      description: 'The REPLICATE() function repeats a string a specified number of times.',
      category: 'Text',
      parameters: [[ValidationConstants.TOKEN_TYPE__STRING], [ValidationConstants.TOKEN_TYPE__NUMBER]],
      numberOfParams: {
        min: 2,
        max: 2,
      },
      example: 'REPLICATE(\'CustomerName\', 2)',
      link: 'https://www.w3schools.com/sql/func_sqlserver_replicate.asp',
      output: ValidationConstants.TOKEN_TYPE__STRING,
    },
    {
      name: 'REVERSE',
      syntax: 'REVERSE(string)',
      description: 'The REVERSE() function reverses a string and returns the result.',
      category: 'Text',
      parameters: [[ValidationConstants.TOKEN_TYPE__STRING]],
      numberOfParams: {
        min: 1,
        max: 1,
      },
      example: 'REVERSE(\'SQL Example\')',
      link: 'https://www.w3schools.com/sql/func_sqlserver_reverse.asp',
      output: ValidationConstants.TOKEN_TYPE__STRING,
    },
    {
      name: 'RIGHT',
      syntax: 'RIGHT(string, number_of_chars)',
      description: 'The RIGHT() function extracts a number of characters from a string (starting from right).',
      category: 'Text',
      parameters: [[ValidationConstants.TOKEN_TYPE__STRING], [ValidationConstants.TOKEN_TYPE__NUMBER]],
      numberOfParams: {
        min: 2,
        max: 2,
      },
      example: 'RIGHT(\'SQL Example\', 3)',
      link: 'https://www.w3schools.com/sql/func_sqlserver_right.asp',
      output: ValidationConstants.TOKEN_TYPE__STRING,
    },
    {
      name: 'RTRIM',
      syntax: 'RTRIM(string)',
      description: 'The RTRIM() function removes trailing spaces from a string.',
      category: 'Text',
      parameters: [[ValidationConstants.TOKEN_TYPE__STRING]],
      numberOfParams: {
        min: 1,
        max: 1,
      },
      example: 'RTRIM(\'SQL Example' + Array(10).fill('\xa0').join('') + '\')',
      link: 'https://www.w3schools.com/sql/func_sqlserver_rtrim.asp',
      output: ValidationConstants.TOKEN_TYPE__STRING,
    },
    {
      name: 'SOUNDEX',
      syntax: 'SOUNDEX(string)',
      description: 'The SOUNDEX() function returns a four-character code to evaluate the similarity of two expressions.',
      category: 'Text',
      parameters: [[ValidationConstants.TOKEN_TYPE__STRING]],
      numberOfParams: {
        min: 1,
        max: 1,
      },
      example: 'SOUNDEX(\'DESelect\')',
      link: 'https://www.w3schools.com/sql/func_sqlserver_soundex.asp',
      output: ValidationConstants.TOKEN_TYPE__STRING,
    },
    {
      name: 'SPACE',
      syntax: 'SPACE(number)',
      description: 'The SPACE() function returns a string of the specified number of space characters.',
      category: 'Text',
      parameters: [[ValidationConstants.TOKEN_TYPE__NUMBER]],
      numberOfParams: {
        min: 1,
        max: 1,
      },
      example: 'SPACE(10)',
      link: 'https://www.w3schools.com/sql/func_sqlserver_space.asp',
      output: ValidationConstants.TOKEN_TYPE__STRING,
    },
    {
      name: 'STR',
      syntax: 'STR(number, length, decimals)',
      description: 'The STR() function returns a number as a string. The length of the result string and the number of decimals to display are optional.',
      category: 'Text',
      parameters: [
        [ValidationConstants.TOKEN_TYPE__NUMBER, ValidationConstants.TOKEN_TYPE__DECIMAL],
        [ValidationConstants.TOKEN_TYPE__NUMBER],
        [ValidationConstants.TOKEN_TYPE__NUMBER],
      ],
      numberOfParams: {
        min: 1,
        max: 3,
      },
      example: 'STR(185.476, 6, 2)',
      link: 'https://www.w3schools.com/sql/func_sqlserver_str.asp',
      output: ValidationConstants.TOKEN_TYPE__STRING,
    },
    {
      name: 'STUFF',
      syntax: 'STUFF(string, start, length, new_string)',
      description: 'The STUFF() function deletes a part of a string and then inserts another part into the string, starting at a specified position.',
      category: 'Text',
      parameters: [
        [ValidationConstants.TOKEN_TYPE__STRING],
        [ValidationConstants.TOKEN_TYPE__NUMBER],
        [ValidationConstants.TOKEN_TYPE__NUMBER],
        [ValidationConstants.TOKEN_TYPE__STRING],
      ],
      numberOfParams: {
        min: 4,
        max: 4,
      },
      example: 'STUFF(\'SQL Example\', 1, 3, \'HTML\')',
      link: 'https://www.w3schools.com/sql/func_sqlserver_stuff.asp',
      output: ValidationConstants.TOKEN_TYPE__STRING,
    },
    {
      name: 'SUBSTRING',
      syntax: 'SUBSTRING(string, start, length)',
      description: 'The SUBSTRING() function extracts some characters from a string.',
      category: 'Text',
      parameters: [
        [ValidationConstants.TOKEN_TYPE__STRING],
        [ValidationConstants.TOKEN_TYPE__NUMBER],
        [ValidationConstants.TOKEN_TYPE__NUMBER],
      ],
      numberOfParams: {
        min: 3,
        max: 3,
      },
      example: 'SUBSTRING(\'SQL Example\', 1, 3)',
      link: 'https://www.w3schools.com/sql/func_sqlserver_substring.asp',
      output: ValidationConstants.TOKEN_TYPE__STRING,
    },
    {
      name: 'UNICODE',
      syntax: 'UNICODE(string)',
      description: 'The UNICODE() function returns an integer value (the Unicode value), for the first character of the string expression.',
      category: 'Text',
      parameters: [[ValidationConstants.TOKEN_TYPE__STRING]],
      numberOfParams: {
        min: 1,
        max: 1,
      },
      example: 'UNICODE(\'Customers\')',
      link: 'https://www.w3schools.com/sql/func_sqlserver_unicode.asp',
      output: ValidationConstants.TOKEN_TYPE__STRING,
    },
    {
      name: 'UPPER',
      syntax: 'UPPER(string)',
      description: 'The UPPER() function converts a string to upper-case.',
      category: 'Text',
      parameters: [[ValidationConstants.TOKEN_TYPE__STRING]],
      numberOfParams: {
        min: 1,
        max: 1,
      },
      example: 'UPPER(\'sql is fun!\')',
      link: 'https://www.w3schools.com/sql/func_sqlserver_upper.asp',
      output: ValidationConstants.TOKEN_TYPE__STRING,
    },

    /** Numeric Functions */
    {
      name: 'ABS',
      syntax: 'ABS(number)',
      description: 'The ABS() function returns the absolute value of a number.',
      category: 'Numeric',
      numberOfParams: {
        min: 1,
        max: 1,
      },
      parameters: [[ValidationConstants.TOKEN_TYPE__NUMBER, ValidationConstants.TOKEN_TYPE__DECIMAL]],
      example: 'ABS(-243.5)',
      link: 'https://www.w3schools.com/sql/func_sqlserver_abs.asp',
      output: ValidationConstants.TOKEN_TYPE__NUMBER,
    },
    {
      name: 'ACOS',
      syntax: 'ACOS(number)',
      description: 'The ACOS() function returns the arc cosine of a number. The specified number must be between -1 to 1.',
      category: 'Numeric',
      numberOfParams: {
        min: 1,
        max: 1,
      },
      parameters: [[ValidationConstants.TOKEN_TYPE__NUMBER, ValidationConstants.TOKEN_TYPE__DECIMAL]],
      example: 'ACOS(0.25)',
      link: 'https://www.w3schools.com/sql/func_sqlserver_acos.asp',
      output: ValidationConstants.TOKEN_TYPE__NUMBER,
      allowedParVal: { minValue: -1, maxValue: 1 },
    },
    {
      name: 'ASIN',
      syntax: 'ASIN(number)',
      description: 'The ASIN() function returns the arc sine of a number. The specified number must be between -1 to 1.',
      category: 'Numeric',
      numberOfParams: {
        min: 1,
        max: 1,
      },
      parameters: [[ValidationConstants.TOKEN_TYPE__NUMBER, ValidationConstants.TOKEN_TYPE__DECIMAL]],
      example: 'ASIN(0.25)',
      link: 'https://www.w3schools.com/sql/func_sqlserver_asin.asp',
      output: ValidationConstants.TOKEN_TYPE__NUMBER,
      allowedParVal: { minValue: -1, maxValue: 1 },
    },
    {
      name: 'ATAN',
      syntax: 'ATAN(number)',
      description: 'The ATAN() function returns the arc tangent of a number.',
      category: 'Numeric',
      numberOfParams: {
        min: 1,
        max: 1,
      },
      parameters: [[ValidationConstants.TOKEN_TYPE__NUMBER, ValidationConstants.TOKEN_TYPE__DECIMAL]],
      example: 'ATAN(2.5)',
      link: 'https://www.w3schools.com/sql/func_sqlserver_atan.asp',
      output: ValidationConstants.TOKEN_TYPE__NUMBER,
    },
    {
      name: 'ATN2',
      syntax: 'ATN2(a, b)',
      description: 'The ATN2() function returns the arc tangent of two numbers.',
      category: 'Numeric',
      numberOfParams: {
        min: 2,
        max: 2,
      },
      parameters: [[ValidationConstants.TOKEN_TYPE__NUMBER, ValidationConstants.TOKEN_TYPE__DECIMAL],
        [ValidationConstants.TOKEN_TYPE__NUMBER, ValidationConstants.TOKEN_TYPE__DECIMAL]],
      example: 'ATN2(0.50, 1)',
      link: 'https://www.w3schools.com/sql/func_sqlserver_atn2.asp',
      output: ValidationConstants.TOKEN_TYPE__NUMBER,
    },
    {
      name: 'CEILING',
      syntax: 'CEILING(number)',
      description: 'The CEILING() function returns the smallest integer value that is larger than or equal to a number.',
      category: 'Numeric',
      numberOfParams: {
        min: 1,
        max: 1,
      },
      parameters: [[ValidationConstants.TOKEN_TYPE__NUMBER, ValidationConstants.TOKEN_TYPE__DECIMAL]],
      example: 'CEILING(25.75)',
      link: 'https://www.w3schools.com/sql/func_sqlserver_ceiling.asp',
      output: ValidationConstants.TOKEN_TYPE__NUMBER,
    },
    {
      name: 'COS',
      syntax: 'COS(number)',
      description: 'The COS() function returns the cosine of a number.',
      category: 'Numeric',
      numberOfParams: {
        min: 1,
        max: 1,
      },
      parameters: [[ValidationConstants.TOKEN_TYPE__NUMBER, ValidationConstants.TOKEN_TYPE__DECIMAL]],
      example: 'COS(2)',
      link: 'https://www.w3schools.com/sql/func_sqlserver_cos.asp',
      output: ValidationConstants.TOKEN_TYPE__NUMBER,
    },
    {
      name: 'COT',
      syntax: 'COT(number)',
      description: 'The COT() function returns the cotangent of a number.',
      category: 'Numeric',
      numberOfParams: {
        min: 1,
        max: 1,
      },
      parameters: [[ValidationConstants.TOKEN_TYPE__NUMBER, ValidationConstants.TOKEN_TYPE__DECIMAL]],
      example: 'COT(6)',
      link: 'https://www.w3schools.com/sql/func_sqlserver_cot.asp',
      output: ValidationConstants.TOKEN_TYPE__NUMBER,
    },
    {
      name: 'DEGREES',
      syntax: 'DEGREES(number)',
      description: 'The DEGREES() function converts a value in radians to degrees.',
      category: 'Numeric',
      numberOfParams: {
        min: 1,
        max: 1,
      },
      parameters: [[ValidationConstants.TOKEN_TYPE__NUMBER, ValidationConstants.TOKEN_TYPE__DECIMAL]],
      example: 'DEGREES(PI()*2)',
      link: 'https://www.w3schools.com/sql/func_sqlserver_degrees.asp',
      output: ValidationConstants.TOKEN_TYPE__NUMBER,
    },
    {
      name: 'EXP',
      syntax: 'EXP(number)',
      description: 'The EXP() function returns e raised to the power of a specified number.',
      category: 'Numeric',
      numberOfParams: {
        min: 1,
        max: 1,
      },
      parameters: [[ValidationConstants.TOKEN_TYPE__NUMBER, ValidationConstants.TOKEN_TYPE__DECIMAL]],
      example: 'EXP(2)',
      link: 'https://www.w3schools.com/sql/func_sqlserver_exp.asp',
      output: ValidationConstants.TOKEN_TYPE__NUMBER,
    },
    {
      name: 'FLOOR',
      syntax: 'FLOOR(number)',
      description: 'The FLOOR() function returns the largest integer value that is smaller than or equal to a number.',
      category: 'Numeric',
      numberOfParams: {
        min: 1,
        max: 1,
      },
      parameters: [[ValidationConstants.TOKEN_TYPE__NUMBER, ValidationConstants.TOKEN_TYPE__DECIMAL]],
      example: 'FLOOR(25.75)',
      link: 'https://www.w3schools.com/sql/func_sqlserver_floor.asp',
      output: ValidationConstants.TOKEN_TYPE__NUMBER,
    },
    {
      name: 'LOG',
      syntax: 'LOG(number, base)',
      description: 'The LOG() function returns the the natural logarithm of a number or the logarithm of the number to the specified base (which is optional).',
      category: 'Numeric',
      numberOfParams: {
        min: 1,
        max: 2,
      },
      parameters: [[ValidationConstants.TOKEN_TYPE__NUMBER, ValidationConstants.TOKEN_TYPE__DECIMAL],
        [ValidationConstants.TOKEN_TYPE__NUMBER, ValidationConstants.TOKEN_TYPE__DECIMAL]],
      example: 'LOG(2, 4)',
      link: 'https://www.w3schools.com/sql/func_sqlserver_log.asp',
      output: ValidationConstants.TOKEN_TYPE__NUMBER,
    },
    {
      name: 'LOG10',
      syntax: 'LOG10(number)',
      description: 'The LOG10() function returns the natural logarithm of a number to base 10.',
      category: 'Numeric',
      numberOfParams: {
        min: 1,
        max: 1,
      },
      parameters: [[ValidationConstants.TOKEN_TYPE__NUMBER, ValidationConstants.TOKEN_TYPE__DECIMAL]],
      example: 'LOG10(2)',
      link: 'https://www.w3schools.com/sql/func_sqlserver_log10.asp',
      output: ValidationConstants.TOKEN_TYPE__NUMBER,
    },
    {
      name: 'PI',
      syntax: 'PI()',
      description: 'The PI() function returns the value of PI.',
      category: 'Numeric',
      parameters: [],
      numberOfParams: {
        min: 0,
        max: 0,
      },
      example: 'PI()',
      link: 'https://www.w3schools.com/sql/func_sqlserver_pi.asp',
      output: ValidationConstants.TOKEN_TYPE__NUMBER,
    },
    {
      name: 'POWER',
      syntax: 'POWER(a, b)',
      description: 'The POWER() function returns the value of a number raised to the power of another number.',
      category: 'Numeric',
      numberOfParams: {
        min: 2,
        max: 2,
      },
      parameters: [[ValidationConstants.TOKEN_TYPE__NUMBER, ValidationConstants.TOKEN_TYPE__DECIMAL],
        [ValidationConstants.TOKEN_TYPE__NUMBER, ValidationConstants.TOKEN_TYPE__DECIMAL]],
      example: 'POWER(4, 2)',
      link: 'https://www.w3schools.com/sql/func_sqlserver_power.asp',
      output: ValidationConstants.TOKEN_TYPE__NUMBER,
    },
    {
      name: 'RADIANS',
      syntax: 'RADIANS(number)',
      description: 'The RADIANS() function converts a degree value into radians.',
      category: 'Numeric',
      numberOfParams: {
        min: 1,
        max: 1,
      },
      parameters: [[ValidationConstants.TOKEN_TYPE__NUMBER, ValidationConstants.TOKEN_TYPE__DECIMAL]],
      example: 'RADIANS(180)',
      link: 'https://www.w3schools.com/sql/func_sqlserver_radians.asp',
      output: ValidationConstants.TOKEN_TYPE__NUMBER,
    },
    {
      name: 'RAND',
      syntax: 'RAND(seed)',
      description: 'The RAND() function returns a random number between 0 (inclusive) and 1 (exclusive). The seed is optional and if passed, the function returns a repeatable sequence of random numbers.',
      category: 'Numeric',
      parameters: [[ValidationConstants.TOKEN_TYPE__NUMBER]],
      numberOfParams: {
        min: 0,
        max: 1,
      },
      example: 'RAND(6)',
      link: 'https://www.w3schools.com/sql/func_sqlserver_rand.asp',
      output: ValidationConstants.TOKEN_TYPE__NUMBER,
    },
    {
      name: 'ROUND',
      syntax: 'ROUND(number, decimals, operation)',
      description: 'The ROUND() function rounds a number to a specified number of decimal places. Operation is optional and if passed, the function truncates the result to the number of decimals.',
      category: 'Numeric',
      parameters: [
        [ValidationConstants.TOKEN_TYPE__NUMBER, ValidationConstants.TOKEN_TYPE__DECIMAL],
        [ValidationConstants.TOKEN_TYPE__NUMBER],
        [ValidationConstants.TOKEN_TYPE__NUMBER],
      ],
      numberOfParams: {
        min: 2,
        max: 3,
      },
      example: 'ROUND(235.415, 2, 1)',
      link: 'https://www.w3schools.com/sql/func_sqlserver_round.asp',
      output: ValidationConstants.TOKEN_TYPE__NUMBER,
    },
    {
      name: 'SIGN',
      syntax: 'SIGN(number)',
      description: 'The SIGN() function returns the sign of a number (from -1 to 1).',
      category: 'Numeric',
      numberOfParams: {
        min: 1,
        max: 1,
      },
      parameters: [[ValidationConstants.TOKEN_TYPE__NUMBER, ValidationConstants.TOKEN_TYPE__DECIMAL]],
      example: 'SIGN(21)',
      link: 'https://www.w3schools.com/sql/func_sqlserver_sign.asp',
      output: ValidationConstants.TOKEN_TYPE__NUMBER,
    },
    {
      name: 'SIN',
      syntax: 'SIN(number)',
      description: 'The SIN() function returns the sine of a number.',
      category: 'Numeric',
      numberOfParams: {
        min: 1,
        max: 1,
      },
      parameters: [[ValidationConstants.TOKEN_TYPE__NUMBER, ValidationConstants.TOKEN_TYPE__DECIMAL]],
      example: 'SIN(2)',
      link: 'https://www.w3schools.com/sql/func_sqlserver_sin.asp',
      output: ValidationConstants.TOKEN_TYPE__NUMBER,
    },
    {
      name: 'SQRT',
      syntax: 'SQRT(number)',
      description: 'The SQRT() function returns the square root of a number.',
      category: 'Numeric',
      numberOfParams: {
        min: 1,
        max: 1,
      },
      parameters: [[ValidationConstants.TOKEN_TYPE__NUMBER, ValidationConstants.TOKEN_TYPE__DECIMAL]],
      example: 'SQRT(64)',
      link: 'https://www.w3schools.com/sql/func_sqlserver_sqrt.asp',
      output: ValidationConstants.TOKEN_TYPE__NUMBER,
    },
    {
      name: 'SQUARE',
      syntax: 'SQUARE(number)',
      description: 'The SQUARE() function returns the square of a number.',
      category: 'Numeric',
      numberOfParams: {
        min: 1,
        max: 1,
      },
      parameters: [[ValidationConstants.TOKEN_TYPE__NUMBER, ValidationConstants.TOKEN_TYPE__DECIMAL]],
      example: 'SQUARE(64)',
      link: 'https://www.w3schools.com/sql/func_sqlserver_square.asp',
      output: ValidationConstants.TOKEN_TYPE__NUMBER,
    },
    {
      name: 'TAN',
      syntax: 'TAN(number)',
      description: 'The TAN() function returns the tangent of a number.',
      category: 'Numeric',
      numberOfParams: {
        min: 1,
        max: 1,
      },
      parameters: [[ValidationConstants.TOKEN_TYPE__NUMBER, ValidationConstants.TOKEN_TYPE__DECIMAL]],
      example: 'TAN(1.75)',
      link: 'https://www.w3schools.com/sql/func_sqlserver_tan.asp',
      output: ValidationConstants.TOKEN_TYPE__NUMBER,
    },

    /** Date functions */
    {
      name: 'CURRENT_TIMESTAMP',
      syntax: 'CURRENT_TIMESTAMP',
      description: 'The CURRENT_TIMESTAMP function returns the current date and time, in a \'YYYY-MM-DD hh:mm:ss.mmm\' format.',
      category: 'Date',
      parameters: [],
      numberOfParams: {
        min: 0,
        max: 0,
      },
      example: 'CURRENT_TIMESTAMP',
      link: 'https://www.w3schools.com/sql/func_sqlserver_current_timestamp.asp',
      output: ValidationConstants.PARAM_TYPE__DATE,
    },
    {
      name: 'DATEADD',
      syntax: 'DATEADD(date_part, number, date)',
      description: 'The DATEADD() function adds a time/date date_part to a date and then returns the date.',
      category: 'Date',
      parameters: [
        [ValidationConstants.PARAM_TYPE__KEYWORD],
        [ValidationConstants.TOKEN_TYPE__NUMBER],
        [ValidationConstants.PARAM_TYPE__DATE],
      ],
      numberOfParams: {
        min: 3,
        max: 3,
      },
      // The index where the special param is
      specialParamsIndices: [0],
      // Special parameters
      specialParams: [DATE_INTERVALS],
      example: 'DATEADD(year, 1, \'2020/12/15\')',
      link: 'https://www.w3schools.com/sql/func_sqlserver_dateadd.asp',
      output: ValidationConstants.PARAM_TYPE__DATE,
    },
    {
      name: 'DATEDIFF',
      syntax: 'DATEDIFF(date_part, date1, date2)',
      description: 'The DATEDIFF() function returns the difference between two dates. ',
      category: 'Date',
      parameters: [
        [ValidationConstants.PARAM_TYPE__KEYWORD],
        [ValidationConstants.PARAM_TYPE__DATE],
        [ValidationConstants.PARAM_TYPE__DATE],
      ],
      numberOfParams: {
        min: 3,
        max: 3,
      },
      // The index where the special param is
      specialParamsIndices: [0],
      // Special parameters
      specialParams: [DATE_INTERVALS],
      example: 'DATEDIFF(year, \'2020/12/15\', \'2019/08/25\')',
      link: 'https://www.w3schools.com/sql/func_sqlserver_datediff.asp',
      output: ValidationConstants.TOKEN_TYPE__NUMBER,
    },
    {
      name: 'DATEFROMPARTS',
      syntax: 'DATEFROMPARTS(year, month, day)',
      description: 'The DATEFROMPARTS() function returns a date from the specified parts (year, month, and day values).',
      category: 'Date',
      parameters: [
        [ValidationConstants.TOKEN_TYPE__NUMBER],
        [ValidationConstants.TOKEN_TYPE__NUMBER],
        [ValidationConstants.TOKEN_TYPE__NUMBER],
      ],
      numberOfParams: {
        min: 3,
        max: 3,
      },
      example: 'DATEFROMPARTS(2020, 12, 15)',
      link: 'https://www.w3schools.com/sql/func_sqlserver_datefromparts.asp',
      output: ValidationConstants.PARAM_TYPE__DATE,
    },
    {
      name: 'DATENAME',
      syntax: 'DATENAME(date_part, date)',
      description: 'The DATENAME() function returns a specified part of a date. This function returns the result as a string value.',
      category: 'Date',
      numberOfParams: {
        min: 2,
        max: 2,
      },
      parameters: [[ValidationConstants.PARAM_TYPE__KEYWORD], [ValidationConstants.PARAM_TYPE__DATE]],
      // The index where the special param is
      specialParamsIndices: [0],
      // Special parameters
      specialParams: [DATE_INTERVALS],
      example: 'DATENAME(year, \'2020/12/15\')',
      link: 'https://www.w3schools.com/sql/func_sqlserver_datename.asp',
      output: ValidationConstants.TOKEN_TYPE__STRING,
    },
    {
      name: 'DATEPART',
      syntax: 'DATEPART(date_part, date)',
      description: 'The DATEPART() function returns a specified part of a date. This function returns the result as an integer value.',
      category: 'Date',
      numberOfParams: {
        min: 2,
        max: 2,
      },
      parameters: [[ValidationConstants.PARAM_TYPE__KEYWORD], [ValidationConstants.PARAM_TYPE__DATE]],
      // The index where the special param is
      specialParamsIndices: [0],
      // Special parameters
      specialParams: [DATE_INTERVALS],
      example: 'DATEPART(year, \'2020/12/15\')',
      link: 'https://www.w3schools.com/sql/func_sqlserver_datepart.asp',
      output: ValidationConstants.TOKEN_TYPE__NUMBER,
    },
    {
      name: 'DAY',
      syntax: 'DAY(date)',
      description: 'The DAY() function returns the day of the month (from 1 to 31) for a specified date.',
      category: 'Date',
      parameters: [[ValidationConstants.PARAM_TYPE__DATE]],
      numberOfParams: {
        min: 1,
        max: 1,
      },
      example: 'DAY(\'2020/12/15\')',
      link: 'https://www.w3schools.com/sql/func_sqlserver_day.asp',
      output: ValidationConstants.TOKEN_TYPE__NUMBER,
    },
    {
      name: 'GETDATE',
      syntax: 'GETDATE()',
      description: 'The GETDATE() function returns the current database system date and time, in a \'YYYY-MM-DD hh:mm:ss.mmm\' format.',
      category: 'Date',
      parameters: [],
      numberOfParams: {
        min: 0,
        max: 0,
      },
      example: 'GETDATE()',
      link: 'https://www.w3schools.com/sql/func_sqlserver_getdate.asp',
      output: ValidationConstants.PARAM_TYPE__DATE,
    },
    {
      name: 'GETUTCDATE',
      syntax: 'GETUTCDATE()',
      description: 'The GETUTCDATE() function returns the current database system UTC date and time, in a \'YYYY-MM-DD hh:mm:ss.mmm\' format.',
      category: 'Date',
      parameters: [],
      numberOfParams: {
        min: 0,
        max: 0,
      },
      example: 'GETUTCDATE()',
      link: 'https://www.w3schools.com/sql/func_sqlserver_getutcdate.asp',
      output: ValidationConstants.PARAM_TYPE__DATE,
    },
    {
      name: 'ISDATE',
      syntax: 'ISDATE(expression)',
      description: 'The ISDATE() function checks an expression and returns 1 if it is a valid date, otherwise 0.',
      category: 'Date',
      parameters: [[ValidationConstants.TOKEN_TYPE__STRING]],
      numberOfParams: {
        min: 1,
        max: 1,
      },
      example: 'ISDATE(\'2020-12-15\')',
      link: 'https://www.w3schools.com/sql/func_sqlserver_isdate.asp',
      output: ValidationConstants.TOKEN_TYPE__NUMBER,
    },
    {
      name: 'MONTH',
      syntax: 'MONTH(date)',
      description: 'The MONTH() function returns the month part for a specified date (a number from 1 to 12).',
      category: 'Date',
      parameters: [[ValidationConstants.PARAM_TYPE__DATE]],
      numberOfParams: {
        min: 1,
        max: 1,
      },
      example: 'MONTH(\'2020/12/15\')',
      link: 'https://www.w3schools.com/sql/func_sqlserver_month.asp',
      output: ValidationConstants.TOKEN_TYPE__NUMBER,
    },
    {
      name: 'SYSDATETIME',
      syntax: 'SYSDATETIME()',
      description: 'The SYSDATETIME() function returns the date and time of the computer where the SQL Server is running.',
      category: 'Date',
      parameters: [],
      numberOfParams: {
        min: 0,
        max: 0,
      },
      example: 'SYSDATETIME()',
      link: 'https://www.w3schools.com/sql/func_sqlserver_sysdatetime.asp',
      output: ValidationConstants.PARAM_TYPE__DATE,
    },
    {
      name: 'YEAR',
      syntax: 'YEAR(date)',
      description: 'The YEAR() function returns the year part for a specified date.',
      category: 'Date',
      parameters: [[ValidationConstants.PARAM_TYPE__DATE]],
      numberOfParams: {
        min: 1,
        max: 1,
      },
      example: 'YEAR(\'2020/12/15\')',
      link: 'https://www.w3schools.com/sql/func_sqlserver_year.asp',
      output: ValidationConstants.TOKEN_TYPE__NUMBER,
    },

    /** logical functions */
    /*
     * {
     *   name: 'CAST',
     *   syntax: 'CAST(expression AS datatype(length))',
     *   description: 'The CAST() function converts a value (of any type) into a specified datatype.',
     *   category: 'Logical',
     *   parameters: [[ValidationConstants.PARAM_TYPE__ANY], [ValidationConstants.PARAM_TYPE__ANY]],
     *   numberOfParams: numberOfParams: {
     *    min: 2,
     *    max: 2,
     *   },
     *   output: ValidationConstants.PARAM_TYPE__ANY,
     * },
     */
    {
      name: 'COALESCE',
      syntax: 'COALESCE(val1, val2, ...., val_n)',
      description: 'The COALESCE() function returns the first non-null value in a list.',
      category: 'Logical',
      parameters: [[ValidationConstants.PARAM_TYPE__ANY]],
      numberOfParams: {
        min: 0,
        max: Infinity,
      },
      containsNParams: true,
      example: 'COALESCE(NULL, 1, 2, \'DESelect.io\')',
      link: 'https://www.w3schools.com/sql/func_sqlserver_coalesce.asp',
      output: ValidationConstants.PARAM_TYPE__ANY,
    },
    {
      name: 'CONVERT',
      syntax: 'CONVERT(data_type, expression, style)',
      description: 'The CONVERT() function converts a value (of any type) into a specified datatype. The style used to convert between data types is optional.',
      category: 'Logical',
      parameters: [
        [ValidationConstants.PARAM_TYPE__KEYWORD],
        [ValidationConstants.PARAM_TYPE__ANY],
        [ValidationConstants.TOKEN_TYPE__NUMBER],
      ],
      numberOfParams: {
        min: 2,
        max: 3,
      },
      // The index where the special param is
      specialParamsIndices: [0],
      // Special parameters
      specialParams: [DATA_TYPE],
      example: 'CONVERT(varchar, \'2020-12-15\', 101)',
      link: 'https://www.w3schools.com/sql/func_sqlserver_convert.asp',
      output: ValidationConstants.PARAM_TYPE__ANY,
    },
    {
      name: 'IIF',
      syntax: 'IIF(condition, value_if_true, value_if_false)',
      description: 'The IIF() function returns a value if a condition is TRUE, or another value if a condition is FALSE.',
      category: 'Logical',
      parameters: [
        [ValidationConstants.PARAM_TYPE__ANY],
        [ValidationConstants.PARAM_TYPE__ANY],
        [ValidationConstants.PARAM_TYPE__ANY],
      ],
      numberOfParams: {
        min: 3,
        max: 3,
      },
      example: 'IIF(500<1000, \'YES\', \'NO\')',
      link: 'https://www.w3schools.com/sql/func_sqlserver_iif.asp',
      output: ValidationConstants.PARAM_TYPE__ANY,
    },
    {
      name: 'ISNULL',
      syntax: 'ISNULL(expression, value)',
      description: 'The ISNULL() function returns a specified value if the expression is NULL.',
      category: 'Logical',
      parameters: [[ValidationConstants.PARAM_TYPE__ANY], [ValidationConstants.PARAM_TYPE__ANY]],
      numberOfParams: {
        min: 2,
        max: 2,
      },
      example: 'ISNULL(NULL, 500)',
      link: 'https://www.w3schools.com/sql/func_sqlserver_isnull.asp',
      output: ValidationConstants.PARAM_TYPE__ANY,
    },
    {
      name: 'ISNUMERIC',
      syntax: 'ISNUMERIC(expression)',
      description: 'The ISNUMERIC() function tests whether an expression is numeric. This function returns 1 if the expression is numeric, otherwise it returns 0.',
      category: 'Logical',
      parameters: [[ValidationConstants.PARAM_TYPE__ANY]],
      numberOfParams: {
        min: 1,
        max: 1,
      },
      example: 'ISNUMERIC(\'4567\')',
      link: 'https://www.w3schools.com/sql/func_sqlserver_isnumeric.asp',
      output: ValidationConstants.TOKEN_TYPE__NUMBER,
    },
    {
      name: 'NULLIF',
      syntax: 'NULLIF(expr1, expr2)',
      description: 'The NULLIF() function returns NULL if two expressions are equal, otherwise it returns the first expression.',
      category: 'Logical',
      parameters: [[ValidationConstants.PARAM_TYPE__ANY], [ValidationConstants.PARAM_TYPE__ANY]],
      numberOfParams: {
        min: 2,
        max: 2,
      },
      example: 'NULLIF(\'Hello\', \'world\')',
      link: 'https://www.w3schools.com/sql/func_sqlserver_nullif.asp',
      output: ValidationConstants.PARAM_TYPE__ANY,
    },
    {
      name: 'SESSION_USER',
      syntax: 'SESSION_USER',
      description: 'The SESSION_USER function returns the name of the current user in the SQL Server database.',
      category: 'Logical',
      parameters: [],
      numberOfParams: {
        min: 0,
        max: 0,
      },
      example: 'SESSION_USER',
      link: 'https://www.w3schools.com/sql/func_sqlserver_session_user.asp',
      output: ValidationConstants.TOKEN_TYPE__STRING,
    },
    {
      name: 'SESSIONPROPERTY',
      syntax: 'SESSIONPROPERTY(option)',
      description: 'The SESSIONPROPERTY() function returns the session settings for a specified option.',
      category: 'Logical',
      numberOfParams: {
        min: 1,
        max: 1,
      },
      parameters: [[ValidationConstants.TOKEN_TYPE__STRING]],
      // The index where the special param is
      specialParamsIndices: [0],
      // Special parameters
      specialParams: [[
        ValidationConstants.KEYWORD__ANSI_NULLS,
        ValidationConstants.KEYWORD__ANSI_PADDING,
        ValidationConstants.KEYWORD__ANSI_WARNINGS,
        ValidationConstants.KEYWORD__ARITHABORT,
        ValidationConstants.KEYWORD__CONCAT_NULL_YIELDS_NULL,
        ValidationConstants.KEYWORD__NUMERIC_ROUNDABOUT,
        ValidationConstants.KEYWORD__QUOTED_IDENTIFIER,
      ]],
      example: 'SESSIONPROPERTY(\'ANSI_NULLS\')',
      link: 'https://www.w3schools.com/sql/func_sqlserver_sessionproperty.asp',
      output: ValidationConstants.TOKEN_TYPE__NUMBER,
    },
    {
      name: 'SYSTEM_USER',
      syntax: 'SYSTEM_USER',
      description: 'The SYSTEM_USER function returns the login name for the current user.',
      category: 'Logical',
      parameters: [],
      numberOfParams: {
        min: 0,
        max: 0,
      },
      example: 'SYSTEM_USER',
      link: 'https://www.w3schools.com/sql/func_sqlserver_system_user.asp',
      output: ValidationConstants.TOKEN_TYPE__STRING,
    },
    {
      name: 'USER_NAME',
      syntax: 'USER_NAME(id_number)',
      description: 'The USER_NAME() function returns the database user name based on the specified id. If id is not specified, the function returns the name of the current user.',
      category: 'Logical',
      parameters: [[ValidationConstants.TOKEN_TYPE__NUMBER]],
      numberOfParams: {
        min: 0,
        max: 1,
      },
      example: 'USER_NAME()',
      link: 'https://www.w3schools.com/sql/func_sqlserver_user_name.asp',
      output: ValidationConstants.TOKEN_TYPE__STRING,
    },
    {
      name: 'CASE STATEMENT',
      syntax: `CASE
      WHEN condition1 THEN result1
      WHEN condition2 THEN result2
      WHEN conditionN THEN resultN
      ELSE result
  END`,
      description: 'The CASE statement goes through conditions and returns a value when the first condition is met (like an if-then-else statement). So, once a condition is true, it will stop reading and return the result. If no conditions are true, it returns the value in the ELSE clause. If there is no ELSE part and no conditions are true, it returns NULL.',
      category: 'Logical',
      parameters: [],
      numberOfParams: {
        min: 0,
        max: 0,
      },
      example: `CASE
      WHEN City IS NULL THEN Country
      ELSE City`,
      link: 'https://www.w3schools.com/sql/sql_case.asp',
      output: ValidationConstants.PARAM_TYPE__ANY,
    },
  ],
};

const keywords = [
  ValidationConstants.KEYWORD__NULL,
  ValidationConstants.KEYWORD__SESSION_USER,
  ValidationConstants.KEYWORD__SYSTEM_USER,
  ValidationConstants.KEYWORD__CURRENT_TIMESTAMP,
  ValidationConstants.KEYWORD__ANSI_NULLS,
  ValidationConstants.KEYWORD__ANSI_PADDING,
  ValidationConstants.KEYWORD__ANSI_WARNINGS,
  ValidationConstants.KEYWORD__ARITHABORT,
  ValidationConstants.KEYWORD__CONCAT_NULL_YIELDS_NULL,
  ValidationConstants.KEYWORD__NUMERIC_ROUNDABOUT,
  ValidationConstants.KEYWORD__QUOTED_IDENTIFIER,
  ...DATE_INTERVALS,
  ValidationConstants.KEYWORD__WHEN,
  ValidationConstants.KEYWORD__END,
  ValidationConstants.KEYWORD__CASE,
  ValidationConstants.KEYWORD__THEN,
  ValidationConstants.KEYWORD__LIKE,
  ValidationConstants.KEYWORD__ELSE,
  ...DATA_TYPE,
];

export { freeFormulaItems, keywords };
