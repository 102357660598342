import axios from 'axios';
import * as rax from 'retry-axios';

import raxConfig from '../configs/raxConfig';
import Util from '../util';

const apiUrl = process.env.REACT_APP_API_URL;

// use retry-axios to automatically retry failed requests
axios.defaults.raxConfig = { ...raxConfig, instance: axios };
rax.attach(axios);

const RunPreviewLogsAPI = {
  /**
   * Create log for complete run preview jobs
   * @param {object} postData - object of run preview log data
   * @param {object} cancelToken - Token axios
   * @returns {object} generated run preview log
   */
  createRunPreviewLog: async (postData, cancelToken) => {
    const res = await Util.catch418And403Error(
      axios.post,
      `${apiUrl}/run-preview-logs`,
      postData,
      Util.apiPostCallSettings(cancelToken),
    );

    return res.data.data;
  },
};

export default RunPreviewLogsAPI;
