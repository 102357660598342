import React from 'react';
import { Button as SLDSButton } from '@salesforce/design-system-react';
import PropTypes from 'prop-types';

import './styles.scss';

import Spinner from '../../../components/shared/Spinner/Spinner';
import ClickableTooltip from '../Tooltip/Tooltip';

const Button = ({
  label,
  variant,
  disabled,
  onClick,
  iconName,
  iconCategory,
  iconPosition,
  iconSize,
  iconVariant,
  className,
  assistiveText,
  title,
  buttonRef,
  tooltip,
  size,
  loadingClickedButton,
  ...otherProps
}) => {
  // Custom class names for additional styles
  const buttonClasses = className ? ` ${className}` : '';
  const sizeClass = size ? ` des-button--${size}` : '';

  // Render the button with the react-slds Button component
  return (
    <SLDSButton
      label={label}
      variant={variant}
      disabled={disabled || loadingClickedButton}
      onClick={onClick}
      iconName={iconName}
      iconCategory={iconCategory}
      iconPosition={iconPosition}
      iconSize={iconSize}
      iconVariant={iconVariant}
      className={`des-button${sizeClass}${buttonClasses}`}
      assistiveText={assistiveText}
      title={title}
      ref={buttonRef}
      {...otherProps}
    >
      {loadingClickedButton && (
        <Spinner size="small" />
      )}
      {
        tooltip && (
          <ClickableTooltip
            id={`${tooltip.id}`}
            align={tooltip.align}
            content={tooltip.content}
            type={tooltip.type}
            dialogClassName="tooltip-dialog"
          />

        )
      }
    </SLDSButton>
  );
};

// For SLDSButton props, see https://react.lightningdesignsystem.com/components/buttons/#property-details

Button.propTypes = {
  /**
   * Text to be displayed inside the button.
   */
  label: PropTypes.string,
  /**
   * Visual style variant of the button.
   */
  variant: PropTypes.oneOf([
    'base', 'link', 'neutral', 'brand', 'outline-brand', 'destructive', 'success',
    'text-destructive', 'icon',
  ]),
  /**
   * If true, the button will be disabled.
   */
  disabled: PropTypes.bool,
  /**
   * Function to call when button is clicked.
   */
  onClick: PropTypes.func,
  /**
   * Name of the icon to display if the button is an icon button.
   */
  iconName: PropTypes.string,
  /**
   * Category of the icon to use from SLDS.
   */
  iconCategory: PropTypes.string,
  /**
   * Position of the icon relative to the label.
   */
  iconPosition: PropTypes.oneOf(['left', 'right']),
  /**
   * Size of the icon.
   */
  iconSize: PropTypes.oneOf(['x-small', 'small', 'medium', 'large']),
  /**
   * Variant of the icon to display.
   */
  iconVariant: PropTypes.oneOf([
    'bare', 'container', 'border', 'border-filled', 'brand', 'more', 'global-header',
  ]),
  /**
   * Additional CSS classes to apply to the button.
   */
  className: PropTypes.string,
  /**
   * Assisting text for screen readers, especially for icon-only buttons.
   */
  assistiveText: PropTypes.shape({
    icon: PropTypes.string,
  }),
  /**
   * HTML title attribute for the button.
   */
  title: PropTypes.string,
  /**
   * Ref function or created ref to access the button DOM element.
   */
  buttonRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  /**
   * Size of the button.
   */
  size: PropTypes.oneOf(['x-small', 'small', 'medium', 'large', 'x-large']),
  /**
   * Object representing tooltip properties.
   */
  tooltip: PropTypes.shape({
    id: PropTypes.string.isRequired,
    align: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
    content: PropTypes.node.isRequired,
    type: PropTypes.string,
    dialogClassName: PropTypes.string,
  }),
  /**
   * If true, the button will be disabled and show a spinner.
   */
  loadingClickedButton: PropTypes.bool,
  // Additional SLDSButton props are passed through using ...otherProps
};

Button.defaultProps = {
  // Default prop values
  disabled: false,
  variant: 'neutral',
  iconSize: 'medium',
  size: 'medium',
  // Any other default props for omitted SLDSButton properties should be defined here if necessary
};

export default Button;
