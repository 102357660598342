import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

import './styles.scss';
import Constants from '../../../../constants/constants';
import Util from '../../../../util';
import ModalTemplate from '../../../shared_v2/ModalTemplate/ModalTemplate';
import ToggleButton from '../../../shared_v2/ToogleButton/ToggleButton';
import Input from '../../../shared_v2/Input/Input';
import RadioButton from '../../../shared_v2/RadioButton/RadioButton';
import SwalUtil from '../../../../utils/swal/swalUtil';

class SortLimit extends React.Component {
  constructor(props) {
    super(props);
    const { sortLimit } = props;

    this.state = {
      enabled: sortLimit && sortLimit.enabled ?
        sortLimit.enabled :
        false,
      select: sortLimit && sortLimit.select ?
        sortLimit.select :
        {
          type: Constants.SORT_LIMIT__SELECT_TYPE__NUMBER,
          value: '0',
        },
      orderBy: sortLimit && sortLimit.orderBy ?
        sortLimit.orderBy :
        {
          type: Constants.SORT_LIMIT__ORDER_BY_TYPE__FIELD,
          fieldObjectID: null,
          sort: null,
        },
    };
  }

  /**
   * Function which helps to close sort limit modal
   * @returns {void}
   */
  handleCloseSortLimitModal = () => {
    const { handleSetSelectionState } = this.props;

    handleSetSelectionState({ showSortLimitModal: false });
  };

  /**
   * validation for states to determine if they can be saved
   * @returns {void}
   */
  validateSubmitValues = async () => {
    const {
      enabled,
      select,
      orderBy,
    } = this.state;

    let isValid = false;

    let errorMsg = '';

    // condition for an empty field value
    const emptyValue = !select.value || select.value.toString() === '0';

    if (enabled) {
      // Check if the number value is set
      if (select.type === Constants.SORT_LIMIT__SELECT_TYPE__NUMBER &&
        emptyValue) errorMsg = 'Value should be greater than 0.';
      // Check if the percentage value is set
      else if (select.type === Constants.SORT_LIMIT__SELECT_TYPE__PERCENTAGE && emptyValue) {
        errorMsg = 'Value should be greater than 0.';
        // Check if there is selected field
      } else if (orderBy.type === Constants.SORT_LIMIT__ORDER_BY_TYPE__FIELD &&
        (typeof orderBy.fieldObjectID === 'undefined' || !orderBy.fieldObjectID)) {
        errorMsg = 'A field should be selected.';
        // Check if there is selected sorting order
      } else if (orderBy.type === Constants.SORT_LIMIT__ORDER_BY_TYPE__FIELD &&
        (typeof orderBy.sort === 'undefined' || !orderBy.sort)) {
        errorMsg = 'Sorting order should be selected.';
      } else {
        // check if no error message then isValid is true
        isValid = true;
      }

      // If any error message (i.e check failed) - Show an error to the user
      if (errorMsg) {
        return SwalUtil.fire({
          type: Constants.SWAL__TYPE__ERROR,
          message: errorMsg,
          options: {
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
          },
        });
      }
    }

    return isValid;
  };

  /**
   * Function for saving sort and limit
   * @returns {void}
   */
  onSave = async () => {
    // wait for the validation result
    const validation = await this.validateSubmitValues();
    // if the validation fails then return

    if (validation.value) {
      return;
    }

    const {
      enabled,
      select,
      orderBy,
    } = this.state;

    const { handleSetSelectionState } = this.props;

    // set sort limit state in selection
    handleSetSelectionState({
      sortLimit: {
        enabled,
        select,
        orderBy,
      },
    });

    // close the data action modal
    this.handleCloseSortLimitModal();
  };

  /**
   * onChange event handler for sort limit toggle button
   * @returns {void}
   */
  handleToggleSortLimitEnable = () => {
    this.setState(prevState => ({ enabled: !prevState.enabled }));
  };

  /**
   * onChange event handler for sorting order by
   * @param {object} event - event
   * @returns {void}
   */
  handleChangeOrderBySorting = (event) => {
    const { value } = event;

    this.setState(prevState => ({
      orderBy: {
        ...prevState.orderBy,
        sort: value,
      },
    }));
  };

  /**
   * onChange event handler for select type option
   * @param {object} event - event
   * @returns {void}
   */
  handleChangeSelectTypeOption = (event) => {
    const { value } = event.target;

    this.setState(prevState => ({
      select: {
        ...prevState.select,
        type: value,
        value: '0',
      },
    }));
  };

  /**
   * onChange event handler for sorting by option
   * @param {object} event - event
   * @returns {void}
   */
  handleChangeOrderByOption = (event) => {
    const { value } = event.target;

    if (value === Constants.SORT_LIMIT__ORDER_BY_TYPE__FIELD) {
      this.setState(prevState => ({
        orderBy: {
          ...prevState.orderBy,
          type: value,
        },
      }));
    } else {
      this.setState(prevState => ({
        orderBy: {
          ...prevState.orderBy,
          type: value,
          fieldObjectID: null,
          sort: null,
        },
      }));
    }
  };

  /**
   * It helps to change selected field
   * @param {object} event - event. Use e.target to get the value
   * @returns {void}
   */
  handleSetSelectedField = async (event) => {
    const { value } = event;

    this.setState(prevState => ({
      orderBy: {
        ...prevState.orderBy,
        fieldObjectID: value || null,
      },
    }));
  };

  /**
   * onChange event handler for change input percentage field
   * @param {object} event - onChange event object
   * @returns {void}
   */
  handleInputChanged = (event) => {
    let { value } = event.target;

    value = value.replace(/[^\d.]|\.(?=.*\.)/g, '').replace(/^0+(?!\.|$)/, '');
    if (Number(value) < 100) {
      this.setState(prevState => ({
        select: {
          ...prevState.select,
          value,
        },
      }));
    }
  };

  /**
   * onChange event handler for change input for number field
   * @param {object} event - onChange event object
   * @returns {void}
   */
  handleInputNumberChange = (event) => {
    let { value } = event.target;

    value = value.replace(/[^0-9]/g, '').replace(/^0+(?!\.|$)/, '');
    if (Number(value) <= 100000000) {
      this.setState(prevState => ({
        select: {
          ...prevState.select,
          value,
        },
      }));
    }
  };

  /**
   * onChange event handler for input field blur
   * @param {object} event - onChange event object
   * @returns {void}
   */
  handleInputBlur = (event) => {
    const { value } = event.target;
    /*
     * if there is no value set, set default to 0
     * if value ends with dot(.), remove unnecessary dot(.)
     */

    this.setState(prevState => ({
      select: {
        ...prevState.select,
        value: value.replace(/\.$/, '') || '0',
      },
    }));
  };

  render() {
    const {
      enabled,
      select,
      orderBy,
    } = this.state;

    const { targetDataExtensionFields } = this.props;

    // target field options to be populated
    const targetFieldsOptions = Util.formattedDataForTheDropdown(targetDataExtensionFields, 'ObjectID', 'Name');

    // sorting options to be populated
    const sortOptions = [
      {
        key: 'Ascending',
        text: 'Ascending',
        value: Constants.SORT_LIMIT__ORDER_BY_SORT__ASCENDING,
      },
      {
        key: 'Descending',
        text: 'Descending',
        value: Constants.SORT_LIMIT__ORDER_BY_SORT__DESCENDING,
      },
    ];

    /*
     * create unique key for elements for union selections
     * to render correctly
     */
    const uniqueKey = Util.uuid(6);

    /**
     * render label for radio input depending of passed type
     * @param {string} type - type of radio button
     * @returns {object} HTML object with Input and label
     */
    const renderRadioLabel = (type) => {
      let id;

      let className;

      const onBlur = this.handleInputBlur;

      let onChange;

      let value = '';

      let disabled;

      let maxLength;

      let label;

      let inputType;

      if (type === Constants.SORT_LIMIT__SELECT_TYPE__NUMBER) {
        id = `number-text-${uniqueKey}`;
        className = 'margin-left number-text';
        onChange = this.handleInputNumberChange;
        value = select.type === Constants.SORT_LIMIT__SELECT_TYPE__NUMBER ? select.value : '';
        maxLength = '9';
        label = 'records';
        disabled = !enabled || select.type !== Constants.SORT_LIMIT__SELECT_TYPE__NUMBER;
      } else if (type === Constants.SORT_LIMIT__SELECT_TYPE__PERCENTAGE) {
        id = `percentage-text-${uniqueKey}`;
        className = 'margin-left percentage-text';
        value = select.type === Constants.SORT_LIMIT__SELECT_TYPE__PERCENTAGE ? select.value : '';
        onChange = this.handleInputChanged;
        maxLength = '5';
        disabled = !enabled || select.type !== Constants.SORT_LIMIT__SELECT_TYPE__PERCENTAGE;
        label = '% of records';
      } else if (type === Constants.SORT_LIMIT__SELECT_TYPE__ALL_RECORDS) {
        inputType = 'hidden';
        id = 'radio-all-records-hidden';
        label = 'Select all records';
      } else if (type === Constants.SORT_LIMIT__ORDER_BY_TYPE__FIELD) {
        inputType = 'hidden';
        id = 'radio-field-hidden';
      } else if (type === Constants.SORT_LIMIT__ORDER_BY_TYPE__RANDOM) {
        inputType = 'hidden';
        id = 'radio-random-hidden';
        label = 'Random split';
      }

      return (
        <>
          <Input
            type={inputType || 'text'}
            id={id}
            className={className}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            disabled={disabled}
            maxLength={maxLength}
          />
          {label && <span className="margin-left sort-limit-labels">{label}</span>}
        </>
      );
    };

    return (
      <ModalTemplate
        id="sort-limit-modal-dialog-v2"
        headerId="sortlimit-header"
        headerTitle="Sort & Limit"
        cancelButtonId="sortlimit-cancel"
        handleCancel={this.handleCloseSortLimitModal}
        saveButtonId="sortlimit-save"
        handleSave={this.onSave}
      >
        <div className="toggle">
          <span className="slds-form-element__label slds-m-bottom_none label">
            Enable Sort & Limit
          </span>
          <ToggleButton
            id={`sortlimit-toggle-${uniqueKey}`}
            onChange={this.handleToggleSortLimitEnable}
            checked={enabled}
          />
        </div>
        <div className="fieldset-center">
          <div className="slds-form-element__control">
            <span className="bold-text line-height">Select:</span>
            <RadioButton
              containerClassName="line-height"
              id={`radio-number-${uniqueKey}`}
              className="radio-number"
              value="number"
              checked={select.type === Constants.SORT_LIMIT__SELECT_TYPE__NUMBER}
              name={`select-${uniqueKey}`}
              disabled={!enabled}
              onChange={this.handleChangeSelectTypeOption}
              label={renderRadioLabel(Constants.SORT_LIMIT__SELECT_TYPE__NUMBER)}
            />
            <RadioButton
              containerClassName="line-height"
              id={`radio-percentage-${uniqueKey}`}
              className="radio-percentage"
              value="percentage"
              checked={select.type === Constants.SORT_LIMIT__SELECT_TYPE__PERCENTAGE}
              name={`select-${uniqueKey}`}
              disabled={!enabled}
              onChange={this.handleChangeSelectTypeOption}
              label={renderRadioLabel(Constants.SORT_LIMIT__SELECT_TYPE__PERCENTAGE)}
            />
            <RadioButton
              containerClassName="line-height"
              id={`radio-all-records-${uniqueKey}`}
              className="radio-all-records"
              value={Constants.SORT_LIMIT__SELECT_TYPE__ALL_RECORDS}
              checked={select.type === Constants.SORT_LIMIT__SELECT_TYPE__ALL_RECORDS}
              name={`select-${uniqueKey}`}
              disabled={!enabled}
              onChange={this.handleChangeSelectTypeOption}
              label={renderRadioLabel(Constants.SORT_LIMIT__SELECT_TYPE__ALL_RECORDS)}
            />

          </div>
          <div className="slds-form-element__control">
            <span className="bold-text line-height">Sort by:</span>
            <span className="line-height display-flex">
              <RadioButton
                containerClassName="line-height display-flex"
                id={`radio-field-${uniqueKey}`}
                className="radio-field"
                value="field"
                checked={orderBy.type === 'field'}
                name={`sort-${uniqueKey}`}
                disabled={!enabled}
                onChange={this.handleChangeOrderByOption}
                label={renderRadioLabel(Constants.SORT_LIMIT__ORDER_BY_TYPE__FIELD)}
              />
              <Dropdown
                id="field-select"
                disabled={!enabled || orderBy.type === Constants.SORT_LIMIT__ORDER_BY_TYPE__RANDOM}
                selection
                className="dropdown searchable-dropdown  margin-left"
                search
                loading
                value={orderBy.fieldObjectID}
                placeholder="Select a field"
                options={targetFieldsOptions}
                onChange={(e, data) => this.handleSetSelectedField(data)}
                style={{ borderRadius: '.25rem' }}
              />
              <Dropdown
                id="sorting-select"
                value={orderBy.sort}
                placeholder="Select Sorting Order"
                onChange={(e, data) => this.handleChangeOrderBySorting(data)}
                className="dropdown searchable-dropdown margin-left margin-right"
                selection
                search
                loading
                options={sortOptions}
                disabled={!enabled || orderBy.type === Constants.SORT_LIMIT__ORDER_BY_TYPE__RANDOM}
                style={{ borderRadius: '.25rem' }}
              />
            </span>
            <RadioButton
              containerClassName="line-height"
              id={`radio-random-${uniqueKey}`}
              className="radio-random"
              value="random"
              checked={orderBy.type === 'random'}
              name={`sort-${uniqueKey}`}
              disabled={!enabled}
              onChange={this.handleChangeOrderByOption}
              label={renderRadioLabel(Constants.SORT_LIMIT__ORDER_BY_TYPE__RANDOM)}
            />
          </div>
        </div>
      </ModalTemplate>
    );
  }
}
SortLimit.propTypes = {
  /**
   * It keeps sortLimit object
   */
  sortLimit: PropTypes.instanceOf(Object).isRequired,
  /**
   * It helps to set the Selection`s state
   * It will be passed from Selection.js
   */
  handleSetSelectionState: PropTypes.func.isRequired,
  /**
   * It keeps the fields of an existing target data extension
   * It will be passed from Selection.js
   */
  targetDataExtensionFields: PropTypes.instanceOf(Array),
};
export default SortLimit;
