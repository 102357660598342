import React from 'react';
import PropTypes from 'prop-types';

import Constants from '../../../constants/constants';
/**
 * Get the options for component based on the run status
 * @param {number} status - run status
 * @param {function} showError - function that shows error message
 * @returns {object} object with options for Status Icon component
 */
const renderOptions = (status, showError) => {
  switch (status) {
    case Constants.STATUS_CREATING:
    case Constants.STATUS_CREATED:
    case Constants.STATUS_PROCESSING:
    case Constants.STATUS_WAITING:
      return ({
        title: Constants.STATUS_PROCESSING_LABEL,
        isStatus: true,
        icon: 'automate',
        className: 'status-queued running-query-icon',
        onClick: null,
      });

    case Constants.STATUS_QUEUED_FOR_WATERFALL:
      return ({
        title: Constants.STATUS_QUEUED_FOR_WATERFALL_LABEL,
        isStatus: true,
        icon: 'filterList',
        className: 'status-queued queued-for-waterfall-icon',
        onClick: null,
      });

    case Constants.STATUS_COMPLETE:
      return ({
        title: Constants.STATUS_COMPLETE_LABEL,
        isStatus: true,
        icon: 'check',
        className: 'status-completed status-completed-green',
        onClick: null,
      });

    case Constants.STATUS_ERROR:
      return ({
        title: Constants.STATUS_ERROR_LABEL,
        isStatus: true,
        icon: 'error',
        className: 'status-error status-error-red',
        onClick: () => showError(false),
      });

    case Constants.STATUS_RETRY:
      return ({
        title: 'The selection will be retried',
        isStatus: true,
        icon: 'refresh',
        className: 'status-error status-error-red',
        onClick: () => showError(true),
      });

    default:
      return ({
        isStatus: false,
        title: null,
        icon: null,
        className: null,
        onClick: null,
      });
  }
};

const StatusIcon = ({ status, showError }) => {
  const {
    title, isStatus, icon, className, onClick,
  } = renderOptions(status, showError);

  return (
    isStatus ?
      <span
        className="slds-icon_container slds-icon-utility-announcement"
        title={title}
        onClick={onClick || null}
      >
        <svg
          className={`slds-icon slds-icon-text-default slds-icon_x-small ${className}`}
          aria-hidden="true"
        >
          <use xlinkHref={`/assets/icons/utility-sprite/svg/symbols.svg#${icon}`} />
        </svg>
      </span> :
      <div className="without-icon-dropline" />
  );
};

StatusIcon.propTypes = {
  /**
   * Run status
   */
  status: PropTypes.number,
  /**
   * Function that shows error message
   */
  showError: PropTypes.func,
};

export default StatusIcon;
