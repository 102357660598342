import React from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';

import Spinner from '../../../shared/Spinner/Spinner';
import SelectionDropLine from '../../StepsView/SelectionContainer/SelectionDropLine/SelectionDropLine';
import './styles.scss';
import Constants from '../../../../constants/constants';

const SelectionChain = ({ fetchingSelectionsForRunChain }) => {
  // get state of properties from selectionReducer
  const {
    selectedSelections, selectionsForRunChain, runStatusForSelectionChain,
  } =
    useSelector(({ selectionReducer, globalReducer }) => ({
      selectedSelections: selectionReducer.selectedSelections,
      selectionsForRunChain: selectionReducer.selectionsForRunChain,
      runStatusForSelectionChain: globalReducer.runStatusForSelectionChain,
    }), shallowEqual);

  // define selections for chain
  const selectionsForChain = (!selectionsForRunChain?.length && !runStatusForSelectionChain?.length) ?
    selectedSelections :
    selectionsForRunChain;

  /**
   * This function returns arrow to the next step depending on the selection index
   * @param {boolean} index - selection index
   * @returns {object} HTML for the arrow
   */
  const showArrowToNextStep = (index) => {
    if (selectionsForChain.length > 1 && index !== selectionsForChain.length - 1) {
      return (
        <div className="next-step-arrow">
          <svg aria-hidden="true" className="slds-button__icon">
            <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#forward" />
          </svg>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="selection-chain-container">
      {selectionsForChain?.length ?
        selectionsForChain.map((selection, index) => (
          <SelectionDropLine
            selection={selection}
            id={selection._id}
            key={selection._id}
            index={index}
            showArrowToNextStep={showArrowToNextStep}
            selectedSelections={selectionsForChain}
            isReadOnly
          />
        )) :
        (

          <div className={`without-selected-selection-container ${fetchingSelectionsForRunChain}`}>
            {fetchingSelectionsForRunChain ?
              (
              <Spinner
                size={Constants.SPINNER__SIZE__MEDIUM}
                assistiveText="Loading..."
              />
              ) :
              <p>Please select selections in the Steps view to build a selection chain.</p>}
          </div>
        )}
    </div>
  );
};

SelectionChain.propTypes = {
  /**
   * Indicates whether selections are being retrieved for a run chain
   */
  fetchingSelectionsForRunChain: PropTypes.bool.isRequired,
};

export default SelectionChain;
