// SelectedDataExtensionContext.js

import React, {
  createContext, useReducer, useMemo,
} from 'react';

export const SelectedDataExtensionContext = createContext(null);

export const ActionTypes = {
  SET_DATA_EXTENSIONS: 'SET_DATA_EXTENSIONS',
};

const initialState = {
  dataExtensions: [],
};

const SelectedDataExtensionReducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.SET_DATA_EXTENSIONS:
      return {
        ...state,
        dataExtensions: action.payload,
      };
    default:
      return state;
  }
};

export const SelectedDataExtensionProvider = (
  {
    children, initialDataExtensions,
  },
) => {
  const [state, dispatch] = useReducer(SelectedDataExtensionReducer, {
    ...initialState,
    dataExtensions: initialDataExtensions,
  });

  const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <SelectedDataExtensionContext.Provider value={value}>
      {children}
    </SelectedDataExtensionContext.Provider>
  );
};
