import React from 'react';
import PropTypes from 'prop-types';

import Constants from '../../../../../../constants/constants';
import './styles.scss';
import Button from '../../../../../shared_v2/Button/Button';
import Select from '../../../../../shared_v2/Select/Select';
import Input from '../../../../../shared_v2/Input/Input';

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
const PrioValueInput = ({
  value,
  handleChangeValue,
  handleAddValueToArray,
  criteriaFieldType,
}) => {
  /**
   * Define datePicker date format based on the users Locale
   * Note: we don't use the built-in Locale setting of ReactDatePicker as it adds a comma between the date and time
   *
   * const userLocale = timeUtil.getUserLocale();
   * note: the datetime format here is different from what util.getUserDateTimeFormat returns
   * const datePickerDateFormat = timeUtil.getDatePickerDateTimeFormat(userLocale);
   * @returns {object} HTML
   */
  const renderInputAccordingToType = () => {
    switch (criteriaFieldType) {
      case Constants.FILTERLINE__FIELDTYPE__NUMBER:
        return (
          <Input
            type="number"
            id={Constants.PRIO_DEDUP__NUMBER_INPUT__ID}
            required
            className="test-value filter-value-small number-filter-value"
            onChange={(e) => {
              // validate input
              if (e.target.validity.valid) {
                handleChangeValue(e);
              }
            }}
            value={value || ''}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleAddValueToArray(e);
              }
            }}
          />
        );
      case Constants.FILTERLINE__FIELDTYPE__DECIMAL:
        return (
          <Input
            type="number"
            id={Constants.PRIO_DEDUP__DECIMAL_INPUT__ID}
            required
            className="test-value filter-value-small number-filter-value"
            onChange={e => handleChangeValue(e)}
            value={value || ''}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleAddValueToArray(e);
              }
            }}
          />
        );
      case Constants.FILTERLINE__FIELDTYPE__BOOLEAN:
        return (
          <Select
            containerClassName="value_input_boolean"
            id={Constants.PRIO_DEDUP__BOOLEAN_INPUT__ID}
            onChange={e => handleChangeValue(e)}
            defaultValue={Constants.FILTERLINE__VALUE__TRUE}
            options={[
              { value: Constants.FILTERLINE__VALUE__TRUE, label: Constants.FILTERLINE__VALUE__TRUE },
              { value: Constants.FILTERLINE__VALUE__FALSE, label: Constants.FILTERLINE__VALUE__FALSE },
            ]}
            />
        );
      /*
       * case Constants.FILTERLINE__FIELDTYPE__DATE:
       *   return (
       *     <DatePicker
       *       className="test-value filter-value"
       *       type="text"
       *       showTimeInput
       *       dateFormat={datePickerDateFormat}
       *       timeFormat="HH:mm"
       *       onChange={e =>
       *         handleChangeValue(e, Constants.PRIO_DEDUP__DATE_INPUT__ID)}
       *       selected={value
       *         ? moment(value)
       *           .add(-moment(value).utcOffset(), 'm')
       *           .utc()._d
       *         : moment(new Date().setHours(0, 0))
       *           .add(-moment(new Date().setHours(0, 0)).utcOffset(), 'm')
       *           .utc()._d}
       *     />
       *   );
       */
      default:
        return (
          <Input
            id={Constants.PRIO_DEDUP__TEXT_INPUT__ID}
            placeholder=""
            required
            style={{ maxWidth: 300 }}
            value={value || ''}
            onChange={e => handleChangeValue(e)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleAddValueToArray(e);
              }
            }}
          />
        );
    }
  };

  return (
    <div className="slds-form-element prio-val-input">
      <label className="slds-form-element__label labels" htmlFor="text-input-id-1">
        Add Value
        <abbr className="slds-required" title="required">* </abbr>
      </label>
      <div className="slds-form-element__control">
        {renderInputAccordingToType()}
        <Button
          id="add_prio_value"
          disabled={!value}
          buttonLook={Constants.BUTTON__TYPE__BRAND}
          onClick={e => handleAddValueToArray(e)}
        >
          Add
        </Button>
      </div>
    </div>
  );
};

PrioValueInput.propTypes = {
  /**
   * It keeps the value for inputs
   *
   */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    PropTypes.instanceOf(Array),
    PropTypes.bool,
    PropTypes.shape({
      minValue: PropTypes.string,
      maxValue: PropTypes.string,
    }),
    PropTypes.number,
  ]),
  /**
   * It helps to change value for the selected field's input
   */
  handleChangeValue: PropTypes.func.isRequired,
  /**
   * It helps to add value to prioritized values
   */
  handleAddValueToArray: PropTypes.func.isRequired,
  /**
   * It keeps the info about selected field's type to render necessary input
   */
  criteriaFieldType: PropTypes.string,
};
export default PrioValueInput;
