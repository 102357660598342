import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import { connect } from 'react-redux';

import mapStateToProps from '../../../../mapStateToProps';
import DynamicValueModal from './DynamicValueModal/DynamicValueModal';
import TransformDateModal from './TransformDateModal/TransformDateModal';
import TimestampModal from './TimestampModal/TimestampModal';
import CustomValuesOption from './CustomValuesOption/CustomValuesOption';
import DateDifferenceModal from './DateDifferenceModal/DateDifferenceModal';
import FreeFormulaModal from './FreeFormulaModal/FreeFormulaModal';
import TimezoneList from '../../ScheduleSelectionModal/TimezoneList.json';
import Constants from '../../../../constants/constants';
import Features from '../../../../features';
import Util from '../../../../util';
import './styles.scss';
import AggregationModal from './AggregationModal/AggregationModal';
import RowNumberModal from './RowNumberModal/RowNumberModal';
import Button from '../../../shared/Button/Button';
import ModalTemplate from '../../../shared/ModalTemplate/ModalTemplate';
import Input from '../../../shared/Input/Input';
import ProgressBar from '../../../shared/ProgressBar/ProgressBar';
import filtersUtil from '../../../../utils/filters/filtersUtil';
import timeUtil from '../../../../utils/time/timeUtil';
import SwalUtil from '../../../../utils/swal/swalUtil';
import FixedValueModal from './FixedValueModal/FixedValueModal';
import FreeFormulaUtil from './FreeFormulaModal/freeFormulaUtil';
import Spinner from '../../../shared/Spinner/Spinner';

class CustomValues extends React.Component {
  constructor(props) {
    super(props);

    // Shorthand for some conditions
    const isNewValue = props.editCustomValueIndex === '';
    const currentValue = props.editCustomValueIndex < props.customValues?.length || isNewValue ?
      props.customValues[props.editCustomValueIndex] :
      props.globalCustomValues?.[props.editCustomValueIndex - (props.customValues?.length || 0)];

    let isTransformDate;

    let isTimestamp;

    let isDateDifference;

    let isFreeFormula;

    let isAggregatedValues;

    let hasTransformDateFormat;

    let hasTimestampFormat;

    let isRowNumber;

    if (currentValue) {
      isTransformDate = currentValue.formula === Constants.CUSTOM_VALUES__FORMULA_TYPE__TRANSFORM;
      isTimestamp = currentValue.formula === Constants.CUSTOM_VALUES__FORMULA_TYPE__TIMESTAMP;
      isDateDifference = currentValue.formula === Constants.CUSTOM_VALUES__FORMULA_TYPE__DATE_DIFFERENCE;
      isFreeFormula = currentValue.formula === Constants.CUSTOM_VALUES__FORMULA_TYPE__FREE_FORMULA;
      isAggregatedValues = currentValue.valueType === Constants.CUSTOM_VALUES__FIELD_TYPE__AGGREGATION_VALUE;
      isRowNumber = currentValue.formula === Constants.CUSTOM_VALUES__FORMULA_TYPE__ROW_NUMBER;

      hasTransformDateFormat = [
        Constants.CUSTOM_VALUES__FORMULA_FORMAT__DATETIME, Constants.CUSTOM_VALUES__FORMULA_FORMAT__DATE,
      ].includes(currentValue.format);
      hasTimestampFormat = [
        Constants.CUSTOM_VALUES__FORMULA_FORMAT__NOW, Constants.CUSTOM_VALUES__FORMULA_FORMAT__TODAY,
      ].includes(currentValue.format);
    }

    this.state = {
      isFreeFormula,
      isAggregatedValues,
      /**
       * Name of the custom value
       */
      valueName: isNewValue ? '' : currentValue.name,
      isGlobal: currentValue?.isGlobal,

      /**
       * First screen - Custom Value type choice
       */
      fixedValue: isNewValue ? true : currentValue.valueType === Constants.CUSTOM_VALUES__FIELD_TYPE__FIXED_VALUE,
      dynamicValue: !isNewValue && currentValue.valueType === Constants.CUSTOM_VALUES__FIELD_TYPE__DYNAMIC_VALUE,
      formula: !isNewValue && currentValue.valueType === Constants.CUSTOM_VALUES__FIELD_TYPE__FORMULA,
      aggregationValue: !isNewValue && currentValue.valueType ===
        Constants.CUSTOM_VALUES__FIELD_TYPE__AGGREGATION_VALUE,

      /**
       * Second screen - Formula type choice
       */
      pickFormulaTypeStep: false,
      transform:
        isNewValue ||
          (!isNewValue && currentValue.valueType !== Constants.CUSTOM_VALUES__FIELD_TYPE__FORMULA) ?
          true :
          currentValue.formula === Constants.CUSTOM_VALUES__FORMULA_TYPE__TRANSFORM,
      timestamp: !isNewValue && currentValue.formula === Constants.CUSTOM_VALUES__FORMULA_TYPE__TIMESTAMP,
      dateDiff: !isNewValue && currentValue.formula === Constants.CUSTOM_VALUES__FORMULA_TYPE__DATE_DIFFERENCE,
      freeFormula: !isNewValue && currentValue.formula === Constants.CUSTOM_VALUES__FORMULA_TYPE__FREE_FORMULA,
      formulaSelected: null, // State for tracking the third screen - Formula

      /**
       * Third screen - FIXED value
       */
      addValuesStep: false, // State for tracking on which screen we are on

      // Field type for FIXED custom value type (by default: Text)
      fixedValueData: {
        fieldType: !isNewValue && currentValue.valueType === Constants.CUSTOM_VALUES__FIELD_TYPE__FIXED_VALUE ?
          currentValue.fieldType :
          Constants.FILTERLINE__FIELDTYPE__TEXT,
        value: isNewValue ? null : currentValue.value, // Entered value for the FIXED custom value type
      },

      // Third screen - DYNAMIC value
      dynamicValueData: {
        fieldType: !isNewValue && currentValue.valueType === Constants.CUSTOM_VALUES__FIELD_TYPE__DYNAMIC_VALUE ?
          currentValue.fieldType :
          Constants.FILTERLINE__FIELDTYPE__TEXT,
        criteria: !isNewValue && currentValue.criteria ? currentValue.criteria : [],
        defaultValue: {
          defaultValue: currentValue?.defaultValue?.defaultValue === null ?
            null :
            currentValue?.defaultValue?.defaultValue ?? '',
          defaultDataExtensionObjectId:
            !isNewValue && currentValue.defaultValue && currentValue.defaultValue?.defaultDataExtensionObjectId ?
              currentValue.defaultValue?.defaultDataExtensionObjectId :
              '',
          defaultFieldObjectId:
            !isNewValue && currentValue.defaultValue && currentValue.defaultValue?.defaultFieldObjectId ?
              currentValue.defaultValue?.defaultFieldObjectId :
              '',
          defaultDataExtensionAlias:
            !isNewValue && currentValue.defaultValue && currentValue.defaultValue?.defaultDataExtensionAlias ?
              currentValue.defaultValue?.defaultDataExtensionAlias :
              '',
        },
      },
      showNoAvailableFieldsError: false,
      criteriaModal: false, // State for closing and opening modal for filters (DYNAMIC)
      dynamicCustomValuesFilterID: 0, // Id of criteria
      dynamicValuesFilters: [], // Filters for when clause

      // Third screen - Aggregation Value
      /**
       * When and then clauses example:
       * criteria: Array[
       *   {
       *     when: { operator: "AND", filters: [{criteria: ">", value:10000, fieldObjectId: "fieldObjectId" }]}
       *     then: "starter"
       *   }
       */
      defaultValue: '', // State for keeping default value

      filtersModal: false, // State for closing and opening modal for filters (AGGREGATION)
      aggregationValues: !isNewValue && currentValue.aggregationValue ? currentValue.aggregationValue : {},

      /**
       * Third screen - FORMULA > Transform Date
       */
      transformDateData: {
        valueType: Constants.CUSTOM_VALUES__FIELD_TYPE__FORMULA,
        formula: Constants.CUSTOM_VALUES__FORMULA_TYPE__TRANSFORM,
        fieldType: Constants.FILTERLINE__FIELDTYPE__DATE,
        dataExtensionCustomerKey: !isTransformDate || isNewValue || !currentValue.dataExtensionCustomerKey ?
          null :
          currentValue.dataExtensionCustomerKey,
        dataExtensionObjectId: !isTransformDate || isNewValue || !currentValue.dataExtensionObjectId ?
          null :
          currentValue.dataExtensionObjectId,
        fieldObjectId: !isTransformDate || isNewValue || !currentValue.fieldObjectId ?
          null :
          currentValue.fieldObjectId,
        addUnit: !isTransformDate || isNewValue || !currentValue.addUnit ?
          Constants.FILTERLINE__DATE_VALUE__INTERVAL_HOUR :
          currentValue.addUnit,
        addValue: !isTransformDate || isNewValue || !currentValue.addValue ? '0' : currentValue.addValue,
        format: !isTransformDate || isNewValue || !currentValue.format || !hasTransformDateFormat ?
          Constants.CUSTOM_VALUES__FORMULA_FORMAT__DATETIME :
          currentValue.format,
        convert: !!(isTransformDate && !isNewValue && currentValue.convert),
        convertToTimezone: !isTransformDate || isNewValue || !currentValue.convertToTimezone ?
          timeUtil.getCurrentUserTimezone(TimezoneList, true) :
          currentValue.convertToTimezone,
        collectionAlias: !isTransformDate || isNewValue || !currentValue.collectionAlias ?
          null :
          currentValue.collectionAlias,
        /*
         * we use convertFromTimezoneQuery property because for old selections we stored convertFromTimezone
         * with different default values, do not change this property name on convertFromTimezone
         */
        convertFromTimezoneQuery: !isTransformDate || isNewValue || !currentValue.convertFromTimezoneQuery ?
          Constants.TIME_ZONE__CENTRAL_STANDARD_TIME__VALUE :
          currentValue.convertFromTimezoneQuery,
      },

      /**
       * Third screen - FORMULA > Timestamp
       */
      timestampData: {
        valueType: Constants.CUSTOM_VALUES__FIELD_TYPE__FORMULA,
        formula: Constants.CUSTOM_VALUES__FORMULA_TYPE__TIMESTAMP,
        fieldType: Constants.FILTERLINE__FIELDTYPE__DATE,
        format: !isTimestamp || isNewValue || !currentValue.format || !hasTimestampFormat ?
          Constants.CUSTOM_VALUES__FORMULA_FORMAT__NOW :
          currentValue.format,
        convert: !!(isTimestamp && !isNewValue && currentValue.convert),
        convertToTimezone: !isTimestamp || isNewValue || !currentValue.convertToTimezone ?
          timeUtil.getCurrentUserTimezone(TimezoneList, true) :
          currentValue.convertToTimezone,
      },

      /**
       * Third screen - FORMULA > Date difference
       */
      dateDifferenceData: {
        valueType: Constants.CUSTOM_VALUES__FIELD_TYPE__FORMULA,
        formula: Constants.CUSTOM_VALUES__FORMULA_TYPE__DATE_DIFFERENCE,
        fieldType: Constants.FILTERLINE__FIELDTYPE__NUMBER,
        field1: !isDateDifference || isNewValue || !currentValue.field1 ?
          {
            dataExtensionCustomerKey: null,
            dataExtensionObjectId: null,
            fieldObjectId: null,
            format: Constants.CUSTOM_VALUES__FORMULA_FORMAT__FIELD,
            collectionAlias: null,
          } :
          currentValue.field1,
        field2: !isDateDifference || isNewValue || !currentValue.field2 ?
          {
            dataExtensionCustomerKey: null,
            dataExtensionObjectId: null,
            fieldObjectId: null,
            format: Constants.CUSTOM_VALUES__FORMULA_FORMAT__FIELD,
            collectionAlias: null,
          } :
          currentValue.field2,
        output: !isDateDifference || isNewValue || !currentValue.output ?
          Constants.FILTERLINE__DATE_VALUE__INTERVAL_HOUR :
          currentValue.output,
      },

      /**
       * Third screen - FORMULA > Free Formula
       */
      freeFormulaData: {
        valueType: Constants.CUSTOM_VALUES__FIELD_TYPE__FORMULA,
        formula: Constants.CUSTOM_VALUES__FORMULA_TYPE__FREE_FORMULA,
        fieldType: !isFreeFormula || isNewValue ?
          Constants.FILTERLINE__FIELDTYPE__TEXT :
          currentValue.fieldType,
        value: !isFreeFormula || isNewValue ?
          '' :
          currentValue.value,
      },

      /**
       * Row Number
       */
      rowNumberData: {
        orderBy: !isNewValue && isRowNumber && currentValue.orderBy ?
          currentValue.orderBy :
          {
            collectionAlias: '',
            fieldObjectID: '',
          },
        sort: !isNewValue && isRowNumber ? currentValue.sort : Constants.PRIO_DEDUP__SORT_ORDER__ASC,
      },
      rowNumber: isRowNumber || false,
      isDefaultValueNull: false,
      showAllNullError: false,
      // validation for syntax in freeFormula
      isSyntaxValid: null,
      // Error message shown when syntax is invalid
      syntaxError: '',
      /**
       * It keeps information whether syntax validation is running or not
       */
      isValidating: false,

      /**
       * It keeps information whether syntax validation button is clicked or not
       */
      isFreeFormulaValidated: false,
    };
    /**
     * Setting the state for keeping filters for criteria (when clause):
     * when: { operator: "AND", filters: [{criteria: ">", value:10000, fieldObjectId: "object id" }]}
     */
    if (!isNewValue && currentValue.criteria && currentValue.criteria.length > 0) {
      this.state.dynamicValuesFilters = currentValue.criteria.map(criteria => criteria.when);
    }

    // Setting defaultValue state from already existing custom value field
    if (!isNewValue && currentValue.defaultValue) {
      this.state.defaultValue = currentValue.defaultValue?.defaultValue;
      this.state.isDefaultValueNull = currentValue.defaultValue?.defaultValue === null;
    }
    this.noAvailableFieldsRef = React.createRef();
    this.showAllNullErrorRef = React.createRef();
  }

  componentDidMount() {
    // Auto focus the name input
    if (this.nameInput) this.nameInput.focus();
  }

  // This function helps to go on the second step where user can input custom value
  switchToAddValuesStep = () => {
    const {
      fixedValue, valueName, dynamicValue, formula, pickFormulaTypeStep,
      timestamp, transform, dateDiff, aggregationValue, rowNumber,
    } = this.state;

    if (pickFormulaTypeStep) {
      let formulaSelected;

      if (transform) {
        formulaSelected = Constants.CUSTOM_VALUES__FORMULA_TYPE__TRANSFORM;
      } else if (timestamp) {
        formulaSelected = Constants.CUSTOM_VALUES__FORMULA_TYPE__TIMESTAMP;
      } else if (dateDiff) {
        formulaSelected = Constants.CUSTOM_VALUES__FORMULA_TYPE__DATE_DIFFERENCE;
      } else if (rowNumber) {
        formulaSelected = Constants.CUSTOM_VALUES__FORMULA_TYPE__ROW_NUMBER;
      } else {
        formulaSelected = Constants.CUSTOM_VALUES__FORMULA_TYPE__FREE_FORMULA;
      }
      this.setState({
        pickFormulaTypeStep: false,
        formulaSelected,
      });

      return;
    }
    if ((fixedValue || dynamicValue || formula || aggregationValue) && this.validate(valueName)) {
      if (formula) {
        this.setState({ pickFormulaTypeStep: true });
      } else {
        this.setState({ addValuesStep: true, formulaSelected: null });
      }
    }
  };

  /**
   * Function for selecting a formula type
   * @param {object} e - event object
   * @param {string} id - id of the selected filterType
   * @returns {void}
   */
  handleOptionSelected = (e, id) => {
    let itemsToUpdate;

    if (id === Constants.CUSTOM_VALUES__FORMULA_TYPE__TRANSFORM ||
      id === Constants.CUSTOM_VALUES__FORMULA_TYPE__TIMESTAMP ||
      id === Constants.CUSTOM_VALUES__FORMULA_TYPE__DATE_DIFFERENCE ||
      id === Constants.CUSTOM_VALUES__FORMULA_TYPE__ROW_NUMBER ||
      id === Constants.CUSTOM_VALUES__FORMULA_TYPE__FREE_FORMULA
    ) {
      itemsToUpdate = [
        Constants.CUSTOM_VALUES__FORMULA_TYPE__TRANSFORM,
        Constants.CUSTOM_VALUES__FORMULA_TYPE__TIMESTAMP,
        Constants.CUSTOM_VALUES__FORMULA_TYPE__DATE_DIFFERENCE,
        Constants.CUSTOM_VALUES__FORMULA_TYPE__ROW_NUMBER,
        Constants.CUSTOM_VALUES__FORMULA_TYPE__FREE_FORMULA,
      ];
    } else {
      itemsToUpdate = [
        Constants.CUSTOM_VALUES__FIELD_TYPE__FIXED_VALUE,
        Constants.CUSTOM_VALUES__FIELD_TYPE__DYNAMIC_VALUE,
        Constants.CUSTOM_VALUES__FIELD_TYPE__FORMULA,
        Constants.CUSTOM_VALUES__FIELD_TYPE__AGGREGATION_VALUE,
      ];
    }
    const newState = {};

    itemsToUpdate.forEach((item) => {
      if (item === id) {
        // Set it as selected
        newState[item] = true;
      } else {
        // Deselect the rest
        newState[item] = false;
      }
    });
    this.setState(newState);
  };

  // This function helps to go on back on the first step where user can choose type of value
  switchToAddTypeOfValueStep = () => {
    const { formulaSelected } = this.state;

    if (formulaSelected) this.setState({ pickFormulaTypeStep: true, formulaSelected: null });
    else this.setState({ addValuesStep: false, pickFormulaTypeStep: false });
  };

  // Function for closing criteria modal
  closeCriteriaModal = () => {
    this.setState({ criteriaModal: false });
  };

  /**
   * Function for saving filters for criteria
   * @param {object} newState - object with new states
   * @returns {void}
   */
  handleSetDynamicValueFilterState = (newState) => {
    const { dynamicValuesFilters, dynamicValueData } = this.state;

    const dynamicValuesFiltersCopy = [...dynamicValuesFilters];
    const criteriaValuesCopy = [...(dynamicValueData?.criteria || [])];

    const updateState = { ...newState };

    const dynamicCustomValuesFilters = updateState.dynamicValuesFilters;
    const id = updateState.dynamicCustomValuesFilterID;
    // if id is null that means we are adding new filters for criteria

    if (id === null) {
      let thenStatement;

      dynamicValuesFiltersCopy.push(dynamicCustomValuesFilters);
      if (dynamicValueData?.fieldType === Constants.FILTERLINE__FIELDTYPE__BOOLEAN) {
        thenStatement = Constants.FILTERLINE__VALUE__TRUE;
      } else if (dynamicValueData?.fieldType === Constants.FILTERLINE__FIELDTYPE__DATE) {
        // Set date value
        const date = timeUtil.formatDateForDatePicker();

        thenStatement = moment(date).set({
          hour: 0, minute: 0, second: 0, millisecond: 0,
        })._d;
      } else if (filtersUtil.isNumberOrDecimal(dynamicValueData?.fieldType)) {
        // set then statement default value for fieldType of Number and Decimal
        thenStatement = '0';
      } else {
        thenStatement = '';
      }
      criteriaValuesCopy.push(
        {
          then: thenStatement,
          when: dynamicCustomValuesFilters,
        },
      );
      // otherwise we are updating current criteria
    } else {
      for (let i = 0; i < dynamicValuesFiltersCopy.length; i += 1) {
        if (i === id) {
          dynamicValuesFiltersCopy[i] = updateState.dynamicValuesFilters;
          criteriaValuesCopy[i].when = updateState.dynamicValuesFilters;
        }
      }
    }
    this.setState({
      dynamicValueData: {
        ...dynamicValueData,
        criteria: criteriaValuesCopy,
      },
      dynamicValuesFilters: dynamicValuesFiltersCopy,
    });
  };

  /**
   * This function throws swal if validation fails
   * @param {String} title - title of the swal fire message
   * @param {String} text - text of the swal fire message
   * @returns {void}
   */
  handleError = async (title, text) => {
    await SwalUtil.fire({
      type: Constants.SWAL__TYPE__ERROR,
      title,
      message: text,
      options: {
        confirmButtonText: 'OK',
      },
    });
  };

  /**
   * This function helps to validate if required fields are filled
   * @param {String} valueName - name of the field
   * @param {String} value - custom value
   * @returns {boolean} - it returns true
   */
  validate = (valueName, value) => {
    const { addValuesStep, isGlobal } = this.state;
    const { customValues, editCustomValueIndex } = this.props;

    // Global values are not edited, therefore no need to validate
    if (isGlobal) return true;

    // if we are on the second step
    if (addValuesStep) {
      if (!value) {
        this.handleError('Value is missing', 'Please fill the Value input.');

        return false;
      }

      return true;
    }
    // if we are on the first step
    let nameExists = false;
    // loop through customValues to see if name already exists

    customValues.forEach((customValue, index) => {
      if (customValue.name === valueName && editCustomValueIndex !== index) {
        nameExists = true;
      }
    });

    // validate if field name exists, if doesn't throw an error
    if (!valueName) {
      this.handleError('Name for the field is missing', 'Please enter a name for this Custom Value.');
      this.setState({ addValuesStep: false });

      return false;
    }
    if (nameExists) {
      this.handleError('Name already exists', 'Please enter a unique name for this Custom Value.');

      return false;
    }

    return true;
  };

  /**
   * function helps to get predefined relations by selected data extensions object id
   * @returns {array} - array with predefined relations for selected DEs
   */
  getAllRelationsForSelectedDE = () => {
    const { predefinedRelations, selectedDataExtensions } = this.props;

    return selectedDataExtensions.reduce((allRelations, dataExtension) => {
      // return relations which belong to selectedDE
      const selectedRelations = predefinedRelations?.filter(el => dataExtension.ObjectID === el
        .fromDEObjectId);

      // get all relations for selected DE
      if (selectedRelations?.length) {
        // for each relation
        selectedRelations.forEach((el) => {
          // find the same DE Object ID and push relation into array with added fromDeAlias
          if (dataExtension.ObjectID === el.fromDEObjectId) {
            allRelations = [...allRelations, { ...el, fromDeAlias: dataExtension.deAlias }];
          }
        });
      }

      return allRelations;
    }, []);
  };

  /**
   * function helps to check if the same relation appears in aggregation data as in predefined relation
   * @param {array} relations - array with predefined relations
   * @param {object} aggregationData - data from aggregation custom value
   * @returns {object} - return object with predefined relation if the conditions are met
   */
  getSelectedRelation = (relations, aggregationData) => (
    relations.find(relation => (
      aggregationData?.dataExtensionCustomerKey === relation.toDECustomerKey &&
      aggregationData?.relations?.toCollectionAlias === relation.fromDeAlias &&
      aggregationData?.relations?.toFieldObjectId === relation.fromFieldObjectId &&
      aggregationData?.relations?.fromFieldObjectId === relation.toFieldObjectId &&
      aggregationData?.relations?.fromCollectionObjectId === relation.toDEObjectId &&
      aggregationData?.relations?.fromCollectionCustomerKey === relation.toDECustomerKey
    ))
  );

  /**
   * function helps to validate if required fields are filled
   * @param {String} valueName - name of the field
   * @returns {boolean} - it returns true
   */
  validateAggregation = (valueName) => {
    const { aggregationValues } = this.state;

    const { customValues, editCustomValueIndex } = this.props;

    let nameExists = false;

    // loop through customValues to see if name already exists
    customValues.forEach((customValue, index) => {
      if (customValue.name === valueName && editCustomValueIndex !== index) {
        nameExists = true;
      }
    });

    // get relation for data extension customer key and deAlias
    const selectedRelation = this.getSelectedRelation(this.getAllRelationsForSelectedDE(), aggregationValues);

    // define all required fields in relations
    const allRequiredFieldsInRelations = (aggregationValues.relations &&
      aggregationValues.relations?.toCollectionAlias &&
      aggregationValues.relations.toFieldObjectId && aggregationValues.relations.fromFieldObjectId &&
      aggregationValues.relations.fromCollectionCustomerKey && aggregationValues.relations.fromCollectionObjectId);

    // validate duplicate custom value name, if exists throw an error
    if (nameExists) {
      this.handleError('Name already exists', 'Please enter a unique name for this Custom Value.');

      return false;
    }

    if (aggregationValues.mode === Constants.AGGREGATION__MODE__ADVANCED) {
      // validate if formula is selected, if not throw an error
      if (!aggregationValues.formula) {
        // eslint-disable-next-line max-len
        this.handleError('Value is missing', '<b class="bold-swal">Aggregation function</b> is not selected. Please select aggregation function.');

        return false;
      }

      // validate if dataExtensionCustomerKey is selected, if not throw an error
      if (!aggregationValues.dataExtensionCustomerKey) {
        // eslint-disable-next-line max-len
        this.handleError('Value is missing', '<b class="bold-swal">Data extension for field</b> is not selected. Please select data extension.');

        return false;
      }

      // validate if fieldObjectId is selected, if not throw an error
      if (!aggregationValues.fieldObjectId) {
        this.handleError('Value is missing', '<b class="bold-swal">Field</b> is not selected. Please select field.');

        return false;
      }

      // validate if relation's fields are selected, if not throw an error
      if (!allRequiredFieldsInRelations) {
        // eslint-disable-next-line max-len
        this.handleError('Value is missing', 'Please complete all fields in the <b class="bold-swal">Relation to results</b> section.');

        return false;
      }
    } else {
      // eslint-disable-next-line max-len
      if (!allRequiredFieldsInRelations || !selectedRelation) {
        // eslint-disable-next-line max-len
        this.handleError('Value is missing', '<b class="bold-swal">Relation</b> is not selected. Please select relation.');

        return false;
      }

      // validate if aggregation function is selected, if not throw an error
      if (!aggregationValues.formula) {
        // eslint-disable-next-line max-len
        this.handleError('Value is missing', '<b class="bold-swal">Aggregation function</b> is not selected. Please select aggregation function.');

        return false;
      }

      // validate if fieldObjectId is selected, if not throw an error
      if (!aggregationValues.fieldObjectId && aggregationValues.formula !== Constants.FORMULA__VALUE__COUNT) {
        this.handleError('Value is missing', '<b class="bold-swal">Field</b> is not selected. Please select field.');

        return false;
      }
    }

    return true;
  };

  // Function which helps to close custom values modal
  closeAddValuesModal = () => {
    const { handleSetSelectionState } = this.props;

    handleSetSelectionState({
      showAddValuesModal: false,
      showAggregationModal: false,
      editCustomValueIndex: '',
      dynamicCustomValuesFilters: {},
      aggregationFilters: {},
    });
  };

  // This function helps to save custom values data
  saveNewValue = async () => {
    const {
      value,
      valueName,
      fixedValue,
      dynamicValuesFilters,
      dynamicValue,
      formulaSelected,
      transformDateData,
      timestampData,
      dateDifferenceData,
      freeFormulaData,
      freeFormula,
      aggregationValues,
      aggregationValue,
      isFreeFormulaValidated,
      isSyntaxValid,
      rowNumber,
      fixedValueData,
      dynamicValueData,
      rowNumberData,
    } = this.state;
    const {
      handleSetSelectionState,
      customValues,
      editCustomValueIndex,
      matchedFields,
    } = this.props;

    const customValuesCopy = [...customValues];

    /**
     * Updates field name in matched fields
     * @returns {void}
     */
    const handleUpdateFieldNameInMatchedFields = () => {
      matchedFields.forEach((field) => {
        if (field.availableFieldName === customValues[editCustomValueIndex].name &&
          field.availableFieldObjectID.includes('customValues')) {
          // eslint-disable-next-line no-param-reassign
          field.availableFieldName = valueName;
        }
      });
    };

    if (fixedValue) {
      // validate if all required fields are filled
      if (this.validate(valueName, fixedValueData?.value)) {
        // validate if an email address is in the right format
        if (
          fixedValueData.fieldType === Constants.FILTERLINE__FIELDTYPE__EMAILADDRESS &&
          !Util.validateEmail(fixedValueData?.value)) {
          return SwalUtil.fire({
            type: Constants.SWAL__TYPE__ERROR,
            title: 'Invalid Email Address',
            message: `The email address<b class="swal_bold"> ${value}</b> does not have the correct format.`,
            htmlClass: ' ',
          });
        }

        /*
         * if editCustomValueIndex is not empty then just
         * change data in the custom value
         */
        if (editCustomValueIndex === '') {
          customValuesCopy.push({
            name: valueName,
            fieldType: fixedValueData.fieldType,
            value: fixedValueData.value,
            valueType: Constants.CUSTOM_VALUES__FIELD_TYPE__FIXED_VALUE,
            formula: null,
          });
        } else {
          // Update field name in matched fields
          handleUpdateFieldNameInMatchedFields();

          // update customValues
          customValuesCopy.forEach((customValue, id) => {
            if (id === editCustomValueIndex) {
              /* eslint-disable no-param-reassign */
              customValue.name = valueName;
              customValue.fieldType = fixedValueData.fieldType;
              customValue.value = fixedValueData.value;
              customValue.valueType = Constants.CUSTOM_VALUES__FIELD_TYPE__FIXED_VALUE;
              customValue.formula = null;
              if (customValue.criteria) {
                delete customValue.criteria;
              }
              if (customValue.defaultValue === '' || customValue.defaultValue) {
                delete customValue.defaultValue;
              }
              /* eslint-disable no-param-reassign */
            }
          });
        }
        handleSetSelectionState({ customValues: customValuesCopy });
        this.closeAddValuesModal();
      }
    } else if (dynamicValue) {
      // if user tries to save custom value without criteria
      if (dynamicValuesFilters && dynamicValuesFilters.length === 0) {
        return SwalUtil.fire({
          type: Constants.SWAL__TYPE__ERROR,
          title: 'Criteria is not defined',
          message: 'You need to have at least one criterion.',
        });
      }

      // validation of email addresses
      if (dynamicValueData?.fieldType === Constants.FILTERLINE__FIELDTYPE__EMAILADDRESS) {
        const wrongFormatEmails = [];

        // Check for incorrect email formats in then statements
        dynamicValueData?.criteria.forEach((criterion) => {
          if (!Util.validateEmail(criterion.then) && criterion.then != null && criterion.then.length > 0) {
            wrongFormatEmails.push(criterion.then);
          }
        });

        // Check for incorrect email formats in default values
        if (
          !dynamicValueData?.defaultValue?.defaultDataExtensionObjectId?.length &&
          !dynamicValueData?.defaultValue?.defaultValue === null &&
          dynamicValueData?.defaultValue?.defaultValue?.length
        ) {
          if (!Util.validateEmail(dynamicValueData?.defaultValue?.defaultValue)) {
            wrongFormatEmails.push(dynamicValueData?.defaultValue?.defaultValue);
          }
        }

        if (wrongFormatEmails.length > 0) {
          // If there are any wrong emails, then show modal accordingly
          let emailString = '';

          for (let i = 0; i < wrongFormatEmails.length; i += 1) {
            emailString += wrongFormatEmails[i];
            if (i !== wrongFormatEmails.length - 1) {
              emailString += ', ';
            }
          }

          return SwalUtil.fire({
            type: Constants.SWAL__TYPE__ERROR,
            title: 'Invalid Email Address',
            // eslint-disable-next-line max-len
            message: `The following email address${wrongFormatEmails.length > 1 ? 'es do not' : ' does not'}
            have the correct format: <p class="swal_bold">
            ${emailString}</p>`,
            htmlClass: ' ',
          });
        }
      }

      if (
        dynamicValueData?.defaultValue?.defaultDataExtensionObjectId?.length &&
        !dynamicValueData?.defaultValue?.defaultFieldObjectId?.length
      ) {
        this.setState({ showNoAvailableFieldsError: true });
        this.noAvailableFieldsRef.current.scrollIntoView({ behavior: 'smooth' });

        return false;
      }
      // If all then values and default value are null
      if (
        dynamicValueData?.defaultValue?.defaultValue === null &&
        dynamicValueData?.criteria?.every(criterion => criterion?.then === null)
      ) {
        this.setState({ showAllNullError: true });
        this.showAllNullErrorRef.current.scrollIntoView({ behavior: 'smooth' });

        return false;
      }

      const criteria = [];

      if (editCustomValueIndex === '') {
        for (let i = 0; i < dynamicValuesFilters.length; i += 1) {
          criteria.push({
            when: dynamicValuesFilters[i],
            then: dynamicValueData?.criteria[i] ? dynamicValueData?.criteria[i].then : '',
          });
        }

        customValuesCopy.push({
          name: valueName,
          fieldType: dynamicValueData?.fieldType,
          valueType: Constants.CUSTOM_VALUES__FIELD_TYPE__DYNAMIC_VALUE,
          criteria,
          formula: null,
          defaultValue: {
            defaultValue: dynamicValueData?.defaultValue?.defaultValue,
            defaultDataExtensionObjectId: dynamicValueData?.defaultValue?.defaultDataExtensionObjectId,
            defaultFieldObjectId: dynamicValueData?.defaultValue?.defaultFieldObjectId,
            defaultDataExtensionAlias: dynamicValueData?.defaultValue?.defaultDataExtensionAlias,
          },
        });
      } else {
        // Update field name in matched fields
        handleUpdateFieldNameInMatchedFields();

        // update customValues
        customValuesCopy.forEach((customValue, id) => {
          if (id === editCustomValueIndex) {
            customValue.name = valueName;
            customValue.fieldType = dynamicValueData?.fieldType;
            customValue.valueType = Constants.CUSTOM_VALUES__FIELD_TYPE__DYNAMIC_VALUE;
            customValue.formula = null;
            customValue.defaultValue = {
              defaultValue: dynamicValueData?.defaultValue?.defaultValue,
              defaultDataExtensionObjectId: dynamicValueData?.defaultValue?.defaultDataExtensionObjectId,
              defaultFieldObjectId: dynamicValueData?.defaultValue?.defaultFieldObjectId,
              defaultDataExtensionAlias: dynamicValueData?.defaultValue?.defaultDataExtensionAlias,
            };
            if (customValue.value) {
              delete customValue.value;
            }
            if (customValue.criteria) {
              customValue.criteria = [];
            }

            for (let i = 0; i < dynamicValuesFilters.length; i += 1) {
              if (customValue.criteria) {
                customValue.criteria[i] = {
                  when: dynamicValuesFilters[i],
                  then: dynamicValueData?.criteria[i] ? dynamicValueData?.criteria[i].then : '',
                };
              } else {
                criteria.push({
                  when: dynamicValuesFilters[i],
                  then: dynamicValueData?.criteria[i] ? dynamicValueData?.criteria[i].then : '',
                });
                customValue.criteria = criteria;
              }
            }
          }
        });
      }

      handleSetSelectionState({ customValues: customValuesCopy });
      this.closeAddValuesModal();
    } else if (aggregationValue) {
      // validate all fields in aggregation modal
      if (this.validateAggregation()) {
        /*
         * if editCustomValueIndex is not empty then just
         * change data in the custom value
         */
        if (editCustomValueIndex === '') {
          customValuesCopy.push({
            name: valueName,
            fieldType: aggregationValues.fieldTypeAggregationValue,
            aggregationValue: aggregationValues,
            valueType: Constants.CUSTOM_VALUES__FIELD_TYPE__AGGREGATION_VALUE,
            formula: null,
          });
        } else {
          // Update field name in matched fields
          handleUpdateFieldNameInMatchedFields();

          // update customValues
          customValuesCopy.forEach((customValue, id) => {
            if (id === editCustomValueIndex) {
              customValue.name = valueName;
              customValue.fieldType = aggregationValues.fieldTypeAggregationValue;
              customValue.aggregationValue = JSON.parse(JSON.stringify(aggregationValues));
              customValue.valueType = Constants.CUSTOM_VALUES__FIELD_TYPE__AGGREGATION_VALUE;
              customValue.formula = null;
            }
          });
        }

        // set aggregation in custom values
        handleSetSelectionState({ customValues: customValuesCopy });

        // close custom values modal
        this.closeAddValuesModal();
      }
    } else if (rowNumber) {
      // check if data extension is selected
      if (!rowNumberData?.orderBy?.collectionAlias) {
        this.handleError('Value is missing', 'Please select Data Extension.');

        return false;
      }

      // check if field is selected
      if (!rowNumberData?.orderBy?.fieldObjectID) {
        this.handleError('Value is missing', 'Please select Field.');

        return false;
      }

      /*
       * if editCustomValueIndex is not empty then just
       * change data in the custom value
       */
      if (editCustomValueIndex === '') {
        customValuesCopy.push({
          name: valueName,
          fieldType: Constants.FILTERLINE__FIELDTYPE__NUMBER,
          orderBy: rowNumberData?.orderBy,
          sort: rowNumberData?.sort,
          valueType: Constants.CUSTOM_VALUES__FIELD_TYPE__FORMULA,
          formula: Constants.CUSTOM_VALUES__FORMULA_TYPE__ROW_NUMBER,
        });
      } else {
        // update field name in matched fields also
        matchedFields.forEach((field) => {
          if (field.availableFieldName === customValues[editCustomValueIndex].name &&
            field.availableFieldObjectID.includes('customValues')) {
            // eslint-disable-next-line no-param-reassign
            field.availableFieldName = valueName;
          }
        });

        // update customValues
        customValuesCopy.forEach((customValue, id) => {
          if (id === editCustomValueIndex) {
            customValue.name = valueName;
            customValue.fieldType = Constants.FILTERLINE__FIELDTYPE__NUMBER;
            customValue.orderBy = JSON.parse(JSON.stringify(rowNumberData.orderBy));
            customValue.sort = rowNumberData.sort;
            customValue.valueType = Constants.CUSTOM_VALUES__FIELD_TYPE__FORMULA;
            customValue.formula = Constants.CUSTOM_VALUES__FORMULA_TYPE__ROW_NUMBER;
          }
        });
      }

      // set row number in custom values
      handleSetSelectionState({ customValues: customValuesCopy });

      // close custom values modal
      this.closeAddValuesModal();
    } else {
      // it's a variable that shows if the freeFormula value is empty
      const isFreeFormulaEmpty = !freeFormulaData.value.trim() || !freeFormulaData.value;

      // Depending on the formulaType, prepare the custom value
      if (editCustomValueIndex === '') {
        switch (formulaSelected) {
          case Constants.CUSTOM_VALUES__FORMULA_TYPE__TRANSFORM:
            customValuesCopy.push({ ...transformDateData, name: valueName });
            break;
          case Constants.CUSTOM_VALUES__FORMULA_TYPE__TIMESTAMP:
            customValuesCopy.push({ ...timestampData, name: valueName });
            break;
          case Constants.CUSTOM_VALUES__FORMULA_TYPE__DATE_DIFFERENCE:
            customValuesCopy.push({ ...dateDifferenceData, name: valueName });
            break;
          case Constants.CUSTOM_VALUES__FORMULA_TYPE__FREE_FORMULA:
            // if free formula is not empty
            if (!isFreeFormulaEmpty) {
              // if check syntax btn has already been clicked don't call handleCheckSyntax function
              if (isFreeFormulaValidated) {
                // if syntax is valid
                if (isSyntaxValid) {
                  // Replace all non-breaking spaces with normal spaces
                  const editedValue = Util.replaceEnterKeysAndNonBreakingSpaces(freeFormulaData.value);

                  // add new custom value and close the modal
                  customValuesCopy.push({ ...freeFormulaData, name: valueName, value: editedValue });
                  handleSetSelectionState({ customValues: customValuesCopy });
                  this.closeAddValuesModal();
                  // otherwise show an error
                } else this.invalidSyntaxError();
              } else {
                await this.handleCheckSyntax().then((res) => {
                  if (res === true) {
                    // Replace all non-breaking spaces with normal spaces
                    const editedValue = Util.replaceEnterKeysAndNonBreakingSpaces(freeFormulaData.value);

                    // if syntax is true set customValues, save and close the modal
                    customValuesCopy.push({ ...freeFormulaData, name: valueName, value: editedValue });
                    handleSetSelectionState({ customValues: customValuesCopy });
                    this.closeAddValuesModal();
                  } else if (res === false) {
                    // if syntax is invalid show the message from invalidSyntaxError function
                    this.invalidSyntaxError();
                  }
                }).catch((error) => {
                  this.handleError('Error', { error });
                });
              }
            }
            break;
          default:
            break;
        }
      } else {
        // Update field name in matched fields
        handleUpdateFieldNameInMatchedFields();

        // Update the custom value
        customValuesCopy.forEach(async (customValue, id) => {
          // Are we editing the right customValue ?
          if (editCustomValueIndex === id) {
            // Transform
            if (formulaSelected === Constants.CUSTOM_VALUES__FORMULA_TYPE__TRANSFORM) {
              Object.assign(customValue, { ...transformDateData, name: valueName });
            } else if (formulaSelected === Constants.CUSTOM_VALUES__FORMULA_TYPE__TIMESTAMP) {
              // Timestamp
              Object.assign(customValue, { ...timestampData, name: valueName });
            } else if (formulaSelected === Constants.CUSTOM_VALUES__FORMULA_TYPE__DATE_DIFFERENCE) {
              // Date Difference
              Object.assign(customValue, { ...dateDifferenceData, name: valueName });
            } else {
              // Free Formula
              if (!isFreeFormulaEmpty) {
                // if check syntax btn has already been clicked don't call handleCheckSyntax function
                if (isFreeFormulaValidated) {
                  // if syntax is valid
                  if (isSyntaxValid) {
                    // Replace all non-breaking spaces with normal spaces
                    const editedValue = Util.replaceEnterKeysAndNonBreakingSpaces(freeFormulaData.value);

                    // update custom value and close the modal
                    Object.assign(customValue, { ...freeFormulaData, name: valueName, value: editedValue });
                    handleSetSelectionState({ customValues: customValuesCopy });
                    this.closeAddValuesModal();
                    // if syntax is not valid show an error
                  } else this.invalidSyntaxError();
                  // if syntax btn hasn't been clicked
                } else {
                  // if free formula is not empty call the checkSyntax function and wait for the response
                  await this.handleCheckSyntax().then((res) => {
                    if (res === true) {
                      // Replace all non-breaking spaces with normal spaces
                      const editedValue = Util.replaceEnterKeysAndNonBreakingSpaces(freeFormulaData.value);
                      // if syntax is true set customValues, save and close the modal

                      Object.assign(customValue, { ...freeFormulaData, name: valueName, value: editedValue });
                      handleSetSelectionState({ customValues: customValuesCopy });
                      this.closeAddValuesModal();
                    } else if (res === false) {
                      // if syntax is invalid show the message from invalidSyntaxError function
                      this.invalidSyntaxError();
                    }
                  }).catch((error) => {
                    this.handleError('Error', { error });
                  });
                }
              }
            }
          }
        });
      }

      if (freeFormula && isFreeFormulaEmpty) {
        // show the message if there is no value in Free Formula Field and set isSyntaxValid to null
        this.handleError('Formula Value Is Required', 'Please fill out the formula input.');
        this.setState({ isSyntaxValid: null });

        return null;
      }

      if (!freeFormula) {
        // save and close the modal if you are not in the FreeFormula
        handleSetSelectionState({ customValues: customValuesCopy });
        this.closeAddValuesModal();
      }
    }

    return null;
  };

  /**
   * It triggers handleError function with proper message for invalid syntax
   * @returns {void}
   */
  invalidSyntaxError = () => {
    this.handleError('Invalid Formula Syntax', 'Please correct formula syntax.');
  };

  /**
   * It helps to check the syntax validation
   * @returns {Promise} - true or false, depends of the syntax validation
   */
  handleCheckSyntax = async () => {
    const { freeFormulaData } = this.state;
    const { selectedDataExtensions, relations, axiosCancelToken } = this.props;

    /*
     * disable check syntax button and show spinner (isValidating: true)
     * and set isFreeFormulaValidated to true
     */
    this.setState({ isValidating: true, isFreeFormulaValidated: true });

    try {
      // Get validation results
      const { isSyntaxValid, syntaxError } = await FreeFormulaUtil.checkSyntax(
        freeFormulaData,
        selectedDataExtensions,
        relations,
        axiosCancelToken,
      );

      return new Promise((resolve) => {
        // set states after one second for the better experience
        setTimeout(async () => {
          this.setState({ isSyntaxValid, syntaxError, isValidating: false });

          // if syntax is valid return true
          if (isSyntaxValid) {
            resolve(true);
            // otherwise return false
          } else {
            resolve(false);
          }
        }, 1000);
      });
    } catch (error) {
      this.handleError('Error', { error });
    }
  };

  /**
   * This function helps with certain actions when enter is pressed
   * @param {object} e - event
   * @returns {void}
   */
  onKeyPress = (e) => {
    const {
      addValuesStep, formulaSelected,
    } = this.state;

    if (e.key === 'Enter') {
      // Try to save the value if we are on the 2nd or 3rd screen for formula and we are not in Free Formula
      if ((addValuesStep || formulaSelected) && formulaSelected !==
        Constants.CUSTOM_VALUES__FORMULA_TYPE__FREE_FORMULA) {
        // Make sure all necessary fields have been selected
        if (formulaSelected && this.isSaveButtonDisabled()) {
          return;
        }
        this.saveNewValue();
      } else if (formulaSelected === Constants.CUSTOM_VALUES__FORMULA_TYPE__FREE_FORMULA) {
        // for free formula we don't want to submit after pressing the enter
        // eslint-disable-next-line consistent-return
        return false;
      } else {
        // Otherwise, go to next screen
        this.switchToAddValuesStep();
      }
    }
  };

  /**
   * Function to set the state of customValues from child components
   * @param {object} customValueUpdate - the updated state piece
   * @returns {void}
   */
  handleSetCustomValuesState = (customValueUpdate) => {
    this.setState(customValueUpdate);
  };

  /**
   * Renders the formula modal
   * @returns {object} JSX to render the formula modal
   */
  renderFormulaModal = () => {
    const {
      formulaSelected,
      transformDateData,
      timestampData,
      dateDifferenceData,
      freeFormulaData,
      isSyntaxValid,
      syntaxError,
      isValidating,
      isFreeFormulaValidated,
      rowNumberData,
      isGlobal,
    } = this.state;

    const { selectedDataExtensions } = this.props;

    switch (formulaSelected) {
      case Constants.CUSTOM_VALUES__FORMULA_TYPE__TRANSFORM:
        return (
          <TransformDateModal
            transformDateData={transformDateData}
            selectedDataExtensions={selectedDataExtensions}
            handleSetCustomValuesState={this.handleSetCustomValuesState}
            disabled={!!isGlobal}
          />
        );
      case Constants.CUSTOM_VALUES__FORMULA_TYPE__TIMESTAMP:
        return (
          <TimestampModal
            timestampData={timestampData}
            handleSetCustomValuesState={this.handleSetCustomValuesState}
            handleConvertTimezoneSwitch={
              () => this.setState({
                timestampData: { ...timestampData, convert: !timestampData.convert },
              })
            }
            disabled={!!isGlobal}
          />
        );
      case Constants.CUSTOM_VALUES__FORMULA_TYPE__DATE_DIFFERENCE:
        return (
          <DateDifferenceModal
            selectedDataExtensions={selectedDataExtensions}
            dateDifferenceData={dateDifferenceData}
            handleSetCustomValuesState={this.handleSetCustomValuesState}
            disabled={!!isGlobal}
          />
        );
      case Constants.CUSTOM_VALUES__FORMULA_TYPE__ROW_NUMBER:
        return (
          <RowNumberModal
            rowNumberData={rowNumberData}
            selectedDataExtensions={selectedDataExtensions}
            handleSetCustomValuesState={this.handleSetCustomValuesState}
            disabled={!!isGlobal}
          />
        );
      case Constants.CUSTOM_VALUES__FORMULA_TYPE__FREE_FORMULA:
        return (
          <FreeFormulaModal
            freeFormulaData={freeFormulaData}
            handleSetCustomValuesState={this.handleSetCustomValuesState}
            selectedDataExtensions={selectedDataExtensions}
            isSyntaxValid={isSyntaxValid}
            syntaxError={syntaxError}
            handleCheckSyntax={this.handleCheckSyntax}
            isValidating={isValidating}
            isFreeFormulaValidated={isFreeFormulaValidated}
            disabled={!!isGlobal}
          />
        );
      default:
        return null;
    }
  };

  /**
   * This function makes sure that the customValue can be saved
   * @returns {boolean} - Is the customValue savable?
   */
  isSaveButtonDisabled = () => {
    const {
      transform,
      formulaSelected,
      dateDiff,
      transformDateData,
      dateDifferenceData,
      isValidating,
      isGlobal,
    } = this.state;

    if (isGlobal) return true;

    const {
      dataExtensionObjectId: transformDataExtensionObjectId,
      fieldObjectId: transformFieldObjectId,
    } = transformDateData;

    // Date difference data
    const { field1, field2 } = dateDifferenceData;
    const {
      format: field1Format,
      dataExtensionObjectId: field1DataExtensionObjectId,
      fieldObjectId: field1FieldObjectId,
    } = field1;
    const {
      format: field2Format,
      dataExtensionObjectId: field2DataExtensionObjectId,
      fieldObjectId: field2FieldObjectId,
    } = field2;
    // Check that all the required fields have been filled

    if (formulaSelected && transform && (!transformDataExtensionObjectId || !transformFieldObjectId)) {
      return true;
    }
    if (formulaSelected && dateDiff) {
      if (field1Format === Constants.CUSTOM_VALUES__FORMULA_FORMAT__FIELD &&
        (!field1DataExtensionObjectId || !field1FieldObjectId)
      ) {
        return true;
      }
      if (field2Format === Constants.CUSTOM_VALUES__FORMULA_FORMAT__FIELD &&
        (!field2DataExtensionObjectId || !field2FieldObjectId)
      ) {
        return true;
      }
    }

    // if check free formula syntax is validating disable the Save button
    if (isValidating) {
      return true;
    }

    return false;
  };

  /**
   * Handle the onClick event on the different progress checkboxes inside the footer of the modal
   * @param {number} step - The step
   *  - 1: Choose custom value type
   *  - 2: Choose formula type
   *  - 3: Final screen
   * @returns {*} Nothing
   */
  handleClickOnProgressButton = (step) => {
    const { editCustomValueIndex } = this.props;
    const {
      valueName,
      fixedValue,
      dynamicValue,
      formula,
      transform,
      timestamp,
      dateDiff,
      formulaSelected,
      aggregationValue,
      rowNumber,
    } = this.state;

    // Depending on the screen, we enable / disable states so the screen we want renders
    switch (step) {
      case 1:
        // No condition, you can always go back here
        this.setState({
          addValuesStep: false,
          pickFormulaTypeStep: false,
          formulaSelected: null,
        });
        break;

      case 2:
        if (
          this.validate(valueName) &&
          (
            // Existing value
            editCustomValueIndex !== '' ||
            (
              // New value && on 3rd screen
              editCustomValueIndex === '' &&
              formulaSelected
            )
          )
        ) {
          this.setState({
            addValuesStep: false,
            pickFormulaTypeStep: true,
            formulaSelected: null,
          });
        }
        break;

      case 3:
        // Only if existing value as you can only go back to completed screen for new values (so either screen 1 or 2)
        if (this.validate(valueName, true) && editCustomValueIndex !== '') {
          // Fixed / Dynamic
          if (fixedValue || dynamicValue || aggregationValue) {
            this.setState({
              addValuesStep: true,
              pickFormulaTypeStep: false,
              formulaSelected: null,
            });
            // Formula
          } else if (formula) {
            let _formulaSelected;

            if (transform) {
              _formulaSelected = Constants.CUSTOM_VALUES__FORMULA_TYPE__TRANSFORM;
            } else if (timestamp) {
              _formulaSelected = Constants.CUSTOM_VALUES__FORMULA_TYPE__TIMESTAMP;
            } else if (dateDiff) {
              _formulaSelected = Constants.CUSTOM_VALUES__FORMULA_TYPE__DATE_DIFFERENCE;
            } else if (rowNumber) {
              _formulaSelected = Constants.CUSTOM_VALUES__FORMULA_TYPE__ROW_NUMBER;
            } else {
              _formulaSelected = Constants.CUSTOM_VALUES__FORMULA_TYPE__FREE_FORMULA;
            }

            this.setState({
              addValuesStep: false,
              pickFormulaTypeStep: false,
              formulaSelected: _formulaSelected,
            });
          }
        }
        break;

      default:
        break;
    }
  };

  render() {
    const {
      addValuesStep,
      fixedValue,
      valueName,
      dynamicValue,
      formula,
      pickFormulaTypeStep,
      transform,
      timestamp,
      dateDiff,
      freeFormula,
      formulaSelected,
      criteriaModal,
      dynamicCustomValuesFilterID,
      showAllNullError,
      isValidating,
      aggregationValue,
      filtersModal,
      aggregationValues,
      rowNumber,
      fixedValueData,
      isGlobal,
      dynamicValueData,
      showNoAvailableFieldsError,
      isFreeFormula,
      isAggregatedValues,
    } = this.state;

    const {
      editCustomValueIndex,
      selectedDataExtensions,
      handleSetSelectionState,
      handleFiltersSave,
      dynamicCustomValuesFilters,
      getDataExtensionOrDataViewFields,
      DEBorderMouseOver,
      filterBorderMouseOver,
      pickLists,
      handlePickListOptions,
      handleRemoveFilterLine,
      dataExtensions,
      loadingAllAvailableDataExtensions,
      aggregationFilters,
      applyTimezoneSettingsToAllDateFields,
      handleSetTimezoneToAllDateFields,
      timezoneSettingsForAllDateFields,
      featuresInfo,
    } = this.props;

    /**
     *  enable aggregation based on Features or
     *  enable it if aggregation already has value
     */
    const featureAggregationIsEnabled = Features.isFeatureEnabled(
      featuresInfo,
      Constants.FEATURE__AGGREGATED_VALUES,
    ) || isAggregatedValues;

    /**
     *  enable predefined relation based on features
     */
    const featurePredefinedRelationsIsEnabled = Features.isFeatureEnabled(
      featuresInfo,
      Constants.FEATURE__PREDEFINED_RELATIONS,
    );

    /**
     *  enable freeFormula based on Features or
     *  enable it if freeFormula already has value
     */
    const featureFreeFormulasIsEnabled = Features.isFeatureEnabled(
      featuresInfo,
      Constants.FEATURE__FREE_FORMULAS,
    ) || isFreeFormula;

    const { tab } = this.props;

    // Custom Value options to be rendered
    const customValuesOptions = [
      {
        name: 'fixed-value',
        id: Constants.CUSTOM_VALUES__FIELD_TYPE__FIXED_VALUE,
        title: 'Fixed Value',
        description: `Choose a fixed value, like a fixed text, number, date, boolean.
          All records will have the same fixed value.`,
        checked: fixedValue,
        isEnabled: true,
      },
      {
        name: 'dynamic-value',
        id: Constants.CUSTOM_VALUES__FIELD_TYPE__DYNAMIC_VALUE,
        title: 'Dynamic Value',
        description: `Select a value based on logic. Different records can have
          different values based on the logic you define.`,
        checked: dynamicValue,
        isEnabled: true,
      },
      {
        name: 'formula-value',
        id: Constants.CUSTOM_VALUES__FIELD_TYPE__FORMULA,
        title: 'Apply formula to a field',
        description: 'Modify the value of a field by applying a formula to it.',
        checked: formula,
        isEnabled: true,
      },
      {
        name: 'aggregation',
        id: Constants.CUSTOM_VALUES__FIELD_TYPE__AGGREGATION_VALUE,
        title: 'Aggregation',
        description: 'Apply aggregate functions like Count, Sum, and more on values of a data extension/data view.',
        checked: aggregationValue,
        isEnabled: featureAggregationIsEnabled,
      },
    ];

    // Formula options to be rendered
    const formulaValueOptions = [
      {
        name: 'transform-date',
        id: Constants.CUSTOM_VALUES__FORMULA_TYPE__TRANSFORM,
        title: 'Transform Date',
        description: 'Transform a date field in a data extension / data view.',
        checked: transform,
        isEnabled: true,
      },
      {
        name: 'timestamp',
        id: Constants.CUSTOM_VALUES__FORMULA_TYPE__TIMESTAMP,
        title: 'Timestamp',
        description: 'Add a timestamp of the date / date time the selection runs.',
        checked: timestamp,
        isEnabled: true,
      },
      {
        name: 'date-difference',
        id: Constants.CUSTOM_VALUES__FORMULA_TYPE__DATE_DIFFERENCE,
        title: 'Date Difference',
        description: 'Calculate the amount of time between 2 dates.',
        checked: dateDiff,
        isEnabled: true,
      },
      {
        name: 'row-number',
        id: Constants.CUSTOM_VALUES__FORMULA_TYPE__ROW_NUMBER,
        title: 'Row Number',
        description: 'Create a new row number.',
        checked: rowNumber,
        isEnabled: true,
      },
      {
        name: 'free-formula',
        id: Constants.CUSTOM_VALUES__FORMULA_TYPE__FREE_FORMULA,
        title: 'Apply any function',
        description: 'Freely combine as many SQL functions as you need.',
        checked: freeFormula,
        isEnabled: featureFreeFormulasIsEnabled,
      },
    ];

    /**
     * renders header text based on different
     * custom value option selected
     * @return {String} text of header
     */
    const renderHeader = () => {
      if (formula && rowNumber && formulaSelected) {
        return Constants.CUSTOM_VALUES__HEADER__ROW_NUMBER;
      }
      if (formula &&
        (pickFormulaTypeStep)) {
        return Constants.CUSTOM_VALUES__HEADER__APPLY_FORMULA;
      }
      if (formula && formulaSelected === Constants.CUSTOM_VALUES__FORMULA_TYPE__TRANSFORM) {
        return Constants.CUSTOM_VALUES__HEADER__TRANSFORM_DATE;
      }
      if (formula && formulaSelected === Constants.CUSTOM_VALUES__FORMULA_TYPE__TIMESTAMP) {
        return Constants.CUSTOM_VALUES__HEADER__TIMESTAMP;
      }
      if (formula && formulaSelected === Constants.CUSTOM_VALUES__FORMULA_TYPE__DATE_DIFFERENCE) {
        return Constants.CUSTOM_VALUES__HEADER__DATE_DIFFERENCE;
      }
      if (formula && formulaSelected === Constants.CUSTOM_VALUES__FORMULA_TYPE__FREE_FORMULA) {
        return Constants.CUSTOM_VALUES__HEADER__FREE_FORMULA;
      }
      if (fixedValue && addValuesStep) {
        return Constants.CUSTOM_VALUES__HEADER__FIXED_VALUE;
      }
      if (dynamicValue && addValuesStep) {
        return Constants.CUSTOM_VALUES__HEADER__DYNAMIC_VALUE;
      }
      if (aggregationValue && addValuesStep) {
        return Constants.CUSTOM_VALUES__HEADER__AGGREGATION;
      }

      return '';
    };

    /**
     * renders custom value title based on different
     * custom value option selected
     * @return {String} title of custom value
     */
    const renderCustomValueTitle = () => {
      if (fixedValue) {
        return Constants.CUSTOM_VALUES__HEADER__FIXED_VALUE;
      }
      if (dynamicValue) {
        return Constants.CUSTOM_VALUES__HEADER__DYNAMIC_VALUE;
      }
      if (formula) {
        return Constants.CUSTOM_VALUES__HEADER__FORMULA_TYPE;
      }
      if (aggregationValue) {
        return Constants.CUSTOM_VALUES__HEADER__AGGREGATION;
      }

      return '';
    };

    /**
     * renders custom value description based on different
     * custom value option selected
     * @return {String} description of custom value
     */
    const renderCustomValueDescription = () => {
      if (fixedValue) {
        return Constants.CUSTOM_VALUES__TEXT_FIXED_VALUE;
      }
      if (dynamicValue) {
        return Constants.CUSTOM_VALUES__TEXT_DYNAMIC_VALUE;
      }
      if (aggregationValue) {
        return Constants.CUSTOM_VALUES__TEXT_AGGREGATION;
      }

      return '';
    };

    /**
     * Renders footer in the modal
     * @return {object} HTML for the footer
     */
    const renderFooter = () => {
      // items for progress bar
      const progressItems = [
        {
          className: (fixedValue || dynamicValue || formula ||
            aggregationValue) && valueName ?
            ' slds-is-completed' :
            '',
          disabled: !(addValuesStep || pickFormulaTypeStep || formulaSelected),
          onClick: () => this.handleClickOnProgressButton(1),
          name: 'Step 1',
          condition: true,
          icon: true,
        },
        {
          condition: !!formula,
          className: pickFormulaTypeStep || formulaSelected ?
            ` slds-is-${(transform || timestamp || dateDiff || formula || rowNumber) ? 'completed' : ''}` :
            '',
          disabled: !(editCustomValueIndex !== '' || (editCustomValueIndex === '' && formulaSelected)),
          onClick: () => this.handleClickOnProgressButton(2),
          name: 'Step 2',
          icon: true,
        },
        {
          className: addValuesStep || formulaSelected ?
            ' slds-is-completed' :
            '',
          disabled: !!((editCustomValueIndex === '' || (addValuesStep || formulaSelected))),
          onClick: () => this.handleClickOnProgressButton(3),
          name: 'Final step',
          condition: true,
          icon: true,
        },
      ];

      // class names for progress bar
      const progressBarClassName = addValuesStep || pickFormulaTypeStep || formulaSelected ?
        '' :
        ' step-two-bar';

      const progressBarValueClassName = classNames(
        addValuesStep || pickFormulaTypeStep || formulaSelected ? '' : 'step-one-bar',
        pickFormulaTypeStep ? 'fifty-width' : '',
      );

      // values for progress bar
      const values = {
        min: '0',
        max: '100',
        now: '25',
      };

      return (
        <>
          <ProgressBar
            progressItems={progressItems}
            progressBarClassName={progressBarClassName}
            progressBarValueClassName={progressBarValueClassName}
            values={values}
            containerClassName="slds-progress_shade"
          />
          {addValuesStep || pickFormulaTypeStep || formulaSelected ?
            (
              <Button
                id="back-button"
                buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
                onClick={this.switchToAddTypeOfValueStep}
                disabled={isValidating}
              >
                Back
              </Button>
            ) :
            null}
          <Button
            id="cancel-modal-btn"
            buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
            onClick={() => this.closeAddValuesModal()}
            disabled={isValidating}
          >
            Cancel
          </Button>
          {((addValuesStep && !formula) || formulaSelected) ?
            (
              <Button
                id="save-custom-value-btn"
                buttonLook={Constants.BUTTON__TYPE__BRAND}
                onClick={this.saveNewValue}
                disabled={this.isSaveButtonDisabled()}
                onKeyPress={e => this.onKeyPress(e)}
              >
                Save
              </Button>
            ) :
            (
              <Button
                id="next-step-btn"
                buttonLook={Constants.BUTTON__TYPE__BRAND}
                onClick={this.switchToAddValuesStep}
              >
                Next
              </Button>
            )}
        </>
      );
    };

    // class name for the modal
    const modalClassName = classNames(
      'slds-modal_large',
      // eslint-disable-next-line quote-props
      { 'hidden': criteriaModal || filtersModal },
      { 'criteria-modal': criteriaModal },
    );

    // class name for the modal content
    const modalContentClassName = classNames(
      'slds-p-around_medium',
      // eslint-disable-next-line quote-props
      (addValuesStep || pickFormulaTypeStep || formulaSelected) && `${formulaSelected}-step2`,
    );

    // define id for the modal content
    const modalContentId = (addValuesStep || pickFormulaTypeStep || formulaSelected) ?
      `modal-content-id-${pickFormulaTypeStep ? 1 : 2}` :
      'modal-content-id-1';

    return (
      <div>
        <ModalTemplate
          id="add-values-modal"
          className={modalClassName}
          containerId={formulaSelected}
          headerId="header-text"
          headerTitle={`Custom Value${renderHeader() && ` > ${renderHeader()}`}`}
          contentId={modalContentId}
          contentClassName={modalContentClassName}
          extraFooterContent={renderFooter()}
          backdropHidden={criteriaModal && !aggregationValue}
          footerClassName="slds-grid slds-grid_align-spread"
        >
          {addValuesStep || pickFormulaTypeStep || formulaSelected ?
            (
              <div>
                {formulaSelected || (aggregationValue && addValuesStep) || (rowNumber && addValuesStep) ?
                  null :
                  (
                    <>
                      <div
                        className={classNames(
                          'custom-values-title',
                          {
                            'type-of-formula':
                          renderCustomValueTitle() === Constants.CUSTOM_VALUES__HEADER__FORMULA_TYPE,
                          },
                        )}
                        >
                        {renderCustomValueTitle()}
                      </div>
                      {
                        renderCustomValueDescription() &&
                        <div className="custom-values-description">
                          {renderCustomValueDescription()}
                        </div>
                      }
                    </>
                  )}
                {fixedValue && addValuesStep ?
                  <FixedValueModal
                    fixedValueData={fixedValueData}
                    handleSetCustomValuesState={this.handleSetCustomValuesState}
                    disabled={isGlobal}
                  /> :
                  null}

                {dynamicValue && addValuesStep ?
                  <DynamicValueModal
                    pickLists={pickLists}
                    selectedDataExtensions={selectedDataExtensions}
                    noAvailableFieldsRef={this.noAvailableFieldsRef}
                    showAllNullError={showAllNullError}
                    showAllNullErrorRef={this.showAllNullErrorRef}
                    dynamicValueData={dynamicValueData}
                    handleSetCustomValuesState={this.handleSetCustomValuesState}
                    handleSetSelectionState={handleSetSelectionState}
                    showNoAvailableFieldsError={showNoAvailableFieldsError}
                    closeCriteriaModal={this.closeCriteriaModal}
                    handleFiltersSave={handleFiltersSave}
                    dynamicCustomValuesFilterID={dynamicCustomValuesFilterID}
                    handleSetDynamicValueFilterState={this.handleSetDynamicValueFilterState}
                    dynamicCustomValuesFilters={dynamicCustomValuesFilters}
                    getDataExtensionOrDataViewFields={getDataExtensionOrDataViewFields}
                    DEBorderMouseOver={DEBorderMouseOver}
                    filterBorderMouseOver={filterBorderMouseOver}
                    handlePickListOptions={handlePickListOptions}
                    handleRemoveFilterLine={handleRemoveFilterLine}
                    applyTimezoneSettingsToAllDateFields={applyTimezoneSettingsToAllDateFields}
                    timezoneSettingsForAllDateFields={timezoneSettingsForAllDateFields}
                    handleSetTimezoneToAllDateFields={handleSetTimezoneToAllDateFields}
                    criteriaModal={criteriaModal}
                    disabled={isGlobal}
                  /> :
                  null}
                {!fixedValue && !dynamicValue && aggregationValue &&
                 addValuesStep && !loadingAllAvailableDataExtensions ?
                  (
                    <AggregationModal
                      aggregationValues={aggregationValues}
                      handleSetCustomValuesState={this.handleSetCustomValuesState}
                      dataExtensions={dataExtensions}
                      loadingAllAvailableDataExtensions={loadingAllAvailableDataExtensions}
                      selectedDataExtensions={selectedDataExtensions}
                      handleSetSelectionState={handleSetSelectionState}
                      handleFiltersSave={handleFiltersSave}
                      aggregationFilters={aggregationFilters}
                      getDataExtensionOrDataViewFields={getDataExtensionOrDataViewFields}
                      DEBorderMouseOver={DEBorderMouseOver}
                      filterBorderMouseOver={filterBorderMouseOver}
                      pickLists={pickLists}
                      handlePickListOptions={handlePickListOptions}
                      handleRemoveFilterLine={handleRemoveFilterLine}
                      filtersModal={filtersModal}
                      isNewValue={editCustomValueIndex === ''}
                      featurePredefinedRelationsIsEnabled={featurePredefinedRelationsIsEnabled}
                      getAllRelationsForSelectedDE={this.getAllRelationsForSelectedDE}
                      getSelectedRelation={this.getSelectedRelation}
                      applyTimezoneSettingsToAllDateFields={applyTimezoneSettingsToAllDateFields}
                      timezoneSettingsForAllDateFields={timezoneSettingsForAllDateFields}
                      handleSetTimezoneToAllDateFields={handleSetTimezoneToAllDateFields}
                      disabled={isGlobal}
                    />
                  ) :
                  null}
                {!fixedValue && !dynamicValue && aggregationValue &&
                 addValuesStep && loadingAllAvailableDataExtensions ?
                  (
                    <Spinner
                    size={Constants.SPINNER__SIZE__LARGE}
                    assistiveText="Loading Data Extensions"
                    />
                  ) :
                  null}
                {
                  formula && pickFormulaTypeStep && (
                    <div className="second-step-custom-values-options">
                      {
                        formulaValueOptions.filter(option => option.isEnabled).map(option => (
                          <CustomValuesOption
                            name={option.name}
                            id={option.id}
                            key={option.id}
                            description={option.description}
                            title={option.title}
                            tab={tab}
                            checked={option.checked}
                            handleOptionSelected={this.handleOptionSelected}
                            dontShowDynamicOrRowNumberValue={!selectedDataExtensions ||
                              (selectedDataExtensions && selectedDataExtensions.length === 0)}
                            disabled={isGlobal}
                          />
                        ))
                      }
                    </div>
                  )
                }
                {formulaSelected ?
                  this.renderFormulaModal() :
                  null}
              </div>
            ) :
            (
              <div className="first-step-custom-values-options">
                <div className="custom-values-input">
                  <p className="custom-values-label">Name</p>
                  <Input
                    forwardRef={(input) => { this.nameInput = input; }}
                    name="value"
                    id={`field-name-text${tab}`}
                    min="1"
                    max="2550"
                    className="custom-values-radio field-name"
                    value={valueName}
                    onChange={e => this.setState({ valueName: e?.target?.value })}
                    placeholder="Enter a name for your custom value"
                    disabled={isGlobal}
                  />
                </div>
                <br />
                <div className="custom-values-question">Type of Custom Value</div>
                {customValuesOptions.filter(option => option.isEnabled).map(option => (
                  <CustomValuesOption
                    name={option.name}
                    id={option.id}
                    key={option.id}
                    description={option.description}
                    title={option.title}
                    tab={tab}
                    disabled={isGlobal}
                    checked={option.checked}
                    handleOptionSelected={this.handleOptionSelected}
                    dontShowDynamicOrRowNumberValue={!selectedDataExtensions ||
                      (selectedDataExtensions && selectedDataExtensions.length === 0)}
                  />
                ))}
              </div>
            )}
        </ModalTemplate>
      </div>
    );
  }
}

CustomValues.propTypes = {
  /**
   * It helps to set the Selection`s state
   * It will be passed from Selection.js
   */
  handleSetSelectionState: PropTypes.func.isRequired,
  /**
   * It keeps custom values data
   * It will be passed from Selection.js
   */
  customValues: PropTypes.instanceOf(Array),
  /**
   * It keeps index of custom value we want to edit
   * It will be passed from Selection.js
   */
  editCustomValueIndex: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  /**
   * It keeps the matchedFields for a target data extension of the Selection
   * It will be passed from Selection.js
   */
  matchedFields: PropTypes.instanceOf(Array).isRequired,
  /**
   * It keeps the selected data extensions for Selection.js
   * selected data extensions are stored as collections in database
   * It will be passed from Selection.js
   */
  selectedDataExtensions: PropTypes.instanceOf(Array),
  /**
   * It helps to save the selected filters for the selection
   * it will be passed from Selection.js
   */
  handleFiltersSave: PropTypes.func.isRequired,
  /**
   * It helps to save the selected filters for the criteria
   * it will be passed from Selection.js
   */
  dynamicCustomValuesFilters: PropTypes.instanceOf(Object),
  /**
   * It helps to retrieve fields of a data extension or data view from SFMC
   * It will be passed from Selection.js
   */
  getDataExtensionOrDataViewFields: PropTypes.func.isRequired,
  /**
   * Keeps track whether Available DE are dragged
   */
  DEBorderMouseOver: PropTypes.bool.isRequired,
  /**
   * Keeps track whether Available Fields are dragged
   */
  filterBorderMouseOver: PropTypes.bool.isRequired,
  /**
   * Responsible for adding/deleting fields Object IDs when searching picklist for the options
   * it will be passed from Selection.js
   */
  handlePickListOptions: PropTypes.func.isRequired,
  /**
   * Keeps searched picklist
   * It will be passed from Selection.js
   */
  pickLists: PropTypes.instanceOf(Array).isRequired,
  /**
   * Keeps tab index
   * It will be passed from TargetDefinition.js
   */
  tab: PropTypes.number.isRequired,
  /**
   * It Removes a given filterLine
   * It is passed from Selection.js
   */
  handleRemoveFilterLine: PropTypes.func,
  /**
   * It keeps the relation between selected data extensions
   * It will be passed from Selection.js
   */
  relations: PropTypes.instanceOf(Array),
  /**
   * It helps to cancel a subscription of an API call to backend
   * It will be passed from Selection.js
   */
  axiosCancelToken: PropTypes.instanceOf(Object),
  /**
   * It keeps the selected data extensions for Selection.js
   * selected data extensions are stored as collections in database
   * It will be passed from Selection.js
   */
  dataExtensions: PropTypes.instanceOf(Array),
  /**
   * It helps to save the selected filters for the criteria
   * it will be passed from Selection.js
   */
  aggregationFilters: PropTypes.instanceOf(Object),
  /**
   * An array containing relations that were defined for Data Extensions
   */
  predefinedRelations: PropTypes.instanceOf(Array).isRequired,
  /**
   * Indicates whether timezone settings are applied to all date filters
   */
  applyTimezoneSettingsToAllDateFields: PropTypes.bool,
  /**
   * Handles the setting of timezone settings to all date filters
   */
  handleSetTimezoneToAllDateFields: PropTypes.func,
  /**
   * An object containing timezone details
   */
  timezoneSettingsForAllDateFields: PropTypes.instanceOf(Object),
  /**
   * It keeps shared custom values data
   * It will be passed from Selection.js
   */
  globalCustomValues: PropTypes.instanceOf(Array),
  /**
   * Responsible for the loading status of all available data extensions
   */
  loadingAllAvailableDataExtensions: PropTypes.bool,
  /**
   * Features info from cookie
   */
  featuresInfo: PropTypes.object,
};

export default connect(mapStateToProps(['featuresInfo']), null, null, { pure: false })(CustomValues);
