import React from 'react';
import classNames from 'classnames';
import { Droppable } from 'react-beautiful-dnd';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Button } from '@salesforce/design-system-react';

import SelectionDropLine from './SelectionDropLine/SelectionDropLine';
import './styles.scss';
import Constants from '../../../../constants/constants';
import SwalUtil from '../../../../utils/swal/swalUtil';
import store from '../../../../redux/store/store';
import Util from '../../../../util';
import Tip from '../../../shared_v2/Tip/Tip';

// import AddSelectionComponent from './AddSelection/AddSelection';

const SelectionContainer = ({
  handleSetAppState, folderId, currentSelectionName, isArchived,
}) => {
  // get state of selectedSelections from selectionReducer
  const {
    selectedSelections, waterfallCopy,
    currentSelectionId,
  } = useSelector(({ selectionReducer, globalReducer }) => ({
    selectedSelections: selectionReducer.selectedSelections,
    waterfallCopy: globalReducer.waterfallCopy,
    currentSelectionId: globalReducer.currentSelectionId,
  }), shallowEqual);

  /**
   * This function returns className for dropzone
   * @param {boolean} isDragging - returns true if a drag operation is in progress
   * @returns {string} className
   */
  const dropzoneClassName = isDragging => classNames(
    'selection-dropzone',
    // eslint-disable-next-line quote-props
    { 'isDragging': isDragging },
  );

  /**
   * This function returns arrow to the next step depending on the selection index
   * @param {boolean} index - selection index
   * @returns {object} HTML for the arrow
   */
  const showArrowToNextStep = (index) => {
    if (selectedSelections.length > 1 && index !== selectedSelections.length - 1) {
      return (
        <div className="next-step-arrow-new" />
      );
    }

    return null;
  };

  /**
   * Function to create new selection from waterfall selection
   * @returns {void}
   */
  const handleCreateNewSelection = async () => {
    // get the current state
    const currentState = store.getState();

    // current state without some property
    const currentStateWithoutProperties = Util.returnStateWithoutSomeProperties(currentState);

    // saved copy of the state without some property
    const copyStateWithoutProperties = Util.returnStateWithoutSomeProperties(waterfallCopy);

    // compare copy with current state
    const stateHasNotChanged = _.isEqual(currentStateWithoutProperties, copyStateWithoutProperties);

    const appState = {
      backToWaterFall: {
        _id: currentSelectionId,
        name: currentSelectionName,
        folderId,
        actionType: Constants.BACKTOWATERFALL__TYPE_CREATE,
      },
      navigator: Constants.NAVIGATION__SELECTION,
      globalNavigator: Constants.NAVIGATION__SELECTION,
      currentSelectionId: null,
      folderId: null,
    };

    if(stateHasNotChanged) {
      handleSetAppState(appState);
    } else {
    // show warning of unsaved changes
      const res = await SwalUtil.fire({
        title: 'Confirmation needed',
        message: `Are you sure you want to create new Selection?
        Unsaved changes will be lost.`,
        options: {
          confirmButtonText: 'Yes',
          showCancelButton: true,
          allowOutsideClick: false,
        },
      });

      // if pressed ok, open the seletion
      if (res.value) {
        handleSetAppState(appState);
      }
    }
  };

  /**
   * Generate label for the Tip text
   * @returns {node} - JSX node
   */
  const getTipDescription = () => {
    return (
      <span>
      Drag and drop Selections from the section on the left to this area
      to chain existing Selections, or
      {' '}
      <Button
        type="button"
        onClick={() => !isArchived && handleCreateNewSelection()}
        className="des-lib-datatable-create-selection"
      >
        create a new Selection
      </Button>
      . When you have selected all the Selections you need, click the Next button.
      </span>
    );
  };

  return (
    <div className={classNames('selection-container-new', { 'selection-container-new-isArchived': isArchived })}>
      <div className="selections">
      <div className="create-new-selection">
        <Button
          type="button"
          iconCategory="utility"
          iconName="add"
          iconPosition="left"
          disabled={isArchived}
          onClick={() => !isArchived && handleCreateNewSelection()}
          >
          Create New Selection
        </Button>
      </div>
        <Droppable
          droppableId={Constants.DROPPABLE__CONTAINER_ID__2}
        >
          {(provided, snapshot) => (
            <div
              className={dropzoneClassName(snapshot.isDraggingOver)}
              ref={provided.innerRef}
            >

              {selectedSelections?.length ?
                selectedSelections.map((selection, index) => (
                  <SelectionDropLine
                    selection={selection}
                    id={selection._id}
                    key={selection._id}
                    index={index}
                    showArrowToNextStep={showArrowToNextStep}
                    selectedSelections={selectedSelections}
                    handleSetAppState={handleSetAppState}
                    currentSelectionId={currentSelectionId}
                    folderId={folderId}
                    currentSelectionName={currentSelectionName}
                    isArchived={isArchived}
                  />
                )) :
                null}
              {/* <AddSelectionComponent
                isArchived={isArchived}
                handleCreateNewSelection={handleCreateNewSelection}
              /> */}
              <div className="provided-placeholder">
                {provided.placeholder}
                {snapshot.placeholder}

                <p className="selection-drop">
                <Tip
                  title="Tip:"
                  description={getTipDescription()}
                  mode="light"
                />
                </p>
              </div>
            </div>
          )}
        </Droppable>
      </div>

    </div>
  );
};

SelectionContainer.propTypes = {
  /*
   * helps to set state in app.js
   */
  handleSetAppState: PropTypes.func.isRequired,
  /*
   * id of selected folder
   */
  folderId: PropTypes.string.isRequired,
  /*
   * the name of the currently open waterfall selection
   */
  currentSelectionName: PropTypes.string.isRequired,
  /*
   * Indicates if a selection is archived
   */
  isArchived: PropTypes.bool.isRequired,
};

export default SelectionContainer;
