import axios from 'axios';

import Util from '../util';

const apiUrl = process.env.REACT_APP_API_URL;

/**
 * A collection of functions to interact with the DeedeeAI API
 */
const DeedeeAIAPI = {
  /**
   * Sends a message to the DeedeeAI API
   * @param {string} message - The message to send to the DeedeeAI API
   * @param {Object} cancelToken - The cancel token to cancel the request
   * @returns {Promise<Object>} - The response data from the DeedeeAI API
   */
  sendMessage: async (message, cancelToken) => {
    const requestUrl = `${apiUrl}/deedeeai`;

    const res = await Util.catch418And403Error(
      axios.post,
      requestUrl,
      { message },
      Util.apiPostCallSettings(cancelToken),
    );

    return res.data;
  },

  /**
   * Gets the scope data extensions from the DeedeeAI API
   * @param {Object} cancelToken - The cancel token to cancel the request
   * @returns {Promise<Object>} - The response data from the DeedeeAI API
   */
  getScopeDataExtensions: async (cancelToken) => {
    const requestUrl = `${apiUrl}/deedeeai/scopedataextensions/`;

    const res = await Util.catch418And403Error(
      axios.get,
      requestUrl,
      Util.apiGetCallSettings(cancelToken),
    );

    return res.data;
  },

  /**
   * Updates the scope data extensions in the DeedeeAI API
   * @param {Object} dataExtensions - The scope data extensions to update in the DeedeeAI API
   * @param {Object} cancelToken - The cancel token to cancel the request
   * @returns {Promise<Object>} - The response data from the DeedeeAI API
   */
  updateScopeDataExtensions: async (dataExtensions, cancelToken) => {
    const requestUrl = `${apiUrl}/deedeeai/scopedataextensions/`;

    const res = await Util.catch418And403Error(
      axios.post,
      requestUrl,
      { dataExtensions },
      Util.apiPostCallSettings(cancelToken),
    );

    return res.data;
  },

  /**
   * Sends feedback to the DeedeeAI API
   * @param {Object} feedback - The feedback to send to the DeedeeAI API
   * @param {String} deedeeAIRequestId - The ID of the DeedeeAIRequest associated with the feedback
   * @param {Object} cancelToken - The cancel token to cancel the request
   * @returns {Promise<Object>} - The response data from the DeedeeAI API
   */
  postDeedeeAIFeedback: async (feedback, deedeeAIRequestId, cancelToken) => {
    const requestUrl = `${apiUrl}/deedeeai/feedback/`;

    const res = await Util.catch418And403Error(
      axios.post,
      requestUrl,
      { feedback, deedeeAIRequestId },
      Util.apiPostCallSettings(cancelToken),
    );

    return res.data;
  },

  /**
   * Gets the DeedeeAIRequest from the DeedeeAI API based on a provided query
   * @param {Object} query - The query to fetch the DeedeeAIRequest from the DeedeeAI API
   * @param {Object} cancelToken - The cancel token to cancel the request
   * @returns {Promise<Object>} - The response data from the DeedeeAI API
   */
  getDeedeeAIRequest: async (query, cancelToken) => {
    const requestUrl = `${apiUrl}/deedeeai/deedeeai-requests/`;

    const res = await Util.catch418And403Error(
      axios.post,
      requestUrl,
      { query },
      Util.apiGetCallSettings(cancelToken),
    );

    return res.data;
  },

};

export default DeedeeAIAPI;
