import {
  SET_FILTER_SET_CATEGORY_NAME,
} from '../types';

/**
 * Function that sets the filter set category name
 * @param {string} value - new value
 * @returns {void}
 */
export const setFilterSetCategoryName = value => (dispatch) => {
  dispatch({
    type: SET_FILTER_SET_CATEGORY_NAME,
    payload: value,
  });
};
