import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import CriteriaNavigation from './CriteriaNavigation/CriteriaNavigation';
import NavigationButtons from './NavigationButtons/NavigationButtons';
import SelectionName from './SelectionName/SelectionName';

// eslint-disable-next-line import/prefer-default-export
const Navbar = ({
  selectionNavigation,
  selectionNavigator,
  handleNavigator,
  selectedDataExtensions,
  selectionName,
  matchedFields,
  validateIfQueryCanBeRun,
  saveSelection,
  previewQueryActivityId,
  numberOfResults,
  previewStatus,
  handleSetSelectionState,
  currentSelectionId,
  axiosCancelToken,
  editNewAutoTargetDE,
  editTargetDataExtension,
  showSaveToast,
  folders,
  handleSetAppState,
  folderId,
  disablePreviewBTN,
  checkMissingFieldsInRelations,
  enabledScheduleSelection,
  copiedSelectionState,
  selectionState,
  checkIncompleteFilter,
  checkValidSchedule,
  runStatus,
  captureSelectionChange,
  handleSetSelectionName,
  isTemplate,
  isArchived,
  selectionCreator,
  scheduleDisabled,
  backToWaterFall,
  clickedSave,
  showEssentialsUpgradeModal,
}) => (
  <div className="selection_header" id="selection-header">
    <div className="selection_wrapper">
      <SelectionName
        folders={folders}
        selectionName={selectionName}
        handleSetSelectionName={handleSetSelectionName}
        handleSetAppState={handleSetAppState}
        folderId={folderId}
        currentSelectionId={currentSelectionId}
        axiosCancelToken={axiosCancelToken}
        isArchived={isArchived}
        showEssentialsUpgradeModal={showEssentialsUpgradeModal}
        backToWaterFall={backToWaterFall}
      />
      <CriteriaNavigation
        selectionNavigation={selectionNavigation}
        selectionNavigator={selectionNavigator}
        selectedDataExtensions={selectedDataExtensions}
        matchedFields={matchedFields}
        previewQueryActivityId={previewQueryActivityId}
        numberOfResults={numberOfResults}
        previewStatus={previewStatus}
        editNewAutoTargetDE={editNewAutoTargetDE}
        editTargetDataExtension={editTargetDataExtension}
        handleSetSelectionState={handleSetSelectionState}
      />
      <NavigationButtons
        selectionNavigator={selectionNavigator}
        selectionNavigation={selectionNavigation}
        handleNavigator={handleNavigator}
        validateIfQueryCanBeRun={validateIfQueryCanBeRun}
        saveSelection={saveSelection}
        selectedDataExtensions={selectedDataExtensions}
        matchedFields={matchedFields}
        currentSelectionId={currentSelectionId}
        axiosCancelToken={axiosCancelToken}
        editNewAutoTargetDE={editNewAutoTargetDE}
        editTargetDataExtension={editTargetDataExtension}
        handleSetSelectionState={handleSetSelectionState}
        showSaveToast={showSaveToast}
        disablePreviewBTN={disablePreviewBTN}
        checkMissingFieldsInRelations={checkMissingFieldsInRelations}
        enabledScheduleSelection={enabledScheduleSelection}
        copiedSelectionState={copiedSelectionState}
        selectionState={selectionState}
        checkIncompleteFilter={checkIncompleteFilter}
        checkValidSchedule={checkValidSchedule}
        runStatus={runStatus}
        captureSelectionChange={captureSelectionChange}
        isTemplate={isTemplate}
        selectionCreator={selectionCreator}
        scheduleDisabled={scheduleDisabled}
        backToWaterFall={backToWaterFall}
        handleSetAppState={handleSetAppState}
        clickedSave={clickedSave}
        showEssentialsUpgradeModal={showEssentialsUpgradeModal}
      />

    </div>
  </div>
);

Navbar.propTypes = {
  /**
   * This function helps to navigate between pages in a Selection
   * This prop will be passed from Selection.js
   */
  selectionNavigation: PropTypes.func.isRequired,
  /**
   * This prop keep the current page`s navigation value
   * This prop will be passed from Selection.js
   */
  selectionNavigator: PropTypes.string.isRequired,
  /**
   * handleNavigator prop is passed from App.js through Selection.js
   * and it helps to navigate between Overview and Selection
   */
  handleNavigator: PropTypes.func.isRequired,
  /**
   * This prop keeps the selectedDataExtensions of the Selection
   * It will be passed from Selection.js
   */
  selectedDataExtensions: PropTypes.instanceOf(Array),
  /**
   * It keeps the selection name
   * It will be passed from Selection.js
   */
  selectionName: PropTypes.string,
  /**
   * It keeps the matchedFields for a target data extension of the Selection
   * It will be passed from Selection.js
   */
  matchedFields: PropTypes.instanceOf(Array).isRequired,
  /**
   * It validates if the query can be run with the given data for the Selection
   * It will be passed from Selection.js
   */
  validateIfQueryCanBeRun: PropTypes.func.isRequired,
  /**
   * It saves the selection to database with the given data for the Selection
   * It will be passed from Selection.js
   */
  saveSelection: PropTypes.func.isRequired,
  /**
   * it keeps the previewQueryActivityId for the selection if the preview is run before
   * It will be passed from Selection.js
   */
  previewQueryActivityId: PropTypes.string,
  /**
   * It keeps the number of results for a Selection`s query after a run
   * It will be passed from Selection.js
   */
  numberOfResults: PropTypes.number,
  /**
   * It keeps the status of the preview task (quued, completed etc)
   * It will be passed from Selection.js
   */
  previewStatus: PropTypes.number,
  /**
   * It helps to set the Selection`s state
   * It will be passed from Selection.js
   */
  handleSetSelectionState: PropTypes.func.isRequired,
  /**
   * It keep`s the selected selection`s id
   * It will be passed from the App through Selection.js
   */
  currentSelectionId: PropTypes.string,
  /**
   * It helps to cancel a subscription of an API call to backend
   * It will be passed from Selection.js
   */
  axiosCancelToken: PropTypes.instanceOf(Object),
  /**
   * It keeps the value of ac/de creation status
   * It will be passed from Selection.js
   */
  editNewAutoTargetDE: PropTypes.bool,
  /**
   * boolean state from Selection for going in or out the edit target de mode
   */
  editTargetDataExtension: PropTypes.bool.isRequired,
  /**
   * Boolean responsible for showing the toast
   */
  showSaveToast: PropTypes.bool.isRequired,
  /**
   * it sets the App component`s state
   * This prop will be passed from App.js component through Selection.js
   */
  handleSetAppState: PropTypes.func.isRequired,
  /**
   * It gives the id of folder
   */
  folderId: PropTypes.string.isRequired,
  /**
   * It contains all root available folders where new DE can be saved
   */
  folders: PropTypes.instanceOf(Object).isRequired,
  /**
   * Boolean to determine if preview button is disabled on the preview screen
   */
  disablePreviewBTN: PropTypes.bool,
  /**
   * Checks if there are any deleted fields in relations
   */
  checkMissingFieldsInRelations: PropTypes.func,
  /**
   * Boolean responsible for changing color for the schedule button
   */
  enabledScheduleSelection: PropTypes.bool.isRequired,
  /*
   * State object of current selection
   * It will be passed from Selection.js
   */
  selectionState: PropTypes.instanceOf(Object),
  /*
   *  Copied state object of current selection
   * It will be passed from Selection.js
   */
  copiedSelectionState: PropTypes.instanceOf(Object),
  /*
   * It helps to check if filter is incomplete for relation
   * It will be passed from Selection.js
   */
  checkIncompleteFilter: PropTypes.func.isRequired,

  /*
   * It helps validate set schedule for selection
   * It will be passed from Selection.js
   */
  checkValidSchedule: PropTypes.func.isRequired,

  /*
   * Stores information about runStatus of current selection
   */
  runStatus: PropTypes.number,
  /*
   * It informs that there has been a change in the selection
   */
  captureSelectionChange: PropTypes.bool.isRequired,
  /**
   * it sets the selection Name in Selection state
   */
  handleSetSelectionName: PropTypes.func.isRequired,
  /**
   * Indicates whether a selection is a template
   */
  isTemplate: PropTypes.bool.isRequired,
  /**
   * Indicates whether a selection is archived
   */
  isArchived: PropTypes.bool.isRequired,
  /**
   * Id of the user that created the selection
   */
  selectionCreator: PropTypes.string.isRequired,
  /**
   * the waterFall we want to go back to, null if we do not
   */
  backToWaterFall: PropTypes.object,
  /**
   * Boolean for saving a selection
   */
  clickedSave: PropTypes.bool,
  /**
   * Indicates whether a schedule button is disabled
   */
  scheduleDisabled: PropTypes.bool,
  /**
   * It toggles a feature advert modal on with specific feature
   */
  showEssentialsUpgradeModal: PropTypes.func.isRequired,
};
export default Navbar;
