import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Tooltip from '../Tooltip/Tooltip';
import Constants from '../../../constants/constants';
import './styles.scss';

const RadioButton = ({
  id,
  name,
  label = '',
  value,
  onChange,
  checked = false,
  disabled = false,
  forwardRef,
  className,
  containerClassName,
  unlabeledInput,
  labelClassName,
  title,
  tooltipText,
  beta,
}) => {
  const radioContainerClassName = classNames(
    unlabeledInput ? '' : 'slds-radio',
    `${containerClassName || ''}`,
  );

  /**
   * Renders input element
   * @returns {object} HTML containing the input element
   */
  const renderInput = () => (
    <input
      type="radio"
      id={id}
      checked={checked}
      value={value}
      onChange={onChange}
      name={name}
      aria-label={name}
      disabled={disabled}
      ref={forwardRef}
      className={className}
    />
  );

  return (
  <span className={radioContainerClassName} title={title}>
    {
      tooltipText &&
        <Tooltip
          nubbinPosition={Constants.NUBBIN_POSITION__TOP_RIGHT}
          tooltipText={tooltipText}
        />
    }
    {unlabeledInput ?
      (
      <>
        {renderInput()}
        <label className={`slds-radio_button__label ${labelClassName || ''}`} htmlFor={id}>
          <span className="slds-radio_faux">
            {label}
          </span>
        </label>
      </>
      ) :
      (
      <label className={`slds-radio__label ${labelClassName || ''}`} htmlFor={id}>
        <span className="slds-form-element__label">
          {renderInput()}
          <span className="slds-radio_faux" />
          <span className="radio-label">{label}</span>
          {beta && (
            <span className="tooltip-container">
            <span className="radio-label beta">Beta</span>
            </span>
          )}
        </span>
      </label>
      )}
  </span>
  );
};

RadioButton.propTypes = {
  /**
   * Id of the button
   */
  id: PropTypes.string.isRequired,
  /**
   * Name of the button
   */
  name: PropTypes.string,
  /**
   * Label of the button
   */
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  /**
   * Value of the button
   */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]),
  /**
   * onChange Event handler
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Checked state of the button
   */
  checked: PropTypes.bool.isRequired,
  /**
   * Disabled state of the button
   */
  disabled: PropTypes.bool,
  /**
   * Ref element of radio button
   */
  forwardRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.elementType }),
  ]),
  /**
   * className of the input
   */
  className: PropTypes.string,
  /**
   * className of the span element that wraps radio element
   */
  containerClassName: PropTypes.string,
  /**
   * indicates that input should not be wrapped by label element
   */
  unlabeledInput: PropTypes.bool,
  /**
   * class name for the label
   */
  labelClassName: PropTypes.string,
  /**
   * title for the radio component
   */
  title: PropTypes.string,
  /**
   * tooltip text for the radio component
   *
   */
  tooltipText: PropTypes.string,
};

export default RadioButton;
