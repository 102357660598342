import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import axios from 'axios';
import { connect } from 'react-redux';

import mapStateToProps from '../../mapStateToProps';
import Constants from '../../constants/constants';
import Util from '../../util';
import Features from '../../features';
import './style.scss';
import Button from '../shared/Button/Button';
import SwalUtil from '../../utils/swal/swalUtil';
import { GuidanceTipsContext } from '../../utils/contexts/guidanceTipsContext';
import DeedeeAIAPI from '../../api/deedeeAI';
import ScopedModal from '../DeedeeAI/ScopedModal/ScopedModal';
import NoScopeDeModal from '../DeedeeAI/NoScopeDeModal/NoScopeDeModal';
import { DeedeeAIModal } from '../DeedeeAI/MessageModal/MessageModal';
import FeedbackModal from '../DeedeeAI/FeedbackModal/FeedbackModal';

const axiosCancelToken = axios.CancelToken.source();

const RightBar = ({
  handleNavigator,
  navigator,
  updateUserPreferences,
  userPreferences,
  handleSetAppState,
  deedeeAIScopeDEs,
  showDeedeeAIFeedbackModal,
  handleOpenAidown,
  deedeeAIRequestFeedback,
  userInfo,
  orgInfo,
  featuresInfo,
}) => {
  const hasReadOnlyAccess = userInfo?.hasReadOnlyAccess;
  const featureDeedeeAI = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__DEEDEE_AI);
  const [isShowTipsEnabled, setIsShowTipsEnabled] = useState(false);

  const tipsData = useContext(GuidanceTipsContext);

  const isAnyGuidanceTipEnabled = tipsData?.guidanceTips?.some(
    tip => tip.isEnabled,
  );

  const [openDeedeeAIModal, setOpenDeedeeAIModal] = useState(false);
  const [openScopedDEsModal, setOpenScopedDEsModal] = useState(false);
  const [noScopeDeModalOpen, setNoScopeDeModalOpen] = useState(false);
  const [deedeeAIMessage, setDeedeeAIMessage] = useState('');
  const [isResponseLoading, setIsResponseLoading] = useState(false);
  const [error, setError] = useState('');
  const [response, setResponse] = useState('');

  useEffect(() => {
    setIsShowTipsEnabled(userPreferences?.showTips === true);
  }, [userPreferences]);

  /**
   * Handler for the onClick event on the support button
   * @param {object} e - JS Event
   * @returns {void}
   */
  const handleOnClick = (e) => {
    const rightbar = e.currentTarget || e.target.closest('.rightbar_support');

    if (rightbar) {
      const currentSate = rightbar.getAttribute('aria-expanded');

      rightbar.setAttribute('aria-expanded', !currentSate);
      rightbar.classList.toggle('slds-is-open');
    }
  };

  /**
   * Handle onCLick event for showing or hiding tips
   * @returns {void}
   */
  const handleUpdateShowTips = async () => {
    await updateUserPreferences({ showTips: !isShowTipsEnabled });

    setIsShowTipsEnabled(!isShowTipsEnabled);
  };

  /**
   * Handler for the onBlur event on the support button
   * @param {object} e - JS Event
   * @returns {void}
   */
  const handleOnBlur = (e) => {
    const rightbar = e.target.closest('.rightbar_support');

    if (rightbar && !rightbar.contains(e.relatedTarget)) {
      rightbar.setAttribute('aria-expanded', false);
      rightbar.classList.remove('slds-is-open');
    }
  };

  /**
   * Handler for the onClick event on the admin button
   * @returns {void}
   */
  const handleClickOnAdminButton = async () => {
    const confirmationNeededFor = [
      Constants.NAVIGATION__SELECTION_CRITERIA,
      Constants.NAVIGATION__TARGET_DEFINITION,
      Constants.NAVIGATION__PREVIEW,
    ];

    if (confirmationNeededFor.includes(navigator)) {
      const res = await SwalUtil.fire({
        title: 'Confirmation needed',
        message: `Are you sure you want to go to the Admin Panel ?
        Unsaved changes will be lost.`,
        options: {
          showCancelButton: true,
          allowOutsideClick: false,
        },
      });

      if (res.value) {
        handleNavigator(Constants.NAVIGATION__ADMIN_PANEL);
      }
    } else {
      handleNavigator(Constants.NAVIGATION__ADMIN_PANEL);
    }
  };

  /**
   * Renders the correct link depending on where we are in the App
   * @returns {void}
   */
  const relevantSupportLink = () => {
    const supportLink = 'https://support.deselect.com/hc/en-us';

    switch (navigator) {
      // OVERVIEW
      case Constants.NAVIGATION__OVERVIEW:
        return `${supportLink}/sections/360000730878-Step-0-Selections-Overview`;
      // SELECTION > CRITERIA
      case Constants.NAVIGATION__SELECTION_CRITERIA:
        return `${supportLink}/sections/360002311818-Step-1-Selection-Criteria`;
      // SELECTION > TARGET DEFINITION
      case Constants.NAVIGATION__TARGET_DEFINITION:
        return `${supportLink}/sections/360002311838-Step-2-Target-Definition`;
      // SELECTION > PREVIEW
      case Constants.NAVIGATION__PREVIEW:
        return `${supportLink}/sections/360002291697-Step-3-Preview-and-Run`;
      default:
        return supportLink;
    }
  };

  const openDeedeeModal = () => {
    setIsResponseLoading(false);
    setResponse('');
    setError('');

    setOpenDeedeeAIModal(true);
  };

  /**
   * Handles click events on the DeeDee button. It performs several checks based on user role
   * and the existence of data extensions in the scope, and takes actions accordingly.
   * @returns {void}
   */
  const handleDeeDeeButtonClick = () => {
    const { isAdmin } = userInfo;
    const noDataExtensionsInScope = (deedeeAIScopeDEs?.length === 0);

    if (noDataExtensionsInScope) {
      // Show appropriate modal based on user role
      if (isAdmin) {
        setOpenScopedDEsModal(true);
      } else {
        setNoScopeDeModalOpen(true);
      }
    } else {
      // Proceed based on current navigator value
      if (
        (navigator === Constants.NAVIGATION__SELECTION_CRITERIA ||
          navigator === Constants.NAVIGATION__TARGET_DEFINITION ||
          navigator === Constants.NAVIGATION__PREVIEW) &&
        deedeeAIRequestFeedback?.status
      ) {
        handleSetAppState({ showDeedeeAIFeedbackModal: true });
      } else {
        openDeedeeModal();
      }
    }
  };

  const saveDeedeeAIModal = async () => {
    setIsResponseLoading(true);

    try {
      const response = await DeedeeAIAPI.sendMessage(
        deedeeAIMessage,
        axiosCancelToken.token,
      );

      if (response) {
        setIsResponseLoading(false);
        setResponse(response);
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        setError(error);

        setIsResponseLoading(false);
        if(error === 'Resource not found') {
          handleOpenAidown(true);
          setOpenDeedeeAIModal(false);
        }
      }
    }
  };

  const handleOpenSelection = () => {
    handleSetAppState(
      {
        navigator: Constants.NAVIGATION__OVERVIEW,
        globalNavigator: Constants.NAVIGATION__OVERVIEW,
      },
      () => {
        handleSetAppState({
          currentSelectionId: response.data._id,
          navigator: Constants.NAVIGATION__SELECTION,
          globalNavigator: Constants.NAVIGATION__SELECTION,
          currentSelectionName: response.data.name,
        });
      },
    );

    setIsResponseLoading(false);
    setResponse('');
    setError('');

    setOpenDeedeeAIModal(false);
  };

  const extraFooterContent = <div className="deedee-ai-footer" />;

  return (
    <>
      <div className="rightbar">
        {/* Top container */}
        <div
          className="slds-dropdown-trigger slds-dropdown-trigger_click rightbar_support"
          onClick={handleOnClick}
          onBlur={handleOnBlur}
        >
          <Button noSpan noButtonClass className="rightbar_support-button">
            <span
              className="slds-icon_container slds-current-color"
              title="Support"
            >
              <svg className="slds-icon slds-icon_medium" aria-hidden="true">
                <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#question_mark" />
              </svg>
              <span className="slds-assistive-text">Support</span>
            </span>
          </Button>
          <div className="slds-dropdown slds-dropdown_right">
            <ul
              className="slds-dropdown__list"
              role="menu"
              aria-label="Show More"
            >
              <li className="slds-dropdown__item" role="presentation">
                <a
                  href={relevantSupportLink()}
                  target="_blank"
                  rel="noopener noreferrer"
                  role="menuitem"
                  tabIndex="0"
                >
                  <span
                    className="slds-truncate"
                    title="Relevant Support Articles"
                  >
                    Relevant Support Articles
                  </span>
                </a>
              </li>
              <li className="slds-dropdown__item" role="presentation">
                <a
                  href="https://support.deselect.com/hc/en-us/requests/new"
                  target="_blank"
                  rel="noopener noreferrer"
                  role="menuitem"
                  tabIndex="0"
                >
                  <span className="slds-truncate" title="Contact Support">
                    Contact Support
                  </span>
                </a>
              </li>
              <li className="slds-dropdown__item" role="presentation">
                <a
                  href="https://deselect.com/trust/"
                  target="_blank"
                  rel="noopener noreferrer"
                  role="menuitem"
                  tabIndex="0"
                >
                  <span className="slds-truncate" title="Trust Status Page">
                    Trust Status Page
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="rightbar_admin">
          {/* Shows the gear icon redirecting to the admin panel
            if the logged in user is an administrator */}
          {userInfo !== undefined &&
            userInfo.isAdmin &&
            !hasReadOnlyAccess && (
              <Button
                noButtonClass
                onClick={() => handleClickOnAdminButton()}
                className="rightbar_admin-button"
                noSpan
              >
                <span
                  className="slds-icon_container slds-current-color"
                  title="Open Admin Panel"
                >
                  <svg
                    className="slds-icon slds-icon-text-default slds-icon_medium"
                    aria-hidden="true"
                  >
                    <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#settings" />
                  </svg>
                  <span className="slds-assistive-text">Open Admin Panel</span>
                </span>
              </Button>
          )}
        </div>

        <div className="rightbar_admin">
          {Util.isDESelectFreeUser(orgInfo) && isAnyGuidanceTipEnabled && (
            <Button
              noButtonClass
              onClick={handleUpdateShowTips}
              id="tips-toggle-button"
              className={classNames({
                'tips-toggle-button-active': isShowTipsEnabled,
              })}
              noSpan
            >
              <span
                className="slds-icon_container slds-current-color"
                title={isShowTipsEnabled ? 'Hide Tips' : 'Show Tips'}
              >
                <svg className="slds-icon" aria-hidden="true">
                  <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#light_bulb" />
                </svg>
                <span className="slds-assistive-text">
                  {isShowTipsEnabled ? 'Hide Tips' : 'Show Tips'}
                </span>
              </span>
            </Button>
          )}
        </div>
        {featureDeedeeAI && (
          <div className="deedee-ai-option-container" onClick={() => handleDeeDeeButtonClick()}>
            <img
              alt="Deedee"
              src="/img/deedee-icon.png"
              className="deedee-ai-btn"
            />
          </div>
        )}
      </div>
      {openDeedeeAIModal && (
        <DeedeeAIModal
          open={openDeedeeAIModal}
          saveDeedeeAIModal={saveDeedeeAIModal}
          deedeeAIMessage={deedeeAIMessage}
          setDeedeeAIMessage={setDeedeeAIMessage}
          handleOpenSelection={handleOpenSelection}
          setOpenDeedeeAIModal={setOpenDeedeeAIModal}
          response={response}
          isResponseLoading={isResponseLoading}
          error={error}
          setIsResponseLoading={setIsResponseLoading}
          setError={setError}
          setResponse={setResponse}
          extraFooterContent={extraFooterContent}
          deedeeAIScopeDEs={deedeeAIScopeDEs}
        />
      )}
      {openScopedDEsModal && (
        <ScopedModal
          handleSetAppState={handleSetAppState}
          deedeeAIScopeDEs={deedeeAIScopeDEs}
          setOpenScopedDEsModal={setOpenScopedDEsModal}
          setOpenDeedeeAIModal={setOpenDeedeeAIModal}
        />
      )}
      {noScopeDeModalOpen && (
        <NoScopeDeModal
          isOpen={noScopeDeModalOpen}
          closeModal={() => setNoScopeDeModalOpen(false)}
        />
      )}
      {showDeedeeAIFeedbackModal &&
        (navigator === Constants.NAVIGATION__SELECTION_CRITERIA ||
          navigator === Constants.NAVIGATION__TARGET_DEFINITION ||
          navigator === Constants.NAVIGATION__PREVIEW) && (
          <FeedbackModal
            isOpen={showDeedeeAIFeedbackModal}
            deedeeAIRequestFeedback={deedeeAIRequestFeedback}
            handleSetAppState={handleSetAppState}
            setOpenDeedeeAIModal={setOpenDeedeeAIModal}
          />
      )}
    </>
  );
};

RightBar.propTypes = {
  /**
   * HandleNavigator prop is passed from App.js and it helps to navigate between the different screen of the App
   */
  handleNavigator: PropTypes.func.isRequired,
  /**
   * User preferences
   */
  userPreferences: PropTypes.object,
  /**
   * Calls the API and Update the user's preferences
   */
  updateUserPreferences: PropTypes.func.isRequired,
  /**
   * Navigator prop is passed from App.js and it determines to know where we where in the App
   */
  navigator: PropTypes.string.isRequired,
  /**
   * it sets the App component`s state
   * This prop will be passed from App.js component
   */
  handleSetAppState: PropTypes.func,
  /**
   * Deedee AI scoped DEs
   * This prop will be passed from App.js component
   */
  deedeeAIScopeDEs: PropTypes.array,
  /**
   * DeedeeAI Feedback request
   */
  deedeeAIRequestFeedback: PropTypes.object,
  /**
   * It toggles the state of Deedee AI feedback modal
   */
  showDeedeeAIFeedbackModal: PropTypes.bool,
  /**
   * User info from cookie
   */
  userInfo: PropTypes.object,
  /**
   * Org info from cookie
   */
  orgInfo: PropTypes.object,
  /**
   * Features info from cookie
   */
  featuresInfo: PropTypes.object,
};

export default connect(mapStateToProps(['userInfo', 'orgInfo', 'featuresInfo']), null, null, { pure: false })(RightBar);
