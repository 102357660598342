import {
  useState, useCallback, useMemo,
} from 'react';

import PreviewTipsAPI from '../../../../../api/preview-tips';

const DEFAULT_TIP = `Preview will show you the first 20 results of your Selection
and will calculate the number of records in your results`;

const useTips = () => {
  const [currentTipIndex, setCurrentTipIndex] = useState(0);
  const [tips, setTips] = useState([]);

  const fetchTips = async (axiosCancelToken) => {
    try {
      const fetchedTips = await PreviewTipsAPI.getPreviewTips(axiosCancelToken);

      setTips(fetchedTips);
    } catch (err) {
      console.log(err);
    }
  };

  const tip = useMemo(() => tips[currentTipIndex] || DEFAULT_TIP, [tips, currentTipIndex]);

  const setNextTip = useCallback(() => {
    setCurrentTipIndex(currentIndex => currentIndex + 1 === tips?.length ? 0 : currentIndex + 1);
  }, [tips]);

  return {
    tip,
    fetchTips,
    setNextTip,
  };
};

export default useTips;
