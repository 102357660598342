import React from 'react';
import PropTypes from 'prop-types';

import RelatedCard from './RelatedCard/RelatedCard';
import './styles.scss';
import Constants from '../../../../constants/constants';
import GuidanceTip from '../../../shared_v2/GuidanceTip/GuidanceTip';

const RelatedRecords = ({
  predefinedRelations,
  selectedDataExtensions,
  handleSetSelectionState,
  DEBorderMouseOver,
  relatedDataExtensions,
}) => {
  /**
   * Returns an array with predefinedRelations for the selected Data Extension
   * @param {string} ObjectID - ObjectID of the selected Data Extension
   * @returns {array} array with predefinedRelations for the selected Data Extension
   */
  const predefinedRelationsForCustomerKey = ObjectID => predefinedRelations
    .filter(predefinedRelation => (predefinedRelation.toDEObjectId === ObjectID &&
      predefinedRelation.relation === Constants.RELATION__TYPE__ONE_TO_ONE) ||
      (predefinedRelation.fromDEObjectId === ObjectID));

  return (
    selectedDataExtensions?.length && relatedDataExtensions?.length ?
      (
        <>
          <hr id="related-records-hr" />
          <div className="available-extension-v2 related-records-container">
            <div className="stickyAE">
              <h4 className="section-title">
                Related Records
                <GuidanceTip tipId="related-records-tip" />
              </h4>
              <div className="make-space-for-scroll related-records-scroll">
                <div
                  id="related-collections"
                  className="available-extension-v2_available_collections"
                >
                  {relatedDataExtensions.map((dataExtension, index) => (
                    <RelatedCard
                      relatedDataExtensionsCount={relatedDataExtensions.length}
                      key={index}
                      predefinedRelations={predefinedRelationsForCustomerKey(dataExtension.ObjectID)}
                      dataExtension={dataExtension}
                      handleSetSelectionState={handleSetSelectionState}
                      DEBorderMouseOver={DEBorderMouseOver}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      ) :
      (
        null
      )
  );
};

RelatedRecords.propTypes = {
  /**
   * An array containing relations that were defined for Data Extensions
   */
  predefinedRelations: PropTypes.instanceOf(Array).isRequired,
  /**
   * An array containing all selected Data Extensions
   */
  selectedDataExtensions: PropTypes.instanceOf(Array).isRequired,
  /**
   * It helps to set the Selection`s state
   * It will be passed from Selection.js
   */
  handleSetSelectionState: PropTypes.func.isRequired,
  /*
   * Keeps track whether related DE is dragged
   */
  DEBorderMouseOver: PropTypes.bool.isRequired,
};

export default RelatedRecords;
