import axios from 'axios';

import Util from '../util';

const apiUrl = process.env.REACT_APP_API_URL;

const GuidanceTipsAPI = {
  /**
   * Get all Guidance tips
   * @param {object} cancelToken - Token axios
   * @returns {Array} Guidance Tips List defined from the Admin App
   */
  getGuidanceTips: async (cancelToken) => {
    const { data: { data } } = await Util.catch418And403Error(
      axios.get,
      `${apiUrl}/guidance-tips`,
      Util.apiGetCallSettings(cancelToken),
    );

    return data;
  },
};

export default GuidanceTipsAPI;
