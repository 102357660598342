const TransformDateUtil = {
  changeAliases: (values, oldName, newName) => values?.forEach(value => (
    TransformDateUtil.changeAlias(value, oldName, newName)
  )),

  changeAlias: (value, oldName, newName) => {
    if (value.collectionAlias.toLowerCase() === oldName.toLowerCase()) {
      // eslint-disable-next-line no-param-reassign
      value.collectionAlias = newName;
    }
  },

  isValid: customValue => !!customValue?.fieldObjectId,
};

export default TransformDateUtil;
