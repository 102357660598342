import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  connect,
  shallowEqual, useDispatch, useSelector,
} from 'react-redux';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';

import store from '../../redux/store/store';
import StepsView from '../../components/WaterfallSelection/StepsView/StepsView';
import NavigationBar from '../../components/WaterfallSelection/NavigationBar/NavigationBar';
import ScheduleSelectionModal from '../../components/Selection/ScheduleSelectionModal/ScheduleSelectionModal';
import { loadingFolders, setAllSelectionFolders } from
  '../../redux/actions/waterfallSelection/folderActions';
import {
  getMultipleSelectionsById, loadingSelections, setAllSelections, setSelectionChain,
  startLoadingSelectionId,
  handleMissingMatchedField,
  handleMissingTargetDataExtension,
  setSelectedSelections,
  setSelectedSelectionData,
  stopLoadingSelectionId,
  getSelectionsSchedules,
  setDataAfterDroppingSelection,
} from
  '../../redux/actions/waterfallSelection/selectionActions';
import './styles.scss';
import Constants from '../../constants/constants';
import RunDetails from '../../components/WaterfallSelection/RunDetails/RunDetails';
import {
  clearWaterfallState, createWaterfallCopy, fetchSelectionRunStatus,
  hideScheduledSelectionModal,
  setScheduledRun,
  loadingWaterfallSelection, setRunStatusForSelectionChain,
  setSelectionId, setSelectionName, setWaterfallSelectionStatus, setWaterfallSelectionCompletedDate,
  saveWaterfallSelection, setRunningQueryStatus, validateSelectionName, setSelectionRequestDone,
  setHasScheduleBeenEnabled,
} from '../../redux/actions/waterfallSelection/globalActions';
import WaterfallSelectionsAPI from '../../api/waterfallSelections';
import { setErrors, setRunError } from '../../redux/actions/waterfallSelection/errorActions';
import { getMultipleTargetDataExtensions, startLoadingTargetDEObjectID } from
  '../../redux/actions/waterfallSelection/targetDataExtensionActions';
import Util from '../../util';
import waterfallSelectionUtil from '../../utils/waterfallSelection/waterfallSelectionUtil';
import LoadingModal from '../../components/shared/LoadingModal/LoadingModal';
import SwalUtil from '../../utils/swal/swalUtil';
import timeUtil from '../../utils/time/timeUtil';
import SelectionNameModal from '../../components/Selection/SelectionNameModal/SelectionNameModal';
import LoadingText from '../../components/shared/LoadingText/LoadingText';
import mapStateToProps from '../../mapStateToProps';

const WaterfallSelection = ({
  handleNavigator,
  getNestedSelectionFolders,
  waterfallFolders,
  folderId,
  handleSetAppState,
  currentSelectionId,
  folderSettings,
  currentSelectionName,
  addSelectionToWFSelectedSelections,
  waterfallSelections,
  userInfo,
}) => {
  const dispatch = useDispatch();
  const axiosCancelToken = axios.CancelToken.source();

  const [showSaveToast, setShowSaveToast] = useState(false);
  const [settingFetchedData, setSettingFetchedData] = useState(null);

  // indicates when selection is saved
  const [savingSelection, setSavingSelection] = useState(false);

  // indicates when run button is clicked
  const [runClicked, setRunClicked] = useState(false);

  const [isArchived, setIsArchived] = useState(false);

  // get state of properties from reducers
  const {
    error, selectedView, loadingSelection,
    runningQuery, waterfallSelectionStatus, waterfallSelectionCompletedDate,
    selectionId, allSelections, loadingSelectionsStatus,
    loadingFoldersStatus, scheduledRun, showScheduleSelectionModal, selectedSelections,
    hasScheduleBeenEnabled, isSelectionRequestDone, selectionName,
    selectionsSchedules,
  } =
  useSelector(({
    errorReducer, globalReducer, selectionReducer, folderReducer,
  }) => ({
    error: errorReducer.error,
    selectedView: globalReducer.selectedView,
    allSelections: selectionReducer.allSelections,
    loadingSelectionsStatus: selectionReducer.loadingSelections,
    showScheduleSelectionModal: globalReducer.showScheduleSelectionModal,
    scheduledRun: globalReducer.scheduledRun,
    loadingFoldersStatus: folderReducer.loadingFolders,
    loadingSelection: globalReducer.loadingWaterfallSelection,
    runningQuery: globalReducer.runningQuery,
    waterfallSelectionStatus: globalReducer.waterfallSelectionStatus,
    waterfallSelectionCompletedDate: globalReducer.waterfallSelectionCompletedDate,
    selectionId: globalReducer.currentSelectionId,
    selectedSelections: selectionReducer.selectedSelections,
    isSelectionRequestDone: globalReducer.isSelectionRequestDone,
    selectionName: globalReducer.selectionName,
    selectionsSchedules: selectionReducer.selectionsSchedules,
    hasScheduleBeenEnabled: globalReducer.hasScheduleBeenEnabled,
  }), shallowEqual);

  /**
   * Function that add a selection to waterFall selected selections
   * @returns {void}
   */
  const addSelectionToWaterFall = async () => {
    // get updated store state
    const currentState = store.getState();
    const { runStatusForSelectionChain } = currentState.globalReducer;
    const { selectedSelections, allSelections, loadingSelectionIds } = currentState.selectionReducer;
    const { targetDataExtensions, loadingTargetDEObjectIDs } = currentState.targetDataExtensionReducer;

    if(addSelectionToWFSelectedSelections?.selectionId) {
      // find recently created selection index
      const createdSelectionIndex = allSelections
        .findIndex(sel => sel._id === addSelectionToWFSelectedSelections.selectionId);

      if(createdSelectionIndex >= 0) {
        // prepare input data
        const inputData = {
          selectedSelections,
          allSelections,
          loadingSelectionIds,
          axiosCancelToken,
          targetDataExtensions,
          loadingTargetDEObjectIDs,
          runStatusForSelectionChain,
        };

        const source = { index: createdSelectionIndex, droppableId: Constants.DROPPABLE__CONTAINER_ID__1 };
        const destination = { droppableId: Constants.DROPPABLE__CONTAINER_ID__2, index: selectedSelections.length };

        dispatch(setDataAfterDroppingSelection(source, destination, inputData));

        // reset addSelectionToWFSelectedSelections object
        handleSetAppState({
          addSelectionToWFSelectedSelections: null,
        });
      }
    }
  };

  /**
   * Function that fetch saved waterfall selection data
   * @returns {void}
   */
  const fetchSelectionData = async () => {
    // start fetching waterfall selection
    dispatch(loadingWaterfallSelection(true));

    try {
      // get selection data
      const waterfallSelection = await WaterfallSelectionsAPI.getWaterfallSelection(
        currentSelectionId,
        axiosCancelToken.token,
      );

      if (waterfallSelection) {
        // stop loading waterfall selection
        dispatch(loadingWaterfallSelection(false));

        // start setting fetched data
        setSettingFetchedData(true);

        // set current selection id in redux state
        dispatch(setSelectionId(currentSelectionId));

        // set selection name
        dispatch(setSelectionName(waterfallSelection.name));

        // Set scheduledRun state
        dispatch(setScheduledRun(waterfallSelection.scheduledRun));

        setIsArchived(waterfallSelection.isArchived);

        const chosenSelections = [];
        const removedSelections = [];

        // define array with ids of selections used in selectionChain as selected selections
        const selectionChainIds = waterfallSelection.selectionChain.map(selection => selection.selectionId);

        // start fetching selections
        dispatch(startLoadingSelectionId(selectionChainIds));

        // fetch selections that appear in selectionChain
        const selectionsInChainResponse = await dispatch(getMultipleSelectionsById(
          selectionChainIds,
          axiosCancelToken.token,
          true,
        ));

        const { selections } = selectionsInChainResponse || {};

        // get chosen and deleted selections
        waterfallSelection.selectionChain?.forEach((segmentation) => {
          const selectionObject = selections?.find((
            selection => selection._id === segmentation.selectionId));

          // if selection has been found
          if (selectionObject) {
            // set unique id for selections that are in selections container
            chosenSelections.push({
              ...selectionObject,
              _id: Util.uuid(32),
              selectionId: selectionObject._id,
            });
          } else {
            // if no selection found, add it to an array with deleted selections
            removedSelections.push(segmentation);
          }
        });

        if (removedSelections?.length) {
          // return proper message depending on how many selections were not found
          const message = removedSelections?.length === 1 ?
            'Selected selection used in waterfall selection was not found and has been deleted.' :
            // eslint-disable-next-line max-len
            `${removedSelections.length} selected selections used in waterfall selection were not found and have been deleted. `;

          // show swal message
          await SwalUtil.fire({
            type: Constants.SWAL__TYPE__ERROR,
            message,
            options: {
              confirmButtonText: 'OK',
              allowOutsideClick: false,
            },
          });
        }
        // set selected selections based on selection chain
        dispatch(setSelectedSelections(chosenSelections));

        // set selection chain in state
        dispatch(setSelectionChain(waterfallSelection.selectionChain));

        // set run status for selection chain
        dispatch(setRunStatusForSelectionChain(waterfallSelection.selectionChainStatus));

        // set overall status of waterfall selection
        dispatch(setWaterfallSelectionStatus(waterfallSelection.waterfallSelectionStatus));

        // set waterfall selection completed date
        dispatch(setWaterfallSelectionCompletedDate(waterfallSelection.waterfallSelectionCompletedDate));

        // set hasScheduleBeenEnabled state based on enabled property
        dispatch(setHasScheduleBeenEnabled(waterfallSelection.scheduledRun.enabled));

        if (waterfallSelection.waterfallSelectionStatus === Constants.STATUS_ERROR &&
          waterfallSelection.waterfallSelectionError) {
          // set run error when exists
          dispatch(setRunError(waterfallSelection.waterfallSelectionError));
        }

        // define post data for fetching targetDEs
        const { targetDEsPostData, targetDEObjectIDs } =
          selections?.reduce(({ targetDEsPostData, targetDEObjectIDs }, selection) => {
            targetDEsPostData?.push({
              collectionCustomerKey: selection.targetCollectionCustomerKey,
              collectionObjectID: selection.targetCollectionObjectID,
            });

            targetDEObjectIDs?.push(selection.targetCollectionObjectID);

            return { targetDEsPostData, targetDEObjectIDs };
          }, { targetDEsPostData: [], targetDEObjectIDs: [] }) || {};

        let targetDEsInSelectedSelections;

        if (targetDEObjectIDs?.length) {
          // set loading indicator and fetch Target Data Extensions used in chosen selections
          dispatch(startLoadingTargetDEObjectID(targetDEObjectIDs));

          targetDEsInSelectedSelections = await dispatch(getMultipleTargetDataExtensions(
            targetDEsPostData,
            axiosCancelToken.token,
          ));
        }

        // set selected selection data in redux state
        dispatch(setSelectedSelectionData(selections));

        // stop loading selection
        dispatch(stopLoadingSelectionId(selectionChainIds));

        // create copy of selection
        dispatch(createWaterfallCopy());

        // Get the selections schedules from the backend
        await dispatch(getSelectionsSchedules(targetDEObjectIDs, axiosCancelToken.token));

        // stop setting fetched data
        setSettingFetchedData(false);

        if (selectionsInChainResponse && targetDEsInSelectedSelections) {
          let selectionsIdsWithMissingTargetDE = [];

          // if one of target DE does not exists
          if (targetDEsInSelectedSelections?.missingObjectIDs) {
            const { missingObjectIDs } = targetDEsInSelectedSelections;

            // show message and remove selected selections using missing targetCollectionObjectID
            selectionsIdsWithMissingTargetDE = await dispatch(handleMissingTargetDataExtension(
              missingObjectIDs,
              chosenSelections,
            ));
          }

          // if there is a selection which does not have a matched field
          if (selectionsInChainResponse?.selectionWithoutMatchedField && selections) {
            const { selectionWithoutMatchedField } = selectionsInChainResponse;

            // get selection objects from selections chain in run detail
            const selectionsInRunChain = waterfallSelectionUtil.getSelectionsData(
              waterfallSelection.selectionChainStatus,
              chosenSelections,
            );

            // take only selections that will not be deleted
            const validSelections = selections.filter((selection) => {
              // selections without matched fields
              const removedSelectionWithoutMatchedField = selectionWithoutMatchedField.find(
                s => s._id === selection._id,
              );

              // selections with missing targetDE
              const removedSelectionWithoutTargetDE = selectionsIdsWithMissingTargetDE.find(
                id => id === selection._id,
              );

              if (!removedSelectionWithoutMatchedField && !removedSelectionWithoutTargetDE) { return selection; }

              return null;
            });

            // get targetDE to remove
            const targetDEObjectIDsToRemove = waterfallSelectionUtil
              .targetDataExtensionsToRemoved(selectionWithoutMatchedField, validSelections, selectionsInRunChain);

            // show message and remove selections that do not have a matched field
            await dispatch(handleMissingMatchedField(
              selectionWithoutMatchedField,
              selectionsIdsWithMissingTargetDE,
              false,
              targetDEObjectIDsToRemove,
            ));
          }
        }

        addSelectionToWaterFall();
      }
    } catch (err) {
      if (!axios.isCancel(err)) dispatch(setErrors(err));
      dispatch(loadingWaterfallSelection(false));
    }
  };

  useEffect(() => {
    // dispatch action to set loading status for selections and folders
    dispatch(loadingSelections(true));
    dispatch(loadingFolders(true));

    dispatch(setAllSelections(axiosCancelToken.token));
    dispatch(setAllSelectionFolders(axiosCancelToken.token));

    // if user opens existing waterfall selection
    if (currentSelectionId !== '' && currentSelectionId) {
      // fetch and sets data from waterfall selection
      fetchSelectionData();
    }

    // when component is unmounted
    return () => {
      // cancel axios token
      axiosCancelToken.cancel();

      // clear the waterfall state
      dispatch(clearWaterfallState());

      // clear the app state
      handleSetAppState({ currentSelectionId: '' });

      // clear intervals
      clearInterval();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // after fetching necessary data
    if (allSelections?.length && !loadingSelectionsStatus && !loadingFoldersStatus) {
      // create copy of waterfall selection
      dispatch(createWaterfallCopy());
    }
  }, [loadingSelectionsStatus, loadingFoldersStatus, allSelections?.length, dispatch]);

  // set interval if selection is still running
  useEffect(() => {
    let intervalAPICall;

    if (waterfallSelectionUtil.isWaterfallSelectionRunning(waterfallSelectionStatus)) {
      intervalAPICall = setInterval(async () => {
        // get run status of selection chain
        const status = dispatch(fetchSelectionRunStatus(selectionId, axiosCancelToken.token));

        // when the process is complete
        if (status?.waterfallSelectionStatus === Constants.STATUS_COMPLETE) {
          // clear interval
          clearInterval(intervalAPICall);
        }
      }, 3000);
    }

    // when component is unmounted
    return () => {
      // clear interval
      clearInterval(intervalAPICall);
    };
    // eslint-disable-next-line
  }, [runningQuery, waterfallSelectionStatus]);

  // handle errors
  useEffect(() => {
    if (error) throw error;
  }, [error]);

  // dynamically set the enabled status for schedule run depending on the selected selections
  useEffect(() => {
    if (selectedSelections?.length && scheduledRun.enabled !== hasScheduleBeenEnabled && settingFetchedData === false) {
      // schedule run has been turned off and selections are selected - set the previously selected enabled option
      dispatch(setScheduledRun({ ...scheduledRun, enabled: hasScheduleBeenEnabled }));
    } else if (!selectedSelections?.length && scheduledRun.enabled) {
      // there are no selections and schedule run in enabled
      dispatch(setScheduledRun({ ...scheduledRun, enabled: false }));
    }

  // eslint-disable-next-line
  }, [selectedSelections, settingFetchedData]);

  useEffect(() => {
    const targetDEObjectIDs = selectedSelections?.map(selection => (selection.targetCollectionObjectID));

    // Get the selections schedules from the backend
    if (targetDEObjectIDs?.length) dispatch(getSelectionsSchedules(targetDEObjectIDs, axiosCancelToken.token));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSelections]);

  /**
   * Checks if the schedule is valid
   * @returns {boolean} true if valid, false otherwise
   */
  const checkValidSchedule = () => {
    /*
     * If schedule is set to run once, make sure that the set date and time have not passed
     */
    let isScheduleValid = true;

    // Check if scheduling is enabled, if so, check if mode is run once
    if (scheduledRun?.enabled && scheduledRun?.mode === Constants.SCHEDULE_SELECTION__MODE__ONCE) {
      const { runOnce, timezone } = scheduledRun;

      // Make sure date time has not passed, if so, set schedule as invalid
      if (timeUtil.hasPassed(runOnce, timezone, true)) isScheduleValid = false;
    }

    return isScheduleValid;
  };

  /**
   * Function that handles saving waterfall selection
   * @param {boolean} isRunClicked - defines whether the run button was clicked
   * @returns {string|null} selection Id or null if the selection cannot be saved
   */
  const handleSelectionSave = async (isRunClicked) => {
    let message;

    let title;

    let shouldSave = true;

    // when the run is not clicked and selection has ID
    if (!isRunClicked && selectionId) { setSavingSelection(true); }

    let validateIfSelectedSelectionsExists = true;

    if (isRunClicked) {
      // check if at least one selection is selected
      validateIfSelectedSelectionsExists = !!selectedSelections?.length;
    }

    // check if selection has a name
    const validateIfSelectionHasName = !!selectionName && selectionName?.toString().trim() !== '';

    // Check if schedule is valid
    const isScheduleValid = checkValidSchedule();

    if (validateIfSelectedSelectionsExists && validateIfSelectionHasName && isScheduleValid) {
      const newSelectionName = selectionName?.toString().trim();

      // check if selection is valid and has no duplicate name
      const validSelectionName = await dispatch(validateSelectionName({
        newSelectionName,
        currentName: selectionName,
        selectionId,
        isSelectionRequestDone,
        cancelToken: axiosCancelToken.token,
      }));

      if (waterfallSelectionStatus === Constants.STATUS_RETRY) {
        const retryDate = moment(waterfallSelectionCompletedDate)
          .add(15, 'minutes')
          .format(Util.getUserDateTimeFormat(userInfo));

        // show warning of unsaved changes
        shouldSave = (await SwalUtil.fire({
          title: 'Confirmation needed',
          message: `Please note that this selection is scheduled to retry at ${retryDate}.
          ${isRunClicked ?
            `Manually running this selection now will cancel the retry, however any other schedules
            will not be impacted.` :
            `Any changes to this selection will cancel the retry.
             You can run this selection manually or wait for the next scheduled run to execute.`
          }`,
          options: {
            confirmButtonText: 'OK',
            showCancelButton: true,
            allowOutsideClick: false,
          },
        }))?.value;
      }

      if(!validSelectionName || !shouldSave) {
        setRunClicked(false);
        setSavingSelection(false);

        return null;
      }

      // create new selectionChain and save it in redux state
      const newSelectionChain = selectedSelections.map((selection, index) => (
        {
          selectionId: selection.selectionId,
          order: index + 1,
        }
      ));

      dispatch(setSelectionChain(newSelectionChain));

      // if selection is valid, then get proper data from the state
      const selectionPostData = {
        name: validSelectionName,
        selectionChain: newSelectionChain,
        folderId,
        scheduledRun,
      };

      // save the selection and return selectionId
      const selectionIdResponse = await dispatch(saveWaterfallSelection(
        selectionPostData,
        selectionId,
        axiosCancelToken.token,
      ));

      const selectionChainIds = newSelectionChain.map(sel => sel.selectionId);

      // fetch selections that appear in selectionChain
      const selectionsInChainResponse = await dispatch(getMultipleSelectionsById(
        selectionChainIds,
        axiosCancelToken.token,
        true,
      ));

      const { selections } = selectionsInChainResponse;

      // get selected selections ids and its targetDE Object IDs
      const targetDEObjectIDs = selections.map(selection => (selection.targetCollectionObjectID));

      // Get the selections schedules from the backend
      await dispatch(getSelectionsSchedules(targetDEObjectIDs, axiosCancelToken.token));

      // create waterfall copy when save is clicked and selection has ID
      if (!isRunClicked && selectionId) { dispatch(createWaterfallCopy()); }

      // do not show toast message when run button is clicked or selection name is saved before opening
      if (isRunClicked || !selectionId) {
        return selectionIdResponse;
      } if (selectionIdResponse) {
        setSavingSelection(false);
        setShowSaveToast(true);

        // show toast with message information
        toast.success(
          <div className="slds-notify slds-notify_toast slds-theme_success">
            <span className="slds-assistive-text">success</span>
            <span
              className="slds-icon_container slds-icon-utility-success
                        slds-m-right_small slds-no-flex slds-align-top"
              title="Description of icon when needed"
            >
              <svg className="slds-icon slds-icon_small" aria-hidden="true">
                <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#success" />
              </svg>
            </span>
            <span>
              {Constants.NOTIFICATION__TEXT__SAVED}
            </span>
          </div>,
          {
            position: toast.POSITION.TOP_RIGHT,
            className: 'toast-saved',
            toastId: Constants.NOTIFICATION__TOAST_ID__SAVE,
            hideProgressBar: true,
            autoClose: 2000,
            onClose: () => setShowSaveToast(false),
            containerId: Constants.NOTIFICATION__CONTAINER_ID__SELECTION,
            draggable: false,
            pauseOnHover: false,
            pauseOnFocusLoss: false,
          },
        );

        return null;
      }
    }

    // stop running the query
    dispatch(setRunningQueryStatus(false));

    // message when selection has no selected selections
    if (!validateIfSelectedSelectionsExists) {
      title = 'Oops...';
      message = 'Please add at least 1 Selected Selection.';
    } else if (!validateIfSelectionHasName) {
      // message when selection has no name
      title = 'Name';
      message = 'Please enter a name for the waterfall selection.';
    } else if (!isScheduleValid) {
      title = 'Oops...';

      // eslint-disable-next-line max-len
      message = 'The scheduling settings are not valid.<br>Make sure the selected date and time have not passed.<br>Please fix this before continuing.';
    }

    if (!isRunClicked) { setSavingSelection(false); }

    // show validation message
    if (title && message) {
      SwalUtil.fire({
        type: Constants.SWAL__TYPE__ERROR,
        title,
        message,
        options: {
          confirmButtonText: 'OK',
          allowOutsideClick: false,
        },
      });
    }

    // set runClicked on false
    setRunClicked(false);

    return null;
  };

  // while entering in New Waterfall Selection show the modal to put new name and folderId
  if (!isSelectionRequestDone && !currentSelectionId) {
    return (
      <SelectionNameModal
        folders={waterfallFolders}
        handleSetAppState={handleSetAppState}
        currentSelectionId={currentSelectionId}
        axiosCancelToken={axiosCancelToken}
        folderId={folderId}
        handleSetSelectionName={e => dispatch(setSelectionName(e.target.value))}
        selectionName={selectionName}
        saveSelection={handleSelectionSave}
        handleNavigator={handleNavigator}
        isWaterfall
        cloneSelectionState={() => dispatch(createWaterfallCopy())}
        handleSetSelectionRequestDone={() => dispatch(setSelectionRequestDone())}
      />
    );
  }

  // if selections are loading, show loading modal
  if(loadingSelection && !isSelectionRequestDone) {
    return (
      <LoadingModal
        closeModal={() => handleNavigator(Constants.NAVIGATION__OVERVIEW)}
        id="DE-loadingmodal"
      >
        <LoadingText
          firstPhrase="Your Waterfall Selection"
          boldText={selectionName || currentSelectionName}
        />
      </LoadingModal>
    );
  }

  return (
    <div className="waterfall-selection">
    <NavigationBar
      waterfallFolders={waterfallFolders}
      folderId={folderId}
      handleSetAppState={handleSetAppState}
      currentSelectionId={currentSelectionId}
      handleNavigator={handleNavigator}
      axiosCancelToken={axiosCancelToken}
      setShowSaveToast={setShowSaveToast}
      showSaveToast={showSaveToast}
      checkValidSchedule={checkValidSchedule}
      disableScheduleButton={!selectedSelections?.length}
      savingSelection={savingSelection}
      setSavingSelection={setSavingSelection}
      runClicked={runClicked}
      setRunClicked={setRunClicked}
      handleSelectionSave={handleSelectionSave}
      isArchived={isArchived}
    />
    <ToastContainer
      enableMultiContainer
      containerId={Constants.NOTIFICATION__CONTAINER_ID__SELECTION}
      limit={1}
    />
    {showScheduleSelectionModal && (
    <ScheduleSelectionModal
      isWaterfallSelection
      handleCloseScheduleWaterfallSelection={() => dispatch(hideScheduledSelectionModal())}
      handleSetScheduledRunState={scheduledRunData => dispatch(setScheduledRun(scheduledRunData))}
      scheduledRun={scheduledRun}
      handleSetHasScheduleBeenEnabled={enabled => dispatch(setHasScheduleBeenEnabled(enabled))}
      selectionsSchedules={selectionsSchedules}
      selectedSelections={selectedSelections}
      waterfallSelections={waterfallSelections}
      currentSelectionId={currentSelectionId}
      currentSelectionName={currentSelectionName}
    />
    )}
    {selectedView === Constants.NAVIGATION__STEPS ?
      (
        <StepsView
          getNestedSelectionFolders={getNestedSelectionFolders}
          axiosCancelToken={axiosCancelToken}
          handleSetAppState={handleSetAppState}
          currentSelectionId={currentSelectionId}
          folderId={folderId}
          folderSettings={folderSettings}
          currentSelectionName={selectionName || currentSelectionName}
          addSelectionToWFSelectedSelections={addSelectionToWFSelectedSelections}
          isArchived={isArchived}
        />
      ) :
        <RunDetails
          axiosCancelToken={axiosCancelToken}
          isArchived={isArchived}
        />}
    </div>
  );
};

WaterfallSelection.propTypes = {
  /**
   * connect parent+children folders
   */
  getNestedSelectionFolders: PropTypes.func.isRequired,
  /*
   * it helps to navigate between Overview and Waterfall Selection
   */
  handleNavigator: PropTypes.func.isRequired,
  /*
   * array with all folders for waterfall selections
   */
  waterfallFolders: PropTypes.instanceOf(Array).isRequired,
  /*
   * helps to set state in app.js
   */
  handleSetAppState: PropTypes.func.isRequired,
  /*
   * id of selected folder
   */
  folderId: PropTypes.string.isRequired,
  /*
   * the id of the currently open selection
   */
  currentSelectionId: PropTypes.string.isRequired,
  /*
   * the name of the currently open selection
   */
  currentSelectionName: PropTypes.string.isRequired,
  /**
   * An array of all waterfall selections
   */
  waterfallSelections: PropTypes.instanceOf(Array),
  /**
   * Object with a saved folder settings
   */
  folderSettings: PropTypes.instanceOf(Object),
  /**
   * add a selection to WF selectedSelections list, null if we do not
   */
  addSelectionToWFSelectedSelections: PropTypes.instanceOf(Object),
  /**
   * User info from cookie
   */
  userInfo: PropTypes.object,
};

export default connect(mapStateToProps(['userInfo']), null, null, { pure: false })(WaterfallSelection);
