import React, { useEffect, useState } from 'react';
import {
  connect, shallowEqual, useDispatch, useSelector,
} from 'react-redux';
import classNames from 'classnames';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

import mapStateToProps from '../../../../mapStateToProps';
import {
  setDataExtensions,
  setPredefinedRelations,
  setPredefinedRelationsMap,
} from '../../../../redux/actions/dataSets/globalActions';
import {
  setFilterSetName,
  clearFilterSetState,
  setFilterSetSourceType,
  setFilterSetDescription,
  setFilterSetSelectedDEs,
  setFilterSetSelectedDataSet,
  setFilterSetSelectedSourceDE,
  setFilterSetSelectedFilters,
  setFilterSetPickLists,
  setFilterSetCategoryName,
} from '../../../../redux/actions/filterSets/globalActions';
import DataExtensionsAPI from '../../../../api/data-extensions';
import PicklistsAPI from '../../../../api/picklists';
import RelationsAPI from '../../../../api/relations';
import Util from '../../../../util';
import filtersUtil from '../../../../utils/filters/filtersUtil';
import filterSetsUtil from '../../../../utils/filterSets/filterSets';
import Constants from '../../../../constants/constants';
import LoadingModal from '../../../shared/LoadingModal/LoadingModal';
import Features from '../../../../features';
import Tooltip from '../../../shared/Tooltip/Tooltip';
import Button from '../../../shared/Button/Button';
import RadioButton from '../../../shared/RadioButton/RadioButton';
import EditFiltersModal from '../EditFiltersModal/EditFiltersModal';
import Alert from '../../../shared/Alert/Alert';
// eslint-disable-next-line
import FilterSetsAPI from '../../../../api/filter-sets';
import DataViewsAPI from '../../../../api/data-views';
// Custom hooks
import { useAvailableDataExtensions } from '../../../../utils/hooks/availableDataExtensions';
import './styles.scss';
import FilterSetsCategoryAPI from '../../../../api/filter-sets-category';

const FilterSetsPanel = ({
  filterSetId,
  openPanel,
  selectedFilterSet,
  dataSets,
  activePanel,
  filterSets,
  featuresInfo,
}) => {
  const dispatch = useDispatch();
  const axiosCancelToken = axios.CancelToken.source();
  const featureDataViews = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__DATA_VIEWS);
  const featureDataSets = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__DATA_SETS);

  const [loading, setLoading] = useState({ dataSets: true, dataExtensions: true });
  const [savingFilterSet, setSavingFilterSet] = useState(false);
  const [nameFilterSetError, setNameFilterSetError] = useState(false);
  const [selectedDataSourceLoading, setSelectedDataSourceLoading] = useState(false);
  const [showEditFiltersModal, setShowEditFiltersModal] = useState(false);
  const [filterSetsCategories, setFilterSetsCategories] = useState([]);

  // Load available data extensions from custom hook
  const [availableDataExtensions] = useAvailableDataExtensions((status) => {
    setLoading(prevState => ({ ...prevState, dataExtensions: status }));
  });

  useEffect(() => {
    const fetchPickList = async () => {
      const pickListsInitialState = await PicklistsAPI.getPicklists(
        axiosCancelToken.token,
      );

      dispatch(setFilterSetPickLists(pickListsInitialState));
    };

    fetchPickList();
    // get filter sets and update filter sets state
    const getAllFilterSetCategories = async () => {
      const filterSets = await FilterSetsCategoryAPI.getFilterSetsCategories(axiosCancelToken.token);

      setFilterSetsCategories(filterSets);
    };

    getAllFilterSetCategories();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // get state of properties from reducers
  const {
    filterSetName,
    filterSetCategory,
    filterSetSourceType,
    filterSetDescription,
    filterSetSelectedDEs,
    filterSetSelectedDataSet,
    filterSetSelectedSourceDE,
    filterSetSelectedFilters,
    filterSetPickLists,
    dataExtensions,
    predefinedRelations,
  } =
  useSelector(({
    dataSetsGlobalReducer,
    filterSetsGlobalReducer,
  }) => ({
    // From filter set reducer
    filterSetName: filterSetsGlobalReducer.filterSetName,
    filterSetCategory: filterSetsGlobalReducer.filterSetCategory,
    filterSetSourceType: filterSetsGlobalReducer.filterSetSourceType,
    filterSetDescription: filterSetsGlobalReducer.filterSetDescription,
    filterSetSelectedDEs: filterSetsGlobalReducer.filterSetSelectedDEs,
    filterSetSelectedDataSet: filterSetsGlobalReducer.filterSetSelectedDataSet,
    filterSetSelectedSourceDE: filterSetsGlobalReducer.filterSetSelectedSourceDE,
    filterSetSelectedFilters: filterSetsGlobalReducer.filterSetSelectedFilters,
    filterSetPickLists: filterSetsGlobalReducer.filterSetPickLists,

    // From data set reducer
    dataExtensions: dataSetsGlobalReducer.dataExtensions,
    predefinedRelations: dataSetsGlobalReducer.predefinedRelations,
  }), shallowEqual);

  // Source label
  const filterSetSourceTypeLabel = filterSetSourceType === 'dataSet' ? 'Data Set' : 'Data Extension';

  // Source value in dropdown
  const filterSetSelectedSourceValue = filterSetSourceType === 'dataSet' ?
    filterSetSelectedDataSet :
    filterSetSelectedSourceDE;

  // Boolean that helps to know if edit is allowed or not
  const editIsDisabled = filterSetSelectedSourceValue &&
    (filterSetSelectedDEs?.length ||
    selectedDataSourceLoading);

  /**
   * Show a swal to the user indicating the feature is not enabled, and bring user back to the overview screen
   * @param {string} feature - Name of feature
   * @returns {void}
   */
  const handleFeatureMissing = async (feature) => {
    axiosCancelToken.cancel(`Missing feature: ${feature}.`);

    await filterSetsUtil.throwSwal(
      {
        typeOfSwal: 'error',
        titleOfSwal: 'Oops...',
        message: `You do not have the feature for <b class="bold_swal">${feature}</b> enabled to open this Filter Set.`,
      },
    );

    await openPanel(Constants.ADMIN_PANEL__MENU__FILTER_SETS);
  };

  /**
   * Helps to retrieve fields of a data extension or data view from SFMC
   * @param {object[]} selectedCollection - Selected collection
   * @param {boolean} isComparedField - Determined if we are calling this function for compared fields
   * @returns {Array} array of data extension fields
   */
  const getDataExtensionOrDataViewFields = async (selectedCollection) => {
    /**
     * Check if selected DE has CategoryID
     * If it has then move on to find DE
     * If it doesn't have then move on to find Data Views
     */
    if (selectedCollection && !selectedCollection.CategoryID) {
      /**
       * If feature 'dataViews' is 'true' get data views fields
       * Else show error message
       */
      if (featureDataViews) {
        try {
          // eslint-disable-next-line no-param-reassign, require-atomic-updates
          selectedCollection.fields = await DataViewsAPI.getDataViewFields(
            selectedCollection.CustomerKey.toString(),
            axiosCancelToken.token,
          );
        } catch (error) {
          if (!axios.isCancel(error)) {
            // show swal error if request failed with error
            filterSetsUtil.throwSwal({
              typeOfSwal: 'error',
              titleOfSwal: 'Error',
              message: error,
            });
          }
        }
      } else {
        await handleFeatureMissing(Constants.FEATURE__DATA_VIEWS_LABEL);

        /**
         * I am returning null so we can differentiate data views feature is disabled and
         * Data view is deleted
         */
        return null;
      }

      // Prepare fields so you can use it properly
      filterSetsUtil.prepareDataViewsFields(selectedCollection);
      // Sort data view fields by Name
      Util.sortArrayOfObjects(selectedCollection.fields, 'Name');
    } else {
      // To get data extension fields
      let customerKeysToGet;

      if (dataExtensions?.length) {
        // find customerKey from collection in dataExtensions
        customerKeysToGet = dataExtensions.filter(de => de.CustomerKey &&
           de.CustomerKey === selectedCollection.CustomerKey);
      }

      // prevent from errors, check if fields have data
      if (customerKeysToGet?.length && customerKeysToGet[0].fields) {
        // make a delay to avoid errors
        const timeout = n => new Promise(cb => setTimeout(cb, n));

        await timeout(100);

        // assign fields from the fetched fields
        // eslint-disable-next-line require-atomic-updates, no-param-reassign
        selectedCollection.fields = customerKeysToGet[0].fields;
      } else {
        try {
          // if it doesn't find the fields, get new ones
          const fields = await DataExtensionsAPI.getDataExtensionFields(
            selectedCollection.CustomerKey,
            axiosCancelToken.token,
          );

          if (fields?.data) {
            // eslint-disable-next-line require-atomic-updates, no-param-reassign
            selectedCollection.fields = fields.data;
          } else {
            // eslint-disable-next-line require-atomic-updates, no-param-reassign
            selectedCollection.fields = [];
          }
        } catch (error) {
          if (!axios.isCancel(error)) {
            // show swal error if request failed with error
            filterSetsUtil.throwSwal({
              typeOfSwal: 'error',
              titleOfSwal: 'Error',
              message: error,
            });
          }
        }
      }
    }

    return selectedCollection.fields;
  };

  /**
   * Gets predefined relations
   * @returns {Promise<array>} - predefined relations
   */
  const getRelations = async () => {
    try {
      return await RelationsAPI.getRelations(axiosCancelToken.token);
    } catch (error) {
      if (!axios.isCancel(error)) {
        if (!String(error?.response?.data?.actualError).includes(
          Constants.ERROR_SFMC_PERMISSION_FAILED,
        )) {
          filterSetsUtil.throwSwal({
            typeOfSwal: 'error',
            titleOfSwal: 'Error',
            message: error,
          });
        }
      }
    }
  };

  /**
   * Handle clear filter set state
   * @returns {void}
   */
  const handleClearFilterSetState = async () => {
    setShowEditFiltersModal(false);
    dispatch(setFilterSetSelectedDataSet(null));
    dispatch(setFilterSetSelectedDEs([]));
    dispatch(setFilterSetSelectedFilters(null));
    dispatch(setFilterSetSelectedSourceDE(null));
  };

  /**
   * Handle clear filter set category state
   * @returns {void}
   */
  const handleClearFilterSetCategoryState = async () => {
    dispatch(setFilterSetCategoryName(null));
  };

  /**
   * Handle change of source option
   * @param {Event} e - OnChange event
   * @param {Object} data - Selected option data object
   * @returns {void}
   */
  const handleSourceOptionOnChange = (e, data) => {
    if (filterSetSourceType === 'dataSet') {
      dispatch(setFilterSetSelectedDataSet(data?.value));
    } else {
      dispatch(setFilterSetSelectedSourceDE(data?.value));
    }
  };
  /**
   * Handle change of source option
   *  @param {Event} e - OnChange event
   * @param {Object} data - Selected option data object
   * @returns {void}
   */
  const handleSourceOptionOnChangeCategory = (e, data) => {
    dispatch(setFilterSetCategoryName(data?.value));
  };

  /**
   * Show a swal to the user indicating the Data Extension cannot be found, and bring user back to the overview screen
   * @param {string} dataExtensionName - Name of DE
   * @returns {void}
   */
  const handleDataExtensionMissing = async (dataExtensionName) => {
    // Only throw alert when the component is mounted
    axiosCancelToken.cancel(`Missing data extension: ${dataExtensionName}.`);

    // Throw error indicating the selection cannot be opened because of the missing data extension
    await filterSetsUtil.throwSwal({
      typeOfSwal: 'error',
      titleOfSwal: 'Oops...',
      message: `The selected Data Set has a missing Data Extension <b class="bold_swal">${
        dataExtensionName}</b>. Therefore, this Data Set is not valid anymore, please select another one.`,
    }).then((result) => {
      if (result.isConfirmed) {
        handleClearFilterSetState();
      }
    });
  };

  /**
   * Convert the stored value of a filter set into the required states
   * @param {object[]} relations - DE relations
   * @param {object[]} collections - selected DEs
   * @param {boolean} loading - parameter indicating that loading is finished
   * @returns {Promise<array>} fetchedDataSet - formatted filter set
   */
  const loadStatesForDataSet = async (relations, collections, loading) => {
    const dataExtensionsCopy = JSON.parse(JSON.stringify(dataExtensions));

    const fetchedDataSet = await Util.formatRelationsAndSelectedDEs(
      relations,
      dataExtensionsCopy,
      collections,
      true,
      filterSetsUtil.setDataExtensionProperties,
      handleDataExtensionMissing,
      null,
      null,
      null,
      null,
      filterSetsUtil.addToSelectedDataExtensions,
      null,
      null,
      axios,
      null,
      null,
    );

    if(fetchedDataSet) {
      // get fields for Selected DEs and update relations and additional joins
      for (let j = 0; j < fetchedDataSet.selectedDataExtensions.length; j += 1) {
        // eslint-disable-next-line
        const fields = await getDataExtensionOrDataViewFields(fetchedDataSet.selectedDataExtensions[j]);

        if (fields) {
          fetchedDataSet.relations?.forEach((relation) => {
            // Assign fields and update additional joins for the fromDE part of relation
            if (relation.fromCollection.ObjectID === fetchedDataSet.selectedDataExtensions[j].ObjectID) {
              relation.fromCollection.fields = fields;

              fields.forEach((field) => {
                relation.additionalJoins.forEach((join) => {
                  if (field.ObjectID === join.fromFieldObjectID) {
                    join.fromFieldObjectID = field.ObjectID;
                  } else if (field.ObjectID === join.toFieldObjectID) {
                    join.toFieldObjectID = join.fromFieldObjectID;
                    join.fromFieldObjectID = field.ObjectID;
                  }

                  join.rowID = Util.uuid();
                });
              });
            }

            // Assign fields and update additional joins for the toDE part of relation
            if ((relation.toCollection.ObjectID === fetchedDataSet.selectedDataExtensions[j].ObjectID) ||
            (relation.toCollection.CustomerKey === fetchedDataSet.selectedDataExtensions[j].CustomerKey)) {
              relation.toCollection.fields = fields;

              fields.forEach((field) => {
                relation.additionalJoins.forEach((join) => {
                  if ((field.ObjectID === join.fromFieldObjectID) ||
                    (field.ObjectID === join.toFieldObjectID)) {
                    join.toFieldObjectID = field.ObjectID;
                  }

                  join.rowID = Util.uuid();
                });
              });
            }
          });
        }
      }

      fetchedDataSet.loading = !loading;

      return fetchedDataSet;
    }
  };

  /**
   * Fetch the DEs and predef relations
   * @returns {void}
   */
  const fetchDataExtensionAndRelations = async () => {
    let dataExtensions = [];

    let relations;

    try {
      // get data extensions and predefined relations
      const DEsAndPredefinedRelations = await Promise.all([
        DataExtensionsAPI.getDataExtensions(
          axiosCancelToken.token,
          Constants.DATAEXTENSION__FILTER_MODE__AVAILABLE,
        ),
        getRelations()]);

      // Get data extensions
      dataExtensions = [...(DEsAndPredefinedRelations?.[0] || [])];

      // Get predefined relations
      relations = [...(DEsAndPredefinedRelations?.[1] || [])];

      // add data views in data extensions array
      filterSetsUtil.addDataViewsInDEs(dataExtensions);

      const predefinedRelationsMap = {};

      let predefinedDEs = [];

      relations?.forEach((relation) => {
        /*
         * for each relation create an object with the key: relation ID and
         * value consisting of the array fromFieldObjectId and toFieldObjectId
         */
        predefinedRelationsMap[relation._id] = [relation?.fromFieldObjectId?.toString(),
          relation?.toFieldObjectId?.toString()];

        // get only DEs that are part of predef relations
        const predefinedFromDE = dataExtensions.find(de => (de.ObjectID === relation.fromDEObjectId) ||
        (de.CustomerKey === relation.fromDECustomerKey));
        const predefinedToDE = dataExtensions.find(de => (de.ObjectID === relation.toDEObjectId) ||
        (de.CustomerKey === relation.toDECustomerKey));

        if (predefinedFromDE) {
          predefinedDEs.push(predefinedFromDE);
        }

        if (predefinedToDE) {
          predefinedDEs.push(predefinedToDE);
        }
      });

      predefinedDEs = Util.removeDuplicatesFromArray(predefinedDEs);
      predefinedDEs = Util.sortArrayOfObjects(predefinedDEs, 'Name');

      dispatch(setDataExtensions(predefinedDEs));
      dispatch(setPredefinedRelations(relations));
      dispatch(setPredefinedRelationsMap(predefinedRelationsMap));

      setLoading(prevState => ({ ...prevState, dataSets: false }));
    } catch (error) {
      if (!axios.isCancel(error)) {
        filterSetsUtil.throwSwal({
          typeOfSwal: 'error',
          titleOfSwal: 'Error',
          message: error,
        });
      }
    }
  };

  /**
   * Handle select filter set to be used
   * @returns {void}
   */
  const handleSetSelectedDataSet = async () => {
    if (!filterSetSelectedDataSet) return;

    setSelectedDataSourceLoading(true);
    const ds = dataSets.find(d => d._id === filterSetSelectedDataSet);
    const stateForDataSet = await loadStatesForDataSet(
      ds.relations,
      ds.collections,
      loading.dataSets,
    );

    if (stateForDataSet) {
      dispatch(setFilterSetSelectedDEs(stateForDataSet.selectedDataExtensions || []));
    }
    setSelectedDataSourceLoading(false);
  };

  /**
   * Handle select filter set source data extension
   * @param {String} sourceDE - Source DE objectID
   * @returns {void}
   */
  const handleSetSelectedSourceDataExtension = async (sourceDE) => {
    setSelectedDataSourceLoading(true);
    const selectedDE = availableDataExtensions?.find(
      d => d.ObjectID === sourceDE,
    );

    try {
      const retrievedDEs = await DataExtensionsAPI.getDataExtensionsAndFieldsByCustomerKeys(
        axiosCancelToken.token,
        [{ collectionCustomerKey: selectedDE?.CustomerKey }],
        null,
      );

      dispatch(setFilterSetSelectedDEs([retrievedDEs?.length > 0 &&
        { ...retrievedDEs[0], deAlias: retrievedDEs[0]?.Name?.toString() }]));
      setSelectedDataSourceLoading(false);
    } catch (error) {
      setSelectedDataSourceLoading(false);
      if (!axios.isCancel(error)) {
        filterSetsUtil.throwSwal({
          typeOfSwal: 'error',
          titleOfSwal: 'Error',
          message: error,
        });
      }
    }
  };

  /**
   * Handle select filter set source
   * @returns {void}
   */
  const handleSelectFilterSetSource = () => {
    if (filterSetSourceType === 'dataSet') {
      handleSetSelectedDataSet();
    } else {
      handleSetSelectedSourceDataExtension(filterSetSelectedSourceDE);
    }
  };

  /**
   * Formats the states for the filter set
   * @returns {void}
   */
  const setFilterSetStates = async () => {
    if (filterSetId) {
      setSelectedDataSourceLoading(true);

      // Set filter set name
      dispatch(setFilterSetName(selectedFilterSet.name));
      dispatch(setFilterSetDescription(selectedFilterSet.description));

      if (selectedFilterSet?.category?.name) {
        dispatch(setFilterSetCategoryName(selectedFilterSet.category._id));
      }

      const ds = dataSets.find(d => d._id === selectedFilterSet.dataSetId);
      const sourceDE = availableDataExtensions?.find(d => d.ObjectID === selectedFilterSet.dataExtensionObjectId);

      if (!ds && !sourceDE) {
        setSelectedDataSourceLoading(false);

        return filterSetsUtil.throwSwal({
          typeOfSwal: 'error',
          titleOfSwal: 'Missing related Data Source',
          message: 'The Data Source related to this Filter Set was not found. Please select a new Data Source.',
        });
      }

      if (!selectedFilterSet.sourceType ||
        selectedFilterSet.sourceType === Constants.FILTER_SET__SOURCE_TYPE__DATA_SET) {
        dispatch(setFilterSetSourceType(Constants.FILTER_SET__SOURCE_TYPE__DATA_SET));

        dispatch(setFilterSetSelectedDataSet(ds?._id || null));

        const stateForDataSet = await loadStatesForDataSet(
          ds?.relations,
          ds?.collections,
          loading.dataSets,
        );

        if (stateForDataSet) {
          const { updatedFilters, isUpdated } = filtersUtil.updateFiltersFromFilterSet(
            selectedFilterSet.filters,
            stateForDataSet.selectedDataExtensions || [],
          );

          dispatch(setFilterSetSelectedFilters(updatedFilters?.filters));
          if (isUpdated) {
            filterSetsUtil.throwSwal({
              typeOfSwal: 'error',
              titleOfSwal: 'Filter Set updated',
              // eslint-disable-next-line max-len
              message: 'The Data Set related to this Filter Set was updated. Therefore, the Selected Filters were updated.',
            });
          }
          dispatch(setFilterSetSelectedDEs(stateForDataSet.selectedDataExtensions || []));
        }
        setSelectedDataSourceLoading(false);
        setLoading(prevState => ({ ...prevState, dataSets: false }));
      } else if (selectedFilterSet.sourceType === Constants.FILTER_SET__SOURCE_TYPE__DATA_EXTENSION) {
        // Set states with data from saved filter set
        dispatch(setFilterSetSourceType(Constants.FILTER_SET__SOURCE_TYPE__DATA_EXTENSION));
        dispatch(setFilterSetSelectedSourceDE(sourceDE?.ObjectID || null));

        // Check for changes and update filters accordingly
        const retrievedDEs = await DataExtensionsAPI.getDataExtensionsAndFieldsByCustomerKeys(
          axiosCancelToken.token,
          [{
            collectionCustomerKey: availableDataExtensions?.find(
              d => d.ObjectID === sourceDE?.ObjectID,
            )?.CustomerKey,
          }],
          null,
        );

        const filterSetSubqueryDEs = filtersUtil.getFilterSetSubqueryCollections(selectedFilterSet);
        const retrievedSubqueryDEs = await DataExtensionsAPI.getDataExtensionsAndFieldsByCustomerKeys(
          axiosCancelToken.token,
          filterSetSubqueryDEs,
          null,
        );

        const { updatedFilters, isUpdated } = filtersUtil
          .updateFiltersFromFilterSet(selectedFilterSet.filters, [retrievedDEs?.length > 0 &&
            { ...retrievedDEs[0], deAlias: retrievedDEs[0]?.Name?.toString() }], retrievedSubqueryDEs);

        dispatch(setFilterSetSelectedFilters(updatedFilters?.filters));
        if (isUpdated) {
          filterSetsUtil.throwSwal({
            typeOfSwal: 'error',
            titleOfSwal: 'Filter Set updated',
            // eslint-disable-next-line max-len
            message: 'The Data Extension related to this Filter Set was updated. Therefore, the Selected Filters were updated.',
          });
        }

        await handleSetSelectedSourceDataExtension(sourceDE?.ObjectID);

        setSelectedDataSourceLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchDataExtensionAndRelations();

    // when component is unmounted
    return () => {
      // cancel axios token
      axiosCancelToken.cancel();

      // clear the filter set state
      dispatch(clearFilterSetState());
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!loading.dataExtensions &&
      (availableDataExtensions?.length || dataExtensions.length)) {
      setFilterSetStates();
    }
    // eslint-disable-next-line
  }, [dataExtensions, predefinedRelations, availableDataExtensions, loading.dataExtensions]);

  /**
   * Cancels a filter set and redirects a user back to the overview page of the filter sets
   * @returns {void}
   */
  const handleCancel = async () => {
    await openPanel(Constants.ADMIN_PANEL__MENU__FILTER_SETS);
  };

  /**
   * Saves all the properties of a filter set in the db
   * @returns {void}
   */
  const handleSaveFilterSet = async () => {
    if(!filterSetName) {
      return filterSetsUtil.throwSwal(
        {
          typeOfSwal: 'error',
          titleOfSwal: 'Missing Filter Set Name',
          message: 'Filter Set name is required.',
        },
      );
    }

    const doesFilterSetNameAlreadyExist = filterSets.some(el => el.name === filterSetName && el._id !== filterSetId);

    if (doesFilterSetNameAlreadyExist) {
      setNameFilterSetError(true);

      return;
    }

    setNameFilterSetError(false);

    if (!filterSetSelectedSourceValue || !(filterSetSelectedDEs.length > 0)) {
      // show swal error if no data source were selected
      return filterSetsUtil.throwSwal(
        {
          typeOfSwal: 'error',
          titleOfSwal: 'Missing Data Source',
          message: 'You should select a Data Source.',
        },
      );
    }

    if (!filterSetSelectedFilters?.filters || filterSetSelectedFilters?.filters?.length < 1) {
      // show swal error if required filters are not given
      return filterSetsUtil.throwSwal(
        {
          typeOfSwal: 'error',
          titleOfSwal: 'Missing Selected Filters',
          message: 'There should be at least one Selected Filters.',
        },
      );
    }

    setSavingFilterSet(true);

    const postData = {};

    postData.filters = filterSetSelectedFilters;
    postData.dataSetId = filterSetSelectedDataSet;
    postData.name = filterSetName;
    postData.sourceType = filterSetSourceType;
    postData.dataExtensionObjectId = filterSetSelectedSourceDE;
    postData.description = filterSetDescription;
    postData.category = filterSetCategory;

    try {
      // if there is a filter set id
      if (filterSetId) {
        // then update that filter set
        await FilterSetsAPI.updateFilterSet(filterSetId, postData, axiosCancelToken.token);
      } else {
        // otherwise create a new filter set
        await FilterSetsAPI.createFilterSet(postData, axiosCancelToken.token);
      }

      setSavingFilterSet(false);
      await openPanel(Constants.ADMIN_PANEL__MENU__FILTER_SETS);
    } catch (error) {
      if (!axios.isCancel(error)) {
        filterSetsUtil.throwSwal({
          typeOfSwal: 'error',
          titleOfSwal: 'Error',
          message: error,
        });
      }
      setSavingFilterSet(false);
    }
  };

  /**
   * Get the filter set button label
   * @returns {string} The filter set button label
   */
  const renderFilterSetLabel = () => {
    if (filterSetId && activePanel === Constants.ADMIN_PANEL__MENU_EDIT_FILTER_SET) return 'Edit Filter Set';
    if (activePanel === Constants.ADMIN_PANEL__MENU__NEW_FILTER_SET) return 'New Filter Set';

    return 'Filter Sets';
  };

  // render loading text based on panel type
  if (loading.dataSets && loading.dataExtensions) {
    return (
      <LoadingModal
        closeModal={() => openPanel(Constants.ADMIN_PANEL__MENU__FILTER_SETS)}
        loadingText={filterSetId ? 'Filter Set is loading...' : 'Loading...'}
        openPanel={openPanel}
        id="loadingmodal-wrapper"
      />
    );
  }

  /*
   * Format the data sets for the dropdown
   */
  const dataSetsOptions = dataSets
    ?.map(ds => ({
      value: ds._id,
      title: ds.name,
      text: ds.name,
      key: ds._id,
    }));

  /*
   * Format the  filter sets category for the dropdown
   */
  const filterSetsCategoriesOptions = filterSetsCategories
    ?.map(ds => ({
      value: ds._id,
      title: ds.name,
      text: ds.name,
      key: ds._id,
    }));

  /*
   * Format the data extensions for the dropdown
   */
  const dataExtensionsOptions = availableDataExtensions
    ?.map(de => ({
      value: de.ObjectID,
      title: String(de.Name),
      text: String(de.Name),
      key: de.ObjectID,
    }));

  const filterSetSourceOptions = filterSetSourceType === 'dataSet' ? dataSetsOptions : dataExtensionsOptions;

  return (
    <div className="relation-container filter-set-container">
      <div className="slds-page-header slds-m-bottom_medium">
        <div className="slds-page-header__row">
          <div className="slds-page-header__col-title">
            <div className="slds-media header-alignment">
              <div className="slds-media__figure">
                <span className="slds-icon_container slds-icon-standard-account" title="Data Sets">
                  <svg className="slds-icon slds-page-header__icon" aria-hidden="true">
                    <use
                      xlinkHref="/assets/icons/standard-sprite/svg/symbols.svg#picklist_type"
                    />
                  </svg>
                </span>
              </div>
              <div className="slds-media__body">
                <div className="slds-page-header__name">
                  <div className="slds-page-header__name-title">
                    <h1>
                      <span
                        className="slds-page-header__title slds-truncate"
                        title="Filter Sets"
                        id="filter-sets-header-title"
                      >
                        {renderFilterSetLabel()}
                      </span>
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relation-panel-container" id="filter-set-panel">
        <div className="relation_wrapper">
          <div className="aggregation-content">
            <div className="filter-sets-header">
              <div className="filter-sets-header-title">Define a Filter Set</div>
            </div>
            <div className="relation_info_text">
              <p className="info_text">
              As an admin, you can create a combination of filters linked to a Data Set.
              These &apos;Filter Sets&apos; can then be used as predefined filters inside selections.
              </p>
            </div>

            <div className="filter-set-name-input">
              <span className="filter-set-name-label">Name</span>
              <input
                id="filterSet-name"
                name="value"
                type="text"
                min="1"
                max="2550"
                className="slds-input custom-values-radio field-name"
                value={filterSetName || ''}
                aria-label="Filter Set Name"
                onChange={e => dispatch(setFilterSetName(e.target.value))}
              />
              <Alert
                className={classNames('mt-5px ' + (nameFilterSetError ? 'alert-visible' : 'alert-hidden'))}
                title="Filter Set with this name already exists."
                id="nameFilterSetError"
              />
            </div>

            <div className="filter-set-description-input">
              <span className="filter-set-description-label">Description</span>
              <textarea
                id="filterSet-description"
                name="value"
                type="text"
                min="1"
                max="2550"
                className="slds-input field-name"
                aria-label="Filter Set Description"
                value={filterSetDescription || ''}
                onChange={e => dispatch(setFilterSetDescription(e.target.value))}
              />
            </div>
            <div className="field-container margin-top category">
                <div className="field-content">
                <span className="filter-set-description-label-category">Category</span>
                  <div className="data-extensions-container">
                    <div className="dropdown-field data-extension display-flex">
                      <Dropdown
                        id="filter-set-categories-dropdown"
                        selection
                        className="target-data-extension-dropdown searchable-dropdown"
                        search
                        placeholder="Choose Filter Set Category"
                        value={filterSetCategory}
                        options={filterSetsCategoriesOptions}
                        onChange={handleSourceOptionOnChangeCategory}
                      />
                    {filterSetCategory &&
                      (
                        <Button
                          id="clear-selected-filter-set-btn"
                          type="button"
                          className="slds-button slds-button_neutral ml-4px"
                          onClick={() => handleClearFilterSetCategoryState()}
                        >
                          Clear
                        </Button>
                      ) }
                    </div>
                  </div>
                </div>
            </div>

            <div className="flex">
              <span className="label display-flex select-filter-set-source-label">
                Filter Set Source
                <Tooltip
                  nubbinPosition={Constants.NUBBIN_POSITION__TOP_RIGHT}
                  type={Constants.TOOLTIP_TYPE__PREDEFINED_FILTER_SET_SOURCE}
                />
              </span>
            </div>
            <div className="filter-set-source">
              <div className="radio-container">
                <RadioButton
                  value="dataSet"
                  checked={filterSetSourceType === 'dataSet'}
                  onChange={e => dispatch(setFilterSetSourceType(e.target.value))}
                  disabled={editIsDisabled || !featureDataSets}
                  id="filter-set-data-set-radio" />
                <span
                  className="radio-label"
                  onClick={() => {
                    if(!editIsDisabled && featureDataSets) { dispatch(setFilterSetSourceType('dataSet')); }
                  }}>
                  Data Set
                  {!featureDataSets && <Tooltip
                  nubbinPosition={Constants.NUBBIN_POSITION__TOP_RIGHT}
                  type={Constants.TOOLTIP_TYPE__UNAVAILABLE_FEATURE}
                />}
                </span>
              </div>
              <div className="radio-container">
                <RadioButton
                  value="dataExtension"
                  checked={filterSetSourceType === 'dataExtension'}
                  onChange={e => dispatch(setFilterSetSourceType(e.target.value))}
                  disabled={editIsDisabled}
                  id="filter-set-de-radio" />
                <span
                  className="radio-label"
                  onClick={() => { if(!editIsDisabled) dispatch(setFilterSetSourceType('dataExtension')); }}>
                  Data Extension
                </span>
              </div>
            </div>

            <div className="field-container margin-top">
                <div className="field-content">
                  <div className="data-extensions-container">
                    <div className="dropdown-field data-extension display-flex">
                      <Dropdown
                        id="filter-set-selected-data-set-dropdown"
                        selection
                        className="target-data-extension-dropdown searchable-dropdown"
                        search
                        placeholder={`Choose ${filterSetSourceTypeLabel}`}
                        value={filterSetSelectedSourceValue}
                        options={filterSetSourceOptions}
                        onChange={handleSourceOptionOnChange}
                        disabled={editIsDisabled}
                      />
                    {filterSetSelectedSourceValue && filterSetSelectedDEs.length > 0 ?
                      (
                        <Button
                          id="clear-selected-filter-set-btn"
                          type="button"
                          className="slds-button slds-button_neutral ml-4px"
                          onClick={() => handleClearFilterSetState()}
                        >
                          Clear
                        </Button>
                      ) :
                      (
                        <Button
                          id="select-filter-set-btn"
                          type="button"
                          className="slds-button slds-button_neutral ml-4px"
                          onClick={handleSelectFilterSetSource}
                          disabled={!filterSetSelectedSourceValue && !selectedDataSourceLoading}
                        >
                          {selectedDataSourceLoading ?
                            (
                            <svg
                            aria-hidden="true"
                            className="slds-button__icon fa-spin"
                          >
                            <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#refresh" />
                            </svg>
                            ) :
                            'Select'}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
            </div>
            <div className="edit-selected-filters-btn">
              <Button
                id="edit-selected-filters-btn"
                type="button"
                className="slds-button slds-button_brand"
                onClick={() => setShowEditFiltersModal(true)}
                disabled={!filterSetSelectedSourceValue || (!filterSetSelectedDEs.length > 0)}
              >
                <i className="fa fa-edit col-button-update float-left lh-23" />
                Edit Selected Filters
              </Button>
            </div>
            {
              showEditFiltersModal && (
                <div>
                  <EditFiltersModal
                    dataExtensions={availableDataExtensions}
                    selectedDataExtensions={filterSetSelectedDEs}
                    selectedFilters={filterSetSelectedFilters || {}}
                    handleFiltersSave={(filters) => { dispatch(setFilterSetSelectedFilters(filters)); }}
                    getDataExtensionOrDataViewFields={getDataExtensionOrDataViewFields}
                    handleFeatureMissing={handleFeatureMissing}
                    inSelectionCriteria
                    pickLists={filterSetPickLists || []}
                    returnPredefinedRelationById={
                      (relationId, relationFilter) => filterSetsUtil.returnPredefinedRelationById(
                        {
                          relationId, relationFilter, predefinedRelations, filterSetSelectedDEs,
                        },
                      )
                    }
                    predefinedRelations={predefinedRelations}
                    handleCloseEditFiltersModal={() => setShowEditFiltersModal(false)}
                  />
                </div>
              )
            }
          </div>
        </div>
      </div>

      <div className="slds-grid cancel-save-relation">
          <div className="slds-col_bump-left">
            <Button
              id="cancel-filter-set"
              type="button"
              className="slds-button slds-button_neutral"
              label="Cancel"
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
            <Button
              id="save-filter-set"
              type="button"
              className="slds-button slds-button_brand"
              onClick={() => handleSaveFilterSet()}
              disabled={savingFilterSet}
            >
              {savingFilterSet ?
                (
                  <div className="preview-loader-container">
                    <div
                      role="status"
                      className="slds-spinner slds-spinner_x-small"
                    >
                      <div className="slds-spinner__dot-a" />
                      <div className="slds-spinner__dot-b" />
                    </div>
                    <span className="when-pressed">
                      Saving...
                    </span>
                  </div>
                ) :
                (
                  <span>Save</span>
                )}
            </Button>
          </div>
      </div>
    </div>
  );
};

FilterSetsPanel.propTypes = {
  /**
   * Id of a filter set
   */
  filterSetId: PropTypes.string,
  /**
   * Function to open a certain panel
   */
  openPanel: PropTypes.func.isRequired,
  /**
   * The currently selected filter set
   */
  selectedFilterSet: PropTypes.instanceOf(Object),
  /**
   * A list of Data Sets
   */
  dataSets: PropTypes.arrayOf(Object),
  /**
   * Active panel
   */
  activePanel: PropTypes.string.isRequired,
  /**
   * array containing the filterSets retrieved, this prop comes
   * from the admin panel component
   */
  filterSets: PropTypes.instanceOf(Array).isRequired,
  /**
   * Features info from cookie
   */
  featuresInfo: PropTypes.object,
};

export default connect(mapStateToProps(['featuresInfo']), null, null, { pure: false })(FilterSetsPanel);
