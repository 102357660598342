import axios from 'axios';
import * as rax from 'retry-axios';

import raxConfig from '../configs/raxConfig';
import Util from '../util';

const apiUrl = process.env.REACT_APP_API_URL;

// use retry-axios to automatically retry failed requests
axios.defaults.raxConfig = { ...raxConfig, instance: axios };
rax.attach(axios);

const PreviewTipsAPI = {
  /**
   * Get random tips
   * @param {object} cancelToken - Token axios
   * @returns {object} The data
   */
  getPreviewTips: async (cancelToken) => {
    const res = await Util.catch418And403Error(
      axios.get,
      `${apiUrl}/preview-tips`,
      Util.apiGetCallSettings(cancelToken),
    );

    return res.data.data;
  },
};

export default PreviewTipsAPI;
