import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import DroplineSelectionInfo from './DroplineSelectionInfo';
import DroplineTargetDEInfo from './DroplineTargetDEInfo';
import Constants from '../../../../../../constants/constants';
import SwalUtil from '../../../../../../utils/swal/swalUtil';
import Spinner from '../../../../../shared_v2/Spinner/Spinner';
import './styles.scss';

const DroplineNameCard = ({
  isLoading,
  isTargetDE,
  name,
  isSelectionUseTargetDEAsSelectedDE,
  previousTargetDEName,
  isThisSelectionHasNoTargetDE,
  isThisSelectionHasNoMatchedField,
  isThisSelectionHasNoMappedFieldsWithRequired,
  removedSelection,
  handleOpenSelection,
  isArchived,
  isReadOnly,
}) => {
  const droplineCardClassName = classNames(
    'dropline-card-new',
    { 'targetDataExtension-dropped': isTargetDE },
    { 'selection-dropped': !isTargetDE },
  );

  /*
   * const iconWrapperClassName = classNames(
   *   { 'target-de-icon-wrapper': isTargetDE },
   *   { 'selection-icon-wrapper': !isTargetDE },
   *   {
   *     // eslint-disable-next-line quote-props
   *     'inactive': isThisSelectionHasNoTargetDE || isThisSelectionHasNoMatchedField ||
   *       isThisSelectionHasNoMappedFieldsWithRequired || removedSelection,
   *   },
   * );
   */

  /**
   * This function returns data for swal message depending on the error
   * @returns {object} object with message and title
   */
  const dataForSwal = () => {
    if (isThisSelectionHasNoTargetDE) {
      return {
        message: 'Target Data Extension used in this selection is not available anymore.',
        title: Constants.SWAL_TITLE__TARGET_DATA_EXTENSION_NO_LONGER_EXISTS,
      };
    }
    if (isThisSelectionHasNoMatchedField) {
      return {
        message: 'Selection does not have at least one matched field with target data extension.',
        title: Constants.SWAL_TITLE__NO_MATCHING_FIELD_FOUND,
      };
    }
    if (isThisSelectionHasNoMappedFieldsWithRequired) {
      return {
        message: 'Selection does not have all required fields mapped in target data extension.',
        title: Constants.SWAL_TITLE__REQUIRED_FIELDS_HAVE_NOT_BEEN_MAPPED,
      };
    }
    if (removedSelection) {
      return {
        message: 'This selection is not available anymore.',
        title: Constants.SWAL_TITLE__NO_SELECTION_FOUND,
      };
    }

    return {
      message: '',
      title: '',
    };
  };

  /**
   * This function returns swal message for error icon
   * @param {boolean} isTargetMissing - indicates that TargetDE was not found for the selection
   * @returns {object} Swal object with message
   */
  const showSwalForErrorIcon = () => {
    SwalUtil.fire({
      type: Constants.SWAL__TYPE__ERROR,
      // eslint-disable-next-line max-len
      title: `${dataForSwal().title}`,
      message: `${dataForSwal().message}`,
      options: {
        confirmButtonText: 'OK',
        customClass: {
          title: 'swal2-waterfall-selection-title',
        },
      },
    });
  };

  // define what will be rendered in place of the name for targetDE
  const targetDEName = isThisSelectionHasNoTargetDE ?
    (
      <i title={Constants.SWAL_TITLE__TARGET_DATA_EXTENSION_NO_LONGER_EXISTS}>
        <svg
          className="slds-icon slds-icon-text-default slds-icon_x-small status-error"
          aria-hidden="true"
          onClick={() => showSwalForErrorIcon()}
        >
          <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#error" />
        </svg>
      </i>
    ) :
    name;

  /**
   * This function returns swal message for warning icon
   * @returns {object} Swal object with message
   */
  const showSwalForWarningIcon = () => {
    SwalUtil.fire({
      type: Constants.SWAL__TYPE__ERROR,
      // eslint-disable-next-line max-len
      title: `${Constants.SWAL_TITLE__SELECTION_DOES_NOT_USE_PREVIOUS_TARGET_DE}`,
      message: `This selection does not use Target Data Extension
      <span class="bold_swal">${previousTargetDEName}</span>
      from the previous step as a Selected Data Extension, in filters, or in Custom Values.`,
      options: {
        confirmButtonText: 'OK',
        customClass: {
          title: 'swal2-waterfall-selection-title',
        },
      },
    });
  };

  return isLoading ?
    (
      <div className="targetDataExtension-dropped dropline-card-new">
        <div className="target-de-icon-wrapper loading">
          <Spinner size={Constants.SPINNER__SIZE__X_SMALL} />
        </div>
        <p className="disable-targetDE">
          {isTargetDE ?
            'Loading Target Data Extension...' :
            'Loading Selection...'}

        </p>
      </div>
    ) :
    (
      <div className={droplineCardClassName}>
        {isTargetDE ?
          <DroplineTargetDEInfo targetDEName={targetDEName} /> :
          (
            <DroplineSelectionInfo
              name={name}
              isArchived={isArchived}
              isReadOnly={isReadOnly}
              isTargetDE={isTargetDE}
              isThisSelectionHasNoMatchedField={isThisSelectionHasNoMatchedField}
              isThisSelectionHasNoMappedFieldsWithRequired={isThisSelectionHasNoMappedFieldsWithRequired}
              removedSelection={removedSelection}
              isSelectionUseTargetDEAsSelectedDE={isSelectionUseTargetDEAsSelectedDE}
              isThisSelectionHasNoTargetDE={isThisSelectionHasNoTargetDE}
              dataForSwal={dataForSwal}
              showSwalForErrorIcon={showSwalForErrorIcon}
              showSwalForWarningIcon={showSwalForWarningIcon}
              handleOpenSelection={handleOpenSelection}
            />
          )}
      </div>
    );
};

DroplineNameCard.propTypes = {
  /*
   * defines whether data is loaded
   */
  isLoading: PropTypes.bool.isRequired,
  /*
   * defines whether the component is used for targetDE
   */
  isTargetDE: PropTypes.bool,
  /*
   * name for selection or targetDE
   */
  name: PropTypes.string,
  /*
   * defines whether the selection uses the targetDE from the previous step as the selected data extension
   */
  isSelectionUseTargetDEAsSelectedDE: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.instanceOf(null),
  ]),
  /*
   * defines the name of target data extension from the previous step
   */
  previousTargetDEName: PropTypes.string,
  /*
   * indicates that TargetDE was not found for this selection
   */
  isThisSelectionHasNoTargetDE: PropTypes.bool,
  /*
   * indicates that no matched field was found for this selection
   */
  isThisSelectionHasNoMatchedField: PropTypes.bool,
  /*
   * indicates that all required fields of target data extension have not been mapped
   */
  isThisSelectionHasNoMappedFieldsWithRequired: PropTypes.bool,
  /*
   * indicates that selection no longer exists
   */
  removedSelection: PropTypes.bool,
  /*
   * function that open selection from waterfall selection
   */
  handleOpenSelection: PropTypes.func,
  /**
   * Indicates if the waterfall selection is archived or not
   */
  isArchived: PropTypes.bool.isRequired,
  /*
   * Indicates that component is read-only, without drag&drop functionality
   */
  isReadOnly: PropTypes.bool,
};

export default DroplineNameCard;
