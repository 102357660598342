import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.scss';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';

import mapStateToProps from '../../../../mapStateToProps';
import Constants from '../../../../constants/constants';
import QueryActivitiesAPI from '../../../../api/query-activities';
import waterfallSelectionUtil from '../../../../utils/waterfallSelection/waterfallSelectionUtil';
import Util from '../../../../util';
import Features from '../../../../features';
import Toast from '../../../shared/Toast/Toast';
import Button from '../../../shared/Button/Button';
import SwalUtil from '../../../../utils/swal/swalUtil';
import UpgradeBadge from '../../../shared/UpgradeBadge/UpgradeBadge';
import Tooltip from '../../../shared/Tooltip/Tooltip';
import { featureAdvertExists } from '../../../shared/Feature/featureUtil';
import GuidanceTip from '../../../shared/GuidanceTip/GuidanceTip';

class NavigationButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clickedRun: false,
    };
  }

  /**
   * Redirect to overview
   * @param {string} isRunClicked - Has the run button been clicked?
   * @returns {void}
   */
  // eslint-disable-next-line consistent-return
  navigateToOverview = async (isRunClicked = false) => {
    let appState;
    const {
      handleNavigator, copiedSelectionState, selectionState, captureSelectionChange,
      backToWaterFall, handleSetAppState, userInfo,
    } = this.props;

    const hasReadOnlyAccess = userInfo?.hasReadOnlyAccess;

    let copiedSelectionStateWithoutSomeProperties;

    // remove non-effective properties from states
    const selectionStateWithoutSomeProperties = (({
      dataExtensions, targetDataExtensions, loadingForDataExtensions,
      loadingForTargetDataExtensions, selectionNavigator, showSaveToast, copySuccess,
      isDataExtensionRequestDone, predefinedRelations, predefinedRelationsMap, runStatus,
      currentSelectionId, pickListFieldObjectIDs, pickLists, dataSets, filterSets, defaultSendRelationshipField,
      ...o
    }) => o)(selectionState);

    // if copy of selection was created
    if (copiedSelectionState) {
      copiedSelectionStateWithoutSomeProperties = (({
        dataExtensions, targetDataExtensions, loadingForDataExtensions,
        loadingForTargetDataExtensions, selectionNavigator, showSaveToast, copySuccess,
        isDataExtensionRequestDone, predefinedRelations, predefinedRelationsMap, runStatus,
        currentSelectionId, pickListFieldObjectIDs, pickLists, dataSets, filterSets, defaultSendRelationshipField,
        ...o
      }) => o)(copiedSelectionState);
    }

    if (backToWaterFall) {
      appState = {
        backToWaterFall: null,
        currentSelectionName: '',
        currentSelectionId: backToWaterFall._id,
        navigator: Constants.NAVIGATION__WATERFALL_SELECTION,
        globalNavigator: Constants.NAVIGATION__WATERFALL_SELECTION,
        folderId: backToWaterFall.folderId,
      };

      if (backToWaterFall.actionType === Constants.BACKTOWATERFALL__TYPE_CREATE) {
        if (selectionState?.targetDataExtension?.ObjectID) {
          appState.addSelectionToWFSelectedSelections = { selectionId: selectionState.currentSelectionId };
        } else {
          // show warning of incomplete selection
          const res = await SwalUtil.fire({
            title: 'Confirmation needed',
            message: `Please note that this selection will not be added to the Waterfall Selection
             since it does not have any assigned Target Data Extension. Are you sure you want to proceed?`,
            options: {
              confirmButtonText: 'OK',
              showCancelButton: true,
              allowOutsideClick: false,
            },
          });

          // if pressed cancel, stay
          if (!res.value) {
            return;
          }
        }
      }
    }

    // if run button clicked, we don't need to compare
    if (isRunClicked && backToWaterFall) {
      return handleSetAppState(appState);
    }
    if (isRunClicked) {
      toast.info(
        <div className="slds-notify slds-notify_toast slds-theme_info">
          <span className="slds-assistive-text">success</span>
          <span
            className="slds-icon_container slds-icon-utility-success slds-m-right_small slds-no-flex slds-align-top"
            title="Success"
          >
            <svg className="slds-icon slds-icon_small" aria-hidden="true">
              <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#success" />
            </svg>
          </span>
          <div>
            <p className="slds-text-title_bold">
              Running Selection
            </p>
            <p className="slds-text-body_small" style={{ marginTop: '0.5em' }}>
              Most Selections finish quickly, but this can take up to 30 minutes.
            </p>
          </div>
        </div>,
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'toast-info',
          toastId: Constants.NOTIFICATION__TOAST_ID__RUNNING,
          hideProgressBar: true,
          autoClose: 10000,
          containerId: Constants.NOTIFICATION__CONTAINER_ID__RUNNING,
          draggable: false,
          pauseOnHover: false,
          pauseOnFocusLoss: false,
        },
      );

      return handleNavigator(Constants.NAVIGATION__OVERVIEW, Constants.PAGE_RESET);
    }

    const nothingHasChanged = (JSON.stringify(selectionStateWithoutSomeProperties) ===
      JSON.stringify(copiedSelectionStateWithoutSomeProperties) ||
      !copiedSelectionStateWithoutSomeProperties) &&
      !captureSelectionChange || selectionState?.isArchived || hasReadOnlyAccess;

    /*
     * compare object deeply if anything changed show swal with warning
     * otherwise navigate to overview without warning
     */
    if (nothingHasChanged && backToWaterFall) {
      handleSetAppState(appState);
    } else if (nothingHasChanged) {
      handleNavigator(Constants.NAVIGATION__OVERVIEW);
    } else {
      // show warning of unsaved changes
      const res = await SwalUtil.fire({
        title: 'Confirmation needed',
        message: `Are you sure you want to go ${backToWaterFall ?
          `back to the Waterfall Selection <b style='font-weight: 700'>${backToWaterFall?.name}</b>` :
          'to the Selections Overview'}?
          Unsaved changes will be lost.`,
        options: {
          confirmButtonText: 'Yes',
          showCancelButton: true,
          allowOutsideClick: false,
        },
      });

      // if pressed ok, go to waterFall or overview screen
      if (res.value && backToWaterFall) {
        handleSetAppState(appState);
      } else if (res.value) {
        handleNavigator(Constants.NAVIGATION__OVERVIEW);
      }
    }

    return null;
  };

  /**
   * Save new data in selection
   * If saved data is ok, save new query activity and navigate to overview
   * @returns {void}
   */
  runQuery = async () => {
    const {
      validateIfQueryCanBeRun,
      saveSelection,
      axiosCancelToken,
      checkMissingFieldsInRelations,
      checkIncompleteFilter,
      checkValidSchedule,
    } = this.props;

    // Validate if query can be run
    if (await validateIfQueryCanBeRun(false) &&
      await checkMissingFieldsInRelations(true) &&
      await checkIncompleteFilter() &&
      await checkValidSchedule()
    ) {
      // Save new data
      const saveSelectionResult = await saveSelection(true);

      if (saveSelectionResult.success) {
        const currentSelectionId = saveSelectionResult.selectionId;

        try {
          // Save activities and navigate to overview
          await QueryActivitiesAPI.runQueryActivity(currentSelectionId, axiosCancelToken);
          this.navigateToOverview(true);
        } catch (error) {
          await SwalUtil.fire({
            type: Constants.SWAL__TYPE__ERROR,
            title: 'Error Running Selection',
            message: error,
          });
        }
      }
    }
  };

  /**
   * Run button
   * Save the new DE, save the result in records, redirect to overview
   * @returns {void}
   */
  handleRunQuery = async () => {
    this.setState({ clickedRun: true });
    try {
      await this.runQuery();
      this.setState({ clickedRun: false });
    } catch (e) {
      this.setState({ clickedRun: false });
    }
  };

  /**
   * Save button handler. Saves new selection.
   * Function cannot be moved to Navbar as it uses saveSelection on Selection
   * @returns {void}
   */
  // eslint-disable-next-line consistent-return
  handleSave = async () => {
    const {
      saveSelection,
      handleSetSelectionState,
      enabledScheduleSelection,
      validateIfQueryCanBeRun,
      checkMissingFieldsInRelations,
      checkIncompleteFilter,
      checkValidSchedule,
    } = this.props;

    // Throw a swal if selection save is disabled
    if (this.shouldSavingBeDisabled()) {
      return SwalUtil.fire({
        title: 'Selection cannot be saved',
        // eslint-disable-next-line max-len
        message: 'This selection is marked as a template. Only admins or the person who created this selection can modify it.',
        options: {
          confirmButtonText: 'OK',
          allowOutsideClick: false,
        },
      });
    }

    handleSetSelectionState({ clickedSave: true });
    try {
      /**
       * Save selection is only possible in the following scenarios:
       * When schedule is not enabled and no incomplete filter
       * When schedule is enabled and valid, and selection can be run and no incomplete filter
       */
      if ((!enabledScheduleSelection || (enabledScheduleSelection &&
        await validateIfQueryCanBeRun(true) &&
        await checkMissingFieldsInRelations(true) &&
        await checkValidSchedule())) &&
        await checkIncompleteFilter()
      ) {
        const result = await saveSelection();

        handleSetSelectionState({
          captureSelectionChange: false,
          clickedSave: false,
        });

        if (result.success) {
          handleSetSelectionState({ showSaveToast: result.success, captureSelectionChange: false });

          // if the toast about auto-fix selection exists, close it
          if (toast.isActive(Constants.NOTIFICATION__TOAST_ID__AUTOFIX)) {
            toast.dismiss(Constants.NOTIFICATION__TOAST_ID__AUTOFIX);
            handleSetSelectionState({ showAutofixToast: false });
          }

          // show toast with message information
          toast.success(
            <Toast
              notificationText={Constants.NOTIFICATION__TEXT__SAVED}
            />,
            {
              position: toast.POSITION.TOP_RIGHT,
              className: 'toast-saved',
              toastId: Constants.NOTIFICATION__TOAST_ID__SAVE,
              hideProgressBar: true,
              autoClose: 2000,
              onClose: () => handleSetSelectionState({ showSaveToast: false }),
              containerId: Constants.NOTIFICATION__CONTAINER_ID__SELECTION,
              draggable: false,
              pauseOnHover: false,
              pauseOnFocusLoss: false,
            },
          );
        }
      } else {
        handleSetSelectionState({ clickedSave: false });
      }
    } catch (e) {
      handleSetSelectionState({ clickedSave: false });
    }
  };

  /**
   * Open the Schedule Selection Modal
   * @returns {void}
   */
  handleOpenScheduleSelection = () => {
    const { handleSetSelectionState, showEssentialsUpgradeModal, featuresInfo } = this.props;
    const featureScheduleSelections = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__SCHEDULE_SELECTIONS);

    if (!featureScheduleSelections) {
      showEssentialsUpgradeModal(Constants.FEATURE__SCHEDULE_SELECTIONS);
    }
    handleSetSelectionState({ showScheduleSelectionModal: true });
  };

  /**
   * Opens the template setting's modal
   * @returns {void}
   */
  handleOpenTemplateSettings = () => {
    const { handleSetSelectionState } = this.props;

    handleSetSelectionState({ showTemplateSettingsModal: true });
  };

  /**
   * Handles the disabling of template editing if the user is not an admin or
   * the template selection is not created by the active user
   * @returns {boolean} - Determines whether saving should be disabled
   */
  shouldSavingBeDisabled = () => {
    const {
      isTemplate, selectionCreator, selectionState: { isSelectionNewlyCreated }, userInfo,
    } = this.props;

    return !isSelectionNewlyCreated && isTemplate &&
      !userInfo.isAdmin && userInfo.id !== selectionCreator;
  };

  render() {
    const {
      editNewAutoTargetDE,
      editTargetDataExtension,
      showSaveToast,
      disablePreviewBTN,
      enabledScheduleSelection,
      runStatus,
      isTemplate,
      scheduleDisabled,
      backToWaterFall,
      clickedSave,
      selectionState,
      userInfo,
      featuresInfo,
      hasServer2ServerInstalledPackageInfo,
    } = this.props;

    const hasReadOnlyAccess = userInfo?.hasReadOnlyAccess;
    const hasServer2ServerInstalledPackage = Util.checkIsEnabledCookies(hasServer2ServerInstalledPackageInfo);
    const scheduleDisableReason = hasServer2ServerInstalledPackage ? '' : Constants.S2S_NOT_INSTALLED;
    const featureScheduleSelections = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__SCHEDULE_SELECTIONS);
    const featureSelectionTemplate = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__SELECTION_TEMPLATE);

    const { clickedRun } = this.state;

    // indicates whether the selection is running
    const isSelectionRunning = waterfallSelectionUtil.isWaterfallSelectionRunning(runStatus);

    // classNames for icons
    const classNameForSelectionSettingsIcon = classNames('slds-icon', isTemplate ? 'template-active' : 'template');
    const classNameForScheduleSelectionIcon = classNames(
      'slds-icon',
      enabledScheduleSelection ? 'schedule-active' : 'schedule',
    );

    // ClassName for save button
    const classNameForSaveButton = classNames(
      { 'greyed-out': this.shouldSavingBeDisabled() },
    );

    return (
      <div className="selection_navigation_buttons">
        {!selectionState?.isArchived && (
          <div
            className={classNames(
              'selection-template-btn-container',
              { 'disabled-overlay': !featureSelectionTemplate },
            )}>
            <Button
              id="selection-settings-button"
              buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
              onClick={this.handleOpenTemplateSettings}
            >
              <span
                className="slds-icon_container slds-icon-standard-account"
                title="Template settings"
              >
                <svg
                  className={classNameForSelectionSettingsIcon}
                  aria-hidden="true"
                  id="selection-settings-icon"
                >
                  <use xlinkHref="/assets/icons/standard-sprite/svg/symbols.svg#form" />
                </svg>
              </span>
              {
                !featureSelectionTemplate &&
                (featureAdvertExists(Constants.FEATURE__SELECTION_TEMPLATE) ?
                  <UpgradeBadge /> :
                  <Tooltip
                    nubbinPosition={Constants.NUBBIN_POSITION__TOP_RIGHT}
                    type={Constants.TOOLTIP_TYPE__UNAVAILABLE_FEATURE}
                  />)
              }
            </Button>
          </div>
        )}
        {!this.shouldSavingBeDisabled() && !selectionState?.isArchived && (
          <div
            className={classNames(
              'schedule-btn-container',
              { 'disabled-overlay': !featureScheduleSelections },
            )}
          >
            <Button
              id="open-schedule-selection"
              buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
              onClick={this.handleOpenScheduleSelection}
              disabled={scheduleDisabled || backToWaterFall?._id || !hasServer2ServerInstalledPackage}
              title={scheduleDisabled ? Constants.SCHEDULE_BUTTON_DISABLED__TITLE__ON_HOVER : scheduleDisableReason}
            >
              <span className="slds-icon_container slds-icon-standard-account" title="Schedule selection">
                <svg
                  className={classNameForScheduleSelectionIcon}
                  aria-hidden="true"
                  id="schedule-icon"
                  style={scheduleDisabled || backToWaterFall?._id || !hasServer2ServerInstalledPackage ?
                    { fill: 'gray' } :
                    {}}
                >
                  <use xlinkHref="/assets/icons/standard-sprite/svg/symbols.svg#service_appointment_capacity_usage" />
                </svg>
                <span className="slds-assistive-text">Schedule Selection</span>
              </span>
              {
                !featureScheduleSelections &&
                (featureAdvertExists(Constants.FEATURE__SCHEDULE_SELECTIONS) ?
                  <UpgradeBadge /> :
                  <Tooltip
                    nubbinPosition={Constants.NUBBIN_POSITION__TOP_RIGHT}
                    type={Constants.TOOLTIP_TYPE__UNAVAILABLE_FEATURE}
                  />)
              }
            </Button>
          </div>
        )}
        <div className="save-btn-container">
          <Button
            disabled={!!(clickedSave || editNewAutoTargetDE || editTargetDataExtension) || clickedRun ||
              showSaveToast || isSelectionRunning || selectionState?.isArchived || hasReadOnlyAccess}
            id="save-button"
            buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
            className={classNameForSaveButton}
            onClick={() => editNewAutoTargetDE || editTargetDataExtension ? null : this.handleSave()}
            loadingClickedButton={clickedSave}
            titleInAction="Saving..."
          >
            Save
          </Button>
        </div>

        {!this.shouldSavingBeDisabled() && (
          <div id="run-button-div" className="run-btn-container">
            <div>
              <Button
                disabled={!!(clickedRun || editNewAutoTargetDE || editTargetDataExtension) || backToWaterFall?._id ||
                  isSelectionRunning || disablePreviewBTN ||
                  clickedSave || selectionState?.isArchived || hasReadOnlyAccess}
                onClick={() => editNewAutoTargetDE || editTargetDataExtension ? null : this.handleRunQuery()}
                id="run-button"
                buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
                loadingClickedButton={clickedRun || isSelectionRunning}
                titleInAction="Running..."
              >
                <i className="fa fa-play" />
                Run
              </Button>
            </div>
            <GuidanceTip tipId="run-button-tip" toolTipPosition="right" />
          </div>
        )}

        <div className="cancel-btn-container">
          <Button
            disabled={clickedSave || clickedRun || disablePreviewBTN || showSaveToast}
            buttonLook={Constants.BUTTON__TYPE__BRAND_DARK}
            id={backToWaterFall ? 'Go-Back-to-WF' : 'cancel-button'}
            onClick={() => this.navigateToOverview()}
          >
            {backToWaterFall ?
              (
                <span>Go Back</span>
              ) :
              (
                <>
                  <svg className="slds-button__icon" aria-hidden="true">
                    <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#home" />
                  </svg>
                  <span>Home</span>
                </>
              )}
          </Button>
          <GuidanceTip tipId="overview-tip" toolTipPosition="right" />
        </div>
      </div>

    );
  }
}

NavigationButtons.propTypes = {
  /*
   * This function helps to navigate between pages in a Selection
   * This prop will be passed from Selection.js through Navbar.js
   */
  handleNavigator: PropTypes.func.isRequired,
  /*
   * It validates if the query can be run with the given data for the Selection
   * It will be passed from Selection.js through Navbar.js
   */
  validateIfQueryCanBeRun: PropTypes.func.isRequired,
  /*
   * It saves the selection to database with the given data for the Selection
   * It will be passed from Selection.js through Navbar.js
   */
  saveSelection: PropTypes.func.isRequired,
  /*
   * It helps to cancel a subscription of an API call to backend
   * It will be passed from Selection.js through Navbar.js
   */
  axiosCancelToken: PropTypes.instanceOf(Object),
  /*
   * It keeps the value of ac/de creation status
   * It will be passed from Selection.js through Navbar.js
   */
  editNewAutoTargetDE: PropTypes.bool,
  /**
   * boolean state from Selection for going in or out the edit target de mode
   */
  editTargetDataExtension: PropTypes.bool.isRequired,
  /*
   * It helps to set the Selection`s state
   * It will be passed from Selection.js
   */
  handleSetSelectionState: PropTypes.func.isRequired,
  /**
   * Boolean responsible for showing and closing toast
   */
  showSaveToast: PropTypes.bool,
  /**
   * Boolean responsible for disabling the cancel button if true
   */
  disablePreviewBTN: PropTypes.bool.isRequired,
  /**
   * Checks if there are any deleted fields in relations
   */
  checkMissingFieldsInRelations: PropTypes.func.isRequired,
  /**
   * Boolean responsible for changing color for the schedule button
   */
  enabledScheduleSelection: PropTypes.bool.isRequired,
  /*
   * State object of current selection
   * It will be passed from Selection.js through Navbar.js
   */
  selectionState: PropTypes.instanceOf(Object),
  /*
   *  Copied state object of current selection
   * It will be passed from Selection.js through Navbar.js
   */
  copiedSelectionState: PropTypes.instanceOf(Object),
  /*
   * It helps to check if filter is incomplete for relation
   * It will be passed from Selection.js
   */
  checkIncompleteFilter: PropTypes.func.isRequired,

  /*
   * It helps validate set schedule for selection
   * It will be passed from Selection.js
   */
  checkValidSchedule: PropTypes.func.isRequired,

  /*
   * Stores information about runStatus of current selection
   */
  runStatus: PropTypes.number,
  /*
   * It informs that there has been a change in the selection
   */
  captureSelectionChange: PropTypes.bool.isRequired,
  /**
   * Indicates whether a selection is a template
   */
  isTemplate: PropTypes.bool.isRequired,
  /**
   * Id of the user that created the selection
   */
  selectionCreator: PropTypes.string.isRequired,
  /**
   * Indicates whether a schedule button is disabled
   */
  scheduleDisabled: PropTypes.bool,
  /**
   * the waterFall we want to go back to, null if we do not
   */
  backToWaterFall: PropTypes.object,
  /**
   * It sets the App component`s state
   * This prop will be passed from App.js component
   */
  handleSetAppState: PropTypes.func.isRequired,
  /**
   * Boolean for saving a selection
   */
  clickedSave: PropTypes.bool,
  /**
   * It toggles a feature advert modal on with specific feature
   */
  showEssentialsUpgradeModal: PropTypes.func.isRequired,
  /**
   * User info from cookie
   */
  userInfo: PropTypes.object,
  /**
   * Features info from cookie
   */
  featuresInfo: PropTypes.object,
  /**
   * hasServer2ServerInstalledPackage info from cookie
   */
  hasServer2ServerInstalledPackageInfo: PropTypes.string,
};

export default connect(mapStateToProps(['userInfo', 'featuresInfo',
  'hasServer2ServerInstalledPackageInfo']), null, null, { pure: false })(NavigationButtons);
