import { useState, useEffect } from 'react';
import axios from 'axios';

import RelationsAPI from '../../api/relations';

/**
 *  Custom hook for updating predefined relations
 * @returns {Array} - Array that contains state of predefined relations
 */
const usePredefinedRelations = () => {
  const [predefinedRelations, setPredefinedRelations] = useState([]);

  /**
   * Fetches predefined relations and updates state.
   * @returns {void}
   */
  const fetchPredefinedRelations = async () => {
    const predefinedRelations = await RelationsAPI.getRelations(
      axios.CancelToken.source().token,
    );

    setPredefinedRelations(predefinedRelations.map(relation => (
      { ...relation, fromDeAlias: relation.fromDEName, toDEAlias: relation.toDEName }
    )));
  };

  useEffect(() => {
    fetchPredefinedRelations();
  }, []);

  // return custom hook elements
  return [predefinedRelations, setPredefinedRelations];
};

export { usePredefinedRelations };
