import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import axios from 'axios';
import { connect } from 'react-redux';

import Constants from '../../../constants/constants';
import FilterSetsAPI from '../../../api/filter-sets';
import FilterSetsCategories from './FilterSetsCategories/FilterSetsCategories';
import AdminHeader from '../../shared/AdminHeader/AdminHeader';
import ActionButton from '../../shared_v2/ActionButton/ActionButton';
import Table from '../../shared/Table/Table';
import timeUtil from '../../../utils/time/timeUtil';
import './styles.scss';
import mapStateToProps from '../../../mapStateToProps';

const FilterSets = ({
  filterSets = [],
  handleSetAdminPanelState,
  handleEditFilterSet,
  openPanel,
  handleSortFilterSets,
  sortDirection,
  sortedProperty,
  isLoading,
  handleCheckEditOrDelete,
  userInfo,
}) => {
  const [showFilterSetCategoriesModel, setFilterSetCategoriesModel] = useState(false);
  const DATEFORMAT = timeUtil.getUserDateTimeFormat(userInfo);

  const tableHeadersData = [
    {
      title: 'Name',
      propertyName: 'name',
      sortIconId: 'nameSort',
      leftSize: Constants.TABLE__CELL_LEFT_SIZE__SMALL,
    },
    {
      title: 'Category',
      propertyName: 'categoryName',
      sortIconId: 'categorySort',
      leftSize: Constants.TABLE__CELL_LEFT_SIZE__SMALL,
    },
    {
      title: 'Last Modified Date',
      propertyName: 'updatedAt',
      sortIconId: 'updatedAtSort',
      leftSize: Constants.TABLE__CELL_LEFT_SIZE__X_SMALL,
    },
    {
      title: 'Action',
    },
  ];

  /**
   * Deletes the filterSet selected in the
   * overview with the given id
   * @param {String} filterSetId - id of the filterSet to delete
   * @returns {void}
   */
  const handleRemoveFilterSet = async (filterSetId) => {
    const result = await Swal.fire({
      type: 'warning',
      title: 'Remove Filter Set',
      html: '<p class="width_swal">Are you sure you want to remove this Filter Set?</p>',
      showCancelButton: true,
      confirmButtonText: 'Remove',
      footer: '<div></div>',
      buttonsStyling: false,
    });

    if (result.value) {
      // Delete filterSet
      await FilterSetsAPI.deleteFilterSet(filterSetId, axios.CancelToken.source().token);

      // Reload data
      openPanel(Constants.ADMIN_PANEL__MENU__FILTER_SETS);
    }
  };

  const tableData = filterSets && filterSets.length > 0 ?
    (filterSets.map(filterSet => (
  <tr key={filterSet._id} className="slds-hint-parent row-data">
    <td data-label="Name">
      <div className="slds-truncate">
        <a
          href="#!"
          title={filterSet.name}
          onClick={(e) => {
            handleEditFilterSet(e, filterSet._id);
          }}
        >
          {filterSet.name}
        </a>
      </div>
    </td>
    <td data-label="Category">
      <div className="slds-truncate slds-m-left_x-small">
          {filterSet.category?.name || 'Uncategorized'}
      </div>
    </td>
    <td data-label="Last Modified Date">
      <div className="slds-truncate slds-m-left_x-small">{timeUtil.formatDate(filterSet.updatedAt, DATEFORMAT)}</div>
    </td>
    <td data-label="Action">
      <div className="filtersets-actions">
        <ActionButton
          rowIndex={filterSet._id}
          actions={[{
            title: 'Delete',
            iconCategory: 'utility',
            iconName: 'delete',
            actionHandler: () => handleRemoveFilterSet(filterSet._id),
          }]}
        />
      </div>
    </td>
  </tr>
    ))) :
    (
  <tr className="row-data">
    <td
      colSpan="5"
      className="slds-text-align_center"
    >
      <div id="no-filterSets-defined">
        No Filter Set have been defined yet. Hit the &apos;New Filter Set&apos; button to get started.
      </div>
    </td>
  </tr>
    );

  return (
    <>
      {/* Filter Sets header */}
      <AdminHeader
        title="Filter Sets"
        buttonId="new-filterSet"
        secondButtonId="manage-filterSet-categories"
        secondButtonLabel={'Manage Filter Set Categories'}
        secondButtonOnClick={() => setFilterSetCategoriesModel(true)}
        onClick={() => handleSetAdminPanelState({ activePanel: Constants.ADMIN_PANEL__MENU__NEW_FILTER_SET })}
        buttonLabel="New Filter Set"
        iconLink="/assets/icons/standard-sprite/svg/symbols.svg#picklist_type"
      />

      <Table
        tableHeaders={tableHeadersData}
        bodyContent={tableData}
        id="filterSets-panel"
        className="filterSet-table"
        handleSort={handleSortFilterSets}
        sortedProperty={sortedProperty}
        sortDirection={sortDirection}
        isLoading={isLoading}
      />

      {showFilterSetCategoriesModel && (
        <div style={{ position: 'relative', zIndex: 9999 }}>
        <FilterSetsCategories
        setFilterSetCategoriesModel={setFilterSetCategoriesModel}
        handleCheckEditOrDelete={handleCheckEditOrDelete}
        />
        </div>
      )}
    </>
  );
};

FilterSets.propTypes = {
  /**
   * array containing the filterSets retrieved, this prop comes
   * from the admin panel component
   */
  filterSets: PropTypes.instanceOf(Array).isRequired,
  /**
   * handles the state of the admin panel
   */
  handleSetAdminPanelState: PropTypes.func.isRequired,
  /**
   * function to open another panel / reload data
   */
  openPanel: PropTypes.func.isRequired,
  /**
   * function to open the edit screen for a filter set
   */
  handleEditFilterSet: PropTypes.func.isRequired,
  /**
   * It sorts filterSets
   */
  handleSortFilterSets: PropTypes.func.isRequired,
  /**
   * It indicates the direction of sort
   */
  sortDirection: PropTypes.string.isRequired,
  /**
   * It gives by which value sort will take place
   */
  sortedProperty: PropTypes.string,
  /**
   * Responsible for showing/hiding loader
   */
  isLoading: PropTypes.bool.isRequired,
  /**
   * Check if category is edited or deleted
   */
  handleCheckEditOrDelete: PropTypes.func.isRequired,
  /**
   * User info from cookie
   */
  userInfo: PropTypes.object,
};

export default connect(mapStateToProps(['userInfo']), null, null, { pure: false })(FilterSets);
