import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavBar } from '@DESelectMC/deselect-component-library';
import { toast } from 'react-toastify';
import { Popover, Input, Textarea } from '@salesforce/design-system-react';
import './styles.scss';
import { connect } from 'react-redux';

import mapStateToProps from '../../../mapStateToProps';
import Features from '../../../features';
import Constants from '../../../constants/constants';
import Util from '../../../util';
import SelectionFoldersAPI from '../../../api/selectionsFolders';
import SelectionsAPI from '../../../api/selections';
import WaterfallSelectionsAPI from '../../../api/waterfallSelections';
import RadioButton from '../../../components/shared/RadioButton/RadioButton';
import SelectionTemplate from '../../../components/Selection/Navbar/SelectionName/SelectionTemplate/SelectionTemplate';
import SqlToSelection from '../../../components/Selection/Navbar/SelectionName/SqlToSelection/SqlToSelection';
// import Input from '../../shared/Input/Input';
import Alert from '../../../components/shared/Alert/Alert';
import UpgradeBadge from '../../../components/shared/UpgradeBadge/UpgradeBadge';
import Tooltip from '../../shared/Tooltip/Tooltip';
import { featureAdvertExists } from '../../../components/shared/Feature/featureUtil';
// eslint-disable-next-line max-len
import Button from '../../shared_v2/Button/Button';
import SwalUtil from '../../../utils/swal/swalUtil';
import ConfirmHome from './Confirm';
import SelectionFolderPopup from './SelectionFolderPopup';
import { ReactComponent as HomeIcon } from '../../../icons_v2/home-icon.svg';

class SelectionName extends Component {
  constructor(props) {
    super(props);
    this.state = {
      folderName: null,
      showFoldersModal: false,
      foldersState: [],
      selections: [],
      selectionTemplates: [],
      mainFoldersState: [],
      mainFolderId: null,
      mainFolderName: null,
      selectedFromPreview: false,
      create: true,
      useTemplate: false,
      convertToSql: false,
      confirmNeeded: false,
      isUseTemplate: false,
      editName: false,
      tempSelectionName: '',
      tempSelectionDescription: '',
    };

    if (props.folderId === '') {
      this.state.foldersState = props.folders;
    } else {
      // get current folder
      const currentFolder = props.folders.filter(folder => folder._id === props.folderId);

      if (currentFolder && currentFolder.length > 0) {
        this.state.folderName = currentFolder[0].name;

        // set folder state
        if (currentFolder[0].children && currentFolder[0].children.length > 0) {
          this.state.foldersState = currentFolder[0].children;
        } else {
          this.state.foldersState = [];
        }
      } else {
        const currentFolders = props.folders.filter(folder => folder.parentFolderId === props.folderId);

        this.state.foldersState = currentFolders;
      }
    }

    const { foldersState, folderName } = this.state;
    // Sets the state of the folder view that remains once popup is closed

    this.state.mainFoldersState = foldersState;
    this.state.mainFolderName = folderName;
    this.state.mainFolderId = props.folderId;
    this.state.tempSelectionName = props.selectionName;
    this.state.tempSelectionDescription = props.selectionDescription;
  }

  async componentDidMount() {
    const {
      inModal, backToWaterFall, handleSetAppState, folderId,
    } = this.props;

    // Auto focus the name input in Selection Name Modal
    if (this.nameInput && inModal) this.nameInput.focus();

    /**
     * If we are coming from waterfall, we need to fetch the folders again
     */
    if (backToWaterFall) {
      const fetchedFolders = await this.fetchFolderData();

      const folders = this.getNestedSelectionFolders(fetchedFolders);

      handleSetAppState({ folders });

      const currentFolder = folders.find(folder => folder._id === folderId);

      let updatedFoldersState;

      /**
       * If folderId is null or undefined, then we are in uncategorized folder and we need to show all folders
       */
      if (folderId === null || folderId === '') {
        updatedFoldersState = folders;
      } else if (currentFolder) {
        updatedFoldersState = currentFolder.children && currentFolder.children.length > 0 ? currentFolder.children : [];
      } else {
        updatedFoldersState = folders.filter(folder => folder.parentFolderId === folderId);
      }

      this.setState({
        folderName: currentFolder ? currentFolder.name : null,
        foldersState: updatedFoldersState,
        mainFoldersState: updatedFoldersState,
        mainFolderName: currentFolder ? currentFolder.name : null,
        mainFolderId: folderId,
      });
    }

    // Fetch selection templates
    this.fetchSelectionTemplates();
  }

  componentDidUpdate(prevProps) {
    const { selectionName, selectionDescription } = this.props;

    if (selectionName !== prevProps.selectionName) {
      this.setState({ tempSelectionName: selectionName });
    }

    if (selectionDescription !== prevProps.selectionDescription) {
      this.setState({ tempSelectionDescription: selectionDescription });
    }
  }

  handleClose = () => {
    this.setState({ confirmNeeded: false });
  };

  /**
   * Returns API query params string for fetching selections
   * @param {string} folderId - folderId for which we are creating the query
   * @returns {string} query string
   */
  returnQueryForFetchingSelections = (folderId) => {
    // if folderId='0' then return '' (uncategorized folder)
    const id = folderId === '0' ? '' : folderId;

    // build query for selection - return first 15 records sorted by updatedAt in descending order
    return Util.queryForSelections({
      page: 1,
      folderId: id,
      value: null,
      criteria: null,
      sortBy: Constants.SELECTION__SEARCH_CRITERIA__UPDATED_AT,
      sortOrder: -1,
      limit: null,
      hasEnabledSchedule: null,
    });
  };

  /**
   * Connect parent+children. Make it out of the received json
   * @param {array} folders - current folders
   * @returns {array} out - connected parent + children
   */
  getNestedSelectionFolders = (folders) => {
    if (!folders) return [];

    // Helper function to build a hierarchy
    const buildHierarchy = (parentId) => {
      return folders
        .filter(folder => folder.parentFolderId === parentId)
        .map((folder) => {
          const children = buildHierarchy(folder._id);

          return children.length > 0 ? { ...folder, children } : { ...folder };
        });
    };

    // Build hierarchy for all nodes
    const allFolders = folders.map((folder) => {
      const children = buildHierarchy(folder._id);

      return children.length > 0 ? { ...folder, children } : { ...folder };
    });

    return allFolders;
  };

  fetchFolderData = async () => {
    try {
      const { axiosCancelToken } = this.props;
      const response = await SelectionFoldersAPI.getSelectionFolders(axiosCancelToken.token);
      const folders = response?.data || [];

      return folders;
    } catch (error) {
      return [];
    }
  };

  /**
   * Change states for this component
   * @param {object} newState - new states we want to change
   * @returns {void}
   */
  handleSetSelectionNameState = (newState) => {
    this.setState(newState);
  };

  /**
   * Gets and sets selections for selected folder Id
   * @param {object} folderId - folderId for which we are fetching the selections
   * @returns {void}
   */
  fetchSelectionsInFolder = async (folderId) => {
    const { isWaterfall, axiosCancelToken } = this.props;

    // get the API query params
    const query = this.returnQueryForFetchingSelections(folderId);

    let selections;

    if (isWaterfall) {
      // fetch waterfall selections
      const selectionResponse = await WaterfallSelectionsAPI.getWaterfallSelections(
        axiosCancelToken.token,
        query,
      );

      selections = selectionResponse.waterfallSelections;
    } else {
      // fetch selections
      const selectionResponse = await SelectionsAPI.getSelections(
        axiosCancelToken.token,
        query,
      );

      selections = selectionResponse.selections;
    }

    // set selections in state
    this.setState({
      selections,
    });
  };

  /**
   * Gets and sets selection templates
   * @returns {void}
   */
  fetchSelectionTemplates = async () => {
    const { isWaterfall, axiosCancelToken } = this.props;

    // API query params
    const selectionTemplateQuery = 'limit=-1&sortBy=name&sortOrder=1&isArchived=false&isTemplate=true';

    let selectionTemplates = [];

    if (!isWaterfall) {
      // fetch selections
      const selectionTemplatesResponse = await SelectionsAPI.getSelections(
        axiosCancelToken.token,
        selectionTemplateQuery,
      );

      selectionTemplates = selectionTemplatesResponse.selections;
    }

    // set selections in state
    this.setState({
      selectionTemplates,
    });
  };

  /**
   * get parent folders of the current folder
   * @param {string} folderName - name of the current folder
   * @returns {void}
   */
  goOneLevelBack = async (folderName) => {
    const { folders, handleSetAppState } = this.props;

    const transitionPopup = '.popupFolder > ul:nth-child(1)';

    // Set the transition styles to go back to previous folder
    if (document.querySelector(transitionPopup)) {
      document.querySelector(transitionPopup).style.transitionDuration = '0s';
      document.querySelector(transitionPopup).style
        .transform = 'translate(-100%,0)';
    }

    // get current folder by name
    const currentFolder = folders.filter(folder => folder.name === folderName);

    if (currentFolder && currentFolder.length > 0) {
      // get parent folders by parentFolderId of the current folder
      const parentFolders = folders.filter(folder => folder.parentFolderId === currentFolder[0].parentFolderId);

      if (parentFolders && parentFolders.length > 0) {
        // get grandparent folder
        const grandParentFolder = folders.filter(folder => folder._id === parentFolders[0].parentFolderId);

        this.setState({ foldersState: parentFolders });

        if (grandParentFolder && grandParentFolder.length > 0) {
          /*
           * if there is no grandparent folder we are getting back from
           * one level below top level folders
           */
          this.setState({ folderName: grandParentFolder[0].name });
          handleSetAppState({ folderId: grandParentFolder[0]._id });
          await this.fetchSelectionsInFolder(grandParentFolder[0]._id);
        } else {
          this.setState({ folderName: '' });
          handleSetAppState({ folderId: parentFolders[0].parentFolderId });
          await this.fetchSelectionsInFolder(parentFolders[0].parentFolderId);
        }
        // if there is no parent folder that means we are in top level folder
      } else {
        this.setState({ foldersState: parentFolders });
        await this.fetchSelectionsInFolder(currentFolder[0].parentFolderId);
      }
    }

    // Styles for the transition with delay to create the "going back" effect
    if (document.querySelector(transitionPopup)) {
      setTimeout(() => {
        document.querySelector(transitionPopup).style.transitionDuration = '.2s';
        document.querySelector(transitionPopup).style.transform = 'translate(0,0)';
      }, 200);
    }
  };

  /**
   * @param {boolean} confirmed - indicate if user confirms folder location
   * Open or close folders modal
   * @returns {void}
   */
  showFoldersModal = async (confirmed) => {
    const { axiosCancelToken } = this.props;

    const {
      currentSelectionId,
      folderId,
      handleSetAppState,
      folders,
      isWaterfall,
    } = this.props;

    const {
      showFoldersModal,
      mainFoldersState,
      mainFolderId,
      mainFolderName,
      foldersState,
      folderName,
      selectedFromPreview,
    } = this.state;

    // if user confirms folder location
    if (confirmed) {
      if (!selectedFromPreview) {
        this.setState({
          mainFoldersState: foldersState,
          mainFolderName: folderName,
          mainFolderId: folderId,
        });
      }

      let fetchedSelections;

      // if we are updating existing selection
      if (currentSelectionId) {
        const query = this.returnQueryForFetchingSelections(folderId);

        if (isWaterfall) {
          // update current waterfall selection folderId
          await WaterfallSelectionsAPI.updateWaterfallSelection(
            currentSelectionId,
            folderId,
            axiosCancelToken.token,
          );

          // refresh waterfall selections
          const retrievedData = await WaterfallSelectionsAPI.getWaterfallSelections(
            axiosCancelToken.token,
            query,
          );

          fetchedSelections = retrievedData.waterfallSelections;
        } else {
          // update current selection folderId
          await SelectionsAPI.updateSelection(
            currentSelectionId,
            folderId,
            axiosCancelToken.token,
          );

          // refresh selections
          const retrievedData = await SelectionsAPI.getSelections(
            axiosCancelToken.token,
            query,
          );

          fetchedSelections = retrievedData.selections;
        }

        this.setState({ selections: fetchedSelections });
      }

      // when we open folder modal again
    } else if (!confirmed) {
      // fetch selections in folder
      await this.fetchSelectionsInFolder(folderId);
    }

    // if open again user finds the actual selection folder
    if (showFoldersModal) {
      if (selectedFromPreview && confirmed) {
        /**
         * if the user confirms the new place for the selection
         * from the previous screen (marking it as green folder by clicking)
         * then we need to retrieve that children folder data to set as state
         * and main state (to remain in the same screen once popup is closed)
         */
        const currentFolder = folders.find(folder => folder._id === folderId);

        let newFolderName = null;

        let newFoldersState = [];

        // get the folder name and folder state depending on the current folder
        if (currentFolder) {
          newFolderName = currentFolder.name;
          if (currentFolder?.children?.length) {
            newFoldersState = currentFolder.children;
          } else {
            newFoldersState = [];
          }
        } else {
          newFoldersState = folders.filter(folder => folder.parentFolderId === folderId);
        }

        // change folders data
        this.setState({
          foldersState: newFoldersState,
          folderName: newFolderName,
          mainFoldersState: newFoldersState,
          mainFolderName: newFolderName,
          mainFolderId: folderId,
        });
      } else if (!confirmed) {
        // Sets the correct state values to remain in the same screen
        this.setState({
          foldersState: mainFoldersState,
          folderName: mainFolderName,
        });
        handleSetAppState({ folderId: mainFolderId });
      }
    }

    this.setState({
      showFoldersModal: !showFoldersModal,
      selectedFromPreview: false,
    });
  };

  /**
   * Change states for useTemplate
   * @param {object} status - current status
   * @returns {void}
   */
  handleFormElementChanged = (status) => {
    const { toggleSQLArea } = this.props;

    if (status === 1) {
      this.setState({
        useTemplate: true, convertToSql: false, create: false, isUseTemplate: true,
      });
      toggleSQLArea(false);
    } else if (status === 2) {
      this.setState({
        convertToSql: true, useTemplate: false, create: false, isUseTemplate: false,
      });
      toggleSQLArea(true);
    } else {
      this.setState({
        convertToSql: false, useTemplate: false, create: true, isUseTemplate: false,
      });
      toggleSQLArea(false);
    }
  };

  /**
   * Redirect to overview
   * @param {string} isRunClicked - Has the run button been clicked?
   * @returns {void}
   */
  // eslint-disable-next-line consistent-return
  navigateToOverview = async (isRunClicked = false) => {
    let appState;
    const {
      handleNavigator, copiedSelectionState, selectionState, captureSelectionChange,
      backToWaterFall, handleSetAppState, userInfo,
    } = this.props;

    const hasReadOnlyAccess = userInfo?.hasReadOnlyAccess;

    let copiedSelectionStateWithoutSomeProperties;

    // remove non-effective properties from states
    const selectionStateWithoutSomeProperties = (({
      dataExtensions, targetDataExtensions, loadingForDataExtensions,
      loadingForTargetDataExtensions, selectionNavigator, showSaveToast, copySuccess,
      isDataExtensionRequestDone, predefinedRelations, predefinedRelationsMap, runStatus,
      currentSelectionId, pickListFieldObjectIDs, pickLists, dataSets, filterSets, defaultSendRelationshipField,
      ...o
    }) => o)(selectionState);

    // if copy of selection was created
    if (copiedSelectionState) {
      copiedSelectionStateWithoutSomeProperties = (({
        dataExtensions, targetDataExtensions, loadingForDataExtensions,
        loadingForTargetDataExtensions, selectionNavigator, showSaveToast, copySuccess,
        isDataExtensionRequestDone, predefinedRelations, predefinedRelationsMap, runStatus,
        currentSelectionId, pickListFieldObjectIDs, pickLists, dataSets, filterSets, defaultSendRelationshipField,
        ...o
      }) => o)(copiedSelectionState);
    }

    if (backToWaterFall) {
      appState = {
        backToWaterFall: null,
        currentSelectionName: '',
        currentSelectionId: backToWaterFall._id,
        navigator: Constants.NAVIGATION__WATERFALL_SELECTION,
        globalNavigator: Constants.NAVIGATION__WATERFALL_SELECTION,
        folderId: backToWaterFall.folderId,
      };

      if (backToWaterFall.actionType === Constants.BACKTOWATERFALL__TYPE_CREATE) {
        if (selectionState?.targetDataExtension?.ObjectID) {
          appState.addSelectionToWFSelectedSelections = { selectionId: selectionState.currentSelectionId };
        } else {
          // show warning of incomplete selection
          const res = await SwalUtil.fire({
            title: 'Confirmation needed',
            message: `Please note that this selection will not be added to the Waterfall Selection
             since it does not have any assigned Target Data Extension. Are you sure you want to proceed?`,
            options: {
              confirmButtonText: 'OK',
              showCancelButton: true,
              allowOutsideClick: false,
            },
          });

          // if pressed cancel, stay
          if (!res.value) {
            return;
          }
        }
      }
    }

    // if run button clicked, we don't need to compare
    if (isRunClicked && backToWaterFall) {
      return handleSetAppState(appState);
    }
    if (isRunClicked) {
      toast.info(
        <div className="slds-notify slds-notify_toast slds-theme_info">
          <span className="slds-assistive-text">success</span>
          <span
            className="slds-icon_container slds-icon-utility-success slds-m-right_small slds-no-flex slds-align-top"
            title="Success"
          >
            <svg className="slds-icon slds-icon_small" aria-hidden="true">
              <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#success" />
            </svg>
          </span>
          <div>
            <p className="slds-text-title_bold">
              Running Selection
            </p>
            <p className="slds-text-body_small" style={{ marginTop: '0.5em' }}>
              Most Selections finish quickly, but this can take up to 30 minutes.
            </p>
          </div>
        </div>,
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'toast-info',
          toastId: Constants.NOTIFICATION__TOAST_ID__RUNNING,
          hideProgressBar: true,
          autoClose: 10000,
          containerId: Constants.NOTIFICATION__CONTAINER_ID__RUNNING,
          draggable: false,
          pauseOnHover: false,
          pauseOnFocusLoss: false,
        },
      );

      return handleNavigator(Constants.NAVIGATION__OVERVIEW, Constants.PAGE_RESET);
    }

    const nothingHasChanged = (JSON.stringify(selectionStateWithoutSomeProperties) ===
      JSON.stringify(copiedSelectionStateWithoutSomeProperties) ||
      !copiedSelectionStateWithoutSomeProperties) &&
      !captureSelectionChange || selectionState?.isArchived || hasReadOnlyAccess;

    /*
     * compare object deeply if anything changed show swal with warning
     * otherwise navigate to overview without warning
     */
    if (nothingHasChanged && backToWaterFall) {
      handleSetAppState(appState);
    } else if (nothingHasChanged) {
      handleNavigator(Constants.NAVIGATION__OVERVIEW);
    } else {
      // if pressed ok, go to waterFall or overview screen
      if (backToWaterFall) {
        handleSetAppState(appState);
      } else {
        handleNavigator(Constants.NAVIGATION__OVERVIEW);
      }
    }

    return null;
  };

  render() {
    const {
      folderName,
      showFoldersModal,
      foldersState,
      selections,
      selectionTemplates,
      create,
      useTemplate,
      convertToSql,
      isUseTemplate,
      confirmNeeded,
      editName,
      tempSelectionName,
      tempSelectionDescription,
    } = this.state;

    const {
      selectionName,
      handleSetAppState,
      folderId,
      folders,
      handleSetSelectionTemplate,
      isWaterfall,
      inModal,
      nameSelectionError,
      isArchived,
      showUseTemplate,
      axiosCancelToken,
      targetDataExtensions,
      loadingForTargetDataExtensions,
      targetDE,
      handleSetTargetDE,
      toggleCopy,
      handleToggleCopy,
      handleSetNewCopyState,
      loadingFoldersForCopy,
      showEssentialsUpgradeModal,
      handleOnChange,
      handleCheckSyntax,
      toggleSQLArea,
      sqlText,
      isValidating,
      isSyntaxValid,
      expandSQLArea,
      syntaxError,
      getNavBarOptions,
      backToWaterFall,
      handleSetSelectionName,
      featuresInfo,
      selectionDescription,
    } = this.props;

    const featureSqlToSelection = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__SQL_TO_SELECTION);
    const featureSelectionFolders = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__FOLDERS);
    const featureSelectionTemplates = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__SELECTION_TEMPLATE);

    const foldersCopy = [];

    // get folders without parents / top level folders
    foldersState.forEach((folder) => {
      if (folder.parentFolderId === '0') {
        foldersCopy.push(folder);
      }
    });

    // get selected folder for saving selection
    const selectedFolder = folders.filter(el => el._id === folderId);

    const elementContainerClassName = classNames(
      'slds-form-element__control slds-input-has-icon slds-input-has-icon_left input-folder-icon',
      { 'in-modal': inModal },
    );

    const spanContainerClassName = classNames(
      {
        'span-container-in-modal': inModal,
        'span-container-in-nav': !inModal,
        'disabled-overlay': !featureSelectionFolders,
      },
    );

    const saveSelectionInfo = () => {
      const { tempSelectionName, tempSelectionDescription } = this.state;
      const { handleSetSelectionState } = this.props;

      handleSetSelectionName({ target: { value: tempSelectionName } });
      handleSetSelectionState({ selectionDescription: tempSelectionDescription });

      this.setState({ editName: false });
    };

    return (
      <div className="selection-input">
        <NavBar
          options={getNavBarOptions()}
          selected="Segment"
        />
        <div className="slds-form-element">

          <div className={elementContainerClassName}>
            <Button
              buttonIcon={!backToWaterFall}
              className={classNames('selection-home-button', { 'back-to-waterfall': backToWaterFall })}
              title={backToWaterFall ? 'Back to Waterfall Selection' : 'Go to the Overview Page'}
              onClick={() => this.navigateToOverview()}
              buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
            >
              {backToWaterFall ?
                'Go Back' :
                (
                  <>
                    <HomeIcon />
                    <span className="home-btn-text">Home</span>
                  </>
                )}
            </Button>
            {featureSqlToSelection && showUseTemplate && !isWaterfall && (
              <span className="input-name">Name</span>
            )}

            <Popover
              hasNoCloseButton
              isOpen={editName}
              onOpen={() => this.setState({
                tempSelectionName: selectionName,
                tempSelectionDescription: selectionDescription,
              })}
              body={
                <div className="slds-form slds-form_stacked slds-p-top_medium slds-p-right_small popup-input-name">
                  <Input
                    id="first-name"
                    placeholder={`Enter name ${inModal ? '*' : ''}`}
                    label="Name"
                    onChange={e => this.setState({ tempSelectionName: e.target.value })}
                    value={tempSelectionName || ''}
                    maxLength="100"
                  />
                  <Textarea
                    id="unique-id-1"
                    label="Description"
                    onChange={e => this.setState({ tempSelectionDescription: e.target.value })}
                    value={tempSelectionDescription || ''}
                  />

                </div>
              }
              footer={
                <div className="slds-text-align_right" style={{ backgroundColor: 'white' }}>
                  <Button
                    buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
                    className="confirmPopupButton"
                    onClick={() => this.setState({ editName: false, tempSelectionName: '' })}
                  >
                    Cancel
                  </Button>

                  <Button
                    buttonLook={Constants.BUTTON__TYPE__BRAND}
                    className="confirmPopupButton"
                    onClick={() => saveSelectionInfo()}
                  >
                    Save
                  </Button>
                </div>
              }
              footerStyle={{ backgroundColor: 'white', border: 'none' }}
              id="popover-controlled-with-footer"
              align="bottom center"
            >
              <span
                onClick={() => this.setState({ editName: true })}
                className="selection-name">
                {selectionName || 'Enter name'}
              </span>
            </Popover>

            {inModal && (
              nameSelectionError ?
                (
                  <Alert
                    className="alert-visible"
                    title={`${isWaterfall ? 'Waterfall selection' : 'Selection'} with this name already exists.`}
                    id="nameCopyError-1"
                  />
                ) :
                <span className="input-name-comment">* You can edit this name later.</span>
            )}
            {showUseTemplate && !isWaterfall && (
              <div className="folder-container">
                <div className="folder-name">Folder</div>
              </div>
            )}
            {!isArchived && (
              <div
                className={spanContainerClassName}
                onClick={featureSelectionFolders ?
                  null :
                  () => showEssentialsUpgradeModal(Constants.FEATURE__FOLDERS)}
              >
                <span
                  className="folder-popup-icon slds-icon_container slds-icon-utility-announcement"
                  onClick={() => {
                    if (featureSelectionFolders) {
                      this.showFoldersModal();
                    }
                  }}
                  title="Choose folder for this selection"
                >
                  <svg className="slds-icon slds-icon-text-default slds-icon_small" aria-hidden="true">
                    <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#open_folder" />
                  </svg>
                </span>
                {
                  !featureSelectionFolders &&
                  (featureAdvertExists(Constants.FEATURE__FOLDERS) ?
                    <UpgradeBadge /> :
                    <Tooltip
                      nubbinPosition={Constants.NUBBIN_POSITION__TOP_RIGHT}
                      type={Constants.TOOLTIP_TYPE__UNAVAILABLE_FEATURE}
                    />)
                }
                {inModal && <span className="selected-folder-name">{folderName || 'All Selections'}</span>}
                <div
                  className={`folderPopup ${showFoldersModal && 'active'}`}
                  id={
                    // eslint-disable-next-line no-nested-ternary
                    convertToSql ?
                      'folder-popup-id-sql' :
                      useTemplate ?
                        'folder-popup-id-template' :
                        'folder-popup-id'
                  }
                >
                  <span className="currentFolderIn">
                    {foldersCopy.length === 0 ?
                      (
                        <svg
                          id="get-parent-folders"
                          className="slds-icon slds-icon-text-default slds-icon_x-small"
                          aria-hidden="true"
                          onClick={() => this.goOneLevelBack(folderName)}
                        >
                          <use
                            xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#chevronleft"
                          />
                        </svg>
                      ) :
                      null}
                    <span className="current-folder-name">
                      {Util.abbreviate(folderName, 33)}
                    </span>
                  </span>
                  <div className="makeSpaceFolder">
                    <SelectionFolderPopup
                      folders={foldersCopy.length > 0 ? foldersCopy : foldersState}
                      handleSetAppState={handleSetAppState}
                      folderId={folderId}
                      handleSetSelectionNameState={this.handleSetSelectionNameState}
                      selections={selections}
                      fetchSelectionsInFolder={this.fetchSelectionsInFolder}
                      isWaterfall={isWaterfall}
                    />
                  </div>
                  <span
                    className="closeFolderPopup slds-icon_container slds-icon-utility-announcement"
                    onClick={() => this.showFoldersModal()}
                  >
                    <svg className="slds-icon slds-icon-text-default slds-icon_x-small" aria-hidden="true">
                      <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#close" />
                    </svg>
                  </span>
                  {folderId && (
                    <Button
                      buttonLook={Constants.BUTTON__TYPE__BRAND}
                      className="confirmPopupButton"
                      onClick={() => this.showFoldersModal(true)}
                    >
                      Confirm
                    </Button>
                  )}

                  {selectedFolder[0] && (
                    <span className="selectedFolder" title={selectedFolder[0].name}>
                      {`Selected: ${selectedFolder[0].name}`}
                    </span>
                  )}
                </div>
              </div>
            )}

          </div>

          {showUseTemplate && !isWaterfall && featureSqlToSelection ?
            (
              <div className="radio-buttons">
                <div className="start-name">Start from</div>
                <div className="radio-button-font radio-three">
                  <RadioButton
                    id="3"
                    name="Scratch"
                    label="Scratch"
                    value="Scratch"
                    onChange={() => this.handleFormElementChanged(3)}
                    checked={create}
                  />
                  <p className="sql-text">
                    Build a Selection from the ground-up.
                  </p>
                </div>
                <div className="radio-one">
                  <div className="radio-button-font">
                    <RadioButton
                      id="1"
                      name="Template"
                      label="Template"
                      value="Template"
                      onChange={() => this.handleFormElementChanged(1)}
                      checked={useTemplate}
                    />
                    <p className="sql-text">
                      Use a saved template to save time.
                    </p>
                  </div>
                  {showUseTemplate && !isWaterfall && useTemplate &&
                    (<div className="selection-choose-template-one">
                      <SelectionTemplate
                        selectionTemplates={selectionTemplates || []}
                        axiosCancelToken={axiosCancelToken}
                        name="Select"
                        isUseTemplate={isUseTemplate}
                        targetDataExtensions={targetDataExtensions}
                        targetDE={targetDE}
                        handleSetTargetDE={handleSetTargetDE}
                        toggleCopy={toggleCopy}
                        featureSqlToSelection={featureSqlToSelection}
                        handleToggleCopy={handleToggleCopy}
                        loadingForTargetDataExtensions={loadingForTargetDataExtensions}
                        handleSetSelectionTemplate={handleSetSelectionTemplate}
                        handleSetNewCopyState={handleSetNewCopyState}
                        loadingFoldersForCopy={loadingFoldersForCopy}
                        featureSelectionTemplates={featureSelectionTemplates}
                        showEssentialsUpgradeModal={showEssentialsUpgradeModal}
                      />
                     </div>)}
                </div>
                <div className="radio-button-font radio-two">
                  <RadioButton
                    id="2"
                    name="SQL"
                    label="SQL"
                    value="SQL"
                    beta
                    onChange={() => this.handleFormElementChanged(2)}
                    checked={convertToSql}
                  />
                  <p className="sql-text">
                    Convert an existing SQL query to Selection format. Learn more
                    <a
                      href="https://support.deselect.com/hc/en-us/articles/18199215259537"
                      target="_blank"
                      className="link-spacing"
                      rel="noopener noreferrer">
                      here
                    </a>
                  </p>
                </div>
                {/* SQL to selection component */}
                {showUseTemplate && !isWaterfall && convertToSql && (
                  <div className="sql-to-selection">
                    <SqlToSelection
                      handleOnChange={handleOnChange}
                      handleCheckSyntax={handleCheckSyntax}
                      toggleSQLArea={toggleSQLArea}
                      sqlText={sqlText}
                      isValidating={isValidating}
                      isSyntaxValid={isSyntaxValid}
                      expandSQLArea={expandSQLArea}
                      syntaxError={syntaxError} />
                  </div>
                )}
              </div>
            ) :
            (
              <div>
                {showUseTemplate && !isWaterfall &&
                  (<div className="selection-choose-template">
                    <SelectionTemplate
                      selectionTemplates={selectionTemplates || []}
                      axiosCancelToken={axiosCancelToken}
                      targetDataExtensions={targetDataExtensions}
                      targetDE={targetDE}
                      name="Use Template"
                      handleSetTargetDE={handleSetTargetDE}
                      toggleCopy={toggleCopy}
                      handleToggleCopy={handleToggleCopy}
                      featureSqlToSelection={featureSqlToSelection}
                      loadingForTargetDataExtensions={loadingForTargetDataExtensions}
                      handleSetSelectionTemplate={handleSetSelectionTemplate}
                      handleSetNewCopyState={handleSetNewCopyState}
                      loadingFoldersForCopy={loadingFoldersForCopy}
                      featureSelectionTemplates={featureSelectionTemplates}
                      showEssentialsUpgradeModal={showEssentialsUpgradeModal}
                    />
                   </div>)}
              </div>
            )}
        </div>
        <div className="alert alert-danger" id="name-field-error" />
        {confirmNeeded && (
          <ConfirmHome
            navigateToOverview={this.navigateToOverview}
            backToWaterFall={backToWaterFall}
            handleClose={this.handleClose}
          />
        )}
      </div>
    );
  }
}

SelectionName.propTypes = {
  /*
   * It keeps the name of a selection
   * It will be passed from Selection.js through Navbar.js
   */
  selectionName: PropTypes.string,
  /*
   * It helps to set selection template in state
   */
  handleSetSelectionTemplate: PropTypes.func,
  /**
   * Folders for selection save
   */
  folders: PropTypes.instanceOf(Object).isRequired,
  /**
   * it sets the App component`s state
   * This prop will be passed from App.js component through Selection.js
   */
  handleSetAppState: PropTypes.func.isRequired,
  /**
   * It gives the id of the current selection
   */
  currentSelectionId: PropTypes.string,
  /**
   * It gives the id of folder
   */
  folderId: PropTypes.string.isRequired,
  /**
   * defines whether the component is used in waterfall selection
   */
  isWaterfall: PropTypes.bool,
  /*
   * It helps to cancel a subscription of an API call to backend
   * It will be passed from Selection.js through Navbar.js
   */
  axiosCancelToken: PropTypes.instanceOf(Object).isRequired,
  /*
   * Indicates that the component is used in the Selection Name Modal
   */
  inModal: PropTypes.bool,
  /*
   * Keeps information whether the name of the selection is wrong
   */
  nameSelectionError: PropTypes.bool,

  /**
   * Defines whether the Selection is archived
   */
  isArchived: PropTypes.bool,
  /**
   * Indicates if useTemplate should be shown or not
   */
  showUseTemplate: PropTypes.bool,
  /**
   * Array of target data extensions
   */
  targetDataExtensions: PropTypes.array,
  /**
   * Boolean value to check if target data extensions are loading
   */
  loadingForTargetDataExtensions: PropTypes.bool,
  /**
   * Target data extension
   */
  targetDE: PropTypes.object,
  /**
   * Function to set target data extension
   */
  handleSetTargetDE: PropTypes.func,
  /**
   * Boolean value to check if copy is toggled
   */
  toggleCopy: PropTypes.bool,
  /**
   * Function to toggle copy
   */
  handleToggleCopy: PropTypes.func,
  /**
   * Function to set new copy state for new auto created target DE
   */
  handleSetNewCopyState: PropTypes.func,
  /**
   * Boolean value to check if loading folders for copy
   */
  loadingFoldersForCopy: PropTypes.bool,
  /**
   * It toggles a feature advert modal on with specific feature
   */
  showEssentialsUpgradeModal: PropTypes.func,
  /**
   * the waterFall we want to go back to, null if we do not
   */
  backToWaterFall: PropTypes.object,
  expandSQLArea: PropTypes.bool,
  toggleSQLArea: PropTypes.func,
  /**
   * User info from cookie
   */
  userInfo: PropTypes.object,
  /**
   * Features info from cookie
   */
  featuresInfo: PropTypes.object,
};

export default connect(mapStateToProps(['userInfo', 'featuresInfo']), null, null, { pure: false })(SelectionName);
