import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import DatePicker from 'react-datepicker';
import classNames from 'classnames';
import moment from 'moment';
import { connect } from 'react-redux';

import mapStateToProps from '../../../../mapStateToProps';
import Constants from '../../../../constants/constants';
import Util from '../../../../util';
import ToggleButton from '../../../shared_v2/ToogleButton/ToggleButton';
import Select from '../../../shared_v2/Select/Select';
import Input from '../../../shared_v2/Input/Input';
import Checkbox from '../../../shared_v2/Checkbox/Checkbox';
import RadioButton from '../../../shared_v2/RadioButton/RadioButton';
import Tooltip from '../../../shared_v2/Tooltip/Tooltip';
import timeUtil from '../../../../utils/time/timeUtil';

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
const DataRetentionPolicy = ({
  individualRecords,
  allRecordsAndDE,
  allRecords,
  periodAfter,
  periodOn,
  resetRetentionPeriodOnImport,
  dataRetentionPeriod,
  dataRetentionPeriodLength,
  dataExtensionPeriodDate,
  dataRetentionPolicy,
  toggle,
  editTargetDataExtension,
  handleDataRetentionPolicyObjectAndCloseModal,
  userInfo,
}) => {
  /**
   * Activate or deactivate retention policy
   * @returns {void}
   */
  const handleActivate = () => {
    if (editTargetDataExtension) { return; }

    switch (toggle) {
      case false:
        handleDataRetentionPolicyObjectAndCloseModal({
          individualRecords: !individualRecords && !allRecords && !allRecordsAndDE ? true : individualRecords,
          dataRetentionPolicy: true,
          periodAfter: !periodAfter && !periodOn ? true : periodAfter,
          toggle: true,
        });
        break;
      case true:
        handleDataRetentionPolicyObjectAndCloseModal({
          dataRetentionPolicy: false,
          toggle: false,
        });
        break;
      default:
        break;
    }
  };

  /**
   * This function helps to change which records will be effected by the retention
   * e.g individual records, all records etc...
   * @param {object} e - event
   * @returns {void}
   */
  const handleOnChangeRadios = (e) => {
    if (editTargetDataExtension) { return; }

    switch (e.target.id) {
      // These values are id attr of the areas
      case Constants.DATA_RETENTION_POLICY__APPLY_CRITERIA__INDIVIDUAL_RECORDS:
        handleDataRetentionPolicyObjectAndCloseModal({
          individualRecords: true,
          allRecordsAndDE: false,
          allRecords: false,
          periodOn: false,
          periodAfter: true,
          resetRetentionPeriodOnImport: false,
        });
        break;
      case Constants.DATA_RETENTION_POLICY__APPLY_CRITERIA__ALL_RECORDS_DE:
        handleDataRetentionPolicyObjectAndCloseModal({
          individualRecords: false,
          allRecordsAndDE: true,
          allRecords: false,
        });
        break;
      case Constants.DATA_RETENTION_POLICY__APPLY_CRITERIA__ALL_RECORDS:
        handleDataRetentionPolicyObjectAndCloseModal({
          individualRecords: false,
          allRecordsAndDE: false,
          allRecords: true,
        });
        break;
      case Constants.DATA_RETENTION_POLICY__PERIOD__AFTER:
        handleDataRetentionPolicyObjectAndCloseModal({
          periodAfter: true,
          periodOn: false,
        });
        break;
      case Constants.DATA_RETENTION_POLICY__PERIOD__ON:
        handleDataRetentionPolicyObjectAndCloseModal({
          periodAfter: false,
          periodOn: true,
          resetRetentionPeriodOnImport: false,
        });
        break;
      default:
        break;
    }
  };

  /**
   * Change the value of period length
   * @param {object} e - event
   * @returns {void}
   */
  const handleOnPeriodLengthChange = (e) => {
    // do nothing if we are in edit mode
    if (editTargetDataExtension) { return; }

    if (e.target.validity.valid && e.target.name && e.target.name === 'dataRetentionPeriodLength') {
      /*
       * if field type is number and value doesn't containe only numbers
       * don't allow entering that value (eg. dot, comma, etc...)
       */
      if (!Util.containsOnlyNumbers(e.target.value) && e.target.value !== '') {
        return;
      }
      handleDataRetentionPolicyObjectAndCloseModal({
        [e.target.name]: e.target.value >= 1 ? e.target.value : 1,
        dataExtensionPeriodDate: '',
      });
    }
  };

  /**
   * This function helps with the date value for date picker
   * @param {object} e - event
   * @returns {void}
   */
  const handlePeriodDateChange = (e) => {
    // do nothing if we are in edit mode
    if (editTargetDataExtension) { return; }

    const newDate = e ? timeUtil.formatDateForDatePicker(e) : '';

    handleDataRetentionPolicyObjectAndCloseModal({
      dataExtensionPeriodDate: newDate,
      dataRetentionPeriodLength: 1,
      periodAfter: false,
      periodOn: true,
    });
  };

  /**
   * Handler for changing data retention period
   * @param {object} newState - object with the new state to be set
   * @returns {void}
   */
  const handleChangeDataRetentionPeriod = (newState) => {
    // do nothing if we are in edit mode
    if (editTargetDataExtension) { return; }

    // set new properties in the state
    handleDataRetentionPolicyObjectAndCloseModal({ ...newState });
  };

  /**
   * Determines whether or not to disable the reset period on import checkbox.
   * @param {object} dataRetentionPolicy - The data retention policy
   * @param {boolean} individualRecords - A boolean indicating if individual records are shown
   * @param {boolean} allRecords - A boolean indicating if all records are shown
   * @param {boolean} allRecordsAndDE - A boolean indicating if all records and data extensions are shown
   * @returns {boolean} True or false
   */
  const shouldResetPeriodOnImportCheckboxBeDisabled = (
    dataRetentionPolicy,
    individualRecords,
    allRecords,
    allRecordsAndDE,
  ) => {
    if (dataRetentionPolicy) {
      if (individualRecords) return true;
      if (allRecords) return false;

      return !allRecordsAndDE;
    }

    return true;
  };

  /**
   * Determines whether or not to disable the policy period radio / datepicker.
   * @param {boolean} individualRecords - A boolean indicating if individual records are shown
   * @param {boolean} allRecords - A boolean indicating if all records are shown
   * @param {boolean} allRecordsAndDE - A boolean indicating if all records and data extensions are shown
   * @returns {boolean} True or false
   */
  const shouldDataRetentionPolicyPeriodBeDisabled = (individualRecords, allRecords, allRecordsAndDE) => {
    if (individualRecords) return true;
    if (allRecords) return false;

    return !allRecordsAndDE;
  };

  const userLocale = timeUtil.getUserLocale(userInfo);

  /**
   * Returns the class name for data retention container - Delete or Period
   * @param {boolean} isPeriod - defines if className should be render for Period container
   * @returns {string} class name
   */
  const containerClassName = isPeriod => classNames(
    isPeriod ? 'period-container' : 'delete-container',
    {
      'disable-container': !dataRetentionPolicy,
      'disable-to-update': editTargetDataExtension,
    },
  );

  /**
   * Returns the class name for elements that change reset period on
   * @param {string} element - it is the name of the element for which we define the name of the class
   * @returns {string} class name
   */
  const resetPeriodOnClassName = element => classNames(
    {
      'disable-container': individualRecords,
      'slds-checkbox__label label-reset-period': element === 'checkbox',
      'slds-radio__label label-period-on': element === 'radio',
    },
  );

  const datePickerClassName = classNames(
    'form-control filter-value period-date-input',
    { 'disable-container': individualRecords || editTargetDataExtension },
  );

  /**
   * Returns date in proper format for DatePicker
   * @returns {string} formatted date
   */
  const selectedDateForDatePicker = () => {
    if (editTargetDataExtension && dataExtensionPeriodDate) {
      // RetainUntil date is formatted to the SFMC timezone, get the local datetime
      const localDateTime = timeUtil.formatDateInDifferentTimezoneToTheLocal(
        'America/Chicago',
        dataExtensionPeriodDate,
      );

      // SFMC doesn't take Daylight Saving into account, so if DST is used, add 1 hour
      if (moment.tz('America/Chicago').isDST()) {
        return moment(localDateTime).add(1, 'hours')._d;
      }

      return localDateTime;
    } if (dataExtensionPeriodDate) {
      return timeUtil.formatDateForDatePicker(dataExtensionPeriodDate)._d;
    }

    return '';
  };

  return (
    <div
      className={classNames('subcontainers', { 'disable-to-update': editTargetDataExtension })}
      id="container-data-retention-policy"
    >
      {/* RETENTION SETTING */}
      <ToggleButton
        name="checkbox-onoff"
        id="policy-switcher"
        onChange={() => handleActivate()}
        checked={toggle}
        value={toggle}
        disabled={editTargetDataExtension}
        label="Retention Settings"
      />
      <p>
        Determine how long the data in the Data Extension or
        the Data Extension itself exists before automatically being deleted.
      </p>
      {
        editTargetDataExtension &&
        <Tooltip
          nubbinPosition={Constants.NUBBIN_POSITION__TOP_LEFT}
          tooltipText="Retention settings of existing Data Extensions cannot be updated."
          customClassName="retention-read-only"
        />
      }

      {toggle && (
        <div className="DRP-part-two">
          <div className="retention-settings-container">
            <label>Delete:</label>

            <div className={containerClassName()}>
              <fieldset className="slds-form-element" disabled={!dataRetentionPolicy || editTargetDataExtension}>
                <div className="slds-form-element__control">
                  <RadioButton
                    id={Constants.DATA_RETENTION_POLICY__APPLY_CRITERIA__INDIVIDUAL_RECORDS}
                    value={individualRecords}
                    checked={individualRecords || false}
                    onChange={e => handleOnChangeRadios(e)}
                    label="Individual records"
                    disabled={editTargetDataExtension}
                  />
                  <RadioButton
                    id={Constants.DATA_RETENTION_POLICY__APPLY_CRITERIA__ALL_RECORDS_DE}
                    value={allRecordsAndDE}
                    checked={allRecordsAndDE || false}
                    onChange={e => handleOnChangeRadios(e)}
                    label="All records and data extensions"
                    disabled={editTargetDataExtension}
                  />
                  <RadioButton
                    id={Constants.DATA_RETENTION_POLICY__APPLY_CRITERIA__ALL_RECORDS}
                    value={allRecords}
                    checked={allRecords || false}
                    onChange={e => handleOnChangeRadios(e)}
                    label="All records"
                    disabled={editTargetDataExtension}
                  />
                </div>
              </fieldset>
            </div>
          </div>

          <div className="retention-settings-container">
            <label>Period:</label>
            <div
              className={containerClassName(true)}
            >
              <fieldset className="slds-form-element">
                <div className="after-period-container mb-8px">
                  <div className="slds-form-element__control">
                    <RadioButton
                      id={Constants.DATA_RETENTION_POLICY__PERIOD__AFTER}
                      checked={periodAfter || (false && periodOn === true)}
                      onChange={e => handleOnChangeRadios(e)}
                      label="After"
                      disabled={!dataRetentionPolicy || editTargetDataExtension}
                    />
                  </div>

                  <div className="group-slds-after">
                    <Input
                      withContainer
                      type="number"
                      name="dataRetentionPeriodLength"
                      id="text-input-id-5"
                      required
                      className="slds-input_counter after-number-input"
                      value={dataRetentionPeriodLength || false}
                      onChange={(e) => { handleOnPeriodLengthChange(e); }}
                      disabled={!periodAfter || editTargetDataExtension}
                    />

                    <div className="slds-form-element">
                      <div className="slds-form-element__control">
                        <Select
                          className="after-period-select"
                          id="select-01"
                          value={dataRetentionPeriod || false}
                          onChange={(e) => {
                            handleChangeDataRetentionPeriod({ dataRetentionPeriod: e.target.value });
                          }}
                          disabled={!periodAfter || editTargetDataExtension}
                          options={[
                            {
                              value: Constants.DATA_RETENTION_POLICY__PERIOD__DAYS,
                              label: Constants.DATA_RETENTION_POLICY__PERIOD__DAYS,
                            },
                            {
                              value: Constants.DATA_RETENTION_POLICY__PERIOD__WEEKS,
                              label: Constants.DATA_RETENTION_POLICY__PERIOD__WEEKS,
                            },
                            {
                              value: Constants.DATA_RETENTION_POLICY__PERIOD__MONTHS,
                              label: Constants.DATA_RETENTION_POLICY__PERIOD__MONTHS,
                            },
                            {
                              value: Constants.DATA_RETENTION_POLICY__PERIOD__YEARS,
                              label: Constants.DATA_RETENTION_POLICY__PERIOD__YEARS,
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <Checkbox
                  formElementClassName="wrapper-secondPart mb-8px"
                  name="options"
                  id="reset-period-on-import"
                  value="reset-period-on-import"
                  checked={resetRetentionPeriodOnImport || false}
                  className={resetPeriodOnClassName()}
                  onChange={() => {
                    handleChangeDataRetentionPeriod({
                      resetRetentionPeriodOnImport: !resetRetentionPeriodOnImport,
                      periodAfter: true,
                      periodOn: false,
                    });
                  }}
                  disabled={shouldResetPeriodOnImportCheckboxBeDisabled(
                    dataRetentionPolicy,
                    individualRecords,
                    allRecords,
                    allRecordsAndDE,
                  ) || editTargetDataExtension}
                  labelClassName={resetPeriodOnClassName('checkbox')}
                  label="Reset period on import"
                />
                <div
                  className="slds-form-element__control wrapper-thirdPart mb-8px"
                  id="label-period-on-container"
                >
                  <RadioButton
                    containerClassName="slds-radio"
                    id={Constants.DATA_RETENTION_POLICY__PERIOD__ON}
                    checked={periodOn || false}
                    onChange={e => handleOnChangeRadios(e)}
                    disabled={shouldDataRetentionPolicyPeriodBeDisabled(
                      individualRecords,
                      allRecords,
                      allRecordsAndDE,
                    ) || editTargetDataExtension}
                    labelClassName={resetPeriodOnClassName('radio')}
                    label="On"
                  />
                  <DatePicker
                    fixedHeight
                    popperPlacement="top-start"
                    className={datePickerClassName}
                    style={{ position: 'absolute' }}
                    name="dataExtensionPeriodDate"
                    type="text"
                    dateFormat={timeUtil.getDatePickerDateFormat(userLocale)}
                    minDate={new Date()}
                    selected={selectedDateForDatePicker()}
                    onChange={e => handlePeriodDateChange(e)}
                    disabled={shouldDataRetentionPolicyPeriodBeDisabled(
                      individualRecords,
                      allRecords,
                      allRecordsAndDE,
                    ) || editTargetDataExtension}
                    data-placement="top-start"
                  />
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

DataRetentionPolicy.propTypes = {
  /**
   * It helps to close the data retention policy modal
   * It will be passed from NewAutoCreatedTargetDE.js
   */
  handleDataRetentionPolicyObjectAndCloseModal: PropTypes.func.isRequired,
  /**
   * It keeps data retention policy state for the new target data extension
   * It will be passed from Selection.js
   */
  dataRetentionPolicy: PropTypes.instanceOf(Object),
  /**
   * Indicates if target data extension is updating
   */
  editTargetDataExtension: PropTypes.bool.isRequired,
  /**
   * User info from cookie
   */
  userInfo: PropTypes.object,
  /**
   * A boolean indicating if the data retention policy is active
   */
  toggle: PropTypes.bool.isRequired,
  /**
   * A boolean indicating if individual records are shown
   */
  individualRecords: PropTypes.bool.isRequired,
  /**
   * A boolean indicating if all records and data extensions are shown
   */
  allRecordsAndDE: PropTypes.bool.isRequired,
  /**
   * A boolean indicating if all records are shown
   */
  allRecords: PropTypes.bool.isRequired,
  /**
   * A boolean indicating if period after is selected
   */
  periodAfter: PropTypes.bool.isRequired,
  /**
   * A boolean indicating if period on is selected
   */
  periodOn: PropTypes.bool.isRequired,
  /**
   * A boolean indicating if reset retention period on import is selected
   */
  resetRetentionPeriodOnImport: PropTypes.bool.isRequired,
  /**
   * Indicating what's selected for data retention period (days, weeks, months, years)
   */
  dataRetentionPeriod: PropTypes.string.isRequired,
  /**
   * Length of the data retention period
   */
  dataRetentionPeriodLength: PropTypes.number.isRequired,
  /**
   * Date for data extension period
   */
  dataExtensionPeriodDate: PropTypes.string.isRequired,
};

export default connect(mapStateToProps(['userInfo']), null, null, { pure: false })(DataRetentionPolicy);
