const validationConstants = {
  // TOKEN TYPEs
  TOKEN_TYPE__STRING: 'string',
  TOKEN_TYPE__OPEN_PAREN: 'open-parenthesis',
  TOKEN_TYPE__CLOSED_PAREN: 'closed-parenthesis',
  TOKEN_TYPE__NUMBER: 'number',
  TOKEN_TYPE__FIELD: 'field',
  TOKEN_TYPE__OPERATOR: 'operator',
  TOKEN_TYPE__DELIMITER: 'delimiter',
  TOKEN_TYPE__FUNCTION: 'function',
  TOKEN_TYPE__KEYWORD: 'keyword',
  TOKEN_TYPE__DECIMAL: 'decimal',
  TOKEN_TYPE__COMPARISON_OPERATOR: 'comparison-operator',
  TOKEN_TYPE__SPACE: 'space',
  TOKEN_TYPE__CHAR: 'char',
  TOKEN_TYPE__NEW_LINE: 'new-line',

  ROOT_NODE__PROGRAM: 'Program',

  // PARAM TYPES
  PARAM_TYPE__DATE: 'date',
  PARAM_TYPE__ANY: 'any',
  PARAM_TYPE__KEYWORD: 'keyword',

  // KEYWORDS
  KEYWORD__NULL: 'NULL',
  KEYWORD__YEAR: 'year',
  KEYWORD__YYYY: 'yyyy',
  KEYWORD__YY: 'yy',
  KEYWORD__MONTH: 'month',
  KEYWORD__MM: 'mm',
  KEYWORD__M: 'm',
  KEYWORD__QUARTER: 'quarter',
  KEYWORD__QQ: 'qq',
  KEYWORD__Q: 'q',
  KEYWORD__DAY_OF_YEAR: 'dayofyear',
  KEYWORD__DY: 'dy',
  KEYWORD__Y: 'y',
  KEYWORD__DAY: 'day',
  KEYWORD__DD: 'd',
  KEYWORD__D: 'd',
  KEYWORD__WEEK: 'week',
  KEYWORD__WW: 'ww',
  KEYWORD__WK: 'wk',
  KEYWORD__WEEKDAY: 'weekday',
  KEYWORD__DW: 'dw',
  KEYWORD__W: 'w',
  KEYWORD__HOUR: 'hour',
  KEYWORD__HH: 'hh',
  KEYWORD__MINUTE: 'minute',
  KEYWORD__MI: 'mi',
  KEYWORD__N: 'n',
  KEYWORD__SECOND: 'second',
  KEYWORD__SS: 'ss',
  KEYWORD__S: 's',
  KEYWORD__MILLISECOND: 'millisecond',
  KEYWORD__MS: 'ms',
  KEYWORD__SESSION_USER: 'SESSION_USER',
  KEYWORD__SYSTEM_USER: 'SYSTEM_USER',
  KEYWORD__CURRENT_TIMESTAMP: 'CURRENT_TIMESTAMP',
  KEYWORD__ANSI_NULLS: 'ANSI_NULLS',
  KEYWORD__ANSI_PADDING: 'ANSI_PADDING',
  KEYWORD__ANSI_WARNINGS: 'ANSI_WARNINGS',
  KEYWORD__ARITHABORT: 'ARITHABORT',
  KEYWORD__CONCAT_NULL_YIELDS_NULL: 'CONCAT_NULL_YIELDS_NULL',
  KEYWORD__NUMERIC_ROUNDABOUT: 'NUMERIC_ROUNDABOUT',
  KEYWORD__QUOTED_IDENTIFIER: 'QUOTED_IDENTIFIER',
  KEYWORD__WHEN: 'WHEN',
  KEYWORD__END: 'END',
  KEYWORD__CASE: 'CASE',
  KEYWORD__THEN: 'THEN',
  KEYWORD__LIKE: 'LIKE',
  KEYWORD__ELSE: 'ELSE',

  // CASE WHEN constants
  CASE_WHEN: 'case-when',
  WHEN_STATEMENT: 'when-statement',
  THEN_STATEMENT: 'then-statement',
  ELSE_STATEMENT: 'else-statement',
  RESULT_STATEMENT: 'result-statement',
  CASE_KEYWORD: 'case-keyword',
  END_KEYWORD: 'end-keyword',
  KEYWORD__BINARY: 'binary',
  KEYWORD__VARBINARY: 'varbinary',
  KEYWORD__CHAR: 'char',
  KEYWORD__VARCHAR: 'varchar',
  KEYWORD__NCHAR: 'nchar',
  KEYWORD__NVARCHAR: 'nvarchar',
  KEYWORD__DATETIME: 'datetime',
  KEYWORD__DATE: 'date',
  KEYWORD__TIME: 'time',
  KEYWORD__DATETIMEOFFSET: 'datetimeoffset',
  KEYWORD__DATETIME2: 'datetime2',
  KEYWORD__DECIMAL: 'decimal',
  KEYWORD__NUMERIC: 'numeric',
  KEYWORD__FLOAT: 'float',
  KEYWORD__REAL: 'real',
  KEYWORD__BIGINT: 'bigint',
  KEYWORD__INT: 'int',
  KEYWORD__SMALLINT: 'smallint',
  KEYWORD__TINYINT: 'tinyint',
  KEYWORD__MONEY: 'money',
  KEYWORD__SMALLMONEY: 'smallmoney',
  KEYWORD__BIT: 'bit',
  KEYWORD__TIMESTAMP: 'timestamp',
  KEYWORD__UNIQUEIDENTIFIER: 'uniqueidentifier',
  KEYWORD__NTEXT: 'ntext',
  KEYWORD__TEXT: 'text',
  KEYWORD__SQL_VARIANT: 'sql_variant',
  KEYWORD__XML: 'xml',
  KEYWORD__HIERARCHYID: 'hierarchyid',
};

export default validationConstants;
