/*
 * Array of objects of hardcoded behavioral filter sets
 * Category is an object containing category name
 * SourceType  source type is dataExtension
 * dataViewName contains the name of the data view to be selected
 * filterType type of filter to be selected
 * _id unique id for the behavioral filter sets
 */
export default [
  {
    category: { name: 'Behavioral' },
    name: 'Clicked in the past x days',
    sourceType: 'dataExtension',
    dataViewName: '_Click',
    filterType: 'in-results',
    _id: '1',
  },
  {
    category: { name: 'Behavioral' },
    name: 'Received in the past x days',
    sourceType: 'dataExtension',
    dataViewName: '_Sent',
    filterType: 'in-results',
    _id: '2',
  },
  {
    category: { name: 'Behavioral' },
    dataExtensionObjectId: '',
    name: 'Opened in the past x days',
    sourceType: 'dataExtension',
    dataViewName: '_Open',
    filterType: 'in-results',
    _id: '3',
  },
  {
    category: { name: 'Behavioral' },
    name: 'Bounced in the past x days',
    sourceType: 'dataExtension',
    filterType: 'in-results',
    dataViewName: '_Bounce',
    _id: '4',
  },
  {
    category: { name: 'Behavioral' },
    name: 'Did not click in the past x days',
    sourceType: 'dataExtension',
    filterType: 'not-in-results',
    dataViewName: '_Click',
    _id: '5',
  },
  {
    category: { name: 'Behavioral' },
    name: 'Did not receive in the past x days',
    sourceType: 'dataExtension',
    filterType: 'not-in-results',
    dataViewName: '_Sent',
    _id: '6',
  },
  {
    category: { name: 'Behavioral' },
    name: 'Did not open in the past x days',
    dataViewName: '_Open',
    filterType: 'not-in-results',
    sourceType: 'dataExtension',
    _id: '7',
  },
];
