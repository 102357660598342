import _ from 'lodash';

import Util from '../../../util';
import waterfallSelectionUtil from '../../../utils/waterfallSelection/waterfallSelectionUtil';
import {
  SET_ALL_SELECTIONS, SET_SELECTED_SELECTIONS,
  LOADING_SELECTIONS, REMOVE_SELECTED_SELECTION, START_LOADING_SELECTION_ID,
  STOP_LOADING_SELECTION_ID, SET_SELECTED_SELECTION_DATA, SET_SELECTIONS_FOR_RUN_CHAIN,
  SET_SELECTION_CHAIN, REMOVE_SELECTED_SELECTIONS_BY_SELECTION_ID,
  SET_SELECTIONS_WITH_MISSING_TARGET_DE,
  SET_SELECTIONS_WITH_NO_MATCHED_FIELD,
  SET_SELECTIONS_WITHOUT_MAPPED_REQUIRED_FIELDS,
  SET_WATERFALL_SCHEDULES,
  SET_WATERFALL_SCHEDULE,
} from '../../actions/types';

const initialState = {
  allSelections: [],
  loadingSelections: false,
  selectedSelections: [],
  loadingSelectionIds: [],
  selectionChain: [],
  selectionsForRunChain: [],
  selectionsWithMissingTargetDE: [],
  selectionsWithNoMatchedField: [],
  selectionsWithoutMappedRequiredFields: [],
  selectionsSchedules: {},
};

/**
 * Function that takes an action and the previous state of the application and returns the new state
 * @param {object} state - contain initial and final state of data
 * @param {object} action - return the action object
 * @returns {object} updated state
 */
const selectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_SELECTIONS:
      return {
        ...state,
        allSelections: action.payload,
      };
    case SET_SELECTIONS_WITH_MISSING_TARGET_DE:
    {
      // save only unique, not duplicated values
      const selectionsWithoutDuplicate = _.uniq(_.flattenDeep(
        [...state.selectionsWithMissingTargetDE, action.payload],
      ));

      return {
        ...state,
        selectionsWithMissingTargetDE: selectionsWithoutDuplicate,
      };
    }
    case SET_SELECTIONS_WITHOUT_MAPPED_REQUIRED_FIELDS:
    {
      // save only unique, not duplicated values
      const selectionsWithoutDuplicate = _.uniq(
        _.flattenDeep([...state.selectionsWithoutMappedRequiredFields, action.payload]),
      );

      return {
        ...state,
        selectionsWithoutMappedRequiredFields: selectionsWithoutDuplicate,
      };
    }
    case SET_SELECTIONS_WITH_NO_MATCHED_FIELD:
    {
      // save only unique, not duplicated values
      const selectionsWithoutDuplicate = _.uniq(_.flattenDeep([...state.selectionsWithNoMatchedField, action.payload]));

      return {
        ...state,
        selectionsWithNoMatchedField: selectionsWithoutDuplicate,
      };
    }
    case SET_SELECTED_SELECTIONS:
      return {
        ...state,
        selectedSelections: action.payload,
      };
    case REMOVE_SELECTED_SELECTION:
      return {
        ...state,
        selectedSelections: state.selectedSelections.filter(selection => selection._id !== action.payload._id),
      };
    case REMOVE_SELECTED_SELECTIONS_BY_SELECTION_ID:
      return {
        ...state,
        selectedSelections: state.selectedSelections.reduce((selectedSelections, selection) => {
          const getSelectionIdFromPayload = action.payload.find(payload => payload === selection.selectionId);

          if (!getSelectionIdFromPayload) {
            return [...selectedSelections, { ...selection }];
          }

          return selectedSelections;
        }, []),
      };
    case LOADING_SELECTIONS:
      return {
        ...state,
        loadingSelections: action.payload,
      };
    case START_LOADING_SELECTION_ID:
      return {
        ...state,
        loadingSelectionIds: _.flatten([...state.loadingSelectionIds, action.payload]),
      };
    case STOP_LOADING_SELECTION_ID:
      return {
        ...state,
        // reduce an array with selection IDs
        loadingSelectionIds: state.loadingSelectionIds.reduce((selectionIdsArray, selectionId) => {
          // find selection in action.payload
          const getSelectionIdFromPayload = action.payload.find(payload => payload === selectionId);

          // do not return selection when it's stops loading
          if (!getSelectionIdFromPayload) {
            return [...selectionIdsArray, selectionId];
          }

          return selectionIdsArray;
        }, []),
      };
    case SET_SELECTED_SELECTION_DATA:
    {
      // when we fetch multiple selections for the first time
      if (action.payload?.length > 1) {
        const selectionsData = action.payload.map((payload) => {
          // get object IDs of data extensions used as selected, in filters and in aggregation custom values
          const selectedDEObjectIDs = waterfallSelectionUtil.getSourceDEsUsedInSelection(
            payload.unionSelections,
          );

          // remove duplicate values in flatten array
          const selectedDEObjectIDsWithoutDuplicates = _.uniq(_.flattenDeep(selectedDEObjectIDs));

          // get matched fields with targetDE
          const matchedFields = [];

          payload.unionSelections.forEach((union) => {
            if (union?.fields?.length) {
              matchedFields.push(union.fields);
            }
          });

          return {
            ...payload,
            selectedDEObjectIDs: selectedDEObjectIDsWithoutDuplicates,
            _id: Util.uuid(32),
            selectionId: payload._id,
            matchedFields: _.flattenDeep(matchedFields),
          };
        });

        return { ...state, selectedSelections: [...selectionsData] };
      }

      if (action.payload?.length) {
        // for drag&drop we fetch single selection

        // check if this selection has been fetched
        const { selectionId, _id } = action.payload[0];
        const getSelectedSelectionById = state.selectedSelections.filter(selection => selection.selectionId ===
          selectionId);

        // if we already have fetched data
        if (getSelectedSelectionById?.length > 1) {
          // get fetched selection data
          const fetchedSelectionData = state.selectedSelections.find(selection => selection.selectionId ===
            selectionId && selection.selectedDEObjectIDs);

          return {
            ...state,
            selectedSelections: state.selectedSelections.map((selection) => {
              // set the data of fetched selection to avoid executing the same api call a second time
              if (selection._id === _id && fetchedSelectionData) {
                return {
                  ...fetchedSelectionData,
                  _id: selection._id,
                  selectionId: selection.selectionId,
                };
              }

              return selection;
            }),
          };
        }
        // when we fetch data for the first time

        // get all union selections property from selection
        const { unionSelections } = action.payload[0];

        // get object IDs of data extensions used as selected, in filters and in aggregation custom values
        const selectedDEObjectIDs = waterfallSelectionUtil.getSourceDEsUsedInSelection(
          unionSelections,
        );

        // remove duplicate values in flatten array
        const selectedDEObjectIDsWithoutDuplicates = _.uniq(_.flattenDeep(selectedDEObjectIDs));

        // get matched fields with targetDE
        const matchedFields = [];

        unionSelections.forEach((union) => {
          if (union?.fields?.length) {
            matchedFields.push(union.fields);
          }
        });

        return {
          ...state,
          selectedSelections: state.selectedSelections.map((selection) => {
            if (selection.selectionId === _id && selectedDEObjectIDs) {
              // set proper data in state with selectedDEObjectIDs property
              return {
                ...selection,
                _id: selection._id,
                selectionId: selection.selectionId,
                selectedDEObjectIDs: selectedDEObjectIDsWithoutDuplicates,
                matchedFields: _.flattenDeep(matchedFields),
              };
            }

            return selection;
          }),
        };
      }

      return state;
    }
    case SET_SELECTIONS_FOR_RUN_CHAIN:
      return {
        ...state,
        selectionsForRunChain: action.payload.selectionsToChain,
      };
    case SET_SELECTION_CHAIN:
      return {
        ...state,
        selectionChain: action.payload.selectionChain,
      };
    case SET_WATERFALL_SCHEDULES:
      return {
        ...state,
        selectionsSchedules: action.payload,
      };
    case SET_WATERFALL_SCHEDULE:
      return {
        ...state,
        selectionsSchedules: {
          ...state.selectionsSchedules,
          [action.payload.selectionId]: action.payload.selectionWaterfallSchedules,
        },
      };
    default:
      return state;
  }
};

export default selectionReducer;
