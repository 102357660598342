import React from 'react';
import PropTypes from 'prop-types';

const LoadingText = ({ firstPhrase, boldText }) => {
  return (
    <p className="loading-text-wrapper">
      <span>
        {firstPhrase}
      </span>
      <span className="bold-loading-text">
        <b>{boldText}</b>
        &nbsp;
        <span>
          is loading...
        </span>
      </span>
    </p>
  );
};

LoadingText.propTypes = {
  /**
   * The string phrase that appears in the first span element in the component
   */
  firstPhrase: PropTypes.string.isRequired,
  /**
   * Bold text sentence
   */
  boldText: PropTypes.string.isRequired,
};

export default LoadingText;
