import React from 'react';
import './styles.scss';
import PropTypes from 'prop-types';

const ToggleButton = ({
  label,
  labelId,
  labelClassName,
  id,
  name,
  onChange,
  checked,
  checkboxOnTitle,
  checkboxOffTitle,
  disabled,
  toggleClassName,
  value,
  checkboxOnId,
  checkboxOffId,
}) => (
  <label
    className={`slds-checkbox_toggle slds-grid ${labelClassName || ''}`}
    id={labelId || 'toggle-button-label'}
    htmlFor={id}
  >
    {label ?
      (
        <span className="slds-form-element__label slds-m-bottom_none">
          {label}
        </span>
      ) :
      null }
    <div className="on-off-checkbox">
      <input
        type="checkbox"
        id={id}
        name={name}
        aria-label={name}
        aria-describedby={id}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
        value={value}
      />
      <span id="checkbox-onoff" className={`slds-checkbox_faux_container ${toggleClassName}`} aria-live="assertive">
        <span className="slds-checkbox_faux" />
        <span className="slds-checkbox_on" id={checkboxOnId}>{checkboxOnTitle || 'On'}</span>
        <span className="slds-checkbox_off" id={checkboxOffId}>{checkboxOffTitle || 'Off'}</span>
      </span>
    </div>
  </label>
);

ToggleButton.propTypes = {
  /**
   * label text for the input element
   */
  label: PropTypes.string,
  /**
   * id of the toggle button label
   */
  labelId: PropTypes.string,
  /**
   * className of the toggle button label
   */
  labelClassName: PropTypes.string,
  /**
   * id of the input element
   */
  id: PropTypes.string,
  /**
   * onChange function for the input element
   */
  onChange: PropTypes.func,
  /**
   * indicates that input is checked
   */
  checked: PropTypes.bool,
  /**
   * title below the component when checkbox is on
   */
  checkboxOnTitle: PropTypes.string,
  /**
   * title below the component when checkbox is off
   */
  checkboxOffTitle: PropTypes.string,
  /**
   * additional class name for the checkbox_faux_container
   */
  toggleClassName: PropTypes.string,
  /**
   * value of the input element
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /**
   * id of the enabled checkbox
   */
  checkboxOnId: PropTypes.string,
  /**
   * id of the disabled checkbox
   */
  checkboxOffId: PropTypes.string,
  /**
   * defines whether select should be disabled
   */
  disabled: PropTypes.bool,
  /**
   * name for the select
   */
  name: PropTypes.string,
};

export default ToggleButton;
