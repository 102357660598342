import Constants from '../../../../constants/constants';
import CustomValuesUtil from '../CustomValues/customValuesUtil';

const TargetDefinitionUtil = {
  /**
   * Function for filtering Shared Custom Values.
   * Only keeps the ones that either need no Data Extensions, or ones that use Data Extensions that exist
   * inside the selection's Selected Data Extensions.
   * @param {Array} globalCustomValues - Existing Shared Custom Values
   * @param {Array} selectedDataExtensions - Data Extensions used in the rendering tab
   * @returns {Array} Array containing Shared Custom Values that can be used in this Selection.
   */
  getRelatedGlobalCustomValues: (globalCustomValues, selectedDataExtensions) => {
    // Create a map to store ObjectID - alias mappings
    const aliasesMappings = new Map();

    // Get all ObjectIDs of Selected Data Extensions
    const availableObjectIDs = selectedDataExtensions?.map((selectedDataExtension) => {
      aliasesMappings.set(selectedDataExtension?.ObjectID, selectedDataExtension?.deAlias);

      return selectedDataExtension?.ObjectID;
    });

    /**
     * Get only Shared Custom Values that use Data Extensions used inside the selection as well,
     * make sure aliases are also up to date.
     */
    const filteredGlobalCustomValues = globalCustomValues?.reduce((filteredValues, customValue) => {
      // If no DEs are used in custom value, include it
      if (!customValue?.selectedDataExtensions?.length) return [...filteredValues, customValue];

      // Check if any DEs used in custom value are not used inside the selection
      const missingDEs = customValue?.selectedDataExtensions
        ?.filter(selectedDataExtension => !availableObjectIDs?.includes(selectedDataExtension?.ObjectID));

      // In case of missing DEs do not include Custom Value
      if (missingDEs?.length) {
        if (customValue?.valueType === Constants.CUSTOM_VALUES__FIELD_TYPE__FORMULA &&
            customValue?.formula === Constants.CUSTOM_VALUES__FORMULA_TYPE__ROW_NUMBER) {
          const missingRowNumberDE = !selectedDataExtensions?.find(
            de => de.deAlias === customValue?.orderBy?.collectionAlias,
          );

          if (missingRowNumberDE) {
            return filteredValues;
          }
        } else {
          return filteredValues;
        }
      }

      if (customValue?.valueType === Constants.CUSTOM_VALUES__FIELD_TYPE__AGGREGATION_VALUE) {
        customValue.aggregationValue = {
          dataExtensionCustomerKey: customValue.dataExtensionCustomerKey,
          fieldObjectId: customValue.fieldObjectId,
          fieldTypeAggregationValue: customValue.fieldTypeAggregationValue,
          filters: customValue.filters,
          formula: customValue.formula,
          mode: customValue.mode,
          relations: customValue.relations,
        };
      }

      // Update aliases
      customValue?.selectedDataExtensions.forEach(dataExtension => (
        CustomValuesUtil.changeAlias(
          customValue,
          dataExtension.Name,
          aliasesMappings.get(dataExtension.ObjectID),
          customValue.valueType,
          customValue.formula,
        )
      ));

      return [...filteredValues, customValue];
    }, []);

    return filteredGlobalCustomValues;
  },
};

export default TargetDefinitionUtil;
