import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

import Constants from '../../../../../constants/constants';
import Tooltip from '../../../../shared_v2/Tooltip/Tooltip';
import TimezoneDropdown from '../../../TargetDefinition/CustomValues/TimezoneDropdown/TimezoneDropdown';

const FromTimezoneContainer = ({
  convertFromTimezone,
  handleTimezoneChange,
  convertTimezone,
  tooltipPosition,
  customValueType,
}) => {
  return (
    <div className="timezone-dropdown-container-from">
      <div className="label-container-from">
        <div id="convertFromTimezoneLabel">
          <span>Convert from timezone: </span>
          <Tooltip
            type={Constants.TOOLTIP_TYPE__CONVERT_FROM_TIMEZONE}
            nubbinPosition={tooltipPosition || Constants.NUBBIN_POSITION__TOP_RIGHT}
            customClassName="tooltip-in-timezone-container"
          />
        </div>

        <div className={`convert-from-timezone-notification ${!!customValueType}`}>
          {convertFromTimezone !== Constants.TIME_ZONE__CENTRAL_STANDARD_TIME__VALUE &&
            <span>
              Click
              {' '}
              <button
                type="button"
                name={customValueType === Constants.CUSTOM_VALUES__FORMULA_TYPE__TRANSFORM ?
                  'convertFromTimezoneQuery' :
                  'convertFromTimezone'}
                value={Constants.TIME_ZONE__CENTRAL_STANDARD_TIME__VALUE}
                onClick={handleTimezoneChange}
              >
                here
              </button>
              {' '}
              to switch back to the default timezone (CST)
            </span>}
        </div>
      </div>
      <div className="timezone-dropdown-tooltip-container">
        <TimezoneDropdown
          handleOnChange={handleTimezoneChange}
          defaultValue={convertFromTimezone}
          name={customValueType === Constants.CUSTOM_VALUES__FORMULA_TYPE__TRANSFORM ?
            'convertFromTimezoneQuery' :
            'convertFromTimezone'}
          disabled={!convertTimezone}
        />
      </div>
    </div>
  );
};

FromTimezoneContainer.propTypes = {
  /**
   * Convert from timezone value
   */
  convertFromTimezone: PropTypes.string.isRequired,
  /**
   * A function to handle the timezone value change
   */
  handleTimezoneChange: PropTypes.func.isRequired,
  /**
   * Determines if the convert timezone checkbox is checked or not
   */
  convertTimezone: PropTypes.bool.isRequired,
  /**
   * Tooltip nubbin position
   */
  tooltipPosition: PropTypes.string,
  /**
   * Indicates the type of the custom value where the component is used
   */
  customValueType: PropTypes.string,
};

export default FromTimezoneContainer;
