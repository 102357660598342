import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const TextHighlight = ({ text = '', highlight = '' }) => {
  if (!highlight.trim()) {
    return <span className="unmark">{text}</span>;
  }

  const regex = new RegExp(`(${highlight})`, 'gi');
  const parts = text.split(regex);

  return (
    <p className="highlighted-container">
      {parts.filter(String).map((part, i) => regex.test(part) ?
        <Fragment key={i}><b>{part}</b></Fragment> :
        <Fragment key={i}>{part}</Fragment>)}
    </p>
  );
};

TextHighlight.propTypes = {
  /**
   * The whole text to display
   */
  text: PropTypes.string.isRequired,
  /**
   * The text for highlight
   */
  highlight: PropTypes.string.isRequired,

};

export default TextHighlight;
