/* eslint-disable spellcheck/spell-checker */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AutoSizer, List } from 'react-virtualized';

import Util from '../../../../../../util';
import './style.scss';
import Constants from '../../../../../../constants/constants';
import Input from '../../../../../shared_v2/Input/Input';

const ROW_HEIGHT = 35;
const MAX_VISIBLE_ROWS = 10;

class MultiSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectFilter: '',
    };
  }

  /**
   * Handler for when the user CLICKS on the dropdown
   * @param {object} e - The event
   * @returns {void}
   */
  handleDropDownOnClick = (e) => {
    const dropdown = e.currentTarget || e.target.closest('.slds-combobox');

    if (dropdown) {
      dropdown.setAttribute('aria-expanded', true);
      dropdown.classList.add('slds-is-open');
      if (this.inputRef) this.inputRef.focus();
    }
  };

  /**
   * Handler for when the user UN-FOCUS (BLURS) the dropdown
   * @param {object} e - The event
   * @returns {void}
   */
  handleDropDownOnBlur = (e) => {
    const dropdown = e.target.closest('.slds-combobox');
    // If we click on a child, don't do anything so it can call its handler(s)

    if (dropdown && !dropdown.contains(e.relatedTarget)) {
      dropdown.setAttribute('aria-expanded', false);
      dropdown.classList.remove('slds-is-open');
    }
  };

  /**
   * Handler for when the user CLICKS on an option of the dropdown
   * @param {object} e - The event
   * @returns {void}
   */
  handleDropDownOptionOnClick = (e) => {
    const option = e.currentTarget || e.target.closest('.slds-listbox__option');

    if (option) this.handleAddOrDeleteOption(option);
  };

  /**
   * Handler for when the user CLICKS on the cross of a pill
   * @param {object} e - The event
   * @returns {void}
   */
  handleClickOnDeletePill = (e) => {
    const pillValue = e.currentTarget.dataset.label;
    const { value, onOptionClick, isPicklistOptions } = this.props;
    const separator = isPicklistOptions ? Constants.SEPARATOR_DEFAULT : ',';
    const newValue = value?.filter(v => v !== pillValue)?.join(separator);

    onOptionClick(newValue);
  };

  /**
   * Add or delete an option to the selected values
   * @param {object} option - DOM element of the option
   * @returns {void} Will call the onOptionClick function from prop with new values and the selected value
   */
  handleAddOrDeleteOption = (option) => {
    const {
      value,
      onOptionClick,
      isPicklistOptions,
    } = this.props;

    const currentState = option.getAttribute('aria-selected');
    const selectedValue = option.dataset.value;
    const separator = isPicklistOptions ? Constants.SEPARATOR_DEFAULT : ',';

    option.setAttribute('aria-expanded', !currentState);
    option.classList.toggle('slds-is-selected');

    let newValue;

    if (value && Array.isArray(value)) {
      // Remove the value - If it already exists
      if (value.includes(selectedValue)) newValue = value.filter(v => v !== selectedValue);
      // Add the value - If it does not already exist
      else newValue = [...value, selectedValue];
      // Transform the array into a string
      newValue = newValue.join(separator);
    } else newValue = selectedValue;

    onOptionClick(newValue, selectedValue);
  };

  /**
   * Find a label with a value from the data
   * @param {object} valueToFind - Object with the `label` and `value` property
   * @returns {string} The corresponding label
   */
  findOptionLabelWithValue = (valueToFind) => {
    const { data } = this.props;

    let labelString = '';

    let res = [];

    res = data.filter(d => d.value === valueToFind);

    labelString = res && res.length > 0 ?
      res[0].label :
      labelString;

    return labelString;
  };

  render() {
    const { value, data } = this.props;
    const { selectFilter } = this.state;

    /**
     * This section parses the data and add a `selected` property so we know which one is selected.
     * A value is considered selected if it is contained in the current value(s) array.
     */
    const dataCopy = data.map((option) => {
      const optCopy = { ...option };
      // eslint-disable-next-line no-param-reassign

      if ((value || []).includes(optCopy.value)) optCopy.selected = true;
      else optCopy.selected = false;

      return optCopy;
    });

    const filteredData = dataCopy.filter(v => v.label && String(v.label).match(
      new RegExp(Util.escapeRegExp(selectFilter), 'i'),
    ));
    const selectedData = dataCopy.filter(v => v.selected);

    /**
     * Renders the list items using react-virtualized.
     *
     * @param {Object} params - Parameters object.
     * @param {string} params.key - Unique key for the item.
     * @param {number} params.index - Index of the item.
     * @param {Object} params.style - Style object for the item.
     * @returns {JSX.Element} List item component.
     */
    const renderList = ({ key, index, style }) => {
      const option = filteredData[index];

      return (
        <div key={key} style={style}>
          <ul className="slds-listbox slds-listbox_vertical" role="presentation">
            <li className="slds-listbox__item" role="presentation">
              <div
                onClick={this.handleDropDownOptionOnClick}
                data-value={option.value}
                className={`slds-media slds-listbox__option slds-listbox__option_plain slds-media_small${
                  option.selected ? ' slds-is-selected' : ''
                }`}
                tabIndex="0"
                aria-selected={!!option.selected}
                role="option"
              >
                <span className="slds-media__figure slds-listbox__option-icon">
                  { option?.selected ?
                    (
                    <span className="slds-icon_container slds-icon-utility-check slds-current-color">
                      <svg className="slds-icon slds-icon_x-small" aria-hidden="true">
                        <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#check" />
                      </svg>
                    </span>
                    ) :
                    (
                      ''
                    )}
                </span>
                <span className="slds-media__body">
                  <span className="slds-truncate" title={option.label}>
                    {option.label}
                  </span>
                </span>
              </div>
            </li>
          </ul>
        </div>
      );
    };

    /**
     * This section handles the name of the label, it is displayed inside the multi-select,
     * just like a placeholder.
     */
    let multiSelectLabel;

    if (value && Array.isArray(value)) {
      if (value.length === 0) multiSelectLabel = ''; // Placeholder will handle it
      else if (value.length === 1) multiSelectLabel = this.findOptionLabelWithValue(value[0]);
      else if (value.length > 1) multiSelectLabel = `${value.length} Options Selected`;
    } else multiSelectLabel = this.findOptionLabelWithValue(value);

    const listHeight = Math.min(filteredData.length * ROW_HEIGHT, MAX_VISIBLE_ROWS * ROW_HEIGHT);

    return (
      <div className="multi-select-v2">
        <div className="slds-select_container">
          <div
            onClick={this.handleDropDownOnClick}
            onBlur={this.handleDropDownOnBlur}
            onMouseDown={e => e.preventDefault()}
            className="slds-combobox slds-dropdown-trigger slds-dropdown-trigger_click"
            aria-expanded="false"
            aria-haspopup="listbox"
            aria-controls=""
            role="combobox"
          >
            <Input
             containerClassName="slds-combobox__form-element"
             forwardRef={(input) => { this.inputRef = input; }}
             className="slds-combobox__input slds-combobox__input-value slds-truncate"
             placeholder={multiSelectLabel || 'Please select'}
             autoComplete="off"
             onChange={e => this.setState({ selectFilter: e.target.value })}
             />

            <div className="slds-dropdown slds-dropdown_fluid virtualized-list" role="listbox">
               <AutoSizer disableHeight>
                  {({ width }) => (
                    <List
                      className="list-top"
                      width={width}
                      height={listHeight}
                      rowCount={filteredData.length}
                      rowHeight={ROW_HEIGHT}
                      rowRenderer={renderList}
                      overscanRowCount={5}
                    />
                  )}
               </AutoSizer>
            </div>
          </div>
        </div>
        {selectedData && selectedData.length ?
          (
            <div className="slds-listbox_selection-group">
              <ul
                className="slds-listbox slds-listbox_horizontal slds-truncate"
                role="listbox"
                aria-label="Selected Options:"
                aria-orientation="horizontal"
              >
                {selectedData.map(option => (
                  <li key={option.value} className="slds-listbox-item" role="presentation">
                    <span className="slds-pill" role="option" tabIndex="0" aria-selected="true">
                      <span className="slds-pill__label" title={option.label}>{option.label}</span>
                      <span
                        onClick={this.handleClickOnDeletePill}
                        data-label={option.label}
                        className="slds-icon_container slds-pill__remove"
                        title="Remove"
                      >
                        <svg className="slds-icon slds-icon_x-small slds-icon-text-default" aria-hidden="true">
                          <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#close" />
                        </svg>
                        <span className="slds-assistive-text">Click on the cross to delete</span>
                      </span>
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          ) :
          null}
      </div>
    );
  }
}

MultiSelect.propTypes = {
  /**
   * Array of objects used to render the options.
   * Each object should contain the properties {string} `label` and {string} `values`.
   */
  data: PropTypes.instanceOf(Array).isRequired,
  /**
   * Currently selected value(s). Should be an array of strings or a string.
   * Not required because it can be empty (no value selected).
   */
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Array),
    PropTypes.string,
  ]),
  /**
   * Function called with (newValue, selectedValue) when user clicks on an option
   * This function only provides the new value and the selected one, this function
   * should update the value prop with the new one.
   */
  onOptionClick: PropTypes.func.isRequired,
  /**
   * Determines if we are in a field that has a picklist or not
   */
  isPicklistOptions: PropTypes.bool.isRequired,
};

export default MultiSelect;
