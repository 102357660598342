import {
  SET_DATA_SET_NAME,
  SET_DATA_EXTENSIONS,
  SET_PREDEFINED_RELATIONS,
  SET_PREDEFINED_RELATIONS_MAP,
  SET_SELECTED_DATA_EXTENSIONS,
  SET_SELECTED_DES_TREE,
  SET_RELATIONS,
  SET_MODAL_DATA_EXTENSIONS,
  CLEAR_DATA_SET_STATE,
} from '../types';

/**
 * Function that sets the data set name
 * @param {string} value - new value
 * @returns {void}
 */
export const setDataSetName = value => (dispatch) => {
  dispatch({
    type: SET_DATA_SET_NAME,
    payload: value,
  });
};

/**
 * Function that sets data extensions
 * @param {string} value - new value
 * @returns {void}
 */
export const setDataExtensions = value => (dispatch) => {
  dispatch({
    type: SET_DATA_EXTENSIONS,
    payload: value,
  });
};

/**
 * Function that sets predefined relations
 * @param {string} value - new value
 * @returns {void}
 */
export const setPredefinedRelations = value => (dispatch) => {
  dispatch({
    type: SET_PREDEFINED_RELATIONS,
    payload: value,
  });
};

/**
 * Function that sets predefined relations map
 * @param {string} value - new value
 * @returns {void}
 */
export const setPredefinedRelationsMap = value => (dispatch) => {
  dispatch({
    type: SET_PREDEFINED_RELATIONS_MAP,
    payload: value,
  });
};

/**
 * Function that sets selected data extensions
 * @param {string} value - new value
 * @returns {void}
 */
export const setSelectedDataExtensions = value => (dispatch) => {
  dispatch({
    type: SET_SELECTED_DATA_EXTENSIONS,
    payload: value,
  });
};

/**
 * Function that sets selected DEs tree
 * @param {string} value - new value
 * @returns {void}
 */
export const setSelectedDEsTree = value => (dispatch) => {
  dispatch({
    type: SET_SELECTED_DES_TREE,
    payload: value,
  });
};

/**
 * Function that sets selected DEs tree
 * @param {string} value - new value
 * @returns {void}
 */
export const setRelations = value => (dispatch) => {
  dispatch({
    type: SET_RELATIONS,
    payload: value,
  });
};

/**
 * Function that sets modal data extensions
 * @param {string} value - new value
 * @returns {void}
 */
export const setModalDataExtensions = value => (dispatch) => {
  dispatch({
    type: SET_MODAL_DATA_EXTENSIONS,
    payload: value,
  });
};

/**
 * Function that clear all state to initial in waterfall selection
 * @returns {void}
 */
export const clearDataSetState = () => (dispatch) => {
  dispatch({
    type: CLEAR_DATA_SET_STATE,
  });
};
