import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import Constants from '../../../../constants/constants';
import Button from '../../../shared/Button/Button';
import ToggleButton from '../../../shared/ToogleButton/ToggleButton';
import Input from '../../../shared/Input/Input';
import SwalUtil from '../../../../utils/swal/swalUtil';

class SplitSeparators extends Component {
  constructor(props) {
    super(props);
    const { picklist } = props;

    this.state = {
      separate: picklist && picklist.separate ?
        picklist.separate :
        false,
      separator: picklist && picklist.separator ?
        picklist.separator :
        '',
    };
  }

  /**
   * onChange event handler for the toggle button
   * @returns {void}
   */
  handleToggleSplitResults = () => {
    this.setState(prevState => ({ separate: !prevState.separate }));
  };

  /**
   * onChange event handler for the separator input
   * @param {object} e - onChange event object
   * @returns {void}
   */
  handleSeparatorInputChanged = (e) => {
    const { value } = e.target;

    this.setState({ separator: value });
  };

  /**
   * onClick event handler for the split separators button
   * @returns {void}
   */
  handleOpenCloseSplitSeparatorsSettings = () => {
    const { handleSetPicklistsAEState, openSplitSeparatorsSettings } = this.props;

    handleSetPicklistsAEState({
      openAutoRefreshSettings: false,
      openSplitSeparatorsSettings: !openSplitSeparatorsSettings,
    });
  };

  /**
   * onClick event handler for the Cancel button
   * @returns {void}
   */
  handleCancelSplitSeparators = () => {
    const { picklist, handleSetPicklistsAEState } = this.props;

    this.setState({
      separate: picklist && picklist.separate ?
        picklist.separate :
        false,
      separator: picklist && picklist.separator ?
        picklist.separator :
        '',
    });

    handleSetPicklistsAEState({ openSplitSeparatorsSettings: false });
  };

  /**
   * onClick event handler for the Save button
   * @returns {void}
   */
  handleSaveSplitSeparators = async () => {
    const { separate, separator } = this.state;
    const { handleSetPicklistsAEState, picklist } = this.props;
    const updatedPicklist = { ...picklist, separate, separator };
    /*
     * if separate is on and separator is not defined
     * throw a swal
     */

    if (separate && separator === '') {
      await SwalUtil.fire({
        title: 'Split separators',
        message: 'Please define the separator',
        options: {
          confirmButtonText: 'OK',
          allowOutsideClick: false,
        },
      });

      return;
    }

    handleSetPicklistsAEState({ picklist: updatedPicklist, openSplitSeparatorsSettings: false });
  };

  /**
   * This function renders the toggle button and the separator input
   * @returns {object} - The HTML of the form elements
   */
  renderFormElements = () => {
    const { separate, separator } = this.state;
    const { disableSplitSeparatorsButton, openSplitSeparatorsSettings } = this.props;

    return (
      // Main div of this component
      <div className="slds-form-element">
        {/* Button to open split separators settings */}
        <Button
          className="retrieve-value-button"
          buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
          onClick={() => this.handleOpenCloseSplitSeparatorsSettings()}
          disabled={disableSplitSeparatorsButton}
          id="splitResultsButton"
        >
          <div title="Split results with separator">
            <svg
              className="slds-icon slds-icon-text-default slds-icon_medium svg-icon"
              aria-hidden="true"
            >
              <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#settings" />
            </svg>
          </div>
        </Button>
        {openSplitSeparatorsSettings && (
        // Main div of the split separators settings modal
          <div id="modal" className="modal hide fade in" data-toggle="modal" data-backdrop="static">
            <div className="slds-button-group" role="group">
              <div className="slds-button slds-button_icon">
                <div
                  className="slds-dropdown slds-dropdown_right slds-dropdown_actions separators-settings"
                  id="splitResultsSettings"
                >
                  {/* Toggle button - user can turn on or of split separators settings */}
                  <ToggleButton
                    labelId="toggle-button-label"
                    label="Split results with separator:"
                    id="splitResultsToggle"
                    onChange={this.handleToggleSplitResults}
                    checked={separate}
                    checkboxOnTitle="Enabled"
                    checkboxOffTitle="Disabled"
                  />
                  <div className="slds-form-element separator">
                    <span className="form-element-label slds-m-bottom_none">Separator:</span>
                    <div className="form-control-elements">
                      <Input
                       value={separator}
                       onChange={this.handleSeparatorInputChanged}
                       disabled={!separate}
                      />
                    </div>
                  </div>
                  <footer className="footer" id="action-footer">
                    <Button
                      buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
                      id="action-cancel"
                      onClick={() => this.handleCancelSplitSeparators()}
                    >
                      Cancel
                    </Button>
                    <Button
                      buttonLook={Constants.BUTTON__TYPE__BRAND}
                      id="action-save"
                      onClick={() => this.handleSaveSplitSeparators()}
                    >
                      Confirm
                    </Button>
                  </footer>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  render() {
    return this.renderFormElements();
  }
}

SplitSeparators.propTypes = {
  /**
   * The current picklist
   */
  picklist: PropTypes.instanceOf(Object),
  /**
   * Function for setting PicklistsAE state
   */
  handleSetPicklistsAEState: PropTypes.func.isRequired,
  /**
   * The disable state of the split separators button
   */
  disableSplitSeparatorsButton: PropTypes.bool.isRequired,
  /**
   * Determines if the split separators settings are opened or not
   */
  openSplitSeparatorsSettings: PropTypes.bool.isRequired,
};

export default SplitSeparators;
