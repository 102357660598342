/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import axios from 'axios';

import DataExtensionsAPI from '../../../api/data-extensions';
import { setErrors } from './errorActions';
import {
  ADD_TARGET_DATA_EXTENSION,
  REMOVE_SELECTED_SELECTION,
  REMOVE_TARGET_DATA_EXTENSION,
  START_LOADING_TARGET_DE_OBJECT_ID,
  STOP_LOADING_TARGET_DE_OBJECT_ID,
} from '../types';
// eslint-disable-next-line import/no-cycle
import { stopLoadingSelectionId } from './selectionActions';
import SwalUtil from '../../../utils/swal/swalUtil';
import Constants from '../../../constants/constants';

/**
 * Function that adds objectID of loading targetDE to an array if it starts loading
 * @param {array} objectIDs - objectIDs of loading targetDE
 * @returns {void}
 */
export const startLoadingTargetDEObjectID = objectIDs => (dispatch) => {
  dispatch({
    type: START_LOADING_TARGET_DE_OBJECT_ID,
    payload: objectIDs,
  });
};

/**
 * Function that removes objectID of targetDE from an array if it stops loading
 * @param {array} objectIDs - objectIDs of loading targetDE
 * @returns {void}
 */
export const stopLoadingTargetDEObjectID = objectIDs => (dispatch) => {
  dispatch({
    type: STOP_LOADING_TARGET_DE_OBJECT_ID,
    payload: objectIDs,
  });
};

/**
 * Function that gets target data extension by Customer Key and sets the retrieved data into the state
 * @param {object} selection - selection for which we fetch targetDE
 * @param {string} cancelToken - The cancel token from Axios
 * @returns {void}
 */
// eslint-disable-next-line consistent-return
export const getTargetDataExtension = (selection, cancelToken) => async (dispatch) => {
  // prepare post data
  const dataExtension = [{
    collectionCustomerKey: selection.targetCollectionCustomerKey,
    collectionObjectID: selection.targetCollectionObjectID,
  }];

  try {
    // fetch targetDE
    const targetDataExtension = await DataExtensionsAPI.getDataExtensionsAndFieldsByCustomerKeys(
      cancelToken,
      dataExtension,
      Constants.QUERYACTIVITY__TASKTYPE__RUN_WATERFALL_SELECTION,
    );

    if (targetDataExtension?.length) {
      // set retrieved data in targetDataExtensions
      dispatch({
        type: ADD_TARGET_DATA_EXTENSION,
        payload: targetDataExtension,
      });
    } else {
      // in other case show message that this targetDE does not exists
      const result = await SwalUtil.fire(
        {
          // eslint-disable-next-line max-len
          message: 'Target Data Extension for this selection is not available anymore. Please choose a different selection.',
          type: Constants.SWAL__TYPE__ERROR,
          options: {
            confirmButtonText: 'OK',
            allowOutsideClick: false,
          },
        },
      );

      if (result.value) {
        // remove selected selection
        dispatch({
          type: REMOVE_SELECTED_SELECTION,
          payload: selection,
        });

        // stop loading this selection from selectionIds
        dispatch(stopLoadingSelectionId([selection._id]));
      }
    }

    // stop loading targetDE
    dispatch(stopLoadingTargetDEObjectID([selection.targetCollectionObjectID]));

    return { targetDataExtension };
  } catch (err) {
    dispatch(stopLoadingTargetDEObjectID([selection.targetCollectionObjectID]));
    if (!axios.isCancel(err)) dispatch(setErrors(err));
  }
};

/**
 * Function that gets multiple target data extensions by CustomerKey and ObjectID and
 * sets the retrieved data into the state
 * @param {array} targetDEsData - array with customer keys and object IDs of targetDEs to be fetched
 * @param {string} cancelToken - The cancel token from Axios
 * @returns {object} object with fetched target data extensions or missing object IDs
 */
// eslint-disable-next-line consistent-return
export const getMultipleTargetDataExtensions = (targetDEsData, cancelToken) => async (dispatch) => {
  // set array with existing targetDEs
  const targetDEs = targetDEsData.reduce((targetDEArray, data) => {
    if (data.collectionObjectID) {
      return [...targetDEArray, data];
    }

    return targetDEArray;
  }, []);

  // set post data without duplicate values
  const targetDEsToFetch = _.uniqWith(targetDEs, _.isEqual);

  // set array with ObjectIDs to fetch
  const objectIDs = targetDEsData.map(target => target.collectionObjectID);

  let targetDataExtensions = [];

  try {
    // fetch targetDEs
    if (targetDEsToFetch?.length) {
      targetDataExtensions = await DataExtensionsAPI.getDataExtensionsAndFieldsByCustomerKeys(
        cancelToken,
        targetDEsToFetch,
        Constants.QUERYACTIVITY__TASKTYPE__RUN_WATERFALL_SELECTION,
      );
    }

    // variables for checking if targetDE exists
    const missingObjectIDs = [];
    const targetDEsToSave = [];

    // check if there are missing targetDE
    objectIDs.forEach((id) => {
      const findObjectIDInFetchedData = targetDataExtensions.find(de => de.ObjectID ===
        id);

      // if targetDE is found, push into targetDEsToSave
      if (findObjectIDInFetchedData) {
        targetDEsToSave.push(findObjectIDInFetchedData);

        return;
      }

      // in other case save in missingObjectIDs
      missingObjectIDs.push(id);
    });

    if (targetDEsToSave?.length) {
      // set retrieved data in targetDataExtensions
      dispatch({
        type: ADD_TARGET_DATA_EXTENSION,
        payload: targetDEsToSave,
      });
    }

    // stop loading targetDE
    dispatch(stopLoadingTargetDEObjectID(objectIDs));

    if (missingObjectIDs?.length) {
      // return missing object IDs without duplicate values
      return { missingObjectIDs: _.uniq(missingObjectIDs), targetDataExtensions };
    }

    // return fetched target data extensions
    return { targetDataExtensions };
  } catch (err) {
    dispatch(stopLoadingTargetDEObjectID(objectIDs));
    if (!axios.isCancel(err)) dispatch(setErrors(err));
  }
};

/**
 * Function that removes targetDataExtension, depending on the removed selection
 * @param {object} objectID - object ID of the targetDE to be removed
 * @returns {void}
 */
export const removeTargetDataExtensions = objectID => (dispatch) => {
  dispatch({
    type: REMOVE_TARGET_DATA_EXTENSION,
    payload: objectID,
  });
};
