import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Constants from '../../../../../constants/constants';
import Tooltip from '../../../../shared/Tooltip/Tooltip';
import Util from '../../../../../util';
import Button from '../../../../shared/Button/Button';
import Select from '../../../../shared/Select/Select';

const AdditionalJoinsComponent = ({
  modalDataExtensions,
  incompatibleJoinObjectId,
  noCompatibleToAdditionalJoins,
  toFields,
  handleAdditionalJoinsChange,
  id,
  removeAdditionalJoins,
  renderJoinError,
  isDataSetDEModal,
  fromFieldMissing,
  toFieldMissing,
  deletedFromFieldName,
  deletedToFieldName,
}) => {
  // shallow copies to prevent reference problem
  const modalDataExtensionsCopy = { ...modalDataExtensions };
  const fromCollectionCopy = { ...modalDataExtensionsCopy.fromCollection };

  /**
   * finds name or field type of selected object from collection if it has the same ID as object from AdditionalJoins
   * @param {string} selectedID - selected object ID from additionalJoins
   * @param {array} collectionFields - array with fields of collection
   * @param {boolean} isFieldType - defines that the field type of the selected object should be returned
   * @returns {string} the name or fieldType of selected object
   */
  const findSelectedObjectNameOrFieldType = (selectedID, collectionFields, isFieldType) => {
    // finds selected object from collectionFields if it has the same ID as selectedID
    const selectedObject = collectionFields?.find(ff => ff.ObjectID === selectedID);

    if (selectedObject) {
      // returns the name or fieldType of the selected object
      return isFieldType ? selectedObject.FieldType : selectedObject.Name;
    }

    return '';
  };

  /**
   * Retrieves the name or field type of the selected field
   * @param {string} fromOrTo - 'from' collection or 'to' collection
   * @param {boolean} isFieldType - defines that the field type of the selected object should be returned
   * @returns {string} The name of the selected field
   */
  const getValueOrFieldType = (fromOrTo, isFieldType) => {
    if (fromOrTo === Constants.RELATION_MODAL__FROM) {
      // finds selected fromField Object ID from additionalJoins
      const selectedID = modalDataExtensionsCopy?.additionalJoins?.find(ff => ff.rowID === id).fromFieldObjectID;

      /*
       * return name or fieldType of the selected object from fromCollectionCopy.fields
       * if it has the same ID as selectedID
       */
      return findSelectedObjectNameOrFieldType(selectedID, fromCollectionCopy.fields, isFieldType);
    }

    if (!noCompatibleToAdditionalJoins && fromOrTo === Constants.RELATION_MODAL__TO) {
      // finds selected toField Object ID from additionalJoins
      const selectedID = modalDataExtensionsCopy?.additionalJoins?.find(ff => ff.rowID === id).toFieldObjectID;

      // return name or fieldType of the selected object from toFields if it has the same ID as selectedID
      return findSelectedObjectNameOrFieldType(selectedID, toFields, isFieldType);
    }

    return '';
  };

  // fields are incompatible but can be mapped
  const isIncompatibleFieldJoinable = !Util.canFieldBeMappedWithWarning(
    getValueOrFieldType(Constants.RELATION_MODAL__FROM, true),
    getValueOrFieldType(Constants.RELATION_MODAL__TO, true),
  );

  // fields are incompatible and cannot be mapped
  const areFieldsIncompatible = incompatibleJoinObjectId === id;

  const classNamesForSldsFormElement = classNames(
    'slds-form-element',
    { 'slds-has-error': incompatibleJoinObjectId === id || toFieldMissing },
  );

  return (
    // return if data is loaded
    (fromCollectionCopy?.fields?.length && toFields?.length) ?
      (
        <div className="relation-wrapper-component">
          <div className="relation-wrapper_one">
            <div className="relation-modal-from-collection-field">
            {!isDataSetDEModal && (
              <Button
                buttonIcon
                className="delete"
                title="delete"
                ariaDescribedBy="delete"
                ariaDisabled="true"
                onClick={() => removeAdditionalJoins(id)}
              >
                <svg
                  className="slds-button__icon slds-icon_small trash"
                  aria-hidden="true"
                >
                  <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#recycle_bin_empty" />
                </svg>
              </Button>
            )}
              <div className={`slds-form-element ${fromFieldMissing ? 'slds-has-error' : ''}`}>
                <div className="slds-form-element__control">
                  <Select
                    className="fromField"
                    onChange={e => handleAdditionalJoinsChange(e, id, Constants.RELATION_MODAL__FROM)}
                    value={getValueOrFieldType(Constants.RELATION_MODAL__FROM)}
                    options={modalDataExtensionsCopy.fromCollection.fields.map(field => ({
                      value: field.Name.toString(),
                      label: field.Name.toString(),
                      disabled: field.disabled,
                    }))}
                    dataSetModalOption={isDataSetDEModal ?
                      {
                        value: getValueOrFieldType(Constants.RELATION_MODAL__FROM),
                        key: getValueOrFieldType(Constants.RELATION_MODAL__FROM),
                      } :
                      null}
                    disabled={isDataSetDEModal}
                  />
                </div>
                { fromFieldMissing && (
                  <div className="slds-form-element__help" id="missing-from-fields-error">
                    {
                      `The selected field '${deletedFromFieldName}' has been deleted and replaced by ` +
                      `'${getValueOrFieldType(Constants.RELATION_MODAL__FROM)}'. Please choose your preferred field.`
                    }
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="relation-wrapper-center">
            <p>equals</p>
          </div>
          <div className="relation-wrapper_last">
            <div className="relation-modal-to-collection-field">
              <div
                className={classNamesForSldsFormElement}
              >
                <div className="slds-form-element__control">
                  <div style={{ flexGrow: 1 }}>
                    <Select
                      className="toField"
                      disabled={(!isDataSetDEModal && noCompatibleToAdditionalJoins) || isDataSetDEModal}
                      onChange={e => handleAdditionalJoinsChange(e, id, Constants.RELATION_MODAL__TO)}
                      value={getValueOrFieldType(Constants.RELATION_MODAL__TO)}
                      options={noCompatibleToAdditionalJoins ?
                        [] :
                        toFields.map(field => ({
                          value: field.Name.toString(),
                          label: field.Name.toString(),
                          disabled: field.disabled,
                        }))}
                      noOptionsLabel="No fields are compatible"
                      dataSetModalOption={isDataSetDEModal ?
                        { value: getValueOrFieldType(Constants.RELATION_MODAL__TO) } :
                        null}
                    />
                  </div>
                  {!isDataSetDEModal && (
                    <Tooltip
                    nubbinPosition={Constants.NUBBIN_POSITION__BOTTOM_RIGHT}
                    type={Constants.TOOLTIP_TYPE__RELATION_MODAL}
                  />
                  )}

                </div>
                {!isDataSetDEModal && (
                  <div className="slds-form-element__help error warning-container">
                    {renderJoinError(
                      isIncompatibleFieldJoinable,
                      areFieldsIncompatible,
                      toFieldMissing,
                      deletedToFieldName,
                      getValueOrFieldType(Constants.RELATION_MODAL__TO),
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) :
      (
        null
      )
  );
};

AdditionalJoinsComponent.propTypes = {
  /**
   * It keeps the data extensions those will be shown in the Relation Modal
   */
  modalDataExtensions: PropTypes.instanceOf(Object).isRequired,
  /**
   * Array of fields from modalDataExtensions.toCollection with disabled value for each field
   */
  toFields: PropTypes.instanceOf(Array).isRequired,
  /**
   * state to determine if the fields are incompatible
   * returns the id of an incompatible object or null
   */
  incompatibleJoinObjectId: PropTypes.string,
  /**
   * returning true if no fields are compatible
   */
  noCompatibleToAdditionalJoins: PropTypes.bool.isRequired,
  /**
   * responsible for changing field values in select and updating additionalJoins with the new value
   */
  handleAdditionalJoinsChange: PropTypes.func.isRequired,
  /**
   * generated, unique id for each component
   */
  id: PropTypes.string.isRequired,
  /**
   * responsible for removing the object from additionalJoins
   */
  removeAdditionalJoins: PropTypes.func.isRequired,
  /**
   * responsible for rendering error or warnings based on conditions
   */
  renderJoinError: PropTypes.func.isRequired,
  /**
   * determines if this component is used in the data set modal
   */
  isDataSetDEModal: PropTypes.bool,
  /*
   * Indicates whether additionalJoin's fromField is missing
   */
  fromFieldMissing: PropTypes.bool,
  /**
   * Indicates whether additionalJoin's toField is missing
   */
  toFieldMissing: PropTypes.bool,
  /**
   * The missing fromField's name
   */
  deletedFromFieldName: PropTypes.string,
  /**
   * The missing toField's name
   */
  deletedToFieldName: PropTypes.string,
};

export default AdditionalJoinsComponent;
