import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Constants from '../../../constants/constants';
import Util from '../../../util';
import Expand from '../../../icons/chevronright.svg';
import Collapse from '../../../icons/chevrondown.svg';
import TextHighlight from '../../shared/TextHighlight/TextHighlight';
import './styles.scss';
import Checkbox from '../../shared/Checkbox/Checkbox';
import Tooltip from '../../shared/Tooltip/Tooltip';

const Folders = (
  {
    folders,
    foldersChildren,
    id,
    error,
    loading,
    handleFolderClicked,
    folderSelectionMap,
    greyedOutFolders,
    handleChangeFolderSelection,
    filteringDEs,
    includeOrExcludeTargetDEFolders,
    foldersInclusionExclusionMap,
    includeOrExcludeAvailableDEFolders,
    isAvailableDEsFoldersModal,
    searchedFolder,
    setSelectedFolder,
    foldersMap,
    areAvailableFoldersLoading,
    handleSetNewAutoCreatedTargetDEState,
    availableDEFolderId,
    greyOutFolder,
    isTargetDEsFoldersModal,
    blockFolderForDE,
  },
) => {
  /**
   * Scrolls to the selected folder
   * @param {object} ref - ref of the selected folder
   * @returns {void}
   */
  const scrollTo = (ref) => {
    if (ref) {
      ref.scrollIntoView({ behavior: 'auto', block: 'center' });

      /*
       * check if the ref reached out to the folder that was previously selected
       * if so set the areAvailableFoldersLoading state to false so this function won't be called anymore
       */
      if (ref.id === `folder-${id}`) {
        handleSetNewAutoCreatedTargetDEState({ areAvailableFoldersLoading: false });
      }
    }
  };

  /**
   * Renders a checkbox
   * @param {number} folder - Folder object
   * @returns {object} The HTML of the checkbox
   */
  const renderCheckbox = folder => (
    <Checkbox
      formElementClassName="folder-selection-checkbox"
      name={folder.ID.toString()}
      id={folder.ID.toString()}
      checked={folderSelectionMap[folder.ID] || false}
      onChange={() => handleChangeFolderSelection(folder)}
      label=""
      spanFauxClassName={folderSelectionMap[folder.ID] && greyedOutFolders[folder.ID] ? 'greyed-out' : ''}
    />
  );

  /**
   * Helps to open a folder
   * @param {number} folderId - id of the folder
   * @param {string} folderName - name of the folder
   * @param {string} folderBusinessUnitId - business Unit Id of the folder
   * @returns {void}
   */
  const handleOpenFolder = async (folderId, folderName, folderBusinessUnitId) => {
    if (searchedFolder) {
      await setSelectedFolder(folderId, foldersMap, {});
    }

    handleFolderClicked(folderId, null, folderName, folderBusinessUnitId);
  };

  /**
   * Returns className for Expand/Collapse icon
   * @param {number} folderID - id of the folder
   * @returns {string} className
   */
  const classNamesForExpandOrCollapseIcon = folderID => classNames(
    'openFolder_icon',
    { hidden: !foldersChildren[folderID]?.folders?.length },
  );

  /**
   * Returns className for folder name
   * @param {object} folder - folder object
   * @returns {string} className
   */
  const classNamesForFolderName = folder => classNames(searchedFolder && folder.Name.toString().toLowerCase().includes(
    searchedFolder.toString().toLowerCase(),
  ) ?
    'fw-800' :
    '');

  /**
   * Returns className for folder item
   * @param {object} folder - folder object
   * @param {string} activeFolderId - id of active folder
   * @returns {string} className
   */
  const classNamesForFolderItem = (folder, activeFolderId) => {
    const shouldGreyOutFolder = greyOutFolder && greyOutFolder(folder.ID, folder.Client.ID);

    return classNames(
      {
        disabled: shouldGreyOutFolder,
        'folder-list-item': !shouldGreyOutFolder,
        'active-folder-list-item': !shouldGreyOutFolder && folder.ID === activeFolderId,
        'disable-div': (!filteringDEs &&
          folder.ContentType === Constants.FOLDER__CONTENT_TYPE__SHARED_DATA &&
          !isAvailableDEsFoldersModal),
      },
    );
  };

  return (
    <ul className="folder-container">
      { error && (
        <p className="folders-error-container slds-text-color_error">{error}</p>
      )}
      { folders && folders.map(folder => (
        <div
          key={folder.ID}
          style={{
            display: 'flex',
            alignItems: 'start',
          }}
        >
          {filteringDEs && (
            <>
              {renderCheckbox(folder)}
            </>
          )}
          <img
            key={'icon-' + folder.ID}
            className={classNamesForExpandOrCollapseIcon(folder.ID)}
            src={foldersChildren[folder.ID]?.showChildren ? Collapse : Expand}
            alt="icon"
            onClick={() => handleFolderClicked(folder.ID, null, folder.Name, folder.Client.ID)}
          />
          <li
            key={folder.ID}
          >
            <span
              id={'folder-' + folder.ID}
              ref={(isAvailableDEsFoldersModal || isTargetDEsFoldersModal) && folder.ID === availableDEFolderId &&
                !searchedFolder && areAvailableFoldersLoading ?
                scrollTo :
                null}
              onClick={() => handleOpenFolder(folder.ID, folder.Name, folder.Client.ID)}
              className={classNamesForFolderItem(folder, id)}
            >
              <span className="folder-icon">
                <i
                  className={'fas ' +
                      (foldersChildren && foldersChildren[folder.ID] &&
                      foldersChildren[folder.ID].showChildren ?
                        'fa-folder-open' :
                        'fa-folder'
                      )}
                />
              </span>
              {/* eslint-disable-next-line react/no-danger */}
              {isAvailableDEsFoldersModal || isTargetDEsFoldersModal ?
                <TextHighlight highlight={searchedFolder} text={Util.abbreviate(folder.Name.toString(), 40)} /> :
                <>
                  <p
                    className={classNamesForFolderName(folder)}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={({ __html: Util.abbreviate(folder.Name.toString(), 40) })}
                  />
                  {blockFolderForDE &&
                  ['shared_dataextension_default', 'dataextension_default'].includes(folder.CustomerKey) &&
                    blockFolderForDE(folder.Client.ID) && (
                      <Tooltip
                        nubbinPosition={Constants.NUBBIN_POSITION__TOP_RIGHT}
                        type={Constants.TOOLTIP_TYPE__CHANGE_LOCATION_TO_SHARED_FOLDER}
                      />
                  )}
                </>}
            </span>
            { id === folder.ID && loading && (
            <img className="loading-indicator" alt="" src="/img/loadingS.gif" />
            )}
            {/* </div> */}
            <span className="folder-children">
              {foldersChildren &&
              foldersChildren[folder.ID] &&
              foldersChildren[folder.ID].showChildren &&
              foldersChildren[folder.ID].folders &&
              foldersChildren[folder.ID].folders.length ?
                (
                  <Folders
                    folders={foldersChildren[folder.ID].folders}
                    loading={loading}
                    foldersChildren={foldersChildren}
                    error={error}
                    id={id}
                    handleFolderClicked={handleFolderClicked}
                    folderSelectionMap={folderSelectionMap}
                    greyedOutFolders={greyedOutFolders}
                    handleChangeFolderSelection={handleChangeFolderSelection}
                    filteringDEs={filteringDEs}
                    includeOrExcludeTargetDEFolders={includeOrExcludeTargetDEFolders}
                    includeOrExcludeAvailableDEFolders={includeOrExcludeAvailableDEFolders}
                    foldersInclusionExclusionMap={foldersInclusionExclusionMap}
                    isAvailableDEsFoldersModal={isAvailableDEsFoldersModal}
                    searchedFolder={searchedFolder}
                    setSelectedFolder={setSelectedFolder}
                    foldersMap={foldersMap}
                    areAvailableFoldersLoading={areAvailableFoldersLoading}
                    availableDEFolderId={availableDEFolderId}
                    handleSetNewAutoCreatedTargetDEState={handleSetNewAutoCreatedTargetDEState}
                    greyOutFolder={greyOutFolder}
                    isTargetDEsFoldersModal={isTargetDEsFoldersModal}
                    blockFolderForDE={blockFolderForDE}
                  />
                ) :
                null}
            </span>
          </li>
        </div>
      ))}
    </ul>
  );
};

Folders.propTypes = {
  /**
   * It contains all root available folders where new DE can be saved
   */
  folders: PropTypes.instanceOf(Object).isRequired,
  /**
   * It contains all child available folders where new DE can be saved
   * For example: if we have folder DESelect_Demo and inside that folder
   * we have Partner_Demo then foldersChildren will contain Partner_Demo
   * as a child of DESelect_Demo folder
   */
  foldersChildren: PropTypes.instanceOf(Object),
  /**
   * id of current selected folder
   */
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  /**
   * helps us to determine if all folders are loaded
   */
  loading: PropTypes.bool.isRequired,
  /**
   * It stores the value if something goes wrong when folders are loaded
   */
  error: PropTypes.string,
  /**
   * It helps to handle with click on some folder
   */
  handleFolderClicked: PropTypes.func.isRequired,
  /**
   * Keeps the selection state of the checkboxes
   */
  folderSelectionMap: PropTypes.instanceOf(Object),
  /**
   * Onchange event handler for checkboxes
   */
  handleChangeFolderSelection: PropTypes.func,
  /**
   * Determines whether we are rendering folders in admin panel
   */
  filteringDEs: PropTypes.bool,
  /**
   * Keeps the greyed-out state of folder checkboxes
   */
  greyedOutFolders: PropTypes.instanceOf(Object),
  /*
   * Folder filter type
   */
  includeOrExcludeTargetDEFolders: PropTypes.string,
  /**
   * An object containing information on which folders to be disabled
   */
  foldersInclusionExclusionMap: PropTypes.instanceOf(Object),
  /**
   * Folder filter type for the available folders
   */
  includeOrExcludeAvailableDEFolders: PropTypes.string,
  /**
   * Determines if we are using this component in the Available Folders component
   */
  isAvailableDEsFoldersModal: PropTypes.bool,
  /**
   * Name of the DE we are looking for
   */
  searchedFolder: PropTypes.string,
  /**
   * Sets selected folder in state
   */
  setSelectedFolder: PropTypes.func,
  /**
   * Keeps the child folders
   */
  foldersMap: PropTypes.instanceOf(Object),
  /**
   * Determines if everything is loaded when it comes to the Available DEs folders
   */
  areAvailableFoldersLoading: PropTypes.bool,
  /**
   * Function for changing the NewAutoCreatedTargetDE states
   */
  handleSetNewAutoCreatedTargetDEState: PropTypes.func,
  /**
   * Keeps the selected folder id
   */
  availableDEFolderId: PropTypes.number,
  /**
   * Function for indicates whether folder should be disabled or not
   */
  greyOutFolder: PropTypes.func,
  /**
   * Function for indicates whether folder should be blocked for specific DE
   */
  blockFolderForDE: PropTypes.func,
  /**
   * Determines if we are using this component from the TargetDE component
   */
  isTargetDEsFoldersModal: PropTypes.bool,
};
export default Folders;
