import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import classNames from 'classnames';

import Constants from '../../../constants/constants';
import Tooltip from '../Tooltip/Tooltip';
import UpgradeBadge from '../UpgradeBadge/UpgradeBadge';
import { featureAdvertExists } from '../Feature/featureUtil';

const Tabs = ({
  tabs, changeTab, active, showEssentialsUpgradeModal,
}) => {
  /**
   * Handle change tab or show advert modal if tab is disabled
   * @param {Object} tab - tab object with name, disabled and feature
   * @returns {void}
   */
  const handleClick = (tab) => {
    if (tab?.disabled) {
      showEssentialsUpgradeModal(tab?.feature);
    } else {
      changeTab(tab?.name);
    }
  };

  return (
    <div className="tab-container-v2">
      {tabs?.map((tab, i) => {
        const tabClassName = classNames(
          'tab-v2',
          { 'active-v2': tab?.name === active },
          { 'disabled-overlay': tab?.disabled && tab?.feature },
          { 'disabled-v2': tab?.disabled },
        );

        return (
          <div
            className={tabClassName}
            key={i}
            onClick={() => handleClick(tab)}>
            <span>{tab.name}</span>
            {tab?.tooltipText || tab?.tooltipType && <Tooltip
                    nubbinPosition={Constants.NUBBIN_POSITION__TOP_RIGHT}
                    tooltipText={tab?.tooltipText}
                    type={tab?.tooltipType}
                  />}
            {
              (tab?.disabled && tab?.feature && featureAdvertExists(tab?.feature)) && (
                <UpgradeBadge />
              )
            }
          </div>
        );
      })}
    </div>
  );
};

Tabs.propTypes = {
  /**
   * Array of tabs
   */
  tabs: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    tooltipText: PropTypes.string,
    tooltipType: PropTypes.string,
  })).isRequired,
  /**
   * Function to change tab
   */
  changeTab: PropTypes.func.isRequired,
  /**
   * Active tab
   */
  active: PropTypes.string.isRequired,
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    disabled: PropTypes.bool,
    feature: PropTypes.string,
    tooltipText: PropTypes.string,
    tooltipType: PropTypes.string,
  })),
  changeTab: PropTypes.func,
  active: PropTypes.string,
  showEssentialsUpgradeModal: PropTypes.func,
};

export default Tabs;
