import { useState, useEffect } from 'react';

import Constants from '../../constants/constants';

/**
 *  Custom hook for updating retention policy
 * @param {object} initialState - initial state of data
 * @param {Function} handleUpdateRetentionPolicy - function that call the api and update the retention policy
 * @returns {Array} - Array that contains state of retention policy, set method and update method
 */
const useUpdateRetentionPolicyACDE = (initialState, handleUpdateRetentionPolicy) => {
  const [retentionPolicy, setRetentionPolicy] = useState({
    dataRetentionPeriodLength: '',
    dataRetentionPeriod: 'Days',
    dataRetentionPolicy: false,
    applyTo: Constants.DATA_RETENTION_POLICY__APPLY_CRITERIA__INDIVIDUAL_RECORDS,
  });

  useEffect(() => {
    setRetentionPolicy({
      dataRetentionPeriodLength: initialState?.dataRetentionPeriodLength || '',
      dataRetentionPeriod: initialState?.dataRetentionPeriod || 'Days',
      dataRetentionPolicy: initialState?.dataRetentionPolicy || false,
      applyTo: initialState.applyTo || Constants.DATA_RETENTION_POLICY__APPLY_CRITERIA__INDIVIDUAL_RECORDS,
    });
  }, [initialState]);

  /**
   * update retention
   * @param {object} retentionPolicy - retention policy to update
   * @returns {void}
   */
  const updateRetentionPolicy = async (retentionPolicy) => {
    handleUpdateRetentionPolicy(retentionPolicy);
  };

  // return custom hook elements
  return [retentionPolicy, setRetentionPolicy, updateRetentionPolicy];
};

export { useUpdateRetentionPolicyACDE };
