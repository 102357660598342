import SwalUtil from '../swal/swalUtil';

const HooksUtil = {
  /**
   * Gets an array of Data Extensions and brings them to the right format.
   * @param {Array} dataExtensions - Array of Data Extensions
   * @returns {Array} Formatted Data Extensions.
   */
  formatDataExtensions: dataExtensions => dataExtensions?.map(dataExtension => ({
    ...dataExtension,
    collectionCustomerKey: dataExtension?.CustomerKey,
    collectionObjectID: dataExtension.ObjectID,
    CustomerKey: dataExtension?.CustomerKey,
    deAlias: dataExtension?.Name,
  })),
  /**
   * Show error in case any data extension is missing
   * @param {Array} missingDataExtensions - Array of missing data extensions
   * @param {String} customMessage - Custom message to render
   * @returns {void}
   */
  showMissingDEsError: async (missingDataExtensions, customMessage) => {
    const pluralS = missingDataExtensions?.length > 1 ? 's' : '';
    const names = missingDataExtensions?.map(de => `"${String(de.Name)}"`).join(', ');
    const mainInfo = customMessage ||
    `The following Date Extension${pluralS} <strong> ${names} </strong>
    could not be found`;

    await SwalUtil.fire({
      type: 'error',
      title: `<div class="error-title">Data Extension${pluralS} not found</div>`,
      messageHTML:
      `<p class="width_swal">${mainInfo}.
       Please update this Shared Custom Value to avoid any errors inside a Selection.</p>`,
      options: {
        showCancelButton: false,
        confirmButtonText: 'OK',
        footer: '<div></div>',
        buttonsStyling: false,
        allowOutsideClick: false,
      },
    });
  },
};

export default HooksUtil;
