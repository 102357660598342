import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Input from '../../../shared/Input/Input';
import Constants from '../../../../constants/constants';
import ModalTemplate from '../../../shared/ModalTemplate/ModalTemplate';
import ToggleButton from '../../../shared/ToogleButton/ToggleButton';
import Util from '../../../../util';
import RadioButton from '../../../shared/RadioButton/RadioButton';

import './styles.scss';

const dataRetentionOptions = [
  {
    value: Constants.DATA_RETENTION_POLICY__PERIOD__DAYS,
    label: Constants.DATA_RETENTION_POLICY__PERIOD__DAYS,
  },
  {
    value: Constants.DATA_RETENTION_POLICY__PERIOD__WEEKS,
    label: Constants.DATA_RETENTION_POLICY__PERIOD__WEEKS,
  },
  {
    value: Constants.DATA_RETENTION_POLICY__PERIOD__MONTHS,
    label: Constants.DATA_RETENTION_POLICY__PERIOD__MONTHS,
  },
  {
    value: Constants.DATA_RETENTION_POLICY__PERIOD__YEARS,
    label: Constants.DATA_RETENTION_POLICY__PERIOD__YEARS,
  },
];

const SettingsDataRetentionPolicyModal = ({
  retentionPolicy,
  setRetentionPolicy,
  updateRetentionPolicy,
  setShowTempDERetentionPolicyModal,
  retentionPolicyACDE,
  setRetentionPolicyACDE,
  updateRetentionPolicyACDE,
  setShowACDERetentionPolicyModal,
  isTempDEModal,
}) => {
  const [dataRetentionPolicy, setDataRetentionPolicy] = useState(
    isTempDEModal ?
      retentionPolicy.dataRetentionPolicy :
      retentionPolicyACDE.dataRetentionPolicy ||
     false,
  );
  const [dataRetentionPeriodLength, setDataRetentionPeriodLength] = useState(
    isTempDEModal ?
      retentionPolicy.dataRetentionPeriodLength :
      retentionPolicyACDE.dataRetentionPeriodLength ||
     '',
  );
  const [dataRetentionPeriod, setDataRetentionPeriod] = useState(
    isTempDEModal ?
      retentionPolicy.dataRetentionPeriod :
      retentionPolicyACDE.dataRetentionPeriod ||
     'Days',
  );

  const [applyTo, setApplyTo] = useState(
    retentionPolicyACDE.applyTo || Constants.DATA_RETENTION_POLICY__APPLY_CRITERIA__INDIVIDUAL_RECORDS,
  );

  /**
   * Change the value of period length
   * @param {object} e - event
   * @returns {void}
   */
  const handleOnPeriodLengthChange = (e) => {
    if (Util.isNaturalNumber(e.target.value)) {
      setDataRetentionPeriodLength(Number(e.target.value));
    }
  };

  /**
   * Saves the retention setting and closes the modal
   * @returns {void}
   */
  const saveRetentionSettings = async () => {
    if (isTempDEModal) {
      setRetentionPolicy({
        dataRetentionPolicy,
        dataRetentionPeriodLength,
        dataRetentionPeriod,
      });

      await updateRetentionPolicy({
        dataRetentionPolicy,
        dataRetentionPeriodLength,
        dataRetentionPeriod,
      });

      setShowTempDERetentionPolicyModal(false);
    } else {
      setRetentionPolicyACDE({
        dataRetentionPolicy,
        dataRetentionPeriodLength,
        dataRetentionPeriod,
        applyTo,
      });

      await updateRetentionPolicyACDE({
        dataRetentionPolicy,
        dataRetentionPeriodLength,
        dataRetentionPeriod,
        applyTo,
      });

      setShowACDERetentionPolicyModal(false);
    }
  };

  return (
    <ModalTemplate
      containerId="container-data-retention-policy"
      headerId="modal-heading-01"
      headerTitle={`Data Retention Policy for ${isTempDEModal ?
        'Temporary' :
        'New'} Data Extensions`}
      contentClassName="slds-p-around_medium"
      contentId="modal-content-id-3"
      cancelButtonId={`cancel${isTempDEModal ? 'TDE' : 'NewDE'}DRPolicy`}
      cancelButtonTitle="Cancel"
      footerClassName="DRP-footer"
      saveButtonId={`save${isTempDEModal ? 'TDE' : 'NewDE'}DRPolicy`}
      handleCancel={() => isTempDEModal ?
        setShowTempDERetentionPolicyModal(false) :
        setShowACDERetentionPolicyModal(false)}
      handleSave={() => saveRetentionSettings()}
    >
      {/* RETENTION SETTING */}
      <div className="DRP-part-one">
        {/* eslint-disable-next-line */}
        <label className="retention-setting-label">
          Retention Setting
        </label>

        <div className="toggle-retention-policy">
          <ToggleButton
            name="checkbox-onoff"
            id={`policy-switcher-${isTempDEModal ? 'TDE' : 'NewDE'}DRPolicy`}
            onChange={() => setDataRetentionPolicy(!dataRetentionPolicy)}
            checked={dataRetentionPolicy}
            value={dataRetentionPolicy}
            checkboxOnTitle="Enabled"
            checkboxOnId={Constants.DATA_RETENTION_POLICY__STATUS__ON}
            checkboxOffTitle="Disabled"
            checkboxOffId={Constants.DATA_RETENTION_POLICY__STATUS__OFF}
          />
        </div>
      </div>
      {/* BELOW RETENTION SETTING */}
      <div className="DRP-part-two">

        {/* ON THE LEFT */}
        <div className="DRP-part-two_1">
          {/* eslint-disable-next-line */}
          <label className="apply-to-label">Apply To</label>
        </div>

        {/* ON THE RIGHT */}
        <div className="DRP-part-two_2">
          {/* eslint-disable-next-line */}
          <label className="delete-label">Delete:</label>

          <div className="delete-container admin-settings-width">
            <fieldset className="slds-form-element">
              <div className="slds-form-element__control admin-settings-small-text">
                {isTempDEModal ?
                  'All temporary data extensions' :
                  (
                  <>
                  <RadioButton
                  id={Constants.DATA_RETENTION_POLICY__APPLY_CRITERIA__INDIVIDUAL_RECORDS}
                  value={applyTo === Constants.DATA_RETENTION_POLICY__APPLY_CRITERIA__INDIVIDUAL_RECORDS || ''}
                  checked={applyTo === Constants.DATA_RETENTION_POLICY__APPLY_CRITERIA__INDIVIDUAL_RECORDS || false}
                  onChange={e => setApplyTo(e.target.id)}
                  label="Individual records"
                  />
                 <RadioButton
                   id={Constants.DATA_RETENTION_POLICY__APPLY_CRITERIA__ALL_RECORDS_DE}
                   value={applyTo === Constants.DATA_RETENTION_POLICY__APPLY_CRITERIA__ALL_RECORDS_DE || ''}
                   checked={applyTo === Constants.DATA_RETENTION_POLICY__APPLY_CRITERIA__ALL_RECORDS_DE || false}
                   onChange={e => setApplyTo(e.target.id)}
                   label="All records and data extensions"
                 />
                 <RadioButton
                   id={Constants.DATA_RETENTION_POLICY__APPLY_CRITERIA__ALL_RECORDS}
                   value={applyTo === Constants.DATA_RETENTION_POLICY__APPLY_CRITERIA__ALL_RECORDS || ''}
                   checked={applyTo === Constants.DATA_RETENTION_POLICY__APPLY_CRITERIA__ALL_RECORDS || false}
                   onChange={e => setApplyTo(e.target.id)}
                   label="All records"
                 />
                  </>
                  )}
              </div>
            </fieldset>
          </div>
          {/* eslint-disable-next-line */}
          <label className="label-period">Period:</label>
          <div
            className="period-container admin-settings-width"
          >
            <fieldset className="slds-form-element mb-0px">
              <div
                className="wrapper-firstPart"
                id="after-period-container"
              >
                <div className="slds-form-element__control elements-position">
                  After
                </div>

                <div className="group-slds-after slds-flex-container">
                  <Input
                    withContainer
                    type="number"
                    name="dataRetentionPeriodLength"
                    id="TDEDRPolicy-input-field"
                    required
                    className="slds-input_counter after-number-input elements-position slds-flex-item"
                    onChange={e => handleOnPeriodLengthChange(e)}
                    value={dataRetentionPeriodLength || ''}
                  />
                  <div className="slds-form-element">
                    <div className="slds-form-element__control">
                      <div className="slds-select_container admin-settings-small-text">
                        <select
                          className="slds-select slds-flex-item admin-settings-small-text"
                          id="TDEDRPolicy-period-dropdown"
                          onChange={e => setDataRetentionPeriod(e.target.value)}
                          value={dataRetentionPeriod || 'Days'}
                        >
                          {dataRetentionOptions.map(option => (
                            <option className="admin-settings-small-text" key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </ModalTemplate>
  );
};

SettingsDataRetentionPolicyModal.propTypes = {
  /**
   * Data retention settings object
   */
  retentionPolicy: PropTypes.instanceOf(Object).isRequired,
  /**
   * Sets the data retention policy properties in the Settings.js
   */
  setRetentionPolicy: PropTypes.func.isRequired,
  /**
   * Calls an api to update the data retention policy
   */
  updateRetentionPolicy: PropTypes.func.isRequired,
  /**
   * Closes the data retention policy modal
   */
  setShowTempDERetentionPolicyModal: PropTypes.func.isRequired,
  /**
   * Data retention settings object
   */
  retentionPolicyACDE: PropTypes.instanceOf(Object).isRequired,
  /**
   * Sets the data retention policy properties in the Settings.js
   */
  setRetentionPolicyACDE: PropTypes.func.isRequired,
  /**
   * Calls an api to update the data retention policy
   */
  updateRetentionPolicyACDE: PropTypes.func.isRequired,
  /**
   * Closes the data retention policy modal
   */
  setShowACDERetentionPolicyModal: PropTypes.func.isRequired,
  /**
   * Determines if the modal for temp DE or newly created DE is opened
   */
  isTempDEModal: PropTypes.bool.isRequired,
};

export default SettingsDataRetentionPolicyModal;
