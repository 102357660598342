import React from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line import/no-cycle
import FilterLine from '../FilterLine';
// eslint-disable-next-line import/no-cycle
import FilterContainer from '../../FilterContainer/FilterContainer';

const inResultsFormulaFilterLine = ({
  filters,
  operator,
  handleUpdateFilterLineCriteria,
  handleUpdateFormulaFilterLineCriteria,
  handleOnChangeFormulaValue,
  handleOnChangeFormulaFilterFormulaValue,
  handleChangeFilterDataExtensionField,
  handleRemoveFilterLine,
  handleFiltersSave,
  subQuery,
  addNewFilterline,
  handleMergeFilterLines,
  handleSetSelectionState,
  handleLogicChange,
  handleHoverFilterMerging,
  handleHideAddFiltersTogether,
  DEBorderMouseOver,
  filterBorderMouseOver,
  handleAddFormulaFilter,
  parentId,
  handleUpdateFilterLineValue,
  handleOpenTimezoneModal,
  updateTags,
  handleSetTags,
  tags,
  filterObject,
  handleSetDataExtensionToFilter,
  handleRemoveDataExtensionFromFilter,
  handleOpenSubQueryModal,
  handleTagsChanged,
  pickLists,
  fieldType,
  field,
  showInResultsOption,
  dropFieldOnFilters,
  collapseFormulas,
  handleCollapseAllFormulas,
  selectedActiveDE,
  showEssentialsUpgradeModal,
}) => {
  return (
    <div className="inResultsFormulaFilterLine-container">
      {filters.length === 1 ?
        filters.map(filter => (
          <div className="filterline-container single_filterline" key={filter.id}>
            <FilterLine
              filters={filters}
              key={filter.id}
              id={filter.id}
              parentId={filter.parentId}
              collectionAlias={filter.selectedActiveDE}
              handleUpdateFilterLineCriteria={handleUpdateFilterLineCriteria}
              handleUpdateFormulaFilterLineCriteria={handleUpdateFormulaFilterLineCriteria}
              handleLogicChange={handleLogicChange}
              handleRemoveDataExtensionFromFilter={handleRemoveDataExtensionFromFilter}
              handleOpenSubQueryModal={handleOpenSubQueryModal}
              handleUpdateFilterLineValue={handleUpdateFilterLineValue}
              criteria={filter.criteria}
              handleOnChangeFormulaValue={handleOnChangeFormulaValue}
              value={filter.value}
              formulaForRelationFilter={filter.formula}
              handleOnChangeFormulaFilterFormulaValue={handleOnChangeFormulaFilterFormulaValue}
              subQuery={subQuery}
              handleChangeFilterDataExtensionField={handleChangeFilterDataExtensionField}
              selectedFieldObjectID={filter.fieldObjectID}
              predefinedRelation={subQuery.selectedDataExtension}
              isInResultsFormula
              handleRemoveFilterLine={handleRemoveFilterLine}
              showInResultsOption={showInResultsOption}
              hideCollectionAlias={false}
              handleFiltersSave={handleFiltersSave}
              handleMergeFilterLines={handleMergeFilterLines}
              filterBorderMouseOver={filterBorderMouseOver}
              DEBorderMouseOver={DEBorderMouseOver}
              handleOpenTimezoneModal={handleOpenTimezoneModal}
              updateTags={updateTags}
              handleSetTags={handleSetTags}
              tags={tags}
              filterObject={filterObject}
              handleSetDataExtensionToFilter={handleSetDataExtensionToFilter}
              handleTagsChanged={handleTagsChanged}
              pickLists={pickLists}
              fieldType={fieldType}
              field={field}
              dropFieldOnFilters={dropFieldOnFilters}
              handleSetSelectionState={handleSetSelectionState}
              collapseFormulas={collapseFormulas}
              handleCollapseAllFormulas={handleCollapseAllFormulas}
              selectedActiveDE={filter.selectedActiveDE}
              showEssentialsUpgradeModal={showEssentialsUpgradeModal}
            />
          </div>
        )) :
        (
         <FilterContainer
            handleFiltersSave={handleFiltersSave}
            subQuery={subQuery}
            collectionAlias={selectedActiveDE}
            handleChangeFilterDataExtensionField={handleChangeFilterDataExtensionField}
            addNewFilterline={addNewFilterline}
            handleRemoveFilterLine={handleRemoveFilterLine}
            handleUpdateFilterLineCriteria={handleUpdateFilterLineCriteria}
            handleUpdateFormulaFilterLineCriteria={handleUpdateFormulaFilterLineCriteria}
            handleLogicChange={handleLogicChange}
            handleMergeFilterLines={handleMergeFilterLines}
            handleHoverFilterMerging={handleHoverFilterMerging}
            handleHideAddFiltersTogether={handleHideAddFiltersTogether}
            filters={filters}
            operator={operator}
            handleSetSelectionState={handleSetSelectionState}
            handleOnChangeFormulaFilterFormulaValue={handleOnChangeFormulaFilterFormulaValue}
            handleUpdateFilterLineValue={handleUpdateFilterLineValue}
            handleAddFormulaFilter={handleAddFormulaFilter}
            handleOnChangeFormulaValue={handleOnChangeFormulaValue}
            DEBorderMouseOver={DEBorderMouseOver}
            filterBorderMouseOver={filterBorderMouseOver}
            isInResultsFormula
            parentId={parentId}
            handleOpenTimezoneModal={handleOpenTimezoneModal}
            updateTags={updateTags}
            handleSetTags={handleSetTags}
            tags={tags}
            filterObject={filterObject}
            handleSetDataExtensionToFilter={handleSetDataExtensionToFilter}
            handleRemoveDataExtensionFromFilter={handleRemoveDataExtensionFromFilter}
            handleOpenSubQueryModal={handleOpenSubQueryModal}
            handleTagsChanged={handleTagsChanged}
            pickLists={pickLists}
            fieldType={fieldType}
            field={field}
            showInResultsOption={showInResultsOption}
            dropFieldOnFilters={dropFieldOnFilters}
            collapseFormulas={collapseFormulas}
            handleCollapseAllFormulas={handleCollapseAllFormulas}
            selectedActiveDE={selectedActiveDE}
            showEssentialsUpgradeModal={showEssentialsUpgradeModal}
          />
        )}
    </div>
  );
};

inResultsFormulaFilterLine.propTypes = {
  /**
   * The formula filters array
   */
  filters: PropTypes.array.isRequired,
  /**
   * The operator of the filter line
   */
  operator: PropTypes.string.isRequired,
  /**
   * It helps to change the selected criteria for a filter
   * It will be passed from SelectedFilters.js
   */
  handleUpdateFilterLineCriteria: PropTypes.func.isRequired,
  /**
   * It helps to change the selected criteria of a formula in filter
   * It will be passed from SelectedFilters.js
   */
  /**
   * It helps to determine if an aggregation formula will be used for a filter
   * It will be passed from SelectedFilters.js
   */
  handleOnChangeFormulaFilterFormulaValue: PropTypes.func,
  /**
   * It helps to change the value if an aggregation formula is selected for the filter
   * It will be passed from SelectedFilters.js
   */
  handleOnChangeFormulaValue: PropTypes.func,
  /**
   * When IN RESULTS is selected as criteria for a filter, there is another
   * drag-drop zone for another dataextension which will be used for subquery.
   * And also there is one field has to be selected to compare.
   * This function helps to change the selected field.
   * It will be passed from SelectedFilters.js
   */
  handleChangeFilterDataExtensionField: PropTypes.func.isRequired,
  /**
   * It helps to remove the selected filter line
   * It will be passed from SelectedFilters.js
   */
  handleRemoveFilterLine: PropTypes.func.isRequired,
  /**
   * It save the selected filters for the selection
   * it is passed from Selection.js/SubqueryModal.js
   */
  handleFiltersSave: PropTypes.func.isRequired,
  /**
   * It keeps the subquery state for the filter if it contains subquery
   */
  subQuery: PropTypes.instanceOf(Object).isRequired,
  /**
   * It keeps the data extension`s alias of a field
   */
  collectionAlias: PropTypes.string.isRequired,
  addNewFilterline: PropTypes.func,
  /**
   * It helps to merge one filterline with another to a filter container
   * It will be passed from SelectedFilters.js
   */
  handleMergeFilterLines: PropTypes.func.isRequired,
  /**
   * It helps to set the Selection`s state
   * It will be passed from Selection.js
   */
  handleSetSelectionState: PropTypes.func.isRequired,
  /**
   * It helps to change the operator of a filter group
   * AND - OR
   * It will be passed from SelectedFilters.js
   */
  handleLogicChange: PropTypes.func.isRequired,
  /**
   * It helps to indicate which filters will be merged as another level
   * when clicked on add filters together button
   * It will be passed from SelectedFilters.js
   */
  handleHoverFilterMerging: PropTypes.func,
  /**
   * It actually updates the color of the 'add filters together' button
   * It will be passed from SelectedFilters.js
   */
  handleHideAddFiltersTogether: PropTypes.func,
  /**
   * Keeps track whether Available DE are dragged
   */
  DEBorderMouseOver: PropTypes.bool.isRequired,
  /**
   * It helps to add formula for a filter if in results/not in results is selected
   */
  filterBorderMouseOver: PropTypes.bool.isRequired,
  /**
   * Keeps track whether Available Fields are dragged
   */
  handleAddFormulaFilter: PropTypes.func,
  /**
   * Gets the current id of the parent
   */

  parentId: PropTypes.string,
  /**
   * It helps to update a value of a filter
   * It will be passed from SelectedFilters.js
   */
  handleUpdateFilterLineValue: PropTypes.func.isRequired,
  /**
   * Handles the opening of the timezone modal
   */
  handleOpenTimezoneModal: PropTypes.func.isRequired,
  /**
   * Gets the current value of filters and updates the object with tags
   */
  updateTags: PropTypes.func.isRequired,
  /**
   * Handles the addition of tags
   */
  handleTagsChanged: PropTypes.func.isRequired,
  /**
   * Handles the setting of tags on filterline load
   */
  handleSetTags: PropTypes.func.isRequired,
  /**
   * An object with filterlineId as keys and array of tags as values
   */
  tags: PropTypes.instanceOf(Object).isRequired,
  /**
   * Object with filter data
   */
  filterObject: PropTypes.instanceOf(Object).isRequired,
  /**
   * used to save data extension in a subquery
   */
  handleSetDataExtensionToFilter: PropTypes.func.isRequired,
  /**
   * When IN RESULTS is selected as criteria for a filter, there is another
   * drag-drop zone for another dataextension which will be used for subquery
   * This function helps to remove that dataextension from that zone if there is
   * one selected
   * It will be passed from SelectedFilters.js
   */
  handleRemoveDataExtensionFromFilter: PropTypes.func.isRequired,
  /**
   * It helps to open subquery modal if a filter`s criteria is IN RESULTS
   * It will be passed from SelectedFilters.js
   */
  handleOpenSubQueryModal: PropTypes.func.isRequired,
  /**
   * Keeps searched picklist
   * It will be passed from Selection.js
   */
  pickLists: PropTypes.instanceOf(Array).isRequired,
  /**
   * It keeps the field Name
   */
  field: PropTypes.string.isRequired,
  /**
   * It keeps the field type
   */
  fieldType: PropTypes.string.isRequired,
  /**
   * It keeps the show in results option
   */
  showInResultsOption: PropTypes.bool.isRequired,
  /**
   * It helps to add a field as filter to multi level filter lines
   * It will be passed from SelectedFilters.js
   */
  dropFieldOnFilters: PropTypes.func.isRequired,
  /**
   * It toggles a feature advert modal on with specific feature
   */
  showEssentialsUpgradeModal: PropTypes.func,

};

export default inResultsFormulaFilterLine;
