import { combineReducers } from 'redux';

import folderReducer from './waterfallSelection/folderReducer';
import globalReducer from './waterfallSelection/globalReducer';
import selectionReducer from './waterfallSelection/selectionReducer';
import targetDataExtensionReducer from './waterfallSelection/targetDataExtensionReducer';
import errorReducer from './waterfallSelection/errorReducer';
import dataSetsGlobalReducer from './dataSets/globalReducer';
import filterSetsGlobalReducer from './filterSets/globalReducer';
import filterSetsCategoryGlobalReducer from './filterSetsCategory/globalReducer';
import featureAdvertsReducer from './featureAdverts/globalReducer';
import userInformationGlobalReducer from './userInformation/globalReducer';
import { CLEAR_WATERFALL_STATE, CLEAR_DATA_SET_STATE } from '../actions/types';

// combine reducers in app
const reducers = combineReducers({
  selectionReducer,
  folderReducer,
  globalReducer,
  targetDataExtensionReducer,
  errorReducer,
  dataSetsGlobalReducer,
  filterSetsGlobalReducer,
  filterSetsCategoryGlobalReducer,
  featureAdvertsReducer,
  userInformationGlobalReducer,
});

const initialState = reducers({}, {});

const rootReducer = (state, action) => {
  // clear waterfall state
  if (action.type === CLEAR_WATERFALL_STATE || action.type === CLEAR_DATA_SET_STATE) {
    // keep userInformation and infoLoaded untouched
    initialState.userInformationGlobalReducer.userInformation = state.userInformationGlobalReducer.userInformation;
    // eslint-disable-next-line no-param-reassign
    state = initialState;
  }

  return reducers(state, action);
};

export default rootReducer;
