import {
  SET_FILTER_SET_NAME,
  SET_FILTER_SET_CATEGORY,
  SET_FILTER_SET_SOURCE_TYPE,
  SET_FILTER_SET_SELECTED_DATA_EXTENSIONS,
  SET_FILTER_SET_SELECTED_DATA_SET,
  SET_FILTER_SET_SELECTED_SOURCE_DATA_EXTENSION,
  SET_FILTER_SET_SELECTED_FILTERS,
  SET_FILTER_SET_PICKLISTS,
  CLEAR_FILTER_SET_STATE,
  SET_FILTER_SET_DESCRIPTION,
} from '../../actions/types';

const initialState = {
  filterSetName: '',
  filterSetCategory: '',
  filterSetSourceType: 'dataExtension',
  filterSetDescription: '',
  filterSetSelectedDEs: [],
  filterSetSelectedDataSet: null,
  filterSetSelectedSourceDE: null,
  filterSetSelectedFilters: [],
  filterSetPickLists: [],
};

/**
 * Function that takes an action and the previous state of the application and returns the new state
 * @param {object} state - contain initial and final state of data
 * @param {object} action - return the action object
 * @returns {object} updated state
 */
const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTER_SET_NAME:
      return {
        ...state,
        filterSetName: action.payload,
      };
    case SET_FILTER_SET_CATEGORY:
      return {
        ...state,
        filterSetCategory: action.payload,
      };
    case SET_FILTER_SET_SOURCE_TYPE:
      return {
        ...state,
        filterSetSourceType: action.payload,
      };
    case SET_FILTER_SET_DESCRIPTION:
      return {
        ...state,
        filterSetDescription: action.payload,
      };
    case SET_FILTER_SET_SELECTED_DATA_EXTENSIONS:
      return {
        ...state,
        filterSetSelectedDEs: action.payload,
      };
    case SET_FILTER_SET_SELECTED_DATA_SET:
      return {
        ...state,
        filterSetSelectedDataSet: action.payload,
      };
    case SET_FILTER_SET_SELECTED_SOURCE_DATA_EXTENSION:
      return {
        ...state,
        filterSetSelectedSourceDE: action.payload,
      };
    case SET_FILTER_SET_SELECTED_FILTERS:
      return {
        ...state,
        filterSetSelectedFilters: action.payload,
      };
    case SET_FILTER_SET_PICKLISTS:
      return {
        ...state,
        filterSetPickLists: action.payload,
      };
    case CLEAR_FILTER_SET_STATE:
      return { ...initialState };
    default:
      return state;
  }
};

export default globalReducer;
