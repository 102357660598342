import Constants from '../../../../../constants/constants';
import filtersUtil from '../../../../../utils/filters/filtersUtil';
import SwalUtil from '../../../../../utils/swal/swalUtil';

const AggregationValueUtil = {
  changeAliases: (values, oldName, newName) => values?.forEach(value => (
    AggregationValueUtil.changeAlias(value, oldName, newName)
  )),

  changeAlias: (value, oldName, newName) => {
    // If name matches, update current alias in relations in aggregation value
    if (value.aggregationValue && value.aggregationValue.relations &&
      value.aggregationValue.relations.toCollectionAlias === oldName) {
      // eslint-disable-next-line no-param-reassign
      value.aggregationValue.relations.toCollectionAlias = newName;
    }

    // Update current alias in filters in aggregation value
    const { filters: aggregationFilters } = (value?.aggregationValue || {});

    // If aggregationFilters exist
    if (aggregationFilters && aggregationFilters.length) {
      // eslint-disable-next-line no-param-reassign
      value.aggregationValue.filters = filtersUtil.handleUpdateCollectionAliasInFilters(
        oldName,
        newName,
        aggregationFilters,
      );
    }
  },

  isValid: (customValue, relation) => {
    const allRequiredFieldsInRelations = (customValue.relations &&
      customValue.relations?.toCollectionAlias &&
      customValue.relations.toFieldObjectId && customValue.relations.fromFieldObjectId &&
      customValue.relations.fromCollectionCustomerKey && customValue.relations.fromCollectionObjectId);

    if (customValue.mode === Constants.AGGREGATION__MODE__ADVANCED) {
      // validate if formula is selected, if not throw an error
      if (!customValue.formula) {
        SwalUtil.fire({
          type: Constants.SWAL__TYPE__ERROR,
          title: 'Value is missing',
          message: '<b class="bold-swal">Aggregation function</b> is not selected. Please select aggregation function.',
          htmlClass: ' ',
        });

        return false;
      }

      // validate if dataExtensionCustomerKey is selected, if not throw an error
      if (!customValue.dataExtensionCustomerKey) {
        SwalUtil.fire({
          type: Constants.SWAL__TYPE__ERROR,
          title: 'Value is missing',
          message: '<b class="bold-swal">Data extension for field</b> is not selected. Please select data extension.',
          htmlClass: ' ',
        });

        return false;
      }

      // validate if fieldObjectId is selected, if not throw an error
      if (!customValue.fieldObjectId) {
        SwalUtil.fire({
          type: Constants.SWAL__TYPE__ERROR,
          title: 'Value is missing',
          message: '<b class="bold-swal">Field</b> is not selected. Please select field.',
          htmlClass: ' ',
        });

        return false;
      }

      // validate if relation's fields are selected, if not throw an error
      if (!allRequiredFieldsInRelations) {
        SwalUtil.fire({
          type: Constants.SWAL__TYPE__ERROR,
          title: 'Value is missing',
          message: 'Please complete all fields in the <b class="bold-swal">Relation to results</b> section.',
          htmlClass: ' ',
        });

        return false;
      }
    } else {
      if (!allRequiredFieldsInRelations || !relation) {
        SwalUtil.fire({
          type: Constants.SWAL__TYPE__ERROR,
          title: 'Value is missing',
          message: '<b class="bold-swal">Relation</b> is not selected. Please select relation.',
          htmlClass: ' ',
        });

        return false;
      }

      // validate if aggregation function is selected, if not throw an error
      if (!customValue.formula) {
        SwalUtil.fire({
          type: Constants.SWAL__TYPE__ERROR,
          title: 'Value is missing',
          message: '<b class="bold-swal">Aggregation function</b> is not selected. Please select aggregation function.',
          htmlClass: ' ',
        });

        return false;
      }

      // validate if fieldObjectId is selected, if not throw an error
      if (!customValue.fieldObjectId && customValue.formula !== Constants.FORMULA__VALUE__COUNT) {
        SwalUtil.fire({
          type: Constants.SWAL__TYPE__ERROR,
          title: 'Value is missing',
          message: '<b class="bold-swal">Field</b> is not selected. Please select field.',
          htmlClass: ' ',
        });

        return false;
      }
    }

    return true;
  },
};

export default AggregationValueUtil;
