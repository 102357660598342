import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import axios from 'axios';
import Swal from 'sweetalert2';

import ModalTemplate from '../../../shared_v2/ModalTemplate/ModalTemplate';
import Input from '../../../../components/shared/Input/Input';
import './styles.scss';
import Button from '../../../../components/shared/Button/Button';
import Constants from '../../../../constants/constants';
import { setFilterSetCategoryName } from '../../../../redux/actions/filterSetsCategory/globalActions';
import Alert from '../../../../components/shared/Alert/Alert';
import filterSetsUtil from '../../../../utils/filterSets/filterSets';
import FilterSetsCategoryAPI from '../../../../api/filter-sets-category';

const FilterSetsCategories = ({ setFilterSetCategoriesModel, handleCheckEditOrDelete }) => {
  const dispatch = useDispatch();
  const axiosCancelToken = axios.CancelToken.source();
  const inputRef = useRef(null);
  const [tableContent, setTableContent] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [nameFilterSetError, setNameFilterSetError] = useState(false);
  const [filterSetsCategories, setFilterSetsCategories] = useState([]);
  const [savingFilterSetCategory, setSavingFilterSetCategory] = useState(false);
  const [categoryName, setCategoryName] = useState('');
  const [filterSetId, setFilterSetId] = useState('');
  const [checkDelete, setCheckDelete] = useState(false);
  const [missingNameError, setMissingNameError] = useState(false);
  const [editError, setEditError] = useState(false);
  const [checkEditOrDelete, setCheckEditOrDelete] = useState(false);

  useEffect(() => {
    // get filter sets and update filter sets state
    const getAllFilterSetCategories = async () => {
      const filterSets = await FilterSetsCategoryAPI.getFilterSetsCategories(axiosCancelToken.token);

      setFilterSetsCategories(filterSets);
    };

    getAllFilterSetCategories();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savingFilterSetCategory, checkDelete]);

  // get state of properties from reducers
  const { filterSetCategoryName } = useSelector(({ filterSetsCategoryGlobalReducer }) => ({
    // From filter set reducer
    filterSetCategoryName: filterSetsCategoryGlobalReducer.filterSetCategoryName,
  }), shallowEqual);
  /**
   * Saves all the properties of a filter set category in the db
   * @returns {void}
   */
  const handleSaveFilterSetCategory = async () => {
    if (!filterSetCategoryName && !isEditing) {
      setMissingNameError(true);
      setNameFilterSetError(false);
      setEditError(false);
    } else {
      setMissingNameError(false);
      setEditError(false);
    }

    if (categoryName || filterSetCategoryName) {
      setEditError(false);
      const doesFilterSetCategoryNameAlreadyExist = filterSetsCategories.some(
        el => (el?.name?.toLowerCase() === filterSetCategoryName?.toLowerCase()) ||
        (el?.name?.toLowerCase() === categoryName?.toLowerCase() && el?._id !== filterSetId),
      );

      if (doesFilterSetCategoryNameAlreadyExist) {
        setNameFilterSetError(true);
        setMissingNameError(false);

        return;
      }
      setNameFilterSetError(false);
      setSavingFilterSetCategory(true);
      const postData = {
        name: isEditing ? categoryName : filterSetCategoryName,
      };

      try {
        if (isEditing) {
          await FilterSetsCategoryAPI.updateFilterSetCategory(filterSetId, postData, axiosCancelToken.token);
          setIsEditing(false);
          setEditIndex(null);
          setCategoryName('');
          setCheckEditOrDelete(true);
        } else {
          await FilterSetsCategoryAPI.createFilterSetCategory(postData, axiosCancelToken.token);
          setIsEditing(false);
        }
        setSavingFilterSetCategory(false);
        dispatch(setFilterSetCategoryName(null));
      } catch (error) {
        if (!axios.isCancel(error)) {
          filterSetsUtil.throwSwal({
            typeOfSwal: 'error',
            titleOfSwal: 'Error',
            message: error,
          });
          setIsEditing(false);
          setSavingFilterSetCategory(false);
          dispatch(setFilterSetCategoryName(null));
        }
      }
    } else {
      if (isEditing && !categoryName) {
        setMissingNameError(true);

        return;
      }

      setCategoryName('');
      setIsEditing(false);
      setEditIndex(null);
    }
  };

  /**
   * Close FilterSetsCategories modal
   * @returns {void}
   */
  const handleCloseModal = () => {
    setFilterSetCategoriesModel(false);
    if (checkEditOrDelete) {
      handleCheckEditOrDelete(true);
    }
  };

  const handleEditFilterSetCategory = useCallback((i, category) => {
    if (isEditing) {
      setEditError(true);
    } else {
      setFilterSetId(category._id);
      setCategoryName(category.name);
      setMissingNameError(false);
      setNameFilterSetError(false);
      setEditIndex(i);
      setIsEditing(true);
      setEditError(false);
    }
  }, [isEditing]);

  const handleCancelEdit = () => {
    setMissingNameError(false);
    setNameFilterSetError(false);
    setCategoryName('');
    setIsEditing(false);
    setEditIndex(null);
    setEditError(false);
  };

  const handleCategoryNameChange = (e) => {
    setCategoryName(e.target.value);
  };
  /**
   * Deletes the filterSet selected in the
   * overview with the given id
   * @param {Object} event - onClick event
   * @param {Object} category - category object
   * @returns {void}
   */
  const handleRemoveFilterSetCategory = async (event, category) => {
    event.preventDefault();
    setMissingNameError(false);
    setNameFilterSetError(false);
    const result = await Swal.fire({
      type: 'warning',
      title: 'Remove Filter Set Category',
      html: `<p class="width_swal">Are you sure you want to remove <strong>${category.name}</strong> ?</p>`,
      showCancelButton: true,
      confirmButtonText: 'Remove',
      footer: '<div></div>',
      buttonsStyling: false,
    });

    if (result.value) {
      // Delete filterSet
      await FilterSetsCategoryAPI.deleteFilterSetCategory(category._id, axios.CancelToken.source().token);
      setCheckDelete(!checkDelete);
      setCheckEditOrDelete(true);
    }
  };

  /**
   * Renders categories row
   * @returns {void}
   */
  const renderCategoriesTable = useCallback(() => {
    return(filterSetsCategories?.map((category, i) => (
    <tr key={category.name} className="slds-hint-parent row-data">
      <td data-label="Category">
        {i === editIndex ?
          (
          <Input
          withContainer
          name="category-name"
          id="filter-set-category-edit"
          placeholder="Enter Category"
          required
          value={categoryName}
          onChange={handleCategoryNameChange}
          maxLength="128"
          ref={inputRef}
          />
          ) :
          (
          <div className="slds-truncate slds-m-left_x-small filterSet-type">
            {category.name}
          </div>
          )}
      </td>
      <td data-label="Action">
      {
      isEditing && i === editIndex ?
        (
          <div className="slds-truncate slds-text-align_left">
            <button
              type="button"
              className="slds-button slds-button_icon slds-button_icon-border-filled remove-filterSet"
              title="Save"
              onClick={handleSaveFilterSetCategory}
            >
              <svg className="slds-button__icon check" aria-hidden="true">
                <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#check" />
              </svg>
            </button>
            <button
              type="button"
              className="slds-button slds-button_icon slds-button_icon-border-filled remove-filterSet"
              title="Cancel"
              onClick={handleCancelEdit}
            >
              <svg className="slds-button__icon close" aria-hidden="true">
                <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#close" />
              </svg>
            </button>
          </div>
        ) :
        (
          <div className="slds-truncate slds-text-align_left">
            <button
              type="button"
              disabled={filterSetCategoryName}
              className="slds-button slds-button_icon slds-button_icon-border-filled remove-filterSet"
              title="Edit"
              onClick={() => handleEditFilterSetCategory(i, category)}
            >
              <svg className="slds-button__icon" aria-hidden="true">
                <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#edit" />
              </svg>
            </button>
            <button
              disabled={filterSetCategoryName}
              type="button"
              className="slds-button slds-button_icon slds-button_icon-border-filled remove-filterSet"
              title="Remove"
              onClick={async e => handleRemoveFilterSetCategory(e, category)}
            >
              <svg className="slds-button__icon" aria-hidden="true">
                <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#delete" />
              </svg>
            </button>
          </div>
        )
      }

      </td>
    </tr>
    )));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSetsCategories, editIndex, handleEditFilterSetCategory, isEditing, categoryName, filterSetCategoryName]);

  useEffect(() => {
    // Call your function here
    const content = renderCategoriesTable();

    if (!checkEditOrDelete) {
      handleCheckEditOrDelete(false);
    }

    setTableContent(content);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderCategoriesTable, filterSetsCategories]);

  return (
  <ModalTemplate
  id="newAutoCreatedTDE-categories"
  headerId="modal-heading-010"
  headerTitle="Categories"
  contentId="modal-content-id-40"
  contentClassName="slds-p-around_large"
  cancelButtonId="cancelAutoCreatedTDE"
  saveButtonId="save-new_auto_created_TDE"
  cancelButtonTitle="Close"
  handleCancel={handleCloseModal}
  cancelButtonDisabled={isEditing || filterSetCategoryName}
  >
    <div className="input-container">
      <div className={classNames('filter-set-category-container ', { 'saving-mode': savingFilterSetCategory })}>
      <Input
      withContainer
      name="name"
      id="filter-set-category"
      placeholder="Enter Category"
      required
      value={filterSetCategoryName || ''}
      onFocus={e => dispatch(setFilterSetCategoryName(e.target.value))}
      onChange={(e) => {
        setMissingNameError(false);
        setNameFilterSetError(false);
        dispatch(setFilterSetCategoryName(e.target.value));
      }}
      maxLength="128"
      disabled={isEditing}
      />
      <Button
        buttonLook={Constants.BUTTON__TYPE__BRAND}
        onClick={() => handleSaveFilterSetCategory()}
        disabled={savingFilterSetCategory || isEditing}
      >
        {savingFilterSetCategory ?
          (
            <span>
                Saving...
            </span>
          ) :
          (
            <span>Add</span>
          )}
      </Button>
      </div>
      {nameFilterSetError && (
      <Alert
        className={classNames('mt-5px ' + (nameFilterSetError ? 'alert-visible' : 'alert-hidden'))}
        title="Filter Set Category with this name already exists."
        id="nameFilterSetError"
      />
      )}
       {missingNameError && (
      <Alert
        className={classNames('mt-5px ' + (missingNameError ? 'alert-visible' : 'alert-hidden'))}
        title="Filter Set Category name is required."
        id="missingNameError"
      />
       )}

       {editError && (
        <Alert
        className={classNames('mt-5px ' + (editError ? 'alert-visible' : 'alert-hidden'))}
        title="Save or discard the changes"
        id="editError"
      />
       )}
    </div>
      <div className="slds-table--header-fixed_container" id="picklists-panel">
        <div className="slds-scrollable">
          <table className="slds-table slds-table_bordered slds-table--header-fixed">
            <thead>
              <tr className="slds-line-height_reset">
                <th scope="col" className="th-size">
                  <div className="slds-truncate slds-cell-fixed slds-m-left_x-small" title="Last Modified Date">
                    Category
                    {/* sort icon  */}
                  </div>
                </th>
                <th scope="col">
                  <div className="slds-truncate slds-cell-fixed" title="Action">Action</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {tableContent}
            </tbody>
          </table>
        </div>
      </div>

  </ModalTemplate>
  );
};

FilterSetsCategories.propTypes = {
  /**
   * Closes the model
   */
  setFilterSetCategoriesModel: PropTypes.func.isRequired,
  /**
   * Check if category is edited or deleted
   */
  handleCheckEditOrDelete: PropTypes.func.isRequired,

};

export default FilterSetsCategories;
