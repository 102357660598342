import React, { useState } from 'react';
import TagsInput from 'react-tagsinput';
import PropTypes from 'prop-types';

import './styles.scss';
import Constants from '../../../../../../constants/constants';
import SwalUtil from '../../../../../../utils/swal/swalUtil';

const TagInput = ({
  handleTagsChanged,
  tags,
  filterLineId,
  fieldType,
  criteria,
}) => {
  const [tagValue, setTagValue] = useState('');

  /**
   * Event handler for when we paste
   * @param {array} value - The pasted value - It is further passed to handleTagsChanged function
   * @returns {array} The preprocessed array
   */
  const onPaste = value => value.split(/,+/).map(val => val.trim());

  /**
   * Renders the tag component
   * @param {string} tag - The tag's text value
   * @param {number} key - The index of the tag
   * @param {function} onRemove - onClick event handler for remove icon
   * @param {function} getTagDisplayValue - Gets tag's display value
   * @returns {object} The HTML for the tag element
   */
  const renderTagComponent = (tag, key, onRemove, getTagDisplayValue) => (
    <span className="slds-pill tags" role="option" tabIndex="0" aria-selected="true" key={key}>
      <span className="slds-pill__label" title={getTagDisplayValue(tag)}>{getTagDisplayValue(tag)}</span>
      <span
        onClick={() => onRemove(key)}
        data-label={getTagDisplayValue(tag)}
        className="slds-icon_container slds-pill__remove remove-tag"
        title="Remove"
      >
        <svg className="slds-icon slds-icon_x-small slds-icon-text-default" aria-hidden="true">
          <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#close" />
        </svg>
        <span className="slds-assistive-text">Click on the cross to delete</span>
      </span>
    </span>
  );

  /**
   * Renders the react-tags-input layout
   * @param {object} tagComponents - The HTML of tag elements
   * @param {object} inputComponent - The HTML of the input element
   * @returns {object} The HTML for the layout
   */
  const renderLayout = (tagComponents, inputComponent) => (
    <span>
      {inputComponent}
      {tagComponents}
    </span>
  );
  /**
   * checks if values are > 10,000
   * @param {object} newTags - tags added
   * @param {string} filterLineId - Filter line id
   * @param {string} fieldType - type of fields
   * @returns {object} returns newTags
   */
  const handleValueChanged = async (newTags, filterLineId, fieldType) => {
    if(newTags.length > 0) {
      newTags = newTags.filter((el) => {
        return el !== '';
      });
      if(newTags.length > 10000) {
        newTags = newTags.slice(0, 10000);
        const result = await SwalUtil.fire({
          title: 'Limit Reached',
          message: `You are not allowed to use more than 10,000 values.
        If you need to do so, consider importing the values to a 
        Data Extension and use
        ${criteria === Constants.FILTERLINE__CRITERIA__IN ?
    'In' :
    'Not In'} Results filter instead`,
          options: {
            allowOutsideClick: false,
          },
        });

        if (result.value) {
          handleTagsChanged(newTags, filterLineId, fieldType);
        }
      } else {
        handleTagsChanged(newTags, filterLineId, fieldType);
      }
    } else {
      handleTagsChanged(newTags, filterLineId, fieldType);
    }
  };

  const handleTagsOnChange = async (newTags, filterLineId, fieldType) => {
    if (criteria === Constants.FILTERLINE__CRITERIA__IN ||
      criteria === Constants.FILTERLINE__CRITERIA__NOT_IN) {
      await handleValueChanged(newTags, filterLineId, fieldType);
    }else {
      await handleTagsChanged(newTags, filterLineId, fieldType);
    }
  };

  return (
    <div className="tag-input-container">
      <TagsInput
        value={tags[filterLineId] || []}
        onChange={newTags => handleTagsOnChange(newTags, filterLineId, fieldType)}
        inputValue={tagValue}
        onChangeInput={value => value !== ',' && setTagValue(value)}
        inputProps={{
          placeholder: `Press enter or comma to add a ${fieldType?.toLowerCase()}`,
        }}
        onlyUnique
        addKeys={[13, 188]}
        removeKeys={[]}
        addOnBlur
        addOnPaste
        pasteSplit={onPaste}
        renderTag={({
          tag, key, onRemove, getTagDisplayValue,
        }) => renderTagComponent(tag, key, onRemove, getTagDisplayValue)}
        renderLayout={renderLayout}
      />
    </div>
  );
};

TagInput.propTypes = {
  handleTagsChanged: PropTypes.func.isRequired,
  tags: PropTypes.instanceOf(Object).isRequired,
  filterLineId: PropTypes.string.isRequired,
  fieldType: PropTypes.string.isRequired,
  criteria: PropTypes.string.isRequired,
};

export default TagInput;
