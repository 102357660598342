import React from 'react';
import PropTypes from 'prop-types';

import Constants from '../../../constants/constants';
import Button from '../Button/Button';

const AdminHeader = ({
  title, buttonId, onClick, buttonLabel, headerRightContent, disabled, iconTitle, iconLink,
}) => {
  return (
    <div className="slds-page-header slds-m-bottom_medium">
    <div className="slds-page-header__row">
      <div className="slds-page-header__col-title">
        <div className="slds-media header-alignment">
          <div className="slds-media__figure">
            <span className="slds-icon_container slds-icon-standard-account" title={iconTitle || title}>
              <svg className="slds-icon slds-page-header__icon" aria-hidden="true">
                <use xlinkHref={iconLink} />
              </svg>
            </span>
          </div>
          <div className="slds-media__body">
            <div className="slds-page-header__name">
              <div className="slds-page-header__name-title">
                <h1>
                  <span className="slds-page-header__title slds-truncate" title={title}>{title}</span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="slds-page-header__control">
        {buttonId &&
          <Button
            id={buttonId}
            buttonLook={Constants.BUTTON__TYPE__BRAND}
            onClick={onClick}
            disabled={disabled}
          >
            {buttonLabel}
          </Button>}
        {headerRightContent}
      </div>
    </div>
    </div>
  );
};

AdminHeader.propTypes = {
  /**
   * title for the Header
   */
  title: PropTypes.string.isRequired,
  /**
   * title for the icon
   */
  iconTitle: PropTypes.string,
  /**
   * xlinkHref for the icon
   */
  iconLink: PropTypes.string.isRequired,
  /**
   * Id of the button used in Header
   */
  buttonId: PropTypes.string,
  /**
   * onClick function for the button
   */
  onClick: PropTypes.func,
  /**
   * label for the button
   */
  buttonLabel: PropTypes.string,
  /*
   * everything that is used to display in the header except button
   */
  headerRightContent: PropTypes.oneOfType(
    [PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array],
  ),
  /**
   * defines whether the button is disabled
   */
  disabled: PropTypes.bool,
};

export default AdminHeader;
