import axios from 'axios';

import Util from '../util';

const apiUrl = process.env.REACT_APP_API_URL;

const GlobalCustomValuesAPI = {
  /**
   * Get Shared Custom Values for logged in BU
   * @param {object} cancelToken - Token axios
   * @returns {object} Shared Custom Values of logged in BU
   */
  getGlobalCustomValues: async (cancelToken) => {
    const { data: { data } } = await Util.catch418And403Error(
      axios.get,
      `${apiUrl}/global-custom-values`,
      Util.apiGetCallSettings(cancelToken),
    );

    return data;
  },

  /**
   * Create a new Shared Custom Value based on the given values
   * @param {object} postData - object of data
   * @param {object} cancelToken - Token axios
   * @returns {object} Newly created Shared Custom Value
   */
  createGlobalCustomValue: async (postData, cancelToken) => {
    const { data } = await Util.catch418And403Error(
      axios.post,
      `${apiUrl}/global-custom-values`,
      postData,
      Util.apiPostCallSettings(cancelToken),
    );

    return data;
  },

  /**
   * Updates an existing Shared Custom Value with the given values
   * @param {string} id - Id of the Shared Custom Value
   * @param {object} postData - object of data
   * @param {object} cancelToken - Token axios
   * @returns {object} Updated Shared Custom Value
   */
  updateGlobalCustomValue: async (
    id,
    postData,
    cancelToken,
  ) => {
    const { data } = await Util.catch418And403Error(
      axios.put,
      `${apiUrl}/global-custom-values/${id}`,
      postData,
      Util.apiPostCallSettings(cancelToken),
    );

    return data;
  },

  /**
   * Delete Shared Custom Value based the on a given id
   * @param {string} id - Id of the Shared Custom Value that's going to be deleted
   * @param {object} cancelToken - Axios token
   * @returns {object} Deleted Shared Custom Value
   */
  deleteGlobalCustomValue: async (
    id,
    cancelToken,
  ) => {
    const { data } = await Util.catch418And403Error(
      axios.delete,
      `${apiUrl}/global-custom-values/${id}`,
      Util.apiPostCallSettings(cancelToken),
    );

    return data;
  },
};

export default GlobalCustomValuesAPI;
