
import SwalUtil from '../../utils/swal/swalUtil';
import Constants from '../../constants/constants';
import Util from '../../util';

const SelectionUtil = {
  /**
   * Check whether a Custom Value is using DEs that are not part of a selection
   * @param {array} selectedDataExtensions -DEs used in the selection
   * @param {Object} value - custom value object
   * @returns {array} - Array with missing DEs if any
   */
  areDataExtensionsMissing: (selectedDataExtensions, value) => {
    if (value?.valueType === Constants.CUSTOM_VALUES__FIELD_TYPE__FORMULA &&
      value?.formula === Constants.CUSTOM_VALUES__FORMULA_TYPE__ROW_NUMBER) {
      const dataExtension = selectedDataExtensions?.find(de => de.deAlias === value?.orderBy?.collectionAlias);

      if (!dataExtension) return [{ deAlias: value?.orderBy?.collectionAlias }];

      return [];
    }
    const availableObjectIDs = selectedDataExtensions?.map(de => de.ObjectID);

    // Check if any DEs used in custom value are not used inside the selection
    const missingDEs = value?.selectedDataExtensions
      ?.filter(selectedDataExtension => !availableObjectIDs.includes(selectedDataExtension?.ObjectID)) || [];

    // In case of missing DEs do not include Custom Value
    return missingDEs;
  },

  /**
   * Finds the custom value a field is matched to
   * @param {object} field - The field we are trying to find the match for
   * @param {object[]} customValues - Array containing the custom values that should be used for matching
   * @param {object[]} targetDataExtensionFields - The field of the Target Data Extension
   * @param {object[]} selectedDataExtensions - The selected Data Extensions
   * @returns {object} - The matched field details
   */
  getCustomValueMatchedField: (field, customValues, targetDataExtensionFields, selectedDataExtensions) => {
    const isMappedWithCustomValue = field.availableFieldObjectID &&
      (field.availableFieldObjectID === null || field.availableFieldObjectID.includes('customValues') &&
      field.globalCustomValueId !== 'copiedFromOtherBU');

    const isMappedWithThisLocalValue = value => !value?.globalCustomValueId?.length && value?.name === field?.field;
    const isMappedWithThisGlobalValue = value => value?.globalCustomValueId?.length &&
      value._id.toString() === field?.globalCustomValueId.toString();
    const value = customValues?.find(value => (
      isMappedWithThisLocalValue(value) ||
      isMappedWithThisGlobalValue(value)
    ) && isMappedWithCustomValue);

    if (value) {
      const missingDEs = SelectionUtil.areDataExtensionsMissing(
        selectedDataExtensions,
        value,
      );

      if (missingDEs?.length) {
        SwalUtil.fire({
          title: 'Warning',
          type: Constants.SWAL__TYPE__WARNING,
          messageHTML: `Shared Custom Value <strong>${field.field}</strong> is using Data Extension${
            missingDEs?.length > 1 ? 's' : ''} ${
            missingDEs.map(de => `<strong>${de.Name || de.deAlias}</strong>`).join(', ')} that ${
            missingDEs?.length > 1 ? 'are' : 'is'} not part of this selection ` +
              'and will be removed from the mappings.',
          options: {
            showConfirmationButton: true,
            buttonsStyling: false,
            allowOutsideClick: false,
          },
        });

        return;
      }

      // define targetDE field
      const targetDataExtensionField =
        targetDataExtensionFields.filter(tf => tf.ObjectID === field.targetDataExtensionFieldObjectID);

      // create ID for customValue
      const customValueID = Util.uuid();

      // Create new matched field
      const newMatchedField = {
        availableFieldName: value.name,
        availableFieldObjectID: `${customValueID}-customValues`,
        availableFieldDataExtensionCustomerKey: null,
        availableFieldDataExtensionAlias: null,
        targetDataExtensionFieldObjectID: field.targetDataExtensionFieldObjectID,
        targetDataExtensionFieldName: targetDataExtensionField && targetDataExtensionField.length > 0 ?
          targetDataExtensionField[0].Name.toString() :
          null,
        availableFieldIsRequired: 'true',
        availableFieldMaxLength:
          value.valueType === Constants.CUSTOM_VALUES__FIELD_TYPE__DYNAMIC_VALUE ||
          value.valueType === Constants.CUSTOM_VALUES__FIELD_TYPE__FORMULA ||
          value.valueType === Constants.CUSTOM_VALUES__FIELD_TYPE__AGGREGATION_VALUE ?
            null :
            value.value.length,
        globalCustomValueId: field?.globalCustomValueId || '',
      };

      return newMatchedField;
    }

    if (field?.globalCustomValueId?.length) {
      let messageHTML;

      if (field?.globalCustomValueId === 'copiedFromOtherBU') {
        messageHTML = `Shared Custom Value <strong>${field.field}</strong> belongs to a different BU ` +
          'and will be removed from the mappings.';
      } else {
        messageHTML = `Shared Custom Value <strong>${field.field}</strong> has been deleted ` +
        'and will be removed from the mappings.';
      }

      SwalUtil.fire({
        title: 'Warning',
        type: Constants.SWAL__TYPE__WARNING,
        messageHTML,
        options: {
          showConfirmationButton: true,
          buttonsStyling: false,
          allowOutsideClick: false,
        },
      });
    }
  },
};

export default SelectionUtil;
