import React from 'react';
import PropTypes from 'prop-types';
import { Input as SLDSInput, InputIcon } from '@salesforce/design-system-react';

import ClickableTooltip from '../Tooltip/Tooltip';
import './styles.scss';

const Input = ({
  id,
  label,
  placeholder,
  type,
  onChange,
  value,
  errorText,
  required,
  disabled,
  readOnly,
  fixedTextLeft,
  fixedTextRight,
  iconLeft,
  iconRight,
  hasSpinner,
  assistiveText,
  ariaDescribedBy,
  ariaControls,
  ariaLabelledBy,
  ariaExpanded,
  ariaHasPopup,
  autoComplete,
  className,
  defaultValue,
  iconLeftOnClick,
  iconRightOnClick,
  onBlur,
  onClick,
  onFocus,
  onInput,
  onInvalid,
  onKeyDown,
  onKeyPress,
  onKeyUp,
  onSelect,
  onSubmit,
  minLength,
  maxLength,
  minValue,
  maxValue,
  name,
  isStatic,
  role,
  step,
  styleInput,
  styleContainer,
  variant,
  labelTooltip,
  labelTooltipPosition,
  size,
  ...props
}) => {
  // Handle icon click events
  const handleIconLeftClick = () => {
    if (iconLeftOnClick) {
      iconLeftOnClick();
    }
  };

  const handleIconRightClick = () => {
    if (iconRightOnClick) {
      iconRightOnClick();
    }
  };

  const sizeClass = size ? ` des-input--${size}` : '';

  return (
    <SLDSInput
      id={id}
      label={label}
      placeholder={placeholder}
      type={type}
      onChange={onChange}
      value={value}
      errorText={errorText}
      required={required}
      disabled={disabled}
      readOnly={readOnly}
      fixedTextLeft={fixedTextLeft}
      fixedTextRight={fixedTextRight}
      iconLeft={iconLeft ?
        (
        <InputIcon
          assistiveText={assistiveText}
          name={iconLeft}
          category="utility"
          onClick={handleIconLeftClick}
        />
        ) :
        null}
      iconRight={iconRight ?
        (
        <InputIcon
          assistiveText={assistiveText}
          name={iconRight}
          category="utility"
          onClick={handleIconRightClick}
        />
        ) :
        null}
      hasSpinner={hasSpinner}
      aria-describedby={ariaDescribedBy}
      aria-controls={ariaControls}
      aria-labelledby={ariaLabelledBy}
      aria-expanded={ariaExpanded}
      aria-haspopup={ariaHasPopup}
      autoComplete={autoComplete}
      className={`${sizeClass} ${className || ''}`}
      defaultValue={defaultValue}
      onBlur={onBlur}
      onClick={onClick}
      onFocus={onFocus}
      onInput={onInput}
      onInvalid={onInvalid}
      onKeyDown={onKeyDown}
      onKeyPress={onKeyPress}
      onKeyUp={onKeyUp}
      onSelect={onSelect}
      onSubmit={onSubmit}
      minLength={minLength}
      maxLength={maxLength}
      minValue={minValue}
      maxValue={maxValue}
      name={name}
      isStatic={isStatic}
      role={role}
      step={step}
      styleInput={styleInput}
      styleContainer={styleContainer}
      variant={variant}
      fieldLevelHelpTooltip={
        labelTooltip ?
          (
            <ClickableTooltip
              id="help-tooltip"
              align={labelTooltipPosition}
              content={labelTooltip}
            />
          ) :
          null
      }
      {...props}
    />
  );
};

// For SLDSInput props, see https://react.lightningdesignsystem.com/components/inputs/#property-details
Input.propTypes = {
  /**
   * Unique ID for the input element.
   */
  id: PropTypes.string,
  /**
   * Label for the input.
   */
  label: PropTypes.string,
  /**
   * Placeholder text for the input.
   */
  placeholder: PropTypes.string,
  /**
   * HTML5 input type.
   */
  type: PropTypes.oneOf(['text',
    'password', 'datetime', 'datetime-local', 'date', 'month',
    'time', 'week', 'number', 'email', 'url', 'search', 'tel', 'color']),
  /**
   * Callback function triggered when the input value changes.
   */
  onChange: PropTypes.func,
  /**
   * The input value.
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Text to display when there is an error with input value.
   */
  errorText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Indicates if the input is required.
   */
  required: PropTypes.bool,
  /**
   * If true, the input is disabled and users cannot interact with it.
   */
  disabled: PropTypes.bool,
  /**
   * If true, the input is read-only and cannot be edited.
   */
  readOnly: PropTypes.bool,
  /**
   * Text or node to display to the left of the input.
   */
  fixedTextLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Text or node to display to the right of the input.
   */
  fixedTextRight: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Icon to display on the left side of the input.
   */
  iconLeft: PropTypes.node,
  /**
   * Icon to display on the right side of the input.
   */
  iconRight: PropTypes.node,
  /**
   * If true, a spinner is displayed inside the input.
   */
  hasSpinner: PropTypes.bool,
  /**
   * Assistive text for the input.
   */
  assistiveText: PropTypes.shape({
    decrement: PropTypes.string,
    increment: PropTypes.string,
    label: PropTypes.string,
    spinner: PropTypes.string,
  }),
  /**
   * ARIA attribute that identifies the element(s) that describe the object.
   */
  ariaDescribedBy: PropTypes.string,
  /**
   * ARIA attribute that defines an element's ID which controls another element on the page in relation to the input.
   */
  ariaControls: PropTypes.string,
  /**
   * ARIA attribute that identifies the element(s) that label the current element.
   */
  ariaLabelledBy: PropTypes.string,
  /**
   * ARIA attribute that indicates that the element has a popup context menu or sub-level menu.
   */
  ariaExpanded: PropTypes.bool,
  /**
   * ARIA attribute that indicates whether the input has a popup.
   */
  ariaHasPopup: PropTypes.bool,
  /**
   * Indicates if the browser's autocomplete is on or off.
   */
  autoComplete: PropTypes.string,
  /**
   * Custom CSS classes to apply to the input container.
   */
  className: PropTypes.string,
  /**
   * Initial value of the input when it is uncontrolled.
   */
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Callback function triggered when the left icon is clicked.
   */
  iconLeftOnClick: PropTypes.func,
  /**
   * Callback function triggered when the right icon is clicked.
   */
  iconRightOnClick: PropTypes.func,
  /**
   * Callback function triggered when the input loses focus.
   */
  onBlur: PropTypes.func,
  /**
   * Callback function triggered when the input is clicked.
   */
  onClick: PropTypes.func,
  /**
   * Callback function triggered when the input gains focus.
   */
  onFocus: PropTypes.func,
  /**
   * Callback function triggered when there is user input in the input element.
   */
  onInput: PropTypes.func,
  /**
   * Callback function triggered when the input is invalid.
   */
  onInvalid: PropTypes.func,
  /**
   * Callback function triggered when a key is pressed down.
   */
  onKeyDown: PropTypes.func,
  /**
   * Callback function triggered when a key is pressed and released.
   */
  onKeyPress: PropTypes.func,
  /**
   * Callback function triggered when a key is released.
   */
  onKeyUp: PropTypes.func,
  /**
   * Callback function triggered after text has been selected in the element.
   */
  onSelect: PropTypes.func,
  /**
   * Callback function triggered when the form is submitted.
   */
  onSubmit: PropTypes.func,
  /**
   * Defines the minimum number of characters the user can enter into the input field.
   */
  minLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Defines the maximum number of characters the user can enter into the input field.
   */
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Specifies the minimum value for the input field for types such as 'number'.
   */
  minValue: PropTypes.number,
  /**
   * Specifies the maximum value for the input field for types such as 'number'.
   */
  maxValue: PropTypes.number,
  /**
   * Name attribute of the input field.
   */
  name: PropTypes.string,
  /**
   * Indicates the input field is not editable and has a fixed value.
   */
  isStatic: PropTypes.bool,
  /**
   * ARIA role for the input field.
   */
  role: PropTypes.string,
  /**
   * Defines the step size to increment/decrement for input types such as 'number'.
   */
  step: PropTypes.number,
  /**
   * Inline styles to be applied to the input element.
   */
  styleInput: PropTypes.object,
  /**
   * Custom styles to be applied to the component container.
   */
  styleContainer: PropTypes.object,
  /**
   * Designates the style of the input, such as 'base' or 'counter'.
   */
  variant: PropTypes.oneOf(['base', 'counter']),
  /**
   * Text to display in a tooltip when hovering over the label text.
   */
  labelTooltip: PropTypes.string,
  /**
   * Position of the tooltip relative to the icon.
   */
  labelTooltipPosition: PropTypes.oneOf([
    'top',
    'top left',
    'top right',
    'right',
    'right top',
    'right bottom',
    'bottom',
    'bottom left',
    'bottom right',
    'left',
    'left top',
    'left bottom',
  ]),
  /**
   * Size of the input.
   */
  size: PropTypes.oneOf(['x-small', 'small', 'medium', 'large', 'x-large']),
  // ...rest of your propTypes based on the slds props table
};

Input.defaultProps = {
  labelTooltipPosition: 'top left',
  size: 'medium',
};

export default Input;
