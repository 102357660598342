import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import './styles.scss';
import classNames from 'classnames';
import { connect } from 'react-redux';

import mapStateToProps from '../../../mapStateToProps';
import SwalUtil from '../../../utils/swal/swalUtil';
import Constants from '../../../constants/constants';
import TimezoneList from './TimezoneList.json';
import TimezoneDropdown from '../TargetDefinition/CustomValues/TimezoneDropdown/TimezoneDropdown';
import ModalTemplate from '../../shared_v2/ModalTemplate/ModalTemplate';
import ToggleButton from '../../shared_v2/ToogleButton/ToggleButton';
import Select from '../../shared_v2/Select/Select';
import Input from '../../shared_v2/Input/Input';
import timeUtil from '../../../utils/time/timeUtil';
import Util from '../../../util';

class ScheduleSelectionModal extends Component {
  constructor(props) {
    super(props);

    const {
      runRepeat,
      runOnce,
    } = props.scheduledRun;

    // converted datetime variables for DatePicker
    let convertTimeValue;

    let convertDateValue;

    let convertRepeatTimeValue;

    // convert time in runOnce from ''HH:MM:SS' into the proper format for DatePicker
    if (runOnce && runOnce.timeValue) {
      // split into hours, minutes and seconds
      const arrFromTimeValue = runOnce.timeValue.split(':');

      // convert time into proper format for DatePicker
      convertTimeValue = moment(timeUtil.convertTimeToMilliseconds(
        parseInt(arrFromTimeValue[0]),
        parseInt(arrFromTimeValue[1]),
        parseInt(arrFromTimeValue[2]),
      ))._d;
    }

    // convert date in runOnce from 'mm/dd/yyyy' into the proper format for DatePicker
    if (runOnce && runOnce.dateValue) {
      convertDateValue = moment(new Date(runOnce.dateValue)).utc()._d;
    }

    // convert time in runRepeat for DatePicker
    if (runRepeat?.hoursValue && runRepeat?.minutesValue) {
      convertRepeatTimeValue = moment(timeUtil.convertTimeToMilliseconds(
        runRepeat.hoursValue,
        runRepeat.minutesValue,
        0,
      ))._d;
    }

    this.state = {
      enabled: props.scheduledRun.enabled ? props.scheduledRun.enabled : false,
      mode: props.scheduledRun.mode ? props.scheduledRun.mode : Constants.SCHEDULE_SELECTION__MODE__ONCE,
      runOnce: {
        dateValue: convertDateValue || null,
        timeValue: convertTimeValue || null,
      },
      hoursValue: runRepeat.hoursValue ? runRepeat.hoursValue : '0',
      minutesValue: runRepeat.minutesValue ? runRepeat.minutesValue : '0',
      daysOfWeek: runRepeat.daysOfWeek ? runRepeat.daysOfWeek : [],
      daysValue: runRepeat.daysValue ? runRepeat.daysValue : '1',
      monthsValue: runRepeat.monthsValue ? runRepeat.monthsValue : '1',
      repeatTimeValue: convertRepeatTimeValue || moment(0).utc()._d,
      repeatMode: runRepeat.repeatMode ? runRepeat.repeatMode : null,
      timezone: props.scheduledRun.timezone ?
        props.scheduledRun.timezone :
        timeUtil.getCurrentUserTimezone(TimezoneList),
      UTCOffset: props.scheduledRun.UTCOffset ?
        props.scheduledRun.UTCOffset :
        moment().tz(timeUtil.getCurrentUserTimezone(TimezoneList)).format('Z'),
    };
  }

  /**
   * This function returns currentTime in selected timezone
   * @returns {date} current date and time for selected timezone
   */
  currentTime = () => {
    const { timezone } = this.state;

    return timeUtil.currentTimeInTimezone(timezone);
  };

  /**
   * This function shows when next run will be executed for chosen mode
   * @returns {string} returns string with the next run information
   */
  nextRunInformation = () => {
    const { userInfo } = this.props;

    const {
      firstRunEachDay, nextRunDate, nextRunHour, nextRunMinutes,
    } = timeUtil.nextRunInformation({ ...this.state }, userInfo) || {};

    if (nextRunDate && nextRunHour && nextRunMinutes) {
      return (
        <div className="next-run-information">
          {firstRunEachDay ?
            (
              <p className="hourly-first-info">
                {'First run of each day will be at ' + firstRunEachDay + '.'}
              </p>
            ) :
            null}
          <p>
            {`Next run will be ${nextRunDate} at ${nextRunHour}:${nextRunMinutes}.`}
          </p>
        </div>
      );
    }

    return null;
  };

  /**
   * This function helps to change the timezone and offsetTimezone in TimezoneSelect
   * @param {object} e - onChange event object
   * @returns {void}
   */
  handleTimezoneChange = (e) => {
    this.setState({
      timezone: e.target.value,
      UTCOffset: moment().tz(e.target.value).format('Z'),
    });
  };

  /**
   * This function helps to change the dateValue in runOnce for date picker
   * @param {object} date - date value from DatePicker
   * @returns {void}
   */
  handleDateOnceChange = (date) => {
    const { runOnce } = this.state;

    // create newDate to assign date with proper format
    let newDate;

    // assign a date only if it is selected
    if (date) {
      newDate = moment(date)
        .add(moment(date).utcOffset(), 'm')
        .utc()._d;
    } else {
      newDate = null;
    }

    // set newDate as dateValue in runOnce
    this.setState({
      runOnce: { ...runOnce, dateValue: newDate },
    }, () => {
      // if runOnce time is selected and if the new selected date is invalid with this time, reset the time.
      if (runOnce && runOnce.timeValue && this.handleValidationForSchedule()) {
        this.setState({
          runOnce: { ...runOnce, dateValue: newDate, timeValue: null },
        });
      }
    });
  };

  /**
   * This function helps to change the timeValue in runOnce for date picker
   * @param {object} time - time value from DatePicker
   * @returns {void}
   */
  handleTimeOnceChange = (time) => {
    const { runOnce } = this.state;

    // create newTime to assign time with proper format
    let newTime;

    // define interval time
    const intervalTime = 15;

    if (time) {
      // assign a time only if it is selected
      if (time.getMinutes() === timeUtil.roundUpMinutes(time, intervalTime)) {
        // if the minutes match the 15-minute interval
        newTime = timeUtil.formatDateForDatePicker(time)._d;
      } else if (time.getMinutes() !== timeUtil.roundUpMinutes(time, intervalTime)) {
        // otherwise round the minutes up
        const newTimeWithRoundedMinutes = moment(time).set(
          Constants.SCHEDULE_SELECTION__DATE_VALUE__MINUTE,
          timeUtil.roundUpMinutes(time, intervalTime),
        );

        newTime = timeUtil.formatDateForDatePicker(newTimeWithRoundedMinutes)._d;
      }
    } else {
      newTime = null;
    }

    // set newTime as timeValue in runOnce
    this.setState({
      runOnce: { ...runOnce, timeValue: newTime },
    });
  };

  /**
   * This function helps to close the schedule selection modal
   * @returns {void}
   */
  handleCloseScheduleSelection = () => {
    const { handleSetSelectionState } = this.props;

    handleSetSelectionState({ showScheduleSelectionModal: false });
  };

  /**
   * onChange event handler for the enabled schedule toggle button
   * @returns {void}
   */
  handleToggleEnabledScheduleSelection = () => {
    const { enabled } = this.state;

    this.setState({
      enabled: !enabled,
    });
  };

  /**
   * onChange event handler for change the mode ('once' or 'repeat')
   * @param {object} mode - mode to choose - 'once' or 'repeat'
   * @returns {void}
   */
  handleChangeMode = (mode) => {
    const { repeatMode, enabled } = this.state;

    // allow to change the mode if enabled toggle button is turn on
    if (enabled) {
      this.setState({ mode });

      if (mode === Constants.SCHEDULE_SELECTION__MODE__REPEAT && repeatMode === null) {
        // set default value for repeatMode after clicking on mode: 'repeat'
        this.setState({ repeatMode: Constants.SCHEDULE_SELECTION__REPEAT_MODE__HOURLY, hoursValue: '1' });
      }
    }
  };

  /**
   * onChange event handler for change the mode(option) in repeat mode
   * @param {object} repeatMode - mode to choose - 'daily', 'hourly', 'weekly' or 'monthly'
   * @returns {void}
   */
  handleChangeRepeatMode = (repeatMode) => {
    const { enabled, hoursValue, minutesValue } = this.state;

    // allow to change the repeat mode if enabled toggle button is turn on
    if (enabled) {
      // Reset time
      const repeatTimeValue = new Date(timeUtil.convertTimeToMilliseconds(hoursValue, minutesValue, 0));

      // Set state
      this.setState({ repeatMode, repeatTimeValue });
    }
  };

  /**
   * onChange event handler for the days of week checkbox
   * @param {object} e - onChange event object
   * @returns {void}
   */
  handleDaysOfWeekCheckbox = (e) => {
    const { daysOfWeek } = this.state;

    // get the array with selected and sorted days of week
    const selectedDaysOfWeek = timeUtil.setDaysOfWeekInArray(e, daysOfWeek);

    this.setState({ daysOfWeek: selectedDaysOfWeek });
  };

  /**
   * onChange event handler for the minutesValue
   * @param {object} e - onChange event
   * @returns {void}
   */
  handleMinutesChange = (e) => {
    const { hoursValue } = this.state;

    // set new state for minutesValue
    this.setState({ minutesValue: e.target.value });

    // update the time in DatePicker
    this.setState({
      repeatTimeValue: new Date(timeUtil.convertTimeToMilliseconds(hoursValue, e.target.value, 0)),
    });
  };

  /**
   * onChange event handler for the hoursValue
   * @param {object} e - onChange event
   * @returns {void}
   */
  handleHoursChange = (e) => {
    const { minutesValue } = this.state;

    // set new state for hoursValue
    this.setState({ hoursValue: e.target.value });

    // update the time in DatePicker
    this.setState({
      repeatTimeValue: new Date(timeUtil.convertTimeToMilliseconds(e.target.value, minutesValue, 0)),
    });
  };

  /**
   * onChange event handler for the monthsValue
   * @param {object} e - onChange event
   * @returns {void}
   */
  handleMonthsChange = (e) => {
    this.setState({ monthsValue: e.target.value });
  };

  /**
   * onChange event handler for the daysValue
   * @param {object} e - onChange event
   * @returns {void}
   */
  handleDaysChange = (e) => {
    // allow to put only numbers
    if (!/^[0-9]*$/.test(e.target.value)) {
      return;
    }

    // allow to put only numbers under 31
    if (e.target.value > 31) {
      e.preventDefault();

      return;
    }

    // don't allow to put 0 into the field, set 1 after pressing 0
    if (e.target.value === '0') {
      this.setState({ daysValue: '1' });
      e.preventDefault();

      return;
    }

    this.setState({ daysValue: e.target.value });
  };

  /**
   * This function helps to set minutes and hours value after setting the time in DatePicker
   * @param {object} date - date from DatePicker
   * @returns {void}
   */
  handleSetTimeInRepeatMode = (date) => {
    // create a variable to save to the correct date
    let savedDate;

    if (date) {
      // round minutes to 15
      const minutesValue = timeUtil.roundUpMinutes(date, 15);

      if (date.getMinutes() === minutesValue) {
        // if minutes are selected from datepicker (interval every 15 minutes), save this date
        savedDate = date;
      } else if (date.getMinutes() !== minutesValue) {
        // otherwise, set a new date with rounded minutes
        savedDate = moment(date).set(
          Constants.SCHEDULE_SELECTION__DATE_VALUE__MINUTE,
          minutesValue,
        )._d;
      }
    }

    /*
     * after setting saveDate, set minutesValue and hoursValue,
     * save repeatTimeValue to display properly time in DatePicker
     */
    this.setState({
      minutesValue: savedDate ? savedDate.getMinutes().toString() : null,
      hoursValue: savedDate ? savedDate.getHours().toString() : null,
      repeatTimeValue: savedDate ?
        new Date(timeUtil.convertTimeToMilliseconds(savedDate.getHours(), savedDate.getMinutes(), 0)) :
        null,
    });
  };

  /**
   * This function helps to check if all fields are filled, it is used for disable schedule button
   * @returns {boolean} returns true if not all required fields are filled, in another case - returns false
   */
  handleValidationForSchedule = () => {
    const {
      runOnce, repeatMode, minutesValue, hoursValue, daysOfWeek,
      daysValue, monthsValue, mode, enabled, timezone,
    } = this.state;

    if (enabled) {
    // required fields for mode: 'once'
      if (mode === Constants.SCHEDULE_SELECTION__MODE__ONCE) {
        if (!runOnce.dateValue || !runOnce.timeValue) return true;

        return timeUtil.hasPassed(runOnce, timezone);
      }

      // required fields for mode: 'repeat'
      if (mode === Constants.SCHEDULE_SELECTION__MODE__REPEAT) {
      // there are values for hour select in hourly repeatMode
        const hoursValueForHourlySelect = ['1', '2', '3', '4', '6', '12'];

        // there are required fields for repeatMode views
        if (repeatMode === Constants.SCHEDULE_SELECTION__REPEAT_MODE__HOURLY) {
          if (!hoursValue || !minutesValue || !hoursValueForHourlySelect.includes(hoursValue)) return true;
        }

        if (repeatMode === Constants.SCHEDULE_SELECTION__REPEAT_MODE__DAILY) {
          if (!daysValue || !hoursValue || !minutesValue) return true;
        }

        if (repeatMode === Constants.SCHEDULE_SELECTION__REPEAT_MODE__WEEKLY) {
          if (!daysOfWeek.length || !hoursValue || !minutesValue) return true;
        }

        if (repeatMode === Constants.SCHEDULE_SELECTION__REPEAT_MODE__MONTHLY) {
          if (!monthsValue || !daysValue || !hoursValue || !minutesValue) return true;
        }
      }
    }

    return false;
  };

  /**
   * This function saves the schedule data
   * @returns {void}
   */
  handleOnSchedule = async () => {
    const {
      runOnce, repeatMode, minutesValue, hoursValue, daysOfWeek,
      daysValue, monthsValue, mode, enabled, timezone, UTCOffset,
    } = this.state;

    const {
      handleSetSelectionState,
      isWaterfallSelection,
      handleSetScheduledRunState,
      handleCloseScheduleWaterfallSelection,
      handleSetHasScheduleBeenEnabled,
      selectionsSchedules,
      selectedSelections,
      currentSelectionId,
      currentSelectionName,
    } = this.props;

    let timeValueAsString;

    if (runOnce.timeValue) {
      // set timeValue in 24-hour format 'HH:MM:SS' UTC timeZone
      timeValueAsString = moment.utc(runOnce.timeValue).format('HH:mm:ss');
    }

    // create new object for selection
    const scheduleRunForSelection = {
      enabled,
      mode,
      runOnce: mode === Constants.SCHEDULE_SELECTION__MODE__ONCE &&
        {
          /*
           * save the timeValue and dateValue in a readable userLocale format
           * send timeValue to the Selection in 24-hour format 'HH:MM:SS'
           * send dateValue to the Selection in proper format ('yyyy-mm-dd')
           */
          timeValue: runOnce.timeValue ? timeValueAsString : null,
          dateValue: runOnce.dateValue ?
            runOnce.dateValue.toISOString().split('T')[0] :
            null,
        },
      runRepeat: mode === Constants.SCHEDULE_SELECTION__MODE__REPEAT &&
        {
          repeatMode,
          minutesValue,
          hoursValue,
          daysOfWeek: repeatMode === Constants.SCHEDULE_SELECTION__REPEAT_MODE__WEEKLY ? daysOfWeek : [],
          daysValue: (repeatMode === Constants.SCHEDULE_SELECTION__REPEAT_MODE__DAILY ||
             repeatMode === Constants.SCHEDULE_SELECTION__REPEAT_MODE__MONTHLY) ?
            daysValue :
            null,
          monthsValue: repeatMode === Constants.SCHEDULE_SELECTION__REPEAT_MODE__MONTHLY ? monthsValue : null,
        },
      timezone,
      UTCOffset,
    };

    // Make sure schedule got enabled, and check if there are concurrent schedules
    if (enabled && Object.keys(selectionsSchedules || {})?.length) {
      // Create a message for each TDE
      const messages = Object.keys(selectionsSchedules).map((targetCollectionObjectID) => {
        const targetCollection = selectionsSchedules[targetCollectionObjectID] || [];
        // Split schedules from standard and waterfall selections
        const { standardSelections, waterfallSelections } = targetCollection
          .reduce(({ standardSelections, waterfallSelections }, selection) => {
            // In case selection itself is scheduled add it to standard selections list
            if (selection?.isScheduled && selection?._id !== currentSelectionId) {
              standardSelections.push(selection.name);
            }

            // In case waterfall selections are scheduled add them to list
            waterfallSelections =
              waterfallSelections.concat(selection?.waterfallSelections?.reduce((validWaterfalls, waterfallName) => {
                if (!waterfallSelections.includes(waterfallName) && waterfallName !== currentSelectionName) {
                  validWaterfalls.push(waterfallName);
                }

                return validWaterfalls;
              }, []) || []);

            return { standardSelections, waterfallSelections };
          }, { standardSelections: [], waterfallSelections: [] });

        // Check if schedules were found
        if (standardSelections?.length || waterfallSelections?.length) {
          // Get TDE name, and selected selection name
          const { runDataExtensionName, name } =
            selectedSelections?.find(selection => selection.targetCollectionObjectID === targetCollectionObjectID) ||
            {};

          // eslint-disable-next-line max-len
          let message = `<p class="scheduled-tde">Target Data Extension <strong>${runDataExtensionName || ''}</strong> of selection <strong>${name || currentSelectionName}</strong> gets updated by:<ul>`;

          if (standardSelections?.length) {
            message +=
              `${standardSelections.map(selection => `<li><strong>${selection}</strong> Selection.</li>`).join('')}`;
          }

          if (waterfallSelections?.length) {
            message +=
              `${waterfallSelections.map(
                selection => `<li><strong>${selection}</strong> Waterfall Selection.</li>`,
              ).join('')}`;
          }

          return `${message}</ul></p>`;
        }

        return '';
      });

      // In case there are messages, so swal warning
      if (messages?.length && messages[0]?.length) {
        const finalMessage =
          `Please note that
          ${isWaterfallSelection ? 'there are Selections in this Waterfall whose ' : 'this '}
          Target Data Extension gets updated by other active schedules.
          If you continue with your current action this may lead to unexpected results.
          <details>
            <summary>
              <span class='show-more'>Show More</span>
            </summary>
            <p>${messages.join('')}</p>
            <span class='show-less' onclick="${Util.hideDetails()}">Show less</span>
          </details>`;

        const result = await SwalUtil.fire({
          title: 'Warning',
          type: Constants.SWAL__TYPE__WARNING,
          messageHTML: finalMessage,
          options: {
            customClass: 'clashing-schedules-error',
            confirmButtonText: 'Continue',
            buttonsStyling: false,
            allowOutsideClick: false,
            showCancelButton: true,
          },
        });

        if (!result.value) return;
      }
    }

    if (isWaterfallSelection) {
      // Set state for run schedule
      handleSetScheduledRunState(scheduleRunForSelection);

      // set hasScheduleBeenEnabled to save the previous user selection
      handleSetHasScheduleBeenEnabled(enabled);

      // Close modal
      handleCloseScheduleWaterfallSelection();
    } else {
      // Set state for selection
      handleSetSelectionState({ scheduledRun: scheduleRunForSelection, hasScheduleBeenEnabled: enabled });

      // Close modal
      this.handleCloseScheduleSelection();
    }
  };

  render() {
    const daysOfWeekArray = timeUtil.returnDaysOfWeek();

    // array with number options for dropdown, from 1 to 6 without 5
    const numberOptionsFromOneToSix = [...Array.from({ length: 5 }, (v, k) => k === 4 ? k + 2 : k + 1)].map(number => (
      { value: number.toString(), label: number.toString() }));

    // array with number options for dropdown with minutes, with an interval of 15
    const numberOptionsWith15MinutesInterval = [...Array.from({ length: 4 }, (v, k) => k * 15)].map(number => (
      { value: number.toString(), label: Util.addLeadingZero(number.toString()) }));

    const {
      runOnce,
      repeatMode,
      hoursValue,
      mode,
      minutesValue,
      daysOfWeek,
      repeatTimeValue,
      daysValue,
      monthsValue,
      enabled,
      timezone,
    } = this.state;

    const { scheduledWaterfallSelections, userInfo } = this.props;

    const userLocale = timeUtil.getUserLocale(userInfo);
    const datePickerDateFormat = timeUtil.getDatePickerDateFormat(userLocale);

    const scheduledWSNames = scheduledWaterfallSelections?.[0]?.waterfallSelections?.reduce((names, waterfalls) => {
      return [...names, waterfalls.name];
    }, []) || [];

    const scheduleOptionClassName = selectedMode => classNames(
      'schedule-option',
      {
        /* eslint-disable quote-props */
        'activeOnce': selectedMode === Constants.SCHEDULE_SELECTION__MODE__ONCE &&
        mode === Constants.SCHEDULE_SELECTION__MODE__ONCE && enabled,
        'activeRepeat': selectedMode === Constants.SCHEDULE_SELECTION__MODE__REPEAT &&
        mode === Constants.SCHEDULE_SELECTION__MODE__REPEAT,
        'disableActiveOnce': selectedMode === Constants.SCHEDULE_SELECTION__MODE__ONCE &&
        mode === Constants.SCHEDULE_SELECTION__MODE__ONCE && !enabled,
        'disableCursor': !enabled,
        /* eslint-enable quote-props */
      },
    );

    /**
     * Returns class names for repeat mode option button
     * @param {string} repeatModeOption - option in the repeat mode
     * @returns {void}
     */
    const timePeriodButtonClassName = repeatModeOption => classNames(
      repeatModeOption,
      'period-btn',
      // eslint-disable-next-line quote-props
      { 'active': repeatMode === repeatModeOption && enabled },
    );
    const { isWaterfallSelection, handleCloseScheduleWaterfallSelection } = this.props;

    return (
      <ModalTemplate
          id="schedule-selection-modal-dialog"
          containerClassName="schedule-selection-modal"
          headerId="schedule-selection-header"
          headerTitle={`Schedule ${isWaterfallSelection ? 'Waterfall ' : ''}Selection`}
          footerId="schedule-selection-footer"
          cancelButtonId={isWaterfallSelection ? 'schedule-waterfall-cancel' : 'schedule-selection-cancel'}
          handleCancel={isWaterfallSelection ?
            handleCloseScheduleWaterfallSelection :
            this.handleCloseScheduleSelection}
          handleSave={this.handleOnSchedule}
          saveButtonDisabled={this.handleValidationForSchedule()}
          saveButtonTitle="Confirm"
          contentClassName="schedule-selection-content"
          saveButtonId={isWaterfallSelection ? 'schedule-waterfall-save' : 'schedule-selection-save'}
        >
          {!isWaterfallSelection && scheduledWSNames.length ?
            (<div>
            <p className="part-of-scheduled-waterfall-notice">
              {`Note: This Selection is part of the following scheduled Waterfall Selection${
                scheduledWSNames?.length === 1 ? '' : 's'}:
               ${scheduledWSNames.map(name => `'${name}'`).join(', ')}`}
            </p>
            <br />
             </div>) :
            null}
          <div className="turn-on-off-schedule">
            <span className="slds-form-element__label slds-m-bottom_none checkbox-label">
              Set up selection to run automatically
            </span>
            <ToggleButton
              id="scheduleSelectionToggle"
              name="scheduleSelectionToggle"
              onChange={this.handleToggleEnabledScheduleSelection}
              checked={enabled}
            />
          </div>
          <div className="timezone-content">
            <p className="UTCTimeOffset-schedule-text">Select a timezone</p>
            <TimezoneDropdown
              timezoneName={timezone}
              handleOnChange={this.handleTimezoneChange}
              disabled={!enabled}
            />
          </div>
          <div className="schedule-options-container">
            <div className="header-options">
              <div
                className={scheduleOptionClassName(Constants.SCHEDULE_SELECTION__MODE__ONCE)}
                onClick={() => this.handleChangeMode(Constants.SCHEDULE_SELECTION__MODE__ONCE)}
              >
                Once
              </div>
              <div
                className={scheduleOptionClassName(Constants.SCHEDULE_SELECTION__MODE__REPEAT)}
                onClick={() => this.handleChangeMode(Constants.SCHEDULE_SELECTION__MODE__REPEAT)}
              >
                Repeat
              </div>
            </div>
            <div className={`content-for-schedule-options ${!enabled && 'disabledTitle'}`}>
              {mode === Constants.SCHEDULE_SELECTION__MODE__ONCE && (
              <>
                <div className="date-time-container date">
                  <p>Date</p>
                  <DatePicker
                    className="datepicker date"
                    type="text"
                    dateFormat={datePickerDateFormat}
                    minDate={this.currentTime()}
                    onChange={date => this.handleDateOnceChange(date)}
                    selected={runOnce.dateValue ?
                      timeUtil.formatDateInDatePicker(runOnce.dateValue) :
                      null}
                    disabled={!enabled}
                  />
                </div>
                <div className="date-time-container time">
                  <p>Time</p>
                  <DatePicker
                    className="datepicker time"
                    type="text"
                    selected={runOnce.timeValue ?
                      timeUtil.formatDateInDatePicker(runOnce.timeValue) :
                      null}
                    onChange={time => this.handleTimeOnceChange(time)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                    disabled={!enabled}
                    minTime={timeUtil.isTodaySelected(runOnce, timezone) ?
                      timeUtil.currentTimeInTimezone(timezone) :
                      moment().set({ hour: 0, minute: 0 })._d}
                    maxTime={moment().set({ hour: 23, minute: 45 })._d}
                  />
                </div>
              </>
              )}
              {mode === Constants.SCHEDULE_SELECTION__MODE__REPEAT && (
              <>
                <div className={`time-period-buttons ${!enabled &&
                          'disabledTitle'}`}
                >
                  <span
                    className={timePeriodButtonClassName(Constants.SCHEDULE_SELECTION__REPEAT_MODE__HOURLY)}
                    onClick={() => this.handleChangeRepeatMode(
                      Constants.SCHEDULE_SELECTION__REPEAT_MODE__HOURLY,
                    )}
                  >
                    Hourly

                  </span>
                  <span
                    className={timePeriodButtonClassName(Constants.SCHEDULE_SELECTION__REPEAT_MODE__DAILY)}
                    onClick={() => this.handleChangeRepeatMode(
                      Constants.SCHEDULE_SELECTION__REPEAT_MODE__DAILY,
                    )}
                  >
                    Daily

                  </span>
                  <span
                    className={timePeriodButtonClassName(Constants.SCHEDULE_SELECTION__REPEAT_MODE__WEEKLY)}
                    onClick={() => this.handleChangeRepeatMode(
                      Constants.SCHEDULE_SELECTION__REPEAT_MODE__WEEKLY,
                    )}
                  >
                    Weekly

                  </span>
                  <span
                    className={timePeriodButtonClassName(Constants.SCHEDULE_SELECTION__REPEAT_MODE__MONTHLY)}
                    onClick={() => this.handleChangeRepeatMode(
                      Constants.SCHEDULE_SELECTION__REPEAT_MODE__MONTHLY,
                    )}
                  >
                    Monthly

                  </span>
                </div>
                <div className={`time-period-content ${!enabled &&
                          'disabledTitle'}`}
                >

                  {repeatMode === Constants.SCHEDULE_SELECTION__REPEAT_MODE__HOURLY && (
                  <>
                    <div className={`${repeatMode} repeat-time-content`}>
                      <span>Every</span>
                      <Select
                        value={hoursValue || ''}
                        onChange={this.handleHoursChange}
                        disabled={!enabled}
                        options={[{ value: '', label: '' },
                          ...numberOptionsFromOneToSix, {
                            value: '12', label: '12',
                          }]}
                        noContainer
                      />
                      <span> hour(s) on minute </span>
                      <Select
                        value={minutesValue || ''}
                        onChange={this.handleMinutesChange}
                        disabled={!enabled}
                        options={[{ value: '', label: '' }, ...numberOptionsWith15MinutesInterval]}
                        noContainer
                      />
                    </div>
                    <div>{this.nextRunInformation()}</div>
                  </>
                  )}
                  {repeatMode === Constants.SCHEDULE_SELECTION__REPEAT_MODE__DAILY && (
                  <>
                    <div className={`${repeatMode} repeat-time-content`}>
                      <span>Every</span>
                      <Input
                        name="value"
                        type={Constants.FILTERLINE__FIELDTYPE__NUMBER}
                        pattern="[0-9]*"
                        inputMode="numeric"
                        id="field-value-text"
                        min="1"
                        max="31"
                        value={daysValue || ''}
                        disabled={!enabled}
                        onChange={this.handleDaysChange}
                      />
                      <span> day(s) at </span>
                      <DatePicker
                        className="datepicker time"
                        type="text"
                        selected={repeatTimeValue ?
                          timeUtil.formatDateInDatePicker(repeatTimeValue) :
                          null}
                        onChange={date => this.handleSetTimeInRepeatMode(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="HH:mm"
                        timeFormat="HH:mm"
                        disabled={!enabled}
                      />
                      <span className="last-daily-span"> from the 1st day throughout the month.</span>
                    </div>
                    <div>{this.nextRunInformation()}</div>
                  </>
                  )}
                  {repeatMode === Constants.SCHEDULE_SELECTION__REPEAT_MODE__WEEKLY && (
                  <>
                    <div className={`${repeatMode} repeat-time-content`}>
                      <div>Every</div>
                      <div className="days-of-week-schedule">
                        {daysOfWeekArray.map((day, index) => (
                          <div
                            className="slds-dropdown__item schedule-days"
                            role="presentation"
                            title={day}
                            id="schedule-days-of-week"
                            key={`${day}-${index < 6 ? index + 1 : 0}`}
                          >
                            <Input
                              type="checkbox"
                              name="checkbox-onoff"
                              noInputClassName
                              className="days-of-week-checkbox"
                                    // for Sunday id should be 0, for Monday - 1, etc...
                              id={index < 6 ? (index + 1).toString() : '0'}
                              onChange={e => this.handleDaysOfWeekCheckbox(e)}
                              checked={daysOfWeek.includes(index < 6 ? index + 1 : 0)}
                              disabled={!enabled}
                            />
                            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                            <label
                              htmlFor={index < 6 ? index + 1 : 0}
                              className="days-of-week-inscription"
                            >
                              {day.substring(0, 3)}
                            </label>
                            {index === 6 &&
                            <span>at</span>}
                          </div>
                        ))}
                        <DatePicker
                          className="datepicker time"
                          type="text"
                          selected={repeatTimeValue ?
                            timeUtil.formatDateInDatePicker(repeatTimeValue) :
                            null}
                          onChange={date => this.handleSetTimeInRepeatMode(date)}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="HH:mm"
                          timeFormat="HH:mm"
                          disabled={!enabled}
                        />
                      </div>
                    </div>
                    <div>{this.nextRunInformation()}</div>
                  </>
                  )}

                  {repeatMode === Constants.SCHEDULE_SELECTION__REPEAT_MODE__MONTHLY && (
                  <>
                    <div className={`${repeatMode} repeat-time-content`}>
                      <span>On day</span>
                      <Input
                        name="value"
                        type={Constants.FILTERLINE__FIELDTYPE__NUMBER}
                        pattern="[0-9]*"
                        inputMode="numeric"
                        id="field-value-text"
                        min="1"
                        max="31"
                        value={daysValue || ''}
                        disabled={!enabled}
                        onChange={this.handleDaysChange}
                      />
                      <span> of every </span>
                      <Select
                        value={monthsValue || ''}
                        onChange={this.handleMonthsChange}
                        disabled={!enabled}
                        options={[{ value: '', label: '' }, ...numberOptionsFromOneToSix]}
                        noContainer
                      />
                      <span> month(s) at </span>
                      <DatePicker
                        className="datepicker time"
                        type="text"
                        selected={repeatTimeValue ?
                          timeUtil.formatDateInDatePicker(repeatTimeValue) :
                          null}
                        onChange={date => this.handleSetTimeInRepeatMode(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="HH:mm"
                        timeFormat="HH:mm"
                        disabled={!enabled}
                      />
                    </div>
                    <div>{this.nextRunInformation()}</div>
                  </>
                  )}
                </div>
              </>
              )}
            </div>
          </div>
      </ModalTemplate>
    );
  }
}

ScheduleSelectionModal.propTypes = {
  /*
   * It helps to set the Selection`s state
   * It will be passed from Selection.js
   */
  handleSetSelectionState: PropTypes.func,
  /*
   * State with schedule selection object
   * It will be passed from Selection.js
   */
  scheduledRun: PropTypes.instanceOf(Object).isRequired,
  /**
   * Indicates whether selection is a waterfall selection
   */
  isWaterfallSelection: PropTypes.bool,
  /**
   * Sets scheduledRun state for waterfall selections
   */
  handleSetScheduledRunState: PropTypes.func,
  /**
   * Handles the closing of scheduleSelectionModal for waterfall selections
   */
  handleCloseScheduleWaterfallSelection: PropTypes.func,
  /**
   * Saves previous user selection about enable / disable schedule run
   */
  handleSetHasScheduleBeenEnabled: PropTypes.func,
  /**
   * An array of selected selections in a waterfall selection
   */
  selectedSelections: PropTypes.instanceOf(Array),
  /**
   * Waterfall schedules for selected selections
   */
  selectionsSchedules: PropTypes.instanceOf(Object),
  /**
   * Id of the current selection
   */
  currentSelectionId: PropTypes.string,
  /**
   * Name of the current selection
   */
  currentSelectionName: PropTypes.string,
  /**
   * Scheduled waterfall selection in which this  selection is used
   */
  scheduledWaterfallSelections: PropTypes.array.isRequired,
  /**
   * User info from cookie
   */
  userInfo: PropTypes.object,
};

export default connect(mapStateToProps(['userInfo']), null, null, { pure: false })(ScheduleSelectionModal);
