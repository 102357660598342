import axios from 'axios';

import Util from '../util';

const apiUrl = process.env.REACT_APP_API_URL;

const FeatureAdvertsAPI = {
  /**
   * Get feature adverts
   * @param {object} cancelToken - Token axios
   * @returns {object} Feature adverts
   */
  getFeatureAdverts: async (cancelToken) => {
    const { data: { data } } = await Util.catch418And403Error(
      axios.get,
      `${apiUrl}/feature-adverts`,
      Util.apiGetCallSettings(cancelToken),
    );

    return data;
  },
};

export default FeatureAdvertsAPI;
