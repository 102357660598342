import React from 'react';
import PropTypes from 'prop-types';

import Constants from '../../../../../../constants/constants';
import './styles.scss';
import Util from '../../../../../../util';
import RadioButton from '../../../../../shared/RadioButton/RadioButton';

const PrioSorting = [
  {
    label: Constants.PRIO_DEDUP__SORTING_PRIO__DEFINE_VALUES__LABEL,
    value: Constants.PRIO_DEDUP__SORTING_PRIO__DEFINE_VALUES__VALUE,
  },
  {
    label: Constants.PRIO_DEDUP__SORTING_PRIO__SORT_ALL_VALUES__LABEL,
    value: Constants.PRIO_DEDUP__SORTING_PRIO__SORT_ALL_VALUES__VALUE,
  },
];

const fieldTypesToDisable = [
  Constants.FILTERLINE__FIELDTYPE__BOOLEAN,
  Constants.FILTERLINE__FIELDTYPE__EMAILADDRESS,
  Constants.FILTERLINE__FIELDTYPE__LOCALE,
  Constants.FILTERLINE__FIELDTYPE__PHONE,
];

const fieldTypesToDisable2 = [
  Constants.FILTERLINE__FIELDTYPE__DATE,
];

/**
 * Component Prio Type
 * @param {object} props - The props
 * @returns {object} HTML for the prio type
 */
const PrioType = ({
  selectedType,
  handleChangeRadioValues,
  deduplicationFieldName,
  criteriaFieldName,
  criteriaFieldType,
}) => {
  const getHelpText = () => {
    let helpText;

    switch (selectedType) {
      case Constants.PRIO_DEDUP__SORTING_PRIO__DEFINE_VALUES__VALUE:
        // eslint-disable-next-line max-len
        helpText = `Results will be prioritized based on a user defined list of values for ${Util.abbreviate(criteriaFieldName, 30)}.`;
        break;

      case Constants.PRIO_DEDUP__SORTING_PRIO__SORT_ALL_VALUES__VALUE:
      default:
        // eslint-disable-next-line max-len
        helpText = `Results will be prioritized based on a sorting of all available values of ${Util.abbreviate(criteriaFieldName, 30)} for each ${Util.abbreviate(deduplicationFieldName, 20)}.`;
        break;
    }

    return helpText;
  };

  return (
    <>
      <fieldset className="slds-form-element prio-type">
        <legend className="slds-form-element__legend slds-form-element__label">
          Priorization Logic
        </legend>
        <div className="slds-form-element__control">
          <div className="slds-radio_button-group">
            {PrioSorting.map(sort => (
              <RadioButton
                unlabeledInput
                containerClassName="slds-button slds-radio_button"
                id={sort.value}
                disabled={
                 !!((sort.value === Constants.PRIO_DEDUP__SORTING_PRIO__SORT_ALL_VALUES__VALUE &&
                   fieldTypesToDisable.includes(criteriaFieldType)) ||
                   (sort.value === Constants.PRIO_DEDUP__SORTING_PRIO__DEFINE_VALUES__VALUE &&
                     fieldTypesToDisable2.includes(criteriaFieldType)))
                }
                name="selectedType"
                value={sort.value}
                checked={selectedType === sort.value}
                onChange={e => handleChangeRadioValues(e)}
                key={sort.value}
                label={sort.label}
              />
            ))}
          </div>
        </div>
      </fieldset>
      <p
        className="getHelpText"
        title={criteriaFieldName && criteriaFieldName.length > 16 ? criteriaFieldName : ''}
      >
        {getHelpText()}
      </p>
    </>
  );
};

PrioType.propTypes = {
  /**
   * It keeps the data for selected type
   *
   */
  selectedType: PropTypes.string.isRequired,
  /**
   * It helps to switch between deduplication modes
   */
  handleChangeRadioValues: PropTypes.func.isRequired,
  /**
   * It keeps the deduplication field's name
   *
   */
  deduplicationFieldName: PropTypes.string,
  /**
   * It keeps the criteria field's name
   *
   */
  criteriaFieldName: PropTypes.string,
  /**
   * It keeps the criteria field's type
   *
   */
  criteriaFieldType: PropTypes.string,
};
export default PrioType;
