import React, {
  useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';

import useTips from './useTips';

function PreviewTips({ axiosCancelToken }) {
  const [fade, setFade] = useState('fade-in');
  const {
    tip, fetchTips, setNextTip,
  } = useTips();

  useEffect(() => {
    fetchTips(axiosCancelToken);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axiosCancelToken]);

  useEffect(() => {
    const interval = setInterval(async () => {
      setFade('fade-out');

      // timeout for 1 second fade out animation for incoming tip
      const timeout = n => new Promise(cb => setTimeout(cb, n));

      await timeout(1000);

      setNextTip();
      setFade('fade-in');
    }, 10000);

    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNextTip]);

  return (
    <div className="preview-tip-container">
      <div className="message">
        <div className="header">DESelect Tip</div>
        <p className={fade}>{tip?.message}</p>
      </div>
    </div>
  );
}

PreviewTips.propTypes = {
  /**
   * It helps to cancel a subscription of an API call to backend
   * It will be passed from Preview.js
   */
  axiosCancelToken: PropTypes.instanceOf(Object),
};

export default PreviewTips;
