/* eslint-disable spellcheck/spell-checker */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Button, Badge, Modal } from '@salesforce/design-system-react';

import Constants from '../../constants/constants';
import './styles.scss';
import Spinner from '../shared_v2/Spinner/Spinner';

const customButtonStyles = {
  width: '9.375rem',
};

class DeleteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteQueryActivities: true,
      deleteTargetDE: true,
      loadingData: false,
    };
  }

  /**
   * Fetch data while loading modal
   * @returns {void}
   */
  async componentDidMount() {
    // Before mounting we create 'CancelToken' for further cancellation of memory leak
    this.axiosCancelToken = axios.CancelToken.source();
  }

  /**
   * Avoid memory leak by canceling all subscriptions and asynchronous tasks
   * @returns {void}
   */
  componentWillUnmount() {
    this.axiosCancelToken.cancel();
  }

  /**
   * Removes selected Selection
   * @returns {void}
   */
  handleOnSubmit = async () => {
    const { deleteQueryActivities, deleteTargetDE } = this.state;
    const { handleRemoveSelection, handleCloseDeleteSelectionModal, selection } = this.props;

    // delete a Selection and close the modal
    handleRemoveSelection(selection._id, deleteQueryActivities, deleteTargetDE);
    handleCloseDeleteSelectionModal();
  };

  render() {
    const {
      handleCloseDeleteSelectionModal,
      selection,
      isWaterfall,
      handleOpenArchiveSelectionModal,
    } = this.props;

    const {
      loadingData,
    } = this.state;

    return (
      selection ?
        (
          <Modal
          disableClose
          size="small"
          isOpen
          >
            {loadingData ?
              (
                <div className="slds-m-around_medium" style={{ height: '6rem' }}>
                  <Spinner size={Constants.SPINNER__SIZE__MEDIUM} />
                </div>
              ) :
              (
              <div className="slds-m-around_medium">
                  <div className="des-lib-datatable-delete-title">
                  {isWaterfall ? 'Delete this Waterfall Selection? ' : 'Delete this Selection?'}
                  </div>
                  <div className="des-lib-datatable-delete-text-top">
                  <div>This action is definitive</div>
                  <div>and you will not be able to restore it.</div>
                  </div>
                  {selection?.isArchived ?
                    null :
                    (
                      <div className="des-lib-datatable-delete-middle-box">
                        <Badge
                          id="badge-tips"
                          color="inverse"
                          content="Tip"
                        />
                        <div className="des-lib-datatable-delete-middle-box-text">
                          <div>If you’re not sure about this, you always can archive it.</div>
                          <div>
                            It won’t be on your active
                            {isWaterfall ? ' Waterfall Selections ' : ' Selections '}
                            list while always being findable
                          </div>
                          in your Archived
                          {isWaterfall ? ' Waterfall Selections ' : ' Selections '}
                          {' '}
                          folder.
                        </div>
                        <div
                          className="des-lib-datatable-delete-middle-box-link"
                          onClick={() => {
                            handleCloseDeleteSelectionModal();
                            handleOpenArchiveSelectionModal(selection?._id);
                          }}
                        >
                          Archive the
                          {isWaterfall ? ' Waterfall Selection ' : ' Selection '}
                        </div>
                      </div>
                    )}
                  <div className="des-lib-datatable-delete-actions">
                  <div>
                      <Button
                          iconCategory="utility"
                          label="Cancel"
                          size="large"
                          style={customButtonStyles}
                          onClick={() => handleCloseDeleteSelectionModal()}
                          />
                  </div>
                  <div className="des-lib-datatable-delete-btn">
                  <Button
                      label="Delete"
                      variant="destructive"
                      style={customButtonStyles}
                      onClick={() => this.handleOnSubmit()}
                      />
                  </div>
                  </div>
              </div>
              )}
          </Modal>

        ) :
        (
          null
        )
    );
  }
}

DeleteModal.propTypes = {
  /**
   * Object with a selected Selection
   */
  selection: PropTypes.oneOfType([PropTypes.object]).isRequired,
  /**
   * It closes the DeleteSelectionModal
   */
  handleCloseDeleteSelectionModal: PropTypes.func.isRequired,
  /**
   * It removes the Selection
   */
  handleRemoveSelection: PropTypes.func.isRequired,
  /**
   * Defines whether the Waterfall Selections section is selected in the Overview
   */
  isWaterfall: PropTypes.bool,
  /**
   * It opens the ArchiveSelection
   */
  handleOpenArchiveSelectionModal: PropTypes.func,
};

export default DeleteModal;
