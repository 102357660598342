import Constants from './constants';

// an object with formulas for relation filter
const relationFormulas = {
  [Constants.FORMULA__VALUE__AVERAGE]: [Constants.FORMULA__LABEL__AVERAGE],
  [Constants.FORMULA__VALUE__SUM]: [Constants.FORMULA__LABEL__SUM],
  [Constants.FORMULA__VALUE__MINIMUM]: [Constants.FORMULA__LABEL__MINIMUM],
  [Constants.FORMULA__VALUE__MAXIMUM]: [Constants.FORMULA__LABEL__MAXIMUM],
};

export default relationFormulas;
