import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import Util from '../../../../../../util';
import Select from '../../../../../shared/Select/Select';

/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
const DeduplicationField = ({
  handleChangeDeduplicationField,
  deduplicationFieldObjectID,
  extensionFields,
}) => (
  <div className="slds-form-element">
    <label className="slds-form-element__label" htmlFor="select-01">
      Field that needs to be unique
    </label>
    <div className="slds-form-element__control">
      <Select
        id="select-01"
        onChange={(e) => {
          handleChangeDeduplicationField(e);
        }}
        value={deduplicationFieldObjectID || ''}
        required
        options={[
          { value: '', label: 'Please select', hidden: true },
          ...extensionFields.map(field => (
            {
              value: field.ObjectID,
              title: field.Name.toString().length > 30 ? field.Name.toString() : '',
              label: Util.abbreviate(field.Name.toString(), 30),
            }
          )),
        ]}
        />
    </div>
  </div>
);

DeduplicationField.propTypes = {
  /**
   * It helps to change selected field for criteria
   */
  handleChangeDeduplicationField: PropTypes.func.isRequired,
  /**
   * It keeps the selected field's ObjectID
   */
  deduplicationFieldObjectID: PropTypes.string,
  /*
   * It keeps the formatted target data extension' fields for combobox
   *
   */
  extensionFields: PropTypes.instanceOf(Object),
};

export default DeduplicationField;
