import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ModalTemplate from '../../../shared/ModalTemplate/ModalTemplate';
import Input from '../../../shared/Input/Input';
import ToggleButton from '../../../shared/ToogleButton/ToggleButton';

import './styles.scss';

const subscriberOptions = [{
  value: 'Subscriber Key',
  label: 'Subscriber Key',
},
{
  value: 'Subscriber Id',
  label: 'Subscriber Id',
}];

const SettingsSendRelationshipModal = ({
  sendRelationship,
  updateSendRelationship,
  setShowSendRelationshipModal,
}) => {
  const [fieldName, setFieldName] = useState('');
  const [defaultRelation, setDefaultRelation] = useState('Subscriber Key');

  const [isEnabled, setIsEnabled] = useState(sendRelationship?.isEnabled);

  /**
   * Update states with retrieved settings from Backend
   */
  useEffect(() => {
    setIsEnabled(sendRelationship?.isEnabled || false);
    setFieldName(sendRelationship?.fieldName || '');
    setDefaultRelation(sendRelationship?.defaultRelation || 'Subscriber Key');
  }, [sendRelationship]);

  /**
   * Saves the send relationship setting and closes the modal
   * @returns {void}
   */
  const saveSendRelationshipSettings = async () => {
    updateSendRelationship({
      isEnabled,
      fieldName,
      defaultRelation,
    });
    setShowSendRelationshipModal(false);
  };

  return (
    <ModalTemplate
      containerId="container-data-send-relationship"
      headerId="modal-heading-01"
      headerTitle="Default Send Relationship Field"
      contentClassName="slds-p-around_medium"
      contentId="modal-content-id-3"
      cancelButtonId="cancel-default-send-relationship"
      cancelButtonTitle="Cancel"
      footerClassName="DRP-footer"
      containerClassName="send-relationship-modal"
      saveButtonId="save-default-send-relationship"
      saveButtonDisabled={isEnabled && fieldName.trim().length === 0}
      handleCancel={() => setShowSendRelationshipModal(false)}
      handleSave={() => saveSendRelationshipSettings()}
    >
      <div className="send-relationship-main-container">
          <div className="send-relationship-toggle-container">
            <div className="send-relationship-setting-label">
              Default Setting
            </div>

            <div className="toggle-send-relationship">
              <ToggleButton
                name="checkbox-onoff"
                id="switcher-default-send-relationship"
                onChange={() => setIsEnabled(!isEnabled)}
                checked={isEnabled}
                value={isEnabled}
                checkboxOnTitle="Enabled"
                checkboxOnId="send-relationship-on-id"
                checkboxOffTitle="Disabled"
                checkboxOffId="send-relationship-off-id"
              />
            </div>
          </div>

          <div className="send-relationship-data-extension">
            <div className="send-relationship-setting-label some-margin strong admin-settings-titles">
              Define Send Relationship
            </div>
            <div
              className="set-relationship-container"
            >
              <span className="admin-settings-paragraph">Send Relationship </span>
              <div className="default-relationship">
                <Input
                  className="slds-select dropdown-relation"
                  value={fieldName || ''}
                  onChange={e => setFieldName(e.target.value)}
                />
              </div>
              <span className="admin-settings-paragraph"> relates to Subscriber on</span>
              <div className="default-relationship">

              <div className="slds-form-element">
                <div className="slds-form-element__control">
                  <div className="slds-select_container">
                    <select
                      className="slds-select"
                      id="TDEDRPolicy-period-dropdown"
                      onChange={e => setDefaultRelation(e.target.value)}
                      value={defaultRelation}
                    >
                      {subscriberOptions.map(option => (
                        <option className="admin-settings-small-text" key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              </div>
            </div>
            <p className="default-send-relationship-note admin-settings-paragraph">
              Note: This Default Send Relationship will be applied to all Data Extensions
               which contain the Send Relationship field specified here.
               However, users can edit the Send Relationship inside a Selection.
            </p>
          </div>
      </div>
    </ModalTemplate>
  );
};

SettingsSendRelationshipModal.propTypes = {
  /**
   * Send relationship settings object
   */
  sendRelationship: PropTypes.instanceOf(Object).isRequired,
  /**
   * Calls an api to update the send relationship
   */
  updateSendRelationship: PropTypes.func.isRequired,
  /**
   * helps to close the modal
   */
  setShowSendRelationshipModal: PropTypes.func.isRequired,
};

export default SettingsSendRelationshipModal;
