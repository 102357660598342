/*
 * array of objects of hardcoded data views
 * Customer Key for data views is same as a name because no other DE has
 * Customer Key and Name that starts with underscore (_) and you cannot create or change DE Name to
 * starts with underscore(_)
 */
export default [
  { Name: '_Bounce', CustomerKey: '_Bounce' },
  { Name: '_BusinessUnitUnsubscribes', CustomerKey: '_BusinessUnitUnsubscribes' },
  { Name: '_Click', CustomerKey: '_Click' },
  { Name: '_Complaint', CustomerKey: '_Complaint' },
  { Name: '_Coupon', CustomerKey: '_Coupon' },
  { Name: '_FTAF', CustomerKey: '_FTAF' },
  { Name: '_MobileLineAddressContactSubscriptionView', CustomerKey: '_MobileLineAddressContactSubscriptionView' },
  { Name: '_MobileLineOrphanContactView', CustomerKey: '_MobileLineOrphanContactView' },
  { Name: '_Job', CustomerKey: '_Job' },
  { Name: '_Journey', CustomerKey: '_Journey' },
  { Name: '_JourneyActivity', CustomerKey: '_JourneyActivity' },
  { Name: '_ListSubscribers', CustomerKey: '_ListSubscribers' },
  { Name: '_Open', CustomerKey: '_Open' },
  { Name: '_Sent', CustomerKey: '_Sent' },
  { Name: '_Smsmessagetracking', CustomerKey: '_smsmessagetracking' },
  { Name: '_SocialNetworkImpressions', CustomerKey: '_SocialNetworkImpressions' },
  { Name: '_SocialNetworkTracking', CustomerKey: '_SocialNetworkTracking' },
  { Name: '_SMSSubscriptionLog', CustomerKey: '_SMSSubscriptionLog' },
  { Name: '_Subscribers', CustomerKey: '_Subscribers' },
  { Name: '_SurveyResponse', CustomerKey: '_SurveyResponse' },
  { Name: '_UndeliverableSms', CustomerKey: '_UndeliverableSms' },
  { Name: '_Unsubscribe', CustomerKey: '_Unsubscribe' },
  // _PublicationSubscriber not on SFMC documentation
  { Name: '_PublicationSubscriber', CustomerKey: '_PublicationSubscriber' },
  // _SubscriberSMS not on SFMC documentation
  { Name: '_SubscriberSMS', CustomerKey: '_SubscriberSMS' },
];
