import Constants from '../../../../constants/constants';
import AggregationValueUtil from './AggregationModal/aggregationValueUtil';
import DateDifferenceUtil from './DateDifferenceModal/dateDifferenceUtil';
import DynamicValueUtil from './DynamicValueModal/dynamicValueUtil';
import FixedValueUtil from './FixedValueModal/fixedValueUtil';
import FreeFormulaUtil from './FreeFormulaModal/freeFormulaUtil';
import RowNumberUtil from './RowNumberModal/rowNumberUtil';
import TransformDateUtil from './TransformDateModal/transformDateUtil';

const CustomValuesUtil = {
  /**
   * Function that handles changing alias of a custom value
   * @param {Object} value - The value itself
   * @param {String} oldName - The old alias of the Data Extension
   * @param {String} newName - The new alias of the Data Extension
   * @param {String} type - The type of the custom value
   * @param {String} formulaType - The type of the formula, if formula
   * @returns {Object} Custom value updated with the new alias.
   */
  changeAlias: (value, oldName, newName, type, formulaType) => {
    switch (type) {
      case Constants.CUSTOM_VALUES__FIELD_TYPE__DYNAMIC_VALUE:
        DynamicValueUtil.changeAlias(value, oldName, newName);

        break;

      case Constants.CUSTOM_VALUES__FIELD_TYPE__FORMULA:
        switch (formulaType) {
          case Constants.CUSTOM_VALUES__FORMULA_TYPE__TRANSFORM:
            TransformDateUtil.changeAlias(value, oldName, newName);

            break;

          case Constants.CUSTOM_VALUES__FORMULA_TYPE__DATE_DIFFERENCE:
            DateDifferenceUtil.changeAlias(value, oldName, newName);

            break;

          case Constants.CUSTOM_VALUES__FORMULA_TYPE__ROW_NUMBER:
            RowNumberUtil.changeAlias(value, oldName, newName);

            break;

          case Constants.CUSTOM_VALUES__FORMULA_TYPE__FREE_FORMULA:
            FreeFormulaUtil.changeAlias(value, oldName, newName);

            break;
        }

        break;

      case Constants.CUSTOM_VALUES__FIELD_TYPE__AGGREGATION_VALUE:
        AggregationValueUtil.changeAlias(value, oldName, newName);

        break;
    }

    return value;
  },

  /**
   * Function that handles the validation of a custom value
   * @param {Object} value - The value itself
   * @param {String} type - The type of the custom value
   * @param {String} formulaType - The type of the formula, if formula
   * @param {Boolean} isSyntaxValid - Used for free formulas, indicates whether the value is already validated
   * @param {Object} relation - Used for aggregations, the relation used
   * @returns {Object} Custom value updated with the new alias.
   */
  validate: (value, type, formulaType, isSyntaxValid, relation) => {
    switch (type) {
      case Constants.CUSTOM_VALUES__FIELD_TYPE__FIXED_VALUE:
        return FixedValueUtil.isValid(value);

      case Constants.CUSTOM_VALUES__FIELD_TYPE__DYNAMIC_VALUE:
        return DynamicValueUtil.isValid(value);

      case Constants.CUSTOM_VALUES__FIELD_TYPE__FORMULA:
        switch (formulaType) {
          case Constants.CUSTOM_VALUES__FORMULA_TYPE__TRANSFORM:
            return TransformDateUtil.isValid(value);

          case Constants.CUSTOM_VALUES__FORMULA_TYPE__DATE_DIFFERENCE:
            return DateDifferenceUtil.isValid(value);

          case Constants.CUSTOM_VALUES__FORMULA_TYPE__ROW_NUMBER:
            return RowNumberUtil.isValid(value);

          case Constants.CUSTOM_VALUES__FORMULA_TYPE__FREE_FORMULA:
            return FreeFormulaUtil.isValid(value, isSyntaxValid);
        }

        break;

      case Constants.CUSTOM_VALUES__FIELD_TYPE__AGGREGATION_VALUE:
        return AggregationValueUtil.isValid(value, relation);
    }

    return true;
  },
};

export default CustomValuesUtil;
