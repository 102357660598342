import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

import Util from '../../../../../../util';
import './styles.scss';
import Button from '../../../../../shared/Button/Button';
import Constants from '../../../../../../constants/constants';

const InsertField = ({ selectedDataExtensions, handleOnChange, disabled }) => {
  const [selectedDE, setSelectedDE] = useState({});
  const [fields, setFields] = useState([]);
  const [selectedField, setSelectedField] = useState({});
  const [loadingFields, setLoadingFields] = useState(true);

  /**
   * Choose the right placeholder for the data extensions dropdown
   * @returns {string} Correct placeholder for data extensions
   */
  const dataExtensionsPlaceHolder = () => {
    if (Object.prototype.hasOwnProperty.call(selectedDE, 'deAlias')) {
      return selectedDE.deAlias.toString();
    }

    return 'Select a Data Extension';
  };

  /*
   * Format the data extensions for the dropdown
   */
  const targetDeOptions = Util.formattedDataForTheDropdown(selectedDataExtensions, 'deAlias', 'deAlias');

  /*
   * Format the fields for the dropdown
   */
  const targetFieldsOptions = Util.formattedDataForTheDropdown(fields, 'ObjectID', 'Name');

  /**
   * It helps to change selected field
   * @param {object} e - event. Use e.target to get the value
   * @returns {void}
   */
  const handleSetSelectedField = (e) => {
    const field = fields.find(f => f.ObjectID === e.value);

    setSelectedField(field);
  };

  /**
   * It helps to change data extension in dropdown
   * @param {object} e - event. Use e.target to get the value
   * @returns {void}
   */
  const handleSetSelectedDE = (e) => {
    //  fields for data extension will be loading until selectedDE and fields are set
    setLoadingFields(true);

    // set empty selected field
    setSelectedField({});

    // select data extension and set a new state for Data Extension and fields
    const dataExtension = Util.getDataExtensionByAlias(e.value, selectedDataExtensions);

    setSelectedDE(dataExtension || {});
    setFields(dataExtension?.fields || []);

    // stop loading fields for data extension
    setLoadingFields(false);
  };

  /**
   * Choose the right placeholder for the fields dropdown
   * @returns {string} Correct placeholder for fields
   */
  const fieldsPlaceHolder = () => {
    let placeholder = '';

    if (Util.objectIsEmpty(selectedDE)) {
      placeholder = 'Select a Field';
    } else {
      if (Object.prototype.hasOwnProperty.call(selectedField, 'Name')) {
        placeholder = selectedField.Name.toString();
      } else {
        placeholder = 'Select a Field';
      }
    }

    return placeholder;
  };

  /**
   * returns selectedDE and selectedField values as linked strings
   * @returns {string} output text
   */
  const insertFieldValues = () => `"${selectedDE.deAlias}"."${selectedField.Name}"`;

  return (
    <div className="insertField-container">
      <div className="insertField-content">
        <div className="insertField-title">
          Insert Field
        </div>
        <div className="dropdown-field data-extension">
          <div className="dropdown-title">Data Extension</div>
          <Dropdown
            id="applyFormula-insert-DE-dropdown"
            selection
            className="target-data-extension-dropdown searchable-dropdown"
            search
            placeholder={dataExtensionsPlaceHolder()}
            options={targetDeOptions}
            disabled={!selectedDataExtensions?.length || disabled}
            loading
            onChange={(e, data) => handleSetSelectedDE(data)}
            style={{ borderRadius: '.25rem' }}
          />
        </div>
        <div className="dropdown-field select-field">
          <div className="dropdown-title">Field</div>
          <Dropdown
            id="applyFormula-insert-field-dropdown"
            selection
            className="target-data-extension-dropdown searchable-dropdown"
            search
            placeholder={fieldsPlaceHolder()}
            options={targetFieldsOptions}
            disabled={Util.objectIsEmpty(selectedDE) || loadingFields || disabled}
            loading
            onChange={(e, data) => handleSetSelectedField(data)}
            style={{ borderRadius: '.25rem' }}
            text={selectedField && selectedField.Name ? selectedField.Name : ''}
            value={selectedField && selectedField.Name ? selectedField.Name : ''}
          />
        </div>
      </div>
      <Button
        id="applyFormula-insert-button"
        buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
        type="button"
        disabled={Util.objectIsEmpty(selectedDE) || !selectedDE.deAlias.toString() ||
          Util.objectIsEmpty(selectedField) || !selectedField.Name.toString() || disabled}
        onClick={() => handleOnChange(null, insertFieldValues())}
      >
        Insert Field
        <svg
          className="slds-button__icon slds-icon_small"
          id="plus-icon"
          aria-hidden="true"
        >
          <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#add" />
        </svg>
      </Button>
    </div>
  );
};

InsertField.propTypes = {
  /**
   * It keeps the selected data extensions for Selection.js
   * selected data extensions are stored as collections in database
   * It will be passed from Selection.js
   */
  selectedDataExtensions: PropTypes.instanceOf(Array),
  /**
   * Function to set the new value of the freeFormulaData
   */
  handleOnChange: PropTypes.func.isRequired,
  /**
   * disable property for the component
   */
  disabled: PropTypes.bool,
};

export default InsertField;
