import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated } from '@react-spring/web';
import './styles.scss';
import { connect } from 'react-redux';

import mapStateToProps from '../../../mapStateToProps';
import Button from '../../shared/Button/Button';
// eslint-disable-next-line max-len
import FolderDataExtensions from '../../Selection/DataExtensions/AvailableExtensions/FolderDataExtensions/FolderDataExtensions';
import DataExtensionsAPI from '../../../api/data-extensions';
import Constants from '../../../constants/constants';
import NewAutoCreatedTargetDE from '../../Selection/TargetDefinition/NewAutoCreatedTargetDE/NewAutoCreatedTargetDE';
import Spinner from '../../shared_v2/Spinner/Spinner';
import foldersAPI from '../../../api/folders';

function SelectScopeController({
  handleAddScopeDE,
  scopeDataExtensionsToAdd,
  axiosCancelToken,
  handleSaveSelectedScopeDEs,
  scopeDEs,
  isSavingChanges,
  userInfo,
}) {
  // Visibility states
  const [isFolderDEModalVisible, setFolderDEModalVisibility] = useState(false);
  const [isAvailableFoldersModalVisible, setAvailableFoldersModalVisibility] =
    useState(false);

  // Data states
  const [dataExtensions, setDataExtensions] = useState([]);
  const [selectedFolderId, setSelectedFolderId] = useState(null);
  const [folderDataExtensions, setFolderDataExtensions] = useState([]);
  const [foldersSettings, setFoldersSettings] = useState({});
  const [availableFolders, setAvailableFolders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Fetches the available data extensions and folders concurrently and updates the state.
   * @function
   * @async
   * @returns {void}
   */
  const fetchDataExtensionsAndFolders = useCallback(async () => {
    setIsLoading(true);

    const params = {
      businessUnitId: userInfo.loggedInBusinessUnitId,
      dataExtensionType: Constants.ADMIN_PANEL__SUBMENU__AVAILABLE_DES,
    };

    // Fetch data extensions and folders concurrently
    const [fetchedDataExtensions, fetchedFolders] = await Promise.all([
      DataExtensionsAPI.getDataExtensions(
        axiosCancelToken.token,
        Constants.DATAEXTENSION__FILTER_MODE__AVAILABLE,
      ),
      foldersAPI.getAllFolders(params, axiosCancelToken.token),
    ]);

    // Update state with fetched data
    setDataExtensions(fetchedDataExtensions);
    setAvailableFolders(fetchedFolders);

    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchDataExtensionsAndFolders();
  }, [fetchDataExtensionsAndFolders]);

  /**
   * Open the modal for selecting available data extensions.
   * @returns {void}
   */
  const onSelectScopeBtnClick = () => {
    setAvailableFoldersModalVisibility(true);
  };

  /**
   * Opens the modal for selecting the data extensions for a specific folder.
   * @param {String} folderId - The ID of the folder to be used for filtering the data extensions.
   * @returns {void}
   */
  const openFolderDataExtensionsModal = (folderId) => {
    const folderSpecificDataExtensions = dataExtensions.filter(
      de => de.CategoryID === folderId,
    );

    setSelectedFolderId(folderId);
    setFolderDEModalVisibility(true);
    setFolderDataExtensions(folderSpecificDataExtensions);
  };

  /**
   * Updates the state of the scope modals.
   * @param {Object} newState - The new state to set for the scope modals.
   * @returns {void}
   */
  const handleSetScopeModalsState = useCallback((newState) => {
    const { isFolderDEModalVisible, isAvailableFoldersModalVisible } = newState;

    if (isFolderDEModalVisible !== undefined) {
      setFolderDEModalVisibility(isFolderDEModalVisible);
    }
    if (isAvailableFoldersModalVisible !== undefined) {
      setAvailableFoldersModalVisibility(isAvailableFoldersModalVisible);
    }
  }, []);

  // ANIMATIONS
  const selectTitleAnimationProps = useSpring({
    from: { opacity: 0, transform: 'scale(0.9)' },
    to: { opacity: 1, transform: 'scale(1)' },
    config: { tension: 180, friction: 14 }, // Adjust these values to change the animation behavior
  });

  return (
    <div className="scope-des-modal__select_container">
      {isAvailableFoldersModalVisible && (
        <NewAutoCreatedTargetDE
          isAvailableDEsFoldersModal
          openAvailableDEsForCertainFolderModal={openFolderDataExtensionsModal}
          availableDEFolderId={selectedFolderId}
          availableDEsFolders={availableFolders}
          isScopedDEsFoldersModal
          handleSetScopeModalsState={handleSetScopeModalsState}
          setAvailableFolders={setAvailableFolders}
          setFoldersSettings={setFoldersSettings}
          foldersSettings={foldersSettings}
        />
      )}

      {isFolderDEModalVisible && (
        <FolderDataExtensions
          dataExtensions={folderDataExtensions}
          availableDEsFolderId={selectedFolderId}
          handleSetScopeModalsState={handleSetScopeModalsState}
          isAvailableDEsFoldersModal
          isScopedDEsFoldersModal
          scopeDataExtensionsToAdd={scopeDataExtensionsToAdd}
          handleAddScopeDE={handleAddScopeDE}
          handleSaveSelectedScopeDEs={handleSaveSelectedScopeDEs}
        />
      )}

      <animated.h1
        className="select-scope-title"
        style={selectTitleAnimationProps}
      >
        Choose the Data Extensions you want DeedeeAI to use for generating
        selections.
      </animated.h1>
      <animated.p
        className="select-scope-description"
        style={selectTitleAnimationProps}
      >
        <p>
        You have selected
          {' '}
          {scopeDEs?.length}
          {' '}
          data extensions.
                  DeedeeAI allows a maximum of
          {' '}
          {Constants.SCOPE_DES_LIMIT}
          {' '}
          data extensions in scope.
        </p>

      </animated.p>

      <Button
        onClick={onSelectScopeBtnClick}
        disabled={isLoading || isSavingChanges || dataExtensions.length === 0}
        className="slds-button slds-button_brand select-scope-data-button"
        type="button"
      >
        {isLoading ?
          (
          <Spinner
            size={Constants.SPINNER__SIZE__X_SMALL}
            loadingText="Loading Data Extensions"
            loadingTextClassName="loading-text"
          />
          ) :
          (
          <>
            <span>Select Data Extensions</span>
            <svg className="slds-button__icon" aria-hidden="true">
              <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#open_folder" />
            </svg>
          </>
          )}
      </Button>
    </div>
  );
}
SelectScopeController.propTypes = {
  /**
   * Function to handle adding a scope data extension
   */
  handleAddScopeDE: PropTypes.func.isRequired,

  /**
   * Array of data extensions to be added to the scope
   */
  scopeDataExtensionsToAdd: PropTypes.array.isRequired,

  /**
   * CancelToken to cancel previous request
   */
  axiosCancelToken: PropTypes.object.isRequired,

  /**
   * Function to handle saving selected scope data extensions
   */
  handleSaveSelectedScopeDEs: PropTypes.func.isRequired,
  /**
   * User info from cookie
   */
  userInfo: PropTypes.object,
};

export default connect(mapStateToProps(['userInfo']), null, null, { pure: false })(SelectScopeController);
