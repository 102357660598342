import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import ReactDragListView from 'react-drag-listview';
import { Dropdown } from 'semantic-ui-react';
import classNames from 'classnames';
import { connect } from 'react-redux';

import mapStateToProps from '../../../mapStateToProps';
import RetrieveDEValuesButton from './RetrieveDEValuesButton/RetrieveDEValuesButton';
import LoadingModal from '../../../components/shared/LoadingModal/LoadingModal';
import PicklistNameInput from './PicklistNameInput/PicklistNameInput';
import PicklistValueInput from './PicklistValueInput/PicklistValueInput';
import Constants from '../../../constants/constants';
import Util from '../../../util';
import Tooltip from '../../../components/shared/Tooltip/Tooltip';
import DataExtensionsAPI from '../../../api/data-extensions';
import PicklistsAPI from '../../../api/picklists';
import AutoRefreshPicklistValues from './AutoRefreshPicklistValues/AutoRefreshPicklistValues';
import SplitSeparators from './SplitSeparators/SplitSeparators';
import './styles.scss';
import PicklistAddValuesComponent from './PicklistAddValuesComponent/PicklistAddValuesComponent';
import Button from '../../../components/shared/Button/Button';
import ToggleButton from '../../../components/shared/ToogleButton/ToggleButton';
import Input from '../../../components/shared/Input/Input';
import timeUtil from '../../../utils/time/timeUtil';
import SwalUtil from '../../../utils/swal/swalUtil';

class PicklistAE extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDataExtension: {},
      selectedField: {},
      fields: [],
      activeValues: [],
      inactiveValues: [],
      name: null,
      isActive: !!props.picklistId,
      picklist: null,
      retrieveDistinctDEValues: false,
      dataExtensions: [],
      loading: true,
      sort: {
        active: 0,
        inactive: 0,
      },
      openSplitSeparatorsSettings: false,
      openAutoRefreshSettings: false,
      isSavingPicklist: false,
    };
    this.prevStateForPicklist = {
      picklistName: null,
      selectedDataExtension: {},
      selectedField: {},
      dataExtensionFields: [],
      options: [],
      activeValues: [],
      inactiveValues: [],
      dataExtensions: [],
      showTooltip: false,
      isGettingValuesFromDEStarted: false,
    };

    this.optionsRef = React.createRef();
    this.valueInputRef = React.createRef();
    this.axiosCancelToken = axios.CancelToken.source();
  }

  /**
   * Mount the component with dataExtensions
   * @returns {void}
   */
  async componentDidMount() {
    const {
      picklistId,
      picklistCustomerKey,
      openPanel,
    } = this.props;

    /**
     * while picklist loading, set isMounting true
     * to work as expected
     */
    this.isMounting = true;

    let dataExtensions = [];

    if (picklistCustomerKey) {
      try {
        // Get specific data extension determined by customerKey
        const data = await DataExtensionsAPI.getDataExtension(picklistCustomerKey, this.axiosCancelToken.token);

        dataExtensions = data ? [data] : [];

        this.setState({ dataExtensions });
      } catch (error) {
        /* console.log(error); */
      }
    } else {
      try {
        // Get data extensions
        dataExtensions = await DataExtensionsAPI.getDataExtensions(
          this.axiosCancelToken.token,
          Constants.DATAEXTENSION__FILTER_MODE__AVAILABLE,
        );

        this.setState({ dataExtensions });
      } catch (error) {
        /* console.log(error); */
      }
    }

    /*
     * if picklistId is other than null/undefined
     * then look for that picklist so the user can edit it
     */
    if (picklistId) {
      let picklist = {};

      let selectedDataExtension = {};

      let selectedField = {};

      let fields = [];
      const activeValues = [];
      const inactiveValues = [];

      // If an error is caught here then it means the user closed the loading modal
      try {
        picklist = await PicklistsAPI.getPicklist(picklistId, this.axiosCancelToken.token);
      } catch (error) {
        /* console.log(error); */
      }

      // if picklist is found
      if (picklist) {
        // get the selected de
        selectedDataExtension = dataExtensions.find(
          // eslint-disable-next-line no-prototype-builtins
          de => de && de.CustomerKey.toString() && picklist.hasOwnProperty('dataExtensionCustomerKey') ?
            de.CustomerKey.toString() === picklist.dataExtensionCustomerKey.toString() :
            false,
        );

        if (selectedDataExtension) {
          try {
            // fetch the fields of it
            const dataExtensionResponse = await DataExtensionsAPI.getDataExtensionFields(
              selectedDataExtension.CustomerKey,
              this.axiosCancelToken.token,
            );

            // get fields inside of fields API call
            fields = dataExtensionResponse.data;
          } catch (error) {
            // eslint-disable-next-line react/no-unused-state
            this.setState({ error });
          }

          // then find the selected field inside of fields
          selectedField = fields.find(f => f.ObjectID === picklist.fieldObjectId);

          /**
           * if selectedField is undefined, that means that the field of the picklist
           * doesn't belong to the data extension anymore, so we handle if the field
           * was removed from SFMC by asking the user to delete the picklist
           */
          if (!selectedField && this.isMounting) {
            /**
             * by setting loading state to false here we force the app to show the picklists panel
             * behind the modal instead of the loading screen so it looks much cleaner to the user
             */
            this.setState({ loading: false });

            /**
             * if the selected field is not found, a swal appears with the message,
             * that this field was removed or does not exist anymore in DataExtension.
             */
            const result = await SwalUtil.fire({
              title: 'Remove Picklist',
              message: `Field <span class="swal_custom_bold">${picklist.fieldName}</span>
               was removed or does not exist anymore on
               <span class="swal_custom_bold">${picklist.dataExtensionName}</span> Data Extension.
               Do you want to delete this picklist?`,
              options: {
                showCancelButton: true,
                confirmButtonText: 'Delete',
                allowOutsideClick: false,
              },
            });

            if (result.value) {
              // Delete picklist
              await PicklistsAPI.deletePicklist(picklistId, axios.CancelToken.source().token);
            }

            // Back to picklists screen
            await openPanel(Constants.ADMIN_PANEL__MENU__PICKLISTS);
          }
        } else if (this.isMounting) {
          this.setState({ loading: false });

          const result = await SwalUtil.fire({
            title: 'Remove Picklist',
            message: `Data extension <span class="swal_custom_bold">${picklist.dataExtensionName}</span>
               with external key <span class="swal_custom_bold">${picklist.dataExtensionCustomerKey}</span>
               is no longer available.
               Do you want to delete this picklist?`,
            options: {
              showCancelButton: true,
              confirmButtonText: 'Delete',
              allowOutsideClick: false,
            },
          });

          if (result.value) {
            // Delete picklist
            await PicklistsAPI.deletePicklist(picklistId, axios.CancelToken.source().token);
          }

          // Back to picklists screen
          await openPanel(Constants.ADMIN_PANEL__MENU__PICKLISTS);
        }

        /**
         * We need this function so we can check if the picklist is empty or not
         * as comparing objects in javascript like {} === {} will return false
         */
        if (!Util.objectIsEmpty(picklist)) {
          const { options, name, isActive } = picklist;
          // loop through the options

          for (let i = 0; i < options.length || 0; i += 1) {
            // and set them to separate arrays as active and inactive
            if (options[i].isActive) {
              activeValues.push(options[i]);
            } else {
              inactiveValues.push(options[i]);
            }
          }
          this.setState({
            picklist,
            retrieveDistinctDEValues: !!picklist?.retrieveDistinctDEValues,
            name,
            activeValues,
            inactiveValues,
            isActive,
            fields,
            selectedField,
            selectedDataExtension,
            loading: false,
          });
        }
      }
    }
    this.setState({
      loading: false,
    });
  }

  /**
   * Update the component while user is adding a new option
   * Update is necessary to show the latest added active value
   * @returns {void}
   */
  componentDidUpdate() {
    if (this.optionsRef.current && this.optionsRef.current.scrollHeight) {
      const { scrollHeight } = this.optionsRef.current;
      const height = this.optionsRef.current.clientHeight;
      const maxScrollTop = scrollHeight - height;

      this.optionsRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  }

  componentWillUnmount() {
    this.axiosCancelToken.cancel('Navigation occurred');

    /**
     * while picklist loading, close is clicked isMounting is used
     * to bypass swal alerts as component is unmounted
     */
    this.isMounting = false;
  }

  /**
   * It helps to arrange the order of activeValues
   * @param {number} fromIndex - starting index
   * @param {number} toIndex - target index
   * @returns {void}
   */
  onDragEndActiveValues = (fromIndex, toIndex) => {
    const { activeValues } = this.state;
    const item = activeValues.splice(fromIndex, 1)[0];

    activeValues.splice(toIndex, 0, item);
    this.setState({ activeValues });
  };

  /**
   * It helps to arrange the order of inactiveValues
   * @param {number} fromIndex - starting index
   * @param {number} toIndex - target index
   * @returns {void}
   */
  onDragEndInActiveValues = (fromIndex, toIndex) => {
    const { inactiveValues } = this.state;
    const item = inactiveValues.splice(fromIndex, 1)[0];

    inactiveValues.splice(toIndex, 0, item);
    this.setState({ inactiveValues });
  };

  /**
   * It helps to change picklist name
   * @param {object} e - event. Use e.target to get the value
   * @returns {void}
   */
  handleChangePicklistName = (e) => {
    this.setState({ name: e.target.value });
  };

  /**
   * It helps to change picklist name
   * @param {object} e - event. Use e.target to get the value
   * @returns {void}
   */
  handleSetSelectedDataExtension = async (e) => {
    const {
      activeValues,
      inactiveValues,
      dataExtensions,
    } = this.state;

    // find the selected de
    const dataExtension = dataExtensions.find(d => d.ObjectID.toString() === e.value);

    let getNewFields = false;

    if ((activeValues && activeValues.length > 0) || (inactiveValues && inactiveValues.length > 0)) {
      const result = await SwalUtil.fire({
        title: 'Change Data Extension',
        message: `Are you sure you want to switch to another
                Data Extension? Options already defined will be lost.`,
        options: {
          showCancelButton: true,
          allowOutsideClick: false,
        },
      });

      if (result.value) {
        this.setState({
          activeValues: [],
          inactiveValues: [],
          selectedDataExtension: dataExtension || '',
        });

        getNewFields = true;
      }
    } else {
      this.setState({
        selectedDataExtension: dataExtension || '',
      });

      getNewFields = true;
    }

    if (getNewFields) {
      // if de is found
      if (dataExtension) {
        try {
          // then start loading process for fields
          this.setState({
            loadingFields: true,
            selectedField: {},
          });
          /**
           * Fetch all the picklists,
           * Keep only those corresponding to the selected DE,
           * Reduce it to an array containing all already taken field name
           */
          const alreadyTakenFields = (await PicklistsAPI
            .getPicklists(this.axiosCancelToken.token))
            .filter(({ dataExtensionCustomerKey }) => dataExtensionCustomerKey === dataExtension.CustomerKey)
            .reduce((acc, { fieldName }) => {
              acc.push(fieldName);

              return acc;
            }, []);

          // fetch the fields
          const dataExtensionResponse = await DataExtensionsAPI.getDataExtensionFields(
            dataExtension.CustomerKey,
            this.axiosCancelToken.token,
            true,
          );

          /**
           * Filter out the date and boolean types of fields
           * Add a property to the already taken field to prevent them from being selected by the user
           */
          const fields = dataExtensionResponse.data
            .map((f) => {
              if (f.FieldType === Constants.FILTERLINE__FIELDTYPE__DATE ||
                f.FieldType === Constants.FILTERLINE__FIELDTYPE__BOOLEAN ||
                alreadyTakenFields.includes(f.Name.toString())) {
                // eslint-disable-next-line no-param-reassign
                f.disabledField = true;
              }

              return f;
            });

          this.setState({
            fields,
            loadingFields: false,
          });
        } catch (error) {
          // eslint-disable-next-line react/no-unused-state
          this.setState({ error });
        }
      } else {
        this.setState({
          fields: [],
          selectedDataExtension: {},
          selectedField: {},
        });
      }
    }
  };

  /**
   * It helps to change selected field
   * @param {object} e - event. Use e.target to get the value
   * @returns {void}
   */
  handleSetSelectedField = async (e) => {
    const {
      fields,
      activeValues,
      inactiveValues,
    } = this.state;

    const field = fields.find(f => f.ObjectID === e.value);

    if ((activeValues && activeValues.length > 0) || (inactiveValues && inactiveValues.length > 0)) {
      const result = await SwalUtil.fire({
        title: 'Change Field',
        message: `Are you sure you want to switch to another
                field? Options already defined will be lost.`,
        options: {
          showCancelButton: true,
          allowOutsideClick: false,
        },
      });

      if (result.value) {
        this.setState({
          activeValues: [],
          inactiveValues: [],
          selectedField: field || '',
        });
      }
    } else {
      this.setState({ selectedField: field || '' });
    }
  };

  /**
   * @param {object} value - state of the value input
   * @param {object} label - state of the label input
   * @param {string} type - it determines if Enter is clicked
   * @returns {void}
   */
  handleAddToOptions = (value, label, type) => {
    const {
      options,
      activeValues,
      inactiveValues,
      selectedField,
    } = this.state;

    // if value for adding in options exists
    if (value) {
      // var for tracking if data exists in options or not
      let dataExists = false;

      let inputLabel = '';

      let validValue = value;

      // if a user presses the enter or the add button and the label is empty, set the default value of the label
      if ((type === Constants.INPUT_TYPE__ENTER && !label) || (type !== Constants.INPUT_TYPE__ENTER && !label)) {
        inputLabel = validValue.toString();
      } else {
        // otherwise set the field value to label
        inputLabel = label.toString();
      }

      // make sure that for a field of type number, the selected value is not a decimal value
      if (selectedField.FieldType === Constants.FILTERLINE__FIELDTYPE__NUMBER) {
        validValue = parseInt(value.toString());
      }

      // check if data exists in active options
      activeValues.forEach((option) => {
        if (option.value === validValue.toString() || option.label === inputLabel) {
          dataExists = true;
        }
      });

      // check if data exists in inactive options
      inactiveValues.forEach((option) => {
        if (option.value === validValue.toString() || option.label === inputLabel) {
          dataExists = true;
        }
      });

      // if data exists already in options throw a swal message
      if (dataExists) {
        SwalUtil.fire({
          title: 'Warning',
          message: 'Value already exists as an option.',
        });

        // if data doesn't exist already in options add it to the active option
      } else {
        activeValues.push({
          value: validValue.toString(),
          label: inputLabel,
          isActive: true,
        });
      }

      this.setState({
        options,
      });

      if (selectedField.FieldType !== Constants.FILTERLINE__FIELDTYPE__BOOLEAN &&
        this.valueInputRef.current && this.valueInputRef.current.focus) {
        this.valueInputRef.current.focus();
      }
    }
  };

  /**
   * @param {object} e - event. Use e.target to get the value
   * @param {string} index - index of the option
   * @param {boolean} isActive - it determines if option is active or not
   * @returns {void}
   */
  handleChangeOptionValueInOptions = (e, index, isActive) => {
    const { activeValues, inactiveValues } = this.state;

    if (isActive) {
      activeValues[index].value = e.target.value;
    } else {
      inactiveValues[index].value = e.target.value;
    }
    this.setState({
      activeValues,
      inactiveValues,
    });
  };

  /**
   * @param {object} e - event. Use e.target to get the value
   * @param {string} index - index of the option
   * @returns {void}
   */
  handleRemovePicklistAE = async (e, index) => {
    e.preventDefault();

    // Swal alert will appear to confirm or cancel deletion
    const result = await SwalUtil.fire({
      title: 'Remove Picklist Value',
      message: 'Are you sure you want to remove this picklist value?',
      options: {
        showCancelButton: true,
        confirmButtonText: 'Remove',
      },
    });

    // If remove clicked and index is found in array then remove it and updated data is setState
    if (result.value) {
      const { inactiveValues } = this.state;

      if (index !== -1) {
        inactiveValues.splice(index, 1);
        this.setState({ inactiveValues });
      }
    }
  };

  /**
   * @param {object} e - event. Use e.target to get the value
   * @param {boolean} onFocusOut - it determines if it s on focus out or not
   * @param {string} index - index of the option
   * @param {boolean} isActive - it determines if option is active or not
   * @returns {void}
   */
  handleChangeOptionLabelInOptions = (e, onFocusOut, index, isActive) => {
    const { activeValues, inactiveValues } = this.state;

    if (onFocusOut) {
      activeValues[index].label = activeValues[index].value;
    } else {
      if (isActive) {
        activeValues[index].label = e.target.value;
      } else {
        inactiveValues[index].label = e.target.value;
      }
    }

    this.setState({ activeValues, inactiveValues });
  };

  /**
   * @param {boolean} checked - it determines active/inactive state of an option
   * @param {string} index - index of the option
   * @returns {void}
   */
  handleChangeIsActiveStateForActiveValues = (checked, index) => {
    const { activeValues, inactiveValues, isActive } = this.state;
    // find the option and while doing that check the status with checked
    const opt = checked === true ? activeValues[index] : inactiveValues[index];

    // if option is found
    if (opt) {
      // and the isActive is true
      if (opt.isActive === true) {
        // make it false
        opt.isActive = false;
        // add that to inactive values list
        inactiveValues.push(opt);
        // then remove from active values list
        activeValues.splice(index, 1);
      } else {
        // make it true
        opt.isActive = true;
        // add that to inactive values list
        activeValues.push(opt);
        // then remove from active values list
        inactiveValues.splice(index, 1);
      }
    }
    this.setState({
      activeValues,
      inactiveValues,
      isActive: isActive && !!activeValues.length,
    });
  };

  /**
   * It cancels the current configs and reset the states
   * @returns {void}
   */
  handleCancel = async () => {
    const { openPanel } = this.props;
    const result = await SwalUtil.fire({
      title: 'Discard changes',
      message: 'Are you sure you want to discard your changes to the picklist settings?',
      options: {
        showCancelButton: true,
        confirmButtonText: 'Discard',
      },
    });

    if (result.value) {
      const { activePanel, picklistId } = this.props;

      // if user cancels creating of the new picklist then delete it from DB
      if (activePanel === Constants.ADMIN_PANEL__MENU__NEW_PICKLIST && picklistId) {
        await PicklistsAPI.deletePicklist(picklistId, this.axiosCancelToken.token);
      }
      this.setState({
        ...this.prevStateForPicklist,
        activeValues: [],
        inactiveValues: [],
      });
      await openPanel(Constants.ADMIN_PANEL__MENU__PICKLISTS);
    }
  };

  /**
   * @param {object} e - event. Use e.target to get the value
   * @param {string} picklistId - id of the picklist
   * @returns {void}
   */
  handleSubmitSave = async (e, picklistId) => {
    e.preventDefault();

    this.setState({ isSavingPicklist: true });

    const { openPanel } = this.props;

    // wait for the validation result
    const validation = await this.validateSubmitValues();

    // if the validation fails then return
    if (validation.value) {
      this.setState({ isSavingPicklist: false });

      return;
    }
    const {
      selectedDataExtension,
      selectedField,
      activeValues,
      inactiveValues,
      name,
      isActive,
      retrieveDistinctDEValues,
      picklist,
    } = this.state;

    const separator = picklist ? picklist.separator : '';
    const separate = picklist ? picklist.separate : false;
    const autoRefresh = picklist ? picklist.autoRefresh : false;
    const refreshTime = picklist ? picklist.refreshTime : 0;
    const daysOfWeek = picklist ? picklist.daysOfWeek : [];
    const assignedUserId = picklist ? picklist.assignedUserId : '';

    try {
      const options = [];
      /*
       * Merge active and inactive values
       * - Add them to the options array
       */

      [...activeValues, ...inactiveValues].map(o => options.push({
        value: o.value,
        label: o.label,
        isActive: o.isActive,
      }));
      /*
       * if there is picklistId then update it
       * if not then create a new one
       */
      const response = picklistId ?
        await PicklistsAPI.updatePicklist(
          picklistId,
          name,
          selectedField.ObjectID,
          selectedDataExtension.CustomerKey,
          selectedDataExtension.ObjectID,
          options,
          selectedField.Name,
          selectedDataExtension.Name,
          isActive,
          retrieveDistinctDEValues,
          this.axiosCancelToken.token,
          separate,
          separator,
          autoRefresh,
          refreshTime,
          daysOfWeek,
          assignedUserId,
        ) :
        await PicklistsAPI.createPicklist(
          name,
          selectedField.ObjectID,
          selectedDataExtension.CustomerKey,
          selectedDataExtension.ObjectID,
          options,
          selectedField.Name,
          selectedDataExtension.Name,
          isActive,
          retrieveDistinctDEValues,
          this.axiosCancelToken.token,
          separate,
          separator,
          autoRefresh,
          refreshTime,
          daysOfWeek,
          assignedUserId,
        );

      // if it succeeds
      if (response.success) {
        // Clear states
        this.setState({
          ...this.prevStateForPicklist,
          activeValues: [],
          inactiveValues: [],
          isSavingPicklist: false,
        });

        // navigate to picklist menu
        await openPanel(Constants.ADMIN_PANEL__MENU__PICKLISTS);
      } else {
        this.setState({
          isSavingPicklist: false,
        });

        // show the error
        await SwalUtil.fire({
          title: 'Error',
          message: response.error,
          options: {
            showCancelButton: false,
            confirmButtonText: 'OK',
          },
        });
      }
    } catch (error) {
      this.setState({
        isSavingPicklist: false,
      });
      await SwalUtil.fire({
        type: Constants.SWAL__TYPE__ERROR,
        title: 'Error',
        message: error,
        options: {
          showCancelButton: false,
          confirmButtonText: 'OK',
        },
      });
    }
  };

  /**
   * validation for states to determine if they can be
   * saved to db or not
   * @returns {void}
   */
  validateSubmitValues = async () => {
    const {
      name,
      selectedDataExtension,
      selectedField,
    } = this.state;

    /**
     * Throw a swal error message.
     * @param {string} errorMsg - THe message to display
     * @returns {object} A SweetAlertResult
     */
    const throwSwal = async (errorMsg) => {
      const res = await SwalUtil.fire({
        type: Constants.SWAL__TYPE__ERROR,
        message: errorMsg,
        options: {
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
        },
      });

      return res;
    };

    let errorMsg = '';

    // Check if the name is set
    if (!name) errorMsg = 'Name cannot be blank.';
    // Check if there is a selected data extension
    else if (typeof selectedDataExtension.CustomerKey === 'undefined') {
      errorMsg = 'A data extension should be selected.';
      // Check if there is selected field
    } else if (typeof selectedField.ObjectID === 'undefined') errorMsg = 'A field should be selected.';
    // Check if there are empty options
    else if (!this.validateNoEmptyOption()) errorMsg = 'Please fill all value and label fields.';

    // If any error message (i.e check failed) - Show an error to the user
    if (errorMsg) return throwSwal(errorMsg);

    return true;
  };

  /**
   * Validates that there is no empty options
   * @returns {boolean} True if there is no empty option
   */
  validateNoEmptyOption = () => {
    const { activeValues, inactiveValues } = this.state;

    // Check if there is any empty value or label in active options
    const missingActive = activeValues.some(v => v.value === '' || v.label === '');
    const missingInactive = inactiveValues.some(v => v.value === '' || v.label === '');

    return !(missingActive || missingInactive);
  };

  /**
   * Sort values from the table
   * @param {boolean} sortActiveValue - True if it should sort active values, false otherwise
   * @returns {void} Will setState directly as needed
   */
  sortTableBy = (sortActiveValue) => {
    const { sort, activeValues, inactiveValues } = this.state;

    const dataToSort = sortActiveValue ? activeValues : inactiveValues;
    const sortOrder = -(sortActiveValue ? sort.active : sort.inactive) || 1;

    dataToSort
      .sort((a, b) => (
        (a.label.toString().localeCompare(b.label.toString(), undefined, { sensitivity: 'base' })) * sortOrder
      ));

    this.setState({
      activeValues: sortActiveValue ? dataToSort : activeValues,
      inactiveValues: sortActiveValue ? inactiveValues : dataToSort,
      sort: {
        active: sortActiveValue ? sortOrder : sort.active,
        inactive: sortActiveValue ? sort.inactive : sortOrder,
      },
    });
  };

  /**
   * Render a table header with the sort icon
   * @param {string} label - The label of the header
   * @param {number} sortOrder - 1: ascending, 0: none, -1: descending
   * @param {boolean} sortActiveValue - True if it should render a table header for active values, false otherwise
   * @returns {object} HTML for the header
   */
  renderSortableHeader = ({ label, sortOrder, sortActiveValue }) => {
    let sortLabel;

    let sortIcon;

    if (sortOrder > 0) {
      sortLabel = 'ascending'; sortIcon = 'arrowdown';
    } else if (sortOrder < 0) {
      sortLabel = 'descending'; sortIcon = 'arrowup';
    } else {
      sortLabel = 'none'; sortIcon = 'sort';
    }

    return (
      <th aria-sort={sortLabel} scope="col">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          role="button"
          className="slds-text-link_reset"
          href="#"
          onClick={() => this.sortTableBy(sortActiveValue)}
          tabIndex="0"
        >
          <span className="slds-assistive-text">Sort by: </span>
          <div className="slds-grid slds-grid_vertical-align-center slds-has-flexi-truncate">
            <div className="slds-col slds-truncate" title={label}>{label}</div>
            <span
              className="slds-col_bump-right slds-icon_container"
              title={sortOrder ? `Sorted ${sortLabel}` : 'Not sorted'}
            >
              <svg className="slds-icon slds-icon-text-default sort-icon" aria-hidden="true">
                <use xlinkHref={`/assets/icons/utility-sprite/svg/symbols.svg#${sortIcon}`} />
              </svg>
            </span>
          </div>
        </a>
        <span className="slds-assistive-text" aria-live="assertive" aria-atomic="true">
          {sortOrder ? `Sorted ${sortLabel}` : 'Not sorted'}
        </span>
      </th>
    );
  };

  /**
   * Renders the active values state
   * @returns {object} HTML for the Active values
   */
  renderActiveValues = () => {
    const {
      activeValues,
      sort,
      selectedField,
    } = this.state;

    return (
      <>
        <table
          className="picklist-values-table slds-table slds-table_cell-buffer slds-table_bordered"
        >
          <thead>
            <tr className="slds-line-height_reset">
              <th colSpan="3" scope="col">
                <div className="slds-truncate v2-h1-style" title="Active values">Active values</div>
              </th>
            </tr>
            <tr className="slds-line-height_reset v2-h2-style">
              <th scope="col">
                <div className="slds-truncate v2-h2-style" title="Value">Value</div>
              </th>
              {this.renderSortableHeader({ label: 'Label', sortOrder: sort.active, sortActiveValue: true })}
              <th className="status-column" scope="col">
                <div className="slds-truncate v2-h2-style" title="Status">Status</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {activeValues && activeValues.length > 0 ?
              (
                activeValues.map((p, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <tr key={i} className="slds-hint-parent">
                    <td data-label="Active" className="v2-input-label-style">
                      <span className="slds-icon_container drag" title="Drag this card to change its order">
                        <svg className="slds-icon slds-icon-text-default slds-icon_small" aria-hidden="true">
                          <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#drag_and_drop" />
                        </svg>
                      </span>
                        <PicklistValueInput
                          val={p.value}
                          i={i}
                          isActive
                          selectedField={selectedField}
                          handleChangeOptionValueInOptions={this.handleChangeOptionValueInOptions}
                          handleSetPicklistsAEState={this.handleSetPicklistsAEState}
                          handleAddToOptions={this.handleAddToOptions}
                          valueInputRef={this.valueInputRef}
                          readOnly
                        />
                    </td>
                    <td data-label="Label">
                      <Input
                        containerTitle={p.label}
                        containerClassName="slds-truncate"
                        id={'active_val_' + i.toString()}
                        placeholder="Label"
                        className="v2-input-label-style"
                        required
                        onChange={e => this.handleChangeOptionLabelInOptions(e, false, i, true)}
                        value={p.label || ''}
                        />
                    </td>
                    <td data-label="Status">
                      <div className="slds-truncate" title="Inactive">
                        <div className="slds-form-element element-container">
                          <ToggleButton
                            id={'checkbox-unique_' + i}
                            checked={p.isActive}
                            onChange={() => this.handleChangeIsActiveStateForActiveValues(true, i)}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              ) :
              null}
          </tbody>
        </table>
        {(!activeValues || !activeValues.length) && (
          <div className="slds-truncate no-values v2-input-label-style" title="No active values">
            No active values
          </div>
        )}
      </>
    );
  };

  /**
   * renders the inactive values state
   * @returns {object} - HTML for the Inactive values state
   */
  renderInActiveValues = () => {
    const {
      inactiveValues,
      sort,
      selectedField,
    } = this.state;

    return (
      <>
        <table
          className="picklist-values-table slds-table slds-table_cell-buffer slds-table_bordered"
        >
          <thead>
            <tr className="slds-line-height_reset">
              <th colSpan="4" scope="col">
                <div className="slds-truncate v2-h1-style" title="Inactive values">Inactive values</div>
              </th>
            </tr>
            <tr className="slds-line-height_reset v2-h2-style">
              <th scope="col">
                <div className="slds-truncate v2-h2-style" title="Value">Value</div>
              </th>
              {this.renderSortableHeader({ label: 'Label', sortOrder: sort.inactive, sortActiveValue: false })}
              <th className="status-column" scope="col">
                <div className="slds-truncate" title="Status v2-h2-style">Status</div>
              </th>
              <th scope="col">
                <div className="slds-truncate v2-h2-style" title="Action">Action</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {inactiveValues && inactiveValues.length > 0 ?
              (
                inactiveValues.map((p, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <tr key={i} className="slds-hint-parent">
                    <td data-label="Inactive">
                      <span className="slds-icon_container drag" title="Drag this card to change its order">
                        <svg className="slds-icon slds-icon-text-default slds-icon_small" aria-hidden="true">
                          <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#drag_and_drop" />
                        </svg>
                      </span>
                        <PicklistValueInput
                          val={p.value}
                          i={i}
                          selectedField={selectedField}
                          handleChangeOptionValueInOptions={this.handleChangeOptionValueInOptions}
                          handleSetPicklistsAEState={this.handleSetPicklistsAEState}
                          handleAddToOptions={this.handleAddToOptions}
                          valueInputRef={this.valueInputRef}
                          readOnly
                        />
                    </td>
                    <td data-label="Label">
                      <Input
                        containerTitle={p.label}
                        containerClassName="slds-truncate"
                        id={'inactive_val_' + i.toString()}
                        placeholder="Label"
                        className="v2-input-label-style"
                        required
                        onChange={e => this.handleChangeOptionLabelInOptions(e, false, i, false)}
                        value={p.label || ''}
                      />
                    </td>
                    <td data-label="Status">
                      <div className="slds-truncate" title="Inactive">
                        <div className="slds-form-element element-container">
                          <ToggleButton
                            id={'checkbox-unique_inactive_' + i}
                            onChange={() => this.handleChangeIsActiveStateForActiveValues(false, i)}
                            checked={p.isActive}
                          />
                        </div>
                      </div>
                    </td>
                    <td data-label="Action">
                      <div className="slds-truncate" title="Action">
                        <Button
                          className="slds-button_icon-border-filled remove-picklist-ae"
                          title="Remove"
                          onClick={async e => this.handleRemovePicklistAE(e, i)}
                        >
                          <svg className="slds-button__icon" aria-hidden="true">
                            <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#delete" />
                          </svg>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))
              ) :
              null}
          </tbody>
        </table>
        {(!inactiveValues || !inactiveValues.length) && (
          <div className="slds-truncate no-values" title="No inactive values">
            No inactive values
          </div>
        )}
      </>
    );
  };

  /**
   * It helps to set AdminPanel's state
   * @param {object} newState - a new state object to be set
   * @returns {void}
   */
  handleSetPicklistsAEState = (newState) => {
    this.setState(newState);
  };

  /**
   * Fires a swal showing why autorefresh failed
   * @returns {void}
   */
  handleShowAutoRefreshFailedError = () => {
    const { picklist: { queryDEError } } = this.state;

    SwalUtil.fire({
      type: Constants.SWAL__TYPE__ERROR,
      title: 'Auto Refresh Failed',
      message: queryDEError,
    });
  };

  render() {
    const {
      picklistId,
      handleSetAdminPanelState,
      activePanel,
      navigateToMenu,
      isGettingValuesFromDEStarted,
      userInfo,
      hasServer2ServerInstalledPackageInfo,
    } = this.props;
    const {
      isActive,
      selectedDataExtension,
      name,
      selectedField,
      fields,
      activeValues,
      inactiveValues,
      loadingFields,
      picklist,
      retrieveDistinctDEValues,
      dataExtensions,
      loading,
      showTooltip,
      openSplitSeparatorsSettings,
      openAutoRefreshSettings,
      isSavingPicklist,
    } = this.state;

    const hasServer2ServerInstalledPackage = Util.checkIsEnabledCookies(hasServer2ServerInstalledPackageInfo);
    const { lastQueryDEDate, queryDEStatus } = picklist || {};
    const DATEFORMAT = timeUtil.getUserDateTimeFormat(userInfo);

    const isCreateANewPicklist = activePanel === Constants.ADMIN_PANEL__MENU__NEW_PICKLIST;
    /**
     * Get the picklist button label
     * @returns {string} The picklist button label
     */
    const pickListButton = () => {
      if (picklistId && activePanel === Constants.ADMIN_PANEL__MENU__EDITED_PICKLIST) return 'Edit Picklist';
      if (isCreateANewPicklist) return 'New Picklist';

      return 'Picklists';
    };

    /**
     * Handle action to take when the modal is closed on loading
     * @returns {void}
     */
    const handleCloseModal = () => {
      // Reset Picklist Id in case of cancellation of the opening
      handleSetAdminPanelState({ picklistId: null });
      navigateToMenu(Constants.ADMIN_PANEL__MENU__PICKLISTS);
    };

    /*
     * Format the data extensions for the dropdown
     */
    const targetDeOptions = Util.formattedDataForTheDropdown(dataExtensions, 'ObjectID', 'Name')
      .filter(de => de.text ? de.text[0] !== '_' : false);

    /*
     * Format the fields for the dropdown
     */
    const targetFieldsOptions = Util.formattedDataForTheDropdown(fields, 'ObjectID', 'Name', null, field => !!(
      field.FieldType === Constants.FILTERLINE__FIELDTYPE__BOOLEAN ||
      field.FieldType === Constants.FILTERLINE__FIELDTYPE__DATE) || field.disabledField);

    if (loading) {
      return (
        <LoadingModal
          closeModal={handleCloseModal}
          id="loadingmodal-wrapper"
          loadingText={picklistId ? 'Picklist is loading...' : 'Loading...'}
        />
      );
    }

    /**
     * Choose the right placeholder for the fields dropdown
     * @returns {string} Correct placeholder for fields
     */
    const fieldsPlaceHolder = () => {
      let placeholder = 'Select a field from the list';

      if (Util.objectIsEmpty(selectedDataExtension)) {
        placeholder = 'Select a Data Extension to load fields';
      } else {
        if (typeof selectedField === 'object' && Object.prototype.hasOwnProperty.call(selectedField, 'Name')) {
          placeholder = selectedField.Name;
        }
      }

      return placeholder;
    };

    /**
     * Choose the right placeholder for the data extensions dropdown
     * @returns {string} Correct placeholder for data extensions
     */
    const dataExtensionsPlaceHolder = () => {
      if (Object.prototype.hasOwnProperty.call(selectedDataExtension, 'Name')) {
        return selectedDataExtension.Name.toString();
      }

      return 'Please select a Data Extension';
    };

    return (
      <>
        <div className="slds-page-header slds-m-bottom_medium">
          <div className="slds-page-header__row">
            <div className="slds-page-header__col-title">
              <div className="slds-media header-alignment">
                <div className="slds-media__figure">
                  <span className="slds-icon_container slds-icon-standard-account" title="Picklists">
                    <svg className="slds-icon slds-page-header__icon" aria-hidden="true">
                      <use xlinkHref="/assets/icons/standard-sprite/svg/symbols.svg#picklist_type" />
                    </svg>
                  </span>
                </div>
                <div className="slds-media__body">
                  <div className="slds-page-header__name">
                    <div className="slds-page-header__name-title">
                      <h1>
                        <span
                          className="slds-page-header__title slds-truncate"
                          title="Picklists"
                          id="picklist-header-title"
                        >
                          {pickListButton()}
                        </span>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="picklist-outer-container">
        <div className="table-common-look picklist_container">
          <div className={classNames('picklist_wrapper', { 'disabled-picklist-wrapper': isSavingPicklist })}>
          <div
          className={classNames(
            'main-picklist-div picklist_wrapper',
            { 'disabled-picklist-wrapper': isSavingPicklist },
          )}
          >

            <div className="slds-grid">
              <div className="slds-col">
                <div className="picklist_header">
                  <div className="picklist_header-title">Define a picklist</div>
                </div>
              </div>

              <div className="slds-col_bump-right">
                <div className="slds-form-element element-container">
                  <ToggleButton
                    id="picklist-status"
                    checked={isActive}
                    disabled={!activeValues.length}
                    onChange={() => this.setState(prevState => ({ isActive: !prevState.isActive }))}
                    label={`Picklist ${isActive ? 'enabled' : 'disabled'}`}
                    toggleClassName={activeValues.length === 0 && 'not-allowed'}
                  />
                  <span
                    className="error-text"
                    style={{ visibility: isActive ? 'hidden' : 'visible' }}
                  >
                    Picklist is disabled. Enable it once you finish editing.
                    {!activeValues.length && (
                      <span
                        className="tooltip-container"
                        onMouseEnter={() => this.setState({ showTooltip: true })}
                        onMouseLeave={() => this.setState({ showTooltip: false })}
                      >
                        <i className="fas fa-info-circle" />
                        {showTooltip && (
                          <span className="popover-body">
                            This picklist is disabled until at least one active value is defined.
                          </span>
                        )}
                      </span>
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div className="slds-grid picklinst_input-margins">
              <div className="slds-col">
                <div className="slds-form-element element-container">
                  <span className="slds-form-element__label picklist-name">
                    <span className="v2-input-label-style">Name</span>
                    <div className="slds-form-element__control">
                      <PicklistNameInput
                        picklist={picklist}
                        picklistId={picklistId}
                        activePanel={activePanel}
                        handleChangePicklistName={this.handleChangePicklistName}
                        picklistName={name}
                      />
                    </div>
                  </span>
                </div>
              </div>
            </div>

            <div className="slds-grid">
              <div className="slds-col slds-size_1-of-2">
                <div className="data-extensions-searchable-dropdown">
                  <span className="v2-input-label-style">Data Extension</span>
                  <Dropdown
                    id="target-DE-dropdown"
                    selection
                    className="target-data-extension-dropdown searchable-dropdown label-space v2-input-label-style"
                    search
                    disabled={!!picklistId}
                    options={targetDeOptions}
                    placeholder={dataExtensionsPlaceHolder()}
                    onChange={(e, data) => this.handleSetSelectedDataExtension(data)}
                    loading
                    style={{ borderRadius: '.25rem' }}
                  />
                </div>
              </div>
              <div className="slds-col slds-size_1-of-2">
                <div className="fields-searchable-dropdown">
                 <span className="v2-input-label-style">Field</span>
                  <Dropdown
                    id="select-fields"
                    selection
                    className="target-data-extension-dropdown searchable-dropdown label-space v2-input-label-style"
                    search
                    placeholder={fieldsPlaceHolder()}
                    disabled={!!picklistId ||
                              loadingFields ||
                              !(selectedDataExtension)}
                    options={targetFieldsOptions}
                    onChange={(e, data) => this.handleSetSelectedField(data)}
                    loading
                    style={{ borderRadius: '.25rem' }}
                    text={selectedField?.Name ? selectedField.Name.toString() : ''}
                    value={selectedField?.Name ? selectedField.Name.toString() : ''}
                  />
                </div>
              </div>
            </div>

            <hr />

            <div className="slds-grid slds-title-position">
              <div className="slds-col">
                <h3 className="title">Add values</h3>
              </div>
            </div>

            <div className="slds-grid">
              <PicklistAddValuesComponent
                selectedField={selectedField}
                handleChangeOptionValueInOptions={this.handleChangeOptionValueInOptions}
                handleAddToOptions={this.handleAddToOptions}
                valueInputRef={this.valueInputRef}
              />
              <div className="buttons-container">
                <div className="slds-col">
                  <AutoRefreshPicklistValues
                    picklist={picklist || {}}
                    handleSetPicklistsAEState={this.handleSetPicklistsAEState}
                    disableAutoRefreshPicklistValues={(!picklist && !selectedField.Name) ||
                      (!hasServer2ServerInstalledPackage) || ((!picklist && !name))}
                    openAutoRefreshSettings={openAutoRefreshSettings}
                  />
                  {
                    !hasServer2ServerInstalledPackage && (
                      <Tooltip
                        nubbinPosition={Constants.NUBBIN_POSITION__BOTTOM_RIGHT}
                        type={Constants.TOOLTIP_TYPE__DISABLED_AUTO_REFRESH}
                        customClassName="disabled-autorefresh"
                      />
                    )
                  }
                  <RetrieveDEValuesButton
                    pickList={picklist || {}}
                    selectedField={selectedField}
                    selectedDataExtension={selectedDataExtension}
                    picklistName={name}
                    isActive={isActive}
                    activeValues={activeValues}
                    inactiveValues={inactiveValues}
                    handleSetPicklistsAEState={this.handleSetPicklistsAEState}
                    handleSetAdminPanelState={handleSetAdminPanelState}
                    retrieveDistinctDEValues={retrieveDistinctDEValues}
                    disableRetrieveButton={(!picklist && !selectedField.Name) ||
                      (!picklist && !name)}
                  />
                  <SplitSeparators
                    handleSetPicklistsAEState={this.handleSetPicklistsAEState}
                    picklist={picklist || {}}
                    disableSplitSeparatorsButton={(!picklist && !selectedField.Name) ||
                      (!picklist && !name)}
                    openSplitSeparatorsSettings={openSplitSeparatorsSettings}
                  />
                </div>
                {/* Last refresh text */}
                {
                  lastQueryDEDate && (
                    <span className="last-refresh-text">
                      Last refresh of picklist values
                      {` (${timeUtil.formatDate(lastQueryDEDate, DATEFORMAT)}): `}
                      {queryDEStatus === Constants.PICKLIST__STATUS__QA_ERROR ?
                        (
                          <span
                            onClick={this.handleShowAutoRefreshFailedError}
                            className="refresh-status-failed"
                          >
                            Failed
                          </span>
                        ) :
                        (
                          <span>Successful</span>
                        )}
                    </span>
                  )
                }
              </div>
            </div>

            <div ref={this.optionsRef} className="slds-grid active-inactive-container">
              <div className="slds-col slds-size_1-of-2">
                <ReactDragListView handleSelector=".drag" onDragEnd={this.onDragEndActiveValues}>
                  {this.renderActiveValues()}
                </ReactDragListView>
              </div>
              <div className="slds-col slds-size_1-of-2">
                <div className="picklist-table-width">
                <ReactDragListView
                handleSelector=".drag"
                onDragEnd={this.onDragEndInActiveValues}>
                  {this.renderInActiveValues()}
                </ReactDragListView>
                </div>
              </div>
            </div>
          </div>
          <div className="slds-grid cancel-save-picklist">
              <div className="slds-col_bump-left picklist-edit-btn-align">
                <Button
                  id="cancel-picklist"
                  buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
                  onClick={() => this.handleCancel()}
                  disabled={(picklist === null && picklistId &&
                    activePanel === Constants.ADMIN_PANEL__MENU__EDITED_PICKLIST) || isGettingValuesFromDEStarted}
                >
                  Cancel
                </Button>
                <Button
                  id="save-picklist"
                  buttonLook={Constants.BUTTON__TYPE__BRAND}
                  onClick={e => this.handleSubmitSave(e, picklistId)}
                  disabled={(picklist === null && picklistId &&
                    activePanel === Constants.ADMIN_PANEL__MENU__EDITED_PICKLIST) ||
                     isSavingPicklist || isGettingValuesFromDEStarted}
                  loadingClickedButton={isSavingPicklist}
                  titleInAction="Saving..."
                >
                  Save
                </Button>
              </div>
          </div>
          </div>
        </div>
        </div>
      </>
    );
  }
}

PicklistAE.propTypes = {
  /**
   * It keeps the id value of a picklist to be able it
   */
  picklistId: PropTypes.string,
  /**
   * It helps to navigate between page and Admin Panel with fetching data
   */
  openPanel: PropTypes.func.isRequired,
  /**
   * It keeps location where we are entering in
   */
  activePanel: PropTypes.string.isRequired,
  /**
   * Function for setting admin panel state
   */
  handleSetAdminPanelState: PropTypes.func.isRequired,
  /**
   * if we are editing a picklist, then this prop will be passed in order to retrieve the data extension
   */
  picklistCustomerKey: PropTypes.string,
  /**
   * It helps to navigate between pages in Admin Panel without fetching data
   */
  navigateToMenu: PropTypes.func.isRequired,
  /**
   * Indicates if the Save button should be disabled
   */
  isGettingValuesFromDEStarted: PropTypes.bool.isRequired,
  /**
   * User info from cookie
   */
  userInfo: PropTypes.object,
  /**
   * hasServer2ServerInstalledPackage info from cookie
   */
  hasServer2ServerInstalledPackageInfo: PropTypes.string,
};

export default connect(
  mapStateToProps(['userInfo', 'hasServer2ServerInstalledPackageInfo']),
  null,
  null,
  { pure: false },
)(PicklistAE);
