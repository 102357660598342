import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import DatePicker from 'react-datepicker';
import classNames from 'classnames';
import moment from 'moment';
import { connect } from 'react-redux';

import mapStateToProps from '../../../../mapStateToProps';
import Constants from '../../../../constants/constants';
import Util from '../../../../util';
import ModalTemplate from '../../../shared/ModalTemplate/ModalTemplate';
import ToggleButton from '../../../shared/ToogleButton/ToggleButton';
import Select from '../../../shared/Select/Select';
import Input from '../../../shared/Input/Input';
import Checkbox from '../../../shared/Checkbox/Checkbox';
import RadioButton from '../../../shared/RadioButton/RadioButton';
import Tooltip from '../../../shared/Tooltip/Tooltip';
import timeUtil from '../../../../utils/time/timeUtil';
import SwalUtil from '../../../../utils/swal/swalUtil';

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
class DataRetentionPolicy extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props.dataRetentionPolicy };
  }

  /**
   * Activate or deactivate retention policy
   * @returns {void}
   */
  handleActivate = () => {
    const {
      toggle, individualRecords, allRecords, allRecordsAndDE, periodAfter, periodOn,
    } = this.state;
    const { editTargetDataExtension } = this.props;

    if(editTargetDataExtension) { return; }

    switch (toggle) {
      case false:
        this.setState({
          individualRecords: !individualRecords && !allRecords && !allRecordsAndDE ? true : individualRecords,
          dataRetentionPolicy: true,
          periodAfter: !periodAfter && !periodOn ? true : periodAfter,
          toggle: true,
        });
        break;
      case true:
        this.setState({
          dataRetentionPolicy: false,
          toggle: false,
        });
        break;
      default:
        break;
    }
  };

  /**
   * This function helps to change which records will be effected by the retention
   * e.g individual records, all records etc...
   * @param {object} e - event
   * @returns {void}
   */
  handleOnChangeRadios = (e) => {
    const { editTargetDataExtension } = this.props;

    if(editTargetDataExtension) { return; }

    switch (e.target.id) {
      // These values are id attr of the areas
      case Constants.DATA_RETENTION_POLICY__APPLY_CRITERIA__INDIVIDUAL_RECORDS:
        this.setState({
          individualRecords: true,
          allRecordsAndDE: false,
          allRecords: false,
          periodOn: false,
          periodAfter: true,
          resetRetentionPeriodOnImport: false,
        });
        break;
      case Constants.DATA_RETENTION_POLICY__APPLY_CRITERIA__ALL_RECORDS_DE:
        this.setState({
          individualRecords: false,
          allRecordsAndDE: true,
          allRecords: false,
        });
        break;
      case Constants.DATA_RETENTION_POLICY__APPLY_CRITERIA__ALL_RECORDS:
        this.setState({
          individualRecords: false,
          allRecordsAndDE: false,
          allRecords: true,
        });
        break;
      case Constants.DATA_RETENTION_POLICY__PERIOD__AFTER:
        this.setState({
          periodAfter: true,
          periodOn: false,
        });
        break;
      case Constants.DATA_RETENTION_POLICY__PERIOD__ON:
        this.setState({
          periodAfter: false,
          periodOn: true,
          resetRetentionPeriodOnImport: false,
        });
        break;
      default:
        break;
    }
  };

  /**
   * Change the value of period length
   * @param {object} e - event
   * @returns {void}
   */
  handleOnPeriodLengthChange = (e) => {
    const { editTargetDataExtension } = this.props;

    // do nothing if we are in edit mode
    if (editTargetDataExtension) { return; }

    if (e.target.validity.valid && e.target.name && e.target.name === 'dataRetentionPeriodLength') {
      /*
       * if field type is number and value doesn't containe only numbers
       * don't allow entering that value (eg. dot, comma, etc...)
       */
      if (!Util.containsOnlyNumbers(e.target.value) && e.target.value !== '') {
        return;
      }
      this.setState({
        [e.target.name]: e.target.value >= 1 ? e.target.value : 1,
        dataExtensionPeriodDate: '',
      });
    }
  };

  /**
   * This function helps with the date value for date picker
   * @param {object} e - event
   * @returns {void}
   */
  handlePeriodDateChange = (e) => {
    const { editTargetDataExtension } = this.props;

    // do nothing if we are in edit mode
    if (editTargetDataExtension) { return; }

    const newDate = e ? timeUtil.formatDateForDatePicker(e) : '';

    this.setState({
      dataExtensionPeriodDate: newDate,
      dataRetentionPeriodLength: 1,
      periodAfter: false,
      periodOn: true,
    });
  };

  /**
   * Handle dataRetentionPolicy object and close the dataRetentionPolicy modal - on save
   * @returns {void}
   */
  onSubmit = () => {
    const {
      periodAfter,
      periodOn,
      individualRecords,
      dataExtensionPeriodDate,
      dataRetentionPeriodLength,
      dataRetentionPolicy,
      allRecordsAndDE,
      allRecords,
      resetRetentionPeriodOnImport,
      dataRetentionPeriod,
      toggle,
    } = this.state;

    const { handleDataRetentionPolicyObjectAndCloseModal } = this.props;

    // Extracting only desired properties from this.state
    const dataRetentionPolicyObjectCopy = {
      dataRetentionPolicy,
      individualRecords,
      allRecordsAndDE,
      allRecords,
      periodAfter,
      periodOn,
      resetRetentionPeriodOnImport,
      dataRetentionPeriod,
      dataRetentionPeriodLength,
      dataExtensionPeriodDate,
      toggle,
    };

    if(!dataRetentionPolicy) {
    // reset the settings if data retention policy is turned off
      Object.assign(dataRetentionPolicyObjectCopy, {
        individualRecords: false,
        allRecordsAndDE: false,
        allRecords: false,
        periodAfter: false,
        periodOn: false,
        resetRetentionPeriodOnImport: false,
      });
    }

    // if 'On' is checked but period length is not picked then show the message
    if(periodAfter && !dataRetentionPeriodLength) {
      SwalUtil.fire({
        title: 'Period Length Missing',
        message: 'Please pick a number for the period.',
      });

      return;
    }

    // if 'On' is checked but date is not picked then show the message
    if (periodOn && !dataExtensionPeriodDate) {
      SwalUtil.fire({
        title: 'Date Missing',
        message: 'Please pick a date.',
      });

      return;
    }

    // if period after or individual records are checked then set dataExtensionPeriodDate to empty
    if (periodAfter || individualRecords) {
      dataRetentionPolicyObjectCopy.dataExtensionPeriodDate = '';
      // if 'On' is checked set dataRetentionPeriod and dataRetentionPeriodLength to their initial values
    } else if (periodOn) {
      dataRetentionPolicyObjectCopy.dataRetentionPeriod = Constants.DATA_RETENTION_POLICY__PERIOD__DAYS;
      dataRetentionPolicyObjectCopy.dataRetentionPeriodLength = 1;
    }

    handleDataRetentionPolicyObjectAndCloseModal(dataRetentionPolicyObjectCopy);
  };

  /**
   * Handler for changing data retention period
   * @param {object} newState - object with the new state to be set
   * @returns {void}
   */
  handleChangeDataRetentionPeriod = (newState) => {
    const { editTargetDataExtension } = this.props;

    // do nothing if we are in edit mode
    if (editTargetDataExtension) { return; }

    // set new properties in the state
    this.setState({ ...newState });
  };

  render() {
    /**
     * Determines whether or not to disable the reset period on import checkbox.
     * @param {object} dataRetentionPolicy - The data retention policy
     * @param {boolean} individualRecords - A boolean indicating if individual records are shown
     * @param {boolean} allRecords - A boolean indicating if all records are shown
     * @param {boolean} allRecordsAndDE - A boolean indicating if all records and data extensions are shown
     * @returns {boolean} True or false
     */
    const shouldResetPeriodOnImportCheckboxBeDisabled = (
      dataRetentionPolicy,
      individualRecords,
      allRecords,
      allRecordsAndDE,
    ) => {
      if (dataRetentionPolicy) {
        if (individualRecords) return true;
        if (allRecords) return false;

        return !allRecordsAndDE;
      }

      return true;
    };

    /**
     * Determines whether or not to disable the policy period radio / datepicker.
     * @param {boolean} individualRecords - A boolean indicating if individual records are shown
     * @param {boolean} allRecords - A boolean indicating if all records are shown
     * @param {boolean} allRecordsAndDE - A boolean indicating if all records and data extensions are shown
     * @returns {boolean} True or false
     */
    const shouldDataRetentionPolicyPeriodBeDisabled = (individualRecords, allRecords, allRecordsAndDE) => {
      if (individualRecords) return true;
      if (allRecords) return false;

      return !allRecordsAndDE;
    };

    const {
      individualRecords,
      allRecordsAndDE,
      allRecords,
      periodAfter,
      periodOn,
      resetRetentionPeriodOnImport,
      dataRetentionPeriod,
      dataRetentionPeriodLength,
      dataExtensionPeriodDate,
      dataRetentionPolicy,
      toggle,
    } = this.state;

    const dataRetentionSettings = {
      individualRecords,
      allRecordsAndDE,
      allRecords,
      periodAfter,
      periodOn,
      resetRetentionPeriodOnImport,
      dataRetentionPeriod,
      dataRetentionPeriodLength,
      dataExtensionPeriodDate,
      dataRetentionPolicy,
      toggle,
    };

    const { handleDataRetentionPolicyObjectAndCloseModal, editTargetDataExtension, userInfo } = this.props;

    const userLocale = timeUtil.getUserLocale(userInfo);

    /**
     * Returns the class name for data retention container - Delete or Period
     * @param {boolean} isPeriod - defines if className should be render for Period container
     * @returns {string} class name
     */
    const containerClassName = isPeriod => classNames(
      isPeriod ? 'period-container' : 'delete-container',
      {
        'disable-container': !dataRetentionPolicy,
        'disable-to-update': editTargetDataExtension,
      },
    );

    /**
     * Returns the class name for elements that change reset period on
     * @param {string} element - it is the name of the element for which we define the name of the class
     * @returns {string} class name
     */
    const resetPeriodOnClassName = element => classNames(
      {
        'disable-container': individualRecords,
        'slds-checkbox__label label-reset-period': element === 'checkbox',
        'slds-radio__label label-period-on': element === 'radio',
      },
    );

    const datePickerClassName = classNames(
      'form-control filter-value period-date-input',
      { 'disable-container': individualRecords || editTargetDataExtension },
    );

    /**
     * Returns date in proper format for DatePicker
     * @returns {string} formatted date
     */
    const selectedDateForDatePicker = () => {
      if (editTargetDataExtension && dataExtensionPeriodDate) {
        // RetainUntil date is formatted to the SFMC timezone, get the local datetime
        const localDateTime = timeUtil.formatDateInDifferentTimezoneToTheLocal(
          'America/Chicago',
          dataExtensionPeriodDate,
        );

        // SFMC doesn't take Daylight Saving into account, so if DST is used, add 1 hour
        if (moment.tz('America/Chicago').isDST()) {
          return moment(localDateTime).add(1, 'hours')._d;
        }

        return localDateTime;
      } if (dataExtensionPeriodDate) {
        return timeUtil.formatDateForDatePicker(dataExtensionPeriodDate)._d;
      }

      return '';
    };

    return (
      <ModalTemplate
          containerId="container-data-retention-policy"
          headerId="modal-heading-01"
          headerTitle="Data Retention Policy"
          contentClassName="slds-p-around_medium"
          contentId="modal-content-id-3"
          cancelButtonId="cancelDRPolicy"
          handleCancel={() => handleDataRetentionPolicyObjectAndCloseModal(dataRetentionSettings)}
          cancelButtonTitle={editTargetDataExtension ? 'Return' : 'Cancel'}
          footerClassName="DRP-footer"
          saveButtonId="saveDRPolicy"
          handleSave={!editTargetDataExtension && this.onSubmit}
          backdropHidden
        >
          {/* RETENTION SETTING */}
          <div className="DRP-part-one">
            <label className="retention-setting-label">
              Retention Setting
            </label>

            <div className={`slds-form-element ${editTargetDataExtension ?
              'disable-to-update' :
              ''}`}>
              <ToggleButton
                name="checkbox-onoff"
                id="policy-switcher"
                onChange={() => this.handleActivate()}
                checked={toggle}
                value={toggle}
                checkboxOnTitle="Enabled"
                checkboxOnId={Constants.DATA_RETENTION_POLICY__STATUS__ON}
                checkboxOffTitle="Disabled"
                checkboxOffId={Constants.DATA_RETENTION_POLICY__STATUS__OFF}
                disabled={editTargetDataExtension}
              />
            </div>
            {editTargetDataExtension &&
              <Tooltip
                nubbinPosition={Constants.NUBBIN_POSITION__TOP_LEFT}
                tooltipText="Retention settings of existing Data Extensions cannot be updated."
                customClassName="retention-read-only"
              />}
          </div>

          {/* BELOW RETENTION SETTING */}
          <div className="DRP-part-two">

            {/* ON THE LEFT */}
            <div className="DRP-part-two_1">
              <label className="apply-to-label">Apply To</label>
            </div>

            {/* ON THE RIGHT */}
            <div className="DRP-part-two_2">
              <label className="delete-label">Delete:</label>

              <div className={containerClassName()}>
                <fieldset className="slds-form-element" disabled={!dataRetentionPolicy || editTargetDataExtension}>
                  <div className="slds-form-element__control">
                    <RadioButton
                      id={Constants.DATA_RETENTION_POLICY__APPLY_CRITERIA__INDIVIDUAL_RECORDS}
                      value={individualRecords}
                      checked={individualRecords || false}
                      onChange={e => this.handleOnChangeRadios(e)}
                      label="Individual records"
                      disabled={editTargetDataExtension}
                    />
                    <RadioButton
                      id={Constants.DATA_RETENTION_POLICY__APPLY_CRITERIA__ALL_RECORDS_DE}
                      value={allRecordsAndDE}
                      checked={allRecordsAndDE || false}
                      onChange={e => this.handleOnChangeRadios(e)}
                      label="All records and data extensions"
                      disabled={editTargetDataExtension}
                    />
                    <RadioButton
                      id={Constants.DATA_RETENTION_POLICY__APPLY_CRITERIA__ALL_RECORDS}
                      value={allRecords}
                      checked={allRecords || false}
                      onChange={e => this.handleOnChangeRadios(e)}
                      label="All records"
                      disabled={editTargetDataExtension}
                    />
                  </div>
                </fieldset>
              </div>

              <label className="label-period">Period:</label>
              <div
                className={containerClassName(true)}
              >
                <fieldset className="slds-form-element">
                  <div
                    className="wrapper-firstPart"
                    id="after-period-container"
                  >
                    <div className="slds-form-element__control">
                      <RadioButton
                        id={Constants.DATA_RETENTION_POLICY__PERIOD__AFTER}
                        checked={periodAfter || (false && periodOn === true)}
                        onChange={e => this.handleOnChangeRadios(e)}
                        label="After"
                        disabled={!dataRetentionPolicy || editTargetDataExtension}
                      />
                    </div>

                    <div className="group-slds-after">
                      <Input
                        withContainer
                        type="number"
                        name="dataRetentionPeriodLength"
                        id="text-input-id-5"
                        required
                        className="slds-input_counter after-number-input"
                        value={dataRetentionPeriodLength || false}
                        onChange={(e) => { this.handleOnPeriodLengthChange(e); }}
                        disabled={!periodAfter || editTargetDataExtension}
                      />

                      <div className="slds-form-element">
                        <div className="slds-form-element__control">
                          <Select
                            className="after-select-input"
                            id="select-01"
                            value={dataRetentionPeriod || false}
                            onChange={(e) => {
                              this.handleChangeDataRetentionPeriod({ dataRetentionPeriod: e.target.value });
                            }}
                            disabled={!periodAfter || editTargetDataExtension}
                            options={[
                              {
                                value: Constants.DATA_RETENTION_POLICY__PERIOD__DAYS,
                                label: Constants.DATA_RETENTION_POLICY__PERIOD__DAYS,
                              },
                              {
                                value: Constants.DATA_RETENTION_POLICY__PERIOD__WEEKS,
                                label: Constants.DATA_RETENTION_POLICY__PERIOD__WEEKS,
                              },
                              {
                                value: Constants.DATA_RETENTION_POLICY__PERIOD__MONTHS,
                                label: Constants.DATA_RETENTION_POLICY__PERIOD__MONTHS,
                              },
                              {
                                value: Constants.DATA_RETENTION_POLICY__PERIOD__YEARS,
                                label: Constants.DATA_RETENTION_POLICY__PERIOD__YEARS,
                              },
                            ]}
                            />
                        </div>
                      </div>
                    </div>
                  </div>
                  <Checkbox
                    formElementClassName="wrapper-secondPart"
                    name="options"
                    id="reset-period-on-import"
                    value="reset-period-on-import"
                    checked={resetRetentionPeriodOnImport || false}
                    className={resetPeriodOnClassName()}
                    onChange={() => {
                      this.handleChangeDataRetentionPeriod({
                        resetRetentionPeriodOnImport: !resetRetentionPeriodOnImport,
                        periodAfter: true,
                        periodOn: false,
                      });
                    }}
                    disabled={shouldResetPeriodOnImportCheckboxBeDisabled(
                      dataRetentionPolicy,
                      individualRecords,
                      allRecords,
                      allRecordsAndDE,
                    ) || editTargetDataExtension}
                    labelClassName={resetPeriodOnClassName('checkbox')}
                    label="Reset period on import"
                  />
                  <div
                    className="slds-form-element__control wrapper-thirdPart"
                    id="label-period-on-container"
                  >
                    <RadioButton
                      containerClassName="slds-radio"
                      id={Constants.DATA_RETENTION_POLICY__PERIOD__ON}
                      checked={periodOn || false}
                      onChange={e => this.handleOnChangeRadios(e)}
                      disabled={shouldDataRetentionPolicyPeriodBeDisabled(
                        individualRecords,
                        allRecords,
                        allRecordsAndDE,
                      ) || editTargetDataExtension}
                      labelClassName={resetPeriodOnClassName('radio')}
                      label="On"
                    />
                  </div>
                      <DatePicker
                        fixedHeight
                        popperPlacement="top-start"
                        className={datePickerClassName}
                        style={{ position: 'absolute' }}
                        name="dataExtensionPeriodDate"
                        type="text"
                        dateFormat={timeUtil.getDatePickerDateFormat(userLocale)}
                        minDate={new Date()}
                        selected={selectedDateForDatePicker()}
                        onChange={e => this.handlePeriodDateChange(e)}
                        disabled={shouldDataRetentionPolicyPeriodBeDisabled(
                          individualRecords,
                          allRecords,
                          allRecordsAndDE,
                        ) || editTargetDataExtension}
                        data-placement="top-start"
                      />
                </fieldset>
              </div>
            </div>
          </div>
      </ModalTemplate>
    );
  }
}

DataRetentionPolicy.propTypes = {
  /**
   * It helps to close the data retention policy modal
   * It will be passed from NewAutoCreatedTargetDE.js
   */
  handleDataRetentionPolicyObjectAndCloseModal: PropTypes.func.isRequired,
  /**
   * It keeps data retention policy state for the new target data extension
   * It will be passed from Selection.js
   */
  dataRetentionPolicy: PropTypes.instanceOf(Object),
  /**
   * Indicates if target data extension is updating
   */
  editTargetDataExtension: PropTypes.bool.isRequired,
  /**
   * User info from cookie
   */
  userInfo: PropTypes.object,
};

export default connect(mapStateToProps(['userInfo']), null, null, { pure: false })(DataRetentionPolicy);
