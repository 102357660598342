import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

// Utilities
import Util from '../../../../util';
import SelectionFolderPopup from '../../../Selection/Navbar/SelectionName/SelectionFolderPopup/SelectionFolderPopup';
import Button from '../../../shared/Button/Button';

const ArchiveSelectionModal = ({
  handleCloseArchiveSelectionModal,
  handleArchiveSelection,
  selection,
  folders,
  isWaterfall,
  defaultRestoreFolderId,
  archivedNotWaterfallSelections,
}) => {
  const [showFolderModal, setShowFolderModal] = useState(false);
  const [restoreFolderInModal, setRestoreFolderInModal] = useState(null);
  const [selectedFolderInModal, setSelectedFolderInModal] = useState(null);
  const [folderIdState, setFolderIdState] = useState(); // Contains the state one child of the current folder we are in
  const [parentFolder, setParentFolder] = useState();
  const [foldersToSee, setFoldersToSee] = useState(); // Contains the state of folders to display

  useEffect(() => {
    setFolderIdState(defaultRestoreFolderId || selection?.folderId);
  }, [selection?.folderId, defaultRestoreFolderId]);

  // Initialize folders to see and the parent one
  useEffect(() => {
    if (folderIdState) {
      const currentF = folders.find(elem => elem._id === folderIdState);

      setParentFolder(folders.find(elem => elem._id === currentF?.parentFolderId));
      setFoldersToSee(folders.filter(elem => elem.parentFolderId === currentF?.parentFolderId));
    } else {
      setParentFolder(null);
      setFoldersToSee(folders.filter(elem => elem.parentFolderId === '0'));
    }
  }, [folderIdState, folders]);

  if (!selection) {
    return (
      null
    );
  }

  const { isArchived } = selection;

  const selectionTypeName = `${isWaterfall ? 'Waterfall ' : ''}Selection`;

  const currentSelectionFolder = selectedFolderInModal || folders.find(elm => elm._id === folderIdState);

  let waterfallSelectionsNamesList;

  // Join the names of all archived selection that are used in the current waterfall selection
  if (archivedNotWaterfallSelections.length > 0) {
    waterfallSelectionsNamesList = archivedNotWaterfallSelections.map(
      w => ` '${w.name}'`,
    ).join(', ');
  }

  const archivingWarningMessage =
    (
      <div>
        <p>
          Are you sure you want to archive this
          {` ${selectionTypeName}`}
          ?
        </p>
        <br />
        <p>
          Once you archive this
          {` ${selectionTypeName}`}
          , you will no longer be able to run/modify it,
          and any active schedules will be automatically canceled.
        </p>
        <br />
        <p>
          You can access your archived
          {` ${selectionTypeName} `}
          from the
          <span style={{ fontWeight: 'bolder' }}>
            {' '}
            {isWaterfall ? 'Archived Waterfall Selections' : 'Archived Selections'}
            {' '}
          </span>
          folder.
        </p>
      </div>);

  const restoringWarningMessage =
    (
      <div>
        <p>
          Are you sure you want to restore this
          {` ${selectionTypeName}`}
          ?
        </p>
        <br />
        <p>
          Please note that once you restore this
          {` ${selectionTypeName} `}
          you will be able to run/modify it,
          however, any previous schedules will not be restored and you will have to create new schedules.
          {waterfallSelectionsNamesList && (
            <>
              <br />
              <br />
              Also, note that this Waterfall contains individually archived selections.
              Restoring this Waterfall  Selection will also restore the following selection(s):
              <span style={{ fontWeight: 'bold' }}>
                {waterfallSelectionsNamesList}
                .
              </span>
            </>
          )}
        </p>
      </div>);

  /**
   * Archives selected Selection
   * @param {string} restoreFolderId - Folder in which selection should be restored
   * @returns {void}
   */
  const handleOnSubmit = async (restoreFolderId) => {
    handleCloseArchiveSelectionModal();
    await handleArchiveSelection(selection._id, restoreFolderId);
  };

  /**
   * Handle Open Children
   * @param {object} folderItem - Object of the folder to open its children
   * @returns {void}
   */
  const handleOpenChildren = (folderItem) => {
    const firstChild = folders.find(elem => elem.parentFolderId === folderItem?._id);

    if (firstChild) {
      setFolderIdState(firstChild._id);
    } else {
      setFoldersToSee([]);
      setParentFolder(folderItem);
    }
  };

  /**
   * Handle select restore folder
   * @param {{folderId: String}} - Object containing folderId
   * @returns {void}
   */
  const handleSelectRestoreFolder = ({ folderId }) => {
    const folderFound = folders.find(elem => elem._id === folderId);

    setRestoreFolderInModal(folderFound);
  };

  return (
    <>
      <section
        role="dialog"
        tabIndex="-1"
        className="slds-modal slds-fade-in-open"
        aria-labelledby="modal-heading-01"
        aria-modal="true"
        aria-describedby="modal-content-id-1"
        id="archive-selection-modal-dialog"
      >
        <div className="slds-modal__container">
          <header className="modal-header">
            <div
              className="slds-notify slds-notify_alert slds-theme_default"
              role="alert"
              id="archive-selection-header"
            >
              <h2 className="slds-modal__title slds-hyphenate">
                {`${isArchived ? 'Restore' : 'Archive'} ${isWaterfall ? 'Waterfall Selection' : 'Selection'}`}
              </h2>
            </div>
          </header>
          <div
            className="slds-modal__content"
            id="content"
          >
            <div>
              {isArchived ?
                (
                  <>
                    <div className="archive-selection-message">
                      {restoringWarningMessage}
                    </div>
                    <div className="restore-selection-folder-container">
                      <span className="restore-location-title">Location</span>
                      <div className="restore-selection-folder">
                        <span
                          className="slds-icon_container slds-icon-utility-announcement"
                        >
                          <svg
                            className="slds-icon slds-icon-text-default slds-icon_small restore-folder-icon"
                            aria-hidden="true">
                            <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#open_folder" />
                          </svg>
                        </span>
                        <span className="selected-folder-name">
                          {Util.abbreviate(currentSelectionFolder ?
                            currentSelectionFolder.name :
                            ' All Selections', 30)}
                        </span>
                        <span
                          className="change-location change-folder-popup"
                          onClick={() => setShowFolderModal(true)}
                          title="Choose folder for this selection"
                        >
                          Change Location
                        </span>
                      </div>
                    </div>
                    <div
                      className={`folderPopup ${showFolderModal && 'active'}`}
                      id="folder-popup-id"
                    >
                      {parentFolder && (
                        <span className="currentFolderIn">
                          <svg
                            id="get-parent-folders"
                            className="slds-icon slds-icon-text-default slds-icon_x-small"
                            aria-hidden="true"
                            onClick={() => {
                              setFolderIdState(parentFolder._id);
                              setParentFolder(folders.find(elem => elem._id === parentFolder.parentFolderId));
                              setFoldersToSee(
                                folders.filter(elem => elem.parentFolderId === parentFolder?.parentFolderId),
                              );
                            }}
                          >
                            <use
                              xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#chevronleft"
                            />
                          </svg>
                          {parentFolder.name}
                        </span>)}
                      <div className="makeSpaceFolder">
                        <SelectionFolderPopup
                          folders={foldersToSee || []}
                          handleSetAppState={handleSelectRestoreFolder}
                          folderId={restoreFolderInModal?._id || folderIdState || selection.folderId || ''}
                          handleSetSelectionNameState={() => {
                          }}
                          selections={[]}
                          fetchSelectionsInFolder={() => { }}
                          onOpenChildren={handleOpenChildren}
                          isWaterfall={isWaterfall}
                        />
                      </div>

                      <span
                        className="closeFolderPopup slds-icon_container slds-icon-utility-announcement"
                        onClick={() => {
                          if (!selectedFolderInModal && defaultRestoreFolderId) {
                            setFolderIdState(defaultRestoreFolderId);
                          }

                          setShowFolderModal(false);
                        }}
                      >
                        <svg className="slds-icon slds-icon-text-default slds-icon_x-small" aria-hidden="true">
                          <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#close" />
                        </svg>
                      </span>

                      {restoreFolderInModal && (
                        <Button
                          className="confirmPopupButton slds-button slds-button_brand"
                          onClick={() => {
                            setSelectedFolderInModal(restoreFolderInModal);
                            setShowFolderModal(false);
                          }}
                        >
                          Confirm
                        </Button>
                      )}

                      {restoreFolderInModal && (
                        <span className="selectedFolder" title="name of selected foder">
                          {`Selected: ${restoreFolderInModal.name}`}
                        </span>
                      )}
                    </div>
                  </>
                ) :
                (<div className="archive-selection-message">
                  {archivingWarningMessage}
                 </div>)}
            </div>
            <div />
            <footer className="slds-modal__footer" id="sortlimit-footer">
              <Button
                className="slds-button slds-button_neutral"
                id={isArchived ?
                  `${isWaterfall ? 'restore-waterfall-selection-cancel-btn' : 'restore-selection-cancel-btn'}` :
                  `${isWaterfall ? 'archive-waterfall-selection-cancel-btn' : 'archive-selection-cancel-btn'}`}
                onClick={handleCloseArchiveSelectionModal}
              >
                Cancel
              </Button>
              <Button
                className="slds-button slds-button_brand"
                id={isArchived ?
                  `${isWaterfall ? 'restore-waterfall-selection-submit-btn' : 'restore-selection-submit-btn'}` :
                  `${isWaterfall ? 'archive-waterfall-selection-submit-btn' : 'archive-selection-submit-btn'}`}
                onClick={() => handleOnSubmit(currentSelectionFolder?._id)}
              >
                Confirm
              </Button>
            </footer>
          </div>
        </div>
      </section>

      <div className="slds-backdrop slds-backdrop_open" />
    </>
  );
};

ArchiveSelectionModal.propTypes = {
  /**
   * Object with a selected Selection
   */
  selection: PropTypes.instanceOf(Object).isRequired,
  /**
   * Array of selection folders
   */
  folders: PropTypes.instanceOf(Object).isRequired,
  /**
   * It closes the ArchiveSelectionModal
   */
  handleCloseArchiveSelectionModal: PropTypes.func.isRequired,
  /**
   * It archives the Selection
   */
  handleArchiveSelection: PropTypes.func.isRequired,
  /**
   * Defines whether the Waterfall Selections section is selected in the Overview
   */
  isWaterfall: PropTypes.bool,
  /**
   * Defines whether the Waterfall Selections section is selected in the Overview
   */
  defaultRestoreFolderId: PropTypes.string,
  /**
   * Archived normal selection; need this to restore them when restoring a waterfall selection
   */
  archivedNotWaterfallSelections: PropTypes.instanceOf(Array),
};

export default ArchiveSelectionModal;
