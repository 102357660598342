import {
  SET_FILTER_SET_NAME,
  SET_FILTER_SET_CATEGORY,
  SET_FILTER_SET_DESCRIPTION,
  SET_FILTER_SET_SELECTED_DATA_EXTENSIONS,
  SET_FILTER_SET_SELECTED_DATA_SET,
  SET_FILTER_SET_SELECTED_FILTERS,
  SET_FILTER_SET_PICKLISTS,
  CLEAR_FILTER_SET_STATE,
  SET_FILTER_SET_SOURCE_TYPE,
  SET_FILTER_SET_SELECTED_SOURCE_DATA_EXTENSION,
} from '../types';

/**
 * Function that sets the filter set name
 * @param {string} value - new value
 * @returns {void}
 */
export const setFilterSetName = value => (dispatch) => {
  dispatch({
    type: SET_FILTER_SET_NAME,
    payload: value,
  });
};
/**
 * Function that sets the filter set category
 * @param {string} value - new value
 * @returns {void}
 */
export const setFilterSetCategoryName = value => (dispatch) => {
  dispatch({
    type: SET_FILTER_SET_CATEGORY,
    payload: value,
  });
};

/**
 * Function that sets the filter set source type
 * @param {string} value - new value
 * @returns {void}
 */
export const setFilterSetSourceType = value => (dispatch) => {
  dispatch({
    type: SET_FILTER_SET_SOURCE_TYPE,
    payload: value,
  });
};

/**
 * Function that sets the filter source de
 * @param {string} value - new value
 * @returns {void}
 */
export const setFilterSetSelectedSourceDE = value => (dispatch) => {
  dispatch({
    type: SET_FILTER_SET_SELECTED_SOURCE_DATA_EXTENSION,
    payload: value,
  });
};

/**
 * Function that sets the filter set description
 * @param {string} value - new value
 * @returns {void}
 */
export const setFilterSetDescription = value => (dispatch) => {
  dispatch({
    type: SET_FILTER_SET_DESCRIPTION,
    payload: value,
  });
};

/**
 * Function that sets the filter set selected DEs
 * @param {Array} value - new value
 * @returns {void}
 */
export const setFilterSetSelectedDEs = value => (dispatch) => {
  dispatch({
    type: SET_FILTER_SET_SELECTED_DATA_EXTENSIONS,
    payload: value,
  });
};

/**
 * Function that sets the filter set selected data set
 * @param {String} value - new value
 * @returns {void}
 */
export const setFilterSetSelectedDataSet = value => (dispatch) => {
  dispatch({
    type: SET_FILTER_SET_SELECTED_DATA_SET,
    payload: value,
  });
};

/**
 * Function that sets the filter set selected filters
 * @param {Object} value - new value
 * @returns {void}
 */
export const setFilterSetSelectedFilters = value => (dispatch) => {
  dispatch({
    type: SET_FILTER_SET_SELECTED_FILTERS,
    payload: value,
  });
};

/**
 * Function that sets the filter set picklists
 * @param {Array} value - new value
 * @returns {void}
 */
export const setFilterSetPickLists = value => (dispatch) => {
  dispatch({
    type: SET_FILTER_SET_PICKLISTS,
    payload: value,
  });
};

export const clearFilterSetState = () => (dispatch) => {
  dispatch({
    type: CLEAR_FILTER_SET_STATE,
  });
};
