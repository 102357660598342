const mapStateToProps = keys => (state) => {
  const { userInformation } = state.userInformationGlobalReducer;
  const result = {};

  keys.forEach((key) => {
    switch (key) {
      case 'userInfo':
        result.userInfo = userInformation[process.env.REACT_APP_SEGMENT__SESSION_USER_KEY];
        break;
      case 'orgInfo':
        result.orgInfo = userInformation.org;
        break;
      case 'featuresInfo':
        result.featuresInfo = userInformation.features;
        break;
      case 'justLoggedInInfo':
        result.justLoggedInInfo = userInformation.justLoggedIn;
        break;
      case 'latePaymentInfo':
        result.latePaymentInfo = userInformation.latePayment;
        break;
      case 'hasServer2ServerInstalledPackageInfo':
        result.hasServer2ServerInstalledPackageInfo = userInformation.hasServer2ServerInstalledPackage;
        break;
      case 'hasImportedRelationsPermissionsInfo':
        result.hasImportedRelationsPermissionsInfo = userInformation.hasImportedRelationsPermissions;
        break;
      case 'userInformation':
        result.userInformation = userInformation;
        break;
      default:
        break;
    }
  });

  return result;
};

export default mapStateToProps;
