import axios from 'axios';

import Util from '../util';

const apiUrl = process.env.REACT_APP_API_URL;

const WaterfallSelectionsAPI = {
  /**
   * Get all waterfall selections based on query params
   * @param {object} cancelToken - Token axios
   * @param {string} query - stringify query for API
   * @returns {array} An array with all waterfall selections
   */
  getWaterfallSelections: async (cancelToken, query) => {
    const res = await Util
      .catch418And403Error(axios.get, `${apiUrl}/waterfall-selections?${query}`, Util.apiGetCallSettings(cancelToken));

    return res.data.data;
  },

  /**
   * Get all waterfall selections that match the passed properties in query params
   * @param {object} cancelToken - Token axios
   * @param {string} query - stringify query for API
   * @returns {array} An array with all matched waterfall selections
   */
  getWaterfallSelectionsByProperty: async (cancelToken, query) => {
    const res = await Util
      .catch418And403Error(
        axios.get,
        `${apiUrl}/waterfall-selections/find?${query}`,
        Util.apiGetCallSettings(cancelToken),
      );

    return res.data.data;
  },

  /**
   * Get one waterfall selection
   * @param {string} selectionId - Id of waterfall selectionP
   * @param {object} cancelToken - Axios token
   * @returns {object} Detailed properties of a certain w.selection
   */
  getWaterfallSelection: async (selectionId, cancelToken) => {
    const res = await Util.catch418And403Error(
      axios.get,
      `${apiUrl}/waterfall-selections/${selectionId}`,
      Util.apiGetCallSettings(cancelToken),
    );

    return res.data.data;
  },

  /**
   * Save one waterfall selection
   * @param {object} postData - Data of the new waterfall selection
   * @param {string} selectionId - id of the saved waterfall selection
   * @param {object} cancelToken - Axios token
   * @returns {object} An object with the properties `selectionId` and `success`
   */
  saveWaterfallSelection: async (postData, selectionId, cancelToken) => {
    const res = await Util.catch418And403Error(
      axios.post,
      `${apiUrl}/waterfall-selections/save/${selectionId}`,
      { ...postData },
      Util.apiPostCallSettings(cancelToken),
    );

    return res.data;
  },

  /**
   * Creates copy of the waterfall selection
   * @param {object} copyParams - Waterfall Selection copy params object
   * @param {string} copyParams.selectionId - Id of waterfall selection
   * @param {string} copyParams.copyWaterfallSelectionName - Waterfall selection copy name
   * @param {boolean} copyParams.copySchedule - true if copySchedule is selected, false if not
   * @param {object} cancelToken - Axios token
   * @returns {object} An object with the properties `selectionId` and `success`
   */
  copyWaterfallSelection: async ({ selectionId, copyWaterfallSelectionName, copySchedule }, cancelToken) => {
    const postData = {
      copyWaterfallSelectionName,
      copySchedule,
    };

    const res = await Util.catch418And403Error(
      axios.post,
      `${apiUrl}/waterfall-selections/${selectionId}/copy`,
      postData,
      Util.apiPostCallSettings(cancelToken),
    );

    return res.data;
  },

  /**
   * Deletes waterfall selection
   * @param {string} selectionId - Id of waterfall selection
   * @param {object} cancelToken - Axios token
   * @returns {object} An object with the property `success`
   */
  deleteWaterfallSelection: async (selectionId, cancelToken) => {
    const res = await Util.catch418And403Error(
      axios.post,
      `${apiUrl}/waterfall-selections/${selectionId}/delete`,
      {},
      Util.apiPostCallSettings(cancelToken),
    );

    return res.data;
  },
  /**
   * Archives waterfall selection
   * @param {string} selectionId - Id of waterfall selection
   * @param {object} cancelToken - Axios token
   * @returns {object} An object with the property `success`
   */
  archiveWaterfallSelection: async (selectionId, cancelToken) => {
    const res = await Util.catch418And403Error(
      axios.post,
      `${apiUrl}/waterfall-selections/${selectionId}/archive`,
      {},
      Util.apiPostCallSettings(cancelToken),
    );

    return res.data;
  },

  /**
   * Update waterfall selection
   * @param {string} selectionId - Id of waterfall selection
   * @param {string} folderId - Id of folder
   * @param {object} cancelToken - Axios token
   * @returns {object} An object with the properties `selections` and `success`
   */
  updateWaterfallSelection: async (selectionId, folderId, cancelToken) => {
    const res = await Util.catch418And403Error(
      axios.put,
      `${apiUrl}/waterfall-selections/${selectionId}`,
      { folderId },
      Util.apiPostCallSettings(cancelToken),
    );

    return res.data;
  },

  /**
   * Archives waterfall selection or restores it based on isArchived flag
   * @param {object} params - Object containing parameters
   * @param {string} params.selectionId - Id of selection
   * @param {string} params.restoreFolderId - Id of selection
   * @param {object} params.cancelToken - Axios token
   * @returns {object} An object with the property `success`
   */
  archiveOrRestoreWaterfallSelection: async ({ selectionId, restoreFolderId, cancelToken }) => {
    const res = await Util.catch418And403Error(
      axios.post,
      `${apiUrl}/waterfall-selections/${selectionId}/archive-or-restore`,
      { restoreFolderId },
      Util.apiPostCallSettings(cancelToken),
    );

    return res.data;
  },
};

export default WaterfallSelectionsAPI;
