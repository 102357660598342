import tokenize from './Tokenizer';
import parse from './Parser';
import { freeFormulaItems as functionsDetails, keywords } from '../FreeFormula';
import ValidationConstants from './ValidationConstants';

const rules = {
  function: {
    before: [
      ValidationConstants.TOKEN_TYPE__OPEN_PAREN,
      ValidationConstants.TOKEN_TYPE__OPERATOR,
      ValidationConstants.TOKEN_TYPE__CLOSED_PAREN,
      ValidationConstants.TOKEN_TYPE__DELIMITER,
      ValidationConstants.TOKEN_TYPE__COMPARISON_OPERATOR,
    ],
    after: [ValidationConstants.TOKEN_TYPE__OPEN_PAREN],
  },
  [ValidationConstants.TOKEN_TYPE__OPEN_PAREN]: {
    before: [
      ValidationConstants.TOKEN_TYPE__OPERATOR,
      ValidationConstants.TOKEN_TYPE__FUNCTION,
      ValidationConstants.TOKEN_TYPE__OPEN_PAREN,
      ValidationConstants.TOKEN_TYPE__CLOSED_PAREN,
      ValidationConstants.TOKEN_TYPE__OPERATOR,
      ValidationConstants.TOKEN_TYPE__DELIMITER,
      ValidationConstants.TOKEN_TYPE__COMPARISON_OPERATOR,
    ],
    after: [
      ValidationConstants.TOKEN_TYPE__STRING,
      ValidationConstants.TOKEN_TYPE__FIELD,
      ValidationConstants.TOKEN_TYPE__NUMBER,
      ValidationConstants.TOKEN_TYPE__DECIMAL,
      ValidationConstants.TOKEN_TYPE__FUNCTION,
      ValidationConstants.TOKEN_TYPE__OPEN_PAREN,
      ValidationConstants.TOKEN_TYPE__CLOSED_PAREN,
      ValidationConstants.TOKEN_TYPE__KEYWORD,
      ValidationConstants.TOKEN_TYPE__OPERATOR,
    ],
  },
  [ValidationConstants.TOKEN_TYPE__CLOSED_PAREN]: {
    before: [
      ValidationConstants.TOKEN_TYPE__STRING,
      ValidationConstants.TOKEN_TYPE__FIELD,
      ValidationConstants.TOKEN_TYPE__NUMBER,
      ValidationConstants.TOKEN_TYPE__DECIMAL,
      ValidationConstants.TOKEN_TYPE__CLOSED_PAREN,
      ValidationConstants.TOKEN_TYPE__OPEN_PAREN,
      ValidationConstants.TOKEN_TYPE__KEYWORD,
    ],
    after: [
      ValidationConstants.TOKEN_TYPE__OPERATOR,
      ValidationConstants.TOKEN_TYPE__CLOSED_PAREN,
      ValidationConstants.TOKEN_TYPE__DELIMITER,
      ValidationConstants.TOKEN_TYPE__COMPARISON_OPERATOR,
    ],
  },
  string: {
    before: [
      ValidationConstants.TOKEN_TYPE__OPEN_PAREN,
      ValidationConstants.TOKEN_TYPE__OPERATOR,
      ValidationConstants.TOKEN_TYPE__DELIMITER,
      ValidationConstants.TOKEN_TYPE__COMPARISON_OPERATOR,
    ],
    after: [
      ValidationConstants.TOKEN_TYPE__CLOSED_PAREN,
      ValidationConstants.TOKEN_TYPE__OPERATOR,
      ValidationConstants.TOKEN_TYPE__DELIMITER,
      ValidationConstants.TOKEN_TYPE__COMPARISON_OPERATOR,
    ],
  },
  field: {
    before: [
      ValidationConstants.TOKEN_TYPE__OPEN_PAREN,
      ValidationConstants.TOKEN_TYPE__OPERATOR,
      ValidationConstants.TOKEN_TYPE__DELIMITER,
      ValidationConstants.TOKEN_TYPE__COMPARISON_OPERATOR,
    ],
    after: [
      ValidationConstants.TOKEN_TYPE__CLOSED_PAREN,
      ValidationConstants.TOKEN_TYPE__OPERATOR,
      ValidationConstants.TOKEN_TYPE__DELIMITER,
      ValidationConstants.TOKEN_TYPE__COMPARISON_OPERATOR,
    ],
  },
  number: {
    before: [
      ValidationConstants.TOKEN_TYPE__OPEN_PAREN,
      ValidationConstants.TOKEN_TYPE__OPERATOR,
      ValidationConstants.TOKEN_TYPE__DELIMITER,
      ValidationConstants.TOKEN_TYPE__COMPARISON_OPERATOR,
    ],
    after: [
      ValidationConstants.TOKEN_TYPE__CLOSED_PAREN,
      ValidationConstants.TOKEN_TYPE__OPERATOR,
      ValidationConstants.TOKEN_TYPE__DELIMITER,
      ValidationConstants.TOKEN_TYPE__COMPARISON_OPERATOR,
    ],
  },
  decimal: {
    before: [
      ValidationConstants.TOKEN_TYPE__OPEN_PAREN,
      ValidationConstants.TOKEN_TYPE__OPERATOR,
      ValidationConstants.TOKEN_TYPE__DELIMITER,
      ValidationConstants.TOKEN_TYPE__COMPARISON_OPERATOR,
    ],
    after: [
      ValidationConstants.TOKEN_TYPE__CLOSED_PAREN,
      ValidationConstants.TOKEN_TYPE__OPERATOR,
      ValidationConstants.TOKEN_TYPE__DELIMITER,
      ValidationConstants.TOKEN_TYPE__COMPARISON_OPERATOR,
    ],
  },
  operator: {
    before: [
      ValidationConstants.TOKEN_TYPE__OPEN_PAREN,
      ValidationConstants.TOKEN_TYPE__CLOSED_PAREN,
      ValidationConstants.TOKEN_TYPE__STRING,
      ValidationConstants.TOKEN_TYPE__FIELD,
      ValidationConstants.TOKEN_TYPE__NUMBER,
      ValidationConstants.TOKEN_TYPE__DECIMAL,
      ValidationConstants.TOKEN_TYPE__OPERATOR,
    ],
    after: [
      ValidationConstants.TOKEN_TYPE__STRING,
      ValidationConstants.TOKEN_TYPE__FIELD,
      ValidationConstants.TOKEN_TYPE__NUMBER,
      ValidationConstants.TOKEN_TYPE__DECIMAL,
      ValidationConstants.TOKEN_TYPE__OPEN_PAREN,
      ValidationConstants.TOKEN_TYPE__FUNCTION,
      ValidationConstants.TOKEN_TYPE__OPERATOR,
    ],
  },
  delimiter: {
    before: [
      ValidationConstants.TOKEN_TYPE__STRING,
      ValidationConstants.TOKEN_TYPE__FIELD,
      ValidationConstants.TOKEN_TYPE__NUMBER,
      ValidationConstants.TOKEN_TYPE__DECIMAL,
      ValidationConstants.TOKEN_TYPE__KEYWORD,
      ValidationConstants.TOKEN_TYPE__CLOSED_PAREN,
    ],
    after: [
      ValidationConstants.TOKEN_TYPE__OPEN_PAREN,
      ValidationConstants.TOKEN_TYPE__STRING,
      ValidationConstants.TOKEN_TYPE__FIELD,
      ValidationConstants.TOKEN_TYPE__NUMBER,
      ValidationConstants.TOKEN_TYPE__DECIMAL,
      ValidationConstants.TOKEN_TYPE__KEYWORD,
      ValidationConstants.TOKEN_TYPE__FUNCTION,
    ],
  },
  keyword: {
    before: [ValidationConstants.TOKEN_TYPE__DELIMITER, ValidationConstants.TOKEN_TYPE__OPEN_PAREN],
    after: [ValidationConstants.TOKEN_TYPE__DELIMITER, ValidationConstants.TOKEN_TYPE__CLOSED_PAREN],
  },
  [ValidationConstants.TOKEN_TYPE__COMPARISON_OPERATOR]: {
    before: [
      ValidationConstants.ROOT_NODE__PROGRAM,
      ValidationConstants.TOKEN_TYPE__DECIMAL,
      ValidationConstants.TOKEN_TYPE__NUMBER,
      ValidationConstants.TOKEN_TYPE__STRING,
      ValidationConstants.TOKEN_TYPE__FUNCTION,
      ValidationConstants.TOKEN_TYPE__FIELD,
      ValidationConstants.TOKEN_TYPE__CLOSED_PAREN,
    ],
    after: [
      ValidationConstants.ROOT_NODE__PROGRAM,
      ValidationConstants.TOKEN_TYPE__DECIMAL,
      ValidationConstants.TOKEN_TYPE__NUMBER,
      ValidationConstants.TOKEN_TYPE__STRING,
      ValidationConstants.TOKEN_TYPE__FUNCTION,
      ValidationConstants.TOKEN_TYPE__FIELD,
      ValidationConstants.TOKEN_TYPE__OPEN_PAREN,
    ],
  },
};

const caseWhenStatementPositionRules = {
  [ValidationConstants.CASE_KEYWORD]: {
    before: [],
    after: [ValidationConstants.WHEN_STATEMENT],
  },
  [ValidationConstants.WHEN_STATEMENT]: {
    before: [ValidationConstants.CASE_KEYWORD, ValidationConstants.THEN_STATEMENT],
    after: [ValidationConstants.THEN_STATEMENT],
  },
  [ValidationConstants.THEN_STATEMENT]: {
    before: [ValidationConstants.WHEN_STATEMENT],
    after: [
      ValidationConstants.WHEN_STATEMENT,
      ValidationConstants.ELSE_STATEMENT,
      ValidationConstants.END_KEYWORD,
    ],
  },
  [ValidationConstants.ELSE_STATEMENT]: {
    before: [ValidationConstants.THEN_STATEMENT],
    after: [ValidationConstants.END_KEYWORD],
  },
  [ValidationConstants.END_KEYWORD]: {
    before: [ValidationConstants.THEN_STATEMENT, ValidationConstants.ELSE_STATEMENT],
    after: [],
  },
};

// Skip the validation of the positions of these tokens
const tokensToSkipValidatingPositions = [
  ValidationConstants.KEYWORD__WHEN,
  ValidationConstants.KEYWORD__END,
  ValidationConstants.KEYWORD__CASE,
  ValidationConstants.KEYWORD__THEN,
  ValidationConstants.KEYWORD__LIKE,
  ValidationConstants.KEYWORD__ELSE,
];

/**
 * Extracts braces from the the tokens array for validation
 * @param {array} tokens - An array of tokens
 * @returns {array} An array consisting of open and closed brackets
 */
const extractBraces = (tokens) => {
  // Declare braces
  const braces = [];

  // Loop over tokens and push open or closed brackets to the braces array
  tokens.forEach((token) => {
    if (token.type === ValidationConstants.TOKEN_TYPE__OPEN_PAREN ||
      token.type === ValidationConstants.TOKEN_TYPE__CLOSED_PAREN) {
      braces.push(token.value);
    }
  });

  return braces;
};

/**
 * Validates braces
 * @param {array} tokens - An array of tokens
 * @returns {object} - An object containing status and success/error message
 */
const checkBraces = (tokens) => {
  // Extract braces from tokens array
  const braces = extractBraces(tokens);
  const openStack = [];

  // Loop through the braces array
  for (let i = 0; i < braces.length; i += 1) {
    if (braces[i] === ')') {
      // Pop the stack if it's not empty
      const character = openStack.length ? openStack.pop() : '#';

      /*
       * Return an error message since the stack is empty (we have no open bracket)
       * and we just encountered a closed-bracket
       */
      if (character === '#') {
        return { success: false, error: 'Syntax error. Extra \')\'' };
      }
    } else {
      // Push the open-bracket to the top of the stack
      openStack.push(braces[i]);
    }
  }

  /*
   * If we're done looping over the braces array and we still have extra
   * open-brackets, return error, otherwise, return success
   */
  return openStack.length ?
    { success: false, error: 'Syntax error. Extra \'(\'' } :
    { success: true };
};

/**
 * Validates token positions
 * @param {array} tokens - Array of tokens
 * @param {number} tokenIndex - The index of the token to validate
 * @returns {object} - An object containing the status and success/error message
 */
const validateTokenPosition = (tokens, tokenIndex) => {
  // Declare current, preceding and succeeding tokens
  const token = tokens[tokenIndex];
  const precedingToken = tokenIndex - 1 < 0 ?
    null :
    tokens[tokenIndex - 1];
  const succeedingToken = tokenIndex + 1 > tokens.length - 1 ?
    null :
    tokens[tokenIndex + 1];

  // Declare token validity flags
  let isPrecedingTokenValid = true;

  let isSucceedingTokenValid = true;

  const validationResult = { success: true };

  // * and / should not start/end the formula
  if ((!precedingToken || !succeedingToken) &&
    token.type === ValidationConstants.TOKEN_TYPE__OPERATOR &&
    (token.value === '/' || token.value === '*')
  ) {
    validationResult.success = false;
    validationResult.error = `Incomplete expression with ${token.value} operator`;

    return validationResult;
  }

  // If precedingToken exists
  if (precedingToken && !tokensToSkipValidatingPositions.includes(precedingToken.value)) {
    // allow to use string expressions (e.g. single quotes)
    if (precedingToken.type === ValidationConstants.TOKEN_TYPE__STRING &&
      token.type === ValidationConstants.TOKEN_TYPE__STRING) {
      isPrecedingTokenValid = true;
      validationResult.success = true;

      return validationResult;
    }

    // Validate token
    isPrecedingTokenValid = rules[token.type]?.before.includes(precedingToken.type);

    // Return an error object if the precedingToken is invalid
    if (!isPrecedingTokenValid) {
      validationResult.success = false;
      validationResult.error = `"${token.value}" cannot be preceded by
        "${precedingToken.value}"`;

      return validationResult;
    }
  }

  // If succeedingToken exists
  if (succeedingToken && !tokensToSkipValidatingPositions.includes(succeedingToken.value)) {
    // allow to use string expressions (e.g. single quotes)
    if (succeedingToken.type === ValidationConstants.TOKEN_TYPE__STRING &&
      token.type === ValidationConstants.TOKEN_TYPE__STRING) {
      isSucceedingTokenValid = true;
      validationResult.success = true;

      return validationResult;
    }

    // Validate token
    isSucceedingTokenValid = rules[token.type]?.after.includes(succeedingToken.type);

    // Return an error object if the succeedingToken is invalid
    if (!isSucceedingTokenValid) {
      validationResult.success = false;

      validationResult.error = `"${token.value}" cannot be succeeded by
        "${succeedingToken.value}"`;

      return validationResult;
    }
  }

  return validationResult;
};

/**
 * Validates tokens positions
 * @param {array} tokens - Array of tokens
 * @returns {object} - An object containing the status and success/error message
 */
const validateTokenPositions = (tokens) => {
  // Loop over the tokens array
  for (let i = 0; i < tokens.length; i += 1) {
    // Validate token's position if it's not in the tokensToSkipValidatingPositions
    if (!tokensToSkipValidatingPositions.includes(tokens[i].value)) {
      // Validate token
      const result = validateTokenPosition(tokens, i);

      // If validation failed, return error object
      if (!result.success) {
        return result;
      }
    }
  }

  return { success: true };
};

/**
 * Validates functions' names
 * @param {string} functionName - The name of the function to validate
 * @returns {object} - An object containing the status and success/error message
 */
const isFunctionRecognized = (functionName) => {
  const { functions } = functionsDetails;

  // Return success object if function is recognized
  if (functions.find(func => func.name.toLowerCase() === functionName.toLowerCase())) {
    return { success: true };
  }

  // Return error object if function is not recognized
  return { success: false, error: `Unknown function ${functionName}. Check spelling.` };
};

/**
 * Validates keywords
 * @param {string} keyword - The value of the keyword to validate
 * @returns {object} - An object containing the status and success/error message
 */
const isKeywordRecognized = (keyword) => {
  // Return success object if keyword is recognized
  if (keywords.find(key => key.toLowerCase() === keyword.toLowerCase())) {
    return { success: true };
  }

  // Return error object if keyword is not recognized
  return { success: false };
};

/**
 * Validates keywords
 * @param {array} tokens - An array of tokens
 * @returns {object} - - An object containing the status and success/error message
 */
const validateKeyword = (tokens) => {
  // Extract keywords from tokens array
  const keywordsList = tokens.filter(token => token.type === ValidationConstants.TOKEN_TYPE__KEYWORD);

  for (let i = 0; i < keywordsList.length; i += 1) {
    let isKeyWordFound;

    if (keywordsList[i].value) {
      isKeyWordFound = !!keywords.find(key => key.toLowerCase() === keywordsList[i].value.toLowerCase());
    }
    if (!isKeyWordFound) return { success: false, error: `Unknown keyword ${keywordsList[i].value}. Check spelling.` };
  }

  return { success: true };
};

/**
 * Validates the functions
 * @param {object} functionToValidate - An object containing the properties of the function
 * @returns {object} - An object containing the status and success/error message
 */
const validateFunction = (functionToValidate) => {
  const { functions } = functionsDetails;

  // Get the function's rules
  const functionRules = functions.find(func => func.name.toLowerCase() === functionToValidate.name.toLowerCase());

  const validationResult = { success: true };

  /*
   * Check no. of parameters
   * expectedParameters are parameters from free formulas array
   */
  const {
    numberOfParams, parameters: expectedParameters, specialParams, specialParamsIndices, allowedParVal = {},
    containsNParams,
  } = functionRules;

  // received receivedParams are function params we've put in the textBox
  const { params: receivedParams } = functionToValidate;
  const { min, max } = numberOfParams;

  /**
   * Validate number of parameters
   * @returns {object} - An object containing the status and success/error message
   */
  const validateNumberOfParams = () => {
    // set numberOfParameters that we put into the function
    const numberOfParameters = receivedParams.length;

    let errorMessage = functionToValidate.name + ' expects ' + min +
       ` parameter${min === 1 ? '' : 's'}, but ` + numberOfParameters + ' given';

    /*
     * If the number of params a function can have is between
     * two numbers, build a different error message
     */
    if (min !== max) {
      errorMessage = functionToValidate.name + ' expects parameters between ' + min +
      ' and ' + max + ', but ' + numberOfParameters + ' given';
    }

    validationResult.success = false;
    validationResult.error = errorMessage;

    return validationResult;
  };

  // Check if a function is given the appropriate number of parameters
  if ((min > receivedParams.length || max < receivedParams.length) &&
  (receivedParams[0] && !receivedParams[0].body)) {
    validateNumberOfParams();
  }

  /*
   * When receivedParams has a body, check if a function is given the appropriate number of parameters,
   * the case is when we join strings or use single quotes in the string
   */
  if (receivedParams[0] && receivedParams[0].body && (min > receivedParams.length || max < receivedParams.length) &&
  (min > (receivedParams[0].body.length + receivedParams.length - 1) ||
  max < (receivedParams[0].body.length + receivedParams.length - 1))) {
    validateNumberOfParams();
  }

  // error message set to the empty value
  let errorMessage = '';

  // Check if param types match
  if (expectedParameters.length && receivedParams.length) {
    let expectedParamsIndex = 0;

    for (let j = 0; j < receivedParams.length; j += 1) {
      if (!containsNParams) {
        expectedParamsIndex = j;
      }

      const isField = receivedParams[j].type === 'field';

      // define isFieldTypeValid - validate fieldType only if received param is a field
      let isFieldTypeValid;

      // check if expectedParameters[expectedParamsIndex] exists to avoid errors
      if (expectedParameters[expectedParamsIndex] && isField) {
        isFieldTypeValid = expectedParameters[expectedParamsIndex]
          .includes(receivedParams[j].fieldType);
      } else {
        isFieldTypeValid = !isField;
      }

      // define isParamTypeValid - validate paramType only if receivedParam is not a field
      let isParamTypeValid;

      // check if expectedParameters[expectedParamsIndex] exists to avoid errors
      if (expectedParameters[expectedParamsIndex] && !isField) {
        isParamTypeValid = expectedParameters[expectedParamsIndex].includes(receivedParams[j].type);
      } else {
        isParamTypeValid = isField;
      }

      // displays error if function parameter or field has different type
      if (receivedParams[j].type !== ValidationConstants.ROOT_NODE__PROGRAM &&
        receivedParams[j].type !== ValidationConstants.TOKEN_TYPE__FUNCTION && (
        expectedParameters[expectedParamsIndex] &&
          !expectedParameters[expectedParamsIndex].includes(ValidationConstants.PARAM_TYPE__ANY)) &&
        (!isFieldTypeValid || !isParamTypeValid)
      ) {
        // check if it is a parameter or field
        const validationType = (!isField || isFieldTypeValid) ? 'parameter' : 'field';

        // specify type for parameter or fieldType for field
        const type = (!isField || isFieldTypeValid) ? receivedParams[j].type : receivedParams[j].fieldType;

        // show an error
        // eslint-disable-next-line max-len
        errorMessage = `${functionToValidate.name} does not accept a ${validationType} of type ${type} at position ${j + 1}`;
      }

      // check if param types match when we join strings or use single quotes in the string
      if (receivedParams[j].body && receivedParams[j].body.length) {
        let expectedParamsIndexBody = j;

        // check if it is the last parameter
        const checkNextReceivedParams = receivedParams[j + 1];

        // loop all over the body
        for (let i = 0; i < receivedParams[j].body.length; i += 1) {
          const receivedParamBodyType = receivedParams[j].body;

          const isFieldBody = receivedParamBodyType[i].type === 'field';

          // check what index we are looking for in the expected parameters
          if (expectedParameters.length === receivedParams[j].length || checkNextReceivedParams) {
            expectedParamsIndexBody = j;
          } else {
            expectedParamsIndexBody = i;
          }

          // variables for validate - depending on what param we received (field or parameter)
          let isParamTypeValidBody;

          let isFieldTypeValidBody;

          // check if expectedParameters[expectedParamsIndexBody] exists to avoid errors
          if (expectedParameters[expectedParamsIndexBody] && isFieldBody) {
            // is param is a field then check what fieldType it is
            isFieldTypeValidBody = expectedParameters[expectedParamsIndexBody]
              .includes(receivedParamBodyType[i].fieldType);
          } else {
            isFieldTypeValidBody = !isFieldBody;
          }

          // check if expectedParameters[expectedParamsIndexBody] exists to avoid errors
          if (expectedParameters[expectedParamsIndexBody] && !isFieldBody) {
            // is param is a parameter then check what type it is
            isParamTypeValidBody = expectedParameters[expectedParamsIndexBody].includes(receivedParamBodyType[i].type);
          } else {
            isParamTypeValidBody = isFieldBody;
          }

          // displays error if function parameter or field has different type
          if (receivedParamBodyType[i].type !== ValidationConstants.TOKEN_TYPE__FUNCTION &&
            (!isParamTypeValidBody || !isFieldTypeValidBody) && receivedParamBodyType[i].type !==
            ValidationConstants.TOKEN_TYPE__OPERATOR && (expectedParameters[expectedParamsIndexBody] &&
              !expectedParameters[expectedParamsIndexBody].includes(ValidationConstants.PARAM_TYPE__ANY)) &&
            receivedParamBodyType[i].type !== ValidationConstants.ROOT_NODE__PROGRAM) {
            // Validate fieldType only if received param is a field
            const validationType = (!isFieldBody || isFieldTypeValidBody) ? 'parameter' : 'field';

            // specify type for parameter or fieldType for field
            // eslint-disable-next-line multiline-ternary
            const type = (!isFieldBody || isFieldTypeValidBody) ? receivedParamBodyType[i].type :
              receivedParamBodyType[i].fieldType;

            // show an error
            // eslint-disable-next-line max-len
            errorMessage = `${functionToValidate.name} does not accept a ${validationType} of type ${type} at position ${expectedParamsIndexBody + 1}`;
          }
        }
      }

      /*
       * if the given function contains defined allowedParVal, check for the allowedParVal values
       * if a value of the given function is not inside the allowedParVal values, show an error
       */
      if (Object.keys(allowedParVal).length && allowedParVal.minValue &&
        receivedParams[j].value < allowedParVal.minValue
      ) {
        errorMessage = `${functionToValidate.name} does not accept a parameter less than ${allowedParVal.minValue}`;
      } else if (Object.keys(allowedParVal).length && allowedParVal.maxValue &&
        receivedParams[j].value > allowedParVal.maxValue
      ) {
        errorMessage = `${functionToValidate.name} does not accept a parameter greater than ${allowedParVal.maxValue}`;
      }

      // if an errors message is not empty, return error message
      if (errorMessage.length > 0) {
        validationResult.success = false;
        validationResult.error = errorMessage;

        return validationResult;
      }
    }
  }

  // Validate param value
  if (specialParams && specialParams.length) {
    for (let i = 0; i < specialParamsIndices.length; i += 1) {
      // Index where the special param is situated
      const idx = specialParamsIndices[i];

      // If param is not accepted in the current index
      if (receivedParams[idx] && receivedParams[idx].value &&
        !specialParams[idx].includes(receivedParams[idx].value.toLowerCase())) {
        // Declare error message and return the error object
        errorMessage = `${functionToValidate.name} does not accept a parameter with value
          ${receivedParams[idx].value} at position ${idx + 1}`;
        validationResult.success = false;
        validationResult.error = errorMessage;

        return validationResult;
      }
    }
  }

  // Validate nested params
  for (let i = 0; i < receivedParams.length; i += 1) {
    if (receivedParams[i].type === ValidationConstants.ROOT_NODE__PROGRAM ||
      receivedParams[i].type === ValidationConstants.TOKEN_TYPE__FUNCTION
    ) {
      // eslint-disable-next-line no-use-before-define
      const result = validateFunctionArguments(receivedParams[i]);

      if (!result.success) {
        return result;
      }
    }
  }

  return validationResult;
};

/**
 * Validates a condition
 * @param {object} condition - The condition to validate
 * @return {object} An object containing the status and success/error message
 */
const validateCondition = (condition) => {
  const { rightSide, leftSide } = condition.value;

  // Validate left side of the condition if it's a function
  if (leftSide.type && leftSide.type === ValidationConstants.TOKEN_TYPE__FUNCTION) {
    const functionValidationResult = validateFunction(leftSide);

    // If validation failed
    if (!functionValidationResult.success) return functionValidationResult;
  }

  // Validate right side of the condition if it's a function
  if (rightSide.type && rightSide.type === ValidationConstants.TOKEN_TYPE__FUNCTION) {
    const functionValidationResult = validateFunction(rightSide);

    // If validation failed
    if (!functionValidationResult.success) return functionValidationResult;
  }

  return { success: true };
};

/**
 * Validates a when statement
 * @param {object} whenStatement - The parsed when statement to validate
 * @return {object} An object containing the status and success/error message
 */
const validateWhenStatement = (whenStatement) => {
  // Make sure the when statement has just two parts; the when part and the condition part
  if (whenStatement.length !== 2) {
    return { success: false, error: 'When-statement must have 2 parts' };
  }

  // Make sure a when statement is started by a when keyword
  const whenKeyword = whenStatement[0];

  if (whenKeyword && whenKeyword.value !== ValidationConstants.KEYWORD__WHEN) {
    return {
      success: false,
      error: `When-statement must start with the '${ValidationConstants.KEYWORD__WHEN}' keyword`,
    };
  }

  // Make sure a when statement contains a condition part
  const conditionPart = whenStatement[1];

  if (conditionPart && conditionPart.type !== 'condition') {
    return { success: false, error: 'When-keyword must be succeeded by a condition statement' };
  }

  // Validate condition
  const conditionValidationResult = validateCondition(conditionPart);

  // If validation failed
  if (!conditionValidationResult.success) return conditionValidationResult;

  return { success: true };
};

/**
 * Validates a then or else statement
 * @param {object} statement - The parsed then or else statement to validate
 * @param {string}  statementType - The type of statement to validate
 * @return {object} An object containing the status and success/error message
 */
const validateThenOrElseStatement = (statement, statementType) => {
  let statementName;

  let keywordType;

  if (statementType === ValidationConstants.THEN_STATEMENT) {
    statementName = 'Then-statement';
    keywordType = ValidationConstants.KEYWORD__THEN;
  } else {
    statementName = 'Else statement';
    keywordType = ValidationConstants.KEYWORD__ELSE;
  }
  // Make sure the then statement has just two parts; the then part and the result part
  if (statement.length !== 2) {
    return { success: false, error: `${statementName} must have 2 parts` };
  }

  // Make sure a then or else statement is started by the appropriate keyword
  const keyword = statement[0];

  if (keyword && keyword.value !== keywordType) {
    return {
      success: false,
      error: `${statementName} must start with the '${keyword}' keyword`,
    };
  }

  const resultPart = statement[1];

  if (resultPart && resultPart.type !== ValidationConstants.RESULT_STATEMENT) {
    return {
      success: false,
      error: `${statementName} must be succeeded by a result statement`,
    };
  }

  const resultStatement = resultPart.value;

  if (resultStatement.type === ValidationConstants.TOKEN_TYPE__FUNCTION) {
    const functionValidationResult = validateFunction(resultStatement);

    if (!functionValidationResult.success) return functionValidationResult;
  }

  return { success: true };
};

/**
 * Validates positions in a case-when statement
 * @param {array} caseStatement - The parsed case statement to validate
 * @param {number} index - The current index
 * @returns {object} - An object containing the status and success/error message
 */
const validateCaseWhenPosition = (caseStatement, index) => {
  // Declare current, preceding and succeeding items
  const currentItem = caseStatement[index];
  const precedingItem = index - 1 < 0 ?
    null :
    caseStatement[index - 1];
  const succeedingItem = index + 1 > caseStatement.length - 1 ?
    null :
    caseStatement[index + 1];

  const validationResult = {};

  // Declare item validity flags
  let isPrecedingItemValid = true;

  let isSucceedingItemValid = true;

  if (precedingItem) {
    isPrecedingItemValid = caseWhenStatementPositionRules[currentItem.type]?.before.includes(precedingItem.type);

    // Return an error object if the precedingItem is invalid
    if (!isPrecedingItemValid) {
      validationResult.success = false;
      validationResult.error = `${currentItem.type} cannot be preceded by
        ${precedingItem.type}`;

      return validationResult;
    }
  }

  if (succeedingItem) {
    isSucceedingItemValid = caseWhenStatementPositionRules[currentItem.type]?.after.includes(succeedingItem.type);

    // Return an error object if the succeedingItem is invalid
    if (!isSucceedingItemValid) {
      validationResult.success = false;
      validationResult.error = `${currentItem.type} cannot be succeeded by
        ${succeedingItem.type}`;

      return validationResult;
    }
  }

  return { success: true };
};

/**
 * Validates positions in a case-when statement
 * @param {array} caseStatement - The parsed case statement to validate
 * @returns {object} - An object containing the status and success/error message
 */
const validateCaseWhenPositions = (caseStatement) => {
  for (let i = 0; i < caseStatement.length; i += 1) {
    // Validate positions of items
    const positionValidationResult = validateCaseWhenPosition(caseStatement, i);

    // If validation fails
    if (!positionValidationResult.success) return positionValidationResult;
  }

  return { success: true };
};

/**
 * Validates a case statement
 * @param {array} caseStatement - The parsed case statement to validate
 * @return {object} An object containing the status and success/error message
 */
const validateCaseWhenStatement = (caseStatement) => {
  const firstKeyword = caseStatement[0];

  if (firstKeyword && firstKeyword.value !== ValidationConstants.KEYWORD__CASE) {
    return { success: false, error: `Case-statement must start with the ${ValidationConstants.KEYWORD__CASE} keyword` };
  }

  // Get last keyword
  const lastIndex = caseStatement.length - 1;
  const lastKeyword = caseStatement[lastIndex];

  // If last keyword is not end
  if (lastKeyword && lastKeyword.value !== ValidationConstants.KEYWORD__END) {
    return { success: false, error: `Case-statement must end with the ${ValidationConstants.KEYWORD__END} keyword` };
  }

  // Validate individual statements
  for (let i = 1; i < caseStatement.length - 1; i += 1) {
    // If statement type is when
    if (caseStatement[i].type === ValidationConstants.WHEN_STATEMENT) {
      // Validate when statement
      const whenStatementValidationResult = validateWhenStatement(caseStatement[i].value);

      // If validation fails
      if (!whenStatementValidationResult.success) return whenStatementValidationResult;
    } else if (caseStatement[i].type === ValidationConstants.THEN_STATEMENT ||
      caseStatement[i].type === ValidationConstants.ELSE_STATEMENT
    ) {
      // Validate then or else statement
      const thenOrElseStatementValidationResult = validateThenOrElseStatement(
        caseStatement[i].value,
        caseStatement[i].type,
      );

      // If validation fails
      if (!thenOrElseStatementValidationResult.success) return thenOrElseStatementValidationResult;
    }
  }

  // Validate positions
  const positionValidationResult = validateCaseWhenPositions(caseStatement);

  // If validation failed
  if (!positionValidationResult.success) return positionValidationResult;

  return { success: true };
};

/**
 * Validates the functions arguments
 * @param {object} parsedInput - An object containing the parsed input
 * @returns {object} - An object containing the status and success/error message
 */
const validateFunctionArguments = (parsedInput) => {
  const { type } = parsedInput;

  let result;

  // If the type of the input is program
  if (type === ValidationConstants.ROOT_NODE__PROGRAM) {
    const { body } = parsedInput;

    // Loop over the body array
    for (let i = 0; i < body.length; i += 1) {
      const currentItem = body[i];

      // If the type of the input is program
      if (body.type === ValidationConstants.ROOT_NODE__PROGRAM) {
        // Validate function (Recursively)
        result = validateFunctionArguments(currentItem);

        // If validation failed
        if (!result.success) {
          return result;
        }
      } else if (currentItem.type === ValidationConstants.TOKEN_TYPE__FUNCTION) {
        // Validate current item
        const functionRecognitionValidationResult = isFunctionRecognized(currentItem.name);

        // If validation failed
        if (!functionRecognitionValidationResult.success) return functionRecognitionValidationResult;

        // Validate
        const functionValidationResult = validateFunction(currentItem);

        // If validation failed
        if (!functionValidationResult.success) return functionValidationResult;
      } else if (currentItem.type === ValidationConstants.CASE_WHEN) {
        // If currentItem is case-when

        // Validate case-when
        const caseWhenValidationResult = validateCaseWhenStatement(currentItem.value);

        // If validation fails
        if (!caseWhenValidationResult.success) return caseWhenValidationResult;
      }
    }
  } else if (type === ValidationConstants.TOKEN_TYPE__FUNCTION) {
    // Validate
    const functionRecognitionValidationResult = isFunctionRecognized(parsedInput.name);

    // If validation failed
    if (!functionRecognitionValidationResult.success) return functionRecognitionValidationResult;

    // Validate
    result = validateFunction(parsedInput);

    // If validation failed
    if (!result.success) {
      return result;
    }
  }

  return { success: true };
};

/**
 * Validates quotes
 * @param {string} formula - The inputed formula
 * @returns {object} - An object containing the status and success/error message
 */
const validateQuotes = (formula) => {
  // counter for single quotes
  let countSingleQuotes = 0;

  // counter for double quotes
  let countDoubleQuotes = 0;

  for (let i = 0; i < formula.length; i += 1) {
    // if single quote is found
    if (formula[i] === '\'') {
      // increase the counter for single quotes
      countSingleQuotes += 1;

      // set new iteration counter to the next character (i + 1)
      let j = i + 1;

      // loop through array until you find another single quote or until array's length
      while (countSingleQuotes % 2 !== 0 && j < formula.length) {
        if (formula[j] === '\'') {
          // increase the counter for single quotes if the single quote is found
          countSingleQuotes += 1;
        }

        // increasing the iteration counter
        j += 1;
      }

      /*
       * set original iteration counter (of the for loop) to the previous character
       * reason for this is because 'for' loop will increase its iteration counter
       * on the end of the current iteration
       */
      i = j - 1;
      // otherwise check if double quote is found
    } else if (formula[i] === '"') {
      // increase the counter for double quotes
      countDoubleQuotes += 1;

      // set new iteration counter to the next character (i + 1)
      let j = i + 1;

      // loop through array until you find another double quote or until array's length
      while (countDoubleQuotes % 2 !== 0 && j < formula.length) {
        if (formula[j] === '"') {
          // increase the counter for double quotes if the double quote is found
          countDoubleQuotes += 1;
        }

        // increasing the iteration counter
        j += 1;
      }

      /*
       * set original iteration counter (of the for loop) to the previous character
       * reason for this is because 'for' loop will increase its iteration counter
       * on the end of the current iteration
       */
      i = j - 1;
    }
  }

  // Check if the number of single quotes is even
  if (countSingleQuotes % 2 === 1) return { success: false, error: '\' is missing.' };

  // Check if the number of double quotes is even
  if (countDoubleQuotes % 2 === 1) return { success: false, error: '" is missing.' };

  return { success: true };
};

/**
 * Validates comparison operators
 * @param {array} tokens - An array of tokens
 * @returns {object} - An object containing the status and success/error message
 */
const validateComparisonOperators = (tokens) => {
  // Extract comparison operators
  const comparisonOperators = tokens.filter(token => token.type ===
    ValidationConstants.TOKEN_TYPE__COMPARISON_OPERATOR);

  // Validate comparison operators
  const VALID_COMPARISON_OPERATORS = [
    '=', '>', '<', '>=', '<=', '!=', 'like',
  ];

  for (let i = 0; i < comparisonOperators.length; i += 1) {
    const operator = comparisonOperators[i].value;

    if (!VALID_COMPARISON_OPERATORS.includes(operator)) {
      return { success: false, error: `${operator} is not a valid comparison operator.` };
    }
  }

  return { success: true };
};

/**
 * @param {string} formula - The formula inputed by the user
 * @param {array} selectedDataExtensions - An array of selected DEs
 * @returns {object} - An object containing the status and success/error message
 */
const validate = (formula, selectedDataExtensions) => {
  // Tokenize the formula
  const tokenizedFormula = tokenize(formula);

  // If tokenization failed
  if (!tokenizedFormula.success) return tokenizedFormula;

  // Validate comparison operators
  const comparisonOperatorsValidationResult = validateComparisonOperators(tokenizedFormula.tokens);

  // Validate
  if (!comparisonOperatorsValidationResult.success) return comparisonOperatorsValidationResult;

  // Validate quotes
  const quotesValidationResult = validateQuotes(formula);

  // If validation failed
  if (!quotesValidationResult.success) return quotesValidationResult;

  // Validate tokens positions
  const positionValidationResult = validateTokenPositions(tokenizedFormula.tokens);

  // If validation failed
  if (!positionValidationResult.success) return positionValidationResult;

  // Validate braces
  const bracesValidationResult = checkBraces(tokenizedFormula.tokens);

  // If validation failed
  if (!bracesValidationResult.success) return bracesValidationResult;

  // Validate keywords
  const keywordValidationResult = validateKeyword(tokenizedFormula.tokens);

  // If validation failed
  if (!keywordValidationResult.success) return keywordValidationResult;

  // Check if we have an inappropriate amount of operators next to each other
  const regexForThreeOperators = /[/*\-+]{3}/;
  const { tokens } = tokenizedFormula;
  const threeOperatorsInARow = regexForThreeOperators.test(formula.replace(/\s/g, ''));
  const twoOperatorsAtTheBeginning = (tokens[0] && tokens[0].type === ValidationConstants.TOKEN_TYPE__OPERATOR &&
    tokens[1] && tokens[1].type === ValidationConstants.TOKEN_TYPE__OPERATOR);

  if (threeOperatorsInARow || twoOperatorsAtTheBeginning) {
    return { success: false, error: 'Incorrect number of operators.' };
  }

  // Parse tokenized formula
  const parseFormulaResult = parse(tokenizedFormula.tokens, selectedDataExtensions);

  // If validation failed
  if (!parseFormulaResult.success) return parseFormulaResult;

  // Validate functions
  const argumentsValidationResult = validateFunctionArguments(parseFormulaResult.ast);

  // If validation failed
  if (!argumentsValidationResult.success) return argumentsValidationResult;

  // None of the validation failed
  return { success: true };
};

export default validate;
export { isFunctionRecognized, isKeywordRecognized };
