import React from 'react';

const SelectUp = ({ className }) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      // eslint-disable-next-line max-len
      d="M3.20041 10.7603C3.48226 11.0639 3.95681 11.0814 4.26034 10.7996L8 7.27348L11.7397 10.7996C12.0432 11.0814 12.5177 11.0639 12.7996 10.7603C13.0815 10.4568 13.0639 9.98226 12.7603 9.7004L8.51034 5.7004C8.22258 5.4332 7.77743 5.4332 7.48967 5.7004L3.23966 9.7004C2.93613 9.98226 2.91856 10.4568 3.20041 10.7603Z"
      fill="#747474"
    />
  </svg>
);

export default SelectUp;
