import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import Constants from '../../../constants/constants';
import UserNotificationsAPI from '../../../api/user-notifications';
import './styles.scss';
import Alert from '../../shared/Alert/Alert';

const Notifications = ({
  notifications,
  handleSetAppState,
  latePayment,
}) => {
  /**
   * Close notification and dismiss if checkbox is marked
   * @param {object} event - event to get the checkbox state
   * @param {string} id - notification id
   * @returns {void}
   */
  const closeNotification = async (event, id) => {
    const mainDiv = event.target ? event.target.parentNode.parentNode : null;
    const checkBox = event.target ? event.target.previousSibling.checked : null;

    if (checkBox) {
      await UserNotificationsAPI.dismissUserNotification(axios.CancelToken.source().token, id);
    }

    if (mainDiv) {
      mainDiv.style.opacity = 0;
    }

    setTimeout(() => {
      let newNotifications = [...notifications];

      newNotifications = newNotifications.filter(notification => notification._id !== id);
      handleSetAppState({ notifications: newNotifications });
    }, 1000);
  };

  /**
   * Displays the proper notification based on notification prop passed
   * @param {string} notification - notification to display
   * @returns {Object} notification JSX
   */
  const displayNotification = (notification) => {
    const {
      _id,
      type,
      message,
      isDismissible,
    } = notification;

    return (
      <div key={_id}>
        <Alert
          type={type}
          assistiveText={type}
          dangerouslySetInnerHTML={message}
          headerId="notification-message"
          closeButton={isDismissible}
          closeText={'Don\'t show again'}
          closeAlert={async e => closeNotification(e, _id)}
          buttonAssistiveText="Close"
          symbolSize="medium"
        />
      </div>
    );
  };

  return (
    <div className="notifications-box">
      {notifications && notifications.length > 0 &&
        notifications.map(notification => displayNotification(notification))}
      {latePayment === 'true' && (
        displayNotification({
          _id: 'latePayment',
          type: Constants.NOTIFICATION__TYPE__ERROR,
          message: 'Your organisation has outstanding overdue invoices. ' +
          'Please request the finance department to settle these asap to avoid service disruption.',
          isDismissible: false,
        })
      )}
    </div>
  );
};

Notifications.propTypes = {
  /**
   * Notifications data from App.js
   */
  notifications: PropTypes.instanceOf(Array).isRequired,
  /**
   * It sets the App component`s state
   */
  handleSetAppState: PropTypes.func.isRequired,
  /**
   * Checks if the organization has some unpaid bills
   */
  latePayment: PropTypes.string.isRequired,
};

export default Notifications;
