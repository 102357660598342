import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

const Select = ({
  id,
  className,
  onChange,
  value,
  options,
  noOptionsLabel,
  selectList,
  containerClassName,
  onLoad,
  forwardRef,
  selectLabel,
  disabled,
  noContainer,
  defaultValue,
  required,
  groupedOptions,
  name,
  selected,
  noSpan,
  dataSetModalOption,
}) => {
  const divContainerClassName = classNames(
    noContainer ? '' : 'slds-select_container',
    `${containerClassName || ''}`,
  );

  /**
   * It helps to render options for group
   * @param {array} groupOptions - array of the options in the group
   * @returns {void}
   */
  const renderOptionsForGroup = groupOptions => groupOptions.map(option => (
    { value: option.value, label: option.label }));

  return (
    <div className={divContainerClassName}>
    {noSpan ?
      null :
      <span className="form-element-label">{selectLabel}</span>}
      <select
        id={id}
        name={name}
        className={`slds-select ${className || ''}`}
        onChange={onChange}
        value={value}
        // eslint-disable-next-line react/no-unknown-property
        onLoad={onLoad}
        ref={forwardRef}
        disabled={disabled}
        defaultValue={defaultValue}
        required={required}
        selected={selected}
      >
        {groupedOptions ?
          Object.keys(groupedOptions).map(group => (
            <optgroup key={group} label={group}>
              {renderOptionsForGroup(groupedOptions[group]).map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </optgroup>
          )) :
          (
            dataSetModalOption ?
              (
                <option
                  value={dataSetModalOption.value}
                  key={dataSetModalOption.key || dataSetModalOption.value}
                  disabled
                >
                  {dataSetModalOption.key || dataSetModalOption.value}
                </option>
              ) :
              (
                options?.length ?
                  (
                    options.map(option => (
                      option ?
                      <option
                        value={option.value}
                        key={option?.key || option.value}
                        disabled={option?.disabled}
                        title={option?.title}
                        hidden={option?.hidden}
                        style={option?.style}
                      >
                        {option.label}
                      </option> :
                        null
                    ))) :
                  (
                    <option value="">
                      {noOptionsLabel || 'No options available'}
                    </option>
                  )
              )
          )}
        {selectList}
      </select>
    </div>
  );
};

Select.propTypes = {
  /**
   * id of the select element
   */
  id: PropTypes.string,
  /**
   * className of the select element
   */
  className: PropTypes.string,
  /**
   * onChange function for the select element
   */
  onChange: PropTypes.func,
  /**
   * value for the select element
   */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  /**
   * options for the select element
   */
  options: PropTypes.instanceOf(Array),
  /**
   * label for the option when there is no options available
   */
  noOptionsLabel: PropTypes.string,
  /**
   * list of possible options for select, out of selection list
   */
  selectList: PropTypes.instanceOf(Array),
  /**
   * Class name for the container of the select component
   */
  containerClassName: PropTypes.string,
  /**
   * onLoad function for the select
   */
  onLoad: PropTypes.func,
  /**
   * Ref element of select
   */
  forwardRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.elementType }),
  ]),
  /**
   * label for the select
   */
  selectLabel: PropTypes.string,
  /**
   * defines whether select should be disabled
   */
  disabled: PropTypes.bool,
  /**
   * indicates that the container for the select is not to have a default class name
   */
  noContainer: PropTypes.bool,
  /**
   * default value for the select
   */
  defaultValue: PropTypes.string,
  /**
   * indicates that select is required
   */
  required: PropTypes.bool,
  /**
   * object with grouped options for select
   */
  groupedOptions: PropTypes.instanceOf(Object),
  /**
   * name for the select
   */
  name: PropTypes.string,
  /**
   * indicates that select is selected
   */
  selected: PropTypes.bool,
  /**
   * indicates that the label for select is not rendered
   */
  noSpan: PropTypes.bool,
  /**
   * Object with the only one option for the Data Sets
   */
  dataSetModalOption: PropTypes.instanceOf(Object),
};

export default Select;
