import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';

import mapStateToProps from '../../../../mapStateToProps';
import Button from '../../../shared/Button/Button';

const SelectionsRight = ({
  handleOpenCopySelectionModal,
  handleOpenDeleteSelectionModal,
  _id,
  restrictedVisibility = false,
  isWaterfall,
  isArchived,
  featureSelectionFoldersEnabled,
  handleOpenArchiveSelectionModal,
  showHover,
  userInfo,
}) => {
  const hasReadOnlyAccess = userInfo?.hasReadOnlyAccess;

  return (
    <ul
    className={classNames('selections_right_body', { 'overview-list-hover': showHover })}
    id="selection-right-container">
      <li>
        <div
          className={classNames('icons-overview', { 'icon-start': restrictedVisibility })}
          title=""
        >
          {!isArchived && (
            <div>
              <Button
                buttonIconBorderFilled
                className="overview-selection-actions-copy"
                title="copy"
                id={isWaterfall ? 'waterfall-copy' : 'selection-copy'}
                onClick={() => handleOpenCopySelectionModal(_id)}
                disabled={hasReadOnlyAccess}
              >
                <svg className="slds-button__icon" aria-hidden="true">
                  <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#copy" />
                </svg>
              </Button>
            </div>
          )}
          {!restrictedVisibility && (
            <div>
              <Button
                buttonIconBorderFilled
                className="overview-selection-actions-delete"
                title="remove"
                id={isWaterfall ? 'waterfall-remove' : 'selection-remove'}
                onClick={() => handleOpenDeleteSelectionModal(_id)}
                disabled={hasReadOnlyAccess}
              >
                <svg className="slds-button__icon" aria-hidden="true">
                  <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#delete" />
                </svg>
              </Button>
            </div>
          )}

          {featureSelectionFoldersEnabled && !restrictedVisibility &&
          (
          <div>
              <Button
                className="overview-selection-actions-archive slds-button
                slds-button_icon slds-button_icon-border-filled"
                title={isArchived ? 'restore' : 'archive'}
                id={isArchived ?
                  `${isWaterfall ? 'waterfall-restore' : 'selection-restore'}` :
                  `${isWaterfall ? 'waterfall-archive' : 'selection-archive'}`}
                onClick={() => handleOpenArchiveSelectionModal(_id)}
              >
                <svg className="slds-button__icon" aria-hidden="true">
                  <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#archive" />
                </svg>
              </Button>
          </div>
          )}
        </div>
      </li>
    </ul>
  );
};

SelectionsRight.propTypes = {
  /**
   * It opens the CopySelectionModal
   */
  handleOpenCopySelectionModal: PropTypes.func.isRequired,
  /**
   * It opens the DeleteSelectionModal
   */
  handleOpenDeleteSelectionModal: PropTypes.func.isRequired,
  /**
   * It opens the ArchiveSelectionModal
   */
  handleOpenArchiveSelectionModal: PropTypes.func.isRequired,
  /**
   * It gives the id of selection
   */
  _id: PropTypes.string.isRequired,
  /**
   * Indicates whether all selection buttons should be shown
   */
  restrictedVisibility: PropTypes.bool.isRequired,
  /**
   * Defines whether the Waterfall Selections section is selected in the Overview
   */
  isWaterfall: PropTypes.bool,
  /**
   * Defines whether the Selection is archived
   */
  isArchived: PropTypes.bool.isRequired,
  /**
   * It determines if the feature for selection folder is enabled or not
   */
  featureSelectionFoldersEnabled: PropTypes.bool.isRequired,
  /**
   * User info from cookie
   */
  userInfo: PropTypes.object,
};

export default connect(mapStateToProps(['userInfo']), null, null, { pure: false })(SelectionsRight);
