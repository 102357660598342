import Constants from '../../constants/constants';
import Util from '../../util';
import SwalUtil from '../swal/swalUtil';
import DataViews from '../../constants/dataViews';

const filterSetsUtil = {
  /**
   * @param {Object} param - Params
   * @param {string} params.typeOfSwal - type of swal
   * @param {string} params.titleOfSwal - title of swal
   * @param {string} params.message - message of swal
   * @returns {object} - return swal
   */
  throwSwal: async ({ typeOfSwal, titleOfSwal, message }) => {
    return SwalUtil.fire({
      type: typeOfSwal,
      title: titleOfSwal,
      message,
      options: {
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'OK',
        showCloseButton: false,
        allowOutsideClick: false,
      },
    });
  },

  /**
   * Push dataViews in dataExtensions if feature 'dataViews' is enabled and then sort it
   * @param {object} dataExtensions - List of data extensions
   * @returns {void}
   */
  addDataViewsInDEs: (dataExtensions) => {
    dataExtensions.push(...DataViews);

    Util.sortArrayOfObjects(dataExtensions, 'Name');
  },

  /**
   * Function for adjusting data view fields for further usage
   * @param {object[]} selectedCollection - Selected collection
   * @returns {void}
   */
  prepareDataViewsFields: (selectedCollection) => {
    const dataExtension = {
      CustomerKey: selectedCollection.CustomerKey,
    };

    selectedCollection.fields.forEach((field) => {
      /* eslint-disable no-param-reassign */
      field.CustomerKey = `[${selectedCollection.CustomerKey}].[${field.Name.toString()}]`;
      field.DataExtension = dataExtension;
      /* eslint-enable no-param-reassign */
    });
  },

  /**
   * Finds data extension in selected data extensions by deAlias
   * @param {array} selectedDataExtensions - array with selected data extensions
   * @param {object} filter - object with filter data
   * @returns {object|*} object with selected data extension if found
   */
  findSelectedDE: (selectedDataExtensions, filter) => selectedDataExtensions
    .find(de => de.deAlias === filter.collectionAlias),

  /**
   * Finds and return predefined relation data for given id
   * @param {Object} params - params
   * @param {string} params.relationId - relation id
   * @param {object} params.relationFilter - object with relation filter data
   * @param {Array} params.predefinedRelations - array of predefinedRelations
   * @param {Array} params.filterSetSelectedDEs - Selected DEs on filter set
   * @returns {object|*} object with predefined relation if found
   */
  returnPredefinedRelationById: ({
    relationId, relationFilter, predefinedRelations, filterSetSelectedDEs,
  }) => {
    const selectedDE = filterSetsUtil.findSelectedDE(filterSetSelectedDEs, relationFilter);

    // if selectedDE is found
    if (predefinedRelations?.length && selectedDE?.ObjectID) {
      // get predefined relation by ID
      const predefinedRelation = predefinedRelations.find(relation => relation._id === relationId);

      if (predefinedRelation && relationFilter?.collectionAlias) {
        // check if toDE in predefined relation appears as selectedDE in the filter
        const toDEAsSelectedDE = predefinedRelation.toDEObjectId === selectedDE.ObjectID &&
                predefinedRelation.relation === Constants.RELATION__TYPE__ONE_TO_ONE;

        // check if fromDE in predefined relation appears as selectedDE in the filter
        const fromDEAsSelectedDE = predefinedRelation.fromDEObjectId === selectedDE.ObjectID;

        // check if relation is valid for selectedDE
        const validateRelationForSelectedDE = toDEAsSelectedDE || fromDEAsSelectedDE;

        // if relation is valid for selectedDE
        if (validateRelationForSelectedDE) {
          /*
           * check if DE in relation filter exists in reverse order than in predefined relation
           * (selected DE in relation filter (collectionAlias) = toDE in predefined relation)
           */
          const reverseOrder = toDEAsSelectedDE || !fromDEAsSelectedDE;

          // if relation filter exists in reverse order
          if (reverseOrder) {
            // return it in reverse order
            return { ...Util.replaceOrdersInPredefinedRelation(predefinedRelation), reverseOrder: true };
          }

          // in case of normal order, return predefined relation
          return { ...predefinedRelation, reverseOrder: false };
        }
      } else {
        // check if we can find another relation with the same properties (DEs, fields) and use that relation

        // get fromDE object and fromFieldObjectID
        const fromDEUsedInRelationFilter = selectedDE;
        const fromFieldObjectIDUsedInRelationFilter = relationFilter.fieldObjectID;

        // get toDE object and toFieldObjectID
        const toDEUsedInRelationFilter = relationFilter.subQuery.selectedDataExtension;
        const toFieldObjectIDUsedInRelationFilter = relationFilter.subQuery.fields[0].availableFieldObjectID;

        // check for normal order
        const predefinedRelationWithTheSamePropertiesInNormalOrder = predefinedRelations.find(relation => (
          (relation.fromDEObjectId === fromDEUsedInRelationFilter?.ObjectID &&
                relation.fromFieldObjectId === fromFieldObjectIDUsedInRelationFilter &&
                relation.toDEObjectId === toDEUsedInRelationFilter?.ObjectID &&
                relation.toFieldObjectId === toFieldObjectIDUsedInRelationFilter)
        ));

        // check for reverse order, but relation has to be 1-1
        const predefinedRelationWithTheSamePropertiesInReverseOrder = predefinedRelations.find(relation => (
          (relation.fromDEObjectId === toDEUsedInRelationFilter?.ObjectID &&
                 relation.fromFieldObjectId === toFieldObjectIDUsedInRelationFilter &&
                 relation.toDEObjectId === fromDEUsedInRelationFilter?.ObjectID &&
                 relation.toFieldObjectId === fromFieldObjectIDUsedInRelationFilter &&
                 relation.relation === Constants.RELATION__TYPE__ONE_TO_ONE)
        ));

        if (predefinedRelationWithTheSamePropertiesInNormalOrder) {
          // in case of normal order, return predefined relation
          return { ...predefinedRelationWithTheSamePropertiesInNormalOrder, reverseOrder: false };
        } if (predefinedRelationWithTheSamePropertiesInReverseOrder) {
          // if relation filter exists in reverse order, return it after reordering
          return {
            ...Util.replaceOrdersInPredefinedRelation(predefinedRelationWithTheSamePropertiesInReverseOrder),
            reverseOrder: true,
          };
        }

        return null;
      }
    }

    return null;
  },

  /**
   * Adds all needed properties into DE
   * @param {object} extension - DE
   * @param {string} alias - Alias of DE
   * @param {object[]} subCollections - Sub collections
   * @param {string} toFromField - Indicates previous field
   * @param {number} toFromIndex - Indicates previous index
   * @param {number} relationsIndex - Indicates index in relations array
   * @param {string} toFromFieldType - Indicates type of previous field
   * @param {string} toFromFieldObjectID - Indicates ObjectId of the field
   * @returns {void}
   */
  setDataExtensionProperties: (
    extension,
    alias,
    subCollections,
    toFromField,
    toFromIndex,
    relationsIndex,
    toFromFieldType,
    toFromFieldObjectID,
  ) => {
    /* eslint-disable no-param-reassign */
    extension.dragged = true;
    extension.id = alias + new Date().getTime();
    extension.deAlias = alias;
    // Push all subCollections, filter(remove) them if there is/are subCollections' subCollections
    extension.subCollections = (extension.subCollections ?
      [...extension.subCollections, subCollections] :
      subCollections
    ).filter(collection => !(collection && collection.length >= 0));
    // 0 -> From (parent extension) properties
    if (toFromIndex === 0) {
      // 1 -> To (child extension) properties
    } else if (toFromIndex === 1) {
      extension.edit = true;
      extension.toField = toFromField;
      extension.toFieldType = toFromFieldType;
      extension.toFieldObjectID = toFromFieldObjectID;
      /**
       * Create unique Id for relationalModal using getTime() and relationsIndex (adding to ensure
       * RelationalModalIds built in same millisecond are still unique)
       */
      extension.relationalModalId = Number.parseInt(
        new Date().getTime() + '' + (typeof relationsIndex === 'number' ? relationsIndex : 0),
      );
    }
    /* eslint-enable no-param-reassign */
  },

  /**
   * Pushes related data extensions (if there is no duplication) into selectedDataExtension state
   * @param {object[]} selectedDataExtensions - Selected DE
   * @param {object} toCollection - To which collection
   * @param {object} fromCollection - From which collection
   * @returns {object[]} The selected data extensions with new related data extension items
   */
  addToSelectedDataExtensions: (selectedDataExtensions, toCollection, fromCollection) => {
    // If both toCollection and fromCollection don't exist; push both of them.
    if (selectedDataExtensions.filter(ex => ex.deAlias === toCollection.deAlias)?.length === 0 &&
          selectedDataExtensions.filter(ex => ex.deAlias === fromCollection.deAlias)?.length === 0) {
      // Add both from and to collection
      // eslint-disable-next-line no-param-reassign
      selectedDataExtensions = [...selectedDataExtensions, fromCollection, toCollection];
      // Push fromCollection if toCollection exists in selectedDataExtensions and fromCollection does not.
    } else if (selectedDataExtensions.filter(ex => ex.deAlias === fromCollection.deAlias)?.length === 0) {
      // Add only from collection
      // eslint-disable-next-line no-param-reassign
      selectedDataExtensions = [...selectedDataExtensions, fromCollection];
      // Push toCollection if fromCollection exists in selectedDataExtensions and toCollection does not.
    } else if (selectedDataExtensions.filter(ex => ex.deAlias === toCollection.deAlias)?.length === 0) {
      // Add only to collection
      // eslint-disable-next-line no-param-reassign
      selectedDataExtensions = [...selectedDataExtensions, toCollection];
    }

    return selectedDataExtensions;
  },

};

export default filterSetsUtil;
