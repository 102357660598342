import axios from 'axios';

import Util from '../util';

const apiUrl = process.env.REACT_APP_API_URL;

const UserNotificationsAPI = {
  /**
   * Dismiss notification so its not shown anymore
   * @param {object} cancelToken - Token axios
   * @param {string} id - Notification id
   * @returns {object} An object with the properties `data` and `success`
   */
  dismissUserNotification: async (cancelToken, id) => {
    const res = await Util.catch418And403Error(
      axios.post,
      `${apiUrl}/user-notifications/${id}/dismiss`,
      {},
      Util.apiGetCallSettings(cancelToken),
    );

    return res.data;
  },
};

export default UserNotificationsAPI;
