import axios from 'axios';

import Util from '../util';

const apiUrl = process.env.REACT_APP_API_URL;

const DataSetsAPI = {
  /**
   * Get data sets for current organization
   * @param {object} cancelToken - Token axios
   * @returns {object} Data sets of current organization
   */
  getDataSets: async (cancelToken) => {
    const res = await Util.catch418And403Error(
      axios.get,
      `${apiUrl}/datasets?noPagination=true`,
      Util.apiGetCallSettings(cancelToken),
    );

    return res.data.data;
  },

  /**
   * Create a new data set based on the given parameters
   * @param {object} postData - object of data
   * @param {object} cancelToken - Token axios
   * @returns {object} Newly created data set
   */
  createDataSet: async (postData, cancelToken) => {
    const res = await Util.catch418And403Error(
      axios.post,
      `${apiUrl}/datasets`,
      postData,
      Util.apiPostCallSettings(cancelToken),
    );

    return res.data;
  },

  /**
   * Updates an existing data set based on the given parameters
   * @param {string} id - Id of the data set
   * @param {object} postData - object of data
   * @param {object} cancelToken - Token axios
   * @returns {object} Updated data set
   */
  updateDataSet: async (
    id,
    postData,
    cancelToken,
  ) => {
    const res = await Util.catch418And403Error(
      axios.put,
      `${apiUrl}/datasets/${id}`,
      postData,
      Util.apiPostCallSettings(cancelToken),
    );

    return res.data;
  },

  /**
   * Delete data set based the on a given id
   * @param {string} id - Id of the data set that's going to be deleted
   * @param {object} cancelToken - Axios token
   * @returns {object} Deleted data set
   */
  deleteDataSet: async (
    id,
    cancelToken,
  ) => {
    const res = await Util.catch418And403Error(
      axios.delete,
      `${apiUrl}/datasets/${id}`,
      Util.apiPostCallSettings(cancelToken),
    );

    return res.data;
  },
};

export default DataSetsAPI;
