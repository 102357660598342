import { useState, useEffect } from 'react';

/**
 *  Custom hook for updating send relationship
 * @param {object} initialState - initial state of data
 * @param {Function} handleUpdateSendRelationship - function that call the api and update the send relationship
 * @returns {Array} - Array that contains state of send relationship, set method and update method
 */
const useUpdateSendRelationship = (initialState, handleUpdateSendRelationship) => {
  const [sendRelationship, setSendRelationship] = useState({
    isEnabled: false,
    fieldName: null,
    defaultRelation: null,
  });

  useEffect(() => {
    setSendRelationship({
      isEnabled: !!initialState?.isEnabled,
      fieldName: initialState?.fieldName || null,
      defaultRelation: initialState?.defaultRelation || null,
    });
  }, [initialState]);

  /**
   * update send Relationship
   * @param {object} sendRelationship - send relationship object
   * @returns {void}
   */
  const updateSendRelationship = async (sendRelationship) => {
    handleUpdateSendRelationship(sendRelationship);
  };

  // return custom hook elements
  return [sendRelationship, setSendRelationship, updateSendRelationship];
};

export { useUpdateSendRelationship };
