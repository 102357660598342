import React from 'react';
import { NavBar as CrossAppNavigationBar } from '@DESelectMC/deselect-component-library';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import mapStateToProps from '../../../mapStateToProps';
import Util from '../../../util';
import Button from '../../shared_v2/Button/Button';
import { ReactComponent as HomeIcon } from '../../../icons_v2/home-icon.svg';
import './styles.scss';
import Constants from '../../../constants/constants';

/**
 * HTML for a navbar
 * @returns {object} A navbar
 */
const Navbar = ({
  handleNavigator, isGettingValuesFromDEStarted, userInfo, orgInfo,
}) => (
  <div className="newAdminpanel_header" id="adminpanel-header">
    <div className="newAdminpanel_wrapper">
      <div className="admin-global-nav-container">
      <CrossAppNavigationBar
          options={Util.getNavBarOptions(userInfo, orgInfo)}
          selected="Segment"
        />
      </div>
      <div className="admin-home-button-container">
        <Button
          buttonIcon
          className="admin-home-button"
          title="Back to Home"
          disabled={isGettingValuesFromDEStarted}
          onClick={() => handleNavigator(Constants.NAVIGATION__OVERVIEW)}
         >
        <HomeIcon />
        </Button>
      </div>
    </div>
  </div>
);

Navbar.propTypes = {
  /**
   * Function to update user's current position in navigation
   */
  handleNavigator: PropTypes.func,
  /**
   * Boolean to check if we are getting values from DE
   */
  isGettingValuesFromDEStarted: PropTypes.bool,
  /**
   * User info from cookie
   */
  userInfo: PropTypes.object,
  /**
   * Org info from cookie
   */
  orgInfo: PropTypes.object,
};

export default connect(mapStateToProps(['userInfo', 'orgInfo']), null, null, { pure: false })(Navbar);
