import axios from 'axios';

import Util from '../util';

const apiUrl = process.env.REACT_APP_API_URL;

const SelectionRunLogsAPI = {
  /**
   * Get top 100 SelectionRunLogs for one selection
   * @param {string} selectionId - Id of the selection
   * @param {object} cancelToken - Axios token
   * @returns {Object} Returned selectionRunLog document
   */
  getSelectionRunLogs: async (
    selectionId,
    cancelToken,
  ) => {
    const res = await Util.catch418And403Error(
      axios.get,
      `${apiUrl}/selection-run-logs/${selectionId}`,
      Util.apiPostCallSettings(cancelToken),
    );

    return res.data.data;
  },

  /**
   * Get top 100 SelectionRunLogs for one waterfall selection
   * @param {string} waterfallSelectionId - Id of the waterfall selection
   * @param {object} cancelToken - Axios token
   * @returns {Object} Returned selectionRunLog document
   */
  getWaterfallSelectionRunLogs: async (
    waterfallSelectionId,
    cancelToken,
  ) => {
    const res = await Util.catch418And403Error(
      axios.get,
      `${apiUrl}/waterfall-selection-run-logs/${waterfallSelectionId}`,
      Util.apiPostCallSettings(cancelToken),
    );

    return res.data.data;
  },

};

export default SelectionRunLogsAPI;
