import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import ToggleButton from '../../../../../shared/ToogleButton/ToggleButton';

const PrioDeduplicationStatus = ({
  usePrioDeduplication,
  handleChangePrioDedupState,
}) => (
  <>
    <div className="slds-form-element">
      <ToggleButton
        id="prio-dedup-status"
        name="usePrioDeduplication"
        checked={usePrioDeduplication}
        onChange={() => handleChangePrioDedupState({
          usePrioDeduplication: !usePrioDeduplication,
        })}
        label="Use Prio Deduplication"
      />
    </div>
    <p className="info-about-prio-dedup-status">
      {usePrioDeduplication ?
        'Prio Deduplication settings will be applied to your selection.' :
        'Prio Deduplication settings will not be applied to your selection.'}
    </p>
  </>
);

PrioDeduplicationStatus.propTypes = {
  /**
   * It keeps if the prio deduplication settings will be applied
   *
   */
  usePrioDeduplication: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /**
   * It helps to change state of prio dedup
   *
   */
  handleChangePrioDedupState: PropTypes.func.isRequired,
};

export default PrioDeduplicationStatus;
