/* eslint-disable react/jsx-one-expression-per-line */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';

import './styles.scss';
import Util from '../../../../util';
import Constants from '../../../../constants/constants';
import AdditionalJoinsComponent from './AdditionalJoinsComponent/AdditionalJoinsComponent';
import Tooltip from '../../../shared_v2/Tooltip/Tooltip';
import Button from '../../../shared_v2/Button/Button';
import ModalTemplate from '../../../shared_v2/ModalTemplate/ModalTemplate';
import Select from '../../../shared_v2/Select/Select';

class RelationModal extends PureComponent {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      showRelationalModal: false,
      modalDataExtensions: {
        relationalModalId: '',
        fromCollection: {},
        toCollection: {},
        joinType: '',
        additionalJoins: [],
      },
      areFieldsIncompatible: false,
      incompatibleJoinObjectId: null,
      fromFieldMissing: false,
      toFieldMissing: false,
      width: null,
      isIncompatibleFieldJoinable: false,
      isPredefinedRelation: false,
      areAdditionalJoinsFromPredefinedRelations: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const newState = {};

    if (
      nextProps.showRelationalModal !== prevState.showRelationalModal ||
      nextProps.modalDataExtensions !== prevState.modalDataExtensions ||
      nextProps.fromFieldMissing !== prevState.fromFieldMissing ||
      nextProps.toFieldMissing !== prevState.toFieldMissing
    ) {
      newState.showRelationalModal = nextProps.showRelationalModal;
      newState.modalDataExtensions = nextProps.modalDataExtensions;
      newState.fromFieldMissing = nextProps.fromFieldMissing;
      newState.toFieldMissing = nextProps.toFieldMissing;

      return newState;
    }

    return null;
  }

  componentDidMount() {
    window.addEventListener('resize', this.getDimension.bind(this));
    if (this.myRef.current !== null) {
      this.getDimension();
    }
  }

  componentDidUpdate() {
    const {
      modalDataExtensions,
      handleSetSelectionState,
      predefinedRelationsMap,
      predefinedRelations,
      setModalDataExtensions,
    } = this.props;

    // Shallow copies to prevent reference problem
    const modalDataExtensionsCopy = { ...modalDataExtensions };
    const fromCollectionCopy = { ...modalDataExtensionsCopy.fromCollection };
    const toCollectionCopy = { ...modalDataExtensionsCopy.toCollection };
    // Flag that determines whether the state should be updated or not

    let shouldUpdate = false;

    let { fromFieldType } = fromCollectionCopy;

    let { toFieldType } = toCollectionCopy;

    if (Object.keys(fromCollectionCopy).length &&
      (!fromCollectionCopy.fromFieldType || !fromCollectionCopy.fromFieldObjectID)) {
      // Find the field
      const field = fromCollectionCopy.fields.find(
        f => f.ObjectID === fromCollectionCopy.fromFieldObjectID ||
          f.Name === fromCollectionCopy.fromField,
      );
      // Return if field doesn't exist

      if (!field) return;
      fromCollectionCopy.fromFieldType = field.FieldType;
      fromCollectionCopy.fromFieldObjectID = field.ObjectID;
      fromFieldType = field.FieldType;
      modalDataExtensionsCopy.fromCollection = fromCollectionCopy;
      shouldUpdate = true;
    }

    if (Object.keys(toCollectionCopy).length &&
      (!toCollectionCopy.toFieldType || !toCollectionCopy.toFieldObjectID)) {
      // Find the field
      const field = toCollectionCopy.fields.find(
        f => f.ObjectID === toCollectionCopy.toFieldObjectID ||
          f.Name === toCollectionCopy.toField,
      );
      // Return if field doesn't exist

      if (!field) return;
      toCollectionCopy.toFieldType = field.FieldType;
      toCollectionCopy.toFieldObjectID = field.ObjectID;
      toFieldType = field.FieldType;
      modalDataExtensionsCopy.toCollection = toCollectionCopy;
      shouldUpdate = true;
    }

    // Set the field compatibility state
    if (fromFieldType && toFieldType) {
      let isPredefinedRelation;

      let areAdditionalJoinsFromPredefinedRelations;

      // check if predefined relation exists for toCollection and fromCollection fields
      const findToValue = Object.values(predefinedRelationsMap).find(relation => (
        // check in normal order
        // eslint-disable-next-line eqeqeq
        JSON.stringify(relation) == JSON.stringify([
          fromCollectionCopy.fromFieldObjectID, toCollectionCopy.toFieldObjectID]) ||
        // check in reverse order
        // eslint-disable-next-line eqeqeq
        JSON.stringify(relation) == JSON.stringify([
          toCollectionCopy.toFieldObjectID, fromCollectionCopy.fromFieldObjectID])));

      /*
       * make sure that toCollection and fromCollection Data Extension belongs to predefined relation
       * this is especially important when using data view, where ObjectID is not unique
       */
      const getPredefinedRelationsForCollectionDEs = predefinedRelations?.find(predefinedRel => (
        // check fromDE and toDE in normal and reverse order
        (predefinedRel.fromDEObjectId === fromCollectionCopy.ObjectID ||
          predefinedRel.fromDEObjectId === toCollectionCopy.ObjectID) &&
        (predefinedRel.toDEObjectId === fromCollectionCopy.ObjectID ||
          predefinedRel.toDEObjectId === toCollectionCopy.ObjectID)
      ));

      // if predefined relation exists for toCollection and fromCollection DE and fields
      if (findToValue && getPredefinedRelationsForCollectionDEs) {
        // set isPredefinedRelation to true
        isPredefinedRelation = true;

        // Determine if additionalJoins are from predefinedRelations too.
        if (modalDataExtensionsCopy?.additionalJoins?.length ===
          getPredefinedRelationsForCollectionDEs?.additionalJoins?.length) {
          areAdditionalJoinsFromPredefinedRelations = modalDataExtensionsCopy?.additionalJoins?.every(
            (additionalJoin, i) => {
              const predefinedRelationAdditionalJoin = getPredefinedRelationsForCollectionDEs.additionalJoins[i];

              // check fromField and toField in normal and reverse order
              return (additionalJoin.fromFieldObjectID === predefinedRelationAdditionalJoin.fromFieldObjectID ||
                additionalJoin.fromFieldObjectID === predefinedRelationAdditionalJoin.toFieldObjectID) &&
                (additionalJoin.toFieldObjectID === predefinedRelationAdditionalJoin.toFieldObjectID ||
                  additionalJoin.toFieldObjectID === predefinedRelationAdditionalJoin.fromFieldObjectID
                );
            },
          );
        } else {
          // If the arrays are of different length, they're not the same
          areAdditionalJoinsFromPredefinedRelations = false;
        }
      } else {
        isPredefinedRelation = false;
      } // otherwise the relation does not exist

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        areFieldsIncompatible: !Util.canFieldBeMapped(fromFieldType, toFieldType),
        isIncompatibleFieldJoinable: !Util.canFieldBeMappedWithWarning(fromFieldType, toFieldType),
        isPredefinedRelation,
        areAdditionalJoinsFromPredefinedRelations,
      });
    }

    if (shouldUpdate) {
      if (handleSetSelectionState) {
        handleSetSelectionState({ modalDataExtensions: modalDataExtensionsCopy });
      } else {
        setModalDataExtensions(modalDataExtensionsCopy);
      }
    }

    this.getDimension();
  }

  componentWillUnmount() {
    window.addEventListener('resize', this.getDimension.bind(this));
  }

  /**
   * Get and set the element width (myRef)
   * @returns {void}
   */
  getDimension() {
    if (this.myRef.current !== null) {
      this.setState({
        width: this.myRef.current.offsetWidth,
      });
    }
  }

  /**
   * It closes the modal when clicked on 'cancel' or 'x'
   * @returns {void}
   */
  handleRelationalModalClose = async () => {
    const {
      selectedDataExtensions,
      modalDataExtensions,
      relations,
      handleSetSelectionState,
      handlePickListOptions,
      movingDE,
      prevSelectedDEs,
      prevRelations,
      setSelectedDataExtensions,
      setModalDataExtensions,
    } = this.props;

    // If we cancel when moving DEs, reset the selectedDEs' and relations' state
    if (movingDE) {
      handleSetSelectionState({ selectedDataExtensions: prevSelectedDEs, movingDE: false, relations: prevRelations });
    } else {
      const parentId = modalDataExtensions.toCollection.id;

      let deletedDE;

      /**
       * Traverse collections, splices matched element by id.
       * @param {object} subCollections - sub collection
       * @returns {void}
       */
      const spliceDataExtension = async (subCollections) => {
        for (let i = 0; i < subCollections.subCollections.length; i += 1) {
          if (subCollections.subCollections[i].id === parentId) {
            const checkRelationExists = relations.filter(
              relation => relation.toCollection.id === parentId,
            );

            /*
             * if result of filter equals to zero it means that
             * this relation is not created yet. So we can remove it
             */

            if (checkRelationExists.length === 0) {
              // remove the extension
              const deletedExtension = subCollections.subCollections.splice(i, 1);

              /*
               * remove from selectedDataExtension
               * array(which means remove available fields about that de)
               */
              const filteredSelectedDataExtensions = selectedDataExtensions.filter(
                extension => deletedExtension[0].deAlias !== extension.deAlias,
              );

              if (handleSetSelectionState) {
                handleSetSelectionState({
                  selectedDataExtensions: filteredSelectedDataExtensions,
                });
              } else {
                setSelectedDataExtensions(filteredSelectedDataExtensions);
              }

              // assign deleted data extension to remove it from the picklist
              [deletedDE] = deletedExtension;
            }
          } else {
            if (subCollections.subCollections[i].subCollections.length > 0) {
              spliceDataExtension(subCollections.subCollections[i]);
            }
          }
        }
      };

      await spliceDataExtension(selectedDataExtensions[0]);

      // if user cancels relation modal remove those fields from picklist fields object ids
      if (deletedDE?.fields && handlePickListOptions) await handlePickListOptions(deletedDE.fields, false, deletedDE);
    }

    /**
     * Hide relational modal
     */
    this.setState({
      showRelationalModal: false, areFieldsIncompatible: false, incompatibleJoinObjectId: null,
    });

    if (handleSetSelectionState) {
      handleSetSelectionState({ modalDataExtensions: {} });
    } else {
      setModalDataExtensions({});
    }
  };

  /**
   * Change relation between DEs
   * Image will change as well
   * Avoid mutation
   * @param {object} event - event
   * @returns {void}
   */
  handleRelationalModalRelationType = (event) => {
    const { modalDataExtensions } = this.state;
    const modalDataExtensionsCopy = { ...modalDataExtensions };
    const { handleSetSelectionState, setModalDataExtensions } = this.props;

    modalDataExtensionsCopy.joinType = event.target.value;

    if (handleSetSelectionState) {
      handleSetSelectionState({ modalDataExtensions: modalDataExtensionsCopy });
    } else {
      setModalDataExtensions(modalDataExtensionsCopy);
    }
  };

  /**
   * Removes unnecessary attributes from modalDataExtensions object
   * @param {object} modalDataExtensions - the modalDataExtensions object
   * @returns {void}
   */
  removeUnnecessaryAttributesBeforeSaving = (modalDataExtensions) => {
    const { fromCollection, toCollection, additionalJoins } = modalDataExtensions;

    // Remove attributes from fromCollection object
    delete fromCollection.deletedFieldName;

    // Remove attributes from toCollection object
    delete toCollection.deletedFieldName;

    // Remove attributes from additionalJoins
    additionalJoins.forEach((additionalJoin) => {
      delete additionalJoin.deletedFromFieldName;
      delete additionalJoin.fromFieldMissing;
      delete additionalJoin.deletedToFieldName;
      delete additionalJoin.toFieldMissing;
    });
  };

  /**
   * Save the new or changed relationship
   * @returns {void}
   */
  handleRelationalModalSave = async () => {
    const { modalDataExtensions } = this.state;
    const { fromCollection, toCollection } = modalDataExtensions;
    const {
      handleSetSelectionState,
      relations,
      checkMissingFieldsInRelations,
      movingDE,
      selectedDataExtensions,
      isDataSetDEModal,
      setRelations,
      setModalDataExtensions,
      setShowRelationalModal,
      selectedDataSet,
      prevRelations,
      selectionMode,
    } = this.props;

    this.removeUnnecessaryAttributesBeforeSaving(modalDataExtensions);

    // finds no compatible object id from additionalJoins
    let findNoCompatibleID;

    if (modalDataExtensions.additionalJoins.length && !isDataSetDEModal) {
      const allIds = modalDataExtensions.additionalJoins.map(aJ => aJ.rowID);

      findNoCompatibleID = allIds.find(f => this.noCompatibleToAdditionalJoins(f));
    }

    // If the current choices and additionalJoins are compatible, save
    if ((!isDataSetDEModal &&
      this.checkIfCompatible(fromCollection.fromFieldType, toCollection.toFieldType, findNoCompatibleID)) ||
      isDataSetDEModal) {
      const relation = relations ?
        relations.filter(r => r.relationalModalId === modalDataExtensions.relationalModalId) :
        null;

      if (relation && relation.length > 0) {
        for (let i = 0; i < relations.length; i += 1) {
          if (relations[i].relationalModalId === modalDataExtensions.relationalModalId) {
            relations[i] = modalDataExtensions;
            break;
          }
        }
        this.setState({ showRelationalModal: false });

        if (handleSetSelectionState) {
          // Get previous relation
          const prevRelation = prevRelations?.find(r => r.relationalModalId ===
            modalDataExtensions.relationalModalId);

          // Check if relation has been tampered with
          const hasRelationBeenChanged = !_.isEqual([prevRelation], relation);

          if (selectedDataSet && hasRelationBeenChanged && selectionMode) {
            selectedDataSet.name = 'Custom Data Set';
            selectedDataSet.id = `custom-${selectedDataSet.id}-${new Date().getTime()}`;
            selectedDataSet.selectedDataExtensions = selectedDataExtensions;
            selectedDataSet.relations = relations;
            selectedDataSet.selectedDEsTree = Util.generateSelectedDEsTree(selectedDataExtensions);
            selectedDataSet.isCustomDataSet = true;
          }

          handleSetSelectionState({
            relations,
            modalDataExtensions: {},
            movingDE: false,
            fromFieldMissing: false,
            toFieldMissing: false,
            ...(selectedDataSet && hasRelationBeenChanged && { selectedDataSet }),
          }, null, checkMissingFieldsInRelations);
        } else {
          setRelations(relations);
          setModalDataExtensions({});
        }
      } else {
        let updatedRelations = [...relations, modalDataExtensions];

        /**
         * If we're moving DEs, we need to make sure we're putting this relation in the right place, i.e
         * Before any of its children's relations
         */
        if (movingDE) {
          /**
           * The function helps to generate an array with DE pairs in relation in the right order
           * @param {array} list - An array of selectedDEs
           * @param {array} relations - An array into which relations in the correct order are pushed
           * @param {object} DEFromCollection - fromCollection DE, for which we provide a list from subcollection
           * @return {array} - array with a value pair of relations in proper order
           */
          const getRelationsMapInProperOrder = (list = [], relations = [], DEFromCollection) => {
            if (!list.length) return relations;

            // assign passed relations to relationMap
            const relationMap = relations;

            // loop through each item in the passed list
            list.forEach((currentDE) => {
              if (DEFromCollection) {
                // first make a relation fromCollection DE - subcollection DE
                relationMap.push([DEFromCollection.deAlias, currentDE.deAlias]);
              }

              // for each subcollection
              currentDE.subCollections.forEach((subCollection) => {
                // make a relation currentDE with its subcollection DE
                relationMap.push([currentDE.deAlias, subCollection.deAlias]);

                if (subCollection.subCollections) {
                  // go deeper and do another relation fromCollection DE with its subcollection DE
                  getRelationsMapInProperOrder(subCollection.subCollections, relationMap, subCollection);
                }
              });
            });

            // return map with a value pair of DE in relations in correct order
            return relationMap;
          };

          // Get an array with a value pair of relations in correct order
          const relationsInCorrectOrder = getRelationsMapInProperOrder([selectedDataExtensions[0]]);

          // sort updatedRelations to the corrected relations order
          updatedRelations = _.sortBy(updatedRelations, [(currentRelation) => {
            return relationsInCorrectOrder.findIndex(rel => rel[0] === currentRelation.fromCollection.deAlias &&
              rel[1] === currentRelation.toCollection.deAlias);
          }]);
        }

        /*
         * Update state in Selection with new relation
         * Close modal
         */
        this.setState({ showRelationalModal: false });

        if (handleSetSelectionState) {
          if (selectedDataSet && selectionMode) {
            selectedDataSet.selectedDataExtensions = selectedDataExtensions;
            selectedDataSet.name = 'Custom Data Set';
            selectedDataSet.id = `custom-${selectedDataSet.id}-${new Date().getTime()}`;
            selectedDataSet.relations = updatedRelations;
            selectedDataSet.selectedDEsTree = Util.generateSelectedDEsTree(selectedDataExtensions);
            selectedDataSet.isCustomDataSet = true;
          }

          handleSetSelectionState({
            relations: updatedRelations,
            modalDataExtensions: {},
            showRelationalModal: false,
            movingDE: false,
            ...(selectedDataSet && { selectedDataSet }),
          }, null, checkMissingFieldsInRelations);
        } else {
          setRelations(updatedRelations);
          setModalDataExtensions({});
          setShowRelationalModal(false);
        }
      }
    } else {
      if (this.noCompatibleToAdditionalJoins(findNoCompatibleID) && !this.noCompatibleToFields()) {
        this.setState({ incompatibleJoinObjectId: findNoCompatibleID });

        return false;
      }
      this.setState({ areFieldsIncompatible: true, isIncompatibleFieldJoinable: false });
    }

    return false;
  };

  /**
   * Change field values in select and updating additionalJoins with the new value
   * @param {object} event - event
   * @param {string} id - identifier
   * @param {string} fromOrTo - 'from' collection or 'to' collection
   * @returns {void}
   */
  handleAdditionalJoinsChange = (event, id, fromOrTo) => {
    const { modalDataExtensions } = this.state;
    const { handleSetSelectionState, setModalDataExtensions } = this.props;

    // shallow copies to prevent reference problem
    const modalDataExtensionsCopy = { ...modalDataExtensions };
    const additionalJoinsCopy = [...modalDataExtensions.additionalJoins];
    const fromCollectionCopy = { ...modalDataExtensionsCopy.fromCollection };
    const toCollectionCopy = { ...modalDataExtensionsCopy.toCollection };

    // define a value depending on the event
    const value = event.target ?
      event.target.value :
      event;

    if (fromOrTo === Constants.RELATION_MODAL__FROM) {
      // finds a name from the fromCollectionCopy.fields that is equal to value
      const fromField = fromCollectionCopy.fields.find(f => f.Name.toString() === value);

      // creates a new array for additionalJoins
      const newAdditionalJoins = additionalJoinsCopy.map((join) => {
        if (join.rowID === id) {
          // creates a new object, where fromFieldObjectID is equal to id of selected object
          const newFromObject = {
            ...join,
            fromFieldObjectID: fromField.ObjectID,
            fromFieldName: fromField.Name,
          };

          // checks if the new selected field type is compatible with the field type of toFieldObjectID
          const checkFieldsType = Util.canFieldBeMapped(
            fromField.FieldType,
            toCollectionCopy.fields.find(i => i.ObjectID === join.toFieldObjectID).FieldType,
          );

          if (!checkFieldsType) {
            // finds objects from toCollection, for which field type will be compatible with the field type of fromField
            const toFields = toCollectionCopy.fields.filter(f => (Util
              .canFieldBeMapped(fromField.FieldType, f.FieldType)));

            if (toFields.length) {
              // if finds toField, it creates and returns an object with new fromFieldObjectID and toFieldObjectID data
              const newJoinsObject = {
                ...join,
                fromFieldObjectID: fromField.ObjectID,
                fromFieldName: fromField.Name,
                toFieldObjectID: toFields[0].ObjectID,
                toFieldName: toFields[0].Name,
              };

              return newJoinsObject;
            }
          }

          // if type of fields are compatible, it returns only the object with the new fromFieldObjectID
          return newFromObject;
        }

        // for remaining objects in the array, not equal to ID, returns unchanged value
        return join;
      });

      // update the additionalJoins with a new array
      modalDataExtensionsCopy.additionalJoins = newAdditionalJoins;
    } else if (fromOrTo === Constants.RELATION_MODAL__TO) {
      // finds a name from the toCollectionCopy.fields that is equal to value
      const toField = toCollectionCopy.fields.find(f => f.Name.toString() === value);

      // creates a new array for additionalJoins
      const newAdditionalJoins = additionalJoinsCopy.map((aJC) => {
        if (aJC.rowID === id) {
          // creates and returns a new object, where toFieldObjectID is equal to id of selected object
          const newToObject = {
            ...aJC,
            toFieldObjectID: toField.ObjectID,
            toFieldName: toField.Name,
          };

          return newToObject;
        }

        // for remaining objects in the array, not equal to ID, returns unchanged value
        return aJC;
      });

      // update the additionalJoins with a new array
      modalDataExtensionsCopy.additionalJoins = newAdditionalJoins;
    }

    if (handleSetSelectionState) {
      // update the state in the modal data extension with new data
      handleSetSelectionState({ modalDataExtensions: modalDataExtensionsCopy });
    } else {
      setModalDataExtensions(modalDataExtensionsCopy);
    }

    // set the state of incompatibleJoinObjectId to null
    this.setState({ incompatibleJoinObjectId: null });
  };

  /**
   * Adjust relation fields in relational modal
   * Avoid mutation
   * @param {object} event - event
   * @param {number} toFromIndex - from index
   * @returns {void}
   */
  handleRelationalModalFields = (event, toFromIndex) => {
    const { modalDataExtensions } = this.state;

    let { fromFieldMissing, toFieldMissing } = this.state;
    const { handleSetSelectionState, setModalDataExtensions } = this.props;
    // Shallow copies to prevent reference problem
    const modalDataExtensionsCopy = { ...modalDataExtensions };
    /**
     * 0 <- options on left, options on right -> 1
     * modalDataExtensionsCopy.formCollection.fromField <- current choice
     * event.target.value <- new choice
     */

    if (toFromIndex === 0) {
      const fromCollectionCopy = { ...modalDataExtensionsCopy.fromCollection };
      const toCollectionCopy = { ...modalDataExtensionsCopy.toCollection };
      const value = event.target ?
        event.target.value :
        event;
      // Find the field
      const field = fromCollectionCopy.fields.find(
        f => f.ObjectID === value ||
          f.Name === value,
      );

      fromCollectionCopy.fromField = field.Name.toString();
      fromCollectionCopy.fromFieldType = field.FieldType;
      fromCollectionCopy.fromFieldObjectID = field.ObjectID;

      fromFieldMissing = false;

      /*
       * Check if the fields types fromField and toField are not compatible.
       * If so change toField to be compatible to fromField
       */
      if (!Util.canFieldBeMapped(fromCollectionCopy.fromFieldType, toCollectionCopy.toFieldType)) {
        /**
         * Find the first compatible toField and
         * set it as the default value
         */
        const defaultToField = toCollectionCopy.fields.find(f => Util.canFieldBeMapped(
          fromCollectionCopy.fromFieldType,
          f.FieldType,
        ));

        toCollectionCopy.toField = defaultToField ?
          defaultToField.Name.toString() :
          toCollectionCopy.fields[0].Name.toString();

        toCollectionCopy.toFieldType = defaultToField ?
          defaultToField.FieldType :
          toCollectionCopy.fields[0].FieldType;

        toCollectionCopy.toFieldObjectID = defaultToField ?
          defaultToField.ObjectID :
          toCollectionCopy.fields[0].ObjectID;
      }

      // Update the modalDataExtensionsCopy object
      modalDataExtensionsCopy.fromCollection = fromCollectionCopy;
      modalDataExtensionsCopy.toCollection = toCollectionCopy;
    } else if (toFromIndex === 1) {
      const toCollectionCopy = { ...modalDataExtensionsCopy.toCollection };
      const value = event.target ?
        event.target.value :
        event;

      toFieldMissing = false;
      // Find the field
      const field = toCollectionCopy.fields.find(
        f => f.ObjectID === value ||
          f.Name === value,
      );

      toCollectionCopy.toField = field.Name.toString();
      toCollectionCopy.toFieldType = field.FieldType;
      toCollectionCopy.toFieldObjectID = field.ObjectID;
      // Update the modalDataExtensionsCopy object
      modalDataExtensionsCopy.toCollection = toCollectionCopy;
    }

    if (handleSetSelectionState) {
      /*
       * Update state in Selection with new modal data extension
       */
      handleSetSelectionState({
        modalDataExtensions: modalDataExtensionsCopy,
        fromFieldMissing,
        toFieldMissing,
      });
    } else {
      setModalDataExtensions(modalDataExtensionsCopy);
    }
  };

  /**
   * @param {object} field - The field to be checked
   * @returns {boolean} - The disabled state of the field
   */
  shouldFieldBeDisabled = (field) => {
    const { modalDataExtensions } = this.state;
    const fieldFilteringType = modalDataExtensions.fromCollection.fromFieldType;

    return !Util.canFieldBeMapped(fieldFilteringType, field.FieldType);
  };

  /**
   * @param {object} field - The field to be checked
   * @param {string|undefined} rowID - The ID of the object in additionalJoins if it's passed
   * @returns {boolean} - The disabled state of the field for additionalJoins
   */
  shouldJoinBeDisabled = (field, rowID) => {
    const { modalDataExtensions } = this.state;

    if (modalDataExtensions && modalDataExtensions.additionalJoins &&
      modalDataExtensions.additionalJoins.length && modalDataExtensions.fromCollection && rowID) {
      // finds in additionalJoins array an object equal to rowID and select fromFieldObjectID
      const fromFieldID = modalDataExtensions.additionalJoins
        .find(aJ => aJ.rowID === rowID).fromFieldObjectID;

      // finds in fromCollection.fields an object, that has the same ID as fromFieldObjectID and select FieldType
      const fieldFilteringType = modalDataExtensions.fromCollection.fields
        .find(fCF => fCF.ObjectID === fromFieldID)?.FieldType;

      // checks if field types can be mapped
      return fieldFilteringType && !Util.canFieldBeMapped(fieldFilteringType, field.FieldType);
    }

    return false;
  };

  /**
   * @param {string|undefined} rowID - The ID of the object if it's passed
   * @returns {array <object>} - An array of toCollections.fields objects, with a disabled property added
   */
  returnToFieldsOrToAdditionalFields = (rowID) => {
    const { modalDataExtensions } = this.state;

    let toFields = [];

    if (modalDataExtensions?.toCollection?.fields) {
      toFields = modalDataExtensions.toCollection.fields
        .map((toField) => {
          /**
           * creates a variable, that returns disabled value if type of toField can not be match
           * with type of fromField in additionalJoins
           */
          const disabled = rowID ? this.shouldJoinBeDisabled(toField, rowID) : this.shouldFieldBeDisabled(toField);

          // add disabled variable for each object
          return { ...toField, disabled };
        });
    }

    return toFields;
  };

  /**
   * @param {string} name - The name of object
   * @param {array<object>} fields - An array of fields
   * @returns {object} - Object for which the name parameter is equal to Name property
   */
  findFieldIdByName = (name, fields) => fields.find(f => f.Name === name);

  /**
   * @param {array} additionalJoins - An array of additionalJoins
   * @param {object} fieldBasicFound - The default object that shows in fromField and toField in modalDataExtension
   * @param {string} fromOrTo - 'from' collection or 'to' collection
   * @returns {array} - An array with id that will not be assigned to additionalJoins
   */
  findIds = (additionalJoins, fieldBasicFound, fromOrTo) => {
    let additionalFields;

    if (fromOrTo === Constants.RELATION_MODAL__FROM) {
      additionalFields = additionalJoins.map(addJoin => addJoin.fromFieldObjectID);
    } else {
      additionalFields = additionalJoins.map(addJoin => addJoin.toFieldObjectID);
    }
    /**
     * creates an array with the objectID that is displayed by default in modalDataExtension
     * and previous object from additionalJoins
     */
    if (fieldBasicFound) {
      const foundIds = [fieldBasicFound.ObjectID, ...additionalFields];

      return foundIds;
    }

    return [];
  };

  /**
   * @returns {boolean} - checks if fields from toFields are compatible
   */
  noCompatibleToFields = () => !this.returnToFieldsOrToAdditionalFields().some(f => !f.disabled);

  /**
   * @param {string} rowID - The ID of object from additionalJoins
   * @returns {boolean} - checks if fields from toFields for additionalJoins are compatible
   */
  noCompatibleToAdditionalJoins = rowID => !this.returnToFieldsOrToAdditionalFields(rowID)?.some(f => !f.disabled);

  /**
   * @returns {void} - add new additionalJoin to an additionalJoins array
   */
  addNewRelation = () => {
    const {
      modalDataExtensions,
    } = this.state;
    const { handleSetSelectionState, setModalDataExtensions } = this.props;

    // creates a variable to find no compatible id from additionalJoins
    let findNoCompatibleID;

    if (modalDataExtensions.additionalJoins.length) {
      const allIds = modalDataExtensions.additionalJoins.map(aJ => aJ.rowID);

      findNoCompatibleID = allIds.find(f => this.noCompatibleToAdditionalJoins(f));
    }

    if (modalDataExtensions && !this.noCompatibleToFields() &&
      !this.noCompatibleToAdditionalJoins(findNoCompatibleID) &&
      this.incompatibleJoinObjectId !== null) {
      // Shallow copies to prevent reference problem
      const modalDataExtensionsCopy = { ...modalDataExtensions };
      const additionalJoinsCopy = [...modalDataExtensionsCopy.additionalJoins];
      const fromCollectionCopy = { ...modalDataExtensionsCopy.fromCollection };
      const toCollectionCopy = { ...modalDataExtensionsCopy.toCollection };

      // creates variables for fromFieldMap and toFieldMap
      let fromFieldMap;

      let toFieldMap;

      // creates an variable with the fromCollection object that is displayed by default in modalDataExtension
      const fromFieldBasicFound = this.findFieldIdByName(fromCollectionCopy.fromField, fromCollectionCopy.fields);

      // creates an array with ID`s from fromCollection that will not be assigned to additionalJoins at the beginning
      const foundFromIds = this.findIds(additionalJoinsCopy, fromFieldBasicFound, Constants.RELATION_MODAL__FROM);

      // creates an array from fromCollection with fields that not contain ID`s from foundFromIds
      fromFieldMap = fromCollectionCopy.fields.filter(field => !foundFromIds.includes(field.ObjectID));

      if (!fromFieldMap.length) {
        fromFieldMap = fromCollectionCopy.fields;
      }

      // assign to fromFieldObjectID the ObjectID from the first element of fromFieldMap
      const fromFieldObjectID = fromFieldMap[0]?.ObjectID;
      const fromFieldName = fromFieldMap[0]?.Name;

      // creates an variable with the toCollection object that is displayed by default in modalDataExtension
      const toFieldBasicFound = this.findFieldIdByName(
        toCollectionCopy.toField,
        this.returnToFieldsOrToAdditionalFields(),
      );

      // creates an array with ID`s from toCollection that will not be assigned to additionalJoins at the beginning
      const foundToIds = this.findIds(additionalJoinsCopy, toFieldBasicFound, Constants.RELATION_MODAL__TO);

      // creates an array from toCollection with fields that not contain ID`s from foundToIds
      toFieldMap = toCollectionCopy.fields.filter(field => !foundToIds
        .includes(field.ObjectID) && (Util.canFieldBeMapped(fromFieldMap[0].FieldType, field.FieldType)));

      if (!toFieldMap.length) {
        // finds the first object from toCollection, which is compatible with the first object from fromFieldMap
        toFieldMap = toCollectionCopy.fields.filter(field => Util
          .canFieldBeMapped(fromFieldMap[0].FieldType, field.FieldType));
        if (!toFieldMap.length) {
          /**
           * if compatible object was not found, assign ID of the first object from fromCollection to toFieldObjectID
           * set in the state incompatibleJoinObjectId generated ID
           */
          toFieldMap = toCollectionCopy.fields;
          this.setState({ incompatibleJoinObjectId: Util.uuid() });
        }
      }

      // assign to toFieldObjectID the ObjectID from the first element of toFieldMap
      const toFieldObjectID = toFieldMap[0]?.ObjectID;
      const toFieldName = toFieldMap[0]?.Name;

      // generate an ID for additionalJoins
      const rowID = Util.uuid();

      // create new object for additionalJoins and push to an existing array
      const newJoins = {
        rowID, fromFieldObjectID, toFieldObjectID, fromFieldName, toFieldName,
      };
      const newJoinsCopy = { ...newJoins };

      additionalJoinsCopy.push(newJoinsCopy);

      // update the additionalJoins with a new array
      modalDataExtensionsCopy.additionalJoins = additionalJoinsCopy;

      if (handleSetSelectionState) {
        // update the state in the modal data extension with new data
        handleSetSelectionState({ modalDataExtensions: modalDataExtensionsCopy });
      } else {
        setModalDataExtensions(modalDataExtensionsCopy);
      }

      // if initial values are compatible reset state, otherwise set id
      if (Util.canFieldBeMapped(fromFieldMap[0].FieldType, toFieldMap[0].FieldType)) {
        this.setState({ incompatibleJoinObjectId: null });
      } else {
        this.setState({ incompatibleJoinObjectId: rowID });
      }
    }

    // if the previous fields from additionalJoins are not compatible set ID as a state
    if (this.noCompatibleToAdditionalJoins(findNoCompatibleID)) {
      this.setState({ incompatibleJoinObjectId: findNoCompatibleID });
    } if (this.noCompatibleToFields() && !this.noCompatibleToAdditionalJoins(findNoCompatibleID)) {
      // if fromFieldType and toFieldType are not compatible, set the state
      this.setState({ areFieldsIncompatible: true });
      this.setState({ incompatibleJoinObjectId: null });
    } else if (this.noCompatibleToFields() && this.noCompatibleToAdditionalJoins(findNoCompatibleID)) {
      // if fromFieldType, toFieldType and fields from additionalJoins are not compatible set the state
      this.setState({ incompatibleJoinObjectId: findNoCompatibleID });
      this.setState({ areFieldsIncompatible: true });
    }

    return false;
  };

  /**
   * @param {string} id - The ID of object from additionalJoins
   * @returns {void} - delete an object from additionalJoins array
   */
  removeAdditionalJoins = (id) => {
    const {
      modalDataExtensions, incompatibleJoinObjectId,
    } = this.state;
    const { handleSetSelectionState, setModalDataExtensions } = this.props;

    // Shallow copies to prevent reference problem
    const modalDataExtensionsCopy = { ...modalDataExtensions };
    const additionalJoinsCopy = [...modalDataExtensionsCopy.additionalJoins];

    // create a new array with an object to be removed
    const newAdditionalJoins = additionalJoinsCopy.filter((e) => {
      if (e.rowID !== id) {
        return e;
      }
      if (incompatibleJoinObjectId === e.rowID) {
        this.setState({ incompatibleJoinObjectId: null });
      }

      return null;
    });

    // update the additionalJoins
    modalDataExtensionsCopy.additionalJoins = newAdditionalJoins;

    if (handleSetSelectionState) {
      // update the state in the modal data extension with new data
      handleSetSelectionState({ modalDataExtensions: modalDataExtensionsCopy });
    } else {
      setModalDataExtensions(modalDataExtensionsCopy);
    }
  };

  /**
   * Checks if fields in modalDataExtension are compatible and can be saved
   * @param {string} fromFieldType - type of fromField to be checked
   * @param {string} toFieldType - type of toField to be checked
   * @param {string} findNoCompatibleID - no compatible object id from additionalJoins
   * @returns {boolean} - returns boolean state if the fields are compatible
   */
  checkIfCompatible(fromFieldType, toFieldType, findNoCompatibleID) {
    const { incompatibleJoinObjectId } = this.state;

    if ((Util.canFieldBeMapped(fromFieldType, toFieldType) && incompatibleJoinObjectId === null &&
      !this.noCompatibleToAdditionalJoins(findNoCompatibleID))) {
      return true;
    }

    return false;
  }

  /**
   * this function renders error or warnings based on conditions
   * @param {boolean} isJoinedWithWarning - indicates that fields are incompatible but can be mapped
   * @param {boolean} areFieldsNotCompatible - indicates that fields are incompatible and cannot be mapped
   * @param {boolean} isFieldDeleted - indicates that field has been deleted
   * @param {string} deletedFieldName - The deleted field's name
   * @param {string} currentFieldName - The current field's name
   * @returns {string} error or warning text
   */
  renderJoinError = (
    isJoinedWithWarning,
    areFieldsNotCompatible,
    isFieldDeleted,
    deletedFieldName,
    currentFieldName,
  ) => {
    if (isJoinedWithWarning) {
      // return warning message if fields are incompatible but can be mapped
      return (
        <span className="warning">
          {Constants.RELATION_MODAL__JOIN_WARNING__TEXT}
        </span>
      );
    }
    if (areFieldsNotCompatible) {
      // return error message if fields are incompatible
      return 'Please select another field in the dropdown from the Data Extension on the left.';
    }
    if (isFieldDeleted) {
      // return error message if field has been deleted
      return `The selected field '${deletedFieldName}' has been deleted and replaced by ` +
        `'${currentFieldName}'. Please choose your preferred field.`;
    }

    return '';
  };

  render() {
    const { width } = this.state;
    /**
     * Take information from both data extensions and set possibilities for relation options
     * @param {string} leftDEAlias - Alias of parent DE
     * @param {string} relation - Particular relation between DE
     * @param {string} rightDEAlias - Alias of child DE
     * @returns {string} The relation option label
     */
    const createRelationOptionLabel = (leftDEAlias, relation, rightDEAlias) => {
      const isCrossJoinRelation = relation === Constants.RELATIONTYPE__UPPERCASE_JOIN_TEXT__CROSS_JOIN;

      // condition if width state of myRef element is not 0
      if (width !== 0) {
        // creates variable number to specify number of characters for string
        const number = Util.returnNumberBasedOnWidth(width);

        // creates variable that specifies max. number of characters for each: leftDEAlias and rightDEAlias
        const remainingLength = Math.floor((number - relation.length) / 2);

        // creates variables that specifies length for leftDEAlias and rightDEAlias
        let leftAliasLength;

        let rightAliasLength;

        let labelText;

        // selects the minimum length based on leftDEAlias and rightDEAlias
        const minLength = Math.min(leftDEAlias.length, rightDEAlias.length);

        // if minLength is greater than/equal to remainingLength, let remainingLength be a length for new variables
        if (minLength >= remainingLength) {
          leftAliasLength = remainingLength;
          rightAliasLength = remainingLength;
        } else if (minLength === leftDEAlias.length) {
          // if leftDEAlias length is equal to minLength, then add to another variable the rest of the available space
          leftAliasLength = minLength;
          rightAliasLength = remainingLength + (remainingLength - leftAliasLength);
        } else {
          // in another case, let rightAliasLength be minLength and then add to another the rest of the available space
          rightAliasLength = minLength;
          leftAliasLength = remainingLength + (remainingLength - rightAliasLength);
        }

        if (isCrossJoinRelation) {
          labelText = (
            `${Constants.RELATIONTYPE__UPPERCASE_JOIN_TEXT__CROSS_JOIN_FROM} ` +
            Util.abbreviate(leftDEAlias, leftAliasLength) +
            ` ${Constants.RELATIONTYPE__UPPERCASE_JOIN_TEXT__CROSS_JOIN_TO} ` +
            Util.abbreviate(rightDEAlias, rightAliasLength));
        } else {
          labelText = (
            Util.abbreviate(leftDEAlias, leftAliasLength) +
            ` ${relation} ` +
            Util.abbreviate(rightDEAlias, rightAliasLength));
        }

        return labelText;
      }

      return false;
    };

    /**
     * Retrieves the collection field name
     * @param {object} modalDataExtensions - Is the modal DE
     * @param {string} fromOrTo - 'from' collection or 'to' collection
     * @returns {string|null} The collection field name or null if no value is found
     */
    const collectionField = (modalDataExtensions, fromOrTo) => {
      let collection = null;

      if (fromOrTo === Constants.RELATION_MODAL__FROM) {
        collection = modalDataExtensions.fromCollection;
        if (collection.fromFieldObjectID) return collection.fromFieldObjectID;
      } else if (fromOrTo === Constants.RELATION_MODAL__TO) {
        collection = modalDataExtensions.toCollection;
        if (collection.toFieldObjectID) return collection.toFieldObjectID;
      }
      if (collection && collection.fields) return collection.fields[0]?.Name.toString();

      return null;
    };

    /**
     * Retrieves the correct image path for the join type passed as parameter
     * @param {string} joinType - Is the type of join
     * @returns {string} The image path corresponding to the join type
     */
    const joinTypeImage = (joinType) => {
      if (joinType === Constants.RELATIONTYPE__LEFT_WITHOUT) return '/img/left-without.png';
      if (joinType === Constants.RELATIONTYPE__RIGHT_WITHOUT) return '/img/right-without.png';

      return `/img/${joinType.toString().toLowerCase()}-join.png`;
    };

    const {
      showRelationalModal,
      modalDataExtensions,
      areFieldsIncompatible,
      fromFieldMissing,
      toFieldMissing,
      incompatibleJoinObjectId,
      isIncompatibleFieldJoinable,
      isPredefinedRelation,
      areAdditionalJoinsFromPredefinedRelations,
    } = this.state;
    const { isDataSetDEModal } = this.props;

    const { additionalJoins, fromCollection, toCollection } = modalDataExtensions || {};

    const isCrossJoinRelation = modalDataExtensions?.joinType === Constants.RELATIONTYPE__CROSS;

    const toFields = this.returnToFieldsOrToAdditionalFields();
    const noCompatibleToFields = !isDataSetDEModal && this.noCompatibleToFields();

    // define fromCollection and toCollection alias
    const fromCollectionDeAlias = modalDataExtensions?.fromCollection?.deAlias;
    const toCollectionDeAlias = modalDataExtensions?.toCollection?.deAlias;

    // define relation type options for the dropdown
    const relationTypeOptions = modalDataExtensions?.fromCollection ?
      [
        {
          value: Constants.RELATIONTYPE__INNER,
          title: `${fromCollectionDeAlias}
          ${Constants.RELATIONTYPE__UPPERCASE_JOIN_TEXT__WITH_MATCHING}
          ${toCollectionDeAlias}`,
          label: createRelationOptionLabel(
            fromCollectionDeAlias,
            Constants.RELATIONTYPE__UPPERCASE_JOIN_TEXT__WITH_MATCHING,
            toCollectionDeAlias,
          ),
        },
        {
          value: Constants.RELATIONTYPE__LEFT,
          title: `${fromCollectionDeAlias}
          ${Constants.RELATIONTYPE__UPPERCASE_JOIN_TEXT__WITH_WITHOUT_MATCHING}
          ${toCollectionDeAlias}`,
          label: createRelationOptionLabel(
            fromCollectionDeAlias,
            Constants.RELATIONTYPE__UPPERCASE_JOIN_TEXT__WITH_WITHOUT_MATCHING,
            toCollectionDeAlias,
          ),
        },
        {
          value: Constants.RELATIONTYPE__RIGHT,
          title: `${toCollectionDeAlias}
          ${Constants.RELATIONTYPE__UPPERCASE_JOIN_TEXT__WITH_WITHOUT_MATCHING}
          ${fromCollectionDeAlias}`,
          label: createRelationOptionLabel(
            toCollectionDeAlias,
            Constants.RELATIONTYPE__UPPERCASE_JOIN_TEXT__WITH_WITHOUT_MATCHING,
            fromCollectionDeAlias,
          ),
        },
        {
          value: Constants.RELATIONTYPE__FULL,
          title: `${fromCollectionDeAlias}
          ${Constants.RELATIONTYPE__UPPERCASE_JOIN_TEXT__WITH_ALL}
          ${toCollectionDeAlias}`,
          label: createRelationOptionLabel(
            fromCollectionDeAlias,
            Constants.RELATIONTYPE__UPPERCASE_JOIN_TEXT__WITH_ALL,
            toCollectionDeAlias,
          ),
        },
        {
          value: Constants.RELATIONTYPE__LEFT_WITHOUT,
          title: `${fromCollectionDeAlias}
          ${Constants.RELATIONTYPE__UPPERCASE_JOIN_TEXT__WITHOUT_MATCHING}
          ${toCollectionDeAlias}`,
          label: createRelationOptionLabel(
            fromCollectionDeAlias,
            Constants.RELATIONTYPE__UPPERCASE_JOIN_TEXT__WITHOUT_MATCHING,
            toCollectionDeAlias,
          ),
        },
        {
          value: Constants.RELATIONTYPE__RIGHT_WITHOUT,
          title: `${toCollectionDeAlias}
          ${Constants.RELATIONTYPE__UPPERCASE_JOIN_TEXT__WITHOUT_MATCHING}
          ${fromCollectionDeAlias}`,
          label: createRelationOptionLabel(
            toCollectionDeAlias,
            Constants.RELATIONTYPE__UPPERCASE_JOIN_TEXT__WITHOUT_MATCHING,
            fromCollectionDeAlias,
          ),
        },
        {
          value: Constants.RELATIONTYPE__CROSS,
          title: `
          ${Constants.RELATIONTYPE__UPPERCASE_JOIN_TEXT__CROSS_JOIN_FROM}
          ${fromCollectionDeAlias}
          ${Constants.RELATIONTYPE__UPPERCASE_JOIN_TEXT__CROSS_JOIN_TO}
          ${toCollectionDeAlias}`,
          label: createRelationOptionLabel(
            fromCollectionDeAlias,
            Constants.RELATIONTYPE__UPPERCASE_JOIN_TEXT__CROSS_JOIN,
            toCollectionDeAlias,
          ),
        },
      ] :
      [];

    const showingIncompatibilityOrMissingFieldsError = isIncompatibleFieldJoinable || areFieldsIncompatible ||
      toFieldMissing || fromFieldMissing;

    const classNamesForRelationWrapper = classNames(
      'relation-wrapper',
      { 'align-items': showingIncompatibilityOrMissingFieldsError },
    );

    const classNamesForSldsFormElement = classNames(
      'slds-form-element',
      { 'slds-form-element-bottom': showingIncompatibilityOrMissingFieldsError },
    );

    return modalDataExtensions?.fromCollection ?
      (
        <div>
          <ModalTemplate
            id="relation-modal-dialog"
            headerId="modal-heading-01"
            headerTitle="Create Relationship"
            cancelButtonId="relation-modal-cancel"
            handleCancel={this.handleRelationalModalClose}
            saveButtonId="save-relation-modal"
            handleSave={this.handleRelationalModalSave}
            ariaHidden={showRelationalModal}
            className="slds-modal_large"
          >
            <div className={classNamesForRelationWrapper}
            >
              <div className="relation-wrapper_one">
                <p
                  className="text-truncate"
                  id="relation-model-from-collection-label"
                  title={
                    `${modalDataExtensions.fromCollection.deAlias} \n
                        ${modalDataExtensions.fromCollection.Name.toString()}`
                  }
                >
                  {modalDataExtensions.fromCollection.deAlias}
                  <br />({modalDataExtensions.fromCollection.Name.toString()})
                </p>
                <br />
                <div id="relation-modal-from-collection-field">
                  <div className={`slds-form-element ${fromFieldMissing ? 'slds-has-error' : ''}`}>
                    <div className={classNames('slds-form-element__control', { 'ghost-element': isCrossJoinRelation })}>
                      <Select
                        id="relation-modal-from-field"
                        onChange={e => this.handleRelationalModalFields(e, 0)}
                        value={collectionField(modalDataExtensions, Constants.RELATION_MODAL__FROM)}
                        onLoad={modalDataExtensions.fromCollection.fromField ?
                          null :
                          this.handleRelationalModalFields(
                            modalDataExtensions.fromCollection.fields ?
                              modalDataExtensions.fromCollection.fields[0]?.ObjectID :
                              null,
                            0,
                          )}
                        options={modalDataExtensions.fromCollection.fields.map(field => ({
                          value: field.ObjectID,
                          disabled: field.disabled,
                          label: field.Name.toString(),
                        }))}
                        dataSetModalOption={isDataSetDEModal ?
                          {
                            value: modalDataExtensions.fromCollection.fromFieldObjectID,
                            key: modalDataExtensions.fromCollection.fromField.toString(),
                          } :
                          null}
                        disabled={isDataSetDEModal || isCrossJoinRelation}
                      />
                    </div>
                    {
                      fromFieldMissing && (
                        <div className="slds-form-element__help" id="incompatible-fields-error">{
                          `The selected field '${fromCollection.deletedFieldName}' has been deleted ` +
                          `and replaced by '${fromCollection.fromField}'. Please choose your preferred field.`
                        }
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
              <div className="relation-wrapper_center">
                <img
                  id="relation-modal-relation-type-img"
                  src={joinTypeImage(modalDataExtensions.joinType)}
                  alt="relation type img"
                  className={(showingIncompatibilityOrMissingFieldsError ?
                    ' margin-bottom' :
                    '')}
                />
                <br />
                <div
                  className={classNamesForSldsFormElement}
                >
                  <div className="slds-form-element__control">
                    <Select
                      id="relation-modal-relation-type"
                      onChange={e => this.handleRelationalModalRelationType(e)}
                      value={modalDataExtensions.joinType}
                      forwardRef={this.myRef}
                      options={relationTypeOptions}
                    />
                  </div>
                </div>
              </div>

              <div className="relation-wrapper_last">
                <p
                  className="text-truncate"
                  id="relation-model-to-collection-label"
                  title={`${modalDataExtensions.toCollection.deAlias} \n
                      ${modalDataExtensions.toCollection.Name.toString()}`}
                >
                  {modalDataExtensions.toCollection.deAlias}
                  <br />({modalDataExtensions.toCollection.Name.toString()})
                </p>
                <br />
                <div id="relation-modal-to-collection-field">
                  <div className={`slds-form-element
                    ${areFieldsIncompatible || toFieldMissing ? 'slds-has-error' : ''}`}
                  >
                    <div className={classNames('slds-form-element__control', { 'ghost-element': isCrossJoinRelation })}>
                      <div style={{ flexGrow: 1 }}>
                        <Select
                          id="relation-modal-to-field"
                          disabled={(noCompatibleToFields && !isDataSetDEModal) ||
                            isDataSetDEModal || isCrossJoinRelation}
                          onChange={e => this.handleRelationalModalFields(e, 1)}
                          value={collectionField(modalDataExtensions, Constants.RELATION_MODAL__TO)}
                          noOptionsLabel="No fields are compatible"
                          options={noCompatibleToFields ?
                            [] :
                            toFields.map(field => ({
                              value: field.ObjectID,
                              disabled: field.disabled,
                              label: field.Name.toString(),
                            }))}
                          dataSetModalOption={isDataSetDEModal ?
                            {
                              value: modalDataExtensions.toCollection.toFieldObjectID,
                              key: modalDataExtensions.toCollection.toField.toString(),
                            } :
                            null}
                        />
                      </div>
                      {!isDataSetDEModal && !isCrossJoinRelation && (
                        <Tooltip
                          nubbinPosition={Constants.NUBBIN_POSITION__BOTTOM_RIGHT}
                          type={Constants.TOOLTIP_TYPE__RELATION_MODAL}
                        />
                      )}
                    </div>
                    {(areFieldsIncompatible || toFieldMissing || isIncompatibleFieldJoinable) && (
                      <div className="slds-form-element__help warning-container">
                        {this.renderJoinError(
                          isIncompatibleFieldJoinable,
                          areFieldsIncompatible,
                          toFieldMissing,
                          toCollection.deletedFieldName,
                          toCollection.toField,
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {
              isCrossJoinRelation && (
                <div className="cross-join-container">
                  <p className="cross-join-container__text">
                    This type of join, also known as a CROSS JOIN, is used to generate a paired
                    combination of each row of the first data extension with each row of the
                    second data extension. Also, note that field matching is not required in this
                    type of a join.
                  </p>
                </div>

              )
            }
            {!isCrossJoinRelation && isPredefinedRelation &&
              areAdditionalJoinsFromPredefinedRelations && (
                <span
                  className="predefined-relation-text"
                >
                  Fields are set according to a predefined relation.
                </span>
            )}
            {(additionalJoins && !isCrossJoinRelation && additionalJoins.length && !noCompatibleToFields) ?
              (additionalJoins.map(e => (
                <AdditionalJoinsComponent
                  key={e.rowID}
                  modalDataExtensions={modalDataExtensions}
                  incompatibleJoinObjectId={incompatibleJoinObjectId}
                  noCompatibleToAdditionalJoins={this.noCompatibleToAdditionalJoins(e.rowID)}
                  toFields={this.returnToFieldsOrToAdditionalFields(e.rowID)}
                  handleAdditionalJoinsChange={this.handleAdditionalJoinsChange}
                  id={e.rowID}
                  removeAdditionalJoins={this.removeAdditionalJoins}
                  renderJoinError={this.renderJoinError}
                  isDataSetDEModal={isDataSetDEModal}
                  fromFieldMissing={e.fromFieldMissing}
                  toFieldMissing={e.toFieldMissing}
                  deletedFromFieldName={e.deletedFromFieldName}
                  deletedToFieldName={e.deletedToFieldName}
                />
              ))) :
              (
                null
              )}
            {!isDataSetDEModal && (
              <Button
                id="relation-modal-button-add-relationship"
                buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
                className={classNames('black', { 'ghost-element': isCrossJoinRelation })}
                ariaDescribedBy="addRelationship"
                title="addRelationship"
                onClick={() => this.addNewRelation()}
                disabled={isCrossJoinRelation}
              >
                <svg
                  className="slds-button__icon slds-icon_small"
                  id="plus-icon"
                  aria-hidden="true"
                >
                  <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#add" />
                </svg>
                Add Relationship
              </Button>
            )}
          </ModalTemplate>
        </div>
      ) :
      null;
  }
}

RelationModal.propTypes = {
  /**
   * Determines if the relational modal will be shown.
   */
  showRelationalModal: PropTypes.bool.isRequired,
  /**
   * It keeps the selected data extensions for Selection.js
   * selected data extensions are stored as collections in database
   * It will be passed from Selection.js
   */
  selectedDataExtensions: PropTypes.instanceOf(Array),
  /**
   * It keeps the relation between selected data extensions
   * It will be passed from Selection.js
   */
  relations: PropTypes.instanceOf(Array),
  /**
   * It helps to set the Selection`s state
   * It will be passed from Selection.js
   */
  handleSetSelectionState: PropTypes.func,
  /**
   * It keeps the data extensions those will be shown in the Relation Modal
   */
  modalDataExtensions: PropTypes.instanceOf(Object),
  /**
   * Responsible for adding/deleting fields Object IDs when searching picklist for the options
   * it will be passed from Selection.js
   */
  handlePickListOptions: PropTypes.func,
  /**
   * Indicates whether the selected fromField has been deleted
   */
  fromFieldMissing: PropTypes.bool,
  /**
   * Indicates whether the selected toField has been deleted
   */
  toFieldMissing: PropTypes.bool,
  /**
   * Checks if there are any deleted fields in relations
   */
  checkMissingFieldsInRelations: PropTypes.func,
  /**
   * An object that stores the object ids of found predefined relations in a selection
   */
  predefinedRelationsMap: PropTypes.instanceOf(Object).isRequired,
  /**
   * An array containing relations that were defined in the admin panel
   */
  predefinedRelations: PropTypes.instanceOf(Array).isRequired,
  /**
   * Indicates whether a DE is being moved
   */
  movingDE: PropTypes.bool,
  /**
   * Copied selectedDEs before addition and removal of DEs from a subcollection
   * when moving DEs
   */
  prevSelectedDEs: PropTypes.instanceOf(Array),
  /**
   * Copied selectedDEs before addition and removal of DEs from a subcollection
   * when moving DEs
   */
  prevRelations: PropTypes.instanceOf(Array),
  /**
   * Determines if this component is being used from the data set modal
   */
  isDataSetDEModal: PropTypes.bool,
  /**
   * Keeps the details about the relationship between two DEs
   */
  setModalDataExtensions: PropTypes.func,
  /**
   * Sets selected DEs
   */
  setSelectedDataExtensions: PropTypes.func,
  /**
   * Opens the relationship modal between two DEs
   */
  setShowRelationalModal: PropTypes.func,
  /**
   * Sets the relation array
   */
  setRelations: PropTypes.func,
  /**
   * Selected dataSet
   */
  selectedDataSet: PropTypes.instanceOf(Object),
  /**
   * If user is in Basic/Advance or no mode
   */
  selectionMode: PropTypes.string,
};

export default RelationModal;
