import React from 'react';
import PropTypes from 'prop-types';
import { animated, useSpring } from '@react-spring/web';

import ModalTemplate from '../../shared/ModalTemplate/ModalTemplate';
import Deedee from '../../../gif/deedee-summer-animation-sky.gif';

import './styles.scss';

/**
 * HTML for no scope data extensions modal
 * @param {object} param - An object with 'isOpen' and 'closeModal' properties
 * @returns {object} The modal
 */
const NoScopeDeModal = ({ isOpen, closeModal }) => {
  // React spring animation
  const textAnimation = useSpring({
    from: { opacity: 0, transform: 'translate3d(0,-40px,0)' },
    to: {
      opacity: isOpen ? 1 : 0,
      transform: isOpen ? 'translate3d(0,0px,0)' : 'translate3d(0,-40px,0)',
    },
  });

  const imageAnimation = useSpring({
    from: { transform: 'scale(0.5)' },
    to: { transform: 'scale(1)' },
    delay: 100, // Delay here gives time for image to animate before text
  });

  return (
    <ModalTemplate
      id="no-data-extension-scope-modal"
      headerId="modal-heading-01"
      headerTitle="Setup Deedee AI"
      contentClassName="slds-p-around_medium scope-de-body"
      handleCancel={isOpen ? closeModal : null}
      cancelButtonId={isOpen ? 'cancelError' : null}
      cancelButtonTitle="OK"
    >
      <h2 className="onboarding-screen__title">Welcome to Deedee AI</h2>
      <animated.img
         src={Deedee}
         alt="Welcome onboarding message"
         className="onboarding-screen__image"
         style={imageAnimation}
      />
      <ul className="information-text">
        <animated.li className="slds-m-top_x-small" style={textAnimation}>
          Deedee AI revolutionizes the way you create selections, with a
          simple prompt, you can generate sophisticated selections.
        </animated.li>
        <animated.li className="slds-m-top_x-small" style={textAnimation}>
          It seems you dont currently have any Data Extensions in scope to use
          with DeedeeAI.
        </animated.li>
        <animated.li className="slds-m-top_x-small" style={textAnimation}>
          Please
          {' '}
          <strong>contact your Administrator</strong>
          {' '}
          to add the necessary Data Extensions
          to your scope.
        </animated.li>
      </ul>
    </ModalTemplate>
  );
};

NoScopeDeModal.propTypes = {
  /*
   * isOpen indicates whether the modal is currently open.
   * this prop is passed from the parent component
   */
  isOpen: PropTypes.bool.isRequired,
  /*
   * closeModal is a function to close the modal.
   * this prop is a function and it is passed from the parent component
   */
  closeModal: PropTypes.func.isRequired,
};

export default NoScopeDeModal;
