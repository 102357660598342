
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Constants from '../../../constants/constants';
import Button from '../Button/Button';
import './styles.scss';

const Alert = ({
  title,
  id,
  assistiveText,
  dangerouslySetInnerHTML,
  withoutIcon = false,
  headerId,
  forwardRef,
  className,
  renderInCell = false,
  type,
  closeButton = false,
  closeText,
  closeAlert,
  buttonAssistiveText,
  symbolSize,
}) => {
  /**
   * Renders proper class name depending on 'type' prop
   * @returns {string} class name
   */
  const renderThemeByType = () => {
    if(type) { return `slds-theme_${type === Constants.NOTIFICATION__TYPE__INFO ? 'offline' : type}`; }

    return `slds-theme_${Constants.ALERT__TYPE__ERROR}`;
  };

  // class name for the container of the alert component
  const alertClassName = classNames(
    'slds-notify slds-notify_alert slds-theme_alert-texture',
    renderThemeByType(),
    ` ${className || ''}`,
  );

  /**
   * Renders HTML object for the content of the alert component
   * @returns {object} HTML object for alert content
   */
  const renderAlertContent = () => (
    <>
      {assistiveText && <span className="slds-assistive-text">{assistiveText}</span>}
      <span className={`slds-icon_container slds-icon-utility-${type ||
        Constants.ALERT__TYPE__ERROR} slds-m-right_x-small`}>
      {withoutIcon ?
        null :
        <svg className={`slds-icon slds-icon_${symbolSize || 'x-small'}`} aria-hidden="true">
          <use xlinkHref={`/assets/icons/utility-sprite/svg/symbols.svg#${type ||
            Constants.ALERT__TYPE__ERROR}`} />
        </svg>}
      </span>
      {dangerouslySetInnerHTML ?
        <h2 dangerouslySetInnerHTML={({ __html: dangerouslySetInnerHTML })} id={headerId || ''} /> :
        <h2 id={headerId || ''}>{title}</h2>}
      {closeButton &&
        <div className="slds-notify__close">
          <span>{closeText}</span>
          <input type="checkbox" aria-label="Alert checkbox" />
          <Button
            buttonIcon
            noSpan
            className="slds-button_icon-small"
            title="Close"
            onClick={closeAlert}
          >
            <svg className="slds-button__icon" aria-hidden="true">
              <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#close" />
            </svg>
            <span className="slds-assistive-text">{buttonAssistiveText}</span>
          </Button>
        </div>}
    </>
  );

  return renderInCell ?
    <td className={alertClassName}>
      {renderAlertContent()}
    </td> :
    <div
      className={alertClassName}
      role="alert"
      id={id || ''}
      ref={forwardRef}
    >
      {renderAlertContent()}
    </div>;
};

Alert.propTypes = {
  /**
   * title for the alert header
   */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  /**
   * id for the container of the alert component
   */
  id: PropTypes.string,
  /**
   * assistive text for the alert content
   */
  assistiveText: PropTypes.string,
  /**
   * HTML content for dangerouslySetInnerHTML
   */
  dangerouslySetInnerHTML: PropTypes.string,
  /**
   * indicates that alert will be displayed without alert icon
   */
  withoutIcon: PropTypes.bool,
  /**
   * id for the alert header
   */
  headerId: PropTypes.string,
  /**
   * Ref element of alert
   */
  forwardRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.elementType }),
  ]),
  /**
   * className for the container of the alert component
   */
  className: PropTypes.string,
  /**
   * indicates that alert content will be wrapped by td element, because it's rendered in the table
   */
  renderInCell: PropTypes.bool,
  /**
   * theme type for alert
   */
  type: PropTypes.string,
  /**
   * mainly used for notifications - indicates whether the closing button of the component is to be rendered
   */
  closeButton: PropTypes.bool,
  /**
   * text that will be rendered next to the button to close the alert
   */
  closeText: PropTypes.string,
  /**
   * onClick function for the button closing the alert
   */
  closeAlert: PropTypes.func,
  /**
   * assistive text for the button closing the alert
   */
  buttonAssistiveText: PropTypes.string,
  /**
   * the size of the icon that appears next to the alert message
   */
  symbolSize: PropTypes.string,

};

export default Alert;
