import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.scss';
import { connect } from 'react-redux';

import mapStateToProps from '../../../../../mapStateToProps';
import Features from '../../../../../features';
import Constants from '../../../../../constants/constants';
import Button from '../../../../shared_v2/Button/Button';
import GuidanceTip from '../../../../shared_v2/GuidanceTip/GuidanceTip';
import { featureAdvertExists } from '../../../../shared_v2/Feature/featureUtil';
import UpgradeBadge from '../../../../shared_v2/UpgradeBadge/UpgradeBadge';
import Tooltip from '../../../../shared/Tooltip/Tooltip';

const SettingsDropdown = ({
  handleSetSelectionState,
  openSettings,
  handleOpenEditTargetDE,
  handleOpenDataActionModal,
  disableSettingsDropdown,
  handleOpenSortLimitModal,
  handleOpenSourceLimitingModal,
  featuresInfo,
}) => {
  const featurePrioDedup = Features.isFeatureEnabled(
    featuresInfo,
    Constants.FEATURE__PRIO_DEDUP,
  );

  const featureSourceLimiting = Features.isFeatureEnabled(
    featuresInfo,
    Constants.FEATURE__SOURCE_LIMITING,
  );

  const enableLinkToPrioDedupScreen = (
    featurePrioDedup === Constants.FEATURE__PRIO_DEDUP__BASIC ||
    featurePrioDedup === Constants.FEATURE__PRIO_DEDUP__ADVANCED);

  /**
   * Handle clicking on the Settings button
   * @returns {void}
   */
  const handleClickSettings = () => {
    handleSetSelectionState({ openSettings: !openSettings });
  };

  /**
   * Handle clicking on the Data Action link
   * @returns {void}
   */
  const handleClickDataAction = () => {
    handleSetSelectionState({
      openSettings: !openSettings,
    });
    handleOpenDataActionModal();
  };

  /**
   * Handle clicking on the Source Limiting link
   * @returns {void}
   */
  const handleClickSourceLimiting = () => {
    handleSetSelectionState({
      openSettings: !openSettings,
    });
    handleOpenSourceLimitingModal();
  };

  /**
   * Handle clicking on the Edit Target DE Fields link
   * @returns {void}
   */
  const handleClickEditTargetDEFields = () => {
    handleOpenEditTargetDE();
  };

  /**
   * AHandle clicking on the Deduplication link
   * @returns {void}
   */
  const handleClickPrioDeduplication = () => {
    handleSetSelectionState({
      switchToDedup: true,
      openSettings: !openSettings,
    });
  };

  /**
   * Handle clicking on the Sort and Limit link
   * @returns {void}
   */
  const handleClickSortLimit = () => {
    handleSetSelectionState({
      openSettings: !openSettings,
    });
    handleOpenSortLimitModal();
  };

  return (
    <div className="settings-dropdown">
      <div className="slds-button-group" role="group">
        <div className="slds-button slds-button_icon">
          <Button
            buttonIconBorderFilled
            buttonIcon
            title="Settings"
            onClick={handleClickSettings}
            disabled={disableSettingsDropdown}
            id="settings-menu"
            noSpan
          >
            <svg className="slds-button__icon" aria-hidden="true">
              <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#settings" />
            </svg>
            <svg className="slds-button__icon slds-button__icon_x-small" aria-hidden="true">
              <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#down" />
            </svg>
          </Button>
          <div
            className={`slds-dropdown slds-dropdown_right slds-dropdown_actions ${openSettings &&
                        'active'}`}
          >
            <ul className="slds-dropdown__list" role="menu">
              <li
                className="slds-dropdown__item"
                role="presentation"
                onClick={handleClickDataAction}
                id="set-data-action"
              >
                <a
                  href="#!"
                  role="menuitem"
                  tabIndex="0"
                >
                  <span
                    className="slds-truncate"
                    title="Set Data Action"
                  >
                    Set Data Action
                  </span>
                  <GuidanceTip tipId="data-action-tip" toolTipPosition="right" />
                </a>
              </li>
              <li
                className="slds-dropdown__item"
                role="presentation"
                onClick={handleClickEditTargetDEFields}
                title="Edit Target Data Extension"
                id="edit-target-data-extension"
              >
                <a role="menuitem" tabIndex="-1" href="#!">
                  <span className="slds-truncate">
                    Edit Target Data Extension
                  </span>
                </a>
              </li>
              {enableLinkToPrioDedupScreen ?
                (
                  <li
                    className="slds-dropdown__item"
                    role="presentation"
                    onClick={handleClickPrioDeduplication}
                    id="prio-deduplication"
                  >
                    <a
                      href="#!"
                      role="menuitem"
                      tabIndex="-2"
                    >
                      <span
                        className="slds-truncate"
                        title="Deduplication"
                        type="button"
                      >
                        Deduplication
                      </span>
                    </a>
                  </li>
                ) :
                (
                  <li
                    className={classNames(
                      'slds-dropdown__item',
                      {
                        'disabled-overlay-priodedup': !enableLinkToPrioDedupScreen,
                      },
                    )}
                    onClick={() => handleSetSelectionState({
                      featureAdvertModal: {
                        show: true,
                        feature: Constants.FEATURE__PRIO_DEDUP,
                      },
                    })}
                    role="presentation"
                    id="prio-deduplication-disabled">
                    <a
                      className="disabled-dropdown-item"
                      href="#!"
                      role="menuitem"
                      tabIndex="-2"
                    >
                      <span
                        className="slds-truncate"
                        title="This feature is not enabled in your current DESelect plan."
                        type="button"
                      >
                        Deduplication
                      </span>
                    </a>
                    {
                      featureAdvertExists(Constants.FEATURE__PRIO_DEDUP) && (
                        <UpgradeBadge />
                      )
                    }
                    <Tooltip
                      nubbinPosition={Constants.NUBBIN_POSITION__TOP_RIGHT}
                      type={Constants.TOOLTIP_TYPE__UNAVAILABLE_FEATURE}
                    />
                  </li>
                )}
              <li
                className="slds-dropdown__item"
                role="presentation"
                onClick={handleClickSortLimit}
                id="sort-limit"
              >
                <a
                  href="#!"
                  role="menuitem"
                  tabIndex="0"
                >
                  <span
                    className="slds-truncate"
                    title="Sort & Limit"
                  >
                    Sort & Limit
                  </span>
                  <GuidanceTip tipId="sort-limit-tip" toolTipPosition="right" />
                </a>
              </li>
              {
                featureSourceLimiting && (
                <li
                  className="slds-dropdown__item"
                  role="presentation"
                  onClick={handleClickSourceLimiting}
                  id="source-limiting"
                >
                  <a
                    href="#!"
                    role="menuitem"
                    tabIndex="0"
                  >
                    <span
                      className="slds-truncate"
                      title="Source Limiting"
                    >
                      Source Limiting
                    </span>
                  </a>
                </li>
                )
              }
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

SettingsDropdown.propTypes = {
  /**
   * Sets the state of Selection.js
   */
  handleSetSelectionState: PropTypes.func.isRequired,
  /**
   * State indicating if Settings menu is open or not
   */
  openSettings: PropTypes.bool.isRequired,
  /**
   * Function to open the DataActionModal
   */
  handleOpenDataActionModal: PropTypes.func.isRequired,
  /**
   * State indicating if edit target de fields mode is on
   */
  handleOpenEditTargetDE: PropTypes.func.isRequired,
  /**
   * Boolean indicating if settings menu should be enabled
   */
  disableSettingsDropdown: PropTypes.bool.isRequired,
  /**
   * Function to open the SortLimitModal
   */
  handleOpenSortLimitModal: PropTypes.func.isRequired,

  /**
   * Function to open the SourceLimitingModal
   */
  handleOpenSourceLimitingModal: PropTypes.func.isRequired,
  /**
   * Features info from cookie
   */
  featuresInfo: PropTypes.object,
};

export default connect(mapStateToProps(['featuresInfo']), null, null, { pure: false })(SettingsDropdown);
