import React from 'react';
import PropTypes from 'prop-types';

import RelatedRow from '../RelatedRow/RelatedRow';

const RelatedCard = ({
  predefinedRelations, dataExtension, handleSetSelectionState, DEBorderMouseOver,
}) => (
  <div>
    <div className="collection-label-container">
      <span
        className="collection-name slds-truncate"
        title="Contacts"
      >
        <p className="slds-truncate">{dataExtension.deAlias}</p>
      </span>
    </div>
    {predefinedRelations.map(predefinedRelation => (
      <RelatedRow
        predefinedRelation={predefinedRelation}
        key={predefinedRelation._id}
        handleSetSelectionState={handleSetSelectionState}
        DEBorderMouseOver={DEBorderMouseOver}
        deAlias={dataExtension.deAlias}
        selectedDataExtension={dataExtension}
      />
    ))}
  </div>
);

RelatedCard.propTypes = {
/**
 * An array containing relations that were defined for Data Extensions
 */
  predefinedRelations: PropTypes.instanceOf(Array).isRequired,
  /**
   * An object containing Data Extension for which relations have been defined
   */
  dataExtension: PropTypes.instanceOf(Object).isRequired,
  /**
   * It helps to set the Selection`s state
   * It will be passed from Selection.js
   */
  handleSetSelectionState: PropTypes.func.isRequired,
  /*
   * Keeps track whether related DE is dragged
   */
  DEBorderMouseOver: PropTypes.bool.isRequired,
};

export default RelatedCard;
