import React from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import classNames from 'classnames';

import Util from '../../../../../../util';
import './styles.scss';

const AvailableSelectionCard = ({
  selection,
  id,
  index,
  isDragDisabled,
}) => {
  const getItemClassName = (isDragging, copy) => classNames(
    'available-selection-card',
    /* eslint-disable quote-props */
    { 'isDragging': isDragging },
    { 'copy': copy },
    { 'disabled': isDragDisabled(selection) },
    /* eslint-enable quote-props */
  );

  return (
    selection ?
      (
        <Draggable
          draggableId={id}
          index={index}
          key={id}
          isDragDisabled={isDragDisabled(selection)}
        >
          {(provided, snapshot) => (
            <>
              <div
                ref={provided.innerRef}
              /* eslint-disable react/jsx-props-no-spreading */
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              /* eslint-enable react/jsx-props-no-spreading */
                className={getItemClassName(snapshot.isDragging)}
                title={selection.name}
              >
                {Util.abbreviate(selection.name, 30)}
                {isDragDisabled(selection) && (
                  <svg aria-hidden="true" className="slds-button__icon">
                    <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#lock" />
                  </svg>
                )}
              </div>
              {snapshot.isDragging && (
                <div
                  className={getItemClassName(snapshot.isDragging, true)}
                >
                  {Util.abbreviate(selection.name, 30)}
                </div>
              )}
            </>

          )}
        </Draggable>
      ) :
      null
  );
};

AvailableSelectionCard.propTypes = {
  /*
   * selection object for dragging
   */
  selection: PropTypes.instanceOf(Object).isRequired,
  /*
   * id of the selection
   */
  id: PropTypes.string.isRequired,
  /*
   * index of the selection
   */
  index: PropTypes.number.isRequired,
  /*
   * function that defines whether the selection for dragging is disabled
   */
  isDragDisabled: PropTypes.func.isRequired,
};

export default AvailableSelectionCard;
