import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import Constants from '../../../constants/constants';
import Tooltip from '../Tooltip/Tooltip';

function FeatureTitle({ title, featureEnabled, feature }) {
  const {
    featureAdverts,
  } =
    useSelector(({
      featureAdvertsReducer,
    }) => ({
      // From feature adverts reducer
      featureAdverts: featureAdvertsReducer.featureAdverts,
    }), shallowEqual);

  const advert = featureAdverts?.find(featureAdvert => featureAdvert.feature === feature);

  return (
    <>
      <span className="feature-title-text">{title}</span>

      {
        !featureEnabled && (
          !isEmpty(advert) && advert?.status ?
            (
              <img
                className="premium-icon"
                src="/assets/icons/Deedee_Certificate_Banner.png"
                alt="Premium icon" />
            ) :

            (
              <Tooltip
                nubbinPosition={Constants.NUBBIN_POSITION__TOP_RIGHT}
                type={Constants.TOOLTIP_TYPE__UNAVAILABLE_FEATURE}
              />
            )
        )
      }

    </>
  );
}

FeatureTitle.propTypes = {
  /** Title of the feature */
  title: PropTypes.string.isRequired,
  /** Whether the feature is enabled or not */
  featureEnabled: PropTypes.bool.isRequired,
  /** Feature name */
  feature: PropTypes.string.isRequired,
};

export default FeatureTitle;
