import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import './styles.scss';
import classNames from 'classnames';

import Tooltip from '../../../shared/Tooltip/Tooltip';
import Constants from '../../../../constants/constants';
import DataExtensionsAPI from '../../../../api/data-extensions';
import Util from '../../../../util';
import ModalTemplate from '../../../shared/ModalTemplate/ModalTemplate';
import Spinner from '../../../shared/Spinner/Spinner';
import Input from '../../../shared/Input/Input';
import SwalUtil from '../../../../utils/swal/swalUtil';

class DeleteSelectionModal extends Component {
  isMounting = false;

  constructor(props) {
    super(props);
    this.state = {
      deleteQueryActivities: false,
      deleteTargetDE: false,
      targetDEName: null,
      dedupDEName: null,
      loadingData: false,
    };
  }

  /**
   * Fetch data while loading modal
   * @returns {void}
   */
  async componentDidMount() {
    // Before mounting we create 'CancelToken' for further cancellation of memory leak
    this.axiosCancelToken = axios.CancelToken.source();
    this.isMounting = true;

    // start loading data when component is mounted
    if (this.isMounting) {
      // show the loading spinner while fetching data
      this.setState({ loadingData: true });

      // call functions asynchronously
      Promise.all([this.getTargetDEName(), this.getDedupDEName()]).then(() => {
        // show the content after retrieving the data
        this.setState({ loadingData: false });
      });
    }
  }

  /**
   * Avoid memory leak by canceling all subscriptions and asynchronous tasks
   * @returns {void}
   */
  componentWillUnmount() {
    this.axiosCancelToken.cancel();
    this.isMounting = false;
  }

  /**
   * Function returns new Promise after retrieving the targetDE
   * @returns {Promise<Void>} void
   */
  getTargetDEName = () => (new Promise((resolve) => {
    resolve(this.fetchTargetDEName());
  }));

  /**
   * Function returns new Promise after retrieving the dedupDE
   * @returns {Promise<Void>} void
   */
  getDedupDEName = () => (new Promise((resolve) => {
    resolve(this.fetchDedupDEName());
  }));

  /**
   * Fetch and set Target Data Extension Name
   * @returns {void}
   */
  fetchTargetDEName = async () => {
    const { selection } = this.props;

    try {
      // if selection has target data extension
      if (selection.targetCollectionCustomerKey) {
        // get target data extension to display the name
        const targetDataExtension = await DataExtensionsAPI.getDataExtension(
          selection.targetCollectionCustomerKey,
        );

        // if found, set the targetDE name
        if (targetDataExtension) {
          this.setState({ targetDEName: targetDataExtension.Name });
        } else {
          // if targetDE is not found, set the state to null
          this.setState({ targetDEName: null });
        }
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        // in case of error, display the message
        SwalUtil.fire({
          type: Constants.SWAL__TYPE__ERROR,
          title: 'Error',
          message: error,
          options: {
            customClass: {
              popup: 'popup-targetDE',
            },
          },
        });
      }
    }
  };

  /**
   * Fetch and set Deduplication Data Extension Name
   * @returns {void}
   */
  fetchDedupDEName = async () => {
    const { selection } = this.props;

    try {
      // if selection has deduplication data extension
      if (selection.runDeduplicationDECustomerKey) {
        // get deduplication data extension to display the name
        const dedupDEName = await DataExtensionsAPI.getDataExtension(
          selection.runDeduplicationDECustomerKey,
        );

        // if found, set the dedupDEName name
        if (dedupDEName) {
          this.setState({ dedupDEName: dedupDEName.Name });
        } else {
          // if dedupDE is not found, set the state to null
          this.setState({ dedupDEName: null });
        }
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        // in case of error, display the message
        SwalUtil.fire({
          type: Constants.SWAL__TYPE__ERROR,
          title: 'Error',
          message: error,
          options: {
            customClass: {
              popup: 'popup-targetDE',
            },
          },
        });
      }
    }
  };

  /**
   * Removes selected Selection
   * @returns {void}
   */
  handleOnSubmit = async () => {
    const { deleteQueryActivities, deleteTargetDE } = this.state;
    const { handleRemoveSelection, handleCloseDeleteSelectionModal, selection } = this.props;

    // delete a Selection and close the modal
    handleRemoveSelection(selection._id, deleteQueryActivities, deleteTargetDE);
    handleCloseDeleteSelectionModal();
  };

  render() {
    const {
      deleteQueryActivities,
      deleteTargetDE,
      targetDEName,
      dedupDEName,
      loadingData,
    } = this.state;
    const {
      handleCloseDeleteSelectionModal,
      selection,
      isWaterfall,
    } = this.props;

    // indicates that the selection being deleted does not use deduplication
    const targetDEWithoutDedup = (loadingData && (selection?.targetCollectionCustomerKey ||
        selection?.queryActivityId) && !selection?.runDeduplicationDECustomerKey
    ) || (!loadingData && !dedupDEName);

    // class names for modal content - the conditions set the minimum modal height depending on the data
    const modalContentClassName = classNames(
      { 'without-dedup': targetDEWithoutDedup },
      { 'with-dedup': !targetDEWithoutDedup },
      {
        'without-options': !loadingData && (!selection?.targetCollectionCustomerKey || !targetDEName) &&
          (!selection?.queryActivityId) && (!selection?.runDeduplicationDECustomerKey || !targetDEName),
      },
      { 'without-targetDE': !loadingData && selection?.targetCollectionCustomerKey && !targetDEName },
    );

    const titleCheckboxClassName = activeCondition => classNames(
      'title-target-DE',
      activeCondition ? 'active' : 'disabled',
    );

    return (
      selection ?
        (
          <ModalTemplate
              id="delete-selection-modal-dialog"
              headerId="delete-selection-header"
              headerWithAlert
              headerTitle={isWaterfall ? 'Delete Waterfall Selection' : 'Delete Selection'}
              handleCancel={handleCloseDeleteSelectionModal}
              cancelButtonId="delete-selection-cancel-btn"
              handleSave={this.handleOnSubmit}
              saveButtonDisabled={loadingData}
              saveButtonTitle="Delete"
              saveButtonId="delete-selection-submit-btn"
            >
              <div className={modalContentClassName}>
                {/* show loading spinner while fetching data */}
                {loadingData ?
                  (
                    <div className="demo-only demo--inverse" style={{ height: '6rem' }}>
                      <Spinner size={Constants.SPINNER__SIZE__MEDIUM} />
                    </div>
                  ) :
                  (
                    <div className="modal-container">
                      <h2 className="question-title">
                        {`'${selection.name}' will be deleted. Are you sure you want to delete this selection?`}
                      </h2>
                      <h2 className="not-bold-warning-text">
                        You won&apos;t be able to revert this!
                      </h2>
                      {((selection.targetCollectionCustomerKey && targetDEName) || (selection.queryActivityId)) &&
                          (
                          <div className="modal-content-with-additional-items">
                            <div className="selection-delete-title-wrapper">
                              Choose additional items to be deleted:
                              <span>
                                <Tooltip
                                  type={Constants.TOOLTIP_TYPE__DELETE_SELECTION}
                                  nubbinPosition={Constants.NUBBIN_POSITION__BOTTOM_LEFT}
                                  customClassName="delete-selection-tooltip"
                                />
                              </span>
                            </div>
                            <div className="selection-modal target-DE ">
                              <div className="checkbox-wrapper">
                                <Input
                                  type="checkbox"
                                  name="selection-copy checkbox-onoff"
                                  className="selection-copy checkbox"
                                  noInputClassName
                                  id="query-activity-checkbox"
                                  onChange={() => this.setState({ deleteQueryActivities: !deleteQueryActivities })}
                                  disabled={!selection.queryActivityId}
                                  checked={deleteQueryActivities}
                                />
                                <div
                                  className={titleCheckboxClassName(selection.queryActivityId)}
                                  id="query-activity-label"
                                  title=" Query Activities"
                                  onClick={() => selection.queryActivityId &&
                                  this.setState({ deleteQueryActivities: !deleteQueryActivities })}
                                >
                                  Query Activities
                                </div>
                              </div>
                              <div className="checkbox-wrapper">
                                <Input
                                  type="checkbox"
                                  name="selection-copy checkbox-onoff"
                                  className="selection-copy checkbox"
                                  noInputClassName
                                  id="target-de-checkbox"
                                  onChange={() => (this.setState({ deleteTargetDE: !deleteTargetDE }))}
                                  disabled={!selection.targetCollectionCustomerKey || !targetDEName}
                                  checked={deleteTargetDE}
                                />
                                <div
                                  className={titleCheckboxClassName(selection.targetCollectionCustomerKey &&
                                    targetDEName)}
                                  title="Target Data Extension"
                                  id="target-de-label"
                                  onClick={() => (selection.targetCollectionCustomerKey && targetDEName) &&
                                  this.setState({ deleteTargetDE: !deleteTargetDE })}
                                >
                                  {selection.targetCollectionCustomerKey && targetDEName ?
                                    'Target Data Extension:' :
                                    'Target Data Extension'}
                                </div>
                              </div>
                              {targetDEName && (
                              <span
                                className="DE-name targetDE"
                                onClick={() => selection.targetCollectionCustomerKey &&
                                this.setState({ deleteTargetDE: !deleteTargetDE })}
                              >
                                {Util.abbreviate(targetDEName, 35)}
                              </span>
                              )}
                              {(selection.runDeduplicationDECustomerKey && dedupDEName &&
                              targetDEName && selection.targetCollectionCustomerKey) &&
                            (
                              <>
                                <div
                                  className="dedup-de-name"
                                  onClick={() => this.setState({ deleteTargetDE: !deleteTargetDE })}
                                >
                                  and related Deduplication Data Extension:
                                </div>
                                {dedupDEName && (
                                <span
                                  className="DE-name dedupDE"
                                  onClick={() => this.setState({ deleteTargetDE: !deleteTargetDE })}
                                >
                                  {Util.abbreviate(dedupDEName, 35)}
                                </span>
                                )}
                              </>
                            )}
                            </div>
                          </div>
                          )}
                    </div>
                  )}
              </div>
          </ModalTemplate>
        ) :
        (
          null
        )
    );
  }
}

DeleteSelectionModal.propTypes = {
  /**
   * Object with a selected Selection
   */
  selection: PropTypes.oneOfType([PropTypes.object]).isRequired,
  /**
   * It closes the DeleteSelectionModal
   */
  handleCloseDeleteSelectionModal: PropTypes.func.isRequired,
  /**
   * It removes the Selection
   */
  handleRemoveSelection: PropTypes.func.isRequired,
  /**
   * Defines whether the Waterfall Selections section is selected in the Overview
   */
  isWaterfall: PropTypes.bool,
};

export default DeleteSelectionModal;
