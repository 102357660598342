import React from 'react';
import PropTypes from 'prop-types';

import Constants from '../../../../constants/constants';
import './styles.scss';
import ModalTemplate from '../../../shared/ModalTemplate/ModalTemplate';
import RadioButton from '../../../shared/RadioButton/RadioButton';

class DataAction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataAction: props.dataAction,
    };
  }

  /**
   * Function for changing radio buttons
   * @param {object} e - event
   * @returns {void}
   */
  handleOnChangeRadios = (e) => {
    this.setState({ dataAction: e.target.id });
  };

  /**
   * Function which helps to close data action modal
   * @returns {void}
   */
  handleCloseDataActionModal = () => {
    const { handleSetSelectionState } = this.props;

    handleSetSelectionState({ showDataActionModal: false });
  };

  /**
   * Function for saving data action
   * @returns {void}
   */
  onSave = () => {
    const { dataAction } = this.state;
    const { handleSetSelectionState } = this.props;
    // set data action state in selection

    handleSetSelectionState({ dataAction });
    // close the data action modal
    this.handleCloseDataActionModal();
  };

  render() {
    const { dataAction } = this.state;

    const { targetDataExtensionFields } = this.props;

    // check if there is at least one field with Primary Key
    const primaryKeyExists = targetDataExtensionFields.filter(
      field => field.IsPrimaryKey === true,
    );
    const disableUpdate = primaryKeyExists && primaryKeyExists.length === 0;

    return (
      <ModalTemplate
          id="data-action-modal-dialog"
          headerId="action-header"
          headerTitle="Data Action"
          contentId="action-content"
          contentClassName="slds-p-around_medium"
          cancelButtonId="action-cancel"
          handleCancel={this.handleCloseDataActionModal}
          saveButtonId="action-save"
          handleSave={this.onSave}
          saveButtonTitle="Select"
          footerId="action-footer"
        >
          <div className="DA_row">
            <div className="DA_line">
              <RadioButton
                labelClassName={`${Constants.DATA_ACTION__OVERWRITE}-text`}
                id={Constants.DATA_ACTION__OVERWRITE}
                value={dataAction === Constants.DATA_ACTION__OVERWRITE ? Constants.DATA_ACTION__OVERWRITE : ''}
                checked={dataAction === Constants.DATA_ACTION__OVERWRITE}
                onChange={e => this.handleOnChangeRadios(e)}
                label="Overwrite"
              />
            </div>
            <span className="data-action-description"> Overwrite the current data in the target data extension.</span>
          </div>

          <div className="DA_row">
            <div className="DA_line">
              <RadioButton
                labelClassName={`${Constants.DATA_ACTION__APPEND}-text`}
                id={Constants.DATA_ACTION__APPEND}
                value={dataAction === Constants.DATA_ACTION__APPEND ? Constants.DATA_ACTION__APPEND : ''}
                checked={dataAction === Constants.DATA_ACTION__APPEND}
                onChange={e => this.handleOnChangeRadios(e)}
                label="Append"
              />
            </div>
            <span className="data-action-description"> Add results as new data to the target data extension.</span>
          </div>

          <div className="DA_row">
            <div
              className={`DA_line ${
                disableUpdate ? ' disable-data-action-radio-container' : ''
              }`}
              title={
                    disableUpdate ?
                      'This option requires at least one field with a primary key' :
                      Constants.DATA_ACTION__LABEL__UPDATE
                  }
              id={`${disableUpdate ? 'data-action-update' : ''}`}
            >
              <RadioButton
                labelClassName={`${Constants.DATA_ACTION__UPDATE}-text`}
                id={Constants.DATA_ACTION__UPDATE}
                value={dataAction === Constants.DATA_ACTION__UPDATE ? Constants.DATA_ACTION__UPDATE : ''}
                checked={dataAction === Constants.DATA_ACTION__UPDATE}
                onChange={e => this.handleOnChangeRadios(e)}
                disabled={disableUpdate}
                label="Update"
              />
            </div>
            <span className="data-action-description">
            Update existing records and add new records to the target data extension.
            </span>
          </div>
      </ModalTemplate>
    );
  }
}

DataAction.propTypes = {
  /**
   * It keeps data action state (overwrite, append, update)
   */
  dataAction: PropTypes.string.isRequired,
  /**
   * It helps to set the Selection`s state
   * It will be passed from Selection.js
   */
  handleSetSelectionState: PropTypes.func.isRequired,
  /**
   * It keeps the fields of an existing target data extension
   * It will be passed from Selection.js
   */
  targetDataExtensionFields: PropTypes.instanceOf(Array).isRequired,
};
export default DataAction;
