import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';

import PickListsAPI from '../../../../api/picklists';
import Constants from '../../../../constants/constants';
import './styles.scss';
import Button from '../../../shared/Button/Button';
import timeUtil from '../../../../utils/time/timeUtil';
import SwalUtil from '../../../../utils/swal/swalUtil';
import mapStateToProps from '../../../../mapStateToProps';

class RetrieveDEValuesButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };

    this.axiosCancelToken = axios.CancelToken.source();
  }

  // eslint-disable-next-line camelcase
  async UNSAFE_componentWillMount() {
    const { pickList } = this.props;

    // If picklist contains queryActivityId or queryDEStatus is not completed, check query activity status
    if ((pickList && pickList.queryActivityId && pickList.retrieveDistinctDEValues) ||
    (pickList && pickList.retrieveDistinctDEValues && pickList.queryDEStatus !==
      Constants.PICKLIST__STATUS__COMPLETE) ||
      (pickList && pickList.queryActivityId && pickList.queryDEStatus !== Constants.PICKLIST__STATUS__COMPLETE)) {
      // if queryDEStatus is not completed, set loading to true
      this.setState({ loading: true });
      await this.checkStatus(pickList);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    this.axiosCancelToken.cancel({
      message: 'all async subscriptions have been canceled',
    });
  }

  /**
   * Swal modal for showing occurred errors
   * @param {string} error - occurred error
   * @returns {object} - swal
   */
  showErrorMessage = async (error) => {
    this.setState({ loading: false });
    const { handleSetPicklistsAEState } = this.props;

    handleSetPicklistsAEState({ retrieveDistinctDEValues: false });
    clearInterval(this.interval);

    return SwalUtil.fire({
      type: Constants.SWAL__TYPE__ERROR,
      message: error,
    });
  };

  /**
   * Save retrieved distinct values from DE in options
   * @param {array} retrievedData - data from DE
   * @param {object} fieldName - name of the field we want to get distinct values
   * @returns {void}
   */
  saveDistinctValuesInOptions = (retrievedData, fieldName) => {
    const { handleSetPicklistsAEState, activeValues, inactiveValues } = this.props;
    /**
     * If there is no retrieved data show message to the user
     * Note: if we have field which is marked as nullable without records we will get an empty string when retrieving
     * distinct values is done (see screenshots) for both text and number types.
     */

    if (retrievedData && (retrievedData.length === 0 ||
    (retrievedData.length === 1 && retrievedData[0] === ''))) {
      SwalUtil.fire({
        title: 'Retrieve values from Data Extension',
        message: `Query has not retrieved any values for ${fieldName}`,
      });
      handleSetPicklistsAEState({ retrieveDistinctDEValues: false });
      this.setState({ loading: false });
    } else {
    // Check if data exists in both active and inactive options
      const values = [...activeValues, ...inactiveValues];

      retrievedData
      // Sort values alphabetically, 0..9 > Aa..Zz
        .sort((a, b) => a.toString().localeCompare(b.toString(), undefined, { sensitivity: 'base' }))
        .forEach((data) => {
          const dataAsString = data.toString();
          const dataExists = values.find(v => v.value === dataAsString && v.label === dataAsString);

          // If data is not empty and doesn't exist already in options add it to the active option
          if (!dataExists && data) {
            activeValues.push({
              value: data.toString(),
              label: data.toString(),
              isActive: true,
            });
          }
        });

      handleSetPicklistsAEState({ activeValues, retrieveDistinctDEValues: false });
      this.setState({ loading: false });
    }
  };

  /**
   * When query for getting values from DE has been started
   * @param {object} pickList - object of the picklist
   * @returns {void}
   */
  getStatus = async (pickList) => {
    try {
      // get query status
      const result = await PickListsAPI.getQueryStatus(pickList._id, this.axiosCancelToken.token);

      if (result.error && result.error === Constants.ERROR__NOT_VALID_TASK_ID) {
        // If we get an error that TaskID is not valid start getting values from DE again to get new TaskID
        clearInterval(this.interval);
        await this.startGettingValuesFromDE(pickList);
      }
      // if status is 2 start with checking and call API to get data from DE
      if (result && result.status === Constants.PICKLIST__STATUS__COMPLETE) {
        clearInterval(this.interval);
        try {
          // refresh pickList to get distinctValuesDECustomerkey property
          const refreshedPickList = await PickListsAPI.getPicklist(pickList._id, this.axiosCancelToken.token);

          // get data from DE
          const res = await PickListsAPI.getQueryData(pickList._id, this.axiosCancelToken.token);

          // Push column values into one array
          const retrievedData = [];

          res.data.forEach((r) => {
            if (r[pickList.fieldName.toLowerCase()] !== undefined) {
              retrievedData.push(r[pickList.fieldName.toLowerCase()]);
            }
          });
          const { handleSetPicklistsAEState } = this.props;

          handleSetPicklistsAEState({ picklist: refreshedPickList });
          // Save values in options
          this.saveDistinctValuesInOptions(retrievedData, refreshedPickList.fieldName);
        } catch (error) {
          if (!axios.isCancel(error)) {
            this.showErrorMessage(error);
          }
        }
        // If status is STATUS_ERROR show swal message
      } else if (
        result &&
        (
          result.status === Constants.PICKLIST__STATUS__QA_ERROR ||
          result.status === Constants.PICKLIST__STATUS__SEPARATION_ERROR
        )
      ) {
        clearInterval(this.interval);

        const errorMessage = result.error ? `: ${result.error}` : '';

        SwalUtil.fire({
          type: Constants.SWAL__TYPE__ERROR,
          message: `Could not retrieve the unique values for ${pickList.fieldName} ${errorMessage}`,
        });

        this.setState({ loading: false });
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        this.showErrorMessage(error);
      }
    }
  };

  /**
   * Swal modal to start with retrieving values
   * @param {object} pickList - picklist for which we want to get values
   * @param {object} selectedDataExtension - data extension from which we will get the values
   * @param {object} selectedField - field for which we will get the values
   * @returns {void}
   */
  throwSwalMessage = (pickList, selectedDataExtension, selectedField) => SwalUtil.fire({
    title: 'Retrieve values from Data Extension',
    // eslint-disable-next-line max-len
    message: `If you continue, DESelect will query the distinct values for ${pickList._id ? pickList.fieldName : selectedField.Name.toString()} in ${pickList._id ? pickList.dataExtensionName : selectedDataExtension.Name.toString()} and add them as active options. This could take some time (2-30 minutes) depending on the size of the data extension. You may close this window while this is processing.`,
    options: {
      showConfirmButton: true,
      confirmButtonText: 'Continue',
      showCancelButton: true,
    },
  });

  /**
   * Check status of API for retrieving values every 10 sec
   * @param {object} pickList - picklist for which we want to get values
   * @returns {void}
   */
  checkStatus = async (pickList) => {
    this.interval = setInterval(async () => {
      try {
        await this.getStatus(pickList);
      } catch (error) {
        if (!axios.isCancel(error)) {
          this.showErrorMessage(error);
        }
      }
    }, 3000);
  };

  /**
   * Start getting the values from a DE and following up on the status
   * @param {object} picklist - picklist for which we want to get values
   * @returns {void}
   */
  startGettingValuesFromDE = async (picklist) => {
    const { handleSetPicklistsAEState, handleSetAdminPanelState } = this.props;

    this.setState({ loading: true });
    handleSetPicklistsAEState({ retrieveDistinctDEValues: true, isGettingValuesFromDEStarted: true });

    if (picklist) {
    // Start getting new values
      try {
        const startResponse = await PickListsAPI.startGettingValuesFromDE(
          picklist._id,
          picklist.dataExtensionCustomerKey,
          picklist.fieldObjectId,
          this.axiosCancelToken.token,
        );

        if (startResponse.queryDETaskId) {
          handleSetAdminPanelState({ isGettingValuesFromDEStarted: false });

          await this.checkStatus(picklist);
        }
      } catch (error) {
        handleSetAdminPanelState({ isGettingValuesFromDEStarted: false });

        this.showErrorMessage(error);
      /* console.log('error', error); */
      }
    }
  };

  /**
   * When user clicks on button " Retrieve values from Data Extension"
   * @param {object} pickList - picklist for which we want to get values
   * @param {object} selectedField - field for which we will get the values
   * @param {object} selectedDataExtension - data extension from which we will get the values
   * @param {string} picklistName - picklist name
   * @param {boolean} isActive - picklist property is active or not
   * @returns {void}
   */
  getValuesFromDEForPickList = async (
    pickList,
    selectedField,
    selectedDataExtension,
    picklistName,
    isActive,
  ) => {
    const { handleSetPicklistsAEState, handleSetAdminPanelState, userInfo } = this.props;

    const DATEFORMAT = timeUtil.getUserDateTimeFormat(userInfo);

    let {
      pickList: {
        separate,
        separator,
        _id: picklistId,
        options,
        retrieveDistinctDEValues,
      },
    } = this.props;

    // check if query for the current pickList has already been run
    if (pickList?.lastQueryDEDate) {
      const refresh = await SwalUtil.fire({
        title: 'Retrieve values from Data Extension',
        // eslint-disable-next-line max-len
        message: `The distinct values for ${pickList.fieldName} in ${pickList.dataExtensionName} were last retrieved at ${timeUtil.showCSTDateTimeInLocale(pickList.lastQueryDEDate, DATEFORMAT)}.<br> Would you like to refresh the results?`,
        options: {
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: 'Refresh results',
          cancelButtonText: 'Cancel',
        },
      });

      // If user wants to refresh values for the pickList
      if (refresh.value) {
        const confirmAndContinue = await this.throwSwalMessage(pickList, selectedDataExtension, selectedField);

        if (confirmAndContinue.value) {
          ({
            pickList: {
              separate,
              separator,
              _id: picklistId,
              options,
              retrieveDistinctDEValues,
            },
          } = this.props);

          handleSetAdminPanelState({ isGettingValuesFromDEStarted: true });

          /**
           * Update the picklist
           */
          const updatedPicklist = await PickListsAPI.updatePicklist(
            picklistId,
            picklistName,
            selectedField.ObjectID,
            selectedDataExtension.CustomerKey,
            selectedDataExtension.ObjectID,
            options,
            selectedField.Name.toString(),
            selectedDataExtension.Name.toString(),
            isActive,
            retrieveDistinctDEValues,
            this.axiosCancelToken.token,
            separate,
            separator,
          );

          handleSetPicklistsAEState({ picklist: updatedPicklist.data });
          // eslint-disable-next-line require-atomic-updates, no-param-reassign
          pickList = updatedPicklist.data;
          await this.startGettingValuesFromDE(pickList);
        } // If user doesn't want to refresh values for the pickList
      } else if (refresh.dismiss === 'cancel') {
        this.setState({ loading: false });
        handleSetPicklistsAEState({ retrieveDistinctDEValues: false });
      }
      // If query for the current pickList hasn't already been run
    } else {
      const confirmAndContinue = await this.throwSwalMessage(pickList, selectedDataExtension, selectedField);

      if (confirmAndContinue.value) {
        this.setState({ loading: true });
        handleSetPicklistsAEState({ retrieveDistinctDEValues: true });

        handleSetAdminPanelState({ isGettingValuesFromDEStarted: true });

        ({
          pickList: {
            separate,
            separator,
            _id: picklistId,
            options,
            retrieveDistinctDEValues,
          },
        } = this.props);

        try {
          /**
           * If we are in process of creation of the new picklist then first
           * create new picklist
           */
          if (picklistId === undefined) {
            const createdPickList = await PickListsAPI.createPicklist(
              picklistName,
              selectedField.ObjectID,
              selectedDataExtension.CustomerKey,
              selectedDataExtension.ObjectID,
              [],
              selectedField.Name.toString(),
              selectedDataExtension.Name.toString(),
              isActive,
              true,
              this.axiosCancelToken.token,
              separate,
              separator,
            );
            // eslint-disable-next-line require-atomic-updates, no-param-reassign

            pickList = createdPickList.data;
            const { handleSetAdminPanelState } = this.props;

            handleSetAdminPanelState({ picklistId: pickList._id });

            await this.startGettingValuesFromDE(pickList);
          } else {
            /**
             * Update the picklist
             */
            const updatedPicklist = await PickListsAPI.updatePicklist(
              picklistId,
              picklistName,
              selectedField.ObjectID,
              selectedDataExtension.CustomerKey,
              selectedDataExtension.ObjectID,
              options,
              selectedField.Name.toString(),
              selectedDataExtension.Name.toString(),
              isActive,
              retrieveDistinctDEValues,
              this.axiosCancelToken.token,
              separate,
              separator,
            );

            handleSetPicklistsAEState({ picklist: updatedPicklist.data });
            // eslint-disable-next-line require-atomic-updates, no-param-reassign
            pickList = updatedPicklist.data;
            await this.startGettingValuesFromDE(pickList);
          }
        } catch (error) {
          if (!axios.isCancel(error)) {
            this.showErrorMessage(error);
          }
        }
      }
    }
  };

  render() {
    const { loading } = this.state;
    const {
      pickList,
      selectedField,
      selectedDataExtension,
      picklistName,
      isActive,
      disableRetrieveButton,
    } = this.props;

    return (
      <Button
        className="retrieve-value-button"
        buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
        onClick={() => this.getValuesFromDEForPickList(
          pickList,
          selectedField,
          selectedDataExtension,
          picklistName,
          isActive,
        )}
        disabled={loading || disableRetrieveButton}
        loadingClickedButton={loading}
        titleInAction="Retrieving values"
        title="Retrieve values from Data extension"
      >
        Retrieve values
      </Button>
    );
  }
}

RetrieveDEValuesButton.propTypes = {
  /**
   * It keeps object of one pickList
   */
  pickList: PropTypes.instanceOf(Object),
  /**
   * It keeps selected field for the pickList
   */
  selectedField: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  /**
   * It keeps selected data extension for the pickList
   */
  selectedDataExtension: PropTypes.instanceOf(Object).isRequired,
  /**
   * It keeps picklist name
   */
  picklistName: PropTypes.string,
  /**
   * Picklist property which determines if the picklist is active or not
   */
  isActive: PropTypes.bool.isRequired,
  /**
   * Function for setting PicklistsAE state
   */
  handleSetPicklistsAEState: PropTypes.func.isRequired,
  /**
   * It keeps active picklist options
   */
  activeValues: PropTypes.instanceOf(Array).isRequired,
  /**
   * It keeps inactive picklist options
   */
  inactiveValues: PropTypes.instanceOf(Array).isRequired,
  /**
   * Function for setting admin panel state
   */
  handleSetAdminPanelState: PropTypes.func.isRequired,
  /**
   * The disable state of the retrieve button
   */
  disableRetrieveButton: PropTypes.bool.isRequired,
  /**
   * User info from cookie
   */
  userInfo: PropTypes.object,
};

export default connect(mapStateToProps(['userInfo']), null, null, { pure: false })(RetrieveDEValuesButton);
