import { GET_ERRORS, GET_RUN_ERROR } from '../../actions/types';

const initialState = {
  error: '',
  runError: '',
};

/**
 * Function that takes an action and the previous state of the application and returns the new state
 * @param {object} state - contain initial and final state of data
 * @param {object} action - return the action object
 * @returns {object} updated state
 */
const errorReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ERRORS:
      return {
        ...state,
        error: action.payload,
      };
    case GET_RUN_ERROR:
      return {
        ...state,
        runError: action.payload,
      };
    default:
      return state;
  }
};

export default errorReducer;
