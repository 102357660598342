import Util from '../../../../../util';
import Validator from './Validation/Validator';
import QueryActivitiesAPI from '../../../../../api/query-activities';
import Constants from '../../../../../constants/constants';
import SwalUtil from '../../../../../utils/swal/swalUtil';

const FreeFormulaUtil = {
  /**
   * It helps to check the syntax validation
   * @returns {Promise} - true or false, depends of the syntax validation
   */
  // eslint-disable-next-line consistent-return
  checkSyntax: async (freeFormulaData, selectedDataExtensions, relations, axiosCancelToken) => {
    const { value } = freeFormulaData;

    // Replace all non-breaking spaces with normal spaces
    const formula = Util.replaceEnterKeysAndNonBreakingSpaces(value);

    // check if syntax is valid using Validator
    const valid = Validator(formula, selectedDataExtensions);

    // set variables to manage the response returned from the API
    let isSyntaxValid = false;

    let syntaxError = '';

    try {
      /**
       * Updates selectedDataExtensions data format for the backend formula syntax validation.
       * @param {object} collection - collection
       * @returns {object} An object with the properties `collection`, `alias`, `collectionObjectID` and
       * `collectionCustomerKey`
       */
      const formatDataExtensions = collection => ({
        collection: collection?.Name?.toString(),
        alias: collection?.deAlias,
        collectionObjectID: collection?.ObjectID,
        collectionCustomerKey: collection?.CustomerKey,
        clientID: collection?.fields[0]?.Client?.ID,
        fields: collection?.fields.map(field => ({ ObjectID: field?.ObjectID, Name: field?.Name })),
      });

      // update selectedDataExtensions data format
      const collections = selectedDataExtensions.map(collection => formatDataExtensions(collection));

      // call the api to check if syntax is valid in the backend
      const validationQuery = await QueryActivitiesAPI.validateQueryForCustomValues(
        formula,
        relations,
        collections,
        freeFormulaData.fieldType,
        axiosCancelToken,
      );

      // if isQueryValid is true, set isSyntaxValid to true
      if (validationQuery.isQueryValid) {
        isSyntaxValid = true;
      } else {
        /**
         * If frontend validation passed and backend validation failed, show backend error
         */
        if (valid.success) {
          // show an error message from response, if backend failed to respond, show custom message
          syntaxError = validationQuery.error || Constants.ERROR__VALIDATION_QUERY__BUILDING;
        } else {
          /**
           * If both frontend and backend validations failed, show frontend error
           */
          syntaxError = valid.error;
        }
      }

      return { isSyntaxValid, syntaxError };
    } catch (error) {
      this.handleError('Error', { error });
    }
  },

  changeAliases: (values, oldName, newName) => values?.forEach(value => (
    FreeFormulaUtil.changeAlias(value, oldName, newName)
  )),

  changeAlias: (value, oldName, newName) => {
    const sanitizedValue = Util.sanitizeSpaces(value.value);
    const sanitizedOldName = Util.sanitizeSpaces(oldName);

    if (sanitizedValue.toLowerCase().includes(`"${sanitizedOldName.toLowerCase()}"."`)) {
      // if there is an inserted field for formula value, update the alias for this name
      const nameRegExp = new RegExp(`"${sanitizedOldName}"."`, 'gi');
      const replaceStr = sanitizedValue.replace(nameRegExp, `"${newName}"."`);

      // eslint-disable-next-line no-param-reassign
      value.value = replaceStr;
    }
  },

  isValid: (customValue, isSyntaxValid) => {
    if (!customValue.value.trim() || !customValue.value) {
      SwalUtil.fire({
        type: Constants.SWAL__TYPE__ERROR,
        title: 'Formula Value Is Required',
        message: 'Please fill out the formula input.',
        htmlClass: ' ',
      });

      return false;
    }

    if (!isSyntaxValid) {
      SwalUtil.fire({
        type: Constants.SWAL__TYPE__ERROR,
        title: 'Syntax is not valid.',
        message: 'Please correct the syntax and try again.',
        htmlClass: ' ',
      });

      return false;
    }

    return true;
  },
};

export default FreeFormulaUtil;
