import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Input from '../../../shared/Input/Input';

class PicklistLabelInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      selectedField,
      handleChangeOptionLabel,
      onChange,
      value,
      inputOnKeyDown,
    } = this.props;

    return (
      <Input
        placeholder="Label"
        className="v2-input-label-style"
        required
        disabled={!selectedField?.ObjectID}
        onChange={onChange}
        onBlur={handleChangeOptionLabel}
        onKeyDown={inputOnKeyDown}
        value={value || ''}
      />
    );
  }
}

PicklistLabelInput.propTypes = {
  /**
   * Selected field in state of PicklistsAE
   */
  selectedField: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  /**
   * Changes the label value in the PicklistAddValuesComponent
   */
  handleChangeOptionLabel: PropTypes.func,
  /**
   * Label value in the state of PicklistAddValuesComponent
   */
  value: PropTypes.string,
  /**
   * Changes the state of the value in PicklistAddValuesComponent
   */
  onChange: PropTypes.func,
  /**
   * Add values to option after pressing the enter
   */
  inputOnKeyDown: PropTypes.func,
};

export default PicklistLabelInput;
