import React from 'react';
import PropTypes from 'prop-types';

import DateDifferenceField from './DateDifferenceField/DateDifferenceField';
import Constants from '../../../../../constants/constants';
import './style.scss';
import Select from '../../../../shared/Select/Select';

const dateDifferenceModal = ({
  dateDifferenceData,
  handleSetCustomValuesState,
  selectedDataExtensions,
  disabled,
  getDataExtensionOrDataViewFields,
  availableDataExtensionsWithExclusionSkipped,

}) => {
  /**
   * Return the data extensions and add excluded DE that was selected before
   * @param {Array} dataExtensions - List of available data extensions
   * @param {Array} availableDataExtensionsWithExclusionSkipped - List of available data extensions with excluded ones
   * @returns {void}
   */
  const getRequiredDataExtensions = (
    dataExtensions,
    availableDataExtensionsWithExclusionSkipped,
  ) => {
    const dataExtensionsToReturn = [...(dataExtensions || [])];

    let firstDataExtension = dataExtensions.find(
      d => d.ObjectID === dateDifferenceData?.field1?.dataExtensionObjectId,
    );

    let secondDataExtension = dataExtensions.find(
      d => d.ObjectID === dateDifferenceData?.field2?.dataExtensionObjectId,
    );

    if (!firstDataExtension) {
      firstDataExtension =
                availableDataExtensionsWithExclusionSkipped?.find(
                  d => d.ObjectID === dateDifferenceData?.field1?.dataExtensionObjectId,
                );

      if (firstDataExtension) {
        dataExtensionsToReturn.push(firstDataExtension);
      }
    }

    if (!secondDataExtension) {
      secondDataExtension =
                availableDataExtensionsWithExclusionSkipped?.find(
                  d => d.ObjectID === dateDifferenceData?.field2?.dataExtensionObjectId,
                );

      if (secondDataExtension) {
        dataExtensionsToReturn.push(secondDataExtension);
      }
    }

    return dataExtensionsToReturn;
  };
  /**
   * Event handler for when Output selection has changed
   * @param {object} e - onChange event object
   * @returns {void}
   */
  const handleOutputChanged = (e) => {
    const { value, name } = e.target;
    const updatedDateDifferenceData = { ...dateDifferenceData };

    updatedDateDifferenceData[name] = value;
    handleSetCustomValuesState({ dateDifferenceData: updatedDateDifferenceData });
  };

  const { output } = dateDifferenceData;

  // Options for output
  const optToRender = [
    [Constants.FILTERLINE__DATE_VALUE__INTERVAL_MINUTE, Constants.FILTERLINE__DATE_VALUE__INTERVAL_MINUTES_LABEL],
    [Constants.FILTERLINE__DATE_VALUE__INTERVAL_HOUR, Constants.FILTERLINE__DATE_VALUE__INTERVAL_HOURS_LABEL],
    [Constants.FILTERLINE__DATE_VALUE__INTERVAL_DAYS, Constants.FILTERLINE__DATE_VALUE__INTERVAL_DAYS_LABEL],
    [Constants.FILTERLINE__DATE_VALUE__INTERVAL_WEEKS, Constants.FILTERLINE__DATE_VALUE__INTERVAL_WEEKS_LABEL],
    [Constants.FILTERLINE__DATE_VALUE__INTERVAL_MONTH, Constants.FILTERLINE__DATE_VALUE__INTERVAL_MONTHS_LABEL],
    [Constants.FILTERLINE__DATE_VALUE__INTERVAL_QUARTER, Constants.FILTERLINE__DATE_VALUE__INTERVAL_QUARTERS_LABEL],
    [Constants.FILTERLINE__DATE_VALUE__INTERVAL_YEAR, Constants.FILTERLINE__DATE_VALUE__INTERVAL_YEARS_LABEL],
  ];

  // Fields to render
  const fieldsToRender = [
    Constants.CUSTOM_VALUES__FIELD_ID__FIELD1,
    Constants.CUSTOM_VALUES__FIELD_ID__FIELD2,
  ];

  return (
    <div className="date-difference">
      <div className="custom-values-question">
        <span className="title">{Constants.CUSTOM_VALUES__HEADER__DATE_DIFFERENCE}</span>
        <span className="description">{Constants.CUSTOM_VALUES__TEXT_DATE_DIFFERENCE}</span>
      </div>
      <div className="custom-values-input">
        <div>
          {
            fieldsToRender.map(fieldId => (
              <DateDifferenceField
                fieldId={fieldId}
                key={fieldId}
                selectedDataExtensions={getRequiredDataExtensions(
                  selectedDataExtensions,
                  availableDataExtensionsWithExclusionSkipped,
                )}
                handleSetCustomValuesState={handleSetCustomValuesState}
                dateDifferenceData={dateDifferenceData}
                disabled={disabled}
                getDataExtensionOrDataViewFields={getDataExtensionOrDataViewFields}
              />
            ))
          }
        </div>
        <br />
        <Select
          containerClassName="output"
          selectLabel="Express difference in: "
          onChange={handleOutputChanged}
          defaultValue={output}
          name="output"
          options={optToRender.map(([val, label]) => ({ value: val, label }))}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

dateDifferenceModal.propTypes = {
  /**
   * Function to set the state of the CustomValues component
   */
  handleSetCustomValuesState: PropTypes.func.isRequired,
  /**
   * It keeps the selected data extensions for Selection.js
   * selected data extensions are stored as collections in database
   * It will be passed from CustomValues.js
   */
  selectedDataExtensions: PropTypes.instanceOf(Array),
  /**
   * Object that stores values of the transformDate custom value
   */
  dateDifferenceData: PropTypes.instanceOf(Object),
  /**
   * Function used to fetch DE's fields when needed
   */
  getDataExtensionOrDataViewFields: PropTypes.func,
  /**
   * Determines whether all input fields should be editable or not by the user
   */
  disabled: PropTypes.bool,
  /**
   * List of data extensions with excluded ones
   */
  availableDataExtensionsWithExclusionSkipped: PropTypes.instanceOf(Array),
};

export default dateDifferenceModal;
