import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const SelectionsLeft = ({
  name,
  _id,
  folderId,
  handleEditSelection,
  startDraggingToFolder,
  handleSetOverviewState,
  openAllFolders,
  openSelections,
  selectionDescription,
  featureSelectionFoldersEnabled,
  showHover,
}) => (
  <ul className={classNames('selections_left_body', { 'overview-list-hover': showHover })}>
    <li className="selections_left_body-name">
      <span
        className="drag_drop_selection"
        draggable={featureSelectionFoldersEnabled}
        onDragStart={() => {
          if (!openAllFolders && openSelections) {
            handleSetOverviewState({ blurDrop: true });
          } else {
            handleSetOverviewState({ folderBorderDragOver: true, draggedFolderId: '' });
            startDraggingToFolder(_id);
          }
        }}
        onDragEnd={() => handleSetOverviewState({
          draggedSelectionId: '',
          folderBorderDragOver: false,
          blurDrop: false,
        })}
      >
        {
          featureSelectionFoldersEnabled && (
            <svg className="slds-button__icon" aria-hidden="true">
              <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#drag_and_drop" />
            </svg>
          )
        }
        <a
          className="selectionName"
          href="#!"
          title={selectionDescription || `Open selection ${name}`}
          role="button"
          onClick={() => handleEditSelection(_id, folderId)}
        >
          {name}
        </a>
      </span>
    </li>
  </ul>
);

SelectionsLeft.propTypes = {
  /**
   * It gives the name of selection
   */
  name: PropTypes.string.isRequired,
  /**
   * It gives the id of folder
   */
  folderId: PropTypes.string.isRequired,
  /**
   * It gives the id of selection
   */
  _id: PropTypes.string.isRequired,
  /**
   * It opens a selection
   */
  handleEditSelection: PropTypes.func.isRequired,
  /**
   * It saves the id of dragged selection to folder
   */
  startDraggingToFolder: PropTypes.func.isRequired,
  /**
   * It sets overview state
   */
  handleSetOverviewState: PropTypes.func.isRequired,
  /**
   * It open/close all created folders
   */
  openAllFolders: PropTypes.bool.isRequired,
  /**
   * It open/close all uncategorized folders
   */
  openSelections: PropTypes.bool.isRequired,
  /**
   * Selection's description
   */
  selectionDescription: PropTypes.string,
  /**
   * It determines if the feature for selection folder is enabled or not
   */
  featureSelectionFoldersEnabled: PropTypes.bool.isRequired,
};

export default SelectionsLeft;
