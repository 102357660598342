import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Util from '../../../../util';
import '../styles.scss';
import Constants from '../../../../constants/constants';

const UpdateSelectionTable = ({ selectionUpdates }) => {
  // selectionUpdates data without duplicate values - we show information only once
  const selectionUpdatesWithoutDuplicate = Util.removeDuplicatesFromArray(selectionUpdates);

  // sorted selectionUpdatesWithoutDuplicate data by tab name
  const sortSelectionUpdates = Util.sortArrayOfObjects(selectionUpdatesWithoutDuplicate, 'tabName');

  /**
   * Returns class name depending of action type
   * @param {string} actionType -Action Type
   * @returns {string} class name
   */
  const colorActionType = actionType => classNames(
    'slds-truncate action-type cell',
    // eslint-disable-next-line quote-props
    { 'removed': actionType === Constants.UPDATE_SELECTION__ACTION_TYPE__REMOVE },
    // eslint-disable-next-line quote-props
    { 'updated': actionType === Constants.UPDATE_SELECTION__ACTION_TYPE__UPDATE },
  );

  return (
    <table className="slds-table slds-table_cell-buffer slds-no-row-hover slds-table_bordered update-selection-table">
      <thead>
        <tr>
          <th className="" scope="col">
            <div className="slds-truncate" title="Action Type">Action Type</div>
          </th>
          <th className="" scope="col">
            <div className="slds-truncate" title="Object Type">Object Type</div>
          </th>
          <th className="" scope="col">
            <div className="slds-truncate" title="Description">Description</div>
          </th>
          <th className="" scope="col">
            <div className="slds-truncate" title="Tab Name">Tab Name</div>
          </th>
        </tr>
      </thead>
      <tbody>
        { sortSelectionUpdates.map((rowUpdateData, i) => (
        // eslint-disable-next-line react/no-array-index-key
          <tr className="slds-hint-parent row-update-data" key={`${rowUpdateData.message}-${i}`}>
            <td data-label="Action Type">
              <div className={colorActionType(rowUpdateData.actionType)} title={rowUpdateData.actionType}>
                {rowUpdateData.actionType}
              </div>
            </td>
            <td data-label="Object Type">
              <div className="slds-truncate object-type cell" title={rowUpdateData.objectType}>
                {rowUpdateData.objectType}
              </div>
            </td>
            {/* eslint-disable-next-line react/no-danger */}
            <td
              data-label="Description"
              dangerouslySetInnerHTML={{ __html: rowUpdateData.message }}
              aria-label="Description" />
            <td data-label="Tab Name">
              <div className="slds-truncate tab-name cell" title={rowUpdateData.tabName}>
                {Util.abbreviate(rowUpdateData.tabName, 32)}

              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

UpdateSelectionTable.propTypes = {
  /**
   * An array containing data about changes after loading a selection - deleting and updating elements in a selection
   */
  selectionUpdates: PropTypes.instanceOf(Array).isRequired,
};

export default UpdateSelectionTable;
