import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

// eslint-disable-next-line import/no-cycle
import Filters from '../../Filters';
import './styles.scss';
import ModalTemplate from '../../../../shared/ModalTemplate/ModalTemplate';
import filtersUtil from '../../../../../utils/filters/filtersUtil';

const SubQueryModal = ({
  hideCollectionAlias,
  show,
  handleSetSelectedFiltersState,
  handleSetSelectionState,
  handleCancelChangesSubQueryModal,
  selectedDataExtensions,
  selectedFilters, // filters of the main query
  subQueryModalFilterLineId,
  subQueryModalSelectedFilters, // filter for the subquerymodal
  getDataExtensionOrDataViewFields,
  compareSelectedDataExtensions,
  handleFeatureMissing,
  manageSubscriberRelationship,
  DEBorderMouseOver,
  filterBorderMouseOver,
  pickLists,
  handlePickListOptions,
  handleRemoveFilterLine,
  isSubQueryRelation,
  applyTimezoneSettingsToAllDateFields,
  handleSetTimezoneToAllDateFields,
  timezoneSettingsForAllDateFields,
  showEssentialsUpgradeModal,
}) => {
  /**
   * Closes the subquery modal
   * @returns {void}
   */
  const closeSubQueryModal = () => {
    handleSetSelectedFiltersState({
      showSubQueryModal: false,
      isSubQueryRelation: false,
    });
  };

  /**
   * handle remove and update subQuery filter line
   * @param {object} properties - Required properties to delete filterline
   * @returns {void}
   */
  const removeAndUpdateSubQueryFilterLine = (properties) => {
    const { subQueryModalFilterLineId } = properties;

    const updatedFilters = handleRemoveFilterLine(properties);

    const subQueryFiltersPath = filtersUtil.createSubQueryFilterPath(subQueryModalFilterLineId);

    handleSetSelectedFiltersState(
      {
        subQueryModalSelectedFilters: _.get(updatedFilters, subQueryFiltersPath),

      },
    );
  };

  /**
   * Update values on filterline
   * @param {string} id - Id of current filter
   * @param {object} subQueryFilters - Filters of sub query
   * @param {object[]} filtersBranch - Filters
   * @returns {void}
   */
  const handleUpdateSubQuery = (id, subQueryFilters, filtersBranch) => {
    const { filters } = selectedFilters;

    /*
     * This is the filters array we need to find a matching element on:
     * works on the filters state unless a specific branch is passed
     */
    const filtersArray = filtersBranch || filters;
    // Loop through filtersArray

    for (let f = 0; f < filtersArray.length; f += 1) {
      // If the array element contains a filters array, execute this function on this branch
      if (filtersArray[f].filters) {
        handleUpdateSubQuery(id, subQueryFilters, filtersArray[f].filters);
      } else {
        if (filtersArray[f].id === id) {
          // define a subQuery object if none exists yet
          if (!filtersArray[f].subQuery) {
            filtersArray[f].subQuery = {};
          }
          // set the filters of the subQuery to the filters shown in this subquerymodal
          filtersArray[f].subQuery.filters = subQueryFilters;
        }
      }
    }
    handleSetSelectedFiltersState(
      {
        filters: filtersArray,
      },
    );
  };

  /**
   * Save filters and close subquery modal
   * @returns {void}
   */
  const save = () => {
    handleUpdateSubQuery(subQueryModalFilterLineId, subQueryModalSelectedFilters);

    if (applyTimezoneSettingsToAllDateFields) {
      // Find first available date filter
      const filtersParam = Array.isArray(subQueryModalSelectedFilters) ?
        subQueryModalSelectedFilters :
        [subQueryModalSelectedFilters];

      const firstAvailableFilter = filtersUtil.findDateTypeFilter(filtersParam);

      const {
        convertToTimezone, convertTimezone, convertFromTimezone,
      } = firstAvailableFilter;

      handleSetTimezoneToAllDateFields(convertTimezone, convertToTimezone, convertFromTimezone);
    }

    closeSubQueryModal();
  };

  /**
   * Cancel changes in subquery modal and close it
   * @returns {void}
   */
  const cancel = () => {
    handleCancelChangesSubQueryModal();
    closeSubQueryModal();
  };

  /**
   * Keeps the selectedFilters filters state on parent level
   * @param {object} filters - Filters added in subquery modal
   * @param {number} tabIndexForSubQueryFilter - index number of tab passed when updating filters in subQuery modal.
   * Prevents the operator from resetting when filter is removed or dragged
   * @returns {void}
   */
  const handleFiltersSave = (filters, tabIndexForSubQueryFilter) => {
    handleSetSelectedFiltersState(
      {
        subQueryModalSelectedFilters: {
          filters: filters.filters,
          operator: typeof tabIndexForSubQueryFilter === 'number' ?
            subQueryModalSelectedFilters.operator :
            filters.operator,
        },
      },
    );
  };

  return (
    <div style={{ display: show ? 'block' : 'none' }}>
      <ModalTemplate
        id="subquery-modal-dialog"
        className="subquery-modal slds-modal_large"
        headerId="modal-heading-01"
        headerTitle="Filter Values"
        contentClassName="slds-p-around_medium subquery-dialog"
        contentId="modal-content-id-1"
        cancelButtonId="subquery-dialog-cancel"
        handleCancel={cancel}
        saveButtonId="subquery-dialog-save"
        handleSave={save}
      >
        <Filters
          hideCollectionAlias={hideCollectionAlias}
          showInResultsOption={false}
          selectedDataExtensions={selectedDataExtensions}
          compareSelectedDataExtensions={compareSelectedDataExtensions}
          selectedFilters={subQueryModalSelectedFilters || []}
          handleFiltersSave={handleFiltersSave}
          handleSetSelectionState={handleSetSelectionState}
          getDataExtensionOrDataViewFields={getDataExtensionOrDataViewFields}
          handleFeatureMissing={handleFeatureMissing}
          isSubQuery
          isFromSelection
          subQueryModalFilterLineId={subQueryModalFilterLineId}
          manageSubscriberRelationship={manageSubscriberRelationship}
          DEBorderMouseOver={DEBorderMouseOver}
          filterBorderMouseOver={filterBorderMouseOver}
          pickLists={pickLists}
          handlePickListOptions={handlePickListOptions}
          handleRemoveFilterLine={removeAndUpdateSubQueryFilterLine}
          handleUpdateSubQuery={handleUpdateSubQuery}
          isSubQueryRelation={isSubQueryRelation}
          applyTimezoneSettingsToAllDateFields={applyTimezoneSettingsToAllDateFields}
          timezoneSettingsForAllDateFields={timezoneSettingsForAllDateFields}
          handleSetTimezoneToAllDateFields={handleSetTimezoneToAllDateFields}
          showEssentialsUpgradeModal={showEssentialsUpgradeModal}
        />
      </ModalTemplate>
    </div>
  );
};

SubQueryModal.propTypes = {
  /**
   * It determines if the collection alias should be hidden after the name in the filter line
   */
  hideCollectionAlias: PropTypes.bool.isRequired,
  /**
   * It stores the value of subquery modal if it will be shown or not
   */
  show: PropTypes.bool.isRequired,
  /**
   * It helps to set the Selection`s state
   * It will be passed from Selection.js
   */
  handleSetSelectionState: PropTypes.func.isRequired,
  /**
   * It helps to set the SelectedFilters` state
   * It will be passed from SelectedFilters.js
   */
  handleSetSelectedFiltersState: PropTypes.func.isRequired,
  /**
   * It helps to close the subquery modal in ease
   * It will be passed from SelectedFilters.js
   */
  handleCancelChangesSubQueryModal: PropTypes.func.isRequired,
  /**
   * It keeps the selected data extension for the subquery
   */
  selectedDataExtensions: PropTypes.instanceOf(Array),
  /**
   * It keeps the selected filters for a Selection
   * selectedFilters are stored as filters in database
   * It will be passed from Selection.js
   */
  selectedFilters: PropTypes.instanceOf(Object),
  /**
   * It keeps the filterline IDs of a subquery`s filters
   */
  subQueryModalFilterLineId: PropTypes.string,
  /**
   * It keeps the selected filters the for the subquery
   */
  subQueryModalSelectedFilters: PropTypes.instanceOf(Object),
  /**
   * It helps to retrieve fields of a data extension or data view from SFMC
   * It will be passed from Selection.js
   */
  getDataExtensionOrDataViewFields: PropTypes.func.isRequired,
  /**
   * It keeps the all selected data extensions if the subquery modal is opened
   */
  compareSelectedDataExtensions: PropTypes.instanceOf(Array),
  /**
   * It helps to throw a warning message if a feature is disabled and used in the Selection
   * It will be passed from Selection.js
   */
  handleFeatureMissing: PropTypes.func,
  /**
   * It helps to manage subscriber relationship while creating a data extension
   * It will be passed from Selection.js
   */
  manageSubscriberRelationship: PropTypes.func,
  /**
   * Keeps track whether Available DE are dragged
   */
  DEBorderMouseOver: PropTypes.bool.isRequired,
  /**
   * Keeps track whether Available Fields are dragged
   */
  filterBorderMouseOver: PropTypes.bool.isRequired,
  /**
   * Keeps searched picklist
   * It will be passed from Selection.js
   */
  pickLists: PropTypes.instanceOf(Array).isRequired,
  /**
   * Responsible for adding/deleting fields Object IDs when searching picklist for the options
   * it will be passed from Selection.js
   */
  handlePickListOptions: PropTypes.func.isRequired,
  /**
   * It Removes a given filterLine
   * It is passed from Selection.js
   */
  handleRemoveFilterLine: PropTypes.func,
  /**
   * Indicates whether the parent of the subQuery is a relation
   */
  isSubQueryRelation: PropTypes.bool.isRequired,
  /**
   * Indicates whether timezone settings are applied to all date filters
   */
  applyTimezoneSettingsToAllDateFields: PropTypes.bool,
  /**
   * Handles the setting of timezone settings to all date filters
   */
  handleSetTimezoneToAllDateFields: PropTypes.func,
  /**
   * An object containing timezone details
   */
  timezoneSettingsForAllDateFields: PropTypes.instanceOf(Object),
  /**
   * It toggles a feature advert modal on with specific feature
   */
  showEssentialsUpgradeModal: PropTypes.func,
};

export default SubQueryModal;
