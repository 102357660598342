import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import swal from 'sweetalert2';
import moment from 'moment';
import { Dropdown } from 'semantic-ui-react';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';

import mapStateToProps from '../../../../../mapStateToProps';
import Constants from '../../../../../constants/constants';
import Features from '../../../../../features';
import Util from '../../../../../util';
import WhiteFormulaIcon from '../../../../../icons/white-formula.png';
import Tooltip from '../../../../shared/Tooltip/Tooltip';
import RelationFilterLine from './RelationFilterLine/RelationFilterLine';
import TagInput from './TagInput/TagInput';
import Button from '../../../../shared/Button/Button';
import Select from '../../../../shared/Select/Select';
import MultiSelect from './MultiSelect/MultiSelect';
import './styles.scss';
import Input from '../../../../shared/Input/Input';
import RadioButton from '../../../../shared/RadioButton/RadioButton';
import filtersUtil from '../../../../../utils/filters/filtersUtil';
import timeUtil from '../../../../../utils/time/timeUtil';
import SwalUtil from '../../../../../utils/swal/swalUtil';
// eslint-disable-next-line import/no-cycle
import InResultsFormulaFilterLine from './InResultsFormulaFilterLine/InResultsFormulaFilterLine';
import UpgradeBadge from '../../../../shared/UpgradeBadge/UpgradeBadge';
import { featureAdvertExists } from '../../../../shared/Feature/featureUtil';
import VirtualizedList from '../../../../shared/Dropdown/Dropdown';

const classNames = require('classnames');

const FilterLine = ({
  dataExtensions,
  handleSetDataExtensionToFilter,
  id,
  handleRemoveFilterLine,
  criteria,
  value,
  filterIndex,
  handleHoverFilterMerging,
  showGroupButton,
  handleHideAddFiltersTogether,
  filters,
  collectionAlias,
  hideCollectionAlias,
  field,
  fieldType,
  handleMergeFilterLines,
  handleUpdateFilterLineCriteria,
  handleUpdateFormulaFilterLineCriteria,
  handleUpdateFilterLineValue,
  isSubQuery,
  isCompareFieldsFilter,
  handleOpenSubQueryModal,
  dateFilterType,
  dateValue,
  filterInterval,
  dateValueStart,
  startDate,
  endDate,
  subQuery,
  handleRemoveDataExtensionFromFilter,
  handleChangeFilterDataExtensionField,
  handleOnChangeRelativeDate,
  handleOnChangeIsCompareFieldsFilter,
  selectedDataExtensions,
  compareSelectedDataExtensions,
  handleOnChangeFilterComparedDataExtension,
  handleOnChangeCompareFieldsFilter,
  comparableFields,
  comparedDataExtension,
  comparedField,
  handleFeatureMissing,
  fieldObjectID,
  handleOnChangeFormulaFilterFormulaValue,
  formula,
  handleOnChangeFormulaValue,
  handleAddFormulaFilter,
  filterBorderMouseOver,
  showInResultsOption,
  pickLists,
  handleOnChangeIsPicklistOption,
  isPicklistOptions,
  loadingSubQueryFields,
  subQueryModalFilterLineId,
  handleFiltersSave,
  filterType,
  subQueryFilters,
  relation,
  filterObject,
  returnPredefinedRelationById,
  tags,
  handleTagsChanged,
  handleSetTags,
  updateTags,
  convertTimezone,
  handleOpenTimezoneModal,
  convertToTimezone,
  convertFromTimezone,
  DEBorderMouseOver,
  isInResultsFormula,
  formulaForRelationFilter,
  selectedFieldObjectID,
  parentId,
  handleLogicChange,
  dropFieldOnFilters,
  handleSetSelectionState,
  collapseFormulas,
  handleCollapseAllFormulas,
  selectedActiveDE,
  showEssentialsUpgradeModal,
  userInfo,
  orgInfo,
  featuresInfo,
}) => {
  const uniqueId = Util.uuid(8);

  const [showFormulas, setShowFormulas] = useState(true);
  const [filterInputValues, setFilterInputValues] = useState('');

  const featureFormulasInFiltersIsEnabled = Features.isFeatureEnabled(
    featuresInfo,
    Constants.FEATURE__FORMULAS_IN_FILTERS,
  );

  // Set isUsingTagInput if fieldType is decimal/number and criteria is in/not in
  const isUsingTagInput = filtersUtil.isFilterUsingTagInput(fieldType, criteria);

  const featurePickLists = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__PICKLISTS);

  let isMultiSelect = false;

  let targetDragIcon = null;

  // check if there is a dataExtensionFields property in subQuery
  const checkIfDataExtensionFieldsAreDefined = subQuery && subQuery.dataExtensionFields !== undefined;

  const hasDataExtensionFields =
    Boolean(subQuery && subQuery.dataExtensionFields && subQuery.dataExtensionFields.length);

  const showPicklistsAdvertFormulas = [
    Constants.FILTERLINE__CRITERIA__IN,
    Constants.FILTERLINE__CRITERIA__NOT_IN,
    Constants.FILTERLINE__CRITERIA__EQUALS,
    Constants.FILTERLINE__CRITERIA__NOT_EQUAL_TO,
    Constants.FILTERLINE__CRITERIA__CONTAINS,
    Constants.FILTERLINE__CRITERIA__DOES_NOT_CONTAIN,
    Constants.FILTERLINE__CRITERIA__CONTAINS_ONE_OF,
    Constants.FILTERLINE__CRITERIA__DOES_NOT_CONTAIN_ONE_OF,
  ].includes(criteria);

  const checkIfFormulasInSubQuery = (filtersArray, hasFormulas = false) => {
    if (filtersArray && filters.length) {
      filtersArray.forEach((filter) => {
        if (filter.formula) {
          hasFormulas = true;
        }
        if (filter?.filters) {
          hasFormulas = checkIfFormulasInSubQuery(filter.filters, hasFormulas);
        }
      });
    }

    return hasFormulas;
  };

  /**
   * This has the same effect as ComponentDidMount, it replaces the onLoad property on the <select> tag
   * You cannot update the DOM in the render function, therefore it's the only way to do it inside a
   * functional component.
   */
  useEffect(() => {
    // When creating a new filterline, we set the EQUALS criteria by default
    if (criteria === '' && !relation) handleUpdateFilterLineCriteria(id, Constants.FILTERLINE__CRITERIA__EQUALS);

    // When creating a new filterline with a BOOLEAN, we set 'True' as its default value
    if (
      !value &&
      fieldType === Constants.FILTERLINE__FIELDTYPE__BOOLEAN &&
      criteria !== Constants.FILTERLINE__CRITERIA__IS_EMPTY &&
      criteria !== Constants.FILTERLINE__CRITERIA__IS_NOT_EMPTY
    ) {
      handleUpdateFilterLineValue(id, 'True');
    }

    // Transform old 'Equals/Not' filter with dateValueStart Today to 'Is Today' filter
    if (fieldType === Constants.FILTERLINE__FIELDTYPE__DATE &&
      filtersUtil.isEqualOrNotEqual(criteria) &&
      dateFilterType === Constants.FILTERLINE__DATE_TYPE__RELATIVE &&
      dateValueStart === Constants.FILTERLINE__DATE_VALUE_START__TODAY
    ) {
      handleUpdateFilterLineCriteria(id, Constants.FILTERLINE__CRITERIA__DATE__TODAY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, criteria, fieldType, value, handleUpdateFilterLineCriteria, handleUpdateFilterLineValue,
    loadingSubQueryFields, relation]);

  /**
   * Function that sets the filter value in the tags object after loading
   * Wrap function with useCallback to avoid change on every render
   * @returns {void}
   */
  const setFilterValueInTags = useCallback(
    () => {
      // Convert value to tags onLoad
      if (isUsingTagInput && !tags[id] && value && Array.isArray(value)) {
        handleSetTags(value, id);
      }
    },

    /* eslint-disable-next-line */
    [tags],
  );

  useEffect(() => {
    setFilterValueInTags();
  }, [setFilterValueInTags]);

  /**
   * Function for setting Count formula by default for relation filter
   * Wrap function with useCallback to avoid change on every render
   * @returns {void}
   */
  const setCountFormulaByDefault = useCallback(
    () => {
      // When creating a new filterline for relation filter, we set the COUNT formula by default
      if (subQuery?.relationData && !subQuery.relationData.formula && relation) {
        handleOnChangeFormulaFilterFormulaValue(id, Constants.FORMULA__VALUE__COUNT, true);
      }
    },

    // specify all dependencies
    /* eslint-disable-next-line */
    [handleOnChangeFormulaFilterFormulaValue, id, relation, subQuery?.relationData],
  );

  useEffect(() => {
    // trigger function to set default formula for relation filter
    setCountFormulaByDefault();
  }, [setCountFormulaByDefault]);

  useEffect(() => {
    // trigger function to set all showFormulas to false
    if (collapseFormulas) {
      setShowFormulas(false);
    }
  }, [collapseFormulas]);

  // get picklist options for the current filterline
  const optionForPicklist = filtersUtil.returnActiveOptionsForPicklist(pickLists, fieldObjectID);
  // get predefinedRelation for relation filter
  const predefinedRelation = filterObject?.relationId &&
    returnPredefinedRelationById(filterObject.relationId, filterObject);

  // get predefinedRelation depending on whether it is a formula filterline or not
  const getPredefinedRelation = isInResultsFormula ? subQuery.selectedDataExtension : predefinedRelation;

  // get formula for relation filter depending on whether it is a formula filterline or not
  const getFormulaForRelationFilter = isInResultsFormula ?
    formulaForRelationFilter || formula :
    subQuery?.relationData?.formula;

  // get value for relation filter depending on whether it is a formula filterline or not
  const getValueForRelationFilter = isInResultsFormula ? value : subQuery?.relationData?.value;

  // get selectedFieldObjectID for relation filter depending on whether it is a formula filterline or not
  const getSelectedFieldObjectID = isInResultsFormula ?
    selectedFieldObjectID || fieldObjectID :
    subQuery?.relationData?.fieldObjectID;

  // get criteria for relation filter depending on whether it is a formula filterline or not
  const getCriteriaForRelationFilter = isInResultsFormula ?
    criteria :
    subQuery?.relationData?.criteria;

  // check if subquery has formulas
  const subQueryHasFormulaFilters = subQuery?.formulas?.filters?.length > 0;

  // text for formula filterline title
  const formulaButtonTitle = subQueryHasFormulaFilters ? 'Formulas selected' : 'No formula selected';

  // expand/collapse button for filterline with formulas

  const expandCollapseButton = showFormulas ? 'fa-caret-up' : 'fa-caret-down';

  /**
   * This function helps to change formula value for the field
   * @param {string} subQueryField - name of a filter in a subQuery
   * @param {string} collectionAliasForSelectedDE - collectionAlias is for selected DE`s alias
   * @param {string} filterId - id is for filter`s id in the filters array
   * @param {string} fieldTypeInSubQuery - type of field in a subQuery
   * @param {object} formulaInSubQuery - formula is for the value of the formula
   * @param {array} optForPicklist - options for the picklist
   * @returns {void}
   */
  const handleOldSubQueryFormulas = async (
    subQueryField,
    collectionAliasForSelectedDE,
    filterId,
    fieldTypeInSubQuery,
    formulaInSubQuery,
    optForPicklist,
  ) => {
    // it helps to build swal`s html content
    const handleBuildSwalContent = (
      formulaLabel,
      formulaValue,
      formulaDescription,
      formulaId,
      fieldTypeForSwalContent,
      formulaForSwalContent,
      disable,
    ) => `
        <div
          class="formula-class ${disable ? 'disable-formula-radio-container' : ''}"
          title="${disable ?
    `${formulaLabel} is not available for ${fieldTypeForSwalContent} type of fields` :
    `${formulaLabel}`}"
        >
        <input
          id="${formulaId}"
          type="radio"
          class="formula-radio"
          value="${formulaValue}"
          ${formulaForSwalContent && formulaValue === formulaForSwalContent ? 'checked' : ''}
          ${disable ? 'disabled' : ''}
        />
        <label
          for="${formulaId}"
          class="${disable ? 'disable-formula-radio-container' : ''}"
          title="${disable ?
    `${formulaLabel} is not available for ${fieldTypeForSwalContent} type of fields` :
    `${formulaLabel}`}"
        >
          <p>${formulaLabel}:</p><p>${formulaDescription}</p>
        </label>
      </div>
      `;
    const disable = !filtersUtil.isNumberOrDecimal(fieldTypeInSubQuery);
    const swalContent = `
        <div
          class=""
          id="${Constants.FORMULA__INPUT__ID_VALUE}"
        >
        ${handleBuildSwalContent(
    Constants.FORMULA__LABEL__NONE,
    Constants.FORMULA__VALUE__NONE,
    'Do not use a formula',
    'f-formula-none',
    fieldTypeInSubQuery,
    formulaInSubQuery,
  )}
        ${handleBuildSwalContent(
    Constants.FORMULA__LABEL__COUNT,
    Constants.FORMULA__VALUE__COUNT,
    `Count the number of times <b>${Util.abbreviate(subQueryField, 40)}</b>
      exists in <b>${Util.abbreviate(collectionAliasForSelectedDE, 40)}</b>`,
    'f-formula-count',
    fieldTypeInSubQuery,
    formulaInSubQuery,
  )}
        ${handleBuildSwalContent(
    Constants.FORMULA__LABEL__AVERAGE,
    Constants.FORMULA__VALUE__AVERAGE,
    `Take the average value for <b>${Util.abbreviate(subQueryField, 40)}</b> in
      <b>${Util.abbreviate(collectionAliasForSelectedDE, 40)}</b>`,
    'f-formula-average',
    fieldTypeInSubQuery,
    formulaInSubQuery,
    disable,
  )}
        ${handleBuildSwalContent(
    Constants.FORMULA__LABEL__SUM,
    Constants.FORMULA__VALUE__SUM,
    `Add up the values for <b>${Util.abbreviate(subQueryField, 40)}</b>
      in <b>${Util.abbreviate(collectionAliasForSelectedDE, 40)}</b>`,
    'f-formula-sum',
    fieldTypeInSubQuery,
    formulaInSubQuery,
    disable,
  )}
        ${handleBuildSwalContent(
    Constants.FORMULA__LABEL__MINIMUM,
    Constants.FORMULA__VALUE__MINIMUM,
    `Take the minimum value for <b>${Util.abbreviate(subQueryField, 40)}</b> in
      <b>${Util.abbreviate(collectionAliasForSelectedDE, 40)}</b>`,
    'f-formula-min',
    fieldTypeInSubQuery,
    formulaInSubQuery,
    disable,
  )}
        ${handleBuildSwalContent(
    Constants.FORMULA__LABEL__MAXIMUM,
    Constants.FORMULA__VALUE__MAXIMUM,
    `Take the maximum value for <b>${Util.abbreviate(subQueryField, 40)}</b> in
      <b>${Util.abbreviate(collectionAliasForSelectedDE, 40)}</b>`,
    'f-formula-max',
    fieldTypeInSubQuery,
    formulaInSubQuery,
    disable,
  )}
      </div>`;

    let getFormulaValue = await SwalUtil.fire({
      message: swalContent,
      htmlClass: ' ',
      title: 'Formulas',
      options: {
        showCancelButton: true,
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Select',
        width: '45rem',
        customClass: {
          cancelButton: 'slds-button slds-button_neutral',
          confirmButton: 'slds-button slds-button_brand moveLeft',
        },
        didOpen: () => {
          // assigned all radio buttons to a variable
          const radioInputs = swal.getContent().getElementsByClassName('formula-radio');
          // get the div which will keep the value of the formula
          const getFormulaValueDiv = swal.getContent().querySelector(`#${Constants.FORMULA__INPUT__ID_VALUE}`);

          if (formulaInSubQuery) {
            for (let r = 0; r < radioInputs.length; r += 1) {
              // if there is formula find the radio and check it`s value
              if (radioInputs[r].value === formulaInSubQuery) {
                radioInputs[r].checked = true;
                getFormulaValueDiv.setAttribute('value', radioInputs[r].value);
              }
            }
          } else {
            // if there is no formula value, check none option
            radioInputs[0].checked = true;
            getFormulaValueDiv.setAttribute('value', Constants.FORMULA__VALUE__NONE);
          }

          /**
           * Helps to change between formula values
           * @param {object} e - event
           * @returns {void}
           */
          function handleChangeRadioButtons(e) {
            const selectedId = e.target.id;

            for (let r = 0; r < radioInputs.length; r += 1) {
              if (radioInputs[r].id === selectedId) {
                radioInputs[r].checked = true;
                getFormulaValueDiv.setAttribute('value', radioInputs[r].value);
              } else {
                radioInputs[r].checked = false;
              }
            }
          }
          // add handleChangeRadioButtons function to radio buttons
          for (let r = 0; r < radioInputs.length; r += 1) {
            radioInputs[r].addEventListener('change', handleChangeRadioButtons);
          }
        },
        preConfirm: () => ({
          formulaValue: swal.getContent().querySelector(`#${Constants.FORMULA__INPUT__ID_VALUE}`)
            .getAttribute('value') ||
            Constants.FORMULA__VALUE__NONE,
        }),
      },
    });

    if (getFormulaValue && getFormulaValue.value) {
      getFormulaValue = getFormulaValue.value.formulaValue;
      handleOnChangeFormulaFilterFormulaValue(
        filterId,
        getFormulaValue,
        !!(optForPicklist && optForPicklist.length > 0),
      );
    }
  };

  /**
   * This function helps to change formula value for the field
   * @param {string} filterId - id is for filter`s id in the filters array
   * @returns {void}
   */
  const handleSubQueryFormulas = async (
    filterId,
  ) => {
    handleAddFormulaFilter(
      filterId,
      subQuery.fields[0] ? subQuery.fields[0].collectionAlias : '',
      subQuery.fields[0] ? subQuery.fields[0].field : '',
      subQuery.fields[0] ? subQuery.fields[0].availableFieldObjectID : '',
      subQuery.fields[0] ? subQuery.fields[0].fieldType : '',
      Constants.FORMULA__VALUE__COUNT,
    );
  };

  // remove filter line where compared field is deleted from SFMC
  if (comparedField && comparedField.Name && !comparedField.ObjectID && comparedDataExtension.Name) {
    handleRemoveFilterLine({
      filterLineId: id,
      field: comparedField.Name.toString(),
      dataExtension: comparedDataExtension.Name.toString(),
      handleFiltersSave,
    });
  }

  const featureDynamicDateFilterIsEnabled = Features.isFeatureEnabled(
    featuresInfo,
    Constants.FEATURE__DYNAMIC_DATE_FILTERS,
  );

  const featureSubQueriesAreEnabled = Features.isFeatureEnabled(
    featuresInfo,
    Constants.FEATURE__SUB_QUERIES,
  );

  const featureCompareFields = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__COMPARE_FIELDS);

  /*
   * Define datePicker date format based on the users Locale
   * Note: we don't use the built-in Locale setting of ReactDatePicker as it adds a comma between the date and time
   */
  const userLocale = timeUtil.getUserLocale(userInfo);
  // note: the datetime format here is different from what util.getUserDateTimeFormat returns
  const datePickerDateFormat = timeUtil.getDatePickerDateTimeFormat(userLocale);

  /*
   * it checks if feature is disabled and dateFilterType is relative
   * if they re like that then call the function handleFeatureMissing
   */
  if (dateFilterType === Constants.FILTERLINE__DATE_TYPE__RELATIVE && !featureDynamicDateFilterIsEnabled) {
    handleFeatureMissing(Constants.FEATURE__DYNAMIC_DATE_FILTERS_LABEL);
  }

  /* with this reference we can change if it's draggable or not dynamically */
  const draggableDiv = React.createRef();

  /**
   * This function takes in the index of the filterline and calculates with which
   * other filterline to merge with.
   * @param {number} currentFiltersIndex - index of the current filterline
   * @param {bool} checkIfIsInResultsFormula - check if criteria in filterline is in results formula
   * @returns {object} handleMergeFilterLines - the function that handles the merging
   * of the two filterlines
   */
  const prepareIdsAndMergeFilterLines = async (currentFiltersIndex, checkIfIsInResultsFormula) => {
    let id1 = filters[currentFiltersIndex].id;

    let id2;
    // If the current filterline is the last in its container

    if (currentFiltersIndex === filters.length - 1) {
      id1 = filters[currentFiltersIndex - 1].id;
      id2 = filters[currentFiltersIndex].id;
    } else {
      /*
       * If the filter next to it is a filter container and it's not
       * the first in its container, merge it with the one above
       */
      if (currentFiltersIndex !== 0 && filters[currentFiltersIndex + 1].filters) {
        id1 = filters[currentFiltersIndex - 1].id;
        id2 = filters[currentFiltersIndex].id;
      } else {
        // Other cases
        id2 = filters[currentFiltersIndex + 1].id;
      }
    }
    await handleMergeFilterLines(
      id1,
      id2,
      parentId,
      null,
      checkIfIsInResultsFormula,
    );

    updateTags();
  };

  /**
   * Function to determine if + icon should be rendered
   * @returns {boolean} if the + icon should be rendered
   */
  const shouldMergeIconBeRendered = () => {
    // Prepare counts
    let notNestedFiltersLength = 0;

    let nestedFiltersLength = 0;

    filters.forEach((filter) => {
      if (filter.filters) {
        nestedFiltersLength += 1;
      } else {
        notNestedFiltersLength += 1;
      }
    });
    /*
     * show + icon as soon as 2 filterlines become available besides filtercontainers OR
     * as soon as 3 filterlines become available if there are no sibling filtercontainers
     */
    if (
      (notNestedFiltersLength >= 2 && notNestedFiltersLength < filters.length) ||
      (notNestedFiltersLength >= 3 && nestedFiltersLength === 0)
    ) {
      // check if previous or next line, with which the line would merge, is a filterline
      let filterToMergeWith;

      let filterContainer;

      for (let f = 0; f < filters.length; f += 1) {
        // get matching filter in filters
        if (filters[f].id === id) {
          filterContainer = filters;
          if (f === filters.length - 1) {
            filterToMergeWith = filters[f - 1];
          } else {
            filterToMergeWith = filters[f + 1];
          }
          break;
        }
      }

      /**
       * If the filter is the first in its container and the filter to merge with
       * is another filter container, don't render the add button
       */
      if (filterToMergeWith && filterToMergeWith.filters &&
        (filterIndex === 0 || filterContainer[filterIndex - 1].filters)
      ) {
        return false;
      }

      return true;
    }

    return false;
  };

  /**
   * onDragStart - set data to be transferred
   * @param {object} event - Event
   * @param {string} filterLineId - Id of dragged element
   * @param {string} filterLineParentId - Id of dragged element's parent
   * @returns {void}
   */
  const dragFilter = (event, filterLineId, filterLineParentId) => {
    // Checks if it is dragging from target drag icon then it allows dragging and changes order
    if (draggableDiv.current.contains(targetDragIcon)) {
      event.dataTransfer.setData('type', 'filter');
      event.dataTransfer.setData('filterLineId', filterLineId);
      event.dataTransfer.setData('filterLineParentId', filterLineParentId);
    } else { /* If it's dragging from anywhere else in FilterLine than drag icon, then it will prevent to
     change order and do nothing */
      event.preventDefault();
    }
  };

  /**
   * Render static text
   * @param {String} text Text to render
   * @returns {JSX.Element} Static text
   */
  const filterLineText = text => (
    <span
      className="filter-line-text"
    >
      {text}
    </span>
  );

  /**
   * Returns true or false depending on the existence of subQuery
   * @returns {boolean} True of false
   */
  const subQueryDataExtensionChosen = () => (subQuery && subQuery.collections && subQuery.collections.length > 0 &&
    (subQuery.collections[0].collection + ''));

  /**
   * Function which limits field comparison combinations
   * @param {string} fieldTypeForFieldOption - Field type
   * @param {string} fieldNameForFieldOption - Field name
   * @returns {object} The field option
   */
  const renderFieldOption = (fieldTypeForFieldOption, fieldNameForFieldOption) => {
    let renderOption = false;
    const noFieldsToCompareTo = 'No fields to compare to';

    switch (fieldTypeForFieldOption) {
      case Constants.FILTERLINE__FIELDTYPE__TEXT:
        renderOption = fieldNameForFieldOption.FieldType === Constants.FILTERLINE__FIELDTYPE__TEXT ||
          fieldNameForFieldOption.FieldType === Constants.FILTERLINE__FIELDTYPE__EMAILADDRESS ||
          fieldNameForFieldOption.FieldType === Constants.FILTERLINE__FIELDTYPE__PHONE ||
          fieldNameForFieldOption.FieldType === Constants.FILTERLINE__FIELDTYPE__LOCALE;
        break;
      case Constants.FILTERLINE__FIELDTYPE__NUMBER:
      case Constants.FILTERLINE__FIELDTYPE__DECIMAL:
        renderOption = filtersUtil.isNumberOrDecimal(fieldNameForFieldOption.FieldType);
        break;
      case Constants.FILTERLINE__FIELDTYPE__DATE:
        renderOption = fieldNameForFieldOption.FieldType === Constants.FILTERLINE__FIELDTYPE__DATE;
        break;
      case Constants.FILTERLINE__FIELDTYPE__BOOLEAN:
        renderOption = fieldNameForFieldOption.FieldType === Constants.FILTERLINE__FIELDTYPE__BOOLEAN;
        break;
      case Constants.FILTERLINE__FIELDTYPE__EMAILADDRESS:
        renderOption = fieldNameForFieldOption.FieldType === Constants.FILTERLINE__FIELDTYPE__TEXT ||
          fieldNameForFieldOption.FieldType === Constants.FILTERLINE__FIELDTYPE__EMAILADDRESS;
        break;
      case Constants.FILTERLINE__FIELDTYPE__PHONE:
        renderOption = fieldNameForFieldOption.FieldType === Constants.FILTERLINE__FIELDTYPE__TEXT ||
          fieldNameForFieldOption.FieldType === Constants.FILTERLINE__FIELDTYPE__PHONE;
        break;
      case Constants.FILTERLINE__FIELDTYPE__LOCALE:
        renderOption = fieldNameForFieldOption.FieldType === Constants.FILTERLINE__FIELDTYPE__TEXT ||
          fieldNameForFieldOption.FieldType === Constants.FILTERLINE__FIELDTYPE__LOCALE;
        break;
      default: renderOption = false;
    }
    if (renderOption) {
      return { value: fieldNameForFieldOption.ObjectID, label: fieldNameForFieldOption.Name.toString() };
    }

    return (
      {
        value: noFieldsToCompareTo,
        hidden: true,
        label: noFieldsToCompareTo,
        key: `no fields to compare to${fieldNameForFieldOption.ObjectID}`,
      }
    );
  };

  /**
   * It prepares necessary criterias for formula filters
   * @returns {object} Rendered formula options
   */
  const renderFormulaOptions = () => {
    const renderOptions = [];

    renderOptions.push({
      value: Constants.FILTERLINE__CRITERIA__EQUALS,
      label: Constants.FILTERLINE__CRITERIA__EQUALS_LABEL,
    });
    renderOptions.push({
      value: Constants.FILTERLINE__CRITERIA__NOT_EQUAL_TO,
      label: Constants.FILTERLINE__CRITERIA__NOT_EQUAL_TO_LABEL,
    });
    renderOptions.push({
      value: Constants.FILTERLINE__CRITERIA__SMALLER_THAN,
      label: Constants.FILTERLINE__CRITERIA__SMALLER_THAN_LABEL,
    });
    renderOptions.push({
      value: Constants.FILTERLINE__CRITERIA__SMALLER_THAN_OR_EQUAL_TO,
      label: Constants.FILTERLINE__CRITERIA__SMALLER_THAN_OR_EQUAL_TO_LABEL,
    });
    renderOptions.push({
      value: Constants.FILTERLINE__CRITERIA__GREATER_THAN,
      label: Constants.FILTERLINE__CRITERIA__GREATER_THAN_LABEL,
    });
    renderOptions.push({
      value: Constants.FILTERLINE__CRITERIA__GREATER_THAN_OR_EQUAL_TO,
      label: Constants.FILTERLINE__CRITERIA__GREATER_THAN_OR_EQUAL_TO_LABEL,
    });

    return renderOptions;
  };

  /**
   * It prepares necessary criterias for filter line options
   * @param {boolean} dateFieldType - defines that criteria will displayed for the date field type
   * @param {boolean} booleanFieldType - defines that criteria will displayed for the boolean field type
   * @param {boolean} textFieldType - defines that criteria will displayed for the text, email, phone, locale field type
   * @returns {array} Array with rendered options
   */
  const renderCriteriaOptions = (dateFieldType, booleanFieldType, textFieldType) => {
    const renderOptions = [];

    if (dateFieldType && featureDynamicDateFilterIsEnabled) {
      renderOptions.push({
        value: Constants.FILTERLINE__CRITERIA__DATE__IN_PREVIOUS,
        label: Constants.FILTERLINE__CRITERIA__DATE__IN_PREVIOUS_LABEL,
      });

      renderOptions.push({
        value: Constants.FILTERLINE__CRITERIA__DATE__IN_NEXT,
        label: Constants.FILTERLINE__CRITERIA__DATE__IN_NEXT_LABEL,
      });
    }

    if (!dateFieldType) {
      renderOptions.push({
        value: Constants.FILTERLINE__CRITERIA__EQUALS,
        label: Constants.FILTERLINE__CRITERIA__EQUALS_LABEL,
      });

      renderOptions.push({
        value: Constants.FILTERLINE__CRITERIA__NOT_EQUAL_TO,
        label: Constants.FILTERLINE__CRITERIA__NOT_EQUAL_TO_LABEL,
      });
    }

    if (!booleanFieldType) {
      if (!textFieldType) {
        renderOptions.push({
          value: Constants.FILTERLINE__CRITERIA__SMALLER_THAN,
          label: dateFieldType ?
            Constants.FILTERLINE__CRITERIA__SMALLER_THAN_DATE_LABEL :
            Constants.FILTERLINE__CRITERIA__SMALLER_THAN_LABEL,
        });

        renderOptions.push({
          value: Constants.FILTERLINE__CRITERIA__SMALLER_THAN_OR_EQUAL_TO,
          label: dateFieldType ?
            Constants.FILTERLINE__CRITERIA__SMALLER_THAN_OR_EQUAL_TO_DATE_LABEL :
            Constants.FILTERLINE__CRITERIA__SMALLER_THAN_OR_EQUAL_TO_LABEL,
        });
      }

      if (dateFieldType) {
        renderOptions.push({
          value: Constants.FILTERLINE__CRITERIA__GREATER_THAN,
          label: Constants.FILTERLINE__CRITERIA__GREATER_THAN_DATE_LABEL,
        });

        renderOptions.push({
          value: Constants.FILTERLINE__CRITERIA__GREATER_THAN_OR_EQUAL_TO,
          label: Constants.FILTERLINE__CRITERIA__GREATER_THAN_OR_EQUAL_TO_DATE_LABEL,
        });

        renderOptions.push({
          value: Constants.FILTERLINE__CRITERIA__EQUALS,
          label: Constants.FILTERLINE__CRITERIA__EQUALS_DATE_LABEL,
        });

        renderOptions.push({
          value: Constants.FILTERLINE__CRITERIA__NOT_EQUAL_TO,
          label: Constants.FILTERLINE__CRITERIA__NOT_EQUAL_TO_DATE_LABEL,
        });

        renderOptions.push({
          value: Constants.FILTERLINE__CRITERIA__BETWEEN,
          label: Constants.FILTERLINE__CRITERIA__BETWEEN_LABEL,
        });

        renderOptions.push({
          value: Constants.FILTERLINE__CRITERIA__NOT_BETWEEN,
          label: Constants.FILTERLINE__CRITERIA__NOT_BETWEEN_LABEL,
        });

        if (featureDynamicDateFilterIsEnabled) {
          renderOptions.push({
            value: Constants.FILTERLINE__CRITERIA__DATE__TODAY,
            label: Constants.FILTERLINE__CRITERIA__DATE__TODAY_LABEL,
          });
        }
      } else {
        renderOptions.push({
          value: Constants.FILTERLINE__CRITERIA__IN,
          label: Constants.FILTERLINE__CRITERIA__IN_LABEL,
        });

        renderOptions.push({
          value: Constants.FILTERLINE__CRITERIA__NOT_IN,
          label: Constants.FILTERLINE__CRITERIA__NOT_IN_LABEL,
        });

        if ((filtersUtil.isNumberOrDecimal(fieldType)) &&
          !isCompareFieldsFilter && !textFieldType) {
          renderOptions.push({
            value: Constants.FILTERLINE__CRITERIA__BETWEEN,
            label: Constants.FILTERLINE__CRITERIA__BETWEEN_LABEL,
          });
        }

        if (!textFieldType) {
          renderOptions.push({
            value: Constants.FILTERLINE__CRITERIA__GREATER_THAN,
            label: Constants.FILTERLINE__CRITERIA__GREATER_THAN_LABEL,
          });

          renderOptions.push({
            value: Constants.FILTERLINE__CRITERIA__GREATER_THAN_OR_EQUAL_TO,
            label: Constants.FILTERLINE__CRITERIA__GREATER_THAN_OR_EQUAL_TO_LABEL,
          });
        }
      }
    }

    if (textFieldType) {
      renderOptions.push({
        value: Constants.FILTERLINE__CRITERIA__CONTAINS,
        label: Constants.FILTERLINE__CRITERIA__CONTAINS_LABEL,
      });

      renderOptions.push({
        value: Constants.FILTERLINE__CRITERIA__DOES_NOT_CONTAIN,
        label: Constants.FILTERLINE__CRITERIA__DOES_NOT_CONTAIN_LABEL,
      });

      renderOptions.push({
        value: Constants.FILTERLINE__CRITERIA__CONTAINS_ONE_OF,
        label: Constants.FILTERLINE__CRITERIA__CONTAINS_ONE_OF_LABEL,
      });

      renderOptions.push({
        value: Constants.FILTERLINE__CRITERIA__DOES_NOT_CONTAIN_ONE_OF,
        label: Constants.FILTERLINE__CRITERIA__DOES_NOT_CONTAIN_ONE_OF_LABEL,
      });

      renderOptions.push({
        value: Constants.FILTERLINE__CRITERIA__BEGINS_WITH,
        label: Constants.FILTERLINE__CRITERIA__BEGINS_WITH_LABEL,
      });

      renderOptions.push({
        value: Constants.FILTERLINE__CRITERIA__ENDS_WITH,
        label: Constants.FILTERLINE__CRITERIA__ENDS_WITH_LABEL,
      });
    }

    if (!isCompareFieldsFilter) {
      renderOptions.push({
        value: Constants.FILTERLINE__CRITERIA__IS_EMPTY,
        label: dateFieldType ?
          Constants.FILTERLINE__CRITERIA__IS_EMPTY_DATE_LABEL :
          Constants.FILTERLINE__CRITERIA__IS_EMPTY_LABEL,
      });

      renderOptions.push({
        value: Constants.FILTERLINE__CRITERIA__IS_NOT_EMPTY,
        label: dateFieldType ?
          Constants.FILTERLINE__CRITERIA__IS_NOT_EMPTY_DATE_LABEL :
          Constants.FILTERLINE__CRITERIA__IS_NOT_EMPTY_LABEL,
      });
    }

    if (featureSubQueriesAreEnabled && !isSubQuery && !isCompareFieldsFilter && showInResultsOption) {
      renderOptions.push({
        value: Constants.FILTERLINE__CRITERIA__IN_RESULTS,
        label: dateFieldType ?
          Constants.FILTERLINE__CRITERIA__IN_RESULTS_DATE_LABEL :
          Constants.FILTERLINE__CRITERIA__IN_RESULTS_LABEL,
      });

      renderOptions.push({
        value: Constants.FILTERLINE__CRITERIA__NOT_IN_RESULTS,
        label: dateFieldType ?
          Constants.FILTERLINE__CRITERIA__NOT_IN_RESULTS_DATE_LABEL :
          Constants.FILTERLINE__CRITERIA__NOT_IN_RESULTS_LABEL,
      });
    }

    return renderOptions;
  };
  /**
   * Get the formula label for the desired formula value
   * @param {string} formulaValue - Keeps the value of the formula
   * @returns {string} Label for the value of the selected formula
   */
  const getFormulaLabelForValue = (formulaValue) => {
    switch (formulaValue) {
      case Constants.FORMULA__VALUE__COUNT:
        return Constants.FORMULA__LABEL__COUNT;
      case Constants.FORMULA__VALUE__SUM:
        return Constants.FORMULA__LABEL__SUM;
      case Constants.FORMULA__VALUE__AVERAGE:
        return Constants.FORMULA__LABEL__AVERAGE;
      case Constants.FORMULA__VALUE__MINIMUM:
        return Constants.FORMULA__LABEL__MINIMUM;
      case Constants.FORMULA__VALUE__MAXIMUM:
        return Constants.FORMULA__LABEL__MAXIMUM;
      default:
        return null;
    }
  };

  /**
   * Retrieves the deAlias from a compared / compared selected / selected DE
   * @param {object} comparedDE - the data extension to compare
   * @param {array} compareSelectedDE - it contains all selected data extensions
   * @param {array} selectedDE - it contains the selected data extensions
   * @returns {string} deAlias
   */
  const getDeAliasFromDE = (
    comparedDE,
    compareSelectedDE,
    selectedDE,
  ) => {
    if (comparedDE) return comparedDE.deAlias;
    if (compareSelectedDE && compareSelectedDE.length) {
      return compareSelectedDE[0].deAlias;
    }

    return selectedDE[0].deAlias;
  };

  /**
   * Handle in and not in result values > 10000
   * @param {*} id - filter type id
   * @param {*} value - filter value
   * @param {*} criteriaValue - type of filter applied
   */

  const handleLimitReachedMessage = async (id, value, criteriaValue) => {
    if ((value.match(/,/g) || []).length > 9999) {
      let arrayOfValues = value.split(',');

      arrayOfValues = arrayOfValues.slice(0, 10000).toString() + '                                                   ';
      setFilterInputValues(arrayOfValues);

      let message = `You are not allowed to use more than 10,000 values.
      Any additional values will be truncated.`;

      switch (criteriaValue) {
        case Constants.FILTERLINE__CRITERIA__IN:
        case Constants.FILTERLINE__CRITERIA__NOT_IN:
          message =
            `You are not allowed to use more than 10,000 values.
        If you need to do so, consider importing the values to a
        Data Extension and use
        ${criteria === Constants.FILTERLINE__CRITERIA__IN ?
    'In' :
    'Not In'} Results filter instead`;

          break;
      }

      const result = await SwalUtil.fire({
        title: 'Limit Reached',
        message,
        options: {
          allowOutsideClick: false,
        },
      });

      if (result.value) {
        handleUpdateFilterLineValue(id, arrayOfValues);
      }
    } else {
      setFilterInputValues(value);
      handleUpdateFilterLineValue(id, value);
    }
  };
  /**
   * Handle not in result and In results filters
   * @param {*} id - filter type id
   * @param {*} value - selected filter
   * @param {*} criteria - filter
   * @param {*} optionForPicklist - picklists
   */

  const handleMultipleValuesLimit = async (id, value, criteria, optionForPicklist) => {
    await handleLimitReachedMessage(id, filterInputValues, value);
    handleUpdateFilterLineCriteria(
      id,
      value,
      criteria,
      null,
      optionForPicklist,
    );
  };

  /**
   * Get the filter string value for specific criteria from user's values
   * @param {string} criteriaValue - Is the criteria (in, not in, empty, not empty)
   * @param {object|string} valueToParse - Are the values to parse
   * @returns {string} Filter string value
   */
  const valuesToFilterStringForInNotInEmptyNotEmpty = (criteriaValue, valueToParse) => {
    switch (criteriaValue) {
      case Constants.FILTERLINE__CRITERIA__IN:
      case Constants.FILTERLINE__CRITERIA__NOT_IN:
        if (typeof valueToParse === 'object' && valueToParse.length > 0) return valueToParse.join(',').trimLeft();

        return '';
      case Constants.FILTERLINE__CRITERIA__IS_EMPTY:
      case Constants.FILTERLINE__CRITERIA__IS_NOT_EMPTY:
        return '';
      default:
        return valueToParse;
    }
  };

  /**
   * Renders picklist options for the certain filterline
   * @param {boolean} minValue - Determines if we are rendering picklist options for between criteria for min value
   * @param {boolean} maxValue - Determines if we are rendering picklist options for between criteria for max value
   * @param {boolean} formulaValue - Determines if we are rendering picklist options when formula is used
   * @returns {object} The picklist options
   */
  const renderPicklistOptions = (minValue, maxValue, formulaValue) => {
    const handleSelectedItem = (item) => {
      if (minValue) {
        handleUpdateFilterLineValue(id, item.value, 'minValue');
      } else if (maxValue) {
        handleUpdateFilterLineValue(id, item.value, 'maxValue');
      } else if (formulaValue) {
        handleOnChangeFormulaValue(id, item.value);
      } else {
        handleUpdateFilterLineValue(id, item.value);
      }
    };

    if (filtersUtil.isEmptyOrNotEmpty(criteria)) {
      return (
        <Input
          placeholder=""
          className="test-value filter-value number-filter-value"
          value=""
          disabled
        />
      );
    }

    if (filtersUtil.isInOrNotInOrOneOf(criteria) ||
      (filtersUtil.isContainsOrNotContains(criteria) && isPicklistOptions)) {
      /**
       * Handler for when the user CLICKS on an option of the dropdown
       * @param {*} newValue - The new value
       * @returns {void}
       */
      const onOptionClick = (newValue) => {
        handleUpdateFilterLineValue(id, newValue);

        if (filtersUtil.isInOrNotInOrOneOf(criteria)) {
          let addedValue = [];

          if (newValue) {
            // define value for the In / Not In criteria
            addedValue = Array.isArray(newValue) ? newValue : [newValue];

            if (newValue.includes(Constants.SEPARATOR_DEFAULT)) {
              // if the value includes a separator, then split it
              addedValue = newValue.split(Constants.SEPARATOR_DEFAULT);
            }
          }

          // add value to tags
          handleTagsChanged(addedValue, id, fieldType);
        }
      };

      return (
        <div className="multiselect-picklist-container">
          <div className="multiselect-picklist-div">
            <MultiSelect
              isPicklistOptions={isPicklistOptions}
              data={optionForPicklist}
              value={value && !Array.isArray(value) ? [value] : value}
              onOptionClick={onOptionClick}
            />
          </div>
          {filtersUtil.isContainsOrNotContains(criteria) ?
            (
              <div className="input-picklist-toggle">
                <Button
                  onClick={async () => handleOnChangeIsPicklistOption(id)}
                  buttonLook={Constants.BUTTON__TYPE__BRAND}
                  className="FL-button"
                  title={`${isPicklistOptions ?
                    'Switch to input' :
                    'Switch to picklist options'}`}
                >
                  <i
                    className={`fas ${isPicklistOptions ? 'fa-font' : 'fa-list'}`}
                  />
                </Button>
              </div>
            ) :
            null}
        </div>
      );
    }

    return (
      <div className="multiselect-picklist-container">
        {filtersUtil.isContainsOrNotContains(criteria) ?
          (
            <Input
              containerClassName="multiselect-picklist-div"
              placeholder=""
              required
              className={'test-value filter-value ' +
                (filtersUtil.isEmptyOrNotEmpty(criteria) ?
                  'is-empty-is-not-empty' :
                  '')}
              onChange={e => handleUpdateFilterLineValue(id, e.target.value)}
              value={valuesToFilterStringForInNotInEmptyNotEmpty(criteria, value)}
              onBlur={e => handleUpdateFilterLineValue(id, e.target.value ?
                e.target.value.trimEnd() :
                '')}
              disabled={!!(filtersUtil.isEmptyOrNotEmpty(criteria))}
            />
          ) :
          (
            <div>
              <VirtualizedList
                data={optionForPicklist}
                handleSelectedItem={handleSelectedItem}
                initialValue={value[minValue ? 'minValue' : 'maxValue'] ?? value}
              />
            </div>
          )}
        {filtersUtil.isContainsOrNotContains(criteria) ?
          (
            <div className="input-picklist-toggle">
              <Button
                onClick={async () => handleOnChangeIsPicklistOption(id)}
                buttonLook={Constants.BUTTON__TYPE__BRAND}
                className="FL-button"
                title={`${isPicklistOptions ?
                  'Switch to input' :
                  'Switch to picklist options'}`}
              >
                <i
                  className={`fas ${isPicklistOptions ? 'fa-font' : 'fa-list'}`}
                />
              </Button>
            </div>
          ) :
          null}
      </div>
    );
  };

  /**
   * Renders the options for the date interval field.
   * @returns {array} The date interval as option fields.
   */
  const optionsForDateFilterInterval = () => {
    // Check if dateValue is greater than 1 and add an 's' at the end of the label
    const labelEnding = dateValue > 1 ? 's' : '';
    const optToRender = [
      [Constants.FILTERLINE__DATE_VALUE__INTERVAL_MINUTE,
        Constants.FILTERLINE__DATE_VALUE__INTERVAL_MINUTES_LABEL + labelEnding],
      [Constants.FILTERLINE__DATE_VALUE__INTERVAL_HOUR,
        Constants.FILTERLINE__DATE_VALUE__INTERVAL_HOURS_LABEL + labelEnding],
      [Constants.FILTERLINE__DATE_VALUE__INTERVAL_DAYS,
        Constants.FILTERLINE__DATE_VALUE__INTERVAL_DAYS_LABEL + labelEnding],
      [Constants.FILTERLINE__DATE_VALUE__INTERVAL_WEEK_DAY,
        Constants.FILTERLINE__DATE_VALUE__INTERVAL_WEEK_DAYS_LABEL + labelEnding],
      [Constants.FILTERLINE__DATE_VALUE__INTERVAL_WEEKS,
        Constants.FILTERLINE__DATE_VALUE__INTERVAL_WEEKS_LABEL + labelEnding],
      [Constants.FILTERLINE__DATE_VALUE__INTERVAL_MONTH,
        Constants.FILTERLINE__DATE_VALUE__INTERVAL_MONTHS_LABEL + labelEnding],
      [Constants.FILTERLINE__DATE_VALUE__INTERVAL_QUARTER,
        Constants.FILTERLINE__DATE_VALUE__INTERVAL_QUARTERS_LABEL + labelEnding],
      [Constants.FILTERLINE__DATE_VALUE__INTERVAL_YEAR,
        Constants.FILTERLINE__DATE_VALUE__INTERVAL_YEARS_LABEL + labelEnding],
    ];

    return optToRender.map(([val, label]) => ({ value: val, label }));
  };

  /**
   * Renders the options for the date value start depending on the date filter interval
   * @returns {array} The date value start as option fields.
   */
  const optionsForDateValueStart = () => {
    let optToRender;
    const oldFilters = [
      [Constants.FILTERLINE__DATE_VALUE_START__TODAY,
        Constants.FILTERLINE__DATE_VALUE_START__TODAY],
      [Constants.FILTERLINE__DATE_VALUE_START__BEFORE_NOW,
        Constants.FILTERLINE__DATE_VALUE_START__BEFORE_NOW],
      [Constants.FILTERLINE__DATE_VALUE_START__AFTER_NOW,
        Constants.FILTERLINE__DATE_VALUE_START__AFTER_NOW],
      [Constants.FILTERLINE__DATE_VALUE_START__BEFORE_TODAY,
        Constants.FILTERLINE__DATE_VALUE_START__BEFORE_TODAY],
      [Constants.FILTERLINE__DATE_VALUE_START__AFTER_TODAY,
        Constants.FILTERLINE__DATE_VALUE_START__AFTER_TODAY],
    ];

    if (criteria === Constants.FILTERLINE__CRITERIA__SMALLER_THAN ||
      criteria === Constants.FILTERLINE__CRITERIA__SMALLER_THAN_OR_EQUAL_TO ||
      criteria === Constants.FILTERLINE__CRITERIA__GREATER_THAN ||
      criteria === Constants.FILTERLINE__CRITERIA__GREATER_THAN_OR_EQUAL_TO) {
      if (filterInterval === Constants.FILTERLINE__DATE_VALUE__INTERVAL_MINUTE ||
        filterInterval === Constants.FILTERLINE__DATE_VALUE__INTERVAL_HOUR) {
        optToRender = [
          [Constants.FILTERLINE__DATE_VALUE_START__TODAY,
            Constants.FILTERLINE__DATE_VALUE_START__TODAY],
          [Constants.FILTERLINE__DATE_VALUE_START__BEFORE_NOW,
            Constants.FILTERLINE__DATE_VALUE_START__BEFORE_DATE_LABEL],
          [Constants.FILTERLINE__DATE_VALUE_START__AFTER_NOW,
            Constants.FILTERLINE__DATE_VALUE_START__AFTER_NOW_DATE_LABEL],
        ];

        // Add exception for old filters
        if (dateValueStart === Constants.FILTERLINE__DATE_VALUE_START__BEFORE_TODAY ||
          dateValueStart === Constants.FILTERLINE__DATE_VALUE_START__AFTER_TODAY) {
          optToRender = oldFilters;
        }
      } else {
        optToRender = [
          [Constants.FILTERLINE__DATE_VALUE_START__TODAY,
            Constants.FILTERLINE__DATE_VALUE_START__TODAY],
          [Constants.FILTERLINE__DATE_VALUE_START__BEFORE_TODAY,
            Constants.FILTERLINE__DATE_VALUE_START__BEFORE_DATE_LABEL],
          [Constants.FILTERLINE__DATE_VALUE_START__AFTER_TODAY,
            Constants.FILTERLINE__DATE_VALUE_START__AFTER_TODAY_DATE_LABEL],
        ];

        // Add exception for old filters
        if (dateValueStart === Constants.FILTERLINE__DATE_VALUE_START__BEFORE_NOW ||
          dateValueStart === Constants.FILTERLINE__DATE_VALUE_START__AFTER_NOW) {
          optToRender = oldFilters;
        }
      }
    } else if (filtersUtil.isEqualOrNotEqual(criteria)) {
      if (filterInterval === Constants.FILTERLINE__DATE_VALUE__INTERVAL_MINUTE ||
        filterInterval === Constants.FILTERLINE__DATE_VALUE__INTERVAL_HOUR) {
        if (dateValueStart === Constants.FILTERLINE__DATE_VALUE_START__BEFORE_TODAY ||
          dateValueStart === Constants.FILTERLINE__DATE_VALUE_START__AFTER_TODAY) {
          optToRender = [
            [Constants.FILTERLINE__DATE_VALUE_START__BEFORE_NOW,
              Constants.FILTERLINE__DATE_VALUE_START__BEFORE_NOW],
            [Constants.FILTERLINE__DATE_VALUE_START__AFTER_NOW,
              Constants.FILTERLINE__DATE_VALUE_START__AFTER_NOW],
            [Constants.FILTERLINE__DATE_VALUE_START__BEFORE_TODAY,
              Constants.FILTERLINE__DATE_VALUE_START__BEFORE_TODAY],
            [Constants.FILTERLINE__DATE_VALUE_START__AFTER_TODAY,
              Constants.FILTERLINE__DATE_VALUE_START__AFTER_TODAY],
          ];
        } else {
          optToRender = [
            [Constants.FILTERLINE__DATE_VALUE_START__BEFORE_NOW,
              Constants.FILTERLINE__DATE_VALUE_START__BEFORE_DATE_LABEL],
            [Constants.FILTERLINE__DATE_VALUE_START__AFTER_NOW,
              Constants.FILTERLINE__DATE_VALUE_START__AFTER_NOW_DATE_LABEL],
          ];
        }
      } else {
        if (dateValueStart === Constants.FILTERLINE__DATE_VALUE_START__BEFORE_NOW ||
          dateValueStart === Constants.FILTERLINE__DATE_VALUE_START__AFTER_NOW) {
          optToRender = [
            [Constants.FILTERLINE__DATE_VALUE_START__BEFORE_NOW,
              Constants.FILTERLINE__DATE_VALUE_START__BEFORE_NOW],
            [Constants.FILTERLINE__DATE_VALUE_START__AFTER_NOW,
              Constants.FILTERLINE__DATE_VALUE_START__AFTER_NOW],
            [Constants.FILTERLINE__DATE_VALUE_START__BEFORE_TODAY,
              Constants.FILTERLINE__DATE_VALUE_START__BEFORE_TODAY],
            [Constants.FILTERLINE__DATE_VALUE_START__AFTER_TODAY,
              Constants.FILTERLINE__DATE_VALUE_START__AFTER_TODAY],
          ];
        } else {
          optToRender = [
            [Constants.FILTERLINE__DATE_VALUE_START__BEFORE_TODAY,
              Constants.FILTERLINE__DATE_VALUE_START__BEFORE_DATE_LABEL],
            [Constants.FILTERLINE__DATE_VALUE_START__AFTER_TODAY,
              Constants.FILTERLINE__DATE_VALUE_START__AFTER_TODAY_DATE_LABEL],
          ];
        }
      }
    } else {
      if (filterInterval === Constants.FILTERLINE__DATE_VALUE__INTERVAL_MINUTE ||
        filterInterval === Constants.FILTERLINE__DATE_VALUE__INTERVAL_HOUR) {
        optToRender = [
          [Constants.FILTERLINE__DATE_VALUE_START__BEFORE_NOW,
            Constants.FILTERLINE__DATE_VALUE_START__BEFORE_DATE_LABEL],
          [Constants.FILTERLINE__DATE_VALUE_START__AFTER_NOW,
            Constants.FILTERLINE__DATE_VALUE_START__AFTER_NOW_DATE_LABEL],
        ];
      } else {
        optToRender = [
          [Constants.FILTERLINE__DATE_VALUE_START__BEFORE_TODAY,
            Constants.FILTERLINE__DATE_VALUE_START__BEFORE_DATE_LABEL],
          [Constants.FILTERLINE__DATE_VALUE_START__AFTER_TODAY,
            Constants.FILTERLINE__DATE_VALUE_START__AFTER_TODAY_DATE_LABEL],
        ];
      }
    }

    return optToRender.map(([val, label]) => ({ value: val, label }));
  };

  /**
   * Get the HTML for a filter line
   * @returns {object} The filter line HTML
   */
  const generateFilterLineHTML = () => {
    if (filtersUtil.isInResultsOrNotInResults(criteria)) {
      return (
        featureSubQueriesAreEnabled && subQueryDataExtensionChosen() && checkIfDataExtensionFieldsAreDefined ?
          (
            <div className="slds-form-element">
              <div className="slds-form-element__control">
                {/*
                  * - If there are fields: we display all of them
                  * - If there are NO fields: we add a single option to specify to user no field is available
                  * and we disable the select
                 */}
                <Select
                  className="slds-truncate filter-criteria subquery-field"
                  onChange={event => handleChangeFilterDataExtensionField(event.target.value, id)}
                  value={subQuery?.fields?.length ? subQuery.fields[0].availableFieldObjectID : ''}
                  disabled={!hasDataExtensionFields}
                  noOptionsLabel={loadingSubQueryFields?.length && loadingSubQueryFields[id] ?
                    'Loading...' :
                    'No matching field available'}
                  options={hasDataExtensionFields ?
                    subQuery.dataExtensionFields.map(f => (
                      {
                        value: f.ObjectID,
                        label: f.Name.toString(),
                      })) :
                    []}
                />
              </div>
            </div>
          ) :
          null
      );
    }
    if (formula) {
      return (
        <Input
          withContainer
          type="number"
          placeholder=""
          required
          className="test-value filter-value number-filter-value"
          onChange={(e) => {
            // validate input
            if (fieldType !== Constants.FILTERLINE__FIELDTYPE__NUMBER ||
              e.target.validity.valid || e.target.value === '') {
              handleOnChangeFormulaValue(id, e.target.value);
            }
          }}
          onKeyDown={e => fieldType === Constants.FILTERLINE__FIELDTYPE__NUMBER &&
            e.keyCode === 190 && e.preventDefault()}
          value={value}
          onBlur={value ?
            null :
            () => handleOnChangeFormulaValue(id, '0')}
        />
      );
    }
    if (isCompareFieldsFilter) {
      return (
        <div className="compare-fields-filter-input-container">
          <div className="slds-form-element">
            <div className="slds-form-element__control">
              <Select
                className="test-value filter-value compare-fields-filter-picklist"
                onChange={e => handleOnChangeFilterComparedDataExtension(e.target.value, id)}
                value={getDeAliasFromDE(comparedDataExtension, comparedDataExtension, selectedDataExtensions)}
                options={compareSelectedDataExtensions?.length ?
                  compareSelectedDataExtensions.map(de => ({ value: de.deAlias, label: de.deAlias })) :
                  selectedDataExtensions.map(de => ({ value: de.deAlias, label: de.deAlias }))}
              />
            </div>
          </div>

          <div className="slds-form-element">
            <div className="slds-form-element__control">
              <Select
                className="test-value filter-value compare-fields-filter-picklist"
                onChange={e => handleOnChangeCompareFieldsFilter(e.target.value, id)}
                value={comparedField ? comparedField.ObjectID : ''}
                disabled={!comparedField}
                options={comparableFields?.length ?
                  comparableFields.map(f => (renderFieldOption(fieldType, f))) :
                  renderFieldOption(fieldType, field)}
              />
            </div>
          </div>
        </div>
      );
    }
    if (fieldType === Constants.FILTERLINE__FIELDTYPE__BOOLEAN &&
      criteria !== Constants.FILTERLINE__CRITERIA__IS_EMPTY &&
      criteria !== Constants.FILTERLINE__CRITERIA__IS_NOT_EMPTY) {
      return (
        <div className="slds-form-element">
          <div className="slds-form-element__control">
            <Select
              className="test-value filter-value boolean-filter-value"
              onChange={e => handleUpdateFilterLineValue(id, e.target.value)}
              value={value || ''}
              options={[
                { value: Constants.FILTERLINE__VALUE__TRUE, label: Constants.FILTERLINE__VALUE__TRUE },
                { value: Constants.FILTERLINE__VALUE__FALSE, label: Constants.FILTERLINE__VALUE__FALSE },
              ]}
            />
          </div>
        </div>
      );
    }
    if (
      fieldType === Constants.FILTERLINE__FIELDTYPE__DATE &&
      featureDynamicDateFilterIsEnabled &&
      !(filtersUtil.isInResultsOrNotInResults(criteria) ||
        filtersUtil.isEmptyOrNotEmpty(criteria)) &&
      !(criteria === Constants.FILTERLINE__CRITERIA__DATE__IN_PREVIOUS ||
        criteria === Constants.FILTERLINE__CRITERIA__DATE__IN_NEXT ||
        filtersUtil.isBetweenOrNotBetween(criteria) ||
        criteria === Constants.FILTERLINE__CRITERIA__DATE__TODAY)
    ) {
      const dynamicDateTypeContainerClassName = classNames(
        'filter-line-col dynamic-date-type-container',
        { 'align_self-flex_start': convertTimezone && dateFilterType !== Constants.FILTERLINE__DATE_TYPE__RELATIVE },
      );

      return (
        <div
          className={dynamicDateTypeContainerClassName}
        >
          <fieldset className="slds-form-element">
            <div className="slds-form-element__control">
              <RadioButton
                containerClassName="dynamic-date-calendar-label"
                id={`filter-date-type-calendar-${id}-${uniqueId}`}
                value={Constants.FILTERLINE__DATE_TYPE__CALENDAR}
                checked={dateFilterType === Constants.FILTERLINE__DATE_TYPE__CALENDAR}
                onChange={e => handleOnChangeRelativeDate(
                  e,
                  id,
                  Constants.FILTERLINE__DYNAMIC_DATE_PROPERTY__DATE_FILTER_TYPE,
                )}
                label="Calendar"
              />
              <RadioButton
                containerClassName="dynamic-date-relative-label"
                id={`filter-date-type-relative-${id}-${uniqueId}`}
                value={Constants.FILTERLINE__DATE_TYPE__RELATIVE}
                onChange={e => handleOnChangeRelativeDate(
                  e,
                  id,
                  Constants.FILTERLINE__DYNAMIC_DATE_PROPERTY__DATE_FILTER_TYPE,
                )}
                checked={dateFilterType === Constants.FILTERLINE__DATE_TYPE__RELATIVE}
                label="Relative"
              />
            </div>
          </fieldset>
        </div>
      );
    }
    if (
      fieldType === Constants.FILTERLINE__FIELDTYPE__DATE &&
      (criteria === Constants.FILTERLINE__CRITERIA__DATE__IN_PREVIOUS ||
        criteria === Constants.FILTERLINE__CRITERIA__DATE__IN_NEXT ||
        filtersUtil.isBetweenOrNotBetween(criteria) ||
        criteria === Constants.FILTERLINE__CRITERIA__DATE__TODAY)
    ) {
      return (<div className="dynamic-date-type-container" />);
    }
    if (
      fieldType === Constants.FILTERLINE__FIELDTYPE__DATE &&
      !featureDynamicDateFilterIsEnabled &&
      !(filtersUtil.isInResultsOrNotInResults(criteria) ||
        filtersUtil.isEmptyOrNotEmpty(criteria) ||
        criteria === Constants.FILTERLINE__CRITERIA__DATE__TODAY)
    ) {
      return null;
    }
    if (filtersUtil.isNumberOrDecimal(fieldType)) {
      return (
        (filtersUtil.isBetweenOrNotBetween(criteria)) ?
          (
            <div className="filter-value-small-container">
              <div className="slds-form-element">
                <div className="slds-form-element__control">
                  {featurePickLists && !filtersUtil.doNotRenderPickListsOptions(criteria) &&
                    optionForPicklist.length > 0 ?
                    renderPicklistOptions(true, false, false) :
                    (
                      <Input
                        type="number"
                        placeholder="Min. Value"
                        required=""
                        className="test-value filter-value-small number-filter-value"
                        onChange={(e) => {
                          // validate input
                          if (fieldType !== Constants.FILTERLINE__FIELDTYPE__NUMBER ||
                            e.target.validity.valid || e.target.value === '') {
                            handleUpdateFilterLineValue(id, e.target.value, 'minValue');
                          }
                        }}
                        onKeyDown={e => fieldType === Constants.FILTERLINE__FIELDTYPE__NUMBER &&
                          e.keyCode === 190 && e.preventDefault()}
                        onBlur={value.minValue ?
                          null :
                          () => handleUpdateFilterLineValue(id, '0', 'minValue')}
                        value={value.minValue}
                      />
                    )}
                </div>
              </div>
              <div className="slds-form-element">
                <div className="slds-form-element__control">
                  {featurePickLists && !filtersUtil.doNotRenderPickListsOptions(criteria) &&
                    optionForPicklist.length > 0 ?
                    renderPicklistOptions(false, true, false) :
                    (
                      <Input
                        type="number"
                        placeholder="Max. Value"
                        required=""
                        className="test-value filter-value-small number-filter-value"
                        onChange={(e) => {
                          // validate input
                          if (fieldType !== Constants.FILTERLINE__FIELDTYPE__NUMBER ||
                            e.target.validity.valid || e.target.value === '') {
                            handleUpdateFilterLineValue(id, e.target.value, 'maxValue');
                          }
                        }}
                        onKeyDown={e => fieldType === Constants.FILTERLINE__FIELDTYPE__NUMBER &&
                          e.keyCode === 190 && e.preventDefault()}
                        onBlur={value.maxValue ?
                          null :
                          () => handleUpdateFilterLineValue(id, '0', 'maxValue')}
                        value={value.maxValue}
                      />
                    )}
                </div>
              </div>
            </div>
          ) :
          (
            <div className="slds-form-element">
              <div className="slds-form-element__control">
                {/* eslint-disable-next-line no-nested-ternary */}
                {featurePickLists && !filtersUtil.doNotRenderPickListsOptions(criteria) &&
                  optionForPicklist.length > 0 ?
                  renderPicklistOptions(false, false, false) :
                  (filtersUtil.isInOrNotInOrOneOf(criteria) ?
                    (
                      <TagInput
                        handleTagsChanged={handleTagsChanged}
                        tags={tags}
                        filterLineId={id}
                        fieldType={fieldType}
                        criteria={criteria}
                      />
                    ) :
                    (
                      <Input
                        type="number"
                        placeholder=""
                        required
                        className="test-value filter-value number-filter-value"
                        onChange={(e) => {
                          // validate input
                          if (fieldType !== Constants.FILTERLINE__FIELDTYPE__NUMBER ||
                            e.target.validity.valid || e.target.value === '') {
                            handleUpdateFilterLineValue(id, e.target.value);
                          }
                        }}
                        onKeyDown={e => fieldType === Constants.FILTERLINE__FIELDTYPE__NUMBER &&
                          e.keyCode === 190 && e.preventDefault()}
                        onBlur={value ?
                          null :
                          () => handleUpdateFilterLineValue(id, '0', null, true)}
                        value={filtersUtil.isEmptyOrNotEmpty(criteria) ?
                          '' :
                          value}
                        disabled={filtersUtil.isEmptyOrNotEmpty(criteria)}
                      />
                    )
                  )}
              </div>
            </div>
          )
      );
    }

    return (
      <div className="slds-form-element">
        <div className="slds-form-element__control">
          {featurePickLists && !filtersUtil.doNotRenderPickListsOptions(criteria) && optionForPicklist.length > 0 ?
            renderPicklistOptions(false, false, false) :
            (
              <Input
                placeholder=""
                required
                className={'test-value filter-value ' +
                  (filtersUtil.isEmptyOrNotEmpty(criteria) ?
                    'is-empty-is-not-empty' :
                    '')}
                onChange={(criteria === Constants.FILTERLINE__CRITERIA__IN ||
                  criteria === Constants.FILTERLINE__CRITERIA__NOT_IN ||
                  criteria === Constants.FILTERLINE__CRITERIA__CONTAINS_ONE_OF ||
                  criteria === Constants.FILTERLINE__CRITERIA__DOES_NOT_CONTAIN_ONE_OF ?
                  e => handleLimitReachedMessage(id, e.target.value, criteria) :
                  (e) => {
                    setFilterInputValues(e.target.value);
                    handleUpdateFilterLineValue(id, e.target.value);
                  })}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                  }
                }}
                value={valuesToFilterStringForInNotInEmptyNotEmpty(criteria, value)}
                onBlur={(filtersUtil.isInOrNotInOrOneOf(criteria) ?
                  e => handleUpdateFilterLineValue(id, e.target.value ?
                    e.target.value.trimEnd() :
                    '', null, true) :
                  e => handleUpdateFilterLineValue(id, e.target.value ?
                    e.target.value :
                    ''))}
                disabled={!!(filtersUtil.isEmptyOrNotEmpty(criteria))}
              />
            )}
        </div>
      </div>
    );
  };

  /**
   * Handles when a picklist is created after the filterline
   * value has been set already
   * @param {string} val - The value to be checked
   * @returns {void}
   */
  const handlePicklistCreatedAfterValueIsSet = (val) => {
    let valueToCompare = val;

    if (fieldType === Constants.FILTERLINE__FIELDTYPE__NUMBER) {
      valueToCompare = +val;
    }
    const valueIsFromPicklists = optionForPicklist.find(opt => opt.value === valueToCompare.toString());

    if (!valueIsFromPicklists) {
      optionForPicklist.push({
        value: valueToCompare,
        label: valueToCompare,
      });
    }
  };

  /**
   * Renders the timezone settings component
   * @param {string} isDateTypeRelative - Indicates whether dateType is relative
   * @returns {JSX.Element} - The timezone component
   */
  const renderFilterTimezoneIcon = (isDateTypeRelative) => {
    const classNameForTimezoneIcon = classNames(
      'slds-icon',
      convertTimezone ?
        'timezone-icon-active' :
        'timezone-icon',
    );

    const classNameForTimezoneIconContainer = classNames(
      'slds-icon_container timezone-icon-container',
      { 'align_self-baseline': isDateTypeRelative && convertTimezone },
    );

    return (
      <span
        className={classNameForTimezoneIconContainer}
        title="Convert Timezone"
        onClick={() => handleOpenTimezoneModal(id)}
      >
        <svg
          className={classNameForTimezoneIcon}
          aria-hidden="true"
          id="schedule-icon"
        >
          <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#clock" />
        </svg>
        <span className="slds-assistive-text">Convert Timezone</span>
      </span>
    );
  };

  // In the case where picklist is created after a value is already set
  if (featurePickLists && !filtersUtil.doNotRenderPickListsOptions(criteria) && optionForPicklist.length) {
    isMultiSelect = true;
    if (filtersUtil.isBetweenOrNotBetween(criteria)) {
      const { minValue, maxValue } = value;

      handlePicklistCreatedAfterValueIsSet(minValue);
      handlePicklistCreatedAfterValueIsSet(maxValue);
    } else if (filtersUtil.isInOrNotInOrOneOf(criteria) ||
      filtersUtil.isContainsOrNotContains(criteria)
    ) {
      // If no value has been inputted before, just render the picklists
      if (Array.isArray(value) && value[0]) {
        value.forEach(val => handlePicklistCreatedAfterValueIsSet(val));
      } else if (typeof value === 'string' && value !== '') {
        handlePicklistCreatedAfterValueIsSet(value);
      }
    } else {
      // Other criteria
      handlePicklistCreatedAfterValueIsSet(value);
    }
  }

  /**
   * Shows the DatePicker depending on where it is located - whether it's in a subquery or not.
   * @returns {array} DatePicker component
   */
  const showDatePicker = () => {
    // define selected Date in DatePicker
    const selectedDate = value ?
      moment(value)
        .add(-moment(value).utcOffset(), 'm')
        .utc()._d :
      moment(new Date().setHours(0, 0))
        .add(-moment(new Date().setHours(0, 0)).utcOffset(), 'm')
        .utc()._d;

    if (isSubQuery) {
      return (
        // set other properties for DatePicker located in subQueryModal
        <div className="datepicker-container">
          <div className="first-child">
            <DatePicker
              className="test-value filter-value datepicker-filterline"
              popperPlacement="bottom-start"
              popperModifiers={{
                flip: {
                  // don't allow it to flip to be above
                  behavior: ['bottom'],
                },
                preventOverflow: {
                  // tell it not to try to stay within the view
                  enabled: false,
                },
                hide: {
                  // turn off since preventOverflow is not enabled
                  enabled: false,
                },
              }}
              type="text"
              dateFormat={datePickerDateFormat}
              timeFormat="HH:mm"
              showTimeInput
              selected={selectedDate}
              onChange={e => handleUpdateFilterLineValue(id, e)}
              disabled={filtersUtil.isEmptyOrNotEmpty(criteria)}
            />
            {renderFilterTimezoneIcon()}
          </div>
          {convertTimezone && (
            <span className="timezone-text" title={Util.labelForTimezoneText(convertToTimezone, convertFromTimezone)}>
              {Util.labelForTimezoneText(convertToTimezone, convertFromTimezone)}
            </span>
          )}
        </div>
      );
    }

    return (
      // shows DatePicker in regular filters
      <div className="datepicker-container">
        <div className="first-child">
          <DatePicker
            className="test-value filter-value datepicker-filterline"
            type="text"
            dateFormat={datePickerDateFormat}
            timeFormat="HH:mm"
            showTimeInput
            selected={selectedDate}
            onChange={e => handleUpdateFilterLineValue(id, e)}
            disabled={filtersUtil.isEmptyOrNotEmpty(criteria)}
          />
          {renderFilterTimezoneIcon()}
        </div>
        {convertTimezone && (
          <span className="timezone-text" title={Util.labelForTimezoneText(convertToTimezone, convertFromTimezone)}>
            {Util.labelForTimezoneText(convertToTimezone, convertFromTimezone)}
          </span>
        )}
      </div>
    );
  };

  /**
   * Define a date range picker to select the start date and the end date.
   * @returns {JSX.Element} DatePicker components
   */
  // eslint-disable-next-line no-unused-vars
  const betweenDateRangePicker = () => {
    const selectedStartDate = moment(startDate)
      .add(-moment(startDate).utcOffset(), 'm')
      .utc()._d;

    const selectedEndDate = moment(endDate)
      .add(-moment(endDate).utcOffset(), 'm')
      .utc()._d;

    return (
      <div className="full-sized-div">
        <div className="filter-value-small-container date-filter-between">
          <div className="slds-form-element">
            <div
              className="slds-form-element__control between-datepicker-container-1"
              style={{ margin: 'none', padding: 'none' }}
            >
              <DatePicker
                className="test-value filter-value datepicker-filterline"
                type="text"
                dateFormat={datePickerDateFormat}
                selected={selectedStartDate}
                selectsStart
                maxDate={selectedEndDate}
                onChange={(e) => {
                  handleUpdateFilterLineValue(id, e, Constants.FILTERLINE__DYNAMIC_DATE_PROPERTY__DATE_RANGE_START);
                }}
                timeFormat="HH:mm"
                showTimeInput
              />
            </div>
          </div>
          <div className="filter-line-and-text">{filterLineText('and')}</div>
          <div className="filter-value-small-container">
            <div className="slds-form-element">
              <div className="slds-form-element__control between-datepicker-container-2">
                <DatePicker
                  className="test-value filter-value datepicker-filterline"
                  type="text"
                  dateFormat={datePickerDateFormat}
                  selected={selectedEndDate}
                  selectsEnd
                  minDate={selectedStartDate}
                  onChange={(e) => {
                    handleUpdateFilterLineValue(id, e, Constants.FILTERLINE__DYNAMIC_DATE_PROPERTY__DATE_RANGE_END);
                  }}
                  timeFormat="HH:mm"
                  showTimeInput
                />
                {renderFilterTimezoneIcon()}
              </div>
            </div>
          </div>
        </div>
        {convertTimezone && (
          <span className="timezone-text" title={Util.labelForTimezoneText(convertToTimezone, convertFromTimezone)}>
            {Util.labelForTimezoneText(convertToTimezone, convertFromTimezone)}
          </span>
        )}
      </div>
    );
  };

  // class names
  const removeLineClassName = classNames(
    'remove-filterline',
    {
      'remove_filterline__in-relation': relation,
      multiselect: isMultiSelect,
    },
  );

  const filterCriteriaColClassName = classNames(
    'filter-line-col filter-criteria-col',
    { 'filter-line-col filter-criteria-col__render-merge-icon': shouldMergeIconBeRendered() },
  );

  const filterCriteriaColDraggableClassName = classNames(
    'filter-value-col draggableDiv',
    { 'filter-value-col draggableDiv__render-merge-icon': shouldMergeIconBeRendered() },
    { 'filter-value-col draggableDiv__isSubQuery': isSubQuery },
    {
      'filter-value-col draggableDiv__without-subQuery-filter-icon': !filtersUtil.isInResultsOrNotInResults(criteria) ||
        (!subQueryDataExtensionChosen() && subQuery),
    },
    { 'filter-value-col draggableDiv__with-subQuery-filter-icon': filtersUtil.isInResultsOrNotInResults(criteria) },
  );

  const filterLabelClassName = classNames(
    'filter-label',
    {
      'filter-label__with-padding-top-8': hideCollectionAlias && featurePickLists &&
        !filtersUtil.doNotRenderPickListsOptions(criteria) &&
        optionForPicklist.length > 0,
    },
  );

  const funnelIconForSubQuery = classNames(
    'slds-button slds-button_brand subquery-filter',
    { 'slds-button slds-button_brand subquery-filter_green': subQuery?.filters?.filters?.length },
  );

  const classNamesForFilterLineIsText = classNames(
    'filter-line-is-text',
    {
      'align_self-flex_start': convertTimezone && !filtersUtil.isEmptyOrNotEmpty(criteria) &&
        !filtersUtil.isInResultsOrNotInResults(criteria) &&
        dateFilterType !== Constants.FILTERLINE__DATE_TYPE__RELATIVE,
    },
  );

  const classNamesForCompareFieldsFilterToggle = classNames(
    'compare-fields-filter-toggle',
    isMultiSelect || (convertTimezone && dateFilterType !== Constants.FILTERLINE__DATE_TYPE__RELATIVE) ?
      'align_self-flex_start' :
      'align_self-center',
  );

  /**
   * It handles filterline removal
   * @returns {void}
   */
  const removeFilterLine = async () => {
    // first remove filterline from filters
    await handleRemoveFilterLine({
      filterLineId: id,
      fieldExists: true,
      subQueryModalFilterLineId,
      isSubQuery,
      handleFiltersSave,
      filterType,
      subQueryFilters,
      isInResultsFormula,
      parentId,
    });

    // then update tags
    updateTags();
  };

  return (
    <div className="filter-line-high-level-container">
      <div
        ref={draggableDiv}
        draggable="true"
        onDragStart={e => dragFilter(e, id)}
        onDragOver={e => e.preventDefault()}
        className={
          showGroupButton ? 'filter-line has-no-children filter-merge-candidate' : 'filter-line has-no-children'
        }
        style={{
          pointerEvents: filterBorderMouseOver ?
            'none' :
            '',
          alignItems: isMultiSelect || isUsingTagInput ? 'flex-start' : 'center',
        }}
      >
        {/* added  maxWidth for better style */}
        <div
          className={removeLineClassName}
          style={{
            alignSelf: 'center',
          }}
        >
          <Button
            className="remove-line"
            onClick={async () => removeFilterLine()}
            noButtonClass
            noSpan
          >
            <i className="far fa-times-circle remove-filter" />
          </Button>
        </div>
        {isInResultsFormula || relation && subQuery.relationData && !Util.objectIsEmpty(subQuery.relationData) ?
          /* relation filterline */
          (
            <RelationFilterLine
              id={id}
              parentId={parentId}
              collectionAlias={collectionAlias}
              handleUpdateFormulaFilterLineCriteria={handleUpdateFormulaFilterLineCriteria}
              handleUpdateFilterLineCriteria={handleUpdateFilterLineCriteria}
              criteria={getCriteriaForRelationFilter}
              handleOnChangeFormulaValue={handleOnChangeFormulaValue}
              value={getValueForRelationFilter}
              formulaForRelationFilter={getFormulaForRelationFilter}
              handleOnChangeFormulaFilterFormulaValue={handleOnChangeFormulaFilterFormulaValue}
              subQuery={subQuery}
              handleChangeFilterDataExtensionField={handleChangeFilterDataExtensionField}
              selectedFieldObjectID={getSelectedFieldObjectID}
              predefinedRelation={getPredefinedRelation || {}}
              isInResultsFormula={isInResultsFormula}
              selectedActiveDE={selectedActiveDE}
            />
          ) :
          (
            /* other filters */
            <>
              <div className="filter-line_filter-name">
                <b
                  className={filterLabelClassName}
                  title={`${formula ?
                    `${getFormulaLabelForValue(formula)} of ${field}` :
                    field}${hideCollectionAlias ?
                    '' :
                    ` (${collectionAlias})`}`}
                >
                  {formula ?
                    `${getFormulaLabelForValue(formula)} of ${Util.abbreviate(field, 25)}` :
                    (
                      <>
                        {Util.abbreviate(field, 25)}
                        {hideCollectionAlias ?
                          '' :
                          (
                            <span className="collection-alias">{`${collectionAlias}`}</span>
                          )}
                      </>
                    )}
                </b>
              </div>
              {/* added col-lg to keep size of the div under some acceptably/proper max width.
          Grid columns without a specified width will automatically
          layout as equal width columns. Also added min width for the nicer appearance */}
              {fieldType === Constants.FILTERLINE__FIELDTYPE__DATE &&
                (
                  <div
                    className={classNamesForFilterLineIsText}
                  >
                    {filterLineText('is')}
                  </div>
                )}
              <div
                className={filterCriteriaColClassName}
                style={convertTimezone &&
                  dateFilterType !== Constants.FILTERLINE__DATE_TYPE__RELATIVE &&
                  !filtersUtil.isEmptyOrNotEmpty(criteria) &&
                  !filtersUtil.isInResultsOrNotInResults(criteria) ?
                  {
                    ...criteria === Constants.FILTERLINE__CRITERIA__DATE__TODAY && { height: '3rem' },
                    alignSelf: 'baseline',
                  } :
                  {}}
              >
                {filtersUtil.isNumberOrDecimal(fieldType) && formula ?
                  (
                    <div className="slds-form-element">
                      <div className="slds-form-element__control">
                        <Select
                          className="filter-select filter-criteria"
                          onChange={e => handleUpdateFilterLineCriteria(
                            id,
                            e.target.value,
                            criteria,
                            null,
                            optionForPicklist,
                          )}
                          value={criteria || Constants.FILTERLINE__CRITERIA__EQUALS}
                          options={renderFormulaOptions()}
                        />
                      </div>
                    </div>
                  ) :
                  null}
                {filtersUtil.isNumberOrDecimal(fieldType) && !formula ?
                  (
                    <div className="slds-form-element">
                      <div className="slds-form-element__control">
                        <Select
                          className="filter-select filter-criteria"
                          onChange={e => handleUpdateFilterLineCriteria(
                            id,
                            e.target.value,
                            criteria,
                            null,
                            optionForPicklist,
                          )}
                          value={criteria || Constants.FILTERLINE__CRITERIA__EQUALS}
                          options={renderCriteriaOptions()}
                        />
                      </div>
                    </div>
                  ) :
                  null}
                {(fieldType === Constants.FILTERLINE__FIELDTYPE__DATE &&
                  dateFilterType === Constants.FILTERLINE__DATE_TYPE__CALENDAR) && formula ?
                  (
                    <div className="slds-form-element">
                      <div className="slds-form-element__control">
                        <Select
                          className="filter-select filter-criteria"
                          onChange={e => handleUpdateFilterLineCriteria(id, e.target.value, criteria)}
                          value={criteria || Constants.FILTERLINE__CRITERIA__EQUALS}
                          options={renderFormulaOptions()}
                        />
                      </div>
                    </div>
                  ) :
                  null}
                {(fieldType === Constants.FILTERLINE__FIELDTYPE__DATE &&
                  dateFilterType === Constants.FILTERLINE__DATE_TYPE__RELATIVE) && formula ?
                  (
                    <div className="slds-form-element">
                      <div className="slds-form-element__control">
                        <Select
                          className="filter-select filter-criteria"
                          onChange={e => handleUpdateFilterLineCriteria(id, e.target.value, criteria)}
                          value={criteria || Constants.FILTERLINE__CRITERIA__EQUALS}
                          options={renderFormulaOptions()}
                        />
                      </div>
                    </div>
                  ) :
                  null}
                {fieldType === Constants.FILTERLINE__FIELDTYPE__DATE && !formula ?
                  (
                    <div className="slds-form-element">
                      <div className="slds-form-element__control">
                        <Select
                          className="filter-select filter-criteria"
                          onChange={e => handleUpdateFilterLineCriteria(id, e.target.value, criteria)}
                          value={criteria || Constants.FILTERLINE__CRITERIA__SMALLER_THAN}
                          options={renderCriteriaOptions(true)}
                        />
                        {/* { convertTimezone && criteria === Constants.FILTERLINE__CRITERIA__DATE__TODAY && (
                      <span className="timezone-text">{timezoneText}</span>
                    )} */}
                      </div>
                    </div>
                  ) :
                  null}
                {fieldType === Constants.FILTERLINE__FIELDTYPE__BOOLEAN && formula ?
                  (
                    <div className="slds-form-element">
                      <div className="slds-form-element__control">
                        <Select
                          className="filter-select filter-criteria"
                          onChange={e => handleUpdateFilterLineCriteria(id, e.target.value, criteria)}
                          value={criteria || Constants.FILTERLINE__CRITERIA__EQUALS}
                          options={renderFormulaOptions()}
                        />
                      </div>
                    </div>
                  ) :
                  null}
                {fieldType === Constants.FILTERLINE__FIELDTYPE__BOOLEAN && !formula ?
                  (
                    <div className="slds-form-element">
                      <div className="slds-form-element__control">
                        <Select
                          className="filter-select filter-criteria"
                          onChange={e => handleUpdateFilterLineCriteria(id, e.target.value, criteria)}
                          value={criteria || Constants.FILTERLINE__CRITERIA__EQUALS}
                          options={renderCriteriaOptions(false, true)}
                        />
                      </div>
                    </div>
                  ) :
                  null}
                {(fieldType === Constants.FILTERLINE__FIELDTYPE__TEXT ||
                  fieldType === Constants.FILTERLINE__FIELDTYPE__EMAILADDRESS ||
                  fieldType === Constants.FILTERLINE__FIELDTYPE__PHONE ||
                  fieldType === Constants.FILTERLINE__FIELDTYPE__LOCALE) && formula ?
                  (
                    <div className="slds-form-element">
                      <div className="slds-form-element__control">
                        <Select
                          className="filter-select filter-criteria"
                          onChange={e => handleUpdateFilterLineCriteria(
                            id,
                            e.target.value,
                            criteria,
                            null,
                            optionForPicklist,
                          )}
                          value={criteria || Constants.FILTERLINE__CRITERIA__EQUALS}
                          options={renderFormulaOptions()}
                        />
                      </div>
                    </div>
                  ) :
                  null}
                {(fieldType === Constants.FILTERLINE__FIELDTYPE__TEXT ||
                  fieldType === Constants.FILTERLINE__FIELDTYPE__EMAILADDRESS ||
                  fieldType === Constants.FILTERLINE__FIELDTYPE__PHONE ||
                  fieldType === Constants.FILTERLINE__FIELDTYPE__LOCALE) && !formula ?
                  (
                    <div className="slds-form-element">
                      <div className="slds-form-element__control">
                        <Select
                          className="filter-select filter-criteria"
                          onChange={async (e) => {
                            if (e.target.value === Constants.FILTERLINE__CRITERIA__IN ||
                              e.target.value === Constants.FILTERLINE__CRITERIA__NOT_IN ||
                              e.target.value === Constants.FILTERLINE__CRITERIA__CONTAINS_ONE_OF ||
                              e.target.value === Constants.FILTERLINE__CRITERIA__DOES_NOT_CONTAIN_ONE_OF) {
                              await handleMultipleValuesLimit(id, e.target.value, criteria, optionForPicklist);
                            } else {
                              handleUpdateFilterLineCriteria(
                                id,
                                e.target.value,
                                criteria,
                                null,
                                optionForPicklist,
                              );
                            }
                          }}
                          value={criteria || Constants.FILTERLINE__CRITERIA__EQUALS}
                          options={renderCriteriaOptions(false, false, true)}
                        />
                      </div>
                    </div>

                  ) :
                  null}
              </div>

              {/*
           * added col-lg to keep size of the div under some acceptably/proper max width.
           * Grid columns without a specified width will automatically
           * layout as equal width columns. Also added min width for the nicer appearance
          */}
              <div
                className={filterCriteriaColDraggableClassName}
                onMouseEnter={() => { draggableDiv.current.draggable = false; }}
                onMouseLeave={() => { draggableDiv.current.draggable = true; }}
                style={{
                  pointerEvents: filterBorderMouseOver ? 'none' : '',
                  ...criteria === Constants.FILTERLINE__CRITERIA__DATE__TODAY && {
                    height: '3rem',
                  },
                }}
              >
                {

                  (criteria !== Constants.FILTERLINE__CRITERIA__IN &&
                    criteria !== Constants.FILTERLINE__CRITERIA__NOT_IN &&
                    criteria !== Constants.FILTERLINE__CRITERIA__CONTAINS_ONE_OF &&
                    criteria !== Constants.FILTERLINE__CRITERIA__DOES_NOT_CONTAIN_ONE_OF &&
                    criteria !== Constants.FILTERLINE__CRITERIA__IN_RESULTS &&
                    criteria !== Constants.FILTERLINE__CRITERIA__NOT_IN_RESULTS &&
                    criteria !== Constants.FILTERLINE__CRITERIA__IS_EMPTY &&
                    criteria !== Constants.FILTERLINE__CRITERIA__IS_NOT_EMPTY &&
                    criteria !== Constants.FILTERLINE__CRITERIA__BETWEEN &&
                    criteria !== Constants.FILTERLINE__CRITERIA__NOT_BETWEEN &&
                    !(criteria === Constants.FILTERLINE__CRITERIA__DATE__IN_PREVIOUS ||
                      criteria === Constants.FILTERLINE__CRITERIA__DATE__IN_NEXT ||
                      criteria === Constants.FILTERLINE__CRITERIA__DATE__TODAY) &&
                    featureCompareFields && !formula) ?
                    (
                      <div
                        className={classNamesForCompareFieldsFilterToggle}
                      >
                        <Button
                          onClick={async () => handleOnChangeIsCompareFieldsFilter(!isCompareFieldsFilter, id)}
                          buttonLook={Constants.BUTTON__TYPE__BRAND}
                          className="FL-button"
                          title={`Compare with ${isCompareFieldsFilter ? 'value' : 'field'}`}
                        >
                          <i
                            className={`fas ${isCompareFieldsFilter ? 'fa-font' : 'fa-table'}`}
                          />
                        </Button>
                      </div>
                    ) :
                    null
                }
                {/* Render a Dropzone, and don't render other elements if the criteria
            is one of in results, or not in results */}
                {filtersUtil.isInResultsOrNotInResults(criteria) && featureSubQueriesAreEnabled ?
                  (
                    <div
                      className={`${subQueryDataExtensionChosen() && checkIfDataExtensionFieldsAreDefined ?
                        'forceWidth' :
                        ''}
                target-collection-dropzone subquery-dropzone`}
                      onDragOver={e => e.preventDefault()}
                      style={{ pointerEvents: filterBorderMouseOver ? 'none' : '' }}
                    >
                      {subQueryDataExtensionChosen() && checkIfDataExtensionFieldsAreDefined ?
                        (
                          <div
                            key={subQuery.collections[0].collection.toString()}
                            title={subQuery.collections[0].collection.toString()}
                            className="subquery-dragged-field"
                          >
                            <div className="remove-field-container">
                              <Button
                                className="remove-field-target-de"
                                onClick={() => handleRemoveDataExtensionFromFilter(id)}
                                noButtonClass
                              >
                                <i className="far fa-times-circle remove-filter" />
                              </Button>
                            </div>
                            <p title={subQuery.collections[0].collection.toString()}>
                              {subQuery.collections[0].collection.toString()}
                            </p>
                          </div>
                        ) :
                        (
                          <div>
                            <Dropdown
                              id="target-DE-dropdown"
                              selection
                              className="target-data-extension-dropdown searchable-dropdown"
                              search
                              placeholder="Select a Data Extension"
                              options={Util.formattedDataForTheDropdown(dataExtensions, 'CustomerKey', 'Name')}
                              onChange={(e, data) => handleSetDataExtensionToFilter(data, id)}
                              loading
                              style={{ borderRadius: '.25rem' }}
                            />
                          </div>
                        )}
                    </div>
                  ) :
                  null}

                {generateFilterLineHTML()}

                {fieldType === Constants.FILTERLINE__FIELDTYPE__DATE &&
                  !(filtersUtil.isInResultsOrNotInResults(criteria) ||
                    filtersUtil.isEmptyOrNotEmpty(criteria) ||
                    criteria === Constants.FILTERLINE__CRITERIA__DATE__TODAY ||
                    filtersUtil.isBetweenOrNotBetween(criteria)) &&
                  dateFilterType === Constants.FILTERLINE__DATE_TYPE__CALENDAR &&
                  !isCompareFieldsFilter &&
                  !formula ?
                  (
                    showDatePicker()
                  ) :
                  null}

                {
                  fieldType === Constants.FILTERLINE__FIELDTYPE__DATE &&
                  filtersUtil.isBetweenOrNotBetween(criteria) &&
                  dateFilterType === Constants.FILTERLINE__DATE_TYPE__CALENDAR &&
                  betweenDateRangePicker()
                }

                {fieldType === Constants.FILTERLINE__FIELDTYPE__DATE &&
                  !(filtersUtil.isInResultsOrNotInResults(criteria) ||
                    filtersUtil.isEmptyOrNotEmpty(criteria) ||
                    criteria === Constants.FILTERLINE__CRITERIA__DATE__TODAY) &&
                  dateFilterType !== Constants.FILTERLINE__DATE_TYPE__CALENDAR &&
                  featureDynamicDateFilterIsEnabled &&
                  !isCompareFieldsFilter &&
                  !formula ?
                  (
                    <div className="full-sized-div">
                      <div className="dynamic-date-parameters-container">
                        {/* Render the date value and interval fields only if date value start is NOT Today */}
                        {dateValueStart === Constants.FILTERLINE__DATE_VALUE_START__TODAY ?
                          null :
                          (
                            <>
                              <Input
                                withContainer
                                onChange={e => handleOnChangeRelativeDate(
                                  e,
                                  id,
                                  Constants.FILTERLINE__DYNAMIC_DATE_PROPERTY__DATE_VALUE,
                                )}
                                type="number"
                                required
                                value={dateValue}
                                className="test-value filter-value
                               number-filter-value dynamic-date-date-value"
                                onBlur={dateValue ?
                                  null :
                                  e => handleOnChangeRelativeDate(
                                    e,
                                    id,
                                    Constants.FILTERLINE__DYNAMIC_DATE_PROPERTY__DATE_VALUE,
                                    'onBlur',
                                  )}
                              />
                              <div className="slds-form-element">
                                <div className="slds-form-element__control">
                                  <Select
                                    className="test-value filter-value number-filter-value
                                dynamic-date-filter-interval"
                                    value={filterInterval}
                                    onChange={e => handleOnChangeRelativeDate(
                                      e,
                                      id,
                                      Constants.FILTERLINE__DYNAMIC_DATE_PROPERTY__FILTER_INTERVAL,
                                    )}
                                    options={optionsForDateFilterInterval()}
                                  />
                                </div>
                              </div>
                            </>
                          )}

                        {(fieldType === Constants.FILTERLINE__FIELDTYPE__DATE &&
                          dateFilterType === Constants.FILTERLINE__DATE_TYPE__RELATIVE &&
                          !(criteria === Constants.FILTERLINE__CRITERIA__DATE__IN_PREVIOUS ||
                            criteria === Constants.FILTERLINE__CRITERIA__DATE__IN_NEXT) ||

                          (((criteria === Constants.FILTERLINE__CRITERIA__SMALLER_THAN ||
                            criteria === Constants.FILTERLINE__CRITERIA__SMALLER_THAN_OR_EQUAL_TO) &&
                            (dateValueStart === Constants.FILTERLINE__DATE_VALUE_START__AFTER_NOW ||
                              dateValueStart === Constants.FILTERLINE__DATE_VALUE_START__AFTER_TODAY)) ||

                            ((criteria === Constants.FILTERLINE__CRITERIA__GREATER_THAN ||
                              criteria === Constants.FILTERLINE__CRITERIA__GREATER_THAN_OR_EQUAL_TO) &&
                              (dateValueStart === Constants.FILTERLINE__DATE_VALUE_START__BEFORE_NOW ||
                                dateValueStart === Constants.FILTERLINE__DATE_VALUE_START__BEFORE_TODAY))) ||

                          dateValueStart === Constants.FILTERLINE__DATE_VALUE_START__TODAY) &&
                          (
                            <div className="slds-form-element">
                              <div className="slds-form-element__control">
                                <Select
                                  className="test-value filter-value
                            number-filter-value dynamic-date-date-value-start"
                                  value={dateValueStart}
                                  onChange={e => handleOnChangeRelativeDate(
                                    e,
                                    id,
                                    Constants.FILTERLINE__DYNAMIC_DATE_PROPERTY__DATE_VALUE_START,
                                  )}
                                  options={optionsForDateValueStart()}
                                />
                              </div>
                            </div>
                          )}
                      </div>
                      {/* { convertTimezone && (<span className="timezone-text transformed">{timezoneText}</span>)} */}
                    </div>
                  ) :
                  null}

                {!isCompareFieldsFilter && fieldType === Constants.FILTERLINE__FIELDTYPE__DATE &&
                  (criteria === Constants.FILTERLINE__CRITERIA__DATE__IN_PREVIOUS ||
                    criteria === Constants.FILTERLINE__CRITERIA__DATE__IN_NEXT ||
                    criteria === Constants.FILTERLINE__CRITERIA__SMALLER_THAN ||
                    criteria === Constants.FILTERLINE__CRITERIA__SMALLER_THAN_OR_EQUAL_TO ||
                    criteria === Constants.FILTERLINE__CRITERIA__GREATER_THAN ||
                    criteria === Constants.FILTERLINE__CRITERIA__GREATER_THAN_OR_EQUAL_TO ||
                    filtersUtil.isEqualOrNotEqual(criteria) ||
                    criteria === Constants.FILTERLINE__CRITERIA__DATE__TODAY) &&
                  dateFilterType === Constants.FILTERLINE__DATE_TYPE__RELATIVE &&
                  (!(dateValueStart === Constants.FILTERLINE__DATE_VALUE_START__AFTER_NOW ||
                    dateValueStart === Constants.FILTERLINE__DATE_VALUE_START__BEFORE_NOW)) && (
                    <>
                      {/* {renderFilterTimezoneIcon(true)} */}
                      {/* <span
                  className={convertTimezone ?
                    'tooltip-span-container' :
                    ''}
                > */}
                      <Tooltip
                        nubbinPosition={Constants.NUBBIN_POSITION__BOTTOM_RIGHT}
                        type={Constants.TOOLTIP_TYPE__RELATIVE_DATE_FILTER_START_TODAY}
                      />
                      {/* </span> */}
                    </>
                )}

                {!isCompareFieldsFilter && fieldType === Constants.FILTERLINE__FIELDTYPE__DATE &&
                  (criteria === Constants.FILTERLINE__CRITERIA__DATE__IN_PREVIOUS ||
                    criteria === Constants.FILTERLINE__CRITERIA__DATE__IN_NEXT ||
                    criteria === Constants.FILTERLINE__CRITERIA__SMALLER_THAN ||
                    criteria === Constants.FILTERLINE__CRITERIA__SMALLER_THAN_OR_EQUAL_TO ||
                    criteria === Constants.FILTERLINE__CRITERIA__GREATER_THAN ||
                    criteria === Constants.FILTERLINE__CRITERIA__GREATER_THAN_OR_EQUAL_TO ||
                    filtersUtil.isEqualOrNotEqual(criteria) ||
                    criteria === Constants.FILTERLINE__CRITERIA__DATE__TODAY) &&
                  dateFilterType === Constants.FILTERLINE__DATE_TYPE__RELATIVE &&
                  (dateValueStart === Constants.FILTERLINE__DATE_VALUE_START__AFTER_NOW ||
                    dateValueStart === Constants.FILTERLINE__DATE_VALUE_START__BEFORE_NOW) &&
                  criteria !== Constants.FILTERLINE__CRITERIA__DATE__TODAY && (
                    <Tooltip
                      nubbinPosition={Constants.NUBBIN_POSITION__BOTTOM_RIGHT}
                      type={Constants.TOOLTIP_TYPE__RELATIVE_DATE_FILTER_START_NOW}
                    />
                )}
              </div>
            </>
          )}

        {featureSubQueriesAreEnabled &&
          isSubQuery && subQuery && !isCompareFieldsFilter && formula !== undefined ?
          (
            <>
              <button
                className={`slds-button slds-button_brand formula-button ${formula ? 'formula-button-selected' : ''}`}
                onClick={() => handleOldSubQueryFormulas(
                  field,
                  collectionAlias,
                  id,
                  fieldType,
                  formula,
                  optionForPicklist,
                )}
                type="button"
                title={formula ? `${formula} is selected` : 'No formula is selected'}
                disabled={!featureFormulasInFiltersIsEnabled}
              >
                <img
                  src={WhiteFormulaIcon}
                  alt="formula-icon"
                />
              </button>
              {
                !featureFormulasInFiltersIsEnabled && (
                  <Tooltip
                    nubbinPosition={Constants.NUBBIN_POSITION__TOP_RIGHT}
                    type={Constants.TOOLTIP_TYPE__UNAVAILABLE_FEATURE}
                  />
                )
              }
            </>
          ) :
          <div className="keep-space-for-formula-btn" />}
        {/* added max height for the nicer appearance */}
        <div className="icons-container">
          {(!isInResultsFormula && !isSubQuery &&
            filtersUtil.isInResultsOrNotInResults(criteria) && subQueryDataExtensionChosen() || relation) ?
            (
              <>
                <button
                  type="button"
                  className={funnelIconForSubQuery}
                  onClick={() => handleOpenSubQueryModal(id, relation)}
                  disabled={!hasDataExtensionFields ||
                    (relation && !subQuery?.relationData?.fieldObjectID)}
                >
                  <i title="Add filter to this result set" className="fa fa-filter" />
                </button>
                {
                  (!relation && !checkIfFormulasInSubQuery(subQuery?.filters && subQuery?.filters?.filters)) &&
                  <button
                    className={`slds-button slds-button_brand formula-button
                  ${subQueryHasFormulaFilters && 'formula-button-selected'}`}
                    onClick={() => {
                      handleSubQueryFormulas(id);
                      setShowFormulas(true);
                      handleCollapseAllFormulas(false);
                    }}
                    type="button"
                    title={formulaButtonTitle}
                    disabled={!featureFormulasInFiltersIsEnabled}
                  >
                    <img
                      src={WhiteFormulaIcon}
                      alt="formula-icon"
                    />
                  </button>
                }

                {
                  subQueryHasFormulaFilters &&
                  <button
                    className="toggle-formula-container-button"
                    onClick={() => {
                      setShowFormulas(!showFormulas);
                      handleCollapseAllFormulas(false);
                    }}
                    type="button"
                  >
                    <i className={`fa ${expandCollapseButton} `} />
                  </button>
                }

                {
                  (!featureFormulasInFiltersIsEnabled && !Util.isDESelectFreeUser(orgInfo)) && (
                    <Tooltip
                      nubbinPosition={Constants.NUBBIN_POSITION__TOP_RIGHT}
                      type={Constants.TOOLTIP_TYPE__UNAVAILABLE_FEATURE}
                    />
                  )
                }
              </>
            ) :
            null}
          {
            showPicklistsAdvertFormulas && !featurePickLists && (
              <div onClick={() => showEssentialsUpgradeModal(Constants.FEATURE__PICKLISTS)}>
                {
                  featureAdvertExists(Constants.FEATURE__PICKLISTS) &&
                  <UpgradeBadge />
                }
              </div>
            )
          }
          <div className="add-logic">
            {shouldMergeIconBeRendered() ?
              (
                <div
                  className="add-logic-link"
                  onMouseOver={() => handleHoverFilterMerging(id, null, isInResultsFormula, parentId)}
                  onFocus={() => undefined}
                  onBlur={() => undefined}
                  onMouseOut={() => handleHideAddFiltersTogether(null, isInResultsFormula, parentId)}
                  onMouseEnter={() => { draggableDiv.current.draggable = false; }}
                  onMouseLeave={() => { draggableDiv.current.draggable = true; }}
                  onClick={() => prepareIdsAndMergeFilterLines(filterIndex, isInResultsFormula)}
                >
                  +
                </div>
              ) :
              null}
          </div>
          {/* here added drag icon with tag title */}
          <svg
            className="slds-button__icon drag-and-drop"
            aria-hidden="true"
            onMouseDown={(e) => { targetDragIcon = e.target; }}
          >
            <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#drag_and_drop" />
            <title>Drag this filter to a drop zone of a filter grouping to move it</title>
          </svg>
        </div>
      </div>
      {!isInResultsFormula &&
        filtersUtil.isInResultsOrNotInResults(criteria) && subQueryHasFormulaFilters && (showFormulas &&
          <InResultsFormulaFilterLine
            collectionAlias={collectionAlias}
            subQuery={subQuery}
            filters={subQuery.formulas.filters}
            operator={subQuery.formulas.operator}
            handleUpdateFilterLineCriteria={handleUpdateFilterLineCriteria}
            handleUpdateFormulaFilterLineCriteria={handleUpdateFormulaFilterLineCriteria}
            handleOnChangeFormulaValue={handleOnChangeFormulaValue}
            handleOnChangeFormulaFilterFormulaValue={handleOnChangeFormulaFilterFormulaValue}
            handleChangeFilterDataExtensionField={handleChangeFilterDataExtensionField}
            handleUpdateFilterLineValue={handleUpdateFilterLineValue}
            handleRemoveFilterLine={handleRemoveFilterLine}
            handleFiltersSave={handleFiltersSave}
            DEBorderMouseOver={DEBorderMouseOver}
            filterBorderMouseOver={filterBorderMouseOver}
            handleLogicChange={handleLogicChange}
            handleHoverFilterMerging={handleHoverFilterMerging}
            handleHideAddFiltersTogether={handleHideAddFiltersTogether}
            handleMergeFilterLines={handleMergeFilterLines}
            parentId={id}
            handleOpenTimezoneModal={handleOpenTimezoneModal}
            updateTags={updateTags}
            handleSetTags={handleSetTags}
            tags={tags}
            filterObject={filterObject}
            handleSetDataExtensionToFilter={handleSetDataExtensionToFilter}
            handleRemoveDataExtensionFromFilter={handleRemoveDataExtensionFromFilter}
            handleOpenSubQueryModal={handleOpenSubQueryModal}
            handleTagsChanged={handleTagsChanged}
            pickLists={pickLists}
            fieldType={fieldType}
            field={field}
            showInResultsOption={showInResultsOption}
            dropFieldOnFilters={dropFieldOnFilters}
            handleSetSelectionState={handleSetSelectionState}
            collapseFormulas={collapseFormulas}
            handleCollapseAllFormulas={handleCollapseAllFormulas}
            selectedActiveDE={selectedActiveDE}
            showEssentialsUpgradeModal={showEssentialsUpgradeModal}
          />
      )}
    </div>
  );
};

FilterLine.propTypes = {
  showInResultsOption: PropTypes.bool.isRequired,
  /**
   * it stores the id property of the filterlines
   */
  id: PropTypes.string,
  /**
   * It helps to remove the selected filter line
   * It will be passed from SelectedFilters.js
   */
  handleRemoveFilterLine: PropTypes.func.isRequired,
  /**
   * it stores the selected criteria for the filterlines
   */
  criteria: PropTypes.string.isRequired,
  /**
   * the value which is entered for the filter
   * It is string when the field type is text, email, phone or locale
   * It is boolean when the field type is boolean
   * It is date when the field type is date (calendar not relative)
   * It is shaped when criteria is between
   * It is number when the field type is number or decimal
   */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    PropTypes.instanceOf(Array),
    PropTypes.bool,
    PropTypes.shape({
      minValue: PropTypes.string,
      maxValue: PropTypes.string,
    }),
    PropTypes.number,
  ]),
  /**
   * It keeps the filter index which  index where the filter is located
   * it is stored in filters array's filter object
   */
  filterIndex: PropTypes.number,
  /**
   * It helps to indicate which filters will be merged as another level
   * when clicked on add filters together button
   * It will be passed from SelectedFilters.js
   */
  handleHoverFilterMerging: PropTypes.func,
  /**
   * It determines if grouping button should be shown for filters
   */
  showGroupButton: PropTypes.bool,
  /**
   * It actually updates the color of the 'add filters together' button
   * It will be passed from SelectedFilters.js
   */
  handleHideAddFiltersTogether: PropTypes.func,
  /**
   * actually it keeps the filters of the parent
   */
  filters: PropTypes.instanceOf(Array).isRequired,
  /**
   * It keeps the data extension`s alias of a field
   */
  collectionAlias: PropTypes.string,
  /**
   * It determines if the collection alias should be hidden after the name in the filter line
   */
  hideCollectionAlias: PropTypes.bool.isRequired,
  /**
   * It keeps the field Name
   */
  field: PropTypes.string.isRequired,
  /**
   * It keeps the field type
   */
  fieldType: PropTypes.string.isRequired,
  /**
   * It helps to merge one filterline with another to a filter container
   * It will be passed from SelectedFilters.js
   */
  handleMergeFilterLines: PropTypes.func.isRequired,
  /**
   * It helps to change the selected criteria for a filter
   * It will be passed from SelectedFilters.js
   */
  handleUpdateFilterLineCriteria: PropTypes.func.isRequired,
  /**
   * It helps to update a value of a filter
   * It will be passed from SelectedFilters.js
   */
  handleUpdateFilterLineValue: PropTypes.func.isRequired,
  /**
   * It determines if the selected field for a filter is a subquery or not
   */
  isSubQuery: PropTypes.bool,
  /**
   * It helps to open subquery modal if a filter`s criteria is IN RESULTS
   * It will be passed from SelectedFilters.js
   */
  handleOpenSubQueryModal: PropTypes.func.isRequired,
  /**
   * When a date type of field is selected for a filter,
   * it keeps the value of its type. (relative, calendar).
   */
  dateFilterType: PropTypes.string,
  /**
   * When a relative type is selected for a date type of filter,
   * It will keep the starting value of it
   */
  dateValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  /**
   * When a relative type is selected for a date type of filter,
   * It will keep its interval period
   */
  filterInterval: PropTypes.string,
  /**
   * When a relative type is selected for a date type of filter,
   * It will keep its starting point
   */
  dateValueStart: PropTypes.string,
  /**
   * start date of the date range picker for criteria Between/Not Between
   */
  startDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Object),
  ]),
  /**
   * end date of the date range picker for criteria Between/Not Between
   */
  endDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Object),
  ]),
  /**
   * It keeps the subquery state for the filter if it contains subquery
   */
  subQuery: PropTypes.instanceOf(Object).isRequired,
  /**
   * When IN RESULTS is selected as criteria for a filter, there is another
   * drag-drop zone for another dataextension which will be used for subquery
   * This function helps to remove that dataextension from that zone if there is
   * one selected
   * It will be passed from SelectedFilters.js
   */
  handleRemoveDataExtensionFromFilter: PropTypes.func.isRequired,
  /**
   * When IN RESULTS is selected as criteria for a filter, there is another
   * drag-drop zone for another dataextension which will be used for subquery.
   * And also there is one field has to be selected to compare.
   * This function helps to change the selected field.
   * It will be passed from SelectedFilters.js
   */
  handleChangeFilterDataExtensionField: PropTypes.func.isRequired,
  /**
   * If a relative option is selected for the date type of filter,
   * this function will help to change its value, depending on fieldProperty.
   * It will be passed from SelectedFilters.js
   */
  handleOnChangeRelativeDate: PropTypes.func,
  /**
   * When comparing a field with another field for the filter,
   * a field will be selected for that action.
   * This function helps to select it.
   * It will be passed from SelectedFilters.js
   */
  handleOnChangeIsCompareFieldsFilter: PropTypes.func,
  /**
   * It contains the selected data extensions but if subquery`s modal is open,
   * it will only contain the selected data extension for subquery
   */
  selectedDataExtensions: PropTypes.instanceOf(Array),
  /**
   * It contains all selected data extensions but it will be passed for only
   * subquery`s modal
   */
  compareSelectedDataExtensions: PropTypes.instanceOf(Array),
  /**
   * When comparing a field with another field for the filter,
   * a data extension will be selected.
   * This function helps to select it.
   * It will be passed from SelectedFilters.js
   */
  handleOnChangeFilterComparedDataExtension: PropTypes.func,
  /**
   * When comparing the selected filter's field with another field,
   * a field from the selected data extension for comparison will be selected for that action.
   * This function helps to select it.
   * It will be passed from SelectedFilters.js
   */
  handleOnChangeCompareFieldsFilter: PropTypes.func,
  /**
   * It keeps the fields of a selected data extension for compare fields type of filter
   */
  comparableFields: PropTypes.instanceOf(Array),
  /**
   * It keeps the state of the filter if it is a compare fields type of filter.
   * It is a boolean value but it is parsed as string.
   */
  isCompareFieldsFilter: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  /**
   * When a filter type of a selected filter is 'compare fields filter',
   * it will keep the data extension to compare
   */
  comparedDataExtension: PropTypes.instanceOf(Object),
  /**
   * It stores the field to be compared for the selected filter
   */
  comparedField: PropTypes.instanceOf(Object),
  /**
   * It helps to throw a warning message if a feature is disabled and used in the Selection
   * It will be passed from Selection.js
   */
  handleFeatureMissing: PropTypes.func,
  /**
   * It keeps the field's ObjectID property which is retrieved from SFMC
   */
  fieldObjectID: PropTypes.string,
  /**
   * It helps to determine if an aggregation formula will be used for a filter
   * It will be passed from SelectedFilters.js
   */
  handleOnChangeFormulaFilterFormulaValue: PropTypes.func,
  /**
   * It stores to selected aggregation formula if there is one selected
   */
  formula: PropTypes.string,
  /**
   * It helps to change the value if an aggregation formula is selected for the filter
   * It will be passed from SelectedFilters.js
   */
  handleOnChangeFormulaValue: PropTypes.func,
  /**
   * Keeps track whether Available Fields are dragged
   */
  handleAddFormulaFilter: PropTypes.func,
  /**
   * It helps to add formula for a filter if in results/not in results is selected
   */
  filterBorderMouseOver: PropTypes.bool.isRequired,
  /**
   * Keeps searched picklist
   * It will be passed from Selection.js
   */
  pickLists: PropTypes.instanceOf(Array).isRequired,
  /**
   * It toggles input to picklist or vice versa
   */
  handleOnChangeIsPicklistOption: PropTypes.func,
  /**
   * It keeps the value of input type
   */
  isPicklistOptions: PropTypes.bool,
  /**
   * Stores the data extensions mainly used for the
   * in / not in results filter searchable dropdown
   */
  dataExtensions: PropTypes.instanceOf(Array),
  /**
   * used to save data extension in a subquery
   */
  handleSetDataExtensionToFilter: PropTypes.func.isRequired,
  /**
   * Stores the loading state of filterlines with subQueries
   */
  loadingSubQueryFields: PropTypes.instanceOf(Object),
  /**
   * It keeps the filterline IDs of a subquery`s filters
   */
  subQueryModalFilterLineId: PropTypes.string,
  /**
   * It save the selected filters for the selection
   * it is passed from Selection.js/SubqueryModal.js
   */
  handleFiltersSave: PropTypes.func.isRequired,
  /**
   * Indicates the type/location of a filter
   */
  filterType: PropTypes.string,
  /**
   * Contains subQuery filters for a given filterline
   */
  subQueryFilters: PropTypes.instanceOf(Array),
  /**
   * Defines whether filter belongs to a relation type or not
   */
  relation: PropTypes.bool,

  /**
   * Object with filter data
   */
  filterObject: PropTypes.instanceOf(Object).isRequired,
  /**
   * It returns predefined relation object for relation filter
   * it is passed from Selection.js
   */
  returnPredefinedRelationById: PropTypes.func,
  /**
   * An object with filterlineId as keys and array of tags as values
   */
  tags: PropTypes.instanceOf(Object).isRequired,
  /**
   * Handles the addition of tags
   */
  handleTagsChanged: PropTypes.func.isRequired,
  /**
   * Handles the setting of tags on filterline load
   */
  handleSetTags: PropTypes.func.isRequired,
  /**
   * Gets the current value of filters and updates the object with tags
   */
  updateTags: PropTypes.func.isRequired,
  /**
   * Handles the opening of the timezone modal
   */
  handleOpenTimezoneModal: PropTypes.func.isRequired,
  /**
   * Determines the on/off state of the convert timezone toggle button
   */
  convertTimezone: PropTypes.bool,
  /**
   * convert to timezone value
   */
  convertToTimezone: PropTypes.string,
  /**
   * convert from timezone value
   */
  convertFromTimezone: PropTypes.string,

  /**
   * Keeps track whether Available DE are dragged
   */
  DEBorderMouseOver: PropTypes.bool.isRequired,

  /**
   * Handles checking if passed filter is a subquery formula
   */
  isInResultsFormula: PropTypes.bool,
  /**
   * it stores the selected formula for the filterlines
   */
  formulaForRelationFilter: PropTypes.string,
  /**
   * objectID of the selected field
   */
  selectedFieldObjectID: PropTypes.string,
  /**
   * Gets the current id of the parent
   */

  parentId: PropTypes.string,
  /**
   * It helps to change the operator of a filter group
   * AND - OR
   * It will be passed from SelectedFilters.js
   */
  handleLogicChange: PropTypes.func.isRequired,

  /**
   * It helps to change the selected criteria of a formula in filter
   * It will be passed from SelectedFilters.js
   */
  handleUpdateFormulaFilterLineCriteria: PropTypes.func.isRequired,
  /**
   * It helps to add a field as filter to multi level filter lines
   * It will be passed from SelectedFilters.js
   */
  dropFieldOnFilters: PropTypes.func.isRequired,
  /**
   * It helps to set the Selection`s state
   * It will be passed from Selection.js
   */
  handleSetSelectionState: PropTypes.func.isRequired,

  /**
   * It helps to set whether all formula filters should be collapsed
   * It will be passed from SelectedFilters.js
   */

  collapseFormulas: PropTypes.bool,

  /**
   * function to toggle state of collapseFormulas
   * It will be passed from SelectedFilters.js
   */

  handleCollapseAllFormulas: PropTypes.func,

  selectedActiveDE: PropTypes.string,
  /**
   * It toggles a feature advert modal on with specific feature
   */
  showEssentialsUpgradeModal: PropTypes.func,

  /**
   * User info from cookie
   */
  userInfo: PropTypes.object,
  /**
   * Org info from cookie
   */
  orgInfo: PropTypes.object,
  /**
   * Features info from cookie
   */
  featuresInfo: PropTypes.object,
};

export default connect(
  mapStateToProps(['userInfo', 'orgInfo', 'featuresInfo']),
  null,
  null,
  { pure: false },
)(FilterLine);
