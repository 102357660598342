import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext } from 'react-beautiful-dnd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import {
  handleUnmappedRequiredFields, reorderSelectedSelections,
  setDataAfterDroppingSelection,
} from '../../../redux/actions/waterfallSelection/selectionActions';
import AvailableSelections from './AvailableSelections/AvailableSelections';
import SelectionContainer from './SelectionContainer/SelectionContainer';
import './styles.scss';
import Constants from '../../../constants/constants';
import waterfallSelectionUtil from '../../../utils/waterfallSelection/waterfallSelectionUtil';
import WarningAlert from '../../shared/WarningAlert/WarningAlert';

const StepsView = ({
  getNestedSelectionFolders,
  axiosCancelToken,
  handleSetAppState,
  folderId,
  folderSettings,
  currentSelectionName,
  isArchived,
}) => {
  const dispatch = useDispatch();

  // get state of properties from selection and targetDE reducer
  const {
    selectedSelections, targetDataExtensions, loadingTargetDEObjectIDs, loadingSelectionIds,
    runStatusForSelectionChain, allSelections,
  } = useSelector(({
    selectionReducer, targetDataExtensionReducer, globalReducer,
  }) => ({
    selectedSelections: selectionReducer.selectedSelections,
    loadingSelectionIds: selectionReducer.loadingSelectionIds,
    targetDataExtensions: targetDataExtensionReducer.targetDataExtensions,
    loadingTargetDEObjectIDs: targetDataExtensionReducer.loadingTargetDEObjectIDs,
    runStatusForSelectionChain: globalReducer.runStatusForSelectionChain,
    allSelections: selectionReducer.allSelections,
  }), shallowEqual);

  /**
   * This function handles drag&drop elements from source to destination
   * @param {array} result - the result of drag&drop operation
   * @returns {void}
   */
  const onDragEnd = async (result) => {
    const { destination, source } = result;

    // do nothing if there's no destination or it's a container 1
    if (!destination || destination.droppableId === Constants.DROPPABLE__CONTAINER_ID__1) {
      return;
    }

    if (destination.droppableId === source.droppableId) {
      // when element is drag&drop in the selected selections container, then reorder selected selections
      dispatch(reorderSelectedSelections(source, destination, selectedSelections));
    } else { // when element is dragged from available selections to selected selections container
      // prepare input data
      const inputData = {
        selectedSelections,
        allSelections,
        loadingSelectionIds,
        axiosCancelToken,
        targetDataExtensions,
        loadingTargetDEObjectIDs,
        runStatusForSelectionChain,
      };

      // set data after dropping selection
      dispatch(setDataAfterDroppingSelection(source, destination, inputData));
    }
  };

  useEffect(() => {
    if (selectedSelections?.length && targetDataExtensions?.length &&
      !loadingSelectionIds?.length && !loadingTargetDEObjectIDs?.length) {
      // get array with selection objects from selection chain
      const selectionsInRunChain = waterfallSelectionUtil.getSelectionsData(
        runStatusForSelectionChain,
        selectedSelections,
      );

      // return swal message and remove selection for which all required fields are not mapped
      dispatch(handleUnmappedRequiredFields(selectedSelections, targetDataExtensions, selectionsInRunChain));
    }

    /* eslint-disable-next-line */
  }, [selectedSelections, targetDataExtensions, loadingSelectionIds, loadingTargetDEObjectIDs]);

  return (

    <div className="waterfall_view_new_v2">
      <div className="waterfall_wrapper">
        <DragDropContext
          onDragEnd={onDragEnd}
        >
          <AvailableSelections
            getNestedSelectionFolders={getNestedSelectionFolders}
            axiosCancelToken={axiosCancelToken}
            handleSetAppState={handleSetAppState}
            folderSettings={folderSettings}
            isArchived={isArchived}
          />
          {/* Put the bottom navigation bar according to the design here */}
          <div className={classNames(
            'selection-container-style',
            { 'isArchived-warning': isArchived },
          )}>
            {isArchived && (
              <div className="archived-warning">
                <WarningAlert text={Constants.WARNING_TEXT__ARCHIVED_SELECTION_READ_ONLY} />
              </div>
            )}
            <SelectionContainer
              folderId={folderId}
              handleSetAppState={handleSetAppState}
              currentSelectionName={currentSelectionName}
              isArchived={isArchived}
            />
          </div>
        </DragDropContext>
      </div>
    </div>

  );
};

StepsView.propTypes = {
  /**
   * It helps to cancel a subscription of an API call to backend
   */
  axiosCancelToken: PropTypes.instanceOf(Object),
  /**
   * connect parent+children folders
   */
  getNestedSelectionFolders: PropTypes.func.isRequired,
  /*
   * id of selected folder
   */
  folderId: PropTypes.string.isRequired,
  /**
   * Object with a saved folder settings
   */
  folderSettings: PropTypes.instanceOf(Object),
  /**
   * it sets the App component`s state
   * This prop will be passed from App.js component through Overview.js
   */
  handleSetAppState: PropTypes.func,
  /*
   * the name of the currently open waterfall selection
   */
  currentSelectionName: PropTypes.string.isRequired,
  /**
   * Indicates if the waterfall selection is archived or not
   */
  isArchived: PropTypes.bool.isRequired,
};

export default StepsView;
