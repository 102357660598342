import { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

/**
 * Check if the window object is available
 * @returns {boolean} - true if the window object is available
 */
const canUseDOM = () => {
  if (
    typeof window === 'undefined' ||
    !window.document ||
    !window.document.createElement
  ) {
    return false;
  }

  return true;
};

/**
 * Call the Zendesk API
 * @param  {...any} args - arguments to pass to the Zendesk API
 * @returns {void} - Calls the Zendesk API
 */
export const ZendeskAPI = (...args) => {
  if (canUseDOM && window.zE) {
    window.zE.apply(null, args);
  }
};

const Zendesk = ({ defer, onLoaded, ...other }) => {
  /**
   * Callback for when the Zendesk script has loaded
   * @returns {void} - Will call the onLoaded callback when the Zendesk script has loaded
   */
  const onScriptLoaded = useCallback(() => {
    if (typeof onLoaded === 'function') {
      onLoaded();
    }
  }, [onLoaded]);

  /**
   * Insert the Zendesk script into the DOM
   * @param {boolean} defer - true if the script should be deferred
   * @returns {void} - Will insert the Zendesk script into the DOM
   */
  const insertScript = useCallback(
    (defer) => {
      const script = document.createElement('script');

      if (defer) {
        script.defer = true;
      } else {
        script.async = true;
      }
      script.id = 'ze-snippet';
      script.src = 'https://static.zdassets.com/ekr/snippet.js?key=2d9c4eb4-b9ff-4c54-98cd-ca97c143fc42';
      script.addEventListener('load', onScriptLoaded);
      document.body.appendChild(script);
    },
    [onScriptLoaded],
  );

  useEffect(() => {
    if (canUseDOM && !window.zE) {
      insertScript(defer);
      window.zESettings = other;
    }

    return () => {
      if (!canUseDOM || !window.zE) {
        return;
      }
      delete window.zE;
      delete window.zESettings;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

Zendesk.propTypes = {
  defer: PropTypes.bool,
  onLoaded: PropTypes.func,
};

export default Zendesk;
