import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment-timezone';
import { connect } from 'react-redux';

import mapStateToProps from '../../../../mapStateToProps';
import Util from '../../../../util';
import Constants from '../../../../constants/constants';
import './styles.scss';
import SwalUtil from '../../../../utils/swal/swalUtil';
import StatusIcon from '../../../shared/StatusIcon/StatusIcon';
import timeUtil from '../../../../utils/time/timeUtil';

const SelectionsCenter = ({
  numberOfRecords,
  runStatus,
  createdBy,
  createdAt,
  updatedBy,
  updatedAt,
  userDateTimeFormat,
  taskCompletedDate,
  userLocale,
  _id,
  taskError,
  countTaskError,
  runDeduplicationTaskError,
  scheduledRun,
  featureScheduleSelectionsIsEnabled,
  handleOpenSelectionRunLogsModal,
  overviewSection,
  isTemplate = false,
  // eslint-disable-next-line no-unused-vars
  featureSelectionTemplateIsEnabled,
  isArchived,
  waterfallSchedules,
  showCalendarViewForSchedules,
  handleSetOverviewState,
  showHover,
  userInfo,
}) => {
  const centerBodyClassName = classNames(
    'selections_center_body',
    // eslint-disable-next-line quote-props
    { 'waterfall': overviewSection === Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS },
  );

  // suppress only the date construction fallback warnings
  // eslint-disable-next-line func-names
  moment.createFromInputFallback = function (config) {
    // eslint-disable-next-line no-param-reassign
    config._d = new Date(config._i);
  };

  /**
   * Format number of records based on the task status
   * @param {number} recordsNumber - record number
   * @param {number} runTaskStatus - task status
   * @returns {string} - N/A or formatted number
   */
  const formatNumberOfRecords = (recordsNumber, runTaskStatus) => {
    // If the task is in any progress change the icons
    if (recordsNumber === null && runTaskStatus !== Constants.STATUS_ERROR ||
      runTaskStatus === Constants.STATUS_QUEUED_FOR_WATERFALL) {
      return <StatusIcon
        status={runTaskStatus}
        showError={() => SwalUtil.fire({
          title: 'Query Failed',
          message: taskError || runDeduplicationTaskError || countTaskError,
          type: Constants.SWAL__TYPE__ERROR,
        })} />;
    }

    // if there are numbers of records then return the number
    if (recordsNumber !== null && recordsNumber !== undefined && recordsNumber !== -1 &&
      typeof recordsNumber === 'number') {
      return Util.formatNumber(recordsNumber, 0, userLocale);
    }

    return 'N/A';
  };

  const getScheduleIconStyle = (color, cursor) => {
    return {
      fill: color || `${scheduledRun?.enabled ? 'green' : 'gray'}`,
      cursor: cursor || `${scheduledRun?.enabled ? 'pointer' : 'default'}`,
    };
  };

  /**
   * Render the right status icon
   * @param {number} queryRunStatus - Status of processing of run
   * @returns {object|undefined} The status icon
   */
  const statusIcon = (queryRunStatus) => {
    let output;

    // In case selection is going to be retried, add info about the retry
    const retryMessage = `<p class='bold_swal'>Selection will be retried at ${
      moment(taskCompletedDate).add(15, 'minutes').format(userDateTimeFormat)}</p><br>`;

    const error = taskError || runDeduplicationTaskError || countTaskError;
    const isPermissionError = String(error)?.includes(Constants.ERROR_SFMC_PERMISSION_FAILED);

    const insufficientPermissionError = Constants.ERROR_INSUFFICIENT_PERMISSIONS_DETAILS;

    if (typeof queryRunStatus === 'number') {
      output = <StatusIcon
        status={queryRunStatus}
        showError={retry => SwalUtil.fire({
          title: isPermissionError ? 'Query Failed: Insufficient Permissions' : 'Query Failed',
          messageHTML: isPermissionError ? insufficientPermissionError : ((retry ? retryMessage : '') + error),
          type: Constants.SWAL__TYPE__ERROR,
        })}
      />;
    } else {
      // return Queued icon
      output = '';
    }

    return output;
  };

  let nextRunDate = '';

  // if the featureScheduleSelectionsIsEnabled is enabled and there is scheduledRun object and it's enabled
  if (featureScheduleSelectionsIsEnabled && scheduledRun && scheduledRun.enabled) {
    // then calculate the next run date
    nextRunDate = timeUtil.calculateNextRunDate(scheduledRun, userInfo);
  }

  /**
   * Show disabled feature advert
   * @param {String} feature - feature name
   * @returns {void}
   */
  const showDisabledFeatureAdvert = (feature) => {
    handleSetOverviewState({
      featureAdvertModal: {
        show: true,
        feature,
      },
    });
  };

  return (
    <ul className={classNames(centerBodyClassName, { 'overview-list-hover': showHover })}>
      <li className="selections_center_body-status">
        <div>{statusIcon(runStatus)}</div>
      </li>
      {overviewSection === Constants.OVERVIEW__SECTION__SELECTIONS && (
      <li className="selections_center_body-nrOfRecord">
        <div className="slds-truncate">
          {formatNumberOfRecords(numberOfRecords, runStatus)}
        </div>
      </li>
      )}
      <li>
        <div
          className="slds-truncate overview-selection-completed-date"
          title=""
        >
          {taskCompletedDate === Constants.DUMMY_DATE__1990_01_01 ||
          taskCompletedDate === undefined || taskCompletedDate === null ?
            '' :
            (
              <a href="#!" role="button" onClick={() => handleOpenSelectionRunLogsModal(_id)}>
                { timeUtil.formatDate(
                  taskCompletedDate,
                  userDateTimeFormat,
                ) }
              </a>
            )}
        </div>
      </li>
      {!isArchived && (
        <li
          className={classNames(
            'scheduled-width',
            { 'disabled-schedule': !featureScheduleSelectionsIsEnabled },
          )}
          onClick={
            featureScheduleSelectionsIsEnabled ?
              null :
              () => showDisabledFeatureAdvert(Constants.FEATURE__SCHEDULE_SELECTIONS)
            }
          >
          <div
            className="slds-truncate scheduled-icon-container"
          >
            <span
              className={classNames(
                'span-scheduled-icon',
                { 'disabled-schedule': !featureScheduleSelectionsIsEnabled },
              )}
              title={`${nextRunDate}`}
              onClick={scheduledRun?.enabled ? () => showCalendarViewForSchedules(_id) : null}
            >
              <svg
                className="slds-icon  slds-icon-text-default"
                style={getScheduleIconStyle()}
                aria-hidden="true"
                id="schedule-icon"
              >
                <use xlinkHref="/assets/icons/standard-sprite/svg/symbols.svg#service_appointment_capacity_usage" />
              </svg>
            </span>

            {isArchived ?
              null :
              (
              <span
                className={classNames('schedule-funnel-icon', { 'ghost-funnel': !waterfallSchedules?.length })}
                title="This selection is part of a scheduled Waterfall Selection"
                >
              <i className="fa fa-filter" />
              </span>)}

          </div>
        </li>
      )}
      <li className="overview-selection-created-by-list">
        <div className="overview-selection-created-by" title={createdBy}>
          {Util.abbreviate(createdBy, 15)}
        </div>
      </li>
      <li>
        <div className="slds-truncate overview-selection-created-at" title="">
          {timeUtil.formatDate(createdAt, userDateTimeFormat)}
        </div>
      </li>
      <li
        className={classNames('overview-selection-updated-by', featureScheduleSelectionsIsEnabled ?
          'width-schedule-on' :
          'width-schedule-off')}
      >
        <div title={updatedBy}>
          {Util.abbreviate(updatedBy, 15)}
        </div>
      </li>
      <li>
        <div className="slds-truncate overview-selection-updated-at" title="">
          {updatedAt ? timeUtil.formatDate(updatedAt, userDateTimeFormat) : null}
        </div>
      </li>
      {overviewSection === Constants.OVERVIEW__SECTION__SELECTIONS && (
        <li
          className={classNames('template-list-item', { 'disabled-schedule': !featureSelectionTemplateIsEnabled })}
          onClick={
            featureSelectionTemplateIsEnabled ?
              null :
              () => showDisabledFeatureAdvert(Constants.FEATURE__SELECTION_TEMPLATE)
            }
          >
          {isTemplate ?
            <i className="far fa-check-square" /> :
            <i className="far fa-square" />}
        </li>
      )}
    </ul>
  );
};

SelectionsCenter.propTypes = {
  /**
   * It stores number of records of that selection`s query
   */
  numberOfRecords: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * It stores the user who created the selection
   */
  createdBy: PropTypes.string.isRequired,
  /**
   * It stores the date when selection is created
   */
  createdAt: PropTypes.string.isRequired,
  /**
   * It stores the user who updated the selection
   */
  updatedBy: PropTypes.string,
  /**
   * It stores the date when selection is updated
   */
  updatedAt: PropTypes.string,
  /**
   * It stores the User`s date time format like mm-dd-yyyy hh:MM
   */
  userDateTimeFormat: PropTypes.string.isRequired,
  /**
   * It stores the completed date of the selection`s query
   */
  taskCompletedDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  runStatus: PropTypes.number,
  /**
   * It stores the User`s local zone like en-US, en-GB, fr-fr, tr-tr
   */
  userLocale: PropTypes.string.isRequired,
  /**
   * It gives the id of selection
   */
  _id: PropTypes.string.isRequired,
  /**
   * It stores the value if something goes wrong when a query is run
   */
  taskError: PropTypes.string,
  countTaskError: PropTypes.string,
  runDeduplicationTaskError: PropTypes.string,
  /**
   * It keeps the info about scheduled selection
   */
  scheduledRun: PropTypes.instanceOf(Object).isRequired,
  /**
   * It determines if the feature for scheduling selections is enabled or not
   */
  featureScheduleSelectionsIsEnabled: PropTypes.bool.isRequired,
  /**
   * It opens the selectionRunLogsModal
   */
  handleOpenSelectionRunLogsModal: PropTypes.func.isRequired,
  /**
   * It opens the calendar view modal
   */
  showCalendarViewForSchedules: PropTypes.func.isRequired,
  /**
   * Defines which section in Overview is selected - selections or waterfall
   */
  overviewSection: PropTypes.string.isRequired,
  /**
   * Indicates whether a selection is a template
   */
  isTemplate: PropTypes.bool,
  /**
   * It determines if the feature for selection template is enabled or not
   */
  featureSelectionTemplateIsEnabled: PropTypes.bool.isRequired,
  /*
   * Indicates if a selection is archived
   */
  isArchived: PropTypes.bool.isRequired,
  /**
   * Schedules of the waterfall selection in which this  selection is used
   */
  waterfallSchedules: PropTypes.array.isRequired,
  /**
   * Function setting state of overview.js
   */
  handleSetOverviewState: PropTypes.func.isRequired,
  /**
   * User info from cookie
   */
  userInfo: PropTypes.object,
};

SelectionsCenter.defaultProps = {
  /**
   * Indicates default scheduledRun object
   */
  scheduledRun: {},
};

export default connect(mapStateToProps(['userInfo']), null, null, { pure: false })(SelectionsCenter);
