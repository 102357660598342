import React, { useState } from 'react';
import { CodeBlock } from 'react-code-blocks';
import PropTypes from 'prop-types';

import Util from '../../../util';
import ModalTemplate from '../../shared_v2/ModalTemplate/ModalTemplate';

const SQLQueryModal = ({ taskQuery = '', closeModal, withoutModal }) => {
  const [showText, setShowText] = useState(false);

  /**
   * Returns formatted SQL query
   * @param {string} query - string with SQL query
   * @returns {void}
   */
  const showFormattedTaskQuery = (query) => {
    const sqlQuery = query || taskQuery;

    return (
      <CodeBlock
        text={sqlQuery}
        language="sql"
        showLineNumbers={false}
      />
    );
  };

  /**
   * Returns abbreviated and formatted SQL Query
   * @returns {void}
   */
  const showAbbreviatedTaskQuery = () => {
    // check the length of the SQL query and abbreviate it if it's too long
    const abbreviateTaskQuery = taskQuery.length > 320 && showText === false ?
      Util.abbreviate(taskQuery, 320) :
      taskQuery;

    return (
      <div className="abbreviate-sql-query">
        {showFormattedTaskQuery(abbreviateTaskQuery)}
        <p
          id="show-more-less-text"
          className={taskQuery.length > 320 ? 'show-more-less-text' : 'show-more-less-text-none'}
          onClick={() => setShowText(!showText)}
          style={{ paddingTop: '0.5rem' }}
        >
          {showText === false ? 'Show More' : 'Show Less'}
        </p>
      </div>
    );
  };

  return (
    withoutModal ?
      showAbbreviatedTaskQuery() :
      (
        <ModalTemplate
            headerId="modal-heading-01"
            headerTitle="View SQL Query"
            containerClassName="sql-query-modal"
            contentClassName="slds-p-around_medium"
            contentId="sql-query-modal"
            handleCancel={closeModal}
            cancelButtonTitle="Close"
          >
            {showFormattedTaskQuery()}
        </ModalTemplate>
      ));
};

SQLQueryModal.propTypes = {
  /**
   * The SQL query to render
   */
  taskQuery: PropTypes.string.isRequired,
  /**
   * Event handler for when the close modal icon is clicked
   */
  closeModal: PropTypes.func,
  /**
   * Inform how the component should be displayed - whether as a modal or not
   */
  withoutModal: PropTypes.bool,
};

export default SQLQueryModal;
