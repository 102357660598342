import React from 'react';
import PropTypes from 'prop-types';

import Constants from '../../../constants/constants';

const Spinner = ({
  size, assistiveText, loadingText, loadingTextClassName, className,
}) => (
  <>
    <div role="status" className={`slds-spinner slds-spinner_${size} ${className || ''}`}>
      <span className="slds-assistive-text">{assistiveText || ''}</span>
      <div className="slds-spinner__dot-a" />
      <div className="slds-spinner__dot-b" />
    </div>
    {loadingText ?
      (
        <span className={loadingTextClassName}>
          {loadingText}
        </span>
      ) :
      null}
  </>
);

Spinner.propTypes = {
  /**
   * Determines the size of the spinner
   */
  size: PropTypes.oneOf([
    Constants.SPINNER__SIZE__XX_SMALL,
    Constants.SPINNER__SIZE__X_SMALL,
    Constants.SPINNER__SIZE__SMALL,
    Constants.SPINNER__SIZE__MEDIUM,
    Constants.SPINNER__SIZE__LARGE]).isRequired,
  /**
   * Assistive text that is read out loud to screen readers.
   */
  assistiveText: PropTypes.string,
  /**
   * Loading text showing next to loading spinner
   */
  loadingText: PropTypes.string,
  /**
   * Class name for the Loading text
   */
  loadingTextClassName: PropTypes.string,
  /**
   * Class name for the spinner component
   */
  className: PropTypes.string,
};

export default Spinner;
