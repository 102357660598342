import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

import DataExtensionsAPI from '../../api/data-extensions';
import HooksUtil from './hooksUtil';
import SwalUtil from '../swal/swalUtil';
import filtersUtil from '../filters/filtersUtil';
import Constants from '../../constants/constants';

/**
 * Check missing fields in dynamic value
 * @param {object} selectedDataExtensions - selected data extensions
 * @param {object} customValue - A custom value
 * @returns {Object} - Object containing missing fields and updated Custom value object
 */
const checkMissingFieldsDynamicValue = async (selectedDataExtensions, customValue) => {
  const copyOfCustomValue = JSON.parse(JSON.stringify(customValue || {}));
  const updatedCustomValue = JSON.parse(JSON.stringify(customValue || {}));

  // go throw all criteria array items
  const missingFieldsFilters =
    copyOfCustomValue?.value?.criteria?.reduce((missingFieldsFilters, criteria, criteriaIndex) => {
      /**
       * Gets all when statement filters and check if there are a missing fields
       * @param {object} selectedFilters - An object containing an array of filters and operator
       * @returns {array} An array of missing filed
       */
      const checkMissingFieldsInWhenFilters = (selectedFilters) => {
        for (let i = 0; i < selectedFilters.length; i += 1) {
        // Nested filter
          if (selectedFilters[i].filters) {
            checkMissingFieldsInWhenFilters(selectedFilters[i].filters);
          } else {
            const { fieldObjectID, deId } = selectedFilters[i];

            // find the data extension of the field
            const selectedDE = selectedDataExtensions.find(DE => deId === DE.ObjectID);

            // check if the field exists
            const isFieldExist = selectedDE?.fields?.some(x => x.ObjectID === fieldObjectID);

            // if the field does not exist, push it to the missingFieldsFilters
            if(!isFieldExist) {
            // push if it does not exist
              if(!missingFieldsFilters.some(x => x.fieldObjectID === fieldObjectID)) {
                missingFieldsFilters.push(selectedFilters[i]);
                // remove criteria if it's not valid anymore
                updatedCustomValue?.value?.criteria.splice(criteriaIndex, 1, null);
              }
            }
          }
        }

        return missingFieldsFilters;
      };

      checkMissingFieldsInWhenFilters(criteria?.when?.filters);

      return missingFieldsFilters;
    }, []);

  if (updatedCustomValue?.value?.criteria) {
    updatedCustomValue.value.criteria = updatedCustomValue.value.criteria.filter(c => c !== null);
  }

  return { missingFieldsFilters, updatedCustomValue };
};

/**
 * Custom hook for updating selected data extensions
 * @param {object} initialState - initial state of data
 * @param {Function} setValue - used to update the value of the Custom Value
 * @returns {Array} - Array that contains state of picklists
 */
const useSelectedDataExtensions = (initialState, setValue) => {
  const [selectedDataExtensions, setSelectedDataExtensions] = useState([]);

  const userInfo = useSelector(
    state => state.userInformationGlobalReducer.userInformation[process.env.REACT_APP_SEGMENT__SESSION_USER_KEY],
  );

  /**
   * Update Selected DEs and handles some edge cases (example missing data extensions).
   * @param {Object} customValue - Custom value object
   * @param {Object} dataExtensionsRetrieved - retrieved DEs with their fields
   * @returns {void}
   */
  const updateSelectedFilters = (customValue, dataExtensionsRetrieved) => {
    if ((customValue?.type === Constants.CUSTOM_VALUES__FIELD_TYPE__DYNAMIC_VALUE ||
      (
        customValue?.type === Constants.CUSTOM_VALUES__FIELD_TYPE__FORMULA &&
        customValue?.value?.formula === Constants.CUSTOM_VALUES__FORMULA_TYPE__FREE_FORMULA
      ))) {
      // Go through the selected data extensions and identify the ones found and the ones missing
      const dataExtensions = customValue?.selectedDataExtensions?.reduce((currentFindings, currentDE) => {
        const dataExtension = dataExtensionsRetrieved?.find(
          de => de?.ObjectID === currentDE?.ObjectID,
        );

        if (!dataExtension) {
          return {
            ...currentFindings,
            missingDataExtensions: [...(currentFindings?.missingDataExtensions || []), currentDE],
          };
        }

        return {
          ...currentFindings,
          dataExtensionsFound: [...(currentFindings?.dataExtensionsFound || []), dataExtension],
        };
      }, { dataExtensionsFound: [], missingDataExtensions: [] });

      // Update selected data extensions accordingly and raise an error if any is missing
      if (dataExtensions) {
        setSelectedDataExtensions(HooksUtil.formatDataExtensions(dataExtensions.dataExtensionsFound));
        setValue({ selectedDataExtensions: dataExtensions.dataExtensionsFound });

        if (dataExtensions.missingDataExtensions?.length !== 0) {
          HooksUtil.showMissingDEsError(dataExtensions.missingDataExtensions);
        }
      }
    }
  };

  /**
   * Fetches Data Extensions and fields selected through the dropdown and updates state.
   * Only used in Dynamic Custom Value and Free Formula.
   * @param {object} userInfo - User info from cookie
   * @returns {void}
   */
  const fetchSelectedDataExtensions = async (userInfo) => {
    if (initialState?.selectedDataExtensions?.length) {
      const dataExtensions = await DataExtensionsAPI.getDataExtensionsAndFieldsByCustomerKeys(
        axios.CancelToken.source().token,
        HooksUtil.formatDataExtensions(initialState?.selectedDataExtensions),
      );

      const { missingFieldsFilters, updatedCustomValue } = await checkMissingFieldsDynamicValue(
        dataExtensions,
        initialState,
      );

      setValue({ criteria: updatedCustomValue.value?.criteria });

      if(missingFieldsFilters?.length) {
        let filterText = '';

        missingFieldsFilters.forEach((filter, index) => {
          filterText += '<p class="one-line-text">' + (index + 1) + ' - ' +
          filtersUtil.getFilterTextForFilters([filter], 'AND', true, [], userInfo) + '</p>';
        });
        await SwalUtil.fire({
          type: 'error',
          title: '<div class="error-title">Shared Custom Value has been updated</div>',
          messageHTML:
              `<p class="slds-var-p-vertical_small"> There are some fields missing, therefore the following
              filters' related criteria have been removed:</p>
              <strong class="slds-var-p-vertical_small text-start display-block">${filterText}</strong>`,
          options: {
            showCancelButton: false,
            confirmButtonText: 'OK',
            footer: '<div></div>',
            buttonsStyling: false,
            allowOutsideClick: false,
            customClass: 'swal-wide',
          },
        });
      }

      updateSelectedFilters(initialState, dataExtensions);
    }
  };

  useEffect(() => {
    fetchSelectedDataExtensions(userInfo);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialState]);

  // return custom hook elements
  return [selectedDataExtensions, setSelectedDataExtensions];
};

export { useSelectedDataExtensions };
