import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import Constants from '../../../constants/constants';
import RelationsAPI from '../../../api/relations';
import Button from '../../shared/Button/Button';
import AdminHeader from '../../shared/AdminHeader/AdminHeader';
import Table from '../../shared/Table/Table';
import SwalUtil from '../../../utils/swal/swalUtil';
import GuidanceTip from '../../shared/GuidanceTip/GuidanceTip';

const Relations = ({
  relations = [],
  handleSetAdminPanelState,
  handleEditRelation,
  handleOpenPanel,
  handleSortRelations,
  sortDirection,
  sortedProperty,
  isLoading,
  dataSets,
}) => {
  /**
   * Deletes the relation selected in the
   * overview with the given id
   * @param {Object} event - onClick event
   * @param {String} relationId - id of the relation to delete
   * @returns {void}
   */
  const handleRemoveRelation = async (event, relationId) => {
    event.preventDefault();

    const result = await SwalUtil.fire({
      title: 'Remove Relation',
      message: 'Are you sure you want to remove this relation?',
      options: {
        showCancelButton: true,
        confirmButtonText: 'Remove',
      },
    });

    if (result.value) {
      // find the relation that is going to be deleted
      const relation = relations.find(r => r._id === relationId);

      let dataSetWhereRelationIsUsed;

      // check if the fromDE and toDE are being used in some of the data sets
      dataSets.forEach((dataSet) => {
        dataSet.relations.forEach((rel) => {
          if (((rel.fromCollectionObjectID === relation.fromDEObjectId) ||
            (rel.fromCollectionObjectID === relation.toDEObjectId)) &&
            ((rel.toCollectionObjectID === relation.toDEObjectId) ||
            (rel.toCollectionObjectID === relation.fromDEObjectId))) {
            dataSetWhereRelationIsUsed = dataSet;
          }
        });
      });

      /*
       * if a data set uses a relation that is going to be deleted
       * do not allow it
       */
      if (dataSetWhereRelationIsUsed) {
        return SwalUtil.fire({
          title: 'Remove Relation',
          messageHTML: `<p class="width_swal">Unable to delete this relation.
          This relation is part of the data set
          <span class="swal_custom_bold">${dataSetWhereRelationIsUsed.name}</span>.
          Please delete it from the data set first before deleting it from here.</p>`,
          options: {
            showCancelButton: false,
            confirmButtonText: 'OK',
          },
        });
      }

      // Delete relation
      await RelationsAPI.deleteRelation(relationId, axios.CancelToken.source().token);

      // Reload data
      handleOpenPanel(Constants.ADMIN_PANEL__SUBMENU__PREDEFINED_RELATIONS);
    }
  };

  /**
   * Renders relation Label based on relation type
   * @param {string} type - type of relation
   * @returns {string} - title of relation
   */
  const relationType = (type) => {
    const relationsType = {
      [Constants.RELATION__TYPE__ONE_TO_MANY]: '1-to-many',
      [Constants.RELATION__TYPE__ONE_TO_ONE]: '1-to-1',
    };

    // title of relation
    return relationsType[type];
  };

  /**
   * Renders relation name
   * @param {string} from - from DE name
   * @param {string} to  - to DE name
   * @returns {string} - relation name
   */
  const renderRelationName = (from, to) => from.toString() + ' - ' + to.toString();

  const renderRelationDataTable = () => {
    /*
     * if relations are available show relations
     * otherwise show text of no relations available
     */
    if (relations && relations.length > 0 && !isLoading) {
      return (relations.map(relation => (
        <tr key={relation._id} className="slds-hint-parent row-data">
          <td data-label="Relation">
            <div className="slds-truncate">
              <a
                href="#!"
                title={renderRelationName(relation.fromDEName, relation.toDEName)}
                onClick={(e) => {
                  handleEditRelation(e, relation._id);
                }}
              >
                {renderRelationName(relation.fromDEName, relation.toDEName)}
              </a>
            </div>
          </td>
          <td data-label="Type">
            <div className="slds-truncate slds-m-left_x-small relation-type">{relationType(relation.relation)}</div>
          </td>
          <td data-label="Action">
            <div className="slds-truncate slds-text-align_left">
              <Button
                buttonIconBorderFilled
                className="remove-relation"
                title="Remove"
                onClick={async e => handleRemoveRelation(e, relation._id)}
              >
                <svg className="slds-button__icon" aria-hidden="true">
                  <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#delete" />
                </svg>
              </Button>
            </div>
          </td>
        </tr>
      )));
    } if (!relations?.length && !isLoading) {
      return (
        <tr className="row-data">
          <td
            colSpan="5"
            className="slds-text-align_center"
          >
            <div id="no-picklists-defined">
              No relations have been defined yet. Hit the &apos;New Relation&apos; button to get started.
            </div>
          </td>
        </tr>
      );
    }

    return <tr aria-label="No picklist" />;
  };

  // data for table header
  const tableHeadersData = [
    {
      title: 'Relation',
      propertyName: 'fromDEName',
      sortIconId: 'relationSort',
      leftSize: Constants.TABLE__CELL_LEFT_SIZE__SMALL,
    },
    {
      title: 'Type',
      propertyName: 'relation',
      sortIconId: 'typeSort',
      leftSize: Constants.TABLE__CELL_LEFT_SIZE__X_SMALL,
    },
    {
      title: 'Action',
    },
  ];

  return (
    <>
      {/* Relations header */}
      <AdminHeader
        title="Custom Relations"
        buttonId="new-relation"
        onClick={() => handleSetAdminPanelState({ activePanel: Constants.ADMIN_PANEL__MENU__NEW_RELATION })}
        disabled={isLoading}
        buttonLabel="New Relation"
        iconLink="/assets/icons/standard-sprite/svg/symbols.svg#picklist_type"
      />
       <div className="guidance-parent-new-relation">
          <GuidanceTip tipId="custom-relations-tip" toolTipPosition="right" />
       </div>

      <Table
        tableHeaders={tableHeadersData}
        bodyContent={renderRelationDataTable()}
        isLoading={isLoading}
        id="picklists-panel"
        className="relations-table"
        handleSort={handleSortRelations}
        sortedProperty={sortedProperty}
        sortDirection={sortDirection}
      />
    </>
  );
};

Relations.propTypes = {
  /**
   * array containing the relations retrieved, this prop comes
   * from the admin panel component
   */
  relations: PropTypes.instanceOf(Array).isRequired,
  // handles the state of the admin panel
  handleSetAdminPanelState: PropTypes.func.isRequired,
  // function to open another panel / reload data
  handleOpenPanel: PropTypes.func.isRequired,
  // function to open the edit screen for a relation
  handleEditRelation: PropTypes.func.isRequired,
  /**
   * It sorts relations
   */
  handleSortRelations: PropTypes.func.isRequired,
  /**
   * It indicates the direction of sort
   */
  sortDirection: PropTypes.string.isRequired,
  /**
   * It gives by which value sort will take place
   */
  sortedProperty: PropTypes.string,
  /**
   * Responsible for showing/hiding loader
   */
  isLoading: PropTypes.bool.isRequired,
  /**
   * Array of the data sets -> used when a relation is being deleted
   */
  dataSets: PropTypes.instanceOf(Array).isRequired,
};

export default Relations;
