import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import Select from '../../../shared_v2/Select/Select';

const inputTypeSelect = ({
  dataExtensionAlias,
  fieldObjectId,
  selectedDataExtensions,
  isDefaultValueNull,
  renderNullCheckbox,
  index,
  showNoAvailableFieldsError,
  fields,
  noAvailableFieldsRef,
  handleSelectedDEChange,
  handleSelectedFieldChange,
  nullValues,
  disabled,
}) => (
  <div className="input-type-select">
    <div className="filter-select filter-criteria input-fields">
      <Select
        id="input-value-selected-de"
        value={dataExtensionAlias}
        onChange={e => handleSelectedDEChange(e, index)}
        options={selectedDataExtensions.map(de => (
          { value: de.deAlias, label: `${de.deAlias?.toString() || de.Name?.toString()}` }
        ))}
        disabled={disabled}
      />
      <div className="null-checkbox-hidden">
        {renderNullCheckbox(index, nullValues, isDefaultValueNull)}
      </div>
    </div>
    <div className={`slds-form-element ${showNoAvailableFieldsError ?
      'slds-has-error' :
      ''}`}
    >
      <div className="slds-form-element__control">
        <div style={{ flexGrow: 1 }}>
          <Select
            containerClassName="filter-select filter-criteria input-fields"
            id="input-value-selected-fields"
            value={fieldObjectId}
            onChange={e => handleSelectedFieldChange(e, index)}
            disabled={!(fields && fields.length > 0) || disabled}
            forwardRef={noAvailableFieldsRef}
            options={[...new Set(fields)].map(field => (
              { value: field.ObjectID, label: field.Name.toString() }
            ))}
            noOptionsLabel="No available fields"
          />
          {showNoAvailableFieldsError && (
            <div className="slds-form-element__help" id="no-available-fields-error">
              The selected field type is not available in this Data Extension.
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
);

inputTypeSelect.propTypes = {
  /**
   * Alias of the DE
   */
  dataExtensionAlias: PropTypes.string,
  /**
   * objectId of the field
   */
  fieldObjectId: PropTypes.string,
  /**
   * An array of all DEs used in the selection
   */
  selectedDataExtensions: PropTypes.instanceOf(Array),
  /**
   * Is the null checkbox checked for default value?
   */
  isDefaultValueNull: PropTypes.bool,
  /**
   * Renders the null checkbox inside dynamic CV
   */
  renderNullCheckbox: PropTypes.func,
  /**
   * Index of the then clause (-1 for default value)
   */
  index: PropTypes.number,
  /**
   * Determines whether noAvailableFieldsError is shown or not
   */
  showNoAvailableFieldsError: PropTypes.bool,
  /**
   * The fields in the selectedDE
   */
  fields: PropTypes.instanceOf(Array),
  /**
   * Ref for the noAvailableFields error
   */
  noAvailableFieldsRef: PropTypes.instanceOf(Object),
  /**
   * Event handler for DE change
   */
  handleSelectedDEChange: PropTypes.func,
  /**
   * Event handler for field change
   */
  handleSelectedFieldChange: PropTypes.func,
  /**
   * An array containing boolean(s) that indicate whether a clause is null or not
   */
  nullValues: PropTypes.instanceOf(Array),
};

export default inputTypeSelect;
