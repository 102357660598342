import React from 'react';
import PropTypes from 'prop-types';

import Constants from '../../../constants/constants';

const SortIcon = ({ sortDirection }) => {
  if (sortDirection === Constants.SORT_DIRECTION__ASCENDING) {
    return <i className="fas fa-angle-double-down" />;
  } if (sortDirection === Constants.SORT_DIRECTION__DESCENDING) {
    return <i className="fas fa-angle-double-up" />;
  }

  return (
    <svg
      className="slds-icon slds-icon-text-default slds-icon_x-small"
      aria-hidden="true"
    >
      <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#sort" />
    </svg>
  );
};

SortIcon.propTypes = {
  /**
   * Direction of sort asc/desc
   */
  sortDirection: PropTypes.string,
};

export default SortIcon;
