import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import TimezoneDropdown from '../../../TargetDefinition/CustomValues/TimezoneDropdown/TimezoneDropdown';
import TimezoneList from '../../../ScheduleSelectionModal/TimezoneList.json';
import Checkbox from '../../../../shared/Checkbox/Checkbox';
import FromTimezoneContainer from '../FromTimezoneContainer/FromTimezoneContainer';
import timeUtil from '../../../../../utils/time/timeUtil';

const TimezoneModal = ({
  handleCloseTimezoneModal,
  convertTimezone,
  convertToTimezone,
  convertFromTimezone,
  handleConvertTimezone,
  handleFormElementChanged,
  handleSaveTimezoneSettings,
  applyTimezoneSettingsToAllDateFields,
  handleChangeApplyTimezoneToAllDateFields,
  showFilterSets,
}) => {
  const ref = useRef(null);

  const [dimensions, setDimensions] = useState(null);

  const handleResize = () => {
    // Get subQuery DOM element
    const subQueryDOMElement = document.querySelector('#subquery-modal-dialog > div') ||
      document.querySelector('#aggregation-subquery-modal-dialog > div');

    if (subQueryDOMElement) {
      let height = 5;

      Array.from(subQueryDOMElement?.children)?.forEach((element) => {
        height += element.clientHeight;
      });

      const top = subQueryDOMElement.children[0].offsetTop;

      setDimensions({ height, top });
    }
  };

  useEffect(() => {
    if(ref.current !== null) {
      handleResize();
    }

    window.addEventListener('resize', handleResize, false);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  return (
    <>
      <section
        role="dialog"
        tabIndex="-1"
        className="slds-modal slds-fade-in-open"
        aria-labelledby="modal-heading-01"
        aria-modal="true"
        aria-describedby="modal-content-id-1"
        id="timezone-settings-modal-dialog"
      >
        <div className="slds-modal__container timezone-modal" ref={ref}>
          <header className="slds-modal__header">
            <h2
              id="timezone-modal-header"
              className="slds-modal__title slds-hyphenate"
            >
              Convert Timezone
            </h2>
          </header>
          <div className="slds-modal__content timezone-modal-content">
            <div className="slds-form-element convert-timezone-checkbox">
              <label
                className="slds-checkbox_toggle slds-grid fit-content"
                htmlFor="timezone-in-filters-convert-timezone-toggle"
              >
                <span
                  id="convertTimezoneLabel"
                  className="slds-form-element__label slds-m-bottom_none"
                >
                  Convert timezone
                </span>
                <input
                  type="checkbox"
                  id="timezone-in-filters-convert-timezone-toggle"
                  name="timezone-in-filters-convert-timezone-toggle"
                  aria-describedby="timezone-in-filters-convert-timezone-toggle"
                  aria-label="Convert timezone"
                  onChange={handleConvertTimezone}
                  checked={convertTimezone}
                />
                <span id="checkbox-toggle-16" className="slds-checkbox_faux_container" aria-live="assertive">
                  <span className="slds-checkbox_faux" />
                  <span className="slds-checkbox_on">Enabled</span>
                  <span className="slds-checkbox_off">Disabled</span>
                </span>
              </label>
            </div>
            <div className="timezone-dropdowns-container">
              <FromTimezoneContainer
                convertFromTimezone={convertFromTimezone}
                handleTimezoneChange={handleFormElementChanged}
                convertTimezone={convertTimezone}
              />
            </div>
            <div className="timezone-dropdowns-container">
              <div className="timezone-dropdown-container-to">
                <span id="convertToTimezoneLabel">Convert to timezone: </span>
                <TimezoneDropdown
                  handleOnChange={handleFormElementChanged}
                  defaultValue={convertToTimezone || timeUtil.getCurrentUserTimezone(TimezoneList, true)}
                  name="convertToTimezone"
                  disabled={!convertTimezone}
                />
              </div>
            </div>
            <div style={{ marginTop: '1rem' }}>
              <Checkbox
                name="applyTimezoneSettingsToAllDateFields"
                id="applyTimezoneSettingsToAllDateFields"
                label="Apply settings to all date fields"
                checked={!!applyTimezoneSettingsToAllDateFields}
                onChange={handleChangeApplyTimezoneToAllDateFields}
                disabled={!showFilterSets}
              />
            </div>
          </div>

          {/* Footer */}
          <footer className="slds-modal__footer" id="timezone-modal-footer">
            <div>
              <button
                type="button"
                className="slds-button slds-button_neutral"
                id="timezone-settings-cancel"
                onClick={handleCloseTimezoneModal}
              >
                Cancel
              </button>
              <button
                type="button"
                className="slds-button slds-button_brand"
                id="timezone-settings-save"
                onClick={handleSaveTimezoneSettings}
              >
                Save
              </button>
            </div>
          </footer>
        </div>
      </section>

      <div
        className="slds-backdrop slds-backdrop_open"
        style={dimensions ?
          {
            top: `${dimensions.top}px`,
            height: `${dimensions.height}px`,
          } :
          {}}
      />
    </>
  );
};

TimezoneModal.propTypes = {
  /**
   * Handles the closing of the modal
   */
  handleCloseTimezoneModal: PropTypes.func.isRequired,
  /**
   * Determines the on/off state of the convert timezone toggle button
   */
  convertTimezone: PropTypes.bool.isRequired,
  /**
   * The selected timezone to convert time to
   */
  convertToTimezone: PropTypes.string.isRequired,
  /**
   * The selected timezone to convert time from
   */
  convertFromTimezone: PropTypes.string.isRequired,
  /**
   * Handles the toggling of the convert timezone button
   */
  handleConvertTimezone: PropTypes.func.isRequired,
  /**
   * onChange event handler for the timezone dropdown
   */
  handleFormElementChanged: PropTypes.func.isRequired,
  /**
   * Handles the saving of the selected timezone settings
   */
  handleSaveTimezoneSettings: PropTypes.func.isRequired,
  /**
   * Indicates whether timezone settings are applied to all date filters
   */
  applyTimezoneSettingsToAllDateFields: PropTypes.bool,
  /**
   * Handles the toggling of apply settings to all filters' checkbox
   */
  handleChangeApplyTimezoneToAllDateFields: PropTypes.func.isRequired,
  /**
   * Define whether filter sets should be shown or not
   */
  showFilterSets: PropTypes.bool,
};

export default TimezoneModal;
