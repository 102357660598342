/* eslint-disable import/prefer-default-export */

// selection reducer
export const SET_ALL_SELECTIONS = 'SET_ALL_SELECTIONS';
export const SET_SELECTIONS_WITH_MISSING_TARGET_DE = 'SET_SELECTIONS_WITH_MISSING_TARGET_DE';
export const SET_SELECTIONS_WITH_NO_MATCHED_FIELD = 'SET_SELECTIONS_WITH_NO_MATCHED_FIELD';
export const SET_SELECTIONS_WITHOUT_MAPPED_REQUIRED_FIELDS = 'SET_SELECTIONS_WITHOUT_MAPPED_REQUIRED_FIELDS';
export const SET_SELECTED_SELECTIONS = 'SET_SELECTED_SELECTIONS';
export const LOADING_SELECTIONS = 'LOADING_SELECTIONS';
export const REMOVE_SELECTED_SELECTION = 'REMOVE_SELECTED_SELECTION';
export const START_LOADING_SELECTION_ID = 'START_LOADING_SELECTION_ID';
export const STOP_LOADING_SELECTION_ID = 'STOP_LOADING_SELECTION_ID';
export const SET_SELECTED_SELECTION_DATA = 'SET_SELECTED_SELECTION_DATA';
export const SET_SELECTIONS_FOR_RUN_CHAIN = 'SET_SELECTIONS_FOR_RUN_CHAIN';
export const SET_SELECTION_CHAIN = 'SET_SELECTION_CHAIN';
export const REMOVE_SELECTED_SELECTIONS_BY_SELECTION_ID = 'REMOVE_SELECTED_SELECTIONS_BY_SELECTION_ID';
export const SET_WATERFALL_SCHEDULES = 'SET_WATERFALL_SCHEDULES';
export const SET_WATERFALL_SCHEDULE = 'SET_WATERFALL_SCHEDULE';

// folder reducer
export const SET_SELECTED_FOLDER_ID = 'SET_SELECTED_FOLDER_ID';
export const SET_ALL_SELECTION_FOLDERS = 'SET_ALL_SELECTION_FOLDERS';
export const LOADING_FOLDERS = 'LOADING_FOLDERS';

// targetDE reducer
export const START_LOADING_TARGET_DE_OBJECT_ID = 'START_LOADING_TARGET_DE_OBJECT_ID';
export const STOP_LOADING_TARGET_DE_OBJECT_ID = 'STOP_LOADING_TARGET_DE_OBJECT_ID';
export const ADD_TARGET_DATA_EXTENSION = 'ADD_TARGET_DATA_EXTENSION';
export const REMOVE_TARGET_DATA_EXTENSION = 'REMOVE_TARGET_DATA_EXTENSION';

// global selection reducer
export const SET_SELECTION_NAME = 'SET_SELECTION_NAME';
export const SET_SELECTION_ID = 'SET_SELECTION_ID';
export const SET_WATERFALL_VIEW = 'SET_WATERFALL_VIEW';
export const CREATE_WATERFALL_COPY = 'CREATE_WATERFALL_COPY';
export const CLEAR_WATERFALL_STATE = 'CLEAR_WATERFALL_STATE';
export const SET_RUN_STATUS_FOR_SELECTION_CHAIN = 'SET_RUN_STATUS_FOR_SELECTION_CHAIN';
export const IS_QUERY_RUNNING = 'IS_QUERY_RUNNING';
export const LOADING_WATERFALL_SELECTION = 'LOADING_WATERFALL_SELECTION';
export const SET_WATERFALL_SELECTION_STATUS = 'SET_WATERFALL_SELECTION_STATUS';
export const SET_WATERFALL_SELECTION_COMPLETED_DATE = 'SET_WATERFALL_SELECTION_COMPLETED_DATE';
export const SET_PAGE_ITEMS = 'SET_PAGE_ITEMS';
export const CHANGE_PAGINATION_INDEX = 'CHANGE_PAGINATION_INDEX';
export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE';
export const SHOW_SCHEDULED_SELECTION_MODAL = 'SHOW_SCHEDULED_SELECTION_MODAL';
export const HIDE_SCHEDULED_SELECTION_MODAL = 'HIDE_SCHEDULED_SELECTION_MODAL';
export const SET_SCHEDULED_RUN_STATE = 'SET_SCHEDULED_RUN_STATE';
export const SET_SELECTION_REQUEST_DONE = 'SET_SELECTION_REQUEST_DONE';
export const SET_HAS_SCHEDULE_BEEN_ENABLED_STATE = 'SET_HAS_SCHEDULE_BEEN_ENABLED_STATE';

// error reducer
export const GET_ERRORS = 'GET_ERRORS';
export const GET_RUN_ERROR = 'GET_RUN_ERROR';

// global Data Sets reducer
export const SET_DATA_SET_NAME = 'SET_DATA_SET_NAME';
export const SET_DATA_EXTENSIONS = 'SET_DATA_EXTENSIONS';
export const SET_PREDEFINED_RELATIONS = 'SET_PREDEFINED_RELATIONS';
export const SET_PREDEFINED_RELATIONS_MAP = 'SET_PREDEFINED_RELATIONS_MAP';
export const SET_SELECTED_DATA_EXTENSIONS = 'SET_SELECTED_DATA_EXTENSIONS';
export const SET_SELECTED_DES_TREE = 'SET_SELECTED_DES_TREE';
export const SET_RELATIONS = 'SET_RELATIONS';
export const SET_MODAL_DATA_EXTENSIONS = 'SET_MODAL_DATA_EXTENSIONS';
export const CLEAR_DATA_SET_STATE = 'CLEAR_DATA_SET_STATE';

// global Filter Sets reducer
export const SET_FILTER_SET_NAME = 'SET_FILTER_SET_NAME';
export const SET_FILTER_SET_CATEGORY = 'SET_FILTER_SET_CATEGORY';
export const SET_FILTER_SET_SOURCE_TYPE = 'SET_FILTER_SET_SOURCE_TYPE';
export const SET_FILTER_SET_SELECTED_DATA_EXTENSIONS = 'SET_FILTER_SET_SELECTED_DATA_EXTENSIONS';
export const SET_FILTER_SET_SELECTED_DATA_SET = 'SET_FILTER_SET_SELECTED_DATA_SET';
export const SET_FILTER_SET_SELECTED_SOURCE_DATA_EXTENSION = 'SET_FILTER_SET_SELECTED_SOURCE_DATA_EXTENSION';
export const SET_FILTER_SET_SELECTED_FILTERS = 'SET_FILTER_SET_SELECTED_FILTERS';
export const SET_FILTER_SET_PICKLISTS = 'SET_FILTER_SET_PICKLISTS';
export const CLEAR_FILTER_SET_STATE = 'CLEAR_DATA_SET_STATE';
export const SET_FILTER_SET_DESCRIPTION = 'SET_FILTER_SET_DESCRIPTION';

// global Feature Advert reducer
export const SET_FEATURE_ADVERT = 'SET_FEATURE_ADVERT';
export const SET_ALL_FEATURE_ADVERTS = 'SET_ALL_FEATURE_ADVERTS';

// global Filter Sets category reducer
export const SET_FILTER_SET_CATEGORY_NAME = 'SET_FILTER_SET_CATEGORY_NAME';

// global User Information reducer
export const SET_USER_INFORMATION = 'SET_USER_INFORMATION';
