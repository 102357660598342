import React from 'react';
import { TextArea } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import Button from '../../../../shared_v2/Button/Button';
import Constants from '../../../../../constants/constants';

import './styles.scss';

const SqlToSelection = ({
  handleOnChange,
  handleCheckSyntax,
  sqlText,
  isValidating,
  isSyntaxValid,
  expandSQLArea,
  syntaxError,
}) => {
  return (
    <div>
      {/* SQL to Selection */}
      {expandSQLArea && (
        <div>
        <TextArea
         placeholder="Input SQL here"
         className="sql-to-selection-textArea"
         value={sqlText}
         onChange={(e) => {
           handleOnChange(e);
         }}
         />
         {/* Validation messages */}
         {(!isSyntaxValid && !isValidating) &&
            (
              <div
                className="invalid-syntax-text"
              >
                {syntaxError}
              </div>
            )}
        {(isSyntaxValid && !isValidating) &&
            (
              <div
                className="valid-syntax-text"
              >
                Validation successful.
              </div>
            )}
      <Button
        buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
        className="slds-button slds-button_outline-brand check-syntax-btn sql"
        titleInAction="Checking syntax..."
        onClick={handleCheckSyntax}
        loadingClickedButton={isValidating}
        disabled={isValidating || !sqlText || !sqlText.trim()}
      >
        Check syntax
      </Button>
        </div>
      )}
    </div>
  );
};

SqlToSelection.propTypes = {
  expandSQLArea: PropTypes.bool,
};

export default SqlToSelection;
