/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import Swal from 'sweetalert2';
import qs from 'qs';

import QueryActivitiesAPI from '../../../api/query-activities';
import WaterfallSelectionsAPI from '../../../api/waterfallSelections';
import Constants from '../../../constants/constants';
import Util from '../../../util';
import store from '../../store/store';
import {
  CREATE_WATERFALL_COPY, SET_SELECTION_NAME,
  SET_WATERFALL_VIEW,
  CLEAR_WATERFALL_STATE,
  SET_RUN_STATUS_FOR_SELECTION_CHAIN,
  SET_SELECTION_ID, IS_QUERY_RUNNING,
  LOADING_WATERFALL_SELECTION,
  SET_WATERFALL_SELECTION_STATUS,
  SET_WATERFALL_SELECTION_COMPLETED_DATE,
  SET_PAGE_ITEMS,
  CHANGE_PAGINATION_INDEX,
  SET_SEARCH_VALUE,
  SHOW_SCHEDULED_SELECTION_MODAL,
  HIDE_SCHEDULED_SELECTION_MODAL,
  SET_SCHEDULED_RUN_STATE,
  SET_SELECTION_REQUEST_DONE,
  SET_HAS_SCHEDULE_BEEN_ENABLED_STATE,
} from '../types';
import { setErrors, setRunError } from './errorActions';
// eslint-disable-next-line import/no-cycle
import { loadingSelections, setAllSelections } from './selectionActions';

/**
 * Function that sets loading status for waterfall selection
 * @param {boolean} value - true / false depending on whether selection is loading
 * @returns {void}
 */
export const loadingWaterfallSelection = value => (dispatch) => {
  dispatch({
    type: LOADING_WATERFALL_SELECTION,
    payload: value,
  });
};

/**
 * Function that sets status for isSelectionRequestDone to the opposite
 * @returns {void}
 */
export const setSelectionRequestDone = () => (dispatch) => {
  dispatch({
    type: SET_SELECTION_REQUEST_DONE,
  });
};

/**
 * Function that sets the selection name
 * @param {string} value - new value
 * @returns {void}
 */
export const setSelectionName = value => (dispatch) => {
  dispatch({
    type: SET_SELECTION_NAME,
    payload: value,
  });
};

/**
 * Function that sets the selection Id
 * @param {string} selectionId - selectionId
 * @returns {void}
 */
export const setSelectionId = selectionId => (dispatch) => {
  dispatch({
    type: SET_SELECTION_ID,
    payload: selectionId,
  });
};

/**
 * Function that changes the view in waterfall selection
 * @param {string} selectedView - selectedView
 * @returns {void}
 */
export const setSelectedView = selectedView => (dispatch) => {
  dispatch({
    type: SET_WATERFALL_VIEW,
    payload: selectedView,
  });
};

/**
 * Function that shows schedule selections modal in waterfall selection
 * @returns {void}
 */
export const showScheduledSelectionModal = () => (dispatch) => {
  dispatch({
    type: SHOW_SCHEDULED_SELECTION_MODAL,
  });
};

/**
 * Function that hides schedule selections modal in waterfall selection
 * @returns {void}
 */
export const hideScheduledSelectionModal = () => (dispatch) => {
  dispatch({
    type: HIDE_SCHEDULED_SELECTION_MODAL,
  });
};

/**
 * Function that sets scheduledRun state in waterfall selection
 * @param {object} scheduledRun - The updated scheduledRun state
 * @returns {void}
 */
export const setScheduledRun = scheduledRun => (dispatch) => {
  dispatch({
    type: SET_SCHEDULED_RUN_STATE,
    payload: scheduledRun,
  });
};

/**
 * Function that sets hasScheduleBeenEnabled state in waterfall selection
 * @param {boolean} status - true or false
 * @returns {void}
 */
export const setHasScheduleBeenEnabled = status => (dispatch) => {
  dispatch({
    type: SET_HAS_SCHEDULE_BEEN_ENABLED_STATE,
    payload: status,
  });
};

/**
 * Function that creates copy of the state
 * @returns {void}
 */
export const createWaterfallCopy = () => (dispatch) => {
  dispatch({
    type: CREATE_WATERFALL_COPY,
    payload: store.getState(),
  });
};

/**
 * Function that clear all state to initial in waterfall selection
 * @returns {void}
 */
export const clearWaterfallState = () => (dispatch) => {
  dispatch({
    type: CLEAR_WATERFALL_STATE,
  });
};

/**
 * Function that sets run status for selection chain
 * @param {string} runStatusForSelectionChain - array with all statuses for selection chain
 * @returns {void}
 */
export const setRunStatusForSelectionChain = runStatusForSelectionChain => async (dispatch) => {
  dispatch({
    type: SET_RUN_STATUS_FOR_SELECTION_CHAIN,
    payload: runStatusForSelectionChain,
  });
};

/**
 * Function that sets the status of running selection - true if running, false if not
 * @param {array} isQueryRunning - true / false depending on whether the selection is running
 * @returns {void}
 */
export const setRunningQueryStatus = isQueryRunning => (dispatch) => {
  dispatch({
    type: IS_QUERY_RUNNING,
    payload: isQueryRunning,
  });
};

/**
 * Function that sets the overall status of waterfall selection
 * @param {number} status - running status number
 * @returns {void}
 */
export const setWaterfallSelectionStatus = status => (dispatch) => {
  dispatch({
    type: SET_WATERFALL_SELECTION_STATUS,
    payload: status,
  });
};

/**
 * Function that sets the waterfall selection completed date
 * @param {Date} completedDate - the date the waterfall selection completed
 * @returns {void}
 */
export const setWaterfallSelectionCompletedDate = completedDate => (dispatch) => {
  dispatch({
    type: SET_WATERFALL_SELECTION_COMPLETED_DATE,
    payload: completedDate,
  });
};

/**
 * Function that saves waterfall selection
 * @param {array} postData - data of the new selection that will be saved
 * @param {string} selectionId - id of the saved waterfall selection
 * @param {object} cancelToken - The cancel token from Axios
 * @returns {void}
 */
// eslint-disable-next-line consistent-return
export const saveWaterfallSelection = (postData, selectionId, cancelToken) => async (dispatch) => {
  try {
    const { data } = await WaterfallSelectionsAPI.saveWaterfallSelection(postData, selectionId, cancelToken);

    // set id of the saved selection
    dispatch(setSelectionId(data));

    return data;
  } catch (err) {
    if (!axios.isCancel(err)) dispatch(setErrors(err));
  }
};

/**
 * Function for fetching run status of selections in chain
 * @param {string} selectionId - id of the waterfall selection
 * @param {object} cancelToken - The cancel token from Axios
 * @returns {object} object with run status for selection chain
 */
// eslint-disable-next-line consistent-return
export const fetchSelectionRunStatus = (selectionId, cancelToken) => async (dispatch) => {
  try {
    // get status for selection chain
    const { status } = await QueryActivitiesAPI.getRunStatusForSelectionChain(selectionId, cancelToken);

    // status for selection chain
    const selectionChainStatus = status?.selectionChainStatus;

    // update state in reducer if the first step will have a status
    dispatch(setRunStatusForSelectionChain(selectionChainStatus));

    // set overall status for waterfall selection
    const waterfallSelectionStatus = status?.waterfallSelectionStatus;

    dispatch(setWaterfallSelectionStatus(waterfallSelectionStatus));

    return status;
  } catch (err) {
    if (!axios.isCancel(err)) dispatch(setRunError(err));
  }
};

/**
 * Function that saves new query activity for the waterfall selection
 * @param {array} selectionId - waterfall selection Id
 * @param {object} cancelToken - The cancel token from Axios
 * @returns {void}
 */
export const handleRun = (selectionId, cancelToken) => async (dispatch) => {
  try {
    // clear the error
    dispatch(setRunError(''));

    // clear the run status for selection chain and waterfall selection
    dispatch(setRunStatusForSelectionChain([]));

    // run the query
    await QueryActivitiesAPI.runQueryActivityForWaterfall(selectionId, cancelToken);

    // set selection status on processing
    dispatch(setWaterfallSelectionStatus(Constants.STATUS_PROCESSING));

    // stop running the query
    dispatch(setRunningQueryStatus(false));
  } catch (err) {
    dispatch(setRunningQueryStatus(false));
    dispatch(setWaterfallSelectionStatus(Constants.STATUS_ERROR));
    if (!axios.isCancel(err)) dispatch(setRunError(err));
  }
};

/**
 * Function that changes the index of current page
 * @param {object} e - JS Event
 * @param {number} nr - A number to set paginationIndex to if `e` is null
 * @param {object} cancelToken - The cancel token from Axios
 * @param {string} folderId - selected folderId
 * @param {string} searchValue - value from search input
 * @returns {void}
 */
export const handleChangePaginationIndex = (e, nr, cancelToken, folderId, searchValue) => (dispatch) => {
  const paginationIndex = +(e && e.target && e.target.value) || +nr;

  dispatch({
    type: CHANGE_PAGINATION_INDEX,
    payload: paginationIndex,
  });

  // start loading selections
  dispatch(loadingSelections(true));

  dispatch(setAllSelections(cancelToken, folderId, paginationIndex, searchValue));
};

/**
 * It sets current number of pagination buttons.
 * @param {array} totalPages - total number of all pages
 * @returns {void}
 */
export const setPageItems = totalPages => (dispatch) => {
  const pageItems = [...Array(totalPages).keys()].map(i => i + 1);

  dispatch({
    type: SET_PAGE_ITEMS,
    payload: pageItems,
  });
};

/**
 * It sets value for search input
 * @param {string} value - value entered into input
 * @returns {void}
 */
export const setSearchValue = value => (dispatch) => {
  dispatch({
    type: SET_SEARCH_VALUE,
    payload: value,
  });
};

/**
 * Function that checks for duplicate selection name and returns it if selection name is valid
 * @param {string} newSelectionName - new selection name that comes from popup or input
 * @param {string} currentName - current selection name
 * @param {string} currentSelectionId - current selection Id
 * @returns {string|null} valid, not duplicated selection name or null if selection name is not valid
 */
export const validateSelectionName = ({
  newSelectionName,
  currentName,
  selectionId,
  isSelectionRequestDone,
  cancelToken,
}) => async (dispatch) => {
  try {
    // build the query to fetch selections by property
    const query = qs.stringify({
      propertyName: Constants.SELECTION__SEARCH_CRITERIA__NAME,
      propertyValue: newSelectionName?.toString().trim(),
    });

    // get selections with the same name
    const allSelections = await WaterfallSelectionsAPI.getWaterfallSelectionsByProperty(cancelToken, query);

    // check if there is a second selection with duplicate name
    const duplicateSelection = Util.findDuplicateName(allSelections, newSelectionName, currentName, selectionId);

    // If there is a duplicated selection in Selection Name Modal (when user enter New Selection)
    if (duplicateSelection && !isSelectionRequestDone && !selectionId) {
      return null;
    }

    // If there is a duplicated selection display the error
    if (duplicateSelection) {
    // eslint-disable-next-line no-await-in-loop
      const swalResponse = await Swal.fire({
        type: 'error',
        title: '<div class="error-title">Duplicate Selection Name</div>',
        // eslint-disable-next-line max-len
        html: '<p class="width_swal">There is already a waterfall selection with this name. Please provide a unique name for your waterfall selection.</p>',
        input: 'text',
        inputValue: currentName,
        confirmButtonText: 'Confirm',
        showCancelButton: true,
        allowOutsideClick: false,
        footer: '<div></div>',
        buttonsStyling: false,
        animation: false,
        inputValidator: newName => !newName && 'Please fill out this field.',
      });

      // If user clicks on cancel button do nothing
      if (swalResponse.dismiss) { return null; }

      // call the function again
      return dispatch(validateSelectionName({
        newSelectionName: swalResponse.value,
        currentName,
        selectionId,
        isSelectionRequestDone,
        cancelToken,
      }));
    }

    // If there is no duplicated selection, then assign new name to post data
    if (newSelectionName) {
      const trimmedName = newSelectionName?.toString().trim();

      dispatch(setSelectionName(trimmedName));

      return trimmedName;
    }

    return null;
  } catch (error) {
    if (!axios.isCancel(error)) dispatch(setErrors(error));
  }
};
