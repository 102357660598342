import React from 'react';
import PropTypes from 'prop-types';

import UpdateSelectionTable from './UpdateSelectionTable/UpdateSelectionTable';
import './styles.scss';
import ModalTemplate from '../../shared/ModalTemplate/ModalTemplate';

const UpdateSelectionModal = ({ selectionUpdates, handleSetSelectionState }) => (
  <ModalTemplate
      id="update-selection-modal-dialog"
      headerId="update-selection-header"
      headerTitle="Update Selection"
      containerClassName="update-selection-modal"
      contentClassName="update-selection-content"
      handleSave={() => handleSetSelectionState({ showUpdateSelectionModal: false })}
      saveButtonTitle="OK"
      saveButtonId="update-selection-confirm"
    >
      <p className="selection-has-been-update-title">
        Your selection has been updated. The changes are listed below.
      </p>
      <UpdateSelectionTable selectionUpdates={selectionUpdates} />
  </ModalTemplate>
);

UpdateSelectionModal.propTypes = {
  /**
   * An array containing data about changes after loading a selection - deleting and updating elements in a selection
   */
  selectionUpdates: PropTypes.instanceOf(Array).isRequired,
  /**
   * It helps to set the Selection`s state
   * It will be passed from Selection.js
   */
  handleSetSelectionState: PropTypes.func.isRequired,
};

export default UpdateSelectionModal;
