/* eslint-disable spellcheck/spell-checker */
import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AutoSizer, List } from 'react-virtualized';
import PropTypes from 'prop-types';

import Input from '../Input/Input';
import './styles.scss';

const ROW_HEIGHT = 35; // Height of each row in pixels
const MAX_VISIBLE_ROWS = 10; // Maximum number of rows to show before scrolling

/**
 * A virtualized list component with search and select functionality.
 * @param {Object[]} data - The list of items to be displayed.
 * @param {function} handleSelectedItem - The function to handle the selected item.
 * @returns {JSX.Element} - VirtualizedList component
 */
const VirtualizedList = ({
  data,
  handleSelectedItem,
  initialValue,
  placeholderText,
  inputClassName,
  imageClassName,
  imageSrcValue,
  inputContainerClassName,
}) => {
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(initialValue);
  const [searchValue, setSearchValue] = useState(initialValue);
  const [filteredData, setFilteredData] = useState(data);

  const listHeight = useMemo(() => {
    const itemCount = filteredData.length;

    return Math.min(itemCount * ROW_HEIGHT, MAX_VISIBLE_ROWS * ROW_HEIGHT);
  }, [filteredData]);

  /**
   * Handles the item click event and sets the selected item.
   * @param {Object} item - The selected item.
   */

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setSearchValue(item.value);
    setIsOpen(false);
    handleSelectedItem(item);
  };

  /**
   * Toggles the dropdown open and closed.
   */

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  /**
   * Filters the data based on the search value.
   * @param {string} value - The search value.
   */

  const filterData = (value) => {
    const filtered = data?.filter(item => item?.value.toLowerCase().includes(value.toLowerCase()));

    setFilteredData(filtered);
  };

  /**
   * Handles the change in the search input.
   * @param {Event} e - The input change event.
   */

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    filterData(e.target.value);
  };

  /**
   * Renders each row in the virtualized list.
   * @param {Object} params - Row parameters.
   * @param {number} params.index - The index of the row.
   * @param {string} params.key - The key of the row.
   * @param {Object} params.style - The style of the row.
   * @returns {JSX.Element} - Rendered row
   */
  const rowRenderer = ({ index, key, style }) => {
    const item = filteredData[index];

    let isSelected = false;

    if (selectedItem) {
      if (selectedItem._id) {
        isSelected =
          (selectedItem && selectedItem._id === item._id && searchValue) ||
          (initialValue && initialValue === item.value && searchValue);
      } else {
        isSelected =
          (selectedItem && selectedItem.value === item.value && searchValue) ||
          (initialValue && initialValue === item.value && searchValue);
      }
    }

    return (
      <div
        key={key}
        style={style}
        onClick={() => handleItemClick(item)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleItemClick(item);
          }
        }}
        data-value={item.value}
        className={`slds-media slds-listbox__option slds-listbox__option_plain
        slds-media_small${isSelected ? ' slds-is-selected' : ''
          }`}
        tabIndex="0"
        aria-selected={!!isSelected}
        role="option"
      >
        <span className="slds-media__figure slds-listbox__option-icon">
          {isSelected ?
            (
              <span className="slds-icon_container slds-icon-utility-check slds-current-color">
                <svg className="slds-icon slds-icon_x-small" aria-hidden="true">
                  <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#check" />
                </svg>
              </span>
            ) :
            (
              ''
            )}
        </span>
        <span className="slds-media__body">
          <span className="slds-truncate" title={item.label}>
            {item.label}
          </span>
        </span>
      </div>
    );
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="virtualized-dropdown-v2 join-type-dropdown">
      <div className="slds-select_container">
        <Input
          placeholder={placeholderText || 'Search or select a value'}
          onClick={toggleDropdown}
          onChange={handleSearchChange}
          value={searchValue}
          className={`slds-combobox__input slds-combobox__input-value slds-truncate ${inputClassName}`}
          autoComplete="off"
          id="virtualizedList"
          name="virtualizedList"
          imageClassName={imageClassName}
          imageSrcValue={imageSrcValue}
          containerClassName={inputContainerClassName}
        />
      </div>

      {isOpen && (
        <div className="virtualized-list-container" style={{ height: `${listHeight}px` }}>
          <AutoSizer disableHeight>
            {({ width }) => (
              <List
                className="virtualized-list"
                width={width}
                height={listHeight}
                rowCount={filteredData.length}
                rowHeight={ROW_HEIGHT}
                rowRenderer={rowRenderer}
              />
            )}
          </AutoSizer>
        </div>
      )}
    </div>
  );
};

VirtualizedList.propTypes = {
  /*
   * The list of items to be displayed.
   */
  data: PropTypes.array.isRequired,
  /*
   * The list of items to be displayed.
   */
  handleSelectedItem: PropTypes.func.isRequired,
  /*
   * The list of items to be displayed.
   */
  initialValue: PropTypes.string,

};

export default VirtualizedList;
