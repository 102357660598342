import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextArea } from 'semantic-ui-react';
import { useSpring, animated as a, config } from '@react-spring/web';

import ModalTemplate from '../../shared/ModalTemplate/ModalTemplate';
import Button from '../../shared/Button/Button';
import { PropagateLoader } from '../../shared/Loaders/Propagate';
import Constants from '../../../constants/constants';
import SwalUtil from '../../../utils/swal/swalUtil';

import './styles.scss';

export function DeedeeAIModal({
  open,
  saveDeedeeAIModal,
  deedeeAIMessage,
  setDeedeeAIMessage,
  setOpenDeedeeAIModal,
  handleOpenSelection,
  response,
  isResponseLoading,
  error,
  setIsResponseLoading,
  setError,
  setResponse,
  extraFooterContent,
}) {
  const [hasTyped, setHasTyped] = useState(false);

  /**
   * Handles the closing of the DeedeeAI modal.
   * If the user has typed a message, a confirmation dialog will be shown before closing the modal.
   * If the response is still loading, a different message will be displayed.
   * @returns {void}
   */
  const handleCloseMessageModal = async () => {
    if (isResponseLoading) {
      const result = await SwalUtil.fire({
        title: 'Selection Creation In Progress',
        message:
          'DeedeeAI is still creating the selection. If you close this, ' +
          'you can check the selection in the overview once complete. ' +
          'Are you sure you want to close?',
        options: {
          showCancelButton: true,
          confirmButtonText: 'Yes, I understand',
        },
      });

      if (result.isConfirmed) {
        setOpenDeedeeAIModal(false);
        setHasTyped(false); // Reset for the next time the modal is opened.
      }
    } else if (hasTyped && deedeeAIMessage) {
      const result = await SwalUtil.fire({
        title: 'Ongoing Prompt',
        message: 'You have an ongoing prompt. Are you sure you want to cancel?',
        options: {
          showCancelButton: true,
          confirmButtonText: 'Yes, discard changes',
        },
      });

      if (result.isConfirmed) {
        setOpenDeedeeAIModal(false);
        setHasTyped(false); // Reset for the next time the modal is opened.
      }
    } else {
      setOpenDeedeeAIModal(false);
    }
  };

  const { actualError } = error;

  let errorMessage = (
    <span className="how-can-I-help-text">
      I&apos;m sorry, I&apos;m having trouble processing your request. I&apos;m still young and learning...
    </span>
  );

  if (actualError && actualError.startsWith('Invalid prompt')) {
    const prompt = actualError.split(': ')[1];

    errorMessage = (
      <span className="invalid-prompt-text">
        Oops! I&apos;m having trouble understanding your prompt:
        <strong>{`"${prompt}"`}</strong>
.
        Could you please try rephrasing it or submit another one?
      </span>
    );
  }

  /**
   * Function to be executed when 'Save' button is clicked.
   *
   * @returns {void}
   */
  const handleSaveModal = () => {
    saveDeedeeAIModal();
    setHasTyped(false);
  };

  /** ANIMATIONS */
  const chatTextProps = useSpring({
    from: { opacity: 0, transform: 'translate3d(0, -40px, 0)' },
    to: { opacity: 1, transform: 'translate3d(0, 0px, 0)' },
    config: config.gentle,
  });

  const chatTextAreaProps = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  if (!open) {
    return null;
  }

  return (
    <ModalTemplate
      id="edit-filters-modal-dialog"
      className="edit-filters-modal slds-modal_small"
      headerId="modal-heading-01"
      headerTitle="Deedee AI"
      contentClassName="slds-p-around_medium deedee-ai-content"
      contentId="modal-content-id-1"
      cancelButtonId="edit-filters-dialog-cancel"
      handleSave={
        response || isResponseLoading || error ? null : handleSaveModal
      }
      handleCloseModal={handleCloseMessageModal}
      saveButtonId="edit-filters-dialog-save"
      saveButtonTitle={
        <p className="deedee-ai-send-btn">
          <span>Send</span>
          <svg className="slds-button__icon" aria-hidden="true">
            <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#send" />
          </svg>
        </p>
      }
      saveButtonDisabled={!deedeeAIMessage}
      containerClassName="container-class"
      extraFooterContent={
        (response || isResponseLoading || error) && extraFooterContent
      }
      showCancelIcon
    >
      <div id="modal-parent">
        <div className="modal-main">
          <div>
            <img
              alt="Deedee"
              src="/img/deedee-icon.png"
              className="deedee-icon"
            />
          </div>
          {!isResponseLoading && error && (
            <div>
              <a.p
                style={chatTextProps}
                className="slds-m-top_x-small slds-m-bottom_x-small  error-text-deedee-ai"
              >
               {errorMessage}
              </a.p>
              <p className="error-deedee">
                <img alt="Deedee" src="/img/sad-penguin.png" />
              </p>

              <Button
                noButtonClass
                onClick={() => {
                  setIsResponseLoading(false);
                  setError('');
                  setResponse('');
                }}
                id="tips-toggle-button"
                noSpan
                buttonLook={Constants.BUTTON__TYPE__BRAND}
                className="new-request-btn"
              >
                <span className="slds-icon_container slds-current-color">
                  New request
                </span>
              </Button>
            </div>
          )}

          {response && (
            <div className="deedee-response-container">
              <p className="mb-1rem how-can-I-help-text your-selection-is-ready-text">
                Your selection is ready
              </p>
              <p className="selection-name">{response.data.name}</p>

              <p className="selection-description">
                {response.generatedSQLDescription}
              </p>

              <Button
                noButtonClass
                onClick={handleOpenSelection}
                id="open-selection-button"
                noSpan
                buttonLook={Constants.BUTTON__TYPE__BRAND}
              >
                <span className="slds-icon_container slds-current-color">
                  Open Selection
                </span>
              </Button>
            </div>
          )}

          {!isResponseLoading && !error && !response && (
            <div>
              <a.p
                style={chatTextProps}
                className="slds-m-top_x-small slds-m-bottom_x-small how-can-I-help-p"
              >
                <span className="how-can-I-help-text">How can I help?</span>
              </a.p>

              <a.p style={chatTextProps} className="deedee-quote">
                DeedeeAI is ready to automate your selection. Let&rsquo;s get
                going.
              </a.p>

              <a.div style={chatTextAreaProps}>
                <TextArea
                  aria-label="Enter message for Deedee AI"
                  placeholder="Select the name of contacts in Belgium"
                  className="deedee-ai-textarea"
                  onChange={(e) => {
                    setDeedeeAIMessage(e.target.value);
                    setHasTyped(true);
                  }}
                  onKeyDown={(e) => {
                    if (
                      e.key === 'Enter' &&
                      deedeeAIMessage.trim() !== '' &&
                      hasTyped
                    ) {
                      e.preventDefault();
                      handleSaveModal();
                    }
                  }}
                />
              </a.div>
            </div>
          )}

          {isResponseLoading && (
            <div className="response-loading-container">
              <p className="slds-m-top_x-small slds-m-bottom_x-small is-response-loading-text">
                <span className="how-can-I-help-text">
                  Let me think about it...
                </span>
              </p>
              <div className="is-response-loader">
                <p className="load-text">
                  I normally need about 15 seconds for that!
                </p>
                <PropagateLoader />

              </div>
            </div>
          )}
        </div>
      </div>
    </ModalTemplate>
  );
}

DeedeeAIModal.propTypes = {
  open: PropTypes.bool.isRequired, // open indicates whether the modal is visible or not
  // saveDeedeeAIModal is the function called when "Save" button is clicked
  saveDeedeeAIModal: PropTypes.func.isRequired,
  deedeeAIMessage: PropTypes.string, // deedeeAIMessage is the message input in the modal
  setDeedeeAIMessage: PropTypes.func.isRequired, // function to set the message
  setOpenDeedeeAIModal: PropTypes.func.isRequired, // function to open/close modal
  handleOpenSelection: PropTypes.func.isRequired, // function called when "Open Selection" button is clicked
  response: PropTypes.string, // response received from server
  isResponseLoading: PropTypes.bool.isRequired, // boolean to indicate if the response is loading
  error: PropTypes.string, // any error that occurred while fetching the response
  setIsResponseLoading: PropTypes.func.isRequired, // function to set the loading state
  setError: PropTypes.func.isRequired, // function to set the error state
  setResponse: PropTypes.func.isRequired, // function to set the response state
  extraFooterContent: PropTypes.node, // additional footer content
};
