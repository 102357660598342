import axios from 'axios';

import Util from '../util';

const apiUrl = process.env.REACT_APP_API_URL;

const DataViewsAPI = {
  /**
   * Get fields for specific data view
   * @param {string} dataViewCustomerKey - Data view customerKey
   * @param {object} cancelToken - Token axios
   * @returns {object} The fields
   */
  getDataViewFields: async (dataViewCustomerKey, cancelToken) => {
    const res = await Util.catch418And403Error(
      axios.get,
      `${apiUrl}/dataviews/${dataViewCustomerKey}/fields`,
      Util.apiGetCallSettings(cancelToken),
    );

    return res.data.data;
  },
};

export default DataViewsAPI;
