import axios from 'axios';

import Util from '../util';

const apiUrl = process.env.REACT_APP_API_URL;

const PicklistsAPI = {
  /**
   * Create a new picklist based on given parameters
   * @param {string} name - Name of the picklist
   * @param {string} fieldObjectId - ObjectId of the field for which the picklist is defined
   * @param {string} dataExtensionCustomerKey - CustomerKey of the DE of the field for which the picklist is defined
   * @param {string} dataExtensionObjectId - ObjectID of the Data Extension
   * @param {array} options - Options for the picklist (value and label)
   * @param {string} fieldName - Name of the field
   * @param {string} dataExtensionName - Name of the data extension
   * @param {boolean} isActive - Determines if is active or not
   * @param {object} retrieveDistinctDEValues - Retrieve Distinct DE Values
   * @param {object} cancelToken - Axios token
   * @param {boolean} separate - Split results with a separator?
   * @param {string} separator - The string to split with
   * @param {boolean} autoRefresh - Determines if auto refresh picklist is active or not
   * @param {number} refreshTime - The hour when the picklist should be refreshed
   * @param {array} daysOfWeek - Day/days in the week when the picklist should be refreshed
   * @param {string} assignedUserId - Id of the user who is assigned to auto refresh picklist
   * - User can select 1 to 7 checkboxes (1 = Monday, 2 = Tuesday etc)
   * @returns {object} Newly created picklist
   */
  createPicklist: async (
    name,
    fieldObjectId,
    dataExtensionCustomerKey,
    dataExtensionObjectId,
    options,
    fieldName,
    dataExtensionName,
    isActive,
    retrieveDistinctDEValues,
    cancelToken,
    separate,
    separator,
    autoRefresh,
    refreshTime,
    daysOfWeek,
    assignedUserId,
  ) => {
    const postData = {
      name,
      fieldObjectId,
      dataExtensionCustomerKey,
      dataExtensionObjectId,
      fieldName,
      dataExtensionName,
      options,
      isActive,
      separate,
      separator,
      autoRefresh,
      refreshTime,
      daysOfWeek,
      retrieveDistinctDEValues,
      assignedUserId,
    };

    const res = await Util.catch418And403Error(
      axios.post,
      `${apiUrl}/picklists`,
      postData,
      Util.apiPostCallSettings(cancelToken),
    );

    return res.data;
  },

  /**
   * Update an existing picklist based on given parameters
   * @param {string} id - Id of the picklist
   * @param {string} name - Name of the picklist
   * @param {string} fieldObjectId - ObjectId of the field for which the picklist is defined
   * @param {string} dataExtensionCustomerKey - CustomerKey of DE of field for which this picklist is defined
   * @param {string} dataExtensionObjectId - ObjectID of the data extension
   * @param {array} options - New options of the updated picklist
   * @param {string} fieldName - Name of the field
   * @param {string} dataExtensionName - Name of the data extension
   * @param {boolean} isActive - Determines if is active or not
   * @param {string} retrieveDistinctDEValues - Retrieve Distinct DE Values
   * @param {object} cancelToken - Axios token
   * @param {boolean} separate - Split results with a separator?
   * @param {string} separator - The string to split with
   * @param {boolean} autoRefresh -  Determines if auto refresh picklist is active or not
   * @param {number} refreshTime - The hour when the picklist should be refreshed
   * @param {array} daysOfWeek - Day/days in the week when the picklist should be refreshed
   *                           - User can select 1 to 7 checkboxes (1 = Monday, 2 = Tuesday etc)
   * @param {string} assignedUserId - Id of the who is assigned to auto refresh picklist
   * @returns {object} Updated picklist
   */
  updatePicklist: async (
    id,
    name,
    fieldObjectId,
    dataExtensionCustomerKey,
    dataExtensionObjectId,
    options,
    fieldName,
    dataExtensionName,
    isActive,
    retrieveDistinctDEValues,
    cancelToken,
    separate,
    separator,
    autoRefresh,
    refreshTime,
    daysOfWeek,
    assignedUserId,
  ) => {
    const postData = {
      name,
      fieldObjectId,
      dataExtensionCustomerKey,
      dataExtensionObjectId,
      options,
      fieldName,
      dataExtensionName,
      isActive,
      separate,
      separator,
      autoRefresh,
      refreshTime,
      daysOfWeek,
      retrieveDistinctDEValues,
      assignedUserId,
    };

    const res = await Util.catch418And403Error(
      axios.put,
      `${apiUrl}/picklists/${id}`,
      postData,
      Util.apiPostCallSettings(cancelToken),
    );

    return res.data;
  },

  /**
   * Delete picklist based the on given id
   * @param {string} id - Id of the picklists that's going to be deleted
   * @param {object} cancelToken - Axios token
   * @returns {object} Deleted picklist
   */
  deletePicklist: async (
    id,
    cancelToken,
  ) => {
    const res = await Util.catch418And403Error(
      axios.post,
      `${apiUrl}/picklists/${id}/delete`,
      {},
      Util.apiPostCallSettings(cancelToken),
    );

    return res.data;
  },

  /**
   * Search picklists based on given array with picklists ids
   * @param {array} fieldObjectIds - List of ids of the fields for which picklist definitions need to be retrieved
   * @param {object} cancelToken - Axios token
   * @returns {array} Searched picklists
   */
  searchPicklists: async (
    fieldObjectIds,
    cancelToken,
  ) => {
    const postData = {
      fieldObjectIds,
    };

    const res = await Util.catch418And403Error(
      axios.post,
      `${apiUrl}/picklists/search`,
      postData,
      Util.apiPostCallSettings(cancelToken),
    );

    return res.data.data;
  },

  /**
   * Get picklists for the admin panel. Admin permissions are required to call this endpoint.
   * Only the properties necessary for the overview are returned.
   * @param {object} cancelToken - Axios token
   * @returns {Object[]} Picklists for this BU in this organization
   */
  getPicklists: async (
    cancelToken,
  ) => {
    const res = await Util.catch418And403Error(
      axios.get,
      `${apiUrl}/picklists`,
      Util.apiPostCallSettings(cancelToken),
    );

    return res.data.data;
  },

  /**
   * Get one picklists with all details
   * @param {string} picklistId - Id of the picklist document
   * @param {object} cancelToken - Axios token
   * @returns {Object} Returned picklist document
   */
  getPicklist: async (
    picklistId,
    cancelToken,
  ) => {
    const res = await Util.catch418And403Error(
      axios.get,
      `${apiUrl}/picklists/${picklistId}`,
      Util.apiPostCallSettings(cancelToken),
    );

    return res.data.data;
  },

  /**
   * Start getting values from DE
   * @param {string} picklistID - Picklist Id
   * @param {string} dataExtensionCustomerKey - DE customer key
   * @param {string} fieldObjectId - Field object Id
   * @param {object} cancelToken - Axios token
   * @returns {object} Contains queryDETaskId and success value
   */
  startGettingValuesFromDE: async (
    picklistID,
    dataExtensionCustomerKey,
    fieldObjectId,
    cancelToken,
  ) => {
    const postData = {
      dataExtensionCustomerKey,
      fieldObjectId,
    };

    const res = await Util.catch418And403Error(
      axios.post,
      `${apiUrl}/picklists/${picklistID}/query-dataextension/start`,
      postData,
      Util.apiPostCallSettings(cancelToken),
    );

    return res.data;
  },

  /**
   * Get query status
   * @param {string} picklistID - Picklist Id
   * @param {object} cancelToken - Axios token
   * @returns {object} Contains status and success value
   */
  getQueryStatus: async (picklistID, cancelToken) => {
    const res = await Util.catch418And403Error(
      axios.get,
      `${apiUrl}/picklists/${picklistID}/query-dataextension/status`,
      Util.apiGetCallSettings(cancelToken),
    );

    return res.data;
  },

  /**
   * Get query data
   * @param {string} picklistID - Picklist Id
   * @param {object} cancelToken - Axios token
   * @returns {object} Contains data and success value
   */
  getQueryData: async (picklistID, cancelToken) => {
    const res = await Util.catch418And403Error(
      axios.get,
      `${apiUrl}/picklists/${picklistID}/query-dataextension/data`,
      Util.apiGetCallSettings(cancelToken),
    );

    return res.data;
  },
};

export default PicklistsAPI;
