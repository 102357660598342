import React from 'react';
import {
  Button, Dropdown,
} from '@salesforce/design-system-react';

import Constants from '../../constants/constants';
import './styles.scss';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pageNumbers = [];

  const customStyles = {
    width: '7rem',
    fontSize: '0.9rem',
    textAlign: 'left',
  };

  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <nav aria-label="Page navigation example" id="pagination">
      <ul>
        <li>
          <Button
            id="pagination_left"
            buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
            className="previous-btn"
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <span className="slds-icon_container slds-icon-utility-announcement">
              <svg
                className="slds-icon slds-icon-text-default slds-icon_x-small"
                aria-hidden="true"
              >
                <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#left" />
              </svg>
            </span>
          </Button>
        </li>
        <li>
            <Dropdown
            align="right"
            iconCategory="utility"
            iconName="down"
            iconVariant="border-filled"
            iconPosition="right"
            onSelect={(value) => {
              onPageChange(value.value);
            }}
            label={`Page ${currentPage}`}
            style={customStyles}
            options={pageNumbers.map(item => ({ value: item, label: item }))}
        />
        </li>

        <li>
          <Button
            id="pagination_right"
            buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
            className="next-btn"
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            <span className="slds-icon_container slds-icon-utility-announcement">
              <svg
                className="slds-icon slds-icon-text-default slds-icon_x-small"
                aria-hidden="true"
              >
                <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#right" />
              </svg>
            </span>
          </Button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
