import Constants from '../../../../../constants/constants';
import Util from '../../../../../util';
import SwalUtil from '../../../../../utils/swal/swalUtil';

const FixedValueUtil = {
  isValid: (customValue) => {
    if (!customValue.value) {
      SwalUtil.fire({
        type: Constants.SWAL__TYPE__ERROR,
        title: 'No value is filled in.',
        message: 'Please fill in a value.',
        htmlClass: ' ',
      });

      return false;
    }

    if(
      customValue.fieldType === Constants.FILTERLINE__FIELDTYPE__EMAILADDRESS &&
      !Util.validateEmail(customValue?.value)
    ) {
      SwalUtil.fire({
        type: Constants.SWAL__TYPE__ERROR,
        title: 'Invalid Email Address',
        message: `The email address<b class="swal_bold"> ${customValue.value}</b> does not have the correct format.`,
        htmlClass: ' ',
      });

      return false;
    }

    return true;
  },
};

export default FixedValueUtil;
