import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Utils
import Util from '../../../util';
import SwalUtil from '../../../utils/swal/swalUtil';
import Input from '../../../components/shared/Input/Input';
import Button from '../../../components/shared/Button/Button';
import AdminHeader from '../../shared/AdminHeader/AdminHeader';
// Hooks
import { useCreateNotificationEmail } from '../../../utils/hooks/notificationEmail';
import { useUpdateRetentionPolicy } from '../../../utils/hooks/retentionPolicy';
import { useUpdateSendRelationship } from '../../../utils/hooks/sendRelationship';
// eslint-disable-next-line
import SettingsDataRetentionPolicyModal from './SettingsDataRetentionPolicyModal/SettingsDataRetentionPolicyModal';
import SettingsSendRelationshipModal from './SettingsSendRelationshipModal/SettingsSendRelationshipModal';
import Tooltip from '../../shared_v2/Tooltip/Tooltip';
import Spinner from '../../shared_v2/Spinner/Spinner';
import Constants from '../../../constants/constants';
import { useUpdateRetentionPolicyACDE } from '../../../utils/hooks/retentionPolicyACDE';
import './styles.scss';

const Settings = ({
  notificationEmails,
  dataRetentionSettings,
  sendRelationshipSettings,
  handleCreateNotificationEmail,
  handleDeleteNotificationEmail,
  handleAddOrUpdateDataRetentionSettings,
  handleAddOrUpdateACDEDataRetentionSettings,
  handleAddOrUpdateSendRelationshipSettings,
  dataRetentionSettingACDE,
  isLoading,
}) => {
  const [newEmails, setNewEmails, saveNewEmails] = useCreateNotificationEmail({
    notificationEmails,
    handleCreateNotificationEmail,
  });

  const [retentionPolicy, setRetentionPolicy, updateRetentionPolicy] =
    useUpdateRetentionPolicy(dataRetentionSettings, handleAddOrUpdateDataRetentionSettings);

  const [sendRelationship, setSendRelationship, updateSendRelationship] =
    useUpdateSendRelationship(sendRelationshipSettings, handleAddOrUpdateSendRelationshipSettings);

  const [retentionPolicyACDE, setRetentionPolicyACDE, updateRetentionPolicyACDE] =
    useUpdateRetentionPolicyACDE(dataRetentionSettingACDE, handleAddOrUpdateACDEDataRetentionSettings);

  const [showTempDERetentionPolicyModal, setShowTempDERetentionPolicyModal] = useState(false);
  const [showSendRelationshipModal, setShowSendRelationshipModal] = useState(false);
  const [showACDERetentionPolicyModal, setShowACDERetentionPolicyModal] = useState(false);

  /**
   * Handle click of remove icon
   * @param {string} id - Id of the notification email to delete
   * @returns {void}
   */
  const handleRemoveEmail = async (id) => {
    // Show warning modal before confirming deletion
    const result = await SwalUtil.fire({
      title: 'Remove Notification Email',
      message: 'Are you sure you want to remove this notification email?',
      options: {
        showCancelButton: true,
        confirmButtonText: 'Remove',
        allowOutsideClick: true,
      },
    });

    // Delete notification email if user confirmed
    if (result.value) {
      await handleDeleteNotificationEmail(id);
    }
  };

  /**
   * Handle click of save email
   * @param {string} key - Key user pressed
   * @returns {void}
   */
  const handleSaveEmail = async (key) => {
    if(Util.validateCommaSeparatedEmails(newEmails) && key === 'Enter') {
      saveNewEmails();
    }
  };

  return (
    <>
      <AdminHeader
        title="Settings"
        iconLink="/assets/icons/standard-sprite/svg/symbols.svg#user"
        iconTitle="Notification Settings Menu"
      />
        <div className="settings-main-v2 table-common-look">
          {isLoading ?
            <Spinner size={Constants.SPINNER__SIZE__MEDIUM} assistiveText="Loading" /> :
            <div>
              <div className="notification-settings">
                <h3 className="admin-settings-titles">Notification Settings</h3>

                <div className="info-text notification-settings-info-text">
                  <span className="admin-settings-paragraph">
                    As an admin, you can specify the email addresses where you would like to receive
                    any error notifications from DESelect.
                  </span>
                </div>

                <div className="email-info-group">
                  <div className="email-info-text">
                    <span className="admin-settings-titles">
                      Enter the email addresses where you would like to receive notifications:
                    </span>
                  </div>
                  <div className="email-input-container">
                    <Input
                      type="email"
                      className="slds-input add-email-input"
                      placeholder="Enter emails"
                      value={newEmails}
                      onChange={e => setNewEmails(e.target.value)}
                      onKeyPress={e => handleSaveEmail(e.key)}
                    />
                    <Button
                      className="slds-button slds-button_brand ml-4px ml-4px"
                      id="add-email-notification-btn"
                      disabled={!Util.validateCommaSeparatedEmails(newEmails)}
                      onClick={saveNewEmails}
                    >
                      Add
                    </Button>
                  </div>
                  <div className={classNames({ 'emails-item-list': notificationEmails?.length > 0 })}>

                    {notificationEmails?.map((notificationEmail, idx) => (
                      <div className="emails-item-container" key={idx}>
                        <span className="slds-pill" role="option" tabIndex="0" aria-selected="true">
                            <span className="slds-pill__label emails-item-text" title={notificationEmail?.email}>
                              {Util.abbreviate(notificationEmail?.email, 50)}

                            </span>
                            <span
                              onClick={() => handleRemoveEmail(notificationEmail._id)}
                              data-label={notificationEmail?.email}
                              className="slds-icon_container slds-pill__remove remove-notification-email"
                              title="Remove"
                            >
                              <svg className="slds-icon slds-icon_x-small slds-icon-text-default" aria-hidden="true">
                                <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#close" />
                              </svg>
                              <span className="slds-assistive-text">Click on the cross to delete</span>
                            </span>
                        </span>

                      </div>))}

                  </div>
                </div>

                <div className="warning-text notification-settings-warning-text">
                  <span>
                    Note: All error notifications will be sent to the above email addresses and to the email
                    address of the user who created the Selection/Waterfall Selection.
                  </span>
                </div>
              </div>

              <div className="data-retention-settings">
                <h3 className="settings-title admin-settings-titles">Data Retention Settings</h3>

                <div className="info-text">
                  <span className="display-flex admin-settings-paragraph">
                  Data Retention Policy for Temporary Data Extensions
                    <Tooltip
                      nubbinPosition={Constants.NUBBIN_POSITION__TOP_LEFT}
                      type={Constants.TOOLTIP_TYPE__TEMPORARY_DATA_EXTENSION_RETENTION_POLICY}
                    />
                  </span>
                </div>
                <div className="settings-item-container">
                  <div className="settings-enable-status admin-settings-titles">
                    <span>{retentionPolicy.dataRetentionPolicy ? 'ON' : 'OFF'}</span>
                  </div>
                  <div className="settings-show-or-edit-text">
                    <span
                      className="show-settings-link"
                      onClick={() => setShowTempDERetentionPolicyModal(true)}
                    >
                      {retentionPolicy.dataRetentionPolicy ? 'Change Policy' : 'Show Policy'}
                    </span>
                  </div>
                  <div />
                </div>
                <div className="info-text">
                  <span className="display-flex admin-settings-paragraph">
                  Data Retention Policy for New Data Extensions
                    <Tooltip
                      nubbinPosition={Constants.NUBBIN_POSITION__TOP_LEFT}
                      type={Constants.TOOLTIP_TYPE__NEW_DATA_EXTENSION_RETENTION_POLICY}
                    />
                  </span>
                </div>
                <div className="settings-item-container">
                  <div className="settings-enable-status admin-settings-titles">
                    <span>{retentionPolicyACDE.dataRetentionPolicy ? 'ON' : 'OFF'}</span>
                  </div>
                  <div className="settings-show-or-edit-text">
                    <span
                      className="show-settings-link"
                      onClick={() => setShowACDERetentionPolicyModal(true)}
                    >
                      {retentionPolicyACDE.dataRetentionPolicy ? 'Change Policy' : 'Show Policy'}
                    </span>
                  </div>
                  <div />
                </div>
                {(showTempDERetentionPolicyModal || showACDERetentionPolicyModal) && (
                  <SettingsDataRetentionPolicyModal
                  retentionPolicy={retentionPolicy}
                  setRetentionPolicy={setRetentionPolicy}
                  updateRetentionPolicy={updateRetentionPolicy}
                  setShowTempDERetentionPolicyModal={setShowTempDERetentionPolicyModal}
                  retentionPolicyACDE={retentionPolicyACDE}
                  setRetentionPolicyACDE={setRetentionPolicyACDE}
                  updateRetentionPolicyACDE={updateRetentionPolicyACDE}
                  setShowACDERetentionPolicyModal={setShowACDERetentionPolicyModal}
                  isTempDEModal={showTempDERetentionPolicyModal}
                  />
                )}
              </div>

              <div className="send-relationship-settings">
                <h3 className="settings-title admin-settings-titles">Send Relationship Settings</h3>

                <div className="info-text">
                  <span className="display-flex admin-settings-paragraph">
                  Default Send Relationship for Sendable Data Extensions
                  </span>
                </div>
                <div className="settings-item-container">
                  <div className="settings-enable-status admin-settings-titles">
                    <span>{sendRelationship?.isEnabled ? 'ON' : 'OFF'}</span>
                  </div>
                  <div className="settings-show-or-edit-text">
                    <span
                      className="show-settings-link"
                      onClick={() => setShowSendRelationshipModal(true)}
                    >
                      {sendRelationship?.isEnabled ? 'Change Relationship' : 'Show Relationship'}
                    </span>
                  </div>
                  <div />
                </div>

                {showSendRelationshipModal && (
                  <SettingsSendRelationshipModal
                  sendRelationship={sendRelationship}
                  setRetentionPolicy={setSendRelationship}
                  updateSendRelationship={updateSendRelationship}
                  setShowSendRelationshipModal={setShowSendRelationshipModal}
                  setShowACDERetentionPolicyModal={setShowACDERetentionPolicyModal}
                  />
                )}
              </div>
            </div>}
        </div>
    </>
  );
};

Settings.propTypes = {
  /**
   * Array of retrieved notification emails
   */
  notificationEmails: PropTypes.arrayOf(Object).isRequired,
  /**
   * Data retention settings object
   */
  dataRetentionSettings: PropTypes.object.isRequired,
  /**
   * Send relationship settings object
   */
  sendRelationshipSettings: PropTypes.object.isRequired,
  /**
   * Handle creation of notification email
   */
  handleCreateNotificationEmail: PropTypes.func.isRequired,
  /**
   * Handle deletion of notification email
   */
  handleDeleteNotificationEmail: PropTypes.func.isRequired,
  /**
   * Calls an API for saving the data retention policy for the temp DEs
   */
  handleAddOrUpdateDataRetentionSettings: PropTypes.func.isRequired,
  /**
   * Calls an API for saving the or updating Send Relationship settings
   */
  handleAddOrUpdateSendRelationshipSettings: PropTypes.func.isRequired,
  /**
   * Calls an API for saving the data retention policy for the newly created DEs
   */
  handleAddOrUpdateACDEDataRetentionSettings: PropTypes.func.isRequired,
  /**
   * Data retention settings object for the newly created DEs
   */
  dataRetentionSettingACDE: PropTypes.object.isRequired,
  /**
   * Responsible for showing/hiding loader
   */
  isLoading: PropTypes.bool,
};

export default Settings;
