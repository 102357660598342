import ValidationConstants from './ValidationConstants';
import Constants from '../../../../../../constants/constants';
import Util from '../../../../../../util';

/* eslint-disable no-plusplus */
/**
 * For our parser we're going to take our array of tokens and turn it into an
 * AST.
 *
 *   [{ type: ValidationConstants.TOKEN_TYPE__OPEN_PAREN, value: '(' }, ...]   =>   { type: 'Program', body: [...] }
 */

const regexForDatesList = [
  // YYYY/MM/DD
  // eslint-disable-next-line max-len
  /^\d{4}\/(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])( (0?[0-9]|1[0-9]|2[0-3]):(0[0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])(:\d{2})?)?$/,
  // MM/DD/YYYY
  // eslint-disable-next-line max-len
  /^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/\d{4}( (0?[0-9]|1[0-9]|2[0-3]):(0[0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])(:\d{2})?)?$/,
  // YYYY/DD/MM
  // eslint-disable-next-line max-len
  /^\d{4}\/(0?[1-9]|[12][0-9]|3[01])\/(0?[1-9]|1[012])( (0?[0-9]|1[0-9]|2[0-3]):(0[0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])(:\d{2})?)?$/,
];

/**
 * Parses the tokenized input and converts it into an AST
 * @param {array} tokens - An array of tokens
 * @param {array} selectedDataExtensions - An array containing selected DEs.
 * @param {object} initNode - An object to initially populate a program's body
 * @returns {object} - An object containing our parsed formula (ast) and status
 */
const parse = (tokens, selectedDataExtensions, initNode) => {
  // Keep a `current` variable that will be used as a cursor.
  let current = 0;

  /**
   * Handles expressions ( a + b)
   * @param {array} tokensPiece - A subsection of the tokens array
   * @param {object} literalObject - An object to initially populate a program's body
   * @returns {function} - Returns the parse function
   */
  const handleExpression = (tokensPiece, literalObject) => {
    // Declare a remaining tokens array
    const remainingTokens = tokensPiece.slice(current);

    // Find where the argument stops (by finding the next delimiter)
    const delimiterIndex = remainingTokens.findIndex(token => token.type === ValidationConstants.TOKEN_TYPE__DELIMITER);

    // Find where the expression stops (by finding the next closed-parenthesis)
    const closedParenthesisIndex = remainingTokens.findIndex(
      token => token.type === ValidationConstants.TOKEN_TYPE__CLOSED_PAREN,
    );

    // Find where the expression stops (by finding the next closed-parenthesis)
    const nextFuncIndex = remainingTokens.findIndex(token => token.type === ValidationConstants.TOKEN_TYPE__FUNCTION);

    /**
     * Declare a tokensArg variable. It is passed as tokens to the parse function
     * that is called recursively
     */
    let tokensArg = null;

    // If there's no delimiter or closedParenthesis or nextFunction
    if (delimiterIndex === -1 && closedParenthesisIndex === -1 && nextFuncIndex === -1) {
      // Set tokensArg to remainingTokens
      tokensArg = remainingTokens;

      // Set counter to the end of the tokens array
      current = tokensPiece.length;
    } else {
      // Get where the expression stops (the first item in the list (lowest index))
      const indexToStop = [delimiterIndex, closedParenthesisIndex, nextFuncIndex].filter(t => t !== -1)
        .sort((a, b) => a - b)[0];

      // Otherwise, slice remainingTokens to indexToStop and set tokensArg value
      tokensArg = remainingTokens.slice(0, indexToStop);

      // Increment counter by the the indexToStop
      current += indexToStop;
    }

    // Recursively parse the expression
    const parseResult = parse(tokensArg, selectedDataExtensions, literalObject);

    // If parse failed
    if (!parseResult.success) return parseResult;

    return { success: true, ast: parseResult.ast };
  };

  /**
   * Handles comparisons ( a > b)
   * @param {array} tokensPiece - A subsection of the tokens array
   * @param {function} walk - Recursively traverses the tokens array and builds the ast
   * @param {boolean} nested - Determines whether the function is nested
   * @param {object} parsedFunction - The function that was previuosly parsed
   * @returns {object} - An object containing status and error or node
   */
  const handleComparison = (tokensPiece, walk, nested, parsedFunction) => {
    let increment = 0;

    let value;

    // If the previously parsed token was a function
    if (parsedFunction) {
      // Set increment value
      increment = 2;

      // Set value properties
      value = {
        leftSide: parsedFunction,
        operator: tokensPiece[current],
      };

      // Handle function
      if (tokensPiece[current + 1].type === ValidationConstants.TOKEN_TYPE__FUNCTION) {
        current += 1;

        // eslint-disable-next-line no-use-before-define
        const handleFunctionResult = handleFunction(tokensPiece, walk, nested);

        // If parsing failed
        if (!handleFunctionResult.success) return handleFunctionResult;

        // Set rightSide
        value.rightSide = handleFunctionResult.node;

        // Set increment value
        increment = 0;
      } else value.rightSide = tokensPiece[current + 1];
    } else {
      // Set increment value
      increment = 3;

      // Set value properties
      value = {
        leftSide: tokensPiece[current],
        operator: tokensPiece[current + 1],
        rightSide: tokensPiece[current + 2],
      };

      // Handle function
      if (tokensPiece[current + 2].type === ValidationConstants.TOKEN_TYPE__FUNCTION) {
        // eslint-disable-next-line no-use-before-define
        const handleFunctionResult = handleFunction(tokensPiece, walk, nested);

        // If parsing failed
        if (!handleFunctionResult.success) return handleFunctionResult;

        value.rightSide = handleFunctionResult.node;
        increment = 0;
      } else value.rightSide = tokensPiece[current + 2];
    }

    if (!value.rightSide) return { success: false, error: 'condition is missing right side' };

    // Increment the counter
    current += increment;
    const node = {
      value,
      type: 'condition',
    };

    return { success: true, node };
  };

  /**
   * Parses a when statement
   * @param {array} tokensPiece - A subsection of the tokens array
   * @param {function} walk - Recursively traverses the tokens array and builds the ast
   * @param {boolean} nested - Determines whether the function is nested
   * @returns {object} - An object containing status and error or value
   */
  const parseWhenStatement = (tokensPiece, walk, nested) => {
    let currentToken = tokensPiece[current];
    const whenStatement = [];

    while (currentToken && currentToken.value !== ValidationConstants.KEYWORD__THEN) {
      // If next token is a comparison operator
      if (tokensPiece[current + 1] && tokensPiece[current + 1].type ===
        ValidationConstants.TOKEN_TYPE__COMPARISON_OPERATOR) {
        // Handle comparison
        const handleComparisonResult = handleComparison(tokensPiece, walk, nested, null);

        // If handling token was not successful
        if (!handleComparisonResult.success) return handleComparisonResult;

        // Push node to whenStatement array
        whenStatement.push(handleComparisonResult.node);
      } else if (currentToken && currentToken.type === ValidationConstants.TOKEN_TYPE__FUNCTION) {
        // Handle function
        // eslint-disable-next-line no-use-before-define
        const handleFunctionResult = handleFunction(tokensPiece, walk, nested);

        // If handling of function fails
        if (!handleFunctionResult.success) return handleFunctionResult;

        // If current token is a comparison operator
        if (tokensPiece[current] && tokensPiece[current].type ===
          ValidationConstants.TOKEN_TYPE__COMPARISON_OPERATOR) {
          // Handle comparison
          const handleComparisonResult = handleComparison(tokensPiece, walk, nested, handleFunctionResult.node);

          // If handling token was not successful
          if (!handleComparisonResult.success) return handleComparisonResult;

          // Push node to whenStatement array
          whenStatement.push(handleComparisonResult.node);
        } else {
          // Push node to whenStatement array
          whenStatement.push(handleFunctionResult.node);
        }
      } else {
        // Push any other token type to the whenStatement array
        whenStatement.push(currentToken);
        current++;
      }
      currentToken = tokensPiece[current];
    }

    return { success: true, value: whenStatement };
  };

  /**
   * Parses a then or an else statement
   * @param {array} tokensPiece - A subsection of the tokens array
   * @param {function} walk - Recursively traverses the tokens array and builds the ast
   * @param {boolean} nested - Determines whether the function is nested
   * @param {string} statementType - The statement to parse
   * @returns {object} - An object containing status and error or value
   */
  const parseThenOrElseStatement = (tokensPiece, walk, nested, statementType) => {
    /**
     * Determines whether the parsing of then statement should be stopped or not
     * @param {object} currentToken - The current token to check
     * @returns {boolean} - Should the parsing stop?
     */
    const thenCondition = currentToken => currentToken.value !== ValidationConstants.KEYWORD__WHEN &&
    currentToken.value !== ValidationConstants.KEYWORD__ELSE;

    let currentToken = tokensPiece[current];
    const statement = [];

    let condition;

    // Build while loop condition
    if (statementType === ValidationConstants.KEYWORD__THEN) {
      condition = thenCondition(currentToken);
    } else {
      condition = true;
    }
    try {
      // While current token is not when and end
      while (currentToken && condition &&
        currentToken.value !== ValidationConstants.KEYWORD__END
      ) {
        // if currentToken is then
        if (currentToken.value === ValidationConstants.KEYWORD__THEN ||
          currentToken.value === ValidationConstants.KEYWORD__ELSE
        ) {
          statement.push(currentToken);
          current++;
        } else if (currentToken && currentToken.type === ValidationConstants.TOKEN_TYPE__FUNCTION) {
          // Handle function
          // eslint-disable-next-line no-use-before-define
          const handleFunctionResult = handleFunction(tokensPiece, walk, nested);

          // If handling of function failed
          if (!handleFunctionResult.success) return handleFunctionResult;

          // Push result to the statement array
          statement.push({ type: ValidationConstants.RESULT_STATEMENT, value: handleFunctionResult.node });
        } else {
          // Push result to the statement array
          statement.push({ type: ValidationConstants.RESULT_STATEMENT, value: currentToken.value });
          current++;
        }
        currentToken = tokensPiece[current];

        // Rebuild while loop condition
        if (statementType === ValidationConstants.KEYWORD__THEN) {
          condition = currentToken && thenCondition(currentToken);
        } else {
          condition = true;
        }
      }
    } catch (error) {
      return { success: false, error };
    }

    return { success: true, value: statement };
  };

  /**
   * Handles the parsing of functions
   * @param {*} tokensPiece - A subsection of the tokens array
   * @param {function} walk - Recursively traverses the tokens array and builds the ast
   * @param {boolean} nested - Determines whether the function is nested
   * @returns {object} - An object containing status and error or value
   */
  const handleFunction = (tokensPiece, walk, nested) => {
    let token = tokensPiece[current];

    /*
     * We create a base node with the type `function`, and we're going
     * to set the name as the current token's value since the next token after
     * the open parenthesis is the name of the function.
     */
    const node = {
      type: ValidationConstants.TOKEN_TYPE__FUNCTION,
      name: token.value,
      params: [],
    };

    // We increment `current` *again* to skip the (open-parenthesis) token.
    token = tokens[++current];

    /*
     * And now we want to loop through each token that will be the `params` of
     * our `function` until we encounter a closing parenthesis.
     *
     * Now this is where recursion comes in. Instead of trying to parse a
     * potentially infinitely nested set of nodes we're going to rely on
     * recursion to resolve things.
     *
     * We're going to rely on the nested `walk` function to increment our
     * `current` variable past any nested `function`.
     */

    // Keep skipping open-brackets
    if (token && token.type === ValidationConstants.TOKEN_TYPE__OPEN_PAREN) token = tokens[++current];

    /*
     * So we create a `while` loop that will continue until it encounters a
     * token with a `type` of `'closed-parenthesis'`
     */
    while (token && token.type !== ValidationConstants.TOKEN_TYPE__CLOSED_PAREN) {
      /*
       * we'll call the `walk` function which will return a `node` and we'll
       * push it into our `node.params`.
       */
      const nodeTraversalResult = walk(true);

      // If traversal failed
      if (!nodeTraversalResult.success) return nodeTraversalResult;

      // Push nodes into params
      node.params.push(nodeTraversalResult.node);
      token = tokens[current];
    }

    /*
     * Increment `current` one last time to skip the closing
     * parenthesis.
     */
    current++;

    // Handle expression if next token is an operator and the function is nested
    if (nested && tokens[current] && tokens[current].type === ValidationConstants.TOKEN_TYPE__OPERATOR) {
      // Handle expression
      const handleExpressionResult = handleExpression(tokens, node);

      // If handling expression failed
      if (!handleExpressionResult.success) return handleExpressionResult;

      return { success: true, node: handleExpressionResult.ast };
    }

    // And return the node and status.
    return { success: true, node };
  };

  try {
    /**
     * @param {string} fieldInput - The field that was inputed
     * @returns {object} - An object containing status and error or fieldType
     */
    const getFieldType = (fieldInput) => {
      // Get data extension name and replace all non-breaking spaces with normal spaces
      const dataExtensionName = Util.replaceEnterKeysAndNonBreakingSpaces(
        fieldInput.substring(0, fieldInput.indexOf('"')),
      );

      // Get field name and replace all non-breaking spaces with normal spaces
      const fieldName = Util.replaceEnterKeysAndNonBreakingSpaces(fieldInput.substring(fieldInput.indexOf('.') + 2));

      // Find data extension
      const dataExtension = selectedDataExtensions.find(
        de => de.deAlias.toLowerCase() === dataExtensionName.toLowerCase(),
      );

      // If data extension could not be found
      if (!dataExtension) return { success: false, error: `Unknown data extension '${dataExtensionName}'` };

      // Find field
      const field = dataExtension.fields.find(f => f.Name.toLowerCase() === fieldName.toLowerCase());

      // If field could not be found
      if (!field) return { success: false, error: `Unknown field '${fieldName}'` };

      /**
       * Return fieldType as string if field's type is one of text, email, locale or phone,
       * otherwise, return the original field's type
       */
      const fieldType = (field.FieldType === Constants.FILTERLINE__FIELDTYPE__TEXT ||
        field.FieldType === Constants.FILTERLINE__FIELDTYPE__EMAILADDRESS ||
        field.FieldType === Constants.FILTERLINE__FIELDTYPE__LOCALE ||
        field.FieldType === Constants.FILTERLINE__FIELDTYPE__PHONE
      ) ?
        ValidationConstants.TOKEN_TYPE__STRING :
        field.FieldType;

      return { success: true, fieldType };
    };

    /**
     * Recursively traverses the tokens array and builds the ast
     * @param {boolean} nested - Determines whether the function is nested
     * @returns {object} - The node object
     */
    const walk = (nested) => {
      // Inside the walk function we start by grabbing the `current` token.
      let token = tokens[current];

      // Skip current token if it's a delimiter
      if (token && token.type === ValidationConstants.TOKEN_TYPE__DELIMITER) token = tokens[++current];

      // Deal with open bracket
      const preceedingToken = token[current - 1];

      if (token && token.type === ValidationConstants.TOKEN_TYPE__OPEN_PAREN) {
        // If the preceding token is a function, then just skip the open bracket
        if (preceedingToken && preceedingToken.type === ValidationConstants.TOKEN_TYPE__FUNCTION) {
          token = tokens[++current];
        } else {
          // If the previous token is not a function, that means this is an expression
          current++;

          // Handle expression
          const handleExpressionResult = handleExpression(tokens);

          // If handling expression failed
          if (!handleExpressionResult.success) return handleExpressionResult;

          return { success: true, node: handleExpressionResult.ast };
        }
      }

      // Keep skipping the current token if it's a closed bracket
      while (!!token && token.type === ValidationConstants.TOKEN_TYPE__CLOSED_PAREN) {
        token = tokens[++current];
      }
      if (!token) return { success: true };

      /*
       * We're going to split each type of token off into a different code path,
       * starting off with `number` or `decimal` tokens.
       */
      if (token && (token.type === ValidationConstants.TOKEN_TYPE__NUMBER ||
        token.type === ValidationConstants.TOKEN_TYPE__DECIMAL
      )) {
        const numberLiteralObject = {
          type: token.type,
          value: token.value,
        };

        // Handle expression if next token is an operator and the function is nested
        if (nested && tokens[current + 1] && tokens[current + 1].type === ValidationConstants.TOKEN_TYPE__OPERATOR) {
          current++;
          // Handle expression
          const handleExpressionResult = handleExpression(tokens, numberLiteralObject);

          // If handling expression failed
          if (!handleExpressionResult.success) return handleExpressionResult;

          return { success: true, node: handleExpressionResult.ast };
        }
        if (tokens[current + 1] && tokens[current + 1].type === ValidationConstants.TOKEN_TYPE__COMPARISON_OPERATOR) {
          // Handle comparison
          const handleComparisonResult = handleComparison(tokens, walk, nested, null);

          // If handling comparison failed
          if (!handleComparisonResult.success) return handleComparisonResult;

          return { success: true, node: handleComparisonResult.node };
        }
        // If we have one, we'll increment `current`.
        current++;

        // Return the node
        return { success: true, node: numberLiteralObject };
      }

      /*
       * If we have a string or field we will do the same as number and create a
       * `string` or `field` node.
       */
      if (token && (token.type === ValidationConstants.TOKEN_TYPE__STRING ||
        token.type === ValidationConstants.TOKEN_TYPE__FIELD)) {
        const stringLiteralObject = {
          type: token.type,
          value: token.value,
        };

        // Check if string is date
        if (regexForDatesList.some(regexForDate => regexForDate.test(token.value))) {
          // Set param type to date if the token matches any of the date regular expressions
          stringLiteralObject.type = ValidationConstants.PARAM_TYPE__DATE;
        }

        // Get fieldType and populate the stringLiteralObject object if token type is field
        if (token && token.type === ValidationConstants.TOKEN_TYPE__FIELD) {
          const obtainFieldType = getFieldType(token.value);

          // If DE/field could not be found
          if (!obtainFieldType.success) return obtainFieldType;

          stringLiteralObject.fieldType = obtainFieldType.fieldType.toLowerCase();
        }

        // Handle expression if next token is an operator and the function is nested
        if (nested && tokens[current + 1] && tokens[current + 1].type === ValidationConstants.TOKEN_TYPE__OPERATOR) {
          // Increment the counter
          current++;

          // Handle expression
          const handleExpressionResult = handleExpression(tokens, stringLiteralObject);

          // If handling expression failed
          if (!handleExpressionResult.success) return handleExpressionResult;

          return { success: true, node: handleExpressionResult.ast };
        }

        // If the next token is a comparison operator
        if (tokens[current + 1] && tokens[current + 1].type === ValidationConstants.TOKEN_TYPE__COMPARISON_OPERATOR) {
          // Handle comparison
          const handleComparisonResult = handleComparison(tokens, walk, nested, null);

          // If handling comparison failed
          if (!handleComparisonResult.success) return handleComparisonResult;

          return { success: true, node: handleComparisonResult.node };
        }

        // check if these objects exist to avoid errors
        if (token && tokens[current] && tokens[current].type && tokens[current + 1] && tokens[current + 1].type) {
          // if we only have one expression with a string and the next expression is not a string
          if ((token && token.type === ValidationConstants.TOKEN_TYPE__STRING &&
            tokens.length === 1) || tokens[current + 1].type !== ValidationConstants.TOKEN_TYPE__STRING) {
            // Increment the counter
            current++;

            // Return the node and status
            return { success: true, node: stringLiteralObject };
          }

          // Handle expression if next token is a string
          if (nested && tokens[current].type === ValidationConstants.TOKEN_TYPE__STRING &&
          (tokens[current + 1].type === ValidationConstants.TOKEN_TYPE__STRING ||
            tokens[current - 1].type === ValidationConstants.TOKEN_TYPE__STRING)) {
            // Declare variables for string expression
            let signedNumber;

            let nodeForString;

            /*
             * Make a loop if it encounters a string. That means we treat expressions as one string,
             * if it is not separated by a comma.
             */
            while (tokens[current] && tokens[current].type === ValidationConstants.TOKEN_TYPE__STRING &&
            stringLiteralObject.type !== ValidationConstants.PARAM_TYPE__DATE) {
              // Treat the expression as one string and add current string to signedNumber variable
              if (signedNumber) {
                signedNumber += tokens[current].value;
              } else {
                signedNumber = tokens[current].value;
              }

              // Create nodeForString for current token value
              nodeForString = {
                type: tokens[current].type,
                value: signedNumber,
              };

              // Increment the counter
              current++;
            }

            if (nodeForString) {
              // Increment the counter
              current++;

              // Handle expression, parse the tokenized input and convert it into an AST
              const handleExpressionResult = handleExpression(tokens, nodeForString);

              // If handling expression failed, return the nodeForString
              if (!handleExpressionResult.success) {
                return { success: true, node: nodeForString };
              }

              return { success: true, node: handleExpressionResult.ast };
            }
          }
        }

        current++;

        // Return the node and status.
        return { success: true, node: stringLiteralObject };
      }

      // Deal with an operator
      if (token && token.type === ValidationConstants.TOKEN_TYPE__OPERATOR) {
        // Check for scenarios where we can have signed numbers(+7, -3)
        if ((current === 0 || tokens[current - 1].type === ValidationConstants.TOKEN_TYPE__OPERATOR ||
          tokens[current - 1].type === ValidationConstants.TOKEN_TYPE__OPEN_PAREN) && tokens[current + 1] &&
          (tokens[current + 1].type === ValidationConstants.TOKEN_TYPE__NUMBER ||
            tokens[current + 1].type === ValidationConstants.TOKEN_TYPE__DECIMAL) &&
          token.value !== '/' && token.value !== '*'
        ) {
          // Build the signed number
          const signedNumber = token.value + tokens[current + 1].value;
          const node = {
            type: tokens[current + 1].type,
            value: signedNumber,
          };
          // Increment the counter

          current += 2;

          return { success: true, node };
        }
        current++;
        const node = {
          type: ValidationConstants.TOKEN_TYPE__OPERATOR,
          value: token.value,
        };

        return { success: true, node };
      }

      // Deal with keyword
      if (token && token.type === ValidationConstants.TOKEN_TYPE__KEYWORD) {
        // If token is case
        if (token.value === ValidationConstants.KEYWORD__CASE) {
          const value = [];

          // While token is not end
          while (token && token.value !== ValidationConstants.KEYWORD__END) {
            switch (token.value) {
              case ValidationConstants.KEYWORD__CASE:
                value.push({ type: ValidationConstants.CASE_KEYWORD, value: token.value });
                current++;
                break;
              case ValidationConstants.KEYWORD__WHEN: {
                // Parse when statement
                const parsedWhenStatement = parseWhenStatement(tokens, walk, nested);

                // If parsing fails
                if (!parsedWhenStatement.success) return parsedWhenStatement;

                // Push when-statement to the value array
                value.push({ type: ValidationConstants.WHEN_STATEMENT, value: parsedWhenStatement.value });
                break;
              }
              case ValidationConstants.KEYWORD__THEN: {
                // Parse then statement
                const parsedThenStatement = parseThenOrElseStatement(
                  tokens,
                  walk,
                  nested,
                  ValidationConstants.KEYWORD__THEN,
                );

                // If parsing fails
                if (!parsedThenStatement.success) return parsedThenStatement;

                // Push then-statement to the value array
                value.push({ type: ValidationConstants.THEN_STATEMENT, value: parsedThenStatement.value });
                break;
              }
              case ValidationConstants.KEYWORD__ELSE: {
                // Parse else statement
                const parsedElseStatement = parseThenOrElseStatement(
                  tokens,
                  walk,
                  nested,
                  ValidationConstants.KEYWORD__ELSE,
                );

                // If parsing fails
                if (!parsedElseStatement.success) return parsedElseStatement;

                // Push else-statement to the value array
                value.push({ type: ValidationConstants.ELSE_STATEMENT, value: parsedElseStatement.value });
                break;
              }
              default:
                value.push(token);
                current++;
                break;
            }
            token = tokens[current];
          }

          // If token is falsy (We've exhausted the tokens array)
          if (!token) throw new Error('CASE-WHEN statement incomplete');

          // Add the end token
          value.push({ type: ValidationConstants.END_KEYWORD, value: token.value });
          token = tokens[current++];

          const node = {
            type: ValidationConstants.CASE_WHEN,
            value,
          };

          return { success: true, node };
        }
        current++;
        const node = {
          type: token.type,
          value: token.value,
        };

        return { success: true, node };
      }

      /*
       * Next we're going to look for functions. We start this off when we
       * encounter an token of type function.
       */
      if (token && token.type === ValidationConstants.TOKEN_TYPE__FUNCTION) {
        const handleFunctionResult = handleFunction(tokens, walk, nested);

        if (!handleFunctionResult.success) return handleFunctionResult;

        return { success: true, node: handleFunctionResult.node };
      }

      /*
       * Again, if we haven't recognized the token type by now we're going to
       * throw an error.
       */
      return { success: false, error: `Unknown token ${token.value}` };
    };

    /*
     * Now, we're going to create our AST which will have a root which is a
     * `Program` node.
     */
    const ast = {
      type: ValidationConstants.ROOT_NODE__PROGRAM,
      body: [],
    };

    // Push initNode into the body array if it exists
    if (initNode) ast.body.push(initNode);

    /*
     * And we're going to kick-start our `walk` function, pushing nodes to our
     * `ast.body` array.
     *
     * The reason we are doing this inside a loop is because our program can have
     * functions after one another instead of being nested.
     */

    while (current < tokens.length) {
      // Traverse the tokens array
      const nodeTraversalResult = walk();

      // If traversal failed
      if (!nodeTraversalResult.success) return nodeTraversalResult;

      // Push into the body array if node exists
      if (nodeTraversalResult.node) ast.body.push(nodeTraversalResult.node);
    }

    // At the end of our parser we'll return the AST.
    return { success: true, ast };
  } catch (e) {
    // Return an object with status and error message
    return { success: false, error: e?.message || 'An unknown error occurred' };
  }
};

export default parse;
