import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Filters from '../../../../Filters/Filters';
import Constants from '../../../../../../constants/constants';
import ModalTemplate from '../../../../../shared_v2/ModalTemplate/ModalTemplate';
import filtersUtil from '../../../../../../utils/filters/filtersUtil';

const FiltersModal = ({
  show,
  handleSetSelectionState,
  selectedDataExtensions,
  aggregationFilters,
  getDataExtensionOrDataViewFields,
  compareSelectedDataExtensions,
  DEBorderMouseOver,
  filterBorderMouseOver,
  closeFiltersModal,
  handleFiltersSave,
  pickLists,
  handlePickListOptions,
  handleRemoveFilterLine,
  handleSetAggregationFilterState,
  applyTimezoneSettingsToAllDateFields,
  timezoneSettingsForAllDateFields,
  handleSetTimezoneToAllDateFields,
}) => {
  /**
   * Save filters and close subquery modal
   * @returns {void}
   */
  const save = () => {
    // parse the filters
    const filters = JSON.parse(JSON.stringify(aggregationFilters));

    // pass filters to aggregation modal for saving
    handleSetAggregationFilterState({
      aggregationValuesFilters: filters,
    });

    if (applyTimezoneSettingsToAllDateFields) {
      // Find first available date filter
      const filtersParam = Array.isArray(filters) ? filters : [filters];

      const firstAvailableFilter = filtersUtil.findDateTypeFilter(filtersParam);

      const {
        convertToTimezone, convertTimezone, convertFromTimezone,
      } = firstAvailableFilter;

      handleSetTimezoneToAllDateFields(convertTimezone, convertToTimezone, convertFromTimezone);
    }

    // close filters modal
    closeFiltersModal();

    return null;
  };

  /**
   * Renders class name for content of modal
   * @param {boolean} showClass - indicates if subquery modal will be shown or not
   * @returns {string} className
   */
  const subqueryDialogClassName = showClass => classNames(
    'slds-p-around_medium subquery-dialog',
    showClass ? 'show' : '',
  );

  return (
    <div>
      <ModalTemplate
        id="aggregation-subquery-modal-dialog"
        className="subquery-modal slds-modal_large"
        headerId="aggregation-filters-title"
        headerTitle="Filters"
        contentClassName={subqueryDialogClassName(show)}
        cancelButtonId="aggregation-filters-cancel"
        handleCancel={closeFiltersModal}
        saveButtonId="aggregation-filters-save"
        handleSave={save}
        backdropHidden
      >
        <Filters
          hideCollectionAlias={false}
          showInResultsOption={false}
          selectedDataExtensions={selectedDataExtensions}
          compareSelectedDataExtensions={compareSelectedDataExtensions}
          handleSetSelectionState={handleSetSelectionState}
          getDataExtensionOrDataViewFields={getDataExtensionOrDataViewFields}
          DEBorderMouseOver={DEBorderMouseOver}
          filterBorderMouseOver={filterBorderMouseOver}
          handleFiltersSave={handleFiltersSave}
          selectedFilters={aggregationFilters || []}
          pickLists={pickLists}
          isFromSelection
          handlePickListOptions={handlePickListOptions}
          handleRemoveFilterLine={handleRemoveFilterLine}
          filterType={Constants.FILTER_TYPE__CUSTOM_VALUES}
          applyTimezoneSettingsToAllDateFields={applyTimezoneSettingsToAllDateFields}
          timezoneSettingsForAllDateFields={timezoneSettingsForAllDateFields}
          handleSetTimezoneToAllDateFields={handleSetTimezoneToAllDateFields}
        />
      </ModalTemplate>
    </div>
  );
};

FiltersModal.propTypes = {
  /**
   * It stores the value of subquery modal if it will be shown or not
   */
  show: PropTypes.bool.isRequired,
  /**
   * It helps to set the Selection`s state
   * It will be passed from Selection.js
   */
  handleSetSelectionState: PropTypes.func.isRequired,
  /**
   * It keeps the selected data extension for the subquery
   */
  selectedDataExtensions: PropTypes.instanceOf(Array),
  /**
   * It helps to retrieve fields of a data extension or data view from SFMC
   * It will be passed from Selection.js
   */
  getDataExtensionOrDataViewFields: PropTypes.func.isRequired,
  /**
   * It keeps the all selected data extensions if the subquery modal is opened
   */
  compareSelectedDataExtensions: PropTypes.instanceOf(Array),
  /**
   * Keeps track whether Available DE are dragged
   */
  DEBorderMouseOver: PropTypes.bool.isRequired,
  /**
   * Keeps track whether Available Fields are dragged
   */
  filterBorderMouseOver: PropTypes.bool.isRequired,
  /**
   * It helps to save the selected filters for the aggregation
   * it will be passed from Selection.js
   */
  aggregationFilters: PropTypes.instanceOf(Object),
  /**
   * It helps to close the aggregation filter modal
   * It will be passed from CustomValues.js
   */
  closeFiltersModal: PropTypes.func.isRequired,
  /**
   * It helps to save the selected filters for the aggregation
   * it will be passed from Selection.js
   */
  handleFiltersSave: PropTypes.func.isRequired,
  /**
   * Responsible for adding/deleting fields Object IDs when searching picklist for the options
   * it will be passed from Selection.js
   */
  handlePickListOptions: PropTypes.func.isRequired,
  /**
   * Keeps searched picklist
   * It will be passed from Selection.js
   */
  pickLists: PropTypes.instanceOf(Array).isRequired,
  /**
   * It Removes a given filterLine
   * It is passed from Selection.js
   */
  handleRemoveFilterLine: PropTypes.func,
  /**
   * Function to set filters in Aggregation state
   * It is Passed from AggregationModal.js
   */
  handleSetAggregationFilterState: PropTypes.func.isRequired,
  /**
   * Indicates whether timezone settings are applied to all date filters
   */
  applyTimezoneSettingsToAllDateFields: PropTypes.bool,
  /**
   * Handles the setting of timezone settings to all date filters
   */
  handleSetTimezoneToAllDateFields: PropTypes.func,
  /**
   * An object containing timezone details
   */
  timezoneSettingsForAllDateFields: PropTypes.instanceOf(Object),
};

export default React.memo(FiltersModal);
