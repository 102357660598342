import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { animated, useSpring } from '@react-spring/web';
import { TextArea } from 'semantic-ui-react';

import ModalTemplate from '../../shared/ModalTemplate/ModalTemplate';
import Button from '../../shared/Button/Button';
import DeedeeAIAPI from '../../../api/deedeeAI';
import './styles.scss';
import Util from '../../../util';

const FeedbackModal = ({
  isOpen,
  deedeeAIRequestFeedback,
  handleSetAppState,
  setOpenDeedeeAIModal,
}) => {
  const [feedbackState, setFeedbackState] = useState({
    rating: '',
    comment: '',
  });
  const [submitFeedback, setSubmitFeedback] = useState(false);
  const [commentSubmitted, setCommentSubmitted] = useState(false); // Add this line

  useEffect(() => {
    if (
      deedeeAIRequestFeedback?.feedback &&
      deedeeAIRequestFeedback?.feedback?.rating
    ) {
      setFeedbackState(deedeeAIRequestFeedback.feedback);
      setSubmitFeedback(true);
    }

    if (
      deedeeAIRequestFeedback?.feedback &&
      deedeeAIRequestFeedback?.feedback?.comment
    ) {
      setCommentSubmitted(true);
    }
  }, [deedeeAIRequestFeedback]);

  useEffect(() => {
    if (feedbackState.rating) {
      setSubmitFeedback(true);
    }
  }, [feedbackState]);

  /**
   * Handles closing of the modal. Posts feedback to DeedeeAIAPI and sets app state.
   * @returns {void}
   */
  const handleCloseModal = async () => {
    // Store feedback in DeedeeAIRequest state before closing modal
    const feedbackRes = await DeedeeAIAPI.postDeedeeAIFeedback(
      feedbackState,
      deedeeAIRequestFeedback.deedeeAIRequestId,
    );

    const comment = feedbackRes && feedbackRes.data && feedbackRes.data.comment;
    const rating = feedbackRes && feedbackRes.data && feedbackRes.data.rating;

    handleSetAppState({
      deedeeAIRequestFeedback: {
        ...deedeeAIRequestFeedback,
        feedback: { comment, rating },
      },
      showDeedeeAIFeedbackModal: false,
    });
    setCommentSubmitted(true);
  };

  /**
   * Handles feedback submission. Sets the feedback state.
   * @param {string} rating Rating provided by user.
   * @param {string} comment Comment provided by user.
   * @returns {void}
   */
  const handleFeedback = async (rating, comment) => {
    setFeedbackState({ rating, comment });
  };

  /**
   * Handles opening of the DeedeeAI modal when inside a feedback modal.
   * @returns {void}
   */
  const handleNewDeedeeAIRequest = () => {
    handleSetAppState({
      showDeedeeAIFeedbackModal: false,
    });
    // Store feedback in DeedeeAIRequest state before closing modal
    handleCloseModal();

    // Open DeedeeAI modal
    setOpenDeedeeAIModal(true);
  };

  // Animation setup
  const textAnimation = useSpring({
    from: { opacity: 0, transform: 'translate3d(0,-40px,0)' },
    to: {
      opacity: isOpen ? 1 : 0,
      transform: isOpen ? 'translate3d(0,0px,0)' : 'translate3d(0,-40px,0)',
    },
  });

  // Find if there are any processing failures
  const processingFailure = Object.values(
    deedeeAIRequestFeedback.processingLog,
  ).some(status => status !== 'success');

  return (
    <ModalTemplate
      id="deedee-ai-feedback-modal"
      headerId="modal-heading-01"
      contentClassName="slds-p-around_medium scope-de-body"
      handleCloseModal={handleCloseModal}
      cancelButtonId="edit-filters-dialog-cancel"
      headerTitle="Deedee AI"
      backdropHidden
      showCancelIcon
    >
      <div className="deedeeai_feedback__container">
        {!submitFeedback && (
          <div className="deedeeai_feedback__logs">
            {processingFailure && (
              <p className="feedback-processing-log">
                I’m sorry, I was unable to create this part of your selection:
                {Object.entries(deedeeAIRequestFeedback?.processingLog).map(
                  ([key, value]) => {
                    if (
                      typeof value === 'object' &&
                      value.result === 'failed'
                    ) {
                      return (
                        <span key={key} className="log-item">
                          {`- ${Util.getProcessingLogFailureDescription(key)}`}
                        </span>
                      );
                    }

                    return null;
                  },
                )}
              </p>
            )}
          </div>
        )}
        {!feedbackState.rating && (
          <animated.p
            style={textAnimation}
            className="deedeeai_feedback__title"
          >
            {Util.formatDeedeeAIRequestDescription(
              deedeeAIRequestFeedback?.openAIDescription,
            )}
          </animated.p>
        )}

        {submitFeedback && feedbackState.rating === 'positive' && (
          <div className="feedback-received-text">
            <span className="thanks-message">
              Great, thanks for your feedback!
            </span>
            <p className="rating-icon">
              <svg className="slds-button__icon" aria-hidden="true">
                <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#like" />
              </svg>
            </p>
            <Button
              className="slds-button slds-button_brand new-request-btn"
              type="button"
              onClick={handleNewDeedeeAIRequest}
            >
              Create new selection
            </Button>
          </div>
        )}
        {submitFeedback &&
          feedbackState.rating === 'negative' &&
          commentSubmitted && ( // Update this line
            <div className="feedback-received-text">
              <span className="thanks-message">Thanks for your feedback!</span>
              <p className="rating-icon">
                <svg className="slds-button__icon" aria-hidden="true">
                  <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#dislike" />
                </svg>
              </p>
              <Button
                className="slds-button slds-button_brand new-request-btn"
                type="button"
                onClick={handleNewDeedeeAIRequest}
              >
                Create new selection
              </Button>
            </div>
        )}

        {!submitFeedback && (
          <div className="submit-rating-container">
            <p className="rating-title">Rate the created selection</p>
            <div className="rating-buttons">
              <Button
                className="rating-button positive"
                onClick={() => handleFeedback('positive', '')}
              >
                <svg className="slds-button__icon" aria-hidden="true">
                  <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#like" />
                </svg>
              </Button>
              <Button
                className="rating-button negative"
                onClick={() => handleFeedback('negative', '')}
              >
                <svg className="slds-button__icon" aria-hidden="true">
                  <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#dislike" />
                </svg>
              </Button>
            </div>
          </div>
        )}

        {feedbackState.rating === 'negative' && !commentSubmitted && (
          <div className="neg-feedback-container">
            <p className="feedback-title">
              I’m sorry, I’m still young and learning.
            </p>
            <p className="feedback-description">What did I mess up?</p>
            <TextArea
              className="feedback-textarea"
              onChange={event => setFeedbackState({
                ...feedbackState,
                comment: event.target.value,
              })}
            />
            <Button
              type="button"
              className="feedback-submit"
              onClick={() => setCommentSubmitted(true)}
            >
              Submit Feedback
            </Button>
          </div>
        )}
      </div>
    </ModalTemplate>
  );
};

FeedbackModal.propTypes = {
  /** Flag indicating if the feedback modal is open. */
  isOpen: PropTypes.bool.isRequired,

  /** Object containing AI request feedback. */
  deedeeAIRequestFeedback: PropTypes.object.isRequired,

  /** Function to set the app state. */
  handleSetAppState: PropTypes.func.isRequired,

  /** Function to set the deedeeai chat modal state. */
  setOpenDeedeeAIModal: PropTypes.func.isRequired,
};

export default FeedbackModal;
