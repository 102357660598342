import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Filters from '../../../../Filters/Filters';
import Constants from '../../../../../../constants/constants';
import ModalTemplate from '../../../../../shared_v2/ModalTemplate/ModalTemplate';
import SwalUtil from '../../../../../../utils/swal/swalUtil';
import filtersUtil from '../../../../../../utils/filters/filtersUtil';

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state

  return () => setValue(value + 1); // update the state to force render
}

const AdvancedDedupModal = ({
  show,
  handleSetSelectionState,
  selectedDataExtensions,
  getDataExtensionOrDataViewFields,
  compareSelectedDataExtensions,
  DEBorderMouseOver,
  filterBorderMouseOver,
  closeCriteriaModal,
  advancedDeduplicationRules,
  handleAdvancedDedupFiltersSave,
  previousAdvancedDeduplicationRules,
  advancedDedupFilterSaveIndex,
  pickLists,
  handlePickListOptions,
  handleRemoveFilterLine,
  applyTimezoneSettingsToAllDateFields,
  handleSetTimezoneToAllDateFields,
  timezoneSettingsForAllDateFields,
}) => {
  const getSelectedFilters = () => advancedDeduplicationRules[advancedDedupFilterSaveIndex];
  const forceUpdate = useForceUpdate();
  /**
   * Save filters and close modal
   * @returns {object|null} - an object or null
   */
  const save = () => {
    const filters = JSON.parse(JSON.stringify(getSelectedFilters() || []));

    if (filters && (filters.filters === undefined || (filters.filters && filters.filters.length === 0))) {
      return SwalUtil.fire({
        title: 'Filter missing',
        message: 'Please add at least one filter.',
      });
    }

    if (applyTimezoneSettingsToAllDateFields) {
      // Find first available date filter
      const filtersParam = Array.isArray(filters) ? filters : [filters];

      const firstAvailableFilter = filtersUtil.findDateTypeFilter(filtersParam);

      const {
        convertToTimezone, convertTimezone, convertFromTimezone,
      } = firstAvailableFilter;

      handleSetTimezoneToAllDateFields(convertTimezone, convertToTimezone, convertFromTimezone);
    }

    closeCriteriaModal();

    return null;
  };

  return (
    <div style={{ display: show ? 'block' : 'none' }}>
      <ModalTemplate
        id="subquery-modal-dialog"
        className="subquery-modal slds-modal_large"
        headerId="criteria-filters-title"
        headerTitle="Criteria Filters"
        contentClassName="slds-p-around_medium subquery-dialog"
        contentId="modal-content-id-1"
        cancelButtonId="criteria-filters-cancel"
        handleCancel={() => {
          handleSetSelectionState({
            advancedDeduplicationRules: JSON.parse(JSON.stringify(previousAdvancedDeduplicationRules)),
            previousAdvancedDeduplicationRules: [],
          });
          closeCriteriaModal();
        }}
        handleSave={save}
        saveButtonId="criteria-filters-save"
      >
        <Filters
          showInResultsOption={false}
          hideCollectionAlias
          selectedDataExtensions={selectedDataExtensions}
          compareSelectedDataExtensions={compareSelectedDataExtensions}
          handleSetSelectionState={handleSetSelectionState}
          getDataExtensionOrDataViewFields={getDataExtensionOrDataViewFields}
          DEBorderMouseOver={DEBorderMouseOver}
          isFromSelection
          filterBorderMouseOver={filterBorderMouseOver}
          handleFiltersSave={handleAdvancedDedupFiltersSave}
          selectedFilters={getSelectedFilters() || []}
          pickLists={pickLists}
          handlePickListOptions={handlePickListOptions}
          handleRemoveFilterLine={async (...args) => {
          // Remove the filterLine
            await handleRemoveFilterLine(...args);

            // Force update the component after removing a filter
            forceUpdate();
          }}
          filterType={Constants.FILTER_TYPE__PRIO_DEDUP}
          applyTimezoneSettingsToAllDateFields={applyTimezoneSettingsToAllDateFields}
          timezoneSettingsForAllDateFields={timezoneSettingsForAllDateFields}
          handleSetTimezoneToAllDateFields={handleSetTimezoneToAllDateFields}
        />
      </ModalTemplate>
    </div>
  );
};

AdvancedDedupModal.propTypes = {
  /**
   * It stores the value of subquery modal if it will be shown or not
   */
  show: PropTypes.bool.isRequired,
  /**
   * It helps to set the Selection`s state
   * It will be passed from Selection.js
   */
  handleSetSelectionState: PropTypes.func.isRequired,
  /**
   * It keeps the selected data extension for the subquery
   */
  selectedDataExtensions: PropTypes.instanceOf(Array),
  /**
   * It helps to retrieve fields of a data extension or data view from SFMC
   * It will be passed from Selection.js
   */
  getDataExtensionOrDataViewFields: PropTypes.func.isRequired,
  /**
   * It keeps the all selected data extensions if the subquery modal is opened
   */
  compareSelectedDataExtensions: PropTypes.instanceOf(Array),
  /**
   * Keeps track whether Available DE are dragged
   */
  DEBorderMouseOver: PropTypes.bool.isRequired,
  /**
   * Keeps track whether Available Fields are dragged
   */
  filterBorderMouseOver: PropTypes.bool.isRequired,
  /**
   * It helps to close the criteria modal
   * It will be passed from CustomValues.js
   */
  closeCriteriaModal: PropTypes.func.isRequired,
  /**
   * Filters Rules for the advanced deduplication
   */
  advancedDeduplicationRules: PropTypes.instanceOf(Array).isRequired,
  /**
   * saves the filters for the advanced deduplication
   */
  handleAdvancedDedupFiltersSave: PropTypes.func.isRequired,
  /**
   * gets the index for the actual rule being worked on
   */
  advancedDedupFilterSaveIndex: PropTypes.number.isRequired,
  /**
   * holds the state of the selected filter in case the cancel button is working
   */
  previousAdvancedDeduplicationRules: PropTypes.instanceOf(Array),
  /**
   * Responsible for adding/deleting fields Object IDs when searching picklist for the options
   * it will be passed from Selection.js
   */
  handlePickListOptions: PropTypes.func.isRequired,
  /**
   * Keeps searched picklist
   * It will be passed from Selection.js
   */
  pickLists: PropTypes.instanceOf(Array).isRequired,
  /**
   * It Removes a given filterLine
   * It is passed from Selection.js
   */
  handleRemoveFilterLine: PropTypes.func,
  /**
   * Indicates whether timezone settings are applied to all date filters
   */
  applyTimezoneSettingsToAllDateFields: PropTypes.bool,
  /**
   * Handles the setting of timezone settings to all date filters
   */
  handleSetTimezoneToAllDateFields: PropTypes.func,
  /**
   * An object containing timezone details
   */
  timezoneSettingsForAllDateFields: PropTypes.instanceOf(Object),
};

export default AdvancedDedupModal;
