import {
  SET_ALL_FEATURE_ADVERTS,
} from '../../actions/types';

const initialState = {
  featureAdverts: [],
};

/**
 * Function that takes an action and the previous state of the application and returns the new state
 * @param {object} state - contain initial and final state of data
 * @param {object} action - return the action object
 * @returns {object} updated state
 */
const featureAdvertsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_FEATURE_ADVERTS:

      return {
        ...state,
        featureAdverts: action.payload,
      };

    default:
      return state;
  }
};

export default featureAdvertsReducer;
