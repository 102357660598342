import React from 'react';
import {
  Dropdown,
} from '@salesforce/design-system-react';

import './styles.scss';
import Button from '../Button/Button';

const ActionButton = ({
  rowIndex,
  actions,
}) => {
  const actionOptions = actions.map(action => ({
    label: action.title,
    className: 'action-custom-style',
    value: action.title,
    leftIcon: {
      category: action.iconCategory,
      name: action.iconName,
    },
  }));

  const isSingle = actions.length === 1;

  const handleOnSelect = (_, data) => {
    actions.find(a => a.title === data.option.label).actionHandler();
  };

  if (isSingle) {
    return (
      <Button
        className="slds-button slds-button_icon slds-button_icon-border-filled slds-button_icon-small"
        title={actions[0].title}
        onClick={actions[0].actionHandler}
      >
        <svg className="slds-button__icon" aria-hidden="true">
          <use xlinkHref={`/assets/icons/utility-sprite/svg/symbols.svg#${actions[0].iconName}`} />
        </svg>
      </Button>
    );
  }

  return (
        <Dropdown
        containerClassName="action-button-container"
        id={rowIndex}
        assistiveText={{ icon: 'More Options' }}
        buttonVariant="icon"
        iconCategory="utility"
        width="xx-small"
        iconName="threedots_vertical"
        iconVariant="border-filled"
        length={1}
        onSelect={handleOnSelect}
        options={actionOptions}
      />
  );
};

export default ActionButton;
