import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

import Constants from '../../../../../../constants/constants';
import RadioButton from '../../../../../shared_v2/RadioButton/RadioButton';

class DateDifferenceField extends Component {
  constructor(props) {
    super(props);
    const { fieldId, dateDifferenceData } = props;
    const { format } = dateDifferenceData[fieldId];

    this.state = {
      dataExtension: null,
      dateFields: [],
      groupFormat: format === Constants.CUSTOM_VALUES__FORMULA_FORMAT__FIELD ?
        Constants.CUSTOM_VALUES__FORMULA_FORMAT__FIELD :
        Constants.CUSTOM_VALUES__FORMULA_TYPE__TIMESTAMP,
    };
  }

  // Called immediately after a component is mounted.
  componentDidMount() {
    const {
      dateDifferenceData, fieldId,
    } = this.props;
    const { [fieldId]: field } = dateDifferenceData;
    const { format } = field;

    // set default values for fields in dateDifferenceData
    this.updateFieldsForDateDifferenceData(format, false);
  }

  /**
   * Function to update fields in dateDifferenceData
   * @param {string} value - value of the selected format
   * @param {boolean} switchFormat - whether the format was switched or the dateDifferenceModal is loading
   * for the first time
   * @returns {void} the updated dateDifferenceData state
   */
  updateFieldsForDateDifferenceData = async (value, switchFormat) => {
    const {
      handleSetCustomValuesState,
      dateDifferenceData,
      selectedDataExtensions,
      fieldId,
      getDataExtensionOrDataViewFields,
    } = this.props;

    let { [fieldId]: field } = dateDifferenceData;

    let { collectionAlias, fieldObjectId } = field;
    const { format } = field;

    // if format is set to a field and there is selected Data Extension
    if (selectedDataExtensions.length > 0 && value === Constants.CUSTOM_VALUES__FORMULA_FORMAT__FIELD) {
      if (!collectionAlias) {
      // set default data extension object ID
        collectionAlias = selectedDataExtensions[0].deAlias;
      }

      // set default data extension
      let dataExtension = selectedDataExtensions.filter(de => de.deAlias === collectionAlias)[0];

      if (!dataExtension) {
        // if there is no data extension, then set fieldObjectId to null
        [dataExtension] = selectedDataExtensions;
        fieldObjectId = null;
      }

      const dataExtensionFields = dataExtension?.fields ?
        dataExtension.fields :
        (await getDataExtensionOrDataViewFields(dataExtension));

      // in data extension find date fields and change the state
      const dateFields = dataExtensionFields.filter(de => de.FieldType === Constants.FILTERLINE__FIELDTYPE__DATE);

      this.setState({ dataExtension, dateFields });

      /**
       * If fieldObjectId is truthy/exists, that means we don't need
       * to update the dateDifferenceData object, else this is the case
       * where there's only one data extension or the user does not
       * change the default data extension and we need to update the
       * dateDifferenceData
       */
      if (!fieldObjectId) {
        field = {
          ...field,
          dataExtensionCustomerKey: dataExtension.CustomerKey,
          dataExtensionObjectId: dataExtension.ObjectID,
          fieldObjectId: dateFields.length ?
            dateFields[0].ObjectID :
            null,
          format: Constants.CUSTOM_VALUES__FORMULA_FORMAT__FIELD,
          collectionAlias,
        };
      }

      /**
       * This is necessary to set default value for the fields in the dateDifferenceData,
       * because if we don't do this, the componentDidMount of field2
       * resets the value of field1, so only update from the first field if they have the
       * same format(field)
       */
      if (fieldId === Constants.CUSTOM_VALUES__FIELD_ID__FIELD1 && !switchFormat) {
        const { fieldObjectId: field2ObjectId, format: field2Format } = dateDifferenceData.field2;
        const updatedDateDifferenceData = { ...dateDifferenceData, [fieldId]: field };

        // Make sure field2's format is field and it's fieldObjectId was not set beforehand
        if (!field2ObjectId && field2Format === Constants.CUSTOM_VALUES__FORMULA_FORMAT__FIELD) {
          updatedDateDifferenceData.field2 = field;
        }

        // set default dateDifferenceData
        handleSetCustomValuesState({ dateDifferenceData: updatedDateDifferenceData });
      } else if (switchFormat) {
        // update the fields value after toggle between field and timestamp
        const updatedDateDifferenceData = { ...dateDifferenceData, [fieldId]: field };

        // set new dateDifferenceData after switching the format
        handleSetCustomValuesState({ dateDifferenceData: updatedDateDifferenceData });
      }
    }

    // If the group format is changed to timestamp (today and now), update the state
    if (value !== Constants.CUSTOM_VALUES__FORMULA_FORMAT__FIELD) {
      handleSetCustomValuesState({
        dateDifferenceData: {
          ...dateDifferenceData,
          [fieldId]: {
            dataExtensionCustomerKey: null,
            dataExtensionObjectId: null,
            collectionAlias: null,
            fieldObjectId: null,
            format: format === Constants.CUSTOM_VALUES__FORMULA_FORMAT__FIELD ?
              Constants.CUSTOM_VALUES__FORMULA_FORMAT__TODAY :
              format,
          },
        },
      });
    }
  };

  /**
   * Event handler for when data extension selection is changed
   * @param {object} data - selected option
   * @returns {void}
   */
  handleDataExtensionSelected = async (data) => {
    const {
      selectedDataExtensions,
      handleSetCustomValuesState,
      dateDifferenceData,
      fieldId,
      getDataExtensionOrDataViewFields,
    } = this.props;

    // Find the selected data extension
    const dataExtension = selectedDataExtensions.find(de => de.deAlias === data?.value);

    const dataExtensionFields = dataExtension?.fields ?
      dataExtension.fields :
      (await getDataExtensionOrDataViewFields(dataExtension));

    // Find the date fields in that data extension
    const dateFields = dataExtensionFields.filter(de => de.FieldType === Constants.FILTERLINE__FIELDTYPE__DATE);

    this.setState({ dataExtension, dateFields });

    const updatedField = {
      ...dateDifferenceData[fieldId],
      dataExtensionCustomerKey: dataExtension?.CustomerKey,
      dataExtensionObjectId: dataExtension?.ObjectID,
      collectionAlias: dataExtension?.deAlias,
      fieldObjectId: dateFields.length ?
        dateFields[0].ObjectID :
        null,
    };
    const updatedDateDifferenceData = {
      ...dateDifferenceData,
      [fieldId]: updatedField,
    };

    // Update the dateDifferenceData object in CustomValues.js
    handleSetCustomValuesState({ dateDifferenceData: updatedDateDifferenceData });
  };

  /**
   * Event handler for when form elements' values are changed
   * Most inputs' and selections' onChange events are handled using this function
   * @param {object} value - data from change
   * @returns {void}
   */
  handleFormElementChanged = async (value) => {
    const {
      selectedDataExtensions,
      handleSetCustomValuesState,
      dateDifferenceData,
      fieldId,
      getDataExtensionOrDataViewFields,
    } = this.props;
    const { dataExtensionObjectId } = dateDifferenceData[fieldId];
    const updatedDateDifferenceData = { ...dateDifferenceData };

    /**
     * If value is not `today` or `now`, then we know we are editing the `Field` field
     */
    if (
      value !== Constants.CUSTOM_VALUES__FORMULA_FORMAT__TODAY &&
      value !== Constants.CUSTOM_VALUES__FORMULA_FORMAT__NOW
    ) {
      // Populate the field values using previous/first item in the array's values
      if (selectedDataExtensions && selectedDataExtensions.length > 0) {
        // If a selection was made previously, just change the fieldObjectId
        if (dataExtensionObjectId) {
          updatedDateDifferenceData[fieldId] = {
            ...updatedDateDifferenceData[fieldId],
            fieldObjectId: value,
            format: Constants.CUSTOM_VALUES__FORMULA_FORMAT__FIELD,
          };
        } else {
          // Set the first data extension as the value
          const dataExtension = selectedDataExtensions[0];

          const dataExtensionFields = dataExtension?.fields ?
            dataExtension.fields :
            (await getDataExtensionOrDataViewFields(dataExtension));

          const dateFields = dataExtensionFields.filter(de => de.FieldType === Constants.FILTERLINE__FIELDTYPE__DATE);

          updatedDateDifferenceData[fieldId] = {
            dataExtensionCustomerKey: dataExtension.CustomerKey,
            dataExtensionObjectId: dataExtension.ObjectID,
            fieldObjectId: dateFields.length ?
              dateFields[0].ObjectID :
              null,
            format: Constants.CUSTOM_VALUES__FORMULA_FORMAT__FIELD,
          };
        }
      }
    } else {
      // Set the new format
      updatedDateDifferenceData[fieldId] = {
        ...updatedDateDifferenceData[fieldId],
        format: value,
      };
    }
    handleSetCustomValuesState({ dateDifferenceData: updatedDateDifferenceData });
  };

  /**
   * Function to render fields
   * @param {object} dataExtension - The selected data extension
   * @param {string} deAlias - deAlias of the selected DE
   * @param {string} fieldObjectId - ObjectId of the selected field
   * @param {string} name - name of the select element
   * @param {object} dateFields - Fields of type 'Date' in the selected DE
   * @returns {object} The HTML of the field
   */
  renderField = (
    dataExtension,
    deAlias,
    fieldObjectId,
    name,
    dateFields,
  ) => {
    const { selectedDataExtensions, disabled } = this.props;

    return (
      <>
        <div className="dropdown-inline-group">
          <span className="form-element-label">Data Extension: </span>
          <Dropdown
            id="data-extensions-dropdown"
            selection
            className="data-extension searchable-dropdown"
            search
            placeholder="No data extension is selected"
            options={selectedDataExtensions.map(dataExt => ({
              value: dataExt.deAlias,
              text: `${dataExt.deAlias?.toString() || dataExt.Name.toString()}`,
            }))}
            value={deAlias || ''}
            onChange={(e, data) => this.handleDataExtensionSelected(data)}
            disabled={!selectedDataExtensions.length || disabled}
            loading
            lazyLoad
          />
        </div>
        <div className="dropdown-inline-group">
          <span className="form-element-label">Field: </span>
          <Dropdown
            id="data-extensions-dropdown"
            selection
            className="data-extension searchable-dropdown"
            search
            placeholder="No Date Fields in the selected Data Extension"
            options={dateFields.map(field => ({
              value: field.ObjectID,
              text: field.Name.toString(),
            }))}
            value={fieldObjectId || ''}
            onChange={(e, data) => this.handleFormElementChanged(data?.value)}
            disabled={!dataExtension || !dateFields.length || disabled}
            loading
            lazyLoad
          />
        </div>
      </>
    );
  };

  /**
   * This function toggles between showing a field or timestamp(today and now)
   * @param {object} e - The onChange event object
   * @returns {void}
   */
  toggleGroupFormat = (e) => {
    const { value } = e.target;

    this.setState({ groupFormat: value });

    // update fields in dateDifferenceData
    this.updateFieldsForDateDifferenceData(value, true);
  };

  renderRadioButtonGroup = () => {
    const { fieldId, disabled } = this.props;
    const { groupFormat } = this.state;

    return (
      <fieldset className="slds-form-element">
        <div className="slds-form-element__control">
          <div className="slds-radio_button-group">
            <RadioButton
              unlabeledInput
              containerClassName="slds-button slds-radio_button"
              name={`${fieldId}-${Constants.CUSTOM_VALUES__FORMULA_FORMAT__GROUP_FORMAT}`}
              id={fieldId + '-' + Constants.CUSTOM_VALUES__FORMULA_FORMAT__FIELD + '-' +
                Constants.CUSTOM_VALUES__FORMULA_FORMAT__GROUP_FORMAT}
              value={Constants.CUSTOM_VALUES__FORMULA_FORMAT__FIELD}
              onChange={this.toggleGroupFormat}
              checked={groupFormat === Constants.CUSTOM_VALUES__FORMULA_FORMAT__FIELD}
              label="Field"
              disabled={disabled}
            />
            <RadioButton
              unlabeledInput
              containerClassName="slds-button slds-radio_button"
              name={`${fieldId}-${Constants.CUSTOM_VALUES__FORMULA_FORMAT__GROUP_FORMAT}`}
              id={fieldId + '-' + Constants.CUSTOM_VALUES__FORMULA_TYPE__TIMESTAMP + '-' +
              Constants.CUSTOM_VALUES__FORMULA_FORMAT__GROUP_FORMAT}
              value={Constants.CUSTOM_VALUES__FORMULA_TYPE__TIMESTAMP}
              onChange={this.toggleGroupFormat}
              checked={groupFormat === Constants.CUSTOM_VALUES__FORMULA_TYPE__TIMESTAMP}
              label="Timestamp"
              disabled={disabled}
            />
          </div>
        </div>
      </fieldset>
    );
  };

  render() {
    const { dataExtension, dateFields, groupFormat } = this.state;
    const { dateDifferenceData, fieldId, disabled } = this.props;
    const { [fieldId]: field } = dateDifferenceData;
    const { collectionAlias, fieldObjectId, format } = field;

    return (
      <>
        <div>
          <div>
            <b className="date-difference-title">
              {fieldId === Constants.CUSTOM_VALUES__FIELD_ID__FIELD1 ?
                'Date 1:' :
                'Date 2:'}
            </b>
          </div>
          <div className="radio-button-group">
            {this.renderRadioButtonGroup()}
          </div>
          <div>
            {
            groupFormat === Constants.CUSTOM_VALUES__FORMULA_FORMAT__FIELD ?
              this.renderField(
                dataExtension,
                collectionAlias,
                fieldObjectId,
                `${fieldId}-${Constants.CUSTOM_VALUES__FORMULA_FORMAT__TODAY}`,
                dateFields,
              ) :
              (
                <fieldset className="slds-form-element">
                  <div className="slds-form-element__control">
                    <RadioButton
                      id={`${fieldId}-${Constants.CUSTOM_VALUES__FORMULA_FORMAT__TODAY}`}
                      name={`${fieldId}-format`}
                      label="Today (date)"
                      value={Constants.CUSTOM_VALUES__FORMULA_FORMAT__TODAY}
                      onChange={e => this.handleFormElementChanged(e?.target?.value)}
                      checked={format === Constants.CUSTOM_VALUES__FORMULA_FORMAT__TODAY}
                      disabled={disabled}
                    />
                    <RadioButton
                      id={`${fieldId}-${Constants.CUSTOM_VALUES__FORMULA_FORMAT__NOW}`}
                      name={`${fieldId}-format`}
                      label="Now (date + time)"
                      value={Constants.CUSTOM_VALUES__FORMULA_FORMAT__NOW}
                      onChange={e => this.handleFormElementChanged(e?.target?.value)}
                      checked={format === Constants.CUSTOM_VALUES__FORMULA_FORMAT__NOW}
                      disabled={disabled}
                    />
                  </div>
                </fieldset>
              )
          }
          </div>
        </div>
        <br />
      </>
    );
  }
}

DateDifferenceField.propTypes = {
  /**
   * Id of this field
   */
  fieldId: PropTypes.string.isRequired,
  /**
   * Function to set the state of the CustomValues component
   */
  handleSetCustomValuesState: PropTypes.func.isRequired,
  /**
   * It keeps the selected data extensions for Selection.js
   * selected data extensions are stored as collections in database
   * It will be passed from CustomValues.js
   */
  selectedDataExtensions: PropTypes.instanceOf(Array),
  /**
   * Object that stores values of the transformDate custom value
   */
  dateDifferenceData: PropTypes.instanceOf(Object),
  /**
   * Function used to fetch DE's fields when needed
   */
  getDataExtensionOrDataViewFields: PropTypes.func,
  /**
   * disable property for the component
   */
  disabled: PropTypes.bool,
};

export default DateDifferenceField;
