import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import axios from 'axios';
import { connect } from 'react-redux';

import Constants from '../../../constants/constants';
import FilterSetsAPI from '../../../api/filter-sets';
import Util from '../../../util';
import SortIcon from '../../shared/SortIcon/SortIcon';
import FilterSetsCategories from './FilterSetsCategories/FilterSetsCategories';
import mapStateToProps from '../../../mapStateToProps';

const FilterSets = ({
  filterSets = [],
  handleSetAdminPanelState,
  handleEditFilterSet,
  openPanel,
  handleSortFilterSets,
  sortDirection,
  sortedProperty,
  isLoading,
  handleCheckEditOrDelete,
  userInfo,
}) => {
  const [showFilterSetCategoriesModel, setFilterSetCategoriesModel] = useState(false);
  const userDateTimeFormat = Util.getUserDateTimeFormat(userInfo);

  /**
   * Deletes the filterSet selected in the
   * overview with the given id
   * @param {Object} event - onClick event
   * @param {String} filterSetId - id of the filterSet to delete
   * @returns {void}
   */
  const handleRemoveFilterSet = async (event, filterSetId) => {
    event.preventDefault();

    const result = await Swal.fire({
      type: 'warning',
      title: 'Remove Filter Set',
      html: '<p class="width_swal">Are you sure you want to remove this Filter Set?</p>',
      showCancelButton: true,
      confirmButtonText: 'Remove',
      footer: '<div></div>',
      buttonsStyling: false,
    });

    if (result.value) {
      // Delete filterSet
      await FilterSetsAPI.deleteFilterSet(filterSetId, axios.CancelToken.source().token);

      // Reload data
      openPanel(Constants.ADMIN_PANEL__MENU__FILTER_SETS);
    }
  };

  /**
   * Renders html with the filter sets info
   * @returns {object} HTML for the Filter Sets overview
   */
  const renderFilterSetDataTable = () => {
    if (filterSets?.length > 0 && !isLoading) {
      return (filterSets.map(filterSet => (
        <tr key={filterSet._id} className="slds-hint-parent row-data">
          <td data-label="FilterSet">
            <div className="slds-truncate">
              <a
                href="#!"
                title={filterSet.name}
                onClick={(e) => {
                  handleEditFilterSet(e, filterSet._id);
                }}
              >
                {filterSet.name}
              </a>
            </div>
          </td>
          <td data-label="Category">
            <div className="slds-truncate slds-m-left_x-small filterSet-type">
              {filterSet?.categoryName || 'Uncategorized'}
            </div>
          </td>
          <td data-label="LastModifiedDate">
            <div className="slds-truncate slds-m-left_x-small filterSet-type">
              {filterSet.updatedAt ? Util.formatDate(filterSet.updatedAt, userDateTimeFormat) : null}
            </div>
          </td>
          <td data-label="Action">
            <div className="slds-truncate slds-text-align_left">
              <button
                type="button"
                className="slds-button slds-button_icon slds-button_icon-border-filled remove-filterSet"
                title="Remove"
                onClick={async e => handleRemoveFilterSet(e, filterSet._id)}
              >
                <svg className="slds-button__icon" aria-hidden="true">
                  <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#delete" />
                </svg>
              </button>
            </div>
          </td>
        </tr>
      )));
    }

    if (!filterSets?.length && !isLoading) {
      return (
        <tr className="row-data">
          <td
            colSpan="5"
            className="slds-text-align_center"
          >
            <div id="no-picklists-defined">
              No Filter Sets have been defined yet. Hit the &apos;New Filter Set&apos; button to get started.
            </div>
          </td>
        </tr>
      );
    }

    return false;
  };

  return (
    <>
      {/* Filter Sets header */}
      <div className="slds-page-header slds-m-bottom_medium">
        <div className="slds-page-header__row">
          <div className="slds-page-header__col-title">
            <div className="slds-media header-alignment">
              <div className="slds-media__figure">
                <span className="slds-icon_container slds-icon-standard-account" title="Filter Sets">
                  <svg className="slds-icon slds-page-header__icon" aria-hidden="true">
                    <use xlinkHref="/assets/icons/standard-sprite/svg/symbols.svg#picklist_type" />
                  </svg>
                </span>
              </div>
              <div className="slds-media__body">
                <div className="slds-page-header__name">
                  <div className="slds-page-header__name-title">
                    <h1>
                      <span className="slds-page-header__title slds-truncate" title="Filter Sets">Filter Sets</span>
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="slds-page-header__control">
            <button
              id="new-filterSet"
              className="slds-button slds-button_neutral"
              type="button"
              onClick={() => setFilterSetCategoriesModel(true)}
            >
              Manage Categories
            </button>
          </div>
          <div className="slds-page-header__control">
            <button
              id="new-filterSet"
              className="slds-button slds-button_brand"
              type="button"
              onClick={() => handleSetAdminPanelState({ activePanel: Constants.ADMIN_PANEL__MENU__NEW_FILTER_SET })}
              disabled={isLoading}
            >
              New Filter Set
            </button>
          </div>
        </div>
      </div>

      <div className="slds-table--header-fixed_container" id="picklists-panel">
        <div className="slds-scrollable">
          <table className="slds-table slds-table_bordered slds-table--header-fixed">
            <thead>
              <tr className="slds-line-height_reset">
                <th scope="col">
                  <div className="slds-truncate slds-cell-fixed slds-m-left_small" title="Name">
                    Name
                    {/* sort icon  */}
                    <span
                      id="filterSetSort"
                      className="sorting slds-icon_container slds-icon-utility-announcement"
                      onClick={() => handleSortFilterSets(
                        'name',
                        sortDirection === Constants.SORT_DIRECTION__ASCENDING ?
                          Constants.SORT_DIRECTION__DESCENDING :
                          Constants.SORT_DIRECTION__ASCENDING,
                      )}
                    >
                      <SortIcon sortDirection={sortedProperty === 'name' ? sortDirection : ''} />
                    </span>
                  </div>
                </th>
                <th scope="col">
                  <div className="slds-truncate slds-cell-fixed slds-m-left_small" title="Category">
                    Category
                    {/* sort icon  */}
                    <span
                      id="filterSetSort"
                      className="sorting slds-icon_container slds-icon-utility-announcement"
                      onClick={() => handleSortFilterSets(
                        'categoryName',
                        sortDirection === Constants.SORT_DIRECTION__ASCENDING ?
                          Constants.SORT_DIRECTION__DESCENDING :
                          Constants.SORT_DIRECTION__ASCENDING,
                      )}
                    >
                      <SortIcon sortDirection={sortedProperty === 'categoryName' ? sortDirection : ''} />
                    </span>
                  </div>
                </th>
                <th scope="col">
                  <div className="slds-truncate slds-cell-fixed slds-m-left_x-small" title="Last Modified Date">
                    Last Modified Date
                    {/* sort icon  */}
                    <span
                      id="dateSort"
                      className="sorting slds-icon_container slds-icon-utility-announcement"
                      onClick={() => handleSortFilterSets(
                        'updatedAt',
                        sortDirection === Constants.SORT_DIRECTION__ASCENDING ?
                          Constants.SORT_DIRECTION__DESCENDING :
                          Constants.SORT_DIRECTION__ASCENDING,
                      )}
                    >
                      <SortIcon sortDirection={sortedProperty === 'updatedAt' ? sortDirection : ''} />
                    </span>
                  </div>
                </th>
                <th scope="col">
                  <div className="slds-truncate slds-cell-fixed" title="Action">Action</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {renderFilterSetDataTable()}
            </tbody>
          </table>
          {isLoading ?
            (
              <div role="status" className="slds-spinner slds-spinner_medium">
                <span className="slds-assistive-text">Loading</span>
                <div className="slds-spinner__dot-a" />
                <div className="slds-spinner__dot-b" />
              </div>
            ) :
            null}
        </div>
      </div>

      {showFilterSetCategoriesModel && (
        <div style={{ position: 'relative', zIndex: 9999 }}>
        <FilterSetsCategories
        setFilterSetCategoriesModel={setFilterSetCategoriesModel}
        handleCheckEditOrDelete={handleCheckEditOrDelete}
        />
        </div>
      )}
    </>
  );
};

FilterSets.propTypes = {
  /**
   * array containing the filterSets retrieved, this prop comes
   * from the admin panel component
   */
  filterSets: PropTypes.instanceOf(Array).isRequired,
  /**
   * handles the state of the admin panel
   */
  handleSetAdminPanelState: PropTypes.func.isRequired,
  /**
   * function to open another panel / reload data
   */
  openPanel: PropTypes.func.isRequired,
  /**
   * function to open the edit screen for a filter set
   */
  handleEditFilterSet: PropTypes.func.isRequired,
  /**
   * It sorts filterSets
   */
  handleSortFilterSets: PropTypes.func.isRequired,
  /**
   * It indicates the direction of sort
   */
  sortDirection: PropTypes.string.isRequired,
  /**
   * It gives by which value sort will take place
   */
  sortedProperty: PropTypes.string,
  /**
   * Responsible for showing/hiding loader
   */
  isLoading: PropTypes.bool.isRequired,
  /**
   * Check if category is edited or deleted
   */
  handleCheckEditOrDelete: PropTypes.func.isRequired,
  /**
   * User info from cookie
   */
  userInfo: PropTypes.object,
};

export default connect(mapStateToProps(['userInfo']), null, null, { pure: false })(FilterSets);
