import swal from 'sweetalert2';

import '../../App.scss';
import Constants from '../../constants/constants';
// eslint-disable-next-line import/no-cycle
import Util from '../../util';

const SwalUtil = {
  /**
   * Show the swal modal with the message
   * @param {string} title - title for the swal modal
   * @param {string} message - string message for the swal modal
   * @param {object} messageHTML - html object with message displayed in swal modal
   * @param {string} type - type of the swal modal
   * @param {object} options - object with additional options for swal
   * @param {string} htmlClass - class name for swal message
   * @returns {Promise<object>} swal modal
   */
  fire: ({
    title, message, messageHTML, type, options = {}, htmlClass,
  }) => {
    const swalMessageClassName = htmlClass || 'width_swal';

    let htmlContent = '';

    let errorMessageHTML = '';

    let titleWithErrorCode = '';

    if (typeof message === 'object' && message.actualError) {
      errorMessageHTML = Util.buildErrorHTML(message);
      titleWithErrorCode = `Error ${message.errorCode}`;
    }

    if (messageHTML || errorMessageHTML) {
      htmlContent = messageHTML || errorMessageHTML;
    } else if (message) {
      htmlContent = `<p class=${swalMessageClassName}>${message}</p>`;
    }

    // eslint-disable-next-line no-nested-ternary
    const titleClass = type === Constants.SWAL__TYPE__ERROR ?
      ' class="error-title"' :
      type === Constants.SWAL__TYPE__WARNING ?
        ' class="warning-title"' :
        '';

    return swal.fire({
      // eslint-disable-next-line max-len
      title: `<div${titleClass}>${titleWithErrorCode || title || 'Oops...'}</div>`,
      html: htmlContent,
      footer: '<div></div>',
      buttonsStyling: false,
      ...options,
    });
  },
};

export default SwalUtil;
