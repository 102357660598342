import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

import Util from '../../util';
import Constants from '../../constants/constants';
import SwalUtil from '../../utils/swal/swalUtil';
import WarningAlert from '../../components/shared/WarningAlert/WarningAlert';
import GuidanceTip from '../../components/shared/GuidanceTip/GuidanceTip';
import DataExtensions from '../Selection/DataExtensions/DataExtensions/DataExtensions';
// eslint-disable-next-line import/no-cycle
import { NewSelectionStates } from '../../containers_v2/newSelection/NewSelection';
import Button from '../shared_v2/Button/Button';

const DataExtensionsMultipleTabsv2 = ({
  selectionNavigator,
  selectionNavigation,
  selectedDataExtensions,
  previewQueryActivityId,
  numberOfResults,
  previewStatus,
  editTargetDataExtension,
  unionSelections,
  unionSelectionsIndex,
  dataExtensions,
  showRelationalModal,
  checkMissingFieldsInRelations,
  currentSelectionId,
  handleDeleteSelectedDE,
  handleFiltersSave,
  handleSetAppState,
  matchedFields,
  handleSetSelectionState,
  getDataExtensionOrDataViewFields,
  handleFeatureMissing,
  manageSubscriberRelationship,
  DEBorderMouseOver,
  filterBorderMouseOver,
  showDataMultipleTabs,
  loaderSelectedDE,
  handlePickListOptions,
  pickLists,
  loadingSubQueryFields,
  handleRemoveFilterLine,
  customValues,
  loadingForDataExtensions,
  loadingAllAvailableDataExtensions,
  predefinedRelations,
  predefinedRelationsMap,
  subQueryDataExtensions,
  returnPredefinedRelationById,
  availableDEsFolderId,
  availableDEsFolders,
  foldersSettings,
  movingDE,
  selectedDEsTree,
  prevSelectedDEs,
  prevRelations,
  applyTimezoneSettingsToAllDateFields,
  handleSetTimezoneToAllDateFields,
  timezoneSettingsForAllDateFields,
  dataSets,
  filterSets,
  featureDataSets,
  featureFilterSets,
  parentDEOfDataSet,
  isArchived,
  selectionMode,
  basicModeSelectionSourceType,
  handleSetSelectedDataSet,
  switchFromAdvancedToBasic,
  handleSetSelectedDE,
  selectedDERef,
  handleClearBasicModeSource,
  showEssentialsUpgradeModal,
  revertBehavioralFilterSet,
  updateDataExtensionsObject,
  onDataExtensionSearchFieldChange,
  dataExtensionSearchField,
  saveSelection,
  handleAddElementToAppArrayState,
  isSelectedDEChanged,
  availableDEs,
  isFilterBoxExpanded,
  isFilterBoxExpandedForFirstTime,
  showFiltersImmediately,
}) => {
  const menuRef = useRef();

  // state for submenu and tab
  const [menuState, setMenuState] = useState(0);
  const [tabId, setTabId] = useState(null);
  const [tabNameState, setTabNameState] = useState('');

  /**
   * Close submenu when click outside
   * @param {object} e - e.target
   * @returns {void}
   */
  const closeSubMenuOnClickOutside = useCallback((e) => {
    // if submenu is open and menuRef exists
    if (menuState === 1 && menuRef.current !== null) {
      if (
        e.target.className !== 'submenu_folder' &&
        e.target.className !== 'submenu tab-submenu active'
      ) {
        // if user have not clicked on the above classes, turn off the menu and clear tabId
        Util.toggleMenuOff(setMenuState, menuState, menuRef);
        setTabId(null);
      }
    }
  }, [menuState]);

  useEffect(() => {
    // perform an action after clicking, depending on the tabID change
    document.addEventListener('click', e => closeSubMenuOnClickOutside(e));

    return () => {
      document.removeEventListener('click', e => closeSubMenuOnClickOutside(e));
    };
  }, [closeSubMenuOnClickOutside]);

  /**
   * Switch between tabs updating unionSelectionsIndex in app state
   * @param {number} index - Index of the tab
   * @returns {void}
   */
  const changeTab = (index) => {
    handleSetAppState({ unionSelectionsIndex: index });
  };

  /**
   * Create/Add new tab
   * @returns {void}
   */
  const addNewTab = () => {
    const tabNames = [];

    unionSelections.map(selectionState => tabNames.push(selectionState.tabName));

    // set tabIndex to 1 so we can check all tabs to prevent duplication
    let tabIndex = 1;

    let tabName = `New Source ${tabIndex}`;
    // check the tabName if exists

    while (tabNames.includes(tabName)) {
      // increase the index
      tabIndex += 1;
      // assign a new value for the tabName
      tabName = `New Source ${tabIndex}`;
    }

    // add new tab to unionSelections
    const newSelection = NewSelectionStates(tabName);

    // assign the correct unionSelectionsIndex property
    const newUnionSelections = {
      ...newSelection.state,
      unionSelectionsIndex: unionSelections.length,
      sourceLimit: Constants.DEFAULT_SOURCE_LIMIT,
    };

    // Add new tab to app state
    handleAddElementToAppArrayState('unionSelections', newUnionSelections);
    // Update the index and focus on a new tab
    handleSetAppState({ unionSelectionsIndex: unionSelections.length });
  };

  /**
   * When user double clicks the name of the tab, the name can be changed
   * @param {string} currentTabName - current tab name
   * @param {string} unionSelectionsIdx - index of the tab
   * @returns {void}
   */
  const changeTabName = async (currentTabName, unionSelectionsIdx) => {
    const result = await SwalUtil.fire({
      title: 'Rename Source',
      options: {
        input: 'text',
        inputValue: currentTabName,
        showCancelButton: true,
        confirmButtonText: 'Save',
      },
    });

    if (result.value) {
      const newUnionSelections = unionSelections;

      newUnionSelections[unionSelectionsIdx].tabName = result.value;
      // Update the tab state with the tabs containing a tab with a new name
      handleSetAppState({ unionSelections: newUnionSelections });
    }
  };

  /**
   * Copy tab
   * @param {string} tabIndex - index of the selected tab
   * @returns {void}
   */
  const copyTab = (tabIndex) => {
    if (unionSelections[tabIndex]) {
      // create copy of the selection tab
      const copyTabSelection = JSON.parse(JSON.stringify(unionSelections[tabIndex]));

      // create name for the copy
      const newTabName = `${copyTabSelection.tabName} copy`;

      copyTabSelection.tabName = newTabName;

      copyTabSelection.sourceLimit = Constants.DEFAULT_SOURCE_LIMIT;

      // update unionSelectionsIndex
      copyTabSelection.unionSelectionsIndex = unionSelections.length;

      // Save copy of the selection tab in app state
      handleAddElementToAppArrayState('unionSelections', copyTabSelection);
    }

    return false;
  };

  /**
   * Delete tab
   * @param {number} id - tab index
   * @returns {void}
   */
  const deleteTab = async (id) => {
    /*
     * Check to make sure it's not the last remaining tab
     * You can't delete last remaining tab
     */
    if (unionSelections.length < 2) {
      SwalUtil.fire({
        type: Constants.SWAL__TYPE__ERROR,
        message: 'You can\'t remove the last source.',
      });
    } else {
      let swalMessage = 'Are you sure you want to delete this source? <br /> You won\'t be able to revert this!';

      if (unionSelections[id]?.sourceLimit?.active) {
        swalMessage += `<br /><br /><strong>${unionSelections[id]?.tabName || 'This source'}</strong>
        is being used in Source Limiting.
        If you choose to proceed, it will also be removed from the Source Limiting settings.`;
      }
      // If it is not the last tab ask for confirmation
      const result = await SwalUtil.fire({
        title: 'Delete source',
        message: swalMessage,
        options: {
          showCancelButton: true,
          confirmButtonText: 'Delete',
        },
      });

      if (result.value) {
        unionSelections.splice(id, 1);

        // Set the state of unionSelections
        handleSetAppState({ unionSelections }, () => {
          // If the tab that we want to remove is at the --> right side of active tab
          if (id > unionSelectionsIndex) {
            // Don't update selection index
            handleSetAppState({ unionSelectionsIndex });
            // If user wants to remove currently active tab
          } else if (id === unionSelectionsIndex) {
            // Navigate to previous tab, set as 0 if there is none. Avoid blank space
            handleSetAppState({ unionSelectionsIndex: id === 0 ? 0 : id - 1 });
            // If the tab that we want to remove is at the left <-- side of the active tab
          } else {
            // Decrease index by 1. Will still navigate the same tab
            handleSetAppState({
              unionSelectionsIndex:
                unionSelectionsIndex === 0 ? 0 : unionSelectionsIndex - 1,
            });
          }

          // update unionSelectionsIndex in unionSelections to avoid duplicate indexes
          const updatedUnionSelection = unionSelections.map((us, i) => {
            // if unionSelectionIndex is equal to the number of index in the array, return that object without updating
            if (us.unionSelectionsIndex && us.unionSelectionsIndex === i) {
              return us;
            } if (us.unionSelectionsIndex && us.unionSelectionsIndex !== i) {
              /*
               * if unionSelectionIndex is different then the number of index in the array,
               * update unionSelectionIndex in this object
               */
              return { ...us, unionSelectionsIndex: i };
            }

            return us;
          });

          // update the unionSelections array in app state
          handleSetAppState({ unionSelections: updatedUnionSelection });
        });
      }
    }
  };

  /**
   * Right click to open sub menu
   * @param {object} e - Refer to clicked folder
   * @param {string} tabID - Id of a tab
   * @param {string} nameOfTab - the name of the Tab
   * @returns {void}
   */
  const rightClick = (e, tabID, nameOfTab) => {
    e.preventDefault();

    // turn on menu
    Util.toggleMenuOn(setMenuState, menuRef, ' tab-submenu ');

    // position menu
    Util.positionMenu(e, menuRef);

    // set the ID of the clicked tab
    setTabId(tabID);

    // set the name of the clicked tab
    setTabNameState(nameOfTab);
  };

  return (
    <div
      className="dataExtension_multipleTabs_v2"
      style={{ display: showDataMultipleTabs ? 'none' : 'block' }}
    >

      {isArchived && <WarningAlert text={Constants.WARNING_TEXT__ARCHIVED_SELECTION_READ_ONLY} />}

      <div className="guidance-parent-dataextension_tab">
        <GuidanceTip tipId="selection-tab-tip" />
      </div>
      <div className="slds-tabs_scoped">
        {unionSelections.map((unionSelection, i) => (
          <div
            className={`slds-tabs_scoped__content slds-show tab-content ${i === unionSelectionsIndex ? 'active' : ''}`}
            key={`tabSelection-${unionSelection.unionSelectionsIndex ? unionSelection.unionSelectionsIndex : i}`}
          >
            <div
              id={`tab-scoped-${i}`}
              className="tab-panel"
              role="tabpanel"
              aria-labelledby={`tab-${i}`}
            >
              <DataExtensions
                selectionNavigator_={selectionNavigator}
                selectionNavigation={selectionNavigation}
                previewQueryActivityId={previewQueryActivityId}
                selectedDataExtensions_={selectedDataExtensions}
                numberOfResults={numberOfResults}
                previewStatus={previewStatus}
                editTargetDataExtension={editTargetDataExtension}
                dataExtensions={dataExtensions}
                availableDEs={availableDEs}
                updateDataExtensionsObject={updateDataExtensionsObject}
                onDataExtensionSearchFieldChange={onDataExtensionSearchFieldChange}
                dataExtensionSearchField={dataExtensionSearchField}
                selectedDataExtensions={unionSelection.selectedDataExtensions}
                handleDeleteSelectedDE={handleDeleteSelectedDE}
                selectedFilters={unionSelection.selectedFilters}
                showRelationalModal={showRelationalModal}
                checkMissingFieldsInRelations={checkMissingFieldsInRelations}
                fromFieldMissing={unionSelection.fromFieldMissing}
                toFieldMissing={unionSelection.toFieldMissing}
                modalDataExtensions={unionSelection.modalDataExtensions}
                handleFiltersSave={handleFiltersSave}
                currentSelectionId={currentSelectionId}
                matchedFields={matchedFields}
                handleSetSelectionState={handleSetSelectionState}
                relations={unionSelection.relations}
                getDataExtensionOrDataViewFields={getDataExtensionOrDataViewFields}
                handleFeatureMissing={handleFeatureMissing}
                manageSubscriberRelationship={manageSubscriberRelationship}
                DEBorderMouseOver={DEBorderMouseOver}
                filterBorderMouseOver={filterBorderMouseOver}
                loaderSelectedDE={loaderSelectedDE}
                handlePickListOptions={handlePickListOptions}
                pickLists={pickLists}
                loadingSubQueryFields={loadingSubQueryFields}
                handleRemoveFilterLine={handleRemoveFilterLine}
                unionSelections={unionSelections}
                unionSelectionsIndex={unionSelectionsIndex}
                handleSetAppState={handleSetAppState}
                customValues={customValues}
                loadingForDataExtensions={loadingForDataExtensions}
                loadingAllAvailableDataExtensions={loadingAllAvailableDataExtensions}
                predefinedRelations={predefinedRelations}
                predefinedRelationsMap={predefinedRelationsMap}
                subQueryDataExtensions={subQueryDataExtensions}
                returnPredefinedRelationById={returnPredefinedRelationById}
                availableDEsFolderId={availableDEsFolderId}
                availableDEsFolders={availableDEsFolders}
                foldersSettings={foldersSettings}
                movingDE={movingDE}
                prevSelectedDEs={prevSelectedDEs}
                prevRelations={prevRelations}
                selectedDEsTree={selectedDEsTree}
                applyTimezoneSettingsToAllDateFields={applyTimezoneSettingsToAllDateFields}
                timezoneSettingsForAllDateFields={timezoneSettingsForAllDateFields}
                handleSetTimezoneToAllDateFields={handleSetTimezoneToAllDateFields}
                dataSets={dataSets}
                filterSets={filterSets}
                featureDataSets={featureDataSets}
                featureFilterSets={featureFilterSets}
                parentDEOfDataSet={parentDEOfDataSet}
                selectionMode={selectionMode}
                basicModeSelectionSourceType={basicModeSelectionSourceType}
                handleSetSelectedDataSet={handleSetSelectedDataSet}
                handleSetSelectedDE={handleSetSelectedDE}
                selectedDERef={selectedDERef}
                selectedDataSet={unionSelection.selectedDataSet}
                switchFromAdvancedToBasic={switchFromAdvancedToBasic}
                handleClearBasicModeSource={handleClearBasicModeSource}
                showEssentialsUpgradeModal={showEssentialsUpgradeModal}
                revertBehavioralFilterSet={revertBehavioralFilterSet}
                saveSelection={saveSelection}
                changeTab={changeTab}
                rightClick={rightClick}
                changeTabName={changeTabName}
                deleteTab={deleteTab}
                addNewTab={addNewTab}
                isSelectedDEChanged={isSelectedDEChanged}
                showDataMultipleTabs={showDataMultipleTabs}
                isFilterBoxExpanded={isFilterBoxExpanded}
                isFilterBoxExpandedForFirstTime={isFilterBoxExpandedForFirstTime}
                showFiltersImmediately={showFiltersImmediately}
              />
            </div>
          </div>
        ))}
      </div>
      <div id="context-menu" className="submenu tab-submenu" ref={menuRef}>
        <Button
          noButtonClass
          className="submenu_folder"
          id="copy-tab"
          onClick={() => {
            copyTab(tabId);
            Util.toggleMenuOff(setMenuState, menuState, menuRef);
          }}
        >
          Copy
        </Button>
        <Button
          noButtonClass
          className="submenu_folder"
          id="rename-tab"
          onClick={() => {
            changeTabName(tabNameState, tabId);
            Util.toggleMenuOff(setMenuState, menuState, menuRef);
          }}
        >
          Rename
        </Button>
      </div>
    </div>
  );
};

DataExtensionsMultipleTabsv2.propTypes = {
  /**
   * It keeps the states of a Selection`s if appendDataExtension feature is enabled
   */
  unionSelections: PropTypes.instanceOf(Array),
  /**
   * This prop keeps the unionSelectionsIndex of Union Selection
   * This prop will be passed from App.js component if the appendDataExtension feature is enabled
   */
  unionSelectionsIndex: PropTypes.number,
  /**
   * It keeps the data extensions after they are retrieved from SFMC
   * if dataViews feature is enabled, it will also contain dataViews as well
   */
  dataExtensions: PropTypes.instanceOf(Array),
  /**
   * It determines if the relationalModal will be shown or not
   */
  showRelationalModal: PropTypes.bool,
  /**
   * It keeps the selected selection`s id
   * It will be passed from the App through Selection.js
   */
  currentSelectionId: PropTypes.string,
  /**
   * It helps to delete a selected data extension
   * it will be passed from Selection.js
   */
  handleDeleteSelectedDE: PropTypes.func.isRequired,
  /**
   * It helps to save the selected filters for the selection
   * it will be passed from Selection.js
   */
  handleFiltersSave: PropTypes.func.isRequired,
  /**
   * it helps to add another element to App component's array state
   * This prop will be passed from App.js component through Selection.js
   */
  handleAddElementToAppArrayState: PropTypes.func.isRequired,
  /**
   * it sets the App component`s state
   * This prop will be passed from App.js component through Selection.js
   */
  handleSetAppState: PropTypes.func.isRequired,
  /**
   * It keeps the matchedFields for a target data extension of the Selection
   * It will be passed from Selection.js
   */
  matchedFields: PropTypes.instanceOf(Array),
  /**
   * It helps to set the Selection`s state
   * It will be passed from Selection.js
   */
  handleSetSelectionState: PropTypes.func.isRequired,
  /**
   * It helps to retrieve fields of a data extension or data view from SFMC
   * It will be passed from Selection.js
   */
  getDataExtensionOrDataViewFields: PropTypes.func.isRequired,
  /**
   * It helps to throw a warning message if a feature is disabled and used in the Selection
   * It will be passed from Selection.js
   */
  handleFeatureMissing: PropTypes.func.isRequired,
  /**
   * It helps to manage subscriber relationship while creating a data extension
   * It will be passed from Selection.js
   */
  manageSubscriberRelationship: PropTypes.func.isRequired,
  /**
   * Keeps track whether Available DE are dragged
   */
  DEBorderMouseOver: PropTypes.bool.isRequired,
  /**
   * Keeps track whether Available Fields are dragged
   */
  filterBorderMouseOver: PropTypes.bool.isRequired,
  /**
   * In order for Loading Modal to cover DataExtensionsMultipleTabs while it renders, we have to make it disable before
   * it loads (maybe there is better way to do it)
   */
  showDataMultipleTabs: PropTypes.bool.isRequired,
  /**
   * Responsible for showing/hiding loader
   */
  loaderSelectedDE: PropTypes.bool.isRequired,
  /**
   * Responsible for adding/deleting fields Object IDs when searching picklist for the options
   * it will be passed from Selection.js
   */
  handlePickListOptions: PropTypes.func.isRequired,
  /**
   * Keeps searched picklist
   * It will be passed from Selection.js
   */
  pickLists: PropTypes.instanceOf(Array).isRequired,
  /**
   * Checks if there are any deleted fields in relations
   */
  checkMissingFieldsInRelations: PropTypes.func.isRequired,
  /*
   * Stores the loading state of filterlines with subQueries
   */
  loadingSubQueryFields: PropTypes.instanceOf(Object),
  /**
   * It Removes a given filterLine
   * It is passed from Selection.js
   */
  handleRemoveFilterLine: PropTypes.func,
  /**
   * It keeps custom values data
   * It will be passed from Selection.js
   */
  customValues: PropTypes.instanceOf(Array).isRequired,
  /**
   * Responsible for the loading status of data extensions
   */
  loadingForDataExtensions: PropTypes.bool.isRequired,
  /**
   * Responsible for the loading status of all available data extensions
   */
  loadingAllAvailableDataExtensions: PropTypes.bool.isRequired,
  /**
   * An array containing relations that were defined in the admin panel
   */
  predefinedRelations: PropTypes.instanceOf(Array).isRequired,
  /**
   * An object that stores the object ids of found predefined relations in a selection
   */
  predefinedRelationsMap: PropTypes.instanceOf(Object).isRequired,
  /**
   * An array containing data extensions used in InResults and relations filters
   */
  subQueryDataExtensions: PropTypes.instanceOf(Array).isRequired,
  /**
   * It returns predefined relation object for relation filter
   * it is passed from Selection.js
   */
  returnPredefinedRelationById: PropTypes.func.isRequired,
  /**
   * Selected id of the available folder
   */
  availableDEsFolderId: PropTypes.number,
  /**
   * Stored availableDEs folders in selection state (Stored in order to prevent making unnecessary api calls)
   */
  availableDEsFolders: PropTypes.instanceOf(Array),
  /**
   * Keeps folder filtering information
   */
  foldersSettings: PropTypes.instanceOf(Object),
  /**
   * Indicates whether a DE is being moved
   */
  movingDE: PropTypes.bool.isRequired,
  /**
   * Copied selectedDEs before addition and removal of DEs from a subcollection
   * when moving DEs
   */
  prevSelectedDEs: PropTypes.instanceOf(Array).isRequired,
  /**
   * Copied selectedDEs before addition and removal of DEs from a subcollection
   * when moving DEs
   */
  prevRelations: PropTypes.instanceOf(Array).isRequired,
  /**
   * An object with child DE's id as key and parent DE's id as value.
   * Used to prevent the dragging of DE's unto their descendants
   */
  selectedDEsTree: PropTypes.instanceOf(Object),
  /**
   * Indicates whether timezone settings are applied to all date filters
   */
  applyTimezoneSettingsToAllDateFields: PropTypes.bool,
  /**
   * Handles the setting of timezone settings to all date filters
   */
  handleSetTimezoneToAllDateFields: PropTypes.func,
  /**
   * An object containing timezone details
   */
  timezoneSettingsForAllDateFields: PropTypes.instanceOf(Object),
  /**
   * Array of the data sets
   */
  dataSets: PropTypes.instanceOf(Array).isRequired,
  /**
   * Array of the filter sets
   */
  filterSets: PropTypes.instanceOf(Array),
  /**
   * Determines if the feature flag for the data sets is true or false
   */
  featureDataSets: PropTypes.bool,
  /**
   * Determines if the feature flag for the filter sets is true or false
   */
  featureFilterSets: PropTypes.bool,
  /**
   * Parent Data Extension of the data set that is being dragged
   */
  parentDEOfDataSet: PropTypes.string,
  /*
   * Tells whether the selection is archived or not
   */
  isArchived: PropTypes.bool.isRequired,
  /**
   * Mode of the selection
   */
  selectionMode: PropTypes.string,
  /**
   * Basic mode selection source type
   */
  basicModeSelectionSourceType: PropTypes.string,
  /**
   * Handles the setting of selected dataSet
   */
  handleSetSelectedDataSet: PropTypes.func,
  /**
   * Handles the setting of selected DE
   */
  handleSetSelectedDE: PropTypes.func,
  /**
   * Handle clear basic mode source
   */
  handleClearBasicModeSource: PropTypes.func,
  /**
   * Ref from the data sets panel
   */
  selectedDERef: PropTypes.instanceOf(Object),
  /**
   * Check if mode was switched from advanced to basic
   */
  switchFromAdvancedToBasic: PropTypes.bool,
  /**
   * It toggles a feature advert modal on with specific feature
   */
  showEssentialsUpgradeModal: PropTypes.func.isRequired,
  /**
   * Revert behavioral filter set
   */
  revertBehavioralFilterSet: PropTypes.func,
  /**
   * Update data extensions with search object
   */
  updateDataExtensionsObject: PropTypes.func,
  /**
   * Handle save selection
   */
  saveSelection: PropTypes.func,
  /**
   * Determines if a selected DE has changed its properties or not (eg. alias change)
   */
  isSelectedDEChanged: PropTypes.bool.isRequired,
};

export default DataExtensionsMultipleTabsv2;
