import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Constants from '../../../constants/constants';
import Alert from '../Alert/Alert';
import Button from '../Button/Button';
import './styles.scss';

const ModalTemplate = ({
  id,
  className,
  noHeaderTitle,
  ariaHidden,
  onKeyPress,
  headerId,
  headerTitle,
  headerTitleHTML,
  headerWithAlert,
  containerClassName,
  containerId,
  contentClassName,
  contentId,
  withoutModalContent,
  children,
  footerClassName,
  footerId,
  extraFooterContent,
  cancelButtonId,
  handleCancel,
  cancelButtonDisabled,
  cancelButtonTitle,
  saveButtonDisabled,
  saveButtonId,
  handleSave,
  saveButtonTitle,
  backdropHidden,
  loadingSaveButton,
  loadingSaveButtonTitle,
  isDataSetDEModal,
  modalParentClassName,
  showCancelIcon,
  handleCloseModal,
}) => {
  // class name of the modal section
  const modalSectionClassName = classNames('slds-modal slds-fade-in-open', className);

  // class name of the modal container
  const modalContainerClassName = classNames('slds-modal__container', containerClassName);

  // class name of the modal content
  const modalContentClassName = classNames('slds-modal__content', contentClassName);

  // class name for the footer
  const modalFooterClassName = classNames('slds-modal__footer', footerClassName);

  // class name for the backdrop component
  // eslint-disable-next-line quote-props
  const backdropClassName = classNames('slds-backdrop slds-backdrop_open', { 'hidden': backdropHidden });

  return (
    <div className={modalParentClassName}>
      <section
        role="dialog"
        tabIndex="-1"
        className={modalSectionClassName}
        aria-labelledby="modal-heading-01"
        aria-modal="true"
        aria-describedby="modal-content-id-1"
        aria-hidden={ariaHidden}
        id={id}
      >
        <div className={modalContainerClassName} id={containerId}>
          {
          noHeaderTitle ?
            (
              null
            ) :
            (
          <header className="slds-modal__header">
            {headerWithAlert ?
              (
                <Alert
                  id="alert-header"
                  withoutIcon
                  headerId={headerId}
                  title={headerTitle}
                />
              ) :
              (
                <div className="lmo">
                  <div className="lmo_wrapper">
                    <h2
                      id={headerId}
                      className="slds-modal__title slds-hyphenate"
                    >
                      {headerTitleHTML ?
                        (
                          <div dangerouslySetInnerHTML={{ __html: headerTitleHTML }} />
                        ) :
                        headerTitle}
                    </h2>
                    {showCancelIcon && (
                      <Button
                        className="closeModal"
                        noButtonClass
                        noSpan
                        id={cancelButtonId}
                        onClick={handleCloseModal}
                      >
                        ×
                      </Button>
                    )}
                  </div>
                </div>
              )}
          </header>
            )
          }
          {withoutModalContent ?
            (
              children
            ) :
            (
              <div
                className={modalContentClassName}
                id={contentId || 'content'}
                onKeyPress={onKeyPress}
              >
                {children}
              </div>
            )}

          {handleCancel || handleSave || extraFooterContent ?
            (
              <footer className={modalFooterClassName} id={footerId}>
                {extraFooterContent}
                {handleCancel && !isDataSetDEModal && (
                  <Button
                    buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
                    id={cancelButtonId}
                    onClick={handleCancel}
                    disabled={cancelButtonDisabled}
                  >
                    {cancelButtonTitle || 'Cancel'}
                  </Button>
                )}
                {handleSave && (
                  <Button
                    buttonLook={Constants.BUTTON__TYPE__BRAND}
                    id={saveButtonId}
                    onClick={handleSave}
                    disabled={saveButtonDisabled}
                    loadingClickedButton={loadingSaveButton}
                    titleInAction={loadingSaveButtonTitle}
                  >
                    {saveButtonTitle || 'Save'}
                  </Button>
                )}
              </footer>
            ) :
            null}
        </div>
      </section>
      <div className={backdropClassName} />
    </div>
  );
};

ModalTemplate.propTypes = {
  /**
   * id of the modal section element
   */
  id: PropTypes.string,
  /**
   * class name of the modal section element
   */
  className: PropTypes.string,
  /**
   * id of the header element
   */
  headerId: PropTypes.string,
  /**
   * header title
   */
  headerTitle: PropTypes.string,
  /**
   * HTML for header title
   */
  headerTitleHTML: PropTypes.string,
  /**
   * id of the footer element
   */
  footerId: PropTypes.string,
  /**
   * id of the cancel button
   */
  cancelButtonId: PropTypes.string,
  /**
   * function that handles the event after pressing cancel button
   */
  handleCancel: PropTypes.func,
  /**
   * indicates whether the cancel button is disabled
   */
  cancelButtonDisabled: PropTypes.bool,
  /**
   * indicates whether the save button is disabled
   */
  saveButtonDisabled: PropTypes.bool,
  /**
   * id of the save button
   */
  saveButtonId: PropTypes.string,
  /**
   * function that handles the event after pressing save button
   */
  handleSave: PropTypes.func,
  /**
   * label for the cancel button
   */
  cancelButtonTitle: PropTypes.string,
  /**
   * label for the save button
   */
  saveButtonTitle: PropTypes.string,
  /*
   * everything that is used to display in the modal content
   */
  children: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array]).isRequired,
  /*
   * indicates whether header should be displayed with alert texture
   */
  headerWithAlert: PropTypes.bool,
  /*
   * aria-hidden property for the section element
   */
  ariaHidden: PropTypes.bool,
  /*
   * class name of the container div element
   */
  containerClassName: PropTypes.string,
  /*
   * additional content displayed in the footer
   */
  extraFooterContent: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array]),
  /*
   * class name of the content div element
   */
  contentClassName: PropTypes.string,
  /*
   * indicates that backdrop component should be hidden
   */
  backdropHidden: PropTypes.bool,
  /*
   * id of the container div element
   */
  containerId: PropTypes.string,
  /**
   * function that handles pressing a key (on the keyboard)
   */
  onKeyPress: PropTypes.func,
  /*
   * id of the content div element
   */
  contentId: PropTypes.string,
  /*
   * class name of the footer element
   */
  footerClassName: PropTypes.string,
  /**
   * indicates that content div element will not be rendered
   */
  withoutModalContent: PropTypes.bool,
  /**
   * Determines if this component is being used from the data set modal
   */
  isDataSetDEModal: PropTypes.bool,
  /*
   * defines whether the loading text should appear when the Save button is clicked
   */
  loadingSaveButton: PropTypes.bool,
  /**
   * title after pressing the Save button
   */
  loadingSaveButtonTitle: PropTypes.string,
  /**
   * class name of the parent div element enclosing modal and backdrop
   *
   */
  modalParentClassName: PropTypes.string,

  /**
   * Function that will be triggered if a user clicks on the X button
   */
  handleCloseModal: PropTypes.func,
  noHeaderTitle: PropTypes.bool,
  showCancelIcon: PropTypes.bool,
};

export default ModalTemplate;
