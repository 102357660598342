import axios from 'axios';

import Util from '../util';

const apiUrl = process.env.REACT_APP_API_URL;

const RelationsAPI = {
  /**
   * Get relations for current organization
   * @param {object} cancelToken - Token axios
   * @returns {object} Relations of current organization
   */
  getRelations: async (cancelToken) => {
    const res = await Util.catch418And403Error(axios.get, `${apiUrl}/relations`, Util.apiGetCallSettings(cancelToken));

    return res.data.data;
  },

  /**
   * Create a new relation based on given parameters
   * @param {object} postData - object of data
   * @param {object} cancelToken - Token axios
   * @returns {object} Newly created relation
   */
  createRelation: async (postData, cancelToken) => {
    const res = await Util.catch418And403Error(
      axios.post,
      `${apiUrl}/relations`,
      postData,
      Util.apiPostCallSettings(cancelToken),
    );

    return res.data;
  },

  /**
   * Updates an existing relation based on given parameters
   * @param {string} id - Id of the relation
   * @param {object} postData - object of data
   * @param {object} cancelToken - Token axios
   * @returns {object} Updated relation
   */
  updateRelation: async (
    id,
    postData,
    cancelToken,
  ) => {
    const res = await Util.catch418And403Error(
      axios.put,
      `${apiUrl}/relations/${id}`,
      postData,
      Util.apiPostCallSettings(cancelToken),
    );

    return res.data;
  },

  /**
   * Delete relation based the on given id
   * @param {string} id - Id of the relation that's going to be deleted
   * @param {object} cancelToken - Axios token
   * @returns {object} Deleted relation
   */
  deleteRelation: async (
    id,
    cancelToken,
  ) => {
    const res = await Util.catch418And403Error(
      axios.delete,
      `${apiUrl}/relations/${id}`,
      Util.apiPostCallSettings(cancelToken),
    );

    return res.data;
  },

  /**
   * Get one relation with all details
   * @param {string} relationId - Id of the relation document
   * @param {object} cancelToken - Axios token
   * @returns {Object} Returned relation document
   */
  getRelation: async (
    relationId,
    cancelToken,
  ) => {
    const res = await Util.catch418And403Error(
      axios.get,
      `${apiUrl}/relations/${relationId}`,
      Util.apiPostCallSettings(cancelToken),
    );

    return res.data.data;
  },

};

export default RelationsAPI;
