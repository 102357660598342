import React from 'react';

const Database = ({ className }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      // eslint-disable-next-line max-len
      d="M5 6.5C5 6.22386 5.22386 6 5.5 6H14.5C14.7761 6 15 6.22386 15 6.5C15 6.77614 14.7761 7 14.5 7H5.5C5.22386 7 5 6.77614 5 6.5ZM7 9.5C7 9.22386 7.22386 9 7.5 9H12.5C12.7761 9 13 9.22386 13 9.5C13 9.77614 12.7761 10 12.5 10H7.5C7.22386 10 7 9.77614 7 9.5ZM9 12C8.72386 12 8.5 12.2239 8.5 12.5C8.5 12.7761 8.72386 13 9 13H11C11.2761 13 11.5 12.7761 11.5 12.5C11.5 12.2239 11.2761 12 11 12H9Z"
      fill="#495D7A"
    />
  </svg>
);

export default Database;
