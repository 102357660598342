import React from 'react';
import PropTypes from 'prop-types';

import NavbarHeader from './NavbarHeader/NavbarHeader';

const Navbar = ({
  handleSearch,
  handleNavigator,
  handleChangeSearchCriteria,
  searchCriteria,
  selections,
  filterSelectList,
  searchField,
  searchCriteriaValue,
  searchBarLonger,
  handleSetOverviewState,
  handleSetAppState,
  overviewSection,
  refreshSelections,
  handleOpenCalendarView,
}) => (
  <NavbarHeader
    handleSearch={handleSearch}
    handleNavigator={handleNavigator}
    handleChangeSearchCriteria={handleChangeSearchCriteria}
    searchCriteria={searchCriteria}
    selections={selections}
    filterSelectList={filterSelectList}
    searchField={searchField}
    searchCriteriaValue={searchCriteriaValue}
    searchBarLonger={searchBarLonger}
    handleSetOverviewState={handleSetOverviewState}
    handleSetAppState={handleSetAppState}
    overviewSection={overviewSection}
    refreshSelections={refreshSelections}
    handleOpenCalendarView={handleOpenCalendarView}
  />
);

Navbar.propTypes = {
  /**
   * It helps to search for selections on the Overview page
   */
  handleSearch: PropTypes.func.isRequired,
  /**
   * handleNavigator prop is passed from App.js and it helps to navigate between Overview and Selection
   */
  handleNavigator: PropTypes.func.isRequired,
  /**
   * handleChangeCriteria is passed from Overview.js and it helps to change the search criteria for selections
   * on Overview page
   */
  handleChangeSearchCriteria: PropTypes.func.isRequired,
  /**
   * search criteria prop keeps the value of the property to search in the selections on Overview Page
   */
  searchCriteria: PropTypes.string.isRequired,
  /**
   * List of all selections
   */
  selections: PropTypes.instanceOf(Array),
  /**
   * Function to create a list of possible options for select, out of selection list
   */
  filterSelectList: PropTypes.func.isRequired,
  /**
   * Input of the search
   */
  searchField: PropTypes.string.isRequired,
  /**
   * Chosen option from possible options in select
   */
  searchCriteriaValue: PropTypes.string.isRequired,
  /**
   * Boolean making search input longer/shorter
   */
  searchBarLonger: PropTypes.bool.isRequired,
  /**
   * Function setting state of overview.js
   */
  handleSetOverviewState: PropTypes.func.isRequired,
  /**
   * handleSetAppState prop is passed from App.js
   * and it helps to set state of app
   */
  handleSetAppState: PropTypes.func.isRequired,
  /**
   * Defines which section in Overview is selected - selections or waterfall
   */
  overviewSection: PropTypes.string.isRequired,
  /**
   * Refresh and set the selections state on the front page
   */
  refreshSelections: PropTypes.func.isRequired,
  /**
   * Handles the opening of calendar view on the overview page
   */
  handleOpenCalendarView: PropTypes.func,
};

export default Navbar;
