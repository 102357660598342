const raxConfig = {
  // Retry 3 times on requests that return a response before giving up.
  retries: 3,

  // Retry 3 times on errors that don't return a response (ENOTFOUND, ETIMEDOUT, etc).
  noResponseRetries: 3,

  // Milliseconds to delay at first. Only considered when backoffType is 'static'
  retryDelay: 3000,

  // HTTP methods to automatically retry.
  httpMethodsToRetry: ['GET', 'HEAD', 'OPTIONS', 'DELETE', 'PUT', 'POST'],

  // The response status code to retry. Sets only for errors with no response
  statusCodesToRetry: [[0]],
  backoffType: 'static',
};

export default raxConfig;
