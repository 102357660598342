import React from 'react';
import './styles.scss';

/**
 * HTML for a navbar
 * @returns {object} A navbar
 */
const Navbar = () => (
  <div className="adminpanel_header" id="adminpanel-header">
    <div className="adminpanel_wrapper">
      <div className="adminpanel_wrapper_title">
        <h2>Admin Panel</h2>
      </div>
    </div>
  </div>
);

export default Navbar;
