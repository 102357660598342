import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Draggable } from 'react-beautiful-dnd';
import _ from 'lodash';

import './styles.scss';
import { removeSelectedSelection } from '../../../../../redux/actions/waterfallSelection/selectionActions';
import DroplineNameCard from './DroplineNameCard/DroplineNameCard';
import Constants from '../../../../../constants/constants';
import waterfallSelectionUtil from '../../../../../utils/waterfallSelection/waterfallSelectionUtil';
import Button from '../../../../shared/Button/Button';
import StatusIcon from '../../../../shared/StatusIcon/StatusIcon';
import SwalUtil from '../../../../../utils/swal/swalUtil';
import store from '../../../../../redux/store/store';
import Util from '../../../../../util';

const SelectionDropLine = ({
  selection,
  id,
  index,
  showArrowToNextStep,
  selectedSelections,
  isReadOnly,
  handleSetAppState,
  currentSelectionId,
  folderId,
  currentSelectionName,
  isArchived,
}) => {
  const dispatch = useDispatch();

  const {
    loadingTargetDEObjectIDs, targetDataExtensions, loadingSelectionIds,
    runStatusForSelectionChain, waterfallCopy, selectionsWithMissingTargetDE, selectionsWithNoMatchedField,
    runningQuery, waterfallSelectionStatus, runError, selectionsWithoutMappedRequiredFields,
    allSelections,
  } = useSelector(({ selectionReducer, targetDataExtensionReducer, globalReducer }) => ({
    loadingTargetDEObjectIDs: targetDataExtensionReducer.loadingTargetDEObjectIDs,
    targetDataExtensions: targetDataExtensionReducer.targetDataExtensions,
    loadingSelectionIds: selectionReducer.loadingSelectionIds,
    selectionsWithMissingTargetDE: selectionReducer.selectionsWithMissingTargetDE,
    selectionsWithNoMatchedField: selectionReducer.selectionsWithNoMatchedField,
    runStatusForSelectionChain: globalReducer.runStatusForSelectionChain,
    waterfallCopy: globalReducer.waterfallCopy,
    runningQuery: globalReducer.runningQuery,
    waterfallSelectionStatus: globalReducer.waterfallSelectionStatus,
    selectionsWithoutMappedRequiredFields: selectionReducer.selectionsWithoutMappedRequiredFields,
    runError: globalReducer.runError,
    allSelections: selectionReducer.allSelections,
  }), shallowEqual);

  // defines whether target data extension is loading
  const isTargetDELoading = !!((loadingTargetDEObjectIDs?.length &&
    loadingTargetDEObjectIDs.find(ck => ck ===
    selection.targetCollectionObjectID)));

  // defines the name of the targetDE belonging to the selection
  const targetDEName = targetDataExtensions?.length ?
    targetDataExtensions.find(targetDE => targetDE?.ObjectID ===
    selection.targetCollectionObjectID)?.Name :
    '';

  // defines whether selection is loading
  const isSelectionLoading = !!((loadingSelectionIds?.length &&
    loadingSelectionIds.find(ck => ck === selection.selectionId)));

  // defines the name of the selection
  const selectionName = selectedSelections?.length ?
    selectedSelections.find(select => select._id ===
    selection._id)?.name :
    '';

  // defines the selections from the previous step
  const previousSelection = (selectedSelections?.length && index?.toString() === '0') ?
    null :
    selectedSelections.find((select, idx) => idx === index - 1);

  // defines whether the selection uses the targetDE from the previous step as the selected data extension
  const isSelectionUseTargetDEAsSelectedDE = (selection?.selectedDEObjectIDs && previousSelection) ?
    !!selection.selectedDEObjectIDs.find(objectID => objectID ===
      previousSelection?.targetCollectionObjectID) :
    null;

  // defines the name of target data extension from the previous step
  const previousTargetDEName = targetDataExtensions?.length ?
    targetDataExtensions.find(targetDE => targetDE?.ObjectID ===
      previousSelection?.targetCollectionObjectID)?.Name :
    '';

  /**
   * Return the status of running query for the selection
   * @returns {number|null} number of run status or null if there is no status
   */
  const runStatusForSelection = () => {
    if (isReadOnly) {
      // define when waterfall selection is still running
      const isSelectionRunning = waterfallSelectionUtil.isWaterfallSelectionRunning(
        waterfallSelectionStatus,
        runningQuery,
      );

      let runStatus;

      if (runStatusForSelectionChain?.length) {
        // get run status from runStatusForSelectionChain
        runStatus = runStatusForSelectionChain.find((status, i) => selection?.selectionId ?
          (status.selectionId ===
          selection.selectionId && i === index) :
          i === index)?.runStatus;
      } if (isSelectionRunning && !runStatus) {
        // if query is running set queued for waterfall status
        runStatus = Constants.STATUS_QUEUED_FOR_WATERFALL;
      } if (runError && runError.selectionId === selection.selectionId) {
        // if run is failed and returns error for selection
        runStatus = Constants.STATUS_ERROR;
      }

      return runStatus;
    }

    return null;
  };

  // check if this selection has no targetDE
  const isThisSelectionHasNoTargetDE = !!(selectionsWithMissingTargetDE?.length && selectionsWithMissingTargetDE.find(
    selectionId => selectionId === selection.selectionId,
  ));

  // check if this selection has no matched fields with targetDE
  const isThisSelectionHasNoMatchedField = !!(selectionsWithNoMatchedField?.length && selectionsWithNoMatchedField.find(
    selectionId => selectionId === selection.selectionId,
  ));

  // check if this selection no mapped fields with all the required fields of target data extension
  const isThisSelectionHasNoMappedFieldsWithRequired = !isThisSelectionHasNoMatchedField &&
    !!(selectionsWithoutMappedRequiredFields?.length && selectionsWithoutMappedRequiredFields.find(
      selectionId => selectionId === selection.selectionId,
    ));

  /**
   * Return className for wrapper, depending of isDragging property
   * @param {boolean} isDragging - Returns true if a drag operation is in progress
   * @returns {string} class name
   */
  const getDropLineWrapperClassName = isDragging => classNames(
    'selection-dropline-wrapper',
    // eslint-disable-next-line quote-props
    { 'isDragging': isDragging },
  );

  /**
   * Function that removes selected selection from selection container
   * @param {object} removedSelection - selection to be removed
   * @returns {void}
   */
  const handleRemoveSelectedSelection = (removedSelection) => {
    // define how many selections use the same target Data Extension as removed one
    const selectionsUsingTheSameTargetDE = selectedSelections.filter(
      select => select.targetCollectionObjectID ===
      removedSelection.targetCollectionObjectID,
    );

    // get selection objects from selections chain in run detail
    const selectionsInRunChain = waterfallSelectionUtil.getSelectionsData(runStatusForSelectionChain, allSelections);

    // check if targetDE is used in selections in run chain
    const removedSelectionInRunChain = selectionsInRunChain?.length ?
      selectionsInRunChain.find(selectionObj => selectionObj.targetCollectionObjectID ===
      removedSelection.targetCollectionObjectID) :
      null;

    // dispatch action to remove selected selection from redux state
    dispatch(removeSelectedSelection(
      removedSelection,
      selectionsUsingTheSameTargetDE,
      !!removedSelectionInRunChain,
    ));
  };

  /**
   * Function that open selection from waterfall selection
   * @returns {void}
   */
  const handleOpenSelection = async () => {
    // get the current state
    const currentState = store.getState();

    // current state without some property
    const currentStateWithoutProperties = Util.returnStateWithoutSomeProperties(currentState);

    // saved copy of the state without some property
    const copyStateWithoutProperties = Util.returnStateWithoutSomeProperties(waterfallCopy);

    // compare copy with current state
    const stateHasNotChanged = _.isEqual(currentStateWithoutProperties, copyStateWithoutProperties);

    const appState = {
      backToWaterFall: {
        _id: currentSelectionId, folderId, name: currentSelectionName, actionType: Constants.BACKTOWATERFALL__TYPE_OPEN,
      },
      currentSelectionName: selection?.name,
      currentSelectionId: selection?.selectionId,
      navigator: Constants.NAVIGATION__SELECTION,
      globalNavigator: Constants.NAVIGATION__SELECTION,
      folderId: selection?.folderId,
    };

    if(stateHasNotChanged) {
      handleSetAppState(appState);
    } else {
    // show warning of unsaved changes
      const res = await SwalUtil.fire({
        title: 'Confirmation needed',
        message: `Are you sure you want to open
        <b style='font-weight: 700'> ${selection?.name || ''}</b> seletion?
        Unsaved changes will be lost.`,
        options: {
          confirmButtonText: 'Yes',
          showCancelButton: true,
          allowOutsideClick: false,
        },
      });

      // if pressed ok, open the seletion
      if (res.value) {
        handleSetAppState(appState);
      }
    }
  };

  /**
   * Returns content for dropline card with steps
   * @returns {object} HTML for the content
   */
  const returnDroplineStepsWrapper = () => (
    <>
      <div className={`dropline-steps-wrapper ${isReadOnly}`}>
        {isReadOnly ?
          <StatusIcon status={runStatusForSelection()} /> :
          (
            <div className="remove-dropline">
              <Button
                noButtonClass
                className="remove-line"
                onClick={(e) => { e?.stopPropagation(); handleRemoveSelectedSelection(selection); }}
                noSpan
              >
                <i className="far fa-times-circle remove-filter" />
              </Button>
            </div>
          )}
        <div className="step-number-label">
          <p>
            Step
            {' '}
            {index + 1}
          </p>
        </div>
      </div>
      <DroplineNameCard
        isLoading={isSelectionLoading}
        name={selectionName}
        isSelectionUseTargetDEAsSelectedDE={isSelectionUseTargetDEAsSelectedDE}
        previousTargetDEName={previousTargetDEName}
        isThisSelectionHasNoMatchedField={isThisSelectionHasNoMatchedField}
        isThisSelectionHasNoMappedFieldsWithRequired={isThisSelectionHasNoMappedFieldsWithRequired}
        removedSelection={selection?.removedSelection}
        handleOpenSelection={handleOpenSelection}
        isArchived={isArchived}
        isReadOnly={isReadOnly}
      />
      <div className={`connecting-line-wrapper ${isReadOnly}`}>
        <div className="connecting-line" />
      </div>
      <DroplineNameCard
        isLoading={isTargetDELoading}
        isTargetDE
        name={targetDEName}
        isThisSelectionHasNoTargetDE={isThisSelectionHasNoTargetDE}
        removedSelection={selection?.removedSelection}
        isArchived={isArchived}
      />
      {isReadOnly ?
        null :
        (
          <svg
            className="slds-button__icon drag-and-drop-waterfall-selection"
            aria-hidden="true"
          >
            <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#drag_and_drop" />
            <title>Grab and move this selection to change the order in the chain.</title>
          </svg>
        )}
      {showArrowToNextStep(index)}
    </>
  );

  return (
    <>
      {selection && !isReadOnly ?
        (
          <Draggable
            draggableId={id}
            index={index}
            isDragDisabled={isReadOnly}
          >
            {(provided, snapshot) => (
              <div
                  /* eslint-disable react/jsx-props-no-spreading */
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                  /* eslint-enable react/jsx-props-no-spreading */
                ref={provided.innerRef}
                className={getDropLineWrapperClassName(snapshot.isDragging)}
              >
                {returnDroplineStepsWrapper()}
              </div>
            )}
          </Draggable>
        ) :
        null}

      {selection && isReadOnly ?
        (
          <div className={getDropLineWrapperClassName()}>
            {returnDroplineStepsWrapper()}
          </div>
        ) :
        null}
    </>
  );
};

SelectionDropLine.propTypes = {
  /*
   * Dropped selection object
   */
  selection: PropTypes.instanceOf(Object).isRequired,
  /*
   * Id of the dropped selection
   */
  id: PropTypes.string.isRequired,
  /*
   * Index of the dropped selection
   */
  index: PropTypes.number.isRequired,
  /*
   * Function that renders arrow to the next step
   */
  showArrowToNextStep: PropTypes.func.isRequired,
  /*
   * Array with selected selections
   */
  selectedSelections: PropTypes.instanceOf(Array).isRequired,
  /*
   * Indicates that component is read-only, without drag&drop functionality
   */
  isReadOnly: PropTypes.bool,
  /*
   * helps to set state in app.js
   */
  handleSetAppState: PropTypes.func.isRequired,
  /*
   * the id of the currently open WF selection
   */
  currentSelectionId: PropTypes.string.isRequired,
  /*
   * id of selected folder
   */
  folderId: PropTypes.string.isRequired,
  /*
   * the name of the currently open waterfall selection
   */
  currentSelectionName: PropTypes.string.isRequired,
  /**
   * Indicates if the waterfall selection is archived or not
   */
  isArchived: PropTypes.bool.isRequired,
};

export default SelectionDropLine;
