import React from 'react';
import PropTypes from 'prop-types';

import ModalTemplate from './components/shared/ModalTemplate/ModalTemplate';

/**
 * HTML for a network error modal
 * @param {object} param - An object with 'error' and 'cancelError' properties
 * @returns {object} The modal
 */
const NetworkErrorModal = ({ error, cancelError }) => (
  <ModalTemplate
    id="network-error-modal"
    headerId="modal-heading-01"
    headerTitle="Network error"
    contentClassName="slds-p-around_medium network-error-body"
    handleCancel={error ? cancelError : null}
    cancelButtonId={error ? 'cancelError' : null}
    cancelButtonTitle="OK"
  >
    <p className="slds-m-top_x-small">
      Connecting to DESelect... Please check your internet connection.
    </p>
  </ModalTemplate>
);

NetworkErrorModal.propTypes = {
  /*
   * if error doesn't equal to a network error like 401, 404 etc. but if the browser is not connected a network,
   * it is true else it is false
   * this props is passed from App.js
   */
  error: PropTypes.bool.isRequired,
  /*
   * if error prop is true then it sets the App component's states (check the function for them) else it is null
   * this prop is a function and it is passed from App.js
   */
  cancelError: PropTypes.func,
};
export default NetworkErrorModal;
