import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon } from '@salesforce/design-system-react';

import Util from '../../../../../../util';
import Constants from '../../../../../../constants/constants';
import Database from '../../../../../../icons_v2/database.svg';

const DroplineSelectionInfo = ({
  name,
  isArchived,
  isReadOnly,
  isTargetDE,
  isThisSelectionHasNoMatchedField,
  isThisSelectionHasNoMappedFieldsWithRequired,
  removedSelection,
  isSelectionUseTargetDEAsSelectedDE,
  isThisSelectionHasNoTargetDE,
  dataForSwal,
  showSwalForErrorIcon,
  showSwalForWarningIcon,
  handleOpenSelection,
}) => {
  const [abbreviatedName, setAbbreviatedName] = useState(name);
  const spanRef = useRef(null);

  useEffect(() => {
    const updateAbbreviation = () => {
      if (spanRef.current) {
        const availableWidth = spanRef.current.offsetWidth;
        const charWidth = 8; // Approximate width of a character in pixels
        const maxChars = Math.floor(availableWidth / charWidth);
        const abbreviationLength = isReadOnly ? Math.max(30, maxChars) : Math.max(20, maxChars);

        setAbbreviatedName(Util.abbreviate(name, abbreviationLength));
      }
    };

    const resizeObserver = new ResizeObserver(updateAbbreviation);
    const currentSpanRef = spanRef.current;

    if (currentSpanRef) {
      resizeObserver.observe(currentSpanRef);
    }

    updateAbbreviation(); // Initial calculation

    return () => {
      if (currentSpanRef) {
        resizeObserver.unobserve(currentSpanRef);
      }
    };
  }, [name, isReadOnly]);

  return (
    <div
      className="selection-info"
      title={isTargetDE || isReadOnly ? '' : 'Open Selection'}
    >
      <img src={Database} alt="Database icon" className="selection-name-icon" />
      <span
        ref={spanRef}
        className={classNames('selection-name-wrapper', { disabled: isArchived })}
        title={name}
        disabled={isArchived}
      >
        {abbreviatedName}
        {isThisSelectionHasNoMatchedField ||
          isThisSelectionHasNoMappedFieldsWithRequired ||
          removedSelection ?
          (
            <i title={dataForSwal().title} className="error-icon-wrapper">
              <svg
                className="slds-icon slds-icon-text-default slds-icon_x-small status-error"
                aria-hidden="true"
                onClick={showSwalForErrorIcon}
              >
                <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#error" />
              </svg>
            </i>
          ) :
          null}
      </span>

      {isSelectionUseTargetDEAsSelectedDE === false && !isThisSelectionHasNoTargetDE ?
        (
          <i
            title={
              `${Constants.SWAL_TITLE__SELECTION_DOES_NOT_USE_PREVIOUS_TARGET_DE} ` +
              'as a Selected Data Extension, in filters, or in Custom Values'
            }
            className="fas fa-exclamation-triangle link status-warning waterfall-selection-icon"
            onClick={showSwalForWarningIcon}
          />
        ) :
        null}

      {!isReadOnly && (
        <div
          className="open-selection-container"
          onClick={() => !isTargetDE && !isArchived && handleOpenSelection()}
        >
          <Icon
            assistiveText={{ label: 'Open Selection' }}
            category="utility"
            name="new_window"
            size="small"
            className="open-selection-btn"
          />
        </div>
      )}
    </div>
  );
};

DroplineSelectionInfo.propTypes = {
  /*
   * name for selection or targetDE
   */
  name: PropTypes.string,

  /**
   * Indicates if the waterfall selection is archived or not
   */
  isArchived: PropTypes.bool.isRequired,

  /*
   * Indicates that component is read-only, without drag&drop functionality
   */
  isReadOnly: PropTypes.bool,

  /*
   * defines whether the component is used for targetDE
   */
  isTargetDE: PropTypes.bool,

  /*
   * indicates that no matched field was found for this selection
   */
  isThisSelectionHasNoMatchedField: PropTypes.bool,

  /*
   * indicates that all required fields of target data extension have not been mapped
   */
  isThisSelectionHasNoMappedFieldsWithRequired: PropTypes.bool,

  /*
   * indicates that selection no longer exists
   */
  removedSelection: PropTypes.bool,

  /*
   * defines whether the selection uses the targetDE from the previous step as the selected data extension
   */
  isSelectionUseTargetDEAsSelectedDE: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.instanceOf(null),
  ]),

  /*
   * indicates that TargetDE was not found for this selection
   */
  isThisSelectionHasNoTargetDE: PropTypes.bool,

  /*
   * function that returns data for SweetAlert
   */
  dataForSwal: PropTypes.func.isRequired,

  /*
   * function to show SweetAlert for error icon
   */
  showSwalForErrorIcon: PropTypes.func.isRequired,

  /*
   * function to show SweetAlert for warning icon
   */
  showSwalForWarningIcon: PropTypes.func.isRequired,

  /*
   * function that open selection from waterfall selection
   */
  handleOpenSelection: PropTypes.func,
};

export default DroplineSelectionInfo;
