import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import Constants from '../../../../constants/constants';
import Button from '../../../shared_v2/Button/Button';
import GuidanceTip from '../../../shared_v2/GuidanceTip/GuidanceTip';

/**
 * Get the classes for the arrow-1 'li' elements
 * @param {string} selectionNavigator - Current page's navigation value
 * @param {array} selectedElements - selectedElements - could be SelectedDataExtensions of the Selection
 * or selected Selections
 * @returns {string} The correct classes for the arrow-1 'li' elements
 */
const arrow1ClassName = (selectionNavigator, selectedElements) => {
  // define class name for completed path item
  const pathItemCompleteClassName = `slds-path__item slds-is-complete selection-navigator-${selectionNavigator}`;

  // if we are on the Selection Criteria (selection) or Steps (waterfall) page
  if (selectionNavigator === Constants.NAVIGATION__SELECTION_CRITERIA ||
    selectionNavigator === Constants.NAVIGATION__STEPS) {
    if (selectedElements && selectedElements.length > 0) {
      return `${pathItemCompleteClassName} selected`;
    }

    return 'slds-path__item slds-is-current slds-is-active btn-active';
  }
  if (selectedElements && selectedElements.length > 0) {
    return pathItemCompleteClassName;
  }

  return 'slds-path__item slds-is-incomplete';
};

/**
 * Get the classes for the arrow-2 'li' elements
 * @param {string} selectionNavigator - Current page's navigation value
 * @param {array} matchedFields - MatchedFields for a target data extension of the Selection
 * @returns {string} The correct classes for the arrow-2 'li' elements
 */
const arrow2ClassName = (selectionNavigator, matchedFields) => {
  if (selectionNavigator === Constants.NAVIGATION__TARGET_DEFINITION) {
    if (matchedFields && matchedFields.length > 0) return 'slds-is-complete selected';

    return 'slds-is-current slds-is-active target-def-btn-active';
  }
  if (matchedFields && matchedFields.length > 0) return 'slds-is-complete';

  return '';
};

/**
 * Get the classes for the arrow-3 'li' elements
 * @param {string} selectionNavigator - Current page's navigation value
 * @param {number} previewStatus - Status of the preview task (quued, completed etc)
 * @param {string} previewQueryActivityId - Preview query activity id for the selection if the preview is run before
 * @param {number} numberOfResults - Number of results for a Selection's query after a run
 * @param {boolean} editNewAutoTargetDE - Defines if new auto target DE is edited
 * @param {boolean} editTargetDataExtension - Defines if Edit Target DE fields mode is enabled
 * @param {boolean} disableRunDetails - Defines if button for Run Details view is disabled
 * @returns {string} The correct classes for the arrow-3 'li' elements
 */
const arrow3ClassName = (
  selectionNavigator,
  previewStatus,
  previewQueryActivityId,
  numberOfResults,
  editNewAutoTargetDE,
  editTargetDataExtension,
  disableRunDetails,
) => {
  if (disableRunDetails) {
    // disable button for Run Details in waterfall
    return 'slds-is-incomplete opacity-for-disable';
  }
  const completeClassNameWithoutError = `slds-is-complete
  ${editNewAutoTargetDE || editTargetDataExtension ?
    'slds-path__item' :
    'slds-is-incomplete'}
  selection-navigator-${selectionNavigator}`;

  // if we are on the Preview (selection) or Run Details (waterfall) page
  if (selectionNavigator === Constants.NAVIGATION__PREVIEW ||
    selectionNavigator === Constants.NAVIGATION__RUN_DETAILS) {
    // if preview is completed and there is no error then set className of the Preview tab btn to certain value
    if (previewStatus !== Constants.STATUS_ERROR && previewStatus === Constants.STATUS_COMPLETE) {
      return `${completeClassNameWithoutError} selected`;
    }

    return `slds-is-current slds-is-active run-preview-btn-active
      ${editNewAutoTargetDE || editTargetDataExtension ?
    'slds-path__item' :
    'slds-is-incomplete'}`;
  }

  // if we are not on the Preview page and preview is completed
  if (previewStatus !== Constants.STATUS_ERROR && previewStatus === Constants.STATUS_COMPLETE) {
    // then set className of the Preview tab btn to the certain value
    return completeClassNameWithoutError;
  }

  return `${editNewAutoTargetDE || editTargetDataExtension ?
    'slds-is-incomplete opacity-for-disable' :
    'slds-is-incomplete_v2'}`;
};

const CriteriaNavigation = ({
  selectedDataExtensions,
  selectionNavigation,
  selectionNavigator,
  matchedFields,
  previewQueryActivityId,
  numberOfResults,
  previewStatus,
  editNewAutoTargetDE,
  editTargetDataExtension,
  waterfallSelection,
  selectedSelections,
  disableRunDetails,
}) => {
  /**
   * Handles click on the first arrow
   * @returns {void}
   */
  const onClickFirstArrow = () => waterfallSelection ?
    selectionNavigation(Constants.NAVIGATION__STEPS) :
    selectionNavigation(Constants.NAVIGATION__SELECTION_CRITERIA);

  const firstArrowTitle = waterfallSelection ?
    'Input' :
    '1. Selection Criteria';

  const thirdArrowTitle = waterfallSelection ?
    'Run Details' :
    '3. Preview';

  /**
   * Handles click on the third arrow
   * @returns {void}
   */
  const handleThirdArrowClick = () => {
    if (editNewAutoTargetDE || editTargetDataExtension || disableRunDetails) {
      return null;
    } if (waterfallSelection) {
      // for waterfall selection go to Run Details view
      selectionNavigation(Constants.NAVIGATION__RUN_DETAILS);
    } else {
      // for selection go to Preview
      selectionNavigation(Constants.NAVIGATION__PREVIEW);
    }

    return null;
  };

  return (
    <div className="selection_criteriaNavigation-bottom-v2">
      <article className="slds-card">
        <div className="slds-card__body slds-card__body_inner">
          <div className="slds-path">
            <div className="slds-grid slds-path__track">
              <div className="slds-grid slds-path__scroller-container">
                <div className="slds-path__scroller" role="application">
                  <div className="slds-path__scroller_inner">
                    <ul className="slds-path__nav" role="listbox" aria-orientation="horizontal">
                      <li
                          role="presentation"
                          onClick={onClickFirstArrow}
                          id="arrow-1"
                          className={arrow1ClassName(selectionNavigator, selectedDataExtensions || selectedSelections)}
                        >
                          <a
                            aria-selected="true"
                            className="slds-path__link"
                            href="#!"
                            id="path-1"
                            role="option"
                            tabIndex="0"
                          >
                            <span className="slds-path__stage">
                              <span className="slds-assistive-text">Current Stage:</span>
                            </span>
                            <span className="slds-path__title">{firstArrowTitle}</span>
                          </a>
                      </li>
                      <div className="guidance-parent-selection-criteria">
                        <GuidanceTip tipId="selection-criteria-tip" />
                      </div>
                      {waterfallSelection ?
                        null :
                        (
                            <li
                              role="presentation"
                              onClick={() => selectionNavigation(
                                Constants.NAVIGATION__TARGET_DEFINITION,
                              )}
                              id="arrow-2"
                        // eslint-disable-next-line max-len
                              className={`slds-path__item slds-is-incomplete ${arrow2ClassName(selectionNavigator, matchedFields)}`}
                            >
                              <a
                                aria-selected="false"
                                className="slds-path__link"
                                href="#!"
                                id="path-2"
                                role="option"
                                tabIndex="-1"
                              >
                                <span className="slds-path__title">2. Target Definition</span>
                              </a>
                            </li>
                        )}
                      <div className="guidance-parent-target-definition">
                        <GuidanceTip tipId="target-definition-tip" />
                      </div>

                      <li
                          role="presentation"
                          onClick={handleThirdArrowClick}
                          id="arrow-3"
                          className={`slds-path__item ${arrow3ClassName(
                            selectionNavigator,
                            previewStatus,
                            previewQueryActivityId,
                            numberOfResults,
                            editNewAutoTargetDE,
                            editTargetDataExtension,
                            disableRunDetails,
                          )}`}
                          disabled={!!(editNewAutoTargetDE || editTargetDataExtension || disableRunDetails)}
                        >
                          <a
                            aria-selected="false"
                            className="slds-path__link"
                            href="#!"
                            id="path-3"
                            role="option"
                            tabIndex="-1"
                          >
                            <span className="slds-path__title">{thirdArrowTitle}</span>
                          </a>
                      </li>
                      <div className="guidance-parent-preview">
                        <GuidanceTip tipId="preview-page-tip" />
                      </div>
                    </ul>
                    {/* Switchers */}
                    {waterfallSelection ?
                      null :
                      (
                          <div className="slds-path__scroll-controls" id="controlers-RL">
                            <Button
                              buttonIconBorderFilled
                              title="Go to Previous Screen"
                              id="previous-button"
                              disabled={selectionNavigator === Constants.NAVIGATION__SELECTION_CRITERIA}
                              onClick={() => {
                                if (selectionNavigator === Constants.NAVIGATION__TARGET_DEFINITION) {
                                  selectionNavigation(Constants.NAVIGATION__SELECTION_CRITERIA);
                                }
                                if (selectionNavigator === Constants.NAVIGATION__PREVIEW) {
                                  selectionNavigation(Constants.NAVIGATION__TARGET_DEFINITION);
                                }
                              }}
                            >
                              <svg className="slds-button__icon" aria-hidden="true">
                                <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#left" />
                              </svg>
                              <span className="slds-assistive-text">Previous</span>
                            </Button>

                            <Button
                              buttonIconBorderFilled
                              title="Go to Next Screen"
                              disabled={!!(((editNewAutoTargetDE || editTargetDataExtension) &&
                              selectionNavigator === Constants.NAVIGATION__TARGET_DEFINITION) ||
                              selectionNavigator === Constants.NAVIGATION__PREVIEW)}
                              onClick={() => {
                                if (selectionNavigator === Constants.NAVIGATION__SELECTION_CRITERIA) {
                                  selectionNavigation(Constants.NAVIGATION__TARGET_DEFINITION);
                                }
                                if (selectionNavigator === Constants.NAVIGATION__TARGET_DEFINITION &&
                            (!editNewAutoTargetDE || !editTargetDataExtension)) {
                                  selectionNavigation(Constants.NAVIGATION__PREVIEW);
                                }
                              }}
                              id="next-button"
                            >
                              <svg className="slds-button__icon" aria-hidden="true">
                                <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#right" />
                              </svg>
                              <span className="slds-assistive-text">Next</span>
                            </Button>
                          </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </div>
  );
};

CriteriaNavigation.propTypes = {
  /*
   * This function helps to navigate between pages in a Selection
   * This prop will be passed from Selection.js through Navbar.js
   */
  selectionNavigation: PropTypes.func,
  /*
   * This prop keep the current page`s navigation value
   * This prop will be passed from Selection.js through Navbar.js
   */
  selectionNavigator: PropTypes.string.isRequired,
  /*
   * This prop keeps the selectedDataExtensions of the Selection
   * It will be passed from Selection.js through Navbar.js
   */
  selectedDataExtensions: PropTypes.instanceOf(Array),
  /*
   * It keeps the matchedFields for a target data extension of the Selection
   * It will be passed from Selection.js through Navbar.js
   */
  matchedFields: PropTypes.instanceOf(Array),
  /*
   * it keeps the previewQueryActivityId for the selection if the preview is run before
   * It will be passed from Selection.js through Navbar.js
   */
  previewQueryActivityId: PropTypes.string,
  /*
   * It keeps the number of results for a Selection`s query after a run
   * It will be passed from Selection.js through Navbar.js
   */
  numberOfResults: PropTypes.number,
  /*
   * It keeps the status of the preview task (quued, completed etc)
   * It will be passed from Selection.js through Navbar.js
   */
  previewStatus: PropTypes.number,
  /*
   * Defines if new auto target DE is edited
   */
  editNewAutoTargetDE: PropTypes.bool,
  /*
   * Defines if Edit Target DE fields mode is enabled
   */
  editTargetDataExtension: PropTypes.bool,
  /*
   * defines if a component is used in a waterfall selection
   */
  waterfallSelection: PropTypes.bool,
  /*
   * array with selected selections in waterfall selection
   */
  selectedSelections: PropTypes.instanceOf(Array),
  /*
   * defines if button for Run Details view is disabled
   */
  disableRunDetails: PropTypes.bool,
};
export default CriteriaNavigation;
