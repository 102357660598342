import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.scss';
import _ from 'lodash';

import Expand from '../../../icons/chevronright.svg';
import Collapse from '../../../icons/chevrondown.svg';
import Util from '../../../util';
import Constants from '../../../constants/constants';

/**
 * Component Folders
 * @param {*} props - The props
 * @returns {object} HTML for the Folders component
 */
function Folders({
  folders,
  id,
  handleFolderClicked,
  rightClick,
  dropOnFolder,
  folderBorderDragOver,
  startDraggingFolder,
  handleSetOverviewState,
  filterFolderId,
  draggedFolderId,
  isReadOnly,
  handleSetAppState,
  folderSettings,
  allFoldersStatus,
}) {
  /**
   * Returns the folder if it was found in the folder settings
   * @param {array} settings - array with settings
   * @param {object|string} folderOrId - selected folder or folder id
   * @returns {object|null} object if found, null otherwise
   */
  const getFolderFromSettings = (settings, folderOrId) => settings?.length ?
    settings?.find(folId => folId === folderOrId?._id || folId === folderOrId) :
    null;

  /**
   * Indicates whether the folder is closed
   * @param {object|string} folderOrId - selected folder or folder id
   * @returns {boolean} true if folder is closed, false otherwise
   */

  const isFolderClosed = (folderOrId) => {
    const { closed, open } = folderSettings || {};

    // get folder status from settings - open or closed
    const closedFolder = getFolderFromSettings(closed, folderOrId);
    const openFolder = getFolderFromSettings(open, folderOrId);

    // return folder as closed if found, or default if not found in any settings
    if(!closedFolder && !openFolder || closedFolder || (!closedFolder &&
      allFoldersStatus === Constants.SELECTION_FOLDER_SETTINGS__CLOSED__VALUE && !openFolder)) {
      return true;
    }

    return false;
  };

  /**
   * Returns true if the selected folder is the drop target
   * @param {string} id - drop target folder id
   * @returns {boolean} true/false
   */
  const isDropOnSelectedFolder = id => id === filterFolderId;

  /**
   * Make border on drag over
   * @param {object} e - e.target
   * @param {boolean} border - If border exists or not
   * @returns {void}
   */
  const makeBorder = (e, border) => {
    const { target: { dataset: { id }, nodeName } } = e || {};

    // When dragging a folder to itself, dont add border to dragged folder
    if (draggedFolderId && draggedFolderId === id) return;

    // If selection or folder is dragged and is over the folder, add the border
    if (nodeName.toString().toLowerCase() === 'span') {
      e.target.className = classNames(
        'folderDrop',
        {
          /* eslint-disable quote-props */
          // when the drop target is not a selected folder
          'border_folder': (border && folderBorderDragOver && !isDropOnSelectedFolder(id)),
          // in other case
          'mark_all': isDropOnSelectedFolder(id),
          /* eslint-enable quote-props */
        },
      );
    }
  };

  /**
   * Determines if a folder has children
   * @param {object} folder - A folder object
   * @param {object} folder.children - a children property from folder object
   * @param {object} folder._id - folder id
   * @returns {boolean} True if folder contains children, false otherwise
   */
  const hasChildren = ({ children, _id }) => {
    if (!children || children.length === 0 || isFolderClosed(_id)) return false;

    return true;
  };

  /**
   * Open children if parent has them
   * @param {object} e - e.target
   * @param {object} folder - e.target
   * @returns {void}
   */
  const openFolderChildren = (e, folder) => {
    /**
     * retrieves the folder icon and change it, open => close OR close => open
     * @returns {void}
     */
    const changeFolderIcon = () => {
      const folderIcon = e.target.parentElement.getElementsByTagName('i')[0];

      let updatedFolderSettings = { ...folderSettings };

      const { closed, open } = updatedFolderSettings || {};
      const { _id } = folder || {};

      // define that folder will be closed
      const willBeClosed = folderIcon.classList.toggle('fa-folder') ||
        !isFolderClosed(folder);

      // define that folder will be open
      const willBeOpen = folderIcon.classList.toggle('fa-folder-open') ||
        isFolderClosed(folder);

      if (willBeOpen) {
        // remove folder from closed folders, add to open folders
        updatedFolderSettings = {
          closed: closed?.length ? closed.filter((id => id !== _id)) : [],
          open: [...open, _id],
        };
      } else if (willBeClosed) {
        // add folder id to the closed folders, remove from open folders
        updatedFolderSettings = {
          closed: [...closed, _id],
          open: open?.length ? open.filter((id => id !== _id)) : [],
        };
      }

      // update the App state
      handleSetAppState({ folderSettings: updatedFolderSettings });
    };

    /**
     * Find the +/- button.
     * It might be the event's target or not depending if user clicks on the +/- button or on the folder name.
     * In case it doesn't exist, we know for sure this folder doesn't have any child so exit this function
     */
    const expandCollapseTag = Object.prototype.hasOwnProperty.call(e.target, 'src') ?
      e.target :
      e.target.parentElement.getElementsByTagName('img')[0];

    if (!expandCollapseTag) return;

    expandCollapseTag?.parentElement?.nextElementSibling?.classList.toggle('open_children');
    if (expandCollapseTag.src === Expand) {
      expandCollapseTag.src = Collapse;
    } else {
      expandCollapseTag.src = Expand;
    }

    changeFolderIcon();
  };

  /**
   * Handles folder selection
   * @param {object} folder - selected folder
   * @returns {void}
   */
  const handleSelectFolder = (folder) => {
    handleFolderClicked(folder._id);

    // for the folders in overview
    if (handleSetOverviewState) {
      handleSetOverviewState({ openSelections: false });
    }
  };

  // sort folders alphabetically
  const sortedFolders = _.sortBy(folders, o => o?.name?.toString()?.toLowerCase());
  /**
   * Returns class name for the folder container
   * @param {object} folder - folder
   * @returns {string} className
   */
  const folderDropContainerClassName = folder => classNames(
    'folderDrop',
    {
      /* eslint-disable quote-props */
      'active': folder._id === id && !isDropOnSelectedFolder(folder._id),
      'mark_all': isDropOnSelectedFolder(folder._id),
      /* eslint-enable quote-props */
    },
  );

  return (
    <div className="overview_folders_container">
      <ul>
        {sortedFolders &&
          sortedFolders.map(folder => (
            <li key={folder._id}>
              <span
                className={folderDropContainerClassName(folder)}
                onDragOver={e => e.preventDefault()}
                onDrop={(e) => {
                  // Needed to prevent a redirect
                  e.preventDefault();
                  if (!isReadOnly) {
                    makeBorder(e, false);
                    dropOnFolder(e);
                    handleSetOverviewState({ blurDrop: false });
                  }
                }}
                data-id={folder._id}
                data-name={folder.name}
                onDragEnter={e => makeBorder(e, true)}
                onDragLeave={e => makeBorder(e, false)}
                draggable
                onDragStart={() => !isReadOnly && startDraggingFolder(folder._id, folder.name)}
                onDragEnd={() => !isReadOnly && handleSetOverviewState({ folderBorderDragOver: false })}
                onContextMenu={e => !isReadOnly && rightClick(e, folder._id)}
                onDoubleClick={openFolderChildren}
              >
                {folder?.children?.length && (
                  <img
                    className="openFolder_icon"
                    src={isFolderClosed(folder) ? Expand : Collapse}
                    onClick={e => openFolderChildren(e, folder)}
                    alt="icon"
                    style={{ pointerEvents: folderBorderDragOver && 'none' }}
                  />
                )}
                <i
                  id="folder-icon"
                  className={`fas ${hasChildren(folder) ? 'fa-folder-open' : 'fa-folder'}`}
                  style={{
                    pointerEvents: folderBorderDragOver && 'none',
                  }}
                  onClick={() => handleSelectFolder(folder)}
                />
                <p
                  title={folder.name || folder.Name}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: Util.abbreviate(folder.name, 28) }}
                  className="folder_element"
                  onClick={() => handleSelectFolder(folder)}
                  style={{
                    pointerEvents: folderBorderDragOver && 'none',
                  }}
                />
              </span>
              {folder.children && folder.children.length > 0 && !isFolderClosed(folder) ?
                (
                  <Folders
                    folders={folder.children}
                    id={id}
                    handleFolderClicked={handleFolderClicked}
                    rightClick={rightClick}
                    dropOnFolder={dropOnFolder}
                    folderBorderDragOver={folderBorderDragOver}
                    startDraggingFolder={startDraggingFolder}
                    handleSetOverviewState={handleSetOverviewState}
                    filterFolderId={filterFolderId}
                    draggedFolderId={draggedFolderId}
                    isReadOnly={isReadOnly}
                    handleSetAppState={handleSetAppState}
                    folderSettings={folderSettings}
                    allFoldersStatus={allFoldersStatus}
                  />
                ) :
                null}
            </li>
          ))}
      </ul>
    </div>
  );
}

Folders.propTypes = {
  /**
   * Folders for selection save
   */
  folders: PropTypes.instanceOf(Object).isRequired,
  /**
   * id of current selected folder
   */
  id: PropTypes.string.isRequired,
  /**
   * It helps to handle with click on some folder
   */
  handleFolderClicked: PropTypes.func.isRequired,
  /**
   * Right click to open sub menu
   */
  rightClick: PropTypes.func,
  /**
   * Drop folder on folder
   * Drop selection on folder
   */
  dropOnFolder: PropTypes.func,
  /**
   *  make border when drag over
   */
  folderBorderDragOver: PropTypes.bool,
  /**
   * When dragging folder to folder
   */
  startDraggingFolder: PropTypes.func,
  /**
   * Function setting state of overview.js
   */
  handleSetOverviewState: PropTypes.func,
  /**
   * based on its id, display proper selections and open proper folder icon
   */
  filterFolderId: PropTypes.string,
  /**
   * id of dragged folder
   */
  draggedFolderId: PropTypes.string,
  /**
   * defines whether the component is read-only
   */
  isReadOnly: PropTypes.bool,
  /**
   * it sets the App component`s state
   * This prop will be passed from App.js component through Overview.js
   */
  handleSetAppState: PropTypes.func,
  /**
   * indicates the status of all folders - open, closed or null
   */
  allFoldersStatus: PropTypes.string,
  /**
   * Object with a saved folder settings
   */
  folderSettings: PropTypes.instanceOf(Object),
};

export default Folders;
