import FeatureAdvertsAPI from '../../../api/feature-adverts';
import {
  SET_ALL_FEATURE_ADVERTS,
} from '../types';

export const setAllFeatureAdverts = () => async (dispatch) => {
  const { featureAdverts } = await FeatureAdvertsAPI.getFeatureAdverts();

  dispatch({
    type: SET_ALL_FEATURE_ADVERTS,
    payload: featureAdverts,
  });
};
